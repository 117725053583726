import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"
import { Row, Col } from "reactstrap"

import { useCountryOptions } from "hooks"
import { NormalizeUtils } from "utils"
import { ControllerHF, InputField, ComboboxFormField } from "components"

export const ClientSection = props => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const countryOptions = useCountryOptions({ visible: true })

  return (
    <>
      <div className={"d-flex"}>
        <h4 className={"mb-4"}>{t("client")}</h4>
      </div>

      <Row>
        <Col md={6}>
          <ControllerHF
            name={`client_name`}
            control={control}
            component={InputField}
            id={"client_name"}
            label={t("client_name")}
            placeholder={t("client_name")}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <ControllerHF
            name={`client_address_line`}
            control={control}
            component={InputField}
            id={"client_address_line"}
            label={t("street")}
            placeholder={t("street")}
          />
        </Col>

        <Col md={6}>
          <ControllerHF
            name={`client_address_city`}
            control={control}
            component={InputField}
            id={"client_address_city"}
            label={t("town")}
            placeholder={t("town")}
          />
        </Col>

        <Col md={6}>
          <ControllerHF
            name={`client_address_zip`}
            control={control}
            component={InputField}
            id={"client_address_zip"}
            label={t("zip")}
            placeholder={t("zip")}
          />
        </Col>

        <Col md={6}>
          <ControllerHF
            name={`client_country_id`}
            control={control}
            component={ComboboxFormField}
            id={"client_country_id"}
            label={t("country")}
            placeholder={t("country")}
            options={countryOptions}
            getOptionLabel={option => `${option.name}`}
            getOptionValue={option => option.id}
            selectProps={{
              isClearable: true,
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <ControllerHF
            name={"client_vat"}
            control={control}
            component={InputField}
            id={"client_vat"}
            label={t("client_vat")}
            placeholder={t("client_vat")}
          />
        </Col>
      </Row>
    </>
  )
}

ClientSection.propTypes = {}
