import { all } from "redux-saga/effects"

import listSaga from "./list/saga"
import removeSaga from "./remove/saga"
import addSaga from "./add/saga"
import updateSaga from "./update/saga"
import groupDetailsSaga from "./groupDetails/saga"
import membersSaga from "./members/sagas"

function* groupsSaga() {
  yield all([
    listSaga(),
    updateSaga(),
    removeSaga(),
    addSaga(),
    groupDetailsSaga(),
    membersSaga(),
  ])
}

export default groupsSaga
