import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useFetchImage, useSubmitHandler } from "hooks"
import { modelPhotosUpdateInstagramCrop } from "store"
import { CropPhotoForm } from "./CropPhotoForm"
import * as HOC from "HOC"
import { modelHelper } from "helpers"

const DataShower = HOC.withDataShower()

const { getInstagramCropFormInitialValues } = modelHelper.photos

const CropPhotoComponent = ({
  instagramCrop,
  src,
  attachmentId,
  onCancel,
  loading,
  error,
  onSuccessCropCreate,
}) => {
  const dispatch = useDispatch()

  const { fetchImage, state: imageState, cancelFetch } = useFetchImage()

  useEffect(() => {
    fetchImage(src)
    return () => {
      dispatch(modelPhotosUpdateInstagramCrop.cleanState())
      cancelFetch()
    }
  }, [src])

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        modelPhotosUpdateInstagramCrop.update({
          attachmentId,
          params: {
            ...params,
          },
          onSuccess,
          onError,
        })
      )
    },
    () => onSuccessCropCreate()
  )

  return (
    <DataShower
      isLoading={imageState.loading}
      isFailed={imageState.error}
      error={imageState.error}
    >
      <CropPhotoForm
        onCancel={onCancel}
        hasCrop={!!instagramCrop}
        imageDataURL={imageState.imageDataURL}
        loading={loading}
        error={error}
        initialValues={
          instagramCrop ? getInstagramCropFormInitialValues(instagramCrop) : {}
        }
        onSubmit={handleSubmit}
      />
    </DataShower>
  )
}

CropPhotoComponent.propTypes = {
  instagramCrop: PropTypes.object,
  src: PropTypes.string,
  attachmentId: PropTypes.any,

  loading: PropTypes.bool,
  error: PropTypes.any,

  onCancel: PropTypes.func,
  onSuccessCropCreate: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, error } =
    state.modelPanel.photos.details.editPhoto.updateInstagramCrop
  return {
    loading,
    error,
  }
}

export const CropPhoto = connect(mapStateToProps)(CropPhotoComponent)
