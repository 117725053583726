import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { validationSchema } from "./validation"
import { ValidationErrors, Button } from "components"
import { InputSection } from "./InputSection"
import { AttachmentsSection } from "./AttachmentsSection"

export const EntryForm = props => {
  const { t } = useTranslation()

  const inputRef = useRef()
  const attachmentsFilesUploaderRef = useRef()

  const methods = useForm({
    defaultValues: {
      body: "",
      files: [],
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  })

  const {
    handleSubmit,
    formState: { isValid },
    setValue,
  } = methods

  const onSuccess = () => {
    onReset()
  }

  const onReset = () => {
    setValue("body", "")
    setValue("files", [])

    // set input focus
    if (inputRef.current) {
      inputRef.current.focus()
    }
    setFilesProcessing([])
  }

  useEffect(() => {
    onReset()
  }, [props.participantId, props.groupChatId])

  // Attachments
  const [filesProcessing, setFilesProcessing] = useState([])

  const addFileToProcessing = fieldId => {
    setFilesProcessing(prevState => [...prevState, fieldId])
  }

  const removeFileFromProcessing = fieldId => {
    setFilesProcessing(prevState => prevState.filter(item => item !== fieldId))
  }

  const onAttachFiles = files => {
    if (attachmentsFilesUploaderRef.current) {
      attachmentsFilesUploaderRef.current?.addFiles(files)
    }
  }
  // --- //

  const isDisabled = useMemo(() => {
    return (
      props.disabled || props.loading || !isValid || !!filesProcessing.length
    )
  }, [props.disabled, props.loading, isValid, filesProcessing])

  const onSubmit = values => {
    if (isDisabled) {
      return
    }

    const { body, files } = values

    let params = {}

    if (body !== "") {
      params.body = body
    }

    const attachments = files.filter(item => item?.id)
    if (!!attachments.length) {
      params.files = attachments.map(item => item?.id)
    }

    props.onSubmit(params, { onSuccess })
  }

  const onFormSubmit = () => {
    handleSubmit(onSubmit)()
  }

  return (
    <FormProvider {...methods}>
      <AttachmentsSection
        addFileToProcessing={addFileToProcessing}
        removeFileFromProcessing={removeFileFromProcessing}
        processing={filesProcessing}
        filesUploaderRef={attachmentsFilesUploaderRef}
      />

      <div className="p-3 chat-input-section">
        {props.error && <ValidationErrors data={props.error} />}

        <Row>
          <Col>
            <InputSection
              disabled={props.loading}
              onSubmit={onFormSubmit}
              inputRef={inputRef}
              onAttachFiles={onAttachFiles}
            />
          </Col>

          <Col className="col-auto">
            <Button
              loading={props.loading}
              leftIcon={"mdi mdi-send"}
              title={"btn.send"}
              disabled={isDisabled}
              onClick={onFormSubmit}
            />
          </Col>
        </Row>
      </div>
    </FormProvider>
  )
}

EntryForm.propTypes = {
  participantId: PropTypes.number,
  groupChatId: PropTypes.number,
  loading: PropTypes.bool,
  error: PropTypes.any,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
}
