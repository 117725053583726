import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Alert } from "reactstrap"

import { Button } from "components"
import { TariffInfo } from "./TariffInfo"

export const SwapConfirm = ({ tariff, ...props }) => {
  const { t } = useTranslation()

  return (
    <>
      {props.error && <Alert color="danger">{props.error}</Alert>}

      <TariffInfo data={tariff} />

      <div className="mt-3 mb-3">
        <div className="button-items">
          <Button
            title={"btn.confirm"}
            disabled={props.loading}
            loading={props.loading}
            onClick={props.onConfirm}
          />

          <Button
            title={"cancel"}
            color="light"
            outline
            onClick={props.onCancel}
          />
        </div>
      </div>
    </>
  )
}

SwapConfirm.propTypes = {
  tariff: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
}
