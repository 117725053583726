import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { useTranslation } from "react-i18next"

import { eventsCalendarHelper } from "helpers"
import { EventInfo } from "./EventInfo"
import { LogoIcon } from "components"

export const PrintContent = React.forwardRef(
  ({ date, events = [], onPrint }, ref) => {
    const { t } = useTranslation()

    const eventsTypeGroup = useMemo(() => {
      let eventsEnum = {}

      events.forEach(event => {
        const eventType = event.type
        if (eventsEnum[eventType]) {
          eventsEnum[eventType].events.push(event)
        } else {
          const eventConfig =
            eventsCalendarHelper.eventConfig.getEventConfig(eventType)
          eventsEnum[eventType] = {
            title: eventConfig.title,
            order: eventConfig.order,
            events: [event],
          }
        }
      })

      return Object.values(eventsEnum).sort((a, b) => a.order - b.order)
    }, [events])

    useEffect(() => {
      onPrint()
    }, [])

    return (
      <div ref={ref} className={"print-events-content"}>
        <div className="mb-4">
          <LogoIcon size="40" />
        </div>

        <div className={"mb-4"}>
          <h4>{t("events_of_day")}</h4>
          <h4>{moment(date).format("DD/MM/YYYY")}</h4>
        </div>
        <div>
          {eventsTypeGroup.map((item, i) => (
            <div className={"mb-5"} key={i}>
              <h5 className={"text-center"}>{item.title}</h5>

              <div>
                {item.events.map((event, index) => (
                  <div
                    key={index}
                    className={"print-events-content__event-item mb-3 pb-3"}
                  >
                    <EventInfo event={event} />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
)

PrintContent.propTypes = {
  date: PropTypes.object,
  events: PropTypes.array,
  onPrint: PropTypes.func,
}
