import { createSlice } from "@reduxjs/toolkit"

const initialState = {}

const socketGeneralSlice = createSlice({
  name: "socketGeneral",
  initialState,
  reducers: {
    resetState(state) {
      return state
    },
  },
})

/**
 * @namespace
 * @property {function} resetState
 */

export const socketGeneral = socketGeneralSlice.actions

export default socketGeneralSlice.reducer
