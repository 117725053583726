import React, { useState, useCallback, useEffect } from "react"
import { useCopyToClipboard as useRUCopyToClipboard } from "react-use"
import { debounce } from "lodash"

export const useCopyToClipboard = ({ resetDelay = 1500, ...props }) => {
  const [state, copyToClipboard] = useRUCopyToClipboard()

  const [copied, setCopied] = useState(false)

  const resetCopied = () => {
    setCopied(false)
  }

  const onDebouncedReset = useCallback(debounce(resetCopied, resetDelay), [
    resetCopied,
  ])

  const onCopy = useCallback(value => {
    copyToClipboard(value)

    setCopied(true)

    onDebouncedReset()
  }, [])

  const onDebouncedResetCancel = () => {
    onDebouncedReset.cancel()
  }

  const onReset = () => {
    resetCopied()
    onDebouncedResetCancel()
  }

  useEffect(() => {
    return () => {
      onDebouncedResetCancel()
    }
  }, [])

  return {
    state: {
      ...state,
      copied,
    },
    onCopy,
    onReset,
  }
}
