import React from "react"
import PropTypes from "prop-types"

import { GeneralProvider } from "./GeneralProvider"
import { HolidayProvider } from "./HolidayProvider"

export const EventsCalendarEventContentProvider = ({ provider, ...props }) => {
  switch (provider) {
    case "general":
      return <GeneralProvider {...props} />

    case "holiday":
      return <HolidayProvider {...props} />

    default:
      return <GeneralProvider {...props} />
  }
}

EventsCalendarEventContentProvider.propTypes = {
  provider: PropTypes.string,
}
