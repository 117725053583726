import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import {
  websiteAddAvatar,
  websiteRemoveAvatar,
} from "../../../../../../store/website/avatar/actions"
import { UploadAvatar } from "./UploadAvatar"

const EditLogoContainerComponent = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(websiteAddAvatar.cleanState())
      dispatch(websiteRemoveAvatar.cleanState())
    }
  }, [])

  const handleUpload = photo => {
    let params = {
      avatar: photo,
    }
    dispatch(
      websiteAddAvatar.add({
        params,
      })
    )
  }

  const handleRemove = () => {
    dispatch(websiteRemoveAvatar.remove())
  }
  return (
    <UploadAvatar
      image={props.website?.avatar?.src}
      loading={props.loading}
      onUpload={handleUpload}
      onRemove={handleRemove}
    />
  )
}

EditLogoContainerComponent.propTypes = {
  website: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.website.general.data
  const { loading, error } = state.website.avatar.add
  return {
    loading,
    error,
    website: data,
  }
}

export const EditLogo = connect(mapStateToProps)(EditLogoContainerComponent)
