import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from "toastr";

import i18n from '../../../../i18n';
import { MODEL_PANEL_PHOTOS_ADD_PHOTO } from './actionTypes';
import { modelPanelPhotosAddPhotoSuccess, modelPanelPhotosAddPhotoError } from './actions';

import { API } from '../../../../api';

function* addPhoto({ payload: { id, params, onSuccess, onError } }) {
    try {
        const response = yield call(API.model.uploadModelPhoto, id, params);

        const { data } = response;

        yield put(modelPanelPhotosAddPhotoSuccess());

        toastr.success(i18n.t('model-photo.add.success'));

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        const errors = error.response?.data?.errors || {};
        const message = error.response?.data?.message || i18n.t('error');

        yield put(modelPanelPhotosAddPhotoError({
            errors,
            message
        }));

        if (onError) {
            onError(errors);
        }
    }
}

export function* watchAddPhoto() {
    yield takeEvery(MODEL_PANEL_PHOTOS_ADD_PHOTO, addPhoto);
}

function* addSaga() {
    yield all([
        fork(watchAddPhoto),
    ]);
}

export default addSaga;