import { combineReducers } from "redux"

import add from "./add/slice"
import remove from "./remove/slice"

const logoReducer = combineReducers({
  add,
  remove,
})

export default logoReducer
