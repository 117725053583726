import { all } from 'redux-saga/effects';

import addSaga from './add/saga';
import listSaga from './list/sagas';

function* panelSaga() {
    yield all([
        addSaga(),
        listSaga(),
    ])
}

export default panelSaga;