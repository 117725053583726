import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { Col, Row } from "reactstrap"
import { ControllerHF } from "../../../../ReactHookForm"
import { InputField } from "../../../../FormElements"

export const NotesSection = ({ config }) => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const modelNoteConfig = useMemo(() => {
    let data = {
      label: t("note_models"),
      placeholder: t("note_models"),
    }

    if (config?.modelNote) {
      data = {
        ...data,
        ...config?.modelNote,
      }
    }
    return data
  }, [config])

  const internalNoteConfig = useMemo(() => {
    let data = {
      label: t("note_internal"),
      placeholder: t("note_internal"),
    }

    if (config?.internalNote) {
      data = {
        ...data,
        ...config?.internalNote,
      }
    }
    return data
  }, [config])

  return (
    <Row>
      <Col md={6}>
        <ControllerHF
          name={"note_models"}
          control={control}
          component={InputField}
          id={"note_models"}
          label={modelNoteConfig.label}
          placeholder={modelNoteConfig.placeholder}
          type={"textarea"}
        />
      </Col>

      <Col md={6}>
        <ControllerHF
          name={"note_internal"}
          control={control}
          component={InputField}
          id={"note_internal"}
          label={internalNoteConfig.label}
          placeholder={internalNoteConfig.placeholder}
          type={"textarea"}
        />
      </Col>
    </Row>
  )
}

NotesSection.propTypes = {
  config: PropTypes.object,
}
