import React from "react"
import { useTranslation } from "react-i18next"

import { ControllerHF, InputField } from "components"
import { useFormContext } from "react-hook-form"

export const Description = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <ControllerHF
      name={"description"}
      id={"description"}
      label={t("description")}
      placeholder={t("description")}
      control={control}
      component={InputField}
      className={"mb-0"}
    />
  )
}

Description.propTypes = {}
