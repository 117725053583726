import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { ModelsFormField } from "./ModelsFormField"
import { getUpdatedLinkedModels } from "../../../helpers"
import {
  ModelDetailsContainer,
  ModelDetailsDrawer,
} from "../../../../EventModelDetails"
import { AlertPopup } from "../../../../../Popups"

export const ModelsForm = props => {
  const { t } = useTranslation()
  const [model, setModel] = useState(null)
  const [modelDetailsDrawerVisible, setModelDetailsDrawerVisible] =
    useState(false)
  const [eventDate, setEventDate] = useState(null)

  const alertModalRef = useRef(null)

  const { control, setValue, getValues, trigger, watch } = useFormContext()

  const watchGender = watch("gender")

  const onModelUpdate = (model, payload) => {
    const modelsValue = getValues("fashion_models")
    const items = [
      ...getUpdatedLinkedModels(modelsValue, {
        id: model.id,
        data: { ...payload },
      }),
    ]
    setValue("fashion_models", items)

    // update selected model data for update model details component (rates, expenses and etc)
    // make: get model data from form
    if (model) {
      setModel({
        ...model,
        ...payload,
      })
    }
  }

  const onModelEdit = async item => {
    const result = await trigger(["date_start", "date_end"])

    if (result) {
      setModel(item)
      setModelDetailsDrawerVisible(true)

      setEventDate({
        start: getValues("date_start"),
        end: getValues("date_end"),
      })
    } else {
      if (alertModalRef.current) {
        alertModalRef.current.alert(
          <p className={"font-size-22"}>{t("event.alert.invalid.date")}</p>
        )
      }
    }
  }

  const closeModelDetailsDrawer = () => {
    setModelDetailsDrawerVisible(false)
  }

  const onModelDetailsDrawerDestroy = () => {
    setModel(null)
  }

  return (
    <>
      <ModelsFormField
        {...props}
        control={control}
        onModelEdit={onModelEdit}
        onModelUpdate={onModelUpdate}
        gender={watchGender}
      />

      <ModelDetailsDrawer
        model={model}
        onDestroy={onModelDetailsDrawerDestroy}
        onClose={closeModelDetailsDrawer}
        visible={modelDetailsDrawerVisible}
      >
        <ModelDetailsContainer
          model={model}
          eventId={props.eventId}
          onModelUpdate={onModelUpdate}
          eventDate={eventDate}
          eventType={props.eventType}
        />
      </ModelDetailsDrawer>

      <AlertPopup ref={alertModalRef} />
    </>
  )
}

ModelsForm.propTypes = {
  eventId: PropTypes.number,
  eventType: PropTypes.string,
}
