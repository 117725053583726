import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { generatePath, Link } from "react-router-dom"

import { Button } from "components"

export const Intro = () => {
  const { t } = useTranslation()

  const getPath = () => {
    return generatePath(`/website/intro/create-website`)
  }

  return (
    <React.Fragment>
      <p>{t("website.intro-text")}</p>
      <Link to={getPath()}>
        <Button type={"button"} title={t("create-website")} />
      </Link>
    </React.Fragment>
  )
}

Intro.propTypes = {}
