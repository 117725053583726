import { combineReducers } from "redux"

import list from "./list/slice"
import data from "./data/slice"
import remove from "./remove/slice"
import update from "./update/slice"
import add from "./add/slice"
import files from "./files/reducers"

const contactsReducer = combineReducers({
  list,
  remove,
  update,
  add,
  data,
  files,
})

export default contactsReducer
