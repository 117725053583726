import { all } from "redux-saga/effects"

import updateSaga from "./update/saga"
import dataSaga from "./data/saga"
import attachmentsSaga from "./attachments/sagas"

function* currentPageSaga() {
  yield all([updateSaga(), dataSaga(), attachmentsSaga()])
}

export default currentPageSaga
