import { combineReducers } from "redux"

import data from "./data/slice"
import edit from "./edit/slice"

const settingsReducer = combineReducers({
  data,
  edit,
})

export default settingsReducer
