import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import checkboxBlankCircle from "../../../assets/images/dev/icons/checkbox-blank-circle.svg"
import checkboxMarkedCircle from "../../../assets/images/dev/icons/checkbox-marked-circle.svg"

export const CheckboxMark = ({ status, className, size, circle = false }) => {
  return (
    <>
      <img
        src={status ? checkboxMarkedCircle : checkboxBlankCircle}
        alt={"icon"}
        className={classNames("checkbox-mark", {
          [`checkbox-mark_size_${size}`]: size,
          [className]: className,
        })}
      />
    </>
  )
}

CheckboxMark.propTypes = {
  status: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
  circle: PropTypes.bool,
}
