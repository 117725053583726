import { eventsCalendarHelper } from "helpers"
import {
  EVENT_TYPE_NOTE,
  EVENT_TYPE_SCOUTING,
  EVENT_TYPE_GO_SEE,
  EVENT_TYPE_CASTING,
  EVENT_TYPE_FITTING,
  EVENT_TYPE_JOB,
} from "consts"

import i18n from "i18n"

const eventsTypes = [
  EVENT_TYPE_NOTE,
  EVENT_TYPE_SCOUTING,
  EVENT_TYPE_GO_SEE,
  EVENT_TYPE_CASTING,
  EVENT_TYPE_FITTING,
  EVENT_TYPE_JOB,
]

export const getEventTypeOptions = () => {
  const eventsOptions = eventsCalendarHelper.eventConfig
    .getEventsByTypes(eventsTypes)
    .map(item => ({
      label: item.title,
      value: item.type,
    }))

  return [
    {
      label: i18n.t("all"),
      value: "all",
    },
    ...eventsOptions,
  ]
}
