import { combineReducers } from "redux"

import list from "./list/slice"
import edit from "./edit/reducers"
import settings from "./settings/reducers"

const statementReducer = combineReducers({
  list,
  edit,
  settings,
})

export default statementReducer
