import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import i18n from '../../../../../../i18n';
import { MODEL_PANEL_GENERAL_FETCH_AGENCY } from './actionTypes';
import { modelPanelGeneralFetchAgencySuccess, modelPanelGeneralFetchAgencyError } from './actions';

import { API } from '../../../../../../api';

export function* fetchAgency({ payload: { id } }) {
    try {
        const response = yield call(API.model.getModelAgency, id);

        const { data } = response;

        yield put(modelPanelGeneralFetchAgencySuccess(data?.data));
    } catch (error) {
        const message = error.response?.data?.message || i18n.t('error');
        yield put(modelPanelGeneralFetchAgencyError(message));
    }
}

export function* watchFetchAgency() {
    yield takeEvery(MODEL_PANEL_GENERAL_FETCH_AGENCY, fetchAgency)
}

function* dataSaga() {
    yield all([
        fork(watchFetchAgency),
    ]);
}

export default dataSaga;