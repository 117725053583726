import moment from "moment"

import {
  getEventColor,
  getEventConfig,
  isHolidayCategoryEvent,
} from "./eventConfig"
import {
  EVENT_TYPE_JOB,
  EVENT_TYPE_OPTION,
  EVENTS_CALENDAR_MODE,
  GENERAL_CALENDAR_ENTITY,
} from "../../consts"
import _ from "lodash"
import { commonHelper } from "helpers"
import i18n from "i18n"

const { MODEL: ENTITY_MODEL } = GENERAL_CALENDAR_ENTITY

const getDatesBetweenDates = (startDate, endDate) => {
  const start = moment(startDate)
  const end = moment(endDate)

  let dates = []

  while (start.isSameOrBefore(end)) {
    dates.push(start.toDate())
    start.add(1, "days")
  }
  return dates
}

const mapCalendarEventData = (
  params,
  event,
  entityScreenConfig,
  eventColors
) => {
  const { date_start, date_end } = params
  const { times, id, type } = event

  const eventConfig = getEventConfig(type)

  const eventColor = getEventColor(eventColors, type)

  const { textColor, order } = eventConfig

  // let allDay = true
  let start = moment(date_start).startOf("day").toDate()
  let end = moment(date_end).endOf("day").toDate()

  const getDate = (date, timeString) => {
    date = moment(date)
    const day = date.date()
    const month = date.month()
    const year = date.year()

    const dateTime = moment(timeString, "HH:mm").toDate()
    const hour = dateTime.getHours()
    const min = dateTime.getMinutes()
    const sec = dateTime.getSeconds()

    return new Date(year, month, day, hour, min, sec)
  }

  if (times && times.length > 0) {
    // allDay = false
    if (times[0]?.start) {
      start = getDate(date_start, times[0].start)
    }
    if (times[times.length - 1]?.end) {
      end = getDate(date_end, times[times.length - 1].end)
    }
  }

  // if open calendar from model page (agency, client pages)
  if (entityScreenConfig) {
    const { entityId, entity } = entityScreenConfig
    if (entityScreenConfig?.entity === ENTITY_MODEL) {
      entityScreenConfig = {
        ...entityScreenConfig,
        entityEventType: getEntityEventType(event, entity, entityId),
      }
    }
  }

  return {
    title: getCalendarEventTitle(event),
    start: start,
    end: end,
    // allDay: allDay,
    className: "calendar-event",
    textColor,
    backgroundColor: eventColor,
    borderColor: eventColor,
    display: "block",
    extendedProps: {
      ...event,
      type: type,
      id: id, // id - empty in birthday event
      order,
      entityScreenConfig, // field store value if calendar open from entity page (model, agency, client)
    },
  }
}

export const mapEventToCalendarEvents = (
  data,
  entityScreenConfig,
  eventColors
) => {
  const { date_start, date_end } = data

  let calendarEvents = []

  let mappedEvents = []

  // check if event has dates period
  if (moment(date_start).isSame(moment(date_end))) {
    let mappedEvent = mapCalendarEventData(
      data,
      data,
      entityScreenConfig,
      eventColors
    )
    mappedEvents = [mappedEvent]
  } else {
    const datesPeriod = getDatesBetweenDates(date_start, date_end)
    for (let d = 0; d < datesPeriod.length; d++) {
      const item = datesPeriod[d]
      let mappedEvent = mapCalendarEventData(
        {
          ...data,
          date_start: item,
          date_end: item,
        },
        data,
        entityScreenConfig,
        eventColors
      )

      mappedEvents = [...mappedEvents, mappedEvent]
    }
  }

  calendarEvents = [...calendarEvents, ...mappedEvents]

  return calendarEvents
}

export const mapEventsToCalendarEvents = (
  events,
  entityScreenConfig,
  eventColors
) => {
  let calendarEvents = []
  for (let i = 0; i < events.length; i++) {
    let event = events[i]

    let mappedEvents = mapEventToCalendarEvents(
      event,
      entityScreenConfig,
      eventColors
    )
    calendarEvents = [...calendarEvents, ...mappedEvents]
  }

  return calendarEvents
}

export const convertTimeStringToDateTime = timePeriods => null

export const getCalendarEventTitle = event => {
  const { title, client, agency, type, product } = event

  let result = null

  if (client && client?.name) {
    result = client?.name
  } else if (agency && agency?.name) {
    result = agency?.name
  } else if (title) {
    if (isHolidayCategoryEvent(type)) {
      result = `${i18n.t("birthday.name", { name: title })} `
    } else {
      result = title
    }
  } else if (product) {
    result = product
  } else {
    result = getEventConfig(type)?.title
  }

  return result
}

// update html element of calendar event (on fullcalendar event did mount)
// e.g.: need for add transition background in event which transform from option to job
export const onCalendarEventUpdateElement = (info, eventColors) => {
  const { all_types, entityScreenConfig } = info.event.extendedProps

  let elementStyle = null
  let fcElementEventMainStyle = null

  if (isOptionJobEventType(all_types)) {
    const jobBackgroundColor = commonHelper.colors.getColorFromEntity(
      eventColors[EVENT_TYPE_JOB]
    )
    const jobTextColor = getEventConfig(EVENT_TYPE_JOB).textColor
    const optionBackgroundColor = commonHelper.colors.getColorFromEntity(
      eventColors[EVENT_TYPE_OPTION]
    )

    // only if is model calendar -> show option/job event like option or job event depend on model event type
    // else -> transition background from option to job colors
    if (entityScreenConfig && entityScreenConfig?.entity === ENTITY_MODEL) {
      const { entityEventType } = entityScreenConfig
      elementStyle = {
        background:
          entityEventType === EVENT_TYPE_JOB
            ? jobBackgroundColor
            : optionBackgroundColor,
        borderColor:
          entityEventType === EVENT_TYPE_JOB
            ? jobBackgroundColor
            : optionBackgroundColor,
      }
    } else {
      elementStyle = {
        background: `linear-gradient(90deg, ${jobBackgroundColor}, ${optionBackgroundColor})`,
        borderColor: jobBackgroundColor, // if make border "transparent" -> padding: "3px"
      }
    }

    fcElementEventMainStyle = {
      color: jobTextColor,
    }
  }

  if (elementStyle) {
    for (let k in elementStyle) {
      if (elementStyle.hasOwnProperty(k)) {
        info.el.style[k] = elementStyle[k]
      }
    }
  }

  // add styles for inner element (text-color)
  let fcElementEventMain = info.el.getElementsByClassName("fc-event-main")[0]
  if (fcElementEventMain && fcElementEventMainStyle) {
    for (let k in fcElementEventMainStyle) {
      if (fcElementEventMainStyle.hasOwnProperty(k)) {
        fcElementEventMain.style[k] = fcElementEventMainStyle[k]
      }
    }
  }
}

export const isOptionJobEventType = (eventAllTypes = []) => {
  return (
    eventAllTypes.includes(EVENT_TYPE_OPTION) &&
    eventAllTypes.includes(EVENT_TYPE_JOB)
  )
}

export const getEventTypeTitle = event => {
  const { type, all_types } = event

  if (isOptionJobEventType(all_types)) {
    return (
      getEventConfig(EVENT_TYPE_OPTION)?.title +
      "/" +
      getEventConfig(EVENT_TYPE_JOB)?.title
    )
  }

  return getEventConfig(type)?.title
}

export const getGeneralEventTypeTitle = (event, entityScreen, entityId) => {
  if (!event) {
    return ""
  }

  if (entityScreen === ENTITY_MODEL) {
    const type = getEntityEventType(event, entityScreen, entityId)

    // can be null
    if (type) {
      return getEventConfig(type)?.title
    }
  }

  return getEventTypeTitle({
    ...event,
  })
}

export const getEntityEventType = (event, entityScreen, entityId) => {
  const { type, all_types } = event

  let entityScreenEventType = type

  if (entityScreen === ENTITY_MODEL && isOptionJobEventType(all_types)) {
    // can be null if model not found in event
    entityScreenEventType = getEventTypeByModelType(event, entityId)
  }

  return entityScreenEventType
}

export const getEventTypeByModelType = (event, modelId) => {
  const { fashion_models } = event
  let eventType = null

  if (fashion_models && !!fashion_models.length) {
    const item = _.find(fashion_models, { id: modelId })
    if (item) {
      eventType = item?.type
    }
  }

  return eventType
}

export const getEventsOfDate = (events, date) =>
  events.filter(item => {
    date = moment(date)
    const dateStart = moment(item.date_start)
    const dateEnd = moment(item.date_end)
    return date.isBetween(dateStart, dateEnd, "days", "[]")
  })

// Calendar config mode
export const isAdministrationMode = mode => {
  return mode === EVENTS_CALENDAR_MODE.ADMINISTRATION
}

export const isEventTypeJobState = (event, entityScreen, entityId) => {
  if (entityScreen === ENTITY_MODEL) {
    const eventType = getEntityEventType(event, entityScreen, entityId)
    return eventType === EVENT_TYPE_JOB
  }

  return event.all_types.includes(EVENT_TYPE_JOB)
}
