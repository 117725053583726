import React from "react"
import PropTypes from "prop-types"
import { PostDelete } from "./PostDelete"
import { Button, LinkButton } from "components"

export const PreviewSidebar = ({
  onEdit,
  isDeletable,
  isEditable,
  isViewable,
  postLink,
}) => {
  return (
    <div
      className={"d-flex flex-column justify-content-center align-items-center"}
    >
      <div className={"w-50"}>
        {isViewable && (
          <div className={"mb-3"}>
            <LinkButton
              title={"btn.view-post"}
              btnClass={"w-100"}
              target={"blank"}
              color={"danger"}
              to={postLink}
              disabled={!postLink}
            />
          </div>
        )}

        {isEditable && (
          <div className={"mb-3"}>
            <Button title={"btn.edit"} btnClass={"w-100"} onClick={onEdit} />
          </div>
        )}
        {isDeletable && (
          <div className={"mt-4"}>
            <PostDelete />
          </div>
        )}
      </div>
    </div>
  )
}

PreviewSidebar.propTypes = {
  onEdit: PropTypes.func,
  onViewPost: PropTypes.func,
  isDeletable: PropTypes.bool,
  isEditable: PropTypes.bool,
  isViewable: PropTypes.bool,
  postLink: PropTypes.string,
}
