import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { CALENDAR_EVENT_NOTIFICATION_STATUS_EMAIL_READ as READ } from "consts"
import { EventCalendarIcon, Button } from "components"

export const ModelRow = ({ data, contractProps, isJobStateEventType }) => {
  const { t } = useTranslation()

  const { contract, ...model } = data

  const { name, surname, notification_status } = model

  const { onCreate } = contractProps

  const renderNotificationStatus = item => (
    <EventCalendarIcon
      name={"check"}
      className={"font-size-18"}
      double={item.notification_status === READ}
    />
  )

  const canCreateContract = useMemo(() => {
    if (contract) {
      return false
    }

    return isJobStateEventType(model)
  }, [contract])

  return (
    <tr>
      <td className={"align-middle"}>
        {notification_status && (
          <span className={"me-2 align-middle"}>
            {renderNotificationStatus(data)}
          </span>
        )}

        <span className={"fw-medium"}>
          {name} {surname}
        </span>
      </td>

      <td
        className={"table-column-sticky_end bg-white text-nowrap text-end"}
        style={{ width: "76px" }}
      >
        {canCreateContract && (
          <Button
            outline
            title={t("create_contract")}
            onClick={() => onCreate(model)}
            size={"btn-sm"}
          />
        )}
      </td>
    </tr>
  )
}

ModelRow.propTypes = {
  data: PropTypes.object,
  contractProps: PropTypes.object,
  isJobStateEventType: PropTypes.func,
}
