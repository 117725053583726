import React from "react"
import { Button as StrapButton } from "reactstrap"
import { useFacebookAuth } from "packages/facebook"
import { useTranslation } from "react-i18next"
import { FBconfig } from "configs"
import classnames from "classnames"

export const FacebookLoginButton = () => {
  const { isLoggedIn, loginToFB, disconnectFB, loading } = useFacebookAuth()
  const { t } = useTranslation()
  const handleClick = () => {
    if (isLoggedIn) {
      disconnectFB()
    } else {
      loginToFB(FBconfig.permissions)
    }
  }
  return (
    <StrapButton
      type={"button"}
      className={classnames("btn  font-weight-bold", {
        "btn-facebook": !isLoggedIn,
        "btn-danger": isLoggedIn,
      })}
      onClick={handleClick}
      disabled={loading}
    >
      <i className={"align-middle mr-2 bx bxl-facebook-circle font-size-20"} />
      <span className={"text-capitalize"}>
        {isLoggedIn ? t("disconnect-facebook") : t("connect-with-facebook")}
      </span>
    </StrapButton>
  )
}

FacebookLoginButton.propTypes = {}
