import {
    MODEL_ADD_BOOK,
    MODEL_ADD_BOOK_SUCCESS,
    MODEL_ADD_BOOK_ERROR,
    MODEL_ADD_BOOK_CLEAN_STATE,
} from './actionTypes';

export const modelAddBook = (id, params, onSuccess, onError) => {
    return {
        type: MODEL_ADD_BOOK,
        payload: { id, params, onSuccess, onError },
    }
};

export const modelAddBookSuccess = () => {
    return {
        type: MODEL_ADD_BOOK_SUCCESS,
    }
};

export const modelAddBookError = (data) => {
    return {
        type: MODEL_ADD_BOOK_ERROR,
        payload: data,
    }
};

export const modelAddBookCleanState = () => {
    return { type: MODEL_ADD_BOOK_CLEAN_STATE };
};