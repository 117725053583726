import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import toastr from "toastr"

import { AttachmentListControl } from "../../../../../Controls"
import {
  calendarEventModelAttachmentList,
  calendarEventModelAttachmentUpload,
  calendarEventModelAttachmentEdit,
  calendarEventModelAttachmentRemove,
} from "store/actions"

import * as HOC from "../../../../../../HOC"

const DataShower = HOC.withDataShower()

export const ModelAttachments = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [isEdit, setIsEdit] = useState(false)

  const { list, listLoading, listError } = useSelector(
    state => state.calendarEvent.model.attachment.list
  )
  const { loading: submitLoading, error: submitError } = useSelector(state =>
    !isEdit
      ? state.calendarEvent.model.attachment.upload
      : state.calendarEvent.model.attachment.edit
  )
  const { loading: removing } = useSelector(
    state => state.calendarEvent.model.attachment.remove
  )

  useEffect(() => {
    onFetchData()

    return () => {
      dispatch(calendarEventModelAttachmentList.cleanState())
      dispatch(calendarEventModelAttachmentRemove.cleanState())
    }
  }, [])

  const onFetchData = () => {
    dispatch(
      calendarEventModelAttachmentList.getList({
        event: props.eventId,
        model: props.modelId,
      })
    )
  }

  const onSubmitReset = () => {
    dispatch(calendarEventModelAttachmentUpload.cleanState())
    dispatch(calendarEventModelAttachmentEdit.cleanState())
  }

  const onSubmitSuccess = payload => data => {
    const { onSuccess } = payload
    onSuccess(data)

    toastr.success(t("save.success"))
  }

  const onSubmitError = payload => errors => {
    const { onError } = payload
    onError(errors)
  }

  const onAdd = (data, payload) => {
    isEdit && setIsEdit(false)

    dispatch(
      calendarEventModelAttachmentUpload.upload({
        event: props.eventId,
        model: props.modelId,
        params: data,
        onSuccess: onSubmitSuccess(payload),
        onError: onSubmitError(payload),
      })
    )
  }

  const onEdit = (data, payload) => {
    setIsEdit(true)

    dispatch(
      calendarEventModelAttachmentEdit.edit({
        id: data?.id,
        params: data,
        onSuccess: onSubmitSuccess(payload),
        onError: onSubmitError(payload),
      })
    )
  }

  const onRemove = (data, payload) => {
    const { onSuccess } = payload

    dispatch(
      calendarEventModelAttachmentRemove.remove({
        id: data?.id,
        onSuccess: () => {
          onSuccess(data)

          toastr.success(t("save.success"))
        },
      })
    )
  }

  const onAttachmentChange = value => {
    dispatch(calendarEventModelAttachmentList.updateList(value))
  }

  return (
    <>
      <DataShower
        isLoading={listLoading}
        isFailed={listError}
        error={listError}
      >
        <AttachmentListControl
          async
          onAdd={onAdd}
          onEdit={onEdit}
          onRemove={onRemove}
          value={list}
          onChange={onAttachmentChange}
          loading={submitLoading}
          error={submitError}
          generalLoading={removing}
          onFormDestroy={onSubmitReset}
        />
      </DataShower>
    </>
  )
}

ModelAttachments.propTypes = {
  eventId: PropTypes.number,
  modelId: PropTypes.number,
}
