import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useDeepCompareEffect, useFirstMountState } from "react-use"
import { websiteModelsList, websiteModelsUpdateBook } from "store"
import { Header } from "../Header"
import { ConfirmPopup, GridDataLoader } from "components"
import { useConfirmModal } from "hooks"
import { AddBooksContainer } from "../AddBooksContainer"
import { useTranslation } from "react-i18next"
import { ModelBookOverviewCard } from "../ModelBookOverviewCard"

const ModelsContainerComponent = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isFirstMount = useFirstMountState()

  useEffect(() => {
    if (!props.isFetched) {
      fetchData()
    }
    return () => {
      dispatch(websiteModelsList.cleanState())
    }
  }, [])

  useDeepCompareEffect(() => {
    if (!isFirstMount) {
      fetchData()
    }
  }, [props.filters.values, props.page])

  const fetchData = () => {
    let params = {
      page: props.page,
      per_page: 8,
    }

    const { keywords } = props.filters.values

    params.filters = {
      ...props.filters.values,
      search_fields: keywords ? ["fullname"] : null,

      with_publish_book: true,
      genders: [props.gender],
    }
    dispatch(websiteModelsList.getList({ params }))
  }

  const handlePageChange = item => {
    dispatch(websiteModelsList.setPage(item.selected + 1))
  }
  //========================ADD DRAWER LOGIC=======================//
  const [addDrawerVisible, setAddDrawerVisible] = useState(false)
  const handleAdd = () => {
    setAddDrawerVisible(true)
  }
  const closeAddDrawer = () => {
    setAddDrawerVisible(false)
  }

  //==========================UPDATE LOGIC ======================//
  const handleUpdateSuccess = () => {
    fetchData()
  }
  const { showModal, closeModal, handleConfirm, modalRef } = useConfirmModal(
    item => {
      dispatch(
        websiteModelsUpdateBook.update({
          id: item.id,
          params: {
            published: false,
          },
          onSuccess: handleUpdateSuccess,
        })
      )
    },
    () => dispatch(websiteModelsUpdateBook.cleanState())
  )
  return (
    <>
      <Header filters={props.filters} onBookAdd={handleAdd} />
      <hr className={"divider my-3"} />
      <GridDataLoader
        items={props.list}
        loading={props.listLoading}
        page={props.page}
        error={props.listError}
        meta={props.meta}
        onPageChange={handlePageChange}
        className={"website-panel-models-grid"}
        renderItem={item => (
          <ModelBookOverviewCard item={item} onRemove={showModal} />
        )}
      />
      <AddBooksContainer
        gender={props.gender}
        visible={addDrawerVisible}
        onClose={closeAddDrawer}
        onAddModelBook={handleUpdateSuccess}
      />
      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
      >
        <p className={"font-size-22"}>{t("sure-unpublish-model-book")}?</p>
      </ConfirmPopup>
    </>
  )
}

ModelsContainerComponent.propTypes = {
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  isFetched: PropTypes.bool,
  meta: PropTypes.object,
  page: PropTypes.any,
  filters: PropTypes.object,
  gender: PropTypes.string,
}

const mapStateToProps = state => {
  const { list, listLoading, listError, meta, page, filters, isFetched } =
    state.website.models.list
  return {
    list,
    listLoading,
    listError,
    meta,
    page,
    filters,
    isFetched,
  }
}

export const ModelsContainer = connect(mapStateToProps)(
  ModelsContainerComponent
)
