import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../../../../i18n';

import { MODEL_PANEL_GENERAL_AGENCY_LIST_REMOVE_ITEM } from './actionTypes';
import {
    modelPanelGeneralAgencyListRemoveItemSuccess,
    modelPanelGeneralAgencyListRemoveItemError,
} from './actions';

import { API } from '../../../../../../api';

function* removeAgency({ payload: { id, onSuccess } }) {
    try {
        const response = yield call(API.model.removeModelAgency, id);

        const { data } = response;

        yield put(modelPanelGeneralAgencyListRemoveItemSuccess(id));
        toastr.success(i18n.t('model-agency.remove.success'));

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        const message = error.response?.data?.message || i18n.t('error');

        yield put(modelPanelGeneralAgencyListRemoveItemError(id));

        toastr.error(message);
    }
}

export function* watchRemoveAgency() {
    yield takeEvery(MODEL_PANEL_GENERAL_AGENCY_LIST_REMOVE_ITEM, removeAgency)
}

function* removeItemSaga() {
    yield all([
        fork(watchRemoveAgency),
    ]);
}

export default removeItemSaga;