import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Link, useRouteMatch } from "react-router-dom"

import { RatingBadge } from "../../../../../components"

export const ClientsGridItem = props => {
  const { t } = useTranslation()
  let { url } = useRouteMatch()

  return (
    <React.Fragment>
      <div className="clients-panel-card-overview">
        <div className={"clients-panel-card-overview__category"}>
          {props.item.category?.value}
        </div>
        <div className={"clients-panel-card-overview__title-block"}>
          <div className={"clients-panel-card-overview__title-name"}>
            <Link to={`${url}/${props.item.id}/general`}>
              {props.item.name}
            </Link>
          </div>

          {props.item?.avg_rating && (
            <RatingBadge
              value={props.item.avg_rating}
              size={"sm"}
              wrapClassName={"ms-1"}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

ClientsGridItem.propTypes = {
  item: PropTypes.object,
}
