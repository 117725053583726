import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { usePopover } from "hooks"
import { Popover } from "components"

export const HelpPopover = ({
  id = "help-popover",
  iconClassName,
  content,
  ...props
}) => {
  const { popoverId, toggle, popoverOpen } = usePopover({
    id,
  })

  return (
    <>
      <i
        role={"button"}
        id={popoverId}
        className={classNames("bx bx-help-circle text-secondary fs-5", {
          [iconClassName]: iconClassName,
        })}
      />

      <Popover popoverId={popoverId} popoverOpen={popoverOpen} toggle={toggle}>
        {content}
      </Popover>
    </>
  )
}

HelpPopover.propTypes = {
  id: PropTypes.string,
  iconClassName: PropTypes.string,
  content: PropTypes.any,
}
