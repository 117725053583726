import i18n from "../../i18n"
import {
  SOCIAL_POST_PUBLICATION_ERROR,
  SOCIAL_POST_PUBLICATION_PENDING,
  SOCIAL_POST_PUBLICATION_SUCCESS,
  SOCIAL_POST_PUBLICATION_UNKNOWN,
  SOCIAL_POST_TYPE_FACEBOOK,
  SOCIAL_POST_TYPE_INSTAGRAM,
} from "consts"
import _ from "lodash"

export const getPostTypeTitle = type => {
  switch (type) {
    case SOCIAL_POST_TYPE_INSTAGRAM:
      return i18n.t("post-type.instagram")
    case SOCIAL_POST_TYPE_FACEBOOK:
      return i18n.t("post-type.facebook")
    default:
      return ""
  }
}

export const getInstagramAccountLink = username => {
  if (username) {
    return `https://www.instagram.com/${username}`
  } else {
    return `https://www.instagram.com/`
  }
}

export const getFacebookPageLink = pageId => {
  if (pageId) {
    return `https://www.facebook.com/${pageId}`
  } else {
    return `https://www.facebook.com/`
  }
}

export const getFacebookPagePostLink = (pageId, postId) => {
  if (pageId && postId) {
    return `https://www.facebook.com/${pageId}_${postId}`
  } else {
    return `https://www.facebook.com/`
  }
}

export const isInstagramPost = type => type === SOCIAL_POST_TYPE_INSTAGRAM

export const isFacebookPost = type => type === SOCIAL_POST_TYPE_FACEBOOK

export const getPublicationStatusConfigs = () => {
  return [
    {
      label: i18n.t("posted"),
      publicationStatus: SOCIAL_POST_PUBLICATION_SUCCESS,
      theme: "success",
    },
    {
      label: i18n.t("pending"),
      publicationStatus: SOCIAL_POST_PUBLICATION_PENDING,
      theme: "warning",
    },
    {
      label: i18n.t("error"),
      publicationStatus: SOCIAL_POST_PUBLICATION_ERROR,
      theme: "danger",
    },
    {
      label: i18n.t("unknown"),
      publicationStatus: SOCIAL_POST_PUBLICATION_UNKNOWN,
      theme: "warning",
    },
  ]
}

export const getPublicationStatusConfig = publicationStatus => {
  const options = getPublicationStatusConfigs()
  return _.find(options, { publicationStatus })
}
