import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { useHistory } from "react-router-dom"

import { BackButton } from "../Buttons"

export const PageHeader = ({
  title,
  headerLeft,
  headerRight,
  back,
  onBack,
  backTitle,
}) => {
  const history = useHistory()

  const renderTitle = () => <h4 className="mb-0 font-size-18">{title}</h4>

  const handleBack = () => {
    if (onBack) {
      onBack()
    } else {
      history.goBack()
    }
  }

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div className={"page-header__left"}>
            <div className={"d-flex"}>
              {back && <BackButton onBack={handleBack} title={backTitle} />}

              {title && renderTitle()}
            </div>

            {!!headerLeft && headerLeft}
          </div>

          {!!headerRight && (
            <div className={"page-header__right"}>{headerRight}</div>
          )}
        </div>
      </Col>
    </Row>
  )
}

PageHeader.propTypes = {
  title: PropTypes.any,
  headerLeft: PropTypes.any,
  headerRight: PropTypes.any,
  back: PropTypes.bool,
  onBack: PropTypes.func,
  backTitle: PropTypes.any,
}
