import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Drawer } from "../../../../../../components"
import { Container, Row } from "reactstrap"
import EditLogoForm from "./EditLogoForm"
import { ValidationsUtils } from "../../../../../../utils"
import { useTranslation } from "react-i18next"
import {
  clientAddLogo,
  clientRemoveLogo,
} from "../../../../../../store/actions"

const EditLogoContainerComponent = props => {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const handleCloseDrawer = () => {
    props.onClose()
  }
  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    props.onClose()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    if (params.avatar) {
      dispatch(
        clientAddLogo.add({
          id: props.client.id,
          params,
          onSuccess: onSubmitSuccess(onSubmitProps),
          onError: onSubmitError(onSubmitProps),
        })
      )
    } else {
      dispatch(
        clientRemoveLogo.remove({
          id: props.client.id,
          onSuccess: onSubmitSuccess(onSubmitProps),
          onError: onSubmitError(onSubmitProps),
        })
      )
    }
  }

  const onDestroy = () => {
    dispatch(clientAddLogo.cleanState())
    dispatch(clientRemoveLogo.cleanState())
  }
  return (
    <>
      <Drawer
        title={t("client.general.edit-logo")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <Container fluid>
          <Row>
            <EditLogoForm
              error={props.error}
              loading={props.loading}
              initialValues={{
                avatar: props.client.avatar,
              }}
              onCancel={handleCloseDrawer}
              onSubmit={handleSubmit}
            />
          </Row>
        </Container>
      </Drawer>
    </>
  )
}

EditLogoContainerComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  client: PropTypes.object,

  loading: PropTypes.bool,
  error: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.client.profile.data
  const { loading, error } = state.client.logo.add
  return {
    loading,
    error,
    client: data,
  }
}

export const EditLogo = connect(mapStateToProps)(EditLogoContainerComponent)
