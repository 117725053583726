import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { StatementPaymentStatusBadge } from "components"
import { commonHelper } from "helpers"
import { DateUtils } from "utils"

const currencySymbol = commonHelper.currency.getGeneralCurrency()?.symbol

export const ClientStatement = ({ data }) => {
  const { t } = useTranslation()

  const { description, statement, client_name } = data

  const items = [
    {
      label: t("client"),
      value: client_name,
    },
    {
      label: t("date"),
      value: statement.date ? moment(statement.date).format("DD/MM/YYYY") : "-",
    },
    {
      label: t("description"),
      value: statement.description || "-",
    },
    {
      label: t("amount"),
      value: `${statement.value} ${currencySymbol}`,
    },
    {
      label: t("payment_deadline"),
      value: statement.deadline
        ? DateUtils.utcToLocale(statement.deadline, "DD/MM/YYYY HH:mm")
        : "-",
    },
    {
      label: t("status"),
      value: <StatementPaymentStatusBadge value={statement.status} />,
    },
  ]

  return (
    <div className={"client-statement"}>
      <h4>{t("client_statement")}</h4>

      {description && <p>{description}</p>}

      <div className={"client-statement__info-block"}>
        <div className="table-responsive">
          <table className="table">
            <tbody>
              {items.map(({ label, value, ...item }, index) => (
                <tr key={index}>
                  <td>
                    <span className={"fw-medium text-nowrap"}>{label}</span>
                  </td>

                  <td>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

ClientStatement.propTypes = {
  data: PropTypes.object,
}
