import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { IbanElement } from "@stripe/react-stripe-js"

import { StripeField } from "components"

export const StripeIbanField = props => {
  const { t } = useTranslation()

  const ELEMENT_OPTIONS = {
    supportedCountries: ["SEPA"],
    placeholderCountry: "IT",
    style: {
      ...props.elementStyle,
    },
  }

  return (
    <>
      <StripeField
        label={t("iban")}
        state={props.state?.["iban"]}
        options={ELEMENT_OPTIONS}
        onChange={props.onChange}
        component={IbanElement}
      />
    </>
  )
}

StripeIbanField.propTypes = {
  state: PropTypes.object,
  elementStyle: PropTypes.object,
  onChange: PropTypes.func,
}
