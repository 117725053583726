import React from "react"
import PropTypes from "prop-types"
import placeholderIcon from "../../../../../../assets/images/dev/icon-photo-placeholder.svg"
import {
  generatePath,
  Link,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom"
import classNames from "classnames"
import { Image } from "components"

export const ClientItem = props => {
  const { url } = useRouteMatch()
  const { path } = useRouteMatch()
  const location = useLocation()
  const { id } = useParams()

  const handleItemClick = () => {
    props.onSelect()
  }
  const getPath = () => {
    const route = location.pathname.split(url)[1]
    return generatePath(`${path}${route}`, { id: props.item.id })
  }

  const nameStyles = classNames("client-item__name", {
    "client-item__name_active": props.item.id.toString() === id,
  })

  return (
    <Link to={getPath()} onClick={handleItemClick}>
      <div className={"client-item"}>
        <div className={"image-rounded"}>
          <Image
            src={props.item.avatar?.src || placeholderIcon}
            crops={props.item?.crops}
            alt={props.item.name}
          />

          <div
            className={classNames({
              "image-rounded__outline": props.item.id.toString() === id,
            })}
          />
        </div>
        <div className={nameStyles}>{props.item.name}</div>
      </div>
    </Link>
  )
}

ClientItem.propTypes = {
  item: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
}
