import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { IconButton, RateInfoCell, StatementPaidStatusBadge } from "components"

export const RateRow = ({ item, index, ...props }) => {
  const { t } = useTranslation()

  return (
    <tr className="table-border-bottom">
      <td>
        <span className={"fw-bold"}>{t("rate")}</span>
      </td>
      <td>
        <RateInfoCell data={item} />
      </td>

      {item.has_paid_statement ? (
        <td className={"text-end text-nowrap"}>
          <StatementPaidStatusBadge value={true} />
        </td>
      ) : (
        <td
          className={"table-column-sticky_end bg-white text-nowrap"}
          style={{ width: "76px" }}
        >
          <IconButton
            name={"edit"}
            bg
            onClick={() => props.onEdit(item, index)}
          />

          <IconButton
            name={"remove"}
            bg
            onClick={() => props.onRemove(item, index)}
            wrapClass={"ms-2"}
          />
        </td>
      )}
    </tr>
  )
}

RateRow.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  currency: PropTypes.string,
}
