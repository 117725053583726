import React from "react"
import PropTypes from "prop-types"
import { Col, Alert, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { Button, ControllerHF, FilePickerField } from "components"
import { FileUtils, ValidationsUtils } from "utils"
import { FILE_MAX_SIZE } from "consts"
import { Instruction } from "./Instruction"

export const Form = props => {
  const { t } = useTranslation()

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      file: null,
    },
    resolver: yupResolver(
      Yup.object().shape({
        file: Yup.mixed()
          .test("maxSize", "field.error.file-size.max", value =>
            ValidationsUtils.fileMaxSize(value?.file, FILE_MAX_SIZE)
          )
          .required("field.error.required"),
      })
    ),
    mode: "onChange",
  })

  const onSubmit = values => {
    const { file } = values
    let params = {
      file: file.file,
    }

    props.onSubmit(params, { reset, setError })
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Instruction type={props.type} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ControllerHF
              name={"file"}
              control={control}
              component={FilePickerField}
              fileNamePlaceholderText={t("attached-file")}
              id={"file"}
              pickerLabelText={t("attach_file.type", { type: "CSV" })}
              accept={"text/csv"}
              translateParams={{
                text: `${FileUtils.formatBytes(FILE_MAX_SIZE)}`,
              }}
            />
          </Col>
        </Row>

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"import"}
              disabled={props.loading || props.importActive}
              loading={props.loading}
            />
          </div>
        </div>
      </form>
    </>
  )
}

Form.propTypes = {
  type: PropTypes.string,
  importActive: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.object,
  onSubmit: PropTypes.func,
}
