import React, { useEffect, useRef, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import {
  useDraftPackageDataPicker,
  usePanelTopBarOptions,
} from "../../../../../hooks"
import { AddVideo } from "./AddVideo"
import { EditVideo } from "./EditVideo"
import { DeleteVideos } from "./DeleteVideos"
import { VideosGrid } from "./VideosGrid"
import {
  modelFetchVideos,
  modelFetchVideosCleanState,
  modelVideosSetPage,
  modelVideosRemoveVideo,
  modelVideosRemoveVideoCleanState,
} from "../../../../../store/actions"
import { selectPackageDataCount } from "../../../../../store/package/draft/data/dataPicker/selectors"
import { ConfirmPopup, DraftPackageContainer } from "../../../../../components"
import { useSelectionRegular } from "../../../../../hooks"

const VideosContainerComponent = props => {
  const { t } = useTranslation()

  const {
    selectionValue,
    selectionClear,
    selectionAddSelectedItems,
    selectionRemoveSelected,
    selectionToggleSelect,
  } = useSelectionRegular({})

  const openPackage = e => {
    e.preventDefault()
    setDraftPackageVisible(true)
  }

  const handleAddSuccess = () => {
    fetchData()
  }

  const handleDeleteSuccess = () => {
    selectionClear()
    fetchData()
  }

  const handleSelectAll = e => {
    e.preventDefault()

    const itemsOnPage = props.list.map(i => i.id)
    selectionAddSelectedItems(itemsOnPage)
  }

  const panelOptions = useMemo(
    () => ({
      actions: (
        <div className={""}>
          <div className="d-inline">
            <Link to={"#"} className="text-secondary" onClick={openPackage}>
              {t("btn.new-package")}
              {props.packageDataCount > 0
                ? ` (${props.packageDataCount})`
                : null}
            </Link>
          </div>

          <div className="ms-3 d-inline">
            <AddVideo onAddSuccess={handleAddSuccess} />
          </div>

          {!!props.list.length && (
            <div className="ms-3 d-inline">
              <Link to="#" className="text-secondary" onClick={handleSelectAll}>
                {t("btn.select-all")}
              </Link>
            </div>
          )}

          {!!selectionValue.length && (
            <div className="ms-3 d-inline">
              <DeleteVideos
                selected={selectionValue}
                onDeleteSuccess={handleDeleteSuccess}
              />
            </div>
          )}
        </div>
      ),
    }),
    [props.page, props.list, selectionValue, props.packageDataCount]
  )

  usePanelTopBarOptions(panelOptions)

  const removeModalRef = useRef(null)
  const [videoRemove, setVideoRemove] = useState(null)
  const [videoEdit, setVideoEdit] = useState(null)
  const [editDrawerVisible, setEditDrawerVisible] = useState(null)
  const [draftPackageVisible, setDraftPackageVisible] = useState(false)

  const playersRef = useRef([])

  const { onVideoRemove, isVideoPicked } = useDraftPackageDataPicker()

  useEffect(() => {
    return () => {
      props.modelFetchVideosCleanState()
      props.modelVideosRemoveVideoCleanState()
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [props.page])

  const fetchData = () => {
    let params = {
      page: props.page,
      per_page: 12,
    }

    props.modelFetchVideos(props.model.id, params)
  }

  const handlePageChange = item => {
    props.modelVideosSetPage(item.selected + 1)
  }

  const removeVideo = item => {
    setVideoRemove(item)

    if (removeModalRef.current) {
      removeModalRef.current.show()
    }
  }

  const resetVideoRemove = () => {
    setVideoRemove(null)
  }

  const onRemoveSuccess = id => () => {
    fetchData()

    if (isVideoPicked(id)) {
      onVideoRemove({ id })
    }
  }

  const confirmRemove = () => {
    const { id } = videoRemove

    if (selectionValue.includes(id)) {
      selectionRemoveSelected(id)
    }

    props.modelVideosRemoveVideo(id, onRemoveSuccess(id))
  }

  const editVideo = item => {
    setVideoEdit(item)
    pausePlayers()
    editDrawerShow()
  }

  const handleEditSuccess = () => {
    fetchData()
  }

  const resetVideoEdit = () => {
    setVideoEdit(null)
  }

  const editDrawerShow = () => {
    setEditDrawerVisible(true)
  }

  const editDrawerClose = () => {
    setEditDrawerVisible(false)
  }

  const editDrawerDestroy = () => {
    resetVideoEdit()
  }

  const pausePlayers = () => {
    if (playersRef.current) {
      playersRef.current.forEach(player => {
        if (player && player?.pause) {
          player.pause()
        }
      })
    }
  }

  const onSelectVideo = video => {
    const { id } = video
    selectionToggleSelect(id)
  }

  return (
    <>
      <VideosGrid
        items={props.list}
        loading={props.listLoading || props.removeVideosLoading}
        error={props.listError}
        meta={props.meta}
        page={props.page}
        removingItems={props.removingItems}
        onPageChange={handlePageChange}
        onVideoRemove={removeVideo}
        onVideoEdit={editVideo}
        playersRef={playersRef}
        selectedVideos={selectionValue}
        onSelectVideo={onSelectVideo}
      />

      <EditVideo
        visible={editDrawerVisible}
        onEditSuccess={handleEditSuccess}
        id={videoEdit?.id}
        onClose={editDrawerClose}
        onDestroy={editDrawerDestroy}
      />

      <ConfirmPopup
        ref={removeModalRef}
        onConfirm={confirmRemove}
        onClosed={resetVideoRemove}
      >
        <p className={"font-size-22"}>{t("sure_delete_video")}?</p>
      </ConfirmPopup>

      <DraftPackageContainer
        initialTab={"videos"}
        draftPackageVisible={draftPackageVisible}
        draftPackageClose={() => setDraftPackageVisible(false)}
      />
    </>
  )
}

VideosContainerComponent.propTypes = {
  model: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
  removingItems: PropTypes.array,
  removeVideosLoading: PropTypes.bool,
  packageDataCount: PropTypes.number,

  modelFetchVideos: PropTypes.func,
  modelFetchVideosCleanState: PropTypes.func,
  modelVideosSetPage: PropTypes.func,
  modelVideosRemoveVideo: PropTypes.func,
  modelVideosRemoveVideoCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.model.profile
  const { list, listLoading, listError, meta, page } =
    state.modelPanel.video.list.data
  const { pending } = state.modelPanel.video.list.removeItem
  const removeVideosLoading = state.modelPanel.video.list.removeVideos.loading

  return {
    model: data,
    list,
    listLoading,
    listError,
    meta,
    page,
    removingItems: pending,
    removeVideosLoading,
    packageDataCount: selectPackageDataCount(state),
  }
}

const mapDispatchToProps = {
  modelFetchVideos,
  modelFetchVideosCleanState,
  modelVideosSetPage,
  modelVideosRemoveVideo,
  modelVideosRemoveVideoCleanState,
}

export const VideosContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideosContainerComponent)
