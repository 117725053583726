import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Modal } from "../../../../../../../components"
import { ValidationsUtils } from "../../../../../../../utils"
import { Container, Row } from "reactstrap"
import { websiteUpdateMenuItem } from "../../../../../../../store/actions"
import { CustomMenuItemForm } from "../CustomMenuItemForm"

const UpdateMenuItemComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const modalRef = useRef(null)
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    return () => {
      if (modalVisible) {
        dispatch(websiteUpdateMenuItem.cleanState())
      }
    }
  }, [modalVisible])

  useEffect(() => {
    if (modalRef.current && props.menuItem) {
      modalRef.current.show()
    }
  }, [props.menuItem])

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.hide()
    }
    props.onClose()
  }

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    closeModal()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      websiteUpdateMenuItem.update({
        id: props.menuItem?.id,
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  return (
    <React.Fragment>
      <Modal
        ref={modalRef}
        title={t("website.update-menu-item.title")}
        onVisibleChange={value => setModalVisible(value)}
        strapModalProp={{
          centered: true,
        }}
      >
        <Container fluid>
          <Row>
            <CustomMenuItemForm
              loading={props.loading}
              error={props.error}
              initialValues={{
                title: props.menuItem?.title,
                custom_url: props.menuItem?.custom_url,
              }}
              onSubmit={handleSubmit}
              onCancel={closeModal}
            />
          </Row>
        </Container>
      </Modal>
    </React.Fragment>
  )
}

UpdateMenuItemComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  onClose: PropTypes.func,
  menuItem: PropTypes.object,
}

const mapStateToProps = state => {
  const { loading, error } = state.website.menuItems.update
  return {
    loading,
    error,
  }
}

export const UpdateMenuItem = connect(mapStateToProps)(UpdateMenuItemComponent)
