import { all, fork, put, takeEvery } from "redux-saga/effects"

import { socketOnNotificationReceive } from "./actions"
import { modalNotificationsList } from "store"
import { MODAL_NOTIFICATION_ALIAS } from "consts"

function* onNotificationReceive({ payload: { data } }) {
  const { event_alert } = data

  //in future use switch depending on notification alias
  yield put(
    modalNotificationsList.addNotification({
      data: event_alert,
      alias: MODAL_NOTIFICATION_ALIAS.CALENDAR_EVENT,
    })
  )
}

export function* watchOnNotificationReceive() {
  yield takeEvery(socketOnNotificationReceive, onNotificationReceive)
}

function* notificationsSaga() {
  yield all([fork(watchOnNotificationReceive)])
}

export default notificationsSaga
