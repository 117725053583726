import { createSlice } from "@reduxjs/toolkit"


const initialState = {
  loading: false,
  error: null
}

const agencyAddRatingSlice = createSlice({
  name: "agencyAddRating",
  initialState,
  reducers: {
    add(state) {
      state.loading = true
      state.error = null
    },
    addCompleted(state) {
      state.loading = false
    },
    addRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    }
  }
})

export const agencyAddRating = agencyAddRatingSlice.actions

export default agencyAddRatingSlice.reducer