import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const sharedEntitySendSlice = createSlice({
  name: "sharedEntitySend",
  initialState,
  reducers: {
    send(state) {
      state.loading = true
      state.error = null
    },
    sendSuccess(state) {
      state.loading = false
    },
    sendError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})

export const sharedEntitySend = sharedEntitySendSlice.actions

export default sharedEntitySendSlice.reducer
