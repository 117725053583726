import React from "react"
import PropTypes from "prop-types"
import s from "./ThankMessage.module.scss"
import classnames from "classnames"
import { useTranslation } from "react-i18next"

export const ThankMessage = () => {
  const { t } = useTranslation()
  return (
    <div className={s.wrapper}>
      <i className={classnames("bx bx-check-circle text-success", [s.icon])} />
      <h4 className={s.message}>{t("send-idea.thank-message")}</h4>
    </div>
  )
}

ThankMessage.propTypes = {}
