import React, { useState } from "react"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import { FormProvider, useForm } from "react-hook-form"
import PropTypes from "prop-types"
import { useDeepCompareEffect } from "react-use"
import { yupResolver } from "@hookform/resolvers/yup"

import { Button, ControllerHF, EditorField, InputField } from "components"
import { EditorUtils } from "utils"
import { EDITOR_DRAFT_DOCX_TOOLBAR_OPTIONS } from "consts"
import {
  ContractDocumentTextEntryControlPickerFormSection,
  TABS_KEY,
} from "components/FormSections/ContractDocumentTextEntryControlPickerFormSection"
import { validationSchema } from "./validation"

export const CompanyForm = props => {
  const { t } = useTranslation()

  const [contractTextMode, setContractTextMode] = useState(TABS_KEY.TEXT)
  const [modelContractTextMode, setModelContractTextMode] = useState(
    TABS_KEY.TEXT
  )

  const methods = useForm({
    defaultValues: {
      invoice_information: "",
      contract_text: EditorUtils.draftJs.getEditorState(""),
      contract_file: null,
      contract_model_text: EditorUtils.draftJs.getEditorState(""),
      contract_model_file: null,
      contract_footer_text: "",
    },
    resolver: yupResolver(validationSchema),
    // mode: "onChange",
  })

  const { control, handleSubmit, setError, reset } = methods

  const onReset = () => {
    const {
      contract_text,
      contract_file,
      contract_model_text,
      contract_model_file,
      ...rest
    } = props.initialValues

    const values = {
      ...rest,
      contract_text: EditorUtils.draftJs.getEditorState(
        contract_text ? contract_text : ""
      ),
      contract_file: contract_file ? { attachment: contract_file } : null,
      contract_model_text: EditorUtils.draftJs.getEditorState(
        contract_model_text ? contract_model_text : ""
      ),
      contract_model_file: contract_model_file
        ? { attachment: contract_model_file }
        : null,
    }

    reset(values)
  }

  // set value after fetch data
  useDeepCompareEffect(() => {
    onReset()
  }, [props.initialValues])

  const onSubmit = values => {
    const {
      contract_text,
      contract_file,
      contract_model_text,
      contract_model_file,
      ...restValues
    } = values

    let params = {
      ...restValues,
    }

    if (contract_text && !EditorUtils.draftJs.isContentEmpty(contract_text)) {
      params.contract_text =
        EditorUtils.draftJs.editorStateToHtml(contract_text)
    } else {
      params.contract_text = ""
    }

    if (
      contract_model_text &&
      !EditorUtils.draftJs.isContentEmpty(contract_model_text)
    ) {
      params.contract_model_text =
        EditorUtils.draftJs.editorStateToHtml(contract_model_text)
    } else {
      params.contract_model_text = ""
    }

    const appendFile = (file, name) => {
      if (file) {
        if (file instanceof File) {
          params[name] = file
        }
      } else {
        params[name] = ""
      }
    }

    appendFile(contract_file, "contract_file")

    appendFile(contract_model_file, "contract_model_file")

    props.onSubmit(params, { setError })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <ControllerHF
              name={`invoice_information`}
              control={control}
              component={InputField}
              id={`invoice_information`}
              label={t("contact_information")}
              placeholder={t("contact_information")}
              disabled={props.isDisabled}
              type={"textarea"}
            />
          </Col>
        </Row>

        {/*<hr className={"divider my-4"} />*/}

        {/*<div>*/}
        {/*  <h4 className={"mb-3"}>{t("client_contract")}</h4>*/}

        {/*  <ContractDocumentTextEntryControlPickerFormSection*/}
        {/*    value={contractTextMode}*/}
        {/*    onChange={setContractTextMode}*/}
        {/*    editorProps={{*/}
        {/*      disabled: props.isDisabled,*/}
        {/*    }}*/}
        {/*    fileProps={{*/}
        {/*      name: "contract_file",*/}
        {/*      id: "contract_file",*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</div>*/}

        {/*<hr className={"divider my-4"} />*/}

        <Row>
          <Col md={12}>
            <ControllerHF
              name={"contract_text"}
              control={control}
              component={EditorField}
              id={"contract_text"}
              label={t("client_contract_text")}
              placeholder={t("client_contract_text")}
              toolbar={{
                options: EDITOR_DRAFT_DOCX_TOOLBAR_OPTIONS,
              }}
              disabled={props.isDisabled}
              editorClassName={"wysiwyg-editor-editor_document-text"}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <ControllerHF
              name={"contract_model_text"}
              control={control}
              component={EditorField}
              id={"contract_model_text"}
              label={t("model_contract_text")}
              placeholder={t("model_contract_text")}
              toolbar={{
                options: EDITOR_DRAFT_DOCX_TOOLBAR_OPTIONS,
              }}
              disabled={props.isDisabled}
              editorClassName={"wysiwyg-editor-editor_document-text"}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <ControllerHF
              name={"contract_footer_text"}
              control={control}
              component={InputField}
              id={"contract_footer_text"}
              label={t("contract_footer_text")}
              placeholder={t("contract_footer_text")}
              type={"textarea"}
              disabled={props.isDisabled}
            />
          </Col>
        </Row>

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.isDisabled}
              loading={props.loading}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

CompanyForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onSubmit: PropTypes.func,
}
