import {
    PACKAGE_ADD,
    PACKAGE_ADD_SUCCESS,
    PACKAGE_ADD_ERROR,
    PACKAGE_ADD_CLEAN_STATE,
} from './actionTypes';

export const packageAdd = (params, onSuccess, onError) => {
    return {
        type: PACKAGE_ADD,
        payload: { params, onSuccess, onError },
    }
};

export const packageAddSuccess = () => {
    return {
        type: PACKAGE_ADD_SUCCESS,
    }
};

export const packageAddError = (data) => {
    return {
        type: PACKAGE_ADD_ERROR,
        payload: data,
    }
};

export const packageAddCleanState = () => {
    return { type: PACKAGE_ADD_CLEAN_STATE };
};