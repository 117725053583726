import React, { useState } from "react"
import PropTypes from "prop-types"
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { eventsCalendarHelper } from "helpers"
import { useSelector } from "react-redux"
import { selectEventColorByEventType } from "store"
import { CALENDAR_EVENT_CATEGORY_OTHER } from "consts"

const events = eventsCalendarHelper.eventConfig.getOtherCategoryEvents()

export const OtherEvents = ({ onSelect }) => {
  const { t } = useTranslation()
  const bgColor = useSelector(state =>
    selectEventColorByEventType(state, CALENDAR_EVENT_CATEGORY_OTHER)
  )

  const [menu, setMenu] = useState(false)

  const handleSelect = (event, { type, title }) => {
    event.preventDefault()

    onSelect({ type, title })
    setMenu(false)
  }

  return (
    <div>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          tag="button"
          className={classNames(
            "btn calendar-event-category other-events-dropdown"
          )}
          style={{
            backgroundColor: bgColor,
          }}
        >
          <div>{t("other_events")}</div>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          {events.map(item => (
            <Link
              key={item.type}
              to="#"
              className="dropdown-item"
              onClick={e => handleSelect(e, item)}
            >
              <span>{item.title}</span>
            </Link>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

OtherEvents.propTypes = {
  onSelect: PropTypes.func,
}
