import { combineReducers } from "redux"

import data from "./data/slice"
import submit from "./submit/slice"

const editReducer = combineReducers({
  data,
  submit,
})

export default editReducer
