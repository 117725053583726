import { combineReducers } from "redux"

import avatar from "./avatar/reducer"
import companyLogo from "./companyLogo/reducer"
import generalInfo from "./generalInfo/reducer"
import password from "./password/reducer"
import remove from "./remove/reducer"
import restore from "./restore/slice"
import company from "./company/reducers"

const profileReducer = combineReducers({
  avatar,
  companyLogo,
  generalInfo,
  password,
  remove,
  restore,
  company,
})

export default profileReducer
