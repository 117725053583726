import React from "react"
import PropTypes from "prop-types"

export const CalendarDayHeaderContent = ({ title, rightSideBar }) => {
  return (
    <div className={"calendar-day-header-content"}>
      <div className={"calendar-day-header-content__main"}>{title}</div>
      {rightSideBar && (
        <div className={"calendar-day-header-content__right-block"}>
          {rightSideBar}
        </div>
      )}
    </div>
  )
}

CalendarDayHeaderContent.propTypes = {
  title: PropTypes.any,
  rightSideBar: PropTypes.any,
}
