import { combineReducers } from "redux"

// import list from "./list/slice"
import data from "./data/slice"
import submit from "./submit/slice"

const importReducer = combineReducers({
  data,
  submit,
})

export default importReducer
