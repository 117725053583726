import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import { FieldError } from "components"
import { MembersControl } from "../MembersControl"

export const MembersFormField = ({
  id,
  value = [],
  meta = {},
  translateParams,
  className,
  ...props
}) => {
  const { error } = meta
  const showError = Boolean(error)
  const { t } = useTranslation()

  return (
    <div className={classNames("", { [className]: className })}>
      <div
        className={classNames("", {
          "is-invalid": showError,
        })}
      >
        <MembersControl {...props} value={value} onChange={props.onChange} />
      </div>

      <FieldError
        error={error}
        translateParams={translateParams}
        showError={showError}
      />
    </div>
  )
}

MembersFormField.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  translateParams: PropTypes.object,
  value: PropTypes.any,
  meta: PropTypes.object,
  className: PropTypes.string,
}
