import React, { useEffect, useRef, forwardRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

export const TableIndeterminateCheckbox = forwardRef(
  ({ indeterminate, size = "lg", color = "primary", ...rest }, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <div
          className={classNames("form-check", {
            [`form-check-${color}`]: color,
            [`form-check_size_${size}`]: size,
          })}
        >
          <div className={classNames("form-check-wrap", {})}>
            <input
              type="checkbox"
              className={classNames("form-check-input", {})}
              ref={resolvedRef}
              {...rest}
            />
          </div>
        </div>
      </>
    )
  }
)

TableIndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string,
}
