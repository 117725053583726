import { all } from "redux-saga/effects"

import loadSaga from "./load/saga"
import settingsSaga from "./settings/sagas"
import optionsSaga from "./options/sagas"
import departmentSaga from "./department/sagas"

function* userSaga() {
  yield all([loadSaga(), settingsSaga(), optionsSaga(), departmentSaga()])
}

export default userSaga
