import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation, Trans } from "react-i18next"
import { Collapse } from "reactstrap"
import classNames from "classnames"

import { Input } from "../Input"
import { Button } from "components"

export const FileContent = ({ data }) => {
  const { t } = useTranslation()

  const { stream, download } = data ?? {}

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <>
      <a href={download} target="_blank" rel="noreferrer" download>
        {download}
      </a>

      <div className={`mt-4`}>
        <Trans i18nKey={"sync_to_calendar.file-instruction"} />
      </div>

      <div className={`mt-4`}>
        <Button
          onClick={toggle}
          icon={`mdi ${isOpen ? "mdi-chevron-up" : "mdi-chevron-down"}`}
          renderTitle={t("calendar_url")}
        />
      </div>

      <Collapse isOpen={isOpen}>
        <div className={"pt-4"}>
          <Input
            tooltipTarget={"file-iCal-urls-copy-btn"}
            value={stream}
            download={download}
          />
        </div>
      </Collapse>
    </>
  )
}

FileContent.propTypes = {
  data: PropTypes.object,
}
