export const PAGE_SHOW_LIST = "pages_show_list"
export const INSTAGRAM_BASIC = "instagram_basic"
export const INSTAGRAM_CONTENT_PUBLISH = "instagram_content_publish"
export const PAGES_READ_ENGAGEMENT = "pages_read_engagement"
export const PAGES_MANAGE_POSTS = "pages_manage_posts"
export const PUBLIC_PROFILE = "public_profile"
export const BUSINESS_MANAGEMENT = "business_management"

export const REQUIRED_PERMISSIONS = [
  PAGE_SHOW_LIST,
  INSTAGRAM_CONTENT_PUBLISH,
  INSTAGRAM_BASIC,
  PAGES_READ_ENGAGEMENT,
  PAGES_MANAGE_POSTS,
  PUBLIC_PROFILE,
  BUSINESS_MANAGEMENT
]
