import { all } from "redux-saga/effects"

import generalSaga from "./general/sagas"
import avatarSaga from "./avatar/sagas"
import menuItemsSaga from "./menuItems/sagas"
import modelsSaga from "./models/sagas"
import templatesSaga from "./templates/sagas"
import requestsSaga from "./requests/sagas"

function* websiteSaga() {
  yield all([
    generalSaga(),
    avatarSaga(),
    menuItemsSaga(),
    modelsSaga(),
    templatesSaga(),
    requestsSaga(),
  ])
}

export default websiteSaga
