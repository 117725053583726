import { createSelector, createSlice } from "@reduxjs/toolkit"
import { extendBackendPostData } from "helpers/socialPostsCalendar/calendar"

const initialState = {
  data: null,
  loading: false,
  error: null,
}

const socialCalendarPostItemSlice = createSlice({
  name: "socialCalendarPostItem",
  initialState,
  reducers: {
    getItem(state) {
      state.loading = true
      state.error = null
    },
    getItemCompleted(state, action) {
      state.loading = false
      state.data = action.payload.data
    },
    getItemRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    updateData(state, action) {
      state.data = { ...state.data, ...action.payload }
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getItem
 *  @property {function} getItemCompleted
 *  @property {function} getItemRejected
 *  @property {function} updateData
 *  @property {function} cleanState
 */
export const socialCalendarPostItem = socialCalendarPostItemSlice.actions

export const selectSocialCalendarPostItemData = state =>
  state.socialCalendar.post.item.data

export const selectSocialCalendarPostItemSlice = createSelector(
  [state => state.user.profile.data, state => state.socialCalendar.post.item],
  (user, postItemSlice) => {
    return {
      loading: postItemSlice.loading,
      error: postItemSlice.error,
      data: postItemSlice.data
        ? extendBackendPostData(postItemSlice.data, user.role)
        : null,
      isError: !!postItemSlice.error,
      isFetched: !!postItemSlice.data,
    }
  }
)

export default socialCalendarPostItemSlice.reducer
