export const EDITOR_DRAFT_COMMON_TOOLBAR_OPTIONS = [
  "inline",
  "blockType",
  "fontSize",
  // "fontFamily",
  "list",
  "textAlign",
  "colorPicker",
  "link",
  "embedded",
  // "emoji",
  "image",
  // "remove",
  "history",
]

export const EDITOR_DRAFT_DOCX_TOOLBAR_OPTIONS = [
  "inline",
  "fontSize",
  "list",
  "textAlign",
  "link",
  "history",
]
