import {
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    REGISTER_USER_CLEAN_STATE,
} from './actionTypes';

const initialState = {
    registerError: null,
    loading: false,
    success: false,
};

const register = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER_USER:
            state = {
                ...state,
                loading: true,
                registerError: null,
                success: false,
            };
            break;

        case REGISTER_USER_SUCCESS:
            state = {
                ...state,
                loading: false,
                registerError: null,
                success: true,
            };
            break;

        case REGISTER_USER_ERROR:
            state = {
                ...state,
                loading: false,
                registerError: action.payload,
                success: false,
            };
            break;

        case REGISTER_USER_CLEAN_STATE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default register;