import React, { useEffect } from "react"
import PropTypes from "prop-types"

import { SepaForm } from "./SepaForm"
import { useFetchData } from "hooks"
import { billingHelper } from "helpers"
import { API } from "api"
import * as HOC from "HOC"

const DataShower = HOC.withDataShower()

export const SepaContainer = props => {
  const { fetchData, loading, data } = useFetchData()

  const onFetch = async () => {
    const request = API.billing.getBillingInfo()
    await fetchData({
      request,
    })
  }

  useEffect(() => {
    onFetch()
  }, [])

  return (
    <>
      <DataShower isLoading={loading}>
        {data?.data && (
          <SepaForm
            initialValues={{
              name: data?.data?.first_name,
              email: data?.data?.email,
              address: billingHelper.info.mapBillingAddress(data?.data),
            }}
            {...props}
          />
        )}
      </DataShower>
    </>
  )
}

SepaContainer.propTypes = {}
