import { takeLatest, fork, put, all, call } from "redux-saga/effects"
import i18n from "../../../../i18n"
import { websitePagesList } from "./slice"
import { API } from "../../../../api"
import toastr from "toastr"

export function* getList() {
  try {
    const response = yield call(API.website.getWebsitePages)

    const { data } = response

    yield put(websitePagesList.getListCompleted(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(websitePagesList.getListRejected(message))
    toastr.error(message)
  }
}

export function* watchGetList() {
  yield takeLatest(websitePagesList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
