import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import {
  ExpensesSection,
  FromToSection,
  ModelAgencyNotesSection,
  ServiceDetailsSection,
} from "../FormsSections"

export const DriverForm = props => {
  const { t } = useTranslation()

  useEffect(() => {
    props.reset()
  }, [])

  return (
    <>
      <ServiceDetailsSection control={props.control} />

      <hr className="drawer-separator drawer-separator_form" />

      <h4 className={"pt-3 mb-3"}>{t("work_days")}</h4>

      <FromToSection control={props.control} watch={props.watch} />

      <hr className="drawer-separator drawer-separator_form" />

      <ModelAgencyNotesSection control={props.control} />

      <hr className="drawer-separator drawer-separator_form" />

      <ExpensesSection control={props.control} />
    </>
  )
}

DriverForm.propTypes = {
  control: PropTypes.object,
  reset: PropTypes.func,
  watch: PropTypes.any,
}
