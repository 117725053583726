import { all } from 'redux-saga/effects';

import listSaga from './list/sagas';

function* modelsSaga() {
    yield all([
        listSaga(),
    ])
}

export default modelsSaga;