import { combineReducers } from "redux"

import add from "./add/reducer"
import list from "./list/reducers"
import details from "./details/reducers"
import expenses from "./expenses/reducers"

const logisticsReducer = combineReducers({
  add,
  list,
  details,
  expenses,
})

export default logisticsReducer
