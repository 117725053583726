import React, { useState } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';

import { FeaturesInfoCard } from './FeaturesInfoCard';
import { EditFeatures } from './EditFeatures';
import { FeaturesView } from './FeaturesView';

const FeaturesContainerComponent = (props) => {
    const [editDrawerVisible, setEditDrawerVisible] = useState(false);
    const [moreDrawerVisible, setMoreDrawerVisible] = useState(false);

    const handleEdit = () => {
        setEditDrawerVisible(true)
    };

    const closeEditDrawer = () => {
        setEditDrawerVisible(false);
    };

    const handleMore = () => {
        setMoreDrawerVisible(true)
    };

    const closeMoreDrawer = () => {
        setMoreDrawerVisible(false);
    };

    return (
        <>
            <FeaturesInfoCard
                onEdit={handleEdit}
                onMore={handleMore}
                data={props.model?.profile}
            />

            <EditFeatures visible={editDrawerVisible} onClose={closeEditDrawer} />

            <FeaturesView visible={moreDrawerVisible} onClose={closeMoreDrawer} />
        </>
    );
};

FeaturesContainerComponent.propTypes = {
    model: PropTypes.object,
}

const mapStateToProps = state => {
    const { data } = state.model.profile;
    return {
        model: data,
    };
};

const mapDispatchToProps = {
};

export const FeaturesContainer = connect(mapStateToProps, mapDispatchToProps)(FeaturesContainerComponent);