import {
    MODEL_PANEL_RATING_REMOVE_RATING,
    MODEL_PANEL_RATING_REMOVE_RATING_SUCCESS,
    MODEL_PANEL_RATING_REMOVE_RATING_ERROR,
    MODEL_PANEL_RATING_REMOVE_RATING_CLEAN_STATE,
} from './actionTypes';

export const modelPanelRatingRemoveRating = (id, onSuccess) => {
    return {
        type: MODEL_PANEL_RATING_REMOVE_RATING,
        payload: { id, onSuccess },
    }
};

export const modelPanelRatingRemoveRatingSuccess = (id) => {
    return {
        type: MODEL_PANEL_RATING_REMOVE_RATING_SUCCESS,
        payload: { id },
    }
};

export const modelPanelRatingRemoveRatingError = (id) => {
    return {
        type: MODEL_PANEL_RATING_REMOVE_RATING_ERROR,
        payload: { id },
    }
};

export const modelPanelRatingRemoveRatingCleanState = () => {
    return { type: MODEL_PANEL_RATING_REMOVE_RATING_CLEAN_STATE };
};