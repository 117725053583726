import React from "react"
import PropTypes from "prop-types"
import placeholder from "assets/images/dev/website-theme-placeholder.png"
import classNames from "classnames"
import { IconButton, MediaOverviewCard } from "components"

export const TemplateOverviewCard = ({
  item,
  currentTheme,
  onUpdate,
  updateLoading,
}) => {
  const renderActions = item => {
    let isMainTheme = item.id === currentTheme.id
    return (
      <IconButton
        name={isMainTheme ? "mark" : "view"}
        bg
        onClick={() => onUpdate(item.id)}
        disabled={updateLoading}
      />
    )
  }
  const renderBadges = item => {
    return null
  }

  const renderContent = item => {
    return (
      <img
        src={item.thumb || placeholder}
        alt={"template"}
        className={classNames("cover-content", {
          "cover-content_main": item.id === currentTheme.id,
        })}
      />
    )
  }
  return (
    <MediaOverviewCard
      actions={renderActions(item)}
      badges={renderBadges(item)}
      content={renderContent(item)}
      className={"website-template-overview-card"}
      title={item.name}
    />
  )
}

TemplateOverviewCard.propTypes = {
  item: PropTypes.object,
  currentTheme: PropTypes.object,
  onUpdate: PropTypes.func,
  updateLoading: PropTypes.bool,
}
