import React from "react"
import PropTypes from "prop-types"

import { BILLING_PAYMENT_METHOD_TYPE } from "consts"
import { CardForm, SepaContainer } from "./Forms"

const { CARD, SEPA_DEBIT } = BILLING_PAYMENT_METHOD_TYPE

export const AddPaymentMethodForm = props => {
  switch (props.paymentMethodType) {
    case CARD:
      return <CardForm {...props} />
    case SEPA_DEBIT:
      return <SepaContainer {...props} />
    default:
      return null
  }
}

AddPaymentMethodForm.propTypes = {
  paymentMethodType: PropTypes.string,
}
