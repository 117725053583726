import React from 'react';
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next';
import { Container } from "reactstrap";

import { Drawer } from '../../../../Drawer'

export const PackageViewDrawer = (props) => {
    const { t } = useTranslation();

    const handleCloseDrawer = () => {
        props.onClose();
    }

    const onDestroy = () => {
        props.onDestroy();
    }

    const renderTitle = () => {
        let result = t('new_package');
        if (props.packageDataCount > 0) {
            result = result + ` (${props.packageDataCount})`
        }
        return result;
    }

    return (
      <React.Fragment>
        <Drawer
          title={renderTitle()}
          visible={props.visible}
          onClose={handleCloseDrawer}
          placement={"right"}
          className={"drawer-container draft-package-drawer"}
          destroyOnClose
          onDestroy={onDestroy}
        >
          <Container fluid>{props.children}</Container>
        </Drawer>
      </React.Fragment>
    )
};

PackageViewDrawer.propTypes = {
    children: PropTypes.any,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onDestroy: PropTypes.func,
    packageDataCount: PropTypes.number,
}