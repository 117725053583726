import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { PageHeader, Button } from "components"

export const Header = ({ onSyncToCalendar }) => {
  const { t } = useTranslation()

  return (
    <div className={"panel-topbar"}>
      <PageHeader
        title={t("title.calendar")}
        headerRight={
          <Button
            color={"secondary"}
            leftIcon={"bx bx-calendar"}
            outline
            title={"sync_to_calendar"}
            onClick={onSyncToCalendar}
          />
        }
      />
    </div>
  )
}

Header.propTypes = {
  onSyncToCalendar: PropTypes.func,
}
