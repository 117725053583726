import { combineReducers } from "redux"

import panel from "./panel/reducers"
import general from "./general/reducers"
import photos from "./photos/reducers"
import rating from "./rating/reducers"
import history from "./history/reducers"
import video from "./video/reducers"
import book from "./book/reducers"
import activities from "./activities/reducers"
import statement from "./statement/reducers"

const modelPanelReducer = combineReducers({
  panel,
  general,
  photos,
  rating,
  history,
  video,
  book,
  activities,
  statement,
})

export default modelPanelReducer
