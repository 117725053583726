import { takeLatest, fork, put, all, call } from "redux-saga/effects"

import i18n from "../../../../../../i18n"
import { MODEL_PANEL_GENERAL_FETCH_LOGISTICS_LIST } from "./actionTypes"
import {
  modelPanelGeneralFetchLogisticsListSuccess,
  modelPanelGeneralFetchLogisticsListError,
  modelPanelGeneralFetchLogisticsList,
} from "./actions"

import { API } from "../../../../../../api"
import { RequestUtils } from "../../../../../../utils"

export function* fetchList({ payload: { id, params } }) {
  try {
    let requestParams = {
      includes: [],
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response = yield call(API.model.getModelLogistics, id, requestParams)

    const { data } = response

    const {
      meta: { current_page, last_page },
    } = data
    if (current_page && last_page && current_page > last_page) {
      yield put(
        modelPanelGeneralFetchLogisticsList(id, {
          ...params,
          page: last_page,
        })
      )
    } else {
      yield put(modelPanelGeneralFetchLogisticsListSuccess(data))
    }
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(modelPanelGeneralFetchLogisticsListError(message))
  }
}

export function* watchFetchList() {
  yield takeLatest(MODEL_PANEL_GENERAL_FETCH_LOGISTICS_LIST, fetchList)
}

function* dataSaga() {
  yield all([fork(watchFetchList)])
}

export default dataSaga
