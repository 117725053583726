import React from "react"
import PropTypes from "prop-types"
import { TabContent, TabPane } from "reactstrap"

import { TabBar } from "./TabBar"
import { TAB_BAR_TYPE_MENU, TAB_BAR_TYPE_NAV } from "./consts"

export const TabNavigator = props => {
  return (
    <div>
      <div className={"d-flex align-items-center justify-content-between"}>
        <div className={"w-100"}>
          <TabBar
            tabBarType={props.tabBarType}
            tabs={props.tabs}
            activeTab={props.activeTab}
            onTabChange={props.onTabChange}
            className={props.tabBarClassName}
          />
        </div>

        {props.headerEnd && (
          <div className={"d-flex ms-3"}>{props.headerEnd}</div>
        )}
      </div>

      <TabContent activeTab={props.activeTab} className="mt-3">
        {props.tabs.map(({ value, render, hidden, ...item }, index) =>
          !hidden ? (
            <TabPane key={index} tabId={value}>
              {props.destroyOnClose
                ? props.activeTab === value
                  ? render()
                  : null
                : render()}
            </TabPane>
          ) : null
        )}
      </TabContent>
    </div>
  )
}

TabNavigator.propTypes = {
  activeTab: PropTypes.string,
  onTabChange: PropTypes.func,
  tabs: PropTypes.array,
  headerEnd: PropTypes.any,
  destroyOnClose: PropTypes.bool,
  tabBarType: PropTypes.oneOf([TAB_BAR_TYPE_MENU, TAB_BAR_TYPE_NAV]),
  tabBarClassName: PropTypes.string,
}
