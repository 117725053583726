/* eslint react/prop-types: off */
import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { ReactTableView } from "components"

export const ExpensesTable = props => {
  const { t } = useTranslation()

  const { table } = props.data

  const data = useMemo(() => {
    return table
  }, [table])

  const [columns, setColumns] = useState([
    {
      Header: t("date"),
      accessor: "date",
      id: "date",
      Cell: ({ value }) => {
        return value ? moment(value).format("DD/MM/YYYY") : "-"
      },
    },
    {
      Header: t("models_name"),
      accessor: "name",
      id: "name",
      Cell: ({ value }) => value,
    },
    {
      Header: t("description"),
      accessor: "description",
      id: "description",
      Cell: ({ value }) => value,
    },
    {
      Header: () => <div className={"w-100 text-end"}>{t("fee")}</div>,
      accessor: "fee",
      id: "fee",
      Cell: ({ value }) => <div className={"text-end"}>{value}</div>,
    },
  ])

  return (
    <div className="invoice-card-expenses-table">
      <ReactTableView columns={columns} data={data} />
    </div>
  )
}

ExpensesTable.propTypes = {
  data: PropTypes.object,
}
