import React, { useEffect } from "react"
import { Col, Row } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { MegaTagTitle } from "components"
import {
  AnalyticsTableContainer,
  EarningsComparisonContainer,
  TopRatingContainer,
  TotalCountContainer,
} from "./components"
import {
  COMPARISON_BY_EARNINGS,
  EMPLOYEES,
  EMPLOYEES_CASTINGS,
  EMPLOYEES_EARNINGS,
  EMPLOYEES_JOBS,
  EMPLOYEES_TABLE,
  TOP_TEN_EMPLOYEES_BY_EARNINGS,
  TOP_TEN_EMPLOYEES_BY_RATING,
} from "consts"
import {
  analyticsGraphsList,
  analyticsPermissionsData,
  selectGraphsByTabName,
} from "store"
import { analyticsHelper } from "helpers"

export const Employees = () => {
  const dispatch = useDispatch()
  const graphs = useSelector(state => selectGraphsByTabName(state, EMPLOYEES))

  useEffect(() => {
    return () => {
      dispatch(analyticsGraphsList.cleanTabState({ tab: EMPLOYEES }))
      dispatch(analyticsPermissionsData.cleanState())
    }
  }, [])

  const isAllowed = alias => {
    return analyticsHelper.permissions.hasGraph(graphs, alias)
  }

  const renderGraph = alias => {
    let graphData
    if (!isAllowed(alias)) {
      return null
    } else {
      graphData = analyticsHelper.permissions.getGraphConfig(graphs, alias)
    }
    switch (alias) {
      case COMPARISON_BY_EARNINGS: {
        return (
          <Col className={"mb-4"}>
            <EarningsComparisonContainer graphData={graphData} />
          </Col>
        )
      }
      case TOP_TEN_EMPLOYEES_BY_EARNINGS:
      case TOP_TEN_EMPLOYEES_BY_RATING: {
        return (
          <Col md={6} className={"mb-4"}>
            <TopRatingContainer graphData={graphData} />
          </Col>
        )
      }
      case EMPLOYEES_JOBS:
      case EMPLOYEES_CASTINGS:
      case EMPLOYEES_EARNINGS: {
        return (
          <Col md={4} className={"mb-4"}>
            <TotalCountContainer graphData={graphData} />
          </Col>
        )
      }
      case EMPLOYEES_TABLE: {
        return (
          <Col className={"mb-4"}>
            <AnalyticsTableContainer graphData={graphData} />
          </Col>
        )
      }
    }
  }
  return (
    <div>
      <MegaTagTitle title={"employees"} />

      <Row>{renderGraph(COMPARISON_BY_EARNINGS)}</Row>
      <Row>
        {renderGraph(TOP_TEN_EMPLOYEES_BY_RATING)}
        {renderGraph(TOP_TEN_EMPLOYEES_BY_EARNINGS)}
      </Row>

      <Row>
        {renderGraph(EMPLOYEES_JOBS)}
        {renderGraph(EMPLOYEES_CASTINGS)}
        {renderGraph(EMPLOYEES_EARNINGS)}
      </Row>
      <Row>{renderGraph(EMPLOYEES_TABLE)}</Row>
    </div>
  )
}
