import React from "react"
import PropTypes from "prop-types"
import { ReadMoreExpand } from "components"
import classNames from "classnames"

export const MediaOverviewCard = ({
  badges,
  actions,
  description,
  headerPanel,
  title,
  content,
  className = "",
  size,
}) => {
  return (
    <div
      className={classNames("media-overview-card", {
        [className]: className,
        [`media-overview-card_size_${size}`]: size,
      })}
    >
      <div className={"media-overview-card__cover-block"}>
        {content}
        {badges && (
          <div className="media-overview-card__badges-block">{badges}</div>
        )}
        {actions && (
          <div className={"media-overview-card__actions-block"}>{actions}</div>
        )}
      </div>
      {headerPanel && (
        <div className={"media-overview-card__header-panel"}>{headerPanel}</div>
      )}

      {title && (
        <div className={"media-overview-card__title-block"}>
          <div className={"media-overview-card__title"}>{title}</div>
        </div>
      )}

      {description && (
        <div className={"media-overview-card__body"}>
          <ReadMoreExpand
            className={"media-overview-card__description"}
            maxHeight={64}
            lineHeight={16}
          >
            {description}
          </ReadMoreExpand>
        </div>
      )}
    </div>
  )
}

MediaOverviewCard.propTypes = {
  actions: PropTypes.any,
  badges: PropTypes.any,
  content: PropTypes.any,
  headerPanel: PropTypes.any,
  className: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
}
