import * as Yup from "yup"

import { ValidationsUtils } from "utils"
import { FILE_MAX_SIZE } from "consts"

export const validationSchema = Yup.object().shape({
  files: Yup.array().of(
    Yup.object().shape({
      file: Yup.mixed().test("maxSize", "field.error.file-size.max", value =>
        ValidationsUtils.fileMaxSize(value, FILE_MAX_SIZE)
      ),
    })
  ),
  body: Yup.string()
    .nullable()
    .when("files", {
      is: files => files.length === 0,
      then: Yup.string().trim().required("field.error.required"),
    }),
})
