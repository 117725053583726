import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';

import { BookDataLoader } from "../BookDataLoader"
import {
    modelBookPhotosPickerSetValue,
    modelBookPhotosPickerCleanValue,
} from '../../../../../store/actions';

const BookCopyDataComponent = (props) => {

    useEffect(() => {
        return () => {
            props.modelBookPhotosPickerCleanValue();
        };
    }, []);

    useEffect(() => {
        if (props.book?.pages.length > 0) {
            const items = props.book?.pages.map((item) => ({
                ...item,
            }))
            props.modelBookPhotosPickerSetValue(items);
        }
    }, [props.book])

    return (
        <BookDataLoader id={props.id}>
            {props.children}
        </BookDataLoader>
    );
};

BookCopyDataComponent.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    children: PropTypes.any,

    book: PropTypes.object,

    modelBookPhotosPickerSetValue: PropTypes.func,
    modelBookPhotosPickerCleanValue: PropTypes.func,
}

const mapStateToProps = state => {
    const { data } = state.modelPanel.book.details.data;

    return {
        book: data,
    };
};

const mapDispatchToProps = {
    modelBookPhotosPickerSetValue,
    modelBookPhotosPickerCleanValue,
};

export const BookCopyData = connect(mapStateToProps, mapDispatchToProps)(BookCopyDataComponent);