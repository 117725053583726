import { combineReducers } from "redux"

import profile from "./profile/reducers"
import panel from "./panel/reducers"
import rating from "./rating/reducers"
import history from "./history/reducers"
import documents from "./documents/reducers"
import contacts from "./contacts/reducers"
import logo from "./logo/reducers"
import addresses from "./addresses/reducers"
import prices from "./prices/reducers"
import statement from "./statement/reducers"
import activities from "./activities/reducers"

const clientReducer = combineReducers({
  profile,
  panel,
  rating,
  documents,
  contacts,
  logo,
  history,
  addresses,
  prices,
  statement,
  activities,
})

export default clientReducer
