import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

import { SelectorCombobox } from "components"
import { RecipientOptionLabel } from "./RecipientOptionLabel"
import { useFetchData } from "hooks"
import { API } from "api"

export const RecipientSelect = ({ value, disabled, className, ...props }) => {
  const { t } = useTranslation()

  const onSelect = option => {
    props.onSelect(option.email)
  }

  const { fetchData, loading, data } = useFetchData()

  const onFetch = async () => {
    const request = API.model.getModel(props.modelId)
    await fetchData({
      request,
    })
  }

  useEffect(() => {
    if (props.modelId) {
      onFetch()
    }
  }, [props.modelId])

  const options = useMemo(() => {
    let result = []

    if (data?.data) {
      const { emails } = data?.data

      result = emails.map(({ value }) => ({
        email: value,
      }))
    }

    return result
  }, [data?.data])

  const mapValue = value.map(item => ({
    email: item,
  }))

  return (
    <Row>
      <Col>
        <SelectorCombobox
          value={mapValue}
          options={options}
          onSelect={onSelect}
          onFetchOptions={onFetch}
          id={"recipient-select"}
          label={t("search_on_contacts")}
          placeholder={t("search_on_contacts")}
          disabled={disabled}
          loading={loading}
          getOptionLabel={option => `${option.email}`}
          getOptionValue={option => `${option.email}`}
          formatOptionLabel={option => <RecipientOptionLabel option={option} />}
          // closeMenuOnSelect
          // blurInputOnSelect
        />
      </Col>
    </Row>
  )
}

RecipientSelect.propTypes = {
  modelId: PropTypes.number,
  value: PropTypes.any,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}
