import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Label } from "reactstrap"
import classNames from "classnames"

import { WYSIWYGEditor } from "../../Editors"
import { FieldError } from "../FieldError"

export const EditorField = ({
  id,
  label,
  placeholder,
  fieldRef,
  children,
  normalize,
  disabled,
  value,
  meta = {},
  translateParams,
  toolbar,
  editorClassName,
  ...props
}) => {
  const { touched, error } = meta

  const { t } = useTranslation()
  const showError = Boolean(error)

  const renderLabel = label => {
    if (label) {
      if (typeof label === "string") {
        return label
      } else return label
    } else return <></>
  }

  const onEditorStateChange = editorState => {
    if (props.onChange) {
      props.onChange(editorState)
    }
  }

  return (
    <div className="mb-3">
      {label && <Label htmlFor={id}>{renderLabel(label)}</Label>}
      <div
        className={classNames("input-group", {
          "is-invalid": showError,
        })}
      >
        <WYSIWYGEditor
          id={id}
          placeholder={placeholder}
          readOnly={disabled}
          editorState={value}
          onEditorStateChange={onEditorStateChange}
          onBlur={props.onBlur}
          toolbar={toolbar}
          editorClassName={editorClassName}
          // editorRef={fieldRef}
        />
      </div>

      <FieldError
        error={error}
        translateParams={translateParams}
        showError={showError}
      />
    </div>
  )
}

EditorField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.any,
  placeholder: PropTypes.string,
  fieldRef: PropTypes.any,
  children: PropTypes.any,
  normalize: PropTypes.func,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  translateParams: PropTypes.object,
  value: PropTypes.any,
  meta: PropTypes.object,
  onEditorStateChange: PropTypes.func,
  toolbar: PropTypes.object,
  editorClassName: PropTypes.string,
}
