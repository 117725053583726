import React, { useImperativeHandle, forwardRef } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { useFieldArray } from "react-hook-form"

import {
  FilesUploaderField,
  FilesUploaderFileField,
} from "../../FormElements/FilesUploaderField"
import { ControllerHF } from "../ControllerHF"

const FilesUploaderHFComponent = props => {
  const fieldName = props.name
  const error = props?.meta?.error

  useImperativeHandle(props.filesUploaderRef, () => ({
    addFiles(files) {
      addFields(files)
    },
  }))

  const { fields, append, remove, update } = useFieldArray({
    control: props.control,
    name: fieldName,
    keyName: "fieldId",
  })

  const addFields = files => {
    // if (props.clearErrors) {
    //     props.clearErrors(fieldName)
    // }

    const result = [...files].map(file => ({
      file,
    }))
    append(result)
  }

  const removeField = (field, fieldIndex, fieldName) => {
    if (field?.id) {
      props.onRemove(field, { remove, fieldName, fieldIndex })
    } else {
      remove(fieldIndex)
    }
  }

  const upload = (field, fieldIndex, fieldName) => {
    props.onUpload(field, { update, fieldName, fieldIndex })
  }

  const control = props.control

  return (
    <FilesUploaderField
      id={props.id}
      className={props.className}
      name={fieldName}
      fields={fields}
      accept={props.accept}
      pickerLabelText={props.pickerLabelText}
      onRemove={removeField}
      onAdd={addFields}
      onUpload={upload}
      onDownload={props.onDownload}
      limit={props.limit}
      processing={props.processing}
      onValidate={props.onValidate}
      picker={props.picker}
      renderFileField={params => (
        <ControllerHF
          control={control}
          component={FilesUploaderFileField}
          onValidate={props.onValidate}
          translateParams={props.translateParams}
          {...params}
        />
      )}
    />
  )
}

FilesUploaderHFComponent.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  limit: PropTypes.number,
  control: PropTypes.object,
  pickerLabelText: PropTypes.string,
  onUpload: PropTypes.func,
  onRemove: PropTypes.func,
  onDownload: PropTypes.func,
  accept: PropTypes.string,
  processing: PropTypes.array,
  onValidate: PropTypes.func,
  translateParams: PropTypes.object,
  meta: PropTypes.object,
  clearErrors: PropTypes.func,
  className: PropTypes.string,
  picker: PropTypes.bool,
  filesUploaderRef: PropTypes.any,
}

export const FilesUploaderHF = forwardRef((props, ref) => (
  <FilesUploaderHFComponent {...props} filesUploaderRef={ref} />
))
