import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import data from "./data/slice"

// stay for example if its will be update reducer structure
// import { migrations } from "./data/migrations"

const appIntroDataPersistConfig = {
  key: "appIntroData",
  storage,
  // version: 0,
  // migrate: createMigrate(migrations, { debug: false }),
}

const introReducer = combineReducers({
  data: persistReducer(appIntroDataPersistConfig, data),
})

export default introReducer
