import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  graphId: null,
  data: null,
  loading: false,
  error: null,
}

const analyticsPermissionsDataSlice = createSlice({
  name: "analyticsPermissionsData",
  initialState,
  reducers: {
    getData(state) {
      state.loading = true
      state.error = null
    },
    getDataCompleted(state, action) {
      state.loading = false
      state.data = action.payload.data
    },
    getDataRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getData
 *  @property {function} getDataCompleted
 *  @property {function} getDataRejected
 *  @property {function} updateData
 *  @property {function} cleanState
 */
export const analyticsPermissionsData = analyticsPermissionsDataSlice.actions

export default analyticsPermissionsDataSlice.reducer
