import { createSlice } from "@reduxjs/toolkit"


const initialState = {
  loading: false,
  error: null
}

const agencyUpdateProfileSlice = createSlice({
  name: "agencyUpdateProfile",
  initialState,
  reducers: {
    update(state) {
      state.loading = true
      state.error = null
    },
    updateCompleted(state) {
      state.loading = false
    },
    updateRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    }
  }
})

export const agencyUpdateProfile = agencyUpdateProfileSlice.actions

export default agencyUpdateProfileSlice.reducer