export const MODEL_PANEL_PHOTOS_FETCH_LIST = "MODEL_PANEL_PHOTOS_FETCH_LIST"
export const MODEL_PANEL_PHOTOS_FETCH_LIST_SUCCESS =
  "MODEL_PANEL_PHOTOS_FETCH_LIST_SUCCESS"
export const MODEL_PANEL_PHOTOS_FETCH_LIST_ERROR =
  "MODEL_PANEL_PHOTOS_FETCH_LIST_ERROR"
export const MODEL_PANEL_PHOTOS_LIST_CLEAN_STATE =
  "MODEL_PANEL_PHOTOS_LIST_CLEAN_STATE"

export const MODEL_PANEL_PHOTOS_LIST_SET_PAGE =
  "MODEL_PANEL_PHOTOS_LIST_SET_PAGE"
export const MODEL_PANEL_PHOTOS_LIST_UPDATE_ITEM =
  "MODEL_PANEL_PHOTOS_LIST_UPDATE_ITEM"
