import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { PostInfo } from "./PostInfo"
import { PreviewSidebar } from "./PreviewSidebar"
import { socialPostsCalendarHelper } from "helpers"

const { getFacebookPagePostLink, isFacebookPost } =
  socialPostsCalendarHelper.postInfo

export const PostPreview = ({ data, onEdit }) => {
  const postLink = useMemo(() => {
    if (isFacebookPost(data.type)) {
      return getFacebookPagePostLink(data.account_number, data.remote_post_id)
    } else {
      return data.remote_post_data?.permalink
    }
  }, [data])

  return (
    <Row>
      <Col md={8}>
        <PostInfo data={data} />
      </Col>

      <Col md={4} className={"col-divider_start col-divider_md"}>
        <div className={"pt-3 ps-md-3"}>
          <PreviewSidebar
            onEdit={() => onEdit(data)}
            isDeletable={data?.isDeletable}
            isEditable={data?.isEditable}
            isViewable={data?.isViewable}
            postLink={postLink}
          />
        </div>
      </Col>
    </Row>
  )
}

PostPreview.propTypes = {
  data: PropTypes.object,
  onEdit: PropTypes.func,
}
