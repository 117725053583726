import React from "react"
import PropTypes from "prop-types"
import { MODELS_PANEL_STATE } from "consts"
import { DefaultControls } from "./DefaultControls"
import { CreateGroupControls } from "./CreateGroupControls"
import { EditGroupControls } from "./EditGroupControls"
import { AddToGroupControls } from "./AddToGroupControls"
import { useSelector } from "react-redux"

const { ADD_TO_GROUP, EDIT_GROUP, CREATE_GROUP, DEFAULT } = MODELS_PANEL_STATE

export const ModelsToolbar = ({
  fetchData,
  group,
  selectedModels,
  onModelsDataChange,
}) => {
  const modelsPanelState = useSelector(state => state.models.panel.state.state)

  switch (modelsPanelState) {
    case DEFAULT: {
      return <DefaultControls fetchData={fetchData} group={group} />
    }
    case CREATE_GROUP: {
      return (
        <CreateGroupControls
          selectedModels={selectedModels}
          onModelsDataChange={onModelsDataChange}
        />
      )
    }
    case EDIT_GROUP: {
      return <EditGroupControls group={group} />
    }
    case ADD_TO_GROUP: {
      return (
        <AddToGroupControls
          selectedModels={selectedModels}
          onModelsDataChange={onModelsDataChange}
          groupId={group}
        />
      )
    }
  }
}

ModelsToolbar.propTypes = {
  selectedModels: PropTypes.array,
  fetchData: PropTypes.func,
  onModelsDataChange: PropTypes.func,
  group: PropTypes.number,
}
