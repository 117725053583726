/* eslint react/prop-types: off */
import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { ReactTableView, Pagination, IconButton } from "components"
import * as HOC from "HOC"

const TableDataLoading = HOC.withTableDataLoading()

export const PackagesList = props => {
  const { t } = useTranslation()

  const data = useMemo(() => {
    return props.list
  }, [props.list])

  const [columns, setColumns] = useState([
    {
      Header: t("created"),
      accessor: "created_at",
      id: "created_at",
      Cell: ({ value }) => {
        return value ? moment(value).format("DD/MM/YYYY HH:mm") : "-"
      },
    },
    {
      Header: t("creator"),
      accessor: "name",
      id: "name",
      Cell: ({ row: { original }, value }) => original.user?.name,
    },
    {
      Header: t("title"),
      accessor: "title",
      id: "title",
      Cell: ({ row: { original }, value }) => original.title || "-",
    },
    {
      Header: t("models"),
      accessor: "models",
      id: "models",
      Cell: ({ row: { original }, value }) => {
        return original.models || "-"
      },
    },
    {
      Header: "",
      id: "actions",
      Cell: ({ row: { original } }) => (
        <div className={"text-end text-nowrap"}>
          <IconButton
            name={"view"}
            onClick={() => props.onView(original)}
            bg
            disabled={props.removing}
            color={"text-white"}
            bgColor={"bg-dark"}
          />
          <IconButton
            name={"edit"}
            wrapClass={"ms-3"}
            onClick={() => props.onEdit(original)}
            bg
            disabled={props.removing}
          />
          <IconButton
            name={"remove"}
            wrapClass={"ms-3"}
            onClick={() => props.onRemove(original)}
            bg
            disabled={props.removing}
          />
        </div>
      ),
    },
  ])

  return (
    <TableDataLoading
      isLoading={props.loading}
      isFailed={props.error}
      error={props.error}
    >
      <ReactTableView isLoading={props.loading} columns={columns} data={data} />

      <Pagination data={props.meta} onPageChange={props.onPageChange} />
    </TableDataLoading>
  )
}

PackagesList.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,

  onPageChange: PropTypes.func,
  onView: PropTypes.func,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
}
