import React, { useState, forwardRef, useImperativeHandle, useRef } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Modal } from "../../Modal"
import { Button } from "../../Buttons"

const ConfirmPopupComponent = props => {
  const { t } = useTranslation()
  const modalRef = useRef(null)
  const [content, setContent] = useState(null)

  useImperativeHandle(props.parentRef, () => ({
    alert(content) {
      setContent(content)
      showModal()
    },
  }))

  const showModal = () => {
    if (modalRef.current) {
      modalRef.current.show()
    }
  }

  const hideModal = () => {
    if (modalRef.current) {
      modalRef.current.hide()
    }
  }

  const apply = () => {
    hideModal()
  }

  return (
    <Modal
      ref={modalRef}
      strapModalProp={{
        centered: true,
        onClosed: props.onClosed,
      }}
      className={"modal-block modal-confirm-popup"}
    >
      {props.children ? props.children : content ? content : null}

      <div className="mt-4">
        <div className="button-items">
          <Button title={"ok"} onClick={apply} />
        </div>
      </div>
    </Modal>
  )
}

ConfirmPopupComponent.propTypes = {
  children: PropTypes.any,
  parentRef: PropTypes.any,

  onClosed: PropTypes.func,
}

export const AlertPopup = forwardRef((props, ref) => (
  <ConfirmPopupComponent {...props} parentRef={ref} />
))
