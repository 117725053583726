import { combineReducers } from "redux"

import list from "./list/slice"

import remove from "./remove/slice"
import update from "./update/slice"
import add from "./add/slice"

const attachmentsReducer = combineReducers({
    list,
    remove,
    update,
    add,
})

export default attachmentsReducer