import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { IconButton } from "components"

export const ModelPhotoCropFieldLabel = ({ onEdit }) => {
  const { t } = useTranslation()
  return (
    <div className={"d-flex"}>
      <div className={"mr-2"}>{t("photo")}</div>
      <IconButton name={"edit"} bg onClick={onEdit} />
    </div>
  )
}

ModelPhotoCropFieldLabel.propTypes = {
  onEdit: PropTypes.func,
}
