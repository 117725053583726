import React from "react"
import PropTypes from "prop-types"
import { InfoCardShower } from "../../../../../../components"
import { AttachmentsTable } from "../AttachmentsTable/AttachmentsTable"
import { useTranslation } from "react-i18next"

export const AttachmentsInfoCard = props => {
    const { t } = useTranslation()
    return (
        <InfoCardShower
            title={t("attachments")}
            addMode={props.addMode}
            onAdd={props.onAdd}
            items={props.items}
            loading={props.loading}
            error={props.error}
            meta={props.meta}
            onPageChange={props.onPageChange}
        >
            <AttachmentsTable
                items={props.items}
                onEdit={props.onEdit}
                onRemove={props.onRemove}
                removeLoading={props.removeLoading}
            />
        </InfoCardShower>
    )
}

AttachmentsInfoCard.propTypes = {
    onEdit: PropTypes.func,
    onAdd: PropTypes.func,
    addMode: PropTypes.bool,
    onRemove: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.any,
    meta: PropTypes.any,
    onPageChange: PropTypes.func,
    items: PropTypes.array,
    removeLoading: PropTypes.bool,
}


