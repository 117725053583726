import {
    MODEL_PANEL_GENERAL_EDIT_PROFILE,
    MODEL_PANEL_GENERAL_EDIT_PROFILE_SUCCESS,
    MODEL_PANEL_GENERAL_EDIT_PROFILE_ERROR,
    MODEL_PANEL_GENERAL_EDIT_PROFILE_CLEAN_STATE,
} from './actionTypes';

export const modelPanelGeneralEditProfile = (id, params, onSuccess, onError) => {
    return {
        type: MODEL_PANEL_GENERAL_EDIT_PROFILE,
        payload: { id, params, onSuccess, onError },
    }
};

export const modelPanelGeneralEditProfileSuccess = () => {
    return {
        type: MODEL_PANEL_GENERAL_EDIT_PROFILE_SUCCESS,
    }
};

export const modelPanelGeneralEditProfileError = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_EDIT_PROFILE_ERROR,
        payload: data,
    }
};

export const modelPanelGeneralEditProfileCleanState = () => {
    return { type: MODEL_PANEL_GENERAL_EDIT_PROFILE_CLEAN_STATE };
};