import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert } from "reactstrap"
import * as Yup from "yup"
import {
  Button,
  ContactsFieldArrayHF,
  ControllerHF,
  InfoCard,
  InputField,
  SocialNetworkFieldArrayHF,
} from "../../../../../../components"
import { commonHelper, websiteHelper } from "../../../../../../helpers"
import { SOCIAL_NETWORK_LIST } from "../../../../../../consts"
import { ValidationsUtils } from "../../../../../../utils"

export const ContactsForm = props => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    setError,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      about_agency: "",
      link_googlemap: "",
      agency_address: "",
      contacts: [],
      social_networks: commonHelper.socialNetwork.formInitialValues(
        [],
        SOCIAL_NETWORK_LIST
      ),
      ...props.initialValues,
    },
    resolver: yupResolver(
      Yup.object().shape({
        contacts: Yup.array().of(
          Yup.object().shape({
            texts: Yup.array().of(
              Yup.object().shape({
                value: Yup.string().required("field.error.required"),
              })
            ),
            title: Yup.string().required("field.error.required"),
          })
        ),
        social_networks: Yup.array().of(
          Yup.object().shape({
            name: Yup.string(),
            value: Yup.string()
              .nullable()
              .test({
                name: "socialNetwork",
                exclusive: false,
                params: {},
                message: "field.error.invalid",
                test: function (value) {
                  return ValidationsUtils.socialNetwork(value, this.parent.name)
                },
              }),
          })
        ),
      })
    ),
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
      contacts: websiteHelper.contacts.formSubmitValues(values.contacts),
    }

    props.onSubmit(params, { reset, setError })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InfoCard title={t("address")} className={"mb-4"}>
          <ControllerHF
            name={"agency_address"}
            control={control}
            component={InputField}
            id={"agency_address"}
            label={t("agency-address")}
            placeholder={t("agency-address")}
          />
          <ControllerHF
            name={"link_googlemap"}
            control={control}
            component={InputField}
            id={"link_googlemap"}
            label={t("link-to-google-map")}
            placeholder={t("link-to-google-map")}
          />
        </InfoCard>
        <InfoCard title={t("contacts")} className={"mb-4"}>
          <ContactsFieldArrayHF
            name={"contacts"}
            control={control}
            limit={3}
            clearErrors={clearErrors}
            meta={{ error: errors.contacts }}
          />
        </InfoCard>
        <InfoCard title={t("social-networks")} className={"mb-4"}>
          <SocialNetworkFieldArrayHF
            name={"social_networks"}
            control={control}
            inputName={"value"}
          />
        </InfoCard>

        <InfoCard title={t("about-agency")} className={"mb-4"}>
          <ControllerHF
            name={"about_agency"}
            control={control}
            component={InputField}
            id={"about_agency"}
            placeholder={t("about-agency")}
            type="textarea"
            rows={8}
          />
        </InfoCard>
        {props.error && <Alert color="danger">{props.error.message}</Alert>}
        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />
          </div>
        </div>
      </form>
    </>
  )
}

ContactsForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
