import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { GroupFormDrawer } from "../GroupFormDrawer"
import { GroupForm } from "../GroupForm"
import { useSubmitHandler } from "hooks"
import { chatGroupsEdit } from "store/actions"

export const EditGroup = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const {
    conversation,
    edit: { loading, error },
  } = useSelector(state => ({
    conversation: state.chat.conversation.data.data,
    edit: state.chat.groups.edit,
  }))

  const { id, name, participants, admin } = conversation

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        chatGroupsEdit.edit({
          id,
          params,
          onSuccess,
          onError,
        })
      )
    },
    () => {
      props.onSuccess()
    }
  )

  const onDestroy = () => {
    dispatch(chatGroupsEdit.cleanState())
  }

  return (
    <GroupFormDrawer
      title={t("edit_group")}
      visible={props.visible}
      onClose={props.onClose}
      onDestroy={onDestroy}
    >
      <GroupForm
        loading={loading}
        error={error}
        onSubmit={handleSubmit}
        onCancel={props.onClose}
        initialValues={{
          name,
          users: participants.filter(item => item?.id !== admin?.id),
        }}
        isEdit
        chatId={id}
      />
    </GroupFormDrawer>
  )
}

EditGroup.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  onSuccess: PropTypes.func,
}
