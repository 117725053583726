import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { ControllerHF } from "components"
import { MembersFormField } from "./MembersFormField"
import { MemberItem } from "./MemberItem"

export const MembersField = ({ control, ...props }) => {
  const { t } = useTranslation()

  const renderListItemContent = item => {
    return <MemberItem item={item} />
  }

  return (
    <ControllerHF
      name={"users"}
      control={control}
      component={MembersFormField}
      renderListItemContent={renderListItemContent}
      selectedListConfig={{
        title: null,
        emptyMessage: t("no_contacts_selected"),
      }}
      {...props}
    />
  )
}

MembersField.propTypes = {
  control: PropTypes.object,
}
