import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { ConfirmPopup, InfoCard, SubscriptionAccessAlert } from "components"
import { RatingGrid } from "../../../../../components/RatingGrid"
import { employeeRatingsData, employeeRemoveRating } from "store/actions"
import { AddRating } from "./AddRating"
import { useUserAccess } from "hooks"

const RatingContainerComponent = props => {
  const dispatch = useDispatch()

  const { subscriptionFullAccessAllowed } = useUserAccess()
  const subscriptionAllowed = subscriptionFullAccessAllowed()

  //========================DATA PROVIDE LOGIC=======================//
  const fetchData = () => {
    let params = {
      page: props.page,
    }
    dispatch(employeeRatingsData.getData({ id: props.employee.id, params }))
  }

  useEffect(() => {
    if (subscriptionAllowed) {
      fetchData()
    }
  }, [props.page])

  useEffect(() => {
    return () => {
      dispatch(employeeRatingsData.cleanState())
      dispatch(employeeRemoveRating.cleanState())
    }
  }, [])

  //========================ADD RATING LOGIC=======================//

  const handleAddSuccess = () => {
    fetchData()
  }

  //========================PAGE CHANGE LOGIC=======================//

  const handlePageChange = item => {
    dispatch(employeeRatingsData.setPage(item.selected + 1))
  }

  //========================REMOVE LOGIC=======================//

  const removeModalRef = useRef(null)
  const [ratingRemove, setRatingRemove] = useState(null)

  const removeRating = item => {
    setRatingRemove(item)

    if (removeModalRef.current) {
      removeModalRef.current.show()
    }
  }

  const resetRatingRemove = () => {
    setRatingRemove(null)
  }

  const onRemoveSuccess = () => {
    fetchData()
  }

  const confirmRemove = () => {
    dispatch(
      employeeRemoveRating.remove({
        id: ratingRemove?.id,
        onSuccess: onRemoveSuccess,
      })
    )
  }

  return (
    <>
      <InfoCard
        title={"Rating"}
        className={"rating-panel-employee"}
        addMode={props.user.id !== props.employee.id && subscriptionAllowed}
        addBtn={<AddRating onAddSuccess={handleAddSuccess} />}
      >
        {subscriptionAllowed ? (
          <RatingGrid
            items={props.list}
            loading={props.listLoading}
            error={props.listError}
            meta={props.meta}
            page={props.page}
            onRemove={removeRating}
            onPageChange={handlePageChange}
            withoutAuthor
            withRemove
            removing={props.removing}
          />
        ) : (
          <SubscriptionAccessAlert />
        )}

        <ConfirmPopup
          ref={removeModalRef}
          onConfirm={confirmRemove}
          onClosed={resetRatingRemove}
        />
      </InfoCard>
    </>
  )
}

RatingContainerComponent.propTypes = {
  employee: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
  removing: PropTypes.bool,

  user: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.employee.details.data
  const { list, loading, error, meta, page } = state.employee.rating.data

  return {
    employee: data,
    listLoading: loading,
    listError: error,
    user: state.user.profile.data,
    removing: state.employee.rating.remove.loading,
    list,
    meta,
    page,
  }
}

export const RatingContainer = connect(mapStateToProps)(
  RatingContainerComponent
)
