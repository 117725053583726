import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { Col, Row } from "reactstrap"
import { ControllerHF } from "../../../../ReactHookForm"
import { DateInputField } from "../../../../FormElements"
import { useUserAccess } from "hooks"
import { SubscriptionAccessAlert } from "components"

/* prettier-ignore */
const mask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/]

export const AlertSection = props => {
  const { t } = useTranslation()

  const { subscriptionFullAccessAllowed } = useUserAccess()
  const subscriptionAllowed = subscriptionFullAccessAllowed()

  const { control } = useFormContext()

  return (
    <Row>
      <h4 className={"mb-4"}>{t("event.alert-title")}</h4>

      {!subscriptionAllowed && (
        <div>
          <SubscriptionAccessAlert />
        </div>
      )}

      <Col md={6}>
        <ControllerHF
          name={"alerts[0].date_start"}
          control={control}
          component={DateInputField}
          id={"alert_first"}
          label={t("alert_first")}
          placeholder={"DD/MM/YYYY hh:mm"}
          showTimeSelect
          dateFormat={"dd/MM/yyyy HH:mm"}
          mask={mask}
          disabled={!subscriptionAllowed}
        />
      </Col>

      <Col md={6}>
        <ControllerHF
          name={"alerts[1].date_start"}
          control={control}
          component={DateInputField}
          id={"alert_second"}
          label={t("alert_second")}
          placeholder={"DD/MM/YYYY hh:mm"}
          showTimeSelect
          dateFormat={"dd/MM/yyyy HH:mm"}
          mask={mask}
          disabled={!subscriptionAllowed}
        />
      </Col>
    </Row>
  )
}

AlertSection.propTypes = {}
