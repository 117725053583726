import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"
import { Row, Col } from "reactstrap"

import { DepartmentsFields } from "./DeparmentsFields"

export const PermissionsFormSection = props => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <>
      <h4>{t("permissions")}</h4>
      <Row>
        <Col lg={8} xl={6}>
          <DepartmentsFields control={control} />
        </Col>
      </Row>
    </>
  )
}

PermissionsFormSection.propTypes = {}
