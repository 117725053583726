import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../../../../i18n';

import { MODEL_PANEL_GENERAL_DOCUMENT_REMOVE_ATTACHMENT } from './actionTypes';
import {
    modelPanelGeneralDocumentRemoveAttachmentSuccess,
    modelPanelGeneralDocumentRemoveAttachmentError,
} from './actions';

import { API } from '../../../../../../api';

function* removeAttachment({ payload: { id, onSuccess, onError } }) {
    try {
        const response = yield call(API.model.removeDocumentFile, id);

        const { data } = response;

        yield put(modelPanelGeneralDocumentRemoveAttachmentSuccess());

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        const message = error.response?.data?.message || i18n.t('error');

        yield put(modelPanelGeneralDocumentRemoveAttachmentError());

        toastr.error(message);

        if (onError) {
            onError();
        }
    }
}

export function* watchRemoveAttachment() {
    yield takeEvery(MODEL_PANEL_GENERAL_DOCUMENT_REMOVE_ATTACHMENT, removeAttachment)
}

function* removeSaga() {
    yield all([
        fork(watchRemoveAttachment),
    ]);
}

export default removeSaga;