import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  meta: null,
  isMoreLoading: false,
}

const chatConversationMessagesListSlice = createSlice({
  name: "chatConversationMessagesList",
  initialState,
  reducers: {
    getList(state, action) {
      state.listLoading = !action.payload.state.isMoreLoading
      state.listError = null
      state.isMoreLoading = action.payload.state.isMoreLoading
    },
    getListSuccess(state, action) {
      state.listLoading = false
      state.list = [...state.list, ...action.payload.data]
      state.meta = action.payload.meta
      state.isMoreLoading = false
    },
    getListError(state, action) {
      state.listLoading = false
      state.listError = action.payload
      state.isMoreLoading = false
    },
    cleanState() {
      return initialState
    },
    addMessage(state, action) {
      state.list = [action.payload, ...state.list]
    },
    markMessagesSeen(state) {
      state.list = [...state.list.map(item => ({ ...item, seen: true }))]
    },
    removeMessage(state, action) {
      state.list = state.list.filter(item => item.id !== action.payload.id)
    },
    markReceivedMessagesSeen(state) {
      state.list = [
        ...state.list.map(item => {
          return !item.is_my ? { ...item, seen: true } : { ...item }
        }),
      ]
    },
  },
})

/**
 * @namespace
 * @property {function} getList
 * @property {function} getListSuccess
 * @property {function} getListError
 * @property {function} cleanState
 * @property {function} addMessage
 * @property {function} markMessagesSeen
 * @property {function} removeMessage
 * @property {function} markReceivedMessagesSeen
 */

export const chatConversationMessagesList =
  chatConversationMessagesListSlice.actions

export default chatConversationMessagesListSlice.reducer
