import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { AttachmentListControl } from "../../../../../Controls"
import {
  calendarEventModelAttachmentUpload,
  calendarEventModelAttachmentEdit,
  calendarEventModelAttachmentRemove,
} from "store/actions"

export const Attachments = ({ attachments = [], ...props }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [isEdit, setIsEdit] = useState(false)

  const { loading: submitLoading, error: submitError } = useSelector(state =>
    !isEdit
      ? state.calendarEvent.model.attachment.upload
      : state.calendarEvent.model.attachment.edit
  )
  const { loading: removing } = useSelector(
    state => state.calendarEvent.model.attachment.remove
  )

  useEffect(() => {
    return () => {
      dispatch(calendarEventModelAttachmentRemove.cleanState())
    }
  }, [])

  const onSubmitReset = () => {
    dispatch(calendarEventModelAttachmentUpload.cleanState())
    dispatch(calendarEventModelAttachmentEdit.cleanState())
  }

  const onSubmitSuccess = payload => data => {
    const { onSuccess } = payload
    onSuccess(data)
  }

  const onSubmitError = payload => errors => {
    const { onError } = payload
    onError(errors)
  }

  const onAdd = (data, payload) => {
    isEdit && setIsEdit(false)

    dispatch(
      calendarEventModelAttachmentUpload.upload({
        params: data,
        onSuccess: onSubmitSuccess(payload),
        onError: onSubmitError(payload),
      })
    )
  }

  const onEdit = (data, payload) => {
    setIsEdit(true)

    dispatch(
      calendarEventModelAttachmentEdit.edit({
        id: data?.id,
        params: data,
        onSuccess: onSubmitSuccess(payload),
        onError: onSubmitError(payload),
      })
    )
  }

  const onRemove = (data, payload) => {
    const { onSuccess } = payload

    dispatch(
      calendarEventModelAttachmentRemove.remove({
        id: data?.id,
        onSuccess: () => {
          onSuccess(data)
        },
      })
    )
  }

  const onAttachmentChange = value => {
    props.onChange(value)
  }

  return (
    <>
      <AttachmentListControl
        async
        onAdd={onAdd}
        onEdit={onEdit}
        onRemove={onRemove}
        value={attachments}
        onChange={onAttachmentChange}
        loading={submitLoading}
        error={submitError}
        generalLoading={removing}
        onFormDestroy={onSubmitReset}
      />
    </>
  )
}

Attachments.propTypes = {
  attachments: PropTypes.array,
  onChange: PropTypes.func,
}
