import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { EditInvoiceDetails } from "./EditInvoiceDetails"
import { InvoiceDetailsInfoCard } from "./InvoiceDetailsInfoCard"

const InvoiceDetailsContainerComponent = props => {
  const [editDrawerVisible, setEditDrawerVisible] = useState(false)

  const handleEdit = () => {
    setEditDrawerVisible(true)
  }

  const closeEditDrawer = () => {
    setEditDrawerVisible(false)
  }

  return (
    <>
      <InvoiceDetailsInfoCard
        editMode={true}
        onEdit={handleEdit}
        data={props.client}
      />

      <EditInvoiceDetails
        visible={editDrawerVisible}
        onClose={closeEditDrawer}
      />
    </>
  )
}

InvoiceDetailsContainerComponent.propTypes = {
  client: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.client.profile.data
  return {
    client: data,
  }
}

export const InvoiceDetailsContainer = connect(mapStateToProps)(
  InvoiceDetailsContainerComponent
)
