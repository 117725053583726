import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Link, useLocation } from "react-router-dom"

import { Drawer, LogoIcon } from "components"
import SidebarContent from "../SidebarContent"

export const SidebarDrawer = ({ visible, toggleVisible, ...props }) => {
  const location = useLocation()

  const onClose = () => {
    toggleVisible(false)
  }

  useEffect(() => {
    if (visible) {
      onClose()
    }
  }, [location])

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      placement={"left"}
      className={"sidebar-drawer"}
      header={false}
    >
      <div className="navbar-brand-box">
        <Link to="/calendar" className="logo logo-light">
          <span className="logo-sm">
            <LogoIcon size="50" />
          </span>
        </Link>
      </div>
      <div className={"sidebar-drawer-content"}>
        <SidebarContent />
      </div>
    </Drawer>
  )
}

SidebarDrawer.propTypes = {
  visible: PropTypes.bool,
  toggleVisible: PropTypes.func,
}
