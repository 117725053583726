import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { billingPaymentMethodAdd } from "./slice"
import { stripeSetup } from "../../stripe/setup/saga"

function* stripeAdd({ payload: { stripe, elements, params, onSuccess } }) {
  try {
    const { setupIntent, error } = yield call(stripeSetup, {
      payload: {
        stripe,
        elements,
        params,
      },
    })

    if (error) {
      yield put(
        billingPaymentMethodAdd.addError({
          errors: null,
          message: error.message,
        })
      )
    } else {
      const payment_method = setupIntent.payment_method

      const values = {
        payment_method,
        default: params.default,
      }

      yield call(addPaymentMethod, { payload: { params: values, onSuccess } })
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      billingPaymentMethodAdd.addError({
        errors,
        message,
      })
    )
  }
}

function* addPaymentMethod({ payload: { params, onSuccess } }) {
  try {
    const response = yield call(API.billing.addPaymentMethod, params)
    const { data } = response

    yield put(billingPaymentMethodAdd.addSuccess())
    toastr.success(i18n.t(`billing.payment_method.add.success`))

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      billingPaymentMethodAdd.addError({
        errors,
        message,
      })
    )
  }
}

export function* watchStripeAdd() {
  yield takeEvery(billingPaymentMethodAdd.stripeAdd, stripeAdd)
}

function* addSaga() {
  yield all([fork(watchStripeAdd)])
}

export default addSaga
