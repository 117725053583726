import {
    EMPLOYEE_CREATE,
    EMPLOYEE_CREATE_SUCCESS,
    EMPLOYEE_CREATE_ERROR,
    EMPLOYEE_CREATE_CLEAN_STATE
} from './actionTypes';

export const employeeCreate = (params, onSuccess, onError) => {
    return {
        type: EMPLOYEE_CREATE,
        payload: { params, onSuccess, onError },
    }
};

export const employeeCreateSuccess = () => {
    return {
        type: EMPLOYEE_CREATE_SUCCESS,
    }
};

export const employeeCreateError = (data) => {
    return {
        type: EMPLOYEE_CREATE_ERROR,
        payload: data,
    }
};

export const employeeCreateCleanState = () => {
    return { type: EMPLOYEE_CREATE_CLEAN_STATE };
};