import { useTranslation } from "react-i18next"
import { IconButton } from "../../../../../../components"
import PropTypes from "prop-types"
import React from "react"

export const AttachmentsTable = props => {
  const { t } = useTranslation()

  return (
    <div className="table-responsive">
      <table className="table align-middle table-cell_p-v_sm">
        <tbody>
          {props.items.map(item => (
            <tr key={item.id}>
              <td>
                <div className={"d-flex"}>
                  <div className={"float-left align-items-center me-2"}>
                    <div className={"mark-dot-pointer pos-t_d_2"} />
                  </div>

                  <span className={"fw-medium text-nowrap"}>{item.title}</span>
                </div>
              </td>

              <td
                className={"table-column-sticky_end bg-neutral text-nowrap"}
                style={{ width: "76px" }}
              >
                <IconButton
                  name={"edit"}
                  bg
                  onClick={() => props.onEdit(item)}
                />

                <IconButton
                  name={"remove"}
                  bg
                  onClick={() => props.onRemove(item)}
                  wrapClass={"ms-2"}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

AttachmentsTable.propTypes = {
  items: PropTypes.array,
  removingLoading: PropTypes.bool,

  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
}
