import axios from "axios"

import { RequestUtils } from "utils"

class DepartmentAPI {
  // Permissions
  getPermissions = async () => {
    return axios.get(`api/departments/permissions`)
  }

  updatePermissions = async params => {
    return axios.post(`api/departments/permissions`, params)
  }
  // --- //
}

export const department = new DepartmentAPI()
