import React from "react"
import PropTypes from "prop-types"

import { AddGroup as AddGroupComponent } from "../../../Common"
import { useDrawer } from "hooks"
import { TextButton } from "components"

export const AddGroup = props => {
  const {
    hide: hideDrawer,
    show: showDrawer,
    visible: drawerVisible,
  } = useDrawer()

  const onSuccess = () => {
    hideDrawer()
  }

  return (
    <>
      <TextButton
        title={"add_group"}
        onClick={showDrawer}
        color={"secondary"}
      />

      <AddGroupComponent
        visible={drawerVisible}
        onClose={hideDrawer}
        onSuccess={onSuccess}
      />
    </>
  )
}

AddGroup.propTypes = {}
