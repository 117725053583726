import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { Alert } from "reactstrap"

import {
  Button,
  ControllerHF,
  InfoCard,
  InputField,
} from "../../../../../../components"

export const BecomeModelForm = props => {
  const { t } = useTranslation()

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      form_text: "",
      ...props.initialValues,
    },
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
    }
    props.onSubmit(params, { reset, setError })
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <InfoCard title={t("description")} className={"mb-4"}>
          <ControllerHF
            name={"form_text"}
            control={control}
            component={InputField}
            id={"form_text"}
            placeholder={t("description")}
            type="textarea"
            rows={8}
          />
        </InfoCard>
        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />
          </div>
        </div>
      </form>
    </>
  )
}

BecomeModelForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
