import {
    MODEL_PANEL_RATING_ADD_RATING,
    MODEL_PANEL_RATING_ADD_RATING_SUCCESS,
    MODEL_PANEL_RATING_ADD_RATING_ERROR,
    MODEL_PANEL_RATING_ADD_RATING_CLEAN_STATE,
} from './actionTypes';

export const modelPanelRatingAddRating = (id, params, onSuccess, onError) => {
    return {
        type: MODEL_PANEL_RATING_ADD_RATING,
        payload: { id, params, onSuccess, onError },
    }
};

export const modelPanelRatingAddRatingSuccess = () => {
    return {
        type: MODEL_PANEL_RATING_ADD_RATING_SUCCESS,
    }
};

export const modelPanelRatingAddRatingError = (data) => {
    return {
        type: MODEL_PANEL_RATING_ADD_RATING_ERROR,
        payload: data,
    }
};

export const modelPanelRatingAddRatingCleanState = () => {
    return { type: MODEL_PANEL_RATING_ADD_RATING_CLEAN_STATE };
};