import { takeEvery, fork, put, all, select } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { socketOnWebsiteCreate } from "./actions"
import { websiteGeneralData as websiteGeneralDataAction } from "../../../website/general/actions"

export const websiteGeneralDataState = state => state.website.general.data

function* onCreate({ payload: { data } }) {
  const { website } = data

  const websiteGeneralData = yield select(websiteGeneralDataState)

  if (websiteGeneralData?.isFetched || websiteGeneralData?.data) {
    yield put(websiteGeneralDataAction.updateData({ ...website }))
  }

  if (website?.created) {
    toastr.success(i18n.t("website.create.success"))
  }
}

export function* watchOnCreate() {
  yield takeEvery(socketOnWebsiteCreate, onCreate)
}

function* createSaga() {
  yield all([fork(watchOnCreate)])
}

export default createSaga
