import { takeEvery, fork, put, all, select } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { socketOnImportDataUpdate } from "./actions"
import { socketImportData } from "./slice"
import { importData as importDataAction } from "../../../import/actions"
import { IMPORT_ENTITY_TYPE } from "consts"
import { importHelper } from "helpers"

const { MODELS, AGENCIES, CLIENTS } = IMPORT_ENTITY_TYPE

const importDataState = state => state.import.data

function* onDataUpdate({ payload: { data } }) {
  const { importer } = data

  const { type, last_status } = importer

  const importData = yield select(importDataState)
  // update import data
  if (importData?.data) {
    // yield put(importDataAction.updateData({ ...importer }))
    yield put(importDataAction.getData({}))
  }

  const entityTitle = importHelper.entity.getEntityType(type)?.label

  // re-fetch entity list
  if (last_status) {
    if (type === MODELS) {
      yield put(socketImportData.syncModels({}))
    } else if (type === AGENCIES) {
      yield put(socketImportData.syncAgencies({}))
    } else if (type === CLIENTS) {
      yield put(socketImportData.syncClients({}))
    }
  }

  if (last_status) {
    toastr.success(
      i18n.t("entity-import.completed.success", { entity: entityTitle })
    )
  } else {
    toastr.error(i18n.t("entity-import.failed", { entity: entityTitle }))
  }
}

export function* watchOnDataUpdate() {
  yield takeEvery(socketOnImportDataUpdate, onDataUpdate)
}

function* dataSaga() {
  yield all([fork(watchOnDataUpdate)])
}

export default dataSaga
