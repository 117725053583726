import React from "react"
import PropTypes from "prop-types"
import { StatementPaidStatusBadge } from "components"
import classNames from "classnames"
import { Badge } from "reactstrap"

export const PaymentStatusMarker = ({
  value,
  variant = "default",
  ...props
}) => {
  const getConfig = React.useCallback(v => {
    if (v) {
      return {
        theme: "success",
      }
    }

    return {
      theme: "warning",
    }
  }, [])

  const config = React.useMemo(() => {
    return getConfig(value)
  }, [getConfig, value])

  return (
    <>
      {variant === "badge" ? (
        <StatementPaidStatusBadge value={value} />
      ) : (
        <>
          <Badge
            pill
            className={classNames("font-size-12 rounded-pill", {
              [`badge-soft-${config?.theme}`]: config?.theme,
            })}
            style={{ padding: "0.125rem" }}
          >
            <i className={"bx bx-check"} />
          </Badge>
        </>
      )}
    </>
  )
}

PaymentStatusMarker.propTypes = {
  value: PropTypes.bool,
  variant: PropTypes.oneOf(["default", "badge"]),
}
