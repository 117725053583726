import { createSelector } from "@reduxjs/toolkit"

const getSyncCount = state => state.agencies.panel.list.syncCount
const getImportSyncCount = state => state.socket.import.data.agenciesSyncCount

export const selectAgenciesListSyncCount = createSelector(
  [getSyncCount, getImportSyncCount],
  (syncCount, importSyncCount) => {
    return syncCount + importSyncCount
  }
)
