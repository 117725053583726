import { combineReducers } from "redux"

import state from "./state/slice"
import intro from "./intro/reducers"

const appReducer = combineReducers({
  state,
  intro,
})

export default appReducer
