import React from "react"
import PropTypes from "prop-types"

import { MegaTagTitle } from "components"
import { SubscriptionTariffsContainer } from "../components"

export const SubscriptionPlans = props => {
  return (
    <div className={"billing-portal-subscription-plans"}>
      <MegaTagTitle title={"subscription_plans"} />

      <SubscriptionTariffsContainer />
    </div>
  )
}

SubscriptionPlans.propTypes = {}
