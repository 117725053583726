import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Modal } from "components/index"
import { useModal } from "hooks"

export const ModalNotificationWrapper = props => {
  const { showModal, modalRef, onModalClose } = useModal(() => {
    props.onModalClose()
  })

  useEffect(() => {
    if (props.isNewNotification) {
      showModal()
    }
  }, [props.isNewNotification])

  return (
    <Modal
      className={"modal-notification"}
      ref={modalRef}
      strapModalProp={{
        centered: true,
        onClosed: onModalClose,
      }}
    >
      {props.children}
    </Modal>
  )
}

ModalNotificationWrapper.propTypes = {
  isNewNotification: PropTypes.bool,
  onModalClose: PropTypes.func,
  children: PropTypes.node,
}
