import {
    MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTOS,
    MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTOS_SUCCESS,
    MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTOS_ERROR,
    MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTOS_CLEAN_STATE,
} from './actionTypes';

export const modelPanelPhotosListRemovePhotos = (photos, onSuccess, onError) => {
    return {
        type: MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTOS,
        payload: { photos, onSuccess, onError },
    }
};

export const modelPanelPhotosListRemovePhotosSuccess = () => {
    return {
        type: MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTOS_SUCCESS,
    }
};

export const modelPanelPhotosListRemovePhotosError = (data) => {
    return {
        type: MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTOS_ERROR,
        payload: data,
    }
};

export const modelPanelPhotosListRemovePhotosCleanState = () => {
    return { type: MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTOS_CLEAN_STATE };
};