import React, { useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

export const LetterAvatar = ({
  textColor = "text-white",
  backgroundColor = "bg-primary",
  text = "",
  size = "xs",
  className = "",
}) => {
  const title = useMemo(() => {
    let result = ""
    const words = text.split(" ")
    result = words[0][0]

    if (words[1]) {
      result = result + words[1][0]
    }

    return result
  }, [text])

  return (
    <div
      className={classNames({
        [className]: className,
        [`avatar-${size}`]: size,
      })}
    >
      <span
        className={classNames("avatar-title rounded-circle text-uppercase", {
          [textColor]: textColor,
          [backgroundColor]: backgroundColor,
        })}
      >
        {title}
      </span>
    </div>
  )
}

LetterAvatar.propTypes = {
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
}
