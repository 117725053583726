import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Drawer } from "../../../../../../components"
import { Container, Row } from "reactstrap"
import { ValidationsUtils } from "../../../../../../utils"
import { EditPaymentDetailsForm } from "./EditPaymentDetailsForm"
import { useTranslation } from "react-i18next"
import { clientUpdateProfile } from "../../../../../../store/actions"

const EditPaymentDetailsComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const handleCloseDrawer = () => {
    props.onClose()
  }
  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    props.onClose()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      clientUpdateProfile.update({
        id: props.client.id,
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  const onDestroy = () => {
    dispatch(clientUpdateProfile.cleanState())
  }
  return (
    <>
      <Drawer
        title={t("payment-details")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <Container fluid>
          <Row>
            <EditPaymentDetailsForm
              onSubmit={handleSubmit}
              onCancel={handleCloseDrawer}
              initialValues={{
                payment_details: props.client.payment_details,
              }}
              loading={props.loading}
              error={props.error}
            />
          </Row>
        </Container>
      </Drawer>
    </>
  )
}

EditPaymentDetailsComponent.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,

  loading: PropTypes.bool,
  error: PropTypes.object,
  client: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.client.profile.data
  const { loading, error } = state.client.profile.update
  return {
    client: data,
    loading,
    error,
  }
}

export const EditPaymentDetails = connect(mapStateToProps)(
  EditPaymentDetailsComponent
)
