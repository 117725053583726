import React, { useRef } from "react"
import PropTypes from "prop-types"
import { TextButton } from "components"

export const UploadPhoto = props => {
  const inputRef = useRef()
  const handleImageSelected = event => {
    let files = event.target.files
    let fileList = [...files]
    props.onUpload(fileList)
    if (inputRef.current) {
      inputRef.current.value = null
    }
  }

  const selectPhoto = event => {
    event.preventDefault()

    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <>
      <input
        type="file"
        id={"multiple-photo"}
        className="d-none"
        onChange={handleImageSelected}
        ref={inputRef}
        accept="image/png, image/jpeg"
        multiple
      />
      <TextButton
        loading={props.loading}
        onClick={e => selectPhoto(e)}
        disabled={props.loading}
        title={"btn.upload-model-photos"}
        color={"secondary"}
      />
    </>
  )
}

UploadPhoto.propTypes = {
  image: PropTypes.any,
  loading: PropTypes.bool,
  onUpload: PropTypes.func,
}
