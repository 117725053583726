import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Card, CardBody } from "reactstrap"

import placeholder from "../../../../assets/images/dev/avatar-placeholder.png"
import {
  profileUploadAvatar,
  profileRemoveAvatar,
  profileAvatarCleanState,
} from "../../../../store/actions"
import { ValidationErrors } from "../../../../components"
import { UploadImage } from "../UploadImage"

const UploadProfileAvatarComponent = ({
  loading,
  error,
  user,
  profileUploadAvatar,
  profileRemoveAvatar,
  profileAvatarCleanState,
}) => {
  useEffect(() => {
    return () => {
      profileAvatarCleanState()
    }
  }, [])

  const handleUpload = file => {
    profileUploadAvatar(file)
  }

  const handleRemove = () => {
    profileRemoveAvatar()
  }

  return (
    <Card>
      <CardBody className={"bg-neutral"}>
        <UploadImage
          id={"avatar"}
          image={user?.avatar}
          placeholder={placeholder}
          labelUpload={"avatar.upload"}
          labelUploadNew={"avatar.upload.new"}
          labelRemove={"avatar.remove"}
          loading={loading}
          onUpdate={handleUpload}
          onRemove={handleRemove}
        />

        {error && <ValidationErrors data={error} />}
      </CardBody>
    </Card>
  )
}

UploadProfileAvatarComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  user: PropTypes.object,

  profileUploadAvatar: PropTypes.func,
  profileRemoveAvatar: PropTypes.func,
  profileAvatarCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, error } = state.profile.avatar
  const user = state.user.profile.data
  return { loading, error, user }
}

const mapDispatchToProps = {
  profileUploadAvatar,
  profileRemoveAvatar,
  profileAvatarCleanState,
}

export const UploadProfileAvatar = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadProfileAvatarComponent)
