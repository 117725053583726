import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const billingSubscriptionResumeSlice = createSlice({
  name: "billingSubscriptionCancel",
  initialState,
  reducers: {
    resume(state) {
      state.loading = true
      state.error = null
    },
    resumeSuccess(state) {
      state.loading = false
    },
    resumeError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} resume
 * @property {function} resumeSuccess
 * @property {function} resumeError
 * @property {function} cleanState
 */
export const billingSubscriptionResume = billingSubscriptionResumeSlice.actions

export default billingSubscriptionResumeSlice.reducer
