import { takeEvery, fork, all, call } from "redux-saga/effects"

import { socketOnChatGroupUpdated } from "./actions"
import { onChatUpdate } from "../../../../chat/chats/list/saga"
import { onConversationUpdate } from "../../../../chat/conversation/data/saga"

function* onUpdate({ payload: { data } }) {
  const { chat } = data

  yield call(onChatUpdate, {
    payload: {
      chat,
    },
  })

  yield call(onConversationUpdate, {
    payload: {
      chat,
    },
  })
}

export function* watchOnUpdate() {
  yield takeEvery(socketOnChatGroupUpdated, onUpdate)
}

function* updateSaga() {
  yield all([fork(watchOnUpdate)])
}

export default updateSaga
