import React, { useState } from "react"
import { requestHelper } from "../../helpers"

export const useClientCategories = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [error, setError] = useState(null)

  const fetchData = async (params = {}) => {
    setLoading(true)
    try {
      const response = await requestHelper.staticData.getClientCategories()
      const { data } = response
      setData(data.data)
      setLoading(false)
    } catch (e) {
      setError(error)
      setLoading(false)
    }
  }

  return { data, loading, error, fetchData }
}
