import React, { useState } from "react"
import PropTypes from "prop-types"
import { RadioInput } from "./RadioInput"
import { useTranslation } from "react-i18next"
import { DateRangePicker } from "components/DatePickers"
import { analyticsHelper } from "helpers"
import { GRAPH_PERIOD_TYPE } from "consts"

const { getComparisonPeriodData, getPeriodData } = analyticsHelper.period

export const GraphsPeriodControls = React.memo(
  ({ id, defaultPeriodType, onChange, withComparisonPeriod = false }) => {
    const { t } = useTranslation()

    const [periodType, setPeriodType] = useState(defaultPeriodType)

    const [range, setRange] = useState(getPeriodData(defaultPeriodType).range)

    const [comparisonRange, setComparisonRange] = useState(
      getComparisonPeriodData(defaultPeriodType).comparisonRange
    )

    const handlePeriodTypeChange = periodType => {
      setPeriodType(periodType)

      if (withComparisonPeriod) {
        if (periodType !== GRAPH_PERIOD_TYPE.CUSTOM) {
          let { params, range, comparisonRange } =
            getComparisonPeriodData(periodType)
          setRange(range)
          setComparisonRange(comparisonRange)
          //Make request with new params
          onChange(params)
        }
      } else {
        if (periodType !== GRAPH_PERIOD_TYPE.CUSTOM) {
          let { params, range } = getPeriodData(periodType)
          setRange(range)
          //Make request with new params
          onChange(params)
        }
      }
    }
    const handleDateRangeChange = newRange => {
      if (!newRange.length) {
        //Set previous range
        setRange([...range])
        return
      }

      setRange(newRange)
      let params
      if (withComparisonPeriod) {
        params = getComparisonPeriodData(
          GRAPH_PERIOD_TYPE.CUSTOM,
          newRange,
          comparisonRange
        ).params
      } else {
        params = getPeriodData(GRAPH_PERIOD_TYPE.CUSTOM, newRange).params
      }

      //Make request
      onChange(params)
    }

    const handleComparisonDateRangeChange = newRange => {
      if (!newRange.length) {
        //Set previous range
        setComparisonRange([...comparisonRange])
        return
      }
      setComparisonRange(newRange)

      let { params } = getComparisonPeriodData(
        GRAPH_PERIOD_TYPE.CUSTOM,
        range,
        newRange
      )
      //Make request
      onChange(params)
    }
    return (
      <>
        <div className="chart-controller mr-2 ">
          <RadioInput
            id={id}
            label={t("week")}
            value={GRAPH_PERIOD_TYPE.WEEK}
            onChange={handlePeriodTypeChange}
            checked={GRAPH_PERIOD_TYPE.WEEK === periodType}
          />
        </div>
        <div className="chart-controller mr-2 ">
          <RadioInput
            id={id}
            label={t("month")}
            value={GRAPH_PERIOD_TYPE.MONTH}
            onChange={handlePeriodTypeChange}
            checked={GRAPH_PERIOD_TYPE.MONTH === periodType}
          />
        </div>
        <div className="chart-controller mr-2 ">
          <RadioInput
            id={id}
            label={t("year")}
            value={GRAPH_PERIOD_TYPE.YEAR}
            onChange={handlePeriodTypeChange}
            checked={GRAPH_PERIOD_TYPE.YEAR === periodType}
          />
        </div>
        <div className="chart-controller">
          <RadioInput
            id={id}
            value={GRAPH_PERIOD_TYPE.CUSTOM}
            onChange={handlePeriodTypeChange}
            checked={GRAPH_PERIOD_TYPE.CUSTOM === periodType}
            renderComponent={() => (
              <div className={"d-flex gap-2"}>
                <div className={"w-100"}>
                  <DateRangePicker
                    value={range}
                    onChange={handleDateRangeChange}
                    placeholder={t("select-period")}
                    id={`${id}-basePeriod`}
                    disabled={GRAPH_PERIOD_TYPE.CUSTOM !== periodType}
                    isClearable={false}
                  />
                </div>
                {withComparisonPeriod && (
                  <div className={"w-100"}>
                    <DateRangePicker
                      value={comparisonRange}
                      onChange={handleComparisonDateRangeChange}
                      placeholder={t("select-period")}
                      id={`${id}-comparisonPeriod`}
                      disabled={GRAPH_PERIOD_TYPE.CUSTOM !== periodType}
                      isClearable={false}
                    />
                  </div>
                )}
              </div>
            )}
          />
        </div>
      </>
    )
  }
)

GraphsPeriodControls.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultPeriodType: PropTypes.oneOf(["month", "week", "year", "custom"]),
  onChange: PropTypes.func,
  withComparisonPeriod: PropTypes.bool,
}
