import React, { useEffect, useRef, useState } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';

import { InfoCardShower } from "../InfoCardShower"
import { AddAgency } from './AddAgency';
import { AgenciesTable } from './AgenciesTable';
import { EditAgency } from './EditAgency';
import {
    modelPanelGeneralFetchAgencyList,
    modelPanelGeneralFetchAgencyListCleanState,
    modelPanelGeneralAgencyListSetPage,
    modelPanelGeneralAgencyListRemoveItem,
    modelPanelGeneralAgencyListRemoveItemCleanState,
} from '../../../../../store/actions';
import { ConfirmPopup } from "../../../../../components"

const AgenciesContainerComponent = (props) => {
    const removeModalRef = useRef(null);

    const [agencyRemove, setAgencyRemove] = useState(null);
    const [agencyEdit, setAgencyEdit] = useState(null);
    const [editDrawerVisible, setEditDrawerVisible] = useState(null);
    const [addDrawerVisible, setAddDrawerVisible] = useState(null);

    useEffect(() => {
        return () => {
            props.modelPanelGeneralFetchAgencyListCleanState();
            props.modelPanelGeneralAgencyListRemoveItemCleanState();
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [props.page]);

    const fetchData = () => {
        let params = {
            page: props.page,
            per_page: 5,
        };

        props.modelPanelGeneralFetchAgencyList(props.model.id, params);
    };

    const handlePageChange = (item) => {
        props.modelPanelGeneralAgencyListSetPage(item.selected + 1);
    };

    const handleAddSuccess = () => {
        fetchData();
    };

    const removeAgency = (item) => {
        setAgencyRemove(item);

        if (removeModalRef.current) {
            removeModalRef.current.show();
        }
    };

    const resetAgencyRemove = () => {
        setAgencyRemove(null);
    };

    const onRemoveSuccess = () => {
        fetchData();
    }

    const confirmRemove = () => {
        props.modelPanelGeneralAgencyListRemoveItem(agencyRemove.id, onRemoveSuccess);
    };

    const editAgency = (item) => {
        setAgencyEdit(item);
        editDrawerShow();
    };

    const handleEditSuccess = () => {
        fetchData();
    }

    const resetAgencyEdit = () => {
        setAgencyEdit(null);
    }

    const editDrawerShow = () => {
        setEditDrawerVisible(true)
    };

    const editDrawerClose = () => {
        setEditDrawerVisible(false)
    };

    const editDrawerDestroy = () => {
        resetAgencyEdit();
    };

    const addAgency = () => {
        setAddDrawerVisible(true);
    };

    const addDrawerClose = () => {
        setAddDrawerVisible(false)
    };

    return (
        <>
            <InfoCardShower
                title={'agencies'}
                className={'model-agencies-info'}
                addMode
                onAdd={addAgency}
                items={props.list}
                loading={props.listLoading}
                error={props.listError}
                meta={props.meta}
                onPageChange={handlePageChange}
            >
                <AgenciesTable
                    items={props.list}
                    removingItems={props.removingItems}
                    onEdit={editAgency}
                    onRemove={removeAgency}
                />
            </InfoCardShower>

            <AddAgency
                visible={addDrawerVisible}
                onAddSuccess={handleAddSuccess}
                onClose={addDrawerClose}
            />

            <EditAgency
                visible={editDrawerVisible}
                onEditSuccess={handleEditSuccess}
                id={agencyEdit?.id}
                onClose={editDrawerClose}
                onDestroy={editDrawerDestroy}
            />

            <ConfirmPopup ref={removeModalRef} onConfirm={confirmRemove} onClosed={resetAgencyRemove} />
        </>
    );
};

AgenciesContainerComponent.propTypes = {
    model: PropTypes.object,
    list: PropTypes.array,
    listLoading: PropTypes.bool,
    listError: PropTypes.any,
    meta: PropTypes.object,
    page: PropTypes.any,
    removingItems: PropTypes.array,

    modelPanelGeneralFetchAgencyList: PropTypes.func,
    modelPanelGeneralFetchAgencyListCleanState: PropTypes.func,
    modelPanelGeneralAgencyListSetPage: PropTypes.func,
    modelPanelGeneralAgencyListRemoveItem: PropTypes.func,
    modelPanelGeneralAgencyListRemoveItemCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { data } = state.model.profile;
    const { list, listLoading, listError, meta, page } = state.modelPanel.general.agency.list.data;
    const { pending } = state.modelPanel.general.agency.list.removeItem;
    return {
        model: data,
        list, listLoading, listError, meta, page,
        removingItems: pending,
    };
};

const mapDispatchToProps = {
    modelPanelGeneralFetchAgencyList,
    modelPanelGeneralFetchAgencyListCleanState,
    modelPanelGeneralAgencyListSetPage,
    modelPanelGeneralAgencyListRemoveItem,
    modelPanelGeneralAgencyListRemoveItemCleanState,
};

export const AgenciesContainer = connect(mapStateToProps, mapDispatchToProps)(AgenciesContainerComponent);