import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import { useFieldArray } from "react-hook-form"
import { IconButton, TextButton } from "../../Buttons"
import { InputField } from "../../FormElements"
import { ControllerHF } from "../ControllerHF"

export const ContactTextFieldArray = props => {
  const { t } = useTranslation()

  const fieldName = props.name

  const error = props?.meta?.error

  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: fieldName,
    keyName: "fieldId",
  })

  const addRow = e => {
    e.preventDefault()
    if (props.clearErrors) {
      props.clearErrors(props.name)
    }
    append({ value: "" })
  }

  const removeRow = index => {
    remove(index)
  }

  return (
    <>
      {fields.map((item, index) => (
        <Row key={item.fieldId}>
          <Col xs={12} md={12}>
            <ControllerHF
              name={`${fieldName}.${index}.value`}
              control={props.control}
              component={InputField}
              id={`${fieldName}.${index}.value`}
              label={index === 0 && t("field.label.contact-text")}
              placeholder={t("field.placeholder.contact-text")}
              rightAccessory={
                <div className={"form-right-accessory"}>
                  <IconButton
                    name={"remove"}
                    bg
                    onClick={() => removeRow(index)}
                  />
                </div>
              }
            />
          </Col>
        </Row>
      ))}
      {fields.length < props.limit && (
        <Col md={12} className={"d-flex justify-content-center"}>
          <TextButton title={"add-contact-text"} onClick={addRow} />
          <div className={"field-error-message"}>
            {error && t(error?.message)}
          </div>
        </Col>
      )}
    </>
  )
}

ContactTextFieldArray.propTypes = {
  name: PropTypes.string,
  limit: PropTypes.number,
  control: PropTypes.object,
  clearErrors: PropTypes.any,
  meta: PropTypes.any,
}
