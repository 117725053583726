import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { DrawerContainer } from "components"
import { PermissionsDataLoader } from "./PermissionsDataLoader"
import { SettingsForm } from "./SettingsForm"
import { useSubmitHandler } from "hooks"
import { departmentPermissionsSubmit } from "store/actions"
import { getInitialValues } from "./helpers"

export const Settings = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const {
    settings,
    submit: { loading, error },
  } = useSelector(state => ({
    settings: state.department.permissions.data.data,
    submit: state.department.permissions.submit,
  }))

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        departmentPermissionsSubmit.submit({
          params,
          onSuccess,
          onError,
        })
      )
    },
    data => {
      props.onSuccess()
    }
  )

  const onDestroy = () => {
    dispatch(departmentPermissionsSubmit.cleanState())
    props.onDestroy && props.onDestroy()
  }

  const initialValues = useMemo(() => {
    return getInitialValues(settings)
  }, [settings])

  return (
    <DrawerContainer
      title={t("settings")}
      visible={props.visible}
      onClose={props.onClose}
      onDestroy={onDestroy}
    >
      <PermissionsDataLoader>
        <SettingsForm
          loading={loading}
          error={error}
          onSubmit={handleSubmit}
          onCancel={props.onClose}
          initialValues={{
            ...initialValues,
          }}
        />
      </PermissionsDataLoader>
    </DrawerContainer>
  )
}

Settings.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  onSuccess: PropTypes.func,
}
