import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Col, Row } from "reactstrap"
import * as Yup from "yup"
import { Button, ControllerHF, InputField } from "../../../../../components"
import { WEBSITE_DOMAIN_PATH } from "../../../../../consts"

export const CreateForm = props => {
  const { t } = useTranslation()

  const { control, handleSubmit, setError, reset, watch } = useForm({
    defaultValues: {
      domain: "",
      title: "",
      ...props.initialValues,
    },
    resolver: yupResolver(
      Yup.object().shape({
        domain: Yup.string().required("field.error.required"),
        title: Yup.string().required("field.error.required"),
      })
    ),
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
      website_theme_id: 1,
    }

    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }
  const domain = watch("domain")
  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <ControllerHF
              name={"title"}
              control={control}
              component={InputField}
              id={"title"}
              label={t("website-title")}
              placeholder={t("website-title")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <ControllerHF
              name={"domain"}
              control={control}
              component={InputField}
              id={"domain"}
              label={t("domain-field.label")}
              placeholder={t("domain-field.placeholder")}
            />
          </Col>
        </Row>
        <Row className={"mb-2"}>
          <div className={"d-flex"}>
            <div className={"mr-2"}>{t("your-domain")}:</div>
            <div className={"fw-medium text-primary-green"}>
              {domain ? domain : "[subdomain]"}
              {WEBSITE_DOMAIN_PATH}
            </div>
          </div>
        </Row>
        <p>{t("domain.warning-massage")}</p>
        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.set-subdomain"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button title={"cancel"} color="light" outline onClick={onCancel} />
          </div>
        </div>
      </form>
    </>
  )
}

CreateForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
