import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Col, Row } from "reactstrap"
import * as Yup from "yup"
import {
  Button,
  ComboboxFormField,
  ControllerHF,
  DateInputField,
  InputField,
} from "components"
import { PhotoSelectSection } from "./PhotoSelectSection"
import { SelectedPhotoSection } from "./SelectedPhotoSection"
import { SOCIAL_POST_METHOD_AUTO, SOCIAL_POST_TYPE_FACEBOOK } from "consts"
import { useFacebookAuth } from "packages/facebook"
import { DateUtils } from "utils"

/* prettier-ignore */
const mask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/]

export const FBPagePostForm = props => {
  const { t } = useTranslation()

  const { pages } = useFacebookAuth()

  const methods = useForm({
    defaultValues: {
      page: null,
      gender: "",
      model: null,
      photo: null,
      description: "",
      publication_date: null,
      ...props.initialValues,
    },
    resolver: yupResolver(
      Yup.object().shape({
        page: Yup.object()
          .nullable(true)
          .required("field.error.required-facebook-page"),
        photo: Yup.object()
          .nullable(true)
          .required("field.error.required-model-photo"),
        publication_date: Yup.date()
          .nullable(true)
          .required("field.error.required"),
      })
    ),
    mode: "onChange",
  })

  const { control, handleSubmit, watch, reset, setError } = methods

  const onSubmit = values => {
    let params = {
      method: !props.isEdit ? SOCIAL_POST_METHOD_AUTO : undefined,
      type: !props.isEdit ? SOCIAL_POST_TYPE_FACEBOOK : undefined,
      ////Editable params
      description: values.description,
      publication_date: DateUtils.localeToUTC(
        values.publication_date,
        "YYYY-MM-DD HH:mm"
      ),
      fashion_model_photo_id: values.photo.id,
      account_number: values.page.id,
      account_name: values.page.name,
    }

    props.onSubmit(params, {
      reset,
      setError,
    })
  }

  const onCancel = () => {
    props.onCancel()
  }
  const isSelectedPhoto = !!watch("photo")

  return (
    <FormProvider {...methods}>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={8} lg={6}>
            <ControllerHF
              name={"page"}
              control={control}
              component={ComboboxFormField}
              id={"page"}
              placeholder={t("fb-page")}
              label={t("fb-page")}
              options={pages}
              getOptionLabel={option => option.name}
              getOptionValue={option => option}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        {isSelectedPhoto ? (
          <SelectedPhotoSection postType={SOCIAL_POST_TYPE_FACEBOOK} />
        ) : (
          <PhotoSelectSection />
        )}

        <hr className="drawer-separator drawer-separator_form" />
        <Row>
          <Col md={8} lg={6}>
            <ControllerHF
              type={"textarea"}
              name={"description"}
              control={control}
              component={InputField}
              id={"description"}
              label={t("description")}
              placeholder={t("description")}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          <Col md={8} lg={6}>
            <ControllerHF
              name={"publication_date"}
              control={control}
              component={DateInputField}
              id={"publication_date"}
              label={t("publication-date")}
              placeholder={"DD/MM/YYYY hh:mm"}
              showTimeSelect
              dateFormat={"dd/MM/yyyy HH:mm"}
              mask={mask}
              translateParams={{
                nsSeparator: false,
              }}
            />
          </Col>
        </Row>

        <div className="mt-3 d-flex justify-content-between">
          <div className="button-items">
            <Button
              submit
              leftIcon={"bx bxl-facebook-circle"}
              color={"facebook"}
              title={!props.isEdit ? "publish-to-page" : "edit-post"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button title={"cancel"} color="light" outline onClick={onCancel} />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

FBPagePostForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  isEdit: PropTypes.bool,
}
