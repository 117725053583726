import {
    MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTO,
    MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTO_SUCCESS,
    MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTO_ERROR,
    MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTO_CLEAN_STATE,
} from './actionTypes';

export const modelPanelPhotosListRemovePhoto = (photo, onSuccess) => {
    return {
        type: MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTO,
        payload: { photo, onSuccess },
    }
};

export const modelPanelPhotosListRemovePhotoSuccess = (photo) => {
    return {
        type: MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTO_SUCCESS,
        payload: { photo },
    }
};

export const modelPanelPhotosListRemovePhotoError = (photo) => {
    return {
        type: MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTO_ERROR,
        payload: { photo },
    }
};

export const modelPanelPhotosListRemovePhotoCleanState = () => {
    return { type: MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTO_CLEAN_STATE };
};