import React, { useEffect } from "react"
import { Col, Row } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { MegaTagTitle } from "components"
import {
  CastingAndJobsContainer,
  EarnedMoneyContainer,
  EarningsComparisonContainer,
  TopRatingContainer,
} from "./components"
import {
  CASTINGS_AND_JOB,
  COMPARISON_BY_EARNINGS,
  EARNED_MONEY,
  OVERALL,
  TOP_TEN_CLIENTS_BY_EARNINGS,
  TOP_TEN_CLIENTS_BY_RATING,
  TOP_TEN_EMPLOYEES_BY_EARNINGS,
  TOP_TEN_EMPLOYEES_BY_RATING,
  TOP_TEN_MODELS_BY_EARNINGS,
  TOP_TEN_MODELS_BY_RATING,
} from "consts"
import { analyticsHelper } from "helpers"
import {
  analyticsGraphsList,
  analyticsPermissionsData,
  selectGraphsByTabName,
} from "store"

export const Overall = () => {
  const dispatch = useDispatch()
  const graphs = useSelector(state => selectGraphsByTabName(state, OVERALL))

  useEffect(() => {
    return () => {
      dispatch(analyticsGraphsList.cleanTabState({ tab: OVERALL }))
      dispatch(analyticsPermissionsData.cleanState())
    }
  }, [])

  const isAllowed = alias => {
    return analyticsHelper.permissions.hasGraph(graphs, alias)
  }

  const renderGraph = alias => {
    let graphData
    if (!isAllowed(alias)) {
      return null
    } else {
      graphData = analyticsHelper.permissions.getGraphConfig(graphs, alias)
    }
    switch (alias) {
      case COMPARISON_BY_EARNINGS: {
        return (
          <Col className={"mb-4"}>
            <EarningsComparisonContainer graphData={graphData} />
          </Col>
        )
      }
      case EARNED_MONEY: {
        return (
          <Col md={6} className={"mb-4"}>
            <EarnedMoneyContainer graphData={graphData} />
          </Col>
        )
      }
      case CASTINGS_AND_JOB: {
        return (
          <Col md={6} className={"mb-4"}>
            <CastingAndJobsContainer graphData={graphData} />
          </Col>
        )
      }
      case TOP_TEN_MODELS_BY_EARNINGS:
      case TOP_TEN_CLIENTS_BY_EARNINGS:
      case TOP_TEN_EMPLOYEES_BY_EARNINGS:
      case TOP_TEN_MODELS_BY_RATING:
      case TOP_TEN_CLIENTS_BY_RATING:
      case TOP_TEN_EMPLOYEES_BY_RATING: {
        return (
          <Col md={6} className={"mb-4"}>
            <TopRatingContainer graphData={graphData} />
          </Col>
        )
      }
    }
  }
  return (
    <div>
      <MegaTagTitle title={"overall"} />
      <Row>{renderGraph(COMPARISON_BY_EARNINGS)}</Row>
      <Row>
        {renderGraph(EARNED_MONEY)}
        {renderGraph(CASTINGS_AND_JOB)}
      </Row>
      <Row>
        {renderGraph(TOP_TEN_MODELS_BY_RATING)}
        {renderGraph(TOP_TEN_CLIENTS_BY_RATING)}
        {renderGraph(TOP_TEN_EMPLOYEES_BY_RATING)}
        {renderGraph(TOP_TEN_MODELS_BY_EARNINGS)}
        {renderGraph(TOP_TEN_CLIENTS_BY_EARNINGS)}
        {renderGraph(TOP_TEN_EMPLOYEES_BY_EARNINGS)}
      </Row>
    </div>
  )
}
