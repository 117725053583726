import { createAction } from "@reduxjs/toolkit"

const SOCKET_ON_BILLING_INFO_VAT_ID_STATUS_UPDATE =
  "socket/onBillingInfoVatIdStatusUpdate"

export const socketOnBillingInfoVatIdStatusUpdate = createAction(
  SOCKET_ON_BILLING_INFO_VAT_ID_STATUS_UPDATE,
  function prepare(data) {
    return {
      payload: {
        data,
      },
    }
  }
)
