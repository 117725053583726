import React, { useState } from "react"
import { useTranslation } from "react-i18next"

export const useGoTo = ({ calendarRef, ...props }) => {
  const { t } = useTranslation()

  const [datePickerVisible, setDatePickerVisible] = useState(false)

  const openDatePicker = e => {
    setDatePickerVisible(true)
  }

  const closeDatePicker = () => {
    setDatePickerVisible(false)
  }

  const onDatePickerVisibleToggle = () => {
    setDatePickerVisible(prevState => !prevState)
  }

  const onDatePickerChange = value => {
    closeDatePicker()

    if (value) {
      goTo(value)
    }
  }

  const goTo = date => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()
      calendarApi.gotoDate(date)
    }
  }

  const goToBtnConfig = {
    text: t("go_to"),
    click: openDatePicker,
  }

  return {
    goToBtnConfig,
    datePickerVisible,
    onDatePickerChange,
    onDatePickerVisibleToggle,
  }
}
