import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import i18n from '../../../i18n';
import { MODEL_FETCH_PROFILE } from './actionTypes';
import { modelFetchProfileSuccess, modelFetchProfileError } from './actions';
import { modelProfileSetData } from '../profile/actions';

import { API } from '../../../api';

export function* fetchProfile({ payload: { id } }) {
    try {
        const includes = [];

        const response = yield call(API.model.getModel, id, { includes });

        const { data } = response;

        yield put(modelFetchProfileSuccess());

        yield put(modelProfileSetData(data?.data));
    } catch (error) {
        const message = error.response?.data?.message || i18n.t('error');
        yield put(modelFetchProfileError(message));
    }
}

export function* watchProfileFetch() {
    yield takeEvery(MODEL_FETCH_PROFILE, fetchProfile)
}

function* loadSaga() {
    yield all([
        fork(watchProfileFetch),
    ]);
}

export default loadSaga;