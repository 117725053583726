import { GRAPH_PERIOD_TYPE } from "consts"
import moment from "moment"

/** @typedef {{date_from: string, date_to: string}} Params */

/**
 * @param {string} periodType
 * @param {Array<Date> | undefined} customRange
 * @return {{range: Array<Date>, params: Params}}
 * */

export const getPeriodData = (periodType, customRange) => {
  let params
  switch (periodType) {
    case GRAPH_PERIOD_TYPE.WEEK: {
      params = {
        date_from: moment().subtract(1, "weeks"),
        date_to: moment(),
      }
      break
    }
    case GRAPH_PERIOD_TYPE.MONTH: {
      params = {
        date_from: moment().subtract(1, "months"),
        date_to: moment(),
      }
      break
    }
    case GRAPH_PERIOD_TYPE.YEAR: {
      params = {
        date_from: moment().subtract(1, "years"),
        date_to: moment(),
      }
      break
    }
    case GRAPH_PERIOD_TYPE.CUSTOM: {
      if (customRange) {
        let [date_from, date_to] = customRange
        params = {
          date_from: moment(date_from),
          date_to: moment(date_to),
        }
      } else {
        params = {
          date_from: moment().subtract(1, "weeks"),
          date_to: moment(),
        }
      }
      break
    }
    default: {
      params = {}
    }
  }
  return {
    range: [params.date_from?.toDate(), params.date_to?.toDate()],
    params: formatParams(params),
  }
}

export const getComparisonPeriodData = (
  periodType,
  customBasePeriod,
  customComparisonPeriod
) => {
  let params
  switch (periodType) {
    case GRAPH_PERIOD_TYPE.WEEK: {
      params = {
        date_from: moment().subtract(1, "weeks"),
        date_to: moment(),
        comparison_date_from: moment().subtract(2, "weeks"),
        comparison_date_to: moment().subtract(1, "weeks"),
      }
      break
    }
    case GRAPH_PERIOD_TYPE.MONTH: {
      params = {
        date_from: moment().subtract(1, "months"),
        date_to: moment(),
        comparison_date_from: moment().subtract(2, "months"),
        comparison_date_to: moment().subtract(1, "months"),
      }
      break
    }
    case GRAPH_PERIOD_TYPE.YEAR: {
      params = {
        date_from: moment().subtract(1, "years"),
        date_to: moment(),
        comparison_date_from: moment().subtract(2, "years"),
        comparison_date_to: moment().subtract(1, "years"),
      }
      break
    }
    case GRAPH_PERIOD_TYPE.CUSTOM: {
      let [date_from, date_to] = customBasePeriod
      let [comparison_date_from, comparison_date_to] = customComparisonPeriod
      params = {
        date_from: moment(date_from),
        date_to: moment(date_to),
        comparison_date_from: moment(comparison_date_from),
        comparison_date_to: moment(comparison_date_to),
      }
      break
    }
    default: {
      params = {}
    }
  }

  return {
    range: [params.date_from?.toDate(), params.date_to?.toDate()],
    comparisonRange: [
      params.comparison_date_from?.toDate(),
      params.comparison_date_to?.toDate(),
    ],
    params: formatParams(params),
  }
}

export const formatParams = params => {
  Object.keys(params).map(key => {
    params[key] = params[key]?.format("YYYY-MM-DD")
  })
  return params
}
