import React from "react"
import PropTypes from "prop-types"
import { useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Col, Row } from "reactstrap"
import * as Yup from "yup"
import { Button, CheckboxField, ControllerHF } from "components"
import { analyticsHelper } from "helpers"

export const DepartmentsPermissionsForm = props => {
  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      departments: analyticsHelper.permissions.formInitialValues(
        props.departmentsOptions,
        props.permissions
      ),
    },
    resolver: yupResolver(Yup.object().shape({})),
    mode: "onChange",
  })
  const { fields } = useFieldArray({
    control,
    name: "departments",
    keyName: "fieldId",
  })
  const onSubmit = values => {
    let params = {
      department_ids: values.departments
        .filter(department => department.value === true)
        .map(department => {
          return department.id
        }),
    }
    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }
  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          {fields.map((item, index) => (
            <Col key={item.fieldId} md={12}>
              <ControllerHF
                name={`departments.${index}.value`}
                control={control}
                component={CheckboxField}
                id={`departments.${index}.value`}
                label={item.name}
                size={"lg"}
              />
            </Col>
          ))}
        </Row>
        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button title={"cancel"} color="light" outline onClick={onCancel} />
          </div>
        </div>
      </form>
    </>
  )
}

DepartmentsPermissionsForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  permissions: PropTypes.array,
  departmentsOptions: PropTypes.array,
}
