import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { EventDelete } from "../EventDelete"
import { EventClose } from "../EventClose"
import { EventReopen } from "../EventReopen"
import { EventDone } from "../EventDone"
import { Button } from "components"
import { useEventsCalendarContext } from "contexts"
import {
  EVENT_TYPE_JOB,
  EVENT_TYPE_NOTE,
  GENERAL_CALENDAR_ENTITY,
  DEPARTMENT_ACCOUNTING,
} from "consts"
import { eventsCalendarHelper } from "helpers"

const { MODEL: ENTITY_MODEL } = GENERAL_CALENDAR_ENTITY

export const PreviewSidebar = props => {
  const { t } = useTranslation()

  const { config: calendarConfig } = useEventsCalendarContext()

  const { data: user } = useSelector(state => state.user.profile)

  const isAdministrationMode =
    eventsCalendarHelper.calendar.isAdministrationMode(calendarConfig.mode)

  const eventClosed = useMemo(() => {
    return props.event.is_closed
  }, [props.event.close])

  // rename
  const isEventTypeJobState = useMemo(() => {
    return eventsCalendarHelper.calendar.isEventTypeJobState(
      props.event,
      props.entityScreen,
      props.entityId
    )
  }, [props.event, props.entityScreen, props.entityId])

  const closable = useMemo(() => {
    if (eventClosed) {
      return false
    }

    return isEventTypeJobState
  }, [props.event, props.entityScreen, props.entityId, eventClosed])

  const canCreateInvoice = useMemo(() => {
    if (props.event.invoice) {
      return false
    }

    return isEventTypeJobState
  }, [props.event, props.entityScreen, props.entityId])

  const canCreateContract = useMemo(() => {
    if (props.event.contract) {
      return false
    }

    return isEventTypeJobState
  }, [props.event, props.entityScreen, props.entityId])

  return (
    <>
      <div
        className={
          "d-flex flex-column justify-content-center align-items-center"
        }
      >
        <div className={"w-50"}>
          {isAdministrationMode &&
            user?.department?.alias === DEPARTMENT_ACCOUNTING &&
            eventClosed && (
              <div className={"mb-3"}>
                <EventReopen
                  id={props.event.id}
                  onSuccess={props.onEventReopenSuccess}
                />
              </div>
            )}

          {!isAdministrationMode && !eventClosed && (
            <>
              {props.event.type === EVENT_TYPE_NOTE && (
                <div className={"mb-3"}>
                  <EventDone
                    event={props.event}
                    onSuccess={props.onEventDoneSuccess}
                  />
                </div>
              )}

              <div className={"mb-3"}>
                <Button
                  title={"btn.edit"}
                  btnClass={"w-100"}
                  onClick={props.onEdit}
                />
              </div>
            </>
          )}

          {canCreateInvoice && (
            <div className={"mb-3"}>
              <Button
                title={"create_invoice"}
                btnClass={"w-100"}
                onClick={props.onEventInvoiceCreate}
              />
            </div>
          )}

          {canCreateContract && (
            <div className={"mb-3"}>
              <Button
                title={"create_contract"}
                btnClass={"w-100"}
                onClick={props.onEventContractCreate}
              />
            </div>
          )}

          {!isAdministrationMode && (
            <>
              <Button
                title={"send_email"}
                btnClass={"w-100"}
                onClick={props.onSendEmail}
              />

              {closable && (
                <div className={"mt-3"}>
                  <EventClose
                    id={props.event.id}
                    onSuccess={props.onEventCloseSuccess}
                  />
                </div>
              )}

              {!eventClosed && (
                <div className={"mt-4"}>
                  <EventDelete
                    id={props.event.id}
                    onSuccess={props.onRemoveSuccess}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

PreviewSidebar.propTypes = {
  event: PropTypes.object,
  onEdit: PropTypes.func,
  onSendEmail: PropTypes.func,
  onRemoveSuccess: PropTypes.func,
  entityScreen: PropTypes.string,
  entityId: PropTypes.number,
  onEventCloseSuccess: PropTypes.func,
  onEventReopenSuccess: PropTypes.func,
  onEventInvoiceCreate: PropTypes.func,
  onEventDoneSuccess: PropTypes.func,
  onEventContractCreate: PropTypes.func,
}
