import { createAction } from "@reduxjs/toolkit"

const SOCKET_ON_CLIENT_STATEMENT_ALERT = "socket/onClientStatementAlert"

export const socketOnClientStatementAlert = createAction(
  SOCKET_ON_CLIENT_STATEMENT_ALERT,
  function prepare(data) {
    return {
      payload: {
        data,
      },
    }
  }
)
