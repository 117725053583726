import { all } from "redux-saga/effects"

import profileSaga from "./profile/sagas"
import panelSaga from "./panel/sagas"
import historySaga from "./history/sagas"
import ratingSaga from "./rating/sagas"
import logoSaga from "./logo/sagas"
import addressesSaga from "./addresses/sagas"
import contactsSaga from "./contacts/sagas"
import attachmentsSaga from "./attachments/sagas"
import statementSaga from "./statement/sagas"

function* agencySaga() {
  yield all([
    profileSaga(),
    panelSaga(),
    historySaga(),
    ratingSaga(),
    logoSaga(),
    addressesSaga(),
    contactsSaga(),
    attachmentsSaga(),
    statementSaga(),
  ])
}

export default agencySaga
