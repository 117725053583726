import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { PageHeader } from "../../../../../components"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { CreateForm } from "./CreateForm"
import { ValidationsUtils } from "../../../../../utils"
import { websiteGeneralCreate } from "../../../../../store/actions"
import { connect, useDispatch } from "react-redux"

export const CreateWebsiteContainerComponent = props => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(websiteGeneralCreate.cleanState())
    }
  }, [])

  const onBack = () => {
    history.push("/website/intro")
  }
  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      websiteGeneralCreate.create({
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }
  const onCancel = () => {
    history.push("/website/intro/create-info")
  }
  return (
    <React.Fragment>
      <PageHeader title={t("create-website")} back onBack={onBack} />
      <CreateForm
        loading={props.loading}
        error={props.error}
        initialValues={{}}
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </React.Fragment>
  )
}

CreateWebsiteContainerComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
}

const mapStateToProps = state => {
  const { loading, error } = state.website.general.create
  return {
    loading,
    error,
  }
}

export const CreateWebsiteContainer = connect(mapStateToProps)(
  CreateWebsiteContainerComponent
)
