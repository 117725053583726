import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { ControllerHF, ComboboxFormField } from "components"
import { useFormContext } from "react-hook-form"

export const ClosedState = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const options = [
    {
      label: t("closed"),
      value: true,
    },
    {
      label: t("opened"),
      value: false,
    },
  ]

  return (
    <ControllerHF
      name={"is_closed"}
      control={control}
      component={ComboboxFormField}
      id={"events_closed_state"}
      label={t("state")}
      placeholder={t("state")}
      options={options}
      normalize={option => (option !== null ? option.value : null)}
      isDetermineValue
      selectProps={{
        isSearchable: false,
        isClearable: true,
      }}
      wrapClass={"mb-0"}
    />
  )
}

ClosedState.propTypes = {}
