import _ from "lodash"

import {
  MODEL_BOOK_PHOTOS_PICKER_ADD_ITEMS,
  MODEL_BOOK_PHOTOS_PICKER_CHANGE_ORDER,
  MODEL_BOOK_PHOTOS_PICKER_CLEAN_VALUE,
  MODEL_BOOK_PHOTOS_PICKER_REMOVE_ITEM,
  MODEL_BOOK_PHOTOS_PICKER_SET_COVER,
  MODEL_BOOK_PHOTOS_PICKER_SET_VALUE,
  MODEL_BOOK_PHOTOS_PICKER_TOGGLE_ITEM,
} from "./actionTypes"

const initialState = {
  picked: [],
}

const getPickedWithCover = picked => {
  if (picked.length > 0) {
    const filtered = picked.filter(item => item.is_cover)
    if (filtered.length === 0) {
      return [
        ...picked.map((item, index) =>
          index === 0 ? { ...item, is_cover: true } : { ...item }
        ),
      ]
    }
  }
  return [...picked]
}

const photosPicker = (state = initialState, action) => {
  switch (action.type) {
    case MODEL_BOOK_PHOTOS_PICKER_SET_VALUE:
      state = {
        ...state,
        picked: getPickedWithCover(action.payload),
      }
      break

    case MODEL_BOOK_PHOTOS_PICKER_CLEAN_VALUE:
      state = {
        ...state,
        ...initialState,
      }
      break

    case MODEL_BOOK_PHOTOS_PICKER_ADD_ITEMS:
      const concat = [...state.picked, ...action.payload]
      const result = _.uniqBy(concat, "photo.id")

      state = {
        ...state,
        picked: [...getPickedWithCover(result)],
      }
      break

    case MODEL_BOOK_PHOTOS_PICKER_REMOVE_ITEM:
      let items = state.picked.filter(
        item => item.photo.id !== action.payload.id
      )

      state = {
        ...state,
        picked: [...getPickedWithCover(items)],
      }
      break

    case MODEL_BOOK_PHOTOS_PICKER_TOGGLE_ITEM:
      const { data } = action.payload
      const { photo } = data
      let picked
      const filtered = state.picked.filter(item => item.photo.id !== photo.id)
      if (state.picked.length === filtered.length) {
        picked = [...state.picked, data]
      } else {
        picked = filtered
      }
      state = {
        ...state,
        picked: [...getPickedWithCover(picked)],
      }
      break

    case MODEL_BOOK_PHOTOS_PICKER_SET_COVER:
      state = {
        ...state,
        picked: [
          ...state.picked.map(item =>
            item.photo.id === action.payload.id
              ? { ...item, is_cover: true }
              : { ...item, is_cover: false }
          ),
        ],
      }
      break
    case MODEL_BOOK_PHOTOS_PICKER_CHANGE_ORDER:
      state = {
        ...state,
        picked: action.payload.data,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default photosPicker
