import _ from "lodash"

import i18n from "../../i18n"

import {
  MODEL_SERVICE_CATEGORY_APARTMENT,
  MODEL_SERVICE_CATEGORY_GYM,
  MODEL_SERVICE_CATEGORY_HAIRDRESSER,
  MODEL_SERVICE_CATEGORY_HOTEL,
  MODEL_SERVICE_CATEGORY_MAKEUP_BEAUTY,
  MODEL_SERVICE_CATEGORY_MULTIMEDIA,
  MODEL_SERVICE_CATEGORY_PUB_BAR,
  MODEL_SERVICE_CATEGORY_RESIDENCE,
  MODEL_SERVICE_CATEGORY_RESTAURANT,
  MODEL_SERVICE_CATEGORY_TRAVEL_AGENCY,
  MODEL_SERVICE_CATEGORY_OTHER,
} from "../../consts"

export const getServiceCategories = () => {
  return [
    {
      label: i18n.t("service_category.apartment"),
      value: MODEL_SERVICE_CATEGORY_APARTMENT,
    },
    {
      label: i18n.t("service_category.gym"),
      value: MODEL_SERVICE_CATEGORY_GYM,
    },
    {
      label: i18n.t("service_category.hairdresser"),
      value: MODEL_SERVICE_CATEGORY_HAIRDRESSER,
    },
    {
      label: i18n.t("service_category.hotel"),
      value: MODEL_SERVICE_CATEGORY_HOTEL,
    },
    {
      label: i18n.t("service_category.makeup_beauty"),
      value: MODEL_SERVICE_CATEGORY_MAKEUP_BEAUTY,
    },
    {
      label: i18n.t("service_category.multimedia"),
      value: MODEL_SERVICE_CATEGORY_MULTIMEDIA,
    },
    {
      label: i18n.t("service_category.pub_bar"),
      value: MODEL_SERVICE_CATEGORY_PUB_BAR,
    },
    {
      label: i18n.t("service_category.residence"),
      value: MODEL_SERVICE_CATEGORY_RESIDENCE,
    },
    {
      label: i18n.t("service_category.restaurant"),
      value: MODEL_SERVICE_CATEGORY_RESTAURANT,
    },
    {
      label: i18n.t("service_category.travel_agency"),
      value: MODEL_SERVICE_CATEGORY_TRAVEL_AGENCY,
    },
    {
      label: i18n.t("other"),
      value: MODEL_SERVICE_CATEGORY_OTHER,
    },
  ]
}

export const getServiceCategory = value => {
  const options = getServiceCategories()
  return _.find(options, { value: value })
}
