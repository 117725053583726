import React from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"
import { analyticsHelper } from "helpers"

export const PieChart = ({
  seriesData,
  optionsData,
  height = "280",
  loading,
  error,
  isData,
  labels,
}) => {
  const defaultOptions = {
    labels: !error && isData ? labels : [],
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.round(val) + " %"
      },
      dropShadow: {
        enabled: false,
      },
    },
    stroke: {
      show: false,
    },
    legend: {
      markers: {
        radius: 0,
        offsetX: "-7px",
      },
    },
    noData: analyticsHelper.options.noDataOptions(loading, error),
  }

  return (
    <ReactApexChart
      options={{ ...defaultOptions, ...optionsData }}
      series={seriesData}
      type={"pie"}
      height={height}
    />
  )
}

PieChart.propTypes = {
  optionsData: PropTypes.object,
  seriesData: PropTypes.array,
  height: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  isData: PropTypes.bool,
  labels: PropTypes.array,
}
