import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import classNames from "classnames"
import { useFieldArray, useWatch } from "react-hook-form"

import { ComboboxFormField, InputField } from "components/FormElements"
import { Link } from "react-router-dom"
import { IconButton } from "../../../Buttons"
import { ControllerHF } from "../../../ReactHookForm"

export const BooksNotesFieldArray = ({ books = [], ...props }) => {
  const { t } = useTranslation()

  const error = props?.meta?.error
  const fieldName = props.name

  const showError = Boolean(error)

  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: fieldName,
    keyName: "fieldId",
  })

  const watchValue = useWatch({
    control: props.control,
    name: fieldName,
  })

  const addRow = e => {
    e.preventDefault()

    if (props.clearErrors) {
      props.clearErrors(fieldName)
    }
    append({
      id: null,
      text: "",
    })
  }

  const removeRow = index => {
    remove(index)
  }

  const renderAdd = () => (
    <Row>
      <Col>
        <Link
          to="#"
          className="text-secondary text-decoration-underline"
          onClick={addRow}
        >
          <i className={classNames("align-middle mr-2 bx bx-plus")} />

          {t("add_books_notes")}
        </Link>
        <div className={"field-error-message"}>
          {error && t(error?.message)}
        </div>
      </Col>
    </Row>
  )

  const booksSelectedValues = useMemo(() => {
    const value = watchValue || []

    return value.map(item => item.id)
  }, [watchValue])

  const limit = useMemo(() => {
    return props.limit
  }, [props.limit])

  return (
    <Col>
      {fields.map((item, index) => (
        <Row key={item.fieldId}>
          <Col>
            <Row>
              <Col xs={10} lg={12}>
                <Row>
                  <Col lg={4}>
                    <ControllerHF
                      name={`${fieldName}.${index}.id`}
                      control={props.control}
                      component={ComboboxFormField}
                      id={`${fieldName}.${index}.id`}
                      label={t("book")}
                      placeholder={t("book")}
                      options={books}
                      getOptionLabel={option => `${option.title}`}
                      getOptionValue={option => option.id}
                      isDetermineValue
                      normalize={option => (option !== null ? option.id : null)}
                      menuPortalTarget={document.querySelector("body")}
                      selectProps={{
                        isOptionDisabled: (option, selectValue) => {
                          if (
                            booksSelectedValues &&
                            booksSelectedValues.length
                          ) {
                            if (
                              selectValue &&
                              selectValue.length &&
                              option.id === selectValue[0]?.id
                            ) {
                              return false
                            }

                            return booksSelectedValues.includes(option.id)
                          }
                          return false
                        },
                      }}
                    />
                  </Col>

                  <Col lg={4}>
                    <ControllerHF
                      name={`${fieldName}.${index}.text`}
                      control={props.control}
                      component={InputField}
                      id={`${fieldName}.${index}.text`}
                      label={t("note")}
                      placeholder={t("note")}
                      type="textarea"
                      rows={2}
                    />
                  </Col>

                  <Col lg={4} className={"d-none d-lg-block"}>
                    <div className={"d-flex"}>
                      <div className={"mb-3 form-action-button-block"}>
                        <IconButton
                          name={"remove"}
                          bg
                          onClick={() => removeRow(index)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col xs={2} className={"d-lg-none"}>
                <div className={"d-flex"}>
                  <div className={"mb-3 form-action-button-block"}>
                    <IconButton
                      name={"remove"}
                      bg
                      onClick={() => removeRow(index)}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ))}

      {limit ? fields.length < limit && renderAdd() : renderAdd()}
    </Col>
  )
}

BooksNotesFieldArray.propTypes = {
  name: PropTypes.string,
  limit: PropTypes.number,
  control: PropTypes.object,
  clearErrors: PropTypes.any,
  meta: PropTypes.any,
  setValue: PropTypes.func,
  books: PropTypes.array,
}
