import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import {
  EVENT_TYPE_NOTE,
  EVENT_TYPE_OUT,
  EVENT_TYPE_SCOUTING,
  EVENT_TYPE_GO_SEE,
  EVENT_TYPE_CASTING,
  EVENT_TYPE_FITTING,
  EVENT_TYPE_OPTION,
  EVENT_TYPE_JOB,
  EVENT_TYPE_ACCOMMODATION,
  EVENT_TYPE_ARRIVAL,
  EVENT_TYPE_TRAVEL,
  EVENT_TYPE_DEPARTURE,
} from "../../../consts"

import { EventNoteForm } from "./EventNoteForm"
import { EventOutForm } from "./EventOutForm"
import { EventScoutingForm } from "./EventScoutingForm"
import { EventGoSeeForm } from "./EventGoSeeForm"
import { EventCastingForm } from "./EventCastingForm"
import { EventFittingForm } from "./EventFittingForm"
import { EventOptionForm } from "./EventOptionForm"
import { EventJobForm } from "./EventJobForm"
import { EventAccommodationForm } from "./EventAccommodationForm"
import { EventTravelForm } from "./EventTravelForm"
import { EventArrivalForm } from "./EventArrivalForm"
import { EventDepartureForm } from "./EventDepartureForm"

export const EventForm = props => {
  const { t } = useTranslation()

  const type = props?.eventType

  switch (type) {
    case EVENT_TYPE_NOTE:
      return <EventNoteForm {...props} />
    case EVENT_TYPE_OUT:
      return <EventOutForm {...props} />
    case EVENT_TYPE_SCOUTING:
      return <EventScoutingForm {...props} />
    case EVENT_TYPE_GO_SEE:
      return <EventGoSeeForm {...props} />
    case EVENT_TYPE_CASTING:
      return <EventCastingForm {...props} />
    case EVENT_TYPE_FITTING:
      return <EventFittingForm {...props} />
    case EVENT_TYPE_OPTION:
      return <EventOptionForm {...props} />
    case EVENT_TYPE_JOB:
      return <EventJobForm {...props} />
    case EVENT_TYPE_DEPARTURE:
      return <EventDepartureForm {...props} />
    case EVENT_TYPE_TRAVEL:
      return <EventTravelForm {...props} />
    case EVENT_TYPE_ARRIVAL:
      return <EventArrivalForm {...props} />
    case EVENT_TYPE_ACCOMMODATION:
      return <EventAccommodationForm {...props} />
    default:
      return null
  }
}

EventForm.propTypes = {
  eventType: PropTypes.string,
}
