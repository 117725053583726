import { takeLatest, fork, put, all, call } from "redux-saga/effects"

import i18n from "../../../../../i18n"
import { API } from "../../../../../api"
import { calendarEventModelAttachmentList } from "./slice"

export function* getList({ payload: { event, model } }) {
  try {
    const response = yield call(
      API.calendar.getEventModelAttachments,
      event,
      model
    )

    const { data } = response

    yield put(calendarEventModelAttachmentList.getListSuccess(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(calendarEventModelAttachmentList.getListError(message))
  }
}

export function* watchGetList() {
  yield takeLatest(calendarEventModelAttachmentList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
