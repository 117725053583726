import { combineReducers } from "redux"

import add from "./add/reducer"
import list from "./list/reducers"
import state from "./state/slice"

const panelReducer = combineReducers({
  add,
  list,
  state,
})

export default panelReducer
