import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"
import { Row, Col } from "reactstrap"

import { ComboboxFormField, ControllerHF } from "components"
import { statementHelper } from "helpers"

const rows = new Array(2).fill(0).map((_, i) => i + 1)

export const AlertsFormSection = props => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <>
      {rows.map(number => (
        <React.Fragment key={number}>
          <h4>
            {t("alert")} {number}
          </h4>
          <Row>
            <Col md={6} lg={4}>
              <ControllerHF
                name={`alert_${number}_period`}
                control={control}
                component={ComboboxFormField}
                id={`alert_${number}_period`}
                label={t("reminder")}
                placeholder={t("reminder")}
                options={statementHelper.alert.getReminderConfigs()}
                selectProps={{
                  isClearable: true,
                  isSearchable: false,
                }}
                normalize={option => (option ? option.value : null)}
                isDetermineValue
              />
            </Col>
          </Row>
        </React.Fragment>
      ))}
    </>
  )
}

AlertsFormSection.propTypes = {}
