import React, { useEffect, useState, useRef } from 'react';
import PropTypes from "prop-types"
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import toastr from 'toastr';
import { useHistory } from "react-router-dom"

import { Modal } from "../../../../components";
import { QuestionnaireForm } from './QuestionnaireForm';
import { commonHelper } from "../../../../helpers"
import { userSettingUpdateData, userSettingUpdateDataCleanState } from '../../../../store/actions';
import i18n from "../../../../i18n"

const QuestionnaireComponent = (props) => {
    const { t } = useTranslation();
    const history = useHistory();

    const modalRef = useRef(null);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        if (props.user && !props.user?.settings && !props.loading) {
            showModal();
        }
    }, [props.user, props.loading]);

    useEffect(() => {
        return () => {
            if (modalVisible) {
                props.userSettingUpdateDataCleanState();
            }
        }
    }, [modalVisible]);

    const showModal = () => {
        if (modalRef.current) {
            modalRef.current.show();
        }
    }

    const closeModal = () => {
        if (modalRef.current) {
            modalRef.current.hide();
        }
    };

    const onSubmitSuccess = (onSubmitProps) => () => {
        const { setSubmitting } = onSubmitProps;
        setSubmitting(false);

        toastr.success(i18n.t('settings.saved.success'));

        closeModal();

        history.push('/calendar')
    }

    const onSubmitError = (onSubmitProps) => (errors) => {
        const { setErrors, setSubmitting } = onSubmitProps;
        setSubmitting(false);
        setErrors(errors);
    }

    const handleSubmit = (params, onSubmitProps) => {
        props.userSettingUpdateData(params, onSubmitSuccess(onSubmitProps), onSubmitError(onSubmitProps))
    }

    return (
        <Modal
            ref={modalRef}
            title={t('user-questionnaire-modal-title')}
            onVisibleChange={(value) => setModalVisible(value)}
            closable={false}
            strapModalProp={{
                centered: true,
            }}
        >
            <QuestionnaireForm
                loading={props.loading}
                error={props.error}
                initialValues={{
                    currency: commonHelper.currency.getCurrency(props.user.settings?.currency) || null,
                    measurement_system: commonHelper.measurements.getMeasurementSystem(props.user.settings?.measurement_system) || null,
                }}
                onSubmit={handleSubmit}
            />
        </Modal>
    );
};

QuestionnaireComponent.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.object,
    user: PropTypes.object,

    userSettingUpdateData: PropTypes.func,
    userSettingUpdateDataCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { loading, error } = state.user.settings.update;
    const { data } = state.user.profile;
    return {
        loading, error,
        user: data,
    };
};

const mapDispatchToProps = {
    userSettingUpdateData,
    userSettingUpdateDataCleanState,
};

export const Questionnaire = connect(mapStateToProps, mapDispatchToProps)(QuestionnaireComponent);