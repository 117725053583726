import _ from 'lodash';

import i18n from '../../i18n';

export const getReminderOptions = () => {
    return [{
        label: i18n.t('reminder.expiry-date'),
        value: 0,
    }, {
        label: i18n.t('reminder.days-before', { number: 2 }),
        value: 2,
    }, {
        label: i18n.t('reminder.days-before', { number: 5 }),
        value: 5,
    }, {
        label: i18n.t('reminder.days-before', { number: 7 }),
        value: 7,
    }, {
        label: i18n.t('reminder.days-before', { number: 10 }),
        value: 10,
    }, {
        label: i18n.t('reminder.days-before', { number: 15 }),
        value: 15,
    }, {
        label: i18n.t('reminder.days-before', { number: 30 }),
        value: 30,
    }, {
        label: i18n.t('reminder.days-before', { number: 60 }),
        value: 60,
    }, {
        label: i18n.t('reminder.days-before', { number: 90 }),
        value: 90,
    }, {
        label: i18n.t('reminder.days-before', { number: 120 }),
        value: 120,
    }];
};

export const getReminderOption = (value) => {
    const options = getReminderOptions();
    return _.find(options, { value: value });
};