import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

import { BILLING_PAYMENT_METHOD_TYPE } from "consts"
import { CardForm } from "./CardForm"
import { SepaContainer } from "./SepaForm"

const { CARD, SEPA_DEBIT } = BILLING_PAYMENT_METHOD_TYPE

export const StripeFormSection = props => {
  const { t } = useTranslation()

  switch (props.type) {
    case CARD:
      return (
        <CardForm
          stripeState={props.cardState}
          onDestroy={() => props.onStripeDestroy(CARD)}
          onChange={e => props.onStripeChange(CARD, e)}
          type={CARD}
        />
      )
    case SEPA_DEBIT:
      return (
        <SepaContainer
          stripeState={props.sepaState}
          onDestroy={() => props.onStripeDestroy(SEPA_DEBIT)}
          onChange={e => props.onStripeChange(SEPA_DEBIT, e)}
          type={SEPA_DEBIT}
        />
      )
    default:
      return null
  }
}

StripeFormSection.propTypes = {
  cardState: PropTypes.object,
  sepaState: PropTypes.object,
  type: PropTypes.string,
  onStripeChange: PropTypes.func,
  onStripeDestroy: PropTypes.func,
}
