import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { ToggleButton } from "components"
import { useLocation } from "react-router-dom"
import { websiteUpdateCurrentPageData } from "store"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { MEN_PAGE, WOMEN_PAGE } from "consts"

export const ChangePageStatus = props => {
  const { state: pageState } = useLocation()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handlePageStatusChange = () => {
    let successMessage = props.page.active
      ? t("page-deactivated")
      : t("page-activated")
    dispatch(
      websiteUpdateCurrentPageData.update({
        alias: props.page.alias,
        params: {
          active: !props.page.active,
        },
        successMessage,
      })
    )
  }

  const visible = useMemo(() => {
    if (props.page?.alias) {
      return !![MEN_PAGE, WOMEN_PAGE].includes(props.page?.alias)
    }

    return false
    // return props.page?.alias || pageState?.isPage
  }, [props.page?.alias, pageState?.isPage])

  return (
    <>
      {visible ? (
        <ToggleButton
          value={props.page ? props.page?.active : pageState?.active}
          trueTitle={"deactivate-page"}
          falseTitle={"activate-page"}
          onClick={handlePageStatusChange}
          disabled={props.loading}
          btnClass={"mr-2"}
        />
      ) : null}
    </>
  )
}

ChangePageStatus.propTypes = {
  page: PropTypes.any,
  loading: PropTypes.bool,
}
