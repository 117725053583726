import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"

import {
  ControllerHF,
  EditorField,
  FilePickerField,
  TabNavigator,
} from "components"
import { EDITOR_DRAFT_DOCX_TOOLBAR_OPTIONS, FILE_MAX_SIZE } from "consts"
import { FileUtils } from "utils"

export const TABS_KEY = {
  TEXT: "text",
  FILE: "file",
}

export const DocumentTextEntryControlPickerFormSection = ({
  value,
  onChange,
  editorProps,
  fileProps,
  ...props
}) => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <TabNavigator
      activeTab={value}
      onTabChange={onChange}
      tabBarType={"nav"}
      tabs={[
        {
          label: t("text"),
          value: TABS_KEY.TEXT,
          render: () => (
            <ControllerHF
              name={"text"}
              control={control}
              component={EditorField}
              id={"text"}
              label={t("text")}
              placeholder={t("text")}
              toolbar={{
                options: EDITOR_DRAFT_DOCX_TOOLBAR_OPTIONS,
              }}
              editorClassName={"wysiwyg-editor-editor_document-text"}
              {...editorProps}
            />
          ),
        },
        {
          label: t("document"),
          value: TABS_KEY.FILE,
          render: () => (
            <>
              <Row>
                <Col>{t("document-text-entry-file-panel-hint")}</Col>
              </Row>
              <Row>
                <Col sm={12} md={6} lg={4}>
                  <ControllerHF
                    name={"file"}
                    control={control}
                    component={FilePickerField}
                    fileNamePlaceholderText={t(
                      "file-picker.file-placeholder.contract"
                    )}
                    id={"file"}
                    pickerLabelText={t("attach-document")}
                    onDownload={FileUtils.downloadFileByLink}
                    accept={
                      "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    }
                    translateParams={{
                      text: `${FileUtils.formatBytes(FILE_MAX_SIZE)}`,
                    }}
                    attachedFileProps={{
                      color: "white",
                    }}
                    {...fileProps}
                  />
                </Col>
              </Row>
            </>
          ),
        },
      ]}
    />
  )
}

DocumentTextEntryControlPickerFormSection.propTypes = {
  value: PropTypes.oneOf([TABS_KEY.TEXT, TABS_KEY.FILE]),
  onChange: PropTypes.func,
  editorProps: PropTypes.object,
  fileProps: PropTypes.object,
}
