import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { EventCalendarIcon } from "../../EventCalendarIcon"

export const CalendarHolidayEventContent = ({ title, type }) => {
  return (
    <div className={classNames("calendar-event-content", {})}>
      <div className={"me-2"}>
        <EventCalendarIcon name={"cake"} className={"font-size-16"} />
      </div>

      <div className={"calendar-event-content__main"}>
        {title && <div>{title}</div>}
      </div>
    </div>
  )
}

CalendarHolidayEventContent.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
}
