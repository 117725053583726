import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import { packageStatisticsList } from "store/actions"
import { StatisticsList } from "./StatisticsList"
import { useModal } from "hooks"
import { ClicksViewModal } from "./ClicksViewModal"

const StatisticsContainerComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(packageStatisticsList.cleanState({}))
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [props.page, props.syncCount])

  const fetchData = () => {
    let params = {
      page: props.page,
    }

    dispatch(packageStatisticsList.getList({ params }))
  }

  const onPageChange = item => {
    dispatch(packageStatisticsList.setPage(item.selected + 1))
  }

  // Clicks view
  const { modalRef, closeModal, showModal, item: clicksData } = useModal()
  // ==== //

  return (
    <React.Fragment>
      <StatisticsList
        list={props.list}
        loading={props.listLoading}
        error={props.listError}
        meta={props.meta}
        page={props.page}
        onPageChange={onPageChange}
        onView={props.onPackageView}
        onClicksDetails={showModal}
      />

      <ClicksViewModal
        modalRef={modalRef}
        onCancel={closeModal}
        data={clicksData}
      />
    </React.Fragment>
  )
}

StatisticsContainerComponent.propTypes = {
  syncCount: PropTypes.number,
  onPackageView: PropTypes.func,

  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
}

const mapStateToProps = state => {
  const { list, listLoading, listError, meta, page } =
    state.package.statistics.list
  return {
    list,
    listLoading,
    listError,
    meta,
    page,
  }
}

const mapDispatchToProps = {}

export const StatisticsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticsContainerComponent)
