import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "../../../../../../i18n"

import { MODEL_PANEL_GENERAL_LOGISTICS_LIST_REMOVE_ITEM } from "./actionTypes"
import {
  modelPanelGeneralLogisticsListRemoveItemSuccess,
  modelPanelGeneralLogisticsListRemoveItemError,
} from "./actions"

import { API } from "../../../../../../api"

function* removeLogistics({ payload: { id, onSuccess } }) {
  try {
    const response = yield call(API.model.removeModelLogistics, id)

    const { data } = response

    yield put(modelPanelGeneralLogisticsListRemoveItemSuccess(id))
    toastr.success(i18n.t("model-logistics.remove.success"))

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")

    yield put(modelPanelGeneralLogisticsListRemoveItemError(id))

    toastr.error(message)
  }
}

export function* watchRemoveLogistics() {
  yield takeEvery(
    MODEL_PANEL_GENERAL_LOGISTICS_LIST_REMOVE_ITEM,
    removeLogistics
  )
}

function* removeItemSaga() {
  yield all([fork(watchRemoveLogistics)])
}

export default removeItemSaga
