import {
    PACKAGE_EDIT_DATA,
    PACKAGE_EDIT_DATA_SUCCESS,
    PACKAGE_EDIT_DATA_ERROR,
    PACKAGE_EDIT_DATA_CLEAN_STATE,
} from './actionTypes';

export const packageEditData = (id, params, onSuccess, onError) => {
    return {
        type: PACKAGE_EDIT_DATA,
        payload: { id, params, onSuccess, onError },
    }
};

export const packageEditDataSuccess = () => {
    return {
        type: PACKAGE_EDIT_DATA_SUCCESS,
    }
};

export const packageEditDataError = (data) => {
    return {
        type: PACKAGE_EDIT_DATA_ERROR,
        payload: data,
    }
};

export const packageEditDataCleanState = () => {
    return { type: PACKAGE_EDIT_DATA_CLEAN_STATE };
};