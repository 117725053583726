import { createAction } from "@reduxjs/toolkit"

const SOCKET_ON_IMPORT_DATA_UPDATE = "socket/onImportDataUpdate"

export const socketOnImportDataUpdate = createAction(
  SOCKET_ON_IMPORT_DATA_UPDATE,
  function prepare(data) {
    return {
      payload: {
        data,
      },
    }
  }
)
