import React, { useMemo } from 'react';
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom"

import { Nav } from '../Nav';
import { PageHeader, RatingBadge } from '../../../../../../../components';
import { usePanelTopBarContext } from '../../../../../../../contexts';

export const HeaderComponent = (props) => {
    const { t } = useTranslation();
    const history = useHistory();

    const { options } = usePanelTopBarContext();

    const title = useMemo(() => {
        if (props.agency) {
            return `${props.agency?.name}`
        }
        return null;
    }, [props.agency])

    const onBack = () => {
        history.push('/agencies');
    }

    return (
        <div className={'panel-topbar'}>
            <PageHeader
                title={title}
                back
                onBack={onBack}
                headerLeft={(
                    props?.agency?.avg_rating && (
                        <RatingBadge value={props.agency.avg_rating} size={'md'} wrapClassName={'ms-3'} />
                    )
                )}
            />

            <div className={'panel-topbar__nav mb-4'}>
                <div className={'panel-topbar__menu'}>
                    <Nav />
                </div>

                {options.actions && (
                    <div className="panel-topbar__actions">
                        {options.actions}
                    </div>
                )}
            </div>
        </div>
    );
};

HeaderComponent.propTypes = {
    agency: PropTypes.object,
    topBarOptions: PropTypes.object,
}

const mapStateToProps = state => {
    const { data } = state.agency.profile.data;
    return {
        agency: data,
    };
};

const mapDispatchToProps = {
};

export const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);