import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { Col, Row } from "reactstrap"
import { ControllerHF } from "../../../../ReactHookForm"
import { InputField } from "../../../../FormElements"

export const TitleInfoSection = props => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <Row>
      <Col md={12}>
        <ControllerHF
          name={"title"}
          control={control}
          component={InputField}
          id={"title"}
          label={t("title")}
          placeholder={t('title')}
        />
      </Col>

      <Col md={12}>
        <ControllerHF
          name={"description"}
          control={control}
          component={InputField}
          id={"description"}
          label={t("description")}
          placeholder={t('description')}
          type={'textarea'}
        />
      </Col>
    </Row>
  )
}

TitleInfoSection.propTypes = {}
