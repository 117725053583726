import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { SOCIAL_POST_TYPE_FACEBOOK, SOCIAL_POST_TYPE_INSTAGRAM } from "consts"

export const PostCategory = ({
  data,
  draggableSelector,
  onPostCategoryClick,
}) => {
  return (
    <div
      className={classNames("post-category", {
        [draggableSelector]: draggableSelector,
      })}
      style={{ background: data.backgroundColor, color: data.textColor }}
      data-event-type={data.type}
      draggable
      onClick={() => onPostCategoryClick(data)}
    >
      <i
        className={classNames("align-middle mr-2 bx  font-size-16", {
          "bxl-instagram": data.type === SOCIAL_POST_TYPE_INSTAGRAM,
          "bxl-facebook-circle": data.type === SOCIAL_POST_TYPE_FACEBOOK,
        })}
      />
      {data.title}
    </div>
  )
}

PostCategory.propTypes = {
  data: PropTypes.object,
  onPostCategoryClick: PropTypes.func,
  draggableSelector: PropTypes.string,
}
