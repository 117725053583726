import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Alert } from "reactstrap"
import { useTranslation } from "react-i18next"

import { BOOK, PACKAGE } from "../consts"
import { API } from "../../../api"
import { useFetchData } from "hooks"
import * as HOC from "../../../HOC"

const DataShower = HOC.withDataShower()

export const SharedEntityDataLoader = props => {
  const { t } = useTranslation()

  const { loading, error, data, fetchData } = useFetchData()

  useEffect(() => {
    onFetchData()
  }, [])

  const onFetchData = async () => {
    const request = API.website.getWebsiteData()
    await fetchData({
      request,
    })
  }

  const entityTitle = props.entity === BOOK ? t("book-lc") : t("package-lc")

  return (
    <DataShower isLoading={loading} isFailed={error} error={error?.message}>
      {data?.data?.created ? (
        props.children
      ) : (
        <Alert color={"secondary"}>
          {t("shared-entity.warning.website", { sharedEntity: entityTitle })}
        </Alert>
      )}
    </DataShower>
  )
}

SharedEntityDataLoader.propTypes = {
  entity: PropTypes.oneOf([BOOK, PACKAGE]).isRequired,
  children: PropTypes.any,
}
