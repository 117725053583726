import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"

import { PanelSidebar } from "../../../../../../components"
import { ModelsSidebar } from "../../ModelsSidebar"
import { modelPanelModelsSidebarListResetFetchedData } from "../../../../../../store/actions"

export const Sidebar = () => {
  const dispatch = useDispatch()

  const [mounted, setMounted] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)

  useEffect(() => {
    setMounted(true)
    return () => {
      dispatch(modelPanelModelsSidebarListResetFetchedData())
    }
  }, [])

  const toggleDrawerVisible = forceStatus => {
    if (forceStatus !== undefined) {
      setDrawerVisible(forceStatus)
    } else {
      setDrawerVisible(prevStatus => !prevStatus)
    }
  }

  return (
    <PanelSidebar
      className={"models-panel-sidebar"}
      drawerClassName={"models-panel-sidebar"}
      drawerVisible={drawerVisible}
      toggleDrawerVisible={toggleDrawerVisible}
    >
      {mounted && (
        <ModelsSidebar
          drawerVisible={drawerVisible}
          toggleDrawerVisible={toggleDrawerVisible}
        />
      )}
    </PanelSidebar>
  )
}

Sidebar.propTypes = {}
