import React, { useCallback, useRef } from "react"
import PropTypes from "prop-types"

import { Logo } from "./Logo"
import { Information } from "./Information"
import { InvoiceDetails } from "./InvoiceDetails"
import { ExpensesTable } from "./ExpensesTable"
import { Summary } from "./Summary"
import { Notes } from "./Notes"
import { Actions } from "./Actions"
import { usePrintContent } from "hooks"
import { FileUtils } from "utils"

export const InvoiceCard = props => {
  let printContentRef = useRef(null)

  const printContent = useCallback(() => {
    return printContentRef.current
  }, [printContentRef.current])

  const { print } = usePrintContent({
    content: printContent,
    onAfterPrint: null,
  })

  const onDownload = () => {
    FileUtils.downloadFileByLink(props.data?.pdf_url)
  }

  return (
    <>
      <div className={"calendar-event-invoice-view-card"} ref={printContentRef}>
        <Logo logo={props.data?.logo} />
        <Information data={props.data} />
        <InvoiceDetails data={props.data} />
        <ExpensesTable data={props.data} />
        <Summary data={props.data} />
        <Notes data={props.data} />
      </div>

      <Actions onPrint={print} onDownload={onDownload} />
    </>
  )
}

InvoiceCard.propTypes = {
  data: PropTypes.object,
}
