import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../../../../i18n';
import { MODEL_PANEL_PHOTOS_PHOTO_DETAILS_EDIT_PHOTO_UPDATE } from './actionTypes';
import {
    modelPanelPhotosPhotoDetailsEditPhotoUpdateSuccess,
    modelPanelPhotosPhotoDetailsEditPhotoUpdateError,
} from './actions';

import { API } from '../../../../../../api';

function* editPhoto({ payload: { attachmentId, params, onSuccess, onError } }) {
    try {
        const response = yield call(API.model.updateModelPhoto, attachmentId, params);

        const { data } = response;

        yield put(modelPanelPhotosPhotoDetailsEditPhotoUpdateSuccess());

        toastr.success(i18n.t('edit.success'));

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        const errors = error.response?.data?.errors || {};
        const message = error.response?.data?.message || i18n.t('error');

        yield put(modelPanelPhotosPhotoDetailsEditPhotoUpdateError({
            errors,
            message
        }));

        if (onError) {
            onError(errors);
        }
    }
}

export function* watchEditPhoto() {
    yield takeEvery(MODEL_PANEL_PHOTOS_PHOTO_DETAILS_EDIT_PHOTO_UPDATE, editPhoto);
}

function* editSaga() {
    yield all([
        fork(watchEditPhoto),
    ]);
}

export default editSaga;