import React, { useMemo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Col, Alert, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import {
  Button,
  ControllerHF,
  InputField,
  SubscriptionAccessAlert,
} from "components"
import { validationSchema } from "./validation"
import { SharedLink } from "./SharedLink"
import {
  PACKAGE_FORM_SUBMIT_TYPE_SAVE,
  PACKAGE_FORM_SUBMIT_TYPE_SEND,
} from "consts"

export const PackageForm = props => {
  const { t } = useTranslation()

  const [submitType, setSubmitType] = useState(PACKAGE_FORM_SUBMIT_TYPE_SAVE)

  const { control, handleSubmit, setError, reset, getValues } = useForm({
    defaultValues: {
      title: "",
      description: "",
      ...props.initialValues,
    },
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    return () => {
      if (props.onDestroy) {
        const values = getValues()
        props.onDestroy(values)
      }
    }
  }, [])

  const onReset = () => {
    reset()
    setSubmitType(PACKAGE_FORM_SUBMIT_TYPE_SAVE)
  }

  const isDisabled = useMemo(() => {
    const { books, videos } = props.dataPicker
    return books.length === 0 && videos.length === 0
  }, [props.dataPicker])

  const onSubmit = (values, type) => {
    const params = {
      ...values,
    }

    const { books, videos } = props.dataPicker
    params.books = books.map(item => item.id)
    params.videos = videos.map(item => item.id)

    setSubmitType(type)

    props.onSubmit(params, { onReset, setError, submitType: type })
  }

  const handleSend = () => {
    handleSubmit(data => onSubmit(data, PACKAGE_FORM_SUBMIT_TYPE_SEND))()
  }

  return (
    <>
      {props.error ? (
        props.error?.forbidden ? (
          <SubscriptionAccessAlert message={`${props.error.message}.`} />
        ) : (
          <Alert color="danger">{props.error.message}</Alert>
        )
      ) : null}

      <form
        onSubmit={handleSubmit(data =>
          onSubmit(data, PACKAGE_FORM_SUBMIT_TYPE_SAVE)
        )}
      >
        <Row>
          <Col md={12}>
            <ControllerHF
              name={"title"}
              control={control}
              component={InputField}
              id={"title"}
              label={t("title")}
              placeholder={t("title")}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <ControllerHF
              name={"description"}
              control={control}
              component={InputField}
              id={"description"}
              label={t("description")}
              placeholder={t("description")}
              type={"textarea"}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={12} className={"col-xxl-6"}>
            <Button
              submit
              title={"btn.save-package"}
              disabled={props.loading || isDisabled}
              loading={
                props.loading && submitType === PACKAGE_FORM_SUBMIT_TYPE_SAVE
              }
              btnClass={"w-100"}
            />
          </Col>

          <Col xl={12} className={"col-xxl-6"}>
            <Button
              onClick={handleSend}
              title={"btn.save-send-package"}
              disabled={props.loading || isDisabled}
              loading={
                props.loading && submitType === PACKAGE_FORM_SUBMIT_TYPE_SEND
              }
              btnClass={"w-100 mt-3 mt-xxl-0"}
            />
          </Col>
        </Row>

        {props.isEdit && (
          <Row>
            <Col xl={12} className={"col-xxl-12"}>
              <SharedLink
                id={props.id}
                disabled={props.loading || isDisabled}
                btnClass={"w-100 mt-3"}
              />
            </Col>
          </Row>
        )}
      </form>
    </>
  )
}

PackageForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  dataPicker: PropTypes.object,
  isEdit: PropTypes.bool,
  id: PropTypes.number,

  onSubmit: PropTypes.func,
  onDestroy: PropTypes.func,
}
