import {
    MODEL_PANEL_RATING_REMOVE_RATING,
    MODEL_PANEL_RATING_REMOVE_RATING_SUCCESS,
    MODEL_PANEL_RATING_REMOVE_RATING_ERROR,
    MODEL_PANEL_RATING_REMOVE_RATING_CLEAN_STATE,
} from './actionTypes';

const initialState = {
    pending: [],
};

const remove = (state = initialState, action) => {
    switch (action.type) {
        case MODEL_PANEL_RATING_REMOVE_RATING:
            state = {
                ...state,
                pending: [...state.pending, action.payload.id],
            };
            break;

        case MODEL_PANEL_RATING_REMOVE_RATING_SUCCESS:
            state = {
                ...state,
                pending: state.pending.filter((item) => item !== action.payload.id),
            };
            break;

        case MODEL_PANEL_RATING_REMOVE_RATING_ERROR:
            state = {
                ...state,
                pending: state.pending.filter((item) => item !== action.payload.id),
            };
            break;

        case MODEL_PANEL_RATING_REMOVE_RATING_CLEAN_STATE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default remove;