import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Alert, Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import moment from "moment"
import { useDeepCompareEffect } from "react-use"

import { Button, DateRangePickerField, ControllerHF } from "components"
import { validationSchema } from "./validation"
import { commonHelper } from "helpers"
import * as HOC from "HOC"

const DataShower = HOC.withDataShowerOverlay()

const currencySymbol = commonHelper.currency.getGeneralCurrency()?.symbol

export const PaymentForm = props => {
  const { t } = useTranslation()

  const methods = useForm({
    defaultValues: {
      period: [],
      ...props.initialValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const { control, handleSubmit, setError, reset, watch } = methods

  const watchPeriod = watch("period")

  // Fetch amount
  useDeepCompareEffect(() => {
    props.onPeriodChange(watchPeriod)
  }, [watchPeriod])
  // === //

  const isDisabled = useMemo(() => {
    return props.loading
  }, [props.loading])

  const onSubmit = values => {
    const { period } = values
    const [date_start, date_end] = period

    let params = {
      date_start: moment(date_start).format("YYYY-MM-DD"),
      date_end: moment(date_end).format("YYYY-MM-DD"),
    }

    props.onSubmit(params, { setError })
  }

  return (
    <FormProvider {...methods}>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <ControllerHF
              name={"period"}
              control={control}
              component={DateRangePickerField}
              id={"period"}
              label={t("period")}
              placeholder={t("select-period")}
              isClearable={false}
            />
          </Col>
        </Row>

        <Row className={"mt-4 mb-3"}>
          <Col>
            <DataShower
              isLoading={props.amountLoading}
              isFailed={props.amountError}
              error={props.amountError?.message}
            >
              <h5>
                {t("amount")}:{" "}
                {props.amount !== null
                  ? `${props.amount} ${currencySymbol}`
                  : "-"}
              </h5>
            </DataShower>
          </Col>
        </Row>

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"pay"}
              disabled={isDisabled}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

PaymentForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onPeriodChange: PropTypes.func,
  amount: PropTypes.any,
  amountLoading: PropTypes.bool,
  amountError: PropTypes.object,
}
