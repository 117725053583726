import React, { useReducer } from "react"

import { SCREEN } from "../../consts"
import { reducers, actions } from "./store"

export const useEventInvoiceManager = ({ initialState = null }) => {
  const [state, dispatch] = useReducer(
    reducers.reducer,
    initialState,
    reducers.reducerInit
  )

  const setState = (data = null) => {
    dispatch(actions.setState(data))
  }

  const resetInvoice = () => {
    dispatch(actions.resetInvoice(initialState))
  }

  // Navigation
  const navigate = (screen, payload = null) => {
    setState({
      screen,
      ...payload,
    })
  }

  const onScreenClose = () => {
    navigate(null)
  }

  const onScreenCreate = () => {
    navigate(SCREEN.CREATE)
  }

  const onScreenEdit = () => {
    navigate(SCREEN.EDIT)
  }

  const onScreenView = () => {
    navigate(SCREEN.VIEW)
  }
  // === //

  return {
    state,
    actions: {
      resetInvoice,
    },
    navigation: {
      navigate,
      onScreenClose,
      onScreenCreate,
      onScreenEdit,
      onScreenView,
    },
  }
}
