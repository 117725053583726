import { createSlice } from "@reduxjs/toolkit"

/**
 * @typedef Graph
 * @property {string} id - an ID of graph.
 * @property {string} name - name of graph.
 * @property {string} alias - alias of graph.
 * @property {GraphStats} stats - stats of graph.
 */

/**
 * @typedef GraphStats
 * @property {boolean} loading - loading status.
 * @property {*} error - error.
 * @property {object} data - stats data of graph.
 */

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  isFetched: false,
  page: 1,
  meta: null,
}

const analyticsGraphsListSlice = createSlice({
  name: "analyticsGraphsList",
  initialState,
  reducers: {
    getList(state) {
      state.listLoading = true
      state.listError = null
    },
    getListCompleted(state, action) {
      state.listLoading = false
      state.isFetched = true
      state.list = action.payload.data
      /** Set initial state to {@link GraphStats} of {@link Graph}*/
      state.list = state.list.map(graph => {
        return {
          ...graph,
          stats: {
            loading: true,
            data: null,
          },
        }
      })
      state.meta = action.payload.meta
    },
    getListRejected(state, action) {
      state.listLoading = false
      state.listError = action.payload
    },
    getGraphStatsData(state, { payload: { alias, tab } }) {
      state.list = state.list.map(graph => {
        if (graph.alias === alias && graph.tab === tab) {
          return {
            ...graph,
            stats: {
              ...graph.stats,
              loading: true,
              error: null,
            },
          }
        } else return graph
      })
    },
    getGraphStatsDataCompleted(state, { payload: { alias, tab, data } }) {
      state.list = state.list.map(graph => {
        if (graph.alias === alias && graph.tab === tab) {
          return {
            ...graph,
            stats: {
              ...graph.stats,
              loading: false,
              data,
            },
          }
        } else return graph
      })
    },
    getGraphStatsDataRejected(state, { payload: { alias, tab, error } }) {
      state.list = state.list.map(graph => {
        if (graph.alias === alias && graph.tab === tab) {
          return {
            ...graph,
            stats: {
              ...graph.stats,
              loading: false,
              error,
              data: null,
            },
          }
        } else return graph
      })
    },
    cleanTabState(state, { payload: { tab } }) {
      state.list = state.list.map(graph => {
        if (graph.tab === tab) {
          return {
            ...graph,
            stats: {
              loading: true,
              data: null,
            },
          }
        } else return graph
      })
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getList
 * @property {function} getListCompleted
 * @property {function} getListRejected
 *  @property {function} setPage
 *  @property {function} getGraphStatsData
 *  @property {function} getGraphStatsDataCompleted
 *  @property {function} getGraphStatsDataRejected
 *  @property {function} cleanTabState
 *  @property {function} cleanState
 */
export const analyticsGraphsList = analyticsGraphsListSlice.actions

export default analyticsGraphsListSlice.reducer
