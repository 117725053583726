import { combineReducers } from "redux";

import dataPicker from './dataPicker/reducer';
import form from './form/reducer';

const dataReducer = combineReducers({
    dataPicker,
    form,
});

export default dataReducer;