import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { MegaTagTitle } from "components"
import { ContactsContainer } from "./components"

export const Contacts = props => {
  return (
    <div className={"website-contacts-panel"}>
      <MegaTagTitle title={"title.website.contacts"} />
      <Row>
        <Col>
          <ContactsContainer alias={props.alias} />
        </Col>
      </Row>
    </div>
  )
}

Contacts.propTypes = {
  alias: PropTypes.string,
}
