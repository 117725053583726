import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const calendarEventCloseSlice = createSlice({
  name: "calendarEventClose",
  initialState,
  reducers: {
    close(state) {
      state.loading = true
      state.error = null
    },
    closeSuccess(state) {
      state.loading = false
    },
    closeError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} close
 * @property {function} closeSuccess
 * @property {function} closeError
 * @property {function} cleanState
 */
export const calendarEventClose = calendarEventCloseSlice.actions

export default calendarEventCloseSlice.reducer
