import React, { useEffect, useCallback } from "react"

import { useEcho } from "hooks"

export const useEchoSubscriptionEffect = ({ channel, events }) => {
  const echo = useEcho()

  const stopListen = useCallback(() => {
    if (echo.init) {
      let eventsNames = []

      Object.entries(events).forEach(entry => {
        const [event] = entry
        eventsNames.push(event)
      })

      echo.privateChannelStopListeners(channel, eventsNames)
    }
  }, [echo.init, events])

  useEffect(() => {
    if (echo.init) {
      echo.privateChannelAddListeners(channel, events)
    }

    return () => {
      if (echo.init) {
        stopListen()
      }
    }
  }, [echo.init])
}
