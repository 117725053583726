import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { billingPaymentMethodDefault } from "./slice"
import { billingPaymentMethodList } from "../list/slice"

function* sendDefault({ payload: { id } }) {
  try {
    const params = {
      payment_method: id,
    }

    const response = yield call(API.billing.sendDefaultPaymentMethod, params)
    const { data } = response

    yield put(billingPaymentMethodDefault.sendSuccess())
    toastr.success(i18n.t(`billing.payment_method.send_default.success`))

    yield put(billingPaymentMethodList.setDefault({ id }))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      billingPaymentMethodDefault.sendError({
        errors,
        message,
      })
    )

    toastr.error(message)
  }
}

export function* watchSendDefault() {
  yield takeEvery(billingPaymentMethodDefault.send, sendDefault)
}

function* defaultSaga() {
  yield all([fork(watchSendDefault)])
}

export default defaultSaga
