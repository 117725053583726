import React, { useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import { PaymentMethodsTable } from "./PaymentMethodsTable"
import { ConfirmPopup, InfoCardShower, TabNavigator } from "components"
import { useConfirmModal, useDrawer } from "hooks"
import { AddPaymentMethod } from "./AddPaymentMethod"
import {
  billingPaymentMethodList,
  billingPaymentMethodRemove,
  billingPaymentMethodDefault,
} from "store/actions"
import { BILLING_PAYMENT_METHOD_TYPE } from "consts"
import { middlewareHelper } from "helpers"

const isPreviewMode = middlewareHelper.previewMode.isAllow()

const { CARD, SEPA_DEBIT } = BILLING_PAYMENT_METHOD_TYPE

const TABS = [
  {
    label: "card",
    value: CARD,
    active: true,
  },
  {
    label: "sepa",
    value: SEPA_DEBIT,
    active: isPreviewMode,
  },
]

const PaymentCardsContainerComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  // TABS
  const [activeTab, setActiveTab] = useState(CARD)

  const onTabChange = tab => {
    setActiveTab(tab)
  }

  const mapPaymentMethods = useCallback(
    type => props.list.filter(item => item.type === type),
    [props.list]
  )
  // === //

  //============================ADD LOGIC =================================//
  const {
    hide: hideAddDrawer,
    show: showAddDrawer,
    visible: addDrawerVisible,
  } = useDrawer()

  const onAddSuccess = () => {
    fetchList()
  }

  //============================== LIST DATA LOGIC ========================//
  useEffect(() => {
    fetchList()

    return () => {
      dispatch(billingPaymentMethodList.cleanState())
      dispatch(billingPaymentMethodRemove.cleanState())
      dispatch(billingPaymentMethodDefault.cleanState())
    }
  }, [])

  const fetchList = () => {
    dispatch(billingPaymentMethodList.getList())
  }
  //========================================================================//

  //======================= REMOVE LOGIC ==============================//
  const { modalRef, showModal, closeModal, handleConfirm } = useConfirmModal(
    item => {
      const { id } = item
      dispatch(billingPaymentMethodRemove.remove({ id, onSuccess: fetchList }))
    },
    () => {}
  )
  // === //

  const onDefault = item => {
    const { id } = item
    dispatch(billingPaymentMethodDefault.send({ id }))
  }

  const isLoading = useMemo(() => {
    return props.listLoading || props.removeLoading || props.sendDefaultLoading
  }, [props.listLoading, props.removeLoading, props.sendDefaultLoading])

  return (
    <>
      <InfoCardShower
        title={"payment_methods"}
        addMode
        onAdd={showAddDrawer}
        items={props.list}
        loading={isLoading}
        error={props.listError}
      >
        <TabNavigator
          activeTab={activeTab}
          onTabChange={onTabChange}
          tabBarType={"nav"}
          tabs={TABS.filter(tab => tab.active).map(item => ({
            label: t(item.label),
            value: item.value,
            render: () => (
              <PaymentMethodsTable
                loading={isLoading}
                list={mapPaymentMethods(item.value)}
                onRemove={showModal}
                onDefault={onDefault}
                type={item.value}
              />
            ),
          }))}
        />
      </InfoCardShower>

      <AddPaymentMethod
        visible={addDrawerVisible}
        onClose={hideAddDrawer}
        onSuccess={onAddSuccess}
        paymentMethodType={activeTab}
      />

      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
      />
    </>
  )
}

PaymentCardsContainerComponent.propTypes = {
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  removeLoading: PropTypes.bool,
  sendDefaultLoading: PropTypes.bool,
}

const mapStateToProps = state => {
  const { list, listLoading, listError } = state.billing.paymentMethod.list
  const { loading: removeLoading } = state.billing.paymentMethod.remove
  const { loading: sendDefaultLoading } = state.billing.paymentMethod.default

  return {
    list,
    listLoading,
    listError,
    removeLoading,
    sendDefaultLoading,
  }
}

export const PaymentMethodsContainer = connect(mapStateToProps)(
  PaymentCardsContainerComponent
)
