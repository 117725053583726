import { all } from 'redux-saga/effects';

import addSaga from './add/saga';
import listSaga from './list/sagas';
import detailsSaga from './details/sagas';
import publishedSaga from './published/sagas';
import publishSaga from './publish/saga';
import commonSaga from './common/sagas';

function* bookSaga() {
    yield all([
        addSaga(),
        listSaga(),
        detailsSaga(),
        publishedSaga(),
        publishSaga(),
        commonSaga(),
    ])
}

export default bookSaga;