import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types"
import { connect } from "react-redux";
import { Alert } from "reactstrap"
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { FormikTextInputFiled as TextInputFiled, FormikPasswordInputFiled as PasswordInputFiled, Button } from '../../../../components';
import { loginUser, loginCleanState, loginSendVerifyEmail } from '../../../../store/actions';

const passwordMinLength = 8;

const LoginFormComponent = (props) => {
    const { t } = useTranslation();

    const [verifyEmail, setVerifyEmail] = useState(null);

    useEffect(() => {
        return () => {
            props.loginCleanState();
        }
    }, []);

    const onSubmit = (values, onSubmitProps) => {
        setVerifyEmail(values.email);

        props.loginUser(values, onSubmitProps);
    };

    const sendConfirmationEmail = async (e, setErrors) => {
        e.preventDefault();
        setErrors({})

        props.loginSendVerifyEmail({ email: verifyEmail });
    };

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
            }}
            validationSchema={Yup.object({
                email: Yup.string().email('field.error.invalid').required('field.error.required'),
                password: Yup.string().min(passwordMinLength, 'field.error.password.length')
                    .required('field.error.required'),
            })}
            onSubmit={onSubmit}
            validateOnChange={true}
            validateOnBlur={false}
        >
            {({
                  isSubmitting,
                  ...rest
              }) => {
                return (
                    <>
                        {props.loginError && (
                            <Alert color="danger">
                                {props.loginError.message}
                            </Alert>
                        )}

                        {props.loginError && props.loginError?.errors.unverified_email && (
                            <div className="mt-4">
                                <p className={'font-weight-medium'}>
                                    <a href='#' onClick={e => sendConfirmationEmail(e, rest.setErrors)} className={'font-weight-medium text-primary-green'}>{t('send_confirmation_email_to')}</a>
                                    {` ${verifyEmail}`}
                                </p>
                            </div>
                        )}

                        <Form className="form-horizontal" noValidate={true}>
                            <TextInputFiled
                                name="email"
                                label={t('field.label.email')}
                                placeholder={t('field.placeholder.email')}
                                id={'email'}
                                type="email"
                            />

                            <PasswordInputFiled
                                name="password"
                                label={t('field.label.password')}
                                placeholder={t('field.placeholder.password')}
                                id={'password'}
                                translateParams={{ count: passwordMinLength }}
                            />


                            <div className="mt-3 d-grid">
                                <Button
                                    submit
                                    title={'btn.login'}
                                    disabled={isSubmitting || props.loading}
                                    loading={props.loading}
                                />
                            </div>
                        </Form>
                    </>
                )
            }}
        </Formik>
    );
};

LoginFormComponent.propTypes = {
    loginError: PropTypes.object,
    loading: PropTypes.bool,

    loginCleanState: PropTypes.func,
    loginUser: PropTypes.func,
    loginSendVerifyEmail: PropTypes.func,
}

const mapStateToProps = state => {
    const {
        loginError, loading,
    } = state.auth.login;
    return {
        loginError, loading,
    };
};

const mapDispatchToProps = {
    loginUser,
    loginCleanState,
    loginSendVerifyEmail,
};

export const LoginForm = connect(mapStateToProps, mapDispatchToProps)(LoginFormComponent);