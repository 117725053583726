import React from "react"
import PropTypes from "prop-types"

export const RadioInput = ({
  id,
  value,
  onChange,
  label,
  renderComponent,
  checked,
}) => {
  return (
    <div className="form-check form-radio-secondary">
      <input
        style={value === "custom" ? { marginTop: "0.7rem" } : {}}
        className="form-check-input"
        type="radio"
        id={`${id}-${value}`}
        value={value}
        checked={checked}
        onChange={e => onChange(e.target.value)}
      />
      {label && (
        <label className="form-check-label" htmlFor={`${id}-${value}`}>
          {label}
        </label>
      )}
      {renderComponent && renderComponent()}
    </div>
  )
}

RadioInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  renderComponent: PropTypes.func,
}
