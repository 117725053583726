import {
    EMPLOYEE_FETCH_LIST,
    EMPLOYEE_FETCH_LIST_SUCCESS,
    EMPLOYEE_FETCH_LIST_ERROR,
    EMPLOYEE_LIST_CLEAN_STATE,

    EMPLOYEE_LIST_SET_PAGE,
} from './actionTypes';

export const employeeFetchList = (params) => {
    return {
        type: EMPLOYEE_FETCH_LIST,
        payload: { params },
    }
};

export const employeeFetchListSuccess = (data) => {
    return {
        type: EMPLOYEE_FETCH_LIST_SUCCESS,
        payload: data,
    }
};

export const employeeFetchListError = (data) => {
    return {
        type: EMPLOYEE_FETCH_LIST_ERROR,
        payload: data,
    }
};

export const employeeListCleanState = () => {
    return { type: EMPLOYEE_LIST_CLEAN_STATE };
};

export const employeeListSetPage = (data) => {
    return {
        type: EMPLOYEE_LIST_SET_PAGE,
        payload: data,
    }
};