import React from 'react';
import PropTypes from "prop-types"
import { Nav, NavItem, NavLink } from "reactstrap";
import classNames from 'classnames';

export const TabsNav = (props) => {

    const onClick = (item) => {
        if (item.value !== props.active) {
            props.onChange(item.value, item);
        }
    }

    return (
        <Nav pills className={classNames('tabs-nav', {
            [props.className]: props.className,
        })}>
            {props.options.map((item) => (
                <NavItem key={item.value}>
                    <NavLink
                        className={classNames({
                            active: item.value === props.active,
                        })}
                        onClick={() => onClick(item)}
                    >
                        {item.label}
                    </NavLink>
                </NavItem>
            ))}
        </Nav>
    );
};

TabsNav.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.any,
        value: PropTypes.any,
    })),
    active: PropTypes.any,
    onChange: PropTypes.func,
    color: PropTypes.string,
    className: PropTypes.string,
}