import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { InvoicesTable } from "./InvoicesTable"
import { InfoCard } from "components"
import { billingInvoiceList } from "store/actions"
import * as HOC from "HOC"

const DataShower = HOC.withDataShowerOverlay()

const LatestInvoicesContainerComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    fetchList()

    return () => {
      dispatch(billingInvoiceList.cleanState())
    }
  }, [])

  const fetchList = () => {
    let params = {
      per_page: 5,
    }

    dispatch(billingInvoiceList.getList({ params }))
  }

  return (
    <InfoCard
      title={t("latest_invoices")}
      className={classNames("h-100 info-card_loader")}
    >
      <DataShower
        isLoading={props.listLoading}
        isFailed={props.listError}
        error={props.listError}
      >
        <InvoicesTable loading={props.listLoading} list={props.list} />
      </DataShower>
    </InfoCard>
  )
}

LatestInvoicesContainerComponent.propTypes = {
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
}

const mapStateToProps = state => {
  const { list, listLoading, listError } = state.billing.invoice.list

  return {
    list,
    listLoading,
    listError,
  }
}

export const LatestInvoicesContainer = connect(mapStateToProps)(
  LatestInvoicesContainerComponent
)
