import React from "react"
import PropTypes from "prop-types"

import { ModelsForm } from "./ModelsForm"
import { AsyncModels } from "./AsyncModels"
import { GenderSection } from "../GenderSection"

export const ModelsSection = props => {
  return (
    <>
      <GenderSection />

      {!props.isEdit ? <ModelsForm {...props} /> : <AsyncModels {...props} />}
    </>
  )
}

ModelsSection.propTypes = {
  isEdit: PropTypes.bool,
}
