import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import {
  selectSocialCalendarPostItemSlice,
  socialCalendarPostItem,
} from "store"
import { PostPreview } from "./PostPreview"
import { DataShower } from "components"

export const PostPreviewContainer = ({ postId, onEdit }) => {
  const dispatch = useDispatch()

  const { data, loading, error, isError, isFetched } = useSelector(
    selectSocialCalendarPostItemSlice
  )

  useEffect(() => {
    if (postId) {
      dispatch(socialCalendarPostItem.getItem({ id: postId }))
    }

    return () => {
      dispatch(socialCalendarPostItem.cleanState())
    }
  }, [postId])

  return (
    <DataShower
      isLoading={loading}
      isFailed={isError}
      error={error}
      isFetched={isFetched}
    >
      <PostPreview data={data} onEdit={onEdit} />
    </DataShower>
  )
}

PostPreviewContainer.propTypes = {
  onEdit: PropTypes.func,
  postId: PropTypes.any,
  remoteInfo: PropTypes.object,
  remoteLoading: PropTypes.bool,
}
