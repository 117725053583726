import moment from "moment"

import { DateUtils } from "utils"

const utcOffset = 120

export const dateAppTimezoneToUtcTime = date => {
  const offsetDate = moment(date).utcOffset(utcOffset, true).toDate()
  return DateUtils.localeToUtcTime(offsetDate)
}

export const utcTimeToAppTimezoneDate = (time, format = "HH:mm") => {
  const offsetTime = moment
    .utc(time, format)
    .utcOffset(utcOffset)
    .format("HH:mm")
  return DateUtils.timeToDate(offsetTime)
}
