import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Container, Row } from "reactstrap"
import { Drawer } from "components"
import { EditProfileForm } from "./EditProfileForm"
import { ValidationsUtils } from "utils"
import { clientUpdateProfile } from "store/actions"
import { useCountryOptions } from "hooks"

const EditProfileComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const countryOptions = useCountryOptions({ visible: props.visible })

  const handleCloseDrawer = () => {
    props.onClose()
  }

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    handleCloseDrawer()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      clientUpdateProfile.update({
        id: props.client.id,
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  const onDestroy = () => {
    dispatch(clientUpdateProfile.cleanState())
  }

  return (
    <React.Fragment>
      <Drawer
        title={t("general")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <Container fluid>
          <Row>
            <EditProfileForm
              initialValues={{
                name: props.client.name,
                website: props.client.website,
                note: props.client.note,
                country: props.client.country,
                category: props.client.category,
                booker: props.client.booker,
                vat: props.client.vat,
                bank_name: props.client.bank_name,
                bank_account_number: props.client.bank_account_number,
                swift_code: props.client.swift_code,
                other_information: props.client.other_information,
              }}
              loading={props.loading}
              error={props.error}
              onSubmit={handleSubmit}
              onCancel={handleCloseDrawer}
              countryOptions={countryOptions}
            />
          </Row>
        </Container>
      </Drawer>
    </React.Fragment>
  )
}

EditProfileComponent.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,

  client: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.client.profile.data
  const { loading, error } = state.client.profile.update
  return {
    client: data,
    loading,
    error,
  }
}

export const EditProfile = connect(mapStateToProps)(EditProfileComponent)
