import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Drawer } from "components"
import { RequestDetails } from "./RequestDetails"
import { Container } from "reactstrap"

export const RequestDetailsDrawer = props => {
  const { t } = useTranslation()
  const handleCloseDrawer = () => {
    props.onClose()
  }
  return (
    <>
      <Drawer
        title={t("request-details")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
      >
        <Container fluid>
          <RequestDetails data={props.requestDetails} />
        </Container>
      </Drawer>
    </>
  )
}

RequestDetailsDrawer.propTypes = {
  requestDetails: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
}
