import React from "react"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"
import { ControllerHF, DateRangePickerField } from "components"

export const Period = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <ControllerHF
      name={"period"}
      control={control}
      component={DateRangePickerField}
      id={"period"}
      label={t("period")}
      placeholder={t("select-period")}
      isClearable={false}
    />
  )
}

Period.propTypes = {}
