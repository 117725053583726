import {
    PACKAGE_FETCH_LIST,
    PACKAGE_FETCH_LIST_SUCCESS,
    PACKAGE_FETCH_LIST_ERROR,
    PACKAGE_FETCH_LIST_CLEAN_STATE,

    PACKAGE_LIST_SET_PAGE,
} from './actionTypes'

export const packageFetchList = (params) => {
    return {
        type: PACKAGE_FETCH_LIST,
        payload: { params },
    }
};

export const packageFetchListSuccess = (data) => {
    return {
        type: PACKAGE_FETCH_LIST_SUCCESS,
        payload: data,
    }
};

export const packageFetchListError = (data) => {
    return {
        type: PACKAGE_FETCH_LIST_ERROR,
        payload: data,
    }
};

export const packageFetchListCleanState = () => {
    return { type: PACKAGE_FETCH_LIST_CLEAN_STATE };
};

export const packageListSetPage = (data) => {
    return {
        type: PACKAGE_LIST_SET_PAGE,
        payload: data,
    }
};