import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"

import { BillingPanel } from "./BillingPanel"
import { BillingPay } from "./BillingPay"

export const BillingIndex = () => {
  let { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/pay`}>
        <BillingPay />
      </Route>
      <Route path={path}>
        <BillingPanel />
      </Route>
      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  )
}
