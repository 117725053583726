import * as Yup from "yup"

export const validationSchema = Yup.object().shape({
  date: Yup.date().nullable().required("field.error.required"),
  type: Yup.mixed().nullable(),
  subject: Yup.string().required("field.error.required"),
  message: Yup.string(),
  recipients: Yup.array()
    .of(
      Yup.object().shape({
        emails: Yup.mixed().test(
          "email-required",
          "field.error.recipients.email-required",
          value => value.length > 0
        ),
      })
    )
    .test(
      "recipient-required",
      "field.error.recipients.empty.add",
      value => value.length > 0
    )
    .test(
      "recipient-max-emails",
      "field.error.recipients.max-emails",
      recipients => {
        let emailsCount = 0
        recipients.forEach(item => {
          emailsCount += item.emails.length
        })
        return emailsCount <= 100
      }
    ),
})
