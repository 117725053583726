import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ChangePermissions } from "../ChangePermissions"
import { useTranslation } from "react-i18next"
import { GraphsPeriodControls } from "../GraphPeriodControls"
import { GRAPH_PERIOD_TYPE } from "consts"

export const ChartCard = ({
  title,
  className,
  children,
  withPeriodControl = true,
  defaultPeriodType = GRAPH_PERIOD_TYPE.MONTH,
  onPeriodChange,
  withComparisonPeriod = false,
  graphData,
}) => {
  const { t } = useTranslation()
  return (
    <div
      className={classNames("chart-card", {
        [className]: className,
      })}
    >
      <div className={"chart-card__header"}>
        <div className="chart-card__title">
          {title || t(graphData.alias)}
          <div className={"chart-card__permission"}>
            <ChangePermissions graphData={graphData} />
          </div>
        </div>
        {withPeriodControl && (
          <div className={"chart-card__controller"}>
            <GraphsPeriodControls
              id={graphData.id}
              defaultPeriodType={defaultPeriodType}
              onChange={onPeriodChange}
              withComparisonPeriod={withComparisonPeriod}
            />
          </div>
        )}
      </div>
      <div className={"chart-card__body"}>{children}</div>
    </div>
  )
}

ChartCard.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  withPeriodControl: PropTypes.bool,
  defaultPeriodType: PropTypes.string,
  onPeriodChange: PropTypes.func.isRequired,
  withComparisonPeriod: PropTypes.bool,
  graphData: PropTypes.object,
}
