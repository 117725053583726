import { combineReducers } from "redux";

import add from './add/slice';
import list from './list/slice';

const panelReducer = combineReducers({
    add,
    list,
});

export default panelReducer;