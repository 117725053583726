import { combineReducers } from "redux"

import list from "./list/slice"
import tablesDataList from "./tablesDataList/slice"

const graphsReducer = combineReducers({
  list,
  tablesDataList,
})

export default graphsReducer
