import { createSlice } from "@reduxjs/toolkit"


const initialState = {
  data: null,
  loading: false,
  error: null,
}

const agencyAddressDataSlice = createSlice({
  name: "agencyAddressData",
  initialState,
  reducers: {
    getData(state) {
      state.loading = true
      state.error = null
    },
    getDataCompleted(state, action) {
      state.loading = false
      state.data = action.payload.data
    },
    getDataRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    updateData(state, action) {
      state.data =  {...state.data, ...action.payload}
    },
    cleanState() {
      return initialState
    }
  }
})
/**
 * @namespace
 * @property {function} getData
 *  @property {function} getDataCompleted
 *  @property {function} getDataRejected
 *  @property {function} updateData
 *  @property {function} cleanState
 */
export const agencyAddressData = agencyAddressDataSlice.actions

export default agencyAddressDataSlice.reducer