import { combineReducers } from "@reduxjs/toolkit"

import contacts from "./contacts/reducers"
import chats from "./chats/reducers"
import conversation from "./conversation/reducers"
import groups from "./groups/reducers"

const chatReducer = combineReducers({
  contacts,
  chats,
  conversation,
  groups,
})

export default chatReducer
