import React from "react"
import PropTypes from "prop-types"
import { IconButton, TextButton } from "components/Buttons"
import classnames from "classnames"
import { useMedia } from "react-use"

export const IdeaIconButton = ({ id, onClick, wrapClass, isPopoverOpen }) => {
  const isWide = useMedia("(min-width: 648px)")

  return isWide ? (
    <TextButton
      id={id}
      leftIcon={classnames("font-size-16 align-middle", {
        "bx bx-bulb": !isPopoverOpen,
        "bx bxs-bulb": isPopoverOpen,
      })}
      title={"btn.share-ideas"}
      color={"secondary"}
      btnClass={wrapClass}
      onClick={onClick}
    />
  ) : (
    <IconButton
      icon={`align-middle font-size-20 bx ${
        isPopoverOpen ? "bxs-bulb" : "bx-bulb"
      }`}
      color={"text-secondary"}
      onClick={onClick}
      wrapClass={wrapClass}
      id={id}
    />
  )
}

IdeaIconButton.propTypes = {
  id: PropTypes.any,
  onClick: PropTypes.func,
  wrapClass: PropTypes.string,
  isPopoverOpen: PropTypes.bool,
}
