import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import { EventContractForm } from "../EventContractForm"
import { useFetchData, useSubmitHandler } from "hooks"
import { calendarEventContractCreate } from "store/actions"
import { mapClientData, mapCompanyData } from "./helpers"
import { API } from "api"
import * as HOC from "HOC"

const DataShower = HOC.withDataShower()

export const EventContractCreate = props => {
  const dispatch = useDispatch()

  const companyLogo = useSelector(
    state => state.user.profile.data?.company?.avatar
  )
  const { loading, error } = useSelector(
    state => state.calendarEvent.contract.create
  )

  useEffect(() => {
    return () => {
      dispatch(calendarEventContractCreate.cleanState())
    }
  }, [])

  // Fetch company data for from initial value
  const companyFetch = useFetchData()
  const companyData = companyFetch?.data?.data || null

  const onCompanyFetch = async () => {
    const request = API.profile.getCompany()
    await companyFetch.fetchData({
      request,
    })
  }

  useEffect(() => {
    onCompanyFetch()
  }, [])
  // === //

  // Fetch client data for from initial value
  const clientFetch = useFetchData()
  const clientData = clientFetch?.data?.data || null

  const onClientFetch = async () => {
    const request = API.client.getClient(props.clientId)
    await clientFetch.fetchData({
      request,
    })
  }

  useEffect(() => {
    if (props.clientId) {
      onClientFetch()
    }
  }, [props.clientId])
  // === //

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        calendarEventContractCreate.create({
          id: props.eventId,
          params,
          onSuccess,
          onError,
        })
      )
    },
    () => {
      props.onSuccess()
    }
  )

  const isLoading = useMemo(() => {
    return companyFetch.loading || clientFetch.loading
  }, [companyFetch.loading, clientFetch.loading])

  const isFormReady = useMemo(() => {
    let ready = true

    if (props.clientId) {
      const { data, loading, error } = clientFetch
      ready = !loading && !!(data || error)
    }

    if (ready) {
      const { data, loading, error } = companyFetch
      ready = !loading && !!(data || error)
    }
    return ready
  }, [companyFetch, props.clientId, clientFetch])

  const initialValues = useMemo(() => {
    let values = {
      logo: companyLogo,
    }

    if (isFormReady) {
      if (companyData) {
        values = {
          ...values,
          ...mapCompanyData(companyData),
        }
      }

      if (clientData) {
        values = {
          ...values,
          ...mapClientData(clientData),
        }
      }
    }
    return values
  }, [companyLogo, isFormReady, companyData, clientData])

  return (
    <DataShower isLoading={isLoading} isFailed={null} error={null}>
      {isFormReady && (
        <EventContractForm
          initialValues={{
            ...initialValues,
          }}
          loading={loading}
          error={error}
          onSubmit={handleSubmit}
          onCancel={props.onCancel}
        />
      )}
    </DataShower>
  )
}

EventContractCreate.propTypes = {
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  eventId: PropTypes.number,
  clientId: PropTypes.number,
}
