import {
    MODEL_FETCH_PHOTOS,
    MODEL_FETCH_PHOTOS_SUCCESS,
    MODEL_FETCH_PHOTOS_ERROR,
    MODEL_FETCH_PHOTOS_CLEAN_STATE,

    MODEL_PHOTOS_LIST_SET_PAGE,
} from './actionTypes';

const initialState = {
    list: [],
    listLoading: false,
    listError: null,
    page: 1,
    meta: null,
};

const data = (state = initialState, action) => {
    switch (action.type) {
        case MODEL_FETCH_PHOTOS:
            state = {
                ...state,
                listLoading: true,
                listError: null,
            };
            break;

        case MODEL_FETCH_PHOTOS_SUCCESS:
            state = {
                ...state,
                listLoading: false,
                list: action.payload.data,
                meta: action.payload.meta,
            };
            break;

        case MODEL_FETCH_PHOTOS_ERROR:
            state = {
                ...state,
                listLoading: false,
                listError: action.payload,
            };
            break;

        case MODEL_FETCH_PHOTOS_CLEAN_STATE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        case MODEL_PHOTOS_LIST_SET_PAGE:
            state = {
                ...state,
                page: action.payload,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default data;