import { combineReducers } from "redux";

import add from './add/reducer';
import list from './list/reducers';
import details from './details/reducers';

const videoReducer = combineReducers({
    add,
    list,
    details,
});

export default videoReducer;