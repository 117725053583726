import { combineReducers } from "redux"

import data from "./data/slice"
import remove from "./remove/slice"
import update from "./update/slice"

const profileReducer = combineReducers({
    data,
    remove,
    update,
})

export default profileReducer