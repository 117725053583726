import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const modelLogisticsExpenseAddSlice = createSlice({
  name: "modelLogisticsExpenseAdd",
  initialState,
  reducers: {
    add(state) {
      state.loading = true
      state.error = null
    },
    addSuccess(state) {
      state.loading = false
    },
    addError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} add
 * @property {function} addSuccess
 * @property {function} addError
 * @property {function} cleanState
 */
export const modelLogisticsExpenseAdd = modelLogisticsExpenseAddSlice.actions

export default modelLogisticsExpenseAddSlice.reducer
