import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
  loading: false,
  error: null,
}

const calendarEventDataSlice = createSlice({
  name: "calendarEventData",
  initialState,
  reducers: {
    getData(state) {
      state.loading = true
      state.error = null
    },
    getDataSuccess(state, action) {
      state.loading = false
      state.data = action.payload.data
    },
    getDataError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    updateData(state, action) {
      state.data = { ...state.data, ...action.payload }
    },
    setModelContract(state, action) {
      const { id, contract } = action.payload

      const models = state.data.fashion_models.map(model => {
        if (model.id === id) {
          return {
            ...model,
            contract,
          }
        } else {
          return model
        }
      })

      state.data = {
        ...state.data,
        fashion_models: [...models],
      }
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getData
 * @property {function} getDataSuccess
 * @property {function} getDataError
 * @property {function} updateData
 * @property {function} setModelContract
 * @property {function} cleanState
 */
export const calendarEventData = calendarEventDataSlice.actions

export default calendarEventDataSlice.reducer
