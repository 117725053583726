import React from 'react'
import PropTypes from "prop-types"

import { DefaultVideoPlayer } from '../DefaultVideoPlayer';

export const IframeVideoPlayer = (props) => {
    switch (props.name) {
        case 'youtube':
            return null;

        default:
            return <DefaultVideoPlayer {...props} ref={props.playerRef} />
    }
};

IframeVideoPlayer.propTypes = {
    name: PropTypes.string,
    playerRef: PropTypes.any,
}