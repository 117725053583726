import React from "react"
import PropTypes from "prop-types"
import { Col, Alert, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { Button, ControllerHF, InputField } from "components"
import { MembersSection } from "./MembersSection"
import { validationSchema } from "./validation"

export const GroupForm = props => {
  const { t } = useTranslation()

  const methods = useForm({
    defaultValues: {
      name: "",
      users: [],
      ...props.initialValues,
    },
    resolver: yupResolver(validationSchema(props.isEdit)),
  })

  const { control, handleSubmit, setError, reset } = methods

  const onSubmit = values => {
    const { name, users } = values
    let params = {
      name,
    }

    if (!props.isEdit) {
      params.users = users.map(item => item.id)
    }

    props.onSubmit(params, { reset, setError })
  }

  const renderButtons = () => (
    <div className="mt-3 mb-3 pt-4">
      <div className="button-items">
        <Button
          submit
          title={"btn.save"}
          disabled={props.loading}
          loading={props.loading}
        />

        <Button
          title={"cancel"}
          color="light"
          outline
          onClick={props.onCancel}
        />
      </div>
    </div>
  )

  return (
    <FormProvider {...methods}>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <ControllerHF
              name={"name"}
              control={control}
              component={InputField}
              id={"name"}
              label={t("name")}
              placeholder={t("name")}
            />
          </Col>
        </Row>

        {props.isEdit && renderButtons()}

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          <Col md={6}>
            <MembersSection isEdit={props.isEdit} chatId={props.chatId} />
          </Col>
        </Row>

        {!props.isEdit && renderButtons()}
      </form>
    </FormProvider>
  )
}

GroupForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  isEdit: PropTypes.bool,
  chatId: PropTypes.number,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
