import { combineReducers } from "redux"

import load from "./load/reducer"
import profile from "./profile/reducer"
import photos from "./photos/reducers"
import groups from "./groups/reducers"

const userReducer = combineReducers({
  load,
  profile,
  photos,
  groups,
})

export default userReducer
