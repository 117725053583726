import { EditorUtils } from "utils"

export const mapCompanyData = (companyData, params = { isModel: false }) => {
  const { isModel } = params

  const contractText = isModel
    ? companyData?.contract_model_text
    : companyData?.contract_text

  return {
    header_text: companyData?.invoice_information || "",
    contract_text: EditorUtils.draftJs.getEditorState(
      contractText ? contractText : ""
    ),
    footer_text: companyData?.contract_footer_text || "",
  }
}
