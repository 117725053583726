import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../../i18n';
import { MODELS_PANEL_ADD_MODEL } from './actionTypes';
import { modelsPanelAddModelSuccess, modelsPanelAddModelError } from './actions';

import { API } from '../../../../api';

function* addModel({ payload: { params, onSuccess, onError } }) {
    try {
        const response = yield call(API.model.addModel, params);

        const { data } = response;

        yield put(modelsPanelAddModelSuccess());

        toastr.success(i18n.t('model.add.success'));

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        const errors = error.response?.data?.errors || {};
        const message = error.response?.data?.message || i18n.t('error');

        yield put(modelsPanelAddModelError({
            errors,
            message
        }));

        if (onError) {
            onError(errors);
        }
    }
}

export function* watchAddModel() {
    yield takeEvery(MODELS_PANEL_ADD_MODEL, addModel);
}

function* addSaga() {
    yield all([
        fork(watchAddModel),
    ]);
}

export default addSaga;