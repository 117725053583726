import { all } from "redux-saga/effects"

import messagesSaga from "./messages/sagas"
import attachmentSaga from "./attachment/sagas"

function* conversationSaga() {
  yield all([messagesSaga(), attachmentSaga()])
}

export default conversationSaga
