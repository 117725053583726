import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

export const InfoCardListItem = ({ label, value, normalize, className }) => {
  const getValue = () => {
    if (value === null || value === undefined) return "-"
    if (normalize) return normalize(value)
    return value
  }
  return (
    <div
      className={classnames("info-list__item", {
        [className]: className,
      })}
    >
      <div className={"info-list__label"}>{label}:</div>
      <div className={"info-list__info"}>{getValue(value)}</div>
    </div>
  )
}

InfoCardListItem.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  normalize: PropTypes.func,
  className: PropTypes.string,
}
