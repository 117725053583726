export const MODEL_LOGISTICS_TYPE = {
  ACCOMMODATION: "accommodation",
  TRAVEL: "travel",
  DRIVER: "driver",
  POCKET_MONEY: "pocket_money",
  BOOK: "book",
  COMPOSIT: "composit",
  WEBSITE: "website",
  VISA: "visa",
  OTHER: "other",
}

export const MODEL_LOGISTICS_TRAVEL_BY = {
  PLAIN: "plane",
  TRAIN: "train",
  SHIP: "ship",
  CAR: "car",
}
