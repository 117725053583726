import {
    PROFILE_REMOVE_USER,
    PROFILE_REMOVE_USER_SUCCESS,
    PROFILE_REMOVE_USER_ERROR,
    PROFILE_REMOVE_USER_CLEAN_STATE,
} from './actionTypes';

export const profileRemoveUser = (onSubmitProps, history) => {
    return {
        type: PROFILE_REMOVE_USER,
        payload: { onSubmitProps, history },
    }
};

export const profileRemoveUserSuccess = () => {
    return {
        type: PROFILE_REMOVE_USER_SUCCESS,
    }
};

export const profileRemoveUserError = (data) => {
    return {
        type: PROFILE_REMOVE_USER_ERROR,
        payload: data,
    }
};

export const profileRemoveUserCleanState = () => {
    return { type: PROFILE_REMOVE_USER_CLEAN_STATE };
};