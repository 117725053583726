import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../i18n';
import { PROFILE_EDIT_GENERAL_INFO } from './actionTypes';
import { profileEditGeneralInfoSuccess, profileEditGeneralInfoError } from './actions';
import { userProfileUpdateData } from '../../user/profile/actions';

import { API } from '../../../api';

function* editInfo({ payload: { params, onSubmitProps } }) {
    const { setErrors, setSubmitting } = onSubmitProps;

    try {
        const response = yield call(API.profile.editProfile, params);

        const { data } = response;

        yield put(profileEditGeneralInfoSuccess());

        yield put(userProfileUpdateData({ ...data.data }));

        toastr.success(i18n.t('profile.edit.success'));

        setSubmitting(false);
    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : i18n.t('error');

        yield put(profileEditGeneralInfoError({
            errors,
            message
        }));

        setSubmitting(false);
        setErrors(errors);
    }
}

export function* watchEditInfo() {
    yield takeEvery(PROFILE_EDIT_GENERAL_INFO, editInfo);
}

function* generalInfoSaga() {
    yield all([
        fork(watchEditInfo),
    ]);
}

export default generalInfoSaga;