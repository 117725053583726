import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "i18n"
import { API } from "api"
import { socialCalendarRemovePost } from "./slice"
import { socialCalendarPostList } from "store/socialCalendar/post/actions"

function* remove({ payload: { id, onSuccess } }) {
  try {
    const response = yield call(API.socialCalendar.removePost, id)

    const { data } = response

    yield put(socialCalendarRemovePost.removeCompleted())
    yield put(socialCalendarPostList.removeItemById(id))

    if (onSuccess) {
      onSuccess()
    }

    toastr.success(i18n.t("remove.success"))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      socialCalendarRemovePost.removeRejected({
        errors,
        message,
      })
    )
    toastr.error(message)
  }
}

export function* watchRemove() {
  yield takeEvery(socialCalendarRemovePost.remove, remove)
}

function* removeSaga() {
  yield all([fork(watchRemove)])
}

export default removeSaga
