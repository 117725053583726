import {
    MODEL_PANEL_GENERAL_AGENCY_LIST_REMOVE_ITEM,
    MODEL_PANEL_GENERAL_AGENCY_LIST_REMOVE_ITEM_SUCCESS,
    MODEL_PANEL_GENERAL_AGENCY_LIST_REMOVE_ITEM_ERROR,
    MODEL_PANEL_GENERAL_AGENCY_LIST_REMOVE_ITEM_CLEAN_STATE,
} from './actionTypes';

export const modelPanelGeneralAgencyListRemoveItem = (id, onSuccess) => {
    return {
        type: MODEL_PANEL_GENERAL_AGENCY_LIST_REMOVE_ITEM,
        payload: { id, onSuccess },
    }
};

export const modelPanelGeneralAgencyListRemoveItemSuccess = (id) => {
    return {
        type: MODEL_PANEL_GENERAL_AGENCY_LIST_REMOVE_ITEM_SUCCESS,
        payload: { id },
    }
};

export const modelPanelGeneralAgencyListRemoveItemError = (id) => {
    return {
        type: MODEL_PANEL_GENERAL_AGENCY_LIST_REMOVE_ITEM_ERROR,
        payload: { id },
    }
};

export const modelPanelGeneralAgencyListRemoveItemCleanState = () => {
    return { type: MODEL_PANEL_GENERAL_AGENCY_LIST_REMOVE_ITEM_CLEAN_STATE };
};