import React, { createContext, useCallback, useState } from "react"
import PropTypes from "prop-types"
import { FacebookClient } from "../services"

const FacebookContext = createContext(null)

const FacebookContextProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(undefined)

  const [account, setAccount] = useState(null)

  const updateAccessToken = useCallback(accessToken => {
    setAccessToken(accessToken)
    FacebookClient.setAccessToken(accessToken)
  }, [])

  const removeAccessToken = useCallback(() => {
    FacebookClient.removeAccessToken()
    setAccessToken(undefined)
  }, [])

  const updateAccount = useCallback(account => {
    setAccount(account)
  }, [])

  const removeAccount = useCallback(() => {
    setAccount(null)
  }, [])

  return (
    <FacebookContext.Provider
      value={{
        accessToken,
        updateAccessToken,
        removeAccessToken,
        updateAccount,
        removeAccount,
        account,
        isAccessToken: !!accessToken,
      }}
    >
      {children}
    </FacebookContext.Provider>
  )
}

FacebookContextProvider.propTypes = {
  children: PropTypes.any,
}

export { FacebookContextProvider, FacebookContext }
