import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { billingPaymentMethodRemove } from "./slice"
import { billingPaymentMethodList } from "../list/slice"

function* remove({ payload: { id, onSuccess } }) {
  try {
    const response = yield call(API.billing.deletePaymentMethod, id)
    const { data } = response

    yield put(billingPaymentMethodRemove.removeSuccess())

    toastr.success(i18n.t(`billing.payment_method.remove.success`))

    yield put(billingPaymentMethodList.removeItem({ id }))

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      billingPaymentMethodRemove.removeError({
        errors,
        message,
      })
    )

    toastr.error(message)
  }
}

export function* watchRemove() {
  yield takeEvery(billingPaymentMethodRemove.remove, remove)
}

function* removeSaga() {
  yield all([fork(watchRemove)])
}

export default removeSaga
