import {
  BILLING_SUBSCRIPTION_STATUS_ACTIVE,
  BILLING_SUBSCRIPTION_STATUS_TRIALING,
  BILLING_SUBSCRIPTION_STATUS_ON_GRACE_PERIOD,
  BILLING_SUBSCRIPTION_TARIFF_ALIAS_BUSINESS,
} from "consts"

export const isAllow = user => {
  if (user) {
    const { subscription } = user

    if (subscription) {
      const { status } = subscription
      return (
        status === BILLING_SUBSCRIPTION_STATUS_ACTIVE ||
        status === BILLING_SUBSCRIPTION_STATUS_TRIALING ||
        status === BILLING_SUBSCRIPTION_STATUS_ON_GRACE_PERIOD
      )
    }

    return false
  }
  return false
}

export const fullAccessAllowed = user => {
  if (user) {
    const { subscription } = user

    if (subscription) {
      const { status, tariff } = subscription

      return (
        status === BILLING_SUBSCRIPTION_STATUS_TRIALING ||
        (tariff?.alias === BILLING_SUBSCRIPTION_TARIFF_ALIAS_BUSINESS &&
          [
            BILLING_SUBSCRIPTION_STATUS_ACTIVE,
            BILLING_SUBSCRIPTION_STATUS_ON_GRACE_PERIOD,
          ].includes(status))
      )
    }

    return false
  }
  return false
}

export default {
  isAllow,
  fullAccessAllowed,
}
