import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';

import { modelPanelGeneralFetchDocument, modelPanelGeneralFetchDocumentCleanState } from '../../../../../../store/actions';

import * as HOC from '../../../../../../HOC';
const DataShower = HOC.withDataShower();

const DocumentDataLoaderComponent = (props) => {

    useEffect(() => {
        fetchData();

        return () => {
            props.modelPanelGeneralFetchDocumentCleanState();
        };
    }, []);

    const fetchData = () => {
        props.modelPanelGeneralFetchDocument(props.id);
    };

    return (
        <DataShower isLoading={props.loading} isFailed={props.error} error={props.error}>
            {props.data && (
                props.children
            )}
        </DataShower>
    );
};

DocumentDataLoaderComponent.propTypes = {
    id: PropTypes.number,
    children: PropTypes.any,

    data: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.any,

    modelPanelGeneralFetchDocument: PropTypes.func,
    modelPanelGeneralFetchDocumentCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { data, loading, error } = state.modelPanel.general.documents.details.data;

    return {
        data,
        loading,
        error,
    };
};

const mapDispatchToProps = {
    modelPanelGeneralFetchDocument,
    modelPanelGeneralFetchDocumentCleanState,
};

export const DocumentDataLoader = connect(mapStateToProps, mapDispatchToProps)(DocumentDataLoaderComponent);