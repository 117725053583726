import React, { useState } from "react"
import PropTypes from "prop-types"
import { useForm } from "react-hook-form"
import { Alert } from "reactstrap"
import { Step, Stepper } from "react-form-stepper"
import { useTranslation } from "react-i18next"
import { Button, ControllerHF, ImageCropControl } from "components"

export const AvatarCropForm = props => {
  const { t } = useTranslation()
  const [activeStep, setActiveStep] = useState(0)
  const [canSubmit, setCanSubmit] = useState(false)

  const { control, reset, setError, handleSubmit } = useForm({
    defaultValues: {
      avatar_square: {
        crop: undefined,
      },
      avatar_rectangle: {
        crop: undefined,
      },
      ...props.initialValues,
    },
    mode: "onChange",
  })

  const handleNext = () => {
    if (activeStep === 1) {
      setCanSubmit(true)
      return
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    if (activeStep === 0) {
      reset()
      props.onCancel()
    }
    if (activeStep > 0) {
      setActiveStep(prevActiveStep => prevActiveStep - 1)
    }
  }

  const onSubmit = values => {
    let params = {
      ...values,
    }
    props.onSubmit(params, { reset, setError })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}
      <Stepper activeStep={activeStep} className={"model-avatar-crop-stepper"}>
        {["select-first-area", "select-second-area"].map(label => {
          return <Step key={label} label={t(label)} />
        })}
      </Stepper>
      <div>
        {activeStep === 0 && (
          <ControllerHF
            id={"avatar_square"}
            name={"avatar_square"}
            control={control}
            component={ImageCropControl}
            imageDataURL={props.imageDataURL}
            withPreview
            previewConfig={{
              width: 160,
              height: 160,
            }}
            aspect={1}
            hasCrop={props.hasCrop}
          />
        )}
        {activeStep === 1 && (
          <ControllerHF
            id={"avatar_rectangle"}
            name={"avatar_rectangle"}
            control={control}
            component={ImageCropControl}
            imageDataURL={props.imageDataURL}
            withPreview
            previewConfig={{
              width: 160,
              height: 256,
            }}
            aspect={0.625}
            hasCrop={props.hasCrop}
          />
        )}
        <div className={"d-flex justify-content-around "}>
          <Button
            onClick={handleBack}
            title={"back"}
            outline
            disabled={props.loading}
          />
          <Button
            disabled={props.loading}
            loading={props.loading}
            color="secondary"
            submit={canSubmit}
            onClick={handleNext}
            renderTitle={activeStep === 1 ? t("finish") : t("next")}
          />
        </div>
      </div>
    </form>
  )
}

AvatarCropForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  imageDataURL: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  hasCrop: PropTypes.bool,
}
