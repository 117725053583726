import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { PrintButton } from "components"

export const Footer = props => {
  const { t } = useTranslation()

  return (
    <div className="d-print-none">
      <div className="float-end">
        <PrintButton />
      </div>
    </div>
  )
}

Footer.propTypes = {}
