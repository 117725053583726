import React, { useRef, useCallback, useImperativeHandle, forwardRef } from 'react'
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next';
import classNames from "classnames";

const DefaultVideoPlayerComponent = ({
    url = '',
    className,
    width = '100%',
    height = 'auto',
    playerRef,
}) => {
    const { t } = useTranslation();

    const wrapRef = useRef(null);
    const iframeRef = useRef(null);

    useImperativeHandle(playerRef, () => ({
            play() {
                handlePlay();
            },
            pause() {
                handlePause();
            }
        }),
    );

    const handlePlay = () => {
        if (iframeRef.current && iframeRef.current?.contentWindow) {
            iframeRef.current.contentWindow.postMessage(
                '{"event":"command","func":"playVideo","args":""}',
                '*');
        }
    }

    const handlePause = () => {
        if (iframeRef.current && iframeRef.current?.contentWindow) {
            iframeRef.current.contentWindow.postMessage(
                '{"event":"command","func":"' + 'pauseVideo' + '","args":""}',
                '*');
        }
    }

    return (
        <div className={classNames('iframe-video-player', {
            [className]: className,
        })}
             ref={wrapRef}
        >
            <iframe
                ref={iframeRef}
                src={url + '?version=3&enablejsapi=1'}
                width={width}
                height={height}
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
            />
        </div>
    );
};

DefaultVideoPlayerComponent.propTypes = {
    url: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    playerRef: PropTypes.any,
}

export const DefaultVideoPlayer = forwardRef((props, ref) => (
    <DefaultVideoPlayerComponent {...props} playerRef={ref} />
));