import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { PerfectScrollbar } from "components"
import { Item } from "./Item"
import * as HOC from "HOC"

const DataShower = HOC.withDataShower()

export const ContactsList = props => {
  const { t } = useTranslation()

  // const onScroll = (e) => {
  //   if (isCloseToBottom(e, 50)) {
  //     props.onLoadMore();
  //   }
  // };

  return (
    <>
      <PerfectScrollbar style={{ height: "390px" }}>
        <DataShower
          isLoading={props.loading}
          isFailed={props.error}
          error={props.error}
        >
          {!!props.items.length ? (
            <>
              <ul className="list-unstyled chat-list">
                {props.items.map(item => (
                  <Item
                    key={`contact-item-${item.id}`}
                    item={item}
                    onSelect={props.onSelect}
                    active={props.participantId === item.id}
                    onRemove={props.onRemove}
                  />
                ))}
              </ul>
            </>
          ) : !props.loading ? (
            props.empty || t("chat.no_contacts")
          ) : null}
        </DataShower>
      </PerfectScrollbar>
    </>
  )
}

ContactsList.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  onSelect: PropTypes.func,
  participantId: PropTypes.any,
  empty: PropTypes.any,
  onRemove: PropTypes.func,
}
