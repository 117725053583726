import React from "react"
import PropTypes from "prop-types"

import { Button } from "components"

export const PaymentDetails = ({ onClick, ...props }) => {
  return (
    <Button
      title={"details"}
      onClick={onClick}
      outline
      color={"secondary"}
      type={"button"}
      size={"btn-sm"}
      btnClass={"statement-payment-action__btn"}
    />
  )
}

PaymentDetails.propTypes = {
  onClick: PropTypes.func,
}
