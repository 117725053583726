import { combineReducers } from "redux";

import add from './add/reducer';
import list from './list/reducers';
import details from './details/reducers';
import models from './models/reducers';
import dataPicker from './dataPicker/reducer';
import draft from './draft/reducers';
import statistics from './statistics/reducers';

const packageReducer = combineReducers({
    add,
    list,
    details,
    models,
    dataPicker,
    draft,
    statistics,
});

export default packageReducer;