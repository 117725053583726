import { takeEvery, fork, all, call } from "redux-saga/effects"

import { socketOnChatGroupParticipantRemoved } from "./actions"
import { onChatUpdate } from "../../../../../chat/chats/list/saga"
import { onConversationUpdate } from "../../../../../chat/conversation/data/saga"

function* onRemove({ payload: { data } }) {
  const { chat, user } = data

  yield call(onChatUpdate, {
    payload: {
      chat,
    },
  })

  yield call(onConversationUpdate, {
    payload: {
      chat,
    },
  })
}

export function* watchOnRemove() {
  yield takeEvery(socketOnChatGroupParticipantRemoved, onRemove)
}

function* removeSaga() {
  yield all([fork(watchOnRemove)])
}

export default removeSaga
