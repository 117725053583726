import React from "react"
import PropTypes from "prop-types"
import { useUserAccess } from "hooks"

import { SubscriptionAccessAlert } from "components"

export const SubscriptionAccessViewer = ({ children, ...props }) => {
  const { subscriptionFullAccessAllowed } = useUserAccess()
  const fullAccessAllowed = subscriptionFullAccessAllowed()

  return fullAccessAllowed ? children : <SubscriptionAccessAlert {...props} />
}

SubscriptionAccessViewer.propTypes = {
  children: PropTypes.any,
}
