import _ from "lodash"

import i18n from "i18n"
import { MODEL_LOGISTICS_TYPE, MODEL_LOGISTICS_TRAVEL_BY } from "consts"

export const getLogisticsTypeOptions = () => {
  return [
    {
      label: i18n.t("logistics_type.accommodation"),
      value: MODEL_LOGISTICS_TYPE.ACCOMMODATION,
    },
    {
      label: i18n.t("logistics_type.travel"),
      value: MODEL_LOGISTICS_TYPE.TRAVEL,
    },
    {
      label: i18n.t("logistics_type.driver"),
      value: MODEL_LOGISTICS_TYPE.DRIVER,
    },
    {
      label: i18n.t("logistics_type.pocket_money"),
      value: MODEL_LOGISTICS_TYPE.POCKET_MONEY,
    },
    {
      label: i18n.t("logistics_type.book"),
      value: MODEL_LOGISTICS_TYPE.BOOK,
    },
    {
      label: i18n.t("logistics_type.composit"),
      value: MODEL_LOGISTICS_TYPE.COMPOSIT,
    },
    {
      label: i18n.t("logistics_type.website"),
      value: MODEL_LOGISTICS_TYPE.WEBSITE,
    },
    {
      label: i18n.t("logistics_type.visa"),
      value: MODEL_LOGISTICS_TYPE.VISA,
    },
    {
      label: i18n.t("logistics_type.other"),
      value: MODEL_LOGISTICS_TYPE.OTHER,
    },
  ]
}

export const getLogisticsTypeAsOption = value => {
  const options = getLogisticsTypeOptions()
  return _.find(options, { value: value })
}

export const getLogisticsTravelOptions = () => {
  return [
    {
      label: i18n.t("logistics.travel_by.plane"),
      value: MODEL_LOGISTICS_TRAVEL_BY.PLAIN,
    },
    {
      label: i18n.t("logistics.travel_by.train"),
      value: MODEL_LOGISTICS_TRAVEL_BY.TRAIN,
    },
    {
      label: i18n.t("logistics.travel_by.ship"),
      value: MODEL_LOGISTICS_TRAVEL_BY.SHIP,
    },
    {
      label: i18n.t("logistics.travel_by.car"),
      value: MODEL_LOGISTICS_TRAVEL_BY.CAR,
    },
  ]
}

export const getLogisticsTravelAsOption = value => {
  const options = getLogisticsTravelOptions()
  return _.find(options, { value: value })
}
