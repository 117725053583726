import React from "react"
import PropTypes from "prop-types"
import { IconButton, ToggleButton } from "components"
import { useTranslation } from "react-i18next"
import { DnDSectionContainer } from "components"

export const WebsiteMenuItemsCard = ({
  index,
  item,
  withStatus,
  onStatusChange,
  withEdit,
  onEdit,
  withRemove,
  onRemove,
  removeLoading,
  updateLoading,
  children,
  ...rest
}) => {
  const { t } = useTranslation()
  const isEditable = item.type === "custom"

  const handleStatusChange = () => {
    onStatusChange(item)
  }
  const handleEdit = () => {
    onEdit(item)
  }
  const handleRemove = () => {
    onRemove(item)
  }

  return (
    <DnDSectionContainer
      title={item.title}
      actions={
        <>
          {withStatus && isEditable && (
            <ToggleButton
              value={item.active}
              trueTitle={t("deactivate")}
              falseTitle={t("activate")}
              onClick={handleStatusChange}
              disabled={updateLoading}
            />
          )}
          {withEdit && isEditable && (
            <IconButton
              name={"edit"}
              onClick={handleEdit}
              wrapClass={"mx-2"}
              bg
            />
          )}
          {withRemove && isEditable && (
            <IconButton
              name={"remove"}
              onClick={handleRemove}
              bg
              disabled={removeLoading}
            />
          )}
        </>
      }
      {...rest}
    >
      {children}
    </DnDSectionContainer>
  )
}

WebsiteMenuItemsCard.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  withStatus: PropTypes.bool,
  onStatusChange: PropTypes.func,
  withEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  withRemove: PropTypes.bool,
  onRemove: PropTypes.func,
  removeLoading: PropTypes.bool,
  updateLoading: PropTypes.bool,
  children: PropTypes.any,
}
