import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Input, Label } from "reactstrap"

export const RadioButton = ({
  id,
  label,
  value,
  color,
  size,
  onChange,
  checked,
  disabled,
  className = "",
}) => {
  const renderLabel = label => {
    if (label) {
      if (typeof label === "string") {
        return label
      } else return label
    } else return <></>
  }

  return (
    <div
      className={classNames("form-check", {
        [`form-check-${color}`]: color,
        [`form-check_size_${size}`]: size,
        [className]: className,
      })}
    >
      <div className="form-check-wrap">
        <Input
          className={classNames("form-check-input", {})}
          type="radio"
          id={id}
          value={value}
          checked={checked}
          onChange={event => onChange(event)}
          disabled={disabled}
        />
        <Label className="form-check-label" htmlFor={id}>
          {renderLabel(label)}
        </Label>
      </div>
    </div>
  )
}

RadioButton.propTypes = {
  value: PropTypes.any,
  color: PropTypes.string,
  size: PropTypes.string,
  id: PropTypes.any,
  label: PropTypes.any,
  normalize: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
}
