import React from "react"
import {
    Switch,
    Route,
    Redirect,
    useRouteMatch,
    generatePath,
} from "react-router-dom"
import { Rating } from "../../Rating"

export const PanelRouting = () => {
    let { path } = useRouteMatch()
    return (
        <Switch>
            <Route exact path={path}>
                <Redirect to={generatePath(`${path}/rating`)} />
            </Route>
            <Route path={`${path}/rating`}>
                <Rating />
            </Route>
            <Route>
                <Redirect to={generatePath(`${path}/rating`)} />
            </Route>
        </Switch>
    )
}