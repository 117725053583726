import { takeEvery, fork, put, all } from "redux-saga/effects"

import { socketGeneral } from "./slice"
import { socketImportData } from "../import/actions"

function* resetState() {
  // clean import state
  yield put(socketImportData.cleanState({}))
}

export function* watchResetState() {
  yield takeEvery(socketGeneral.resetState, resetState)
}

function* generalSaga() {
  yield all([fork(watchResetState)])
}

export default generalSaga
