import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"
import { Row, Col } from "reactstrap"

import {
  ControllerHF,
  InputField,
  StripeFormElement,
  StripeSepaMandateInfo,
} from "components"
import { BILLING_PAYMENT_METHOD_TYPE } from "consts"

export const StripeSepaFormSection = ({
  defaultValues = {},
  mandate = true,
  ...props
}) => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <>
      <Row>
        <Col md={6}>
          <ControllerHF
            name={`name`}
            control={control}
            component={InputField}
            id={`name`}
            label={t("name")}
            placeholder={t("name")}
            defaultValue={defaultValues["name"]}
          />
        </Col>
        <Col md={6}>
          <ControllerHF
            name={`email`}
            control={control}
            component={InputField}
            id={`email`}
            label={t("field.label.email")}
            placeholder={t("field.placeholder.email")}
            type="email"
            defaultValue={defaultValues["email"]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <ControllerHF
            name={`address`}
            control={control}
            component={InputField}
            id={`address`}
            label={t("address")}
            placeholder={t("address")}
            defaultValue={defaultValues["address"]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <StripeFormElement
            state={props.state}
            type={BILLING_PAYMENT_METHOD_TYPE.SEPA_DEBIT}
            onChange={props.onStripeChange}
          />
        </Col>
      </Row>

      {mandate && (
        <Row>
          <Col>
            <div className="mt-3 mb-3">
              <StripeSepaMandateInfo />
            </div>
          </Col>
        </Row>
      )}
    </>
  )
}

StripeSepaFormSection.propTypes = {
  state: PropTypes.object,
  defaultValues: PropTypes.object,
  onStripeChange: PropTypes.func,
  mandate: PropTypes.bool,
}
