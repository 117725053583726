import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

export const Nav = ({
  page,
  onChange,
  count,
  title,
  withCounterLabel,
  color = "text-primary-green",
}) => {
  const { t } = useTranslation()

  const isFirstPage = useMemo(() => {
    return page === 1
  }, [page])

  const isLastPage = useMemo(() => {
    return page === count
  }, [page])

  const onPrev = () => {
    if (isFirstPage) {
      return
    }

    onChange(page - 1)
  }

  const onNext = () => {
    if (isLastPage) {
      return
    }

    onChange(page + 1)
  }

  const renderPrevButton = () => (
    <button
      onClick={onPrev}
      disabled={isFirstPage}
      className={"btn btn-sm ps-0"}
    >
      <i
        className={classNames("bx bxs-left-arrow", {
          [color]: color,
        })}
      />
    </button>
  )

  const renderNextButton = () => (
    <button
      onClick={onNext}
      disabled={isLastPage}
      className={"btn btn-sm pe-0"}
    >
      <i
        className={classNames("bx bxs-right-arrow ", {
          [color]: color,
        })}
      />
    </button>
  )

  const renderTitle = () => {
    let result = title

    if (withCounterLabel) {
      result = result + ` ${page} ${t("of-suffix")} ${count}`
    }

    return result
  }

  return (
    <div className={"data-slider-nav"}>
      {renderPrevButton()}

      <div
        className={classNames("data-slider-nav__title text-truncate", {
          [color]: color,
        })}
      >
        <span className={""}>{renderTitle()}</span>
      </div>

      {renderNextButton()}
    </div>
  )
}

Nav.propTypes = {
  page: PropTypes.number,
  onChange: PropTypes.func,
  count: PropTypes.number,
  title: PropTypes.string,
  withCounterLabel: PropTypes.bool,
  color: PropTypes.string,
}
