import { all, call, fork, put, takeLatest } from "redux-saga/effects"
import i18n from "i18n"
import { API } from "api"
import { socialCalendarPostItem } from "./slice"

export function* getItem({ payload: { id } }) {
  try {
    const response = yield call(API.socialCalendar.getPost, id)

    const { data } = response

    yield put(socialCalendarPostItem.getItemCompleted(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(socialCalendarPostItem.getItemRejected(message))
  }
}

export function* watchGetItem() {
  yield takeLatest(socialCalendarPostItem.getItem, getItem)
}

function* itemSaga() {
  yield all([fork(watchGetItem)])
}

export default itemSaga
