import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import i18n from "../../../i18n";

import { REGISTER_USER } from './actionTypes';
import {
    registerUserSuccess,
    registerUserError,
} from './actions';

import { API } from '../../../api'

function* registerUser({ payload: { params, onSubmitProps } }) {
    const { setErrors, setSubmitting } = onSubmitProps;

    try {
        const response = yield call(API.auth.signUp, params);

        const { data } = response;

        yield put(registerUserSuccess(data));

        setSubmitting(false);
    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : i18n.t('error');
        yield put(registerUserError({
            errors,
            message,
        }));

        setSubmitting(false);
        setErrors(errors);
    }
}

export function* watchUserRegister() {
    yield takeEvery(REGISTER_USER, registerUser);
}

function* registerSaga() {
    yield all([
        fork(watchUserRegister),
    ]);
}

export default registerSaga;