import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Modal, RatingForm } from "../../../../../../components"
import { employeeAddRating } from "../../../../../../store/actions"

const AddRatingComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const modalRef = useRef(null)
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    return () => {
      if (modalVisible) {
        dispatch(employeeAddRating.cleanState())
      }
    }
  }, [modalVisible])

  const handleAdd = e => {
    e.preventDefault()
    if (modalRef.current) {
      modalRef.current.show()
    }
  }

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.hide()
    }
  }

  const onSubmitSuccess = onSubmitProps => () => {
    const { setSubmitting, resetForm } = onSubmitProps
    setSubmitting(false)
    resetForm()
    props.onAddSuccess()
    closeModal()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setErrors, setSubmitting } = onSubmitProps
    setSubmitting(false)
    setErrors(errors)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      employeeAddRating.add({
        id: props.employee.id,
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  return (
    <React.Fragment>
      <Link to="#" className="text-secondary fw-normal" onClick={handleAdd}>
        {t("btn.new-rating")}
      </Link>

      <Modal
        ref={modalRef}
        title={t("employee-rating.title")}
        onVisibleChange={value => setModalVisible(value)}
        strapModalProp={{
          centered: true,
        }}
      >
        <RatingForm
          loading={props.loading}
          error={props.error}
          onSubmit={handleSubmit}
          onCancel={closeModal}
        />
      </Modal>
    </React.Fragment>
  )
}

AddRatingComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  onAddSuccess: PropTypes.func,

  employee: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.employee.details.data
  const { loading, error } = state.employee.rating.add
  return {
    employee: data,
    loading,
    error,
  }
}

export const AddRating = connect(mapStateToProps)(AddRatingComponent)
