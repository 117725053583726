import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const clientAddRatingSlice = createSlice({
  name: "clientAddRating",
  initialState,
  reducers: {
    add(state) {
      state.loading = true
      state.error = null
    },
    addCompleted(state) {
      state.loading = false
    },
    addRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})

export const clientAddRating = clientAddRatingSlice.actions

export default clientAddRatingSlice.reducer
