import React, { useEffect } from 'react';
import PropTypes from "prop-types"
import { Container } from "reactstrap";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { PageHeader, MegaTagTitle, RatingBadge } from "../../components"
import { EmployeeDetailsOverview, RemoveEmployee } from './components';
import { employeeDetailsFetchData, employeeDetailsDataCleanState } from '../../store/actions';

import * as HOC from '../../HOC';

const DataShower = HOC.withDataShower();

const EmployeeDetailsComponent = (props) => {
    const { t } = useTranslation();
    let { id } = useParams();

    useEffect(() => {
        props.employeeDetailsFetchData(id);

        return () => {
            props.employeeDetailsDataCleanState();
        }
    }, []);

    return (
        <React.Fragment>
            <MegaTagTitle title={'title.employee_details'} />

            <div className="page-content">
                <Container fluid>
                    <PageHeader
                        back
                        title={props.employee?.name}
                        headerRight={(
                            props.employee && (
                                <RemoveEmployee id={id} />
                            )
                        )}
                        headerLeft={(
                          props?.employee?.avg_rating && (
                            <RatingBadge value={props.employee.avg_rating} size={'md'} wrapClassName={'ms-3'} />
                          )
                        )}
                    />

                    <DataShower
                        isLoading={props.loading}
                        isFailed={props.error}
                        error={props.error}
                        customLoaderClass={'mt-5'}
                    >
                        {props.employee && (
                            <EmployeeDetailsOverview
                                data={props.employee}
                            />
                        )}
                    </DataShower>
                </Container>
            </div>
        </React.Fragment>
    );
};

EmployeeDetailsComponent.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.any,
    employee: PropTypes.object,

    employeeDetailsFetchData: PropTypes.func,
    employeeDetailsDataCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { loading, error, data } = state.employee.details.data;
    return {
        loading,
        error,
        employee: data,
    };
};

const mapDispatchToProps = {
    employeeDetailsFetchData,
    employeeDetailsDataCleanState,
};

export const EmployeeDetails = connect(mapStateToProps, mapDispatchToProps)(EmployeeDetailsComponent);