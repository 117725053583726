import { GENDER_FEMALE } from "../model"

export const MODEL_PANEL_MODELS_SIDEBAR_FILTER_INITIAL_VALUES = {
  keywords: null,
  genders: [GENDER_FEMALE],
  is_in_town: null,
  group: null,
}

export const MODEL_PANEL_MODELS_SIDEBAR = "MODEL_PANEL_MODELS_SIDEBAR"
export const MODEL_PANEL_BOOK_PHOTOS_PICKER_SIDEBAR =
  "MODEL_PANEL_BOOK_PHOTOS_PICKER_SIDEBAR"
