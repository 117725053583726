import { all } from 'redux-saga/effects';

import addSaga from './add/saga';
import listSaga from './list/sagas';
import detailsSaga from './details/sagas';
import modelsSaga from './models/sagas';
import draftSaga from './draft/sagas';
import statisticsSaga from './statistics/sagas';

function* packageSaga() {
    yield all([
        addSaga(),
        listSaga(),
        detailsSaga(),
        modelsSaga(),
        draftSaga(),
        statisticsSaga(),
    ])
}

export default packageSaga;