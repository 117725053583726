import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { InfoCard, PhoneFormat, SocialNetworkOverview } from "components"
import { YOUTUBE } from "consts"

export const ContactsInfoCard = props => {
  const { t } = useTranslation()

  return (
    <InfoCard
      title={t("contacts_&_social")}
      onEdit={props.onEdit}
      editMode
      className={"h-100 model-contacts-info"}
    >
      <div className={"model-contacts-info__content-title"}>
        {t("phone_number")}
      </div>

      <div className={"model-contacts-info__content"}>
        {!!props.data.phones.length
          ? props.data.phones.map((item, index) => (
              <div key={index} className={"d-flex flex-wrap"}>
                <div>
                  <PhoneFormat value={item.value} />

                  {item.country && ` - `}
                </div>

                {item.country && (
                  <div className={"ms-1"}>{item.country?.name}</div>
                )}
              </div>
            ))
          : "-"}
      </div>

      <div className={"mt-2 model-contacts-info__content-title"}>
        {t("email")}
      </div>

      <div className={"model-contacts-info__content"}>
        {!!props.data.emails.length
          ? props.data.emails.map((item, index) => (
              <div className={"text-break"} key={`email.${index}`}>
                {item.value}
              </div>
            ))
          : "-"}
      </div>

      {!!props.data.social_networks.length && (
        <div className={"mt-3"}>
          <SocialNetworkOverview
            items={props.data.social_networks}
            config={{
              [YOUTUBE]: {
                tooltip: false,
              },
            }}
          />
        </div>
      )}
    </InfoCard>
  )
}

ContactsInfoCard.propTypes = {
  data: PropTypes.object,
  onEdit: PropTypes.func,
}
