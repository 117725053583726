import React from "react"
import PropTypes from "prop-types"
import { FILE_MAX_SIZE } from "../../../../../../consts/common/maxFileSize"
import { FileUtils, ValidationsUtils } from "../../../../../../utils"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Col } from "reactstrap"
import { Button, ControllerHF } from "../../../../../../components"
import * as Yup from "yup"
import { LogoPickerField } from "../../../../../../components/FormElements/LogoPickerField"


const EditLogoForm = props => {
    const { t } = useTranslation()

    const { control, handleSubmit, setError, reset, watch } = useForm({
        defaultValues: {
            avatar: null,
            ...props.initialValues,
        },
        resolver: yupResolver(
            Yup.object().shape({
                avatar: Yup.mixed()
                    .nullable()
                    .test("maxSize", "field.error.file-size.max", value =>
                        ValidationsUtils.fileMaxSize(value, FILE_MAX_SIZE)
                    ),
            })
        ),
        mode: "onChange",
    })

    const onSubmit = values => {
        let params = {
            ...values,
        }
        if (values.avatar?.src) {
            onCancel()
        } else {
            props.onSubmit(params, { reset, setError })
        }
    }

    const onCancel = () => {
        props.onCancel()
    }
    const isPhoto = !!watch("avatar")

    return (
        <>
            {props.error && <Alert color="danger">{props.error.message}</Alert>}

            <form onSubmit={handleSubmit(onSubmit)}>
                <Col  md={4}>
                    <ControllerHF
                        name={"avatar"}
                        control={control}
                        component={LogoPickerField}
                        selectBtnIcon={isPhoto ? null : "bx bx-plus"}
                        selectBtnText={
                            isPhoto
                                ? t("agency.general.change-logo")
                                : t("agency.general.add-logo")
                        }
                        id={"avatar"}
                        type={"file"}
                        translateParams={{
                            text: `${FileUtils.formatBytes(FILE_MAX_SIZE)}`,
                        }}
                    />
                </Col>
                <div className="mt-3">
                    <div className="button-items">
                        <Button
                            submit
                            title={"btn.save"}
                            disabled={props.loading}
                            loading={props.loading}
                        />

                        <Button
                            title={"cancel"}
                            color="light"
                            outline
                            onClick={onCancel}
                        />
                    </div>
                </div>
            </form>
        </>
    )
}

EditLogoForm.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.object,
    initialValues: PropTypes.object,

    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
}

export default EditLogoForm
