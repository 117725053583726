import { combineReducers } from "redux"

import list from "./list/slice"
import payment from "./payment/reducers"
import data from "./data/reducers"

const statementReducer = combineReducers({
  list,
  payment,
  data,
})

export default statementReducer
