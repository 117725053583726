import {
    PASSWORD_RESET,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_ERROR,
    PASSWORD_RESET_CLEAN_STATE,
} from './actionTypes';

export const passwordReset = (params, onSubmitProps) => {
    return {
        type: PASSWORD_RESET,
        payload: { params, onSubmitProps },
    }
};

export const passwordResetSuccess = (data) => {
    return {
        type: PASSWORD_RESET_SUCCESS,
        payload: data,
    }
};

export const passwordResetError = (error) => {
    return {
        type: PASSWORD_RESET_ERROR,
        payload: error,
    }
};

export const passwordResetCleanState = () => {
    return {
        type: PASSWORD_RESET_CLEAN_STATE,
    };
};