import React from "react"
import PropTypes from "prop-types"
import { socialPostsCalendarHelper } from "helpers"
import { Image, PostPublicationStatusBadge } from "components"
import { InfoRow } from "./InfoRow"
import { AccountLink } from "./AccountLink"
import { DateUtils } from "utils"
import { isFacebookPost } from "helpers/socialPostsCalendar/postInfo"
import { IMAGE_CROP_TYPE } from "consts"

const { getPostTypeTitle, isInstagramPost } = socialPostsCalendarHelper.postInfo

export const PostInfo = ({ data }) => {
  return (
    <div className="table-responsive">
      <table className="table table-nowrap">
        <tbody>
          <InfoRow title={"type"} content={getPostTypeTitle(data.type)} />
          <InfoRow
            title={"photo"}
            content={
              <div className={"image-rect image-rect__xl"}>
                <Image
                  crops={data.fashion_model_photo?.crops}
                  alt={data.fashion_model_photo?.name}
                  src={data.fashion_model_photo?.attachment?.src}
                  type={
                    isInstagramPost(data?.type)
                      ? IMAGE_CROP_TYPE.INSTAGRAM
                      : undefined
                  }
                />
              </div>
            }
          />
          <InfoRow title={"description"} content={data.description} />
          <InfoRow
            title={"publication-date"}
            content={DateUtils.utcToLocale(
              data.publication_date,
              "DD/MM/YYYY HH:mm"
            )}
          />
          <InfoRow
            title={
              isFacebookPost(data.type) ? "facebook-page" : "instagram-account"
            }
            content={
              <AccountLink
                type={data.type}
                accountId={data.account_number}
                accountName={data.account_name}
              />
            }
          />
          <InfoRow
            title={"status"}
            content={
              <PostPublicationStatusBadge
                publicationStatus={data.publicationStatus}
              />
            }
          />

          <InfoRow
            title={"error-message"}
            content={data.error_message}
            isInvisible={!data.isError}
          />

          <InfoRow
            title={"likes-count"}
            content={data.remote_post_data?.like_count}
            isInvisible={!data.remote_post_data}
          />
          <InfoRow
            title={"comments-count"}
            content={data.remote_post_data?.comments_count}
            //remote_post_data is null if facebook post - we have no yet permissions to get comments count
            isInvisible={
              isFacebookPost(data.type) ? true : !data.remote_post_data
            }
          />
        </tbody>
      </table>
    </div>
  )
}

PostInfo.propTypes = {
  data: PropTypes.object,
}
