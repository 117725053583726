import { createSlice } from "@reduxjs/toolkit"

import { STATEMENT_TYPE } from "consts"

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  meta: null,
}

const modelStatementListSlice = createSlice({
  name: "modelStatementList",
  initialState,
  reducers: {
    getList(state) {
      state.listLoading = true
      state.listError = null
    },
    getListSuccess(state, action) {
      state.listLoading = false
      state.list = action.payload.data
      state.meta = action.payload.meta
    },
    getListError(state, action) {
      state.listLoading = false
      state.listError = action.payload
    },
    updateStatement(state, action) {
      // list can store duplicated id (id of statement and id of statement_payment)
      state.list = state.list.map(item => {
        if (
          item.id === action.payload.id &&
          item.type === STATEMENT_TYPE.STATEMENT
        ) {
          return {
            ...item,
            ...action.payload,
          }
        } else {
          return item
        }
      })
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getList
 * @property {function} getListSuccess
 * @property {function} getListError
 * @property {function} updateStatement
 * @property {function} cleanState
 */
export const modelStatementList = modelStatementListSlice.actions

export default modelStatementListSlice.reducer
