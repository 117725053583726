import React, { useRef, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Modal, IconButton } from "components"
import { EditEmployeeForm } from "./EditEmployeeForm"
import {
  employeeDetailsEditProfile,
  employeeDetailsEditProfileCleanState,
} from "store/actions"
import { employeeHelper } from "helpers"
import { ValidationsUtils } from "utils"
// import { FormatUtils } from "../../../../utils";

const EditEmployeeComponent = props => {
  const { t } = useTranslation()

  const modalRef = useRef(null)
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    return () => {
      if (modalVisible) {
        props.employeeDetailsEditProfileCleanState()
      }
    }
  }, [modalVisible])

  const handleEdit = () => {
    if (modalRef.current) {
      modalRef.current.show()
    }
  }

  const handleCancel = () => {
    if (modalRef.current) {
      modalRef.current.hide()
    }
  }

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    if (modalRef.current) {
      modalRef.current.hide()
    }
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    props.employeeDetailsEditProfile(
      props.employee.id,
      params,
      onSubmitSuccess(onSubmitProps),
      onSubmitError(onSubmitProps)
    )
  }

  return (
    <React.Fragment>
      <IconButton name={"edit"} onClick={handleEdit} bg />

      <Modal
        ref={modalRef}
        title={t("edit_employee")}
        onVisibleChange={value => setModalVisible(value)}
        strapModalProp={{
          centered: false,
        }}
      >
        <EditEmployeeForm
          loading={props.loading}
          error={props.error}
          initialValues={{
            email: props.employee.email,
            name: props.employee.name,
            // phone: FormatUtils.formatPhone(props.employee?.phone) || '',
            department: props.employee.department
              ? employeeHelper.general.getDepartment(
                  props.employee.department?.alias
                )
              : null,
            date_of_birth: props.employee.date_of_birth,
            address: props.employee.address,
            bank_name: props.employee.bank_name,
            bank_account_number: props.employee.bank_account_number,
            other_information: props.employee.other_information,
          }}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </Modal>
    </React.Fragment>
  )
}

EditEmployeeComponent.propTypes = {
  employee: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,

  employeeDetailsEditProfile: PropTypes.func,
  employeeDetailsEditProfileCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.employee.details.data
  const { loading, error } = state.employee.details.edit
  return {
    employee: data,
    loading,
    error,
  }
}

const mapDispatchToProps = {
  employeeDetailsEditProfile,
  employeeDetailsEditProfileCleanState,
}

export const EditEmployee = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEmployeeComponent)
