import React from "react"
import PropTypes from "prop-types"
import { UncontrolledTooltip } from "reactstrap"

import { SocialNetworkIcon } from "./SocialNetworkIcon"
import { commonHelper } from "helpers"
import { SOCIAL_NETWORK_LIST } from "consts"

export const SocialNetworkLink = ({
  name,
  size,
  value,
  config = { tooltip: true },
}) => {
  const { deeplink } = commonHelper.socialNetwork.getConfig(name)

  const link = deeplink ? `${deeplink}${value}` : value

  return (
    <div className={"social-network-overview__button-wrap"}>
      <a
        href={link}
        target={"_blank"}
        rel="noreferrer"
        id={`social-tooltip-${name}`}
      >
        <SocialNetworkIcon name={name} size={size} />
      </a>

      {config.tooltip && (
        <UncontrolledTooltip target={`social-tooltip-${name}`} autohide={false}>
          {value}
        </UncontrolledTooltip>
      )}
    </div>
  )
}

SocialNetworkLink.propTypes = {
  name: PropTypes.oneOf(SOCIAL_NETWORK_LIST),
  size: PropTypes.string,
  value: PropTypes.string,
  config: PropTypes.object,
}
