import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"
import { Clients } from "./Clients"
import { ClientPanel } from "../ClientPanel"

export const ClientsIndex = () => {
  let { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <Clients />
      </Route>
      <Route path={`${path}/:id`}>
        <ClientPanel />
      </Route>
      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  )
}
