import React from "react"
import PropTypes from "prop-types"
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom"
import { Overall } from "../../Overall"
import { Models } from "../../Models"
import { Clients } from "../../Clients"
import { Employees } from "../../Employees"
import { AnalyticsPanelLayout } from "../Panel/Layout"
import { analyticsHelper } from "helpers"
import { CLIENTS, EMPLOYEES, MODELS, OVERALL } from "consts"

export const AnalyticsPanelRouting = ({ graphs }) => {
  let { path } = useRouteMatch()

  let redirectPath = graphs => {
    return generatePath(
      `${path}/${analyticsHelper.permissions.generateRedirectPath(graphs)}`
    )
  }
  let renderTabRoute = tabName => {
    if (!analyticsHelper.permissions.hasTab(graphs, tabName)) {
      return null
    }
    switch (tabName) {
      case OVERALL: {
        return (
          <Route key={tabName} path={`${path}/overall`}>
            <Overall />
          </Route>
        )
      }
      case MODELS: {
        return (
          <Route key={tabName} path={`${path}/models`}>
            <Models />
          </Route>
        )
      }
      case CLIENTS: {
        return (
          <Route key={tabName} path={`${path}/clients`}>
            <Clients />
          </Route>
        )
      }
      case EMPLOYEES: {
        return (
          <Route key={tabName} path={`${path}/employees`}>
            <Employees />
          </Route>
        )
      }
    }
  }
  return (
    <AnalyticsPanelLayout>
      <Switch>
        <Route exact path={path}>
          <Redirect to={redirectPath(graphs)} />
        </Route>
        {[OVERALL, MODELS, CLIENTS, EMPLOYEES].map(tabName => {
          return renderTabRoute(tabName)
        })}
        <Route>
          <Redirect to={redirectPath(graphs)} />
        </Route>
      </Switch>
    </AnalyticsPanelLayout>
  )
}

AnalyticsPanelRouting.propTypes = {
  graphs: PropTypes.array,
}
