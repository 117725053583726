import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { Models } from "./Models"
import { useEventModelContractManager } from "components/Events/EventModelContract/EventModelContractManager/hooks"
import {
  EventModelContractManager,
  EventModelContractSendEmail,
} from "components/Events/EventModelContract"
import { useDrawer, useUserAccess } from "hooks"
import { FileUtils } from "utils"
import { DEPARTMENT_ACCOUNTING } from "consts"
import { calendarEventData } from "store"

export const ModelsContainer = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { isDirector, isDepartmentAllow } = useUserAccess()

  const onContractDownload = url => {
    FileUtils.downloadFileByLink(url)
  }

  // Send email contract
  const {
    hide: hideSendDrawer,
    show: showSendDrawer,
    visible: sendDrawerVisible,
    data: sendEmailData,
  } = useDrawer()

  const onSendSuccess = () => {
    hideSendDrawer()
  }

  const isSendAllow = isDirector() || isDepartmentAllow([DEPARTMENT_ACCOUNTING])
  // === //

  // Manage event model contract drawers (create, edit)
  const eventModelContractManager = useEventModelContractManager({
    initialState: {
      event: props.event,
    },
  })

  const onContractCreate = model => {
    eventModelContractManager.navigation.onScreenCreate(model)
  }

  const onContractEdit = model => {
    eventModelContractManager.navigation.onScreenEdit(model)
  }

  const onContractCreateSuccess = (model, contract) => {
    dispatch(
      calendarEventData.setModelContract({
        id: model.id,
        contract,
      })
    )
  }

  const onContractEditSuccess = (model, contract) => {
    dispatch(
      calendarEventData.setModelContract({
        id: model.id,
        contract,
      })
    )
  }

  const onContractRemoveSuccess = model => {
    dispatch(
      calendarEventData.setModelContract({
        id: model.id,
        contract: null,
      })
    )
  }
  // === //

  return (
    <>
      <Models
        data={props.event?.fashion_models}
        contractProps={{
          onCreate: onContractCreate,
          onEdit: onContractEdit,
          onRemoveSuccess: onContractRemoveSuccess,
          send: isSendAllow,
          onSend: showSendDrawer,
          onDownload: onContractDownload,
        }}
        event={props.event}
      />

      <EventModelContractManager
        {...eventModelContractManager}
        onCreateSuccess={onContractCreateSuccess}
        onEditSuccess={onContractEditSuccess}
      />

      <EventModelContractSendEmail
        visible={sendDrawerVisible}
        onSuccess={onSendSuccess}
        onClose={hideSendDrawer}
        eventId={props.event?.id}
        modelId={sendEmailData?.id}
      />
    </>
  )
}

ModelsContainer.propTypes = {
  event: PropTypes.object,
}
