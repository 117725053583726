import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Container, Row } from "reactstrap"
import { Drawer } from "../../../../../../components"
import { EditProfileForm } from "./EditProfileForm"
import { ValidationsUtils } from "../../../../../../utils"
import { agencyUpdateProfile } from "../../../../../../store/actions"
import { useCountryOptions } from "../../../../../../hooks"

const EditProfileComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const countryOptions = useCountryOptions({ visible: props.visible })

  const handleCloseDrawer = () => {
    props.onClose()
  }

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    handleCloseDrawer()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      agencyUpdateProfile.update({
        id: props.agency.id,
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  const onDestroy = () => {
    dispatch(agencyUpdateProfile.cleanState())
  }

  return (
    <React.Fragment>
      <Drawer
        title={t("general")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <Container fluid>
          <Row>
            <EditProfileForm
              initialValues={{
                name: props.agency?.name,
                website: props.agency?.website,
                note: props.agency?.note,
                client_id: props.agency?.billing_client,
                agency_commission: props.agency?.agency_commission,
                vat: props.agency?.vat,
                country_id: props.agency?.country,
              }}
              loading={props.loading}
              error={props.error}
              onSubmit={handleSubmit}
              onCancel={handleCloseDrawer}
              countryOptions={countryOptions}
              agencyId={props.agency?.id}
            />
          </Row>
        </Container>
      </Drawer>
    </React.Fragment>
  )
}

EditProfileComponent.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,

  agency: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.agency.profile.data
  const { loading, error } = state.agency.profile.update
  return {
    agency: data,
    loading,
    error,
  }
}

export const EditProfile = connect(mapStateToProps)(EditProfileComponent)
