import React, { useMemo } from "react"

import { useSelector } from "react-redux"
import { selectUserDepartmentPermission } from "store/selectors"
import { useUserAccess } from "hooks"

export const useDepartmentPermissionGuard = ({ permission }) => {
  const { isEmployee, isDirector } = useUserAccess()

  const allowed = useSelector(state =>
    selectUserDepartmentPermission(state, permission)
  )

  const status = useMemo(() => {
    if (isDirector()) return true

    return isEmployee() && allowed
  }, [isEmployee, isDirector, allowed])

  return {
    status: status,
  }
}
