import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { Col, Row } from "reactstrap"
import { ControllerHF } from "../../../../ReactHookForm"
import { DateInputField } from "../../../../FormElements"

const dateMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
const timeMask = [/\d/, /\d/, ":", /\d/, /\d/]

export const PeriodSection = props => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext()

  const [watchPeriodDateStart, watchPeriodDateEnd] = watch([
    "period_date_start",
    "period_date_end",
  ])

  return (
    <>
      <h4 className={"mb-3"}>{t("period")}</h4>

      <Row>
        <Col md={6}>
          <Row>
            <Col md={5}>
              <ControllerHF
                name={"period_date_start"}
                control={control}
                component={DateInputField}
                id={"period_date_start"}
                label={t("from")}
                placeholder={"DD/MM/YYYY"}
                mask={dateMask}
                openToDate={(!watchPeriodDateStart && watchPeriodDateEnd) || ""}
              />
            </Col>

            <Col md={5}>
              <ControllerHF
                name={"period_time_start"}
                control={control}
                component={DateInputField}
                id={"period_time_start"}
                label={t("hour")}
                placeholder={t("hour")}
                mask={timeMask}
                showTimeSelect
                showTimeSelectOnly
              />
            </Col>
          </Row>
        </Col>

        <Col md={6}>
          <Row>
            <Col md={5}>
              <ControllerHF
                name={"period_date_end"}
                control={control}
                component={DateInputField}
                id={"period_date_end"}
                label={t("to")}
                placeholder={"DD/MM/YYYY"}
                mask={dateMask}
                translateParams={{ date: t("from") }}
                openToDate={(!watchPeriodDateEnd && watchPeriodDateStart) || ""}
              />
            </Col>

            <Col md={5}>
              <ControllerHF
                name={"period_time_end"}
                control={control}
                component={DateInputField}
                id={"period_time_end"}
                label={t("hour")}
                placeholder={t("hour")}
                mask={timeMask}
                showTimeSelect
                showTimeSelectOnly
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

PeriodSection.propTypes = {}
