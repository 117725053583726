import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { usePopover, useSubmitHandler } from "hooks"
import { Popover } from "components"
import { addUserIdea } from "store"
import { SendUserIdeaForm } from "./SendUserIdeaForm"
import { IdeaIconButton } from "./IdeaIconButton"
import { ThankMessage } from "./ThankMessage"

const SendUserIdeaComponent = ({ btnWrapClass, loading, error }) => {
  const dispatch = useDispatch()

  const { popoverId, toggle, popoverOpen } = usePopover({
    id: "sendUserIdea",
  })

  const [isSuccessMessage, setIsSuccessMessage] = useState(false)

  const onSuccess = () => {
    setIsSuccessMessage(true)
  }

  useEffect(() => {
    if (isSuccessMessage) {
      setTimeout(() => {
        toggle()
      }, 2000)
    }
  }, [isSuccessMessage])

  useEffect(() => {
    if (!popoverOpen) {
      dispatch(addUserIdea.cleanState())
      //Too avoid flicker
      setTimeout(() => setIsSuccessMessage(false), 100)
    }
  }, [popoverOpen])

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        addUserIdea.add({
          params,
          onSuccess,
          onError,
        })
      )
    },
    () => {
      onSuccess()
    }
  )

  return (
    <>
      <IdeaIconButton
        id={popoverId}
        onClick={toggle}
        wrapClass={btnWrapClass}
        isPopoverOpen={popoverOpen}
      />
      <Popover
        popoverId={popoverId}
        popoverOpen={popoverOpen}
        toggle={toggle}
        placement={"bottom"}
        popperClassName={"popover-block popover-block__big"}
      >
        {!isSuccessMessage ? (
          <SendUserIdeaForm
            loading={loading}
            error={error}
            onCancel={toggle}
            onSubmit={handleSubmit}
            initialValues={{}}
          />
        ) : (
          <ThankMessage />
        )}
      </Popover>
    </>
  )
}

SendUserIdeaComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,

  btnWrapClass: PropTypes.string,
}

const mapStateToProps = state => {
  const { loading, error } = state.userIdeas.add
  return {
    loading,
    error,
  }
}

export const SendUserIdea = connect(mapStateToProps)(SendUserIdeaComponent)
