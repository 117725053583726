import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { FormatUtils } from "utils"

export const CountBadge = ({ color = "secondary", count, children }) => {
  return (
    <div className={classNames("count-badge", {})}>
      {children}
      {typeof count == "number" && (
        <div className={"count-badge__wrapper"}>
          <span
            className={classNames("count-badge__content", {
              [`count-badge__content_${color}`]: color,
            })}
          >
            {FormatUtils.formatNumberToCompact(count)}
          </span>
        </div>
      )}
    </div>
  )
}

CountBadge.propTypes = {
  children: PropTypes.any,
  count: PropTypes.number,
  color: PropTypes.string,
}
