import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { Row, Col } from "reactstrap"
import { TimePeriodFieldArrayHF } from "../../../../ReactHookForm"

export const TimeSection = props => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <Row>
      <Col md={12}>
        <TimePeriodFieldArrayHF
          name={"times"}
          control={control}
          limit={4}
          meta={{}}
        />
      </Col>
    </Row>
  )
}

TimeSection.propTypes = {}
