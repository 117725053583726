import { createAction } from "@reduxjs/toolkit"

const SOCKET_ON_CHAT_GROUP_PARTICIPANT_REMOVED =
  "socket/onChatGroupParticipantRemoved"

export const socketOnChatGroupParticipantRemoved = createAction(
  SOCKET_ON_CHAT_GROUP_PARTICIPANT_REMOVED,
  function prepare(data) {
    return {
      payload: {
        data,
      },
    }
  }
)
