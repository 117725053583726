import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Spinner } from "reactstrap"
import { Image } from "components"

export const PhotosGridItem = props => {
  const { t } = useTranslation()

  const onSelect = (e, item) => {
    e.preventDefault()

    if (props.markAvatar) {
      return
    }

    props.onSelect(item)
  }

  return (
    <div className="model-uploaded-photo-card">
      <Link to={"#"} onClick={e => onSelect(e, props.item)}>
        <div className={"model-uploaded-photo-card__photo-block"}>
          <Image
            crops={props.item?.crops}
            src={props.item?.attachment?.src}
            alt={"model-photo"}
          />

          {props.markAvatar && (
            <div className="model-uploaded-photo-card__avatar-badge-block">
              <div className={"model-uploaded-photo-card__avatar-badge"}>
                <i className="align-middle bx bx-user" />
              </div>
            </div>
          )}

          {props.loading && (
            <div className={"avatar-loading"}>
              <Spinner size={"sm"} color="primary-green" />
            </div>
          )}
        </div>
      </Link>
    </div>
  )
}

PhotosGridItem.propTypes = {
  item: PropTypes.object,
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  markAvatar: PropTypes.bool,
}
