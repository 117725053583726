import { combineReducers } from "redux"

import profile from "./profile/reducers"
import avatar from "./avatar/reducers"
import contacts from "./contacts/reducers"
import features from "./features/reducers"
import logistics from "./logistics/reducers"
import documents from "./documents/reducers"
import work from "./work/reducers"
import agency from "./agency/reducers"

const generalReducer = combineReducers({
  profile,
  avatar,
  contacts,
  features,
  logistics,
  documents,
  work,
  agency,
})

export default generalReducer
