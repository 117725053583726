import React from 'react'
import PropTypes from "prop-types"
import { Alert } from "reactstrap"
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { FormikTextInputFiled as TextInputFiled } from '../../Formik';
import { Button } from '../../Buttons';
import { FormikRatingRadioGroupFiled } from '../../Formik';
import { NormalizeUtils } from "../../../utils"

export const RatingForm = (props) => {
    const { t } = useTranslation();

    const onSubmit = (values, onSubmitProps) => {
        let params = {};

        if (values.message) {
            params.message = values.message;
        }

        if (values.value) {
            params.value = values.value;
        }

        props.onSubmit(params, onSubmitProps);
    };

    return (
        <Formik
            initialValues={{
                value: 0,
                message: '',
            }}
            validationSchema={Yup.object().shape({
                value: Yup.number(),
                message: Yup.string()
                    .when('value', {
                        is: 0,
                        then: Yup.string().required('field.error.required')
                    }),
            })}
            onSubmit={onSubmit}
            validateOnChange={true}
            validateOnBlur={false}
            validateOnMount={true}
            enableReinitialize={true}
        >
            {({
                  isSubmitting,
                  ...rest
              }) => {
                return (
                    <>
                        {props.error && (
                            <Alert color="danger">
                                {props.error.message}
                            </Alert>
                        )}

                        <Form className="form-horizontal rating-form" noValidate={true}>

                            <FormikRatingRadioGroupFiled
                                id={'rating'}
                                name={'value'}
                                normalize={NormalizeUtils.toNumber}
                                className={'mb-3'}
                            />

                            <TextInputFiled
                                name="message"
                                label={t('field.label.comment')}
                                placeholder={t('field.placeholder.comment')}
                                id={'message'}
                                type="textarea"
                            />

                            <div className="mt-3">
                                <div className="button-items">
                                    <Button
                                        submit
                                        title={'btn.save'}
                                        disabled={isSubmitting || props.loading}
                                        loading={props.loading}
                                    />

                                    <Button
                                        title={'cancel'}
                                        color="light"
                                        outline
                                        onClick={props.onCancel}
                                    />
                                </div>
                            </div>
                        </Form>
                    </>
                )
            }}
        </Formik>
    );
};

RatingForm.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.object,
    initialValues: PropTypes.object,

    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
}