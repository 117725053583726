import { takeEvery, fork, all, call } from "redux-saga/effects"

import { socketOnChatGroupRemoved } from "./actions"
import { onChatRemove } from "../../../../chat/chats/list/saga"
import { onConversationClose } from "../../../../chat/conversation/data/saga"

function* onRemove({ payload: { data } }) {
  const { chat_id } = data

  yield call(onChatRemove, {
    payload: {
      id: chat_id,
    },
  })

  yield call(onConversationClose, {
    payload: {
      id: chat_id,
    },
  })
}

export function* watchOnRemove() {
  yield takeEvery(socketOnChatGroupRemoved, onRemove)
}

function* removeSaga() {
  yield all([fork(watchOnRemove)])
}

export default removeSaga
