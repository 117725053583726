import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { Col, Row } from "reactstrap"
import { ControllerHF } from "../../../../ReactHookForm"
import { ComboboxFormField } from "../../../../FormElements"
import { useFetchAgencies, useFetchData } from "../../../../../hooks"
import { LocationSection } from "../LocationSection"
import { OverlayBlockLoading } from "../../../../OverlayLoading"
import { API } from "../../../../../api"
import { getLocationsDefaultValues } from "../../helpers"
import { eventsCalendarHelper } from "../../../../../helpers"

export const AgencySection = props => {
  const { t } = useTranslation()

  const { control, setValue, watch } = useFormContext()

  const agencies = useFetchAgencies()
  const fetch = useFetchData()

  useEffect(() => {
    agencies.fetchData()
  }, [])

  const watchAgency = watch("agency")

  const onAgencyChange = value => {
    setValue("agency", value)

    if (value && value?.id !== watchAgency?.id) {
      const { id } = value

      setValue("locations", getLocationsDefaultValues())
      onFetchAgency(id)
    }
  }

  const onFetchAgency = async id => {
    const request = API.agency.getAgency(id)
    const agencyData = await fetch.fetchData({
      request,
    })

    if (agencyData?.data) {
      const locations =
        eventsCalendarHelper.eventForm.getEventLocationsFromEntity(
          agencyData?.data
        )

      // use map for each object key to set all fields values
      // if setValue("locations", locations), country not set value
      locations.forEach((item, index) => {
        Object.entries(item).forEach(entry => {
          const [key, value] = entry
          setValue(`locations.${index}.${key}`, value)
        })
      })
    }
  }

  const getAgencyOptionLabel = option => {
    let result = option.name
    const country = option?.country?.name
    if (country) {
      result = `${result} - ${country}`
    }

    return result
  }

  return (
    <Row>
      <Col md={12}>
        <Row>
          <Col md={4}>
            <ControllerHF
              name={"agency"}
              control={control}
              component={ComboboxFormField}
              id={"agency"}
              label={t("agency")}
              placeholder={t("agency")}
              options={agencies.data}
              getOptionLabel={option => getAgencyOptionLabel(option)}
              getOptionValue={option => option.id}
              selectProps={{
                isClearable: true,
              }}
              onChange={onAgencyChange}
            />
          </Col>
        </Row>

        <OverlayBlockLoading isLoading={fetch.loading}>
          <LocationSection />
        </OverlayBlockLoading>
      </Col>
    </Row>
  )
}

AgencySection.propTypes = {}
