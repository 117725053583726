import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFirstMountState } from "react-use"
import toastr from "toastr"
import * as HOC from "HOC"
import { useFetchData } from "hooks"
import { BookOverviewCard } from "../BookOverviewCard"
import { API } from "api"
import { DataSlider } from "components"

const DataShower = HOC.withDataShowerOverlay()

export const BooksSlider = props => {
  const { t } = useTranslation()

  const isFirstMount = useFirstMountState()
  const { loading, data, fetchData } = useFetchData()

  const [page, setPage] = useState(1)
  const [book, setBook] = useState(props.data?.first_book || {})

  const onPageChange = value => {
    setPage(value)
  }

  useEffect(() => {
    if (!isFirstMount) {
      onFetchData()
    }
  }, [page])

  const onFetchData = async () => {
    const params = {
      per_page: 1,
      page,
      ...props.customParams,
    }
    const request = API.model.getModelBooks(props.data.id, params)
    await fetchData({
      request,
      onError: onFetchError,
    })
  }

  const onFetchError = () => {
    toastr.error(t(props.error))
  }

  useEffect(() => {
    if (!isFirstMount) {
      const book = data?.data[0]
      setBook(book)
    }
  }, [data?.data])
  return (
    <div className={"w-100"}>
      <DataShower isLoading={loading} isFailed={null} error={null}>
        <DataSlider
          title={props.data?.name}
          onPageChange={onPageChange}
          currentPage={page}
          count={props.data?.books_count}
          navTitle={t("book")}
          withCounterLabel
          renderSlide={() => (
            <BookOverviewCard
              key={`book-card-${book?.id}`}
              crops={book?.cover?.photo?.crops}
              cover={book?.cover?.photo?.attachment?.src}
              size={"sm"}
              actions={props.actions(book)}
            />
          )}
        />
      </DataShower>
    </div>
  )
}

BooksSlider.propTypes = {
  data: PropTypes.object,
  actions: PropTypes.any,
  error: PropTypes.string,
  customParams: PropTypes.any,
}
