import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const calendarEventReopenSlice = createSlice({
  name: "calendarEventReopen",
  initialState,
  reducers: {
    reopen(state) {
      state.loading = true
      state.error = null
    },
    reopenSuccess(state) {
      state.loading = false
    },
    reopenError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} reopen
 * @property {function} reopenSuccess
 * @property {function} reopenError
 * @property {function} cleanState
 */
export const calendarEventReopen = calendarEventReopenSlice.actions

export default calendarEventReopenSlice.reducer
