export const EVENT_INFO_DETAILS_ALIAS = {
  TYPE: "TYPE",
  DIRECT_BOOKING: "DIRECT_BOOKING",
  DATE: "DATE",
  PERIOD: "PERIOD",
  DEPARTURE: "DEPARTURE",
  ARRIVAL: "ARRIVAL",
  TIME: "TIME",
  GENDER: "GENDER",
  SERVICE_CATEGORY: "SERVICE_CATEGORY",
  SERVICE_NAME: "SERVICE_NAME",
  SERVICE_DETAILS: "SERVICE_DETAILS",
  TRAVEL_BY: "TRAVEL_BY",
  TITLE: "TITLE",
  DESCRIPTION: "DESCRIPTION",
  CLIENT: "CLIENT",
  AGENCY: "AGENCY",
  NOTE_FOR_MODEL: "NOTE_FOR_MODEL",
  NOTE_FOR_AGENCY: "NOTE_FOR_AGENCY",
  FAMILY: "FAMILY",
  PRODUCT: "PRODUCT",
  NOTE_MODELS: "NOTE_MODELS",
  NOTE_AGENCY: "NOTE_AGENCY",
}
