import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useConfirmModal } from "hooks"
import { useDispatch, useSelector } from "react-redux"

import { ConfirmPopup, Button } from "components"
import { billingSubscriptionCancel } from "store/actions"

export const SubscriptionCancel = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { loading } = useSelector(state => state.billing.subscription.cancel)

  const { modalRef, showModal, closeModal, handleConfirm } = useConfirmModal(
    () => {
      dispatch(billingSubscriptionCancel.cancel())
    },
    () => {}
  )

  useEffect(() => {
    return () => {
      dispatch(billingSubscriptionCancel.cleanState())
    }
  }, [])

  return (
    <div>
      <Button
        title={"cancel"}
        onClick={showModal}
        outline
        color={"secondary"}
        loading={loading}
        disabled={loading}
        type={"button"}
        size={"btn-sm"}
      />

      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
        questionText={"confirm-sure"}
      />
    </div>
  )
}

SubscriptionCancel.propTypes = {}
