import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Drawer } from "../../../../../../components"
import { Container, Row } from "reactstrap"
import EditLogoForm from "./EditLogoForm"
import { ValidationsUtils } from "../../../../../../utils"
import { useTranslation } from "react-i18next"
import { agencyAddLogo, agencyRemoveLogo } from "../../../../../../store/actions"

const EditLogoContainerComponent = props => {
    const dispatch = useDispatch()

    const { t } = useTranslation()
    const handleCloseDrawer = () => {
        props.onClose()
    }
    const onSubmitSuccess = onSubmitProps => () => {
        const { reset } = onSubmitProps
        reset()
        props.onClose()
    }

    const onSubmitError = onSubmitProps => errors => {
        const { setError } = onSubmitProps
        ValidationsUtils.setServerSideErrors(errors, setError)
    }

    const handleSubmit = (params, onSubmitProps) => {
        if (params.avatar) {
            dispatch(
                agencyAddLogo.add({
                    id: props.agency.id,
                    params,
                    onSuccess: onSubmitSuccess(onSubmitProps),
                    onError: onSubmitError(onSubmitProps),
                })
            )
        } else {
            dispatch(
                agencyRemoveLogo.remove({
                    id: props.agency.id,
                    onSuccess: onSubmitSuccess(onSubmitProps),
                    onError: onSubmitError(onSubmitProps),
                })
            )
        }
    }

    const onDestroy = () => {
        dispatch(agencyAddLogo.cleanState())
    }
    return (
        <>
            <Drawer
                title={t("agency.general.edit-logo")}
                visible={props.visible}
                onClose={handleCloseDrawer}
                placement={"right"}
                className={"drawer-container"}
                destroyOnClose
                onDestroy={onDestroy}
            >
                <Container fluid>
                    <Row>
                        <EditLogoForm
                            error={props.error}
                            loading={props.loading}
                            initialValues={{
                                avatar: props.agency.avatar,
                            }}
                            onCancel={handleCloseDrawer}
                            onSubmit={handleSubmit}
                        />
                    </Row>
                </Container>
            </Drawer>
        </>
    )
}

EditLogoContainerComponent.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    agency: PropTypes.object,

    loading: PropTypes.bool,
    error: PropTypes.object,
}

const mapStateToProps = state => {
    const { data } = state.agency.profile.data
    const { loading, error } = state.agency.logo.add
    return {
        loading,
        error,
        agency: data,
    }
}

export const EditLogo = connect(mapStateToProps)(EditLogoContainerComponent)
