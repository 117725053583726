import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Drawer } from "../../../../../../components"
import { Container, Row } from "reactstrap"
import { ValidationsUtils } from "../../../../../../utils"
import { DocumentsForm } from "../DocumentsForm"
import { useTranslation } from "react-i18next"
import { clientAddDocument } from "../../../../../../store/actions"

const AddDocumentContainerComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const handleCloseDrawer = () => {
    props.onClose()
  }
  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    props.onAddSuccess()
    handleCloseDrawer()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      clientAddDocument.add({
        id: props.client.id,
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  const onDestroy = () => {
    dispatch(clientAddDocument.cleanState())
  }
  return (
    <>
      <Drawer
        title={t("documents")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <Container fluid>
          <Row>
            <DocumentsForm
              onSubmit={handleSubmit}
              onCancel={handleCloseDrawer}
              initialValues={{}}
              loading={props.loading}
              error={props.error}
            />
          </Row>
        </Container>
      </Drawer>
    </>
  )
}

AddDocumentContainerComponent.propTypes = {
  visible: PropTypes.bool,
  onAddSuccess: PropTypes.func,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
  client: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.client.profile.data
  const { loading, error } = state.client.documents.add
  return {
    client: data,
    loading,
    error,
  }
}

export const AddDocument = connect(mapStateToProps)(
  AddDocumentContainerComponent
)
