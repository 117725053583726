import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { Col, Row } from "reactstrap"
import { ControllerHF } from "../../../../ReactHookForm"
import { DateInputField, InputField } from "../../../../FormElements"

const dateMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
const timeMask = [/\d/, /\d/, ":", /\d/, /\d/]

export const DepartureSection = ({ place = true }) => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext()

  const [watchDepartureDate, watchArrivalDate] = watch([
    "departure_date",
    "arrival_date",
  ])

  return (
    <>
      <h4 className={"mb-3"}>{t("departure")}</h4>

      <Row>
        {place && (
          <Col md={4}>
            <ControllerHF
              name={"departure_place"}
              control={control}
              component={InputField}
              id={"departure_place"}
              label={t("place")}
              placeholder={t("place")}
            />
          </Col>
        )}

        <Col md={2}>
          <ControllerHF
            name={"departure_date"}
            control={control}
            component={DateInputField}
            id={"departure_date"}
            label={t("date")}
            placeholder={"DD/MM/YYYY"}
            mask={dateMask}
            openToDate={(!watchDepartureDate && watchArrivalDate) || ""}
          />
        </Col>

        <Col md={2}>
          <ControllerHF
            name={"departure_time"}
            control={control}
            component={DateInputField}
            id={"departure_time"}
            label={t("hour")}
            placeholder={t("hour")}
            mask={timeMask}
            showTimeSelect
            showTimeSelectOnly
          />
        </Col>
      </Row>
    </>
  )
}

DepartureSection.propTypes = {
  place: PropTypes.bool,
}
