import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Card, CardBody } from "reactstrap"

import { TabNavigator } from "components"
import { SIDEBAR_CONTENT } from "../../consts"
import { ChatsContainer } from "../ChatsContainer"
import { ContactsContainer } from "../ContactsContainer"

const { CHATS, CONTACTS } = SIDEBAR_CONTENT

export const Content = props => {
  const { t } = useTranslation()

  const [activeContent, setActiveContent] = useState(CHATS)

  return (
    <div className="chat-leftsidebar-nav">
      <Card className="bg-neutral">
        <CardBody>
          <TabNavigator
            activeTab={activeContent}
            onTabChange={tab => setActiveContent(tab)}
            tabBarType={"nav"}
            tabBarClassName={"nav-justified"}
            tabs={[
              {
                label: t("chat"),
                value: CHATS,
                render: () => <ChatsContainer />,
              },
              {
                label: t("contacts"),
                value: CONTACTS,
                render: () => <ContactsContainer />,
              },
            ]}
          />
        </CardBody>
      </Card>
    </div>
  )
}

Content.propTypes = {}
