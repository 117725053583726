import { all } from "redux-saga/effects"

import removeSaga from "./remove/saga"
import listSaga from "./list/saga"

function* requestsSaga() {
  yield all([removeSaga(), listSaga()])
}

export default requestsSaga
