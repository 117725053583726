import axios from "axios"

class CommonAPI {
  getStatic = async params => {
    return axios.get(`api/static/${params}`)
  }

  getCountries = async params => {
    return axios.get("api/countries", { params })
  }

  getMeasurementsOptions = async params => {
    return axios.get("api/measurements", { params })
  }

  getEmails = async params => {
    return axios.get("api/emails", { params })
  }
  getDepartments = async params => {
    return axios.get("api/departments", { params })
  }

  getStaticPageByAlias = async pageAlias => {
    return axios.get(`api/static-pages/${pageAlias}`)
  }
}

export const common = new CommonAPI()
