import { combineReducers } from "@reduxjs/toolkit"

import list from "./list/slice"
import send from "./send/slice"
import read from "./read/slice"

const messagesReducer = combineReducers({
  list,
  send,
  read,
})

export default messagesReducer
