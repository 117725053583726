import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from "toastr";

import i18n from '../../../../../i18n';
import { MODEL_PANEL_GENERAL_ADD_AGENCY } from './actionTypes';
import { modelPanelGeneralAddAgencySuccess, modelPanelGeneralAddAgencyError } from './actions';

import { API } from '../../../../../api';

function* addAgency({ payload: { id, params, onSuccess, onError } }) {
    try {
        const response = yield call(API.model.addModelAgency, id, params);

        const { data } = response;

        yield put(modelPanelGeneralAddAgencySuccess());

        toastr.success(i18n.t('model-agency.add.success'));

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        const errors = error.response?.data?.errors || {};
        const message = error.response?.data?.message || i18n.t('error');

        yield put(modelPanelGeneralAddAgencyError({
            errors,
            message
        }));

        if (onError) {
            onError(errors);
        }
    }
}

export function* watchAddAgency() {
    yield takeEvery(MODEL_PANEL_GENERAL_ADD_AGENCY, addAgency);
}

function* addSaga() {
    yield all([
        fork(watchAddAgency),
    ]);
}

export default addSaga;