import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { SocialNetworkLink } from "./SocialNetworkLink"
import { SOCIAL_NETWORK_LIST } from "consts"
import { CountBadge } from "components"

export const SocialNetworkOverview = ({ items, config }) => {
  const renderItem = name => {
    const item = _.find(items, { name: name })

    if (item) {
      return (
        <CountBadge key={name} count={item.followers_count}>
          <SocialNetworkLink
            name={name}
            value={item.value}
            config={config?.[name]}
          />
        </CountBadge>
      )
    }

    return null
  }

  return (
    <div className={"social-network-overview"}>
      {SOCIAL_NETWORK_LIST.map(name => renderItem(name))}
    </div>
  )
}

SocialNetworkOverview.propTypes = {
  items: PropTypes.array,
  config: PropTypes.object,
}
