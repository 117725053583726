import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
  loading: false,
  error: null,
}

const websiteCurrentPageDataSlice = createSlice({
  name: "websiteCurrentPageData",
  initialState,
  reducers: {
    getData(state) {
      state.loading = true
      state.error = null
    },
    getDataCompleted(state, action) {
      state.loading = false
      state.data = action.payload.data
    },
    getDataRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    updateData(state, action) {
      state.data = { ...state.data, ...action.payload }
    },
    removeAttachment(state, action) {
      state.data.template_values.attachments =
        state.data.template_values.attachments.filter(
          attachment => attachment.id !== action.payload.id
        )
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getData
 *  @property {function} getDataCompleted
 *  @property {function} getDataRejected
 *  @property {function} updateData
 *  @property {function} setAttachmentProgress
 *  @property {function} cleanState
 */
export const websiteCurrentPageData = websiteCurrentPageDataSlice.actions

export default websiteCurrentPageDataSlice.reducer
