import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  page: 1,
  meta: null,
  isFetched: false,
  filters: {
    values: {
      keywords: null,
      is_in_town: null,
    },
  },
}

const websiteModelsListSlice = createSlice({
  name: "websiteModelsList",
  initialState,
  reducers: {
    getList(state, action) {
      state.listLoading = true
      state.listError = null
    },
    getListCompleted(state, action) {
      state.listLoading = false
      state.list = action.payload.data
      state.meta = action.payload.meta
      state.isFetched = true
    },
    getListRejected(state, action) {
      state.listLoading = false
      state.listError = action.payload
    },
    changeFilter(state, action) {
      state.page = initialState.page
      state.filters.values = {
        ...state.filters.values,
        ...action.payload,
      }
    },
    setPage(state, action) {
      state.page = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getList
 * @property {function} getListCompleted
 * @property {function} getListRejected
 *  @property {function} setPage
 *  @property {function} changeFilter
 *  @property {function} cleanState
 */
export const websiteModelsList = websiteModelsListSlice.actions

export default websiteModelsListSlice.reducer
