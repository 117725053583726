import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import i18n from "../../../i18n";

import { FORGET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";

import { API } from '../../../api';

function* forgetUser({ payload: { params, onSubmitProps} }) {
    const { setErrors, setSubmitting } = onSubmitProps;

    try {
        const response = yield call(API.auth.forgotPassword, params);
        const { data } = response;

        yield put(
            userForgetPasswordSuccess(
                data?.message ? data?.message : "Reset link are send to your mailbox, check there first"
            )
        );

        setSubmitting(false);
    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : i18n.t('error');

        yield put(userForgetPasswordError({
            errors,
            message,
        }));

        setSubmitting(false);
        setErrors(errors);
    }
}

export function* watchUserPasswordForget() {
    yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
    yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
