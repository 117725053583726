import {
    MODEL_PANEL_GENERAL_DOCUMENT_REMOVE_ATTACHMENT,
    MODEL_PANEL_GENERAL_DOCUMENT_REMOVE_ATTACHMENT_SUCCESS,
    MODEL_PANEL_GENERAL_DOCUMENT_REMOVE_ATTACHMENT_ERROR,
    MODEL_PANEL_GENERAL_DOCUMENT_REMOVE_ATTACHMENT_CLEAN_STATE,
} from './actionTypes';

const initialState = {
    // pending: [],
};

const remove = (state = initialState, action) => {
    switch (action.type) {
        case MODEL_PANEL_GENERAL_DOCUMENT_REMOVE_ATTACHMENT:
            state = {
                ...state,
                // pending: [...state.pending, action.payload.fieldId],
            };
            break;

        case MODEL_PANEL_GENERAL_DOCUMENT_REMOVE_ATTACHMENT_SUCCESS:
            state = {
                ...state,
                // pending: state.pending.filter((item) => item !== action.payload.fieldId),
            };
            break;

        case MODEL_PANEL_GENERAL_DOCUMENT_REMOVE_ATTACHMENT_ERROR:
            state = {
                ...state,
                // pending: state.pending.filter((item) => item !== action.payload.fieldId),
            };
            break;

        case MODEL_PANEL_GENERAL_DOCUMENT_REMOVE_ATTACHMENT_CLEAN_STATE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default remove;