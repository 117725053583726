import {
  MODEL_PANEL_MODELS_SIDEBAR_FETCH_LIST,
  MODEL_PANEL_MODELS_SIDEBAR_FETCH_LIST_SUCCESS,
  MODEL_PANEL_MODELS_SIDEBAR_FETCH_LIST_ERROR,
  MODEL_PANEL_MODELS_SIDEBAR_LIST_CLEAN_STATE,
  MODEL_PANEL_MODELS_SIDEBAR_LIST_SET_PAGE,
  MODEL_PANEL_MODELS_SIDEBAR_LIST_CHANGE_SEARCH_INPUT,
  MODEL_PANEL_MODELS_SIDEBAR_LIST_CHANGE_FILTER,
  MODEL_PANEL_MODELS_SIDEBAR_LIST_RESET_FILTER,
  MODEL_PANEL_MODELS_SIDEBAR_LIST_RESET_FETCHED_DATA,
  MODEL_PANEL_MODELS_SIDEBAR_LIST_SYNC,
} from "./actionTypes"

export const modelPanelModelsSidebarFetchList = params => {
  return {
    type: MODEL_PANEL_MODELS_SIDEBAR_FETCH_LIST,
    payload: { params },
  }
}

export const modelPanelModelsSidebarFetchListSuccess = data => {
  return {
    type: MODEL_PANEL_MODELS_SIDEBAR_FETCH_LIST_SUCCESS,
    payload: data,
  }
}

export const modelPanelModelsSidebarFetchListError = data => {
  return {
    type: MODEL_PANEL_MODELS_SIDEBAR_FETCH_LIST_ERROR,
    payload: data,
  }
}

export const modelPanelModelsSidebarListCleanState = () => {
  return { type: MODEL_PANEL_MODELS_SIDEBAR_LIST_CLEAN_STATE }
}

export const modelPanelModelsSidebarListSetPage = data => {
  return {
    type: MODEL_PANEL_MODELS_SIDEBAR_LIST_SET_PAGE,
    payload: data,
  }
}

export const modelPanelModelsSidebarListChangeSearchInput = value => {
  return {
    type: MODEL_PANEL_MODELS_SIDEBAR_LIST_CHANGE_SEARCH_INPUT,
    payload: value,
  }
}

export const modelPanelModelsSidebarListChangeFilter = data => {
  return {
    type: MODEL_PANEL_MODELS_SIDEBAR_LIST_CHANGE_FILTER,
    payload: data,
  }
}

export const modelPanelModelsSidebarListResetFilter = () => {
  return {
    type: MODEL_PANEL_MODELS_SIDEBAR_LIST_RESET_FILTER,
  }
}

export const modelPanelModelsSidebarListResetFetchedData = () => {
  return { type: MODEL_PANEL_MODELS_SIDEBAR_LIST_RESET_FETCHED_DATA }
}

export const modelPanelModelsSidebarListSync = () => {
  return { type: MODEL_PANEL_MODELS_SIDEBAR_LIST_SYNC }
}
