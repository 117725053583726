import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { Modal, Button } from "components"
import { Input } from "./Input"
import { PackageSharedLinkDataLoader } from "./PackageSharedLinkDataLoader"

export const PackageSharedLink = ({ id, modalRef, onCancel }) => {
  const { t } = useTranslation()

  const { data } = useSelector(state => state.package.details.url.data)

  const [modalVisible, setModalVisible] = useState(false)

  return (
    <Modal
      ref={modalRef}
      title={t("package_link")}
      onVisibleChange={value => setModalVisible(value)}
      strapModalProp={{
        centered: true,
      }}
    >
      <PackageSharedLinkDataLoader id={id}>
        <Input value={data?.url} />
      </PackageSharedLinkDataLoader>

      <div className="mt-3">
        <div className="button-items">
          <Button title={"cancel"} color="light" outline onClick={onCancel} />
        </div>
      </div>
    </Modal>
  )
}

PackageSharedLink.propTypes = {
  id: PropTypes.number,
  modalRef: PropTypes.any,
  onCancel: PropTypes.func,
}
