import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PublishBookForm } from './PublishBookForm';
import { modelPublishBook } from '../../../../../store/actions';
import { ValidationsUtils } from "../../../../../utils"

const PublishBookComponent = (props) => {
    const { t } = useTranslation();

    const handleCancel = () => {
        props.onCancel();
    }

    const onSubmitSuccess = (onSubmitProps) => () => {
        const { reset } = onSubmitProps;
        reset();

        props.onSuccess();
    }

    const onSubmitError = (onSubmitProps) => (errors) => {
        const { setError } = onSubmitProps;
        ValidationsUtils.setServerSideErrors(errors, setError);
    }

    const handleSubmit = (params, onSubmitProps) => {
        props.modelPublishBook(props.book.id, params, onSubmitSuccess(onSubmitProps), onSubmitError(onSubmitProps));
    };

    return (
        <PublishBookForm
            initialValues={{
                published: props?.book?.published,
            }}
            loading={props.loading}
            error={props.error}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
        />
    );
};

PublishBookComponent.propTypes = {
    book: PropTypes.object,
    onSuccess: PropTypes.func,
    onCancel: PropTypes.func,
    onClosed: PropTypes.func,

    loading: PropTypes.bool,
    error: PropTypes.object,

    modelPublishBook: PropTypes.func,
}

const mapStateToProps = state => {
    const { loading, error } = state.modelPanel.book.publish;
    return {
        loading, error,
    };
};

const mapDispatchToProps = {
    modelPublishBook,
};

export const PublishBook = connect(mapStateToProps, mapDispatchToProps)(PublishBookComponent);