import moment from "moment"
import _ from "lodash"

import { FormatUtils, CommonUtils } from "../../../../utils"

// Form section default values
export const getDatePeriodDefaultValues = (dateStart, dateEnd) => ({
  date_start: dateStart ? moment(dateStart).format("YYYY-MM-DD") : null,
  date_end: dateEnd ? moment(dateEnd).format("YYYY-MM-DD") : null,
})

export const getTimePeriodsDefaultValues = times => ({
  times:
    times?.map(item => ({
      start: item.start ? moment(item.start, "HH:mm").toDate() : null,
      end: item.end ? moment(item.end, "HH:mm").toDate() : null,
    })) || [],
})

export const getTitleInfoDefaultValues = () => ({
  title: "",
  description: "",
})

export const getAttachFilesDefaultValues = () => ({
  files: [],
})

export const getModelsDefaultValues = models => ({
  fashion_models: models || [],
})

export const getAgencyDefaultValues = agency => ({
  agency: agency || null,
})

export const getClientDefaultValues = client => ({
  client: client || null,
})

export const getExpensesDefaultValues = () => ({
  expenses: [],
})

export const getAlertDefaultValues = alerts => {
  const initialValue = { date_start: null }
  let values = [initialValue, initialValue]

  if (alerts && alerts.length) {
    for (let i = 0; i < 2; i++) {
      const alert = _.find(alerts, { sort: i + 1 })
      const date_start = alert?.date_start
        ? moment.utc(alert?.date_start).local().toDate()
        : null
      values[i] = { date_start }
    }
  }

  return {
    alerts: values,
  }
}

export const getNotesDefaultValues = () => ({
  note_models: "",
  note_internal: "",
})

export const getClientsInfoDefaultValues = () => ({
  family: null,
  product: "",
})

export const getLocationsDefaultValues = () =>
  Array.from({ length: 2 }, (_, i) => ({
    street: "",
    town: "",
    state: "",
    zip: "",
    country_id: null,
    access: "",
    phone: "",
    sort: i + 1,
    is_primary: i === 0,
  }))

export const getMapLocationsDefaultValues = (locations, defaultValues) => {
  let values = defaultValues || getLocationsDefaultValues()

  if (locations && locations.length) {
    for (let i = 0; i < 2; i++) {
      const location = _.find(locations, { sort: i + 1 })
      if (location) {
        const { country, ...rest } = location
        values[i] = {
          ...rest,
          phone: rest.phone ? FormatUtils.formatPhone(rest.phone) : "",
          country_id: country || null,
        }

        if (location.is_primary && i === 1) {
          values[0].is_primary = false
        }
      }
    }
  }

  return {
    locations: values,
  }
}

export const getServiceDefaultValues = () => ({
  // service_category: null,
  service_name: "",
  service_details: "",
})

export const getPeriodDefaultValues = data => {
  const { date_start, date_end, time_start, time_end } = data
  return {
    period_date_start: date_start ? moment(date_start).toDate() : null,
    period_date_end: date_end ? moment(date_end).toDate() : null,
    period_time_start: time_start ? moment(time_start, "HH:mm").toDate() : null,
    period_time_end: time_end ? moment(time_end, "HH:mm").toDate() : null,
  }
}

export const getDepartureDefaultValues = (date, time, place) => {
  return {
    departure_date: date ? moment(date).toDate() : null,
    departure_time: time ? moment(time, "HH:mm").toDate() : null,
    departure_place: place || "",
  }
}

export const getArrivalDefaultValues = (date, time, place) => {
  return {
    arrival_date: date ? moment(date).toDate() : null,
    arrival_time: time ? moment(time, "HH:mm").toDate() : null,
    arrival_place: place || "",
  }
}
// === END ===

// Map values for submit
export const mapLocationsSubmitValues = locations => {
  return locations
    .map((item, i) => ({
      ...item,
      phone: item?.phone?.replace(/[^0-9]/g, ""),
      country_id: item?.country_id?.id || null,
      sort: item.sort || i + 1,
    }))
    .filter(location => {
      const { sort, is_primary, ...rest } = location
      return CommonUtils.isObjectHasValue(rest)
    })
}

export const mapDatePeriodSubmitValues = (dateStart, dateEnd) => ({
  date_start: moment(dateStart).format("YYYY-MM-DD"),
  date_end: moment(dateEnd).format("YYYY-MM-DD"),
})

export const mapTimePeriodsSubmitValues = times => ({
  times: times.map(item => ({
    start: moment(item.start).format("HH:mm"),
    end: item.end ? moment(item.end).format("HH:mm") : null,
  })),
})

export const mapAttachFilesSubmitValues = files => ({
  files: files.map(item => item.id),
})

export const mapModelsSubmitValues = models => ({
  fashion_models: models.map(item => ({
    model_id: item.id,
    rates: item.rates || [],
    expenses: item.expenses || [],
    files: item?.files
      ? item?.files.map(file => ({
          id: file.id,
        }))
      : [],
    priority: item.priority || null,
  })),
})

export const mapAgencySubmitValues = (agency, isEdit) => {
  return isEdit
    ? { agency: agency?.id || null }
    : agency
    ? { agency: agency?.id }
    : null
}

export const mapClientSubmitValues = (client, isEdit) => {
  return isEdit
    ? { client: client?.id || null }
    : client
    ? { client: client?.id }
    : null
}

export const mapExpensesSubmitValues = expenses => ({
  expenses: expenses.map(item => {
    const { type, value, description, currency } = item
    return {
      type,
      description,
      value,
      currency,
    }
  }),
})

export const mapAlertsSubmitValues = alerts => ({
  alerts: alerts
    .map((item, i) =>
      item.date_start
        ? {
            date_start: moment(item.date_start)
              .utc()
              .format("YYYY-MM-DD HH:mm"),
            sort: i + 1,
          }
        : null
    )
    .filter(alert => alert),
})

export const mapPeriodSubmitValues = data => {
  const {
    period_date_start,
    period_date_end,
    period_time_start,
    period_time_end,
  } = data

  return {
    date_start: moment(period_date_start).format("YYYY-MM-DD"),
    date_end: moment(period_date_end).format("YYYY-MM-DD"),
    time_start: period_time_start
      ? moment(period_time_start).format("HH:mm")
      : null,
    time_end: period_time_end ? moment(period_time_end).format("HH:mm") : null,
  }
}
// ==== END ===

export const getUpdatedLinkedModels = (models, payload) => {
  return models.map(item => {
    if (item.id !== payload.id) {
      return item
    }

    return {
      ...item,
      ...payload.data,
    }
  })
}

// Check if models rates date between event date
export const checkModelsRatesDate = (models, dateStart, dateEnd) => {
  let invalidModels = []

  if (!!models.length) {
    dateStart = moment(dateStart)
    dateEnd = moment(dateEnd)

    // filter models which date rate not between event date
    invalidModels = models.filter(model => {
      const { rates } = model
      if (rates && !!rates.length) {
        let filteredRates = rates.filter(rate => {
          let { date } = rate

          if (date) {
            date = moment(date)
            const isBetween = date.isBetween(dateStart, dateEnd, "days", "[]")
            return !isBetween
          }

          return false
        })

        return !!filteredRates.length
      }

      return false
    })
  }

  const isValid = !invalidModels.length

  return { isValid, invalidModels }
}
