import moment from "moment";

const getAge = date => {
    if (!date) {
        return date
    }

    return moment().diff(date, 'years');
};

export {
    getAge,
}