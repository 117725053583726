import { useCallback, useMemo } from "react"
import { STATEMENT_TYPE } from "consts"

const pluginName = "useStatementsPayment"

export const useStatementsPayment = hooks => {
  hooks.useInstance.push(useInstance)
}

useStatementsPayment.pluginName = pluginName

function useInstance(instance) {
  const { selectedFlatRows } = instance

  const getUnpaidStatements = useCallback((statements = []) => {
    return statements.filter(
      ({ type, is_paid }) => type === STATEMENT_TYPE.STATEMENT && !is_paid
    )
  }, [])

  const selectedRows = selectedFlatRows || []

  const unpaidSelectedStatementsIds = useMemo(() => {
    const selectedStatements = selectedRows.map(item => item.original)

    return getUnpaidStatements(selectedStatements).map(item => item.id)
  }, [getUnpaidStatements, selectedRows])

  Object.assign(instance, {
    _statementsPayment: {
      unpaidSelectedStatementsIds,
    },
  })
}
