import React, { useMemo } from "react"
import { Row, Col } from "reactstrap"
import { MegaTagTitle } from "../../components"
import {
  ProfileContainer,
  AddressContainer,
  ContactsContainer,
  InvoiceDetailsContainer,
  PaymentDetailsContainer,
  AttachmentsContainer,
  DeleteAgency,
} from "./components"
import { usePanelTopBarOptions } from "../../hooks"

export const General = () => {
  const panelOptions = useMemo(
    () => ({
      actions: (
        <>
          <DeleteAgency />
        </>
      ),
    }),
    []
  )

  usePanelTopBarOptions(panelOptions)

  return (
    <div className={"agency-general"}>
      <MegaTagTitle title={"title.agency.general"} />

      <Row>
        <Col xl={6} className={"mb-4"}>
          <ProfileContainer />
        </Col>

        <Col xl={6} className={"mb-4"}>
          <AttachmentsContainer />
        </Col>
      </Row>

      <Row className={"mb-4"}>
        <Col>
          <AddressContainer />
        </Col>
      </Row>

      <Row className={"mb-4"}>
        <Col>
          <ContactsContainer />
        </Col>
      </Row>

      <Row>
        <Col mg={6} className={"mb-4"}>
          <InvoiceDetailsContainer />
        </Col>
        <Col md={6} className={"mb-4"}>
          <PaymentDetailsContainer />
        </Col>
      </Row>
    </div>
  )
}
