import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { createFilter } from "react-select"

import { SelectorCombobox } from "components"

export const AgenciesSelect = ({ value, loading, options = [], onChange }) => {
  const { t } = useTranslation()

  const filterConfig = {
    matchFrom: "start",
  }

  return (
    <SelectorCombobox
      value={value}
      options={options}
      onChange={onChange}
      onSelect={null}
      onFetchOptions={null}
      id={"agencies"}
      label={t("agencies")}
      placeholder={t("agencies")}
      loading={loading}
      getOptionLabel={option => option.name}
      getOptionValue={option => `${option.id}`}
      filterOption={createFilter(filterConfig)}
      controlShouldRenderValue={true}
      isClearable={true}
      menuPortalTarget={document.querySelector("body")}
      wrapClassName={"mb-0"}
    />
  )
}

AgenciesSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  options: PropTypes.array,
}
