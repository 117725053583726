import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import i18n from '../../../../i18n';
import { API } from '../../../../api';
import { RequestUtils } from '../../../../utils';

import { agenciesSidebar } from "./slice"


export function* fetchAgencies({ payload: { params } }) {
  try {
    let requestParams = {
      includes: [],
    };
    requestParams = RequestUtils.getMapRequestParams(params, requestParams);

    const response = yield call(API.agency.getAgencies, requestParams);

    const { data } = response;

    yield put(agenciesSidebar.getAgenciesCompleted(data));

  } catch (error) {
    const message = error.response?.data?.message || i18n.t('error');
    yield put(agenciesSidebar.getAgenciesRejected(message));
  }
}

export function* watchFetchList() {
  yield takeLatest(agenciesSidebar.getAgencies, fetchAgencies)
}

function* agenciesSidebarSaga() {
  yield all([
    fork(watchFetchList),
  ]);
}

export default agenciesSidebarSaga;