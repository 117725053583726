import * as Yup from "yup"

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required("field.error.required"),
  email: Yup.string()
    .email("field.error.invalid")
    .nullable()
    .required("field.error.required"),
  address: Yup.string().nullable().required("field.error.required"),
})
