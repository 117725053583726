import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "../../../../../../i18n"
import { MODEL_PANEL_GENERAL_LOGISTICS_DETAILS_EDIT } from "./actionTypes"
import {
  modelPanelGeneralLogisticsDetailsEditSuccess,
  modelPanelGeneralLogisticsDetailsEditError,
} from "./actions"

import { API } from "../../../../../../api"

function* editLogistics({ payload: { id, params, onSuccess, onError } }) {
  try {
    const response = yield call(API.model.updateModelLogistics, id, params)

    const { data } = response

    yield put(modelPanelGeneralLogisticsDetailsEditSuccess())

    toastr.success(i18n.t("edit.success"))

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      modelPanelGeneralLogisticsDetailsEditError({
        errors,
        message,
      })
    )

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchEditLogistics() {
  yield takeEvery(MODEL_PANEL_GENERAL_LOGISTICS_DETAILS_EDIT, editLogistics)
}

function* editSaga() {
  yield all([fork(watchEditLogistics)])
}

export default editSaga
