import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { InfoCard } from "components"

export const FeaturesInfoCard = ({ data, onEdit, onMore }) => {
  const { t } = useTranslation()

  data = data || {}

  const handleMore = e => {
    e.preventDefault()

    onMore()
  }

  return (
    <InfoCard
      title={t("measurements_and_features")}
      onEdit={onEdit}
      editMode
      className={"h-100 model-features-info"}
    >
      <div className={"d-flex flex-wrap h-100"}>
        <div className="info-list__block">
          <div className="info-list__item">
            <div className={"info-list__label"}>{t("height")}</div>
            <div className={"info-list__info"}>{data.height?.text || "-"}</div>
          </div>
          <div className="info-list__item">
            <div className={"info-list__label"}>{t("bust")}</div>
            <div className={"info-list__info"}>{data.bust?.text || "-"}</div>
          </div>
          <div className="info-list__item">
            <div className={"info-list__label"}>{t("waist")}</div>
            <div className={"info-list__info"}>{data.waist?.text || "-"}</div>
          </div>
          <div className="info-list__item">
            <div className={"info-list__label"}>{t("hips")}</div>
            <div className={"info-list__info"}>{data.hips?.text || "-"}</div>
          </div>
          <div className="info-list__item">
            <div className={"info-list__label"}>{t("shoes")}</div>
            <div className={"info-list__info"}>{data.shoes?.text || "-"}</div>
          </div>
        </div>

        <div className="info-list__block">
          <div className="info-list__item">
            <div className={"info-list__label"}>{t("hair-color")}</div>
            <div className={"info-list__info"}>
              {data?.hair_color?.text || "-"}
            </div>
          </div>
          <div className="info-list__item">
            <div className={"info-list__label"}>{t("eyes")}</div>
            <div className={"info-list__info"}>
              {data?.eyes_color?.text || "-"}
            </div>
          </div>
        </div>
      </div>

      <div className={"mt-3 text-center"}>
        <Link
          to="#"
          className="text-secondary text-decoration-underline"
          onClick={handleMore}
        >
          {t("btn.more")}
        </Link>
      </div>
    </InfoCard>
  )
}

FeaturesInfoCard.propTypes = {
  data: PropTypes.object,
  onEdit: PropTypes.func,
  onMore: PropTypes.func,
}
