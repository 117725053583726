import React, { useMemo } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import { CalendarDayHeaderContent } from "./CalendarDayHeaderContent"

export const EventsCalendarDayHeaderContent = ({
  info,
  calendarView = "week",
  renderContent,
}) => {
  const { date } = info

  const contentData = useMemo(() => {
    return {
      title: moment(date).format("ddd DD/MM"),
    }
  }, [date])

  return (
    <>
      {renderContent ? (
        renderContent(info, contentData)
      ) : (
        <CalendarDayHeaderContent {...contentData} />
      )}
    </>
  )
}

EventsCalendarDayHeaderContent.propTypes = {
  info: PropTypes.object,
  calendarView: PropTypes.string,
  renderContent: PropTypes.func,
}
