import { combineReducers } from "redux"

import list from "./list/slice"
import data from "./data/slice"
import remove from "./remove/slice"
import update from "./update/slice"
import add from "./add/slice"

const addressesReducer = combineReducers({
  list,
  remove,
  update,
  add,
  data,
})

export default addressesReducer
