import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Nav } from "../Nav"
import { PageHeader } from "components"
import { usePanelTopBarContext } from "contexts"
import { RemoveWebsite } from "../RemoveWebsite"
import { ChangePageStatus } from "../ChangePageStatus"
import { HeaderLink } from "../HeaderLink"

export const HeaderComponent = props => {
  const { options } = usePanelTopBarContext()

  const title = useMemo(() => {
    if (props.website) {
      return `${props.website?.title}`
    }
    return null
  }, [props.website])
  const pageState = useMemo(() => {
    if (props.page) {
      return {
        isPage: true,
        active: props.page.active,
      }
    }
    return null
  }, [props.page])
  return (
    <div className={"panel-topbar"}>
      <PageHeader
        title={title}
        headerRight={
          <>
            <HeaderLink
              page={props.page}
              subdomain={props.website.domain}
              linkType={"website"}
            />

            <ChangePageStatus page={props.page} loading={props.loading} />

            <HeaderLink
              page={props.page}
              subdomain={props.website.domain}
              linkType={"page"}
            />

            <RemoveWebsite />
          </>
        }
      />

      <div className={"panel-topbar__nav mb-4"}>
        <div className={"panel-topbar__menu"}>
          <Nav stateParams={pageState} />
        </div>

        {options.actions && (
          <div className="panel-topbar__actions">{options.actions}</div>
        )}
      </div>
    </div>
  )
}

HeaderComponent.propTypes = {
  website: PropTypes.object,
  page: PropTypes.object,
  loading: PropTypes.bool,
  topBarOptions: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.website.general.data
  const { data: page, loading } = state.website.general.currentPage.data
  return {
    website: data,
    loading,
    page,
  }
}

const mapDispatchToProps = {}

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent)
