import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { BooksGrid } from './BooksGrid';
import {
    packageDraftDataCollectionsFetchBooks,
    packageDraftDataCollectionsBooksSetPage,
} from '../../../../../../../store/actions';

const BooksComponent = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchData();
    }, [props.page]);

    const fetchData = () => {
        let params = {
            page: props.page,
            per_page: 8,
        };

        props.packageDraftDataCollectionsFetchBooks(props.model.id, params);
    };

    const handlePageChange = (item) => {
        props.packageDraftDataCollectionsBooksSetPage(item.selected + 1);
    };

    return (
        <div className={'pe-md-3'}>
            <BooksGrid
                items={props.list}
                loading={props.listLoading}
                error={props.listError}
                meta={props.meta}
                page={props.page}
                onPageChange={handlePageChange}
                onAdd={props.onAdd}
                picked={props.picked}
            />
        </div>
    );
};

BooksComponent.propTypes = {
    picked: PropTypes.array,
    onAdd: PropTypes.func,

    list: PropTypes.array,
    listLoading: PropTypes.bool,
    listError: PropTypes.any,
    meta: PropTypes.object,
    page: PropTypes.any,
    model: PropTypes.object,

    packageDraftDataCollectionsFetchBooks: PropTypes.func,
    packageDraftDataCollectionsBooksSetPage: PropTypes.func,
}

const mapStateToProps = state => {
    const { data } = state.model.profile;
    const { list, listLoading, listError, meta, page } = state.package.draft.dataCollections.books;
    return {
        list, listLoading, listError, meta, page,
        model: data,
    };
};

const mapDispatchToProps = {
    packageDraftDataCollectionsFetchBooks,
    packageDraftDataCollectionsBooksSetPage,
};

export const Books = connect(mapStateToProps, mapDispatchToProps)(BooksComponent);