import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import { MegaTagTitle } from "../../components"
import { CalendarContainer } from "./components/CalendarComponents"

const CalendarComponent = props => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {}
  }, [])

  return (
    <div className={"agency-calendar"}>
      <MegaTagTitle title={"title.agency.calendar"} />

      <Row>
        <Col>
          <CalendarContainer />
        </Col>
      </Row>
    </div>
  )
}

CalendarComponent.propTypes = {}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const Calendar = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarComponent)
