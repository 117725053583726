import React, { useRef } from "react"

// issue in Safari
// const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const usePrintContent = ({
  bodyRootClass = "print-content-mode",
  bodyClass,
  content,
  onAfterPrint,
}) => {
  const rootElementRef = useRef(null)

  const createRootElement = (id, className) => {
    const el = document.createElement("div")

    if (id) el.setAttribute("id", id)
    if (className) el.setAttribute("class", className)

    return el
  }

  const toggleBodyClass = () => {
    document.body.classList.toggle(bodyRootClass)

    bodyClass && document.body.classList.toggle(bodyClass)
  }

  const onPrintClose = () => {
    if (rootElementRef.current) {
      document.body.removeChild(rootElementRef.current)
    }

    toggleBodyClass()

    onAfterPrint && onAfterPrint()
  }

  const print = () => {
    const contentEl = content()

    if (!contentEl) {
      return
    }

    const clonedContentNodes = contentEl.cloneNode(true)

    // images in content component for preload
    const contentImgNodes = clonedContentNodes.querySelectorAll("img")

    const linksTotal = contentImgNodes.length
    let linksLoaded = []
    let linksErrored = []

    const markLoaded = (linkNode, loaded) => {
      if (loaded) {
        linksLoaded.push(linkNode)
      } else {
        linksErrored.push(linkNode)
      }

      const numResourcesManaged = linksLoaded.length + linksErrored.length

      if (numResourcesManaged === linksTotal) {
        triggerPrint(clonedContentNodes)
      }
    }

    const preloadImages = () => {
      for (let i = 0; i < contentImgNodes.length; i++) {
        const imgNode = contentImgNodes[i]
        const imgSrc = imgNode.getAttribute("src")

        if (!imgSrc) {
          markLoaded(imgNode, false)
        } else {
          const img = new Image()
          img.onload = markLoaded.bind(null, imgNode, true)
          img.onerror = markLoaded.bind(null, imgNode, false)
          img.src = imgSrc
        }
      }
    }

    // if print content not has images -> print
    // else preload images and then trigger print
    if (linksTotal === 0) {
      triggerPrint(clonedContentNodes)
    } else {
      preloadImages()
    }
  }

  const insertContent = content => {
    toggleBodyClass()

    // insert root element and print content to document body
    rootElementRef.current = createRootElement("print-content-root")
    rootElementRef.current.appendChild(content)
    document.body.appendChild(rootElementRef.current)
  }

  const triggerPrint = content => {
    insertContent(content)

    window.print()

    onPrintClose()
  }

  return {
    print,
  }
}
