import { createAction } from "@reduxjs/toolkit"

const SOCKET_ON_CHAT_GROUP_USER_ADDED = "socket/onChatGroupUserAdded"

export const socketOnChatGroupUserAdded = createAction(
  SOCKET_ON_CHAT_GROUP_USER_ADDED,
  function prepare({ data, history }) {
    return {
      payload: {
        data,
        history,
      },
    }
  }
)
