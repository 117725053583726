import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

import { GeneralEventsCalendar } from "components"
import { GENERAL_CALENDAR_ENTITY } from "consts"
import { EventsCalendarProvider } from "contexts"
import { useEventsCalendar } from "hooks"

const { CLIENT: ENTITY_CLIENT } = GENERAL_CALENDAR_ENTITY

export const CalendarContainer = props => {
  const client = useSelector(state => state.client.profile.data.data)

  const calendar = useEventsCalendar({
    config: {},
  })

  return (
    <EventsCalendarProvider {...calendar}>
      <GeneralEventsCalendar entity={ENTITY_CLIENT} entityData={client} />
    </EventsCalendarProvider>
  )
}

CalendarContainer.propTypes = {}
