import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "i18n"
import { websiteModelsUpdateBook } from "./slice"
import { API } from "api"

function* updateItem({ payload: { id, params, onSuccess, onError } }) {
  try {
    const response = yield call(API.model.updateModelBook, id, params)

    const { data } = response

    yield put(websiteModelsUpdateBook.updateCompleted())

    toastr.success(i18n.t("model-book.publish.change"))

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      websiteModelsUpdateBook.updateRejected({
        errors,
        message,
      })
    )

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchUpdateItem() {
  yield takeEvery(websiteModelsUpdateBook.update, updateItem)
}

function* updateSaga() {
  yield all([fork(watchUpdateItem)])
}

export default updateSaga
