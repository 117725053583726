import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Card, CardBody } from "reactstrap"

import placeholder from "../../../../assets/images/dev/logo-placeholder.svg"
import {
  profileUploadCompanyLogo,
  profileRemoveCompanyLogo,
  profileCompanyLogoCleanState,
} from "../../../../store/actions"
import { ValidationErrors } from "../../../../components"
import { UploadImage } from "../UploadImage"

const UploadCompanyLogoComponent = ({
  loading,
  error,
  user,
  profileUploadCompanyLogo,
  profileRemoveCompanyLogo,
  profileCompanyLogoCleanState,
}) => {
  useEffect(() => {
    return () => {
      profileCompanyLogoCleanState()
    }
  }, [])

  const handleUpload = file => {
    profileUploadCompanyLogo(file)
  }

  const handleRemove = () => {
    profileRemoveCompanyLogo()
  }

  return (
    <Card>
      <CardBody className={"bg-neutral"}>
        <UploadImage
          id={"company-logo"}
          image={user?.company?.avatar}
          placeholder={placeholder}
          labelUpload={"logo.upload"}
          labelUploadNew={"logo.upload.new"}
          labelRemove={"logo.remove"}
          loading={loading}
          onUpdate={handleUpload}
          onRemove={handleRemove}
        />

        {error && <ValidationErrors data={error} />}
      </CardBody>
    </Card>
  )
}

UploadCompanyLogoComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  user: PropTypes.object,

  profileUploadCompanyLogo: PropTypes.func,
  profileRemoveCompanyLogo: PropTypes.func,
  profileCompanyLogoCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, error } = state.profile.companyLogo
  const user = state.user.profile.data
  return { loading, error, user }
}

const mapDispatchToProps = {
  profileUploadCompanyLogo,
  profileRemoveCompanyLogo,
  profileCompanyLogoCleanState,
}

export const UploadCompanyLogo = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadCompanyLogoComponent)
