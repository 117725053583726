import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';

import { HistoryTable } from '../../../../../components';
import {
    modelPanelHistoryFetchList,
    modelPanelHistoryListCleanState,
    modelPanelHistoryListSetPage,
} from '../../../../../store/actions';

const RatingContainerComponent = (props) => {
    useEffect(() => {
        return () => {
            props.modelPanelHistoryListCleanState();
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [props.page]);

    const fetchData = () => {
        let params = {
            page: props.page,
        };

        props.modelPanelHistoryFetchList(props.model.id, params);
    };

    const handlePageChange = (item) => {
        props.modelPanelHistoryListSetPage(item.selected + 1);
    };

    return (
        <>
            <HistoryTable
                list={props.list}
                loading={props.listLoading}
                error={props.listError}
                meta={props.meta}
                page={props.page}
                onPageChange={handlePageChange}
            />
        </>
    );
};

RatingContainerComponent.propTypes = {
    model: PropTypes.object,
    list: PropTypes.array,
    listLoading: PropTypes.bool,
    listError: PropTypes.any,
    meta: PropTypes.object,
    page: PropTypes.any,

    modelPanelHistoryFetchList: PropTypes.func,
    modelPanelHistoryListCleanState: PropTypes.func,
    modelPanelHistoryListSetPage: PropTypes.func,
}

const mapStateToProps = state => {
    const { data } = state.model.profile;
    const { list, listLoading, listError, meta, page } = state.modelPanel.history.list.data;
    return {
        model: data,
        list, listLoading, listError, meta, page,
    };
};

const mapDispatchToProps = {
    modelPanelHistoryFetchList,
    modelPanelHistoryListCleanState,
    modelPanelHistoryListSetPage,
};

export const HistoryContainer = connect(mapStateToProps, mapDispatchToProps)(RatingContainerComponent);