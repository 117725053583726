import { combineReducers } from "redux";

import dataCollections from './dataCollections/reducers';
import data from './data/reducers';
import common from './common/reducer';

const draftReducer = combineReducers({
    dataCollections,
    data,
    common,
});

export default draftReducer;