import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { IconButton } from "../../../Buttons"
import { FileUtils } from "../../../../utils"

export const AttachmentListTable = props => {
  const { t } = useTranslation()

  const onDownload = item => {
    FileUtils.downloadFileByLink(item?.attachment?.src)
  }

  return (
    <div className="table-responsive mt-4">
      <table className="table table-cell_p-v_sm">
        <tbody>
          {props.data.map(item => (
            <tr key={item.id} className="table-border-bottom">
              <td>
                <div className={"text-nowrap"}>
                  <span className={"fw-bold"}>{item.title}</span>
                </div>
              </td>

              <td
                className={"table-column-sticky_end bg-white text-nowrap"}
                style={{ width: "76px" }}
              >
                <IconButton
                  name={"edit"}
                  bg
                  onClick={() => props.onEdit(item)}
                />

                <IconButton
                  name={"download"}
                  onClick={() => onDownload(item)}
                  bg
                  wrapClass={"ms-2"}
                />

                <IconButton
                  name={"remove"}
                  bg
                  onClick={() => props.onRemove(item)}
                  wrapClass={"ms-2"}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

AttachmentListTable.propTypes = {
  data: PropTypes.array,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
}
