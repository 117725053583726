import React, { useEffect } from 'react';
import PropTypes from "prop-types"
import { connect } from "react-redux";
import { Alert } from "reactstrap"
import { useTranslation } from 'react-i18next';
import { useParams, Link } from "react-router-dom";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Button, FormikPasswordInputFiled as PasswordInputFiled } from '../../../../components'
import { passwordReset, passwordResetCleanState } from '../../../../store/actions';
import { useQuery } from '../../../../hooks';

const passwordMinLength = 8;

const ResetPasswordFormComponent = (props) => {
    const { t } = useTranslation();

    let query = useQuery();
    let { token } = useParams();

    useEffect(() => {
        return () => {
            props.passwordResetCleanState();
        }
    }, []);

    const onSubmit = (values, onSubmitProps) => {
        const params = {
            ...values,
            email: query.get('email'),
            token,
        };

        props.passwordReset(params, onSubmitProps);
    };

    return (
        <Formik
            initialValues={{
                password: '',
                password_confirmation: '',
            }}
            validationSchema={Yup.object({
                password: Yup.string().min(passwordMinLength, 'field.error.password.length')
                    .required('field.error.required'),
                password_confirmation: Yup.string().required('field.error.required')
                    .oneOf([Yup.ref('password'), null], 'field.error.password.no.match')
                    .min(passwordMinLength, 'field.error.password.length'),
            })}
            onSubmit={onSubmit}
            validateOnChange={true}
            validateOnBlur={false}
        >
            {({
                  isSubmitting,
                  ...rest
              }) => {
                return (
                    <>
                        {props.resetError && (
                            <Alert color="danger">
                                {props.resetError.message}
                            </Alert>
                        )}

                        {props.resetSuccessMsg && (
                            <>
                                <Alert color="success">
                                    {props.resetSuccessMsg}
                                </Alert>

                                <div className="mt-5 text-center">
                                    <p>
                                        {t('go.back.to')}{" "}
                                        <Link to="/login" className="fw-medium text-primary-green">
                                            {" "}
                                            {t('btn.login')}{" "}
                                        </Link>{" "}
                                    </p>
                                </div>
                            </>
                        )}

                        {!props.resetSuccessMsg && (
                            <Form className="form-horizontal" noValidate={true}>
                                <PasswordInputFiled
                                    name="password"
                                    label={t('field.label.password')}
                                    placeholder={t('field.placeholder.password')}
                                    id={'password'}
                                    translateParams={{ count: passwordMinLength }}
                                />

                                <PasswordInputFiled
                                    name="password_confirmation"
                                    label={t('field.label.confirm.password')}
                                    placeholder={t('field.label.confirm.password')}
                                    id={'password_confirmation'}
                                    translateParams={{ count: passwordMinLength }}
                                />

                                <div className="mt-3 d-grid">
                                    <Button
                                        submit
                                        title={'reset'}
                                        disabled={isSubmitting || props.loading}
                                        loading={props.loading}
                                    />
                                </div>
                            </Form>
                        )}
                    </>
                )
            }}
        </Formik>
    );
};

ResetPasswordFormComponent.propTypes = {
    loading: PropTypes.bool,
    resetSuccessMsg: PropTypes.string,
    resetError: PropTypes.object,

    passwordReset: PropTypes.func,
    passwordResetCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const {
        resetSuccessMsg, resetError, loading,
    } = state.auth.passwordReset;
    return {
        resetSuccessMsg, resetError, loading,
    };
};

const mapDispatchToProps = {
    passwordReset,
    passwordResetCleanState,
};

export const ResetPasswordForm = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordFormComponent);