import React from 'react'
import PropTypes from "prop-types"
import { Col, Alert, Row } from "reactstrap"
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";

import {
    Button,
    ControllerHF,
    CheckboxField,
} from '../../../../../components'

export const PublishBookForm = (props) => {
    const { t } = useTranslation();

    const { control, handleSubmit, setError, reset } = useForm({
        defaultValues: {
            published: false,
            ...props.initialValues,
        },
        mode: 'onChange'
    });

    const onSubmit = (values) => {
        const params = {
            ...values,
        }

        props.onSubmit(params, { reset, setError });
    };

    const onCancel = () => {
        props.onCancel();
    }

    return (
        <>
            {props.error && (
                <Alert color="danger">
                    {props.error.message}
                </Alert>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={12}>
                        <ControllerHF
                            name={'published'}
                            control={control}
                            component={CheckboxField}
                            id={'published'}
                            label={t('set_as_published')}
                            wrapClass={'form-checkbox-control m-0'}
                            size={'lg'}
                        />
                    </Col>
                </Row>

                <div className="mt-3">
                    <div className="button-items">
                        <Button
                            submit
                            title={'btn.save'}
                            disabled={props.loading}
                            loading={props.loading}
                        />

                        <Button
                            title={'cancel'}
                            color="light"
                            outline
                            onClick={onCancel}
                        />
                    </div>
                </div>
            </form>
        </>
    );
};

PublishBookForm.propTypes = {
    initialValues: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.object,

    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
}