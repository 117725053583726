import { takeLatest, fork, put, all, call } from "redux-saga/effects"

import i18n from "i18n"
import { API } from "api"
import { RequestUtils } from "utils"
import { packageStatisticsList } from "./slice"

export function* getList({ payload: { params } }) {
  try {
    let requestParams = {
      includes: [],
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response = yield call(
      API.packageApi.getPackagesSharedEntities,
      requestParams
    )

    const { data } = response

    const {
      meta: { current_page, last_page },
    } = data
    if (current_page && last_page && current_page > last_page) {
      yield put(
        packageStatisticsList.getList({
          ...params,
          page: last_page,
        })
      )
    } else {
      yield put(packageStatisticsList.getListSuccess(data))
    }
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(packageStatisticsList.getListError(message))
  }
}

export function* watchGetList() {
  yield takeLatest(packageStatisticsList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
