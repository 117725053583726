import { createSlice } from "@reduxjs/toolkit"
import { MODELS_PANEL_STATE } from "consts"

const initialState = {
  state: MODELS_PANEL_STATE.DEFAULT,
}

const modelsPanelStateSlice = createSlice({
  name: "modelsPanelState",
  initialState,
  reducers: {
    setDefaultState(state) {
      state.state = MODELS_PANEL_STATE.DEFAULT
    },
    setCreateGroupState(state) {
      state.state = MODELS_PANEL_STATE.CREATE_GROUP
    },
    setEditGroupState(state) {
      state.state = MODELS_PANEL_STATE.EDIT_GROUP
    },
    setAddToGroupState(state) {
      state.state = MODELS_PANEL_STATE.ADD_TO_GROUP
    },
  },
})

/**
 * @namespace
 * @property {function} setDefaultState
 * @property {function} setCreateGroupState
 * @property {function} setEditGroupState
 * @property {function} setAddToGroupState
 */

export const modelsPanelState = modelsPanelStateSlice.actions

export default modelsPanelStateSlice.reducer
