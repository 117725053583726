import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"

import { ModelsForm } from "./ModelsForm"
import {
  calendarEventModelLinkingRemove,
  calendarEventModelLinkingAdd,
  calendarEventModelUpdate,
} from "store/actions"

const AsyncModelsComponent = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(calendarEventModelLinkingAdd.cleanState())
      dispatch(calendarEventModelLinkingRemove.cleanState())
      // reset model update action submit
      dispatch(calendarEventModelUpdate.cleanState())
    }
  }, [])

  const onRemove = (item, callback) => {
    const params = {
      event: props.eventId,
      model: item.id,
      onSuccess: data => {
        callback()
        onEventUpdate(data)
      },
    }
    dispatch(calendarEventModelLinkingRemove.remove(params))
  }

  const onSelect = (item, callback) => {
    const params = {
      event: props.eventId,
      model: item.id,
      onSuccess: data => {
        callback()
        onEventUpdate(data)
      },
    }
    dispatch(calendarEventModelLinkingAdd.add(params))
  }

  const onEventUpdate = data => {
    props.onEventUpdate(data)
  }

  return (
    <>
      <ModelsForm
        {...props}
        async
        onRemove={onRemove}
        onSelect={onSelect}
        loading={props.linkingProcessing}
        eventIsEdit
        onEventUpdate={onEventUpdate}
      />
    </>
  )
}

AsyncModelsComponent.propTypes = {
  linkingProcessing: PropTypes.bool,
  eventId: PropTypes.number,
  onEventUpdate: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    linkingProcessing:
      state.calendarEvent.model.linking.remove.loading ||
      state.calendarEvent.model.linking.add.loading,
  }
}

export const AsyncModels = connect(mapStateToProps)(AsyncModelsComponent)
