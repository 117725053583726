import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { compcardCreate } from "./slice"

function* create({ payload: { params, onSuccess, onError } }) {
  try {
    const response = yield call(API.compcard.create, params)

    const { data } = response

    yield put(compcardCreate.createSuccess())

    if (onSuccess) {
      onSuccess(data?.data)
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      compcardCreate.createError({
        errors,
        message,
      })
    )

    toastr.error(message)

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchCreate() {
  yield takeEvery(compcardCreate.create, create)
}

function* createSaga() {
  yield all([fork(watchCreate)])
}

export default createSaga
