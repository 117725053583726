import { takeLatest, fork, put, all, call } from "redux-saga/effects"

import i18n from "i18n"
import { API } from "api"
import { clientActivitiesList } from "./slice"

export function* getList({ payload: { id, params } }) {
  try {
    const response = yield call(API.client.getClientActivities, id, params)

    const { data } = response
    const {
      meta: { current_page, last_page },
    } = data
    if (current_page && last_page && current_page > last_page) {
      yield put(
        clientActivitiesList.getList({
          params: {
            ...params,
            page: last_page,
          },
        })
      )
    } else {
      yield put(clientActivitiesList.getListSuccess(data))
    }
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(clientActivitiesList.getListError(message))
  }
}

export function* watchGetList() {
  yield takeLatest(clientActivitiesList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
