import { all } from "redux-saga/effects"

import profileSaga from "./profile/sagas"
import panelSaga from "./panel/sagas"
import ratingSaga from "./rating/sagas"
import historySaga from "./history/sagas"
import documentsSaga from "./documents/sagas"
import contactsSaga from "./contacts/sagas"
import logoSaga from "./logo/sagas"
import addressesSaga from "./addresses/sagas"
import pricesSaga from "./prices/sagas"
import statementSaga from "./statement/sagas"
import activitiesSaga from "./activities/sagas"

function* clientSaga() {
  yield all([
    profileSaga(),
    panelSaga(),
    ratingSaga(),
    documentsSaga(),
    contactsSaga(),
    logoSaga(),
    historySaga(),
    addressesSaga(),
    pricesSaga(),
    statementSaga(),
    activitiesSaga(),
  ])
}

export default clientSaga
