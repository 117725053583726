import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import moment from "moment"
import { useDeepCompareEffect } from "react-use"

import { calendarEventCompareList } from "store/actions"
import { selectCompareFullCalendarEvents } from "store/selectors"
import { CompareEventsCalendarControl } from "./CompareEventsCalendarControl"
import { EventsCalendarEventContentProvider } from "components/Calendars/EventsCalendar"
import { Header } from "./Header"
import {
  GENERAL_CALENDAR_ENTITY,
  EVENTS_CALENDAR_DEFAULT_EVENTS_FETCH_PARAMS,
} from "consts"
import { eventsCalendarHelper } from "helpers"
import { useCompareEventsCalendarContext } from "../../contexts"

const { getEntityEventsRequestParams, calendarEventOrderByModelPriority } =
  eventsCalendarHelper.generalCalendar

const {
  MODEL: ENTITY_MODEL,
  AGENCY: ENTITY_AGENCY,
  CLIENT: ENTITY_CLIENT,
} = GENERAL_CALENDAR_ENTITY

const CompareEventsCalendarComponent = props => {
  const dispatch = useDispatch()

  const compareEventsCalendarContext = useCompareEventsCalendarContext()

  const {
    state: { fetchParams },
  } = compareEventsCalendarContext

  const [datePeriod, setDatePeriod] = useState(null)

  useEffect(() => {
    return () => {
      dispatch(calendarEventCompareList.cleanState())
    }
  }, [])

  useDeepCompareEffect(() => {
    if (datePeriod) {
      fetchEvents()
    }
  }, [datePeriod, fetchParams])

  const fetchEvents = (params = {}) => {
    params = {
      includes: EVENTS_CALENDAR_DEFAULT_EVENTS_FETCH_PARAMS.includes,
      ...datePeriod,
      ...params,
    }

    if (props.entity && props.entityData) {
      params = {
        ...params,
        ...getEntityEventsRequestParams(props.entity, props.entityData),
      }
    }

    if (fetchParams) {
      params = {
        ...params,
        ...fetchParams,
      }
    }

    dispatch(calendarEventCompareList.getList({ params }))
  }

  const onCalendarDatesSet = rangeInfo => {
    const { start, end } = rangeInfo
    const dates = {
      date_start: moment(start).format("YYYY-MM-DD"),
      date_end: moment(end).add(-1, "days").format("YYYY-MM-DD"),
    }

    setDatePeriod(dates)
  }

  // Custom render calendar event content
  const renderCalendarEventContent = eventInfo => {
    const {
      event: { extendedProps },
    } = eventInfo

    const isHoliday = eventsCalendarHelper.eventConfig.isHolidayCategoryEvent(
      extendedProps?.type
    )

    return (
      <EventsCalendarEventContentProvider
        eventInfo={eventInfo}
        entity={props.entity}
        entityData={props.entityData}
        provider={isHoliday ? "holiday" : "general"}
      />
    )
  }

  const calendarEventOrder = React.useMemo(() => {
    return props.entity === ENTITY_MODEL
      ? ["start", "order", calendarEventOrderByModelPriority(props.entityData)]
      : ["start", "order"]
  }, [props.entity, props.entityData, calendarEventOrderByModelPriority])

  return (
    <>
      <Header />

      <CompareEventsCalendarControl
        calendarLoading={props.listLoading}
        calendarError={props.listError}
        calendarEvents={props.fullCalendarEvents}
        onCalendarDatesSet={onCalendarDatesSet}
        entity={props.entity}
        entityData={props.entityData}
        renderCalendarEventContent={eventInfo =>
          renderCalendarEventContent(eventInfo)
        }
        calendarEventOrder={calendarEventOrder}
        eventColors={props.eventColors}
      />
    </>
  )
}

CompareEventsCalendarComponent.propTypes = {
  entity: PropTypes.oneOf([ENTITY_MODEL, ENTITY_AGENCY, ENTITY_CLIENT]),
  entityData: PropTypes.object,

  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  fullCalendarEvents: PropTypes.array,

  eventColors: PropTypes.object,
}

const mapStateToProps = (state, { entity, entityData }) => {
  const { listLoading, listError } = state.calendarEvent.compare.list

  const entityScreenConfig = entity
    ? {
        entity,
        entityId: entityData?.id,
      }
    : null

  return {
    listLoading,
    listError,
    fullCalendarEvents: selectCompareFullCalendarEvents(
      state,
      entityScreenConfig
    ),

    eventColors: state.user.options.data.data.event_colors,
  }
}

export const CompareEventsCalendar = connect(mapStateToProps)(
  CompareEventsCalendarComponent
)
