import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import {
  DepartmentsPermissionsForm,
  IconButton,
  Popover,
} from "components/index"
import { useFetchData, usePopover } from "hooks"
import * as HOC from "HOC"
import { analyticsPermissionsData, analyticsUpdatePermissions } from "store"
import { requestHelper } from "helpers"
import { ValidationsUtils } from "utils"
import { ROLE_DIRECTOR } from "consts"

const DataShower = HOC.withDataShower()

const ChangePermissionsComponent = props => {
  const dispatch = useDispatch()
  const { popoverId, toggle, popoverOpen } = usePopover({
    id: props.graphData.id,
  })
  const fetchDepartments = useFetchData()
  const onFetchDepartments = async () => {
    let request = requestHelper.staticData.getDepartments()
    await fetchDepartments.fetchData({
      request,
    })
  }

  const fetchData = () => {
    dispatch(analyticsPermissionsData.getData({ graphId: props.graphData.id }))
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }
  const onSubmitSuccess = onSubmitProps => () => {
    toggle()
  }
  const handleSubmit = (data, onSubmitProps) => {
    let params = {
      alias: props.graphData.alias,
      tab: props.graphData.tab,
      department_ids: [...data.department_ids],
    }
    dispatch(
      analyticsUpdatePermissions.update({
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }
  const handleClick = () => {
    if (popoverOpen === false) {
      fetchData()
      onFetchDepartments()
    }
  }

  return props.role === ROLE_DIRECTOR ? (
    <>
      <IconButton
        name={"view"}
        type={"button"}
        id={popoverId}
        onClick={handleClick}
        bgColor={"bg-primary"}
        color={"text-white"}
        wrapClass={"mx-2"}
      />
      <Popover
        title={"show-permissions"}
        popoverId={popoverId}
        placement={"bottom-start"}
        popoverOpen={popoverOpen}
        toggle={toggle}
      >
        <DataShower
          isLoading={props.loading || fetchDepartments.loading}
          error={props.error}
        >
          <DepartmentsPermissionsForm
            onCancel={toggle}
            onSubmit={handleSubmit}
            loading={props.updateLoading}
            permissions={props.permissions}
            departmentsOptions={fetchDepartments.data?.data || []}
          />
        </DataShower>
      </Popover>
    </>
  ) : null
}

ChangePermissionsComponent.propTypes = {
  graphData: PropTypes.object,
  permissions: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  updateLoading: PropTypes.bool,
  role: PropTypes.string,
}

const mapStateToProps = state => {
  const { data: permissions, loading, error } = state.analytics.permissions.data
  const { loading: updateLoading } = state.analytics.permissions.update
  const {
    data: { role },
  } = state.user.profile
  return {
    permissions,
    loading,
    error,
    updateLoading,
    role,
  }
}

export const ChangePermissions = connect(mapStateToProps)(
  ChangePermissionsComponent
)
