import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { PackageDataGrid, Pagination } from "../../../../../../components"
import { BooksSlider } from "../BooksSlider"

import * as HOC from "../../../../../../HOC"
const DataShower = HOC.withDataShowerOverlay()

export const BooksGrid = props => {
  const { t } = useTranslation()

  return (
    <DataShower
      isLoading={props.loading}
      isFailed={props.error}
      error={props.error}
    >
      {!!props.items.length ? (
        <>
          <PackageDataGrid
            className={"package-data-picker-grid"}
            items={props.items}
            renderItem={item => (
              <BooksSlider
                data={item}
                onAddBook={props.onAdd}
                pickedBooks={props.picked}
              />
            )}
          />

          <Pagination data={props.meta} onPageChange={props.onPageChange} />
        </>
      ) : !props.loading ? (
        t("model_not_found_with_entities", { entities: t("books-lc") })
      ) : null}
    </DataShower>
  )
}

BooksGrid.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,
  picked: PropTypes.array,

  onPageChange: PropTypes.func,
  onAdd: PropTypes.func,
}
