import { all } from "redux-saga/effects"

import updateSaga from "./update/saga"
import removeSaga from "./remove/saga"
import createSaga from "./create/saga"
import dataSaga from "./data/saga"
import pagesListSaga from "./pagesList/saga"
import currentPageSaga from "./currentPage/sagas"

function* generalSaga() {
  yield all([
    updateSaga(),
    removeSaga(),
    createSaga(),
    dataSaga(),
    pagesListSaga(),
    currentPageSaga(),
  ])
}

export default generalSaga
