import { combineReducers } from "redux"

import data from "./data/slice"
import done from "./done/slice"

const editReducer = combineReducers({
  data,
  done,
})

export default editReducer
