import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Container, Alert } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import { PageHeader, MegaTagTitle } from "../../components"
import { ROLE_EMPLOYEE } from "consts"
import { middlewareHelper } from "helpers"

const DashboardComponent = props => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {}
  }, [])

  return (
    <React.Fragment>
      <MegaTagTitle title={"title.dashboard"} />

      <div className="page-content">
        <Container fluid>
          <PageHeader title={t("title.dashboard")} />

          {props.user?.role === ROLE_EMPLOYEE &&
            !middlewareHelper.subscription.isAllow(props.user) && (
              <Alert color={"secondary"}>
                {t("subscription.required.employee_warning")}
              </Alert>
            )}
        </Container>
      </div>
    </React.Fragment>
  )
}

DashboardComponent.propTypes = {
  user: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    user: state.user.profile.data,
  }
}

const mapDispatchToProps = {}

export const Dashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardComponent)
