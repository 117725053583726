import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import * as HOC from "../../../../../../HOC"
import { useTranslation } from "react-i18next"
import {
  websiteCurrentPageData,
  websiteUpdateCurrentPageData,
} from "../../../../../../store/actions"
import { BecomeModelForm } from "../BecomeModelForm"

const DataShower = HOC.withDataShower()

const BecomeModelComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  useEffect(() => {
    fetchData()
    return () => {
      dispatch(websiteCurrentPageData.cleanState())
    }
  }, [])

  const fetchData = () => {
    dispatch(websiteCurrentPageData.getData({ alias: props.alias }))
  }
  const handleSubmit = params => {
    dispatch(
      websiteUpdateCurrentPageData.update({
        alias: props.alias,
        params,
        successMessage: t("become-model-edit.success"),
      })
    )
  }

  return (
    <>
      <DataShower
        isLoading={props.loading}
        isFailed={props.error}
        error={props.error}
      >
        <BecomeModelForm
          initialValues={{
            ...props.data?.template_values,
          }}
          onSubmit={handleSubmit}
          loading={props.updateLoading}
          error={props.updateError}
        />
      </DataShower>
    </>
  )
}

BecomeModelComponent.propTypes = {
  alias: PropTypes.string,

  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.any,

  updateLoading: PropTypes.bool,
  updateError: PropTypes.any,
}

const mapStateToProps = state => {
  const { data, loading, error } = state.website.general.currentPage.data
  const { loading: updateLoading, error: updateError } =
    state.website.general.currentPage.update
  return {
    data,
    loading,
    error,
    updateError,
    updateLoading,
  }
}

const mapDispatchToProps = {}

export const BecomeModelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BecomeModelComponent)
