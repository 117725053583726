import { createAction } from "@reduxjs/toolkit"

const SOCKET_ON_CHAT_GROUP_PARTICIPANT_ADDED =
  "socket/onChatGroupParticipantAdded"

export const socketOnChatGroupParticipantAdded = createAction(
  SOCKET_ON_CHAT_GROUP_PARTICIPANT_ADDED,
  function prepare(data) {
    return {
      payload: {
        data,
      },
    }
  }
)
