import { createSelector } from "@reduxjs/toolkit"

const getSyncCount = state => state.clients.panel.list.syncCount
const getImportSyncCount = state => state.socket.import.data.clientsSyncCount

export const selectClientsListSyncCount = createSelector(
  [getSyncCount, getImportSyncCount],
  (syncCount, importSyncCount) => {
    return syncCount + importSyncCount
  }
)
