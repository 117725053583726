import React from "react"
import PropTypes from "prop-types"
import { Alert, Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import moment from "moment"

import {
  Button,
  ControllerHF,
  DateInputField,
  InputField,
  LogoPickerField,
} from "components"
import {
  ContractDocumentTextEntryControlPickerFormSection,
  TABS_KEY,
} from "components/FormSections/ContractDocumentTextEntryControlPickerFormSection"
import { validationSchema } from "./validation"
import { FileUtils, EditorUtils } from "utils"
import { FILE_MAX_SIZE, EDITOR_DRAFT_DOCX_TOOLBAR_OPTIONS } from "consts"

export const EventContractForm = props => {
  const { t } = useTranslation()

  const methods = useForm({
    defaultValues: {
      header_text: "",
      client_information: "",
      city: "",
      contract_date: null,
      type: TABS_KEY.TEXT,
      file: null,
      contract_text: EditorUtils.draftJs.getEditorState(""),
      footer_text: "",
      ...props.initialValues,
      logo: props.initialValues?.logo
        ? { src: props.initialValues.logo }
        : null,
    },
    resolver: yupResolver(validationSchema),
  })

  const { control, handleSubmit, setError, reset, watch, setValue } = methods

  const watchType = watch("type")

  const onSubmit = values => {
    const { contract_date, contract_text, logo, file, ...restValues } = values

    let params = {
      contract_date: contract_date
        ? moment(contract_date).format("YYYY-MM-DD")
        : "",
      ...restValues,
    }

    if (contract_text && !EditorUtils.draftJs.isContentEmpty(contract_text)) {
      params.contract_text =
        EditorUtils.draftJs.editorStateToHtml(contract_text)
    } else {
      params.contract_text = ""
    }

    if (logo) {
      if (logo instanceof File) {
        params.logo = logo
      }
    } else {
      params.logo = ""
    }

    const appendFile = (file, name) => {
      if (file) {
        if (file instanceof File) {
          params[name] = file
        }
      } else {
        params[name] = ""
      }
    }

    appendFile(file?.file, "file")

    props.onSubmit(params, { reset, setError })
  }

  const onTypeChange = type => {
    setValue("type", type)
  }

  return (
    <FormProvider {...methods}>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={4} className={"d-flex"}>
            <ControllerHF
              name={"logo"}
              control={control}
              component={LogoPickerField}
              selectBtnText={t("btn.add-logo")}
              id={"logo"}
              type={"file"}
              translateParams={{
                text: `${FileUtils.formatBytes(FILE_MAX_SIZE)}`,
              }}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          <Col md={6}>
            <ControllerHF
              name={"header_text"}
              control={control}
              component={InputField}
              id={"header_text"}
              label={t("contact_information")}
              placeholder={t("contact_information")}
              type={"textarea"}
            />
          </Col>

          <Col md={6}>
            <ControllerHF
              name={"client_information"}
              control={control}
              component={InputField}
              id={"client_information"}
              label={t("client_information")}
              placeholder={t("client_information")}
              type={"textarea"}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          <Col md={6}>
            <ControllerHF
              name={"city"}
              control={control}
              component={InputField}
              id={"city"}
              label={t("town")}
              placeholder={t("town")}
            />
          </Col>

          <Col md={6}>
            <ControllerHF
              name={"contract_date"}
              control={control}
              component={DateInputField}
              id={"contract_date"}
              label={t("contract_date")}
              placeholder={"DD/MM/YYYY"}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <div>
          <h4 className={"mb-3"}>{t("contract")}</h4>

          <ContractDocumentTextEntryControlPickerFormSection
            value={watchType}
            onChange={onTypeChange}
          />
        </div>

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          <Col md={12}>
            <ControllerHF
              name={"footer_text"}
              control={control}
              component={InputField}
              id={"footer_text"}
              label={t("footer_text")}
              placeholder={t("footer_text")}
              type={"textarea"}
            />
          </Col>
        </Row>

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

EventContractForm.propTypes = {
  isEdit: PropTypes.bool,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
