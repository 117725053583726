import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import i18n from '../../../i18n';
import { PROFILE_UPLOAD_COMPANY_LOGO, PROFILE_REMOVE_COMPANY_LOGO } from './actionTypes';
import { profileUpdateCompanyLogoSuccess, profileUpdateCompanyLogoError } from './actions';
import { userProfileUpdateCompanyLogo } from '../../user/profile/actions';

import { API } from '../../../api';

function* uploadCompanyLogo({ payload: { params } }) {
    try {
        const response = yield call(API.profile.updateCompanyLogo, params);

        const { data } = response;

        yield put(profileUpdateCompanyLogoSuccess());

        yield put(userProfileUpdateCompanyLogo(data.data.src));
    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : i18n.t('error');

        yield put(profileUpdateCompanyLogoError({
            errors,
            message
        }));
    }
}

function* removeCompanyLogo() {
    try {
        const response = yield call(API.profile.removeCompanyLogo);

        const { data } = response;

        yield put(profileUpdateCompanyLogoSuccess());

        yield put(userProfileUpdateCompanyLogo(null));
    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : i18n.t('error');

        yield put(profileUpdateCompanyLogoError({
            errors,
            message
        }));
    }
}

export function* watchUploadCompanyLogo() {
    yield takeEvery(PROFILE_UPLOAD_COMPANY_LOGO, uploadCompanyLogo);
}

export function* watchRemoveCompanyLogo() {
    yield takeEvery(PROFILE_REMOVE_COMPANY_LOGO, removeCompanyLogo);
}

function* companyLogoSaga() {
    yield all([
        fork(watchUploadCompanyLogo),
        fork(watchRemoveCompanyLogo),
    ]);
}

export default companyLogoSaga;