import React, { useEffect, useRef, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { DocumentsInfoCard } from "./DocumentsInfoCard"
import { EditDocuments } from "./EditDocuments"
import { AddDocument } from "./AddDocument"
import { ConfirmPopup } from "../../../../../components"
import PropTypes from "prop-types"
import {
  clientDocumentsList,
  clientRemoveDocument,
} from "../../../../../store/actions"

const DocumentsContainerComponent = props => {
  const dispatch = useDispatch()
  //==================REMOVE LOGIC ==============///
  const removeModalRef = useRef(null)
  const [removeDocument, setRemoveDocument] = useState(null)
  const handleRemove = item => {
    setRemoveDocument(item)

    if (removeModalRef.current) {
      removeModalRef.current.show()
    }
  }
  const resetRemove = () => {
    setRemoveDocument(null)
  }
  const onRemoveSuccess = () => {
    fetchData()
  }
  const confirmRemove = () => {
    dispatch(
      clientRemoveDocument.remove({
        id: removeDocument.id,
        onSuccess: onRemoveSuccess,
      })
    )
  }

  //============================ADD AND EDIT LOGIC ============================///
  const [addDrawerVisible, setAddDrawerVisible] = useState(false)
  const [editDrawerVisible, setEditDrawerVisible] = useState(false)
  const [documentEdit, setDocumentEdit] = useState(null)
  const handleAdd = () => {
    setAddDrawerVisible(true)
  }

  const closeAddDrawer = () => {
    setAddDrawerVisible(false)
  }

  const handleEdit = item => {
    setDocumentEdit(item)
    setEditDrawerVisible(true)
  }

  const closeEditDrawer = () => {
    setEditDrawerVisible(false)
  }

  const handleEditSuccess = () => {
    fetchData()
  }
  const handleAddSuccess = () => {
    fetchData()
  }
  const editDrawerDestroy = () => {
    setDocumentEdit(null)
  }
  const fetchData = () => {
    let params = {
      page: props.page,
      per_page: 5,
    }
    dispatch(clientDocumentsList.getList({ id: props.client.id, params }))
  }

  const handlePageChange = item => {
    dispatch(clientDocumentsList.setPage(item.selected + 1))
  }
  useEffect(() => {
    fetchData()
  }, [props.page])
  useEffect(() => {
    return () => {
      dispatch(clientDocumentsList.cleanState())
      dispatch(clientRemoveDocument.cleanState())
    }
  }, [])
  return (
    <>
      <DocumentsInfoCard
        addMode
        onAdd={handleAdd}
        onEdit={handleEdit}
        onRemove={handleRemove}
        onPageChange={handlePageChange}
        error={props.listError}
        loading={props.listLoading}
        meta={props.meta}
        items={props.list}
        removeLoading={props.removeLoading}
      />

      <EditDocuments
        visible={editDrawerVisible}
        onClose={closeEditDrawer}
        onEditSuccess={handleEditSuccess}
        document={documentEdit}
        onDestroy={editDrawerDestroy}
      />
      <AddDocument
        visible={addDrawerVisible}
        onClose={closeAddDrawer}
        onAddSuccess={handleAddSuccess}
      />

      <ConfirmPopup
        ref={removeModalRef}
        onConfirm={confirmRemove}
        onClosed={resetRemove}
      />
    </>
  )
}

DocumentsContainerComponent.propTypes = {
  client: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  page: PropTypes.any,
  removeLoading: PropTypes.bool,
  error: PropTypes.object,
  meta: PropTypes.any,
}

const mapStateToProps = state => {
  const { data } = state.client.profile.data
  const { list, listLoading, listError, meta, page } =
    state.client.documents.list
  const { loading } = state.client.documents.remove
  return {
    client: data,
    list,
    listLoading,
    listError,
    meta,
    page,
    removeLoading: loading,
  }
}

export const DocumentsContainer = connect(mapStateToProps)(
  DocumentsContainerComponent
)
