import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Alert, Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"

import {
  Button,
  ControllerHF,
  Image,
  InputField,
} from "../../../../../../components"

const EditPhotoFormComponent = props => {
  const { t } = useTranslation()

  const initialValues = {
    title: props.photo?.title || "",
    description: props.photo?.description || "",
  }

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      ...initialValues,
    },
  })

  const onSubmit = values => {
    props.onSubmit(values, { setError, reset })
  }

  const onCancel = () => {
    props.onCancel()
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form
        className="form-horizontal edit-photo-form"
        noValidate={true}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row>
          <Col>
            <div className={"mb-4 model-photo"}>
              <Image
                src={props.photo?.attachment?.src}
                crops={props.photo?.crops}
                alt={"photo"}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <ControllerHF
              name="title"
              control={control}
              component={InputField}
              label={t("model.photo.title.label")}
              placeholder={t("model.photo.title.placeholder")}
              id={"title"}
              type="text"
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <ControllerHF
              name="description"
              control={control}
              component={InputField}
              label={t("model.photo.description.label")}
              placeholder={t("model.photo.description.placeholder")}
              id={"description"}
              type="textarea"
            />
          </Col>
        </Row>

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={() => onCancel()}
            />
          </div>
        </div>
      </form>
    </>
  )
}

EditPhotoFormComponent.propTypes = {
  photo: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const EditPhotoForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPhotoFormComponent)
