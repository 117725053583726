import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useForm } from "react-hook-form"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import { ControllerHF, InputField } from "components"
import { useFormValuesChangedDebounce } from "hooks"
import { chatContactsList } from "store/actions"

const ContactsSearchComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onFiltersChange = values => {
    const { keywords } = values
    values = {
      keywords: keywords.trim(),
    }
    dispatch(chatContactsList.changeFilter(values))
  }

  const defaultValues = useMemo(() => {
    const { keywords } = props.filters.values
    return {
      keywords,
    }
  }, [props.filters.values])

  const { control, watch } = useForm({
    defaultValues: {
      ...defaultValues,
    },
  })

  useFormValuesChangedDebounce(watch, onFiltersChange)

  return (
    <>
      <ControllerHF
        name={"keywords"}
        control={control}
        component={InputField}
        label={null}
        placeholder={t("search")}
        leftAccessory={
          <div className={"form-left-accessory"}>
            <i className={"mdi mdi-magnify font-size-16"} />
          </div>
        }
        autoComplete="off"
      />
    </>
  )
}

ContactsSearchComponent.propTypes = {
  filters: PropTypes.object,
}

const mapStateToProps = state => {
  const { filters } = state.chat.contacts.list

  return {
    filters,
  }
}

export const ContactsSearch = connect(mapStateToProps)(ContactsSearchComponent)
