import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "../../../i18n"
import { API } from "../../../api"
import { calendarEventSubmit } from "./slice"

function* submitEvent({ payload: { params, onSuccess, onError } }) {
  try {
    const { id } = params;

    const response = id
      ? yield call(API.calendar.updateEvent, id, params)
      : yield call(API.calendar.createEvent, params);

    const { data } = response

    yield put(calendarEventSubmit.submitSuccess())

    toastr.success(i18n.t("save.success"))

    if (onSuccess) {
      onSuccess(data?.data)
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      calendarEventSubmit.submitError({
        errors,
        message,
      })
    )

    toastr.error(message)

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchSubmitEvent() {
  yield takeEvery(calendarEventSubmit.submit, submitEvent)
}

function* submitSaga() {
  yield all([fork(watchSubmitEvent)])
}

export default submitSaga
