import {
    PROFILE_REMOVE_USER,
    PROFILE_REMOVE_USER_SUCCESS,
    PROFILE_REMOVE_USER_ERROR,
    PROFILE_REMOVE_USER_CLEAN_STATE,
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
};

const remove = (state = initialState, action) => {
    switch (action.type) {
        case PROFILE_REMOVE_USER:
            state = {
                ...state,
                loading: true,
                error: null,
            };
            break;

        case PROFILE_REMOVE_USER_SUCCESS:
            state = {
                ...state,
                loading: false,
            };
            break;

        case PROFILE_REMOVE_USER_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        case PROFILE_REMOVE_USER_CLEAN_STATE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default remove;