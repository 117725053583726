import { takeLatest, fork, put, all, call, select } from "redux-saga/effects"
import toastr from "toastr"
import _ from "lodash"

import i18n from "i18n"
import { API } from "api"
import { chatConversationMessagesList } from "./slice"
import { readChatSubmit } from "../read/saga"
import { userProfileUpdateUnreadMessages } from "../../../../user/actions"

export const userState = state => state.user.profile

export function* getList({
  payload: {
    userId,
    chatId,
    isGroup,
    params,
    state: { isMoreLoading },
  },
}) {
  try {
    const response = isGroup
      ? yield call(API.chat.getGroupMessages, chatId, params)
      : yield call(API.chat.getUserMessages, userId, params)

    const { data } = response
    yield put(chatConversationMessagesList.getListSuccess(data))

    const messages = data.data
    const { unread_count } = data.meta

    if (messages.length > 0 && !isMoreLoading) {
      let unSeenMessage = null
      // need for case: when user added to group and group has message history
      if (isGroup && unread_count === 0) {
        unSeenMessage = _.find(messages, { seen: false, is_my: false })
      }

      if (unread_count || unSeenMessage) {
        const messageChatId = messages[0].chat_id

        // read chat (seen messages)
        const readChat = yield call(readChatSubmit, {
          payload: { userId, chatId: messageChatId, isGroup },
        })

        if (readChat && unread_count) {
          const user = yield select(userState)
          const count = user.data.unread_messages - unread_count

          // update count all of unread messages (- badge in side menu)
          yield put(userProfileUpdateUnreadMessages(count))
        }
      }
    }
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(chatConversationMessagesList.getListError(message))

    toastr.error(message)
  }
}

export function* watchGetList() {
  yield takeLatest(chatConversationMessagesList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
