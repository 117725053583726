import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
  loading: false,
  error: null,
}

const staticPageSlice = createSlice({
  name: "staticPage",
  initialState,
  reducers: {
    getData(state) {
      state.loading = true
      state.error = null
    },
    getDataSuccess(state, action) {
      state.loading = false
      state.data = action.payload.data
    },
    getDataError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})

/**
 * @namespace
 * @property {function} getData
 * @property {function} getDataSuccess
 * @property {function} getDataError
 * @property {function} cleanState
 */

export const staticPage = staticPageSlice.actions

export default staticPageSlice.reducer
