import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import i18n from "i18n"
import { API } from "api"
import { billingPay } from "./slice"
import { stripeSetup } from "../stripe/setup/saga"

function* stripeSetupAndPay({
  payload: { stripe, elements, payload, onSuccess },
}) {
  try {
    const { setupIntent, error } = yield call(stripeSetup, {
      payload: { stripe, elements, params: { ...payload } },
    })

    if (error) {
      yield put(billingPay.payError({ errors: null, message: error.message }))
    } else {
      const paymentMethod = setupIntent.payment_method

      yield call(stripePay, { payload: { paymentMethod, payload, onSuccess } })
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      billingPay.payError({
        errors,
        message,
      })
    )
  }
}

function* stripePay({ payload: { paymentMethod, payload, onSuccess } }) {
  yield call(stripePaySubscription, {
    payload: { paymentMethod, payload, onSuccess },
  })
}

function* stripePaySubscription({
  payload: { paymentMethod, payload, onSuccess },
}) {
  try {
    const params = {
      payment_method: paymentMethod,
      tariff: payload.tariffId,
    }

    const response = yield call(API.billing.subscriptionPay, params)
    const { data } = response

    yield call(stripePayHandler, { data: data?.data, onSuccess })
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      billingPay.payError({
        errors,
        message,
      })
    )
  }
}

function* stripePayHandler({ data, onSuccess }) {
  const { success, type, message, url } = data

  if (success) {
    yield put(billingPay.paySuccess())

    if (onSuccess) {
      onSuccess()
    }
  } else if (url) {
    // redirect to 3d secure page
    window.location.href = url
  } else if (message) {
    // error
    yield put(billingPay.payError({ errors: null, message }))
  }
}

export function* watchStripeSetupAndPay() {
  yield takeEvery(billingPay.stripeSetupAndPay, stripeSetupAndPay)
}

export function* watchStripePay() {
  yield takeEvery(billingPay.stripePay, stripePay)
}

function* paySaga() {
  yield all([fork(watchStripeSetupAndPay), fork(watchStripePay)])
}

export default paySaga
