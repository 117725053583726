import React from "react"
import PropTypes from "prop-types"
import { IconButton } from "components/Buttons"
import { MediaOverviewCard } from "components/MediaOverviewCard"
import { useFormContext } from "react-hook-form"
import { Image } from "components"

export const ModelPhoto = ({ onEditPhoto }) => {
  const { watch } = useFormContext()

  const photo = watch("photo")

  const renderActions = () => {
    return (
      <>
        <IconButton name={"edit"} onClick={onEditPhoto} bg />
      </>
    )
  }
  const renderContent = () => {
    return <Image src={photo?.attachment?.src} alt={"post-photo"} />
  }
  return (
    <MediaOverviewCard
      actions={renderActions()}
      content={renderContent()}
      size={"sm"}
      className={"d-inline-flex justify-content-start"}
    />
  )
}

ModelPhoto.propTypes = {
  onEditPhoto: PropTypes.func,
}
