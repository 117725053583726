import React, { useState } from "react"
import PropTypes from "prop-types"
import { CommonUtils } from "utils"
import { Button, ConfirmPopup } from "components"
import { useConfirmModal } from "hooks"

const { isNil } = CommonUtils

export const Payment = ({ data = {}, onPayment }) => {
  const { payment_status } = data

  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    try {
      setLoading(true)

      await onPayment?.(data)
    } finally {
      setLoading(false)
    }
  }

  const { modalRef, closeModal, handleConfirm, showModal } = useConfirmModal(
    () => {
      void onSubmit()
    },
    () => {}
  )
  // === //

  return (
    <>
      {!isNil(payment_status) && !payment_status ? (
        <>
          <Button
            loading={loading}
            disabled={loading}
            title={"pay"}
            onClick={() => {
              showModal()
            }}
            type={"button"}
            size={"btn-sm"}
            style={{
              minWidth: "5.5rem",
            }}
          />

          <ConfirmPopup
            ref={modalRef}
            onConfirm={handleConfirm}
            onClosed={closeModal}
            questionText={"sure_make_payment"}
          />
        </>
      ) : null}
    </>
  )
}

Payment.propTypes = {
  data: PropTypes.object,
  onPayment: PropTypes.func,
}
