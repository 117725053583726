import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { InputField } from "../../../../../../components"
import { useTranslation } from "react-i18next"
import { WEBSITE_DOMAIN_PATH } from "../../../../../../consts"

const EditDomainComponent = props => {
  const { t } = useTranslation()
  return (
    <InputField
      value={`${props.website.domain}${WEBSITE_DOMAIN_PATH}`}
      disabled={true}
      label={t("domain")}
      placeholder={t("subdomain")}
    />
  )
}

EditDomainComponent.propTypes = {
  website: PropTypes.object,
}

const mapStateToProps = state => {
  const { data: website } = state.website.general.data
  return {
    website,
  }
}

const mapDispatchToProps = {}

export const EditDomain = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDomainComponent)
