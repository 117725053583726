import { createSlice } from "@reduxjs/toolkit"


const initialState = {
  data: null,
  loading: false,
  error: null,
}

const agencyProfileDataSlice = createSlice({
  name: "agencyProfileData",
  initialState,
  reducers: {
    getData(state) {
      state.loading = true
      state.error = null
    },
    getDataCompleted(state, action) {
      state.loading = false
      state.data = action.payload.data
    },
    getDataRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    updateData(state, action) {
      state.data =  {...state.data, ...action.payload}
    },
    cleanState() {
      return initialState
    }
  }
})

export const agencyProfileData = agencyProfileDataSlice.actions

export default agencyProfileDataSlice.reducer