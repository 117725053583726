import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const calendarEventContractCreateSlice = createSlice({
  name: "calendarEventContractCreate",
  initialState,
  reducers: {
    create(state) {
      state.loading = true
      state.error = null
    },
    createSuccess(state) {
      state.loading = false
    },
    createError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})

/**
 * @namespace
 * @property {function} create
 * @property {function} createSuccess
 * @property {function} createError
 * @property {function} cleanState
 */

export const calendarEventContractCreate =
  calendarEventContractCreateSlice.actions

export default calendarEventContractCreateSlice.reducer
