import React, { useState } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { ChangePermissions, SearchInput } from "components"
import { CLIENTS_TABLE, EMPLOYEES_TABLE, MODELS_TABLE } from "consts"

export const TableFilter = ({ onSearch, graphData }) => {
  const { t } = useTranslation()

  const [searchInputValue, setSearchInputValue] = useState("")

  const handleInputChange = value => {
    setSearchInputValue(value)
  }
  const getPlaceholderText = alias => {
    switch (alias) {
      case MODELS_TABLE:
        return t("start_typing_models")
      case CLIENTS_TABLE:
        return t("start_typing_clients")
      case EMPLOYEES_TABLE:
        return t("start_typing_employees")
    }
  }

  return (
    <Row className={"mb-2"}>
      <Col md={4} xs={10}>
        <SearchInput
          value={searchInputValue}
          onInputChange={handleInputChange}
          onSearch={onSearch}
          placeholder={getPlaceholderText(graphData.alias)}
          autoComplete={"off"}
        />
      </Col>
      <Col md={4} xs={2} className={"align-self-center"}>
        <ChangePermissions graphData={graphData} />
      </Col>
    </Row>
  )
}

TableFilter.propTypes = {
  onSearch: PropTypes.func,
  graphData: PropTypes.object,
}
