import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { DrawerContainer } from "components"
import { PaymentInfo } from "./PaymentInfo"
import { AgencyStatementsTable } from "./AgencyStatementsTable"
import { ModelStatementsTable } from "./ModelStatementsTable"
import { APP_ENTITY } from "consts"

const { MODEL, AGENCY } = APP_ENTITY

export const StatementPaymentDetails = props => {
  const { t } = useTranslation()

  const onDestroy = () => {
    props.onDestroy && props.onDestroy()
  }

  return (
    <DrawerContainer
      title={t("payment_details")}
      visible={props.visible}
      onClose={props.onClose}
      onDestroy={onDestroy}
    >
      {props.data && (
        <>
          <PaymentInfo data={props.data} />

          <div className={"mt-4"}>
            <h4 className={"mb-3"}>{t("statements")}</h4>

            {props.entity === MODEL && (
              <ModelStatementsTable list={props.data.statements} />
            )}

            {props.entity === AGENCY && (
              <AgencyStatementsTable list={props.data.statements} />
            )}
          </div>
        </>
      )}
    </DrawerContainer>
  )
}

StatementPaymentDetails.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  data: PropTypes.object,
  entity: PropTypes.oneOf([MODEL, AGENCY]),
}
