import axios from 'axios';

import { RequestUtils } from '../../utils';

class EmployeeAPI {
    getEmployeeList = async (params) => {
        return axios.get('api/employees', {
            params,
            paramsSerializer: () => RequestUtils.paramsSerializer(params),
        });
    };

    getEmployee = async (id) => {
        return axios.get(`api/employees/${id}`);
    };

    createEmployee = async (params) => {
        return axios.post('api/employees', params);
    };

    editEmployee = async (id, params) => {
        return axios.put(`api/employees/${id}`, params);
    };

    removeEmployee = async (id) => {
        return axios.delete(`api/users/${id}`);
    };
    //=====================RATING=============================//
    getRatingsData = async (id, params) => {
        return axios.get(`api/employees/${id}/ratings`, {
            params,
            paramsSerializer: () => RequestUtils.paramsSerializer(params),
        });
    };

    addRating = async (id, params) => {
        return axios.post(`api/employees/${id}/ratings`, params);
    };

    removeRating = async (id) => {
        return axios.delete(`api/employees/ratings/${id}`);
    };
}

export const employee = new EmployeeAPI();
