import {
  USER_PROFILE_SET_DATA,
  USER_PROFILE_CLEAN_STATE,
  USER_PROFILE_UPDATE_DATA,
  USER_PROFILE_UPDATE_COMPANY_LOGO,
  USER_PROFILE_UPDATE_SUBSCRIPTION,
  USER_PROFILE_RESTORE_COMPANY,
  USER_PROFILE_UPDATE_UNREAD_MESSAGES,
} from "./actionTypes"

const initialState = {
  data: null,
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case USER_PROFILE_SET_DATA:
      state = {
        ...state,
        data: action.payload,
      }
      break

    case USER_PROFILE_CLEAN_STATE:
      state = {
        ...state,
        ...initialState,
      }
      break

    case USER_PROFILE_UPDATE_DATA:
      state = {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      }
      break

    case USER_PROFILE_UPDATE_COMPANY_LOGO:
      state = {
        ...state,
        data: {
          ...state.data,
          company: {
            ...state.data.company,
            avatar: action.payload,
          },
        },
      }
      break

    case USER_PROFILE_UPDATE_SUBSCRIPTION:
      state = {
        ...state,
        data: {
          ...state.data,
          subscription: {
            ...state.data?.subscription,
            ...action.payload,
          },
        },
      }
      break

    case USER_PROFILE_RESTORE_COMPANY:
      const { deleted_at, ...company } = state.data.company
      state = {
        ...state,
        data: {
          ...state.data,
          company: {
            ...company,
          },
        },
      }
      break

    case USER_PROFILE_UPDATE_UNREAD_MESSAGES:
      state = {
        ...state,
        data: {
          ...state.data,
          unread_messages: action.payload >= 0 ? action.payload : 0,
        },
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
