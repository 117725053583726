import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const billingSubscriptionSwapSlice = createSlice({
  name: "billingSubscriptionSwap",
  initialState,
  reducers: {
    swap(state) {
      state.loading = true
      state.error = null
    },
    swapSuccess(state) {
      state.loading = false
    },
    swapError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} swap
 * @property {function} swapSuccess
 * @property {function} swapError
 * @property {function} cleanState
 */
export const billingSubscriptionSwap = billingSubscriptionSwapSlice.actions

export default billingSubscriptionSwapSlice.reducer
