import _ from "lodash"
import moment from "moment"

import i18n from "../../i18n"
import {
  RATE_TYPE_GENERAL,
  RATE_TYPE_EACH_DAY,
  RATE_PERIOD_TYPE_DAY,
  RATE_PERIOD_TYPE_HALF_DAY,
  RATE_PERIOD_TYPE_HOUR,
  RATE_PERIOD_TYPE_FLAT,
} from "../../consts"

// Date types
export const getRateTypes = () => {
  return [
    {
      label: i18n.t("rate-type.general"),
      value: RATE_TYPE_GENERAL,
    },
    {
      label: i18n.t("rate-type.each-day"),
      value: RATE_TYPE_EACH_DAY,
    },
  ]
}

export const getRateType = value => {
  const options = getRateTypes()
  return _.find(options, { value: value })
}

export const isGeneralType = value => value === RATE_TYPE_GENERAL

export const isEachDayType = value => value === RATE_TYPE_EACH_DAY
// === //

// Rate period types
export const getRatePeriodTypes = () => {
  return [
    {
      label: i18n.t("rate-period-type.day"),
      value: RATE_PERIOD_TYPE_DAY,
    },
    {
      label: i18n.t("rate-period-type.half-day"),
      value: RATE_PERIOD_TYPE_HALF_DAY,
    },
    {
      label: i18n.t("rate-period-type.hour"),
      value: RATE_PERIOD_TYPE_HOUR,
    },
    {
      label: i18n.t("rate-period-type.flat-rate"),
      value: RATE_PERIOD_TYPE_FLAT,
    },
  ]
}

export const getRatePeriodType = value => {
  const options = getRatePeriodTypes()
  return _.find(options, { value: value })
}
// === //

// Calculated rate data
export const getRateCalculatedData = (values, byConvertedValue) => {
  const value = byConvertedValue
    ? Number(values.converted_value)
    : Number(values.value)
  const count = Number(values.count)
  const model_commission = Number(values.model_commission)

  let total_fee = value * count
  total_fee = Math.round((total_fee + Number.EPSILON) * 100) / 100

  let net_for_model = (total_fee * model_commission) / 100
  net_for_model = Math.round((net_for_model + Number.EPSILON) * 100) / 100

  return {
    total_fee,
    net_for_model,
  }
}

export const getRateCalculatedAgencyCommissionAmount = (totalFee, percent) => {
  totalFee = Number(totalFee)
  percent = Number(percent)
  let amount = (totalFee * percent) / 100
  amount = Math.round((amount + Number.EPSILON) * 100) / 100

  return amount
}

export const getRateCalculatedModelCommissionPercent = (
  totalFee,
  net_for_model
) => {
  totalFee = Number(totalFee)
  net_for_model = Number(net_for_model)

  let model_commission = 0

  if (totalFee) {
    model_commission = ((totalFee - net_for_model) * 100) / totalFee
  }

  // model_commission = Math.round((model_commission + Number.EPSILON) * 100) / 100

  return model_commission
}

export const mapRatesData = data => {
  let items = []

  let sumTotalFees = {}
  let sumAgencyFees = {}
  let sumTotal = {}

  data.forEach(item => {
    let { total_fee, net_for_model } = getRateCalculatedData(item)

    let { agency_commission, currency } = item
    total_fee = Number(total_fee)
    const agencyAmount = getRateCalculatedAgencyCommissionAmount(
      total_fee,
      agency_commission
    )
    const total = total_fee + agencyAmount
    items = [
      ...items,
      { ...item, total_fee, net_for_model, agencyAmount, total },
    ]

    sumTotalFees = {
      ...sumTotalFees,
      [currency]: (sumTotalFees[currency] || 0) + total_fee,
    }

    sumAgencyFees = {
      ...sumAgencyFees,
      [currency]: (sumAgencyFees[currency] || 0) + agencyAmount,
    }

    sumTotal = {
      ...sumTotal,
      [currency]: (sumTotalFees[currency] || 0) + sumAgencyFees[currency],
    }
  })

  return {
    items,
    totalFees: sumTotalFees,
    agencyFees: sumAgencyFees,
    total: sumTotal,
  }
}

export const generateRatesForEachDay = (rate, period = {}) => {
  let { start, end } = period

  if (!start || !end) {
    return []
  }

  start = moment(start)
  end = moment(end)

  let rates = []

  while (start.isSameOrBefore(end)) {
    rates = [...rates, { ...rate, date: start.format("YYYY-MM-DD") }]

    start.add(1, "days")
  }
  return rates
}
