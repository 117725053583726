import { combineReducers } from "redux"

import load from "./load/reducer"
import profile from "./profile/reducer"
import settings from "./settings/reducers"
import options from "./options/reducers"
import department from "./department/reducers"

const userReducer = combineReducers({
  load,
  profile,
  settings,
  options,
  department,
})

export default userReducer
