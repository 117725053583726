import {
    MODEL_PANEL_GENERAL_FETCH_FEATURES,
    MODEL_PANEL_GENERAL_FETCH_FEATURES_SUCCESS,
    MODEL_PANEL_GENERAL_FETCH_FEATURES_ERROR,
    MODEL_PANEL_GENERAL_FETCH_FEATURES_CLEAN_STATE,
} from './actionTypes';

const initialState = {
    data: null,
    loading: false,
    error: null,
};

const data = (state = initialState, action) => {
    switch (action.type) {
        case MODEL_PANEL_GENERAL_FETCH_FEATURES:
            state = {
                ...state,
                loading: true,
                error: null,
            };
            break;

        case MODEL_PANEL_GENERAL_FETCH_FEATURES_SUCCESS:
            state = {
                ...state,
                loading: false,
                data: action.payload,
            };
            break;

        case MODEL_PANEL_GENERAL_FETCH_FEATURES_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        case MODEL_PANEL_GENERAL_FETCH_FEATURES_CLEAN_STATE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default data;