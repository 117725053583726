import { all } from "redux-saga/effects"

import listSaga from "./list/saga"
import drawerListSaga from "./drawerList/saga"
import updateBookSaga from "./updateBook/saga"

function* modelsSaga() {
  yield all([listSaga(), drawerListSaga(), updateBookSaga()])
}

export default modelsSaga
