import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Modal, Button } from "components"
import { Table } from "./Table"

export const ClicksViewModal = props => {
  const { t } = useTranslation()

  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    if (modalVisible) {
    }
    return () => {
      if (modalVisible) {
      }
    }
  }, [modalVisible])

  return (
    <Modal
      ref={props.modalRef}
      title={t("clicks")}
      onVisibleChange={value => setModalVisible(value)}
      strapModalProp={{
        centered: true,
      }}
      size={null}
    >
      <Table data={props.data} />

      <div className={"mt-3"}>
        <Button
          title={"close"}
          color="dark-gray"
          outline
          onClick={props.onCancel}
        />
      </div>
    </Modal>
  )
}

ClicksViewModal.propTypes = {
  modalRef: PropTypes.any,
  onCancel: PropTypes.func,
  data: PropTypes.object,
}
