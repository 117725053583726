import ratingDefault from '../../assets/images/dev/rating/smile-default.svg';
import rating1 from '../../assets/images/dev/rating/smile-1.svg';
import rating2 from '../../assets/images/dev/rating/smile-2.svg';
import rating3 from '../../assets/images/dev/rating/smile-3.svg';
import rating4 from '../../assets/images/dev/rating/smile-4.svg';
import rating5 from '../../assets/images/dev/rating/smile-5.svg';

import i18n from '../../i18n';

export const getConfig = (value) => {
    let data = {
        icon: ratingDefault,
        label: i18n.t('rating.label.default'),
    };

    value = Math.round(Number(value));

    switch (value) {
        case 1:
            data = {
                icon: rating1,
                label: i18n.t('rating.label.1'),
            };
            break;

        case 2:
            data = {
                icon: rating2,
                label: i18n.t('rating.label.2'),
            };
            break;

        case 3:
            data = {
                icon: rating3,
                label: i18n.t('rating.label.3'),
            };
            break;

        case 4:
            data = {
                icon: rating4,
                label: i18n.t('rating.label.4'),
            };
            break;

        case 5:
            data = {
                icon: rating5,
                label: i18n.t('rating.label.5'),
            };
            break;

        default:
            data = { ...data };
            break;
    }
    return data;
};