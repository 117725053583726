import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useFetchImage } from "hooks"
import { PhotoCard } from "./PhotoCard"
import * as HOC from "HOC"
import { ImageCropControl } from "components/Controls/ImageCropControl"
import { Button } from "components/Buttons"
import { Alert } from "reactstrap"
import { useEditModelPhotoCrop } from "./useEditModelPhotoCrop"
import { formInitialValue } from "./helper"

const DataShower = HOC.withDataShower()

export const ModelPhotoCropControl = ({ cropType, photo, onCropChange }) => {
  const { crops, attachment, id } = photo
  const cropData = crops.find(crop => crop.type === cropType)
  const isCropData = !!cropData

  const { loading, error, cleanState, editCrop } = useEditModelPhotoCrop()

  const [cropValue, setCropValue] = useState(formInitialValue(cropData))

  const [isEditCropMode, setIsEditCropMode] = useState(!isCropData)

  const { fetchImage, state: imageState, cancelFetch } = useFetchImage()

  useEffect(() => {
    fetchImage(attachment.src)
    return () => {
      cancelFetch()
      cleanState()
    }
  }, [attachment])

  const handleEditCropClick = () => {
    setIsEditCropMode(true)
  }

  const handleCropChange = value => {
    setCropValue(value)
  }

  const handleCancel = () => {
    setIsEditCropMode(false)
  }

  const handleAccept = () => {
    editCrop(cropValue, cropType, id).then(data => {
      onCropChange(data)
      setIsEditCropMode(false)
    })
  }

  if (!isEditCropMode) {
    return <PhotoCard editCrop={handleEditCropClick} src={cropData.src} />
  }

  return (
    <DataShower
      isLoading={imageState.loading}
      isFailed={imageState.error}
      error={imageState.error}
    >
      {error && <Alert color="danger">{error.message}</Alert>}
      <ImageCropControl
        value={cropValue}
        onChange={handleCropChange}
        imageDataURL={imageState.imageDataURL}
        hasCrop={isCropData}
        aspect={1}
      />
      <div className={"d-flex justify-content-around "}>
        {isCropData && (
          <Button
            onClick={handleCancel}
            title={"cancel"}
            outline
            disabled={loading}
          />
        )}
        <Button
          disabled={loading}
          loading={loading}
          color="secondary"
          title={"accept"}
          onClick={handleAccept}
        />
      </div>
    </DataShower>
  )
}

ModelPhotoCropControl.propTypes = {
  photo: PropTypes.object,
  onCropChange: PropTypes.func,
  cropType: PropTypes.oneOf(["instagram", "square", "rectangle"]),
}
