import { all } from "redux-saga/effects"

import createSaga from "./create/saga"
import viewSaga from "./view/sagas"
import editSaga from "./edit/sagas"
import removeSaga from "./remove/saga"
import sendEmailSaga from "./sendEmail/saga"

function* invoiceSaga() {
  yield all([
    createSaga(),
    viewSaga(),
    editSaga(),
    removeSaga(),
    sendEmailSaga(),
  ])
}

export default invoiceSaga
