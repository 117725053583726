import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import {
  Button,
  ConfirmPopup,
  OverlayBlockLoading,
  SelectedListControl,
} from "components"
import { GroupSelect } from "./GroupSelect"
import { connect, useDispatch } from "react-redux"
import {
  addModelToGroup as addModelToGroupAC,
  modelGroupsList,
  removeModelFromGroup,
} from "store"
import { useConfirmModal } from "hooks"

export const GroupsSectionComponent = props => {
  const dispatch = useDispatch()
  const [selectedGroup, setSelectedGroup] = useState(null)

  useEffect(() => {
    fetchGroups()
    return () => {
      dispatch(modelGroupsList.cleanState())
    }
  }, [])

  const fetchGroups = () => {
    dispatch(
      modelGroupsList.getList({
        modelId: props.model.id,
        params: {
          pagination: false,
        },
      })
    )
  }
  const {
    showModal: removeFromGroup,
    closeModal,
    handleConfirm,
    modalRef,
  } = useConfirmModal(
    item => {
      dispatch(
        removeModelFromGroup.remove({
          modelId: props.model.id,
          groupId: item.id,
        })
      )
    },
    () => dispatch(removeModelFromGroup.cleanState())
  )
  const handleAddSuccess = () => {
    fetchGroups()
    setSelectedGroup(null)
  }
  const addModelToGroup = () => {
    let params = {
      ...selectedGroup,
      model_ids: [props.model.id],
    }
    dispatch(
      addModelToGroupAC.add({
        id: selectedGroup.id,
        params,
        onSuccess: handleAddSuccess,
      })
    )
  }
  const handleSelect = option => {
    setSelectedGroup(option)
  }

  return (
    <>
      <Row>
        <Col md={6} sm={9}>
          <GroupSelect
            gender={props.model.gender}
            modelGroups={props.groups}
            onSelect={handleSelect}
            value={selectedGroup}
          />
        </Col>
        <Col md={2} sm={3}>
          <Button
            title={"add"}
            onClick={addModelToGroup}
            btnClass={"w-100 mb-3"}
            disabled={!selectedGroup}
          />
        </Col>
      </Row>
      <Row className={""}>
        <Col md={8}>
          <OverlayBlockLoading isLoading={props.listLoading}>
            <SelectedListControl
              items={props.groups}
              renderItemContent={item => item.group_name}
              onRemove={removeFromGroup}
            />
          </OverlayBlockLoading>
        </Col>
      </Row>
      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
        questionText={"sure-remove-from-group"}
      />
    </>
  )
}

GroupsSectionComponent.propTypes = {
  model: PropTypes.object,
  groups: PropTypes.array,
  listLoading: PropTypes.bool,
}

const mapStateToProps = state => {
  const { data: model } = state.model.profile
  const { list: groups, listLoading } = state.model.groups.list
  return {
    model,
    groups,
    listLoading,
  }
}

export const GroupsSection = connect(mapStateToProps)(GroupsSectionComponent)
