import _ from 'lodash';

import i18n from '../../i18n';

export const getEmailNotifyOptions = (entity) => {
    return [{
        label: i18n.t('email-notify.visit-first', { entity: entity }),
        value: 'first',
    }, {
        label: i18n.t('email-notify.visit-each', { entity: entity }),
        value: 'ever',
    }];
};

export const getEmailNotifyOption = (value) => {
    const options = getEmailNotifyOptions();
    return _.find(options, { value: value });
};