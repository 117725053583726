import React from "react"
import PropTypes from "prop-types"
import { Route, withRouter, Redirect } from "react-router-dom"
import { connect } from "react-redux"

import { middlewareHelper } from "helpers"
import {
  ROUTE_MIDDLEWARE_USER_SETTINGS,
  ROUTE_MIDDLEWARE_SUBSCRIPTION,
  ROLE_DIRECTOR,
  ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
  MIDDLEWARE_PREVIEW_MODE,
} from "consts"

const ProtectedRouteComponent = ({
  component: Component,
  path,
  middlewares = [],
  ...rest
}) => {
  const withMiddleware = middleware => {
    return middlewares.includes(middleware)
  }

  return (
    <Route {...rest}>
      {withMiddleware(MIDDLEWARE_PREVIEW_MODE) &&
      !middlewareHelper.previewMode.isAllow() ? (
        <Redirect
          to={{ pathname: "/calendar", state: { from: rest.location } }}
        />
      ) : withMiddleware(ROUTE_MIDDLEWARE_USER_SETTINGS) &&
        !middlewareHelper.userSettings.isAllow(rest.user) ? (
        <Redirect
          to={{ pathname: "/questionnaire", state: { from: rest.location } }}
        />
      ) : withMiddleware(ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED) &&
        middlewareHelper.company.isCompanyDeactivated(rest.user) ? (
        <Redirect
          to={{ pathname: "/profile", state: { from: rest.location } }}
        />
      ) : withMiddleware(ROUTE_MIDDLEWARE_SUBSCRIPTION) &&
        !middlewareHelper.subscription.isAllow(rest.user) ? (
        <Redirect
          to={{
            pathname:
              rest.user.role === ROLE_DIRECTOR ? "/billing" : "/dashboard",
            state: { from: rest.location },
          }}
        />
      ) : (
        <Component {...rest} />
      )}
    </Route>
  )
}

ProtectedRouteComponent.propTypes = {
  component: PropTypes.any,
  path: PropTypes.string,
  middlewares: PropTypes.array,

  user: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.user.profile
  return {
    user: data,
  }
}

export const ProtectedRoute = withRouter(
  connect(mapStateToProps, {})(ProtectedRouteComponent)
)
