import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import _ from "lodash"

import { PackageDataGrid } from "../../../../../PackageDataGrid"
import { Pagination } from "../../../../../../Pagination"
import { PackageDataAddButton } from "../../../../../PackageDataAddButton"
import { BookOverviewCard } from "../../../../../../Books"

import * as HOC from "../../../../../../../HOC"
const DataShower = HOC.withDataShowerOverlay()

export const BooksGrid = props => {
  const { t } = useTranslation()

  return (
    <DataShower
      isLoading={props.loading}
      isFailed={props.error}
      error={props.error}
    >
      {!!props.items.length ? (
        <>
          <PackageDataGrid
            className={"package-data-picker-grid"}
            items={props.items}
            renderItem={item => (
              <BookOverviewCard
                cover={item?.cover?.photo?.attachment?.src}
                crops={item?.cover?.photo?.crops}
                title={item?.title}
                description={item?.description}
                size={"sm"}
                actions={
                  <PackageDataAddButton
                    status={!!_.find(props.picked, { id: item.id })}
                    onClick={() => props.onAdd(item)}
                    disabled={false}
                  />
                }
              />
            )}
          />

          <Pagination data={props.meta} onPageChange={props.onPageChange} />
        </>
      ) : !props.loading ? (
        t("empty")
      ) : null}
    </DataShower>
  )
}

BooksGrid.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,
  picked: PropTypes.array,

  onPageChange: PropTypes.func,
  onAdd: PropTypes.func,
}
