import { all } from "redux-saga/effects"

import listSaga from "./list/saga"
import removeFromGroupSaga from "./removeFromGroup/saga"
import addToGroupSaga from "./addToGroup/saga"

function* groupsSaga() {
  yield all([listSaga(), removeFromGroupSaga(), addToGroupSaga()])
}

export default groupsSaga
