import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import i18n from '../../../../../../i18n';
import { MODEL_PANEL_GENERAL_FETCH_DOCUMENT } from './actionTypes';
import { modelPanelGeneralFetchDocumentSuccess, modelPanelGeneralFetchDocumentError } from './actions';

import { API } from '../../../../../../api';

export function* fetchDocument({ payload: { id } }) {
    try {
        const response = yield call(API.model.getModelDocument, id);

        const { data } = response;

        yield put(modelPanelGeneralFetchDocumentSuccess(data?.data));
    } catch (error) {
        const message = error.response?.data?.message || i18n.t('error');
        yield put(modelPanelGeneralFetchDocumentError(message));
    }
}

export function* watchFetchDocument() {
    yield takeEvery(MODEL_PANEL_GENERAL_FETCH_DOCUMENT, fetchDocument)
}

function* dataSaga() {
    yield all([
        fork(watchFetchDocument),
    ]);
}

export default dataSaga;