import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { IconButton } from "../../../../../../components"
import { modelHelper } from "../../../../../../helpers"

export const AgenciesTable = props => {
  const { t } = useTranslation()

  const inActionPending = id => props.removingItems.includes(id)

  return (
    <div className="table-responsive">
      <table className="table table-nowrap align-middle table-cell_p-v_sm">
        <tbody>
          {props.items.map(item => (
            <tr key={item.id}>
              <td>
                <div className="d-flex">
                  <div className={"float-left align-items-center me-2"}>
                    <div className={"mark-dot-pointer pos-t_d_2"} />
                  </div>

                  {item.current_location ? (
                    <div className={"float-left align-items-center me-2"}>
                      <i className={"fas fa-map-marker-alt text-secondary"} />
                    </div>
                  ) : null}

                  <div>
                    {item.as_type && (
                      <span className={"fw-medium"}>
                        {`${
                          modelHelper.agency.getAgencyAsOption(item.as_type)
                            .label
                        }: `}
                      </span>
                    )}
                    {item.agency?.name} – {item.agency?.country?.name}
                  </div>
                </div>
              </td>

              <td
                className={"table-column-sticky_end bg-neutral text-nowrap"}
                style={{ width: "76px" }}
              >
                <IconButton
                  name={"edit"}
                  bg
                  onClick={() => props.onEdit(item)}
                  disabled={inActionPending(item.id)}
                />

                <IconButton
                  name={"remove"}
                  bg
                  onClick={() => props.onRemove(item)}
                  disabled={inActionPending(item.id)}
                  wrapClass={"ms-2"}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

AgenciesTable.propTypes = {
  items: PropTypes.array,
  removingItems: PropTypes.array,

  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
}
