import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
import classNames from "classnames"
import { useFieldArray } from "react-hook-form"

import { IconButton } from "../../Buttons"
import { InputField, ComboboxFormField } from "../../FormElements"
import { NormalizeUtils } from "../../../utils"
import { ControllerHF } from "../ControllerHF"

export const PhonesCountriesFieldArrayHF = props => {
  const { t } = useTranslation()
  const error = props?.meta?.error
  const fieldName = props.name

  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: fieldName,
    keyName: "fieldId",
  })

  const addRow = e => {
    e.preventDefault()
    if (props.clearErrors) {
      props.clearErrors(props.name)
    }
    append({ value: "", country: null })
  }

  const removeRow = index => {
    remove(index)
  }

  return (
    <Col>
      {fields.map((item, index) => (
        <Row key={item.fieldId}>
          <Col xs={8} md={4}>
            <ControllerHF
              name={`${fieldName}.${index}.value`}
              control={props.control}
              component={InputField}
              id={`${fieldName}.${index}.value`}
              label={t("field.label.phone")}
              placeholder={t("field.placeholder.phone")}
              normalize={NormalizeUtils.phone}
              type="text"
            />
          </Col>

          <Col xs={8} md={4}>
            <ControllerHF
              name={`${fieldName}.${index}.country`}
              control={props.control}
              component={ComboboxFormField}
              id={`${fieldName}.${index}.country`}
              label={t("country")}
              placeholder={t("country")}
              options={props.countryOptions}
              getOptionLabel={option => `${option.name}`}
              getOptionValue={option => `${option.id}`}
              selectProps={{
                isClearable: true,
              }}
            />
          </Col>

          <Col xs={2} md={1}>
            <div className={"mb-3 form-action-button-block"}>
              <IconButton name={"remove"} bg onClick={() => removeRow(index)} />
            </div>
          </Col>
        </Row>
      ))}

      {fields.length < props.limit && (
        <Row>
          <Col>
            <Link
              to="#"
              className="text-secondary text-decoration-underline"
              onClick={addRow}
            >
              <i className={classNames("align-middle mr-2 bx bx-plus")} />

              {t("btn.add-phone")}
            </Link>
            <div className={"field-error-message"}>
              {error && t(error?.message)}
            </div>
          </Col>
        </Row>
      )}
    </Col>
  )
}

PhonesCountriesFieldArrayHF.propTypes = {
  name: PropTypes.string,
  countryOptions: PropTypes.array,
  limit: PropTypes.number,
  control: PropTypes.object,
  clearErrors: PropTypes.any,
  meta: PropTypes.any,
}
