import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Alert, Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { Button, ComboboxFormField, ControllerHF } from "components"
import { AccommodationForm } from "./AccommodationForm"
import { TravelForm } from "./TravelForm"
import { DriverForm } from "./DriverForm"
import { BasicForm } from "./BasicForm"
import { MODEL_LOGISTICS_TYPE } from "consts"
import { modelHelper } from "helpers"
import { logisticsFormHelper } from "./logisticFormHelpers"

const { ACCOMMODATION, TRAVEL, DRIVER } = MODEL_LOGISTICS_TYPE

const typeOptions = modelHelper.logistics.getLogisticsTypeOptions()
const travelOptions = modelHelper.logistics.getLogisticsTravelOptions()

const defaultLogisticsType = ACCOMMODATION

export const LogisticsForm = props => {
  const { t } = useTranslation()

  const [logisticsType, setLogisticsType] = useState(defaultLogisticsType)

  const { control, handleSubmit, setError, reset, watch } = useForm({
    defaultValues: {
      type: defaultLogisticsType,
      ...logisticsFormHelper.defaultValues.getLogisticsDefaultValues(
        defaultLogisticsType
      ),
      ...props.initialValues,
    },
    resolver:
      logisticsFormHelper.validation.logisticsFormValidation(logisticsType),
    mode: "onChange",
  })

  const watchLogisticsType = watch("type", defaultLogisticsType)

  const onSubmit = values => {
    const params = logisticsFormHelper.submit.getLogisticsParams(values)
    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }

  const resetForm = () => {
    reset({
      ...logisticsFormHelper.defaultValues.getLogisticsDefaultValues(
        watchLogisticsType
      ),
      type: watchLogisticsType,
      ...props.initialValues,
    })
  }

  useEffect(() => {
    watchLogisticsType && setLogisticsType(watchLogisticsType)
  }, [watchLogisticsType])

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={8}>
            <ControllerHF
              name={"type"}
              control={control}
              component={ComboboxFormField}
              id={"type"}
              label={t("choose_type")}
              placeholder={t("choose_type")}
              options={typeOptions}
              disabled={!!props.initialValues?.id}
              selectProps={{
                isSearchable: false,
              }}
              normalize={option => (option ? option.value : null)}
              isDetermineValue
            />
          </Col>
        </Row>

        {watchLogisticsType === ACCOMMODATION && (
          <AccommodationForm
            countryOptions={props.countryOptions}
            control={control}
            reset={resetForm}
            watch={watch}
          />
        )}

        {watchLogisticsType === TRAVEL && (
          <TravelForm
            travelOptions={travelOptions}
            control={control}
            reset={resetForm}
            watch={watch}
          />
        )}

        {watchLogisticsType === DRIVER && (
          <DriverForm control={control} reset={resetForm} watch={watch} />
        )}

        {![ACCOMMODATION, TRAVEL, DRIVER].includes(watchLogisticsType) && (
          <BasicForm control={control} reset={resetForm} watch={watch} />
        )}

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </>
  )
}

LogisticsForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  countryOptions: PropTypes.array,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
