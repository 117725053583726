import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  page: 1,
  meta: null,
}

const billingInvoiceListSlice = createSlice({
  name: "billingInvoiceList",
  initialState,
  reducers: {
    getList(state) {
      state.listLoading = true
      state.listError = null
    },
    getListSuccess(state, action) {
      state.listLoading = false
      state.list = action.payload.data
      state.meta = action.payload.meta
    },
    getListError(state, action) {
      state.listLoading = false
      state.listError = action.payload
    },
    setPage(state, action) {
      state.page = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getList
 * @property {function} getListSuccess
 * @property {function} getListError
 * @property {function} setPage
 * @property {function} cleanState
 */
export const billingInvoiceList = billingInvoiceListSlice.actions

export default billingInvoiceListSlice.reducer
