import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import { useFieldArray } from "react-hook-form"
import { IconButton, TextButton } from "../../Buttons"
import { InputField } from "../../FormElements"
import { ControllerHF } from "../ControllerHF"
import { ContactTextFieldArray } from "./ContactTextFieldArray"

export const ContactsFieldArrayHF = props => {
  const { t } = useTranslation()
  const error = props?.meta?.error
  const fieldName = props.name

  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: fieldName,
    keyName: "fieldId",
  })

  const addRow = e => {
    e.preventDefault()
    if (props.clearErrors) {
      props.clearErrors(props.name)
    }
    append({ title: "", texts: [] })
  }

  const removeRow = index => {
    remove(index)
  }
  const renderLabel = index => {
    return (
      <div className={"d-flex"}>
        <div className={"mr-2"}>{t("field.label.contact-title")}</div>
        <IconButton name={"remove"} bg onClick={() => removeRow(index)} />
      </div>
    )
  }

  return (
    <Row>
      {fields.map((item, index) => (
        <Col sm={4} md={4} key={item.fieldId} className={"mb-4"}>
          <Row>
            <Col xs={12} md={12}>
              <ControllerHF
                name={`${fieldName}.${index}.title`}
                control={props.control}
                component={InputField}
                id={`${fieldName}.${index}.title`}
                label={renderLabel(index)}
                placeholder={t("field.placeholder.contact-title")}
              />
            </Col>
          </Row>
          <ContactTextFieldArray
            control={props.control}
            limit={3}
            name={`${fieldName}.${index}.texts`}
          />
        </Col>
      ))}

      {fields.length < props.limit && (
        <Col sm={4} md={4} className={"d-flex align-items-center"}>
          <TextButton title={"add-new-contact"} onClick={addRow} />
          <div className={"field-error-message"}>
            {error && t(error?.message)}
          </div>
        </Col>
      )}
    </Row>
  )
}

ContactsFieldArrayHF.propTypes = {
  name: PropTypes.string,
  countryOptions: PropTypes.array,
  limit: PropTypes.number,
  control: PropTypes.object,
  clearErrors: PropTypes.any,
  meta: PropTypes.any,
}
