import {
    MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTO,
    MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTO_SUCCESS,
    MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTO_ERROR,
    MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTO_CLEAN_STATE,
} from './actionTypes';

const initialState = {
    pending: [],
};

const removeItem = (state = initialState, action) => {
    switch (action.type) {
        case MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTO:
            state = {
                ...state,
                pending: [...state.pending, action.payload.photo.id],
            };
            break;

        case MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTO_SUCCESS:
            state = {
                ...state,
                pending: state.pending.filter((item) => item !== action.payload.photo.id),
            };
            break;

        case MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTO_ERROR:
            state = {
                ...state,
                pending: state.pending.filter((item) => item !== action.payload.photo.id),
            };
            break;

        case MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTO_CLEAN_STATE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default removeItem;