import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { Alert, Col, Row } from "reactstrap"
import { Button, ControllerHF, InputField } from "components"

export const EditInvoiceDetailsForm = props => {
  const { t } = useTranslation()

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      invoice_details: "",
      ...props.initialValues,
    },

    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
      edit_payment: 1,
    }
    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <ControllerHF
              control={control}
              component={InputField}
              name="bank_name"
              label={t("bank-name")}
              placeholder={t("bank-name")}
              id={"bank_name"}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <ControllerHF
              control={control}
              component={InputField}
              name="bank_account_number"
              label={t("bank-account-number")}
              placeholder={t("bank-account-number")}
              id={"bank_account_number"}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <ControllerHF
              control={control}
              component={InputField}
              name="swift_code"
              label={t("swift-code")}
              placeholder={t("swift-code")}
              id={"swift_code"}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <ControllerHF
              control={control}
              component={InputField}
              name="vat"
              label={t("vat")}
              placeholder={t("vat")}
              id={"vat"}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <ControllerHF
              control={control}
              component={InputField}
              name="other_information"
              label={t("other-information")}
              placeholder={t("other-information")}
              id={"other_information"}
              type="textarea"
            />
          </Col>
        </Row>
        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button title={"cancel"} color="light" outline onClick={onCancel} />
          </div>
        </div>
      </form>
    </>
  )
}

EditInvoiceDetailsForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
