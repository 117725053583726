import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { OverlayBlockLoading } from "components"
import { MembersField } from "../MembersField"
import {
  chatGroupsParticipantAdd,
  chatGroupsParticipantRemove,
} from "store/actions"

export const AsyncMembers = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const processing = useSelector(
    state =>
      state.chat.groups.participant.add.loading ||
      state.chat.groups.participant.remove.loading
  )

  useEffect(() => {
    return () => {
      dispatch(chatGroupsParticipantAdd.cleanState())
      dispatch(chatGroupsParticipantRemove.cleanState())
    }
  }, [])

  const onSelect = (data, payload) => {
    const { onSuccess } = payload

    dispatch(
      chatGroupsParticipantAdd.add({
        chat: props.chatId,
        user: data?.id,
        onSuccess: () => {
          onSuccess(data)
        },
      })
    )
  }

  const onRemove = (data, payload) => {
    const { onSuccess } = payload

    dispatch(
      chatGroupsParticipantRemove.remove({
        chat: props.chatId,
        user: data?.id,
        onSuccess: () => {
          onSuccess(data)
        },
      })
    )
  }

  return (
    <OverlayBlockLoading isLoading={processing}>
      <MembersField
        control={props.control}
        async
        onSelect={onSelect}
        onRemove={onRemove}
      />
    </OverlayBlockLoading>
  )
}

AsyncMembers.propTypes = {
  control: PropTypes.object,
  chatId: PropTypes.number,
}
