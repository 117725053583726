import { createSlice } from "@reduxjs/toolkit"
import { createSelector } from "@reduxjs/toolkit"

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  page: 1,
  meta: null,
  isFetched: false,
}

const modelsGroupsListSlice = createSlice({
  name: "modelsGroupsList",
  initialState,
  reducers: {
    getList(state) {
      state.listLoading = true
      state.listError = null
      state.isFetched = false
    },
    getListCompleted(state, action) {
      state.listLoading = false
      state.list = action.payload.data
      state.meta = action.payload.meta
      state.isFetched = true
    },
    getListRejected(state, action) {
      state.listLoading = false
      state.listError = action.payload
      state.isFetched = true
    },
    setPage(state, action) {
      state.page = action.payload
    },
    addItem(state, action) {
      state.list.push(action.payload)
    },
    removeItem(state, action) {
      state.list = state.list.filter(item => item.id !== action.payload.id)
    },
    removeMember(state, action) {
      state.list = state.list.map(group => {
        if (group.id === action.payload.groupId) {
          return {
            ...group,
            fashion_models: group.fashion_models.filter(
              model => model.id !== action.payload.modelId
            ),
          }
        } else return group
      })
    },
    updateItem(state, action) {
      state.list = state.list.map(group => {
        if (group.id === action.payload.id) {
          return action.payload
        } else return group
      })
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getList
 * @property {function} getListCompleted
 * @property {function} getListRejected
 *  @property {function} setPage
 *  @property {function} addItem
 *  @property {function} removeItem
 *  @property {function} removeMember
 *  @property {function} updateItem
 *  @property {function} cleanState
 */

export const selectModelGroupById = createSelector(
  [state => state.models.groups.list.list, (_, groupId) => groupId],
  (groups, groupId) => groups.find(group => group.id === groupId)
)

export const modelsGroupsList = modelsGroupsListSlice.actions

export default modelsGroupsListSlice.reducer
