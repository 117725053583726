import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"
import { ComboboxFormField, ControllerHF, FilterTitle } from "components"
import { modelHelper } from "helpers"

const options = modelHelper.general.getEthnicCategories().map(item => ({
  text: item.label,
  value: item.value,
}))

export const EthnicSection = props => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <>
      <FilterTitle title={t("ethnic_categories")} />

      <ControllerHF
        name={"ethnics"}
        control={control}
        component={ComboboxFormField}
        id={"ethnics"}
        label={null}
        placeholder={t("ethnic_categories")}
        options={options}
        getOptionLabel={option => `${option.text}`}
        getOptionValue={option => option.value}
        normalize={value => value.map(item => item.value)}
        isMulti
        isDetermineValue
        selectProps={{
          isClearable: true,
        }}
      />
    </>
  )
}

EthnicSection.propTypes = {}
