import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { PackageDataGrid } from "../../../../../PackageDataGrid"
import { BookOverviewCard } from "../../../../../../Books"
import { IconButton } from "../../../../../../Buttons"

export const BooksGrid = props => {
  const { t } = useTranslation()

  return (
    <PackageDataGrid
      className={"package-data-picker-grid"}
      items={props.books}
      empty={t("no_books")}
      renderItem={item => (
        <BookOverviewCard
          title={item.title}
          description={item.description}
          cover={item?.cover?.photo?.attachment?.src}
          crops={item?.cover?.photo?.crops}
          size={"sm"}
          actions={
            <IconButton
              name={"remove"}
              onClick={() => props.onRemove(item)}
              bg
              disabled={false}
            />
          }
        />
      )}
    />
  )
}

BooksGrid.propTypes = {
  books: PropTypes.array,
  onRemove: PropTypes.func,
}
