import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"

import { ControllerHF, InputField } from "../../../../../../../components"

export const ServiceDetailsSection = props => {
  const { t } = useTranslation()

  return (
    <>
      <Row>
        <Col md={8}>
          <ControllerHF
            name={"service_details"}
            control={props.control}
            component={InputField}
            id={"service_details"}
            label={t("service_details")}
            placeholder={t("service_details")}
            type={"textarea"}
          />
        </Col>
      </Row>
    </>
  )
}

ServiceDetailsSection.propTypes = {
  control: PropTypes.object,
}
