import React from "react"
import { connect } from "react-redux"

import "toastr/build/toastr.min.css"

// initialize api service
// eslint-disable-next-line
import { API } from "./api"

// Import scss
import "./assets/scss/theme.scss"

import { RootNavigator } from "./navigation"
import { useAppWindowFocus } from "hooks"
import { useFacebookInit } from "packages/facebook"
import { FBconfig } from "configs"

const App = props => {
  useAppWindowFocus()
  useFacebookInit({ ...FBconfig })

  return (
    <React.Fragment>
      <RootNavigator />
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {}
}

export default connect(mapStateToProps, null)(App)
