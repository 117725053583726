import { combineReducers } from "redux";

import remove from './remove/reducer';
import upload from './upload/reducer';

const attachmentsReducer = combineReducers({
    remove,
    upload,
});

export default attachmentsReducer;