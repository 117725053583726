import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useField } from "formik"
import { Label, Input, FormFeedback } from "reactstrap"
import classNames from "classnames"
import Select from "react-select"

export const Combobox = ({
  id,
  label,
  placeholder,
  type,
  reference,
  children,
  options = [],
  normalize,
  disabled,
  selectProps,
  getOptionValue,
  getOptionLabel,
  wrapClass,
  isDetermineValue,
  isMulti,
  ...props
}) => {
  const { field, meta, form } = props
  const { setValue } = form

  const { touched, error } = meta

  const { t } = useTranslation()
  const showError = touched && Boolean(error)

  const onChange = option => {
    option = normalize ? normalize(option) : option
    setValue(option)
    props.onChange && props.onChange(option)
  }

  const determineValue = () => {
    if (isMulti) {
    } else {
      return options.find(item => item.value === field.value)
    }
  }

  return (
    <div
      className={classNames("mb-3 select2-container", {
        [wrapClass]: wrapClass,
      })}
    >
      <Label htmlFor={id}>{label}</Label>
      <Select
        id={id}
        options={options}
        placeholder={placeholder}
        className={classNames({
          "is-invalid": showError,
        })}
        classNamePrefix="select2-selection"
        noOptionsMessage={() => t("select.no_options")}
        getOptionLabel={option =>
          getOptionLabel ? getOptionLabel(option) : `${option.label}`
        }
        getOptionValue={option =>
          getOptionValue ? getOptionValue(option) : `${option.value}`
        }
        isDisabled={disabled}
        {...selectProps}
        {...field}
        value={isDetermineValue ? determineValue() : field.value}
        onChange={option => onChange(option)}
      />

      <FormFeedback invalid={showError ? error : null}>
        {t(error, props.translateParams ? { ...props.translateParams } : {})}
      </FormFeedback>
    </div>
  )
}

Combobox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  reference: PropTypes.any,
  children: PropTypes.any,
  normalize: PropTypes.func,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  field: PropTypes.object,
  meta: PropTypes.object,
  form: PropTypes.object,
  onChange: PropTypes.func,
  translateParams: PropTypes.object,
  selectProps: PropTypes.object,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  wrapClass: PropTypes.string,
  isDetermineValue: PropTypes.bool,
  isMulti: PropTypes.bool,
}

export const ComboboxFiled = props => {
  const [field, meta, form] = useField(props)

  return <Combobox field={field} meta={meta} form={form} {...props} />
}
