import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { clientStatementList, clientStatementSettingsData } from "store/actions"
import { useDrawer, useUserAccess } from "hooks"
import { StatementCard } from "./StatementCard"
import { EditStatement } from "./EditStatement"
import { StatementSettings } from "./StatementSettings"
import { DEPARTMENT_ACCOUNTING } from "consts"

const defaultPeriod = [
  moment().startOf("month").toDate(),
  moment().endOf("month").toDate(),
]

const StatementContainerComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { isDirector, isDepartmentAllow } = useUserAccess()

  const [period, setPeriod] = useState(defaultPeriod)
  const [date_start, date_end] = period

  useEffect(() => {
    return () => {
      dispatch(clientStatementList.cleanState())
      dispatch(clientStatementSettingsData.cleanState())
    }
  }, [])

  // Data
  useEffect(() => {
    if (date_start && date_end) {
      fetchData()
    }
  }, [period])

  const fetchData = (params = {}) => {
    params = {
      ...params,
      date_start: moment(date_start).format("YYYY-MM-DD"),
      date_end: moment(date_end).format("YYYY-MM-DD"),
    }

    dispatch(clientStatementList.getList({ id: props.client.id, params }))
  }

  const onPeriodChange = value => {
    setPeriod(value)
  }

  const onStatementsUpdate = () => {
    if (date_start && date_end) {
      fetchData()
    } else {
      dispatch(clientStatementList.cleanState())
    }
  }
  // === //

  // Edit statement
  const {
    hide: hideEditDrawer,
    show: showEditDrawer,
    visible: editDrawerVisible,
    data: editStatementData,
  } = useDrawer()

  const onEditSuccess = () => {
    hideEditDrawer()
  }
  // === //

  // Settings
  const isSettingsAllow =
    isDirector() || isDepartmentAllow([DEPARTMENT_ACCOUNTING])

  useEffect(() => {
    if (isSettingsAllow) {
      dispatch(clientStatementSettingsData.getData({ id: props.client.id }))
    }
  }, [isSettingsAllow])

  const {
    hide: hideSettingsDrawer,
    show: showSettingsDrawer,
    visible: settingsDrawerVisible,
  } = useDrawer()

  const onSettingsSuccess = () => {
    hideSettingsDrawer()
  }
  // === //

  return (
    <>
      <StatementCard
        period={period}
        onPeriodChange={onPeriodChange}
        list={props.list}
        listLoading={props.listLoading}
        listError={props.listError}
        meta={props.meta}
        client={props.client}
        onStatementEdit={showEditDrawer}
        settings={isSettingsAllow}
        onSettings={showSettingsDrawer}
        settingsData={props.settingsData}
      />

      <EditStatement
        id={editStatementData?.id}
        visible={editDrawerVisible}
        onSuccess={onEditSuccess}
        onClose={hideEditDrawer}
        settings={props.settingsData}
        onStatementsUpdate={onStatementsUpdate}
      />

      <StatementSettings
        id={props.client?.id}
        visible={settingsDrawerVisible}
        onSuccess={onSettingsSuccess}
        onClose={hideSettingsDrawer}
        onStatementsUpdate={onStatementsUpdate}
      />
    </>
  )
}

StatementContainerComponent.propTypes = {
  client: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  settingsData: PropTypes.object,
}

const mapStateToProps = state => {
  const { data: client } = state.client.profile.data
  const { list, listLoading, listError, meta } = state.client.statement.list
  const { data: settingsData } = state.client.statement.settings.data

  return {
    client,
    list,
    listLoading,
    listError,
    meta,
    settingsData,
  }
}

export const StatementContainer = connect(mapStateToProps)(
  StatementContainerComponent
)
