import React, { useReducer } from "react"
import { API } from "api"
import { getModelPhotoRequestBody } from "./helper"

const initialState = {
  loading: false,
  error: null,
}

function reducer(state, action) {
  switch (action.type) {
    case "getStarted":
      return {
        ...state,
        loading: true,
      }
    case "getCompleted":
      return {
        ...state,
        loading: false,
      }
    case "getRejected":
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case "cleanState":
      return initialState
    default:
      return state
  }
}

export const useEditModelPhotoCrop = () => {
  const [{ loading, error }, dispatch] = useReducer(reducer, initialState)

  const editCrop = async (params, type, attachmentId) => {
    dispatch({ type: "getStarted" })
    try {
      const requestBody = getModelPhotoRequestBody(params, type)
      const response = await API.model.updateModelPhoto(
        attachmentId,
        requestBody
      )
      const { data } = response
      dispatch({ type: "getCompleted" })
      return data?.data
    } catch (e) {
      dispatch({ type: "getRejected", payload: e.message })
    }
  }

  const cleanState = () => {
    dispatch({ type: "cleanState" })
  }

  return { loading, error, cleanState, editCrop }
}
