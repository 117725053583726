import * as permissions from "./permissions"
import * as common from "./common"
import * as period from "./period"
import * as stats from "./stats"
import * as options from "./options"
import * as names from "./names"

export const analyticsHelper = {
  permissions,
  common,
  period,
  stats,
  options,
  names,
}
