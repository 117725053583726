import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { PaymentDetailsInfoCard } from "./PaymentDetailsInfoCard"
import { EditPaymentDetails } from "./EditPaymentDetails"

const PaymentDetailsContainerComponent = props => {
  const [editDrawerVisible, setEditDrawerVisible] = useState(false)

  const handleEdit = () => {
    setEditDrawerVisible(true)
  }

  const closeEditDrawer = () => {
    setEditDrawerVisible(false)
  }

  return (
    <>
      <PaymentDetailsInfoCard
        editMode={true}
        onEdit={handleEdit}
        data={props.client}
      />

      <EditPaymentDetails
        visible={editDrawerVisible}
        onClose={closeEditDrawer}
      />
    </>
  )
}

PaymentDetailsContainerComponent.propTypes = {
  client: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.client.profile.data
  return {
    client: data,
  }
}

const mapDispatchToProps = {}

export const PaymentDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentDetailsContainerComponent)
