import React from "react"
import PropTypes from "prop-types"
import { useForm } from "react-hook-form"
import { Alert } from "reactstrap"
import { Button, ControllerHF, ImageCropControl } from "components"

export const CropPhotoForm = props => {
  const { control, reset, setError, handleSubmit } = useForm({
    defaultValues: {
      instagram_photo: {
        crop: {
          unit: "px",
        },
      },
      ...props.initialValues,
    },
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
    }
    props.onSubmit(params, { reset, setError })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}
      <div>
        <ControllerHF
          id={"instagram_photo"}
          name={"instagram_photo"}
          control={control}
          component={ImageCropControl}
          imageDataURL={props.imageDataURL}
          aspect={1}
          hasCrop={props.hasCrop}
        />
        <div className={"d-flex justify-content-around "}>
          <Button
            onClick={props.onCancel}
            title={"cancel"}
            outline
            disabled={props.loading}
          />
          <Button
            disabled={props.loading}
            loading={props.loading}
            color="secondary"
            title={"accept"}
            submit
          />
        </div>
      </div>
    </form>
  )
}

CropPhotoForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  imageDataURL: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  hasCrop: PropTypes.bool,
}
