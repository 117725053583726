import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import i18n from "../../../../i18n"
import { API } from "../../../../api"
import { agencyAddLogo } from "./slice"
import { agencyProfileData } from "../../profile/actions"
import toastr from "toastr"

function* addLogo({ payload: { id, params, onSuccess, onError } }) {
  try {
    const response = yield call(API.agency.addAgencyAvatar, id, params)

    const { data } = response
    if (onSuccess) {
      onSuccess()
    }
    yield put(agencyAddLogo.addCompleted())
    toastr.success(i18n.t("agency-logo.add.success"))
    yield put(agencyProfileData.updateData({ avatar: { src: data.data?.src } }))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")
    if (onError) {
      onError(errors)
    }
    yield put(
      agencyAddLogo.addRejected({
        errors,
        message,
      })
    )
  }
}

export function* watchAddLogo() {
  yield takeEvery(agencyAddLogo.add, addLogo)
}

function* addSaga() {
  yield all([fork(watchAddLogo)])
}

export default addSaga
