import React from "react"
import PropTypes from "prop-types"
import { Progress as StrapProgress } from "reactstrap"
import classNames from "classnames"

export const FileUploadingCard = React.memo(
  ({ fileName, value, error, className, actions }) => {
    return (
      <div
        className={classNames("file-uploading-card", {
          [className]: className,
        })}
      >
        <div className={"file-uploading-card_info-block"}>
          {fileName && (
            <div className="file-uploading-card_name ">{fileName}</div>
          )}
          <StrapProgress
            striped
            animated
            barClassName={"progress_bar"}
            className={"progress"}
            value={value}
          />
          {error && <div className={"file-uploading-card_error"}>{error}</div>}
        </div>
        {actions && (
          <div className={"file-uploading-card_actions-block"}>{actions}</div>
        )}
      </div>
    )
  }
)

FileUploadingCard.propTypes = {
  fileName: PropTypes.any,
  value: PropTypes.number,
  error: PropTypes.any,
  className: PropTypes.string,
  actions: PropTypes.any,
  isUploaded: PropTypes.bool,
}
