import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Col, Alert, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { Button, ComboboxFormField, ControllerHF, InputField } from "components"
import { FileUtils, ValidationsUtils } from "utils"
import { FILE_MAX_SIZE } from "consts/common/maxFileSize"
import { LogoPickerField } from "components"
import { useFetchClients } from "hooks"

export const AddAgencyForm = props => {
  const { t } = useTranslation()
  const { data, fetchData } = useFetchClients()
  const { control, handleSubmit, setError, reset, watch } = useForm({
    defaultValues: {
      name: "",
      website: "",
      country_id: "",
      note: "",
      client_id: "",
      agency_commission: 10,
      vat: "",
      avatar: "",
      ...props.initialValues,
    },
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required("field.error.required"),
        avatar: Yup.mixed()
          .nullable()
          .test("maxSize", "field.error.file-size.max", value =>
            ValidationsUtils.fileMaxSize(value, FILE_MAX_SIZE)
          ),
      })
    ),
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
      country_id: values.country_id ? values.country_id.id : "",
      client_id: values.client_id ? values.client_id.id : "",
    }

    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }
  useEffect(() => {
    fetchData()
  }, [])

  const isPhoto = !!watch("avatar")

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={8}>
            <Row>
              <Col lg={6}>
                <ControllerHF
                  name={"name"}
                  control={control}
                  component={InputField}
                  id={"name"}
                  label={t("name")}
                  placeholder={t("name")}
                />
              </Col>
              <Col lg={6}>
                <ControllerHF
                  name={"website"}
                  control={control}
                  component={InputField}
                  id={"website"}
                  label={t("website")}
                  placeholder={t("website")}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <ControllerHF
                  name={"client_id"}
                  control={control}
                  component={ComboboxFormField}
                  id={"client_id"}
                  label={t("billing-client")}
                  placeholder={t("billing-client")}
                  options={data}
                  getOptionLabel={option => `${option.name}`}
                  getOptionValue={option => option.id}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ControllerHF
                  name={"country_id"}
                  control={control}
                  component={ComboboxFormField}
                  id={"country_id"}
                  label={t("country")}
                  placeholder={t("country")}
                  options={props.countryOptions}
                  getOptionLabel={option => `${option.name}`}
                  getOptionValue={option => option.id}
                  selectProps={{
                    isClearable: true,
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ControllerHF
                  name={"vat"}
                  control={control}
                  component={InputField}
                  id={"vat"}
                  label={t("VAT")}
                  placeholder={t("VAT")}
                  type={"textarea"}
                />
              </Col>
              <Col md={6}>
                <ControllerHF
                  name={"note"}
                  control={control}
                  component={InputField}
                  id={"note"}
                  label={t("note")}
                  placeholder={t("note")}
                  type={"textarea"}
                />
              </Col>
            </Row>
          </Col>
          <Col md={4} className={"d-flex justify-content-center"}>
            <ControllerHF
              name={"avatar"}
              control={control}
              component={LogoPickerField}
              selectBtnIcon={isPhoto ? null : "bx bx-plus"}
              selectBtnText={
                isPhoto
                  ? t("agency.general.change-logo")
                  : t("agency.general.add-logo")
              }
              id={"avatar"}
              type={"file"}
              translateParams={{
                text: `${FileUtils.formatBytes(FILE_MAX_SIZE)}`,
              }}
            />
          </Col>
          <Col>
            <div className="mt-3">
              <div className="button-items">
                <Button
                  submit
                  title={"btn.save"}
                  disabled={props.loading}
                  loading={props.loading}
                />

                <Button
                  title={"cancel"}
                  color="light"
                  outline
                  onClick={onCancel}
                />
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </>
  )
}

AddAgencyForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  countryOptions: PropTypes.any,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
