import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { connect, useDispatch } from "react-redux"
import { useDeepCompareEffect, useFirstMountState } from "react-use"

import { SearchInput } from "../../../../../components"
import { Agencies } from "./Agencies/Agencies"
import { agenciesSidebar } from "store/actions"
import { selectAgenciesSidebarSyncCount } from "store"

const AgenciesSidebarComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isFirstMount = useFirstMountState()

  useEffect(() => {
    if (!props.isFetched) {
      fetchData({ page: 1 })
    }
  }, [])

  useDeepCompareEffect(() => {
    if (!isFirstMount) {
      fetchData({ page: 1 })
    }
  }, [props.filters.values, props.syncCount])

  const fetchData = params => {
    params = {
      ...params,
    }

    const { keywords } = props.filters.values

    params.filters = {
      ...props.filters.values,
      search_fields: keywords ? ["name"] : null,
    }
    dispatch(agenciesSidebar.getAgencies({ params }))
  }

  const onSearchInputChange = value => {
    dispatch(agenciesSidebar.changeSearchInput(value))
  }

  const handleSearch = value => {
    dispatch(agenciesSidebar.changeFilter({ keywords: value }))
  }

  const hasMore = useMemo(() => {
    const { meta } = props
    return meta && meta.current_page !== meta.last_page
  }, [props.meta])

  const isMoreLoading = useMemo(() => {
    return props.loading && !!props.meta
  }, [props.loading, props.meta])
  //
  const fetchMore = () => {
    const { meta } = props
    if (hasMore && !props.loading) {
      fetchData({
        page: meta.current_page + 1,
      })
    }
  }

  const handleSelect = () => {
    if (props.drawerVisible) {
      props.toggleDrawerVisible(false)
    }
  }

  return (
    <div className="panel-sidebar__sidebar-content panel-common-sidebar-content">
      <SearchInput
        value={props.searchInput}
        onInputChange={onSearchInputChange}
        onSearch={handleSearch}
        placeholder={t("start_typing_agencies")}
        autoComplete={"off"}
      />

      <hr className={"divider"} />

      <Agencies
        items={props.list}
        loading={props.loading}
        error={props.error}
        onSelect={handleSelect}
        onLoadMore={fetchMore}
        isMoreLoading={isMoreLoading}
        showMore={hasMore}
      />
    </div>
  )
}

AgenciesSidebarComponent.propTypes = {
  drawerVisible: PropTypes.bool,
  toggleDrawerVisible: PropTypes.func,

  list: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  searchInput: PropTypes.string,
  meta: PropTypes.object,
  page: PropTypes.any,
  filters: PropTypes.object,
  isFetched: PropTypes.bool,
  syncCount: PropTypes.number,
  profile: PropTypes.object,
}

const mapStateToProps = state => {
  const { loading, error, searchInput, meta, page, filters, list, isFetched } =
    state.agency.panel.agenciesSidebar
  const { data: profile } = state.agency.profile.data

  const syncCount = selectAgenciesSidebarSyncCount(state)
  return {
    list,
    loading,
    error,
    searchInput,
    meta,
    page,
    filters,
    isFetched,
    syncCount,
    profile,
  }
}

export const AgenciesSidebar = connect(mapStateToProps)(
  AgenciesSidebarComponent
)
