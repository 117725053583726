import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Col, Row } from "reactstrap"
import * as Yup from "yup"
import {
  Button,
  ColorPickerField,
  ConfirmPopup,
  ControllerHF,
} from "components"
import { eventsCalendarHelper } from "helpers"
import { useConfirmModal } from "hooks"
import { CALENDAR_EVENT_CATEGORY_OTHER } from "consts"
import { getEmptyCustomParams } from "./formHelper"

export const ColorSettingsForm = props => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      ...props.initialValues,
    },
    resolver: yupResolver(Yup.object().shape({})),
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
    }

    props.onSubmit(params, { reset, setError })
  }

  const handleReset = () => {
    reset()
  }

  const handleSetDefaultColors = () => {
    let params = {
      ...getEmptyCustomParams(),
      isDefaultValues: true,
    }
    props.onSubmit(params, { reset, setError })
  }

  const { showModal, closeModal, handleConfirm, modalRef } = useConfirmModal(
    () => {
      handleSetDefaultColors()
    },
    () => {}
  )

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          {eventsCalendarHelper.eventConfig.getEvents().map(eventData => {
            if (
              !eventsCalendarHelper.eventConfig.isOtherCategoryEvent(
                eventData.type
              )
            ) {
              return (
                <Col
                  sm={12}
                  md={4}
                  key={`color-settings-field-${eventData.type}`}
                >
                  <ControllerHF
                    name={eventData.type}
                    control={control}
                    component={ColorPickerField}
                    id={eventData.type}
                    label={eventData.title}
                    wrapClass={"mb-3 w-50"}
                  />
                </Col>
              )
            }
          })}

          {/*Other events render separate because it contains 4 events*/}
          <Col sm={12} md={4}>
            <ControllerHF
              name={CALENDAR_EVENT_CATEGORY_OTHER}
              control={control}
              component={ColorPickerField}
              id={CALENDAR_EVENT_CATEGORY_OTHER}
              label={t("other_events")}
              wrapClass={"w-50 mb-3"}
            />
          </Col>
        </Row>
        <div className="mt-3 d-flex justify-content-between">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading || !isDirty}
              loading={props.loading}
            />
            {isDirty && (
              <Button
                outline
                title={"btn.reset"}
                disabled={props.loading}
                onClick={handleReset}
              />
            )}
          </div>
          <div>
            {!isDirty && props.isExistCustomColors && (
              <>
                <Button
                  color={"secondary"}
                  outline
                  title={"btn.set-default-colors"}
                  onClick={showModal}
                />
                <ConfirmPopup
                  ref={modalRef}
                  onConfirm={handleConfirm}
                  onClosed={closeModal}
                  questionText={"sure-set-default-colors"}
                />
              </>
            )}
          </div>
        </div>
      </form>
    </>
  )
}

ColorSettingsForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  isExistCustomColors: PropTypes.bool,
}
