import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
import classNames from "classnames"
import { useFieldArray, useWatch } from "react-hook-form"

import { IconButton } from "../../Buttons"
import { DateInputField } from "../../FormElements"
import { ControllerHF } from "../ControllerHF"

export const TimePeriodFieldArrayHF = props => {
  const { t } = useTranslation()
  const error = props?.meta?.error
  const fieldName = props.name

  const value = useWatch({
    name: fieldName,
    control: props.control,
  })

  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: fieldName,
    keyName: "fieldId",
  })

  const addRow = e => {
    e.preventDefault()
    if (props.clearErrors) {
      props.clearErrors(props.name)
    }
    append({ start: null, end: null })
  }

  const removeRow = index => {
    remove(index)
  }

  return (
    <Col>
      <Row>
        {fields.map((item, index) => (
          <Col key={item.fieldId} md={6}>
            <h5>
              {t("time")} {index + 1}
            </h5>
            <Row>
              <Col xs={8} md={5}>
                <ControllerHF
                  name={`${fieldName}.${index}.start`}
                  control={props.control}
                  component={DateInputField}
                  id={`${fieldName}.${index}.start`}
                  label={t("start")}
                  placeholder={t("start")}
                  mask={[/\d/, /\d/, ":", /\d/, /\d/]}
                  showTimeSelect
                  showTimeSelectOnly
                />
              </Col>

              <Col xs={8} md={5}>
                <ControllerHF
                  name={`${fieldName}.${index}.end`}
                  control={props.control}
                  component={DateInputField}
                  id={`${fieldName}.${index}.end`}
                  label={t("end")}
                  placeholder={t("end")}
                  mask={[/\d/, /\d/, ":", /\d/, /\d/]}
                  showTimeSelect
                  showTimeSelectOnly
                  translateParams={{ time: t("start") }}
                />
              </Col>

              <Col xs={4} md={2}>
                <div className={"mb-3 form-action-button-block"}>
                  <IconButton
                    name={"remove"}
                    bg
                    onClick={() => removeRow(index)}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>

      {fields.length < props.limit && (
        <Row>
          <Col>
            <Link
              to="#"
              className="text-secondary text-decoration-underline"
              onClick={addRow}
            >
              <i className={classNames("align-middle mr-2 bx bx-plus")} />

              {t("btn.add-time-period")}
            </Link>
            <div className={"field-error-message"}>
              {error && t(error?.message)}
            </div>
          </Col>
        </Row>
      )}
    </Col>
  )
}

TimePeriodFieldArrayHF.propTypes = {
  name: PropTypes.string,
  limit: PropTypes.number,
  control: PropTypes.object,
  clearErrors: PropTypes.any,
  meta: PropTypes.any,
}
