import { createSelector } from "@reduxjs/toolkit"

const getSyncCount = state => state.modelPanel.panel.modelsSidebar.syncCount
const getImportSyncCount = state => state.socket.import.data.modelsSyncCount

export const selectModelsSidebarSyncCount = createSelector(
  [getSyncCount, getImportSyncCount],
  (syncCount, importSyncCount) => {
    return syncCount + importSyncCount
  }
)
