import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../../i18n';

import { PACKAGE_LIST_REMOVE_ITEM } from './actionTypes';
import {
    packageListRemoveItemSuccess,
    packageListRemoveItemError,
} from './actions';

import { API } from '../../../../api';

function* removeItem({ payload: { id, onSuccess } }) {
    try {
        const response = yield call(API.packageApi.removePackage, id);

        const { data } = response;

        yield put(packageListRemoveItemSuccess(id));
        toastr.success(i18n.t('package.remove.success'));

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        const message = error.response?.data?.message || i18n.t('error');

        yield put(packageListRemoveItemError(id));

        toastr.error(message);
    }
}

export function* watchRemoveItem() {
    yield takeEvery(PACKAGE_LIST_REMOVE_ITEM, removeItem)
}

function* removeItemSaga() {
    yield all([
        fork(watchRemoveItem),
    ]);
}

export default removeItemSaga;