import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { Col, Row } from "reactstrap"
import { ControllerHF } from "../../../../ReactHookForm"
import { ComboboxFormField } from "../../../../FormElements"
import { modelHelper } from "helpers"

const genderOptions = modelHelper.general.getGenderOptions()

export const GenderSection = props => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <Row>
      <Col md={4}>
        <ControllerHF
          name={"gender"}
          control={control}
          component={ComboboxFormField}
          id={"gender"}
          label={t("department")}
          placeholder={t("department")}
          options={genderOptions}
          selectProps={{
            isClearable: true,
            isSearchable: false,
          }}
          normalize={option => (option ? option.value : null)}
          isDetermineValue
        />
      </Col>
    </Row>
  )
}

GenderSection.propTypes = {}
