import { all } from "redux-saga/effects"

import listSaga from "./list/saga"
import updateSaga from "./update/saga"

function* templatesSaga() {
  yield all([listSaga(), updateSaga()])
}

export default templatesSaga
