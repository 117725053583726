import moment from "moment"

const isSamePeriodLength = (firstDateRange, secondDateRange) => {
  const startDate = moment(firstDateRange[0])
  const endDate = moment(firstDateRange[1])
  const secondStart = moment(secondDateRange[0])
  const secondEnd = moment(secondDateRange[1])

  return endDate.diff(startDate, "days") === secondEnd.diff(secondStart, "days")
}

const localeToUTC = (date, format = null) => {
  const value = moment(date).utc()

  if (format) {
    return value.format(format)
  }

  return value.toDate()
}

const utcToLocale = (date, format = null) => {
  const value = moment.utc(date).local()

  if (format) {
    return value.format(format)
  }

  return value.toDate()
}

const timeToDate = (time, format = "HH:mm") => {
  return moment(time, format).toDate()
}

const dateToTime = (date, format = "HH:mm") => {
  return moment(date).format(format)
}

const utcTimeToLocaleDate = (time, format = "HH:mm") => {
  return moment.utc(time, format).local().toDate()
}

const localeToUtcTime = (date, format = "HH:mm") => {
  return localeToUTC(date, format)
}

export {
  isSamePeriodLength,
  localeToUTC,
  utcToLocale,
  timeToDate,
  dateToTime,
  utcTimeToLocaleDate,
  localeToUtcTime,
}
