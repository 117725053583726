export const BILLING_TAX_ID_STATUS = {
  PENDING: "pending",
  VERIFIED: "verified",
  UNVERIFIED: "unverified",
}

export const BILLING_PAYMENT_METHOD_TYPE = {
  CARD: "card",
  SEPA_DEBIT: "sepa_debit",
}
