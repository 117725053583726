import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import moment from "moment"
import { useDeepCompareEffect } from "react-use"
import _ from "lodash"

import {
  calendarEventList,
  calendarEventSubmit,
  calendarEventEditData,
} from "store/actions"
import { selectFullCalendarEvents } from "store/selectors"
import { GeneralEventsCalendarControl } from "./GeneralEventsCalendarControl"
import {
  GENERAL_CALENDAR_ENTITY,
  EVENTS_CALENDAR_DEFAULT_EVENTS_FETCH_PARAMS,
} from "consts"
import { EventsCalendarEventContentProvider } from "../EventsCalendar"
import { ModelCalendarDayHeaderContent } from "./CalendarDayHeaderContent"
import { EventsCalendarDayHeaderContent } from "../EventsCalendar"
import { eventsCalendarHelper } from "helpers"
import { useDrawer } from "hooks"
import { EventsEmailSend } from "./EventsEmailSend"
import { PrintEvents } from "./PrintEvents"

const getEntityEventType = eventsCalendarHelper.calendar.getEntityEventType
const { getEntityEventsRequestParams, calendarEventOrderByModelPriority } =
  eventsCalendarHelper.generalCalendar

const {
  MODEL: ENTITY_MODEL,
  AGENCY: ENTITY_AGENCY,
  CLIENT: ENTITY_CLIENT,
} = GENERAL_CALENDAR_ENTITY

const GeneralEventsCalendarComponent = props => {
  const dispatch = useDispatch()

  const [datePeriod, setDatePeriod] = useState(null)

  useEffect(() => {
    return () => {
      dispatch(calendarEventList.resetFetchedData())
    }
  }, [])

  useDeepCompareEffect(() => {
    if (datePeriod) {
      fetchEvents()
    }
  }, [datePeriod, props.filters.values, props.eventsFetchParams])

  const fetchEvents = (params = {}) => {
    params = {
      includes: EVENTS_CALENDAR_DEFAULT_EVENTS_FETCH_PARAMS.includes,
      ...datePeriod,
      ...params,
    }

    if (props.entity && props.entityData) {
      params = {
        ...params,
        ...getEntityEventsRequestParams(props.entity, props.entityData),
      }
    }

    const { type } = props.filters.values
    if (type) {
      params = {
        ...params,
        type: type,
      }
    }

    if (props.eventsFetchParams) {
      params = {
        ...params,
        ...props.eventsFetchParams,
      }
    }

    dispatch(calendarEventList.getList({ params }))
  }

  const onCalendarDatesSet = rangeInfo => {
    const { start, end } = rangeInfo
    const dates = {
      date_start: moment(start).format("YYYY-MM-DD"),
      date_end: moment(end).add(-1, "days").format("YYYY-MM-DD"),
    }

    setDatePeriod(dates)
  }

  const onEventTypeFilterChange = type => {
    dispatch(calendarEventList.changeFilters({ type }))
  }

  const onSubmitReset = () => {
    dispatch(calendarEventSubmit.cleanState())
  }

  const onEventUpdate = data => {
    // One way update events list local in store
    // but in entity calendar need watch when add, update or remove calendar form store
    // now fetch events after close event edit form

    // if event changed type => fetch edit event data in form
    // not update in store for user friendly performance (loading) and reliability
    if (
      !_.isEqual(
        _.sortBy(data?.all_types),
        _.sortBy(props.editEventData?.all_types)
      )
    ) {
      dispatch(calendarEventEditData.getData({ id: data.id }))
    } else if (props.entity === ENTITY_MODEL) {
      // get entity event type
      const currentEventType = getEntityEventType(
        props.editEventData,
        props.entity,
        props.entityData?.id
      )
      const updatedEventType = getEntityEventType(
        data,
        props.entity,
        props.entityData?.id
      )
      // compare if event type changed
      if (currentEventType !== updatedEventType) {
        dispatch(calendarEventEditData.updateData({ ...data }))
      }
    }
  }

  const onSubmitSuccess = payload => data => {
    const { onSuccess } = payload
    onSuccess(data)

    fetchEvents()

    // Second way update events list local in store
    // but in entity calendar need watch when add, update or remove calendar form store
  }

  const onSubmit = (params, payload) => {
    const { onError } = payload
    dispatch(
      calendarEventSubmit.submit({
        params,
        onSuccess: onSubmitSuccess(payload),
        onError: onError,
      })
    )
  }

  const onEventRemoveSuccess = data => {
    dispatch(calendarEventList.removeListItem({ ...data }))
  }

  const onEventReopenSuccess = data => {
    dispatch(calendarEventList.removeListItem({ ...data }))
  }

  const onEventDoneSuccess = data => {
    dispatch(calendarEventList.updateListItem({ ...data }))
  }

  // Custom render calendar event content
  const renderCalendarEventContent = eventInfo => {
    const {
      event: { extendedProps },
    } = eventInfo

    const isHoliday = eventsCalendarHelper.eventConfig.isHolidayCategoryEvent(
      extendedProps?.type
    )

    return (
      <EventsCalendarEventContentProvider
        eventInfo={eventInfo}
        entity={props.entity}
        entityData={props.entityData}
        provider={isHoliday ? "holiday" : "general"}
      />
    )
  }

  // === Day events email send === //
  const {
    hide: hideEventsEmailSendDrawer,
    show: showEventsEmailSendDrawer,
    visible: eventsEmailSendDrawerVisible,
    data: eventsEmailSendData,
    resetData: resetEventsEmailSendData,
  } = useDrawer()

  const onEventsEmailSendSuccess = () => {
    hideEventsEmailSendDrawer()

    fetchEvents()
  }
  // === //

  // === Print day events === //
  const [printVisible, setPrintVisible] = useState(false)
  const [printDate, setPrintDate] = useState(null)
  const onPrint = date => {
    setPrintDate(date)
    setPrintVisible(true)
  }
  const onPrintDestroy = () => {
    setPrintDate(null)
    setPrintVisible(false)
  }
  // === //

  // Custom render calendar day header content for model
  // need refactoring
  const renderModelCalendarDayHeaderContent = info => (
    <EventsCalendarDayHeaderContent
      info={info}
      renderContent={(info, contentData) => (
        <ModelCalendarDayHeaderContent
          info={info}
          contentData={contentData}
          onEmailSend={date => {
            showEventsEmailSendDrawer({ date, model: props.entityData })
          }}
          onPrint={date => {
            onPrint(date)
          }}
        />
      )}
    />
  )
  // === //

  const calendarEventOrder = React.useMemo(() => {
    return props.entity === ENTITY_MODEL
      ? ["start", "order", calendarEventOrderByModelPriority(props.entityData)]
      : ["start", "order"]
  }, [props.entity, props.entityData, calendarEventOrderByModelPriority])

  return (
    <>
      <GeneralEventsCalendarControl
        calendarLoading={props.listLoading}
        calendarError={props.listError}
        calendarEvents={props.fullCalendarEvents}
        submitLoading={props.submitLoading}
        submitError={props.submitError}
        onSubmit={onSubmit}
        onEventRemoveSuccess={onEventRemoveSuccess}
        onEventReopenSuccess={onEventReopenSuccess}
        onEventDoneSuccess={onEventDoneSuccess}
        onFetchEvents={fetchEvents}
        onSubmitReset={onSubmitReset}
        onEventUpdate={onEventUpdate}
        editEventData={props.editEventData}
        previewEventData={props.previewEventData}
        onCalendarDatesSet={onCalendarDatesSet}
        entity={props.entity}
        entityData={props.entityData}
        renderCalendarEventContent={eventInfo =>
          renderCalendarEventContent(eventInfo)
        }
        renderCalendarDayHeaderContent={
          props.entity === ENTITY_MODEL
            ? info => renderModelCalendarDayHeaderContent(info)
            : null
        }
        calendarEventOrder={calendarEventOrder}
        eventTypeFilterProps={{
          value: props.filters.values.type,
          onChange: onEventTypeFilterChange,
        }}
        eventColors={props.eventColors}
      />

      <EventsEmailSend
        data={eventsEmailSendData}
        visible={eventsEmailSendDrawerVisible}
        onClose={hideEventsEmailSendDrawer}
        onDestroy={resetEventsEmailSendData}
        onSuccess={onEventsEmailSendSuccess}
      />

      {printVisible && (
        <PrintEvents
          date={printDate}
          model={props.entityData}
          onDestroy={onPrintDestroy}
        />
      )}
    </>
  )
}

GeneralEventsCalendarComponent.propTypes = {
  eventsFetchParams: PropTypes.any,
  entity: PropTypes.oneOf([ENTITY_MODEL, ENTITY_AGENCY, ENTITY_CLIENT]),
  entityData: PropTypes.object,
  eventColors: PropTypes.object,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  fullCalendarEvents: PropTypes.array,
  filters: PropTypes.object,

  editEventData: PropTypes.object,
  previewEventData: PropTypes.object,
  submitLoading: PropTypes.bool,
  submitError: PropTypes.any,
}

const mapStateToProps = (state, { entity, entityData }) => {
  const { listLoading, listError, filters } = state.calendarEvent.list

  const entityScreenConfig = entity
    ? {
        entity,
        entityId: entityData?.id,
      }
    : null

  return {
    listLoading,
    listError,
    fullCalendarEvents: selectFullCalendarEvents(state, entityScreenConfig),
    filters,

    editEventData: state.calendarEvent.edit.data.data,
    previewEventData: state.calendarEvent.data.data,

    submitLoading: state.calendarEvent.submit.loading,
    submitError: state.calendarEvent.submit.error,

    eventColors: state.user.options.data.data.event_colors,
  }
}

export const GeneralEventsCalendar = connect(mapStateToProps)(
  GeneralEventsCalendarComponent
)
