import {
    USER_SETTINGS_UPDATE_DATA,
    USER_SETTINGS_UPDATE_DATA_SUCCESS,
    USER_SETTINGS_UPDATE_DATA_ERROR,
    USER_SETTINGS_UPDATE_DATA_CLEAN_STATE,
} from './actionTypes';

export const userSettingUpdateData = (params, onSuccess, onError) => {
    return {
        type: USER_SETTINGS_UPDATE_DATA,
        payload: { params, onSuccess, onError },
    }
};

export const userSettingUpdateDataSuccess = () => {
    return {
        type: USER_SETTINGS_UPDATE_DATA_SUCCESS,
    }
};

export const userSettingUpdateDataError = (data) => {
    return {
        type: USER_SETTINGS_UPDATE_DATA_ERROR,
        payload: data,
    }
};

export const userSettingUpdateDataCleanState = () => {
    return { type: USER_SETTINGS_UPDATE_DATA_CLEAN_STATE };
};