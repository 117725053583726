import React from "react"
import PropTypes from "prop-types"
import { useTranslation, Trans } from "react-i18next"

import { Input } from "../Input"
import googleMedia from "assets/images/dev/illistrations/sync-to-calendar/google-subscribe-to-calendar.png"
import s from "./CalendarURL.module.scss"

export const CalendarURL = ({ data }) => {
  const { t } = useTranslation()

  const { stream, download } = data ?? {}

  return (
    <>
      <div>
        <Input value={stream} download={download} />
      </div>

      <div className={`pt-2 gap-2 ${s.googleInstructions}`}>
        <div className={s.googleInstructionsTextBlock}>
          <Trans i18nKey={"sync_to_calendar.google-instruction"} />
        </div>

        <img className={s.media} src={googleMedia} alt={"media"} />
      </div>
    </>
  )
}

CalendarURL.propTypes = {
  data: PropTypes.object,
}
