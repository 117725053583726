import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  isLoaded: false,
}

const chatChatsListSlice = createSlice({
  name: "chatChatsList",
  initialState,
  reducers: {
    getList(state) {
      state.listLoading = true
      state.listError = null
      state.isLoaded = false
    },
    getListSuccess(state, action) {
      state.listLoading = false
      state.list = action.payload.data
      state.isLoaded = true
    },
    getListError(state, action) {
      state.listLoading = false
      state.listError = action.payload
    },
    addItem(state, action) {
      state.list.push(action.payload)
    },
    updateItem(state, action) {
      state.list = state.list.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          }
        } else {
          return item
        }
      })
    },
    removeItem(state, action) {
      state.list = state.list.filter(item => item.id !== action.payload.id)
    },
    cleanState() {
      return initialState
    },
  },
})

/**
 * @namespace
 * @property {function} getList
 * @property {function} getListSuccess
 * @property {function} getListError
 * @property {function} addItem
 * @property {function} updateItem
 * @property {function} removeItem
 * @property {function} cleanState
 */

export const chatChatsList = chatChatsListSlice.actions

export default chatChatsListSlice.reducer
