import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "i18n"
import { API } from "api"
import { modelGeneralUpdateAvatar } from "./slice"
import { modelHelper } from "helpers"
import {
  modelPanelGeneralSyncUploadedPhotos,
  modelProfileUpdateData,
} from "store"

function* updateItem({
  payload: { attachmentId, params, onSuccess, onError },
}) {
  try {
    let requestBody = yield call(
      modelHelper.photos.getModelAvatarRequestBody,
      params
    )

    const response = yield call(
      API.model.updateModelPhoto,
      attachmentId,
      requestBody
    )

    const { data } = response

    if (onSuccess) {
      onSuccess()
    }
    toastr.success(i18n.t("model.update-avatar.success"))

    yield put(modelGeneralUpdateAvatar.updateCompleted())
    yield put(
      modelProfileUpdateData({
        avatar: data.data?.attachment?.src,
        crops: data.data?.crops,
      })
    )
    yield put(modelPanelGeneralSyncUploadedPhotos())
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      modelGeneralUpdateAvatar.updateRejected({
        errors,
        message,
      })
    )
    if (onError) {
      onError(errors)
    }
  }
}

export function* watchUpdateItem() {
  yield takeEvery(modelGeneralUpdateAvatar.update, updateItem)
}

function* updateSaga() {
  yield all([fork(watchUpdateItem)])
}

export default updateSaga
