import { all } from "redux-saga/effects"

import generalSaga from "./general/saga"
import loaderSaga from "./loader/saga"

function* panelSaga() {
  yield all([generalSaga(), loaderSaga()])
}

export default panelSaga
