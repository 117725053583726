import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { Button as StrapButton } from "reactstrap"

export const LinkButton = ({
  disabled,
  title,
  leftIcon,
  icon,
  size,
  color = "primary-green",
  btnClass,
  to = "#",
  target = "blank",
  outline = false,
  loading,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <StrapButton
      tag={"a"}
      href={to}
      target={target}
      className={classNames({
        btn: true,
        "btn-block": true,
        [`btn-${color}`]: color && !outline,
        [size]: size,
        [btnClass]: btnClass,
      })}
      disabled={disabled}
      outline={outline}
      color={color && outline ? color : null}
      {...rest}
    >
      {loading && (
        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2" />
      )}
      {leftIcon && (
        <i
          className={classNames("align-middle mr-2", {
            [leftIcon]: leftIcon,
          })}
        />
      )}

      {t(title)}

      {icon && <i className={icon} />}
    </StrapButton>
  )
}

LinkButton.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.string,
  leftIcon: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  btnClass: PropTypes.string,
  outline: PropTypes.bool,
  to: PropTypes.string,
  target: PropTypes.string,
  loading: PropTypes.bool,
}
