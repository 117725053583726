import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import { Select } from "../Select"

export const Combobox = ({
  value,
  options = [],
  normalize,
  isDetermineValue,
  isMulti,
  disabled,
  className,
  ...props
}) => {
  const { t } = useTranslation()

  const onChange = option => {
    option = normalize ? normalize(option) : option
    props.onChange && props.onChange(option)
  }

  const determineValue = () => {
    if (isMulti) {
    } else {
      if (value === null || value === undefined) {
        return null
      }
      let optionFieldValue = props.getOptionValue
        ? props.getOptionValue
        : option => option.value
      return options.find(item => optionFieldValue(item) === value)
    }
  }

  return (
    <Select
      options={options}
      className={className}
      classNamePrefix="select2-selection"
      noOptionsMessage={() => t("select.no_options")}
      isDisabled={disabled}
      {...props}
      value={isDetermineValue ? determineValue() : value}
      onChange={option => onChange(option)}
      onBlur={props.onBlur}
    />
  )
}

Combobox.propTypes = {
  normalize: PropTypes.func,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
  isDetermineValue: PropTypes.bool,
  isMulti: PropTypes.bool,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  className: PropTypes.string,
  getOptionValue: PropTypes.func,
}
