import React, { useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Col, Row } from "reactstrap"

import {
  Button,
  ComboboxFormField,
  ControllerHF,
  InputField,
} from "../../../index"
import { NormalizeUtils } from "../../../../utils"
import { validationSchema } from "./validation"
import { commonHelper } from "../../../../helpers"

export const ExpenseForm = props => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      props.onFormDestroy && props.onFormDestroy()
    }
  }, [])

  const defaultValues = useMemo(() => {
    const { value, currency } = props.initialValues

    return {
      ...props.initialValues,
      value: value || value === 0 ? value.toString() : "",
      currency: currency || props.currency,
    }
  }, [props.initialValues])

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      type: null,
      description: "",
      ...defaultValues,
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  })

  const onReset = () => {
    reset()
  }

  const onSubmit = values => {
    const params = {
      ...values,
    }

    props.onSubmit(params, { onReset, setError })
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form
        onSubmit={e => {
          e.stopPropagation()
          handleSubmit(onSubmit)(e)
        }}
      >
        <Row>
          <Col md={12}>
            <ControllerHF
              name={"type"}
              control={control}
              component={ComboboxFormField}
              id={"type"}
              label={t("expense_type")}
              placeholder={t("expense_type")}
              options={props.expenseTypeOptions}
              normalize={option => (option ? option.value : null)}
              isDetermineValue
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <ControllerHF
              name={"description"}
              control={control}
              component={InputField}
              id={"description"}
              label={t("description")}
              placeholder={t("description")}
              type={"textarea"}
            />
          </Col>
        </Row>

        <Row>
          <Col md={8}>
            <ControllerHF
              name={"value"}
              control={control}
              component={InputField}
              id={"value"}
              label={t("amount")}
              placeholder={t("amount")}
              normalize={value => NormalizeUtils.onlyDecimal(value, 2)}
            />
          </Col>

          <Col md={4}>
            <ControllerHF
              name={"currency"}
              control={control}
              component={ComboboxFormField}
              id={"currency"}
              label={" "}
              placeholder={t("currency")}
              options={commonHelper.currency.getCurrencies()}
              getOptionLabel={option => option.label.toUpperCase()}
              normalize={option => (option ? option.value : null)}
              isDetermineValue
              selectProps={{
                isSearchable: false,
              }}
            />
          </Col>
        </Row>

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </>
  )
}

ExpenseForm.propTypes = {
  isEdit: PropTypes.bool,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  currency: PropTypes.string,
  expenseTypeOptions: PropTypes.array,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onFormDestroy: PropTypes.func,
}
