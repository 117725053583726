import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"

import { PanelSidebar } from "../../../../../../components"
import { ModelsSidebar } from "../../ModelsSidebar"
import { BookPhotosPickerSidebar } from "../../BookPhotosPickerSidebar"
import { modelPanelModelsSidebarListResetFetchedData } from "../../../../../../store/actions"
import { usePanelSidebarContext } from "../../../../../../contexts"
import { MODEL_PANEL_BOOK_PHOTOS_PICKER_SIDEBAR } from "../../../../../../consts"

export const Sidebar = () => {
  const dispatch = useDispatch()
  const { options } = usePanelSidebarContext()

  const [mounted, setMounted] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)

  useEffect(() => {
    setMounted(true)
    return () => {
      dispatch(modelPanelModelsSidebarListResetFetchedData())
    }
  }, [])

  const toggleDrawerVisible = forceStatus => {
    if (forceStatus !== undefined) {
      setDrawerVisible(forceStatus)
    } else {
      setDrawerVisible(prevStatus => !prevStatus)
    }
  }

  return (
    <PanelSidebar
      className={"models-panel-sidebar"}
      drawerClassName={"models-panel-sidebar"}
      drawerVisible={drawerVisible}
      toggleDrawerVisible={toggleDrawerVisible}
    >
      {mounted && (
        <>
          {options?.active === MODEL_PANEL_BOOK_PHOTOS_PICKER_SIDEBAR ? (
            <BookPhotosPickerSidebar
              drawerVisible={drawerVisible}
              toggleDrawerVisible={toggleDrawerVisible}
              mode={options?.extra?.mode}
            />
          ) : (
            <ModelsSidebar
              drawerVisible={drawerVisible}
              toggleDrawerVisible={toggleDrawerVisible}
            />
          )}
        </>
      )}
    </PanelSidebar>
  )
}

Sidebar.propTypes = {}
