import { createSelector } from "@reduxjs/toolkit"
import { eventsCalendarHelper } from "helpers"

const getCalendarEventsList = state => state.calendarEvent.list.list
const getEventColors = state => state.user.options.data.data.event_colors

export const selectFullCalendarEvents = createSelector(
  [
    getCalendarEventsList,
    (state, entityScreenConfig) => entityScreenConfig,
    getEventColors,
  ],
  (list, entityScreenConfig, eventColors) => {
    return eventsCalendarHelper.calendar.mapEventsToCalendarEvents(
      list,
      entityScreenConfig,
      eventColors
    )
  }
)
