import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const billingPaymentMethodDefaultSlice = createSlice({
  name: "billingPaymentMethodDefault",
  initialState,
  reducers: {
    send(state) {
      state.loading = true
      state.error = null
    },
    sendSuccess(state) {
      state.loading = false
    },
    sendError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} send
 * @property {function} sendSuccess
 * @property {function} sendError
 * @property {function} cleanState
 */
export const billingPaymentMethodDefault =
  billingPaymentMethodDefaultSlice.actions

export default billingPaymentMethodDefaultSlice.reducer
