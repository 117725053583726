import React from "react"
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  generatePath,
} from "react-router-dom"

import { useDepartmentPermissionGuard } from "hooks"
import { DEPARTMENTS_PERMISSIONS } from "consts"
import { Rating } from "../../Raiting"
import { General } from "../../General"
import { History } from "../../History"
import { Prices } from "../../Prices"
import { Calendar } from "../../Calendar"
import { Statement } from "../../Statement"
import { Activities } from "../../Activities"

export const PanelRouting = props => {
  let { path } = useRouteMatch()
  const { id } = useParams()

  const { status: allowed } = useDepartmentPermissionGuard({
    permission: DEPARTMENTS_PERMISSIONS.CLIENT_STATEMENT_READ,
  })

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={generatePath(`${path}/general`, { id: id })} />
      </Route>
      <Route path={`${path}/general`}>
        <General />
      </Route>
      <Route path={`${path}/calendar`}>
        <Calendar {...props} />
      </Route>

      {allowed && (
        <Route path={`${path}/statement`}>
          <Statement {...props} />
        </Route>
      )}

      <Route path={`${path}/activities`}>
        <Activities {...props} />
      </Route>
      <Route path={`${path}/rating`}>
        <Rating />
      </Route>
      <Route path={`${path}/history`}>
        <History />
      </Route>
      {/*<Route path={`${path}/prices`}>*/}
      {/*  <Prices />*/}
      {/*</Route>*/}
      <Route>
        <Redirect to={generatePath(`${path}/general`, { id: id })} />
      </Route>
    </Switch>
  )
}
