import React from "react"
import PropTypes from "prop-types"

//Common
import { useSubmitHandler } from "hooks"

//LOCAL
import { PublishToFBPageForm } from "./PublishToFBPageForm"
import { useDispatch, useSelector } from "react-redux"
import { selectSocialCalendarCreatePost, socialCalendarCreatePost } from "store"

export const PublishToFBPage = ({
  attachmentId,
  src,
  description,
  onClose,
}) => {
  const dispatch = useDispatch()

  const { loading, error } = useSelector(selectSocialCalendarCreatePost)

  const closeModal = () => {
    onClose()
    dispatch(socialCalendarCreatePost.cleanState())
  }

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        socialCalendarCreatePost.create({
          params,
          onSuccess,
          onError,
        })
      )
    },
    () => {
      closeModal()
    }
  )

  return (
    <PublishToFBPageForm
      error={error}
      loading={loading}
      onCancel={closeModal}
      onSubmit={handleSubmit}
      initialValues={{
        description,
        fashion_model_photo_id: attachmentId,
      }}
      imageUrl={src}
    />
  )
}

PublishToFBPage.propTypes = {
  src: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  attachmentId: PropTypes.any,
}
