import {
    PACKAGE_LIST_REMOVE_ITEM,
    PACKAGE_LIST_REMOVE_ITEM_SUCCESS,
    PACKAGE_LIST_REMOVE_ITEM_ERROR,
    PACKAGE_LIST_REMOVE_ITEM_CLEAN_STATE,
} from './actionTypes';

export const packageListRemoveItem = (id, onSuccess) => {
    return {
        type: PACKAGE_LIST_REMOVE_ITEM,
        payload: { id, onSuccess },
    }
};

export const packageListRemoveItemSuccess = (id) => {
    return {
        type: PACKAGE_LIST_REMOVE_ITEM_SUCCESS,
        payload: { id },
    }
};

export const packageListRemoveItemError = (id) => {
    return {
        type: PACKAGE_LIST_REMOVE_ITEM_ERROR,
        payload: { id },
    }
};

export const packageListRemoveItemCleanState = () => {
    return { type: PACKAGE_LIST_REMOVE_ITEM_CLEAN_STATE };
};