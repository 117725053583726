import { takeLatest, fork, put, all, call } from "redux-saga/effects"

import i18n from "../../../../i18n"
import { RequestUtils } from "../../../../utils"
import { API } from "../../../../api"
import { agenciesList } from "./slice"

export function* getList({ payload: { params } }) {
    try {
        let requestParams = {
            includes: [],
        }

        requestParams = RequestUtils.getMapRequestParams(params, requestParams)

        const response = yield call(API.agency.getAgencies, requestParams)

        const { data } = response

        yield put(agenciesList.getListCompleted(data))
    } catch (error) {
        const message = error.response?.data?.message || i18n.t("error")
        yield put(agenciesList.getListRejected(message))
    }
}

export function* watchGetList() {
    yield takeLatest(agenciesList.getList, getList)
}

function* listSaga() {
    yield all([fork(watchGetList)])
}

export default listSaga
