import { combineReducers } from "redux";

import add from './add/reducer';
import list from './list/reducers';
import details from './details/reducers';
import published from './published/reducers';
import photosPicker from './photosPicker/reducer';
import publish from './publish/reducer';
import common from './common/reducers';

const bookReducer = combineReducers({
    add,
    list,
    details,
    published,
    photosPicker,
    publish,
    common,
});

export default bookReducer;