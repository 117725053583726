import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "../../../../i18n"
import { websiteReorderMenuItems } from "./slice"
import { API } from "../../../../api"
import { websiteMenuItemsList } from "../actions"

function* reorder({ payload: { params, previousOrder } }) {
  try {
    yield put(websiteMenuItemsList.updateList(params.data))

    const response = yield call(API.website.reorderMenuItems, params)

    const { data } = response

    toastr.success(i18n.t("website.reorder-menu-item.success"))

    yield put(websiteReorderMenuItems.reorderCompleted())
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")
    yield put(websiteMenuItemsList.updateList(previousOrder))
    yield put(
      websiteReorderMenuItems.reorderRejected({
        errors,
        message,
      })
    )

    toastr.error(message)
  }
}

export function* watchReorder() {
  yield takeEvery(websiteReorderMenuItems.reorder, reorder)
}

function* reorderSaga() {
  yield all([fork(watchReorder)])
}

export default reorderSaga
