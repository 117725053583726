import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { useTranslation } from "react-i18next"

export const Menu = ({ isAdmin, onEdit, onRemove, onLeave, onView }) => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  const toggleMenuOpen = () => {
    setOpen(prevState => !prevState)
  }

  return (
    <Dropdown isOpen={open} toggle={toggleMenuOpen}>
      <DropdownToggle className={"btn nav-btn btn-white"}>
        <i className={"bx bx-dots-vertical-rounded text-secondary"} />
      </DropdownToggle>

      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem onClick={onView}>{t("info")}</DropdownItem>

        {isAdmin ? (
          <>
            <DropdownItem onClick={onEdit}>{t("btn.edit")}</DropdownItem>
            <DropdownItem onClick={onRemove}>{t("btn.remove")}</DropdownItem>
          </>
        ) : (
          <>
            <DropdownItem onClick={onLeave}>{t("leave")}</DropdownItem>
          </>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}

Menu.propTypes = {
  isAdmin: PropTypes.bool,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onLeave: PropTypes.func,
  onView: PropTypes.func,
}
