import { combineReducers } from "redux"

import remove from "./remove/slice"
import defaultReducer from "./default/slice"
import list from "./list/slice"
import add from "./add/slice"

const paymentMethodReducer = combineReducers({
  remove,
  default: defaultReducer,
  list,
  add,
})

export default paymentMethodReducer
