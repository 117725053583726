import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { IconButton } from "../../../../../../components"

export const WorkTable = props => {
  const { t } = useTranslation()

  return (
    <div className="table-responsive">
      <table className="table table-nowrap align-middle table-cell_p-v_sm">
        <tbody>
          {props.items.map(work => (
            <tr key={work.id}>
              <td>
                <span className={"fw-medium"}>{work.number}</span>
              </td>
              <td>
                {work.date_expiration && (
                  <div className={"text-gray"}>
                    {`${t("exp")}. ${moment(work.date_expiration).format(
                      "DD/MM/YYYY"
                    )}`}
                  </div>
                )}
              </td>
              <td>
                {work.rate_hint && (
                  <div className={"text-gray"}>{work.rate_hint}</div>
                )}

                {work.percentage_agency && (
                  <div className={"text-gray"}>
                    {`${work.percentage_agency} %`}
                  </div>
                )}
              </td>
              <td
                className={"table-column-sticky_end bg-neutral text-nowrap"}
                style={{ width: "76px" }}
              >
                <IconButton
                  name={"edit"}
                  bg
                  onClick={() => props.onEdit(work)}
                />

                <IconButton
                  name={"remove"}
                  bg
                  onClick={() => props.onRemove(work)}
                  wrapClass={"ms-2"}
                  disabled={props.removeLoading}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

WorkTable.propTypes = {
  items: PropTypes.array,
  removeLoading: PropTypes.bool,

  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
}
