import React, { useEffect, useRef, useState, useMemo } from 'react'
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next';

import { PackageDataTabs } from "../../../../PackageDataTabs";
import { BooksGrid } from './BooksGrid';
import { VideosGrid } from './VideosGrid';
import { useDraftPackageDataPicker } from "../../../../../../hooks"
import { Link } from "react-router-dom"

const BOOKS_TAB = 'books';
const VIDEOS_TAB = 'videos';

export const PackageData = (props) => {
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState(props.initialTab);
    const playersRef = useRef([]);

    const { pickedBooks, onBookRemove, pickedVideos, onVideoRemove, pickerResetValue } = useDraftPackageDataPicker();

    const packageDataCount = useMemo(() => {
        return pickedBooks.length + pickedVideos.length;
    }, [pickedBooks.length, pickedVideos.length]);

    useEffect(() => {
        return () => {
            if (activeTab === VIDEOS_TAB) {
                pausePlayers();
            }
        }
    }, [activeTab])

    const pausePlayers = () => {
        if (playersRef.current) {
            playersRef.current.forEach((player) => {
                if (player && player?.pause) {
                    player.pause();
                }
            })
        }
    }

    const onPickedBookRemove = (book) => {
        onBookRemove(book);
    }

    const onPickedVideoRemove = (video) => {
        onVideoRemove(video);
    }

    const clearPackage = (e) => {
        e.preventDefault();
        pickerResetValue();
    }

    return (
        <div>
            <PackageDataTabs
                activeTab={activeTab}
                onTabChange={(tab) => setActiveTab(tab)}
                booksTab={
                    <BooksGrid books={pickedBooks} onRemove={onPickedBookRemove} />
                }
                videosTab={
                    <VideosGrid videos={pickedVideos} onRemove={onPickedVideoRemove} playersRef={playersRef} />
                }
                headerEnd={(
                    packageDataCount > 0 ? (
                        <Link to="#" className="text-gray" onClick={clearPackage}>
                            {t('btn.clear-package')}
                        </Link>
                    ) : null
                )}
            />
        </div>
    );
};

PackageData.propTypes = {
    initialTab: PropTypes.oneOf([BOOKS_TAB, VIDEOS_TAB]),
}