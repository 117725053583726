import React, { useState, useRef, useCallback } from 'react';
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom"
import classNames from "classnames";
import { useResizeDetector } from 'react-resize-detector';

export const ReadMoreExpand = ({
    children,
    className,
    maxHeight,
    lineHeight,
}) => {
    const { t } = useTranslation();

    const [isExpandMode, setIsExpandMode] = useState(false)
    const [open, setOpen] = useState(false)

    const wrapRef = useRef(null);

    useResizeDetector({
        targetRef: wrapRef,
        onResize: (width, height) => detectExpand(),
    });

    const toggleShow = (e) => {
        e.preventDefault();

        setOpen(prevOpen => !prevOpen);
    }

    const countLines = () => {
        let height = wrapRef?.current?.scrollHeight;
        if ((height - 2) / lineHeight > maxHeight) {
            setIsExpandMode(true);
        }
    }

    const detectExpand = useCallback(() => {
        let height = wrapRef?.current?.scrollHeight;
        if ((height - 2) > maxHeight) {
            setIsExpandMode(true);
        } else {
            setIsExpandMode(false);
        }
    }, []);

    return (
        <div className={classNames('read-more-expand', {
            [className]: className,
        })}>
            <div className={classNames('read-more-expand__text', {
                'read-more-expand__open': isExpandMode && open,
            })}
                 ref={wrapRef}
                 style={{
                     maxHeight: isExpandMode && open ? wrapRef?.current?.scrollHeight : maxHeight,
                     lineHeight: `${lineHeight}px`,
                 }}
            >
                {children}
            </div>

            {isExpandMode && (
                <div className={'read-more-expand__actions'}>
                    <Link to={"#"} onClick={(e) => toggleShow(e)} className={'read-more-expand__btn'}>
                        {open ? t('collapse') : t('expand')}
                    </Link>
                </div>
            )}
        </div>
    );
};

ReadMoreExpand.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    maxHeight: PropTypes.number,
    lineHeight: PropTypes.number,
}