import { all } from "redux-saga/effects"

import removeSaga from "./remove/saga"
import defaultSaga from "./default/saga"
import listSaga from "./list/saga"
import addSaga from "./add/saga"

function* paymentMethodSaga() {
  yield all([removeSaga(), defaultSaga(), listSaga(), addSaga()])
}

export default paymentMethodSaga
