import React from "react"
import PropTypes from "prop-types"
import { TabContent, TabPane } from "reactstrap"
import { useTranslation } from "react-i18next"

import i18n from "../../../i18n"
import { TabsNav } from "../../index"

const BOOKS_TAB = "books"
const VIDEOS_TAB = "videos"
const TABS = [
  { label: i18n.t("books"), value: BOOKS_TAB },
  { label: i18n.t("videos"), value: VIDEOS_TAB },
]

export const PackageDataTabs = props => {
  const { t } = useTranslation()

  return (
    <div className={"package-data-tabs"}>
      <div className={"package-data-tabs__nav-block"}>
        <div className={""}>
          <TabsNav
            options={TABS}
            active={props.activeTab}
            onChange={tab => props.onTabChange(tab)}
          />
        </div>

        {props.headerEnd && (
          <div className={"d-flex ms-3"}>{props.headerEnd}</div>
        )}
      </div>

      <TabContent activeTab={props.activeTab} className="mt-3">
        <TabPane tabId={BOOKS_TAB}>
          {props.destroyOnClose
            ? props.activeTab === BOOKS_TAB
              ? props.booksTab
              : null
            : props.booksTab}
        </TabPane>

        <TabPane tabId={VIDEOS_TAB}>
          {props.destroyOnClose
            ? props.activeTab === VIDEOS_TAB
              ? props.videosTab
              : null
            : props.videosTab}
        </TabPane>
      </TabContent>
    </div>
  )
}

PackageDataTabs.propTypes = {
  activeTab: PropTypes.oneOf([BOOKS_TAB, VIDEOS_TAB]),
  onTabChange: PropTypes.func,
  booksTab: PropTypes.any,
  videosTab: PropTypes.any,
  headerEnd: PropTypes.any,
  destroyOnClose: PropTypes.bool,
}
