import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import {
  DateBlock,
  LocationBlock,
  OtherEventDetailsBlock,
} from "./CalendarEventInfoBlocks"
import { clientHelper, eventsCalendarHelper } from "helpers"
import { useSelector } from "react-redux"
import { selectEventColorByEventType } from "store"

export const CalendarEventInfo = ({ data }) => {
  const { t } = useTranslation()
  const {
    agency,
    client,
    type,
    title,
    date_end,
    date_start,
    family,
    locations,
    times,
    product,
  } = data

  const isOtherCategoryEvent =
    eventsCalendarHelper.eventConfig.isOtherCategoryEvent(data?.type)

  const otherEventInfoList = useMemo(
    () => eventsCalendarHelper.eventInfo.getOtherEventsInfoData(data),
    [data]
  )
  const eventColor = useSelector(state =>
    selectEventColorByEventType(state, data?.type)
  )

  return (
    <div className={"calendar-event-info"}>
      {(client || agency) && (
        <div>
          {t("alert-for")}: {client?.name || agency?.name}
        </div>
      )}

      <div className={"font-size-12"}>{t("calendar-event-alert-info")}</div>

      <div className={"d-flex gap-1"}>
        <div
          className={"calendar-event-info__event-type"}
          style={{ backgroundColor: eventColor }}
        >
          {eventsCalendarHelper.eventConfig.getEventConfig(type).title}
        </div>
        {title && (
          <div className={"calendar-event-info__event-title"}>{title}</div>
        )}
      </div>

      {family && (
        <div className={"fw-bold"}>
          {t("family")}: {clientHelper.family.getFamily(family).label}
        </div>
      )}

      {product && (
        <div className={"fw-bold"}>
          {t("product")}: {t(product)}
        </div>
      )}

      <DateBlock
        date_start={date_start}
        date_end={date_end}
        bgColor={eventColor}
      />

      <div>
        {times &&
          times.map((time, index) => {
            return (
              <div key={`calendar-event-time-${index}`}>
                {time.start} - {time.end}
              </div>
            )
          })}
      </div>

      {isOtherCategoryEvent && (
        <OtherEventDetailsBlock list={otherEventInfoList} />
      )}

      <div className={"d-flex gap-4 flex-wrap"}>
        {!!locations.length && <LocationBlock locations={locations} />}
      </div>
    </div>
  )
}

CalendarEventInfo.propTypes = {
  data: PropTypes.object,
}
