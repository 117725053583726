import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import { FieldError } from "../../../FormElements"
import { RecipientsControl } from './RecipientsControl';

export const RecipientsField = ({
  id,
  disabled,
  value = [],
  meta = {},
  translateParams,
  className,
  ...props
}) => {
  const { error } = meta

  const { t } = useTranslation()
  const showError = Boolean(error)

  return (
    <div className={classNames("", { [className]: className })}>
      <div
        className={classNames("", {
          "is-invalid": showError,
        })}
      >

        <RecipientsControl
          value={value}
          onChange={props.onChange}
          disabled={disabled}
          limit={props.limit}
        />

      </div>

      <FieldError
        error={error}
        translateParams={translateParams}
        showError={showError}
      />
    </div>
  )
}

RecipientsField.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  translateParams: PropTypes.object,
  value: PropTypes.any,
  meta: PropTypes.object,
  className: PropTypes.string,
  limit: PropTypes.number,
}
