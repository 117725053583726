import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Drawer } from "../../../../../../components"
import { Container, Row } from "reactstrap"
import { ValidationsUtils } from "../../../../../../utils"
import { ContactsForm } from "../ContactsForm"
import { useTranslation } from "react-i18next"
import { clientUpdateContact } from "../../../../../../store/actions"
import { ContactDataLoader } from "../ContactDataLoader"
import { useCountryOptions } from "../../../../../../hooks"
import { commonHelper } from "../../../../../../helpers"

const EditContactsContainerComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const countryOptions = useCountryOptions({ visible: props.visible })
  const handleCloseDrawer = () => {
    props.onClose()
  }
  const onSubmitSuccess = onSubmitProps => () => {
    const { onReset } = onSubmitProps
    onReset()
    props.onEditSuccess()
    handleCloseDrawer()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      clientUpdateContact.update({
        id: props.id,
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  const onDestroy = () => {
    dispatch(clientUpdateContact.cleanState())
    props.onDestroy()
  }
  return (
    <>
      <Drawer
        title={t("edit-contact")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <ContactDataLoader id={props.id}>
          <Container fluid>
            <Row>
              <ContactsForm
                onSubmit={handleSubmit}
                onCancel={handleCloseDrawer}
                initialValues={{
                  ...props.contact,
                  phones: props.contact?.phones || [],
                  emails: props.contact?.emails || [],
                  social_networks: commonHelper.socialNetwork.formInitialValues(
                    props.contact?.social_networks,
                    ["whatsapp", "telegram", "viber", "instagram"]
                  ),
                }}
                loading={props.loading}
                error={props.error}
                contactId={props.id}
                countryOptions={countryOptions}
              />
            </Row>
          </Container>
        </ContactDataLoader>
      </Drawer>
    </>
  )
}

EditContactsContainerComponent.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.number,
  loading: PropTypes.bool,
  error: PropTypes.object,
  contact: PropTypes.object,
  onDestroy: PropTypes.func,
  onEditSuccess: PropTypes.func,
  client: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.client.contacts.data
  const { loading, error } = state.client.contacts.update
  const { profile } = state.client.profile
  return {
    contact: data,
    loading,
    error,
    client: profile,
  }
}

export const EditContacts = connect(mapStateToProps)(
  EditContactsContainerComponent
)
