import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert } from "reactstrap"
import * as Yup from "yup"
import {
  Button,
  ComboboxFormField,
  ControllerHF,
  DateInputField,
  InputField,
} from "components"
import { employeeHelper } from "helpers"
import { NormalizeUtils } from "utils"
import moment from "moment"

const departmentOptions = employeeHelper.general.getDepartmentOptions()

export const EditEmployeeForm = props => {
  const { t } = useTranslation()

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      ...props.initialValues,
    },
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string(),
        name: Yup.string().required("field.error.required"),
        department: Yup.object().nullable().required("field.error.required"),
        // phone: Yup.string()
        //     .test('phone', 'field.error.invalid', val => {
        //         if (val && val !== '') {
        //             return val.toString() !== '+';
        //         }
        //         return true;
        //     })
      })
    ),
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
      department: values.department.value,
      date_of_birth: values.date_of_birth
        ? moment(values.date_of_birth).format("YYYY-MM-DD")
        : null,
    }

    // if (values.phone) {
    //     params.phone = values.phone.replace(/[^0-9]/g, '');
    // }

    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <ControllerHF
          name={"email"}
          control={control}
          component={InputField}
          id={"email"}
          label={t("field.label.email")}
          placeholder={t("field.label.email")}
          disabled
        />
        <ControllerHF
          name={"name"}
          control={control}
          component={InputField}
          id={"name"}
          label={t("field.label.fullname")}
          placeholder={t("field.label.fullname")}
        />
        <ControllerHF
          name={"department"}
          control={control}
          component={ComboboxFormField}
          id={"department"}
          label={t("department")}
          placeholder={t("department")}
          options={departmentOptions}
          selectProps={{
            isSearchable: false,
          }}
        />
        {/*<ControllerHF*/}
        {/*    control={control}*/}
        {/*    name="phone"*/}
        {/*    label={t('field.label.phone')}*/}
        {/*    placeholder={t('field.placeholder.phone')}*/}
        {/*    id={'phone'}*/}
        {/*    normalize={NormalizeUtils.phone}*/}
        {/*/>*/}
        <ControllerHF
          control={control}
          component={DateInputField}
          name="date_of_birth"
          label={t("date-of-birth")}
          placeholder={"DD/MM/YYYY"}
          id={"date_of_birth"}
          mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
        />
        <ControllerHF
          name={"address"}
          control={control}
          component={InputField}
          id={"address"}
          label={t("address")}
          placeholder={t("address")}
        />

        <ControllerHF
          name={"bank_name"}
          control={control}
          component={InputField}
          id={"bank_name"}
          label={t("bank-name")}
          placeholder={t("bank-name")}
        />
        <ControllerHF
          name={"bank_account_number"}
          control={control}
          component={InputField}
          id={"bank_account_number"}
          label={t("bank-account-number")}
          placeholder={t("bank-account-number")}
          normalize={NormalizeUtils.onlyNumbers}
        />
        <ControllerHF
          name={"vat"}
          control={control}
          component={InputField}
          id={"vat"}
          label={t("vat")}
          placeholder={t("vat")}
        />
        <ControllerHF
          name={"other_information"}
          control={control}
          component={InputField}
          id={"other_information"}
          label={t("other-information")}
          placeholder={t("other-information")}
          type={"textarea"}
        />
        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button title={"cancel"} color="light" outline onClick={onCancel} />
          </div>
        </div>
      </form>
    </>
  )
}

EditEmployeeForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
