import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { PACKAGE_ADD } from "./actionTypes"
import { packageAddSuccess, packageAddError } from "./actions"

import { API } from "api"

function* add({ payload: { params, onSuccess, onError } }) {
  try {
    const response = yield call(API.packageApi.addPackage, params)

    const { data } = response

    yield put(packageAddSuccess())

    toastr.success(i18n.t("save.success"))

    if (onSuccess) {
      onSuccess(data?.data)
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    let payload = {
      errors,
      message,
    }

    if (error.response && error.response.status === 403) {
      payload = {
        ...payload,
        forbidden: true,
      }
    }

    yield put(packageAddError(payload))

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchAdd() {
  yield takeEvery(PACKAGE_ADD, add)
}

function* addSaga() {
  yield all([fork(watchAdd)])
}

export default addSaga
