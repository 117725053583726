import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Row } from "reactstrap";

import { Drawer } from '../../../../../../components';
import { AgencyForm } from '../AgencyForm';
import { AgencyDataLoader } from '../AgencyDataLoader';
import { modelPanelGeneralAgencyDetailsEdit, modelPanelGeneralAgencyDetailsEditCleanState } from '../../../../../../store/actions';
import { ValidationsUtils } from "../../../../../../utils"

const EditAgencyComponent = (props) => {
    const { t } = useTranslation();

    const handleCloseDrawer = () => {
        props.onClose();
    }

    const onSubmitSuccess = (onSubmitProps) => () => {
        const { reset } = onSubmitProps;
        reset();

        props.onEditSuccess();
        props.onClose();
    }

    const onSubmitError = (onSubmitProps) => (errors) => {
        const { setError } = onSubmitProps;
        ValidationsUtils.setServerSideErrors(errors, setError);
    }

    const handleSubmit = (params, onSubmitProps) => {
        props.modelPanelGeneralAgencyDetailsEdit(props.id, params, onSubmitSuccess(onSubmitProps), onSubmitError(onSubmitProps));
    };

    const onDestroy = () => {
        props.modelPanelGeneralAgencyDetailsEditCleanState();
        props.onDestroy();
    }

    return (
        <React.Fragment>
            <Drawer
                title={t('agency')}
                visible={props.visible}
                onClose={handleCloseDrawer}
                placement={'right'}
                className={'drawer-container'}
                destroyOnClose
                onDestroy={onDestroy}
            >
                <AgencyDataLoader id={props.id}>
                    <Container fluid>
                        <Row>
                            <AgencyForm
                                initialValues={{
                                    ...props.agency,
                                }}
                                loading={props.loading}
                                error={props.error}
                                onSubmit={handleSubmit}
                                onCancel={handleCloseDrawer}
                                mode={'edit'}
                            />
                        </Row>
                    </Container>
                </AgencyDataLoader>
            </Drawer>
        </React.Fragment>
    );
};

EditAgencyComponent.propTypes = {
    visible: PropTypes.bool,
    onEditSuccess: PropTypes.func,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onDestroy: PropTypes.func,

    agency: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.object,

    modelPanelGeneralAgencyDetailsEdit: PropTypes.func,
    modelPanelGeneralAgencyDetailsEditCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { data } = state.modelPanel.general.agency.details.data;
    const { loading, error } = state.modelPanel.general.agency.details.edit;
    return {
        agency: data,
        loading, error,
    };
};

const mapDispatchToProps = {
    modelPanelGeneralAgencyDetailsEdit,
    modelPanelGeneralAgencyDetailsEditCleanState,
};

export const EditAgency = connect(mapStateToProps, mapDispatchToProps)(EditAgencyComponent);