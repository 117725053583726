import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import classNames from "classnames"
import { Media, Badge } from "reactstrap"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { Avatar } from "./Avatar"

export const Item = props => {
  const { t } = useTranslation()

  const {
    item: { participant, last_message, unread_count, is_group, name },
  } = props

  const onClick = e => {
    e.preventDefault()

    if (props.active) {
      return
    }

    props.onSelect(props.item)
  }

  const dateFormat = useMemo(() => {
    if (last_message?.created_at) {
      let mDate = moment.utc(last_message?.created_at).local()

      if (mDate.isSame(moment(), "day")) {
        return mDate.format("HH:mm")
      }
      return mDate.format("DD/MM/YYYY")
    }

    return null
  }, [last_message])

  const isAttachmentsOnly =
    last_message?.files?.length > 0 && !last_message?.body

  return (
    <li
      className={classNames({
        active: props.active,
      })}
    >
      <Link to="#" onClick={onClick}>
        <Media>
          <div className="align-self-center me-3">
            <Avatar item={props.item} />
          </div>

          <Media className="overflow-hidden" body>
            <p className="fw-medium text-truncate mb-1 chat-item-name">
              {is_group ? name : participant.name}
            </p>

            <p className="text-truncate mb-0">
              {last_message?.is_my && (
                <span className={"text-secondary"}>{t("you")}: </span>
              )}

              {/*/!* if not mine and is group -> render participant name (didn't has field) *!/*/}
              {/*{!last_message?.is_my && is_group && (*/}
              {/*  <span className={"text-secondary"}>{last_message?.participant?.name}: </span>*/}
              {/*)}*/}

              {isAttachmentsOnly ? (
                <span>
                  <i
                    className={
                      "bx bx-file font-size-16 text-secondary me-1 chat__attachment-icon"
                    }
                  />
                  {t("attachments")}
                </span>
              ) : (
                last_message?.body
              )}
            </p>
          </Media>

          <div className={"chat-list__item-end-block"}>
            <p className="font-size-11 mb-0">{dateFormat}</p>
            {unread_count > 0 && (
              <div className={"text-end"}>
                <Badge
                  pill
                  className={"font-size-12 badge-soft-secondary rounded-pill"}
                >
                  {unread_count}
                </Badge>
              </div>
            )}
          </div>
        </Media>
      </Link>
    </li>
  )
}

Item.propTypes = {
  item: PropTypes.object,
  onSelect: PropTypes.func,
  active: PropTypes.bool,
}
