import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { chatGroupsParticipantRemove } from "./slice"

function* remove({ payload: { chat, user, onSuccess } }) {
  try {
    const response = yield call(API.chat.deleteUserFromGroup, chat, user)

    const { data } = response

    yield put(chatGroupsParticipantRemove.removeSuccess())
    if (onSuccess) {
      onSuccess(data.data)
    }

    toastr.success(i18n.t("chat.group.participant.remove.success"))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      chatGroupsParticipantRemove.removeError({
        errors,
        message,
      })
    )
    toastr.error(message)
  }
}

export function* watchRemove() {
  yield takeEvery(chatGroupsParticipantRemove.remove, remove)
}

function* removeSaga() {
  yield all([fork(watchRemove)])
}

export default removeSaga
