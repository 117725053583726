import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

export const InvoiceDetails = props => {
  const { t } = useTranslation()

  const {
    invoice_number,
    invoice_date,
    client_number,
    payment_terms,
    bank_name,
    iban,
    swift,
  } = props.data

  const renderDetailsInfo = () => {
    const info = [
      {
        label: t("invoice-nr"),
        value: <h5 className={"mb-0"}>{invoice_number}</h5>,
      },
      {
        label: t("date"),
        value: (
          <h5 className={"mb-0"}>
            {moment(invoice_date).format("DD/MM/YYYY")}
          </h5>
        ),
      },
      {
        label: t("client"),
        value: client_number || "-",
      },
    ]

    return info.map((item, i) => (
      <div key={i} className="invoice-card-invoice-details__info-item">
        <div className="invoice-card-invoice-details__info-label">
          {item.label}
        </div>
        <div className="invoice-card-invoice-details__info-value">
          {item.value}
        </div>
      </div>
    ))
  }

  return (
    <div className="invoice-card-invoice-details card-block">
      <div className="invoice-card-invoice-details__info">
        {renderDetailsInfo()}
      </div>

      <div className="invoice-card-invoice-details__payment">
        <div className="invoice-card-invoice-details__payment-terms-block">
          <p className={"fw-medium mb-0"}>{t("terms_of_payment")}</p>
          <div className="invoice-card-invoice-details__payment-terms">
            {payment_terms}
          </div>
        </div>

        <div className="invoice-card-invoice-details__payment-bank-block">
          <p className={"mb-0"}>{bank_name}</p>

          <div className={"d-flex flex-wrap"}>
            <div className={"me-1"}>{t("iban")}:</div>
            <div className={"text-break"}>{iban}</div>
          </div>

          <div className={"d-flex flex-wrap"}>
            <div className={"me-1"}>{t("swift")}:</div>
            <div className={"text-break"}>{swift}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

InvoiceDetails.propTypes = {
  data: PropTypes.object,
}
