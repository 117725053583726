import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import toastr from "toastr"

import { RatesControl } from "../../../../../Controls"
import {
  calendarEventModelRateList,
  calendarEventModelRateAdd,
  calendarEventRateEdit,
  calendarEventRateRemove,
} from "../../../../../../store/actions"

import * as HOC from "../../../../../../HOC"

const DataShower = HOC.withDataShower()

export const AsyncRates = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [isEdit, setIsEdit] = useState(false)

  const { list, listLoading, listError } = useSelector(
    state => state.calendarEvent.model.rate.list
  )
  const { loading: submitLoading, error: submitError } = useSelector(state =>
    !isEdit ? state.calendarEvent.model.rate.add : state.calendarEvent.rate.edit
  )
  const { loading: removing } = useSelector(
    state => state.calendarEvent.rate.remove
  )

  useEffect(() => {
    onFetchData()

    return () => {
      dispatch(calendarEventModelRateList.cleanState())
      dispatch(calendarEventRateRemove.cleanState())
    }
  }, [])

  const onFetchData = () => {
    dispatch(
      calendarEventModelRateList.getList({
        event: props.eventId,
        model: props.modelId,
      })
    )
  }

  const onSubmitReset = () => {
    dispatch(calendarEventModelRateAdd.cleanState())
    dispatch(calendarEventRateEdit.cleanState())
  }

  const onSubmitSuccess = payload => data => {
    const { onSuccess } = payload
    onSuccess(data)

    toastr.success(t("save.success"))
  }

  const onSubmitError = payload => errors => {
    const { onError } = payload
    onError(errors)
  }

  const onAdd = (data, payload) => {
    isEdit && setIsEdit(false)

    dispatch(
      calendarEventModelRateAdd.add({
        event: props.eventId,
        model: props.modelId,
        params: { rates: data },
        onSuccess: onSubmitSuccess(payload),
        onError: onSubmitError(payload),
      })
    )
  }

  const onEdit = (data, payload) => {
    setIsEdit(true)

    dispatch(
      calendarEventRateEdit.edit({
        id: data?.id,
        params: data,
        onSuccess: onSubmitSuccess(payload),
        onError: onSubmitError(payload),
      })
    )
  }

  const onRemove = (data, payload) => {
    const { onSuccess } = payload

    dispatch(
      calendarEventRateRemove.remove({
        id: data?.id,
        onSuccess: () => {
          onSuccess(data)

          toastr.success(t("save.success"))
        },
      })
    )
  }

  const onRatesChange = value => {
    dispatch(calendarEventModelRateList.updateList(value))
  }

  return (
    <>
      <DataShower
        isLoading={listLoading}
        isFailed={listError}
        error={listError}
      >
        <RatesControl
          async
          onAdd={onAdd}
          onEdit={onEdit}
          onRemove={onRemove}
          value={list}
          onChange={onRatesChange}
          currency={props.currency}
          loading={submitLoading}
          error={submitError}
          generalLoading={removing}
          datePeriod={props.datePeriod}
          modelId={props.modelId}
          onFormDestroy={onSubmitReset}
        />
      </DataShower>
    </>
  )
}

AsyncRates.propTypes = {
  eventId: PropTypes.number,
  currency: PropTypes.string,
  datePeriod: PropTypes.object,
  modelId: PropTypes.number,
}
