import { all } from "redux-saga/effects"

import dataSaga from "./data/saga"
import removeItemSaga from "./removeItem/saga"

function* listSaga() {
  yield all([dataSaga(), removeItemSaga()])
}

export default listSaga
