import {
    PACKAGE_MODELS_FETCH_LIST,
    PACKAGE_MODELS_FETCH_LIST_SUCCESS,
    PACKAGE_MODELS_FETCH_LIST_ERROR,
    PACKAGE_MODELS_FETCH_LIST_CLEAN_STATE,

    PACKAGE_MODELS_LIST_SET_PAGE,

    PACKAGE_MODELS_LIST_CHANGE_FILTER,
    PACKAGE_MODELS_LIST_RESET_FILTER,
} from './actionTypes'

export const packageModelsFetchList = (params) => {
    return {
        type: PACKAGE_MODELS_FETCH_LIST,
        payload: { params },
    }
};

export const packageModelsFetchListSuccess = (data) => {
    return {
        type: PACKAGE_MODELS_FETCH_LIST_SUCCESS,
        payload: data,
    }
};

export const packageModelsFetchListError = (data) => {
    return {
        type: PACKAGE_MODELS_FETCH_LIST_ERROR,
        payload: data,
    }
};

export const packageModelsFetchListCleanState = () => {
    return { type: PACKAGE_MODELS_FETCH_LIST_CLEAN_STATE };
};

export const packageModelsListSetPage = (data) => {
    return {
        type: PACKAGE_MODELS_LIST_SET_PAGE,
        payload: data,
    }
};

export const packageModelsListChangeFilter = (data) => {
    return {
        type: PACKAGE_MODELS_LIST_CHANGE_FILTER,
        payload: data,
    }
};

export const packageModelsListResetFilter = () => {
    return {
        type: PACKAGE_MODELS_LIST_RESET_FILTER,
    }
};