import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { Modal, Button } from "components"
import { ChatInfo } from "./ChatInfo"

export const ChatInfoView = props => {
  const { t } = useTranslation()

  const { conversation } = useSelector(state => ({
    conversation: state.chat.conversation.data.data,
  }))

  return (
    <Modal
      ref={props.modalRef}
      title={t("info")}
      strapModalProp={{
        centered: true,
      }}
      size={null}
    >
      <ChatInfo data={conversation} />

      <div className={"mt-3"}>
        <Button
          title={"close"}
          color="dark-gray"
          outline
          onClick={props.onCancel}
        />
      </div>
    </Modal>
  )
}

ChatInfoView.propTypes = {
  modalRef: PropTypes.any,
  onCancel: PropTypes.func,
}
