import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "../../../../../i18n"
import { API } from "../../../../../api"
import { agencyContactRemoveFile } from "./slice"

function* remove({ payload: { id, onSuccess, onError } }) {
    try {
        const response = yield call(API.agency.removeAgencyContactFile, id)

        const { data } = response

        yield put(agencyContactRemoveFile.removeCompleted())

        if (onSuccess) {
            onSuccess()
        }
    } catch (error) {
        const message = error.response?.data?.message || i18n.t("error")

        yield put(agencyContactRemoveFile.removeRejected())

        toastr.error(message)

        if (onError) {
            onError()
        }
    }
}

export function* watchRemove() {
    yield takeEvery(agencyContactRemoveFile.remove, remove)
}

function* removeSaga() {
    yield all([fork(watchRemove)])
}

export default removeSaga
