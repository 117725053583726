import React from "react"
import PropTypes from "prop-types"

import { RatesControl } from "../../../../../Controls"

export const Rates = ({ rates = [], ...props }) => {
  return (
    <>
      <RatesControl
        value={rates}
        onChange={value => props.onChange(value)}
        currency={props.currency}
        datePeriod={props.datePeriod}
        modelId={props.modelId}
      />
    </>
  )
}

Rates.propTypes = {
  rates: PropTypes.array,
  onChange: PropTypes.func,
  currency: PropTypes.string,
  datePeriod: PropTypes.object,
  modelId: PropTypes.number,
}
