import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import i18n from "../../../../../i18n"
import { API } from "../../../../../api"
import { calendarEventModelAttachmentUpload } from "./slice"

function* upload({ payload: { event, model, params, onSuccess, onError } }) {
  try {
    const response =
      event && model
        ? yield call(
            API.calendar.uploadEventModelAttachment,
            event,
            model,
            params
          )
        : yield call(API.calendar.uploadModelsAttachmentForNewEvent, params)

    const { data } = response

    yield put(calendarEventModelAttachmentUpload.uploadSuccess())

    if (onSuccess) {
      onSuccess(data?.data)
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      calendarEventModelAttachmentUpload.uploadError({
        errors,
        message,
      })
    )

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchUpload() {
  yield takeEvery(calendarEventModelAttachmentUpload.upload, upload)
}

function* uploadSaga() {
  yield all([fork(watchUpload)])
}

export default uploadSaga
