import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Button, ImportEntity } from "components"
import { useModal } from "hooks"
import { IMPORT_ENTITY_TYPE } from "consts"

const { MODELS, AGENCIES, CLIENTS } = IMPORT_ENTITY_TYPE

export const ImportEntityModalButtonControl = ({
  type,
  color = "primary",
  disabled = false,
  btnClass = "",
  ...props
}) => {
  const { t } = useTranslation()

  const { modalRef, closeModal, showModal } = useModal()

  return (
    <>
      <Button
        leftIcon={"bx bx-import"}
        title={"import"}
        onClick={showModal}
        color={color}
        disabled={disabled}
        btnClass={btnClass}
      />

      <ImportEntity type={type} modalRef={modalRef} onCancel={closeModal} />
    </>
  )
}

ImportEntityModalButtonControl.propTypes = {
  type: PropTypes.oneOf([MODELS, AGENCIES, CLIENTS]),
  color: PropTypes.string,
  disabled: PropTypes.bool,
  btnClass: PropTypes.string,
}
