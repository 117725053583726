import { combineReducers } from "redux"

import data from "./data/slice"
import update from "./update/slice"
import attachments from "./attachments/reducers"

const currentPageReducer = combineReducers({
  data,
  update,
  attachments,
})

export default currentPageReducer
