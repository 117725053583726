// select group chat id
export const selectConversationGroupChatId = state =>
  state.chat.conversation.data.data?.id

// select participant id
export const selectConversationParticipantId = state =>
  state.chat.conversation.data.data?.participant?.id

// select conversation is group
export const selectConversationIsGroup = state =>
  state.chat.conversation.data.data?.is_group
