import React from "react"
import PropTypes from "prop-types"

import { MasonryComponent } from "../MasonryComponent"

export const MasonryGrid = ({ items = [], renderItem, ...props }) => {
  return (
    <MasonryComponent {...props}>
      {items.map((item, i) => (
        <React.Fragment key={item?.id || i}>{renderItem(item)}</React.Fragment>
      ))}
    </MasonryComponent>
  )
}

MasonryGrid.propTypes = {
  items: PropTypes.array,
  renderItem: PropTypes.func,
}
