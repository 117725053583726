import React from "react"

import { INITIAL_STATE } from "../../../consts"
import {
  SET_STATE,
  RESET_STATE,
  CLEAN_STATE,
  SET_CONTRACT,
  RESET_CONTRACT,
} from "./actionTypes"

export function reducerInit(initialState) {
  return {
    ...INITIAL_STATE,
    ...initialState,
  }
}

export function reducer(state, action) {
  switch (action.type) {
    // state
    case SET_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case RESET_STATE:
      return reducerInit(action.payload)

    case CLEAN_STATE:
      return INITIAL_STATE

    // contract
    case SET_CONTRACT:
      return {
        ...state,
        contract: action.payload,
      }

    case RESET_CONTRACT:
      return {
        ...state,
        contract: reducerInit(action.payload).contract,
      }

    default:
      return state
  }
}
