import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { Modal } from "../../../../../../components"
import { clientAddPrice } from "../../../../../../store/actions"
import { ValidationsUtils } from "../../../../../../utils"
import { Container, Row } from "reactstrap"
import { PriceForm } from "../PriceForm"
import { commonHelper } from "../../../../../../helpers"

const AddPriceComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const modalRef = useRef(null)
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    return () => {
      if (modalVisible) {
        dispatch(clientAddPrice.cleanState())
      }
    }
  }, [modalVisible])

  const handleAdd = e => {
    e.preventDefault()
    if (modalRef.current) {
      modalRef.current.show()
    }
  }

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.hide()
    }
  }

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    props.onAddSuccess()
    closeModal()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      clientAddPrice.add({
        id: props.client.id,
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  return (
    <React.Fragment>
      <Link to="#" className="text-secondary" onClick={handleAdd}>
        {t("btn.new-price")}
      </Link>

      <Modal
        ref={modalRef}
        title={t("client.add-price.title")}
        onVisibleChange={value => setModalVisible(value)}
        strapModalProp={{
          centered: true,
        }}
      >
        <Container fluid>
          <Row>
            <PriceForm
              onSubmit={handleSubmit}
              onCancel={closeModal}
              initialValues={{
                currency:
                  commonHelper.currency.getCurrency(
                    props.profile?.settings?.currency
                  ) || null,
              }}
              loading={props.loading}
              error={props.error}
            />
          </Row>
        </Container>
      </Modal>
    </React.Fragment>
  )
}

AddPriceComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  onAddSuccess: PropTypes.func,

  client: PropTypes.object,
  profile: PropTypes.object,
}

const mapStateToProps = state => {
  const { data: profile } = state.user.profile
  const { data } = state.client.profile.data
  const { loading, error } = state.client.prices.add
  return {
    client: data,
    loading,
    error,
    profile,
  }
}

export const AddPrice = connect(mapStateToProps)(AddPriceComponent)
