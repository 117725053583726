import React from 'react';
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next';

import { Nav } from './Nav';

export const PackageDataSlider = (props) => {
    const { t } = useTranslation();

    return (
        <div className={'package-data-slider'}>
            <div className={'package-data-slider__slide'}>
                {props.renderSlide()}
            </div>

            <div className={'package-data-slider__title'}>
                {props.title}
            </div>

            <div className={'package-data-slider__nav'}>
                <Nav
                    countPrefix={props.countPrefix}
                    count={props.count}
                    page={props.currentPage}
                    onChange={props.onPageChange}
                />
            </div>
        </div>
    );
};

PackageDataSlider.propTypes = {
    title: PropTypes.string,
    renderSlide: PropTypes.func,
    currentPage: PropTypes.number,
    onPageChange: PropTypes.func,
    count: PropTypes.number,
    countPrefix: PropTypes.string,
}