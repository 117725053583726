import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import i18n from "../../../../../../i18n"
import { MODEL_PANEL_GENERAL_FETCH_LOGISTICS } from "./actionTypes"
import {
  modelPanelGeneralFetchLogisticsSuccess,
  modelPanelGeneralFetchLogisticsError,
} from "./actions"

import { API } from "../../../../../../api"

export function* fetchLogistics({ payload: { id } }) {
  try {
    const response = yield call(API.model.getModelLogistic, id)

    const { data } = response

    yield put(modelPanelGeneralFetchLogisticsSuccess(data?.data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(modelPanelGeneralFetchLogisticsError(message))
  }
}

export function* watchFetchLogistics() {
  yield takeEvery(MODEL_PANEL_GENERAL_FETCH_LOGISTICS, fetchLogistics)
}

function* dataSaga() {
  yield all([fork(watchFetchLogistics)])
}

export default dataSaga
