import React from "react"
import PropTypes from "prop-types"
import { commonHelper } from "helpers"
import { CommonUtils } from "utils"
import { PaymentStatusMarker } from "../PaymentStatusMarker"

const { isNil } = CommonUtils

const currencySymbol = commonHelper.currency.getGeneralCurrency()?.symbol

export const Amount = ({ data = {}, ...props }) => {
  const { rates_sum, payment_status } = data

  return (
    <>
      {!isNil(rates_sum) ? (
        <div className={"d-flex gap-3 justify-content-end"}>
          <div
            className={"text-nowrap"}
          >{`${rates_sum} ${currencySymbol}`}</div>

          {!isNil(payment_status) ? (
            <div
              className={"d-flex justify-content-end"}
              style={{ minWidth: 54 }}
            >
              <div>
                <PaymentStatusMarker value={payment_status} variant={"badge"} />
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        "-"
      )}
    </>
  )
}

Amount.propTypes = {
  data: PropTypes.object,
}
