import React, { useState, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { Alert } from "reactstrap"
import { useTranslation } from "react-i18next"

import { OverlayBlockLoading } from "../../../OverlayLoading"
import { EventsCalendar } from "../../EventsCalendar"
import { CalendarActionBar } from "../CalendarActionBar"
import {
  EventForm,
  EventFormDrawer,
  EventPreviewContainer,
  EventPreviewDrawer,
  EventSendEmailDrawer,
  EventSendEmail,
} from "../../../Events"
import { ConfirmPopup } from "../../../ConfirmPopup"
import { ValidationsUtils } from "utils"
import { eventsCalendarHelper } from "helpers"
import { useDrawer } from "hooks"
import { useEventsCalendarContext } from "contexts"

export const GeneralEventsCalendarControl = props => {
  const { t } = useTranslation()

  const { config: calendarConfig } = useEventsCalendarContext()

  const confirmCloseModalRef = useRef(null)

  const [eventFormDrawerVisible, setEventFormDrawerVisible] = useState(false)
  const [calendarEvent, setCalendarEvent] = useState(null)
  const [eventIsEdit, setEventIsEdit] = useState(false)
  // for event preview
  const [eventPreviewDrawerVisible, setEventPreviewDrawerVisible] =
    useState(false)
  const [previewEvent, setPreviewEvent] = useState(null)

  const onEventCreate = data => {
    const { eventData } = data
    setCalendarEvent(eventData)
    setEventFormDrawerVisible(true)
  }

  // Preview
  const onEventView = data => {
    const { eventData } = data

    if (
      eventsCalendarHelper.eventConfig.isHolidayCategoryEvent(eventData?.type)
    ) {
      return
    }

    setPreviewEvent(eventData)
    setEventPreviewDrawerVisible(true)
  }

  const closeEventPreviewDrawer = () => {
    setEventPreviewDrawerVisible(false)
  }

  const onEventPreviewDrawerDestroy = () => {
    setPreviewEvent(null)
  }

  const onEventEdit = () => {
    closeEventPreviewDrawer()

    setEventIsEdit(true)
    setCalendarEvent(props.previewEventData)
    setEventFormDrawerVisible(true)
  }

  const onEventSendEmail = () => {
    closeEventPreviewDrawer()

    showEventSendEmailDrawerVisible(props.previewEventData)
  }
  // --- //

  const closeEventFormDrawer = () => {
    if (!eventIsEdit) {
      if (confirmCloseModalRef.current) {
        confirmCloseModalRef.current.show()
      }
    } else {
      confirmCloseEventFormDrawer()

      props.onFetchEvents()
    }
  }

  const confirmCloseEventFormDrawer = () => {
    setEventFormDrawerVisible(false)
  }

  const onEventFormDrawerDestroy = () => {
    setCalendarEvent(null)
    setEventIsEdit(false)

    props.onSubmitReset()
  }

  const onSubmitSuccess = data => {
    confirmCloseEventFormDrawer()

    // show event preview
    setPreviewEvent(data)
    setEventPreviewDrawerVisible(true)
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const onSubmit = (params, onSubmitProps) => {
    const payload = {
      isEdit: eventIsEdit,
      onSuccess: onSubmitSuccess,
      onError: errors => onSubmitError(onSubmitProps)(errors),
    }

    props.onSubmit(params, payload)
  }

  const onRemoveEventSuccess = data => {
    closeEventPreviewDrawer()

    props.onEventRemoveSuccess(data)
  }

  // Event close/reopen //
  const onEventCloseSuccess = data => {
    closeEventPreviewDrawer()

    props.onFetchEvents()
  }

  const onEventReopenSuccess = data => {
    closeEventPreviewDrawer()

    props.onEventReopenSuccess(data)
  }
  // ---- //

  // Event send email //
  const {
    hide: hideEventSendEmailDrawerVisible,
    show: showEventSendEmailDrawerVisible,
    visible: eventSendEmailDrawerVisible,
    data: sendEmailEvent,
    resetData: resetSendEmailEvent,
  } = useDrawer()

  const onEventSendEmailSuccess = data => {
    hideEventSendEmailDrawerVisible()

    props.onFetchEvents()
  }
  // ---- //

  // Initial form data for create event
  const createEventInitialValues = useMemo(() => {
    let values = null

    if (!eventIsEdit && calendarEvent) {
      values = {
        gender: calendarConfig.gender,
        ...calendarEvent,
      }

      if (props.entity && props.entityData) {
        values = {
          ...values,
          ...eventsCalendarHelper.eventForm.getEntityCreateEventInitialValues(
            props.entity,
            props.entityData,
            calendarEvent.type
          ),
        }
      }
    }
    return values
  }, [
    calendarEvent,
    eventIsEdit,
    props.entity,
    props.entityData,
    calendarConfig.gender,
  ])

  // Initial data for event form (create, edit)
  const eventInitialValues = useMemo(() => {
    let values = {}
    if (eventIsEdit && props.editEventData) {
      values = {
        ...props.editEventData,
      }
    } else if (createEventInitialValues) {
      values = createEventInitialValues
    }
    return values
  }, [props.editEventData, createEventInitialValues, eventIsEdit])

  const eventType = useMemo(() => {
    return eventInitialValues?.type || calendarEvent?.type
  }, [eventInitialValues, calendarEvent])

  return (
    <>
      {props.calendarError && (
        <Alert color={"danger"}>{props.calendarError || t("error")}</Alert>
      )}

      {/* Calendar */}
      <OverlayBlockLoading isLoading={props.calendarLoading}>
        <EventsCalendar
          events={props.calendarEvents}
          onDatesSet={props.onCalendarDatesSet}
          onEventCreate={onEventCreate}
          onEventView={onEventView}
          onEventDidMount={info =>
            eventsCalendarHelper.calendar.onCalendarEventUpdateElement(
              info,
              props.eventColors
            )
          }
          renderEventContent={props.renderCalendarEventContent}
          eventOrder={props.calendarEventOrder}
          eventTypeFilterProps={props.eventTypeFilterProps}
          subBarActionBar={
            <CalendarActionBar
              onEventSelect={data => onEventCreate({ eventData: data })}
            />
          }
          renderCalendarDayHeaderContent={props.renderCalendarDayHeaderContent}
          eventsToolbar={calendarConfig.eventsToolbar}
        />
      </OverlayBlockLoading>

      {/* Create/edit form */}
      <EventFormDrawer
        isEditMode={eventIsEdit}
        eventId={calendarEvent?.id}
        eventType={eventType}
        visible={eventFormDrawerVisible}
        onClose={closeEventFormDrawer}
        onDestroy={onEventFormDrawerDestroy}
        entityScreen={props.entity}
        entityId={props.entityData?.id}
        editEventData={props.editEventData}
      >
        <EventForm
          isEditMode={eventIsEdit}
          eventType={eventType}
          initialValues={eventInitialValues}
          error={props.submitError}
          loading={props.submitLoading}
          onSubmit={onSubmit}
          onCancel={closeEventFormDrawer}
          onEventUpdate={props.onEventUpdate}
          entity={props.entity}
          entityId={props.entityData?.id}
        />
      </EventFormDrawer>

      {/* Preview event */}
      <EventPreviewDrawer
        event={previewEvent}
        visible={eventPreviewDrawerVisible}
        onClose={closeEventPreviewDrawer}
        onDestroy={onEventPreviewDrawerDestroy}
        entityScreen={props.entity}
        entityId={props.entityData?.id}
        previewEventData={props.previewEventData}
      >
        <EventPreviewContainer
          event={props.previewEventData}
          onEdit={onEventEdit}
          onSendEmail={onEventSendEmail}
          onRemoveSuccess={onRemoveEventSuccess}
          entityScreen={props.entity}
          entityId={props.entityData?.id}
          onEventCloseSuccess={onEventCloseSuccess}
          onEventReopenSuccess={onEventReopenSuccess}
          onEventDoneSuccess={props.onEventDoneSuccess}
        />
      </EventPreviewDrawer>

      {/* Event send email */}
      <EventSendEmailDrawer
        event={sendEmailEvent}
        visible={eventSendEmailDrawerVisible}
        onClose={hideEventSendEmailDrawerVisible}
        onDestroy={resetSendEmailEvent}
      >
        <EventSendEmail
          event={sendEmailEvent}
          onSuccess={onEventSendEmailSuccess}
        />
      </EventSendEmailDrawer>

      {/* Confirm popup when close create event form */}
      <ConfirmPopup
        ref={confirmCloseModalRef}
        onConfirm={confirmCloseEventFormDrawer}
      >
        <p className={"font-size-22"}>{t("sure_close_event_form")}?</p>
      </ConfirmPopup>
    </>
  )
}

GeneralEventsCalendarControl.propTypes = {
  calendarLoading: PropTypes.bool,
  calendarError: PropTypes.any,
  calendarEvents: PropTypes.array,
  submitLoading: PropTypes.bool,
  submitError: PropTypes.any,
  onSubmit: PropTypes.func,
  onEventRemoveSuccess: PropTypes.func,
  onEventReopenSuccess: PropTypes.func,
  onEventDoneSuccess: PropTypes.func,
  onFetchEvents: PropTypes.func,
  onSubmitReset: PropTypes.func,
  onEventUpdate: PropTypes.func,
  editEventData: PropTypes.object,
  previewEventData: PropTypes.object,
  onCalendarDatesSet: PropTypes.func,
  entity: PropTypes.string,
  entityData: PropTypes.object,
  renderCalendarEventContent: PropTypes.func,
  calendarEventOrder: PropTypes.any,
  eventTypeFilterProps: PropTypes.object,
  renderCalendarDayHeaderContent: PropTypes.func,
  eventColors: PropTypes.object,
}
