import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { ModelRow } from "./ModelRow"
import { ContractRow } from "./ContractRow"
import { eventsCalendarHelper } from "helpers"
import { GENERAL_CALENDAR_ENTITY } from "consts"

const { MODEL: ENTITY_MODEL } = GENERAL_CALENDAR_ENTITY

export const Models = ({ data, contractProps, ...props }) => {
  const { t } = useTranslation()

  const isJobStateEventType = useCallback(
    model => {
      return eventsCalendarHelper.calendar.isEventTypeJobState(
        props.event,
        ENTITY_MODEL,
        model?.id
      )
    },
    [props.event]
  )

  return (
    <div className="event-preview-models">
      {!!data.length ? (
        <div className="table-responsive">
          <table className="table table-nowrap">
            <tbody>
              {data.map((item, index) => {
                const { contract } = item
                return (
                  <React.Fragment key={index}>
                    <ModelRow
                      data={item}
                      contractProps={contractProps}
                      isJobStateEventType={isJobStateEventType}
                    />

                    {contract && (
                      <ContractRow
                        data={item}
                        contractProps={contractProps}
                        event={props.event}
                      />
                    )}
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </div>
      ) : (
        t("no_models")
      )}
    </div>
  )
}

Models.propTypes = {
  data: PropTypes.array,
  contractProps: PropTypes.object,
  event: PropTypes.object,
}
