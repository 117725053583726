import { takeLatest, fork, put, all, call } from "redux-saga/effects"
import { analyticsGraphsList } from "./slice"
import { API } from "api"
import i18n from "i18next"

export function* getList() {
  try {
    const response = yield call(API.analytics.getGraphs)

    const { data } = response

    yield put(analyticsGraphsList.getListCompleted(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(analyticsGraphsList.getListRejected(message))
  }
}

export function* watchGetList() {
  yield takeLatest(analyticsGraphsList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
