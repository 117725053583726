import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

export const Unread = () => {
  const { t } = useTranslation()

  return (
    <div className="chat-day-title">
      <div className="title rounded-pill">{t("chat.unread_messages")}</div>
    </div>
  )
}

Unread.propTypes = {}
