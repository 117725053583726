import { clientHelper, eventsCalendarHelper } from "helpers"

export const mapCompanyData = companyData => {
  return eventsCalendarHelper.contract.mapCompanyData(companyData)
}

export const mapClientData = client => {
  let result = {
    client_information: "",
    city: "",
  }

  const mapInfo = () => {
    const newLine = `\n`

    let value = `${client.name}`
    const country = client?.country?.name
    const otherInformation = client?.other_information
    const billingAddress = clientHelper.common.getBillingAddress(client)

    if (billingAddress) {
      const { street, town, zip } = billingAddress
      value += `${newLine}${street}`
      value += `${newLine}${town}`

      if (zip) {
        value += ` - ${zip}`
      }
    }

    if (country) {
      value += `${newLine}${country}`
    }

    if (client?.vat) {
      value += `${newLine}P.IVA / VAT .: ${client?.vat}`
    }

    if (billingAddress?.email) {
      value += `${newLine}Pec ${billingAddress?.email}`
    }

    if (otherInformation) {
      value += `${newLine}${otherInformation}`
    }

    return value
  }

  result.client_information = mapInfo()

  return result
}
