export const MODEL_BOOK_PHOTOS_PICKER_SET_VALUE =
  "MODEL_BOOK_PHOTOS_PICKER_SET_VALUE"
export const MODEL_BOOK_PHOTOS_PICKER_CLEAN_VALUE =
  "MODEL_BOOK_PHOTOS_PICKER_CLEAN_VALUE"
export const MODEL_BOOK_PHOTOS_PICKER_ADD_ITEMS =
  "MODEL_BOOK_PHOTOS_PICKER_ADD_ITEMS"
export const MODEL_BOOK_PHOTOS_PICKER_TOGGLE_ITEM =
  "MODEL_BOOK_PHOTOS_PICKER_TOGGLE_ITEM"
export const MODEL_BOOK_PHOTOS_PICKER_REMOVE_ITEM =
  "MODEL_BOOK_PHOTOS_PICKER_REMOVE_ITEM"
export const MODEL_BOOK_PHOTOS_PICKER_SET_COVER =
  "MODEL_BOOK_PHOTOS_PICKER_SET_COVER"
export const MODEL_BOOK_PHOTOS_PICKER_CHANGE_ORDER =
  "MODEL_BOOK_PHOTOS_PICKER_CHANGE_ORDER"
