import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Button } from "../../../Buttons"
import { Modal } from "../../../Modal"
import { ExpensesTable } from "../ExpensesTable"
import { ValidationsUtils } from "../../../../utils"
import { ExpenseForm } from "../ExpenseForm"
import { OverlayBlockLoading } from "../../../OverlayLoading"

export const ExpensesContainer = props => {
  const { t } = useTranslation()

  const modalRef = useRef(null)

  const [isEdit, setIsEdit] = useState(false)
  const [expense, setExpense] = useState(null)

  const onSubmitSuccess = onSubmitProps => () => {
    const { onReset } = onSubmitProps
    closeModal()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const onSubmit = (values, onSubmitProps) => {
    const payload = {
      onSuccess: onSubmitSuccess(onSubmitProps),
      onError: onSubmitError(onSubmitProps),
    }

    if (!isEdit) {
      props.onAdd(values, payload)
    } else {
      props.onEdit(values, payload)
    }
  }

  const handleAdd = e => {
    e.preventDefault()
    isEdit && setIsEdit(false)
    if (modalRef.current) {
      modalRef.current.show()
    }
  }

  const handleEdit = item => {
    setIsEdit(true)
    if (modalRef.current) {
      modalRef.current.show()
    }
    setExpense({ ...item })
  }

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.hide()
    }
  }

  const onModalClosed = () => {
    setExpense(null)
  }

  return (
    <div className="expenses-container">
      <Button
        title={props.addBtnText || "btn.add-expense"}
        onClick={handleAdd}
      />

      {props.value && props.value.length > 0 ? (
        <OverlayBlockLoading isLoading={props.generalLoading}>
          <ExpensesTable
            data={props.value}
            onEdit={handleEdit}
            onRemove={props.onRemove}
            currency={props.currency}
          />
        </OverlayBlockLoading>
      ) : (
        <div className={"mt-3"}>{t("no_expenses")}</div>
      )}

      <Modal
        ref={modalRef}
        title={t("expense")}
        strapModalProp={{
          centered: true,
          onClosed: onModalClosed,
        }}
      >
        <ExpenseForm
          isEdit={isEdit}
          loading={props.loading}
          error={props.error}
          onSubmit={onSubmit}
          onCancel={closeModal}
          expenseTypeOptions={props.expenseTypeOptions}
          initialValues={{ ...expense }}
          currency={props.currency}
          onFormDestroy={props.onFormDestroy}
        />
      </Modal>
    </div>
  )
}

ExpensesContainer.propTypes = {
  value: PropTypes.any,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.any,
  className: PropTypes.string,
  currency: PropTypes.string,
  generalLoading: PropTypes.bool,
  onFormDestroy: PropTypes.func,
  expenseTypeOptions: PropTypes.array,
  addBtnText: PropTypes.string,
}
