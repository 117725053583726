import {
    MODEL_FETCH_BOOKS,
    MODEL_FETCH_BOOKS_SUCCESS,
    MODEL_FETCH_BOOKS_ERROR,
    MODEL_FETCH_BOOKS_CLEAN_STATE,

    MODEL_BOOKS_SET_PAGE,
} from './actionTypes'

export const modelFetchBooks = (id, params) => {
    return {
        type: MODEL_FETCH_BOOKS,
        payload: { id, params },
    }
};

export const modelFetchBooksSuccess = (data) => {
    return {
        type: MODEL_FETCH_BOOKS_SUCCESS,
        payload: data,
    }
};

export const modelFetchBooksError = (data) => {
    return {
        type: MODEL_FETCH_BOOKS_ERROR,
        payload: data,
    }
};

export const modelFetchBooksCleanState = () => {
    return { type: MODEL_FETCH_BOOKS_CLEAN_STATE };
};

export const modelBooksSetPage = (data) => {
    return {
        type: MODEL_BOOKS_SET_PAGE,
        payload: data,
    }
};