import { all } from "redux-saga/effects"

import paymentLinkSaga from "./paymentLink/saga"
import cancelSaga from "./cancel/saga"
import resumeSaga from "./resume/saga"
import swapSaga from "./swap/saga"

function* subscriptionSaga() {
  yield all([paymentLinkSaga(), cancelSaga(), resumeSaga(), swapSaga()])
}

export default subscriptionSaga
