import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { CalendarURL } from "./CalendarURL"
import { FileContent } from "./FileContent"

const TYPE = {
  GOOGLE: "google",
  FILE: "file",
}

const { GOOGLE, FILE } = TYPE

export const Content = ({ data, type }) => {
  const { t } = useTranslation()

  if (type === GOOGLE) {
    return <CalendarURL data={data} />
  } else if (type === FILE) {
    return <FileContent data={data} />
  }

  return <></>
}

Content.propTypes = {
  data: PropTypes.object,
  type: PropTypes.oneOf([GOOGLE, FILE]),
}
