import * as Yup from 'yup';

import { MODEL_AGENCY_AS_TYPE_MOTHER } from "../../../../../../consts"
import { ValidationsUtils } from "../../../../../../utils"

export const validationSchema = (PERCENT_VALUES) => (
    Yup.object().shape({
        agency_id: Yup.object().nullable().required('field.error.required'),
        as_type: Yup.string().nullable().required('field.error.required'),
        percentage_mother: Yup.number()
            .min(PERCENT_VALUES.min, 'field.error.percent.between')
            .max(PERCENT_VALUES.max, 'field.error.percent.between')
            .nullable(true)
            .transform((v, o) => o === '' ? null : v)
            .when("as_type", {
                is: MODEL_AGENCY_AS_TYPE_MOTHER,
                then: Yup.number().transform((v, o) => o === '' ? undefined : v).required('field.error.required'),
            }),
        percentage_type: Yup.string()
            .when("as_type", {
                is: MODEL_AGENCY_AS_TYPE_MOTHER,
                then: Yup.string().nullable().required('field.error.required'),
            }),
        date_in: Yup.date().nullable().required('field.error.required'),
        date_out: Yup.date().nullable()
            .test({
                name: 'dateEqualOrGrater',
                exclusive: false,
                params: { },
                message: 'field.error.date.after-or-equal',
                test: function (value) {
                    return ValidationsUtils.dateEqualOrAfter(value, this.parent.date_in)
                },
            }),
    })
)