import {
  MODEL_PANEL_GENERAL_LOGISTICS_LIST_REMOVE_ITEM,
  MODEL_PANEL_GENERAL_LOGISTICS_LIST_REMOVE_ITEM_SUCCESS,
  MODEL_PANEL_GENERAL_LOGISTICS_LIST_REMOVE_ITEM_ERROR,
  MODEL_PANEL_GENERAL_LOGISTICS_LIST_REMOVE_ITEM_CLEAN_STATE,
} from "./actionTypes"

export const modelPanelGeneralLogisticsListRemoveItem = (id, onSuccess) => {
  return {
    type: MODEL_PANEL_GENERAL_LOGISTICS_LIST_REMOVE_ITEM,
    payload: { id, onSuccess },
  }
}

export const modelPanelGeneralLogisticsListRemoveItemSuccess = id => {
  return {
    type: MODEL_PANEL_GENERAL_LOGISTICS_LIST_REMOVE_ITEM_SUCCESS,
    payload: { id },
  }
}

export const modelPanelGeneralLogisticsListRemoveItemError = id => {
  return {
    type: MODEL_PANEL_GENERAL_LOGISTICS_LIST_REMOVE_ITEM_ERROR,
    payload: { id },
  }
}

export const modelPanelGeneralLogisticsListRemoveItemCleanState = () => {
  return { type: MODEL_PANEL_GENERAL_LOGISTICS_LIST_REMOVE_ITEM_CLEAN_STATE }
}
