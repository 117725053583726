import React from "react"

import { useFetchData } from "hooks"
import { API } from "api"

export const usePaymentMethodInit = () => {
  const { data, fetchData, loading } = useFetchData()

  const init = async ({ callback }) => {
    const request = API.billing.getPaymentMethods()
    const data = await fetchData({
      request,
    })

    if (data?.data && !!data?.data.length) {
      const defaultItem = data?.data.find(item => {
        return item?.default
      })

      callback(defaultItem)
    }
  }

  return {
    init,
    loading,
    data: data?.data || [],
  }
}
