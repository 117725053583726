import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
}

const billingPaymentMethodListSlice = createSlice({
  name: "billingPaymentMethodList",
  initialState,
  reducers: {
    getList(state) {
      state.listLoading = true
      state.listError = null
    },
    getListSuccess(state, action) {
      state.listLoading = false
      state.list = action.payload.data
    },
    getListError(state, action) {
      state.listLoading = false
      state.listError = action.payload
    },
    setDefault(state, action) {
      state.list = state.list.map(item => ({
        ...item,
        default: item.id === action.payload.id,
      }))
    },
    removeItem(state, action) {
      state.list = state.list.filter(item => item.id !== action.payload.id)
    },
    cleanState() {
      return initialState
    },
  },
})

/**
 * @namespace
 * @property {function} getList
 * @property {function} getListSuccess
 * @property {function} getListError
 * @property {function} setDefault
 * @property {function} removeItem
 * @property {function} cleanState
 */

export const billingPaymentMethodList = billingPaymentMethodListSlice.actions

export default billingPaymentMethodListSlice.reducer
