import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { FormatUtils, ValidationsUtils } from "../../../../../../utils"
import { Alert, Col, Row } from "reactstrap"
import {
  Button,
  ControllerHF,
  EmailsFieldArrayHF,
  InputField,
  PhonesCountriesFieldArrayHF,
  SocialNetworkFieldArrayHF,
} from "../../../../../../components"
import { commonHelper } from "../../../../../../helpers"
import { FILE_MAX_SIZE } from "../../../../../../consts/common/maxFileSize"
import { FilesUploader } from "./FilesUploader"

export const ContactsForm = props => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
    trigger,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: "",
      address: "",
      note: null,
      files: [],
      social_networks: commonHelper.socialNetwork.formInitialValues(
        [],
        ["whatsapp", "telegram", "viber", "instagram"]
      ),
      ...props.initialValues,
      phones: props.initialValues.phones.map(item => ({
        ...item,
        value: FormatUtils.formatPhone(item.value),
      })),
    },
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required("field.error.required"),
        phones: Yup.array().of(
          Yup.object().shape({
            id: Yup.number(),
            value: Yup.string()
              .required("field.error.required")
              .test(
                "phone",
                "field.error.invalid",
                ValidationsUtils.phoneFormat
              ),
            country: Yup.object().nullable(),
          })
        ),
        emails: Yup.array().of(
          Yup.object().shape({
            id: Yup.number(),
            value: Yup.string()
              .email("field.error.invalid")
              .required("field.error.required"),
          })
        ),
        files: Yup.array().of(
          Yup.object().shape({
            file: Yup.mixed().test(
              "maxSize",
              "field.error.file-size.max",
              value => ValidationsUtils.fileMaxSize(value, FILE_MAX_SIZE)
            ),
          })
        ),
        social_networks: Yup.array().of(
          Yup.object().shape({
            name: Yup.string(),
            value: Yup.string().test({
              name: "socialNetwork",
              exclusive: false,
              params: {},
              message: "field.error.invalid",
              test: function (value) {
                return ValidationsUtils.socialNetwork(value, this.parent.name)
              },
            }),
          })
        ),
      })
    ),
    mode: "onBlur",
  })
  const onReset = () => {
    setValue("files", [])
    reset()
  }
  const onSubmit = values => {
    let params = {
      ...values,
      files: values.files.map(item => item.id),
    }
    params.phones = values.phones.map(item => {
      let result = {}

      if (item.id) {
        result.id = item.id
      }

      return {
        ...result,
        value: item.value.replace(/[^0-9]/g, ""),
        country_id: item.country?.id,
      }
    })

    props.onSubmit(params, { onReset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }

  const [filesProcessing, setFilesProcessing] = useState([])

  const addFileToProcessing = fieldId => {
    setFilesProcessing(prevState => [...prevState, fieldId])
  }

  const removeFileFromProcessing = fieldId => {
    setFilesProcessing(prevState => prevState.filter(item => item !== fieldId))
  }

  const isDisabled = useMemo(() => {
    return props.loading || !!filesProcessing.length
  }, [props.loading, filesProcessing])

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Col>
          <Row>
            <Row>
              <Col md={4}>
                <ControllerHF
                  name={"name"}
                  control={control}
                  component={InputField}
                  id={"name"}
                  label={t("field.label.name")}
                  placeholder={t("field.placeholder.name")}
                />
              </Col>
            </Row>
            <hr className="drawer-separator drawer-separator_form" />
            <Row>
              <PhonesCountriesFieldArrayHF
                name={"phones"}
                control={control}
                limit={5}
                countryOptions={props.countryOptions}
                clearErrors={clearErrors}
                meta={{ error: errors.phones }}
              />
            </Row>
            <hr className="drawer-separator drawer-separator_form" />
            <Row>
              <EmailsFieldArrayHF
                name={"emails"}
                control={control}
                limit={5}
                clearErrors={clearErrors}
                meta={{ error: errors.emails }}
              />
            </Row>
            <hr className="drawer-separator drawer-separator_form" />
            <Row>
              <Col md={4}>
                <ControllerHF
                  name={"address"}
                  control={control}
                  component={InputField}
                  id={"address"}
                  label={t("address")}
                  placeholder={t("address")}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <ControllerHF
                  name={"note"}
                  control={control}
                  component={InputField}
                  id={"note"}
                  label={t("note")}
                  placeholder={t("note")}
                  type={"textarea"}
                />
              </Col>
            </Row>
          </Row>
          <Row>
            <SocialNetworkFieldArrayHF
              name={"social_networks"}
              control={control}
              inputName={"value"}
            />
          </Row>
          <Row>
            <Col md={12}>
              <FilesUploader
                control={control}
                processing={filesProcessing}
                addFileToProcessing={addFileToProcessing}
                removeFileFromProcessing={removeFileFromProcessing}
                trigger={trigger}
                setError={setError}
                fileMaxSize={FILE_MAX_SIZE}
                contactId={props.contactId}
              />
            </Col>
          </Row>
        </Col>
        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={isDisabled}
              loading={props.loading}
            />

            <Button title={"cancel"} color="light" outline onClick={onCancel} />
          </div>
        </div>
      </form>
    </>
  )
}

ContactsForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  countryOptions: PropTypes.array,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  contactId: PropTypes.number,
}
