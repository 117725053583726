import { all, call, fork, put, takeEvery } from "redux-saga/effects"

import i18n from "../../../i18n"
import { API } from "api"
import { staticPage } from "./slice"

export function* getData({ payload: { alias } }) {
  try {
    const response = yield call(API.common.getStaticPageByAlias, alias)

    const { data } = response

    yield put(staticPage.getDataSuccess(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(staticPage.getDataError(message))
  }
}

export function* watchGetData() {
  yield takeEvery(staticPage.getData, getData)
}

function* dataSaga() {
  yield all([fork(watchGetData)])
}

export default dataSaga
