import React from "react"
import PropTypes from "prop-types"

import { Closed } from "./Closed"
import { Check } from "./Check"
import { Cake } from "./Cake"
import { Done } from "./Done"

export const EventCalendarIcon = ({ name, ...props }) => {
  switch (name) {
    case "closed":
      return <Closed {...props} />
    case "check":
      return <Check {...props} />
    case "cake":
      return <Cake {...props} />
    case "done":
      return <Done {...props} />
    default:
      return null
  }
}

EventCalendarIcon.propTypes = {
  name: PropTypes.string,
}
