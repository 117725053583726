import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const calendarEventModelContractEditSubmitSlice = createSlice({
  name: "calendarEventModelContractEditSubmit",
  initialState,
  reducers: {
    submit(state) {
      state.loading = true
      state.error = null
    },
    submitSuccess(state) {
      state.loading = false
    },
    submitError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})

/**
 * @namespace
 * @property {function} submit
 * @property {function} submitSuccess
 * @property {function} submitError
 * @property {function} cleanState
 */

export const calendarEventModelContractEditSubmit =
  calendarEventModelContractEditSubmitSlice.actions

export default calendarEventModelContractEditSubmitSlice.reducer
