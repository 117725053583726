import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { Alert } from "reactstrap"
import { useTranslation } from "react-i18next"

import { DataShower } from "components"
import { importData } from "store/actions"
import { importHelper } from "helpers"

export const ImportEntityDataLoader = ({ type, children }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { data, loading, error } = useSelector(state => state.import.data)

  const fetchData = () => {
    dispatch(importData.getData({}))
  }

  useEffect(() => {
    fetchData()
    return () => {
      dispatch(importData.cleanState())
    }
  }, [])

  return (
    <DataShower
      isLoading={loading}
      isFailed={!!error}
      error={error}
      isFetched={!!data}
    >
      {data?.active && data?.type !== type ? (
        <Alert color={"secondary"}>
          {t("entity-import.warning.running", {
            entity: importHelper.entity.getEntityType(data?.type)?.label,
          })}
        </Alert>
      ) : (
        children
      )}
    </DataShower>
  )
}

ImportEntityDataLoader.propTypes = {
  type: PropTypes.string,
  children: PropTypes.any,
}
