import { takeEvery, fork, put, all, select } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { socketOnSubscriptionUpdate } from "./actions"
import {
  BILLING_SUBSCRIPTION_STATUS_ACTIVE,
  BILLING_SUBSCRIPTION_STATUS_TRIALING,
  BILLING_SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT,
} from "consts"
import { userProfileUpdateSubscription } from "../../user/actions"

export const userState = state => state.user.profile

function* onSubscriptionUpdate({ payload: { data } }) {
  const { subscription } = data

  const user = yield select(userState)

  const userSubscription = user?.data?.subscription

  let toastMessage = null

  if (subscription) {
    if (subscription?.status === BILLING_SUBSCRIPTION_STATUS_ACTIVE) {
      if (
        userSubscription === null ||
        userSubscription?.status === BILLING_SUBSCRIPTION_STATUS_TRIALING
      ) {
        // if current no sub or was trialing and pay new subscription
        toastMessage = "billing.subscription.subscribed.success"
      } else if (
        userSubscription?.status === BILLING_SUBSCRIPTION_STATUS_ACTIVE &&
        userSubscription?.tariff?.id !== subscription?.tariff?.id
      ) {
        // if swap subscription
        toastMessage = "billing.subscription.swap.success"
      }
    } else if (
      subscription?.status === BILLING_SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT &&
      (userSubscription === null ||
        userSubscription?.status === BILLING_SUBSCRIPTION_STATUS_TRIALING)
    ) {
      // if current no sub or trialing and pay new subscription with 3d-secure payment method
      toastMessage = "billing.subscription.subscribed.success"
    }
  }

  if (toastMessage) {
    toastr.success(i18n.t(toastMessage))
  }

  // update user subscription
  yield put(userProfileUpdateSubscription(subscription))
}

export function* watchOnSubscriptionUpdate() {
  yield takeEvery(socketOnSubscriptionUpdate, onSubscriptionUpdate)
}

function* subscriptionSaga() {
  yield all([fork(watchOnSubscriptionUpdate)])
}

export default subscriptionSaga
