import { all } from "redux-saga/effects"

import graphsSaga from "./graphs/sagas"
import permissionsSaga from "./permissions/sagas"

function* analyticsSaga() {
  yield all([graphsSaga(), permissionsSaga()])
}

export default analyticsSaga
