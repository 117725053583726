import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { commonHelper } from "helpers"

const currencySymbol = commonHelper.currency.getGeneralCurrency()?.symbol

export const PaymentInfo = props => {
  const { t } = useTranslation()

  const { date, description, value } = props.data

  const items = [
    {
      label: t("date"),
      value: date ? moment(date).format("DD/MM/YYYY") : "-",
    },
    {
      label: t("description"),
      value: description,
    },
    {
      label: t("amount"),
      value: `${value} ${currencySymbol}`,
    },
  ]

  return (
    <div className="table-responsive">
      <table className="table">
        <tbody>
          {items.map(({ label, value, ...item }, index) => (
            <tr key={index}>
              <td>
                <span className={"fw-medium text-nowrap"}>{label}</span>
              </td>

              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

PaymentInfo.propTypes = {
  data: PropTypes.object,
}
