import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "../../../../i18n"

import { agencyUpdateAddress } from "./slice"
import { API } from "../../../../api"

function* updateItem({ payload: { id, params, onSuccess, onError } }) {
    try {
        const response = yield call(API.agency.updateAgencyAddress, id, params)

        const { data } = response

        if (onSuccess) {
            onSuccess()
        }
        toastr.success(i18n.t("edit.success"))

        yield put(agencyUpdateAddress.updateCompleted())
    } catch (error) {
        const errors = error.response?.data?.errors || {}
        const message = error.response?.data?.message || i18n.t("error")

        yield put(
            agencyUpdateAddress.updateRejected({
                errors,
                message,
            })
        )
        if (onError) {
            onError(errors)
        }
    }
}

export function* watchUpdateItem() {
    yield takeEvery(agencyUpdateAddress.update, updateItem)
}

function* updateSaga() {
    yield all([fork(watchUpdateItem)])
}

export default updateSaga
