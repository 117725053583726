import { all } from "redux-saga/effects"

import panelSaga from "./panel/sagas"
import groupSaga from "./groups/sagas"

function* modelsSaga() {
  yield all([panelSaga(), groupSaga()])
}

export default modelsSaga
