import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useRouteMatch, generatePath } from "react-router-dom"

import { PanelMenu } from "components"

export const Nav = () => {
  const { t } = useTranslation()

  let { path } = useRouteMatch()

  const getPath = route => generatePath(`${path}/${route}`)

  const menu = [
    {
      key: "calendar",
      route: getPath("calendar"),
      title: t("calendar"),
    },
    {
      key: "events",
      route: getPath("events"),
      title: t("events"),
    },
  ]

  return <PanelMenu items={menu} />
}

Nav.propTypes = {}
