import React, { useMemo } from 'react';
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next';
import classNames from "classnames";

export const Nav = (props) => {
    const { t } = useTranslation();

    const isFirstPage = useMemo(() => {
        return props.page === 1;
    }, [props.page]);

    const isLastPage = useMemo(() => {
        return props.page === props.count;
    }, [props.page]);

    const onPrev = () => {
        if (isFirstPage) {
            return;
        }

        props.onChange(props.page - 1);
    }

    const onNext = () => {
        if (isLastPage) {
            return;
        }

        props.onChange(props.page + 1);
    }

    const renderPrevButton = () => (
        <button onClick={onPrev} disabled={isFirstPage} className={'btn btn-sm ps-0'}>
            <i className={'bx bxs-left-arrow text-primary-green'} />
        </button>
    )

    const renderNextButton = () => (
        <button onClick={onNext} disabled={isLastPage} className={'btn btn-sm pe-0'}>
            <i className={'bx bxs-right-arrow text-primary-green'} />
        </button>
    )

    const renderTitle = () => {
        let result = '';
        if (props.countPrefix) {
            result = `${props.countPrefix} `;
        }
        result = result + `${props.page} ${t('of-suffix')} ${props.count}`;
        return result
    }

    return (
        <div className={'package-data-slider-nav'}>
            {renderPrevButton()}

            <div className={'package-data-slider-nav__title text-primary-green text-truncate'}>
                <span className={''}>
                    {renderTitle()}
                </span>

            </div>

            {renderNextButton()}
        </div>
    );
};

Nav.propTypes = {
    page: PropTypes.number,
    onChange: PropTypes.func,
    count: PropTypes.number,
    countPrefix: PropTypes.string,
}