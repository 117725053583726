import { createAction } from "@reduxjs/toolkit"

const SOCKET_ON_WEBSITE_CREATE = "socket/onWebsiteCreate"

export const socketOnWebsiteCreate = createAction(
  SOCKET_ON_WEBSITE_CREATE,
  function prepare(data) {
    return {
      payload: {
        data,
      },
    }
  }
)
