import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Drawer } from "components"
import { Container } from "reactstrap"
import { EditInvoiceDetailsForm } from "./EditInvoiceDetailsForm"
import { ValidationsUtils } from "utils"
import { useTranslation } from "react-i18next"
import { agencyUpdateProfile } from "store/actions"

const EditInvoiceDetailsComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const handleCloseDrawer = () => {
    props.onClose()
  }
  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    props.onClose()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      agencyUpdateProfile.update({
        id: props.agency.id,
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  const onDestroy = () => {
    dispatch(agencyUpdateProfile.cleanState())
  }
  return (
    <>
      <Drawer
        title={t("invoice-details")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <Container fluid>
          <EditInvoiceDetailsForm
            onSubmit={handleSubmit}
            onCancel={handleCloseDrawer}
            initialValues={{
              bank_name: props.agency.bank_name,
              bank_account_number: props.agency.bank_account_number,
              swift_code: props.agency.swift_code,
              vat: props.agency.vat,
              other_information: props.agency.other_information,
            }}
            loading={props.loading}
            error={props.error}
          />
        </Container>
      </Drawer>
    </>
  )
}

EditInvoiceDetailsComponent.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,

  loading: PropTypes.bool,
  error: PropTypes.object,
  agency: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.agency.profile.data
  const { loading, error } = state.agency.profile.update
  return {
    agency: data,
    loading,
    error,
  }
}

export const EditInvoiceDetails = connect(mapStateToProps)(
  EditInvoiceDetailsComponent
)
