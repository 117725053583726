import React from 'react';
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap";
import { connect } from 'react-redux';

import { MegaTagTitle } from "../../components";
import { VideosContainer } from './components/VideoComponents';

const VideoComponent = (props) => {
    return (
        <div className={'model-panel-video'}>
            <MegaTagTitle title={'title.model.video'} />

            <Row>
                <Col>
                    <VideosContainer />
                </Col>
            </Row>

        </div>
    );
};

VideoComponent.propTypes = {
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const Video = connect(mapStateToProps, mapDispatchToProps)(VideoComponent);