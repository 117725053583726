import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

import { ComboboxFormField, SelectorCombobox } from "components"
import { RecipientOptionLabel } from "./RecipientOptionLabel"
import { useFetchData } from "hooks"
import { API } from "api"
import i18n from "i18n"

const CATEGORY_AGENCY = "agency"
const CATEGORY_CLIENT = "client"

const categoryOptions = [
  { label: i18n.t("agencies"), value: CATEGORY_AGENCY },
  { label: i18n.t("clients"), value: CATEGORY_CLIENT },
]

export const RecipientSelect = ({ value, disabled, className, ...props }) => {
  const { t } = useTranslation()

  const [category, setCategory] = useState(CATEGORY_AGENCY)

  const onSelect = option => {
    props.onSelect(option)
  }

  const { fetchData, loading, data, clean } = useFetchData()

  const onFetch = async searchValue => {
    const params = {
      category: category,
      // keywords: searchValue,
    }
    const request = API.common.getEmails(params)
    await fetchData({
      request,
    })
  }

  useEffect(() => {
    clean()
  }, [category])

  return (
    <Row>
      <Col md={6}>
        <SelectorCombobox
          value={value}
          options={data?.data}
          onSelect={onSelect}
          onFetchOptions={onFetch}
          id={"recipient-select"}
          label={t("search_on_contacts")}
          placeholder={t("search_on_contacts")}
          disabled={disabled}
          loading={loading}
          getOptionLabel={option =>
            `${option.value} ${option?.emailable?.owner?.name || ""}`
          }
          getOptionValue={option => `${option.value}`}
          formatOptionLabel={option => <RecipientOptionLabel option={option} />}
        />
      </Col>
      <Col md={6}>
        <div className={"recipients-list-control-wrapper"}>
          <ComboboxFormField
            wrapClass={"w-100 mb-3"}
            value={category}
            onChange={value => setCategory(value)}
            id={"category"}
            label={""}
            placeholder={t("reminder-date")}
            options={categoryOptions}
            selectProps={{
              isSearchable: false,
            }}
            normalize={option => (option ? option.value : null)}
            isDetermineValue
          />
        </div>
      </Col>
    </Row>
  )
}

RecipientSelect.propTypes = {
  value: PropTypes.any,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}
