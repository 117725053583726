import React, { useEffect } from "react"

import { usePanelLayoutIntro } from "hooks"

export const usePanelLayoutPageIntroEffect = ({ options = {} }) => {
  const { setOptions, resetConfig } = usePanelLayoutIntro()

  useEffect(() => {
    setOptions(options)

    return () => {
      resetConfig()
    }
  }, [])

  return null
}
