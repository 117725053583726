import { all } from "redux-saga/effects"

import listSaga from "./list/saga"
import graphsDataSaga from "./graphsDataSaga/saga"
import tablesDataListSaga from "./tablesDataList/saga"

function* graphsSaga() {
  yield all([listSaga(), graphsDataSaga(), tablesDataListSaga()])
}

export default graphsSaga
