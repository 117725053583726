import { all } from "redux-saga/effects"

import editSaga from "./edit/saga"
import updateInstagramCropSaga from "./updateInstagramCrop/saga"

function* editPhotoSaga() {
  yield all([editSaga(), updateInstagramCropSaga()])
}

export default editPhotoSaga
