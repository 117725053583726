import { combineReducers } from "redux"

import panel from "./panel/reducers"
import groups from "./groups/reducers"

const modelsReducer = combineReducers({
  panel,
  groups,
})

export default modelsReducer
