import axios from "axios"

import { RequestUtils } from "../../utils"

class CalendarAPI {
  // Events
  getEvents = async (params, options) => {
    return axios.get(`api/calendar/events`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
      ...options,
    })
  }

  createEvent = async params => {
    return axios.post(`api/calendar/events`, params)
  }

  getEvent = async (id, params) => {
    return axios.get(`api/calendar/events/${id}`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  updateEvent = async (id, params) => {
    return axios.put(`api/calendar/events/${id}`, params)
  }

  removeEvent = async id => {
    return axios.delete(`api/calendar/events/${id}`)
  }

  closeEvent = async id => {
    return axios.post(`api/calendar/events/${id}/close`)
  }

  reopenEvent = async id => {
    return axios.post(`api/calendar/events/${id}/reopen`)
  }
  // --- //

  // Linking models
  addModelToEvent = async (event, model) => {
    return axios.post(`api/calendar/events/${event}/models/${model}`)
  }

  deleteModelFromEvent = async (event, model) => {
    return axios.delete(`api/calendar/events/${event}/models/${model}`)
  }

  getModels = async event => {
    return axios.get(`api/calendar/events/${event}/models`)
  }

  getModel = async (event, model) => {
    return axios.get(`api/calendar/events/${event}/models/${model}`)
  }

  updateModel = async (event, model, params) => {
    return axios.put(`api/calendar/events/${event}/models/${model}`, params)
  }
  // --- //

  // Attachments
  uploadEventFile = async (id, params) => {
    let formData = new FormData()
    formData.append("file", params.file)

    return axios.post(`api/calendar/events/${id}/files`, formData)
  }

  uploadFileForNewEvent = async params => {
    let formData = new FormData()
    formData.append("file", params.file)

    return axios.post(`api/calendar/events/files`, formData)
  }

  removeEventFile = async id => {
    return axios.delete(`api/calendar/events/files/${id}`)
  }
  // --- //

  // Expense
  addEventExpense = async (id, params) => {
    return axios.post(`api/calendar/events/${id}/expenses`, params)
  }

  getModelExpenses = async (event, model) => {
    return axios.get(`api/calendar/events/${event}/models/${model}/expenses`)
  }

  addModelExpenses = async (event, model, params) => {
    return axios.post(
      `api/calendar/events/${event}/models/${model}/expenses`,
      params
    )
  }
  // --- //

  // Rates
  getModelRates = async (event, model) => {
    return axios.get(`api/calendar/events/${event}/models/${model}/rates`)
  }

  addModelRates = async (event, model, params) => {
    return axios.post(
      `api/calendar/events/${event}/models/${model}/rates`,
      params
    )
  }

  getRate = async id => {
    return axios.get(`api/calendar/events/rates/${id}`)
  }

  updateRate = async (id, params) => {
    return axios.put(`api/calendar/events/rates/${id}`, params)
  }

  removeRate = async id => {
    return axios.delete(`api/calendar/events/rates/${id}`)
  }
  // --- //

  // Model attachment
  uploadModelsAttachmentForNewEvent = async params => {
    let formData = new FormData()
    formData.append("title", params.title)
    formData.append("file", params.file)

    return axios.post(`api/calendar/events/models/files`, formData)
  }

  getEventModelAttachments = async (event, model) => {
    return axios.get(`api/calendar/events/${event}/models/${model}/files`)
  }

  uploadEventModelAttachment = async (event, model, params) => {
    let formData = new FormData()
    formData.append("title", params.title)
    formData.append("file", params.file)

    return axios.post(
      `api/calendar/events/${event}/models/${model}/files`,
      formData
    )
  }

  updateEventModelAttachment = async (id, params) => {
    return axios.put(`api/calendar/events/models/files/${id}`, params)
  }

  removeEventModelAttachment = async id => {
    return axios.delete(`api/calendar/events/models/files/${id}`)
  }
  // --- //

  // Send email
  sendEmail = async (id, params) => {
    return axios.post(`api/calendar/events/${id}/send-email`, params)
  }
  // --- //

  // Event invoice
  createInvoice = async (id, params) => {
    let formData = new FormData()

    Object.entries(params).forEach(entry => {
      const [key, value] = entry

      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          formData.append(`${key}[${i}]`, value[i])
        }
      } else {
        formData.append(key, value)
      }
    })

    return axios.post(`api/calendar/events/${id}/invoice`, formData)
  }

  getInvoice = async (id, params) => {
    return axios.get(`api/calendar/events/${id}/invoice`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  updateInvoice = async (id, params) => {
    let formData = new FormData()

    formData.append("_method", "PUT")

    Object.entries(params).forEach(entry => {
      const [key, value] = entry

      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          formData.append(`${key}[${i}]`, value[i])
        }
      } else {
        formData.append(key, value)
      }
    })

    return axios.post(`api/calendar/events/${id}/invoice`, formData)
  }

  removeInvoice = async id => {
    return axios.delete(`api/calendar/events/${id}/invoice`)
  }

  // Send email invoice
  sendEmailInvoice = async (id, params) => {
    return axios.post(`api/calendar/events/${id}/invoice/send-email`, params)
  }
  // --- //

  // Event contract
  createContract = async (id, params) => {
    let formData = new FormData()

    Object.entries(params).forEach(entry => {
      const [key, value] = entry

      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          formData.append(`${key}[${i}]`, value[i])
        }
      } else {
        formData.append(key, value)
      }
    })

    return axios.post(`api/calendar/events/${id}/contract`, formData)
  }

  getContract = async (id, params) => {
    return axios.get(`api/calendar/events/${id}/contract`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  updateContract = async (id, params) => {
    let formData = new FormData()

    formData.append("_method", "PUT")

    Object.entries(params).forEach(entry => {
      const [key, value] = entry

      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          formData.append(`${key}[${i}]`, value[i])
        }
      } else {
        formData.append(key, value)
      }
    })

    return axios.post(`api/calendar/events/${id}/contract`, formData)
  }

  removeContract = async id => {
    return axios.delete(`api/calendar/events/${id}/contract`)
  }

  // Send email contract
  sendEmailContract = async (id, params) => {
    return axios.post(`api/calendar/events/${id}/contract/send-email`, params)
  }
  // --- //

  // Event Model Contract
  createModelContract = async (event, model, params) => {
    let formData = new FormData()

    Object.entries(params).forEach(entry => {
      const [key, value] = entry

      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          formData.append(`${key}[${i}]`, value[i])
        }
      } else {
        formData.append(key, value)
      }
    })

    return axios.post(
      `api/calendar/events/${event}/models/${model}/contract`,
      formData
    )
  }

  getModelContract = async (event, model, params) => {
    return axios.get(`api/calendar/events/${event}/models/${model}/contract`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  updateModelContract = async (event, model, params) => {
    let formData = new FormData()

    formData.append("_method", "PUT")

    Object.entries(params).forEach(entry => {
      const [key, value] = entry

      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          formData.append(`${key}[${i}]`, value[i])
        }
      } else {
        formData.append(key, value)
      }
    })

    return axios.post(
      `api/calendar/events/${event}/models/${model}/contract`,
      formData
    )
  }

  removeModelContract = async (event, model) => {
    return axios.delete(`api/calendar/events/${event}/models/${model}/contract`)
  }

  // Send email contract
  sendEmailModelContract = async (event, model, params) => {
    return axios.post(
      `api/calendar/events/${event}/models/${model}/contract/send-email`,
      params
    )
  }
  // --- //

  // Pay event (pay models, client, agencies statements)
  eventPayment = async ({ id }) => {
    return axios.post(`api/calendar/events/${id}/payment`)
  }
}

export const calendar = new CalendarAPI()
