import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import placeholderIcon from "assets/images/dev/icon-photo-placeholder.svg"
import { Button, IconButton, Image } from "components"
import { FileUtils, ValidationsUtils } from "utils"
import { FILE_MAX_SIZE, IMAGE_CROP_TYPE } from "consts"
import { useTranslation } from "react-i18next"
import toastr from "toastr"
import { nanoid } from "@reduxjs/toolkit"

export const AvatarPicker = ({
  avatar,
  onChangeAvatar,
  onRemoveAvatar,
  onEditCropClick,
  className,
  crops,
}) => {
  const { t } = useTranslation()

  const [selectedPhoto, setSelectedPhoto] = useState(avatar)
  const inputRef = useRef()

  useEffect(() => {
    if (avatar !== selectedPhoto) {
      setSelectedPhoto(avatar)
    }
  }, [avatar])

  const handleImageSelected = event => {
    const file = event.target.files[0]

    const isValid = ValidationsUtils.fileMaxSize(file, FILE_MAX_SIZE)

    if (!isValid) {
      toastr.error(
        t("file.maxSize.error", {
          size: FileUtils.formatBytes(FILE_MAX_SIZE),
        })
      )
      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setSelectedPhoto(reader.result)
        onChangeAvatar({
          avatarDataURL: reader.result,
          avatarFileObject: file,
        })
      }
    }
    reader.readAsDataURL(file)

    if (inputRef.current) {
      inputRef.current.value = null
    }
  }

  const selectAvatar = event => {
    event.preventDefault()

    if (inputRef.current) {
      inputRef.current.click()
    }
  }
  const editCrop = () => {
    onEditCropClick()
  }

  const removeAvatar = () => {
    onRemoveAvatar()
  }

  return (
    <div
      className={classNames("avatar-picker", {
        [className]: className,
        "avatar-picker-controller__selected": selectedPhoto,
      })}
    >
      <div className={"d-flex"}>
        <div className={"avatar-picker__block"}>
          <div className={"avatar-picker__select-block"}>
            <div className="avatar-picker__select-wrap">
              <label htmlFor={"avatar-picker"} className={"avatar-label"}>
                <div className="avatar-picker__logo-block">
                  <Image
                    type={IMAGE_CROP_TYPE.SQUARE}
                    crops={crops}
                    alt={"logo"}
                    src={selectedPhoto || placeholderIcon}
                    key={nanoid(3)}
                  />
                </div>
              </label>
              <input
                type="file"
                id={"avatar-picker"}
                className="d-none"
                onChange={handleImageSelected}
                ref={inputRef}
                accept="image/png, image/jpeg"
              />
            </div>
          </div>
          <Button
            title={
              selectedPhoto
                ? t("model.general.change-avatar")
                : t("btn.upload-model-avatar")
            }
            onClick={selectAvatar}
          />
          {selectedPhoto && (
            <div className={"avatar-picker__actions"}>
              <IconButton name={"remove"} onClick={removeAvatar} bg />
              <IconButton name={"crop"} onClick={editCrop} bg />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

AvatarPicker.propTypes = {
  avatar: PropTypes.string,
  onChangeAvatar: PropTypes.func,
  onEditCropClick: PropTypes.func,
  onRemoveAvatar: PropTypes.func,
  className: PropTypes.string,
  crops: PropTypes.array,
}
