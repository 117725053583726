import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { FilterForm } from "./FilterForm"

export const Filters = ({
  filters,
  modelsOptions = [],
  modelsFetching = false,
  onFiltersChange,
}) => {
  const { t } = useTranslation()

  return (
    <FilterForm
      onFiltersChange={onFiltersChange}
      defaultValues={filters}
      modelsOptions={modelsOptions}
      modelsFetching={modelsFetching}
    />
  )
}

Filters.propTypes = {
  filters: PropTypes.object,
  modelsOptions: PropTypes.array,
  onFiltersChange: PropTypes.func,
  modelsFetching: PropTypes.bool,
}
