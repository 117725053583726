import { all, fork, put, select, takeEvery } from "redux-saga/effects"

import { socketOnClientStatementAlert } from "./actions"
import {
  modalNotificationsList,
  clientStatementList as clientStatementListActions,
} from "store/actions"
import { MODAL_NOTIFICATION_ALIAS } from "consts"

export const clientStatementListState = state => state.client.statement.list

function* onAlert({ payload: { data } }) {
  const { statement } = data

  yield put(
    modalNotificationsList.addNotification({
      data: data,
      alias: MODAL_NOTIFICATION_ALIAS.CLIENT_STATEMENT,
    })
  )

  // update statement if fetched
  const clientStatementList = yield select(clientStatementListState)
  if (!!clientStatementList.list.length) {
    yield put(clientStatementListActions.updateItem({ ...statement }))
  }
}

export function* watchOnAlert() {
  yield takeEvery(socketOnClientStatementAlert, onAlert)
}

function* alertSaga() {
  yield all([fork(watchOnAlert)])
}

export default alertSaga
