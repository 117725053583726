import React from "react"
import PropTypes from "prop-types"

import { AccommodationRow } from "./AccommodationRow"
import { TravelRow } from "./TravelRow"
import { DriverRow } from "./DriverRow"
import { BasicRow } from "./BasicRow"
import { MODEL_LOGISTICS_TYPE } from "consts"

export const LogisticsTableRow = ({ item }) => {
  switch (item.type) {
    case MODEL_LOGISTICS_TYPE.ACCOMMODATION:
      return <AccommodationRow item={item} />

    case MODEL_LOGISTICS_TYPE.TRAVEL:
      return <TravelRow item={item} />

    case MODEL_LOGISTICS_TYPE.DRIVER:
      return <DriverRow item={item} />

    default:
      return <BasicRow item={item} />
  }
}

LogisticsTableRow.propTypes = {
  item: PropTypes.object,
}
