import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Badge, Table } from "reactstrap"

import { InfoCard } from "components"
import classNames from "classnames"
import { WEBSITE_DOMAIN_PATH } from "consts"

export const WebsiteInfo = ({ data }) => {
  const { t } = useTranslation()

  return (
    <InfoCard title={t("information")}>
      <div className="table-responsive">
        <Table className="table mb-0 table-border-bottom">
          <tbody>
            <tr>
              <th scope="row">{t("title")}:</th>
              <td>{data.title}</td>
            </tr>

            <tr>
              <th scope="row">{t("domain")}:</th>
              <td>
                {data.domain}
                {WEBSITE_DOMAIN_PATH}
              </td>
            </tr>

            {!data?.created && (
              <tr>
                <th scope="row">{t("status")}:</th>
                <td>
                  <Badge
                    pill
                    className={classNames("font-size-12 badge-soft-warning")}
                  >
                    {t("in_progress")}
                  </Badge>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </InfoCard>
  )
}

WebsiteInfo.propTypes = {
  data: PropTypes.object,
}
