import { takeEvery, fork, put, all, call, select } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { chatGroupsRemove } from "./slice"

function* remove({ payload: { id } }) {
  try {
    const response = yield call(API.chat.deleteChat, id)

    const { data } = response

    yield put(chatGroupsRemove.removeSuccess())

    toastr.success(i18n.t("chat.remove.success"))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      chatGroupsRemove.removeError({
        message,
      })
    )
    toastr.error(message)
  }
}

export function* watchRemove() {
  yield takeEvery(chatGroupsRemove.remove, remove)
}

function* removeSaga() {
  yield all([fork(watchRemove)])
}

export default removeSaga
