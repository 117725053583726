import { combineReducers } from "redux"

import edit from "./edit/reducer"
import updateInstagramCrop from "./updateInstagramCrop/slice"

const editPhotoReducer = combineReducers({
  edit,
  updateInstagramCrop,
})

export default editPhotoReducer
