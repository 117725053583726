import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import _ from "lodash"

import i18n from "../../../../i18n"

import { API } from "../../../../api"
import { employeeRemoveRating } from "./slice"
import { employeeDetailsUpdateData } from "../../details/data/actions"

function* removeRating({ payload: { id, onSuccess } }) {
    try {
        const response = yield call(API.employee.removeRating, id)

        const { data } = response

        yield put(employeeRemoveRating.removeCompleted(id))

        if (_.has(data.data, "avg_rating")) {
            yield put(
              employeeDetailsUpdateData({ avg_rating: data?.data?.avg_rating })
            )
        }

        toastr.success(i18n.t("rating.remove.success"))

        if (onSuccess) {
            onSuccess()
        }
    } catch (error) {
        const message = error.response?.data?.message || i18n.t("error")

        yield put(employeeRemoveRating.removeRejected(id))

        toastr.error(message)
    }
}

export function* watchRemoveRating() {
    yield takeEvery(employeeRemoveRating.remove, removeRating)
}

function* removeSaga() {
    yield all([fork(watchRemoveRating)])
}

export default removeSaga