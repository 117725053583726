const path = `${process.env.PUBLIC_URL}/images/logo`

const icon = `${path}/logo-192.png`
const badge = `${path}/logo-192.png`

const options = {
  icon,
  badge,
}

export { options }
