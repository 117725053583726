import React from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"

import {
  packageDataPickerToggleBook,
  packageDataPickerRemoveBook,
  packageDataPickerToggleVideo,
  packageDataPickerRemoveVideo,
  packageDataPickerSetBooks,
} from "store/actions"
import _ from "lodash"

export const usePackageDataPicker = props => {
  const dispatch = useDispatch()
  const { books, videos } = useSelector(state => state.package.dataPicker)

  const onBooksAdd = (items = []) => {
    const list = [...books, ...items]

    const result = _.uniqBy(list, "id")

    dispatch(packageDataPickerSetBooks(result))
  }

  const onBookToggle = book => {
    const data = {
      ...book,
    }

    dispatch(packageDataPickerToggleBook(data))
  }

  const onBookRemove = book => {
    dispatch(packageDataPickerRemoveBook(book.id))
  }

  const onVideoToggle = video => {
    const data = {
      ...video,
    }

    dispatch(packageDataPickerToggleVideo(data))
  }

  const onVideoRemove = book => {
    dispatch(packageDataPickerRemoveVideo(book.id))
  }

  return {
    pickedBooks: books,
    pickedVideos: videos,
    onBookToggle,
    onBookRemove,
    onVideoToggle,
    onVideoRemove,
    onBooksAdd,
  }
}

usePackageDataPicker.propTypes = {}
