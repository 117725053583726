import { combineReducers } from "redux";

import add from './add/reducer';
import list from './list/reducers';

const ratingReducer = combineReducers({
    add,
    list,
});

export default ratingReducer;