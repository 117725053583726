import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

export const Done = ({ className, ...props }) => {
  return (
    <i
      className={classNames("bx bx-check-square", { [className]: className })}
    />
  )
}

Done.propTypes = {
  className: PropTypes.string,
}
