import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { connect } from "react-redux"
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"

import { ROLE_DIRECTOR, ROLE_EMPLOYEE } from "consts"
import { ChatUnreadBadge } from "../Badges"

const SidebarContent = props => {
  const ref = useRef()

  const config = [
    {
      title: props.t("title.calendar"),
      icon: "bx bx-calendar",
      route: "/calendar",
      access: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    },
    {
      title: props.t("title.models"),
      icon: "bxs-user-rectangle",
      route: "/models",
      access: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    },
    {
      title: props.t("title.clients"),
      icon: "bx bx-user",
      route: "/clients",
      access: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    },
    {
      title: props.t("title.agencies"),
      icon: "bxs-briefcase",
      route: "/agencies",
      access: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    },
    {
      title: props.t("title.packages"),
      icon: "bxs-folder",
      route: "/packages",
      access: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    },
    {
      title: props.t("title.chat"),
      icon: "bx-chat",
      route: "/chat",
      access: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
      badge: () => <ChatUnreadBadge />,
    },
    {
      title: props.t("title.employees"),
      icon: "bxs-contact",
      route: "/employees",
      access: [ROLE_DIRECTOR],
    },
    {
      title: props.t("title.team"),
      icon: "bxs-contact",
      route: "/team",
      access: [ROLE_EMPLOYEE],
    },
    {
      title: props.t("title.administration"),
      icon: "bx-cog",
      route: "/administration",
      access: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    },
    {
      title: props.t("title.marketing_analytics"),
      icon: "bx bx-stats",
      route: "/analytics/overall",
      access: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    },
    {
      title: props.t("title.website"),
      icon: "bx bx-world",
      route: "/website",
      access: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    },
    {
      title: props.t("title.social-media-calendar"),
      icon: "bx bxs-network-chart",
      route: "/social-media-calendar",
      access: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    },
    // {
    //   title: props.t('title.my_list'),
    //   icon: 'bxs-city',
    //   route: '#',
    //   access: ['client', 'consultant', 'manager'],
    //   submenu: [{
    //     title: props.t('title.overview'),
    //     route: '/properties',
    //     access: ['client', 'consultant', 'manager'],
    //   },
    //     {
    //       title: props.t('title.invite_propositions'),
    //       route: '/properties/invites',
    //       access: ['client'],
    //       badge: () => <InviteBadge />,
    //     },],
    // },
  ]

  useEffect(() => {
    const pathName = props.location.pathname

    let ul = document.getElementById("side-menu")
    let items = ul.getElementsByTagName("a")

    const initMenu = () => {
      for (let i = 0; i < items.length; ++i) {
        let el = items[i] // a

        // if current route === link from menu -> set active
        if (pathName === el.pathname) {
          el.classList.add("active")
          let parent = el.parentElement // li

          if (parent) {
            parent.classList.add("mm-active")

            const parent2 = parent.parentElement // ul - "sub-menu"

            if (parent2 && parent2.classList.contains("sub-menu")) {
              // setActive(parent2);
            }
          }
        } else {
          // if current route !== link from menu -> set inactive
          el.classList.remove("active")
          let parent = el.parentElement
          if (parent) {
            parent.classList.remove("mm-active")

            const parent2 = parent.parentElement // ul - "sub-menu"
            if (parent2 && parent2.classList.contains("sub-menu")) {
              // if current link from active submenu -> not inactive submenu
              if (!parent2.classList.contains("sub-menu-show-disabled")) {
                // inactive parent submenu link
                // removeActive(parent2);
              }
            }
          }
        }
      }
    }
    initMenu()
  }, [props.location.pathname])

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  // useEffect(() => {
  //   const pathName = props.location.pathname
  //
  //   const initMenu = () => {
  //     new MetisMenu("#side-menu")
  //     let matchingMenuItem = null
  //     const ul = document.getElementById("side-menu")
  //     const items = ul.getElementsByTagName("a")
  //     for (let i = 0; i < items.length; ++i) {
  //       if (pathName === items[i].pathname) {
  //         matchingMenuItem = items[i]
  //         break
  //       }
  //     }
  //     if (matchingMenuItem) {
  //       activateParentDropdown(matchingMenuItem)
  //     }
  //   }
  //   initMenu()
  // }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  const renderSubMenu = submenu => {
    return submenu.map((item, index) => {
      if (item.access.includes(props.user.role)) {
        return (
          <li key={`sidebar-subnav-${index}`}>
            <Link to={item.route}>
              <>
                {item.badge ? item.badge() : null}
                <span>{item.title}</span>
              </>
            </Link>
          </li>
        )
      }
    })
  }

  const renderMenuPoints = () => {
    return config.map((item, index) => {
      if (item.access.includes(props.user.role)) {
        return (
          <li
            key={`sidebar-nav-${index}`}
            className={item.submenu ? " has-arrow" : ""}
          >
            <Link to={item.route} className={``}>
              <i className={`bx ${item.icon}`} />
              <span>{item.title}</span>
              {item.badge ? item.badge() : null}
            </Link>

            {item.submenu && (
              <ul className="sub-menu" aria-expanded="false">
                {renderSubMenu(item.submenu)}
              </ul>
            )}
          </li>
        )
      }
    })
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {renderMenuPoints()}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,

  user: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.user.profile
  return {
    user: data,
  }
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(SidebarContent)))
