import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { Col, Row } from "reactstrap"
import { ControllerHF } from "../../../../ReactHookForm"
import { ComboboxFormField, InputField } from "../../../../FormElements"
import { OverlayBlockLoading } from "../../../../OverlayLoading"
import { useFetchClients, useFetchData } from "../../../../../hooks"
import { LocationSection } from "../LocationSection"
import { API } from "../../../../../api"
import { getLocationsDefaultValues } from "../../helpers"
import { clientHelper, eventsCalendarHelper } from "../../../../../helpers"

export const ClientAndLocationSection = props => {
  const { t } = useTranslation()

  const { control, setValue, watch } = useFormContext()

  const clients = useFetchClients()
  const fetch = useFetchData()

  useEffect(() => {
    clients.fetchData()
  }, [])

  const watchClient = watch("client")

  const onClientChange = value => {
    setValue("client", value)

    if (value && value?.id !== watchClient?.id) {
      const { id } = value

      setValue("locations", getLocationsDefaultValues())
      onFetchClientAddresses(id)
    }
  }

  const onFetchClientAddresses = async id => {
    const request = API.client.getClient(id)
    const clientData = await fetch.fetchData({
      request,
    })

    if (clientData?.data) {
      const locations =
        eventsCalendarHelper.eventForm.getEventLocationsFromEntity(
          clientData?.data
        )

      // use map for each object key to set all fields values
      // if setValue("locations", locations), country not set value
      locations.forEach((item, index) => {
        Object.entries(item).forEach(entry => {
          const [key, value] = entry
          setValue(`locations.${index}.${key}`, value)
        })
      })
    }
  }

  return (
    <Row>
      <Col md={12}>
        <Row>
          <Col md={4}>
            <ControllerHF
              name={"client"}
              control={control}
              component={ComboboxFormField}
              id={"client"}
              label={t("client")}
              placeholder={t("client")}
              options={clients.data}
              getOptionLabel={option => `${option.name}`}
              getOptionValue={option => option.id}
              selectProps={{
                isClearable: true,
              }}
              onChange={onClientChange}
            />
          </Col>

          <Col md={4}>
            <ControllerHF
              name={"family"}
              control={control}
              component={ComboboxFormField}
              id={"family"}
              label={t("family")}
              placeholder={t("family")}
              options={clientHelper.family.getFamilyOptions()}
              selectProps={{
                isClearable: true,
              }}
              normalize={option => (option ? option.value : null)}
              isDetermineValue
            />
          </Col>

          <Col md={4}>
            <ControllerHF
              name={"product"}
              control={control}
              component={InputField}
              id={"product"}
              label={t("product")}
              placeholder={t("product")}
            />
          </Col>
        </Row>

        <OverlayBlockLoading isLoading={fetch.loading}>
          <LocationSection />
        </OverlayBlockLoading>
      </Col>
    </Row>
  )
}

ClientAndLocationSection.propTypes = {}
