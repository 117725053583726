import React from "react"
import PropTypes from "prop-types"

export const Recipients = ({ data = [] }) => {
  const renderList = () => {
    const list = data.map(item => ({
      name: item?.recipientable?.name || null,
      email: item.email,
    }))

    return (
      <ul className={"m-0 ps-3"}>
        {list.map((item, i) => (
          <li key={i}>
            {item?.name ? `${item.name} (${item.email})` : item.email}
          </li>
        ))}
      </ul>
    )
  }

  return !!data?.length ? renderList() : "-"
}

Recipients.propTypes = {
  data: PropTypes.array,
}
