import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"
import { ComboboxFormField, ControllerHF, InputField } from "components"

export const SearchSection = props => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const leftAccessory = () => (
    <div className={"form-left-accessory"}>
      <i className={"mdi mdi-magnify font-size-16"} />
    </div>
  )

  return (
    <>
      <ControllerHF
        name={"keywords"}
        control={control}
        component={InputField}
        label={null}
        placeholder={t("start_typing_agencies")}
        leftAccessory={leftAccessory()}
        autoComplete="off"
      />

      <ControllerHF
        name={"country"}
        control={control}
        component={ComboboxFormField}
        id={"country"}
        label={null}
        placeholder={t("country")}
        options={props.countryOptions}
        getOptionLabel={option => `${option.name}`}
        getOptionValue={option => option.id}
        normalize={option => (option ? option.id : null)}
        isDetermineValue
        selectProps={{
          isClearable: true,
        }}
      />

      <ControllerHF
        name={"city"}
        control={control}
        component={InputField}
        label={null}
        placeholder={t("city")}
        leftAccessory={leftAccessory()}
        autoComplete="off"
      />
    </>
  )
}

SearchSection.propTypes = {
  countryOptions: PropTypes.array,
}
