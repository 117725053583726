import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import i18n from '../../../../i18n';

import { agencyProfileData } from "./slice"
import { API } from "../../../../api"

export function* getData({ payload: { id } }) {
  try {

    const response = yield call(API.agency.getAgency, id);

    const { data } = response;

    yield put(agencyProfileData.getDataCompleted(data));
  } catch (error) {
    const message = error.response?.data?.message || i18n.t('error');
    yield put(agencyProfileData.getDataRejected(message));
  }
}

export function* watchGetData() {
  yield takeLatest(agencyProfileData.getData, getData)
}

function* dataSaga() {
  yield all([
    fork(watchGetData),
  ]);
}

export default dataSaga;