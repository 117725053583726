import {
  BILLING_SUBSCRIPTION_TARIFF_ALIAS_BASIC,
  BILLING_SUBSCRIPTION_TARIFF_ALIAS_BUSINESS,
  BILLING_SUBSCRIPTION_TARIFF_PERIOD_MONTHLY,
  BILLING_SUBSCRIPTION_TARIFF_PERIOD_ANNUALLY,
} from "consts"

export const compareTariff = (tariff, current) => {
  let result = {
    isDowngrade: false,
    isUpgrade: false,
  }

  if (current.id === tariff.id) {
    return result
  }

  // if is upgrade
  if (
    (current.period === BILLING_SUBSCRIPTION_TARIFF_PERIOD_MONTHLY &&
      tariff.period === BILLING_SUBSCRIPTION_TARIFF_PERIOD_ANNUALLY) ||
    (current.period === tariff.period &&
      current.alias === BILLING_SUBSCRIPTION_TARIFF_ALIAS_BASIC &&
      tariff.alias !== BILLING_SUBSCRIPTION_TARIFF_ALIAS_BASIC)
  ) {
    result = {
      isDowngrade: false,
      isUpgrade: true,
    }
  } else {
    // is downgrade
    result = {
      isDowngrade: true,
      isUpgrade: false,
    }
  }

  return result
}

export default {
  compareTariff,
}
