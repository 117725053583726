import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../../../i18n';
import { MODEL_PANEL_GENERAL_EDIT_CONTACTS } from './actionTypes';
import { modelPanelGeneralEditContactsSuccess, modelPanelGeneralEditContactsError } from './actions';
import { modelProfileUpdateData } from '../../../../model/profile/actions';

import { API } from '../../../../../api';

function* editContacts({ payload: { id, params, onSuccess, onError } }) {
    try {
        const response = yield call(API.model.editModel, id, params);

        const { data } = response;

        yield put(modelPanelGeneralEditContactsSuccess());

        yield put(modelProfileUpdateData({ ...data?.data }));

        toastr.success(i18n.t('edit.success'));

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        const errors = error.response?.data?.errors || {};
        const message = error.response?.data?.message || i18n.t('error');

        yield put(modelPanelGeneralEditContactsError({
            errors,
            message
        }));

        if (onError) {
            onError(errors);
        }
    }
}

export function* watchEditContacts() {
    yield takeEvery(MODEL_PANEL_GENERAL_EDIT_CONTACTS, editContacts);
}

function* editSaga() {
    yield all([
        fork(watchEditContacts),
    ]);
}

export default editSaga;