import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import { Dashboard } from './Dashboard';

export const DashboardIndex = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <Dashboard />
            </Route>
            <Route>
                <Redirect to={path} />
            </Route>
        </Switch>
    );
};