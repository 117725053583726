import { all } from 'redux-saga/effects';

import updateSaga from './update/saga';

function* settingsSaga() {
    yield all([
        updateSaga(),
    ])
}

export default settingsSaga;