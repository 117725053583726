import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import { DrawerContainer, StripeWrapper } from "components"
import { AddPaymentMethodForm } from "./AddPaymentMethodForm"
import { billingPaymentMethodAdd } from "store/actions"

const AddPaymentCardComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onSubmitSuccess = onSubmitProps => () => {
    props.onSuccess()
    props.onClose()
  }

  const handleSubmit = (stripe, elements, params, onSubmitProps) => {
    dispatch(
      billingPaymentMethodAdd.stripeAdd({
        stripe,
        elements,
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
      })
    )
  }

  const onDestroy = () => {
    dispatch(billingPaymentMethodAdd.cleanState())
  }

  return (
    <React.Fragment>
      <DrawerContainer
        title={t("add_payment_method")}
        visible={props.visible}
        onClose={props.onClose}
        onDestroy={onDestroy}
      >
        <StripeWrapper>
          <AddPaymentMethodForm
            loading={props.loading}
            error={props.error}
            onSubmit={handleSubmit}
            onCancel={props.onClose}
            paymentMethodType={props.paymentMethodType}
          />
        </StripeWrapper>
      </DrawerContainer>
    </React.Fragment>
  )
}

AddPaymentCardComponent.propTypes = {
  visible: PropTypes.bool,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  paymentMethodType: PropTypes.string,

  loading: PropTypes.bool,
  error: PropTypes.object,
}

const mapStateToProps = state => {
  const { loading, error } = state.billing.paymentMethod.add

  return {
    loading,
    error,
  }
}

export const AddPaymentMethod = connect(mapStateToProps)(
  AddPaymentCardComponent
)
