import React from "react"
import PropTypes from "prop-types"

export const SelectedItem = ({ item, index, ...props }) => {
  const onRemove = e => {
    e.preventDefault()

    props.onRemove(item, index)
  }

  return (
    <div className={"selected-list-control__item"}>
      {props.remove && (
        <div className={"selected-list-control__item-remove"}>
          <a href={"#"} onClick={onRemove}>
            {props.removeBtn ? (
              props.removeBtn
            ) : (
              <i
                className={
                  "bx bx-x text-danger font-size-20 selected-list-control__item-remove-icon"
                }
              />
            )}
          </a>
        </div>
      )}

      <div className={"selected-list-control__item-content-block"}>
        {props.renderItemContent ? (
          props.renderItemContent(item, index)
        ) : (
          <div className={"selected-list-control__item-text"}>{item}</div>
        )}
      </div>
    </div>
  )
}

SelectedItem.propTypes = {
  item: PropTypes.any,
  index: PropTypes.number,
  onRemove: PropTypes.func,
  renderItemContent: PropTypes.func,
  removeBtn: PropTypes.any,
  remove: PropTypes.bool,
}
