import {
  USER_PROFILE_SET_DATA,
  USER_PROFILE_CLEAN_STATE,
  USER_PROFILE_UPDATE_DATA,
  USER_PROFILE_UPDATE_COMPANY_LOGO,
  USER_PROFILE_UPDATE_SUBSCRIPTION,
  USER_PROFILE_RESTORE_COMPANY,
  USER_PROFILE_UPDATE_UNREAD_MESSAGES,
} from "./actionTypes"

export const userProfileSetData = data => {
  return {
    type: USER_PROFILE_SET_DATA,
    payload: data,
  }
}

export const userProfileCleanState = () => {
  return { type: USER_PROFILE_CLEAN_STATE }
}

export const userProfileUpdateData = data => {
  return {
    type: USER_PROFILE_UPDATE_DATA,
    payload: data,
  }
}

export const userProfileUpdateCompanyLogo = data => {
  return {
    type: USER_PROFILE_UPDATE_COMPANY_LOGO,
    payload: data,
  }
}

export const userProfileUpdateSubscription = data => {
  return {
    type: USER_PROFILE_UPDATE_SUBSCRIPTION,
    payload: data,
  }
}

export const userProfileRestoreCompany = () => {
  return {
    type: USER_PROFILE_RESTORE_COMPANY,
  }
}

export const userProfileUpdateUnreadMessages = data => {
  return {
    type: USER_PROFILE_UPDATE_UNREAD_MESSAGES,
    payload: data,
  }
}
