import React from "react"
import PropTypes from "prop-types"

import {
  BILLING_SUBSCRIPTION_STATUS_ACTIVE,
  BILLING_SUBSCRIPTION_STATUS_ON_GRACE_PERIOD,
} from "consts"
import { billingSubscriptionHelper } from "helpers"
import {
  SubscriptionCancel,
  SubscriptionResume,
} from "../SubscriptionComponents"

export const SubscriptionStatus = ({ status }) => {
  return (
    <div className={"d-flex align-items-center justify-content-between"}>
      {billingSubscriptionHelper.status.getStatus(status).label}

      {status === BILLING_SUBSCRIPTION_STATUS_ACTIVE ? (
        <div className={"ms-2 me-2"}>
          <SubscriptionCancel />
        </div>
      ) : status === BILLING_SUBSCRIPTION_STATUS_ON_GRACE_PERIOD ? (
        <div className={"ms-2 me-2"}>
          <SubscriptionResume />
        </div>
      ) : null}
    </div>
  )
}

SubscriptionStatus.propTypes = {
  status: PropTypes.string,
}
