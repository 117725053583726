import {
  MODEL_PANEL_GENERAL_LOGISTICS_DETAILS_EDIT,
  MODEL_PANEL_GENERAL_LOGISTICS_DETAILS_EDIT_SUCCESS,
  MODEL_PANEL_GENERAL_LOGISTICS_DETAILS_EDIT_ERROR,
  MODEL_PANEL_GENERAL_LOGISTICS_DETAILS_EDIT_CLEAN_STATE,
} from "./actionTypes"

const initialState = {
  loading: false,
  error: null,
}

const edit = (state = initialState, action) => {
  switch (action.type) {
    case MODEL_PANEL_GENERAL_LOGISTICS_DETAILS_EDIT:
      state = {
        ...state,
        loading: true,
        error: null,
      }
      break

    case MODEL_PANEL_GENERAL_LOGISTICS_DETAILS_EDIT_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break

    case MODEL_PANEL_GENERAL_LOGISTICS_DETAILS_EDIT_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      }
      break

    case MODEL_PANEL_GENERAL_LOGISTICS_DETAILS_EDIT_CLEAN_STATE:
      state = {
        ...state,
        ...initialState,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default edit
