import React from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"
import { analyticsHelper } from "helpers"

export const BarChart = ({
  seriesData,
  optionsData,
  height = "280",
  loading,
  error,
  isData,
}) => {
  const defaultOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    yaxis: {
      type: "category",
      labels: {
        show: isData,
        style: {
          fontSize: "1rem",
        },
      },
    },
    noData: analyticsHelper.options.noDataOptions(loading, error),
  }

  return (
    <ReactApexChart
      options={{ ...defaultOptions, ...optionsData }}
      series={seriesData}
      type={"bar"}
      height={height}
    />
  )
}

BarChart.propTypes = {
  optionsData: PropTypes.object,
  seriesData: PropTypes.array,
  height: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  isData: PropTypes.bool,
}
