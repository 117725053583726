import React from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import { MegaTagTitle } from "../../components"
import { ModelsPanelContainer, Sidebar } from "./components"

const ModelsComponent = props => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <MegaTagTitle title={"title.models"} />

      <div className="page-content page-content_sidebar">
        <div className={"page-content__container"}>
          <Container fluid>
            <div>
              <ModelsPanelContainer />
            </div>
          </Container>
        </div>

        <div className={"page-content__sidebar"}>
          <Sidebar />
        </div>
      </div>
    </React.Fragment>
  )
}

ModelsComponent.propTypes = {}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const Models = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelsComponent)
