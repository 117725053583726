import { all } from "redux-saga/effects"

import avatarSaga from "./avatar/saga"
import companyLogoSaga from "./companyLogo/saga"
import generalInfoSaga from "./generalInfo/saga"
import passwordSaga from "./password/saga"
import removeSaga from "./remove/saga"
import restoreSaga from "./restore/saga"
import companySaga from "./company/sagas"

function* profileSaga() {
  yield all([
    avatarSaga(),
    companyLogoSaga(),
    generalInfoSaga(),
    passwordSaga(),
    removeSaga(),
    restoreSaga(),
    companySaga(),
  ])
}

export default profileSaga
