import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { AgencyItem } from "./AgencyItem"
import * as HOC from "../../../../../../HOC"
import { useTranslation } from "react-i18next"
import { Button } from "../../../../../../components"
import SimpleBar from "simplebar-react"

const DataShower = HOC.withDataShower()

export const Agencies = props => {
    const ref = useRef()
    const { t } = useTranslation()

    useEffect(() => {
        if (ref.current) {
            ref.current.recalculate()
        }
    })
    return (
        <>
            <DataShower
                isLoading={props.loading && !props.isMoreLoading}
                isFailed={props.error}
                error={props.error}
            >
                {!!props.items.length ? (
                    <div className={"agencies"}>
                        <SimpleBar className="h-100 " ref={ref}>
                            {props.items.map(item => (
                                <AgencyItem
                                    key={`agency-item-${item.id}`}
                                    item={item}
                                    onSelect={props.onSelect}
                                />
                            ))}
                        </SimpleBar>
                    </div>
                ) : !props.loading ? (
                    t("empty")
                ) : null}
            </DataShower>
            {props.showMore && (
                <div className={"mt-3 d-flex justify-content-center w-100"}>
                    <Button
                        title={"btn.show-more"}
                        loading={props.isMoreLoading}
                        disabled={props.isMoreLoading}
                        onClick={props.onLoadMore}
                    />
                </div>
            )}
        </>
    )
}

Agencies.propTypes = {
    items: PropTypes.array,
    error: PropTypes.any,
    loading: PropTypes.bool,
    onSelect: PropTypes.func,
    isMoreLoading: PropTypes.bool,
    showMore: PropTypes.bool,
    onLoadMore: PropTypes.func,
}


