import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Container, Row } from "reactstrap"
import { Drawer } from "components"
import { WorkForm } from "../WorkForm"
import { ValidationsUtils } from "utils"
import { modelAddWork } from "store"

const AddWorkComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const handleCloseDrawer = () => {
    props.onClose()
  }

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    props.onAddSuccess()
    props.onClose()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      modelAddWork.add({
        id: props.modelId,
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  const onDestroy = () => {
    dispatch(modelAddWork.cleanState())
  }

  return (
    <React.Fragment>
      <Drawer
        title={t("add-work")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <Container fluid>
          <Row>
            <WorkForm
              initialValues={{}}
              loading={props.loading}
              error={props.error}
              onSubmit={handleSubmit}
              onCancel={handleCloseDrawer}
            />
          </Row>
        </Container>
      </Drawer>
    </React.Fragment>
  )
}

AddWorkComponent.propTypes = {
  visible: PropTypes.bool,
  modelId: PropTypes.number,

  loading: PropTypes.bool,
  error: PropTypes.object,

  onAddSuccess: PropTypes.func,
  onClose: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, error } = state.modelPanel.general.work.add
  return {
    loading,
    error,
  }
}

export const AddWork = connect(mapStateToProps)(AddWorkComponent)
