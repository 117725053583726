import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import PropTypes from "prop-types"
import { useStripe, useElements } from "@stripe/react-stripe-js"
import { Col, Row } from "reactstrap"

import { useStripeForm } from "hooks"

import {
  Button,
  CheckboxField,
  ControllerHF,
  ValidationErrors,
  StripeFormElement,
} from "components"

export const CardForm = props => {
  const { t } = useTranslation()

  const stripe = useStripe()
  const elements = useElements()

  const stripeInitialState = {
    cardNumber: undefined,
    cardExpiry: undefined,
    cardCvc: undefined,
  }

  const stripeForm = useStripeForm({
    initialState: stripeInitialState,
  })

  const { control, handleSubmit } = useForm({
    defaultValues: {
      default: false,
    },
  })

  const isDisabled = useMemo(() => {
    if (!stripe || !elements) {
      return true
    }

    if (!stripeForm.isValid) {
      return true
    }

    return props.loading
  }, [stripe, elements, stripeForm.isValid, props.loading])

  const onSubmit = values => {
    if (isDisabled) {
      return
    }

    const params = {
      ...values,
      payment_method_type: props.paymentMethodType,
    }

    props.onSubmit(stripe, elements, params, {})
  }

  return (
    <>
      {props.error && <ValidationErrors data={props.error} />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <StripeFormElement
              state={stripeForm.state}
              type={props.paymentMethodType}
              onChange={stripeForm.onChange}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <ControllerHF
              name={"default"}
              control={control}
              component={CheckboxField}
              id={"default"}
              label={t("default")}
              wrapClass={"form-checkbox-control"}
              size={"lg"}
            />
          </Col>
        </Row>

        <div className="mt-3 mb-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={isDisabled}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </>
  )
}

CardForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  paymentMethodType: PropTypes.string,
}
