import React, { useEffect } from "react"
import { ComboboxFormField } from "components"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { modelsGroupsList } from "store"
import PropTypes from "prop-types"
import { find } from "lodash"

export const GroupSelect = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { list: groups } = useSelector(state => state.models.groups.list)

  useEffect(() => {
    fetchOptions()
    return () => {
      dispatch(modelsGroupsList.cleanState())
    }
  }, [])

  const fetchOptions = () => {
    dispatch(
      modelsGroupsList.getList({
        params: {
          pagination: false,
          type: props.gender,
        },
      })
    )
  }

  const filterGroups = groups => {
    return groups.filter(group => {
      return !find(props.modelGroups, { id: group.id })
    })
  }
  return (
    <>
      <ComboboxFormField
        onChange={props.onSelect}
        id={"groups"}
        value={props.value}
        placeholder={t("group")}
        options={filterGroups(groups)}
        getOptionLabel={option => `${option.name}`}
        getOptionValue={option => option.id}
        normalize={option => (option ? option : null)}
        isDetermineValue
        selectProps={{
          isClearable: true,
        }}
      />
    </>
  )
}

GroupSelect.propTypes = {
  gender: PropTypes.string,
  modelGroups: PropTypes.array,
  value: PropTypes.object,
  onSelect: PropTypes.func,
}
