import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { EventInvoiceDrawer } from "../EventInvoiceDrawer"
import { EventInvoiceDataLoader } from "../EventInvoiceDataLoader"
import { InvoiceCard } from "./InvoiceCard"

export const EventInvoiceView = props => {
  const { t } = useTranslation()

  const { data } = useSelector(state => state.calendarEvent.invoice.view.data)

  const onDestroy = () => {
    props.onDestroy && props.onDestroy()
  }

  return (
    <EventInvoiceDrawer
      title={t("view_invoice")}
      visible={props.visible}
      onClose={props.onClose}
      onDestroy={onDestroy}
      className={"calendar-event-invoice-view"}
    >
      <EventInvoiceDataLoader id={props.eventId}>
        <InvoiceCard data={data} />
      </EventInvoiceDataLoader>
    </EventInvoiceDrawer>
  )
}

EventInvoiceView.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  eventId: PropTypes.number,
}
