import { all, fork, put, select, takeEvery } from "redux-saga/effects"

import { socketOnSocialPostUpdate } from "./actions"
import {
  selectSocialCalendarPostItemData,
  selectSocialCalendarPostList,
  socialCalendarPostItem,
  socialCalendarPostList,
} from "store"

function* onSocialPostUpdate({ payload: { data } }) {
  const { action, post } = data

  if (action === "updated") {
    const posts = yield select(selectSocialCalendarPostList)

    if (posts.some(item => item.id === post.id)) {
      yield put(socialCalendarPostList.updateItemById(post))
    }

    const postItemData = yield select(selectSocialCalendarPostItemData)

    if (postItemData && postItemData.id === post.id) {
      yield put(socialCalendarPostItem.updateData(post))
    }
  }
}

export function* watchOnSocialPostUpdate() {
  yield takeEvery(socketOnSocialPostUpdate, onSocialPostUpdate)
}

function* socialPostSaga() {
  yield all([fork(watchOnSocialPostUpdate)])
}

export default socialPostSaga
