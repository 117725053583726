import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "i18n"
import { API } from "api"
import { modelPhotosUpdateInstagramCrop } from "./slice"
import { modelHelper } from "helpers"
import { modelPanelPhotosListUpdateItem } from "store"

function* updateInstagramCrop({
  payload: { attachmentId, params, onSuccess, onError },
}) {
  try {
    let requestBody = yield call(
      modelHelper.photos.getInstagramPhotoRequestBody,
      params
    )

    const response = yield call(
      API.model.updateModelPhoto,
      attachmentId,
      requestBody
    )

    const { data } = response

    if (onSuccess) {
      onSuccess(data)
    }
    toastr.success(i18n.t("model.instagram-crop-update.success"))

    yield put(modelPhotosUpdateInstagramCrop.updateCompleted())
    yield put(modelPanelPhotosListUpdateItem({ ...data.data }))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      modelPhotosUpdateInstagramCrop.updateRejected({
        errors,
        message,
      })
    )
    if (onError) {
      onError(errors)
    }
  }
}

export function* watchUpdateItem() {
  yield takeEvery(modelPhotosUpdateInstagramCrop.update, updateInstagramCrop)
}

function* updateSaga() {
  yield all([fork(watchUpdateItem)])
}

export default updateSaga
