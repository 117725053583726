import { all } from 'redux-saga/effects';

import removeSaga from './delete/saga';
import modelsSidebarSaga from './modelsSidebar/saga';

function* panelSaga() {
    yield all([
        removeSaga(),
        modelsSidebarSaga(),
    ])
}

export default panelSaga;