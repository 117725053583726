import React from "react"
import { useTranslation } from "react-i18next"
import { Alert } from "reactstrap"
import PropTypes from "prop-types"
import { Link } from 'react-router-dom';

export const BillingSubscriptionRequiredAlert = props => {
  const { t } = useTranslation()

  return (
    <Alert color="secondary">
      {t("subscription.required.message")}
      {"."}
      &nbsp;
      <Link
        to={"/billing/subscriptions"}
        className={
          "text-decoration-underline fw-bold text-primary"
        }
      >
        {t("subscribe")}
      </Link>
    </Alert>
  )
}

BillingSubscriptionRequiredAlert.propTypes = {}
