import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';

import { modelFetchBook, modelFetchBookCleanState } from '../../../../../store/actions';

import * as HOC from '../../../../../HOC';
const DataShower = HOC.withDataShower();

const BookDataLoaderComponent = (props) => {

    useEffect(() => {
        fetchData();

        return () => {
            props.modelFetchBookCleanState();
        };
    }, []);

    const fetchData = () => {
        props.modelFetchBook(props.id);
    };

    return (
        <DataShower isLoading={props.loading} isFailed={props.error} error={props.error}>
            {props.data && (
                props.children
            )}
        </DataShower>
    );
};

BookDataLoaderComponent.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    children: PropTypes.any,

    data: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.any,

    modelFetchBook: PropTypes.func,
    modelFetchBookCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { data, loading, error } = state.modelPanel.book.details.data;

    return {
        data,
        loading,
        error,
    };
};

const mapDispatchToProps = {
    modelFetchBook,
    modelFetchBookCleanState,
};

export const BookDataLoader = connect(mapStateToProps, mapDispatchToProps)(BookDataLoaderComponent);