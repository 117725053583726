import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import i18n from '../../../../i18n';
import { EMPLOYEE_DETAILS_FETCH_DATA } from './actionTypes';
import { employeeDetailsFetchDataSuccess, employeeDetailsFetchDataError } from './actions';

import { API } from '../../../../api';

export function* fetchData({ payload: { id } }) {
    try {
        const response = yield call(API.employee.getEmployee, id);

        const { data } = response;

        yield put(employeeDetailsFetchDataSuccess(data?.data));

    } catch (error) {
        const message = error.response?.data?.message || i18n.t('error');
        yield put(employeeDetailsFetchDataError(message));
    }
}

export function* watchFetchData() {
    yield takeEvery(EMPLOYEE_DETAILS_FETCH_DATA, fetchData)
}

function* dataSaga() {
    yield all([
        fork(watchFetchData),
    ]);
}

export default dataSaga;