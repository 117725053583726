import React from "react"
import PropTypes from "prop-types"

export const Description = ({ data = {}, ...props }) => {
  const { description } = data

  return description
}

Description.propTypes = {
  data: PropTypes.object,
}
