import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import { PageTitle, MegaTagTitle } from "components"
import {
  UploadProfileAvatar,
  UploadCompanyLogo,
  EditGeneralInfo,
  ChangePassword,
  RemoveProfile,
  Settings,
  ActivateProfile,
  ColorSettings,
  SocialAccounts,
  Company,
} from "./components"
import { useUserAccess } from "hooks"

const ProfileComponent = props => {
  const { t } = useTranslation()

  const { isDirector, isCompanyDeactivated } = useUserAccess()

  return (
    <React.Fragment>
      <MegaTagTitle title={"title.profile"} />

      <div className="page-content">
        <Container fluid>
          <PageTitle title={t("title.profile")} />

          {!isCompanyDeactivated() ? (
            <>
              <Row>
                <Col lg={isDirector() ? "6" : "12"}>
                  <UploadProfileAvatar />
                </Col>

                {isDirector() && (
                  <Col lg="6">
                    <UploadCompanyLogo />
                  </Col>
                )}
              </Row>

              <EditGeneralInfo />

              <Row>
                <Col lg={"6"} className={"mb-4"}>
                  <Settings />
                </Col>

                <Col lg={"6"} className={"mb-4"}>
                  <ChangePassword />
                </Col>
              </Row>

              <Row>
                <Col className={"mb-4"}>
                  <ColorSettings options={props.options} />
                </Col>
              </Row>

              <Row>
                <Col className={"mb-4"}>
                  <Company />
                </Col>
              </Row>

              <Row>
                <Col className={"mb-4"}>
                  <SocialAccounts />
                </Col>
              </Row>

              <RemoveProfile />
            </>
          ) : (
            <ActivateProfile />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

ProfileComponent.propTypes = {
  user: PropTypes.object,
  options: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    user: state.user.profile.data,
    options: state.user.options.data.data,
  }
}

const mapDispatchToProps = {}

export const Profile = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileComponent)
