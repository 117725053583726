import _ from "lodash"

import i18n from "i18n"
import { BILLING_TAX_ID_STATUS } from "consts"

const { PENDING, VERIFIED, UNVERIFIED } = BILLING_TAX_ID_STATUS

export const getVatIdStatusConfigs = () => {
  return [
    {
      label: i18n.t("pending"),
      value: PENDING,
      theme: "warning",
    },
    {
      label: i18n.t("verified"),
      value: VERIFIED,
      theme: "success",
    },
    {
      label: i18n.t("unverified"),
      value: UNVERIFIED,
      theme: "danger",
    },
  ]
}

export const getVatIdStatusConfig = value => {
  const options = getVatIdStatusConfigs()
  return _.find(options, { value: value })
}

export const mapBillingAddress = (info = {}) => {
  let result = ""

  const { address, city, zip } = info

  if (address && city && zip) {
    result += `${address}, ${city}, ${zip}`
  }

  return result
}
