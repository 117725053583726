import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Table, Spinner } from "reactstrap"

import s from "./ImportEntityInfo.module.scss"
import { DateUtils } from "utils"

export const ImportEntityInfo = ({ data, ...props }) => {
  const { t } = useTranslation()

  const { active, finished_at, last_run_at, last_status, text, type } = data
  return (
    <div className={s.infoBlock}>
      <div className={"table-responsive table-centered"}>
        <Table className="table mb-0 table-border-bottom table-nowrap">
          <tbody>
            <tr>
              <th scope="row">{t("import_status")} :</th>
              <td>
                {active ? (
                  <>
                    {t("active")}
                    <Spinner size={"sm"} color={"primary"} className={"ms-2"} />
                  </>
                ) : (
                  t("inactive")
                )}
              </td>
            </tr>

            {props.type === type && (
              <>
                <tr>
                  <th scope="row">{t("last_import")} :</th>
                  <td>
                    {finished_at
                      ? DateUtils.utcToLocale(finished_at, "DD/MM/YYYY HH:mm:ss")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th scope="row">{t("last_run")} :</th>
                  <td>
                    {last_run_at
                      ? DateUtils.utcToLocale(last_run_at, "DD/MM/YYYY HH:mm:ss")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th scope="row">{t("last_status")} :</th>
                  <td>
                    {last_status !== null
                      ? last_status
                        ? t("success")
                        : t("failed")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th scope="row">{t("info")} :</th>
                  <td>
                    {text ? (
                      <div dangerouslySetInnerHTML={{ __html: text }} />
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

ImportEntityInfo.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
}
