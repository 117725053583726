import React from 'react';
import PropTypes from "prop-types"
import classNames from "classnames"
import { commonHelper } from '../../helpers';



export const SocialNetworkIcon = ({ name, size }) => {
    const icon = commonHelper.socialNetwork.getConfig(name).icon;

    return (
        <img src={icon} alt={'icon'} className={classNames('', {
            [`social-network-icon_size_`]: size,
        })} />
    );
};

SocialNetworkIcon.propTypes = {
    name: PropTypes.string,
    size: PropTypes.string,
}