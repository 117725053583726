import React from "react"
import PropTypes from "prop-types"
import moment from "moment"

export const BasicRow = ({ item }) => {
  return (
    <>
      <td></td>
      <td>
        {item.workdays_from && (
          <span>{moment(item.workdays_from).format("DD/MM/YYYY")}</span>
        )}
        {item.workdays_to && (
          <span>
            {" - "}
            {moment(item.workdays_to).format("DD/MM/YYYY")}
          </span>
        )}
      </td>
      <td></td>
    </>
  )
}

BasicRow.propTypes = {
  item: PropTypes.object,
}
