import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { eventsCalendarHelper } from "helpers"
import { FormatUtils } from "utils"

export const Info = ({ data, entityScreen, entityId }) => {
  const { t } = useTranslation()

  const renderList = items => (
    <div className="table-responsive">
      <table className="table table-nowrap">
        <tbody>
          {items.map(
            ({ label, value, itemClassName, hidden, ...item }, index) =>
              !hidden && (
                <tr key={index}>
                  <td>
                    <span className={"fw-medium"}>{label}</span>
                  </td>

                  <td>{value}</td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  )

  const renderLocation = location => {
    const { street, town, state, zip, access, phone, country, is_primary } =
      location
    const list = [
      {
        label: t("street"),
        value: street,
        hidden: !street,
      },
      {
        label: t("town"),
        value: town,
        hidden: !town,
      },
      {
        label: t("state"),
        value: state,
        hidden: !state,
      },
      {
        label: t("zip"),
        value: zip,
        hidden: !zip,
      },
      {
        label: t("country"),
        value: country?.name,
        hidden: !country,
      },
      {
        label: t("access"),
        value: access,
        hidden: !access,
      },
      {
        label: t("phone"),
        value: FormatUtils.formatPhone(phone),
        hidden: !phone,
      },
    ]

    return (
      <div className={""}>
        <h5>
          {t("location")}
          {is_primary ? ` (${t("primary")})` : null}
        </h5>

        {renderList(list)}
      </div>
    )
  }

  return (
    <div className="event-preview-details-info">
      {renderList(
        eventsCalendarHelper.eventInfo.getEventInfoData(
          data,
          entityScreen,
          entityId
        )
      )}
      {data.locations.map((item, index) => (
        <div className="mt-4" key={index}>
          {renderLocation(item)}
        </div>
      ))}
    </div>
  )
}

Info.propTypes = {
  data: PropTypes.object,
  entityScreen: PropTypes.string,
  entityId: PropTypes.number,
}
