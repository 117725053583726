import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  tableAlias: null,
  list: [],
  listLoading: false,
  listError: null,
  page: 1,
  meta: null,
  filters: {
    values: {
      name: "",
    },
  },
  sort: [],
}

const analyticsTablesDataListSlice = createSlice({
  name: "analyticsTablesDataList",
  initialState,
  reducers: {
    getList(state, action) {
      state.listLoading = true
      state.tableAlias = action.payload.alias
    },
    getListCompleted(state, action) {
      state.listLoading = false
      state.list = action.payload.data
      state.meta = action.payload.meta
    },
    getListRejected(state, action) {
      state.listLoading = false
      state.listError = action.payload
    },
    setPage(state, action) {
      state.page = action.payload
    },
    changeFilter(state, action) {
      state.page = 1
      state.filters.values = { ...state.filters.values, ...action.payload }
    },
    changeSort(state, action) {
      state.page = 1
      state.sort = action.payload.sort
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getList
 * @property {function} getListCompleted
 * @property {function} getListRejected
 *  @property {function} setPage
 *  @property {function} changeFilter
 *  @property {function} changeSort
 *  @property {function} cleanState
 */
export const analyticsTablesDataList = analyticsTablesDataListSlice.actions

export default analyticsTablesDataListSlice.reducer
