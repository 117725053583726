import { all } from "redux-saga/effects"

import updateSaga from "./update/saga"
import removeSaga from "./remove/saga"
import listSaga from "./list/saga"
import addSaga from "./add/saga"


function* attachmentsSaga() {
  yield all([updateSaga(), removeSaga(), listSaga(), addSaga()])
}

export default attachmentsSaga