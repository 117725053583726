import React from "react"
import PropTypes from "prop-types"

import { IconButton } from "components"
import { LogisticsTableRow } from "./TableRows"
import { modelHelper } from "helpers"

export const LogisticsTable = props => {
  const inActionPending = id => props.removingItems.includes(id)

  return (
    <div className="table-responsive">
      <table className="table align-middle table-cell_p-v_sm">
        <tbody>
          {props.items.map(item => (
            <tr key={item.id}>
              <td>
                <div className={"text-nowrap"}>
                  <div className={"float-left align-items-center me-2"}>
                    <div className={"mark-dot-pointer pos-t_d_2"} />
                  </div>
                  <span>
                    {
                      modelHelper.logistics.getLogisticsTypeAsOption(item.type)
                        .label
                    }
                  </span>
                </div>
              </td>

              <LogisticsTableRow item={item} />

              <td
                className={"table-column-sticky_end bg-neutral text-nowrap"}
                style={{ width: "76px" }}
              >
                <IconButton
                  name={"edit"}
                  bg
                  onClick={() => props.onEdit(item)}
                  disabled={inActionPending(item.id)}
                />

                <IconButton
                  name={"remove"}
                  bg
                  onClick={() => props.onRemove(item)}
                  disabled={inActionPending(item.id)}
                  wrapClass={"ms-2"}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

LogisticsTable.propTypes = {
  items: PropTypes.array,
  removingItems: PropTypes.array,

  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
}
