import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "i18n"
import { API } from "api"
import { modelGeneralRemoveAvatar } from "./slice"
import { modelProfileUpdateData } from "store/model/profile/actions"

function* remove({ payload: { id } }) {
  try {
    const response = yield call(API.model.removeModelAvatar, id)

    const { data } = response

    toastr.success(i18n.t("model.remove-avatar.success"))

    yield put(modelProfileUpdateData({ avatar: null, crops: null }))
    yield put(modelGeneralRemoveAvatar.removeCompleted())
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      modelGeneralRemoveAvatar.removeRejected({
        errors,
        message,
      })
    )
    toastr.error(message)
  }
}

export function* watchRemove() {
  yield takeEvery(modelGeneralRemoveAvatar.remove, remove)
}

function* removeSaga() {
  yield all([fork(watchRemove)])
}

export default removeSaga
