import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from "toastr";

import i18n from '../../../../i18n';
import { MODEL_ADD_BOOK } from './actionTypes';
import { modelAddBookSuccess, modelAddBookError } from './actions';

import { API } from '../../../../api';

function* add({ payload: { id, params, onSuccess, onError } }) {
    try {
        const response = yield call(API.model.addModelBook, id, params);

        const { data } = response;

        yield put(modelAddBookSuccess());

        toastr.success(i18n.t('model-book.add.success'));

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        const errors = error.response?.data?.errors || {};
        const message = error.response?.data?.message || i18n.t('error');

        yield put(modelAddBookError({
            errors,
            message
        }));

        if (onError) {
            onError(errors);
        }
    }
}

export function* watchAdd() {
    yield takeEvery(MODEL_ADD_BOOK, add);
}

function* addSaga() {
    yield all([
        fork(watchAdd),
    ]);
}

export default addSaga;