import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Tooltip } from "reactstrap"

import { InputField, Button } from "components"
import { useCopyToClipboard } from "hooks"
import { FileUtils } from "utils"

export const Input = ({
                        tooltipTarget = "iCal-urls-copy-btn",
  value = "",
  download = "",
}) => {
  const { t } = useTranslation()

  const { state: copyState, onCopy, onReset } = useCopyToClipboard({})

  const [tooltipOpen, setTooltipOpen] = useState(false)

  const inputRef = useRef(null)
  const domElementId = tooltipTarget

  const toggleTooltip = () => {
    if (copyState.copied && !tooltipOpen) {
      onReset()
    }

    setTooltipOpen(prevState => !prevState)
  }

  const handleCopy = event => {
    onCopy(value)

    inputRef.current && inputRef.current?.select()

    toggleTooltip()
  }

  const onDownload = () => {
    FileUtils.downloadFileByLink(download)
  }

  return (
    <InputField
      fieldRef={inputRef}
      id={"stream"}
      name={"stream"}
      value={value}
      meta={{}}
      readOnly
      appendAccessory={[
        <React.Fragment key={"copy"}>
          <Button
            id={domElementId}
            renderTitle={null}
            icon={"fas fa-copy"}
            onClick={handleCopy}
          />
          <Tooltip
            // key={copyState?.copied}
            target={domElementId}
            isOpen={tooltipOpen}
            toggle={toggleTooltip}
          >
            {copyState?.copied ? t("copied_to_clipboard") : t("copy")}
          </Tooltip>
        </React.Fragment>,
        <React.Fragment key={"download"}>
          <Button
            outline
            renderTitle={null}
            icon={"bx bxs-download font-size-13"}
            onClick={onDownload}
          />
        </React.Fragment>,
      ]}
    />
  )
}

Input.propTypes = {
  tooltipTarget: PropTypes.any,
  value: PropTypes.string,
  download: PropTypes.string,
}
