import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Col, Alert, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import {
  Button,
  ControllerHF,
  InputField,
  OverlayBlockLoading,
  RecipientsContactsField,
} from "components"
import { validationSchema } from "./validation"
import { useFetchData } from "hooks"
import { API } from "api"

export const EventSendEmailForm = props => {
  const { t } = useTranslation()

  const [recipientsOptions, setRecipientsOptions] = useState([])

  const { control, handleSubmit, setError, setValue, reset } = useForm({
    defaultValues: {
      subject: "",
      message: "",
      recipients: [],
      ...props.initialValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const fetchModels = useFetchData()

  useEffect(() => {
    onFetchModels()
  }, [])

  const onFetchModels = async () => {
    const request = API.calendar.getModels(props.event?.id)
    const data = await fetchModels.fetchData({
      request,
    })

    if (data?.data) {
      setRecipientsOptions(data?.data)

      const recipientsValue = data?.data.map(model => ({
        id: model.id,
        name: model.name,
        // set first email selected
        emails: model.emails.filter((_, i) => i === 0),
      }))
      setValue("recipients", recipientsValue)
    }
  }

  const onSubmit = values => {
    const { subject, message, recipients } = values
    let params = {
      subject,
      message,
    }

    let recipientList = []
    recipients.forEach(item => {
      item.emails.forEach(email => {
        let recipient = {
          email,
        }

        if (item.id) {
          recipient.model_id = item.id
        }

        recipientList = [...recipientList, { ...recipient }]
      })
    })

    params.recipients = recipientList

    props.onSubmit(params, { onReset, setError })
  }

  const onReset = () => {
    reset()
  }

  const onAddRecipient = data => {
    setRecipientsOptions(prevState => [...prevState, data])
  }

  const onRemoveRecipient = (data, index) => {
    let items = recipientsOptions.filter((_, i) => i !== index)
    setRecipientsOptions([...items])
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={12}>
            <ControllerHF
              name={"subject"}
              control={control}
              component={InputField}
              id={"subject"}
              label={t("subject")}
              placeholder={t("subject")}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <ControllerHF
              name={"message"}
              control={control}
              component={InputField}
              id={"message"}
              label={t("message")}
              placeholder={t("message")}
              type={"textarea"}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          <Col>
            <OverlayBlockLoading isLoading={fetchModels.loading}>
              <ControllerHF
                name={"recipients"}
                control={control}
                component={RecipientsContactsField}
                id={"recipients"}
                placeholder={t("recipients")}
                limit={null}
                options={recipientsOptions}
                onAdd={onAddRecipient}
                onRemove={onRemoveRecipient}
              />
            </OverlayBlockLoading>
          </Col>
        </Row>

        <div className="mt-3 mb-3 pt-4">
          <div className="button-items">
            <Button
              submit
              title={"btn.send"}
              disabled={props.loading}
              loading={props.loading}
            />
          </div>
        </div>
      </form>
    </>
  )
}

EventSendEmailForm.propTypes = {
  event: PropTypes.object,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,

  onSubmit: PropTypes.func,
}
