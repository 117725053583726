import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { PerfectScrollbar } from "components"
import { Item } from "./Item"
import * as HOC from "HOC"

const DataShower = HOC.withDataShower()

export const ChatsList = props => {
  const { t } = useTranslation()

  const isActive = useCallback(
    chat => {
      if (chat?.is_group && props.groupChatId) {
        return props.groupChatId === chat?.id
      } else if (!chat?.is_group && props.participantId) {
        return props.participantId === chat?.participant?.id
      }
      return false
    },
    [props.participantId, props.groupChatId]
  )

  return (
    <>
      <PerfectScrollbar style={{ height: "455px" }}>
        <DataShower
          isLoading={props.loading}
          isFailed={props.error}
          error={props.error}
        >
          {!!props.items.length ? (
            <>
              <ul className="list-unstyled chat-list">
                {props.items.map(item => (
                  <Item
                    key={`chat-item-${item.id}`}
                    item={item}
                    onSelect={props.onSelect}
                    active={isActive(item)}
                  />
                ))}
              </ul>
            </>
          ) : !props.loading ? (
            t("chat.no_chats")
          ) : null}
        </DataShower>
      </PerfectScrollbar>
    </>
  )
}

ChatsList.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  onSelect: PropTypes.func,
  participantId: PropTypes.number,
  groupChatId: PropTypes.number,
  isGroupConversation: PropTypes.bool,
}
