import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

export const CroppedAvatarPreview = React.memo(
  ({ crop, src, width, height, className }) => {
    const scale = 100 / crop.height

    const transform = {
      x: `${-crop.x * scale}%`,
      y: `${-crop.y * scale}%`,
      scale,
      width: "auto",
      height: "100%",
    }
    const imageStyle = {
      transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
      width: transform.width,
      height: transform.height,
    }

    return (
      <div
        className={classNames("cropped-avatar-output", {
          [className]: className,
        })}
        style={{
          width: width,
          height: height,
        }}
      >
        <img src={src} alt="previewImage" style={imageStyle} />
      </div>
    )
  }
)

CroppedAvatarPreview.propTypes = {
  crop: PropTypes.object.isRequired,
  src: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  className: PropTypes.string,
}
