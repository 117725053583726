import React from "react"
import { Row } from "reactstrap"
import { MegaTagTitle } from "components"
import { TemplatesContainer } from "./components"

export const Templates = () => {
  return (
    <div className={"website-templates-panel"}>
      <MegaTagTitle title={"title.website.templates"} />
      <Row>
        <TemplatesContainer />
      </Row>
    </div>
  )
}
