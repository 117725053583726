import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"
import { useDrawerNavigator } from "hooks"

const DrawerNavigatorContext = createContext(null)

const DrawerNavigatorProvider = ({ children }) => {
  const { state, resetScreen, navigate, onScreenClose } = useDrawerNavigator()

  return (
    <DrawerNavigatorContext.Provider
      value={{
        state,
        resetScreen,
        navigate,
        onScreenClose,
      }}
    >
      {children}
    </DrawerNavigatorContext.Provider>
  )
}

DrawerNavigatorProvider.propTypes = {
  children: PropTypes.any,
}

const useDrawerNavigatorContext = () => {
  const context = useContext(DrawerNavigatorContext)
  if (!context) {
    throw new Error("useDrawerNavigatorContext should be wrapped in Provider")
  }
  return context
}

export { DrawerNavigatorProvider, useDrawerNavigatorContext }
