import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { IconButton } from "../../Buttons"

export const PackageDataAddButton = props => {
  const { t } = useTranslation()

  return (
    <IconButton
      name={"add"}
      disabled={props.disabled}
      bg
      onClick={props.onClick}
      icon={props.status ? "bx bx-comment-check" : null}
      bgColor={props.status ? "bg-success" : null}
      color={props.status ? "text-white" : null}
    />
  )
}

PackageDataAddButton.propTypes = {
  status: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}
