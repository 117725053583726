import React from 'react';
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next';
import moment from "moment";

import { RatingBadge, IconButton } from '../../../../../../components';
import { commonHelper } from '../../../../../../helpers';

export const RatingGridItem = (props) => {
    const { t } = useTranslation();

    return (
        <div className="info-card rating-card">
            <div className={'rating-card__icon-block'}>
                <RatingBadge value={props.item.value} size={'lg'} />
            </div>

            <div className={'rating-card__content-block'}>
                <div className={'rating-card__title-block'}>
                    <div className={'rating-card__label'}>
                        {commonHelper.rating.getConfig(props.item.value).label}
                    </div>

                    <div className={'rating-card__date'}>
                        {moment(props.item.created_at).format('DD/MMM/YYYY HH:mm')}
                    </div>
                </div>

                {props.item.message && (
                    <div className={'rating-card__text'}>
                        {props.item.message}
                    </div>
                )}

                <div className={'rating-card__author'}>
                    {props.item.user?.name}
                </div>
            </div>

            {props.withRemove && (
                <div className="rating-card__remove-block">
                    <IconButton name={'remove'} bg disabled={props.loading} onClick={() => props.onRemove(props.item)} />
                </div>
            )}
        </div>
    );
};

RatingGridItem.propTypes = {
    item: PropTypes.object,
    withRemove: PropTypes.bool,
    onRemove: PropTypes.func,
    loading: PropTypes.bool,
}