import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../../../i18n';

import { MODEL_VIDEOS_REMOVE_ITEM } from './actionTypes';
import {
    modelVideosRemoveVideoSuccess,
    modelVideosRemoveVideoError,
} from './actions';

import { API } from '../../../../../api';

function* remove({ payload: { id, onSuccess } }) {
    try {
        const response = yield call(API.model.removeModelVideo, id);

        const { data } = response;

        yield put(modelVideosRemoveVideoSuccess(id));
        toastr.success(i18n.t('model-video.remove.success'));

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        const message = error.response?.data?.message || i18n.t('error');

        yield put(modelVideosRemoveVideoError(id));

        toastr.error(message);
    }
}

export function* watchRemove() {
    yield takeEvery(MODEL_VIDEOS_REMOVE_ITEM, remove)
}

function* removeItemSaga() {
    yield all([
        fork(watchRemove),
    ]);
}

export default removeItemSaga;