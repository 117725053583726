import {
    PACKAGE_FETCH_DATA,
    PACKAGE_FETCH_DATA_SUCCESS,
    PACKAGE_FETCH_DATA_ERROR,
    PACKAGE_FETCH_DATA_CLEAN_STATE,
} from './actionTypes';

export const packageFetchData = (id) => {
    return {
        type: PACKAGE_FETCH_DATA,
        payload: { id },
    }
};

export const packageFetchDataSuccess = (data) => {
    return {
        type: PACKAGE_FETCH_DATA_SUCCESS,
        payload: data,
    }
};

export const packageFetchDataError = (data) => {
    return {
        type: PACKAGE_FETCH_DATA_ERROR,
        payload: data,
    }
};

export const packageFetchDataCleanState = () => {
    return { type: PACKAGE_FETCH_DATA_CLEAN_STATE };
};