import _ from "lodash"

import i18n from "../../i18n"

import {
  MODEL_TRAVEL_BY_PLANE,
  MODEL_TRAVEL_BY_TRAIN,
  MODEL_TRAVEL_BY_CAR,
  MODEL_TRAVEL_BY_TAXI,
  MODEL_TRAVEL_BY_BOAT,
  MODEL_TRAVEL_BY_OTHER,
} from "../../consts"

export const getTravelByOptions = () => {
  return [
    {
      label: i18n.t("plane"),
      value: MODEL_TRAVEL_BY_PLANE,
    },
    {
      label: i18n.t("train"),
      value: MODEL_TRAVEL_BY_TRAIN,
    },
    {
      label: i18n.t("car"),
      value: MODEL_TRAVEL_BY_CAR,
    },
    {
      label: i18n.t("taxi"),
      value: MODEL_TRAVEL_BY_TAXI,
    },
    {
      label: i18n.t("boat"),
      value: MODEL_TRAVEL_BY_BOAT,
    },
    {
      label: i18n.t("other"),
      value: MODEL_TRAVEL_BY_OTHER,
    },
  ]
}

export const getTravelByOption = value => {
  const options = getTravelByOptions()
  return _.find(options, { value: value })
}
