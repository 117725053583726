export const MODEL_PANEL_MODELS_SIDEBAR_FETCH_LIST =
  "MODEL_PANEL_MODELS_SIDEBAR_FETCH_LIST"
export const MODEL_PANEL_MODELS_SIDEBAR_FETCH_LIST_SUCCESS =
  "MODEL_PANEL_MODELS_SIDEBAR_FETCH_LIST_SUCCESS"
export const MODEL_PANEL_MODELS_SIDEBAR_FETCH_LIST_ERROR =
  "MODEL_PANEL_MODELS_SIDEBAR_FETCH_LIST_ERROR"
export const MODEL_PANEL_MODELS_SIDEBAR_LIST_CLEAN_STATE =
  "MODEL_PANEL_MODELS_SIDEBAR_LIST_CLEAN_STATE"

export const MODEL_PANEL_MODELS_SIDEBAR_LIST_SET_PAGE =
  "MODEL_PANEL_MODELS_SIDEBAR_LIST_SET_PAGE"

export const MODEL_PANEL_MODELS_SIDEBAR_LIST_CHANGE_SEARCH_INPUT =
  "MODEL_PANEL_MODELS_SIDEBAR_LIST_CHANGE_SEARCH_INPUT"

export const MODEL_PANEL_MODELS_SIDEBAR_LIST_CHANGE_FILTER =
  "MODEL_PANEL_MODELS_SIDEBAR_LIST_CHANGE_FILTER"
export const MODEL_PANEL_MODELS_SIDEBAR_LIST_RESET_FILTER =
  "MODEL_PANEL_MODELS_SIDEBAR_LIST_RESET_FILTER"

export const MODEL_PANEL_MODELS_SIDEBAR_LIST_RESET_FETCHED_DATA =
  "MODEL_PANEL_MODELS_SIDEBAR_LIST_RESET_FETCHED_DATA"

export const MODEL_PANEL_MODELS_SIDEBAR_LIST_SYNC =
  "MODEL_PANEL_MODELS_SIDEBAR_LIST_SYNC"
