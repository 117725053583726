import React from "react"
import PropTypes from "prop-types"

import { useModal } from "hooks"
import { Button, PackageSharedLink } from "components"

export const SharedLink = ({ disabled = false, btnClass = "", ...props }) => {
  const { modalRef, closeModal, showModal } = useModal()

  return (
    <>
      <Button
        title={"package.create_link_and_send"}
        onClick={showModal}
        outline
        disabled={disabled}
        btnClass={btnClass}
      />

      <PackageSharedLink
        id={props.id}
        modalRef={modalRef}
        onCancel={closeModal}
      />
    </>
  )
}

SharedLink.propTypes = {
  id: PropTypes.number,
  disabled: PropTypes.bool,
  btnClass: PropTypes.string,
}
