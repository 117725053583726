import {
    PACKAGE_DRAFT_DATA_COLLECTIONS_FETCH_BOOKS,
    PACKAGE_DRAFT_DATA_COLLECTIONS_FETCH_BOOKS_SUCCESS,
    PACKAGE_DRAFT_DATA_COLLECTIONS_FETCH_BOOKS_ERROR,
    PACKAGE_DRAFT_DATA_COLLECTIONS_FETCH_BOOKS_CLEAN_STATE,

    PACKAGE_DRAFT_DATA_COLLECTIONS_BOOKS_SET_PAGE,
} from './actionTypes'

export const packageDraftDataCollectionsFetchBooks = (id, params) => {
    return {
        type: PACKAGE_DRAFT_DATA_COLLECTIONS_FETCH_BOOKS,
        payload: { id, params },
    }
};

export const packageDraftDataCollectionsFetchBooksSuccess = (data) => {
    return {
        type: PACKAGE_DRAFT_DATA_COLLECTIONS_FETCH_BOOKS_SUCCESS,
        payload: data,
    }
};

export const packageDraftDataCollectionsFetchBooksError = (data) => {
    return {
        type: PACKAGE_DRAFT_DATA_COLLECTIONS_FETCH_BOOKS_ERROR,
        payload: data,
    }
};

export const packageDraftDataCollectionsFetchBooksCleanState = () => {
    return { type: PACKAGE_DRAFT_DATA_COLLECTIONS_FETCH_BOOKS_CLEAN_STATE };
};

export const packageDraftDataCollectionsBooksSetPage = (data) => {
    return {
        type: PACKAGE_DRAFT_DATA_COLLECTIONS_BOOKS_SET_PAGE,
        payload: data,
    }
};