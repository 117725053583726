import React, { useRef, useCallback } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

import { usePrintContent } from "hooks"
import { eventsCalendarHelper } from "helpers"
import { PrintContent } from "./PrintContent"
import { GENERAL_CALENDAR_ENTITY } from "consts"

// get events of selected date and transform event type if event is option-job
const selectEvents = (events, date, model) => {
  return eventsCalendarHelper.calendar
    .getEventsOfDate(events, date)
    .map(event => {
      let result = { ...event }
      const type = eventsCalendarHelper.calendar.getEntityEventType(
        event,
        GENERAL_CALENDAR_ENTITY.MODEL,
        model.id
      )
      // can be null
      if (type) {
        result.type = type
      }

      return { ...result }
    })
}

export const PrintEvents = ({ date, model, onDestroy }) => {
  const events = useSelector(state =>
    selectEvents(state.calendarEvent.list.list, date, model)
  )

  let printContentRef = useRef(null)

  const printContent = useCallback(() => {
    return printContentRef.current
  }, [printContentRef.current])

  const { print } = usePrintContent({
    content: printContent,
    onAfterPrint: onDestroy,
  })

  const onPrint = () => {
    print()
  }

  return (
    <div className={"d-none print-events"}>
      <PrintContent
        ref={printContentRef}
        date={date}
        onPrint={onPrint}
        events={events}
      />
    </div>
  )
}

PrintEvents.propTypes = {
  date: PropTypes.object,
  model: PropTypes.object,
  onDestroy: PropTypes.func,
}
