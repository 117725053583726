import {
    PROFILE_UPLOAD_COMPANY_LOGO,
    PROFILE_UPDATE_COMPANY_LOGO_SUCCESS,
    PROFILE_UPDATE_COMPANY_LOGO_ERROR,
    PROFILE_REMOVE_COMPANY_LOGO,
    PROFILE_COMPANY_LOGO_CLEAN_STATE,
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
};

const companyLogo = (state = initialState, action) => {
    switch (action.type) {
        case PROFILE_UPLOAD_COMPANY_LOGO:
            state = {
                ...state,
                loading: true,
                error: null,
            };
            break;

        case PROFILE_UPDATE_COMPANY_LOGO_SUCCESS:
            state = {
                ...state,
                loading: false,
            };
            break;

        case PROFILE_UPDATE_COMPANY_LOGO_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        case PROFILE_COMPANY_LOGO_CLEAN_STATE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        case PROFILE_REMOVE_COMPANY_LOGO:
            state = {
                ...state,
                loading: true,
                error: null,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default companyLogo;