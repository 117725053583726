import React, { useEffect } from 'react';
import PropTypes from "prop-types"
import { Container } from "reactstrap";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PageHeader, MegaTagTitle } from '../../components';
import { Questionnaire as QuestionnaireModal } from './components';

const QuestionnaireComponent = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
        }
    }, []);

    return (
        <React.Fragment>
            <MegaTagTitle title={'title.questionnaire'} />

            <div className="page-content">
                <Container fluid>
                    <PageHeader
                        title={t('title.questionnaire')}
                    />

                    <QuestionnaireModal />

                </Container>
            </div>
        </React.Fragment>
    );
};

QuestionnaireComponent.propTypes = {
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const Questionnaire = connect(mapStateToProps, mapDispatchToProps)(QuestionnaireComponent);