import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../../../i18n';
import { MODEL_EDIT_BOOK } from './actionTypes';
import {
    modelEditBookSuccess,
    modelEditBookError,
} from './actions';

import { API } from '../../../../../api';

function* edit({ payload: { id, params, onSuccess, onError } }) {
    try {
        const response = yield call(API.model.updateModelBook, id, params);

        const { data } = response;

        yield put(modelEditBookSuccess());

        toastr.success(i18n.t('edit.success'));

        if (onSuccess) {
            onSuccess(data?.data);
        }
    } catch (error) {
        const errors = error.response?.data?.errors || {};
        const message = error.response?.data?.message || i18n.t('error');

        yield put(modelEditBookError({
            errors,
            message
        }));

        if (onError) {
            onError(errors);
        }
    }
}

export function* watchEdit() {
    yield takeEvery(MODEL_EDIT_BOOK, edit);
}

function* editSaga() {
    yield all([
        fork(watchEdit),
    ]);
}

export default editSaga;