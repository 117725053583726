import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

import {
  Button,
  DateRangePickerField,
  InfoCard,
  PrintButton,
  StatementPrintHeader,
} from "components"
import { commonHelper } from "helpers"
import { StatementTable } from "../StatementTable"

const currencySymbol = commonHelper.currency.getGeneralCurrency()?.symbol

export const StatementCard = ({ period, onPeriodChange, ...props }) => {
  const { t } = useTranslation()

  const [date_start, date_end] = period

  const invalidPeriod = useMemo(() => {
    return !(date_start || date_end)
  }, [date_start, date_end])

  return (
    <>
      {/* Only for printing */}
      <StatementPrintHeader title={props.agency?.name} period={period} />

      <InfoCard>
        <Row className={"mb-3 d-print-none"}>
          <Col md={4}>
            <DateRangePickerField
              id={"period"}
              label={t("period")}
              placeholder={t("select-period")}
              value={period}
              onChange={onPeriodChange}
              isClearable={false}
              meta={{
                error: invalidPeriod && "field.error.period.required",
              }}
            />
          </Col>
        </Row>

        <StatementTable
          list={props.list}
          loading={props.listLoading}
          error={props.listError}
          onPayment={props.onStatementPayment}
          onPaymentDetails={props.onPaymentDetails}
        />

        <Row>
          <Col>
            {props.meta && (
              <div
                className={
                  "d-flex justify-content-end align-items-end statement-table-total"
                }
              >
                <div className={"d-flex align-items-center flex-wrap"}>
                  <Button
                    title={t("select_period_to_pay")}
                    onClick={props.onPeriodPay}
                    btnClass={"me-4 d-print-none"}
                  />

                  <div>
                    <span className={"fw-bold"}>
                      {t("total_selected_period_amount")}:{" "}
                    </span>
                    {props.meta?.total_current} {currencySymbol}
                    <br />
                    <span className={"fw-bold"}>{t("total_amount")}: </span>
                    <span>
                      {props.meta?.total} {currencySymbol}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            <PrintButton />
          </Col>
        </Row>
      </InfoCard>
    </>
  )
}

StatementCard.propTypes = {
  period: PropTypes.array,
  onPeriodChange: PropTypes.func,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  agency: PropTypes.object,
  onPeriodPay: PropTypes.func,
  onStatementPayment: PropTypes.func,
  onPaymentDetails: PropTypes.func,
}
