import React from "react"
import PropTypes from "prop-types"
import * as ReactPerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

export const PerfectScrollbar = props => {
  return (
    <ReactPerfectScrollbar {...props}>{props.children}</ReactPerfectScrollbar>
  )
}

PerfectScrollbar.propTypes = {
  children: PropTypes.any,
}
