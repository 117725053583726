import React, { useMemo } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import {
  BILLING_SUBSCRIPTION_TARIFF_PERIOD_ANNUALLY,
  BILLING_SUBSCRIPTION_STATUS_ON_GRACE_PERIOD,
  BILLING_SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT,
} from "consts"
import { billingSubscriptionHelper } from "helpers"
import { TariffCard } from "../TariffCard"

const TariffComponent = props => {
  const { data, selectedTariff, onSelect, currency } = props

  const isAnnual = data?.period === BILLING_SUBSCRIPTION_TARIFF_PERIOD_ANNUALLY

  const isCurrent = useMemo(() => {
    return data.id === props.currentTariff?.id
  }, [data, props.currentTariff])

  const isDowngrade = useMemo(() => {
    if (data && props.currentTariff) {
      const { isDowngrade } = billingSubscriptionHelper.tariff.compareTariff(
        data,
        props.currentTariff
      )
      return isDowngrade
    }
    return false
  }, [data, props.currentTariff])

  const isDisabled = useMemo(() => {
    if (
      props.subscription?.status ===
        BILLING_SUBSCRIPTION_STATUS_ON_GRACE_PERIOD ||
      props.subscription?.status ===
        BILLING_SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT ||
      isDowngrade
    ) {
      return true
    }

    return isCurrent
  }, [data, isCurrent, props.subscription, isDowngrade])

  const isSelected = useMemo(() => {
    return data?.id === selectedTariff?.id
  }, [data, selectedTariff])

  const handleSelect = data => {
    if (isDisabled) {
      return
    }

    onSelect(data)
  }

  return (
    <TariffCard
      data={data}
      onSelect={handleSelect}
      disabled={isDisabled}
      current={isCurrent}
      selected={isSelected}
      isAnnual={isAnnual}
      currency={currency}
    />
  )
}

TariffComponent.propTypes = {
  data: PropTypes.object,
  selectedTariff: PropTypes.object,
  onSelect: PropTypes.func,
  currency: PropTypes.string,

  currentTariff: PropTypes.object,
  subscription: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    currentTariff: state.user.profile?.data?.subscription?.tariff,
    subscription: state.user.profile?.data?.subscription,
  }
}

const mapDispatchToProps = {}

export const Tariff = connect(
  mapStateToProps,
  mapDispatchToProps
)(TariffComponent)
