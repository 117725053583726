import React from "react"
import PropTypes from "prop-types"
import ModalVideo from "react-modal-video"

export const VideoModal = ({ visible, onClose, ...props }) => {
  return (
    <ModalVideo
      isOpen={visible}
      onClose={onClose}
      {...props}
    />
  )
}

VideoModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
}
