import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { EditInvoiceDetails } from "./EditInvoiceDetails"
import { InvoiceDetailsInfoCard } from "./InvoiceDetailsInfoCard"

const InvoiceDetailsContainerComponent = props => {
    const [editDrawerVisible, setEditDrawerVisible] = useState(false)

    const handleEdit = () => {
        setEditDrawerVisible(true)
    }

    const closeEditDrawer = () => {
        setEditDrawerVisible(false)
    }

    return (
        <>
            <InvoiceDetailsInfoCard editMode={true} onEdit={handleEdit} data={props.agency} />

            <EditInvoiceDetails visible={editDrawerVisible} onClose={closeEditDrawer} />
        </>
    )
}

InvoiceDetailsContainerComponent.propTypes = {
    agency: PropTypes.object,
}

const mapStateToProps = state => {
    const { data } = state.agency.profile.data
    return {
        agency: data,
    }
}

export const InvoiceDetailsContainer = connect(mapStateToProps)(InvoiceDetailsContainerComponent)
