import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Card, CardHeader, CardTitle, CardBody, Alert, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import { Formik, Form } from "formik"
import * as Yup from "yup"

import {
  FormikPasswordInputFiled as PasswordInputFiled,
  Button,
} from "../../../../components"
import {
  profileChangePassword,
  profileChangePasswordCleanState,
} from "../../../../store/actions"

const passwordMinLength = 8

const ChangePasswordComponent = ({
  loading,
  error,
  profileChangePassword,
  profileChangePasswordCleanState,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      profileChangePasswordCleanState()
    }
  }, [])

  const onSubmit = (values, onSubmitProps) => {
    profileChangePassword(values, onSubmitProps)
  }

  return (
    <Card className={"h-100"}>
      <CardHeader className={"bg-dark"}>
        <CardTitle className={"text-white mb-0"}>{t("password")}</CardTitle>
      </CardHeader>
      <CardBody className={"bg-neutral"}>
        {error && <Alert color="danger">{error.message}</Alert>}

        <Formik
          initialValues={{
            current_password: "",
            password: "",
            password_confirmation: "",
          }}
          validationSchema={Yup.object({
            current_password: Yup.string()
              .min(passwordMinLength, "field.error.password.length")
              .required("field.error.required"),
            password: Yup.string()
              .min(passwordMinLength, "field.error.password.length")
              .required("field.error.required"),
            password_confirmation: Yup.string()
              .required("field.error.required")
              .oneOf(
                [Yup.ref("password"), null],
                "field.error.password.no.match"
              )
              .min(passwordMinLength, "field.error.password.length"),
          })}
          onSubmit={onSubmit}
          validateOnChange={true}
          validateOnBlur={false}
          enableReinitialize={true}
        >
          {({ isSubmitting, ...rest }) => {
            return (
              <Form className="form-horizontal" noValidate={true}>
                <Col lg={12}>
                  <PasswordInputFiled
                    name="current_password"
                    label={t("field.label.current-password")}
                    placeholder={t("field.placeholder.current-password")}
                    id={"current_password"}
                    translateParams={{ count: passwordMinLength }}
                  />
                </Col>

                <Col lg={12}>
                  <PasswordInputFiled
                    name="password"
                    label={t("field.label.new-password")}
                    placeholder={t("field.placeholder.new-password")}
                    id={"password"}
                    translateParams={{ count: passwordMinLength }}
                  />
                </Col>

                <Col lg={12}>
                  <PasswordInputFiled
                    name="password_confirmation"
                    label={t("field.label.confirm-new-password")}
                    placeholder={t("field.placeholder.confirm-new-password")}
                    id={"password_confirmation"}
                    translateParams={{ count: passwordMinLength }}
                  />
                </Col>

                <div className="mt-3">
                  <Button
                    submit
                    title={"btn.change_password"}
                    disabled={isSubmitting || loading}
                    loading={loading}
                  />
                </div>
              </Form>
            )
          }}
        </Formik>
      </CardBody>
    </Card>
  )
}

ChangePasswordComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,

  profileChangePassword: PropTypes.func,
  profileChangePasswordCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, error } = state.profile.password
  return {
    loading,
    error,
  }
}

const mapDispatchToProps = {
  profileChangePassword,
  profileChangePasswordCleanState,
}

export const ChangePassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordComponent)
