import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Label } from "reactstrap"
import classNames from "classnames"
import Select from "react-select"

import { FieldError } from "../FieldError"

export const ComboboxFormField = ({
  id,
  label,
  placeholder,
  type,
  reference,
  children,
  options = [],
  normalize,
  disabled,
  selectProps,
  getOptionValue,
  getOptionLabel,
  wrapClass = "mb-3",
  isDetermineValue,
  isMulti = false,
  value,
  meta = {},
  ...props
}) => {
  const { error } = meta

  const { t } = useTranslation()
  const showError = Boolean(error)

  const onChange = value => {
    value = normalize ? normalize(value) : value

    if (isMulti && Array.isArray(value)) {
      value = !!value.length ? value : null
    }

    props.onChange && props.onChange(value)
  }

  const determineValue = () => {
    let optionFieldValue = getOptionValue
      ? getOptionValue
      : option => option.value

    if (value === null || value === undefined) {
      return null
    }

    if (isMulti) {
      return options.filter(item => value.includes(optionFieldValue(item)))
    } else {
      return options.find(item => optionFieldValue(item) === value)
    }
  }

  return (
    <div
      className={classNames("select2-container", {
        [wrapClass]: wrapClass,
      })}
    >
      {label && <Label htmlFor={id}>{label}</Label>}
      <Select
        id={id}
        options={options}
        placeholder={placeholder}
        className={classNames({
          "is-invalid": showError,
        })}
        classNamePrefix="select2-selection"
        styles={{}}
        noOptionsMessage={() => t("select.no_options")}
        getOptionLabel={option =>
          getOptionLabel ? getOptionLabel(option) : `${option.label}`
        }
        getOptionValue={option =>
          getOptionValue ? getOptionValue(option) : `${option.value}`
        }
        isDisabled={disabled}
        isMulti={isMulti}
        {...selectProps}
        value={isDetermineValue ? determineValue() : value}
        onChange={option => onChange(option)}
        onBlur={props.onBlur}
      />

      <FieldError
        error={error}
        translateParams={props.translateParams}
        showError={showError}
      />
    </div>
  )
}

ComboboxFormField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  reference: PropTypes.any,
  children: PropTypes.any,
  normalize: PropTypes.func,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  meta: PropTypes.object,
  onChange: PropTypes.func,
  translateParams: PropTypes.object,
  selectProps: PropTypes.object,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  wrapClass: PropTypes.string,
  isDetermineValue: PropTypes.bool,
  isMulti: PropTypes.bool,
  onBlur: PropTypes.func,
  value: PropTypes.any,
}
