import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { CommonUtils } from "utils"
import { PaymentStatusMarker } from "../PaymentStatusMarker"
import { GENERAL_CALENDAR_ENTITY } from "consts"

const { isNil } = CommonUtils

const { MODEL, AGENCY, CLIENT } = GENERAL_CALENDAR_ENTITY

const ENTITY_CONFIG = {
  [MODEL]: {
    path: "models",
  },
  [AGENCY]: {
    path: "agencies",
  },
  [CLIENT]: {
    path: "clients",
  },
}

export const EntityWithPaymentStatus = ({ data, entity }) => {
  const { id, name, status } = data ?? {}

  const config = useMemo(() => {
    return ENTITY_CONFIG[entity]
  }, [entity])

  if (data) {
    return (
      <div className={"d-flex"}>
        <div>
          <Link
            to={`/${config.path}/${id}/general`}
            className={"d-flex link-secondary"}
          >
            {name}
          </Link>
        </div>

        {!isNil(status) ? (
          <div className={"ms-1"}>
            <PaymentStatusMarker value={status} />
          </div>
        ) : null}
      </div>
    )
  } else {
    return <>-</>
  }
}

EntityWithPaymentStatus.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.bool,
  }),
  entity: PropTypes.oneOf([MODEL, AGENCY, CLIENT]),
}
