import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Alert,
  Row,
  Col,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { Formik, Form } from "formik"
import * as Yup from "yup"

import { FormatUtils, NormalizeUtils } from "../../../../utils"
import {
  FormikTextInputFiled as TextInputFiled,
  Button,
} from "../../../../components"
import { ROLE_DIRECTOR } from "../../../../consts"
import {
  profileEditGeneralInfo,
  profileEditGeneralInfoCleanState,
} from "../../../../store/actions"

const EditGeneralInfoComponent = ({
  loading,
  error,
  user,
  initialValues,
  profileEditGeneralInfo,
  profileEditGeneralInfoCleanState,
}) => {
  const { t } = useTranslation()

  const isDirector = user?.role === ROLE_DIRECTOR

  useEffect(() => {
    return () => {
      profileEditGeneralInfoCleanState()
    }
  }, [])

  const onSubmit = (values, onSubmitProps) => {
    let params = {
      ...values,
    }

    if (values.phone) {
      params.phone = values.phone.replace(/[^0-9]/g, "")
    }

    profileEditGeneralInfo(params, onSubmitProps)
  }

  const formSchema = isDirector =>
    Yup.object({
      email: Yup.string(),
      name: Yup.string().required("field.error.required"),
      company_name: isDirector
        ? Yup.string().required("field.error.required")
        : Yup.string(),
      company_address: Yup.string().nullable(),
      phone: Yup.string().test("phone", "field.error.invalid", val => {
        if (val && val !== "") {
          return val.toString() !== "+"
        }
        return true
      }),
      department: Yup.string(),
    })

  return (
    <Card>
      <CardHeader className={"bg-dark"}>
        <CardTitle className={"text-white mb-0"}>{t("general")}</CardTitle>
      </CardHeader>
      <CardBody className={"bg-neutral"}>
        {error && <Alert color="danger">{error.message}</Alert>}

        <Formik
          initialValues={initialValues}
          validationSchema={formSchema(isDirector)}
          onSubmit={onSubmit}
          validateOnChange={true}
          validateOnBlur={false}
          enableReinitialize={true}
        >
          {({ isSubmitting, ...rest }) => {
            return (
              <Form className="form-horizontal" noValidate={true}>
                <Row>
                  <Col lg={6}>
                    <TextInputFiled
                      name="email"
                      label={t("field.label.email")}
                      placeholder={t("field.placeholder.email")}
                      id={"email"}
                      type="email"
                      disabled
                    />
                  </Col>

                  <Col lg={6}>
                    <TextInputFiled
                      name="name"
                      label={t("field.label.fullname")}
                      placeholder={t("field.placeholder.fullname")}
                      id={"name"}
                      type="text"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <TextInputFiled
                      name="company_name"
                      label={t("field.label.company")}
                      placeholder={t("field.placeholder.company")}
                      id={"company_name"}
                      type="text"
                      disabled={!isDirector}
                    />
                  </Col>

                  <Col lg={6}>
                    <TextInputFiled
                      name="company_address"
                      label={t("company_address")}
                      placeholder={t("field.placeholder.company_address")}
                      id={"company_address"}
                      type="text"
                      disabled={!isDirector}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <TextInputFiled
                      name="phone"
                      label={t("field.label.phone")}
                      placeholder={t("field.placeholder.phone")}
                      id={"phone"}
                      type="text"
                      normalize={NormalizeUtils.phone}
                    />
                  </Col>

                  {!isDirector && (
                    <Col lg={6}>
                      <TextInputFiled
                        name="department"
                        label={t("department")}
                        placeholder={t("department")}
                        id={"department"}
                        type="text"
                        disabled={true}
                      />
                    </Col>
                  )}
                </Row>

                <div className="mt-3">
                  <Button
                    submit
                    title={"save"}
                    disabled={isSubmitting || loading}
                    loading={loading}
                  />
                </div>
              </Form>
            )
          }}
        </Formik>
      </CardBody>
    </Card>
  )
}

EditGeneralInfoComponent.propTypes = {
  user: PropTypes.object,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,

  profileEditGeneralInfo: PropTypes.func,
  profileEditGeneralInfoCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.user.profile
  const { loading, error } = state.profile.generalInfo
  return {
    user: data,
    initialValues: {
      email: data?.email || "",
      name: data?.name || "",
      company_name: data?.company?.name || "",
      company_address: data?.company?.address || "",
      phone: FormatUtils.formatPhone(data?.phone) || "",
      department: data?.department?.name || "",
    },
    loading,
    error,
  }
}

const mapDispatchToProps = {
  profileEditGeneralInfo,
  profileEditGeneralInfoCleanState,
}

export const EditGeneralInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditGeneralInfoComponent)
