import React from "react"
import PropTypes from "prop-types"
import { useFormContext } from "react-hook-form"

import { AsyncMembers } from "./AsyncMembers"
import { MembersField } from "./MembersField"

export const MembersSection = props => {
  const { control } = useFormContext()

  return props.isEdit ? (
    <AsyncMembers control={control} chatId={props.chatId} />
  ) : (
    <MembersField control={control} />
  )
}

MembersSection.propTypes = {
  isEdit: PropTypes.bool,
  chatId: PropTypes.number,
}
