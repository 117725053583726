import axios from "axios"

import { RequestUtils } from "../../utils"

class PackageAPI {
  getPackages = async params => {
    return axios.get("api/fashion-model-packages", {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  getPackage = async (id, params) => {
    return axios.get(`api/fashion-model-packages/${id}`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  addPackage = async params => {
    return axios.post("api/fashion-model-packages", params)
  }

  updatePackage = async (id, params) => {
    return axios.put(`api/fashion-model-packages/${id}`, params)
  }

  removePackage = async id => {
    return axios.delete(`api/fashion-model-packages/${id}`)
  }

  getPackageUrl = async (id, params) => {
    return axios.get(`api/fashion-model-packages/${id}/url`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  getPackagesSharedEntities = async params => {
    return axios.get("api/fashion-model-packages/shared-entities", {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  getPackageSharedEntity = async (id, params) => {
    return axios.get(`api/fashion-model-packages/${id}/shared-entities`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
}

export const packageApi = new PackageAPI()
