import React, { useReducer } from "react"

import { SCREEN } from "../../consts"
import { reducers, actions } from "./store"

export const useEventModelContractManager = ({ initialState = null }) => {
  const [state, dispatch] = useReducer(
    reducers.reducer,
    initialState,
    reducers.reducerInit
  )

  const setState = (data = null) => {
    dispatch(actions.setState(data))
  }

  const resetContract = () => {
    dispatch(actions.resetContract(initialState))
  }

  // Navigation
  const navigate = (screen, payload = null) => {
    setState({
      screen,
      ...payload,
    })
  }

  const onScreenClose = () => {
    navigate(null)
  }

  const onScreenCreate = (model) => {
    navigate(SCREEN.CREATE, { model })
  }

  const onScreenEdit = (model) => {
    navigate(SCREEN.EDIT, { model })
  }
  // === //

  return {
    state,
    actions: {
      resetContract,
    },
    navigation: {
      navigate,
      onScreenClose,
      onScreenCreate,
      onScreenEdit,
    },
  }
}
