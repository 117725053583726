import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { PanelLayout, PanelRouting } from "./components"
import { agencyProfileData } from "../../store/actions"
import * as HOC from "../../HOC"

const DataShower = HOC.withDataShower()

const AgencyPanelComponent = props => {
    let { id } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(agencyProfileData.getData({ id }))
        return () => {
            dispatch(agencyProfileData.cleanState())
        }
    }, [id])

    return (
        <PanelLayout>
            <DataShower isLoading={props.loading} isFailed={props.error} error={props.error} customLoaderClass={"mt-5"}>
                {props.agency && <PanelRouting />}
            </DataShower>
        </PanelLayout>
    )
}

AgencyPanelComponent.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.any,
    agency: PropTypes.object,
}

const mapStateToProps = state => {
    const { loading, error, data } = state.agency.profile.data
    return {
        loading,
        error,
        agency: data,
    }
}

export const AgencyPanel = connect(mapStateToProps)(AgencyPanelComponent)
