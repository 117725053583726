import React from "react"
import { Row, Col } from "reactstrap"
import { MegaTagTitle } from "../../../components"
import { EditDomain, EditLogo, MenuItemsContainer } from "./components"

export const Settings = () => {
  return (
    <div className={"website-settings-panel"}>
      <MegaTagTitle title={"title.website.settings"} />
      <Row>
        <Col>
          <Row className={"mb-4"}>
            <EditLogo />
          </Row>
          <Row>
            <Col md={6}>
              <EditDomain />
            </Col>
          </Row>
          <Row>
            <MenuItemsContainer />
          </Row>
        </Col>
      </Row>
    </div>
  )
}
