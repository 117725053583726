import React from "react"
import PropTypes from "prop-types"
import { useToggle } from "react-use"
import { CropPhoto } from "./CropPhoto"
import { PublishPhoto } from "./PublishPhoto"

export const PublishToInstagram = ({
  src,
  description,
  onClose,
  instagramCrop,
  attachmentId,
}) => {
  const [isEditCropMode, toggleEditCropMode] = useToggle(!instagramCrop)

  const handleCropPhotoCancel = () => {
    if (instagramCrop) {
      toggleEditCropMode()
    } else {
      onClose()
    }
  }
  const handleSuccessCropCreate = () => {
    toggleEditCropMode(false)
  }

  if (isEditCropMode) {
    return (
      <CropPhoto
        onCancel={handleCropPhotoCancel}
        onSuccessCropCreate={handleSuccessCropCreate}
        instagramCrop={instagramCrop}
        src={src}
        attachmentId={attachmentId}
      />
    )
  } else {
    return (
      <PublishPhoto
        src={instagramCrop?.src}
        onEditCrop={toggleEditCropMode}
        onClose={onClose}
        description={description}
        attachmentId={attachmentId}
      />
    )
  }
}

PublishToInstagram.propTypes = {
  src: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  instagramCrop: PropTypes.object,
  attachmentId: PropTypes.any,
}
