import React, { useEffect, useMemo } from "react"
import { Alert, Card, CardBody, CardHeader, CardTitle } from "reactstrap"
import { useTranslation } from "react-i18next"
import { connect, useDispatch } from "react-redux"
import PropTypes from "prop-types"

import { OverlayBlockLoading } from "components"
import { CompanyForm } from "./CompanyForm"
import { profileCompanyData, profileCompanyEdit } from "store/actions"
import { useSubmitHandler } from "hooks"

const CompanyComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(profileCompanyData.getData())

    return () => {
      dispatch(profileCompanyData.cleanState())
      dispatch(profileCompanyEdit.cleanState())
    }
  }, [])

  const isDisabled = useMemo(() => {
    return !props.data || props.editLoading
  }, [props.data, props.editLoading])

  const infoError = useMemo(() => {
    if (props.error || props.editError) {
      return props.error || props.editError?.message
    }
    return false
  }, [props.error, props.editError])

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        profileCompanyEdit.edit({
          params,
          onSuccess,
          onError,
        })
      )
    },
    () => {}
  )

  return (
    <Card className={"h-100"}>
      <CardHeader className={"bg-dark"}>
        <CardTitle className={"text-white mb-0"}>
          {t("invoice_contact_information")}
        </CardTitle>
      </CardHeader>
      <CardBody className={"bg-neutral"}>
        {infoError && <Alert color="danger">{infoError}</Alert>}

        <OverlayBlockLoading isLoading={props.loading}>
          <CompanyForm
            isDisabled={isDisabled}
            loading={props.editLoading}
            initialValues={{
              invoice_information: props.data?.invoice_information || "",
              contract_text: props.data?.contract_text || "",
              contract_model_text: props.data?.contract_model_text || "",
              contract_footer_text: props.data?.contract_footer_text || "",
            }}
            onSubmit={handleSubmit}
          />
        </OverlayBlockLoading>
      </CardBody>
    </Card>
  )
}

CompanyComponent.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.any,
  editLoading: PropTypes.bool,
  editError: PropTypes.any,
}

const mapStateToProps = state => {
  const { data, loading, error } = state.profile.company.data
  const { loading: editLoading, error: editError } = state.profile.company.edit

  return {
    data,
    loading,
    error,
    editLoading,
    editError,
  }
}

export const Company = connect(mapStateToProps)(CompanyComponent)
