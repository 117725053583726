import * as Yup from "yup"

import { ValidationsUtils } from "../../../../utils"
import { FILE_MAX_SIZE } from "../../../../consts/common/maxFileSize"

export const getEventDateValidations = () => ({
  date_start: Yup.date().nullable().required("field.error.required"),
  date_end: Yup.date()
    .nullable()
    .required("field.error.required")
    .test({
      name: "dateEqualOrGrater",
      exclusive: false,
      params: {},
      message: "field.error.date.after-or-equal",
      test: function (value) {
        return ValidationsUtils.dateEqualOrAfter(value, this.parent.date_start)
      },
    }),
})

export const getEventTimeValidations = () => ({
  times: Yup.array().of(
    Yup.object().shape({
      start: Yup.date().nullable().required("field.error.required"),
      end: Yup.date()
        .nullable()
        .test({
          name: "timeGrater",
          exclusive: false,
          params: {},
          message: "field.error.time.after",
          test: function (value) {
            return ValidationsUtils.dateAfter(
              value,
              this.parent.start,
              "minutes"
            )
          },
        }),
    })
  ),
})

export const getEventTitleInfoValidations = () => ({
  title: Yup.string().nullable().required("field.error.required"),
})

export const getEventAttachFilesValidations = () => ({
  files: Yup.array().of(
    Yup.object().shape({
      file: Yup.mixed().test("maxSize", "field.error.file-size.max", value =>
        ValidationsUtils.fileMaxSize(value, FILE_MAX_SIZE)
      ),
    })
  ),
})

export const getEventLocationValidations = () => ({
  locations: Yup.array().of(
    Yup.object().shape({
      phone: Yup.string()
        .nullable()
        .test("phone", "field.error.invalid", ValidationsUtils.phoneFormat),
    })
  ),
})

export const getEventPeriodValidations = () => ({
  period_date_start: Yup.date().nullable().required("field.error.required"),
  period_date_end: Yup.date()
    .nullable()
    .required("field.error.required")
    .test({
      name: "dateEqualOrGrater",
      exclusive: false,
      params: {},
      message: "field.error.date.after-or-equal",
      test: function (value) {
        return ValidationsUtils.dateEqualOrAfter(
          value,
          this.parent.period_date_start
        )
      },
    }),
})
