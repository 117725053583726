import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Table } from "reactstrap"
import classNames from "classnames"

import { IconButton, FieldError } from "components"
import { RecipientEmails } from "../RecipientEmails"

export const RecipientsTable = props => {
  const { t } = useTranslation()

  const onEmailsUpdate = (emails, recipient, index) => {
    const data = { emails }
    props.onUpdate(data, index)
  }

  const isInvalid = index => {
    return !!props.validationsErrors && !!props.validationsErrors[index]?.emails
  }

  return (
    <div className="table-responsive mt-4">
      <Table className={"table-border-bottom"}>
        <thead className={"table-head-bg"}>
          <tr>
            <th>{t("name")}</th>
            <th>{t("emails")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.options.map((item, index) => (
            <tr key={index}>
              <td>{item?.name || "-"}</td>

              <td>
                <div
                  className={classNames("", {
                    "is-invalid": isInvalid(index),
                  })}
                >
                  {item?.emails && !!item.emails?.length ? (
                    <RecipientEmails
                      option={item}
                      optionIndex={index}
                      optionValues={props.value[index]?.emails}
                      onChange={value => onEmailsUpdate(value, item, index)}
                    />
                  ) : (
                    "-"
                  )}
                </div>

                {props.validationsErrors && (
                  <FieldError
                    error={props.validationsErrors[index]?.emails}
                    showError={isInvalid(index)}
                  />
                )}
              </td>

              <td className={"text-nowrap"} style={{ width: "22px" }}>
                <IconButton
                  name={"remove"}
                  bg
                  onClick={() => props.onRemove(item, index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

RecipientsTable.propTypes = {
  value: PropTypes.array,
  options: PropTypes.array,
  onRemove: PropTypes.func,
  onUpdate: PropTypes.func,
  validationsErrors: PropTypes.array,
}
