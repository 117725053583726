import React from "react"
import { useDrawerNavigatorContext } from "contexts"

const SCREEN = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  VIEW: "VIEW",
}

export const usePostsDrawerManager = () => {
  const {
    state: { screen, screenData, config },
    resetScreen,
    navigate,
    onScreenClose,
  } = useDrawerNavigatorContext()

  const showPostCreateDrawer = postData => {
    navigate(SCREEN.CREATE, postData)
  }

  const showPostEditDrawer = postData => {
    navigate(SCREEN.EDIT, postData)
  }

  const showPostViewDrawer = postData => {
    navigate(SCREEN.VIEW, postData)
  }

  return {
    navigation: {
      showPostCreateDrawer,
      showPostEditDrawer,
      showPostViewDrawer,
    },
    state: {
      isCreatePostDrawer: screen === SCREEN.CREATE,
      isEditPostDrawer: screen === SCREEN.EDIT,
      isViewPostDrawer: screen === SCREEN.VIEW,
      postData: screenData,
      config,
    },
    destroyDrawer: resetScreen,
    closeDrawer: onScreenClose,
  }
}
