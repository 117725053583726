import React from "react"
import { Row, Col, CardBody, Card } from "reactstrap"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import { MegaTagTitle } from "../../components"
import { ForgetPasswordForm } from "./components"

const ForgetPasswordComponent = props => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <MegaTagTitle title={"title.forgot_password"} />

      <Card className="overflow-hidden">
        <div className="bg-primary">
          <Row>
            <Col>
              <div className=" p-4 text-center">
                <h5 className="text-white mb-0">
                  {t("title.forgot_password")}
                </h5>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody>
          <div className="p-2">
            <ForgetPasswordForm />
          </div>
        </CardBody>
      </Card>

      <div className="mt-5 text-center">
        <p>
          {t("remember_it")}?{" "}
          <Link to="/login" className="fw-medium text-secondary">
            {" "}
            {t("btn.login")}{" "}
          </Link>{" "}
        </p>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const ForgetPassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgetPasswordComponent)
