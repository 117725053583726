import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../../../i18n';

import { MODEL_VIDEOS_REMOVE_VIDEOS } from './actionTypes';
import {
    modelVideosRemoveVideosSuccess,
    modelVideosRemoveVideosError,
} from './actions';

import { API } from '../../../../../api';

function* removeVideos({ payload: { videos, onSuccess, onError } }) {
    try {
        const response = yield call(API.model.removeModelVideos, videos);

        const { data } = response;

        yield put(modelVideosRemoveVideosSuccess());
        toastr.success(i18n.t('model-videos.remove.success'));

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        const errors = error.response?.data?.errors || {};
        const message = error.response?.data?.message || i18n.t('error');

        yield put(modelVideosRemoveVideosError({
            errors,
            message
        }));

        toastr.error(message);

        if (onError) {
            onError(errors);
        }
    }
}

export function* watchRemoveVideos() {
    yield takeEvery(MODEL_VIDEOS_REMOVE_VIDEOS, removeVideos)
}

function* removeVideosSaga() {
    yield all([
        fork(watchRemoveVideos),
    ]);
}

export default removeVideosSaga;