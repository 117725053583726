import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { LOGOUT_USER } from "./actionTypes"
import { logoutUserSuccess } from "./actions"
import { cleanAuthData } from "../data/actions"
import { userProfileCleanState } from "../../user/profile/actions"
import { API } from "api"

function* logoutUser({ payload: { onSuccess } }) {
  try {
    const response = yield call(API.auth.logOut)
    const { data } = response
    yield put(logoutUserSuccess())

    yield put(cleanAuthData())
    yield put(userProfileCleanState())

    onSuccess && onSuccess()
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")

    toastr.error(message)
  }
}

export function* watchLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser)
}

function* logoutSaga() {
  yield all([fork(watchLogout)])
}

export default logoutSaga
