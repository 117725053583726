import React, { useEffect } from "react"
import { useRouteMatch } from "react-router-dom"
import * as HOC from "../../HOC"
import PropTypes from "prop-types"
import {
    employeeDetailsDataCleanState,
    employeeDetailsFetchData,
} from "../../store/employee/details/data/actions"
import { connect } from "react-redux"
import { PanelLayout } from "./components/Panel"
import { PanelRouting } from "./components/PanelRouting"

const DataShower = HOC.withDataShower()

export const StatisticsPanelComponent = (props) => {

    useEffect(() => {
        props.employeeDetailsFetchData(props.profile.id)

        return () => {
            props.employeeDetailsDataCleanState()
        }
    }, [props.profile.id])

    return (
        <PanelLayout>
            <DataShower
                isLoading={props.loading}
                isFailed={props.error}
                error={props.error}
                customLoaderClass={"mt-5"}
            >
                {props.employee && <PanelRouting />}
            </DataShower>
        </PanelLayout>
    )
}

StatisticsPanelComponent.propTypes = {
    profile: PropTypes.object,
    employee: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.any,
    employeeDetailsFetchData: PropTypes.func,
    employeeDetailsDataCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { data } = state.user.profile
    const { loading, error, data: employee } = state.employee.details.data
    return {
        profile: data,
        loading,
        error,
        employee,
    }
}

const mapDispatchToProps = {
    employeeDetailsFetchData,
    employeeDetailsDataCleanState,
}

export const StatisticsPanel = connect(
    mapStateToProps,
    mapDispatchToProps
)(StatisticsPanelComponent)
