import { createAction } from "@reduxjs/toolkit"

const SOCKET_ON_CHAT_GROUP_CREATED = "socket/onChatGroupCreated"

export const socketOnChatGroupCreated = createAction(
  SOCKET_ON_CHAT_GROUP_CREATED,
  function prepare({ data, history }) {
    return {
      payload: {
        data,
        history,
      },
    }
  }
)
