import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import placeholder from "assets/images/dev/avatar-placeholder.png"
import { Image } from "components"

export const Header = props => {
  const { t } = useTranslation()
  const { data: user } = useSelector(state => state.user.profile)

  return (
    <Card className="bg-neutral">
      <CardBody>
        <div className={"d-flex"}>
          <div className="align-self-center me-3 image-rounded photo-rounded__xs">
            <Image
              src={user?.avatar ? user.avatar : placeholder}
              alt={"avatar"}
            />
          </div>
          <div className={"align-self-center"}>
            <h5 className="font-size-15 mt-0 mb-0">{user.name}</h5>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

Header.propTypes = {}
