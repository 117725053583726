import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import _ from "lodash"
import i18n from "../../../../i18n"
import { API } from "../../../../api"
import { employeeAddRating } from "./slice"
import { employeeDetailsUpdateData } from "../../details/data/actions"


function* addRating({ payload: { id, params, onSuccess, onError } }) {
    try {
        const response = yield call(API.employee.addRating, id, params)

        const { data } = response

        yield put(employeeAddRating.addCompleted())

        if (_.has(data.data, "avg_rating")) {
            yield put(
              employeeDetailsUpdateData({ avg_rating: data?.data?.avg_rating })
            )
        }

        toastr.success(i18n.t("rating.add.success"))

        if (onSuccess) {
            onSuccess()
        }
    } catch (error) {
        const errors = error.response?.data?.errors || {}
        const message = error.response?.data?.message || i18n.t("error")

        yield put(
            employeeAddRating.addRejected({
                errors,
                message,
            })
        )

        if (onError) {
            onError(errors)
        }
    }
}

export function* watchAddRating() {
    yield takeEvery(employeeAddRating.add, addRating)
}

function* addSaga() {
    yield all([fork(watchAddRating)])
}

export default addSaga