import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { IconButton, TooltipWrapper } from "components"

export const EditControl = props => {
  const { t } = useTranslation()

  const domElementId = `event-model-action-edit-${props.modelId}`

  return (
    <>
      <TooltipWrapper target={domElementId} content={t("edit")}>
        <IconButton
          id={domElementId}
          wrapClass={"text-secondary font-size-20"}
          icon={"bx bxs-pencil"}
          onClick={props.onClick}
        />
      </TooltipWrapper>
    </>
  )
}

EditControl.propTypes = {
  onClick: PropTypes.func,
  modelId: PropTypes.number,
}
