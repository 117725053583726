import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"
import { modelHelper } from "helpers"
import { ComboboxFormField, ControllerHF } from "components"

const genderOptions = modelHelper.general.getGenderOptions()

export const GenderSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <Row>
      <Col>
        <ControllerHF
          name={"gender"}
          control={control}
          component={ComboboxFormField}
          id={"gender"}
          label={t("department")}
          placeholder={t("department")}
          options={genderOptions}
          selectProps={{
            isClearable: true,
            isSearchable: false,
          }}
          normalize={option => (option ? option.value : null)}
          isDetermineValue
        />
      </Col>
    </Row>
  )
}

GenderSection.propTypes = {}
