import { SOCIAL_POST_TYPE_FACEBOOK, SOCIAL_POST_TYPE_INSTAGRAM } from "consts"
import { DateUtils } from "utils"

export const getCreatePostInitialValues = (type, postData) => {
  switch (type) {
    case SOCIAL_POST_TYPE_FACEBOOK: {
      return {
        publication_date: postData?.date_start ? postData?.date_start : null,
      }
    }
    case SOCIAL_POST_TYPE_INSTAGRAM: {
      return {
        publication_date: postData?.date_start ? postData?.date_start : null,
      }
    }
  }
}

export const getEditPostInitialValues = (type, postData) => {
  switch (type) {
    case SOCIAL_POST_TYPE_FACEBOOK: {
      return {
        page: {
          id: postData?.account_number,
          name: postData?.account_name,
        },
        photo: postData?.fashion_model_photo,
        description: postData?.description,
        publication_date: DateUtils.utcToLocale(postData?.publication_date),
      }
    }
    case SOCIAL_POST_TYPE_INSTAGRAM: {
      return {
        instagram_profile: {
          id: postData?.account_number,
          username: postData?.account_name,
        },
        photo: postData?.fashion_model_photo,
        description: postData?.description,
        publication_date: DateUtils.utcToLocale(postData?.publication_date),
      }
    }
  }
}
