import { all } from 'redux-saga/effects';

import editPhotoSaga from './editPhoto/sagas';

function* detailsSaga() {
    yield all([
        editPhotoSaga(),
    ])
}

export default detailsSaga;