import { takeLatest, fork, put, all, call } from "redux-saga/effects"

import i18n from "i18n"
import { calendarEventInvoiceEditData } from "./slice"
import { API } from "api"

export function* getData({ payload: { id, params } }) {
  try {
    const response = yield call(API.calendar.getInvoice, id, params)

    const { data } = response

    yield put(calendarEventInvoiceEditData.getDataSuccess(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(calendarEventInvoiceEditData.getDataError(message))
  }
}

export function* watchGetData() {
  yield takeLatest(calendarEventInvoiceEditData.getData, getData)
}

function* dataSaga() {
  yield all([fork(watchGetData)])
}

export default dataSaga
