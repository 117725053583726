import React, { useState } from "react"

/**
 * Represents a useDrawer.
 * @constructor
 * @returns {{resetData: resetData, hide: hide, visible: boolean, data: unknown, show: function}}
 */

export const useDrawer = () => {
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(null)

  const show = data => {
    data && setData(data)
    setVisible(true)
  }

  const hide = () => {
    setVisible(false)
  }

  const resetData = () => {
    setData(null)
  }
  return { data, visible, show, hide, resetData }
}
