import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
  backgroundUnreadMessages: false,
}

const chatConversationDataSlice = createSlice({
  name: "chatConversationData",
  initialState,
  reducers: {
    initChat(state, action) {
      state.data = {
        ...action.payload,
      }
    },
    initParticipant(state, action) {
      state.data = {
        is_group: false,
        participant: {
          ...action.payload,
        },
      }
    },
    setBackgroundUnreadMessages(state, action) {
      state.backgroundUnreadMessages = action.payload
    },
    updateData(state, action) {
      state.data = { ...state.data, ...action.payload }
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} initChat
 * @property {function} initParticipant
 * @property {function} setBackgroundUnreadMessages
 * @property {function} updateData
 * @property {function} cleanState
 */
export const chatConversationData = chatConversationDataSlice.actions

export default chatConversationDataSlice.reducer
