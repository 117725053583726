import { takeEvery, fork, all, call } from "redux-saga/effects"

import { socketOnChatGroupParticipantAdded } from "./actions"
import { onChatUpdate } from "../../../../../chat/chats/list/saga"
import { onConversationUpdate } from "../../../../../chat/conversation/data/saga"

function* onAdd({ payload: { data } }) {
  const { chat, user } = data

  yield call(onChatUpdate, {
    payload: {
      chat,
    },
  })

  yield call(onConversationUpdate, {
    payload: {
      chat,
    },
  })
}

export function* watchOnAdd() {
  yield takeEvery(socketOnChatGroupParticipantAdded, onAdd)
}

function* addSaga() {
  yield all([fork(watchOnAdd)])
}

export default addSaga
