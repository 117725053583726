import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { isEqual } from "lodash"
import { FormProvider, useForm } from "react-hook-form"
import { TextButton } from "components/Buttons"
import { useFormValuesChangedDebounce } from "hooks"

export const FilterFormProvider = props => {
  const methods = useForm({
    defaultValues: {
      ...props.defaultValues,
    },
  })
  const { reset, watch } = methods

  useFormValuesChangedDebounce(watch, props.onFiltersChange)

  const watchValues = watch()

  const isFiltersApplied = useMemo(() => {
    return !isEqual(watchValues, props.initialValues)
  }, [watchValues])

  const handleReset = e => {
    reset(props.initialValues)
  }

  return (
    <FormProvider {...methods}>
      {isFiltersApplied && !props.resetHidden && (
        <div className={"d-flex justify-content-end mb-2"}>
          <div>
            <TextButton
              title={"btn.reset"}
              color={"secondary"}
              onClick={handleReset}
            />
          </div>
        </div>
      )}

      {props.children}
    </FormProvider>
  )
}

FilterFormProvider.propTypes = {
  onFiltersChange: PropTypes.func,
  defaultValues: PropTypes.object,
  children: PropTypes.any,
  initialValues: PropTypes.object,
  resetHidden: PropTypes.bool,
}
