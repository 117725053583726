import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Link, useHistory, useLocation, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { connect, useDispatch } from "react-redux"
import { APP_NAME, STATIC_PAGE } from "consts"
import { MegaTagTitle } from "components"
import * as HOC from "../../HOC"
import { staticPage } from "store"

const DataShower = HOC.withDataShower()

export const TermsComponent = props => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const from = location.state?.from?.pathname
    ? location.state?.from?.pathname
    : "/"

  const fetchTerms = () => {
    dispatch(staticPage.getData({ alias: STATIC_PAGE.TERMS_AND_CONDITIONS }))
  }

  useEffect(() => {
    fetchTerms()

    return () => {
      dispatch(staticPage.cleanState())
    }
  }, [])

  const navigate = e => {
    e.preventDefault()
    history.push(from)
  }

  return (
    <React.Fragment>
      <MegaTagTitle title={"title.terms"} />

      <div className="account-pages my-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={12} lg={12} xl={12}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="p-4">
                        <h5 className={"text-white"}>{t("title.terms")}</h5>
                        <Link
                          className="font-weight-medium text-white"
                          to="#"
                          onClick={navigate}
                        >
                          {from === "/"
                            ? `${t("go_to")} ${APP_NAME}`
                            : t("go_back")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody>
                  <DataShower
                    isLoading={props.loading}
                    isFailed={props.error}
                    error={props.error}
                  >
                    <div
                      className={"px-4"}
                      dangerouslySetInnerHTML={{
                        __html: props.data?.content || "",
                      }}
                    />
                  </DataShower>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                <Link className="font-weight-medium text-primary" to="/">
                  {t("go_to")} {APP_NAME}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

TermsComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  data: PropTypes.object,
}

const mapStateToProps = state => {
  const { loading, error, data } = state.static.staticPage
  return {
    loading,
    error,
    data,
  }
}

export const Terms = withRouter(connect(mapStateToProps)(TermsComponent))
