export const SCREEN = {
  CREATE: "create",
  VIEW: "view",
  EDIT: "edit",
}

export const INITIAL_STATE = {
  screen: null,
  event: null,
  invoice: null,
}
