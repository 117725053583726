import {
    MODEL_PUBLISH_BOOK,
    MODEL_PUBLISH_BOOK_SUCCESS,
    MODEL_PUBLISH_BOOK_ERROR,
    MODEL_PUBLISH_BOOK_CLEAN_STATE,
} from './actionTypes';

export const modelPublishBook = (id, params, onSuccess, onError) => {
    return {
        type: MODEL_PUBLISH_BOOK,
        payload: { id, params, onSuccess, onError },
    }
};

export const modelPublishBookSuccess = () => {
    return {
        type: MODEL_PUBLISH_BOOK_SUCCESS,
    }
};

export const modelPublishBookError = (data) => {
    return {
        type: MODEL_PUBLISH_BOOK_ERROR,
        payload: data,
    }
};

export const modelPublishBookCleanState = () => {
    return { type: MODEL_PUBLISH_BOOK_CLEAN_STATE };
};