import React from "react"
import PropTypes from "prop-types"
import { MembersContainer } from "./MembersContainer"

export const MembersControl = ({
  disabled,
  value,
  async = false,
  className,
  ...props
}) => {
  // Select contact
  const onSelect = data => {
    data = props.normalize ? props.normalize(data) : data
    if (async) {
      const params = {
        // callback: (data) => add(data),
        onSuccess: data => onSelectSuccess(data),
      }
      props.onSelect && props.onSelect(data, params)
    } else {
      onSelectSuccess(data)
    }
  }

  const onSelectSuccess = data => {
    add(data)
  }

  const add = data => {
    props.onChange([...value, { ...data }])
  }
  // --- //

  // Remove
  const onRemove = data => {
    if (async) {
      const params = {
        // callback: (data) => remove(data),
        onSuccess: data => remove(data),
      }
      props.onRemove && props.onRemove(data, params)
    } else {
      remove(data)
    }
  }

  const remove = data => {
    let items = value.filter(item => item.id !== data.id)
    props.onChange([...items])
  }

  return (
    <>
      <MembersContainer
        value={value}
        onSelect={onSelect}
        onRemove={onRemove}
        disabled={disabled}
        renderListItemContent={props.renderListItemContent}
        selectedListConfig={props.selectedListConfig}
        className={className}
      />
    </>
  )
}

MembersControl.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  async: PropTypes.bool,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  normalize: PropTypes.func,
  renderListItemContent: PropTypes.func,
  selectedListConfig: PropTypes.object,
}
