import { all } from "redux-saga/effects"

import addSaga from "./add/saga"
import listSaga from "./list/sagas"
import detailsSaga from "./details/sagas"
import expensesSaga from "./expenses/sagas"

function* logisticsSaga() {
  yield all([addSaga(), listSaga(), detailsSaga(), expensesSaga()])
}

export default logisticsSaga
