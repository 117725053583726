import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { EventModelContractDrawer } from "../EventModelContractDrawer"
import { EventModelContractDataLoader } from "../EventModelContractDataLoader"
import { EventModelContractForm } from "../EventModelContractForm"
import { useSubmitHandler } from "hooks"
import { calendarEventModelContractEditSubmit } from "store/actions"
import { EditorUtils } from "utils"

export const EventModelContractEdit = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const {
    contract,
    submit: { loading, error },
  } = useSelector(state => ({
    contract: state.calendarEvent.model.contract.edit.data.data,
    submit: state.calendarEvent.model.contract.edit.submit,
  }))

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        calendarEventModelContractEditSubmit.submit({
          event: props.eventId,
          model: props.modelId,
          params,
          onSuccess,
          onError,
        })
      )
    },
    data => {
      props.onSuccess(data)
    }
  )

  const onDestroy = () => {
    dispatch(calendarEventModelContractEditSubmit.cleanState())
    props.onDestroy && props.onDestroy()
  }

  const initialValues = useMemo(() => {
    let result = {}
    if (contract) {
      const { contract_text, ...rest } = contract

      result = {
        ...rest,
        contract_text: EditorUtils.draftJs.getEditorState(
          contract_text ? contract_text : ""
        ),
      }
    }

    return result
  }, [contract])

  return (
    <EventModelContractDrawer
      title={t("edit_contract")}
      visible={props.visible}
      onClose={props.onClose}
      onDestroy={onDestroy}
    >
      <EventModelContractDataLoader
        event={props.eventId}
        model={props.modelId}
        isEdit
      >
        <EventModelContractForm
          loading={loading}
          error={error}
          onSubmit={handleSubmit}
          onCancel={props.onClose}
          initialValues={{
            ...initialValues,
          }}
          isEdit
        />
      </EventModelContractDataLoader>
    </EventModelContractDrawer>
  )
}

EventModelContractEdit.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  onSuccess: PropTypes.func,
  eventId: PropTypes.number,
  modelId: PropTypes.number,
}
