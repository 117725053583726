import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"

import { MegaTagTitle } from "components"
import { InvoiceDetailsContainer } from "../../components/InvoiceComponents"

export const InvoiceDetails = props => {
  return (
    <div className={"billing-portal-invoice-details"}>
      <MegaTagTitle title={"title.billing.invoice-details"} />

      <Row>
        <Col>
          <InvoiceDetailsContainer />
        </Col>
      </Row>
    </div>
  )
}

InvoiceDetails.propTypes = {}
