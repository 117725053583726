import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Container } from "reactstrap"

import { Drawer } from "../../../Drawer"

export const EventsEmailSendDrawer = props => {
  const { t } = useTranslation()

  const handleCloseDrawer = () => {
    props.onClose()
  }

  const onDestroy = () => {
    props.onDestroy && props.onDestroy()
  }

  return (
    <React.Fragment>
      <Drawer
        title={t("event-send-email")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <Container fluid>{props.children}</Container>
      </Drawer>
    </React.Fragment>
  )
}

EventsEmailSendDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  children: PropTypes.any,
}
