import React from "react"
import PropTypes from "prop-types"

import { Button, ConfirmPopup } from "components"
import { useConfirmModal } from "hooks"

export const StatementsPayment = ({
  btnProps: { loading, disabled },
  onStatementsPayment,
  unpaidSelectedStatementsIds = [],
}) => {
  const { modalRef, closeModal, handleConfirm, showModal } = useConfirmModal(
    () => {
      void onStatementsPayment(unpaidSelectedStatementsIds)
    },
    () => {}
  )
  // === //

  return (
    <>
      <Button
        loading={loading}
        disabled={
          disabled || loading || unpaidSelectedStatementsIds.length === 0
        }
        title={"pay_all_selected"}
        onClick={() => {
          showModal()
        }}
        type={"button"}
        size={"btn-sm"}
      />

      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
        questionText={"sure_make_payment"}
      />
    </>
  )
}

StatementsPayment.propTypes = {
  btnProps: PropTypes.shape({
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
  }),
  onStatementsPayment: PropTypes.func,
  unpaidSelectedStatementsIds: PropTypes.array,
}
