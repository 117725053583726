import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';

import { modelPanelGeneralFetchFeatures, modelPanelGeneralFetchFeaturesCleanState } from '../../../../../../store/actions';

import * as HOC from '../../../../../../HOC';
const DataShower = HOC.withDataShower();

const FeaturesDataLoaderComponent = (props) => {

    useEffect(() => {
        fetchData();

        return () => {
            props.modelPanelGeneralFetchFeaturesCleanState();
        };
    }, []);

    const fetchData = () => {
        props.modelPanelGeneralFetchFeatures(props.model.id);
    };

    return (
        <DataShower isLoading={props.loading} isFailed={props.error} error={props.error}>
            {props.data && (
                props.children
            )}
        </DataShower>
    );
};

FeaturesDataLoaderComponent.propTypes = {
    children: PropTypes.any,

    model: PropTypes.object,
    data: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.object,

    modelPanelGeneralFetchFeatures: PropTypes.func,
    modelPanelGeneralFetchFeaturesCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { data, loading, error } = state.modelPanel.general.features.data;

    return {
        model: state.model.profile.data,
        data,
        loading,
        error,
    };
};

const mapDispatchToProps = {
    modelPanelGeneralFetchFeatures,
    modelPanelGeneralFetchFeaturesCleanState,
};

export const FeaturesDataLoader = connect(mapStateToProps, mapDispatchToProps)(FeaturesDataLoaderComponent);