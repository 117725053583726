import React, { useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { Popover, StatementReminderIcon } from "components/index"
import { usePopover } from "hooks"
import { DateUtils } from "utils"

export const StatementReminder = ({ id, alerts, className }) => {
  const { t } = useTranslation()

  const { popoverId, toggle, popoverOpen } = usePopover({
    id,
  })

  const notified = useMemo(() => {
    const notifiedAlerts = alerts.filter(item => item.notified)

    return notifiedAlerts.length === alerts.length
  }, [alerts])

  return (
    <div>
      <div className={"d-flex"} id={popoverId}>
        <StatementReminderIcon active={!notified} />
      </div>

      <Popover
        popoverId={popoverId}
        popoverOpen={popoverOpen}
        toggle={toggle}
        placement={"top"}
        boundariesElement={"viewport"}
        trigger={"hover"}
      >
        {alerts.map(({ date_start }, i) => (
          <div key={i}>
            {t("alert")}:{" "}
            {DateUtils.utcToLocale(date_start, "DD/MM/YYYY HH:mm")}
          </div>
        ))}
      </Popover>
    </div>
  )
}

StatementReminder.propTypes = {
  id: PropTypes.number,
  alerts: PropTypes.array,
  className: PropTypes.string,
}
