import {
    USER_SETTINGS_UPDATE_DATA,
    USER_SETTINGS_UPDATE_DATA_SUCCESS,
    USER_SETTINGS_UPDATE_DATA_ERROR,
    USER_SETTINGS_UPDATE_DATA_CLEAN_STATE,
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
};

const update = (state = initialState, action) => {
    switch (action.type) {
        case USER_SETTINGS_UPDATE_DATA:
            state = {
                ...state,
                loading: true,
                error: null,
            };
            break;

        case USER_SETTINGS_UPDATE_DATA_SUCCESS:
            state = {
                ...state,
                loading: false,
            };
            break;

        case USER_SETTINGS_UPDATE_DATA_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        case USER_SETTINGS_UPDATE_DATA_CLEAN_STATE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default update;