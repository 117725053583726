import { combineReducers } from "redux"

import data from "./data/reducer"
import edit from "./edit/reducer"

const detailsReducer = combineReducers({
  data,
  edit,
})

export default detailsReducer
