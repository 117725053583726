import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardBody,
  Alert,
} from "reactstrap"
import { useTranslation } from "react-i18next"

import { Button } from "components"
import { profileRestore } from "store/actions"
import { useUserAccess } from "hooks"

const ActivateProfileComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isDirector } = useUserAccess()

  useEffect(() => {
    return () => {
      dispatch(profileRestore.cleanState())
    }
  }, [])

  const onRestore = () => {
    dispatch(profileRestore.submit())
  }

  return (
    <Card>
      <CardHeader className={"bg-dark"}>
        <CardTitle className={"text-white mb-0"}>{t("activation")}</CardTitle>
      </CardHeader>
      <CardBody>
        {isDirector() ? (
          <>
            <CardSubtitle className="mb-3">
              {t("restore_your_account")}
            </CardSubtitle>

            {props.error && <Alert color="danger">{props.error.message}</Alert>}

            <Button
              onClick={onRestore}
              title={"btn.restore"}
              disabled={props.loading}
              loading={props.loading}
            />
          </>
        ) : (
          <Alert color={"info"}>{t("company_deactivated")}</Alert>
        )}
      </CardBody>
    </Card>
  )
}

ActivateProfileComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
}

const mapStateToProps = state => {
  const { loading, error } = state.profile.restore
  return {
    loading,
    error,
  }
}

export const ActivateProfile = connect(mapStateToProps)(
  ActivateProfileComponent
)
