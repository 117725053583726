import React from "react"
import PropTypes from "prop-types"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { reorder } from "helpers/DnD/reorder"

export const DndListControl = ({
  value,
  onChange,
  renderContainer,
  wrapClass = "",
}) => {
  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const reorderedItems = reorder(
      value,
      result.source.index,
      result.destination.index
    )
    onChange(reorderedItems)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={wrapClass}
          >
            {value.map((item, index) => {
              return (
                <Draggable
                  key={`draggableKey-${item.id || index}`}
                  draggableId={`draggableId-${item.id || index}`}
                  index={index}
                >
                  {provided => renderContainer(provided, item, index)}
                </Draggable>
              )
            })}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
DndListControl.propTypes = {
  initialOrder: PropTypes.array,
  renderContainer: PropTypes.any,
  value: PropTypes.array,
  onChange: PropTypes.func,
  wrapClass: PropTypes.string,
}
