import { combineReducers } from "redux"

import create from "./create/slice"
import edit from "./edit/reducers"
import remove from "./remove/slice"
import sendEmail from "./sendEmail/slice"

const contractReducer = combineReducers({
  create,
  edit,
  remove,
  sendEmail,
})

export default contractReducer
