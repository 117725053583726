import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "../../../../../i18n"
import { MODEL_PANEL_SET_MODEL_AVATAR } from "./actionTypes"
import {
  modelPanelSetModelAvatarError,
  modelPanelSetModelAvatarSuccess,
} from "./actions"
import { modelProfileUpdateData } from "../../../../model/profile/actions"

import { API } from "../../../../../api"
import { modelGeneralUpdateAvatar, modelGeneralUploadAvatar } from "store"

function* setAvatar({ payload: { attachmentId, params } }) {
  try {
    const response = yield call(
      API.model.updateModelPhoto,
      attachmentId,
      params
    )

    const { data } = response

    yield put(modelPanelSetModelAvatarSuccess())

    yield put(
      modelProfileUpdateData({
        avatar: data.data?.attachment?.src,
        crops: data.data?.crops,
        avatar_photo_id: data.data?.id,
      })
    )
    //To remove errors from crop form
    yield put(modelGeneralUploadAvatar.cleanState())
    yield put(modelGeneralUpdateAvatar.cleanState())
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      modelPanelSetModelAvatarError({
        errors,
        message,
      })
    )

    toastr.error(message)
  }
}

export function* watchSetAvatar() {
  yield takeEvery(MODEL_PANEL_SET_MODEL_AVATAR, setAvatar)
}

function* setSaga() {
  yield all([fork(watchSetAvatar)])
}

export default setSaga
