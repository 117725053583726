import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';

import { modelFetchVideo, modelFetchVideoCleanState } from '../../../../../../store/actions';

import * as HOC from '../../../../../../HOC';
const DataShower = HOC.withDataShower();

const VideoDataLoaderComponent = (props) => {

    useEffect(() => {
        fetchData();

        return () => {
            props.modelFetchVideoCleanState();
        };
    }, []);

    const fetchData = () => {
        props.modelFetchVideo(props.id);
    };

    return (
        <DataShower isLoading={props.loading} isFailed={props.error} error={props.error}>
            {props.data && (
                props.children
            )}
        </DataShower>
    );
};

VideoDataLoaderComponent.propTypes = {
    id: PropTypes.number,
    children: PropTypes.any,

    data: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.any,

    modelFetchVideo: PropTypes.func,
    modelFetchVideoCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { data, loading, error } = state.modelPanel.video.details.data;

    return {
        data,
        loading,
        error,
    };
};

const mapDispatchToProps = {
    modelFetchVideo,
    modelFetchVideoCleanState,
};

export const VideoDataLoader = connect(mapStateToProps, mapDispatchToProps)(VideoDataLoaderComponent);