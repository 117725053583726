import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { ModalNotification } from "./ModalNotification"
import { modalNotificationsList } from "store"

export const ModalNotificationsEcho = () => {
  const dispatch = useDispatch()
  const notifications = useSelector(state => state.modalNotifications.list.list)

  const [notificationData, setNotificationData] = useState(null)

  useEffect(() => {
    if (notifications.length > 0 && !notificationData) {
      setNotificationData(notifications[0])
    }
  }, [notifications.length, notificationData])

  const removeNotification = () => {
    dispatch(modalNotificationsList.removeNotificationByIndex({ index: 0 }))
    setNotificationData(null)
  }

  return (
    <ModalNotification
      data={notificationData?.data}
      removeNotification={removeNotification}
      alias={notificationData?.alias}
    />
  )
}

ModalNotificationsEcho.propTypes = {
  children: PropTypes.any,
}
