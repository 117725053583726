import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import { clientAddPrice } from "./slice"
import { API } from "../../../../api"
import i18n from "../../../../i18n"

function* addItem({ payload: { id, params, onSuccess, onError } }) {
  try {
    const response = yield call(API.client.addClientPrice, id, params)

    const { data } = response

    toastr.success(i18n.t("client.add-price.success"))

    if (onSuccess) {
      onSuccess()
    }
    yield put(clientAddPrice.addCompleted())
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      clientAddPrice.addRejected({
        errors,
        message,
      })
    )

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchAddItem() {
  yield takeEvery(clientAddPrice.add, addItem)
}

function* addSaga() {
  yield all([fork(watchAddItem)])
}

export default addSaga
