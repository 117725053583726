import { takeLatest, fork, put, all, call, select } from 'redux-saga/effects';

import i18n from '../../../../../i18n';
import { MODEL_PANEL_GENERAL_FETCH_UPLOADED_PHOTOS, MODEL_PANEL_GENERAL_SYNC_UPLOADED_PHOTOS } from './actionTypes';
import {
    modelPanelGeneralFetchUploadedPhotosSuccess,
    modelPanelGeneralFetchUploadedPhotosError,
    modelPanelGeneralFetchUploadedPhotos,
} from './actions';

import { API } from '../../../../../api';
import { RequestUtils } from '../../../../../utils';

const modelState = (state) => state.model.profile;
const uploadedPhotosState = (state) => state.modelPanel.general.avatar.photos;

export function* fetchUploadedPhotos({ payload: { id, params } }) {
    try {
        let requestParams = {
            includes: [],
        };

        requestParams = RequestUtils.getMapRequestParams(params, requestParams);

        const response = yield call(API.model.getModelPhotos, id, requestParams);

        const { data } = response;

        yield put(modelPanelGeneralFetchUploadedPhotosSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message || i18n.t('error');
        yield put(modelPanelGeneralFetchUploadedPhotosError(message));
    }
}

export function* syncUploadedPhotos() {
    try {
        const model = yield select(modelState);
        const uploadedPhotos = yield select(uploadedPhotosState);

        if (model.data?.id) {
            const id = model.data?.id;
            const params = {
                page: uploadedPhotos.page || 1,
            };
            yield put(modelPanelGeneralFetchUploadedPhotos(id, params));
        }
    } catch (error) {
    }
}

export function* watchFetchUploadedPhotos() {
    yield takeLatest(MODEL_PANEL_GENERAL_FETCH_UPLOADED_PHOTOS, fetchUploadedPhotos)
}

export function* watchSyncUploadedPhotos() {
    yield takeLatest(MODEL_PANEL_GENERAL_SYNC_UPLOADED_PHOTOS, syncUploadedPhotos)
}

function* photosSaga() {
    yield all([
        fork(watchFetchUploadedPhotos),
        fork(watchSyncUploadedPhotos),
    ]);
}

export default photosSaga;