import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import { InfoCard, EmployeeDetails } from "../../../../components"
import { EditEmployee } from "../EditEmployee"
import { RatingContainer } from "./RatingContainer"
import { ROLE_DIRECTOR } from "../../../../consts"
import { connect } from "react-redux"

export const EmployeeDetailsOverviewComponent = props => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {}
  }, [])

  return (
    <Row>
      <Col xl="6" className={"mb-4"}>
        <InfoCard
          editBtn={<EditEmployee />}
          editMode={props.user?.role === ROLE_DIRECTOR}
        >
          <EmployeeDetails data={props.data} />
        </InfoCard>
      </Col>
      <Col xl={6} className={"mb-4"}>
        <RatingContainer />
      </Col>
    </Row>
  )
}

EmployeeDetailsOverviewComponent.propTypes = {
  data: PropTypes.object,
  onAdd: PropTypes.func,
  user: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    user: state.user.profile.data,
  }
}

export const EmployeeDetailsOverview = connect(mapStateToProps)(
  EmployeeDetailsOverviewComponent
)
