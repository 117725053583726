import { createSlice } from "@reduxjs/toolkit"

import { APP_INTRO_TOUR_ALIAS } from "consts"

export const getInitData = () => {
  let result = []
  Object.entries(APP_INTRO_TOUR_ALIAS).forEach(entry => {
    const [key, value] = entry
    result = [...result, { alias: value, viewed: false }]
  })

  return result
}

const initialState = {
  data: getInitData(),
}

const appIntroDataSlice = createSlice({
  name: "appIntroData",
  initialState,
  reducers: {
    setTourViewed(state, action) {
      state.data = state.data.map(item => {
        if (item.alias === action.payload.alias) {
          return {
            ...item,
            viewed: true,
          }
        } else {
          return item
        }
      })
    },
  },
})

/**
 * @namespace
 * @property {function} setTourViewed
 */

export const appIntroData = appIntroDataSlice.actions

export default appIntroDataSlice.reducer
