import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { ModelsSelectedList } from "./ModelsSelectedList"

export const AsyncModelsSelectedListControl = ({
  disabled,
  value,
  className,
  ...props
}) => {
  const { t } = useTranslation()

  const onRemove = item => {
    props.onRemove(item, remove(item))
  }

  const onSelect = item => {
    item = props.normalize ? props.normalize(item) : item
    props.onSelect(item, add(item))
  }

  const remove = item => () => {
    let items = value.filter(i => i.id !== item.id)
    props.onChange([...items])
  }

  const add = item => () => {
    props.onChange([...value, item])
  }

  const isDisabled = useMemo(() => {
    return disabled
  }, [disabled])

  return (
    <>
      <ModelsSelectedList
        disabled={isDisabled}
        onSelect={onSelect}
        onRemove={onRemove}
        value={value}
        renderItemContent={props.renderItemContent}
        loading={props.loading}
        selectedListConfig={props.selectedListConfig}
        gendersValue={props.gendersValue}
      />
    </>
  )
}

AsyncModelsSelectedListControl.propTypes = {
  value: PropTypes.any,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  renderItemContent: PropTypes.func,
  loading: PropTypes.bool,
  normalize: PropTypes.func,
  selectedListConfig: PropTypes.object,
  gendersValue: PropTypes.array,
}
