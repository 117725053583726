import {
    MODEL_PANEL_GENERAL_FETCH_AGENCY,
    MODEL_PANEL_GENERAL_FETCH_AGENCY_SUCCESS,
    MODEL_PANEL_GENERAL_FETCH_AGENCY_ERROR,
    MODEL_PANEL_GENERAL_FETCH_AGENCY_CLEAN_STATE,
} from './actionTypes';

export const modelPanelGeneralFetchAgency = (id) => {
    return {
        type: MODEL_PANEL_GENERAL_FETCH_AGENCY,
        payload: { id },
    }
};

export const modelPanelGeneralFetchAgencySuccess = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_FETCH_AGENCY_SUCCESS,
        payload: data,
    }
};

export const modelPanelGeneralFetchAgencyError = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_FETCH_AGENCY_ERROR,
        payload: data,
    }
};

export const modelPanelGeneralFetchAgencyCleanState = () => {
    return { type: MODEL_PANEL_GENERAL_FETCH_AGENCY_CLEAN_STATE };
};