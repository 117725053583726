import React, { useEffect } from 'react';

import { usePanelSidebarContext } from "../../contexts"

export const usePanelSidebar = (options = {}) => {
    const { setOptions } = usePanelSidebarContext();

    useEffect(() => {
        return () => {
            setOptions({});
        };
    }, []);

    useEffect(() => {
        setOptions(options);
    }, [options]);
};