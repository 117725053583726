import React from "react"
import PropTypes from "prop-types"
import { Alert } from "reactstrap"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import classNames from "classnames"

import { useUserAccess } from "hooks"

export const SubscriptionAccessAlert = ({ type = "alert", ...props }) => {
  const { t } = useTranslation()

  const { isDirector } = useUserAccess()

  const renderContent = () =>
    props.content || (
      <>
        {props.message || (
          <>
            {t("subscription.tariff-access-warning")}
            {"."}
          </>
        )}

        {isDirector() && (
          <>
            {" "}
            <Link
              to={"/billing/subscriptions"}
              className={"text-decoration-underline fw-bold"}
            >
              {t("upgrade")}
            </Link>
          </>
        )}
      </>
    )

  if (type === "text") {
    return <div>{renderContent()}</div>
  }

  const renderAlert = () => (
    <Alert color="secondary">
      {renderContent()}
    </Alert>
  )

  const renderText = () => (
    <div>{renderContent()}</div>
  )

  return (
    <div
      className={classNames({
        [props.wrapClass]: props.wrapClass,
      })}
    >
      {type === "text" ? renderText() : renderAlert()}
    </div>
  )
}

SubscriptionAccessAlert.propTypes = {
  message: PropTypes.any,
  link: PropTypes.bool,
  type: PropTypes.string,
  wrapClass: PropTypes.string,
  content: PropTypes.any,
}
