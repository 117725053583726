import React from "react"
import { Row, Col } from "reactstrap"

import { MegaTagTitle } from "components"
import { ActivitiesContainer } from "./components/ActivitiesComponents"

export const Activities = () => {
  return (
    <div className={"model-panel-activities"}>
      <MegaTagTitle title={"title.model.activities"} />
      <Row>
        <Col>
          <ActivitiesContainer />
        </Col>
      </Row>
    </div>
  )
}
