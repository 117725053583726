import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { ProfileInfoCard } from "./ProfileInfoCard"
import { EditProfile } from "./EditProfile"
import { EditLogo } from "./EditLogo"

const ProfileContainerComponent = props => {
  const [editProfileDrawerVisible, setEditProfileDrawerVisible] =
    useState(false)
  const [editAvatarDrawerVisible, setEditAvatarDrawerVisible] = useState(false)

  const handleEditProfile = () => {
    setEditProfileDrawerVisible(true)
  }

  const closeEditProfileDrawer = () => {
    setEditProfileDrawerVisible(false)
  }

  const handleEditAvatar = () => {
    setEditAvatarDrawerVisible(true)
  }

  const closeEditAvatarDrawer = () => {
    setEditAvatarDrawerVisible(false)
  }

  return (
    <>
      <ProfileInfoCard
        onEdit={handleEditProfile}
        data={props.client}
        onAvatarEdit={handleEditAvatar}
      />

      <EditProfile
        visible={editProfileDrawerVisible}
        onClose={closeEditProfileDrawer}
      />

      <EditLogo
        visible={editAvatarDrawerVisible}
        onClose={closeEditAvatarDrawer}
      />
    </>
  )
}

ProfileContainerComponent.propTypes = {
  client: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.client.profile.data
  return {
    client: data,
  }
}

export const ProfileContainer = connect(mapStateToProps)(
  ProfileContainerComponent
)
