import { combineReducers } from "redux";

import create from './create/reducer';
import list from './list/reducers';
import details from './details/reducers';
import rating from './rating/reducers';

const employeeReducer = combineReducers({
    create,
    list,
    details,
    rating
});

export default employeeReducer;