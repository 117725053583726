import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import {
  modelFetchPublishedBooks,
  modelFetchPublishedBooksCleanState,
} from "../../../../../store/actions"
import { BooksGrid } from "../BooksGrid"

const PublishedBookContainerComponent = props => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      props.modelFetchPublishedBooksCleanState()
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [props.syncCount])

  const fetchData = () => {
    const params = {
      per_page: 1,
      filters: {
        published: [true],
      },
    }
    props.modelFetchPublishedBooks(props.model.id, params)
  }

  return (
    <>
      <h4 className={"text-capitalize mb-3"}>
        {t("book_published_on_website")}
      </h4>

      <BooksGrid
        items={props.list}
        loading={props.listLoading}
        error={props.listError}
        meta={null}
        page={1}
        removingItems={props.removingItems}
        empty={t("no_published_books")}
        onRemove={props.onRemove}
        onEdit={props.onEdit}
        onPublish={props.onPublish}
        onCopy={props.onCopy}
        onDownload={props.onDownload}
        packagePickedBooks={props.packagePickedBooks}
        onAddToPackage={props.onAddToPackage}
        onSend={props.onSend}
        onCoverClick={props.onCoverClick}
      />
    </>
  )
}

PublishedBookContainerComponent.propTypes = {
  syncCount: PropTypes.number,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onPublish: PropTypes.func,
  onCopy: PropTypes.func,
  onDownload: PropTypes.func,
  packagePickedBooks: PropTypes.array,
  onAddToPackage: PropTypes.func,
  onSend: PropTypes.func,
  onCoverClick: PropTypes.func,

  model: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
  removingItems: PropTypes.array,

  modelFetchPublishedBooks: PropTypes.func,
  modelFetchPublishedBooksCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.model.profile
  const { list, listLoading, listError, meta, page } =
    state.modelPanel.book.published.list.data
  const { pending } = state.modelPanel.book.list.removeItem
  return {
    model: data,
    list,
    listLoading,
    listError,
    meta,
    page,
    removingItems: pending,
  }
}

const mapDispatchToProps = {
  modelFetchPublishedBooks,
  modelFetchPublishedBooksCleanState,
}

export const PublishedBookContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublishedBookContainerComponent)
