import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { DrawerContainer } from "components"
import { StatementSettingsDataLoader } from "../StatementSettingsDataLoader"
import { SettingsForm } from "./SettingsForm"
import { useSubmitHandler } from "hooks"
import { clientStatementSettingsEdit } from "store/actions"

export const StatementSettings = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const {
    settings,
    edit: { loading, error },
  } = useSelector(state => ({
    settings: state.client.statement.settings.data.data,
    edit: state.client.statement.settings.edit,
  }))

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        clientStatementSettingsEdit.edit({
          id: props.id,
          params,
          onSuccess,
          onError,
        })
      )
    },
    data => {
      props.onStatementsUpdate()
      props.onSuccess()
    }
  )

  const onDestroy = () => {
    dispatch(clientStatementSettingsEdit.cleanState())
    props.onDestroy && props.onDestroy()
  }

  return (
    <DrawerContainer
      title={t("settings")}
      visible={props.visible}
      onClose={props.onClose}
      onDestroy={onDestroy}
    >
      <StatementSettingsDataLoader id={props.id}>
        <SettingsForm
          loading={loading}
          error={error}
          onSubmit={handleSubmit}
          onCancel={props.onClose}
          initialValues={{
            ...settings,
          }}
        />
      </StatementSettingsDataLoader>
    </DrawerContainer>
  )
}

StatementSettings.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  onSuccess: PropTypes.func,
  id: PropTypes.number,
  onStatementsUpdate: PropTypes.func,
}
