import { all } from 'redux-saga/effects';

import removeSaga from './remove/saga';
import uploadSaga from './upload/saga';

function* attachmentsSaga() {
    yield all([
        removeSaga(),
        uploadSaga(),
    ])
}

export default attachmentsSaga;