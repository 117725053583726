import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  list: [],
  listLoading: false,
  listError: null,

  filters: {
    values: {
      type: null,
    },
  },
}

const calendarEventListSlice = createSlice({
  name: "calendarEventList",
  initialState,
  reducers: {
    getList(state) {
      state.listLoading = true
      state.listError = null
    },
    getListSuccess(state, action) {
      state.listLoading = false
      state.list = action.payload.data
    },
    getListError(state, action) {
      state.listLoading = false
      state.listError = action.payload
    },
    cleanState() {
      return initialState
    },
    addListItem(state, action) {
      state.list.push(action.payload)
    },
    updateListItem(state, action) {
      state.list = state.list.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          }
        } else {
          return item
        }
      })
    },
    removeListItem(state, action) {
      state.list = state.list.filter(item => item.id !== action.payload.id)
    },
    resetFetchedData(state) {
      state.list = []
      state.listLoading = false
      state.listError = null
    },
    changeFilters(state, action) {
      state.filters = {
        ...state.filters,
        values: {
          ...state.filters.values,
          ...action.payload,
        },
      }
    },
    resetFilters(state) {
      state.filters = initialState.filter
    },
  },
})

/**
 * @namespace
 * @property {function} getList
 * @property {function} getListSuccess
 * @property {function} getListError
 * @property {function} cleanState
 * @property {function} addListItem
 * @property {function} updateListItem
 * @property {function} removeListItem
 * @property {function} resetFetchedData
 * @property {function} changeFilters
 * @property {function} resetFilters
 */

export const calendarEventList = calendarEventListSlice.actions

export default calendarEventListSlice.reducer
