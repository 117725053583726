import React from "react"
import { Row, Col } from "reactstrap"

import { MegaTagTitle, SubscriptionAccessViewer } from "components"
import { RatingContainer } from "./components"

export const Rating = () => {
  return (
    <>
      <MegaTagTitle title={"title.client.rating"} />

      <SubscriptionAccessViewer>
        <div className={"rating-panel"}>
          <Row>
            <Col>
              <RatingContainer />
            </Col>
          </Row>
        </div>
      </SubscriptionAccessViewer>
    </>
  )
}
