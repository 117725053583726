import {
    DRAFT_PACKAGE_DATA_PICKER_SET_VALUE,
    DRAFT_PACKAGE_DATA_PICKER_CLEAN_VALUE,
    DRAFT_PACKAGE_DATA_PICKER_TOGGLE_BOOK,
    DRAFT_PACKAGE_DATA_PICKER_REMOVE_BOOK,
    DRAFT_PACKAGE_DATA_PICKER_UPDATE_BOOK,
    DRAFT_PACKAGE_DATA_PICKER_TOGGLE_VIDEO,
    DRAFT_PACKAGE_DATA_PICKER_REMOVE_VIDEO,
    DRAFT_PACKAGE_DATA_PICKER_UPDATE_VIDEO,
} from './actionTypes';

export const draftPackageDataPickerSetValue = (books, videos) => {
    return {
        type: DRAFT_PACKAGE_DATA_PICKER_SET_VALUE,
        payload: { books, videos },
    }
};

export const draftPackageDataPickerCleanValue = () => {
    return {
        type: DRAFT_PACKAGE_DATA_PICKER_CLEAN_VALUE,
    }
};

export const draftPackageDataPickerToggleBook = (data) => {
    return {
        type: DRAFT_PACKAGE_DATA_PICKER_TOGGLE_BOOK,
        payload: data,
    }
};

export const draftPackageDataPickerRemoveBook = (id) => {
    return {
        type: DRAFT_PACKAGE_DATA_PICKER_REMOVE_BOOK,
        payload: { id },
    }
};

export const draftPackageDataPickerUpdateBook = (id, data) => {
    return {
        type: DRAFT_PACKAGE_DATA_PICKER_UPDATE_BOOK,
        payload: { id, data },
    }
};

export const draftPackageDataPickerToggleVideo = (data) => {
    return {
        type: DRAFT_PACKAGE_DATA_PICKER_TOGGLE_VIDEO,
        payload: data,
    }
};

export const draftPackageDataPickerRemoveVideo = (id) => {
    return {
        type: DRAFT_PACKAGE_DATA_PICKER_REMOVE_VIDEO,
        payload: { id },
    }
};

export const draftPackageDataPickerUpdateVideo = (id, data) => {
    return {
        type: DRAFT_PACKAGE_DATA_PICKER_UPDATE_VIDEO,
        payload: { id, data },
    }
};