import React from "react"
import { useTranslation } from "react-i18next"

import { ComboboxFormField, ControllerHF } from "components"
import { useFormContext } from "react-hook-form"

export const IncomingPayments = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const options = [
    {
      label: t("paid"),
      value: true,
    },
    {
      label: t("unpaid"),
      value: false,
    },
  ]

  return (
    <ControllerHF
      name={"in_payments"}
      control={control}
      component={ComboboxFormField}
      id={"in_payments"}
      label={t("incoming_payments")}
      placeholder={t("incoming_payments")}
      options={options}
      normalize={option => (option !== null ? option.value : null)}
      isDetermineValue
      selectProps={{
        isSearchable: false,
        isClearable: true,
      }}
      wrapClass={"mb-0"}
    />
  )
}

IncomingPayments.propTypes = {}
