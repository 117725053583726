import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { CommonUtils, FormatUtils } from "utils"

export const LocationBlock = ({ locations }) => {
  let location = _.find(locations, { is_primary: true }) || locations[0]
  let { t } = useTranslation()
  let { country, state, town, street, zip, phone } = location
  let countryName = country?.name
  let locationInfoArray = [
    countryName,
    state,
    town,
    street,
    zip,
    FormatUtils.formatPhone(phone),
    {
      isLink: true,
      link_address: CommonUtils.generateGoogleMapLink(
        countryName,
        state,
        town,
        street
      ),
    },
  ]
  return (
    <div>
      <div className={"fw-bold"}>{t("location").toUpperCase()}</div>
      {locationInfoArray.map((value, index) => {
        if (value?.isLink) {
          return (
            <a
              key={`location-info-${index}`}
              className={"text-secondary p-0"}
              href={value.link_address}
              target={"blank"}
            >
              {t("see-on-google-map")}
            </a>
          )
        }

        return value && <div key={`location-info-${index}`}>{value}</div>
      })}
    </div>
  )
}

LocationBlock.propTypes = {
  locations: PropTypes.array,
}
