import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import classNames from "classnames"

import { CURRENCY_USD, CURRENCY_EUR, CURRENCY_GBP } from "consts"
import { commonHelper } from "helpers"

const currencyOptions = commonHelper.currency
  .getCurrencies()
  .filter(({ value }) =>
    [CURRENCY_USD, CURRENCY_EUR, CURRENCY_GBP].includes(value)
  )

const getCurrency = value =>
  commonHelper.currency.getCurrency(value)?.label?.toUpperCase()

export const TariffCurrencyDropdown = ({ value, onChange }) => {
  const { t } = useTranslation()

  const onClick = currency => {
    if (value !== currency) {
      onChange(currency)
    }
  }

  return (
    <UncontrolledDropdown>
      <DropdownToggle tag="button" className={classNames("btn btn-primary")}>
        {t("currency")}: {getCurrency(value)}{" "}
        <i className="mdi mdi-chevron-down" />
      </DropdownToggle>

      <DropdownMenu>
        {currencyOptions.map(item => (
          <DropdownItem key={item.value} onClick={() => onClick(item.value)}>
            {getCurrency(item.value)}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

TariffCurrencyDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}
