import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Drawer } from "../../../../../../components"
import { Container, Row } from "reactstrap"
import { ValidationsUtils } from "../../../../../../utils"
import { DocumentsForm } from "../DocumentsForm"
import { useTranslation } from "react-i18next"

import { clientUpdateDocument } from "../../../../../../store/actions"

const EditDocumentsContainerComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const handleCloseDrawer = () => {
    props.onClose()
  }
  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    props.onEditSuccess()
    handleCloseDrawer()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      clientUpdateDocument.update({
        id: props.document.id,
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  const onDestroy = () => {
    dispatch(clientUpdateDocument.cleanState())
    props.onDestroy()
  }
  return (
    <>
      <Drawer
        title={t("documents")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <Container fluid>
          <Row>
            <DocumentsForm
              onSubmit={handleSubmit}
              onCancel={handleCloseDrawer}
              initialValues={{ ...props.document }}
              loading={props.loading}
              error={props.error}
            />
          </Row>
        </Container>
      </Drawer>
    </>
  )
}

EditDocumentsContainerComponent.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  document: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  onDestroy: PropTypes.func,
  onEditSuccess: PropTypes.func,
  client: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.client.profile.data
  const { loading, error } = state.client.documents.add

  return {
    client: data,
    loading,
    error,
  }
}

export const EditDocuments = connect(mapStateToProps)(
  EditDocumentsContainerComponent
)
