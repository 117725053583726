import _ from "lodash"
import { modelHelper } from "helpers"
import moment from "moment"

const { rate: rateHelper } = modelHelper

export const isExcludedEventDateByModels = (date, fashionModels = []) => {
  let status = false

  if (fashionModels && !!fashionModels.length) {
    const rates = fashionModels.flatMap(model => model.rates || []) || []

    if (rates.length > 0) {
      const hasGeneral = !!_.find(rates, item =>
        rateHelper.isGeneralType(item.type)
      )

      // if models has not general type rate
      if (!hasGeneral) {
        const ratesOnDate = rates.filter(
          item => item.date === moment(date).format("YYYY-MM-DD")
        )

        return ratesOnDate.length === 0
      }
    }
  }

  return status
}

export const isExcludedEventDateByRates = (date, rates = []) => {
  let status = false

  // if model has rates
  if (rates.length > 0) {
    const hasGeneral = !!_.find(rates, item =>
      rateHelper.isGeneralType(item.type)
    )

    // if rates has not general type
    if (!hasGeneral) {
      const hasRateOnDate = !!_.find(
        rates,
        item => item.date === moment(date).format("YYYY-MM-DD")
      )

      // if no rate to the date
      if (!hasRateOnDate) {
        status = true
      }
    }
  }

  return status
}
