import { createSlice } from "@reduxjs/toolkit"
import { AGENCY_AGENCIES_SIDEBAR_FILTER_INITIAL_VALUES } from "../../../../consts/agencyPanel"

const initialState = {
  list: [],
  loading: false,
  error: null,
  meta: null,
  page: 1,
  searchInput: "",
  isFetched: false,
  filters: {
    values: AGENCY_AGENCIES_SIDEBAR_FILTER_INITIAL_VALUES,
  },
  syncCount: 0,
}

const agenciesSidebarSlice = createSlice({
  name: "agenciesSidebar",
  initialState,
  reducers: {
    getAgencies(state, action) {
      state.list = action.payload.params?.page !== 1 ? state.list : []
      state.meta = action.payload.params?.page !== 1 ? state.meta : null
      state.loading = true
      state.error = null
    },
    getAgenciesCompleted(state, action) {
      state.list =
        action.payload.meta.current_page === 1
          ? [...action.payload.data]
          : [...state.list, ...action.payload.data]
      state.meta = action.payload.meta
      state.loading = false
      state.isFetched = true
    },
    getAgenciesRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    changeSearchInput(state, action) {
      state.searchInput = action.payload
    },
    changeFilter(state, action) {
      state.filters.values = {
        ...state.filters.values,
        ...action.payload,
      }
    },
    sync(state) {
      state.syncCount = state.syncCount + 1
    },
    cleanState() {
      return initialState
    },
  },
})

export const agenciesSidebar = agenciesSidebarSlice.actions

export default agenciesSidebarSlice.reducer
