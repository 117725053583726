import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  prepared: false,
  loading: false,
  error: null,
}

const panelLoaderSlice = createSlice({
  name: "panelLoader",
  initialState,
  reducers: {
    loadData(state) {
      state.prepared = false
      state.loading = true
      state.error = null
    },
    loadDataSuccess(state, action) {
      state.loading = false
      state.prepared = true
    },
    loadDataError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} loadData
 * @property {function} loadDataSuccess
 * @property {function} loadDataError
 * @property {function} cleanState
 */
export const panelLoader = panelLoaderSlice.actions

export default panelLoaderSlice.reducer
