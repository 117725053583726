import React, { useReducer } from "react"

const INITIAL_STATE = {
  screen: null,
  screenData: null,
  config: {},
}

function reducer(state, action) {
  switch (action.type) {
    case "setScreen":
      return {
        ...state,
        ...action.payload,
      }
    case "resetScreen":
      return INITIAL_STATE
    default:
      return state
  }
}

function reducerInit(initialState) {
  return {
    ...INITIAL_STATE,
    ...initialState,
  }
}

export const useDrawerNavigator = (initialState = null) => {
  const [{ screenData, config, screen }, dispatch] = useReducer(
    reducer,
    initialState,
    reducerInit
  )

  const setScreen = (data = null) => {
    dispatch({ type: "setScreen", payload: data })
  }

  const resetScreen = () => {
    dispatch({ type: "resetScreen" })
  }

  const navigate = (screen, screenData = null, config = {}) => {
    setScreen({ screen, screenData, config })
  }

  const onScreenClose = () => {
    setScreen({ screen: null })
  }

  return {
    navigate,
    resetScreen,
    state: {
      screenData,
      config,
      screen,
    },
    onScreenClose,
  }
}
