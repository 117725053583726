import { combineReducers } from "redux"

import Layout from "./layout/reducer"
import auth from "./auth/reducers"
import user from "./user/reducers"
import profile from "./profile/reducers"
import employee from "./employee/reducers"
import models from "./models/reducers"
import model from "./model/reducers"
import modelPanel from "./modelPanel/reducers"
import agencies from "./agencies/reducers"
import agency from "./agency/reducers"
import packageReducers from "./package/reducers"
import sharedEntity from "./sharedEntity/reducers"
import clients from "./clients/reducers"
import client from "./client/reducers"
import website from "./website/reducers"
import calendarEvent from "./calendarEvent/reducers"
import expense from "./expense/reducers"
import staticReducers from "./static/reducers"
import analytics from "./analytics/reducers"
import multipleUpload from "./multipleUpload/slice"
import billing from "./billing/reducers"
import app from "./app/reducers"
import modalNotifications from "./modalNotifications/reducers"
import chat from "./chat/reducers"
import userIdeas from "./userIdeas/reducers"
import socialCalendar from "./socialCalendar/reducers"
import panel from "./panel/reducers"
import importReducer from "./import/reducers"
import socket from "./socket/reducers"
import department from "./department/reducers"
import compcard from "./compcard/reducers"
import iCal from "features/ical/store/reducers"

const rootReducer = combineReducers({
  Layout,
  auth,
  user,
  profile,
  employee,
  models,
  model,
  modelPanel,
  agencies,
  agency,
  package: packageReducers,
  sharedEntity,
  clients,
  client,
  website,
  calendarEvent,
  expense,
  static: staticReducers,
  analytics,
  multipleUpload,
  billing,
  app,
  modalNotifications,
  chat,
  userIdeas,
  socialCalendar,
  panel,
  import: importReducer,
  socket,
  department,
  compcard,
  iCal,
})

export default rootReducer
