import React from "react"
import PropTypes from "prop-types"

import { IconButton } from "components"

export const Edit = ({ onClick, disabled = false, ...props }) => {
  return (
    <IconButton
      name={"edit"}
      disabled={disabled}
      onClick={onClick}
      wrapClass={"ms-4"}
      bg
    />
  )
}

Edit.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}
