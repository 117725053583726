import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { EventInvoiceDrawer } from "../EventInvoiceDrawer"
import { EventInvoiceDataLoader } from "../EventInvoiceDataLoader"
import { EventInvoiceForm } from "../EventInvoiceForm"
import { useSubmitHandler } from "hooks"
import { calendarEventInvoiceEditSubmit } from "store/actions"

export const EventInvoiceEdit = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const {
    invoice,
    submit: { loading, error },
  } = useSelector(state => ({
    invoice: state.calendarEvent.invoice.edit.data.data,
    submit: state.calendarEvent.invoice.edit.submit,
  }))

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        calendarEventInvoiceEditSubmit.submit({
          id: props.eventId,
          params,
          onSuccess,
          onError,
        })
      )
    },
    () => {
      props.onSuccess()
    }
  )

  const onDestroy = () => {
    dispatch(calendarEventInvoiceEditSubmit.cleanState())
    props.onDestroy && props.onDestroy()
  }

  return (
    <EventInvoiceDrawer
      title={t("edit_invoice")}
      visible={props.visible}
      onClose={props.onClose}
      onDestroy={onDestroy}
    >
      <EventInvoiceDataLoader id={props.eventId} isEdit>
        <EventInvoiceForm
          loading={loading}
          error={error}
          onSubmit={handleSubmit}
          onCancel={props.onClose}
          initialValues={{
            ...invoice,
            client_country_id: invoice?.client_country || null,
          }}
          isEdit
        />
      </EventInvoiceDataLoader>
    </EventInvoiceDrawer>
  )
}

EventInvoiceEdit.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  onSuccess: PropTypes.func,
  eventId: PropTypes.number,
}
