import React from "react"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

import { InfoCard, StripeWrapper } from "components"
import { PayForm } from "./PayForm"

export const PaySection = props => {
  const { t } = useTranslation()

  return (
    <StripeWrapper>
      <Row>
        <Col>
          <InfoCard title={t("pay")}>
            <PayForm paymentInfo={props.paymentInfo || {}} />
          </InfoCard>
        </Col>
      </Row>
    </StripeWrapper>
  )
}

PaySection.propTypes = {
  paymentInfo: PropTypes.object,
}
