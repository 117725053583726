/* eslint react/prop-types: off */
import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Row, Col, Card, CardBody } from "reactstrap"
import * as HOC from "HOC"
import { IconButton, Pagination, ReactTableView, TextButton } from "components"

const TableDataLoading = HOC.withTableDataLoading()

export const RequestsTable = props => {
  const { t } = useTranslation()

  const data = useMemo(() => {
    return props.list
  }, [props.list])

  const [columns, setColumns] = useState([
    {
      Header: t("name"),
      accessor: "name",
      id: "name",
      Cell: ({ value }) => value,
    },
    {
      Header: t("email"),
      accessor: "email",
      id: "email",
      Cell: ({ value }) => value,
    },
    {
      Header: t("mobile_phone"),
      accessor: "mobile_phone",
      id: "mobile_phone",
      Cell: ({ value }) => value,
    },
    {
      Header: t("country-and-city"),
      accessor: "country-and-city",
      id: "country-and-City",
      Cell: ({ row: { original } }) => {
        return `${original.country.name}, ${original.city}`
      },
    },
    {
      Header: t("details"),
      accessor: "details",
      id: "details",
      Cell: ({ row: { original } }) => {
        return (
          <TextButton
            title={"show-details"}
            onClick={() => props.onShowDetails(original)}
          />
        )
      },
    },
    {
      Header: "",
      accessor: "remove",
      id: "remove",
      Cell: ({ row: { original } }) => {
        return (
          <div className={"d-flex justify-content-end"}>
            <IconButton
              name={"remove"}
              bg
              onClick={() => props.onRemove(original)}
              disabled={props.removingLoading}
            />
          </div>
        )
      },
    },
  ])

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody className={"bg-neutral"}>
            <TableDataLoading
              isLoading={props.loading}
              isFailed={props.error}
              error={props.error}
            >
              <ReactTableView
                isLoading={props.loading}
                columns={columns}
                data={data}
              />

              <Pagination
                data={props.meta}
                onPageChange={props.onPageChange}
                className={"mt-2"}
              />
            </TableDataLoading>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

RequestsTable.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,
  onRemove: PropTypes.func,
  onPageChange: PropTypes.func,
  removingLoading: PropTypes.bool,
  onShowDetails: PropTypes.func,
}
