import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { billingBillingInfoEdit } from "./slice"
import { billingBillingInfoData } from "../data/slice"

function* edit({ payload: { params, onSuccess, onError } }) {
  try {
    const response = yield call(API.billing.editBillingInfo, params)

    const { data } = response

    yield put(billingBillingInfoEdit.editSuccess())

    yield put(billingBillingInfoData.updateData(data?.data))

    toastr.success(i18n.t('billing_info.success.saved'));

    if (onSuccess) {
      onSuccess(data?.data)
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      billingBillingInfoEdit.editError({
        errors,
        message,
      })
    )
    if (onError) {
      onError(errors)
    }
  }
}

export function* watchEdit() {
  yield takeEvery(billingBillingInfoEdit.edit, edit)
}

function* editSaga() {
  yield all([fork(watchEdit)])
}

export default editSaga
