import {
    MODEL_PANEL_GENERAL_ADD_DOCUMENT,
    MODEL_PANEL_GENERAL_ADD_DOCUMENT_SUCCESS,
    MODEL_PANEL_GENERAL_ADD_DOCUMENT_ERROR,
    MODEL_PANEL_GENERAL_ADD_DOCUMENT_CLEAN_STATE,
} from './actionTypes';

export const modelPanelGeneralAddDocument = (id, params, onSuccess, onError) => {
    return {
        type: MODEL_PANEL_GENERAL_ADD_DOCUMENT,
        payload: { id, params, onSuccess, onError },
    }
};

export const modelPanelGeneralAddDocumentSuccess = () => {
    return {
        type: MODEL_PANEL_GENERAL_ADD_DOCUMENT_SUCCESS,
    }
};

export const modelPanelGeneralAddDocumentError = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_ADD_DOCUMENT_ERROR,
        payload: data,
    }
};

export const modelPanelGeneralAddDocumentCleanState = () => {
    return { type: MODEL_PANEL_GENERAL_ADD_DOCUMENT_CLEAN_STATE };
};