import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "../../../../../i18n"
import { MODEL_PANEL_GENERAL_ADD_LOGISTICS } from "./actionTypes"
import {
  modelPanelGeneralAddLogisticsSuccess,
  modelPanelGeneralAddLogisticsError,
} from "./actions"

import { API } from "../../../../../api"

function* addLogistics({ payload: { id, params, onSuccess, onError } }) {
  try {
    const response = yield call(API.model.addModelLogistics, id, params)

    const { data } = response

    yield put(modelPanelGeneralAddLogisticsSuccess())

    toastr.success(i18n.t("model-logistics.add.success"))

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      modelPanelGeneralAddLogisticsError({
        errors,
        message,
      })
    )

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchAddLogistics() {
  yield takeEvery(MODEL_PANEL_GENERAL_ADD_LOGISTICS, addLogistics)
}

function* addSaga() {
  yield all([fork(watchAddLogistics)])
}

export default addSaga
