import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Modal, Button } from "components"
import { ICalUrlsContainer } from "./ICalUrlsContainer"

export const ICalUrlsManager = ({ modalRef, onCancel }) => {
  const { t } = useTranslation()

  return (
    <Modal size={"md"} ref={modalRef} title={t("sync_to_calendar")}>
      <ICalUrlsContainer />

      <div className="mt-3">
        <div className="button-items">
          <Button title={"cancel"} color="light" outline onClick={onCancel} />
        </div>
      </div>
    </Modal>
  )
}

ICalUrlsManager.propTypes = {
  modalRef: PropTypes.any,
  onCancel: PropTypes.func,
}
