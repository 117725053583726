import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import moment from "moment"
import { useDeepCompareEffect } from "react-use"

import { calendarEventList } from "store/actions"
import { EventsCard } from "./EventsCard"
import { EVENT_TYPE_JOB } from "consts"
import { EventsFilter } from "./EventsFilter"
import { useFetchData } from "../../../../../hooks"
import { API } from "../../../../../api"
import toastr from "toastr"
import { useCalendarEventPayment } from "features/calendarEvent/CalendarEvent/hooks"

const defaultPeriod = [
  moment().startOf("month").toDate(),
  moment().endOf("month").toDate(),
]

const EventsContainerComponent = props => {
  const dispatch = useDispatch()

  const [filter, setFilter] = useState({
    period: defaultPeriod,
    booker: null,
    is_closed: null,
    client: null,
    product: "",
    fashion_models: [],
    agencies: [],
    rates_sum_min: "",
    rates_sum_max: "",
    out_payments: null,
    in_payments: null,
  })

  const [date_start, date_end] = filter.period

  const onFilterChange = value => {
    setFilter(prevState => ({
      ...prevState,
      ...value,
    }))
  }

  useEffect(() => {
    return () => {
      dispatch(calendarEventList.resetFetchedData())
    }
  }, [])

  useDeepCompareEffect(() => {
    if (date_start && date_end) {
      fetchEvents()
    }
  }, [filter])

  const getMappedParams = () => {
    let params = {
      type: EVENT_TYPE_JOB,
      includes: [
        "eventClient.client",
        "agenciesWithPaymentStatus",
        "eventModelsWithPaymentStatus",
        "agencyStatements",
        "fashionModelStatements",
        "eventModelRatesSum",
        "clientStatement",
        "clientWithPaymentStatus",
      ],
      date_start: moment(date_start).format("YYYY-MM-DD"),
      date_end: moment(date_end).format("YYYY-MM-DD"),
    }

    const {
      booker,
      is_closed,
      client,
      product,
      fashion_models,
      agencies,
      rates_sum_min,
      rates_sum_max,
      out_payments,
      in_payments,
    } = filter

    const fashionModelsIds = fashion_models.map(item => item.id) || []
    const agenciesIds = agencies.map(item => item.id) || []

    params = {
      ...params,
      booker,
      is_closed,
      client,
      product: product.trim() ? product.trim() : null,
      fashion_models: fashionModelsIds,
      agencies: agenciesIds,
      rates_sum_min: rates_sum_min !== "" ? rates_sum_min : null,
      rates_sum_max: rates_sum_max !== "" ? rates_sum_max : null,
      out_payments,
      in_payments,
    }

    return params
  }

  const fetchEvents = () => {
    const params = getMappedParams()

    dispatch(calendarEventList.getList({ params }))
  }

  // === Export === //
  const { fetchData } = useFetchData()

  const onExport = async () => {
    try {
      const params = getMappedParams()

      const request = API.calendar.getEvents(
        {
          ...params,
          export: true,
        },
        {
          responseType: "blob",
        }
      )

      const result = await fetchData({
        request,
      })

      if (result) {
        const href = URL.createObjectURL(result)

        const link = document.createElement("a")
        link.href = href
        link.setAttribute("download", `exported-events.xlsx`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        URL.revokeObjectURL(href)
      }
    } catch (error) {
      toastr.error(error?.message || "")
    }
  }
  // === //

  const { onPayment } = useCalendarEventPayment()

  const handlePayment = async calendarEvent => {
    try {
      await onPayment({ id: calendarEvent.id })

      fetchEvents()
    } catch (error) {}
  }

  return (
    <>
      <EventsCard
        list={props.list}
        listLoading={props.listLoading}
        listError={props.listError}
        onPayment={handlePayment}
        toolbar={
          <EventsFilter
            filter={filter}
            onFilterChange={onFilterChange}
            onExport={onExport}
          />
        }
      />
    </>
  )
}

EventsContainerComponent.propTypes = {
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
}

const mapStateToProps = state => {
  const { list, listLoading, listError } = state.calendarEvent.list
  return {
    list,
    listLoading,
    listError,
  }
}

export const EventsContainer = connect(mapStateToProps)(
  EventsContainerComponent
)
