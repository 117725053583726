import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from "toastr";
import _ from "lodash";

import i18n from '../../../../../i18n';
import { MODEL_PANEL_RATING_REMOVE_RATING } from './actionTypes';
import { modelPanelRatingRemoveRatingSuccess, modelPanelRatingRemoveRatingError } from './actions';
import { modelProfileUpdateData } from '../../../../model/profile/actions';

import { API } from '../../../../../api';

function* removeRating({ payload: { id, onSuccess } }) {
    try {
        const response = yield call(API.rating.removeRating, id);

        const { data } = response;

        yield put(modelPanelRatingRemoveRatingSuccess(id));

        if (_.has(data.data, 'avg_rating')) {
            yield put(modelProfileUpdateData({ avg_rating: data?.data?.avg_rating }));
        }

        toastr.success(i18n.t('rating.remove.success'));

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        const message = error.response?.data?.message || i18n.t('error');

        yield put(modelPanelRatingRemoveRatingError(id));

        toastr.error(message);
    }
}

export function* watchRemoveRating() {
    yield takeEvery(MODEL_PANEL_RATING_REMOVE_RATING, removeRating);
}

function* removeSaga() {
    yield all([
        fork(watchRemoveRating),
    ]);
}

export default removeSaga;