import {
  MODEL_PANEL_MODELS_SIDEBAR_FETCH_LIST,
  MODEL_PANEL_MODELS_SIDEBAR_FETCH_LIST_SUCCESS,
  MODEL_PANEL_MODELS_SIDEBAR_FETCH_LIST_ERROR,
  MODEL_PANEL_MODELS_SIDEBAR_LIST_CLEAN_STATE,
  MODEL_PANEL_MODELS_SIDEBAR_LIST_SET_PAGE,
  MODEL_PANEL_MODELS_SIDEBAR_LIST_CHANGE_SEARCH_INPUT,
  MODEL_PANEL_MODELS_SIDEBAR_LIST_CHANGE_FILTER,
  MODEL_PANEL_MODELS_SIDEBAR_LIST_RESET_FILTER,
  MODEL_PANEL_MODELS_SIDEBAR_LIST_RESET_FETCHED_DATA,
  MODEL_PANEL_MODELS_SIDEBAR_LIST_SYNC,
} from "./actionTypes"

import { MODEL_PANEL_MODELS_SIDEBAR_FILTER_INITIAL_VALUES } from "../../../../consts"

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  meta: null,
  isFetched: false,

  searchInput: "",

  filters: {
    values: MODEL_PANEL_MODELS_SIDEBAR_FILTER_INITIAL_VALUES,
  },

  syncCount: 0,
}

const modelsSidebar = (state = initialState, action) => {
  switch (action.type) {
    case MODEL_PANEL_MODELS_SIDEBAR_FETCH_LIST:
      state = {
        ...state,
        list: action.payload.params?.page !== 1 ? state.list : [],
        meta: action.payload.params?.page !== 1 ? state.meta : null,
        listLoading: true,
        listError: null,
        isFetched: false,
      }
      break

    case MODEL_PANEL_MODELS_SIDEBAR_FETCH_LIST_SUCCESS:
      state = {
        ...state,
        listLoading: false,
        list:
          action.payload.meta.current_page === 1
            ? [...action.payload.data]
            : [...state.list, ...action.payload.data],
        meta: action.payload.meta,
        isFetched: true,
      }
      break

    case MODEL_PANEL_MODELS_SIDEBAR_FETCH_LIST_ERROR:
      state = {
        ...state,
        listLoading: false,
        listError: action.payload,
      }
      break

    case MODEL_PANEL_MODELS_SIDEBAR_LIST_CLEAN_STATE:
      state = {
        ...state,
        ...initialState,
      }
      break

    case MODEL_PANEL_MODELS_SIDEBAR_LIST_CHANGE_SEARCH_INPUT:
      state = {
        ...state,
        searchInput: action.payload,
      }
      break

    case MODEL_PANEL_MODELS_SIDEBAR_LIST_CHANGE_FILTER:
      state = {
        ...state,
        filters: {
          values: {
            ...state.filters.values,
            ...action.payload,
          },
        },
      }
      break

    case MODEL_PANEL_MODELS_SIDEBAR_LIST_RESET_FILTER:
      state = {
        ...state,
        filters: initialState.filter,
      }
      break

    case MODEL_PANEL_MODELS_SIDEBAR_LIST_RESET_FETCHED_DATA:
      state = {
        ...state,
        list: [],
        listLoading: false,
        listError: null,
        meta: null,
        isFetched: false,
      }
      break

    case MODEL_PANEL_MODELS_SIDEBAR_LIST_SYNC:
      state = {
        ...state,
        syncCount: state.syncCount + 1,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default modelsSidebar
