import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { BarChart, ChartCard } from "components"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { analyticsGraphsList } from "store"
import { GRAPH_PERIOD_TYPE } from "consts"
import { analyticsHelper } from "helpers"

const defaultPeriodType = GRAPH_PERIOD_TYPE.MONTH

const { getPeriodData } = analyticsHelper.period
const { formatStatsData } = analyticsHelper.stats
const { generateCustomOptions } = analyticsHelper.options
const { generateTopTenTooltipTitles } = analyticsHelper.names

export const TopRatingContainer = ({ graphData }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const customChartOptions = generateCustomOptions(graphData.alias)
  const { name } = generateTopTenTooltipTitles(graphData.alias)

  useEffect(() => {
    let { params } = getPeriodData(defaultPeriodType)
    fetchGraphData(params)
  }, [])

  const fetchGraphData = params => {
    dispatch(
      analyticsGraphsList.getGraphStatsData({
        alias: graphData.alias,
        tab: graphData.tab,
        params: {
          ...params,
          tab: graphData.tab,
        },
      })
    )
  }

  const [charData, setCharData] = useState([])

  useEffect(() => {
    if (graphData.stats.data) {
      let formattedData = formatStatsData(graphData.alias, graphData.stats.data)
      setCharData(formattedData)
    } else {
      setCharData([])
    }
  }, [graphData.stats.data])

  const handlePeriodChange = useCallback(
    params => {
      fetchGraphData(params)
    },
    [fetchGraphData]
  )
  return (
    <ChartCard graphData={graphData} onPeriodChange={handlePeriodChange}>
      <BarChart
        isData={!!charData.length}
        seriesData={[{ name: t(name), data: charData }]}
        optionsData={customChartOptions}
        loading={graphData.stats.loading}
        error={graphData.stats.error}
      />
    </ChartCard>
  )
}

TopRatingContainer.propTypes = {
  graphData: PropTypes.object,
}
