import React from "react"
import PropTypes from "prop-types"
import { Popover } from "components/Popover"
import { SocialNetworkButton } from "components/SocialNetwork"
import { PublishMediaBox } from "./PublishMediaBox"

export const PublishPopover = ({
  popoverId,
  popoverOpen,
  toggle,
  socialNetworkList,
  onSocialButtonClick,
}) => {
  return (
    <Popover popoverId={popoverId} popoverOpen={popoverOpen} toggle={toggle}>
      <PublishMediaBox>
        {socialNetworkList.map(socialNetwork => {
          return (
            <SocialNetworkButton
              key={socialNetwork}
              onClick={onSocialButtonClick(socialNetwork)}
              name={socialNetwork}
            />
          )
        })}
      </PublishMediaBox>
    </Popover>
  )
}

PublishPopover.propTypes = {
  popoverId: PropTypes.any,
  popoverOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  socialNetworkList: PropTypes.array.isRequired,
  onSocialButtonClick: PropTypes.func.isRequired,
}
