import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import { v4 as uuidv4 } from "uuid"

import i18n from "../../../i18n"

import { LOGIN_USER, LOGIN_SEND_VERIFY_EMAIL } from "./actionTypes"
import { loginUserSuccess, loginUserError } from "./actions"
import { setAuthData } from "../data/actions"

import { API } from "../../../api"

function* loginUser({ payload: { params, onSubmitProps } }) {
  try {
    const secret = uuidv4()

    params = {
      ...params,
      secret,
    }

    const response = yield call(API.auth.signIn, params)

    const { data } = response
    yield put(loginUserSuccess(true))

    yield put(setAuthData(true, secret))
  } catch (error) {
    const errors = error.response?.data?.errors
      ? error.response.data.errors
      : {}
    const message = error.response?.data?.message
      ? error.response.data.message
      : i18n.t("error")

    yield put(
      loginUserError({
        errors,
        message,
      })
    )

    const { setErrors, setSubmitting } = onSubmitProps
    setSubmitting(false)
    setErrors(errors)
  }
}

function* loginSendVerifyEmail({ payload: { params } }) {
  try {
    const response = yield call(API.auth.sendVerifyEmail, params)

    const { data } = response

    toastr.success(i18n.t("confirmation.email.sent"))
  } catch (error) {
    const message = error.response?.data?.message
      ? error.response.data.message
      : i18n.t("error")

    toastr.error(message)
  }
}

export function* watchLogin() {
  yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchSendVerifyEmail() {
  yield takeEvery(LOGIN_SEND_VERIFY_EMAIL, loginSendVerifyEmail)
}

function* loginSaga() {
  yield all([fork(watchLogin), fork(watchSendVerifyEmail)])
}

export default loginSaga
