import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Col, Row } from "reactstrap"
import * as Yup from "yup"
import {
  Button,
  CheckboxField,
  ControllerHF,
  InputField,
} from "../../../../../../components"
import { NormalizeUtils, ValidationsUtils } from "../../../../../../utils"

export const AddressForm = props => {
  const { t } = useTranslation()

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      billing_type: false,
      main_type: false,
      street: "",
      town: "",
      phone: "",
      email: "",
      zip: "",
      ...props.initialValues,
    },
    resolver: yupResolver(
      Yup.object().shape({
        street: Yup.string().required("field.error.required"),
        town: Yup.string().required("field.error.required"),
        phone: Yup.string()
          .required("field.error.required")
          .test("phone", "field.error.invalid", ValidationsUtils.phoneFormat),

        email: Yup.string()
          .email("field.error.invalid")
          .required("field.error.required"),
        zip: Yup.string().nullable(true),
      })
    ),
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
      phone: values.phone.replace(/[^0-9]/g, ""),
    }

    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={8}>
            <Row>
              <Col xs={1}>
                <ControllerHF
                  name={"main_type"}
                  control={control}
                  component={CheckboxField}
                  id={"main_type"}
                  label={t("main-type")}
                  placeholder={t("main-type")}
                  size={"lg"}
                />
              </Col>
              <Col xs={{ size: 1, offset: 3 }} md={{ offset: 1 }}>
                <ControllerHF
                  name={"billing_type"}
                  control={control}
                  component={CheckboxField}
                  id={"billing_type"}
                  label={t("billing-type")}
                  placeholder={t("billing-type")}
                  size={"lg"}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ControllerHF
                  name={"town"}
                  control={control}
                  component={InputField}
                  id={"town"}
                  label={t("town")}
                  placeholder={t("town")}
                />
              </Col>
              <Col md={6}>
                <ControllerHF
                  name={"street"}
                  control={control}
                  component={InputField}
                  id={"street"}
                  label={t("street")}
                  placeholder={t("street")}
                />
              </Col>
            </Row>
            <ControllerHF
              name={"zip"}
              control={control}
              component={InputField}
              id={"zip"}
              label={t("zip")}
              placeholder={t("zip")}
            />
            <ControllerHF
              name={`email`}
              control={control}
              component={InputField}
              id={`email`}
              label={t("field.label.email")}
              placeholder={t("field.placeholder.email")}
              type="email"
            />
            <ControllerHF
              name={`phone`}
              control={control}
              component={InputField}
              id={`phone`}
              label={t("field.label.phone")}
              placeholder={t("field.placeholder.phone")}
              normalize={NormalizeUtils.phone}
            />
          </Col>
          <Col />
        </Row>
        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button title={"cancel"} color="light" outline onClick={onCancel} />
          </div>
        </div>
      </form>
    </>
  )
}

AddressForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
