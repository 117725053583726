import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../../i18n';
import { MODEL_PANEL_DELETE_MODEL } from './actionTypes';
import { modelPanelDeleteModelSuccess, modelPanelDeleteModelError } from './actions';

import { API } from '../../../../api';

function* deleteModel({ payload: { id, history } }) {
    try {
        const response = yield call(API.model.removeModel, id);

        const { data } = response;

        yield put(modelPanelDeleteModelSuccess());

        toastr.success(i18n.t('model.delete.success'));

        history.push('/models');
    } catch (error) {
        const errors = error.response?.data?.errors || {};
        const message = error.response?.data?.message || i18n.t('error');

        yield put(modelPanelDeleteModelError({
            errors,
            message
        }));

        toastr.error(message);
    }
}

export function* watchDeleteModel() {
    yield takeEvery(MODEL_PANEL_DELETE_MODEL, deleteModel);
}

function* deleteSaga() {
    yield all([
        fork(watchDeleteModel),
    ]);
}

export default deleteSaga;