import { FacebookPermissions } from "../packages/facebook"

const FBconfig = {
  permissions: FacebookPermissions.REQUIRED_PERMISSIONS,
  appId: process.env.REACT_APP_FACEBOOK_APP_ID,
  cookie: true,
  xfbml: true,
  version: "v20.0",
  src: "https://connect.facebook.net/en_US/sdk.js",
}

export { FBconfig }
