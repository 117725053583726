import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"
import { Employees } from "./Employees"
import { EmployeeDetails } from "./EmployeeDetails"

export const TeamIndex = () => {
  let { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <Employees />
      </Route>
      <Route path={`${path}/:id`}>
        <EmployeeDetails />
      </Route>
      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  )
}
