import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"

import { ControllerHF, InputField } from "../../../../../../../components"

export const ModelAgencyNotesSection = props => {
  const { t } = useTranslation()

  return (
    <Row>
      <Col md={4}>
        <ControllerHF
          name={"model_notes"}
          control={props.control}
          component={InputField}
          id={"model_notes"}
          label={t("notes_for_model")}
          placeholder={t("notes_for_model")}
          type={"textarea"}
        />
      </Col>

      <Col md={4}>
        <ControllerHF
          name={"agency_notes"}
          control={props.control}
          component={InputField}
          id={"agency_notes"}
          label={t("notes_for_agency")}
          placeholder={t("notes_for_agency")}
          type={"textarea"}
        />
      </Col>
    </Row>
  )
}

ModelAgencyNotesSection.propTypes = {
  control: PropTypes.object,
}
