export const STATEMENT_TYPE = {
  STATEMENT: "statement",
  PAYMENT: "payment",
}

export const STATEMENT_PAYMENT_TYPE = {
  PAID: "paid",
  UNPAID: "unpaid",
}

export const STATEMENT_PAYMENT_STATUS = {
  UNPAID: "unpaid",
  PENDING: "pending",
  PAID: "paid",
}
