import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { connect, useDispatch } from "react-redux"

import { ContentHeader } from "../ContentHeader"
import { ChatsList } from "./ChatsList"
import { AddGroup } from "./AddGroup"

import { chatChatsList, chatConversationData } from "store/actions"
import {
  selectChatsSortList,
  selectConversationParticipantId,
  selectConversationGroupChatId,
} from "store/selectors"

const ChatsContainerComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    fetchData()

    return () => {
      dispatch(chatChatsList.cleanState())
    }
  }, [])

  const fetchData = () => {
    dispatch(chatChatsList.getList())
  }

  const onChatSelect = item => {
    dispatch(chatConversationData.initChat(item))
  }

  return (
    <>
      <ContentHeader title={t("chats")} headerEnd={<AddGroup />} />

      <ChatsList
        items={props.list}
        loading={props.listLoading}
        error={props.listError}
        onSelect={onChatSelect}
        participantId={props.participantId}
        groupChatId={props.groupChatId}
      />
    </>
  )
}

ChatsContainerComponent.propTypes = {
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  participantId: PropTypes.number,
  groupChatId: PropTypes.number,
}

const mapStateToProps = state => {
  const { listLoading, listError } = state.chat.chats.list

  return {
    list: selectChatsSortList(state),
    listLoading,
    listError,
    participantId: selectConversationParticipantId(state),
    groupChatId: selectConversationGroupChatId(state),
  }
}

export const ChatsContainer = connect(mapStateToProps)(ChatsContainerComponent)
