import React, { useRef, useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Modal } from "../../../Modal"
import { Button } from "../../../Buttons"
import { RateForm } from "../../../Forms"
import { ValidationsUtils } from "../../../../utils"
import { modelHelper } from "../../../../helpers"
import { RatesDetails } from "../RatesDetails"
import { OverlayBlockLoading, OverlayLoading } from "../../../OverlayLoading"
import { RATE_TYPE_EACH_DAY } from "../../../../consts"
import { useFetchData } from "../../../../hooks"
import { API } from "../../../../api"

export const RatesContainer = props => {
  const { t } = useTranslation()

  const modalRef = useRef(null)

  const [isEdit, setIsEdit] = useState(false)
  const [rate, setRate] = useState(null)

  const onSubmitSuccess = onSubmitProps => () => {
    const { onReset } = onSubmitProps
    closeModal()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const onSubmit = (values, onSubmitProps) => {
    const payload = {
      onSuccess: onSubmitSuccess(onSubmitProps),
      onError: onSubmitError(onSubmitProps),
    }

    if (!isEdit) {
      let items = [values]

      if (values.type === RATE_TYPE_EACH_DAY) {
        items = modelHelper.rate.generateRatesForEachDay(
          values,
          props.datePeriod
        )
      }

      props.onAdd(items, payload)
    } else {
      props.onEdit(values, payload)
    }
  }

  const handleAdd = e => {
    e.preventDefault()
    isEdit && setIsEdit(false)
    if (modalRef.current) {
      modalRef.current.show()
    }
  }

  const handleEdit = item => {
    setIsEdit(true)
    if (modalRef.current) {
      modalRef.current.show()
    }
    setRate({ ...item })
  }

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.hide()
    }
  }

  const onModalClosed = () => {
    setRate(null)
  }

  const [modalVisible, setModalVisible] = useState(false)

  // Fetch model data for from initial value
  const modelFetch = useFetchData()
  const modelData = modelFetch?.data?.data || null

  const onModelFetch = async () => {
    const request = API.model.getModel(props.modelId)
    await modelFetch.fetchData({
      request,
    })
  }

  useEffect(() => {
    if (props.modelId && modalVisible && !isEdit && !modelData) {
      onModelFetch()
    }
  }, [props.modelId, modalVisible, isEdit, modelData])
  // === //

  const initialValues = useMemo(() => {
    if (isEdit && rate) {
      return rate
    }

    if (modelData && modelData?.percentage_mother) {
      const values = {
        value: 0, // default value
        count: 1, // default value
        model_commission: modelData?.percentage_mother || 40, // default value
      }
      const { total_fee, net_for_model } =
        modelHelper.rate.getRateCalculatedData(values)

      return {
        ...values,
        total_fee,
        net_for_model,
      }
    }

    return null
  }, [rate, isEdit, modelData])

  return (
    <div className="">
      <Button title={"btn.add-rate"} onClick={handleAdd} />

      {props.value && props.value.length > 0 ? (
        <OverlayBlockLoading isLoading={props.generalLoading}>
          <RatesDetails
            data={modelHelper.rate.mapRatesData(props.value)}
            onEdit={handleEdit}
            onRemove={props.onRemove}
            currency={props.currency}
          />
        </OverlayBlockLoading>
      ) : (
        <div className={"mt-3"}>{t("no_rates")}</div>
      )}

      <Modal
        ref={modalRef}
        title={t("rate")}
        onVisibleChange={value => setModalVisible(value)}
        strapModalProp={{
          centered: true,
          onClosed: onModalClosed,
        }}
      >
        {!isEdit && modelFetch.loading && !modelFetch.fetched ? (
          <OverlayLoading isLoading={true} />
        ) : (
          <RateForm
            isEdit={isEdit}
            loading={props.loading}
            error={props.error}
            onSubmit={onSubmit}
            onCancel={closeModal}
            initialValues={{ ...initialValues }}
            currency={props.currency}
            modelId={props.modelId}
            onFormDestroy={props.onFormDestroy}
          />
        )}
      </Modal>
    </div>
  )
}

RatesContainer.propTypes = {
  value: PropTypes.any,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.any,
  className: PropTypes.string,
  currency: PropTypes.string,
  generalLoading: PropTypes.bool,
  datePeriod: PropTypes.object,
  modelId: PropTypes.number,
  onFormDestroy: PropTypes.func,
}
