import { all } from "redux-saga/effects"

import createSaga from "./create/saga"
import editSaga from "./edit/sagas"
import removeSaga from "./remove/saga"
import sendEmailSaga from "./sendEmail/saga"

function* contractSaga() {
  yield all([createSaga(), editSaga(), removeSaga(), sendEmailSaga()])
}

export default contractSaga
