import React from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import classNames from "classnames"

import { Attachment } from "./Attachment"

export const Message = ({ item, ...props }) => {
  const dispatch = useDispatch()

  const { data: user } = useSelector(state => state.user.profile)

  return (
    <>
      <div className="ctext-wrap">
        {!item.is_my && (
          <div className="conversation-name">{item?.user?.name}</div>
        )}

        {item?.files &&
          !!item?.files.length &&
          item.files.map(item => (
            <Attachment item={item} key={"message-attachment-" + item.id} />
          ))}

        {item?.body && <p className={"text-start"}>{item?.body}</p>}

        <div className={"d-flex w-100 justify-content-between"}>
          <p className="chat-time mb-0 text-gray">
            <i className="bx bx-time-five align-middle me-1" />
            {moment.utc(item.created_at).local().format("DD/MM/YY HH:mm")}
          </p>

          {item.is_my && (
            <i
              className={classNames("bx ms-2 font-size-18 text-secondary", {
                "bx-check": !item.seen,
                "bx-check-double": item.seen,
              })}
            />
          )}
        </div>
      </div>
    </>
  )
}

Message.propTypes = {
  item: PropTypes.object,
}
