import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { Contracts } from "./Contracts"
import { useConfirmModal, useDrawer, useUserAccess } from "hooks"
import { ConfirmPopup, EventContractSendEmail } from "components"
import { calendarEventContractRemove } from "store"
import { FileUtils } from "utils"
import { DEPARTMENT_ACCOUNTING } from "consts"

export const ContractsContainer = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isDirector, isDepartmentAllow } = useUserAccess()

  //======================= REMOVE LOGIC ==============================//
  const { loading: removing } = useSelector(
    state => state.calendarEvent.contract.remove
  )

  const { modalRef, showModal, closeModal, handleConfirm } = useConfirmModal(
    item => {
      dispatch(
        calendarEventContractRemove.remove({
          id: props.event?.id,
          onSuccess: props.onRemoveSuccess,
        })
      )
    },
    () => {}
  )
  // === //

  useEffect(() => {
    return () => {
      dispatch(calendarEventContractRemove.cleanState())
    }
  }, [])

  const onDownload = url => {
    FileUtils.downloadFileByLink(url)
  }

  // Send email contract
  const {
    hide: hideSendDrawer,
    show: showSendDrawer,
    visible: sendDrawerVisible,
  } = useDrawer()

  const onSendSuccess = () => {
    hideSendDrawer()
  }

  const isSendAllow = isDirector() || isDepartmentAllow([DEPARTMENT_ACCOUNTING])
  // === //

  return (
    <>
      <Contracts
        data={[props.event?.contract]}
        onEdit={props.onEdit}
        onRemove={showModal}
        onDownload={onDownload}
        removing={removing}
        send={isSendAllow}
        onSend={showSendDrawer}
      />

      <EventContractSendEmail
        visible={sendDrawerVisible}
        onSuccess={onSendSuccess}
        onClose={hideSendDrawer}
        eventId={props.event?.id}
        clientId={props.event?.client?.id}
      />

      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
      />
    </>
  )
}

ContractsContainer.propTypes = {
  event: PropTypes.object,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onRemoveSuccess: PropTypes.func,
}
