import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"

import { Menu } from "./Menu"

export const HeaderContent = ({
  title,
  isGroup,
  isAdmin,
  participants = [],
  onEdit,
  onRemove,
  onLeave,
  onView,
}) => {
  const { t } = useTranslation()

  return (
    <div className="p-4 chat-room__header">
      <Row>
        <Col md="4" xs="9">
          <div className={"d-flex align-items-start me-3"}>
            <h5 className="font-size-15 mb-1">{title}</h5>
          </div>

          {isGroup && participants && !!participants.length && (
            <div className={"chat-room-header-user-status text-muted"}>
              <span className={"text-lowercase"}>
                {participants.length} {t("members")}
              </span>
            </div>
          )}
        </Col>
        <Col md="8" xs="3">
          {isGroup && (
            <ul className="list-inline user-chat-nav text-end mb-0">
              <li className="list-inline-item">
                <Menu
                  isAdmin={isAdmin}
                  onEdit={onEdit}
                  onRemove={onRemove}
                  onLeave={onLeave}
                  onView={onView}
                />
              </li>
            </ul>
          )}
        </Col>
      </Row>
    </div>
  )
}

HeaderContent.propTypes = {
  title: PropTypes.any,
  isGroup: PropTypes.bool,
  isAdmin: PropTypes.bool,
  participants: PropTypes.array,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onLeave: PropTypes.func,
  onView: PropTypes.func,
}
