import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "../../../../i18n"
import { API } from "../../../../api"
import { websiteGeneralRemove } from "./slice"
import { websiteGeneralData } from "../data/slice"

function* remove() {
  try {
    const response = yield call(API.website.removeWebsite)

    const { data } = response

    yield put(websiteGeneralRemove.removeCompleted())
    yield put(websiteGeneralData.cleanData())

    toastr.success(i18n.t("website.remove.success"))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      websiteGeneralRemove.removeRejected({
        errors,
        message,
      })
    )
    toastr.error(message)
  }
}

export function* watchRemove() {
  yield takeEvery(websiteGeneralRemove.remove, remove)
}

function* removeSaga() {
  yield all([fork(watchRemove)])
}

export default removeSaga
