import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"
import { socialCalendarCreatePost } from "./slice"
import i18n from "i18n"
import { API } from "api"
import { socialCalendarPostList } from "store/socialCalendar/post/actions"

function* createItem({ payload: { params, onSuccess, onError } }) {
  try {
    const response = yield call(API.socialCalendar.addPost, params)

    const { data } = response

    toastr.success(i18n.t("social-calendar.create-post.success"))

    if (onSuccess) {
      onSuccess(data?.data)
    }

    yield put(socialCalendarCreatePost.createCompleted())
    yield put(socialCalendarPostList.addItem(data?.data))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      socialCalendarCreatePost.createRejected({
        errors,
        message,
      })
    )

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchCreateItem() {
  yield takeEvery(socialCalendarCreatePost.create, createItem)
}

function* createSaga() {
  yield all([fork(watchCreateItem)])
}

export default createSaga
