import React from "react"
import PropTypes from "prop-types"
import { HexColorInput, HexColorPicker } from "react-colorful"
import s from "./ColorPicker.module.scss"
import { usePopover } from "hooks"
import { Popover } from "reactstrap"

export const ColorPicker = ({ color, onChange, id }) => {
  const { popoverId, toggle, popoverOpen } = usePopover({
    id,
  })

  return (
    <>
      <div
        className={s.swatch}
        style={{ backgroundColor: color }}
        id={popoverId}
      />
      <Popover
        popperClassName={s.popover_color_picker}
        placement={"bottom"}
        target={popoverId}
        isOpen={popoverOpen}
        toggle={toggle}
        trigger={"legacy"}
        fade={false}
      >
        <div className={s.popover_content}>
          <HexColorPicker color={color} onChange={onChange} id={id} />
          <div className={s.input_wrapper}>
            <HexColorInput color={color} onChange={onChange} prefixed id={id} />
          </div>
        </div>
      </Popover>
    </>
  )
}

ColorPicker.propTypes = {
  id: PropTypes.any,
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultColor: PropTypes.string,
}
