import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import i18n from "i18n"
import { API } from "api"
import { billingSubscriptionSwap } from "./slice"

function* swap({ payload: { id, onSuccess } }) {
  try {
    const params = {
      tariff: id,
    }

    const response = yield call(API.billing.subscriptionSwap, params)
    const { data } = response

    const { success, type, message, url } = data.data

    if (success) {
      yield put(billingSubscriptionSwap.swapSuccess())

      if (onSuccess) {
        onSuccess()
      }
    } else if (url) {
      window.location.href = url
    } else if (message) {
      // error
      yield put(billingSubscriptionSwap.swapError(message))
    }
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")

    yield put(billingSubscriptionSwap.swapError(message))
  }
}

export function* watchSwap() {
  yield takeEvery(billingSubscriptionSwap.swap, swap)
}

function* swapSaga() {
  yield all([fork(watchSwap)])
}

export default swapSaga
