import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import {
  modelFetchPhotos,
  modelFetchPhotosCleanState,
  modelPhotosSetPage,
} from "store"
import { PhotosGrid } from "./PhotosGrid"

const PhotosContainerComponent = props => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      props.modelFetchPhotosCleanState()
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [props.page, props.model])

  const fetchData = () => {
    let params = {
      page: props.page,
      per_page: 5,
    }

    props.modelFetchPhotos(props.model.id, params)
  }

  const handlePageChange = item => {
    props.modelPhotosSetPage(item.selected + 1)
  }

  return (
    <PhotosGrid
      items={props.list}
      loading={props.listLoading}
      error={props.listError}
      meta={props.meta}
      page={props.page}
      onPageChange={handlePageChange}
      onSelect={props.onSelect}
    />
  )
}

PhotosContainerComponent.propTypes = {
  onSelect: PropTypes.func,
  onAddItems: PropTypes.func,

  model: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,

  modelFetchPhotos: PropTypes.func,
  modelFetchPhotosCleanState: PropTypes.func,
  modelPhotosSetPage: PropTypes.func,
}

const mapStateToProps = state => {
  const { list, listLoading, listError, meta, page } =
    state.model.photos.list.data
  return {
    list,
    listLoading,
    listError,
    meta,
    page,
  }
}

const mapDispatchToProps = {
  modelFetchPhotos,
  modelFetchPhotosCleanState,
  modelPhotosSetPage,
}

export const PhotosContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhotosContainerComponent)
