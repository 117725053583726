import {
    DRAFT_PACKAGE_FORM_SET_VALUES,
    DRAFT_PACKAGE_FORM_RESET_VALUES,
} from './actionTypes';

export const draftPackageFormSetValues = (data) => {
    return {
        type: DRAFT_PACKAGE_FORM_SET_VALUES,
        payload: data,
    }
};

export const draftPackageFormResetValues = () => {
    return {
        type: DRAFT_PACKAGE_FORM_RESET_VALUES,
    }
};