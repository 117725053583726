import { combineReducers } from "redux";

import data from './data/reducer';
import removeItem from './removeItem/reducer';

const listReducer = combineReducers({
    data,
    removeItem,
});

export default listReducer;