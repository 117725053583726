import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import i18n from "../../../../i18n"
import { API } from "../../../../api"
import { calendarEventRateEdit } from "./slice"

function* edit({ payload: { id, params, onSuccess, onError } }) {
  try {
    const response = yield call(API.calendar.updateRate, id, params)

    const { data } = response

    yield put(calendarEventRateEdit.editSuccess())

    if (onSuccess) {
      onSuccess(data?.data)
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      calendarEventRateEdit.editError({
        errors,
        message,
      })
    )
    if (onError) {
      onError(errors)
    }
  }
}

export function* watchEdit() {
  yield takeEvery(calendarEventRateEdit.edit, edit)
}

function* editSaga() {
  yield all([fork(watchEdit)])
}

export default editSaga
