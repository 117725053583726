import { createSlice } from "@reduxjs/toolkit"


const initialState = {
  list: [],
  loading: false,
  error: null,
  page: 1,
  meta: null
}

const employeeRatingsDataSlice = createSlice({
  name: "employeeRatingsData",
  initialState,
  reducers: {
    getData(state) {
      state.loading = true
      state.error = null
    },
    getDataCompleted(state, action) {
      state.loading = false
      state.list = action.payload.data
      state.meta = action.payload.meta
    },
    getDataRejected(state, action) {
      state.listLoading = false
      state.listError = action.payload
    },
    setPage(state, action) {
      state.page = action.payload
    },
    cleanState() {
      return initialState
    }
  }
})

export const employeeRatingsData = employeeRatingsDataSlice.actions

export default employeeRatingsDataSlice.reducer