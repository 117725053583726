import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"

import {agencyContactData } from "../../../../../../store/actions"

import * as HOC from "../../../../../../HOC"

const DataShower = HOC.withDataShower()

const ContactDataLoaderComponent = props => {
    const dispatch = useDispatch()
    useEffect(() => {
        fetchData()
        return () => {
            dispatch(agencyContactData.cleanState())
        }
    }, [])

    const fetchData = () => {
        dispatch(agencyContactData.getData({ id: props.id }))
    }

    return (
        <DataShower
            isLoading={props.loading}
            isFailed={props.error}
            error={props.error}
        >
            {props.data && props.children}
        </DataShower>
    )
}

ContactDataLoaderComponent.propTypes = {
    id: PropTypes.number,
    children: PropTypes.any,
    data: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.any,
}

const mapStateToProps = state => {
    const { data, loading, error } = state.agency.contacts.data

    return {
        data,
        loading,
        error,
    }
}

export const ContactDataLoader = connect(mapStateToProps)(
    ContactDataLoaderComponent
)