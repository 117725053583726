import React, { useState } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { ModelsSelect } from "./ModelsSelect"
import { useFormContext } from "react-hook-form"

export const ModelsSection = () => {
  const [selectedGroup, setSelectedGroup] = useState(null)

  const handleSelect = option => {
    setSelectedGroup(option)
  }
  const { watch } = useFormContext()

  const gender = watch("gender")

  return (
    <Row>
      <Col>
        <ModelsSelect
          gender={gender}
          onSelect={handleSelect}
          value={selectedGroup}
        />
      </Col>
    </Row>
  )
}
