import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Row } from "reactstrap";

import { Drawer } from '../../../../../../components';
import { EditContactsForm } from './EditContactsForm';
import { requestHelper, commonHelper } from '../../../../../../helpers';
import { modelPanelGeneralEditContacts, modelPanelGeneralEditContactsCleanState } from '../../../../../../store/actions';
import { ValidationsUtils } from "../../../../../../utils"

const EditContactsComponent = (props) => {
    const { t } = useTranslation();

    const [countryOptions, setCountryOptions] = useState([]);

    useEffect(() => {
        if (props.visible) {
            fetchCountries();
        }
        return () => {
            if (props.visible) {
                setCountryOptions([]);
            }
        }
    }, [props.visible]);

    const fetchCountries = async () => {
        try {
            const response = await requestHelper.staticData.getCountries({ pagination: false });
            const { data } = response;

            setCountryOptions(data.data)
        } catch (e) {
        }
    };

    const onSubmitSuccess = (onSubmitProps) => () => {
        const { reset } = onSubmitProps;
        reset();

        props.onClose();
    }

    const onSubmitError = (onSubmitProps) => (errors) => {
        const { setError } = onSubmitProps;
        ValidationsUtils.setServerSideErrors(errors, setError);
    }

    const handleSubmit = (params, onSubmitProps) => {
        props.modelPanelGeneralEditContacts(props.model.id, params, onSubmitSuccess(onSubmitProps), onSubmitError(onSubmitProps))
    };

    const onDestroy = () => {
        props.modelPanelGeneralEditContactsCleanState();
    };

    return (
        <React.Fragment>
            <Drawer
                title={t('model.contacts-edit.title')}
                visible={props.visible}
                onClose={props.onClose}
                placement={'right'}
                className={'drawer-container'}
                destroyOnClose
                onDestroy={onDestroy}
                drawerProps={{}}
            >
                <Container fluid>
                    <Row>
                        <EditContactsForm
                            initialValues={{
                                phones: props.model?.phones || [],
                                emails: props.model?.emails || [],
                                social_networks: commonHelper.socialNetwork.formInitialValues(props.model?.social_networks),
                            }}
                            loading={props.loading}
                            error={props.error}
                            onSubmit={handleSubmit}
                            onCancel={props.onClose}
                            countryOptions={countryOptions}
                        />
                    </Row>
                </Container>
            </Drawer>
        </React.Fragment>
    );
};

EditContactsComponent.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,

    model: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.object,

    modelPanelGeneralEditContacts: PropTypes.func,
    modelPanelGeneralEditContactsCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { data } = state.model.profile;
    const { loading, error } = state.modelPanel.general.contacts.edit;
    return {
        model: data,
        loading, error,
    };
};

const mapDispatchToProps = {
    modelPanelGeneralEditContacts,
    modelPanelGeneralEditContactsCleanState,
};

export const EditContacts = connect(mapStateToProps, mapDispatchToProps)(EditContactsComponent);