export const formInitialValues = contacts => {
  if (!contacts) {
    return []
  }
  return contacts.map(contact => {
    return {
      ...contact,
      texts: contact.texts.map(text => {
        return {
          value: text,
        }
      }),
    }
  })
}

export const formSubmitValues = contacts => {
  return contacts.map(contact => {
    return {
      ...contact,
      texts: contact.texts.map(obj => {
        return obj.value
      }),
    }
  })
}
