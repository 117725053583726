import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { FilterFormProvider } from "components"
import {
  modelsGroupsList,
  modelsPanelListCallChangeFilter,
  selectModelsPanelState,
  staticCountryAll,
  staticMeasurementAll,
} from "store"
import { MODELS_PANEL_FILTERS_INITIAL_VALUES } from "consts"
import {
  AgeSection,
  EthnicSection,
  GenderSection,
  InTownSection,
  MeasurementSection,
  SearchSection,
  GeneralDetailsSection,
} from "./FormSections"

const ModelsFilterContainerComponent = props => {
  const dispatch = useDispatch()

  const onFiltersChange = values => {
    const { keywords, birthdayplace } = values
    values = {
      ...values,
      keywords: keywords.trim(),
      birthdayplace: birthdayplace.trim(),
    }
    dispatch(modelsPanelListCallChangeFilter(values))
  }

  const defaultValues = useMemo(() => {
    const { keywords, ...restValues } = props.filters.values
    return {
      keywords,
      ...restValues,
    }
  }, [props.filters.values])

  useEffect(() => {
    if (!props.measurement) {
      dispatch(staticMeasurementAll.getData({}))
    }
    if (!props.countries.length) {
      dispatch(staticCountryAll.getData({}))
    }
  }, [])

  useEffect(() => {
    dispatch(
      modelsGroupsList.getList({
        params: {
          pagination: false,
          type: props.filters.values.gender,
        },
      })
    )
    return () => {
      dispatch(modelsGroupsList.cleanState())
    }
  }, [props.filters.values.gender])

  return (
    <FilterFormProvider
      initialValues={MODELS_PANEL_FILTERS_INITIAL_VALUES}
      defaultValues={defaultValues}
      onFiltersChange={onFiltersChange}
      resetHidden={
        props.modelsPanelState.isAddToGroupState ||
        props.modelsPanelState.isCreateGroupState
      }
    >
      <SearchSection
        countryOptions={props.countries}
        groupsOptions={props.groups}
        modelPanelState={props.modelsPanelState}
        isGroupSelected={!!props.filters.values.group}
      />

      <GenderSection />

      <InTownSection />

      <GeneralDetailsSection />

      <EthnicSection />

      <AgeSection />

      <MeasurementSection measurementsOptions={props.measurement} />
    </FilterFormProvider>
  )
}

ModelsFilterContainerComponent.propTypes = {
  measurement: PropTypes.object,
  countries: PropTypes.array,
  filters: PropTypes.object,
  groups: PropTypes.array,
  modelsPanelState: PropTypes.object,
}

const mapStateToProps = state => {
  const { data: measurement } = state.static.measurement.all
  const { data: countries } = state.static.country.all
  const { list: groups } = state.models.groups.list
  const { filters } = state.models.panel.list.data
  const modelsPanelState = selectModelsPanelState(state)
  return {
    measurement,
    countries,
    filters,
    groups,
    modelsPanelState,
  }
}

export const ModelsFilterContainer = connect(mapStateToProps)(
  ModelsFilterContainerComponent
)
