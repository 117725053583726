import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

export const InfoRow = ({ title = "", content = "", isInvisible }) => {
  const { t } = useTranslation()

  if (isInvisible) return null

  return (
    <tr>
      <td className={"fw-medium"}>{t(title)}</td>
      <td>{content}</td>
    </tr>
  )
}

InfoRow.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any,
  isInvisible: PropTypes.bool,
}
