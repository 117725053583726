import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { GroupFormDrawer } from "../GroupFormDrawer"
import { GroupForm } from "../GroupForm"
import { useSubmitHandler } from "hooks"
import { chatGroupsCreate } from "store/actions"

export const AddGroup = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { loading, error } = useSelector(state => state.chat.groups.create)

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        chatGroupsCreate.create({
          params,
          onSuccess,
          onError,
        })
      )
    },
    () => {
      props.onSuccess()
    }
  )

  const onDestroy = () => {
    dispatch(chatGroupsCreate.cleanState())
  }

  return (
    <GroupFormDrawer
      title={t("add_group")}
      visible={props.visible}
      onClose={props.onClose}
      onDestroy={onDestroy}
    >
      <GroupForm
        loading={loading}
        error={error}
        onSubmit={handleSubmit}
        onCancel={props.onClose}
      />
    </GroupFormDrawer>
  )
}

AddGroup.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  onSuccess: PropTypes.func,
}
