import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Col, Alert, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { Button, ComboboxFormField, ControllerHF, InputField } from "components"
import { useClientCategories, useFetchBookers } from "hooks"

export const EditProfileForm = props => {
  const { t } = useTranslation()
  const { data: categories, fetchData: fetchCategories } = useClientCategories()
  const { data: bookers, fetchData: fetchBookers } = useFetchBookers()
  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      name: "",
      website: "",
      note: "",
      country: "",
      category: "",
      booker: "",
      vat: "",
      bank_name: "",
      bank_account_number: "",
      swift_code: "",
      other_information: "",
      ...props.initialValues,
    },
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required("field.error.required"),
      })
    ),
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
      country_id: values.country ? values.country.id : null,
      client_category_id: values.category ? values.category.id : null,
      booker_id: values.booker ? values.booker.id : null,
    }
    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }
  useEffect(() => {
    fetchCategories()
    fetchBookers()
  }, [])

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={10}>
            <Row>
              <Col lg={6}>
                <ControllerHF
                  name={"name"}
                  control={control}
                  component={InputField}
                  id={"name"}
                  label={t("name")}
                  placeholder={t("name")}
                />
              </Col>
              <Col lg={6}>
                <ControllerHF
                  name={"website"}
                  control={control}
                  component={InputField}
                  id={"website"}
                  label={t("website")}
                  placeholder={t("website")}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ControllerHF
                  name={"booker"}
                  control={control}
                  component={ComboboxFormField}
                  id={"booker"}
                  label={t("booker")}
                  placeholder={t("booker")}
                  options={bookers}
                  getOptionLabel={option => `${option.name}`}
                  getOptionValue={option => option.id}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ControllerHF
                  name={"country"}
                  control={control}
                  component={ComboboxFormField}
                  id={"country"}
                  label={t("country")}
                  placeholder={t("country")}
                  options={props.countryOptions}
                  getOptionLabel={option => `${option.name}`}
                  getOptionValue={option => option.id}
                />
              </Col>
              <Col md={6}>
                <ControllerHF
                  name={"category"}
                  control={control}
                  component={ComboboxFormField}
                  id={"category"}
                  label={t("category")}
                  placeholder={t("category")}
                  options={categories}
                  getOptionLabel={option => `${option.value}`}
                  getOptionValue={option => option.id}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ControllerHF
                  name={"note"}
                  control={control}
                  component={InputField}
                  id={"note"}
                  label={t("note")}
                  placeholder={t("note")}
                  type={"textarea"}
                />
              </Col>
            </Row>

            <hr className="drawer-separator drawer-separator_form" />

            <Row>
              <Col md={6}>
                <ControllerHF
                  name={"vat"}
                  control={control}
                  component={InputField}
                  id={"vat"}
                  label={t("VAT")}
                  placeholder={t("VAT")}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ControllerHF
                  name={"bank_name"}
                  control={control}
                  component={InputField}
                  id={"bank_name"}
                  label={t("bank-name")}
                  placeholder={t("bank-name")}
                />
              </Col>
              <Col md={6}>
                <ControllerHF
                  name={"bank_account_number"}
                  control={control}
                  component={InputField}
                  id={"bank_account_number"}
                  label={t("bank-account-number")}
                  placeholder={t("bank-account-number")}
                />
              </Col>
              <Col md={6}>
                <ControllerHF
                  name={"swift_code"}
                  control={control}
                  component={InputField}
                  id={"swift_code"}
                  label={t("swift")}
                  placeholder={t("swift")}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ControllerHF
                  name={"other_information"}
                  control={control}
                  component={InputField}
                  id={"other_information"}
                  label={t("other-information")}
                  placeholder={t("other-information")}
                  type="textarea"
                />
              </Col>
            </Row>

            <div className="mt-3">
              <div className="button-items">
                <Button
                  submit
                  title={"btn.save"}
                  disabled={props.loading}
                  loading={props.loading}
                />

                <Button
                  title={"cancel"}
                  color="light"
                  outline
                  onClick={onCancel}
                />
              </div>
            </div>
          </Col>
          <Col />
        </Row>
      </form>
    </>
  )
}

EditProfileForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  countryOptions: PropTypes.any,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
