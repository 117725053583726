import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import React from "react"

export const OtherEventDetailsBlock = props => {
  const { t } = useTranslation()

  return (
    <div className={"calendar-event-info__other-event-details"}>
      <div className={"title"}>{t("event_details")}</div>
      <hr className="divider" />
      {props.list.map((infoItem, index) => {
        return (
          !infoItem.hidden && (
            <div
              className={"info-item"}
              key={`other-event-details-info-${index}`}
            >
              <div className={"info-item__label"}>{infoItem.label}:</div>
              <div className={"info-item__value"}>{infoItem.value}</div>
            </div>
          )
        )
      })}
    </div>
  )
}

OtherEventDetailsBlock.propTypes = {
  list: PropTypes.array.isRequired,
}
