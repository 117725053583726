import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { calendarEventModelContractRemove } from "./slice"

function* remove({ payload: { event, model, onSuccess } }) {
  try {
    const response = yield call(API.calendar.removeModelContract, event, model)

    const { data } = response

    yield put(calendarEventModelContractRemove.removeSuccess())

    if (onSuccess) {
      onSuccess()
    }
    toastr.success(i18n.t("remove.success"))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      calendarEventModelContractRemove.removeError({
        errors,
        message,
      })
    )

    toastr.error(message)
  }
}

export function* watchRemove() {
  yield takeEvery(calendarEventModelContractRemove.remove, remove)
}

function* removeSaga() {
  yield all([fork(watchRemove)])
}

export default removeSaga
