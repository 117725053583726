import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import toastr from "toastr"
import { useTranslation } from "react-i18next"

import { ExpensesForm } from "./ExpensesForm"
import {
  calendarEventExpenseAdd,
  expenseEdit,
  expenseRemove,
} from "../../../../../store/actions"

export const AsyncExpenses = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [isEdit, setIsEdit] = useState(false)

  const { loading, error } = useSelector(state =>
    !isEdit ? state.calendarEvent.expense.add : state.expense.edit
  )
  const { loading: removing } = useSelector(state => state.expense.remove)

  useEffect(() => {
    return () => {
      dispatch(expenseRemove.cleanState())
    }
  }, [])

  const onSubmitReset = () => {
    dispatch(calendarEventExpenseAdd.cleanState())
    dispatch(expenseEdit.cleanState())
  }

  const onSubmitSuccess = payload => data => {
    const { onSuccess } = payload
    onSuccess(data)

    toastr.success(t("event.update.success"))
  }

  const onSubmitError = payload => errors => {
    const { onError } = payload
    onError(errors)
  }

  const onAdd = (data, payload) => {
    isEdit && setIsEdit(false)

    dispatch(
      calendarEventExpenseAdd.add({
        id: props.eventId,
        params: data,
        onSuccess: onSubmitSuccess(payload),
        onError: onSubmitError(payload),
      })
    )
  }

  const onEdit = (data, payload) => {
    setIsEdit(true)

    dispatch(
      expenseEdit.edit({
        id: data?.id,
        params: data,
        onSuccess: onSubmitSuccess(payload),
        onError: onSubmitError(payload),
      })
    )
  }

  const onRemove = (data, payload) => {
    const { onSuccess } = payload

    dispatch(
      expenseRemove.remove({
        id: data?.id,
        onSuccess: () => {
          onSuccess(data)

          toastr.success(t("event.update.success"))
        },
      })
    )
  }

  return (
    <>
      <ExpensesForm
        async
        onAdd={onAdd}
        onEdit={onEdit}
        onRemove={onRemove}
        loading={loading}
        error={error}
        generalLoading={removing}
        onFormDestroy={onSubmitReset}
      />
    </>
  )
}

AsyncExpenses.propTypes = {
  eventId: PropTypes.number,
}
