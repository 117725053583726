import {
    MODEL_FETCH_PUBLISHED_BOOKS,
    MODEL_FETCH_PUBLISHED_BOOKS_SUCCESS,
    MODEL_FETCH_PUBLISHED_BOOKS_ERROR,
    MODEL_FETCH_PUBLISHED_BOOKS_CLEAN_STATE,

    MODEL_PUBLISHED_BOOKS_SET_PAGE,
} from './actionTypes'

export const modelFetchPublishedBooks = (id, params) => {
    return {
        type: MODEL_FETCH_PUBLISHED_BOOKS,
        payload: { id, params },
    }
};

export const modelFetchPublishedBooksSuccess = (data) => {
    return {
        type: MODEL_FETCH_PUBLISHED_BOOKS_SUCCESS,
        payload: data,
    }
};

export const modelFetchPublishedBooksError = (data) => {
    return {
        type: MODEL_FETCH_PUBLISHED_BOOKS_ERROR,
        payload: data,
    }
};

export const modelFetchPublishedBooksCleanState = () => {
    return { type: MODEL_FETCH_PUBLISHED_BOOKS_CLEAN_STATE };
};

export const modelPublishedBooksSetPage = (data) => {
    return {
        type: MODEL_PUBLISHED_BOOKS_SET_PAGE,
        payload: data,
    }
};