import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import i18n from '../../../../../i18n';
import { MODEL_PANEL_RATING_FETCH_LIST } from './actionTypes';
import { modelPanelRatingFetchListSuccess, modelPanelRatingFetchListError, modelPanelRatingFetchList } from './actions';

import { API } from '../../../../../api';
import { RequestUtils } from '../../../../../utils';

export function* fetchList({ payload: { id, params } }) {
    try {
        let requestParams = {
            includes: [],
        };

        requestParams = RequestUtils.getMapRequestParams(params, requestParams);

        const response = yield call(API.model.getModelRatingsData, id, requestParams);

        const { data } = response;

        const { meta: { current_page, last_page } } = data;
        if (current_page && last_page && current_page > last_page ) {
            yield put(modelPanelRatingFetchList(id, {
                ...params,
                page: last_page,
            }));
        } else {
            yield put(modelPanelRatingFetchListSuccess(data));
        }

    } catch (error) {
        const message = error.response?.data?.message || i18n.t('error');
        yield put(modelPanelRatingFetchListError(message));
    }
}

export function* watchFetchList() {
    yield takeLatest(MODEL_PANEL_RATING_FETCH_LIST, fetchList)
}

function* dataSaga() {
    yield all([
        fork(watchFetchList),
    ]);
}

export default dataSaga;