import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { connect, useDispatch } from "react-redux"
import { FilesUploaderHF } from "../../../../../../components"
import {
  clientContactUploadFile,
  clientContactRemoveFile,
} from "../../../../../../store/actions"
import { FileUtils } from "../../../../../../utils"

const FilesUploaderComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const onUploadError = (field, fieldArrayProps) => errors => {
    props.removeFileFromProcessing(field?.fieldId)

    if (errors["file"]) {
      props.setError(fieldArrayProps.fieldName, {
        type: "server",
        message: errors["file"].join(" "),
      })
    }
  }

  const onUploadSuccess = (field, fieldArrayProps) => data => {
    props.removeFileFromProcessing(field?.fieldId)
    const { fieldIndex, update } = fieldArrayProps
    update(fieldIndex, {
      ...field,
      ...data,
    })
  }

  const handleUpload = (field, fieldArrayProps) => {
    props.addFileToProcessing(field?.fieldId)
    let params = {
      file: field?.file,
    }
    if (props.contactId) {
      params.id = props.contactId
    }
    dispatch(
      clientContactUploadFile.upload({
        params,
        onSuccess: onUploadSuccess(field, fieldArrayProps),
        onError: onUploadError(field, fieldArrayProps),
      })
    )
  }

  const onRemoveError = (field, fieldArrayProps) => () => {
    props.removeFileFromProcessing(field?.fieldId)
  }

  const onRemoveSuccess = (field, fieldArrayProps) => data => {
    props.removeFileFromProcessing(field?.fieldId)
    const { fieldIndex } = fieldArrayProps
    fieldArrayProps.remove(fieldIndex)
  }

  const handleRemove = (field, fieldArrayProps) => {
    props.addFileToProcessing(field?.fieldId)
    dispatch(
      clientContactRemoveFile.remove({
        id: field?.id,
        onSuccess: onRemoveSuccess(field, fieldArrayProps),
        onError: onRemoveError(field, fieldArrayProps),
      })
    )
  }

  const onValidate = async (name, onValid) => {
    const result = await props.trigger(name)
    if (result && onValid) {
      onValid()
    }
  }

  const onDownload = field => {
    const link = document.createElement("a")
    link.href = field?.attachment?.src
    link.setAttribute("download", true)
    link.setAttribute("target", "_blank")
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  }

  return (
    <FilesUploaderHF
      id={"files"}
      name={"files"}
      pickerLabelText={t("attach-files")}
      control={props.control}
      onUpload={handleUpload}
      onRemove={handleRemove}
      onDownload={onDownload}
      processing={props.processing}
      onValidate={onValidate}
      accept={
        "image/jpeg, image/png, image/gif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      }
      translateParams={{
        text: `${FileUtils.formatBytes(props.fileMaxSize)}`,
      }}
    />
  )
}

FilesUploaderComponent.propTypes = {
  control: PropTypes.object,
  processing: PropTypes.array,
  addFileToProcessing: PropTypes.func,
  removeFileFromProcessing: PropTypes.func,
  trigger: PropTypes.func,
  setError: PropTypes.func,
  fileMaxSize: PropTypes.number,
  contactId: PropTypes.number,
}

const mapStateToProps = state => {
  return {}
}

export const FilesUploader = connect(mapStateToProps)(FilesUploaderComponent)
