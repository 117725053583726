import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import classNames from "classnames"

import { AddRecipient } from "./AddRecipient"
import { RecipientSelect } from "./RecipientSelect"
import { SelectedListControl } from "components"

export const RecipientsControl = ({ disabled, value, className, ...props }) => {
  const { t } = useTranslation()

  const onAdd = item => {
    props.onChange([...value, item])
  }

  const onRemove = (item, index) => {
    let items = value.filter((item, i) => i !== index)
    props.onChange([...items])
  }

  const onSelect = item => {
    props.onChange([...value, item])
  }

  const isDisabled = useMemo(() => {
    return disabled || value.length >= props.limit
  }, [value.length, props.limit, disabled])

  return (
    <>
      <Row>
        {props.select && (
          <Col md={8}>
            <RecipientSelect
              value={value}
              disabled={isDisabled}
              onSelect={onSelect}
              clientId={props.clientId}
            />
          </Col>
        )}
        <Col md={4}>
          <div className={"recipients-list-control-wrapper"}>
            <div className={"mb-3"}>
              <AddRecipient disabled={isDisabled} onAdd={onAdd} />
            </div>
          </div>
        </Col>
      </Row>
      <Row className={"mt-3"}>
        <Col md={8}>
          <SelectedListControl
            items={value}
            title={t("recipients_list")}
            onRemove={onRemove}
          />
        </Col>
      </Row>
    </>
  )
}

RecipientsControl.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  limit: PropTypes.number,
  select: PropTypes.bool,
  clientId: PropTypes.number,
}
