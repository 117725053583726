import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { ConfirmPopup, PricesTable } from "../../../../../components"
import {
  clientPricesList,
  clientRemovePrice,
} from "../../../../../store/actions"
import { UpdatePrice } from "./UpdatePrice"
import { usePanelTopBarOptions } from "../../../../../hooks"
import { AddPrice } from "./AddPrice"

const PricesContainerComponent = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(clientPricesList.cleanState())
      dispatch(clientRemovePrice.cleanState())
    }
  }, [])

  useEffect(() => {
    fetchList()
  }, [props.page])

  const fetchList = () => {
    let params = {
      page: props.page,
    }
    dispatch(clientPricesList.getList({ id: props.client.id, params }))
  }

  const handlePageChange = item => {
    dispatch(clientPricesList.setPage(item.selected + 1))
  }
  //==========================REMOVE LOGIC =================================//
  const removeModalRef = useRef(null)
  const [removePrice, setRemovePrice] = useState(null)
  const handleRemove = item => {
    setRemovePrice(item)
    if (removeModalRef.current) {
      removeModalRef.current.show()
    }
  }
  const resetRemove = () => {
    setRemovePrice(null)
  }
  const onRemoveSuccess = () => {
    fetchList()
  }
  const confirmRemove = () => {
    dispatch(
      clientRemovePrice.remove({
        id: removePrice.id,
        onSuccess: onRemoveSuccess,
      })
    )
  }
  //==========================UPDATE LOGIC =================================//
  const [updatePrice, setUpdatePrice] = useState(null)
  const handleUpdate = item => {
    setUpdatePrice(item)
  }
  const handleUpdateClose = () => {
    setUpdatePrice(null)
  }
  const handleUpdateSuccess = () => {
    fetchList()
  }
  //===========================ADD LOGIC ====================================//
  const handleAddSuccess = () => {
    fetchList()
  }

  const panelOptions = useMemo(
    () => ({
      actions: (
        <>
          <AddPrice onAddSuccess={handleAddSuccess} />
        </>
      ),
    }),
    [props.page]
  )

  usePanelTopBarOptions(panelOptions)

  return (
    <>
      <PricesTable
        list={props.list}
        loading={props.listLoading}
        error={props.listError}
        meta={props.meta}
        page={props.page}
        onRemove={handleRemove}
        onUpdate={handleUpdate}
        removingLoading={props.removeLoading}
        onPageChange={handlePageChange}
      />
      <UpdatePrice
        onUpdateSuccess={handleUpdateSuccess}
        id={updatePrice?.id}
        onClose={handleUpdateClose}
      />
      <ConfirmPopup
        ref={removeModalRef}
        onConfirm={confirmRemove}
        onClosed={resetRemove}
      />
    </>
  )
}

PricesContainerComponent.propTypes = {
  client: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
  removeLoading: PropTypes.bool,
}

const mapStateToProps = state => {
  const { data } = state.client.profile.data
  const { list, listLoading, listError, meta, page } = state.client.prices.list
  const { loading: removeLoading } = state.client.prices.remove
  return {
    client: data,
    list,
    listLoading,
    listError,
    meta,
    page,
    removeLoading,
  }
}

export const PricesContainer = connect(mapStateToProps)(
  PricesContainerComponent
)
