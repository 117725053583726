import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Table } from "reactstrap"
import _ from "lodash"

import { billingSubscriptionHelper } from "helpers"
import { billingHelper } from "helpers"

const currencySymbol = billingHelper.currency.getCurrency().symbol
const billingCurrency = billingHelper.currency.getCurrency().value

export const TariffInfo = ({ data }) => {
  const { t } = useTranslation()

  const { price, name, period } = data

  const totalPrice = useMemo(() => {
    let priceData = _.find(data?.prices, { currency: billingCurrency })

    return priceData?.total || price
  }, [price])

  const items = [
    {
      label: t("subscription_plan"),
      value: name,
    },
    {
      label: t("billed_period"),
      value: billingSubscriptionHelper.period.getConfig(period).label,
    },
    {
      label: t("price"),
      value: `${totalPrice} ${currencySymbol} `,
    },
  ]

  return (
    <>
      <div className="table-responsive">
        <Table className="table mb-0 table-border-bottom">
          <tbody>
            {items.map((item, i) => (
              <tr key={i}>
                <th scope="row" style={{ width: "300px" }}>
                  {item.label}:
                </th>
                <td>{item.value}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  )
}

TariffInfo.propTypes = {
  data: PropTypes.object,
}
