import React from "react"
import PropTypes from "prop-types"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { IMAGE_CROP_TYPE } from "consts"
import classnames from "classnames"
import placeholderSquare from "assets/images/dev/image-placeholder-square.svg"
import placeholderRectangle from "assets/images/dev/image-placeholder-rect.svg"

const { INSTAGRAM, RECTANGLE, SQUARE, THUMBNAIL } = IMAGE_CROP_TYPE

/**
 * Represents a Image component.
 * Component is used as wrap component on img tag
 */

export const Image = ({
  type,
  crops,
  src,
  alt = "photo",
  preferSmallSize = true,
  objectPosition = "center",
  errorPlaceholder,
}) => {
  const image_square = crops ? crops.find(crop => crop.type === SQUARE) : null

  const image_rectangle = crops
    ? crops.find(crop => crop.type === RECTANGLE)
    : null

  const image_instagram = crops
    ? crops.find(crop => crop.type === INSTAGRAM)
    : null

  const image_thumbnail = crops
    ? crops.find(crop => crop.type === THUMBNAIL)
    : null

  const getSrc = type => {
    if (type === SQUARE && image_square) {
      return image_square.src
    }

    if (type === RECTANGLE && image_rectangle) {
      return image_rectangle.src
    }

    if (type === INSTAGRAM && image_instagram) {
      return image_instagram.src
    }

    if (preferSmallSize && image_thumbnail) {
      return image_thumbnail.src
    }

    return src ? src : "" // Empty string throw error and placeholder will appear. Needed when baseUrl is null (photo was deleted)
  }

  const handleLoadingError = ({ currentTarget }) => {
    currentTarget.style.objectPosition = "center"

    switch (type) {
      case SQUARE: {
        currentTarget.src = placeholderSquare
        break
      }
      case RECTANGLE: {
        currentTarget.src = placeholderRectangle
        break
      }
      case INSTAGRAM: {
        currentTarget.src = placeholderSquare
        break
      }

      default: {
        if (errorPlaceholder) {
          currentTarget.src = errorPlaceholder
        } else {
          currentTarget.src = placeholderRectangle
        }
      }
    }
  }

  return (
    <LazyLoadImage
      alt={alt}
      src={getSrc(type)}
      effect="skeleton"
      wrapperClassName={classnames("image-wrap", {
        [`image-wrap__as-cover image-wrap__as-cover_${objectPosition}`]: !type,
      })}
      onError={handleLoadingError}
    />
  )
}

Image.propTypes = {
  src: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  type: PropTypes.oneOf([SQUARE, RECTANGLE, INSTAGRAM]),
  crops: PropTypes.array,
  alt: PropTypes.string,
  preferSmallSize: PropTypes.bool,
  objectPosition: PropTypes.oneOf(["center", "top"]),
  errorPlaceholder: PropTypes.node,
}
