import React from "react"
import { Row, Col } from "reactstrap"
import { MegaTagTitle } from "../../components"
import { HistoryContainer } from "./components"

export const History = () => {
  return (
    <div className={"history-panel"}>
      <MegaTagTitle title={"title.client.history"} />
      <Row>
        <Col>
          <HistoryContainer />
        </Col>
      </Row>
    </div>
  )
}
