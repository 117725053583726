import React from "react"
import PropTypes from "prop-types"

import { PrintButton, Button } from "components"

export const Actions = ({ onPrint, onDownload }) => {
  return (
    <div className="d-print-none mt-4">
      <div className={"button-items"}>
        <PrintButton onPrint={onPrint} />

        <Button
          icon={"bx bxs-download"}
          renderTitle={""}
          onClick={onDownload}
        />
      </div>
    </div>
  )
}

Actions.propTypes = {
  onPrint: PropTypes.func,
  onDownload: PropTypes.func,
}
