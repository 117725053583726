import {
    MODEL_PANEL_RATING_FETCH_LIST,
    MODEL_PANEL_RATING_FETCH_LIST_SUCCESS,
    MODEL_PANEL_RATING_FETCH_LIST_ERROR,
    MODEL_PANEL_RATING_LIST_CLEAN_STATE,

    MODEL_PANEL_RATING_LIST_SET_PAGE,
} from './actionTypes'

export const modelPanelRatingFetchList = (id, params) => {
    return {
        type: MODEL_PANEL_RATING_FETCH_LIST,
        payload: { id, params },
    }
};

export const modelPanelRatingFetchListSuccess = (data) => {
    return {
        type: MODEL_PANEL_RATING_FETCH_LIST_SUCCESS,
        payload: data,
    }
};

export const modelPanelRatingFetchListError = (data) => {
    return {
        type: MODEL_PANEL_RATING_FETCH_LIST_ERROR,
        payload: data,
    }
};

export const modelPanelRatingListCleanState = () => {
    return { type: MODEL_PANEL_RATING_LIST_CLEAN_STATE };
};

export const modelPanelRatingListSetPage = (data) => {
    return {
        type: MODEL_PANEL_RATING_LIST_SET_PAGE,
        payload: data,
    }
};