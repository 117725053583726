import { all } from "redux-saga/effects"

import dataSaga from "./data/saga"
import doneSaga from "./done/saga"

function* editSaga() {
  yield all([dataSaga(), doneSaga()])
}

export default editSaga
