import React from "react"
import PropTypes from "prop-types"

import placeholder from "assets/images/dev/avatar-placeholder.png"

export const Avatar = ({ avatar }) => {
  return (
    <div className={"align-self-end me-2"}>
      <img
        src={avatar || placeholder}
        className="rounded-circle avatar-xs img-cover"
        alt="avatar"
      />
    </div>
  )
}

Avatar.propTypes = {
  avatar: PropTypes.any,
}
