import { combineReducers } from "@reduxjs/toolkit"

import list from "./list/slice"
import add from "./add/slice"
import remove from "./remove/slice"

const contactsReducer = combineReducers({
  list,
  add,
  remove,
})

export default contactsReducer
