import React from "react"
import PropTypes from "prop-types"

import { STATEMENT_TYPE } from "consts"

export const Amount = ({ data = {}, ...props }) => {
  const { type, value_gross, value } = data

  return type === STATEMENT_TYPE.STATEMENT
    ? value_gross
      ? value_gross
      : null
    : value
}

Amount.propTypes = {
  data: PropTypes.object,
}
