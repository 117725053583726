import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"
import { ControllerHF, FilterTitle, InputField } from "components"
import { NormalizeUtils } from "utils"

const min = 0
const max = 100

export const AgeSection = props => {
  const { t } = useTranslation()

  const { control, setValue } = useFormContext()

  const onChange = (name, event) => {
    let value = event.target.value

    if (value !== "") {
      value = Number(value)

      if (value > max) {
        value = max
      } else if (value < min) {
        value = min
      }

      value = value.toString()
    }

    setValue(name, value)
  }

  return (
    <>
      <FilterTitle title={t("age")} />

      <div className={"d-flex"}>
        <div className={"me-2"}>
          <ControllerHF
            name={"age_from"}
            control={control}
            component={InputField}
            label={null}
            placeholder={t("from")}
            normalize={NormalizeUtils.onlyNumbers}
            onChange={event => onChange("age_from", event)}
          />
        </div>

        <div className={"ms-2"}>
          <ControllerHF
            name={"age_to"}
            control={control}
            component={InputField}
            label={null}
            placeholder={t("to")}
            normalize={NormalizeUtils.onlyNumbers}
            onChange={event => onChange("age_to", event)}
          />
        </div>
      </div>
    </>
  )
}

AgeSection.propTypes = {
  countryOptions: PropTypes.array,
}
