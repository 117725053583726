import React, { useEffect, useRef, useState } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';

import { InfoCardShower } from '../InfoCardShower';
import { AddDocument } from './AddDocument';
import { DocumentsTable } from './DocumentsTable';
import { EditDocument } from './EditDocument';
import {
    modelPanelGeneralFetchDocumentList,
    modelPanelGeneralFetchDocumentListCleanState,
    modelPanelGeneralDocumentListSetPage,
    modelPanelGeneralDocumentListRemoveItem,
    modelPanelGeneralDocumentListRemoveItemCleanState,
} from '../../../../../store/actions';
import { ConfirmPopup } from "../../../../../components"

const DocumentsContainerComponent = (props) => {
    const removeModalRef = useRef(null);

    const [documentRemove, setDocumentRemove] = useState(null);
    const [documentEdit, setDocumentEdit] = useState(null);
    const [editDrawerVisible, setEditDrawerVisible] = useState(null);
    const [addDrawerVisible, setAddDrawerVisible] = useState(null);

    useEffect(() => {
        return () => {
            props.modelPanelGeneralFetchDocumentListCleanState();
            props.modelPanelGeneralDocumentListRemoveItemCleanState();
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [props.page]);

    const fetchData = () => {
        let params = {
            page: props.page,
            per_page: 5,
        };

        props.modelPanelGeneralFetchDocumentList(props.model.id, params);
    };

    const handlePageChange = (item) => {
        props.modelPanelGeneralDocumentListSetPage(item.selected + 1);
    };

    const handleAddSuccess = () => {
        fetchData();
    };

    const removeDocument = (item) => {
        setDocumentRemove(item);

        if (removeModalRef.current) {
            removeModalRef.current.show();
        }
    };

    const resetDocumentRemove = () => {
        setDocumentRemove(null);
    };

    const onRemoveSuccess = () => {
        fetchData();
    }

    const confirmRemove = () => {
        props.modelPanelGeneralDocumentListRemoveItem(documentRemove.id, onRemoveSuccess);
    };

    const editDocument = (item) => {
        setDocumentEdit(item);
        editDrawerShow();
    };

    const handleEditSuccess = () => {
        fetchData();
    }

    const resetDocumentEdit = () => {
        setDocumentEdit(null);
    }

    const editDrawerShow = () => {
        setEditDrawerVisible(true)
    };

    const editDrawerClose = () => {
        setEditDrawerVisible(false)
    };

    const editDrawerDestroy = () => {
        resetDocumentEdit();
    };

    const addDocument = () => {
        setAddDrawerVisible(true);
    };

    const addDrawerClose = () => {
        setAddDrawerVisible(false)
    };

    return (
        <>
            <InfoCardShower
                title={'documents'}
                className={'model-documents-info'}
                addMode
                onAdd={addDocument}
                items={props.list}
                loading={props.listLoading}
                error={props.listError}
                meta={props.meta}
                onPageChange={handlePageChange}
            >
                <DocumentsTable
                    items={props.list}
                    removingItems={props.removingItems}
                    onEdit={editDocument}
                    onRemove={removeDocument}
                />
            </InfoCardShower>

            <AddDocument
                visible={addDrawerVisible}
                onAddSuccess={handleAddSuccess}
                onClose={addDrawerClose}
            />

            <EditDocument
                visible={editDrawerVisible}
                onEditSuccess={handleEditSuccess}
                id={documentEdit?.id}
                onClose={editDrawerClose}
                onDestroy={editDrawerDestroy}
            />

            <ConfirmPopup ref={removeModalRef} onConfirm={confirmRemove} onClosed={resetDocumentRemove} />
        </>
    );
};

DocumentsContainerComponent.propTypes = {
    model: PropTypes.object,
    list: PropTypes.array,
    listLoading: PropTypes.bool,
    listError: PropTypes.any,
    meta: PropTypes.object,
    page: PropTypes.any,
    removingItems: PropTypes.array,

    modelPanelGeneralFetchDocumentList: PropTypes.func,
    modelPanelGeneralFetchDocumentListCleanState: PropTypes.func,
    modelPanelGeneralDocumentListSetPage: PropTypes.func,
    modelPanelGeneralDocumentListRemoveItem: PropTypes.func,
    modelPanelGeneralDocumentListRemoveItemCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { data } = state.model.profile;
    const { list, listLoading, listError, meta, page } = state.modelPanel.general.documents.list.data;
    const { pending } = state.modelPanel.general.documents.list.removeItem;
    return {
        model: data,
        list, listLoading, listError, meta, page,
        removingItems: pending,
    };
};

const mapDispatchToProps = {
    modelPanelGeneralFetchDocumentList,
    modelPanelGeneralFetchDocumentListCleanState,
    modelPanelGeneralDocumentListSetPage,
    modelPanelGeneralDocumentListRemoveItem,
    modelPanelGeneralDocumentListRemoveItemCleanState,
};

export const DocumentsContainer = connect(mapStateToProps, mapDispatchToProps)(DocumentsContainerComponent);