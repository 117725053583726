import React from "react"
import PropTypes from "prop-types"

import { Attachments, ModelAttachments } from "./Attachments"

export const AttachmentsContainer = props => {
  const onAttachmentChange = attachments => {
    props.onModelUpdate(props.model, { files: attachments })
  }

  return (
    <>
      {!props.eventId ? (
        <Attachments
          attachments={props?.model?.files}
          onChange={onAttachmentChange}
        />
      ) : (
        <ModelAttachments eventId={props.eventId} modelId={props.model?.id} />
      )}
    </>
  )
}

AttachmentsContainer.propTypes = {
  model: PropTypes.object,
  eventId: PropTypes.number,
  onModelUpdate: PropTypes.func,
}
