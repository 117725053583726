import React from "react"
import PropTypes from "prop-types"

import { HorizontalMenu, TabsNav } from "components"
import { TAB_BAR_TYPE_MENU, TAB_BAR_TYPE_NAV } from "../consts"

export const TabBar = props => {
  return (
    <>
      {props.tabBarType === TAB_BAR_TYPE_NAV ? (
        <TabsNav
          options={props.tabs}
          active={props.activeTab}
          onChange={tab => props.onTabChange(tab)}
          className={props.className}
        />
      ) : (
        <HorizontalMenu
          items={props.tabs}
          active={props.activeTab}
          onChange={tab => props.onTabChange(tab)}
        />
      )}
    </>
  )
}

TabBar.propTypes = {
  activeTab: PropTypes.string,
  onTabChange: PropTypes.func,
  tabs: PropTypes.array,
  tabBarType: PropTypes.oneOf([TAB_BAR_TYPE_MENU, TAB_BAR_TYPE_NAV]),
  className: PropTypes.string,
}
