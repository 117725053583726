import { takeEvery, fork, put, all, select } from "redux-saga/effects"

import { socketOnChatTyping } from "./actions"

export const participantState = state =>
  state.chat.conversation.data.participant

function* onChatTyping({ payload: { data } }) {
  const { userId } = data

  const participant = yield select(participantState)

  // if message come from current participant
  const isCurrentChat = participant?.id === userId

  if (isCurrentChat) {
  }
}

export function* watchOnChatTyping() {
  yield takeEvery(socketOnChatTyping, onChatTyping)
}

function* typingSaga() {
  yield all([fork(watchOnChatTyping)])
}

export default typingSaga
