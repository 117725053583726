import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames"
import { Input, Label } from "reactstrap"

export const Checkbox = ({
    id,
    item,
    index,
    fieldValue,
    onChange,
}) => {
    const { label, value, color, size } = item;

    const renderLabel = (label) => {
        if (label) {
            if (typeof label === 'string') {
                return label
            } else return label;
        } else return <></>;
    };

    return (
        <div className={classNames('form-check', {
            [`form-check-${color}`]: color,
            [`form-check_size_${size}`]: size,
        })}>
            <div className="form-check-wrap">
                <Input
                    className={classNames('form-check-input', {})}
                    type="checkbox"
                    id={`${id}-${value}-${index}`}
                    value={value}
                    checked={fieldValue.includes(value)}
                    onChange={(event) => onChange(event)}
                />
                <Label
                    className="form-check-label"
                    htmlFor={`${id}-${value}-${index}`}
                >
                    {renderLabel(label)}
                </Label>
            </div>
        </div>
    );
};

export const CheckboxGroup = ({
    id,
    label,
    normalize,
    options,
    fieldValue,
    name,
    onChange,
    className,
    ...props
}) => {
    const inputValue = fieldValue;

    const handleChange = (event) => {
        const arr = [...inputValue];
        const value = normalize ? normalize(event.target.value) : event.target.value;
        if (event.target.checked) {
            arr.push(value);
        } else {
            arr.splice(arr.indexOf(value), 1);
        }

        if (onChange) {
            onChange(arr);
        }
    };

    return (
        <div className={classNames('form-control form-checkbox-group', {
            [className]: className,
        })}>
            {options.map((item, index) => {
                return (
                    <Checkbox
                        key={`${id}-${index}`}
                        id={id}
                        fieldValue={fieldValue}
                        item={item}
                        index={index}
                        onChange={handleChange}
                    />
                );
            })}
        </div>
    );
};

Checkbox.propTypes = {
    id: PropTypes.any,
    item: PropTypes.any,
    fieldValue: PropTypes.any,
    onChange: PropTypes.func,
    index: PropTypes.number,
}

CheckboxGroup.propTypes = {
    id: PropTypes.any,
    label: PropTypes.any,
    normalize: PropTypes.func,
    options: PropTypes.array,
    fieldValue: PropTypes.any,
    name: PropTypes.any,
    onChange: PropTypes.func,
    className: PropTypes.string,
}