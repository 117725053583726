import {
  MODEL_BOOK_PHOTOS_PICKER_ADD_ITEMS,
  MODEL_BOOK_PHOTOS_PICKER_CHANGE_ORDER,
  MODEL_BOOK_PHOTOS_PICKER_CLEAN_VALUE,
  MODEL_BOOK_PHOTOS_PICKER_REMOVE_ITEM,
  MODEL_BOOK_PHOTOS_PICKER_SET_COVER,
  MODEL_BOOK_PHOTOS_PICKER_SET_VALUE,
  MODEL_BOOK_PHOTOS_PICKER_TOGGLE_ITEM,
} from "./actionTypes"

export const modelBookPhotosPickerSetValue = data => {
  return {
    type: MODEL_BOOK_PHOTOS_PICKER_SET_VALUE,
    payload: data,
  }
}

export const modelBookPhotosPickerCleanValue = () => {
  return {
    type: MODEL_BOOK_PHOTOS_PICKER_CLEAN_VALUE,
  }
}

export const modelBookPhotosPickerAddValue = data => {
  return {
    type: MODEL_BOOK_PHOTOS_PICKER_ADD_ITEMS,
    payload: data,
  }
}

export const modelBookPhotosPickerRemoveItem = id => {
  return {
    type: MODEL_BOOK_PHOTOS_PICKER_REMOVE_ITEM,
    payload: { id },
  }
}

export const modelBookPhotosPickerToggleItem = data => {
  return {
    type: MODEL_BOOK_PHOTOS_PICKER_TOGGLE_ITEM,
    payload: { data },
  }
}

export const modelBookPhotosPickerSetCover = id => {
  return {
    type: MODEL_BOOK_PHOTOS_PICKER_SET_COVER,
    payload: { id },
  }
}

export const modelBookPhotosPickerChangeOrder = data => {
  return {
    type: MODEL_BOOK_PHOTOS_PICKER_CHANGE_ORDER,
    payload: { data },
  }
}
