import React from "react"
import {
  modelBookPhotosPickerAddValue,
  modelBookPhotosPickerChangeOrder,
  modelBookPhotosPickerRemoveItem,
  modelBookPhotosPickerSetCover,
  modelBookPhotosPickerToggleItem,
} from "store/actions"
import { useDispatch } from "react-redux"

export const useBookPhotosPicker = () => {
  const dispatch = useDispatch()

  const toggleSelect = photo => {
    const data = {
      is_cover: false,
      photo: {
        ...photo,
      },
    }
    dispatch(modelBookPhotosPickerToggleItem(data))
  }

  const removeItem = item => {
    const { photo } = item
    dispatch(modelBookPhotosPickerRemoveItem(photo.id))
  }

  const changeCover = item => {
    const { photo } = item
    dispatch(modelBookPhotosPickerSetCover(photo.id))
  }

  const addItems = photos => {
    const data = [
      ...photos.map(i => ({
        is_cover: false,
        photo: {
          ...i,
        },
      })),
    ]
    dispatch(modelBookPhotosPickerAddValue(data))
  }

  const changeOrder = value => {
    dispatch(modelBookPhotosPickerChangeOrder(value))
  }

  return { toggleSelect, removeItem, addItems, changeCover, changeOrder }
}
