import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "i18n"
import { API } from "api"
import { userOptionsUpdate } from "./slice"
import { userOptionsData } from "../actions"

function* updateOptions({ payload: { params, onError, onSuccess } }) {
  try {
    const response = yield call(API.profile.updateOptions, params)

    const { data } = response

    toastr.success(i18n.t("edit.success"))

    yield put(userOptionsUpdate.updateCompleted())
    yield put(userOptionsData.updateData({ data: data.data }))
    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      userOptionsUpdate.updateRejected({
        errors,
        message,
      })
    )

    toastr.error(message)

    if (onError) {
      onError()
    }
  }
}

export function* watchUpdateItem() {
  yield takeEvery(userOptionsUpdate.update, updateOptions)
}

function* updateSaga() {
  yield all([fork(watchUpdateItem)])
}

export default updateSaga
