import { takeLatest, fork, put, all, call } from "redux-saga/effects"

import i18n from "i18n"
import { panelLoader } from "./slice"
import { fetchUser } from "store/user/load/saga"
import { getOptions } from "store/user/options/data/saga"
import { getData } from "store/user/department/permissions/data/saga"
import { ROLE_EMPLOYEE } from "consts"

export function* loadData() {
  try {
    const [user, options] = yield all([call(fetchUser), call(getOptions)])

    if (user && user?.role === ROLE_EMPLOYEE) {
      const permissions = yield call(getData)
    }

    yield put(panelLoader.loadDataSuccess({}))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(panelLoader.loadDataError(message))
  }
}

export function* watchLoadData() {
  yield takeLatest(panelLoader.loadData, loadData)
}

function* loaderSaga() {
  yield all([fork(watchLoadData)])
}

export default loaderSaga
