import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import { websiteAddAvatar } from "./slice"
import i18n from "../../../../i18n"
import { API } from "../../../../api"
import { websiteGeneralData } from "../../general/actions"

function* addItem({ payload: { params } }) {
  try {
    const response = yield call(API.website.uploadWebsiteAvatar, params)

    const { data } = response

    toastr.success(i18n.t("website.add-avatar.success"))

    yield put(websiteAddAvatar.addCompleted())
    yield put(websiteGeneralData.updateData({ avatar: data.data }))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")
    yield put(
      websiteAddAvatar.addRejected({
        errors,
        message,
      })
    )
  }
}

export function* watchAddItem() {
  yield takeEvery(websiteAddAvatar.add, addItem)
}

function* addSaga() {
  yield all([fork(watchAddItem)])
}

export default addSaga
