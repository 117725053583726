import React from 'react';
import PropTypes from "prop-types"
import { Row } from "reactstrap";
import { useTranslation } from 'react-i18next';

import { RatingGridItem } from './RatingGridItem';
import { Pagination } from '../../../../../../components';

import * as HOC from '../../../../../../HOC';
const DataShower = HOC.withDataShowerOverlay();

export const RatingGrid = (props) => {
    const { t } = useTranslation();

    return (
        <Row>
            <div className={'rating-grid mb-4'}>
                <DataShower isLoading={props.loading} isFailed={props.error} error={props.error}>
                    {!!props.items.length ? (
                        <>
                            <div className="grid">
                                <div className="grid__row">
                                    {props.items.map((item, i) => (
                                        <div className="grid__col" key={`rating-grid-item-${i}`}>
                                            <div className="grid__item">
                                                <RatingGridItem
                                                    item={item}
                                                    withRemove={props.withRemove}
                                                    onRemove={props.onRemove}
                                                    loading={props.removing.includes(item?.id)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <Pagination data={props.meta} onPageChange={props.onPageChange}  />
                        </>
                    ) : !props.loading ? (
                        t('empty')
                    ) : null}
                </DataShower>
            </div>
        </Row>
    );
};

RatingGrid.propTypes = {
    items: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.any,
    page: PropTypes.any,
    meta: PropTypes.object,
    withRemove: PropTypes.bool,
    removing: PropTypes.array,

    onPageChange: PropTypes.func,
    onRemove: PropTypes.func,
}