import {
    MODEL_PANEL_GENERAL_FETCH_DOCUMENT,
    MODEL_PANEL_GENERAL_FETCH_DOCUMENT_SUCCESS,
    MODEL_PANEL_GENERAL_FETCH_DOCUMENT_ERROR,
    MODEL_PANEL_GENERAL_FETCH_DOCUMENT_CLEAN_STATE,
} from './actionTypes';

export const modelPanelGeneralFetchDocument = (id) => {
    return {
        type: MODEL_PANEL_GENERAL_FETCH_DOCUMENT,
        payload: { id },
    }
};

export const modelPanelGeneralFetchDocumentSuccess = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_FETCH_DOCUMENT_SUCCESS,
        payload: data,
    }
};

export const modelPanelGeneralFetchDocumentError = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_FETCH_DOCUMENT_ERROR,
        payload: data,
    }
};

export const modelPanelGeneralFetchDocumentCleanState = () => {
    return { type: MODEL_PANEL_GENERAL_FETCH_DOCUMENT_CLEAN_STATE };
};