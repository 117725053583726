import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Col } from "reactstrap"
import * as Yup from "yup"
import {
  Button,
  ControllerHF,
  InputField,
} from "../../../../../../../components"

export const CustomMenuItemForm = props => {
  const { t } = useTranslation()

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      title: "",
      custom_url: "",
      ...props.initialValues,
    },
    resolver: yupResolver(
      Yup.object().shape({
        title: Yup.string().required("field.error.required"),
        custom_url: Yup.string().required("field.error.required"),
      })
    ),
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
    }

    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Col>
          <ControllerHF
            name={"title"}
            control={control}
            component={InputField}
            id={"title"}
            label={t("title")}
            placeholder={t("title")}
          />
          <ControllerHF
            name={"custom_url"}
            control={control}
            component={InputField}
            id={"custom_url"}
            label={t("custom_url")}
            placeholder={t("custom_url")}
          />

          <div className="mt-3">
            <div className="button-items">
              <Button
                submit
                title={"btn.save"}
                disabled={props.loading}
                loading={props.loading}
              />

              <Button
                title={"cancel"}
                color="light"
                outline
                onClick={onCancel}
              />
            </div>
          </div>
        </Col>
      </form>
    </>
  )
}

CustomMenuItemForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
