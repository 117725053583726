import React from "react"
import PropTypes from "prop-types"
import { Row } from "reactstrap"
import { useTranslation } from "react-i18next"

import { PhotosGridItem } from "./PhotosGridItem"
import { MasonryDataGrid } from "components"

export const PhotosGrid = props => {
  const { t } = useTranslation()

  const masonryConfig = {
    options: {
      gutter: 8,
    },
    enableResizableChildren: true,
  }

  return (
    <Row>
      <div className={"model-uploaded-photos-grid mb-4"}>
        <MasonryDataGrid
          masonryConfig={masonryConfig}
          loading={props.loading}
          error={props.error}
          meta={props.meta}
          onPageChange={props.onPageChange}
          items={props.items}
          renderItem={item => (
            <div className={"grid-item"}>
              <PhotosGridItem
                item={item}
                onSelect={props.onPhotoSelect}
                selected={item.id === props.selectedPhoto?.id}
                loading={props.uploading && props.attachmentId === item.id}
                markAvatar={props.profileAvatarSrc === item?.attachment?.src}
              />
            </div>
          )}
          empty={t("no_photos")}
        />
      </div>
    </Row>
  )
}

PhotosGrid.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,
  selectedPhoto: PropTypes.object,
  uploading: PropTypes.bool,
  attachmentId: PropTypes.number,
  profileAvatarSrc: PropTypes.string,

  onPageChange: PropTypes.func,
  onPhotoSelect: PropTypes.func,
}
