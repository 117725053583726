import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import { API } from "../../../../api"
import { agenciesAddAgency } from "./slice"
import i18n from "../../../../i18n"

function* addAgency({ payload: { params, onSuccess, onError } }) {
    try {
        const response = yield call(API.agency.addAgency, params)

        const { data } = response

        toastr.success(i18n.t("agency.add.success"))

        if (onSuccess) {
            onSuccess()
        }
        yield put(agenciesAddAgency.addCompleted())

    } catch (error) {
        const errors = error.response?.data?.errors || {}
        const message = error.response?.data?.message || i18n.t("error")

        yield put(
            agenciesAddAgency.addRejected({
                errors,
                message,
            })
        )

        if (onError) {
            onError(errors)
        }
    }
}

export function* watchAddAgency() {
    yield takeEvery(agenciesAddAgency.add, addAgency)
}

function* addSaga() {
    yield all([fork(watchAddAgency)])
}

export default addSaga