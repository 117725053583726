import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Container } from "reactstrap"

import { Drawer } from "../../../Drawer"

export const ModelDetailsDrawer = props => {
  const { t } = useTranslation()

  const handleCloseDrawer = () => {
    props.onClose()
  }

  const onDestroy = () => {
    props.onDestroy()
  }

  const title = useMemo(() => {
    if (props.model) {
      return `${props.model?.name} ${props.model?.surname || ''}`
    }
    return ""
  }, [props.model])

  return (
    <React.Fragment>
      <Drawer
        title={title}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container drawer_nested"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <Container fluid>
          {props.children}
        </Container>
      </Drawer>
    </React.Fragment>
  )
}

ModelDetailsDrawer.propTypes = {
  model: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  children: PropTypes.any,
}
