import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

export const PostContent = ({ postData }) => {
  const {
    event: {
      title,
      extendedProps: { times },
    },
  } = postData

  const renderTimes = () =>
    times.map((item, index) => (
      <div key={index}>
        {item.start} {item.end ? `- ${item.end}` : null}
      </div>
    ))

  return (
    <div className={classNames("d-flex", {})}>
      {title && <div>{title}</div>}
      {times && !!times.length && <div className={"time"}>{renderTimes()}</div>}
    </div>
  )
}

PostContent.propTypes = {
  postData: PropTypes.object,
}
