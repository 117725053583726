import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { IconButton } from "components"

export const Invoices = ({
  data,
  onEdit,
  onView,
  onRemove,
  removing,
  onDownload,
  send,
  onSend,
}) => {
  const { t } = useTranslation()

  return (
    <div className="event-preview-invoices">
      <div className="table-responsive">
        <table className="table table-nowrap table-border-bottom">
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>
                  <div className={"text-nowrap"}>
                    <span className={"fw-medium"}>{item.invoice_number}</span>
                  </div>
                </td>

                <td
                  className={
                    "table-column-sticky_end bg-white text-nowrap text-end"
                  }
                  style={{ width: "76px" }}
                >
                  {send && (
                    <IconButton
                      name={"mail"}
                      onClick={() => onSend()}
                      bg
                      disabled={removing}
                    />
                  )}
                  <IconButton
                    name={"edit"}
                    onClick={() => onEdit(item)}
                    bg
                    wrapClass={"ms-2"}
                    disabled={removing}
                  />
                  <IconButton
                    name={"download"}
                    onClick={() => onDownload(item)}
                    bg
                    wrapClass={"ms-2"}
                    disabled={removing}
                  />
                  <IconButton
                    name={"view"}
                    onClick={() => onView(item)}
                    bg
                    color={"text-white"}
                    bgColor={"bg-dark"}
                    wrapClass={"ms-2"}
                    disabled={removing}
                  />
                  <IconButton
                    name={"remove"}
                    onClick={() => onRemove(item)}
                    bg
                    wrapClass={"ms-2"}
                    disabled={removing}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

Invoices.propTypes = {
  data: PropTypes.array,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onRemove: PropTypes.func,
  removing: PropTypes.bool,
  onDownload: PropTypes.func,
  send: PropTypes.bool,
  onSend: PropTypes.func,
}
