import React from "react"
import PropTypes from "prop-types"
import { MODAL_NOTIFICATION_ALIAS } from "consts"
import { CalendarEventInfo, ClientStatement } from "./NotificationContent"
import { ModalNotificationWrapper } from "./ModalNotificationWrapper"

export const ModalNotification = ({
  alias = MODAL_NOTIFICATION_ALIAS.CALENDAR_EVENT,
  data,
  removeNotification,
}) => {
  const renderModalContent = alias => {
    switch (alias) {
      case MODAL_NOTIFICATION_ALIAS.CALENDAR_EVENT: {
        return <CalendarEventInfo data={data} />
      }

      case MODAL_NOTIFICATION_ALIAS.CLIENT_STATEMENT: {
        return <ClientStatement data={data} />
      }

      default:
        return null
    }
  }

  return (
    <ModalNotificationWrapper
      onModalClose={removeNotification}
      isNewNotification={!!data}
    >
      {renderModalContent(alias)}
    </ModalNotificationWrapper>
  )
}

ModalNotification.propTypes = {
  data: PropTypes.object,
  alias: PropTypes.string,
  removeNotification: PropTypes.func,
}
