import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Card, Badge } from "reactstrap"
import { useTranslation } from "react-i18next"
import { connect, useDispatch } from "react-redux"

import { Header } from "./Header"
import { MessagesContainer } from "./MessagesContainer"
import { EntryToolbar } from "./EntryToolbar"
import {
  chatConversationData,
  chatConversationMessagesRead,
} from "store/actions"
import { commonHelper } from "helpers"

const RoomComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(chatConversationData.cleanState())
    }
  }, [])

  // check app in background and received new messages
  useEffect(() => {
    if (props.backgroundUnreadMessages) {
      if (!props.windowFocus) {
        commonHelper.common.setDocumentTitle(t("new_message"))
      } else {
        commonHelper.common.setDocumentTitle(t("title.chat"))
        dispatch(chatConversationData.setBackgroundUnreadMessages(false))

        // send read chat request
        dispatch(
          chatConversationMessagesRead.submit({
            userId: props.conversation?.participant?.id,
            chatId: props.conversation?.id,
            isGroup: props.conversation?.is_group,
          })
        )
      }
    }
  }, [props.windowFocus, props.backgroundUnreadMessages])

  return (
    <div className="w-100 h-100 user-chat">
      <Card className={"h-100 bg-neutral"}>
        {props.conversation ? (
          <div>
            <Header />

            <MessagesContainer />

            <EntryToolbar />
          </div>
        ) : (
          <div
            className={
              "chat__room-empty-block d-flex align-items-center justify-content-center"
            }
          >
            <Badge
              pill
              className={"font-size-12 badge-soft-secondary rounded-pill"}
            >
              {t("select_chat_to_start")}
            </Badge>
          </div>
        )}
      </Card>
    </div>
  )
}

RoomComponent.propTypes = {
  conversation: PropTypes.object,
  backgroundUnreadMessages: PropTypes.bool,
  windowFocus: PropTypes.bool,
}

const mapStateToProps = state => {
  const { data: conversation, backgroundUnreadMessages } =
    state.chat.conversation.data

  return {
    conversation,
    backgroundUnreadMessages,
    windowFocus: state.app.state.windowFocus,
  }
}

export const Room = connect(mapStateToProps)(RoomComponent)
