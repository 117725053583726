import { all } from "redux-saga/effects"

import updateSaga from "./update/saga"
import dataSaga from "./data/saga"

function* modelDataSaga() {
  yield all([updateSaga(), dataSaga()])
}

export default modelDataSaga
