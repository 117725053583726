import {
  CLIENTS_CASTINGS,
  CLIENTS_EARNINGS,
  CLIENTS_JOBS,
  COMPARISON_BY_EARNINGS,
  EMPLOYEES_CASTINGS,
  EMPLOYEES_EARNINGS,
  EMPLOYEES_JOBS,
  MODELS_CASTINGS,
  MODELS_EARNINGS,
  MODELS_JOBS,
  TOP_TEN_CLIENTS_BY_EARNINGS,
  TOP_TEN_CLIENTS_BY_RATING,
  TOP_TEN_EMPLOYEES_BY_EARNINGS,
  TOP_TEN_EMPLOYEES_BY_RATING,
  TOP_TEN_MODELS_BY_EARNINGS,
  TOP_TEN_MODELS_BY_RATING,
} from "consts"

export const formatStatsData = (graphAlias, data) => {
  switch (graphAlias) {
    case TOP_TEN_CLIENTS_BY_RATING: {
      return data.map(item => {
        return {
          y: item.rating,
          x: item.client,
        }
      })
    }
    case TOP_TEN_EMPLOYEES_BY_RATING: {
      return data.map(item => {
        return {
          y: item.rating,
          x: item.employee,
        }
      })
    }
    case TOP_TEN_MODELS_BY_RATING: {
      return data.map(item => {
        return {
          y: item.rating,
          x: item.full_name,
        }
      })
    }
    case TOP_TEN_CLIENTS_BY_EARNINGS: {
      return data.map(item => {
        return {
          y: item.earning,
          x: item.client,
        }
      })
    }
    case TOP_TEN_EMPLOYEES_BY_EARNINGS: {
      return data.map(item => {
        return {
          y: item.earning,
          x: item.employee,
        }
      })
    }
    case TOP_TEN_MODELS_BY_EARNINGS: {
      return data.map(item => {
        return {
          y: item.earning,
          x: item.full_name,
        }
      })
    }
    case EMPLOYEES_EARNINGS:
    case CLIENTS_EARNINGS:
    case MODELS_EARNINGS: {
      return data.earnings
    }

    case MODELS_JOBS:
    case EMPLOYEES_JOBS:
    case CLIENTS_JOBS: {
      return data.job
    }
    case MODELS_CASTINGS:
    case EMPLOYEES_CASTINGS:
    case CLIENTS_CASTINGS: {
      return data.casting
    }

    case COMPARISON_BY_EARNINGS: {
      return {
        baseChart: data.first_period.map(value => {
          return {
            x: value.group_date,
            y: value.converted_net_value,
          }
        }),
        comparisonChart: data.following_period.map(value => {
          return {
            x: value.group_date,
            y: value.converted_net_value,
          }
        }),
      }
    }
  }
}
