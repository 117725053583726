import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const chatConversationAttachmentUploadSlice = createSlice({
  name: "chatConversationAttachmentUpload",
  initialState,
  reducers: {
    upload(state) {
      state.loading = true
      state.error = null
    },
    uploadSuccess(state) {
      state.loading = false
    },
    uploadError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} upload
 * @property {function} uploadSuccess
 * @property {function} uploadError
 * @property {function} cleanState
 */
export const chatConversationAttachmentUpload =
  chatConversationAttachmentUploadSlice.actions

export default chatConversationAttachmentUploadSlice.reducer
