import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

export const StatementReminderIcon = ({ className, active = false }) => {
  return (
    <i
      className={classNames("font-size-16 text-secondary bx", {
        [className]: className,
        "bx-bell": !active,
        "bxs-bell": active,
      })}
    />
  )
}

StatementReminderIcon.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
}
