import { takeLatest, fork, put, all, call } from "redux-saga/effects"
import i18n from "../../../../i18n"
import { API } from "../../../../api"
import { RequestUtils } from "../../../../utils"
import { clientRatingsData } from "./slice"

export function* getData({ payload: { id, params } }) {
  try {
    let requestParams = {
      includes: [],
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response = yield call(
      API.client.getClientRatingsData,
      id,
      requestParams
    )

    const { data } = response

    const {
      meta: { current_page, last_page },
    } = data
    if (current_page && last_page && current_page > last_page) {
      yield put(
        clientRatingsData.getData({
          id,
          params: {
            ...params,
            page: last_page,
          },
        })
      )
    } else {
      yield put(clientRatingsData.getDataCompleted(data))
    }
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")

    yield put(clientRatingsData.getDataRejected(message))
  }
}

export function* watchGetData() {
  yield takeLatest(clientRatingsData.getData, getData)
}

function* dataSaga() {
  yield all([fork(watchGetData)])
}

export default dataSaga
