export const USER_PROFILE_SET_DATA = "USER_PROFILE_SET_DATA"
export const USER_PROFILE_CLEAN_STATE = "USER_PROFILE_CLEAN_STATE"
export const USER_PROFILE_UPDATE_DATA = "USER_PROFILE_UPDATE_DATA"

export const USER_PROFILE_UPDATE_COMPANY_LOGO =
  "USER_PROFILE_UPDATE_COMPANY_LOGO"

export const USER_PROFILE_UPDATE_SUBSCRIPTION =
  "USER_PROFILE_UPDATE_SUBSCRIPTION"

export const USER_PROFILE_RESTORE_COMPANY = "USER_PROFILE_RESTORE_COMPANY"

export const USER_PROFILE_UPDATE_UNREAD_MESSAGES =
  "USER_PROFILE_UPDATE_UNREAD_MESSAGES"
