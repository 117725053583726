import { all } from "redux-saga/effects"

import listSaga from "./list/saga"
import sendSaga from "./send/saga"
import readSaga from "./read/saga"

function* messagesSaga() {
  yield all([listSaga(), sendSaga(), readSaga()])
}

export default messagesSaga
