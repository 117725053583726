import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  modelsSyncCount: 0,
  agenciesSyncCount: 0,
  clientsSyncCount: 0,
}

const socketImportDataSlice = createSlice({
  name: "socketImportData",
  initialState,
  reducers: {
    syncModels(state) {
      state.modelsSyncCount = state.modelsSyncCount + 1
    },
    syncAgencies(state) {
      state.agenciesSyncCount = state.agenciesSyncCount + 1
    },
    syncClients(state) {
      state.clientsSyncCount = state.clientsSyncCount + 1
    },
    cleanState() {
      return initialState
    },
  },
})

/**
 * @namespace
 * @property {function} syncModels
 * @property {function} syncAgencies
 * @property {function} syncClients
 * @property {function} cleanState
 */

export const socketImportData = socketImportDataSlice.actions

export default socketImportDataSlice.reducer
