import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { createFilter } from "react-select"

import { SelectorCombobox } from "components"

export const ModelSelect = ({
  value,
  fetching,
  options = [],
  className,
  ...props
}) => {
  const { t } = useTranslation()

  const filterConfig = {
    matchFrom: "start",
  }

  return (
    <SelectorCombobox
      value={value}
      options={options}
      onChange={props.onChange}
      onSelect={null}
      onFetchOptions={null}
      id={"models-select"}
      label={t("filter")}
      placeholder={t("select_models")}
      loading={fetching}
      getOptionLabel={option =>
        option.name + (option.surname ? ` ${option.surname}` : "")
      }
      getOptionValue={option => `${option.id}`}
      filterOption={createFilter(filterConfig)}
      controlShouldRenderValue={true}
      isClearable={true}
      menuPortalTarget={document.querySelector("body")}
    />
  )
}

ModelSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  fetching: PropTypes.bool,
  options: PropTypes.array,
  onFetch: PropTypes.func,
  className: PropTypes.string,
}
