import { all, call, fork, put, select, takeLatest } from "redux-saga/effects"

import i18n from "../../../../../i18n"
import {
  MODELS_PANEL_FETCH_LIST,
  MODELS_PANEL_LIST_CALL_CHANGE_FILTER,
} from "./actionTypes"
import {
  modelsPanelFetchListError,
  modelsPanelFetchListSuccess,
  modelsPanelListChangeFilter,
} from "./actions"

import { API } from "../../../../../api"
import { RequestUtils } from "../../../../../utils"
import { selectModelsPanelState } from "../../selectors"

export function* fetchList({ payload: { params } }) {
  try {
    let requestParams = {
      includes: [],
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response = yield call(API.model.getModelList, requestParams)

    const { data } = response

    yield put(modelsPanelFetchListSuccess(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(modelsPanelFetchListError(message))
  }
}

function* modelsPanelListCallChangeFilter({ payload }) {
  const { isAddToGroupState } = yield select(selectModelsPanelState)

  if (isAddToGroupState) {
    yield put(modelsPanelListChangeFilter({ ...payload, not_in_group: true }))
  } else {
    yield put(modelsPanelListChangeFilter(payload))
  }
}

export function* watchModelsPanelListFilter() {
  yield takeLatest(
    MODELS_PANEL_LIST_CALL_CHANGE_FILTER,
    modelsPanelListCallChangeFilter
  )
}

export function* watchFetchList() {
  yield takeLatest(MODELS_PANEL_FETCH_LIST, fetchList)
}

function* dataSaga() {
  yield all([fork(watchFetchList), fork(watchModelsPanelListFilter)])
}

export default dataSaga
