import { useState } from "react"

const defaultState = {
  period: [null, null],
  fetchParams: {},
}

export const useCompareEventsCalendar = () => {
  const [state, setState] = useState(defaultState)

  const onSetup = (data = { period: [], fetchParams: {} }) => {
    setState(data)
  }

  const onClean = () => {
    setState(defaultState)
  }

  return {
    state,
    onSetup,
    onClean,
  }
}
