import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { calendarEventInvoiceCreate } from "./slice"
import { calendarEventData } from "../../data/slice"

function* create({ payload: { id, params, onSuccess, onError } }) {
  try {
    const response = yield call(API.calendar.createInvoice, id, params)

    const { data } = response

    yield put(calendarEventInvoiceCreate.createSuccess())

    // update invoice in event preview
    yield put(calendarEventData.updateData({ invoice: { ...data?.data } }))

    if (onSuccess) {
      onSuccess(data?.data)
    }

    toastr.success(i18n.t("save.success"))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      calendarEventInvoiceCreate.createError({
        errors,
        message,
      })
    )
    if (onError) {
      onError(errors)
    }
  }
}

export function* watchCreate() {
  yield takeEvery(calendarEventInvoiceCreate.create, create)
}

function* createSaga() {
  yield all([fork(watchCreate)])
}

export default createSaga
