import {
  MODEL_PANEL_GENERAL_FETCH_LOGISTICS_LIST,
  MODEL_PANEL_GENERAL_FETCH_LOGISTICS_LIST_SUCCESS,
  MODEL_PANEL_GENERAL_FETCH_LOGISTICS_LIST_ERROR,
  MODEL_PANEL_GENERAL_FETCH_LOGISTICS_LIST_CLEAN_STATE,
  MODEL_PANEL_GENERAL_LOGISTICS_LIST_SET_PAGE,
} from "./actionTypes"

export const modelPanelGeneralFetchLogisticsList = (id, params) => {
  return {
    type: MODEL_PANEL_GENERAL_FETCH_LOGISTICS_LIST,
    payload: { id, params },
  }
}

export const modelPanelGeneralFetchLogisticsListSuccess = data => {
  return {
    type: MODEL_PANEL_GENERAL_FETCH_LOGISTICS_LIST_SUCCESS,
    payload: data,
  }
}

export const modelPanelGeneralFetchLogisticsListError = data => {
  return {
    type: MODEL_PANEL_GENERAL_FETCH_LOGISTICS_LIST_ERROR,
    payload: data,
  }
}

export const modelPanelGeneralFetchLogisticsListCleanState = () => {
  return { type: MODEL_PANEL_GENERAL_FETCH_LOGISTICS_LIST_CLEAN_STATE }
}

export const modelPanelGeneralLogisticsListSetPage = data => {
  return {
    type: MODEL_PANEL_GENERAL_LOGISTICS_LIST_SET_PAGE,
    payload: data,
  }
}
