import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { PageHeader, MegaTagTitle, EmployeesTable } from "../../components"
import {
  employeeFetchList,
  employeeListSetPage,
  employeeListCleanState,
} from "../../store/actions"

const EmployeesComponent = props => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      props.employeeListCleanState()
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [props.page])

  const fetchData = () => {
    let params = {
      page: props.page,
    }

    props.employeeFetchList(params)
  }

  const handleChangePage = item => {
    props.employeeListSetPage(item.selected + 1)
  }

  return (
    <React.Fragment>
      <MegaTagTitle title={"title.team"} />

      <div className="page-content">
        <Container fluid>
          <PageHeader title={t("title.team")} />

          <EmployeesTable
            list={props.list}
            loading={props.listLoading}
            error={props.listError}
            meta={props.meta}
            page={props.page}
            onChangePage={handleChangePage}
            userId={props.user.id}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

EmployeesComponent.propTypes = {
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
  user: PropTypes.object,
  employeeFetchList: PropTypes.func,
  employeeListSetPage: PropTypes.func,
  employeeListCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { list, listLoading, listError, meta, page } = state.employee.list.data
  return {
    list,
    listLoading,
    listError,
    meta,
    page,
    user: state.user.profile.data,
  }
}

const mapDispatchToProps = {
  employeeFetchList,
  employeeListSetPage,
  employeeListCleanState,
}

export const Employees = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeesComponent)
