import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import moment from "moment"

import { billingHelper } from "helpers"

const currencySymbol = billingHelper.currency.getCurrency().symbol

export const InvoiceCard = props => {
  const { t } = useTranslation()

  const { id, number, created_at, total } = props.data

  return (
    <div className="billing-invoice-card-overview">
      <div>
        <Link
          to={`/billing/invoices/${id}`}
          className="d-block text-secondary text-decoration-underline mb-2"
        >
          {t("invoice")} {number}
        </Link>
        {/*<h5 className="text-truncate mb-4 mb-lg-5"></h5>*/}
        <ul className="list-inline mt-4 mb-0">
          <li className="list-inline-item me-3">
            <p className="fw-medium mb-2 d-flex align-items-center">
              <i className="bx bx-money me-1 text-muted" />
              {total} {` ${currencySymbol} `}
            </p>
          </li>
          <li className="list-inline-item">
            <p className="fw-medium mb-2 d-flex align-items-center">
              <i className="bx bx-calendar me-1 text-muted" />
              {moment(created_at).format("DD/MM/YYYY")}
            </p>
          </li>
        </ul>
      </div>
    </div>
  )
}

InvoiceCard.propTypes = {
  data: PropTypes.object,
}
