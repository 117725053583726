import {
  PACKAGE_ADD,
  PACKAGE_ADD_SUCCESS,
  PACKAGE_ADD_ERROR,
  PACKAGE_ADD_CLEAN_STATE,
} from "./actionTypes"

const initialState = {
  loading: false,
  error: null,
}

const add = (state = initialState, action) => {
  switch (action.type) {
    case PACKAGE_ADD:
      state = {
        ...state,
        loading: true,
        error: null,
      }
      break

    case PACKAGE_ADD_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break

    case PACKAGE_ADD_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      }
      break

    case PACKAGE_ADD_CLEAN_STATE:
      state = {
        ...state,
        ...initialState,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default add
