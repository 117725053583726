import { takeEvery, fork, put, all, call, select } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { chatContactsRemove } from "./slice"
import { chatContactsList } from "../list/slice"
import { chatConversationData } from "../../conversation/data/slice"
import { selectConversationParticipantId } from "../../conversation/data/selectors"

export const participantIdState = state =>
  selectConversationParticipantId(state)

function* remove({ payload: { id, onSuccess } }) {
  try {
    const response = yield call(API.users.removeContact, id)

    const { data } = response

    yield put(chatContactsRemove.removeSuccess())
    yield put(chatContactsList.removeItem({ id }))

    if (onSuccess) {
      onSuccess(data.data)
    }

    toastr.success(i18n.t("chat.contact.remove.success"))

    const participantId = yield select(participantIdState)
    const isCurrentChat = id === participantId
    if (isCurrentChat) {
      yield put(chatConversationData.cleanState())
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      chatContactsRemove.removeError({
        errors,
        message,
      })
    )
    toastr.error(message)
  }
}

export function* watchRemove() {
  yield takeEvery(chatContactsRemove.remove, remove)
}

function* removeSaga() {
  yield all([fork(watchRemove)])
}

export default removeSaga
