import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { importHelper } from "helpers"
import s from "./Instruction.module.scss"

export const Instruction = props => {
  const { t } = useTranslation()

  return (
    <>
      <ol className={s.list}>
        <li>
          {t("download")}{" "}
          <a
            href={importHelper.entity.getEntityType(props.type)?.example}
            target="_blank"
            rel="noreferrer"
          >
            {t("example")}
          </a>
        </li>
        <li>{t("import.instruction.point-2")}</li>
        <li>{t("upload_file")}</li>
      </ol>
    </>
  )
}

Instruction.propTypes = {
  type: PropTypes.string,
}
