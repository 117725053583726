import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import { useTranslation } from "react-i18next"

import { Button } from "../../../Buttons"
import { ModelsItem } from "./ModelsItem"
import * as HOC from "../../../../HOC"

const DataShower = HOC.withDataShower()

export const Models = props => {
  const ref = useRef()
  const { t } = useTranslation()

  useEffect(() => {
    if (ref.current) {
      ref.current.recalculate()
    }
  })

  return (
    <>
      <div className="models">
        <DataShower
          isLoading={props.loading && !props.isMoreLoading}
          isFailed={props.error}
          error={props.error}
        >
          {!!props.items.length ? (
            <>
              <div className="models__block">
                <SimpleBar className="h-100" ref={ref}>
                  {props.items.map(item => (
                    <ModelsItem
                      key={`model-item-${item.id}`}
                      item={item}
                      onSelect={props.onSelect}
                      selectedId={props.selectedId}
                    />
                  ))}
                </SimpleBar>
              </div>
            </>
          ) : !props.loading ? (
            t("empty")
          ) : null}
        </DataShower>
      </div>

      {props.showMore && (
        <div className={"mt-3 d-flex justify-content-center w-100"}>
          <Button
            title={"btn.show-more"}
            loading={props.isMoreLoading}
            disabled={props.isMoreLoading}
            onClick={props.onLoadMore}
          />
        </div>
      )}
    </>
  )
}

Models.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  meta: PropTypes.object,
  isMoreLoading: PropTypes.bool,
  showMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  onSelect: PropTypes.func,
  selectedId: PropTypes.string,
}
