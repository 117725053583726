import { all } from "redux-saga/effects"

import panelSaga from "./panel/sagas"
import generalSaga from "./general/sagas"
import photosSaga from "./photos/sagas"
import ratingSaga from "./rating/sagas"
import historySaga from "./history/sagas"
import videoSaga from "./video/sagas"
import bookSaga from "./book/sagas"
import activitiesSaga from "./activities/sagas"
import statementSaga from "./statement/sagas"

function* modelPanelSaga() {
  yield all([
    panelSaga(),
    generalSaga(),
    photosSaga(),
    ratingSaga(),
    historySaga(),
    videoSaga(),
    bookSaga(),
    activitiesSaga(),
    statementSaga(),
  ])
}

export default modelPanelSaga
