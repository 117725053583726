import React from "react"
import PropTypes from "prop-types"
import { Button as StrapButton } from "reactstrap"
import { useFacebookAuth } from "packages/facebook"
import { useTranslation } from "react-i18next"
import { FBconfig } from "configs"
import classnames from "classnames"
import { useMedia } from "react-use"

export const FacebookPermissionsButton = ({
  size = "sm",
  responsive = false,
}) => {
  const isSmallScreen = useMedia("(max-width: 560px)")

  const isTitleVisible = !(isSmallScreen && responsive)

  const { loginToFB, loading, isLoggedIn } = useFacebookAuth()

  const { t } = useTranslation()

  const handleClick = () => {
    loginToFB(FBconfig.permissions)
  }

  if (!isLoggedIn) {
    return null
  }

  return (
    <StrapButton
      type={"button"}
      className={classnames("btn btn-facebook font-weight-bold", {
        [`btn-${size}`]: true,
      })}
      onClick={handleClick}
      disabled={loading}
    >
      <i className={"align-middle bx bxl-facebook-circle font-size-20"} />
      {isTitleVisible && (
        <span className={"text-capitalize ms-2"}>
          {t("change-fb-permissions")}
        </span>
      )}
    </StrapButton>
  )
}

FacebookPermissionsButton.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  responsive: PropTypes.bool,
}
