import { combineReducers } from "redux"

import upload from "./upload/slice"
import remove from "./remove/slice"

const attachmentReducer = combineReducers({
  upload,
  remove,
})

export default attachmentReducer
