import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "../../../../i18n"

import { API } from "../../../../api"
import { clientRemoveProfile } from "./slice"

function* remove({ payload: { id, history } }) {
  try {
    const response = yield call(API.client.removeClient, id)

    const { data } = response

    yield put(clientRemoveProfile.removeCompleted())

    toastr.success(i18n.t("client.remove.success"))

    history.push("/clients")
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      clientRemoveProfile.removeRejected({
        errors,
        message,
      })
    )
    toastr.error(message)
  }
}

export function* watchRemove() {
  yield takeEvery(clientRemoveProfile.remove, remove)
}

function* removeSaga() {
  yield all([fork(watchRemove)])
}

export default removeSaga
