import React from "react"
import PropTypes from "prop-types"
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  generatePath,
} from "react-router-dom"
import { connect } from "react-redux"

import { useDepartmentPermissionGuard } from "hooks"
import { DEPARTMENTS_PERMISSIONS } from "consts"
import { General } from "../../General"
import { Photos } from "../../Photos"
import { Rating } from "../../Rating"
import { History } from "../../History"
import { Video } from "../../Video"
import { BookIndex } from "../../Book"
import { Calendar } from "../../Calendar"
import { Activities } from "../../Activities"
import { Statement } from "../../Statement"

const ModelPanelRoutingComponent = props => {
  let { path } = useRouteMatch()
  const { id } = useParams()

  const { status: allowed } = useDepartmentPermissionGuard({
    permission: DEPARTMENTS_PERMISSIONS.MODEL_STATEMENT_READ,
  })

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={generatePath(`${path}/general`, { id: id })} />
      </Route>
      <Route path={`${path}/general`}>
        <General {...props} />
      </Route>
      <Route path={`${path}/calendar`}>
        <Calendar {...props} />
      </Route>

      {allowed && (
        <Route path={`${path}/statement`}>
          <Statement {...props} />
        </Route>
      )}

      <Route path={`${path}/activities`}>
        <Activities {...props} />
      </Route>
      <Route path={`${path}/photo`}>
        <Photos {...props} />
      </Route>
      <Route path={`${path}/book`}>
        <BookIndex {...props} />
      </Route>
      <Route path={`${path}/video`}>
        <Video {...props} />
      </Route>
      <Route path={`${path}/rating`}>
        <Rating {...props} />
      </Route>
      <Route path={`${path}/history`}>
        <History {...props} />
      </Route>
      <Route>
        <Redirect to={generatePath(`${path}/general`, { id: id })} />
      </Route>
    </Switch>
  )
}

ModelPanelRoutingComponent.propTypes = {}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const ModelPanelRouting = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelPanelRoutingComponent)
