import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { SCREEN } from "./consts"
import { EventModelContractCreate } from "../EventModelContractCreate"
import { EventModelContractEdit } from "../EventModelContractEdit"
import { EventModelContractDrawer } from "../EventModelContractDrawer"

export const EventModelContractManager = ({
  state: { screen, event, model, contract },
  actions,
  navigation,
  ...props
}) => {
  const { t } = useTranslation()

  const onScreenClose = () => {
    navigation.onScreenClose()
  }

  const resetContract = () => {
    actions.resetContract()
  }

  const onCreateSuccess = contract => {
    props.onCreateSuccess(model, contract)

    onScreenClose()
  }

  const onEditSuccess = contract => {
    props.onEditSuccess(model, contract)

    onScreenClose()
  }

  return (
    <>
      <EventModelContractDrawer
        title={t("create_contract")}
        visible={screen === SCREEN.CREATE}
        onClose={onScreenClose}
      >
        <EventModelContractCreate
          onSuccess={onCreateSuccess}
          onCancel={onScreenClose}
          eventId={event?.id}
          modelId={model?.id}
        />
      </EventModelContractDrawer>

      <EventModelContractEdit
        visible={screen === SCREEN.EDIT}
        onSuccess={onEditSuccess}
        onDestroy={null}
        onClose={onScreenClose}
        eventId={event?.id}
        modelId={model?.id}
      />
    </>
  )
}

EventModelContractManager.propTypes = {
  state: PropTypes.object,
  actions: PropTypes.object,
  navigation: PropTypes.object,
  onCreateSuccess: PropTypes.func,
  onEditSuccess: PropTypes.func,
}
