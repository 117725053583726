import { createSlice } from "@reduxjs/toolkit"


const initialState = {
  loading: false,
  error: null
}

const agencyContactUploadFileSlice = createSlice({
  name: "agencyContactUploadFile",
  initialState,
  reducers: {
    upload(state) {
      state.loading = true
      state.error = null
    },
    uploadCompleted(state) {
      state.loading = false
    },
    uploadRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    }
  }
})
/**
 * @namespace
 * @property {function} upload
 * @property {function} uploadCompleted
 * @property {function} uploadRejected
 *  @property {function} cleanState
 */
export const agencyContactUploadFile = agencyContactUploadFileSlice.actions

export default agencyContactUploadFileSlice.reducer
