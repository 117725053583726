import React from "react"
import PropTypes from "prop-types"
import { Popover as StrapPopover, PopoverBody, PopoverHeader } from "reactstrap"
import { useTranslation } from "react-i18next"

export const Popover = ({
  placement,
  popoverOpen,
  popoverId,
  toggle,
  title,
  children,
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <>
      <StrapPopover
        popperClassName={"popover-block"}
        placement={placement}
        isOpen={popoverOpen}
        target={popoverId}
        toggle={toggle}
        trigger={"legacy"}
        fade={false}
        {...rest}
      >
        {title && (
          <PopoverHeader className={"popover-block__header"}>
            {t(`${title}`)}
          </PopoverHeader>
        )}
        <PopoverBody className={"popover-block__body"}>{children}</PopoverBody>
      </StrapPopover>
    </>
  )
}

Popover.propTypes = {
  placement: PropTypes.string,
  popoverOpen: PropTypes.bool,
  popoverId: PropTypes.any,
  toggle: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.any,
}
