import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import {
  modelFetchBooks,
  modelFetchBooksCleanState,
  modelBooksSetPage,
} from "../../../../../store/actions"
import { BooksGrid } from "../BooksGrid"
import { VideosGrid } from "../../VideoComponents/VideosContainer/VideosGrid"
import { PublishedBookContainer } from "../PublishedBookContainer"

const BooksContainerComponent = props => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      props.modelFetchBooksCleanState()
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [props.page, props.syncCount])

  const fetchData = () => {
    const params = {
      page: props.page,
      per_page: 4,
    }
    props.modelFetchBooks(props.model.id, params)
  }

  const handlePageChange = item => {
    props.modelBooksSetPage(item.selected + 1)
  }

  return (
    <>
      <h4 className={"text-capitalize mb-3"}>{t("your_books")}</h4>

      <BooksGrid
        items={props.list}
        loading={props.listLoading}
        error={props.listError}
        meta={props.meta}
        page={props.page}
        removingItems={props.removingItems}
        onPageChange={handlePageChange}
        onRemove={props.onRemove}
        onEdit={props.onEdit}
        onPublish={props.onPublish}
        onCopy={props.onCopy}
        onDownload={props.onDownload}
        packagePickedBooks={props.packagePickedBooks}
        onAddToPackage={props.onAddToPackage}
        onSend={props.onSend}
        onCoverClick={props.onCoverClick}
      />
    </>
  )
}

BooksContainerComponent.propTypes = {
  syncCount: PropTypes.number,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onPublish: PropTypes.func,
  onCopy: PropTypes.func,
  onDownload: PropTypes.func,
  packagePickedBooks: PropTypes.array,
  onAddToPackage: PropTypes.func,
  onSend: PropTypes.func,
  onCoverClick: PropTypes.func,

  model: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
  removingItems: PropTypes.array,

  modelFetchBooks: PropTypes.func,
  modelFetchBooksCleanState: PropTypes.func,
  modelBooksSetPage: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.model.profile
  const { list, listLoading, listError, meta, page } =
    state.modelPanel.book.list.data
  const { pending } = state.modelPanel.book.list.removeItem
  return {
    model: data,
    list,
    listLoading,
    listError,
    meta,
    page,
    removingItems: pending,
  }
}

const mapDispatchToProps = {
  modelFetchBooks,
  modelFetchBooksCleanState,
  modelBooksSetPage,
}

export const BooksContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BooksContainerComponent)
