import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import { Packages } from './Packages';
import { PackageAdd } from './PackageAdd';
import { PackageEdit } from './PackageEdit';

export const PackagesIndex = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <Packages />
            </Route>
            <Route path={`${path}/:id/edit`}>
                <PackageEdit />
            </Route>
            <Route path={`${path}/add`}>
                <PackageAdd />
            </Route>
            <Route>
                <Redirect to={path} />
            </Route>
        </Switch>
    );
};