import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import { EVENT_TYPE_JOB, EVENT_TYPE_CASTING } from "consts"
import { eventsCalendarHelper } from "helpers"
import { modelActivitiesList } from "store/actions"
import { ActivitiesTable } from "./ActivitiesTable"
import { TabNavigator } from "components"
import { Card, CardBody, Col, Row } from "reactstrap"

const getTabLabel = type =>
  eventsCalendarHelper.eventConfig.getEventConfig(type).title

const ActivitiesContainerComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState(EVENT_TYPE_JOB)

  useEffect(() => {
    return () => {
      cleanState()
    }
  }, [])

  const cleanState = () => {
    dispatch(modelActivitiesList.cleanState())
  }

  useEffect(() => {
    fetchList()
  }, [props.page, activeTab])

  const fetchList = () => {
    let params = {
      page: props.page,
      type: activeTab,
    }
    dispatch(modelActivitiesList.getList({ id: props.model.id, params }))
  }

  const handlePageChange = item => {
    dispatch(modelActivitiesList.setPage(item.selected + 1))
  }

  const onTabChange = tab => {
    setActiveTab(tab)

    cleanState()
  }

  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody className={"bg-neutral"}>
              <TabNavigator
                activeTab={activeTab}
                onTabChange={onTabChange}
                destroyOnClose={true}
                tabBarType={"nav"}
                tabs={[
                  {
                    label: getTabLabel(EVENT_TYPE_JOB),
                    value: EVENT_TYPE_JOB,
                    render: () => (
                      <ActivitiesTable
                        type={EVENT_TYPE_JOB}
                        list={props.list}
                        loading={props.listLoading}
                        error={props.listError}
                        meta={props.meta}
                        page={props.page}
                        onPageChange={handlePageChange}
                      />
                    ),
                  },
                  {
                    label: getTabLabel(EVENT_TYPE_CASTING),
                    value: EVENT_TYPE_CASTING,
                    render: () => (
                      <ActivitiesTable
                        type={EVENT_TYPE_CASTING}
                        list={props.list}
                        loading={props.listLoading}
                        error={props.listError}
                        meta={props.meta}
                        page={props.page}
                        onPageChange={handlePageChange}
                      />
                    ),
                  },
                ]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

ActivitiesContainerComponent.propTypes = {
  model: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
}

const mapStateToProps = state => {
  const { data: model } = state.model.profile
  const { list, listLoading, listError, meta, page } =
    state.modelPanel.activities.list
  return {
    model,
    list,
    listLoading,
    listError,
    meta,
    page,
  }
}

export const ActivitiesContainer = connect(mapStateToProps)(
  ActivitiesContainerComponent
)
