import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Container, Row } from "reactstrap"

import { Drawer } from "../../../../../../components"
import { FeaturesDataLoader } from "../FeaturesDataLoader"
import { FeaturesInfo } from "./FeaturesInfo"

const FeaturesViewComponent = props => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Drawer
        title={t("measurements_and_features")}
        visible={props.visible}
        onClose={props.onClose}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        drawerProps={{}}
      >
        <FeaturesDataLoader>
          <Container fluid>
            <Row>
              <FeaturesInfo
                data={props.features}
                measurementsSystem={props.user.settings?.measurement_system}
              />
            </Row>
          </Container>
        </FeaturesDataLoader>
      </Drawer>
    </React.Fragment>
  )
}

FeaturesViewComponent.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,

  features: PropTypes.object,
  user: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.modelPanel.general.features.data
  return {
    features: data,
    user: state.user.profile.data,
  }
}

const mapDispatchToProps = {}

export const FeaturesView = connect(
  mapStateToProps,
  mapDispatchToProps
)(FeaturesViewComponent)
