import { all } from 'redux-saga/effects';

import createSaga from './create/saga';
import listSaga from './list/sagas';
import detailsSaga from './details/sagas';
import ratingSaga from './rating/sagas';

function* employeeSaga() {
    yield all([
        createSaga(),
        listSaga(),
        detailsSaga(),
        ratingSaga()
    ])
}

export default employeeSaga;