import { createSlice } from "@reduxjs/toolkit"


const initialState = {
  loading: false,
  error: null
}

const agencyRemoveRatingSlice = createSlice({
  name: "agencyRemoveRating",
  initialState,
  reducers: {
    remove(state, action) {
      state.loading = true
      state.error = null
    },
    removeCompleted(state, action) {
      state.loading = false
    },
    removeRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    }
  }
})

export const agencyRemoveRating = agencyRemoveRatingSlice.actions

export default agencyRemoveRatingSlice.reducer