import * as Yup from "yup"

const PERCENT_VALUES = {
  min: 0,
  max: 100,
}

export const validationSchema = Yup.object().shape({
  type: Yup.string().nullable().required("field.error.required"),
  currency: Yup.string().nullable().required("field.error.required"),
  label: Yup.string().nullable().max(255, "field.error.length.max-255"),
  value: Yup.number()
    .min(0, "field.error.between")
    .max(999999, "field.error.between")
    .transform((v, o) => (o === "" ? 0 : v))
    .required("field.error.required"),
  count: Yup.number()
    .min(1, "field.error.between")
    .max(999, "field.error.between")
    .transform((v, o) => (o === "" ? 1 : v))
    .required("field.error.required"),
  period: Yup.string().nullable().required("field.error.required"),
  agency_commission: Yup.number()
    .min(PERCENT_VALUES.min, "field.error.percent.between")
    .max(PERCENT_VALUES.max, "field.error.percent.between")
    .transform((v, o) => (o === "" ? 0 : v))
    .required("field.error.required"),
  model_commission: Yup.number()
    .min(PERCENT_VALUES.min, "field.error.percent.between")
    .max(PERCENT_VALUES.max, "field.error.percent.between")
    .integer("field.error.positive_integer")
    .transform((v, o) => (o === "" ? 0 : v))
    .required("field.error.required"),
  net_for_model: Yup.number()
    .min(0, "field.error.min")
    .max(Yup.ref("total_fee"), "field.error.max")
    .transform((v, o) => (o === "" ? 0 : v)),
  description: Yup.string().nullable().max(255, "field.error.length.max-255"),
  agency_percent: Yup.number()
    .min(PERCENT_VALUES.min, "field.error.percent.between")
    .max(PERCENT_VALUES.max, "field.error.percent.between")
    .nullable(true)
    .transform((v, o) => (o === "" ? null : v))
    .when("fashion_model_agency_id", {
      is: fashion_model_agency_id => fashion_model_agency_id,
      then: Yup.number()
        .transform((v, o) => (o === "" ? undefined : v))
        .required("field.error.required"),
    }),
  agency_on: Yup.string()
    .nullable()
    .when("fashion_model_agency_id", {
      is: fashion_model_agency_id => fashion_model_agency_id,
      then: Yup.string().required("field.error.required"),
    }),
})
