import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import i18n from "../../../../../i18n"
import { API } from "../../../../../api"
import { calendarEventModelRateAdd } from "./slice"

function* add({ payload: { event, model, params, onSuccess, onError } }) {
  try {
    const response = yield call(
      API.calendar.addModelRates,
      event,
      model,
      params
    )

    const { data } = response

    yield put(calendarEventModelRateAdd.addSuccess())

    if (onSuccess) {
      onSuccess(data?.data)
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      calendarEventModelRateAdd.addError({
        errors,
        message,
      })
    )

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchAdd() {
  yield takeEvery(calendarEventModelRateAdd.add, add)
}

function* addSaga() {
  yield all([fork(watchAdd)])
}

export default addSaga
