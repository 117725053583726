import React, { useState } from 'react'

export const useSelection = ({
    initialValues = [],
    multi = true,
}) => {
    const [selected, setSelected] = useState(initialValues);

    const reset = (values) => {
        setSelected(values || initialValues);
    };

    const setValue = (values) => {
        if (values) {
            setSelected([...values]);
        }
    }

    const clear = () => {
        setSelected([]);
    }

    return {
        selectionValue: selected,
        selectionSetValue: setValue,
        selectionReset: reset,
        selectionClear: clear,
    };
};