import React from "react"
import { useTranslation } from "react-i18next"

import { ComboboxFormField, ControllerHF } from "components"
import { useFormContext } from "react-hook-form"
import { statementHelper } from "helpers"

export const ClientPaymentStatus = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const options = statementHelper.common.getPaymentStatusConfigs()

  return (
    <ControllerHF
      name={"client_payment_status"}
      control={control}
      component={ComboboxFormField}
      id={"client_payment_status"}
      label={t("client_payment_status")}
      placeholder={t("client_payment_status")}
      options={options}
      normalize={option => (option !== null ? option.value : null)}
      isDetermineValue
      selectProps={{
        isSearchable: false,
        isClearable: true,
      }}
      wrapClass={"mb-0"}
    />
  )
}

ClientPaymentStatus.propTypes = {}
