import React, { useEffect } from "react"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import PropTypes from "prop-types"
import { useDeepCompareEffect } from "react-use"

import {
  Button,
  ComboboxFormField,
  ControllerHF,
  InputField,
  VatIdStatusBadge,
} from "components"
import { validationSchema } from "./validation"
import { NormalizeUtils, FormatUtils } from "utils"
import { useFetchData } from "hooks"
import { API } from "api"

export const BillingInformationEditForm = props => {
  const { t } = useTranslation()

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      email: "",
      first_name: "",
      last_name: "",
      company_name: "",
      phone: "",
      address: "",
      city: "",
      zip: "",
      note: "",
      billing_address_country_id: null,
      tax_id: "",
    },
    resolver: yupResolver(validationSchema),
  })

  const onReset = () => {
    const { phone, country, ...restValues } = props.initialValues

    const formDefaultValues = {
      ...restValues,
      phone: phone ? FormatUtils.formatPhone(phone) : "",
      billing_address_country_id: country?.id || null,
    }

    reset(formDefaultValues)
  }

  // set value after fetch data
  useDeepCompareEffect(() => {
    onReset()
  }, [props.initialValues])

  const onSubmit = values => {
    const { billing_address_country_id, tax_id, phone, ...restValues } = values

    let params = {
      ...restValues,
      phone: phone.replace(/[^0-9]/g, ""),
    }

    if (props.isEditable) {
      params = {
        ...params,
        billing_address_country_id,
        tax_id,
      }
    }

    props.onSubmit(params, { onReset, setError })
  }

  // Fetch countries
  const { data: countriesData, fetchData, loading, error } = useFetchData()

  const onFetchCountries = async () => {
    const request = API.billing.getBillingInfoCountries()
    await fetchData({
      request,
    })
  }

  useEffect(() => {
    if (!loading && !countriesData && !error) {
      onFetchCountries()
    }
  }, [loading, !!countriesData, !!error])
  // === //

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col xs={12} sm={6}>
          <ControllerHF
            name={`first_name`}
            control={control}
            component={InputField}
            id={`first_name`}
            label={t("field.label.first_name")}
            placeholder={t("field.placeholder.first_name")}
            disabled={props.isDisabled}
          />
        </Col>

        <Col xs={12} sm={6}>
          <ControllerHF
            name={`last_name`}
            control={control}
            component={InputField}
            id={`last_name`}
            label={t("field.label.last_name")}
            placeholder={t("field.placeholder.last_name")}
            disabled={props.isDisabled}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={6}>
          <ControllerHF
            name={`email`}
            control={control}
            component={InputField}
            id={`email`}
            label={t("field.label.email")}
            placeholder={t("field.placeholder.email")}
            type="email"
            disabled={props.isDisabled}
          />
        </Col>

        <Col xs={12} sm={6}>
          <ControllerHF
            name={`company_name`}
            control={control}
            component={InputField}
            id={`company_name`}
            label={t("field.label.company")}
            placeholder={t("field.placeholder.company")}
            disabled={props.isDisabled}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={6}>
          <ControllerHF
            name={`phone`}
            control={control}
            component={InputField}
            id={`phone`}
            label={t("field.label.phone")}
            placeholder={t("field.placeholder.phone")}
            normalize={NormalizeUtils.phone}
            disabled={props.isDisabled}
          />
        </Col>

        <Col xs={12} sm={6}>
          <ControllerHF
            name={`address`}
            control={control}
            component={InputField}
            id={`address`}
            label={t("field.label.address")}
            placeholder={t("field.placeholder.address")}
            disabled={props.isDisabled}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={6}>
          <ControllerHF
            name={`city`}
            control={control}
            component={InputField}
            id={`city`}
            label={t("field.label.city")}
            placeholder={t("field.placeholder.city")}
            disabled={props.isDisabled}
          />
        </Col>

        <Col xs={12} sm={6}>
          <ControllerHF
            name={"zip"}
            control={control}
            component={InputField}
            id={"zip"}
            label={t("zip")}
            placeholder={t("zip")}
            normalize={NormalizeUtils.onlyNumbers}
            disabled={props.isDisabled}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={6}>
          <ControllerHF
            name={"billing_address_country_id"}
            control={control}
            component={ComboboxFormField}
            id={"billing_address_country_id"}
            label={t("country")}
            placeholder={t("country")}
            options={countriesData?.data}
            getOptionValue={option => option.id}
            normalize={option => (option ? option.id : null)}
            isDetermineValue
            selectProps={{
              isClearable: true,
            }}
            disabled={props.isDisabled || !props.isEditable}
          />
        </Col>

        <Col xs={12} sm={6}>
          <ControllerHF
            name={`tax_id`}
            control={control}
            component={InputField}
            id={`tax_id`}
            label={
              <>
                {t("vat_id")}
                {props.initialValues?.tax_id_status && (
                  <span className={"ms-2"}>
                    <VatIdStatusBadge
                      value={props.initialValues?.tax_id_status}
                    />
                  </span>
                )}
              </>
            }
            placeholder={t("vat_id")}
            disabled={props.isDisabled || !props.isEditable}
            hintText={t("billing_info.vat_id-field.hint")}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={6}>
          <ControllerHF
            name={`note`}
            control={control}
            component={InputField}
            id={`note`}
            label={t("note")}
            placeholder={t("note")}
            disabled={props.isDisabled}
            type={"textarea"}
          />
        </Col>
      </Row>

      <div className="mt-3 mb-3">
        <div className="button-items">
          {props.disabledMode && (
            <>
              {props.isFormActive ? (
                <Button
                  title={"cancel"}
                  color="secondary"
                  outline
                  onClick={() => {
                    props.toggleActiveForm()
                    onReset()
                  }}
                />
              ) : (
                <Button
                  title={"btn.edit"}
                  color="secondary"
                  outline
                  onClick={props.toggleActiveForm}
                />
              )}
            </>
          )}

          {props.isFormActive && (
            <Button
              submit
              title={"btn.save"}
              disabled={props.isDisabled}
              loading={props.loading}
            />
          )}
        </div>
      </div>
    </form>
  )
}

BillingInformationEditForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  disabledMode: PropTypes.bool,
  isFormActive: PropTypes.bool,
  toggleActiveForm: PropTypes.func,
  isEditable: PropTypes.bool,

  onSubmit: PropTypes.func,
}
