import React from "react"
import PropTypes from "prop-types"

import placeholder from "assets/images/dev/avatar-placeholder.png"
import { LetterAvatar } from "components"

export const Avatar = props => {
  const { item } = props

  return item.is_group ? (
    <LetterAvatar text={item.name} />
  ) : (
    <img
      src={item.participant.avatar ? item.participant.avatar : placeholder}
      className="rounded-circle avatar-xs img-cover"
      alt="avatar"
    />
  )
}

Avatar.propTypes = {
  item: PropTypes.object,
}
