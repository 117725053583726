import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "../../../../i18n"
import { API } from "../../../../api"
import { clientUpdateProfile } from "./slice"
import { clientProfileData } from "../data/slice"

function* updateProfile({ payload: { id, params, onSuccess, onError } }) {
  try {
    const response = yield call(API.client.updateClient, id, params)

    const { data } = response

    if (onSuccess) {
      onSuccess()
    }

    toastr.success(i18n.t("edit.success"))
    yield put(clientUpdateProfile.updateCompleted())
    yield put(clientProfileData.updateData({ ...data.data }))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      clientUpdateProfile.updateRejected({
        errors,
        message,
      })
    )
    if (onError) {
      onError(errors)
    }
  }
}

export function* watchUpdateProfile() {
  yield takeEvery(clientUpdateProfile.update, updateProfile)
}

function* updateSaga() {
  yield all([fork(watchUpdateProfile)])
}

export default updateSaga
