import React from "react"
import PropTypes from "prop-types"

export const RecipientOptionLabel = ({ option }) => {
  return (
    <div className={"recipient-option-label"}>
      <div className={"recipient-option-label__email"}>{option?.value}</div>

      <div className={"recipient-option-label__info"}>
        <div className={"recipient-option-label__owner-name"}>
          {option?.emailable?.owner?.name}
        </div>

        <div className={"recipient-option-label__contact-name"}>
          {option?.emailable?.name}
        </div>
      </div>
    </div>
  )
}

RecipientOptionLabel.propTypes = {
  option: PropTypes.any,
}
