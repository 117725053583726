import React from 'react';
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next';
import _ from "lodash"

import { PackageDataGrid } from '../../../../../PackageDataGrid';
import { Pagination } from '../../../../../../Pagination';
import { PackageDataAddButton } from "../../../../../PackageDataAddButton"
import { VideoOverviewCard } from "../../../../../../Videos"

import * as HOC from '../../../../../../../HOC';
const DataShower = HOC.withDataShowerOverlay();

export const VideosGrid = (props) => {
    const { t } = useTranslation();

    return (
        <DataShower isLoading={props.loading} isFailed={props.error} error={props.error}>
            {!!props.items.length ? (
                <>
                    <PackageDataGrid
                        className={'package-data-picker-grid_videos'}
                        items={props.items}
                        renderItem={(item) => (
                            <VideoOverviewCard
                                iframeUrl={item.iframe_url}
                                playerRef={el => props.playersRef.current[item.id] = el}
                                title={item.title}
                                description={item.description}
                                actions={(
                                    <PackageDataAddButton
                                        status={!!_.find(props.picked, { id: item.id })}
                                        onClick={() => props.onAdd(item)}
                                        disabled={false}
                                    />
                                )}
                            />
                        )}
                    />

                    <Pagination data={props.meta} onPageChange={props.onPageChange}  />
                </>
            ) : !props.loading ? (
                t('empty')
            ) : null}
        </DataShower>
    );
};

VideosGrid.propTypes = {
    items: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.any,
    page: PropTypes.any,
    meta: PropTypes.object,
    picked: PropTypes.array,
    playersRef: PropTypes.any,

    onPageChange: PropTypes.func,
    onAdd: PropTypes.func,
}