import {
    MODEL_PANEL_GENERAL_DOCUMENT_DETAILS_EDIT,
    MODEL_PANEL_GENERAL_DOCUMENT_DETAILS_EDIT_SUCCESS,
    MODEL_PANEL_GENERAL_DOCUMENT_DETAILS_EDIT_ERROR,
    MODEL_PANEL_GENERAL_DOCUMENT_DETAILS_EDIT_CLEAN_STATE,
} from './actionTypes';

export const modelPanelGeneralDocumentDetailsEdit = (id, params, onSuccess, onError) => {
    return {
        type: MODEL_PANEL_GENERAL_DOCUMENT_DETAILS_EDIT,
        payload: { id, params, onSuccess, onError },
    }
};

export const modelPanelGeneralDocumentDetailsEditSuccess = () => {
    return {
        type: MODEL_PANEL_GENERAL_DOCUMENT_DETAILS_EDIT_SUCCESS,
    }
};

export const modelPanelGeneralDocumentDetailsEditError = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_DOCUMENT_DETAILS_EDIT_ERROR,
        payload: data,
    }
};

export const modelPanelGeneralDocumentDetailsEditCleanState = () => {
    return { type: MODEL_PANEL_GENERAL_DOCUMENT_DETAILS_EDIT_CLEAN_STATE };
};