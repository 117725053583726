import * as eventConfig from "./eventConfig"
import * as calendar from "./calendar"
import * as eventForm from "./eventForm"
import * as eventInfo from "./eventInfo"
import * as contract from "./contract"
import * as event from "./event"
import * as generalCalendar from "./generalCalendar"

export const eventsCalendarHelper = {
  eventConfig,
  calendar,
  eventForm,
  eventInfo,
  contract,
  event,
  generalCalendar,
}
