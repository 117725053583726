import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Modal, Button } from "components"
import { Models } from "./Models"
import { useFetchData } from "hooks"
import { API } from "api"
import * as HOC from "HOC"

const DataShower = HOC.withDataShowerOverlay()

export const ModelsViewModal = props => {
  const { t } = useTranslation()

  const [modalVisible, setModalVisible] = useState(false)

  const { fetchData, loading, error, data, clean } = useFetchData()

  const onFetch = async () => {
    const params = {
      pagination: false,
      filters: {
        mother_agencies: [props.agencyId],
      },
    }

    const request = API.model.getModelList(params)
    await fetchData({
      request,
    })
  }

  useEffect(() => {
    if (modalVisible) {
      onFetch()
    }
    return () => {
      if (modalVisible) {
        setTimeout(() => {
          clean()
        }, 200)
      }
    }
  }, [modalVisible])

  return (
    <Modal
      ref={props.modalRef}
      title={t("models")}
      onVisibleChange={value => setModalVisible(value)}
      strapModalProp={{
        centered: true,
      }}
      size={null}
    >
      <DataShower isLoading={loading} isFailed={error} error={error?.message}>
        <Models data={data?.data} />
      </DataShower>

      <div className={"mt-3"}>
        <Button
          title={"close"}
          color="dark-gray"
          outline
          onClick={props.onCancel}
        />
      </div>
    </Modal>
  )
}

ModelsViewModal.propTypes = {
  modalRef: PropTypes.any,
  onCancel: PropTypes.func,
  agencyId: PropTypes.number,
}
