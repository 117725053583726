import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { agencyStatementDataPaymentSubmit } from "./slice"
import { agencyStatementList } from "../../../list/slice"

function* submit({ payload: { id, statement, onSuccess } }) {
  try {
    const is_paid = true

    const response = yield call(API.agency.paidAgencyStatement, id, statement)

    const { data } = response

    yield put(agencyStatementDataPaymentSubmit.submitSuccess())

    // Update statement in list
    yield put(agencyStatementList.updateStatement({ id: statement, is_paid }))

    if (onSuccess) {
      onSuccess()
    }

    toastr.success(
      i18n.t(is_paid ? "statement.paid.success" : "statement.refund.success")
    )
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      agencyStatementDataPaymentSubmit.submitError({
        errors,
        message,
      })
    )

    toastr.error(message)
  }
}

export function* watchSubmit() {
  yield takeEvery(agencyStatementDataPaymentSubmit.submit, submit)
}

function* submitSaga() {
  yield all([fork(watchSubmit)])
}

export default submitSaga
