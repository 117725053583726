import { createSelector, createSlice } from "@reduxjs/toolkit"
import { socialPostsCalendarHelper } from "helpers"

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
}

const socialCalendarPostListSlice = createSlice({
  name: "socialCalendarPostList",
  initialState,
  reducers: {
    getList(state) {
      state.listLoading = true
      state.listError = null
    },
    getListCompleted(state, action) {
      state.listLoading = false
      state.list = action.payload.data
    },
    getListRejected(state, action) {
      state.listLoading = false
      state.listError = action.payload
    },
    removeItemById(state, action) {
      state.list = state.list.filter(item => item.id !== action.payload)
    },
    addItem(state, action) {
      state.list.push(action.payload)
    },
    updateItemById(state, action) {
      state.list = state.list.map(item => {
        if (item.id === action.payload.id) return { ...item, ...action.payload }
        return item
      })
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getList
 * @property {function} getListCompleted
 * @property {function} getListRejected
 * @property {function}  removeItemById
 * @property {function}  updateItemById
 * @property {function}  addItem
 *  @property {function} cleanState
 */
export const socialCalendarPostList = socialCalendarPostListSlice.actions

export const selectSocialCalendarPostList = state =>
  state.socialCalendar.post.list.list

export const selectSocialCalendarPostLoading = state =>
  state.socialCalendar.post.list.listLoading

export const selectFullSocialCalendarPosts = createSelector(
  [selectSocialCalendarPostList],
  list => {
    return socialPostsCalendarHelper.calendarConfig.mapPostsToCalendarPosts(
      list
    )
  }
)

export default socialCalendarPostListSlice.reducer
