import React from "react"

import { usePanelLayoutIntroContext } from "contexts"

export const usePanelLayoutIntro = () => {
  const { config, setConfig, updateConfig, resetConfig } =
    usePanelLayoutIntroContext()

  const setOptions = (data = {}) => {
    updateConfig(data)
  }

  return {
    config,
    setConfig,
    setOptions,
    resetConfig,
  }
}
