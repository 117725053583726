import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import { DataShower } from "components"
import { packageDetailsUrlData } from "store/actions"

export const PackageSharedLinkDataLoader = ({ id, children }) => {
  const dispatch = useDispatch()

  const { data, loading, error } = useSelector(
    state => state.package.details.url.data
  )

  const fetchData = () => {
    dispatch(
      packageDetailsUrlData.getData({
        id,
      })
    )
  }

  useEffect(() => {
    fetchData()
    return () => {
      dispatch(packageDetailsUrlData.cleanState())
    }
  }, [])

  return (
    <DataShower
      isLoading={loading}
      isFailed={!!error}
      error={error}
      isFetched={!!data}
    >
      {children}
    </DataShower>
  )
}

PackageSharedLinkDataLoader.propTypes = {
  id: PropTypes.number,
  children: PropTypes.any,
}
