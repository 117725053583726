import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import {
  modelPanelGeneralFetchLogistics,
  modelPanelGeneralFetchLogisticsCleanState,
} from "../../../../../../store/actions"

import * as HOC from "../../../../../../HOC"
const DataShower = HOC.withDataShower()

const LogisticsDataLoaderComponent = props => {
  useEffect(() => {
    fetchData()

    return () => {
      props.modelPanelGeneralFetchLogisticsCleanState()
    }
  }, [])

  const fetchData = () => {
    props.modelPanelGeneralFetchLogistics(props.id)
  }

  return (
    <DataShower
      isLoading={props.loading}
      isFailed={props.error}
      error={props.error}
    >
      {props.data && props.children}
    </DataShower>
  )
}

LogisticsDataLoaderComponent.propTypes = {
  id: PropTypes.number,
  children: PropTypes.any,

  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.any,

  modelPanelGeneralFetchLogistics: PropTypes.func,
  modelPanelGeneralFetchLogisticsCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  //must be changed after updating backend
  const { data, loading, error } =
    state.modelPanel.general.logistics.details.data

  return {
    data,
    loading,
    error,
  }
}

const mapDispatchToProps = {
  modelPanelGeneralFetchLogistics,
  modelPanelGeneralFetchLogisticsCleanState,
}

export const LogisticsDataLoader = connect(
  mapStateToProps,
  mapDispatchToProps
)(LogisticsDataLoaderComponent)
