export const COMPARISON_BY_EARNINGS = "comparison_by_earnings"
export const EARNED_MONEY = "earned_money"
export const CASTINGS_AND_JOB = "castings_and_jobs"

export const TOP_TEN_MODELS_BY_RATING = "top_ten_models_by_rating"
export const TOP_TEN_CLIENTS_BY_RATING = "top_ten_clients_by_rating"
export const TOP_TEN_EMPLOYEES_BY_RATING = "top_ten_employees_by_rating"

export const TOP_TEN_MODELS_BY_EARNINGS = "top_ten_models_by_earnings"
export const TOP_TEN_CLIENTS_BY_EARNINGS = "top_ten_clients_by_earnings"
export const TOP_TEN_EMPLOYEES_BY_EARNINGS = "top_ten_employees_by_earnings"

export const MODELS_JOBS = "models_jobs"
export const MODELS_CASTINGS = "models_castings"
export const MODELS_EARNINGS = "models_earnings"
export const MODELS_TABLE = "models_table"

export const CLIENTS_JOBS = "clients_jobs"
export const CLIENTS_CASTINGS = "clients_castings"
export const CLIENTS_EARNINGS = "clients_earnings"
export const CLIENTS_PAYMENT = "clients_payment"
export const CLIENTS_TABLE = "clients_table"

export const EMPLOYEES_JOBS = "employees_jobs"
export const EMPLOYEES_CASTINGS = "employees_castings"
export const EMPLOYEES_EARNINGS = "employees_earnings"
export const EMPLOYEES_TABLE = "employees_table"
