import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
}

const calendarEventModelRateListSlice = createSlice({
  name: "calendarEventModelRateList",
  initialState,
  reducers: {
    getList(state) {
      state.listLoading = true
      state.listError = null
    },
    getListSuccess(state, action) {
      state.listLoading = false
      state.list = action.payload.data
    },
    getListError(state, action) {
      state.listLoading = false
      state.listError = action.payload
    },
    updateList(state, action) {
      state.list = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})

/**
 * @namespace
 * @property {function} getList
 * @property {function} getListSuccess
 * @property {function} getListError
 * @property {function} updateList
 * @property {function} cleanState
 */

export const calendarEventModelRateList =
  calendarEventModelRateListSlice.actions

export default calendarEventModelRateListSlice.reducer
