import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Label } from "reactstrap"

import { RadioButton } from "./RadioButton"

export const RadioButtonGroup = ({
  id,
  normalize,
  options,
  label,
  value,
  name,
  onChange,
  className,
  ...props
}) => {
  const handleChange = event => {
    const value = normalize ? normalize(event.target.value) : event.target.value

    if (onChange) {
      onChange(value)
    }
  }

  const renderLabel = label => {
    if (label) {
      if (typeof label === "string") {
        return label
      } else return label
    } else return <></>
  }

  return (
    <>
      {label && <Label>{renderLabel(label)}</Label>}

      <div
        className={classNames("form-control form-checkbox-group", {
          [className]: className,
        })}
      >
        {options.map((item, index) => {
          return (
            <RadioButton
              key={`${id}-${index}`}
              id={`${id}-${index}`}
              checked={value === item.value}
              value={item.value}
              label={item.label}
              className={item.className}
              index={index}
              onChange={handleChange}
              disabled={item.disabled}
            />
          )
        })}
      </div>
    </>
  )
}

RadioButtonGroup.propTypes = {
  id: PropTypes.any,
  label: PropTypes.any,
  normalize: PropTypes.func,
  options: PropTypes.array,
  fieldValue: PropTypes.any,
  name: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.any,
}
