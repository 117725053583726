import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { validationSchema } from "./validation"
import { Alert, Col, Row } from "reactstrap"
import {
  DateSection,
  TimeSection,
  TitleInfoSection,
  AttachFilesSection,
  ModelsSection,
} from "../EventFormSections"
import { Button } from "../../../Buttons"
import {
  getDatePeriodDefaultValues,
  getTimePeriodsDefaultValues,
  getTitleInfoDefaultValues,
  getAttachFilesDefaultValues,
  getModelsDefaultValues,
  mapDatePeriodSubmitValues,
  mapTimePeriodsSubmitValues,
  mapModelsSubmitValues,
  mapAttachFilesSubmitValues,
} from "../helpers"

export const EventOutForm = props => {
  const { t } = useTranslation()

  const [filesProcessing, setFilesProcessing] = useState([])

  const defaultValues = useMemo(() => {
    const {
      id,
      date_start,
      date_end,
      times,
      agency,
      client,
      defaultLocations,
      ...restValues
    } = props.initialValues
    return {
      ...restValues,
      id: id,
      ...getDatePeriodDefaultValues(date_start, date_end),
      ...getTimePeriodsDefaultValues(times),
    }
  }, [props.initialValues])

  const methods = useForm({
    defaultValues: {
      ...getTitleInfoDefaultValues(),
      ...getModelsDefaultValues(),
      ...getAttachFilesDefaultValues(),
      ...defaultValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const { handleSubmit, setError } = methods

  const onSubmit = values => {
    const params = {
      ...values,
      ...mapDatePeriodSubmitValues(values.date_start, values.date_end),
      ...mapTimePeriodsSubmitValues(values.times),
      ...mapModelsSubmitValues(values.fashion_models),
      ...mapAttachFilesSubmitValues(values.files),
    }

    props.onSubmit(params, { setError })
  }

  const addFileToProcessing = fieldId => {
    setFilesProcessing(prevState => [...prevState, fieldId])
  }

  const removeFileFromProcessing = fieldId => {
    setFilesProcessing(prevState => prevState.filter(item => item !== fieldId))
  }

  const isDisabled = useMemo(() => {
    return props.loading
  }, [props.loading])

  return (
    <FormProvider {...methods}>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <DateSection />

        <TimeSection />

        <hr className="drawer-separator drawer-separator_form" />

        <TitleInfoSection />

        <hr className="drawer-separator drawer-separator_form" />

        <ModelsSection
          isEdit={props.isEditMode}
          eventId={props.initialValues.id}
          eventType={props.eventType}
          modelActionBar={{
            cancel: props.isEditMode,
          }}
          onEventUpdate={props.onEventUpdate}
          entity={props.entity}
          entityId={props.entityId}
        />

        <hr className="drawer-separator drawer-separator_form" />

        <AttachFilesSection
          processing={filesProcessing}
          addFileToProcessing={addFileToProcessing}
          removeFileFromProcessing={removeFileFromProcessing}
          accept={
            "image/jpeg, image/png, image/gif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          }
          eventId={props.initialValues.id}
        />

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={isDisabled}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

EventOutForm.propTypes = {
  isEditMode: PropTypes.bool,
  eventType: PropTypes.string,
  initialValues: PropTypes.object,
  error: PropTypes.any,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onEventUpdate: PropTypes.func,
  entity: PropTypes.string,
  entityId: PropTypes.number,
}
