import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { useDispatch } from "react-redux"
import { websiteModelsList } from "store/actions"
import { Button } from "components"
import { ModelsFilters } from "../ModelsFilters"

export const Header = props => {
  const dispatch = useDispatch()

  const handleSearchChange = value => {
    dispatch(websiteModelsList.changeFilter({ keywords: value }))
  }

  const handleInTownChange = value => {
    dispatch(websiteModelsList.changeFilter({ is_in_town: value }))
  }
  return (
    <>
      <Row>
        <Col md={8} className={"mb-2"}>
          <Row>
            <ModelsFilters
              alias={"models"}
              filters={props.filters}
              onInTownChange={handleInTownChange}
              onSearch={handleSearchChange}
            />
          </Row>
        </Col>
        <Col className={"d-flex justify-content-end mb-2"}>
          <div className={"align-self-end"}>
            <Button
              type={"button"}
              title={"btn.publish-model"}
              onClick={props.onBookAdd}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

Header.propTypes = {
  filters: PropTypes.any,
  onBookAdd: PropTypes.func,
}
