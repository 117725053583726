import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "../../../i18n"
import { PROFILE_REMOVE_USER } from "./actionTypes"
import { profileRemoveUserSuccess, profileRemoveUserError } from "./actions"
import { logoutUser } from "../../auth/logout/actions"

import { API } from "../../../api"

function* removeUser({ payload: { onSubmitProps } }) {
  const { setErrors, setSubmitting } = onSubmitProps

  try {
    const response = yield call(API.profile.removeUser)

    const { data } = response

    yield put(profileRemoveUserSuccess())

    setSubmitting(false)
    toastr.success(i18n.t("profile.remove.success"))

    yield put(logoutUser({}))
  } catch (error) {
    const errors = error.response?.data?.errors
      ? error.response.data.errors
      : {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      profileRemoveUserError({
        errors,
        message,
      })
    )

    setSubmitting(false)
    setErrors(errors)
  }
}

export function* watchRemoveUser() {
  yield takeEvery(PROFILE_REMOVE_USER, removeUser)
}

function* removeSaga() {
  yield all([fork(watchRemoveUser)])
}

export default removeSaga
