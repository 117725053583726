import React, { useEffect } from "react"
import PropTypes from "prop-types"

import { StripeFormElement } from "components"

export const CardForm = props => {
  useEffect(() => {
    return () => {
      props.onDestroy()
    }
  }, [])

  return (
    <>
      <StripeFormElement {...props} state={props.stripeState} />
    </>
  )
}

CardForm.propTypes = {
  onDestroy: PropTypes.func,
  stripeState: PropTypes.object,
}
