import React from "react"

import { INITIAL_STATE } from "../../../consts"
import {
  SET_STATE,
  RESET_STATE,
  CLEAN_STATE,
  SET_INVOICE,
  RESET_INVOICE,
} from "./actionTypes"

export function reducerInit(initialState) {
  return {
    ...INITIAL_STATE,
    ...initialState,
  }
}

export function reducer(state, action) {
  switch (action.type) {
    // state
    case SET_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case RESET_STATE:
      return reducerInit(action.payload)

    case CLEAN_STATE:
      return INITIAL_STATE

    // invoice
    case SET_INVOICE:
      return {
        ...state,
        invoice: action.payload,
      }

    case RESET_INVOICE:
      return {
        ...state,
        invoice: reducerInit(action.payload).invoice,
      }

    default:
      return state
  }
}
