import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

export const Alerts = ({ data }) => {
  const { t } = useTranslation()

  return (
    <div className="event-preview-models">
      {!!data.length ? (
        <div className="table-responsive">
          <table className="table table-nowrap">
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>
                    <span className={"fw-medium"}>
                      {item.sort === 1
                        ? t("alert_first")
                        : item.sort === 2
                        ? t("alert_second")
                        : null}
                    </span>
                  </td>

                  <td>
                    {item?.date_start
                      ? moment
                          .utc(item.date_start)
                          .local()
                          .format("DD/MM/YYYY HH:mm")
                      : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        t("no_alerts")
      )}
    </div>
  )
}

Alerts.propTypes = {
  data: PropTypes.array,
}
