import React from "react"
import { Row, Col } from "reactstrap"
import { MegaTagTitle } from "../../../../../components"

import { CreateInfoContainer } from "../../components"

export const CreateInfoPage = () => {
  return (
    <div className={"create-info"}>
      <MegaTagTitle title={"title.website.create-info"} />
      <Row>
        <Col>
          <CreateInfoContainer />
        </Col>
      </Row>
    </div>
  )
}
