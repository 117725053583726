import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { TabContent, TabPane } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import i18n from "../../../../i18n"
import {
  TabsNav,
  PackageDataGrid,
  BookOverviewCard,
  VideoOverviewCard,
  BookPhotosCarouselShower,
} from "../../../../components"

import * as HOC from "../../../../HOC"
const DataShower = HOC.withDataShowerOverlay()

const BOOKS_TAB = "books"
const VIDEOS_TAB = "videos"
const TABS = [
  { label: i18n.t("books"), value: BOOKS_TAB },
  { label: i18n.t("videos"), value: VIDEOS_TAB },
]

const PackageDetailsContainerComponent = props => {
  const { t } = useTranslation()

  const [activeTab, setActiveTab] = useState(BOOKS_TAB)
  const playersRef = useRef([])

  const [bookId, setBookId] = useState(null)

  const showPhotos = (e, item) => {
    e.preventDefault()
    if (item?.pages_count) {
      setBookId(item.id)
    }
  }

  return (
    <div>
      <DataShower
        isLoading={props.bookDataLoading}
        isFailed={null}
        error={null}
      >
        <TabsNav
          options={TABS}
          active={activeTab}
          onChange={tab => setActiveTab(tab)}
        />

        <TabContent activeTab={activeTab} className="mt-3">
          <TabPane tabId={BOOKS_TAB}>
            <PackageDataGrid
              items={props.package.books}
              renderItem={item => (
                <BookOverviewCard
                  title={item.title}
                  description={item.description}
                  cover={item?.cover?.photo?.attachment?.src}
                  crops={item?.cover?.photo?.crops}
                  size={"sm"}
                  headerPanel={
                    <Link
                      to="#"
                      className="text-primary-green"
                      onClick={e => showPhotos(e, item)}
                    >
                      {t("photos")} {item?.pages_count}
                    </Link>
                  }
                />
              )}
            />
          </TabPane>

          <TabPane tabId={VIDEOS_TAB}>
            <PackageDataGrid
              className={"package-data-grid_videos"}
              items={props.package.videos}
              renderItem={item => (
                <VideoOverviewCard
                  iframeUrl={item.iframe_url}
                  playerRef={el => (playersRef.current[item.id] = el)}
                  title={item.title}
                  description={item.description}
                />
              )}
            />
          </TabPane>
        </TabContent>

        <BookPhotosCarouselShower
          id={bookId}
          onDestroy={() => setBookId(null)}
        />
      </DataShower>
    </div>
  )
}

PackageDetailsContainerComponent.propTypes = {
  package: PropTypes.object,

  bookDataLoading: PropTypes.bool,
}

const mapStateToProps = state => {
  const { data } = state.package.details.data
  return {
    package: data,
    bookDataLoading: state.modelPanel.book.common.book.loading,
  }
}

const mapDispatchToProps = {}

export const PackageDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackageDetailsContainerComponent)
