import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const billingPaySlice = createSlice({
  name: "billingPay",
  initialState,
  reducers: {
    stripeSetupAndPay(state) {
      state.loading = true
      state.error = null
    },
    stripePay(state) {
      state.loading = true
      state.error = null
    },
    paySuccess(state) {
      state.loading = false
    },
    payError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})

/**
 * @namespace
 * @property {function} stripeSetupAndPay
 * @property {function} stripePay
 * @property {function} paySuccess
 * @property {function} payError
 * @property {function} cleanState
 */

export const billingPay = billingPaySlice.actions

export default billingPaySlice.reducer
