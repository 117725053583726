import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Button, ConfirmPopup, TextButton } from "components"
import { useDispatch, useSelector } from "react-redux"
import {
  modelsPanelListChangeFilter,
  modelsPanelState,
  modelsUpdateGroup,
  selectModelGroupById,
} from "store"

import { useConfirmModal } from "hooks"
import { useTranslation } from "react-i18next"

export const AddToGroupControls = ({ selectedModels, groupId }) => {
  const dispatch = useDispatch()
  const group = useSelector(state => selectModelGroupById(state, groupId))
  const { t } = useTranslation()

  const handleAddSuccess = () => {
    dispatch(modelsPanelState.setEditGroupState())
    dispatch(modelsPanelListChangeFilter({ not_in_group: null }))
  }

  const { modalRef, showModal, closeModal, handleConfirm } = useConfirmModal(
    () => {
      dispatch(
        modelsUpdateGroup.update({
          id: groupId,
          params: { ...group, model_ids: selectedModels },
          onSuccess: handleAddSuccess,
        })
      )
    },
    () => dispatch(modelsUpdateGroup.cleanState())
  )

  const handleAddToGroupSave = () => {
    showModal()
  }
  const handleAddToGroupCancel = () => {
    dispatch(modelsPanelListChangeFilter({ not_in_group: null }))
    dispatch(modelsPanelState.setEditGroupState())
  }

  const renderSaveTitle = () => {
    return `${t("save")} (${selectedModels.length})`
  }

  useEffect(() => {
    return () => {
      dispatch(modelsPanelListChangeFilter({ not_in_group: null }))
    }
  }, [])

  return (
    <>
      <Button
        color={"secondary"}
        title={"save"}
        btnClass={"mr-2"}
        renderTitle={renderSaveTitle()}
        onClick={handleAddToGroupSave}
      />
      <TextButton
        btnClass={"mr-2"}
        title={"cancel"}
        onClick={handleAddToGroupCancel}
      />
      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
      >
        <p className={"font-size-22"}>
          {t("add-to-group.modal.message", {
            count: selectedModels.length,
            groupName: group.name,
          })}
        </p>
      </ConfirmPopup>
    </>
  )
}

AddToGroupControls.propTypes = {
  selectedModels: PropTypes.array,
  groupId: PropTypes.number,
}
