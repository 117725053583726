/* eslint react/prop-types: off */
import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { useRowSelect } from "react-table"

import {
  ReactTableView,
  TableIndeterminateCheckbox,
  StatementPaidStatusBadge,
  StatementPaymentStatusBadge,
} from "components"
import { PaymentSubmit } from "./PaymentSubmit"
import { PaymentDetails } from "./PaymentDetails"
import { Amount } from "./Amount"
import { Description } from "./Description"
import { STATEMENT_TYPE } from "consts"
import { StatementsPayment } from "./StatementsPayment"
import { useStatementsPayment } from "./useStatementsPayment"

import * as HOC from "HOC"

const TableDataLoading = HOC.withTableDataLoading()

export const StatementTable = ({
  statementsPayment,
  statementsSelectedProps,
  getTableInstanceCallback,
  ...props
}) => {
  const { t } = useTranslation()

  const data = useMemo(() => {
    return props.list
  }, [props.list])

  const columns = useMemo(
    () => [
      {
        Header: t("date"),
        accessor: "date",
        id: "date",
        Cell: ({ value }) => {
          return value ? moment(value).format("DD/MM/YYYY") : "-"
        },
      },
      {
        Header: t("client"),
        accessor: "client_name",
        id: "client_name",
        Cell: ({ value }) => {
          return value || null
        },
      },
      {
        Header: t("client_payment_status"),
        accessor: "client_payment_status",
        id: "client_payment_status",
        Cell: ({ value }) =>
          value ? (
            <div className="d-flex align-items-center justify-content-center">
              <StatementPaymentStatusBadge value={value} />
            </div>
          ) : (
            ""
          ),
      },
      {
        Header: t("description"),
        accessor: "description",
        id: "description",
        Cell: ({ row: { original } }) => <Description data={original} />,
      },
      {
        Header: t("invoice"),
        accessor: "invoice_number",
        id: "invoice_number",
        Cell: ({ value }) => {
          return value || null
        },
      },
      {
        Header: t("gross_amount"),
        accessor: "gross_amount",
        id: "gross_amount",
        Cell: ({ row: { original } }) => <Amount data={original} />,
      },
      {
        Header: () => (
          <span className={"text-uppercase"}>{`+ ${t("net")}`}</span>
        ),
        accessor: "net_plus",
        id: "net_plus",
        Cell: ({ value }) => value,
      },
      {
        Header: () => (
          <span className={"text-uppercase"}>{`- ${t("net")}`}</span>
        ),
        accessor: "net_minus",
        id: "net_minus",
        Cell: ({ value }) => value,
      },
      {
        Header: () => <div className={"w-100 text-center"}>{t("status")}</div>,
        accessor: "paid",
        id: "paid",
        Cell: ({ row: { original } }) =>
          original.type === STATEMENT_TYPE.STATEMENT ? (
            <div className="d-flex align-items-center justify-content-center">
              <StatementPaidStatusBadge value={original.is_paid} />
            </div>
          ) : null,
      },
      {
        Header: row => {
          return (
            <div className={"d-print-none"}>
              <StatementsPayment
                unpaidSelectedStatementsIds={
                  row._statementsPayment.unpaidSelectedStatementsIds
                }
                {...row.meta.statementsPayment}
              />
            </div>
          )
        },
        id: "action",
        Cell: ({ row: { original } }) => (
          <div className="d-flex align-items-center justify-content-center d-print-none">
            {original.type === STATEMENT_TYPE.STATEMENT ? (
              <PaymentSubmit
                isDebt={original.net_minus !== null}
                is_paid={original.is_paid}
                onClick={() => props.onPayment(original)}
              />
            ) : (
              <PaymentDetails
                onClick={() => props.onPaymentDetails(original)}
              />
            )}
          </div>
        ),
      },
    ],
    []
  )

  const tablePlugins = useMemo(
    () => [
      useRowSelect,
      useStatementsPayment,
      hooks => {
        hooks.visibleColumns.push(columns => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <TableIndeterminateCheckbox
                {...getToggleAllRowsSelectedProps()}
              />
            ),
            Cell: ({ row }) => (
              <TableIndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
              />
            ),
          },
          ...columns,
        ])
      },
    ],
    []
  )

  return (
    <TableDataLoading
      isLoading={props.loading}
      isFailed={props.error}
      error={props.error}
    >
      <ReactTableView
        isLoading={props.loading}
        columns={columns}
        data={data}
        meta={{
          statementsPayment,
        }}
        plugins={tablePlugins}
      />
    </TableDataLoading>
  )
}

StatementTable.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  onPayment: PropTypes.func,
  onPaymentDetails: PropTypes.func,
  statementsPayment: PropTypes.shape({
    btnProps: PropTypes.shape({
      loading: PropTypes.bool,
      disabled: PropTypes.bool,
    }),
    onStatementsPayment: PropTypes.func,
  }),
}
