import React from "react"
import PropTypes from "prop-types"
import { FormProvider, useForm } from "react-hook-form"
import { Col, Row } from "reactstrap"

import { useFormValuesChangedDebounce } from "hooks"
import { ModelSelect } from "./ModelsSelect"
import { ControllerHF } from "components"

export const FilterForm = props => {
  const methods = useForm({
    defaultValues: {
      ...props.defaultValues,
    },
  })
  const { watch, control } = methods

  useFormValuesChangedDebounce(watch, props.onFiltersChange)

  return (
    <FormProvider {...methods}>
      <Row>
        <Col md={6}>
          <ControllerHF
            name={"fashion_models"}
            control={control}
            component={ModelSelect}
            options={props.modelsOptions}
            fetching={props.modelsFetching}
          />
        </Col>
      </Row>
    </FormProvider>
  )
}

FilterForm.propTypes = {
  onFiltersChange: PropTypes.func,
  defaultValues: PropTypes.object,
  modelsOptions: PropTypes.array,
  modelsFetching: PropTypes.bool,
}
