import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import toastr from "toastr"

import { bookCommonFetchBook, bookCommonFetchBookCleanState } from '../../../store/actions';
import { ImageLightbox } from '../../ImageLightbox';

const BookPhotosCarouselShowerComponent = (props) => {
    const { t } = useTranslation();

    const [lightboxOpen, setLightboxOpen] = useState(false);

    const onFetchError = () => {
        toastr.error(t('error'));
        props.onDestroy();
    }

    const fetchBook = () => {
        props.bookCommonFetchBook(props.id, () => setLightboxOpen(true), onFetchError);
    }

    useEffect(() => {
        if (props.id) {
            fetchBook();
        }

        return () => {
            if (props.id) {
                setLightboxOpen(false);
                props.bookCommonFetchBookCleanState();
            }
        };
    }, [props.id]);

    const closeLightBox = () => {
        setLightboxOpen(false);
        props.onDestroy();
    }

    const images = useMemo(() => {
        if (props.book) {
            return props.book.pages.map(item => item?.photo?.attachment?.src);
        }
        return [];
    }, [props.book]);

    return (
        <>
            <ImageLightbox
                items={images}
                isOpen={lightboxOpen}
                initialIndex={0}
                onClose={closeLightBox}
            />
        </>
    );
};

BookPhotosCarouselShowerComponent.propTypes = {
    id: PropTypes.number,
    onDestroy: PropTypes.func,

    book: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.any,

    bookCommonFetchBook: PropTypes.func,
    bookCommonFetchBookCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { data, loading, error } = state.modelPanel.book.common.book;
    return {
        book: data,
        loading,
        error,
    };
};

const mapDispatchToProps = {
    bookCommonFetchBook,
    bookCommonFetchBookCleanState,
};

export const BookPhotosCarouselShower = connect(mapStateToProps, mapDispatchToProps)(BookPhotosCarouselShowerComponent);