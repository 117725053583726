import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import { departmentPermissionsData } from "store/actions"
import * as HOC from "HOC"

const DataShower = HOC.withDataShower()

export const PermissionsDataLoader = props => {
  const dispatch = useDispatch()

  const { data, loading, error } = useSelector(
    state => state.department.permissions.data
  )

  const fetchData = () => {
    dispatch(departmentPermissionsData.getData({}))
  }

  useEffect(() => {
    fetchData()

    return () => {
      dispatch(departmentPermissionsData.cleanState({}))
    }
  }, [])

  return (
    <DataShower isLoading={loading} isFailed={error} error={error}>
      {data && props.children}
    </DataShower>
  )
}

PermissionsDataLoader.propTypes = {
  children: PropTypes.any,
}
