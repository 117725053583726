import React from 'react';
import PropTypes from 'prop-types'
import { Row, Col } from "reactstrap";

export const PageTitle = (props) => {
    return (
        <Row>
            <Col xs="12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18">{props.title}</h4>
                </div>
            </Col>
        </Row>
    )
}

PageTitle.propTypes = {
    title: PropTypes.string
}