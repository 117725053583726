import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { IconButton, TooltipWrapper } from "components"
import { EVENT_TYPE_OPTION, EVENT_TYPE_JOB } from "consts"

export const JobControl = ({ loading, ...props }) => {
  const { t } = useTranslation()

  const domElementId = `event-model-action-job-${props.modelId}`

  const isDisabled = useMemo(() => {
    return props.type !== EVENT_TYPE_OPTION || loading
  }, [props.type, loading])

  const onChange = () => {
    if (!isDisabled) {
      const payload = { type: EVENT_TYPE_JOB }

      if (props.eventId) {
        props.onSubmit("type", payload)
      } else {
        props.onChange(payload)
      }
    }
  }

  return (
    <TooltipWrapper target={domElementId} content={t("event.convert-to-job")}>
      <IconButton
        id={domElementId}
        onClick={onChange}
        disabled={isDisabled}
        wrapClass={"text-secondary font-size-20"}
        icon={"bx bx-transfer"}
      />
    </TooltipWrapper>
  )
}

JobControl.propTypes = {
  eventId: PropTypes.number,
  modelId: PropTypes.number,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
}
