import { all } from 'redux-saga/effects';

import registerSaga from './register/saga';
import loginSaga from './login/saga';
import passwordForgetSaga from './passwordForget/saga';
import passwordResetSaga from './passwordReset/saga';
import logoutSaga from './logout/saga';

function* authSaga() {
    yield all([
        registerSaga(),
        loginSaga(),
        passwordForgetSaga(),
        passwordResetSaga(),
        logoutSaga(),
    ])
}

export default authSaga;