import { combineReducers } from "redux"

import profile from "./profile/reducers"
import panel from "./panel/reducers"
import history from "./history/reducers"
import rating from "./rating/reducers"
import logo from "./logo/reducers"
import addresses from "./addresses/reducers"
import contacts from "./contacts/reducers"
import attachments from "./attachments/reducers"
import statement from "./statement/reducers"

const agencyReducer = combineReducers({
  profile,
  panel,
  history,
  rating,
  logo,
  addresses,
  contacts,
  attachments,
  statement,
})

export default agencyReducer
