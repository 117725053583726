import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

import { ValidationsUtils } from "utils"
import { MODEL_LOGISTICS_TYPE } from "consts"

const accommodationFormValidation = {
  date_in: Yup.date().nullable().required("field.error.required"),
  date_out: Yup.date()
    .nullable()
    .test({
      name: "dateEqualOrGrater",
      exclusive: false,
      params: {},
      message: "field.error.date.after-or-equal",
      test: function (value) {
        return ValidationsUtils.dateEqualOrAfter(value, this.parent.date_in)
      },
    }),
}

const travelFormValidation = {
  travel_by: Yup.string().nullable().required("field.error.required"),
  departure_place: Yup.string().required("field.error.required"),
  departure_date: Yup.date().nullable().required("field.error.required"),
  arrival_place: Yup.string().required("field.error.required"),
  arrival_date: Yup.date().nullable().required("field.error.required"),
}

const driverFormValidation = {
  workdays_from: Yup.date().nullable().required("field.error.required"),
  workdays_to: Yup.date()
    .nullable()
    .required("field.error.required")
    .test({
      name: "dateEqualOrGrater",
      exclusive: false,
      params: {},
      message: "field.error.date.after-or-equal",
      test: function (value) {
        return ValidationsUtils.dateEqualOrAfter(
          value,
          this.parent.workdays_from
        )
      },
    }),
}

const basicFormValidation = {
  workdays_to: Yup.date()
    .nullable()
    .test({
      name: "dateEqualOrGrater",
      exclusive: false,
      params: {},
      message: "field.error.date.after-or-equal",
      test: function (value) {
        return ValidationsUtils.dateEqualOrAfter(
          value,
          this.parent.workdays_from
        )
      },
    }),
}

export const getLogisticsFormValidationSchema = logisticType => {
  switch (logisticType) {
    case MODEL_LOGISTICS_TYPE.ACCOMMODATION:
      return accommodationFormValidation

    case MODEL_LOGISTICS_TYPE.TRAVEL:
      return travelFormValidation

    case MODEL_LOGISTICS_TYPE.DRIVER:
      return driverFormValidation

    default:
      return basicFormValidation
  }
}

export const logisticsFormValidation = logisticType => {
  return yupResolver(
    Yup.object().shape(getLogisticsFormValidationSchema(logisticType))
  )
}
