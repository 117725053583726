import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';

import { PackageForm } from '../../../../../Forms';
import { ValidationsUtils } from "../../../../../../utils"
import { PACKAGE_FORM_SUBMIT_TYPE_SEND } from "../../../../../../consts"
import { packageAdd, packageAddCleanState, draftPackageFormSetValues } from '../../../../../../store/actions';

const AddPackageFormComponent = (props) => {
    useEffect(() => {
        return () => {
            props.packageAddCleanState();
        }
    }, [])

    const onSubmitSuccess = (onSubmitProps) => (data) => {
        const { onReset, submitType } = onSubmitProps;
        onReset();

        props.onSaveSuccess();

        if (submitType && submitType === PACKAGE_FORM_SUBMIT_TYPE_SEND) {
            props.onSend(data);
        }
    }

    const onSubmitError = (onSubmitProps) => (errors) => {
        const { setError } = onSubmitProps;
        ValidationsUtils.setServerSideErrors(errors, setError);
    }

    const handleSubmit = (params, onSubmitProps) => {
        props.packageAdd(params, onSubmitSuccess(onSubmitProps), onSubmitError(onSubmitProps));
    };

    const onFormDestroy = (values) => {
        props.draftPackageFormSetValues({ ...values })
    }

    return (
        <PackageForm
            initialValues={{
                ...props.formValues,
            }}
            loading={props.loading}
            error={props.error}
            onSubmit={handleSubmit}
            dataPicker={props.dataPicker}
            onDestroy={onFormDestroy}
        />
    );
};

AddPackageFormComponent.propTypes = {
    onSaveSuccess: PropTypes.func,
    onSend: PropTypes.func,

    loading: PropTypes.bool,
    error: PropTypes.object,
    dataPicker: PropTypes.object,
    formValues: PropTypes.object,

    packageAdd: PropTypes.func,
    packageAddCleanState: PropTypes.func,
    draftPackageFormSetValues: PropTypes.func,
}

const mapStateToProps = state => {
    const { loading, error } = state.package.add;
    return {
        loading, error,
        dataPicker: state.package.draft.data.dataPicker,
        formValues: state.package.draft.data.form.values,
    };
};

const mapDispatchToProps = {
    packageAdd,
    packageAddCleanState,
    draftPackageFormSetValues,
};

export const AddPackageForm = connect(mapStateToProps, mapDispatchToProps)(AddPackageFormComponent);