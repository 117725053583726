import React from "react"
import PropTypes from "prop-types"

import { Header } from "./Header"
import { Content } from "./Content"

export const Sidebar = props => {
  return (
    <div className="chat-leftsidebar me-lg-4">
      <div>
        <Header />

        <Content />
      </div>
    </div>
  )
}

Sidebar.propTypes = {}
