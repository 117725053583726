import React from "react"
import PropTypes from "prop-types"
import { v4 as uuidv4 } from "uuid"
import { ExpensesContainer } from "./ExpensesContainer"

export const ExpensesControl = ({
  disabled,
  value,
  async = false,
  className,
  ...props
}) => {
  // Add
  const onAdd = (data, payload) => {
    const { onSuccess, onError } = payload
    if (async) {
      const params = {
        // callback: (data) => add(data),
        onSuccess: data => onAddSuccess(data, onSuccess),
        onError: errors => onError(errors),
      }
      props.onAdd && props.onAdd(data, params)
    } else {
      data = {
        ...data,
        id: uuidv4(),
      }
      onAddSuccess(data, onSuccess)
    }
  }

  const onAddSuccess = (data, onSuccess) => {
    add(data)
    onSuccess()
  }

  const add = data => {
    props.onChange([...value, { ...data }])
  }
  // --- //

  // Edit
  const onEdit = (data, payload) => {
    const { onSuccess, onError } = payload

    if (async) {
      const params = {
        // callback: (data) => edit(data),
        onSuccess: data => onEditSuccess(data, onSuccess),
        onError: errors => onError(errors),
      }
      props.onEdit && props.onEdit(data, params)
    } else {
      onEditSuccess(data, onSuccess)
    }
  }

  const onEditSuccess = (data, onSuccess) => {
    edit(data)
    onSuccess()
  }

  const edit = data => {
    let items = value.map(item => {
      if (item.id !== data.id) {
        return item
      }
      return {
        ...item,
        ...data,
      }
    })

    props.onChange([...items])
  }
  // --- //

  const onRemove = data => {
    if (async) {
      const params = {
        // callback: (data) => remove(data),
        onSuccess: data => remove(data),
      }
      props.onRemove && props.onRemove(data, params)
    } else {
      remove(data)
    }
  }

  const remove = data => {
    let items = value.filter(item => item.id !== data.id)
    props.onChange([...items])
  }

  return (
    <>
      <ExpensesContainer
        value={value}
        onAdd={onAdd}
        onEdit={onEdit}
        onRemove={onRemove}
        loading={props.loading}
        error={props.error}
        disabled={disabled}
        currency={props.currency}
        generalLoading={props.generalLoading}
        onFormDestroy={props.onFormDestroy}
        expenseTypeOptions={props.expenseTypeOptions}
        addBtnText={props.addBtnText}
      />
    </>
  )
}

ExpensesControl.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  async: PropTypes.bool,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.any,
  className: PropTypes.string,
  currency: PropTypes.string,
  generalLoading: PropTypes.bool,
  onFormDestroy: PropTypes.func,
  expenseTypeOptions: PropTypes.array,
  addBtnText: PropTypes.string,
}
