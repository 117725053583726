import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../i18n';
import { EMPLOYEE_CREATE } from './actionTypes';
import { employeeCreateSuccess, employeeCreateError } from './actions';

import { API } from '../../../api';

function* createEmployee({ payload: { params, onSuccess, onError } }) {
    try {
        const response = yield call(API.employee.createEmployee, params);

        const { data } = response;

        yield put(employeeCreateSuccess());

        toastr.success(i18n.t('employee.creat.success'));


        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        const errors = error.response?.data?.errors || {};
        const message = error.response?.data?.message || i18n.t('error');

        yield put(employeeCreateError({
            errors,
            message
        }));

        if (onError) {
            onError(errors);
        }
    }
}

export function* watchCreateEmployee() {
    yield takeEvery(EMPLOYEE_CREATE, createEmployee);
}

function* createSaga() {
    yield all([
        fork(watchCreateEmployee),
    ]);
}

export default createSaga;