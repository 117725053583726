import React from "react"
import { Row, Col } from "reactstrap"

import { MegaTagTitle } from "components"
import { EventsContainer } from "./components"

export const Events = () => {
  return (
    <div style={{ paddingBottom: "60px" }}>
      <MegaTagTitle title={"title.administration"} />

      <Row>
        <Col>
          <EventsContainer />
        </Col>
      </Row>
    </div>
  )
}
