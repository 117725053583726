import {
    PROFILE_UPLOAD_COMPANY_LOGO,
    PROFILE_UPDATE_COMPANY_LOGO_SUCCESS,
    PROFILE_UPDATE_COMPANY_LOGO_ERROR,
    PROFILE_REMOVE_COMPANY_LOGO,
    PROFILE_COMPANY_LOGO_CLEAN_STATE,
} from './actionTypes';

export const profileUploadCompanyLogo = (params) => {
    return {
        type: PROFILE_UPLOAD_COMPANY_LOGO,
        payload: { params },
    }
};

export const profileUpdateCompanyLogoSuccess = () => {
    return {
        type: PROFILE_UPDATE_COMPANY_LOGO_SUCCESS,
    }
};

export const profileUpdateCompanyLogoError = (data) => {
    return {
        type: PROFILE_UPDATE_COMPANY_LOGO_ERROR,
        payload: data,
    }
};

export const profileCompanyLogoCleanState = () => {
    return { type: PROFILE_COMPANY_LOGO_CLEAN_STATE };
};

export const profileRemoveCompanyLogo = () => {
    return {
        type: PROFILE_REMOVE_COMPANY_LOGO,
    }
};