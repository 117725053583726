import React from 'react';
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next';
import classNames from "classnames"

import { ReadMoreExpand } from '../../ReadMoreExpand'
import { IframeVideoPlayer } from '../../IframeVideoPlayers'

export const VideoOverviewCard = (props) => {
    const { t } = useTranslation();

    return (
        <div className={classNames('video-overview-card', {
            [props.className]: props.className,
        })}>
            <div className={'video-overview-card__video-player-block'}>
                <IframeVideoPlayer playerRef={props.playerRef} url={props.iframeUrl} />

                {props.actions && (
                    <div className={'video-overview-card__actions-block'}>
                        {props.actions}
                    </div>
                )}
            </div>

            {props.title && (
                <div className={'video-overview-card__title-block'}>
                    <div className={'video-overview-card__title'}>
                        {props.title}
                    </div>
                </div>
            )}

            {props.description && (
                <div className={'video-overview-card__body'}>
                    <ReadMoreExpand
                        className={'video-overview-card__description'}
                        maxHeight={64}
                        lineHeight={16}
                    >
                        {props.description}
                    </ReadMoreExpand>
                </div>
            )}
        </div>
    );
};

VideoOverviewCard.propTypes = {
    iframeUrl: PropTypes.string,
    playerRef: PropTypes.any,
    title: PropTypes.string,
    description: PropTypes.string,
    className: PropTypes.string,
    actions: PropTypes.any,
}