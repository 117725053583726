import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { Rates, AsyncRates } from "./Rates"

const RatesContainerComponent = props => {
  const onRatesChange = rates => {
    props.onModelUpdate(props.model, { rates: rates })
  }

  return (
    <>
      {!props.eventId ? (
        <Rates
          modelId={props.model?.id}
          rates={props?.model?.rates}
          onChange={onRatesChange}
          currency={props.currency}
          datePeriod={props.eventDate}
        />
      ) : (
        <AsyncRates
          eventId={props.eventId}
          modelId={props.model?.id}
          currency={props.currency}
          datePeriod={props.eventDate}
        />
      )}
    </>
  )
}

RatesContainerComponent.propTypes = {
  model: PropTypes.object,
  eventId: PropTypes.number,
  onModelUpdate: PropTypes.func,
  eventDate: PropTypes.object,

  currency: PropTypes.string,
}

const mapStateToProps = state => {
  return {
    currency: state.user.profile?.data?.settings?.currency,
  }
}

export const RatesContainer = connect(mapStateToProps)(RatesContainerComponent)
