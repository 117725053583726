import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import { FieldError } from "../FieldError"
import { TabsNav } from "../../Tabs"

export const TabsControlField = ({
  id,
  options,
  disabled,
  value,
  meta = {},
  translateParams,
  className,
  ...props
}) => {
  const { error } = meta

  const { t } = useTranslation()
  const showError = Boolean(error)

  return (
    <div className={classNames("", { [className]: className })}>
      <div
        className={classNames("", {
          "is-invalid": showError,
        })}
      >
        <TabsNav
          {...props}
          options={options}
          active={value}
          onChange={props.onChange}
          disabled={disabled}
        />
      </div>

      <FieldError
        error={error}
        translateParams={translateParams}
        showError={showError}
      />
    </div>
  )
}

TabsControlField.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  translateParams: PropTypes.object,
  value: PropTypes.any,
  meta: PropTypes.object,
  className: PropTypes.string,
}
