import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "i18n"
import { API } from "api"
import { socialCalendarUpdatePost } from "./slice"
import { socialCalendarPostList } from "../actions"

function* updateItem({ payload: { id, params, onSuccess, onError } }) {
  try {
    const response = yield call(API.socialCalendar.updatePost, id, params)

    const { data } = response

    if (onSuccess) {
      onSuccess(data?.data)
    }
    toastr.success(i18n.t("edit.success"))

    yield put(socialCalendarUpdatePost.updateCompleted())
    yield put(socialCalendarPostList.updateItemById(data?.data))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      socialCalendarUpdatePost.updateRejected({
        errors,
        message,
      })
    )
    if (onError) {
      onError(errors)
    }
  }
}

export function* watchUpdateItem() {
  yield takeEvery(socialCalendarUpdatePost.update, updateItem)
}

function* updateSaga() {
  yield all([fork(watchUpdateItem)])
}

export default updateSaga
