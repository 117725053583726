import * as Yup from "yup"

import { ValidationsUtils } from "utils"
import { FILE_MAX_SIZE } from "consts"

export const validationSchema = Yup.object().shape({
  contract_file: Yup.mixed()
    .nullable()
    .test("maxSize", "field.error.file-size.max", value =>
      ValidationsUtils.fileMaxSize(value?.file, FILE_MAX_SIZE)
    ),
  contract_model_file: Yup.mixed()
    .nullable()
    .test("maxSize", "field.error.file-size.max", value =>
      ValidationsUtils.fileMaxSize(value, FILE_MAX_SIZE)
    ),
})
