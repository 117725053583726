import { getPostConfig } from "./postConfig"
import moment from "moment"
import {
  ROLE_DIRECTOR,
  SOCIAL_POST_PUBLICATION_ERROR,
  SOCIAL_POST_PUBLICATION_PENDING,
  SOCIAL_POST_PUBLICATION_SUCCESS,
  SOCIAL_POST_PUBLICATION_UNKNOWN,
  SOCIAL_POST_TYPE_FACEBOOK,
  SOCIAL_POST_TYPE_INSTAGRAM,
} from "consts"

export const getPostTypeTitle = type => {
  if (type) {
    return getPostConfig(type)?.title
  }
}

export const getPostBackgroundColor = type => {
  if (type) {
    return getPostConfig(type)?.backgroundColor
  }
}

export const getPostBorderColor = type => {
  if (type) {
    return getPostConfig(type)?.borderColor
  }
}

export const mapPostsToCalendarPosts = posts => {
  return posts.map(post => {
    return mapPostToCalendarPost(post)
  })
}

const getPostPublicationStatus = (posted, processed, isError) => {
  if (processed && posted) return SOCIAL_POST_PUBLICATION_SUCCESS
  if (processed && !posted && isError) return SOCIAL_POST_PUBLICATION_ERROR
  if (!processed) return SOCIAL_POST_PUBLICATION_PENDING

  return SOCIAL_POST_PUBLICATION_UNKNOWN
}

export const mapPostToCalendarPost = post => {
  return {
    title: getPostTypeTitle(post.type),
    start: moment.utc(post.publication_date).local().toDate(),
    backgroundColor: getPostBackgroundColor(post.type),
    borderColor: getPostBorderColor(post.type),
    className: "calendar-event",
    display: "block",
    extendedProps: {
      ...post,
    },
  }
}

export const extendBackendPostData = (post, userRole) => {
  return {
    ...post,
    publicationStatus: getPostPublicationStatus(
      post.posted,
      post.processed,
      !!post.error_message
    ),
    isInstagramPost: post.type === SOCIAL_POST_TYPE_INSTAGRAM,
    isFacebookPost: post.type === SOCIAL_POST_TYPE_FACEBOOK,
    isError: !!post.error_message,
    isDeletable:
      (!post.posted || post.isError) &&
      (post.is_my || userRole === ROLE_DIRECTOR),
    isEditable:
      (!post.posted || post.isError) &&
      (post.is_my || userRole === ROLE_DIRECTOR),
    isViewable: post.posted && !!post.remote_post_id,
  }
}
