import { all } from "redux-saga/effects"

import uploadSaga from "./upload/saga"
import removeSaga from "./remove/saga"

function* filesSaga() {
  yield all([uploadSaga(), removeSaga()])
}

export default filesSaga
