import { createAction } from "@reduxjs/toolkit"

const SOCKET_ON_SOCIAL_POST_UPDATE = "socket/onSocialPostUpdate"

export const socketOnSocialPostUpdate = createAction(
  SOCKET_ON_SOCIAL_POST_UPDATE,
  function prepare(data) {
    return {
      payload: {
        data,
      },
    }
  }
)
