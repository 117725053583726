import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import { FieldError } from "../FieldError"
import {
  ModelsSelectedListControl,
  AsyncModelsSelectedListControl,
} from "../../Controls"

export const ModelsSelectedListField = ({
  id,
  disabled,
  value = [],
  meta = {},
  translateParams,
  className,
  async = false,
  ...props
}) => {
  const { error } = meta

  const { t } = useTranslation()
  const showError = Boolean(error)

  return (
    <div className={classNames("", { [className]: className })}>
      <div
        className={classNames("", {
          "is-invalid": showError,
        })}
      >
        {!async ? (
          <ModelsSelectedListControl
            {...props}
            value={value}
            onChange={props.onChange}
            disabled={disabled}
          />
        ) : (
          <AsyncModelsSelectedListControl
            {...props}
            value={value}
            onChange={props.onChange}
            disabled={disabled}
          />
        )}
      </div>

      <FieldError
        error={error}
        translateParams={translateParams}
        showError={showError}
      />
    </div>
  )
}

ModelsSelectedListField.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  translateParams: PropTypes.object,
  value: PropTypes.any,
  meta: PropTypes.object,
  className: PropTypes.string,
  async: PropTypes.bool,
}
