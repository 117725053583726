import TagManager from "react-gtm-module"

class GtmService {
  init = ({ id, ...rest }) => {
    TagManager.initialize({
      gtmId: id,
      ...rest,
    })
  }

  dispatch = (data = {}, ...rest) => {
    TagManager.dataLayer({
      dataLayer: {
        ...data,
      },
      ...rest,
    })
  }
}

export const gtmService = new GtmService()
