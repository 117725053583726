import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Link, useRouteMatch } from "react-router-dom"
import {
  IconButton,
  InTownBadge,
  Image,
  RatingBadge,
  SelectionControl,
} from "components"
import { modelHelper } from "helpers"
import placeholder from "assets/images/dev/avatar-placeholder.png"
import placeholderSquare from "assets/images/dev/avatar-placeholder-square.svg"
import { ModelUtils } from "utils"
import { useMedia } from "react-use"
import { IMAGE_CROP_TYPE } from "consts"

const { SQUARE, RECTANGLE } = IMAGE_CROP_TYPE

export const ModelsGridItem = props => {
  const { t } = useTranslation()
  let { url } = useRouteMatch()
  const isSmallScreen = useMedia("(max-width: 575px)")
  const features = props.item?.profile || {}

  const handleImageClick = () => {
    if (props.isSelectMode) {
      props.toggleSelected(props.item?.id)
    }
  }
  return (
    <React.Fragment>
      <div className="models-panel-card-overview">
        <div className={"models-panel-card-overview__avatar-block"}>
          <div onClick={handleImageClick}>
            <Link
              to={`${url}/${props.item?.id}/general`}
              disabled={props.isSelectMode}
            >
              <Image
                type={isSmallScreen ? SQUARE : RECTANGLE}
                crops={props.item.crops}
                src={
                  props.item.avatar ||
                  (isSmallScreen ? placeholderSquare : placeholder)
                }
                alt={props.item?.name}
              />
            </Link>
          </div>
          <div className={"models-panel-card-overview__actions-block"}>
            {props.isSelectMode && (
              <SelectionControl
                id={`model-selection-control-${props.item.id}`}
                onChange={() => props.toggleSelected(props.item.id)}
                selected={props.selected}
              />
            )}
            {props.isEditGroupMode && (
              <IconButton
                name={"remove"}
                bg
                onClick={() => props.onRemoveFromGroup(props.item)}
              />
            )}
          </div>
          <div className={"models-panel-card-overview__in-town-badge"}>
            <InTownBadge status={props.item.is_in_town} />
          </div>
        </div>

        <div className={"models-panel-card-overview__title-block"}>
          <div className={"models-panel-card-overview__title-name"}>
            {props.item.name}
            {props.item.birthday
              ? `, ${ModelUtils.getAge(props.item.birthday)}`
              : null}
          </div>

          {props.item?.avg_rating && (
            <RatingBadge
              value={props.item.avg_rating}
              size={"sm"}
              wrapClassName={"ms-1"}
            />
          )}
        </div>

        <div className={"models-panel-card-overview__body-block"}>
          <div className={"d-flex flex-wrap"}>
            <div className="info-list__block">
              <div className="info-list__item">
                <div className={"info-list__label"}>{t("height")}</div>
                <div className={"info-list__info"}>
                  {features?.height?.text || "-"}
                </div>
              </div>
              <div className="info-list__item">
                <div className={"info-list__label"}>{t("bust")}</div>
                <div className={"info-list__info"}>
                  {features?.bust?.text || "-"}
                </div>
              </div>
              <div className="info-list__item">
                <div className={"info-list__label"}>{t("waist")}</div>
                <div className={"info-list__info"}>
                  {features?.waist?.text || "-"}
                </div>
              </div>
              <div className="info-list__item">
                <div className={"info-list__label"}>{t("hips")}</div>
                <div className={"info-list__info"}>
                  {features?.hips?.text || "-"}
                </div>
              </div>
              <div className="info-list__item">
                <div className={"info-list__label"}>{t("shoes")}</div>
                <div className={"info-list__info"}>
                  {features?.shoes?.text || "-"}
                </div>
              </div>
            </div>

            <div className="info-list__block">
              <div className="info-list__item">
                <div className={"info-list__label"}>
                  {modelHelper.general.getGender(props.item.gender).label}
                </div>
              </div>
              <div className="info-list__item">
                <div className={"info-list__label"}>{t("hair-color")}</div>
                <div className={"info-list__info"}>
                  {features?.hair_color?.text || "-"}
                </div>
              </div>
              <div className="info-list__item">
                <div className={"info-list__label"}>{t("eyes")}</div>
                <div className={"info-list__info"}>
                  {features?.eyes_color?.text || "-"}
                </div>
              </div>
            </div>
          </div>
          <div className={"info-list__item"}>
            <div className={"info-list__label mr-2"}>{t("groups")}</div>
            <div className={"info-list__info"}>
              {!!props.item.groups.length
                ? props.item.groups.map(group => {
                    return (
                      <span key={group.id} className={"info-list__info_group"}>
                        {group.name}
                      </span>
                    )
                  })
                : "-"}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

ModelsGridItem.propTypes = {
  item: PropTypes.object,

  selected: PropTypes.bool,
  toggleSelected: PropTypes.func,
  isSelectMode: PropTypes.bool,
  isEditGroupMode: PropTypes.bool,
  onRemoveFromGroup: PropTypes.func,
}
