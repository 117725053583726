import { takeEvery, fork, put, all, call, select } from "redux-saga/effects"

import i18n from "i18n"
import { API } from "api"
import { chatConversationMessagesSend } from "./slice"
import { chatConversationMessagesList } from "../list/slice"
import { chatUpdateOrCreate } from "../../../chats/list/saga"
import {
  selectConversationGroupChatId,
  selectConversationParticipantId,
} from "../../data/selectors"

export const conversationState = state => state.chat.conversation.data.data
export const participantIdState = state =>
  selectConversationParticipantId(state)
export const groupChatIdState = state => selectConversationGroupChatId(state)

function* send({
  payload: { userId, chatId, isGroup, params, onSuccess, onError },
}) {
  try {
    const response = isGroup
      ? yield call(API.chat.sendMessageToGroup, chatId, params)
      : yield call(API.chat.sendMessageToUser, userId, params)

    const { data } = response

    yield put(chatConversationMessagesSend.sendSuccess())

    const message = data?.data

    // update message feed after send message
    const conversation = yield select(conversationState)
    const participantId = yield select(participantIdState)
    const groupChatId = yield select(groupChatIdState)

    // check current opened chat (group or personal)
    if (
      (isGroup && groupChatId === chatId) ||
      (!isGroup && participantId === userId)
    ) {
      yield put(chatConversationMessagesList.addMessage(message))
    }

    if (onSuccess) {
      onSuccess()
    }

    // update chat list
    const chatListPayload = {
      message,
      chat: {
        id: message.chat_id,
        participant: !isGroup ? conversation?.participant : null,
        is_group: isGroup,
      },
    }
    yield call(chatUpdateOrCreate, { payload: chatListPayload })
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      chatConversationMessagesSend.sendError({
        errors,
        message,
      })
    )

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchSend() {
  yield takeEvery(chatConversationMessagesSend.send, send)
}

function* sendSaga() {
  yield all([fork(watchSend)])
}

export default sendSaga
