import { takeLatest, fork, put, all, call } from "redux-saga/effects"

import { agencyStatementList } from "./slice"
import i18n from "i18n"
import { API } from "api"

export function* getList({ payload: { id, params } }) {
  try {
    const response = yield call(API.agency.getAgencyStatements, id, params)

    const { data } = response
    yield put(agencyStatementList.getListSuccess(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(agencyStatementList.getListError(message))
  }
}

export function* watchGetList() {
  yield takeLatest(agencyStatementList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
