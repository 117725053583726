import React, { useState, useMemo } from 'react';
import { useField } from 'formik';
import classNames from "classnames";

import { TextInput } from '../TextInput';

export const PasswordInputFiled = (props) => {
    const [field, meta, form] = useField(props);

    const [visible, setVisible] = useState(false);

    const type = visible ? 'text' : 'password';

    const toggleVisible = (e) => {
        e.preventDefault();

        setVisible(prevVisible => !prevVisible)
    }

    const rightAccessory = useMemo(() => (
        <div className={'form-right-accessory'}>
            <a className={'form-control-eye text-dark'} href={'#'} onClick={toggleVisible}>
                <i className={classNames('mdi font-size-16', {
                    'mdi-eye-outline': !visible,
                    'mdi-eye-off-outline': visible,
                })}
                />
            </a>
        </div>
    ), [visible]);

    return (
        <TextInput field={field} meta={meta} type={type} rightAccessory={rightAccessory} {...props} />
    )
}
