import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { calendarEventPaymentSubmit } from "./slice"

function* submit({ payload: { id, onSuccess, onError } }) {
  try {
    yield call(API.calendar.eventPayment, id)

    yield put(calendarEventPaymentSubmit.submitSuccess())
    if (onSuccess) {
      onSuccess()
    }
    toastr.success(i18n.t("paid.success"))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      calendarEventPaymentSubmit.submitError({
        errors,
        message,
      })
    )

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchSubmit() {
  yield takeEvery(calendarEventPaymentSubmit.submit, submit)
}

function* submitSaga() {
  yield all([fork(watchSubmit)])
}

export default submitSaga
