import { all } from "redux-saga/effects"

import dataSaga from "./data/saga"
import submitSaga from "./submit/saga"

function* permissionsSaga() {
  yield all([dataSaga(), submitSaga()])
}

export default permissionsSaga
