import React from "react"
import PropTypes from "prop-types"
import { Button, ConfirmPopup, TextButton } from "components"
import { useDispatch } from "react-redux"
import {
  modelsPanelListChangeFilter,
  modelsPanelState,
  modelsRemoveGroup,
} from "store"
import { useConfirmModal } from "hooks"

export const EditGroupControls = ({ group }) => {
  const dispatch = useDispatch()

  const { modalRef, showModal, closeModal, handleConfirm } = useConfirmModal(
    item => {
      dispatch(
        modelsRemoveGroup.remove({
          id: item,
          onSuccess: handleRemoveSuccess,
        })
      )
    },
    () => dispatch(modelsRemoveGroup.cleanState())
  )

  const handleRemoveSuccess = () => {
    dispatch(modelsPanelState.setDefaultState())
    dispatch(modelsPanelListChangeFilter({ group: null }))
  }

  const handleAddToGroup = () => {
    dispatch(modelsPanelListChangeFilter({ not_in_group: true }))
    dispatch(modelsPanelState.setAddToGroupState())
  }

  const handleRemoveGroup = () => {
    showModal(group)
  }

  const handleCancel = () => {
    dispatch(modelsPanelState.setDefaultState())
  }

  return (
    <>
      <Button
        color={"secondary"}
        title={"add-to-group"}
        btnClass={"mr-2"}
        onClick={handleAddToGroup}
      />
      <Button
        btnClass={"mr-2"}
        title={"remove-group"}
        onClick={handleRemoveGroup}
      />
      <TextButton btnClass={"mr-2"} title={"cancel"} onClick={handleCancel} />
      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
      />
    </>
  )
}

EditGroupControls.propTypes = {
  group: PropTypes.number,
}
