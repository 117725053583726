import {
    MODEL_PANEL_GENERAL_AGENCY_DETAILS_EDIT,
    MODEL_PANEL_GENERAL_AGENCY_DETAILS_EDIT_SUCCESS,
    MODEL_PANEL_GENERAL_AGENCY_DETAILS_EDIT_ERROR,
    MODEL_PANEL_GENERAL_AGENCY_DETAILS_EDIT_CLEAN_STATE,
} from './actionTypes';

export const modelPanelGeneralAgencyDetailsEdit = (id, params, onSuccess, onError) => {
    return {
        type: MODEL_PANEL_GENERAL_AGENCY_DETAILS_EDIT,
        payload: { id, params, onSuccess, onError },
    }
};

export const modelPanelGeneralAgencyDetailsEditSuccess = () => {
    return {
        type: MODEL_PANEL_GENERAL_AGENCY_DETAILS_EDIT_SUCCESS,
    }
};

export const modelPanelGeneralAgencyDetailsEditError = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_AGENCY_DETAILS_EDIT_ERROR,
        payload: data,
    }
};

export const modelPanelGeneralAgencyDetailsEditCleanState = () => {
    return { type: MODEL_PANEL_GENERAL_AGENCY_DETAILS_EDIT_CLEAN_STATE };
};