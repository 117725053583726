import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"
import classNames from "classnames"

import { FILE_MAX_SIZE } from "consts"
import { FilesUploader } from "./FilesUploader"
import { PerfectScrollbar } from "components"

export const AttachmentsSection = props => {
  const { t } = useTranslation()

  const { control, trigger, setError, watch } = useFormContext()

  const watchFiles = watch("files")

  return (
    <div
      className={classNames("chat-attachments-section p-3", {
        "d-none": watchFiles.length === 0,
      })}
    >
      <div className={"w-100"}>
        <PerfectScrollbar
          style={{ height: watchFiles.length > 1 ? "150px" : "78px" }}
        >
          <FilesUploader
            control={control}
            processing={props.processing}
            addFileToProcessing={props.addFileToProcessing}
            removeFileFromProcessing={props.removeFileFromProcessing}
            trigger={trigger}
            setError={setError}
            fileMaxSize={FILE_MAX_SIZE}
            filesUploaderRef={props.filesUploaderRef}
          />
        </PerfectScrollbar>
      </div>
    </div>
  )
}

AttachmentsSection.propTypes = {
  processing: PropTypes.array,
  addFileToProcessing: PropTypes.func,
  removeFileFromProcessing: PropTypes.func,
  filesUploaderRef: PropTypes.any,
}
