import {
    MODEL_EDIT_BOOK,
    MODEL_EDIT_BOOK_SUCCESS,
    MODEL_EDIT_BOOK_ERROR,
    MODEL_EDIT_BOOK_CLEAN_STATE,
} from './actionTypes';

export const modelEditBook = (id, params, onSuccess, onError) => {
    return {
        type: MODEL_EDIT_BOOK,
        payload: { id, params, onSuccess, onError },
    }
};

export const modelEditBookSuccess = () => {
    return {
        type: MODEL_EDIT_BOOK_SUCCESS,
    }
};

export const modelEditBookError = (data) => {
    return {
        type: MODEL_EDIT_BOOK_ERROR,
        payload: data,
    }
};

export const modelEditBookCleanState = () => {
    return { type: MODEL_EDIT_BOOK_CLEAN_STATE };
};