/* eslint react/prop-types: off */
import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import moment from "moment"

import { ReactTableView } from "components"
import { billingHelper } from "helpers"

const currencySymbol = billingHelper.currency.getCurrency().symbol

export const InvoicesTable = props => {
  const { t } = useTranslation()

  const data = useMemo(() => {
    return props.list
  }, [props.list])

  const [columns, setColumns] = useState([
    {
      Header: t("invoice"),
      accessor: "number",
      id: "number",
      Cell: ({ row: { original }, value }) => {
        return (
          <Link
            to={`/billing/invoices/${original.id}`}
            className="fw-bold text-decoration-underline text-dark"
          >
            {value}
          </Link>
        )
      },
    },
    {
      Header: t("date"),
      accessor: "created_at",
      id: "created_at",
      Cell: ({ row: { original } }) => {
        const { created_at } = original
        return (
          <Link to={`/billing/invoices/${original.id}`} className={"text-dark"}>
            {created_at ? moment(created_at).format("DD/MM/YYYY") : "-"}
          </Link>
        )
      },
    },
    {
      Header: () => <div className={"w-100 text-end"}>{t("amount")}</div>,
      accessor: "total",
      id: "total",
      Cell: ({ row: { original }, value }) => {
        return (
          <div className={"text-end"}>
            <Link to={`/billing/invoices/${original.id}`} className="text-dark">
              {value} {currencySymbol}
            </Link>
          </div>
        )
      },
    },
  ])

  return (
    <ReactTableView isLoading={props.loading} columns={columns} data={data} />
  )
}

InvoicesTable.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
}
