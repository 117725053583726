import React from "react"
import PropTypes from "prop-types"
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  generatePath,
} from "react-router-dom"
import { connect } from "react-redux"

import { useDepartmentPermissionGuard } from "hooks"
import { DEPARTMENTS_PERMISSIONS } from "consts"
import { General } from "../../General"
import { History } from "../../History"
import { Rating } from "../../Raiting"
import { Calendar } from "../../Calendar"
import { Statement } from "../../Statement"

const PanelRoutingComponent = props => {
  let { path } = useRouteMatch()
  const { id } = useParams()

  const { status: allowed } = useDepartmentPermissionGuard({
    permission: DEPARTMENTS_PERMISSIONS.AGENCY_STATEMENT_READ,
  })

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={generatePath(`${path}/general`, { id: id })} />
      </Route>
      <Route path={`${path}/general`}>
        <General />
      </Route>
      <Route path={`${path}/calendar`}>
        <Calendar {...props} />
      </Route>

      {allowed && (
        <Route path={`${path}/statement`}>
          <Statement {...props} />
        </Route>
      )}

      <Route path={`${path}/rating`}>
        <Rating />
      </Route>
      <Route path={`${path}/history`}>
        <History />
      </Route>
      <Route>
        <Redirect to={generatePath(`${path}/general`, { id: id })} />
      </Route>
    </Switch>
  )
}

PanelRoutingComponent.propTypes = {}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const PanelRouting = connect(
  mapStateToProps,
  mapDispatchToProps
)(PanelRoutingComponent)
