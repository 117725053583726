/* eslint react/prop-types: off */
import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Badge } from "reactstrap"

import { IconButton, ReactTableView, Button } from "components"
import { BillingUtils } from "utils"
import { BILLING_PAYMENT_METHOD_TYPE } from "consts"

const { SEPA_DEBIT } = BILLING_PAYMENT_METHOD_TYPE

export const PaymentMethodsTable = props => {
  const { t } = useTranslation()

  const data = useMemo(() => {
    return props.list
  }, [props.list])

  const [columns, setColumns] = useState([
    {
      Header: props.type === SEPA_DEBIT ? t("iban") : t("card_number"),
      accessor: "last4",
      id: "last4",
      Cell: ({ row: { original } }) => {
        const { country, bank_code, last4 } = original
        return (
          <>
            <span className="">
              {props.type === SEPA_DEBIT
                ? BillingUtils.paymentIbanHiddenNumber({
                    country,
                    bank_code,
                    last4,
                  })
                : BillingUtils.paymentCardHiddenNumber(last4)}
            </span>
            {original.default && (
              <Badge className={"ms-2 font-size-12 badge-soft-secondary"} pill>
                {t("default")}
              </Badge>
            )}
          </>
        )
      },
    },
    {
      Header: t("expiry_date"),
      accessor: "expiry_date",
      id: "expiry_date",
      Cell: ({ row: { original } }) => {
        return BillingUtils.paymentCardFormatExpiryDate(
          original.exp_month,
          original.exp_year
        )
      },
    },
    {
      Header: t("payment_system"),
      accessor: "brand",
      id: "brand",
      Cell: ({ row: { original }, value }) => {
        return <span className={"text-capitalize"}>{value}</span>
      },
    },
    {
      Header: "",
      id: "action",
      Cell: ({ row: { original } }) => (
        <div className="d-flex align-items-center justify-content-end">
          {!original.default && (
            <div className={"me-4"}>
              <Button
                color={"secondary"}
                outline
                size={"btn-sm"}
                onClick={() => props.onDefault(original)}
                title={"default"}
              />
            </div>
          )}

          <IconButton
            name={"remove"}
            bg
            onClick={() => props.onRemove(original)}
          />
        </div>
      ),
    },
  ])

  return (
    <ReactTableView
      isLoading={props.loading}
      columns={columns}
      data={data}
      initialState={{
        hiddenColumns:
          props.type === SEPA_DEBIT ? ["expiry_date", "brand"] : [],
      }}
    />
  )
}

PaymentMethodsTable.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  type: PropTypes.string,

  onRemove: PropTypes.func,
  onDefault: PropTypes.func,
}
