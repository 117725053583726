import { all } from "redux-saga/effects"

// import listSaga from "./list/saga"
import dataSaga from "./data/saga"
import submitSaga from "./submit/saga"

function* importSaga() {
  yield all([dataSaga(), submitSaga()])
}

export default importSaga
