import React from 'react';
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next';
import classNames from "classnames"
import * as HOC from '../../HOC';
import { InfoCard } from "./InfoCard"
import { Pagination } from "../Pagination"
const DataShower = HOC.withDataShowerOverlay();

export const InfoCardShower = (props) => {
  const { t } = useTranslation();

  return (
    <InfoCard
      title={t(props.title)}
      onAdd={props.onAdd}
      addMode={props.addMode}
      onEdit={props.onEdit}
      editMode={props.editMode}
      className={classNames('h-100 info-card_loader', {
        [props.className]: props.className,
      })}
    >
      <DataShower isLoading={props.loading} isFailed={props.error} error={props.error}>
        {!!props.items.length ? (
          <>
            {props.children}

            <Pagination data={props.meta} onPageChange={props.onPageChange} className={'pagination-sm'} />
          </>
        ) : !props.loading ? (
          t('empty')
        ) : null}
      </DataShower>
    </InfoCard>
  );
};

InfoCardShower.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  addMode: PropTypes.bool,
  editMode: PropTypes.bool,
  className: PropTypes.string,
  items: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  meta: PropTypes.object,
  onPageChange: PropTypes.func,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
}