import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Row } from "reactstrap"
import { connect } from "react-redux"
import { Link, useRouteMatch, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"

import {
  ConfirmPopup,
  MegaTagTitle,
  DraftPackageContainer,
  BookPhotosCarouselShower,
  OverlayBlockLoading,
  SharedEntity,
} from "../../../components"
import {
  useDraftPackageDataPicker,
  usePanelTopBarOptions,
} from "../../../hooks"
import {
  PublishedBookContainer,
  BooksContainer,
  PublishBookModal,
} from "../components/BookComponents"
import {
  modelBooksRemoveBook,
  modelBooksRemoveBookCleanState,
  modelPublishBookCleanState,
} from "store/actions"
import { selectPackageDataCount } from "../../../store/package/draft/data/dataPicker/selectors"
import { FileUtils } from "../../../utils"

const BooksComponent = props => {
  const { t } = useTranslation()
  let { url } = useRouteMatch()
  const history = useHistory()

  const openPackage = e => {
    e.preventDefault()
    setDraftPackageVisible(true)
  }

  const panelOptions = useMemo(
    () => ({
      actions: (
        <div>
          <div className="d-inline">
            <Link to={"#"} className="text-secondary" onClick={openPackage}>
              {t("btn.new-package")}
              {props.packageDataCount > 0
                ? ` (${props.packageDataCount})`
                : null}
            </Link>
          </div>

          <div className="ms-3 d-inline">
            <Link to={`${url}/add`} className="text-secondary">
              {t("btn.add-book")}
            </Link>
          </div>
        </div>
      ),
    }),
    [props.packageDataCount]
  )

  usePanelTopBarOptions(panelOptions)

  const [syncCount, forceUpdate] = React.useReducer(x => x + 1, 0)
  const removeModalRef = useRef(null)
  const publishModalRef = useRef(null)
  const [bookRemove, setBookRemove] = useState(null)
  const [bookPublish, setBookPublish] = useState(null)
  const [draftPackageVisible, setDraftPackageVisible] = useState(false)
  const [sendBook, setSendBook] = useState(null)
  const [sendBookDrawerVisible, setSendBookDrawerVisible] = useState(false)

  const { pickedBooks, onBookToggle, onBookRemove, isBookPicked } =
    useDraftPackageDataPicker()

  useEffect(() => {
    return () => {
      props.modelBooksRemoveBookCleanState()
    }
  }, [])

  const syncData = () => {
    forceUpdate()
  }

  const onEdit = item => {
    const { id } = item
    history.push(`${url}/${id}/edit`)
  }

  const onRemove = item => {
    setBookRemove(item)

    if (removeModalRef.current) {
      removeModalRef.current.show()
    }
  }

  const resetBookRemove = () => {
    setBookRemove(null)
  }

  const onRemoveSuccess = id => () => {
    syncData()

    if (isBookPicked(id)) {
      onBookRemove({ id })
    }
  }

  const confirmRemove = () => {
    props.modelBooksRemoveBook(bookRemove.id, onRemoveSuccess(bookRemove.id))
  }

  const onPublish = item => {
    setBookPublish(item)

    if (publishModalRef.current) {
      publishModalRef.current.show()
    }
  }

  const resetBookPublish = () => {
    setBookPublish(null)
    props.modelPublishBookCleanState()
  }

  const onCopy = item => {
    const copyBookId = item.id
    history.push({ pathname: `${url}/add`, state: { copyBookId } })
  }

  const onDownload = item => {
    FileUtils.downloadFileByLink(item?.pdf_url)
  }

  const onAddToPackage = book => {
    onBookToggle(book)
  }

  const onSend = book => {
    setSendBookDrawerVisible(true)
    setSendBook(book)
  }

  const closeSendBookDrawer = () => {
    setSendBookDrawerVisible(false)
    setSendBook(null)
  }

  const onSendBookSuccess = () => {
    closeSendBookDrawer()
  }

  // Show book photos
  const [photosBookId, setPhotosBookId] = useState(null)
  const showBookPhotos = item => {
    if (item?.pages_count) {
      setPhotosBookId(item.id)
    }
  }
  // --- //

  return (
    <div className={"model-panel-books"}>
      <MegaTagTitle title={"title.model.book"} />

      <OverlayBlockLoading isLoading={props.bookDataLoading}>
        <Row>
          <PublishedBookContainer
            syncCount={syncCount}
            onEdit={onEdit}
            onRemove={onRemove}
            onPublish={onPublish}
            onCopy={onCopy}
            onDownload={onDownload}
            packagePickedBooks={pickedBooks}
            onAddToPackage={onAddToPackage}
            onSend={onSend}
            onCoverClick={showBookPhotos}
          />
        </Row>

        <Row className={"mt-4"}>
          <BooksContainer
            syncCount={syncCount}
            onEdit={onEdit}
            onRemove={onRemove}
            onPublish={onPublish}
            onCopy={onCopy}
            onDownload={onDownload}
            packagePickedBooks={pickedBooks}
            onAddToPackage={onAddToPackage}
            onSend={onSend}
            onCoverClick={showBookPhotos}
          />
        </Row>
      </OverlayBlockLoading>

      <ConfirmPopup
        ref={removeModalRef}
        onConfirm={confirmRemove}
        onClosed={resetBookRemove}
      >
        <p className={"font-size-22"}>{t("sure_delete_book")}?</p>
      </ConfirmPopup>

      <PublishBookModal
        ref={publishModalRef}
        book={bookPublish}
        onClosed={resetBookPublish}
        onSuccess={syncData}
      />

      <DraftPackageContainer
        initialTab={"books"}
        draftPackageVisible={draftPackageVisible}
        draftPackageClose={() => setDraftPackageVisible(false)}
      />

      <SharedEntity
        entity={"book"}
        entityId={sendBook?.id}
        visible={sendBookDrawerVisible}
        onCancel={closeSendBookDrawer}
        onSuccess={onSendBookSuccess}
      />

      <BookPhotosCarouselShower
        id={photosBookId}
        onDestroy={() => setPhotosBookId(null)}
      />
    </div>
  )
}

BooksComponent.propTypes = {
  modelBooksRemoveBook: PropTypes.func,
  modelBooksRemoveBookCleanState: PropTypes.func,
  modelPublishBookCleanState: PropTypes.func,

  packageDataCount: PropTypes.number,

  bookDataLoading: PropTypes.bool,
}

const mapStateToProps = state => {
  return {
    packageDataCount: selectPackageDataCount(state),

    // for book photos carousel
    bookDataLoading: state.modelPanel.book.common.book.loading,
  }
}

const mapDispatchToProps = {
  modelBooksRemoveBook,
  modelBooksRemoveBookCleanState,
  modelPublishBookCleanState,
}

export const Books = connect(
  mapStateToProps,
  mapDispatchToProps
)(BooksComponent)
