import React from "react"
import PropTypes from "prop-types"
import { InfoCard, InfoCardListItem } from "components"
import { useTranslation } from "react-i18next"

export const InvoiceDetailsInfoCard = props => {
  const { t } = useTranslation()
  return (
    <InfoCard
      onEdit={props.onEdit}
      editMode={props.editMode}
      title={t("invoice-details")}
      className={"h-100 client-note-info"}
    >
      <div className={"info-card__block"}>
        <InfoCardListItem label={t("bank-name")} value={props.data.bank_name} />
        <InfoCardListItem
          label={t("company-name")}
          value={props.data.company_name}
        />
        <InfoCardListItem
          label={t("billing-address")}
          value={props.data.billing_address}
        />
        <InfoCardListItem label={t("vat")} value={props.data.vat} />
        <InfoCardListItem
          label={t("other-information")}
          value={props.data.other_information}
        />
      </div>
    </InfoCard>
  )
}

InvoiceDetailsInfoCard.propTypes = {
  onEdit: PropTypes.func,
  editMode: PropTypes.bool,
  data: PropTypes.object,
}
