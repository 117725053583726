/* eslint react/prop-types: off */
import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Row, Col, Card, CardBody } from "reactstrap"
import { useRouteMatch, Link } from "react-router-dom"
import moment from "moment"
import { ReactTableView, Pagination, PhoneFormat } from "../../../components"
import placeholder from "../../../assets/images/dev/avatar-placeholder.png"
import * as HOC from "../../../HOC"

const TableDataLoading = HOC.withTableDataLoading()

export const EmployeesTable = props => {
  const { t } = useTranslation()

  let { url } = useRouteMatch()

  const data = useMemo(() => {
    return props.list
  }, [props.list])

  const [columns, setColumns] = useState([
    {
      Header: "",
      accessor: "avatar",
      id: "avatar",
      Cell: ({ value }) => (
        <img
          src={value ? value : placeholder}
          alt=""
          className="avatar-xs rounded-circle img-cover"
        />
      ),
      disableSortBy: true,
    },
    {
      Header: t("name"),
      accessor: "name",
      id: "name",
      Cell: ({ row: { original }, value }) => {
        let isActualUser = original.id === props.userId
        return (
          <>
            <Link
              to={`${url}/${original.id}`}
              className="text-dark font-weight-bold mr-2"
            >
              {value}
            </Link>
            {isActualUser && (
              <div className={"d-inline text-danger"}>({t("me")})</div>
            )}
          </>
        )
      },
    },
    {
      Header: t("email"),
      accessor: "email",
      id: "email",
      Cell: ({ row: { original }, value }) => {
        return (
          <Link
            to={`${url}/${original.id}`}
            className="text-dark text-decoration-underline"
          >
            {value}
          </Link>
        )
      },
    },
    {
      Header: t("department"),
      accessor: "department",
      id: "department",
      Cell: ({ value }) => {
        return value?.name || ""
      },
    },
    {
      Header: t("phone"),
      accessor: "phone",
      id: "phone",
      Cell: ({ value }) => {
        return <PhoneFormat value={value} />
      },
    },
    {
      Header: t("date_added"),
      accessor: "created_at",
      id: "created_at",
      Cell: ({ value }) => {
        return value ? moment(value).format("DD/MM/YYYY") : "-"
      },
    },
  ])

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody className={"bg-neutral"}>
            <TableDataLoading
              isLoading={props.loading}
              isFailed={props.error}
              error={props.error}
            >
              <ReactTableView
                isLoading={props.loading}
                columns={columns}
                data={data}
              />

              <Pagination
                data={props.meta}
                onPageChange={props.onChangePage}
                className={"mt-2"}
              />
            </TableDataLoading>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

EmployeesTable.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,
  onChangePage: PropTypes.func,
  userId: PropTypes.any,
}
