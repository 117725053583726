import {
    EMPLOYEE_DETAILS_FETCH_DATA,
    EMPLOYEE_DETAILS_FETCH_DATA_SUCCESS,
    EMPLOYEE_DETAILS_FETCH_DATA_ERROR,
    EMPLOYEE_DETAILS_DATA_CLEAN_STATE,

    EMPLOYEE_DETAILS_UPDATE_DATA,
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    data: null,
};

const data = (state = initialState, action) => {
    switch (action.type) {
        case EMPLOYEE_DETAILS_FETCH_DATA:
            state = {
                ...state,
                loading: true,
                error: null,
            };
            break;

        case EMPLOYEE_DETAILS_FETCH_DATA_SUCCESS:
            state = {
                ...state,
                loading: false,
                data: action.payload,
            };
            break;

        case EMPLOYEE_DETAILS_FETCH_DATA_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        case EMPLOYEE_DETAILS_DATA_CLEAN_STATE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        case EMPLOYEE_DETAILS_UPDATE_DATA:
            state = {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default data;