import {
  MODEL_PROFILE_SET_DATA,
  MODEL_PROFILE_CLEAN_STATE,
  MODEL_PROFILE_UPDATE_DATA,
  MODEL_PROFILE_UPDATE_FEATURES,
  MODEL_PROFILE_ADD_GROUP,
  MODEL_PROFILE_REMOVE_GROUP,
} from "./actionTypes"

const initialState = {
  data: null,
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case MODEL_PROFILE_SET_DATA:
      state = {
        ...state,
        data: action.payload,
      }
      break

    case MODEL_PROFILE_CLEAN_STATE:
      state = {
        ...state,
        ...initialState,
      }
      break

    case MODEL_PROFILE_UPDATE_DATA:
      state = {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      }
      break

    case MODEL_PROFILE_UPDATE_FEATURES:
      state = {
        ...state,
        data: {
          ...state.data,
          profile: {
            ...state.data.profile,
            ...action.payload,
          },
        },
      }
      break

    case MODEL_PROFILE_ADD_GROUP:
      state = {
        ...state,
        data: {
          ...state.data,
          groups: [...state.data.groups, action.payload],
        },
      }
      break

    case MODEL_PROFILE_REMOVE_GROUP:
      state = {
        ...state,
        data: {
          ...state.data,
          groups: state.data.groups.filter(
            group => group.id !== action.payload.id
          ),
        },
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
