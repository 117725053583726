import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import { EventsEmailSendForm } from "./EventsEmailSendForm"
import { ValidationsUtils } from "utils"
import { calendarEventEventsEmailSend } from "store/actions"

export const EventsEmailSendContainer = props => {
  const dispatch = useDispatch()

  const { loading, error } = useSelector(
    state => state.calendarEvent.eventsEmailSend
  )

  const [recipientsOptions, setRecipientsOptions] = useState(
    props.initialRecipients || []
  )

  useEffect(() => {
    return () => {
      dispatch(calendarEventEventsEmailSend.cleanState())
    }
  }, [])

  const onSubmitSuccess = onSubmitProps => () => {
    const { onReset } = onSubmitProps
    onReset()

    props.onSuccess()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    params = {
      ...params,
      id: props.modelId,
    }

    dispatch(
      calendarEventEventsEmailSend.send({
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  const onAddRecipient = data => {
    setRecipientsOptions(prevState => [...prevState, data])
  }

  const onRemoveRecipient = (data, index) => {
    let items = recipientsOptions.filter((_, i) => i !== index)
    setRecipientsOptions([...items])
  }

  return (
    <>
      <EventsEmailSendForm
        initialValues={{
          date: props.date,
        }}
        loading={loading}
        error={error}
        onSubmit={handleSubmit}
        recipientsOptions={recipientsOptions}
        onAddRecipient={onAddRecipient}
        onRemoveRecipient={onRemoveRecipient}
      />
    </>
  )
}

EventsEmailSendContainer.propTypes = {
  modelId: PropTypes.any,
  date: PropTypes.object,
  onSuccess: PropTypes.func,
  initialRecipients: PropTypes.array,
}
