import { all, call, fork, put, takeLatest } from "redux-saga/effects"
import i18n from "i18n"
import { API } from "api"
import { socialCalendarPostList } from "./slice"

export function* getList({ payload: { params } }) {
  try {
    const response = yield call(API.socialCalendar.getPosts, params)

    const { data } = response
    yield put(socialCalendarPostList.getListCompleted(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")

    yield put(socialCalendarPostList.getListRejected(message))
  }
}

export function* watchGetList() {
  yield takeLatest(socialCalendarPostList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
