import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import i18n from "../../../../i18n"

import { API } from "../../../../api"
import { agencyRemoveLogo } from "./slice"
import { agencyProfileData } from "../../profile/data/slice"
import toastr from "toastr"

function* remove({ payload: { id, onSuccess, onError } }) {
  try {
    const response = yield call(API.agency.removeAgencyAvatar, id)

    const { data } = response
    if (onSuccess) {
      onSuccess()
    }

    yield put(agencyRemoveLogo.removeCompleted())
    toastr.success(i18n.t("agency-logo.remove.success"))
    yield put(agencyProfileData.updateData({ avatar: null }))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")
    if (onError) {
      onError(errors)
    }
    yield put(
      agencyRemoveLogo.removeRejected({
        errors,
        message,
      })
    )
  }
}

export function* watchRemove() {
  yield takeEvery(agencyRemoveLogo.remove, remove)
}

function* removeSaga() {
  yield all([fork(watchRemove)])
}

export default removeSaga
