import { createSelector } from "@reduxjs/toolkit"
import { MODELS_PANEL_STATE } from "consts"

const { DEFAULT, EDIT_GROUP, CREATE_GROUP, ADD_TO_GROUP } = MODELS_PANEL_STATE

export const selectModelsPanelState = createSelector(
  [state => state.models.panel.state.state],
  state => {
    return {
      isDefaultState: state === DEFAULT,
      isCreateGroupState: state === CREATE_GROUP,
      isAddToGroupState: state === ADD_TO_GROUP,
      isEditGroupState: state === EDIT_GROUP,
    }
  }
)
