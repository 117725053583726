import moment from "moment"
import {
  TOP_TEN_CLIENTS_BY_EARNINGS,
  TOP_TEN_CLIENTS_BY_RATING,
  TOP_TEN_EMPLOYEES_BY_EARNINGS,
  TOP_TEN_EMPLOYEES_BY_RATING,
  TOP_TEN_MODELS_BY_EARNINGS,
  TOP_TEN_MODELS_BY_RATING,
} from "consts"

export const generateEarningsComparisonPeriodNames = params => {
  Object.keys(params).map(key => {
    params[key] = moment(params[key]).format("DD/MM/YYYY")
  })
  let { date_from, date_to, comparison_date_from, comparison_date_to } = params

  return {
    baseChart: `${date_from} - ${date_to}`,
    comparisonChart: `${comparison_date_from} - ${comparison_date_to}`,
  }
}

export const generateTopTenTooltipTitles = alias => {
  switch (alias) {
    case TOP_TEN_CLIENTS_BY_RATING:
    case TOP_TEN_EMPLOYEES_BY_RATING:
    case TOP_TEN_MODELS_BY_RATING: {
      return { name: "rating" }
    }
    case TOP_TEN_CLIENTS_BY_EARNINGS:
    case TOP_TEN_EMPLOYEES_BY_EARNINGS:
    case TOP_TEN_MODELS_BY_EARNINGS: {
      return { name: "earnings" }
    }
  }
}
