import _ from "lodash"

import i18n from "i18n"
import { STATEMENT_PAYMENT_TYPE, STATEMENT_PAYMENT_STATUS } from "consts"

export const getPaymentTypes = () => {
  return [
    {
      label: i18n.t("payment"),
      value: STATEMENT_PAYMENT_TYPE.PAID,
    },
    {
      label: i18n.t("refund"),
      value: STATEMENT_PAYMENT_TYPE.UNPAID,
    },
  ]
}

export const getPaymentType = value => {
  const options = getPaymentTypes()
  return _.find(options, { value: value })
}

export const getPaidStatusConfigs = () => {
  return [
    {
      label: i18n.t("paid"),
      value: true,
      theme: "success",
    },
    {
      label: i18n.t("unpaid"),
      value: false,
      theme: "warning",
    },
  ]
}

export const getPaidStatusConfig = value => {
  const options = getPaidStatusConfigs()
  return _.find(options, { value: value })
}

export const getPaymentStatusConfigs = () => {
  return [
    {
      label: i18n.t("unpaid"),
      value: STATEMENT_PAYMENT_STATUS.UNPAID,
      theme: "warning",
    },
    {
      label: i18n.t("pending"),
      value: STATEMENT_PAYMENT_STATUS.PENDING,
      theme: "secondary",
    },
    {
      label: i18n.t("paid"),
      value: STATEMENT_PAYMENT_STATUS.PAID,
      theme: "success",
    },
  ]
}

export const getPaymentStatusConfig = value => {
  const options = getPaymentStatusConfigs()
  return _.find(options, { value: value })
}
