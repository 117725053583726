import React from "react"
import PropTypes from "prop-types"

export const ContentHeader = ({ headerEnd, title }) => {
  return (
    <div className={"mb-3 d-flex justify-content-between align-items-center"}>
      <p className="fw-medium mb-0">{title}</p>

      {headerEnd}
    </div>
  )
}

ContentHeader.propTypes = {
  title: PropTypes.any,
  headerEnd: PropTypes.any,
}
