import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const importSubmitSlice = createSlice({
  name: "importSubmit",
  initialState,
  reducers: {
    submit(state) {
      state.loading = true
      state.error = null
    },
    submitSuccess(state) {
      state.loading = false
    },
    submitError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} submit
 * @property {function} submitSuccess
 * @property {function} submitError
 * @property {function} cleanState
 */
export const importSubmit = importSubmitSlice.actions

export default importSubmitSlice.reducer
