import * as Yup from 'yup';

import { CommonUtils } from '../../../../../../utils';

export const validationSchema = (
    Yup.object().shape({
        url: Yup.string()
            .required('field.error.required')
            .matches(CommonUtils.youtubeVideoRegex, 'field.error.invalid'),
    })
)