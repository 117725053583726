import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import moment from "moment"
import { Alert, Col, Row } from "reactstrap"

import { validationSchema } from "./validation"
import {
  TitleInfoSection,
  ServiceSection,
  DepartureSection,
  ArrivalSection,
  NotesSection,
  AlertSection,
  ModelsSection,
} from "../EventFormSections"
import { Button } from "../../../Buttons"
import {
  getModelsDefaultValues,
  getTitleInfoDefaultValues,
  getServiceDefaultValues,
  getDepartureDefaultValues,
  getArrivalDefaultValues,
  getNotesDefaultValues,
  mapModelsSubmitValues,
  getAlertDefaultValues,
  mapAlertsSubmitValues,
} from "../helpers"
import { ControllerHF } from "../../../ReactHookForm"
import { ComboboxFormField } from "../../../FormElements"
import { modelHelper } from "helpers"

const travelOptions = modelHelper.travel.getTravelByOptions()

export const EventTravelForm = props => {
  const { t } = useTranslation()

  const defaultValues = useMemo(() => {
    const {
      id,
      type,
      gender,
      fashion_models,
      // service_category,
      service_name,
      service_details,
      travel_by,
      title,
      description,
      date_start,
      date_end,
      time_start,
      time_end,
      departure_place,
      arrival_place,
      note_models,
      note_internal,
      alerts,
    } = props.initialValues
    return {
      id,
      type,
      gender,
      // service_category,
      service_name,
      service_details,
      travel_by,
      title,
      description,
      note_models,
      note_internal,
      ...getDepartureDefaultValues(date_start, time_start, departure_place),
      ...getArrivalDefaultValues(date_end, time_end, arrival_place),
      ...getModelsDefaultValues(fashion_models),
      ...getAlertDefaultValues(alerts),
    }
  }, [props.initialValues])

  const methods = useForm({
    defaultValues: {
      travel_by: null,
      ...getServiceDefaultValues(),
      ...getTitleInfoDefaultValues(),
      ...getNotesDefaultValues(),
      ...defaultValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const { control, handleSubmit, setError } = methods

  const onSubmit = values => {
    const {
      departure_date,
      departure_time,
      departure_place,
      arrival_date,
      arrival_time,
      arrival_place,
      ...restValues
    } = values
    const params = {
      ...restValues,
      date_start: moment(departure_date).format("YYYY-MM-DD"),
      time_start: departure_time
        ? moment(departure_time).format("HH:mm")
        : null,
      departure_place: departure_place || null,
      date_end: moment(arrival_date).format("YYYY-MM-DD"),
      time_end: arrival_time ? moment(arrival_time).format("HH:mm") : null,
      arrival_place: arrival_place || null,
      ...mapModelsSubmitValues(values.fashion_models),
      ...mapAlertsSubmitValues(values.alerts),
    }

    props.onSubmit(params, { setError })
  }

  const isDisabled = useMemo(() => {
    return props.loading
  }, [props.loading])

  return (
    <FormProvider {...methods}>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <ServiceSection />

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          <Col md={6}>
            <ControllerHF
              name={"travel_by"}
              control={control}
              component={ComboboxFormField}
              id={"travel_by"}
              label={t("travel_by")}
              placeholder={t("travel_by")}
              options={travelOptions}
              selectProps={{
                isClearable: true,
              }}
              normalize={option => (option ? option.value : null)}
              isDetermineValue
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <DepartureSection />

        <ArrivalSection />

        <hr className="drawer-separator drawer-separator_form" />

        <TitleInfoSection />

        <hr className="drawer-separator drawer-separator_form" />

        <ModelsSection
          isEdit={props.isEditMode}
          eventId={props.initialValues.id}
          eventType={props.eventType}
          modelActionBar={{
            cancel: props.isEditMode,
          }}
          onEventUpdate={props.onEventUpdate}
          entity={props.entity}
          entityId={props.entityId}
        />

        <hr className="drawer-separator drawer-separator_form" />

        <NotesSection
          config={{
            modelNote: {
              label: t("notes_for_model"),
              placeholder: t("notes_for_model"),
            },
            internalNote: {
              label: t("notes_for_agency"),
              placeholder: t("notes_for_agency"),
            },
          }}
        />

        <hr className="drawer-separator drawer-separator_form" />

        <AlertSection />

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={isDisabled}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

EventTravelForm.propTypes = {
  isEditMode: PropTypes.bool,
  eventType: PropTypes.string,
  initialValues: PropTypes.object,
  error: PropTypes.any,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onEventUpdate: PropTypes.func,
  entity: PropTypes.string,
  entityId: PropTypes.number,
}
