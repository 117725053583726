import * as Yup from "yup"

import { BILLING_PAYMENT_METHOD_TYPE } from "consts"
import { PAYMENT_METHOD_VIEW } from "./consts"

const { CARD, SEPA_DEBIT } = BILLING_PAYMENT_METHOD_TYPE
const { SELECT, NEW } = PAYMENT_METHOD_VIEW

export const validationSchema = paymentType =>
  Yup.object().shape({
    card: Yup.mixed().when("payment_method_type", {
      is: payment_method_type =>
        payment_method_type === CARD && paymentType === SELECT,
      then: Yup.mixed().required("field.error.required"),
    }),
    iban: Yup.mixed().when("payment_method_type", {
      is: payment_method_type =>
        payment_method_type === SEPA_DEBIT && paymentType === SELECT,
      then: Yup.mixed().required("field.error.required"),
    }),
    name: Yup.string()
      .nullable()
      .when("payment_method_type", {
        is: payment_method_type =>
          payment_method_type === SEPA_DEBIT && paymentType === NEW,
        then: Yup.string().nullable().required("field.error.required"),
      }),
    email: Yup.string()
      .email("field.error.invalid")
      .nullable()
      .when("payment_method_type", {
        is: payment_method_type =>
          payment_method_type === SEPA_DEBIT && paymentType === NEW,
        then: Yup.string()
          .email("field.error.invalid")
          .nullable()
          .required("field.error.required"),
      }),
    address: Yup.string()
      .nullable()
      .when("payment_method_type", {
        is: payment_method_type =>
          payment_method_type === SEPA_DEBIT && paymentType === NEW,
        then: Yup.string().nullable().required("field.error.required"),
      }),
  })
