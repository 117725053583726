import { all } from "redux-saga/effects"

import dataSaga from "./data/saga"
import editSaga from "./edit/saga"

function* settingsSaga() {
  yield all([dataSaga(), editSaga()])
}

export default settingsSaga
