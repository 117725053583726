import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import { IconButton, TooltipWrapper } from "components"

export const CancelControl = ({ loading, ...props }) => {
  const { t } = useTranslation()

  const domElementId = `event-model-action-cancel-${props.modelId}`

  const onChange = () => {
    const payload = { active: !props.status }

    if (props.eventId) {
      props.onSubmit("active", payload)
    } else {
      props.onChange(payload)
    }
  }

  return (
    <TooltipWrapper
      target={domElementId}
      content={t(props.status ? "cancel" : "uncancel")}
    >
      <IconButton
        id={domElementId}
        onClick={onChange}
        disabled={loading}
        wrapClass={classNames("font-size-20", {
          "text-primary": props.status,
          "text-success": !props.status,
        })}
        icon={props.status ? "bx bx-minus-circle" : "bx bx-plus-circle"}
      />
    </TooltipWrapper>
  )
}

CancelControl.propTypes = {
  eventId: PropTypes.number,
  modelId: PropTypes.number,
  status: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
}
