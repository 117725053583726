import * as Yup from "yup"

import { ValidationsUtils, EditorUtils } from "utils"
import { FILE_MAX_SIZE } from "consts"
import { TABS_KEY } from "components/FormSections/DocumentTextEntryControlPickerFormSection"

export const validationSchema = Yup.object().shape({
  logo: Yup.mixed()
    .nullable()
    .test("maxSize", "field.error.file-size.max", value =>
      ValidationsUtils.fileMaxSize(value, FILE_MAX_SIZE)
    ),
  header_text: Yup.string().nullable().required("field.error.required"),
  client_information: Yup.string().nullable().required("field.error.required"),
  city: Yup.string().nullable().required("field.error.required"),
  contract_date: Yup.date().nullable().required("field.error.required"),
  file: Yup.mixed()
    .nullable(true)
    .when("type", {
      is: type => type === TABS_KEY.FILE,
      then: Yup.mixed().nullable(true).required("field.error.required"),
    })
    .test("maxSize", "field.error.file-size.max", value =>
      ValidationsUtils.fileMaxSize(value?.file, FILE_MAX_SIZE)
    ),
  contract_text: Yup.mixed()
    .nullable(true)
    .when("type", {
      is: type => type === TABS_KEY.TEXT,
      then: Yup.mixed()
        .nullable(true)
        .test(
          "draft-editor-required",
          "field.error.required",
          value => !EditorUtils.draftJs.isContentEmpty(value)
        ),
    }),
  footer_text: Yup.string().nullable().required("field.error.required"),
})
