import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { EventCalendarIcon } from "components"

export const Date = ({ data = {}, ...props }) => {
  const { date_start, date_end, is_closed } = data

  const start = moment(date_start).format("DD/MM/YYYY")
  const end = moment(date_end).format("DD/MM/YYYY")

  return (
    <>
      <div className={"d-flex align-items-center"}>
        {start} - {end}
        {is_closed && (
          <EventCalendarIcon name={"closed"} className={"ms-2 font-size-20"} />
        )}
      </div>
    </>
  )
}

Date.propTypes = {
  data: PropTypes.object,
}
