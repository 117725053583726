import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { SelectedListControl } from "components"
import { useFetchData } from "hooks"
import { API } from "api"
import * as HOC from "HOC"

const DataShower = HOC.withDataShowerOverlay()

export const ModelsSection = ({ agencyId, ...props }) => {
  const { t } = useTranslation()

  const { fetchData, loading, error, data } = useFetchData()

  const onFetch = async () => {
    const params = {
      pagination: false,
      filters: {
        mother_agencies: [agencyId],
      },
    }

    const request = API.model.getModelList(params)
    await fetchData({
      request,
    })
  }

  useEffect(() => {
    onFetch()
  }, [])

  const renderItemContent = item => (
    <div className="d-flex">
      <div className={"float-left align-items-center me-2"}>
        <div className={"mark-dot-pointer pos-t_d_2"} />
      </div>

      <div className={"selected-list-control__item-text"}>
        {item.name} {item.surname}
      </div>
    </div>
  )

  return (
    <>
      <h4 className={"mb-4"}>{t("models")}</h4>

      <div>
        <DataShower isLoading={loading} isFailed={error} error={error?.message}>
          <SelectedListControl
            items={data?.data || []}
            remove={false}
            renderItemContent={renderItemContent}
          />
        </DataShower>
      </div>
    </>
  )
}

ModelsSection.propTypes = {
  agencyId: PropTypes.number,
}
