export const CLIENT_FAMILY_TYPE_ADVERTORIAL = "advertorial"
export const CLIENT_FAMILY_TYPE_CALLBACK = "callback"
export const CLIENT_FAMILY_TYPE_CAMPAIGN = "campaign"
export const CLIENT_FAMILY_TYPE_CATALOGUE = "catalogue"
export const CLIENT_FAMILY_TYPE_COMMERCIAL = "commercial"
export const CLIENT_FAMILY_TYPE_DIGITAL_CAMPAIGN = "digital_campaign"
export const CLIENT_FAMILY_TYPE_DIGITAL_SHOOTING = "digital_shooting"
export const CLIENT_FAMILY_TYPE_EDITORIAL = "editorial"
export const CLIENT_FAMILY_TYPE_ECOMMERCE = "e_commerce"
export const CLIENT_FAMILY_TYPE_FITTING = "fitting"
export const CLIENT_FAMILY_TYPE_FREE_TEST = "free_test"
export const CLIENT_FAMILY_TYPE_HAIR_AND_MAKEUP = "hair_and_make_up"
export const CLIENT_FAMILY_TYPE_INTERNAL_VIDEO = "internal_video"
export const CLIENT_FAMILY_TYPE_INTERNET = "internet"
export const CLIENT_FAMILY_TYPE_LOOKS = "looks"
export const CLIENT_FAMILY_TYPE_LOOKBOOK = "lookbook"
export const CLIENT_FAMILY_TYPE_ON_REQUEST = "on_request"
export const CLIENT_FAMILY_TYPE_OPEN = "open"
export const CLIENT_FAMILY_TYPE_POP = "pop"
export const CLIENT_FAMILY_TYPE_POSTER = "poster"
export const CLIENT_FAMILY_TYPE_PRESENTATION = "presentation"
export const CLIENT_FAMILY_TYPE_PRESS_RELEASE = "press_release"
export const CLIENT_FAMILY_TYPE_REHEARSAL = "rehearsal"
export const CLIENT_FAMILY_TYPE_SHOWROOM = "showroom"
export const CLIENT_FAMILY_TYPE_STANDING_SHOW = "standing_show"
export const CLIENT_FAMILY_TYPE_SHOW = "show"
export const CLIENT_FAMILY_TYPE_TEST = "test"
export const CLIENT_FAMILY_TYPE_TV = "tv"
export const CLIENT_FAMILY_TYPE_VIDEOCLIP = "videoclip"
