import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { ContactsInfoCard } from "./ContactsInfoCard"
import { EditContacts } from "./EditContacts"
import { ConfirmPopup } from "../../../../../components"
import { AddContact } from "./AddContact"
import {
  clientContactsList,
  clientRemoveContact,
} from "../../../../../store/actions"

const ContactsContainerComponent = props => {
  const dispatch = useDispatch()

  const removeModalRef = useRef(null)
  const [removeContact, setRemoveContact] = useState(null)

  const handleRemove = item => {
    setRemoveContact(item)
    if (removeModalRef.current) {
      removeModalRef.current.show()
    }
  }
  const resetRemove = () => {
    setRemoveContact(null)
  }

  const onRemoveSuccess = () => {
    fetchData()
  }
  const confirmRemove = () => {
    dispatch(
      clientRemoveContact.remove({
        id: removeContact.id,
        onSuccess: onRemoveSuccess,
      })
    )
  }

  const [addDrawerVisible, setAddDrawerVisible] = useState(false)
  const [editDrawerVisible, setEditDrawerVisible] = useState(false)
  const [contactEdit, setContactEdit] = useState(null)
  const handleAdd = () => {
    setAddDrawerVisible(true)
  }

  const closeAddDrawer = () => {
    setAddDrawerVisible(false)
  }

  const handleEdit = item => {
    setContactEdit(item)
    setEditDrawerVisible(true)
  }

  const closeEditDrawer = () => {
    setEditDrawerVisible(false)
  }
  const handleEditSuccess = () => {
    fetchData()
  }
  const handleAddSuccess = () => {
    fetchData()
  }
  const editDrawerDestroy = () => {
    setContactEdit(null)
  }
  const fetchData = () => {
    let params = {
      page: props.page,
      per_page: 6,
    }
    dispatch(clientContactsList.getList({ id: props.client.id, params }))
  }

  const handlePageChange = item => {
    dispatch(clientContactsList.setPage(item.selected + 1))
  }
  useEffect(() => {
    fetchData()
  }, [props.page])

  useEffect(() => {
    return () => {
      dispatch(clientContactsList.cleanState())
      dispatch(clientRemoveContact.cleanState())
    }
  }, [])

  return (
    <>
      <ContactsInfoCard
        addMode
        onAdd={handleAdd}
        onEdit={handleEdit}
        onRemove={handleRemove}
        onPageChange={handlePageChange}
        error={props.listError}
        loading={props.listLoading}
        meta={props.meta}
        items={props.list}
        removeLoading={props.removeLoading}
      />
      <AddContact
        visible={addDrawerVisible}
        onClose={closeAddDrawer}
        onAddSuccess={handleAddSuccess}
      />
      <EditContacts
        visible={editDrawerVisible}
        onClose={closeEditDrawer}
        onEditSuccess={handleEditSuccess}
        id={contactEdit?.id}
        onDestroy={editDrawerDestroy}
      />
      <ConfirmPopup
        ref={removeModalRef}
        onConfirm={confirmRemove}
        onClosed={resetRemove}
      />
    </>
  )
}

ContactsContainerComponent.propTypes = {
  client: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  page: PropTypes.any,
  removeLoading: PropTypes.bool,
  error: PropTypes.object,
  meta: PropTypes.any,
}

const mapStateToProps = state => {
  const { data } = state.client.profile.data
  const { list, listLoading, listError, meta, page } =
    state.client.contacts.list
  const { loading } = state.client.contacts.remove
  return {
    client: data,
    removeLoading: loading,
    list,
    listLoading,
    listError,
    meta,
    page,
  }
}

export const ContactsContainer = connect(mapStateToProps)(
  ContactsContainerComponent
)
