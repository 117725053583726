import {
  SET_STATE,
  RESET_STATE,
  CLEAN_STATE,
  SET_INVOICE,
  RESET_INVOICE,
} from "./actionTypes"

export const setState = data => {
  return {
    type: SET_STATE,
    payload: data,
  }
}

export const resetState = () => {
  return { type: RESET_STATE }
}

export const cleanState = () => {
  return { type: CLEAN_STATE }
}

export const setInvoice = data => {
  return {
    type: SET_INVOICE,
    payload: data,
  }
}

export const resetInvoice = () => {
  return { type: RESET_INVOICE }
}
