import {
    PASSWORD_RESET,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_ERROR,
    PASSWORD_RESET_CLEAN_STATE,
} from './actionTypes';

const initialState = {
    loading: false,
    resetSuccessMsg: null,
    resetError: null,
};

const passwordReset = (state = initialState, action) => {
    switch (action.type) {
        case PASSWORD_RESET:
            state = {
                ...state,
                loading: true,
                resetSuccessMsg: null,
                resetError: null,
            };
            break;

        case PASSWORD_RESET_SUCCESS:
            state = {
                ...state,
                loading: false,
                resetSuccessMsg: action.payload,
            };
            break;

        case PASSWORD_RESET_ERROR:
            state = {
                ...state,
                loading: false,
                resetError: action.payload,
            };
            break;

        case PASSWORD_RESET_CLEAN_STATE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default passwordReset;