import React from "react"
import moment from "moment"

import i18n from "i18n"
import {
  EVENT_INFO_DETAILS_ALIAS,
  EVENT_TYPE_ACCOMMODATION,
  EVENT_TYPE_ARRIVAL,
  EVENT_TYPE_DEPARTURE,
  EVENT_TYPE_TRAVEL,
} from "consts"
import { eventsCalendarHelper } from "helpers/eventsCalendar"
import { modelHelper } from "helpers/model"
import { clientHelper } from "helpers/client"

const alias = EVENT_INFO_DETAILS_ALIAS

export const getEventInfoData = (data, entityScreen, entityId) => {
  const [dateStart, dateEnd, timeStart, timeEnd] = [
    data.date_start ? moment(data.date_start).format("DD/MM/YYYY") : null,
    data.date_end ? moment(data.date_end).format("DD/MM/YYYY") : null,
    data.time_start ? moment(data.time_start, "HH:mm").format("HH:mm") : "",
    data.time_end ? moment(data.time_end, "HH:mm").format("HH:mm") : "",
  ]

  const isOtherCategoryEvent =
    eventsCalendarHelper.eventConfig.isOtherCategoryEvent(data?.type)

  const getEventTypeTitle = event => {
    return eventsCalendarHelper.calendar.getGeneralEventTypeTitle(
      event,
      entityScreen,
      entityId
    )
  }

  return [
    {
      alias: alias.TYPE,
      label: i18n.t("type"),
      value: getEventTypeTitle(data),
    },
    {
      alias: alias.DIRECT_BOOKING,
      label: i18n.t("direct_booking"),
      value: data.is_direct ? i18n.t("yes") : i18n.t("no"),
      hidden: !data.is_direct,
    },
    {
      alias: alias.DATE,
      label: i18n.t("date"),
      value: `${dateStart} - ${dateEnd}`,
      hidden: isOtherCategoryEvent,
    },
    {
      alias: alias.PERIOD,
      label: i18n.t("period"),
      value: (
        <div>
          {dateStart} {timeStart}
          {" - "}
          {dateEnd} {timeEnd}
        </div>
      ),
      hidden: ![EVENT_TYPE_ACCOMMODATION].includes(data.type),
    },
    {
      alias: alias.DEPARTURE,
      label: i18n.t("departure"),
      value: (
        <div>
          <div>
            {dateStart} {timeStart}{" "}
            {data.departure_place && <>({data.departure_place})</>}
          </div>
        </div>
      ),
      hidden: ![EVENT_TYPE_DEPARTURE, EVENT_TYPE_TRAVEL].includes(data.type),
    },
    {
      alias: alias.ARRIVAL,
      label: i18n.t("arrival"),
      value: (
        <div>
          <div>
            {data.type === EVENT_TYPE_ARRIVAL
              ? dateStart + " " + timeStart
              : dateEnd + " " + timeEnd}{" "}
            {data.arrival_place && <>({data.arrival_place})</>}
          </div>
        </div>
      ),
      hidden: ![EVENT_TYPE_ARRIVAL, EVENT_TYPE_TRAVEL].includes(data.type),
    },
    {
      alias: alias.TIME,
      label: i18n.t("time"),
      value:
        data.times &&
        data.times.map((item, index) => (
          <div key={index}>
            {item.start} {item.end ? `- ${item.end}` : null}
          </div>
        )),
      hidden: !data.times || data.times?.length === 0,
    },
    {
      alias: alias.GENDER,
      label: i18n.t("department"),
      value: modelHelper.general.getGender(data.gender)?.label,
      hidden: !data.gender,
    },
    {
      alias: alias.SERVICE_CATEGORY,
      label: i18n.t("service_category"),
      value: modelHelper.service.getServiceCategory(data.service_category)
        ?.label,
      hidden: true, // !data.service_category,
    },
    {
      alias: alias.SERVICE_NAME,
      label: i18n.t("service_name"),
      value: data.service_name,
      hidden: !data.service_name,
    },
    {
      alias: alias.SERVICE_DETAILS,
      label: i18n.t("service_details"),
      value: data.service_details,
      hidden: !data.service_details,
    },
    {
      alias: alias.TRAVEL_BY,
      label: i18n.t("travel_by"),
      value: modelHelper.travel.getTravelByOption(data.travel_by)?.label,
      hidden: !data.travel_by,
    },
    {
      alias: alias.TITLE,
      label: i18n.t("title"),
      value: data.title,
      hidden: !data.title,
    },
    {
      alias: alias.DESCRIPTION,
      label: i18n.t("description"),
      value: data.description,
      hidden: !data.description,
    },
    {
      alias: alias.CLIENT,
      label: i18n.t("client"),
      value: data.client?.name,
      hidden: !data.client,
    },
    {
      alias: alias.FAMILY,
      label: i18n.t("family"),
      value: clientHelper.family.getFamily(data.family)?.label,
      hidden: !data.family,
    },
    {
      alias: alias.PRODUCT,
      label: i18n.t("product"),
      value: data.product,
      hidden: !data.product,
    },
    {
      alias: alias.AGENCY,
      label: i18n.t("agency"),
      value: data.agency?.name,
      hidden: !data.agency,
    },
    {
      alias: alias.NOTE_FOR_MODEL,
      label:
        data?.type === EVENT_TYPE_TRAVEL
          ? i18n.t("notes_for_model")
          : i18n.t("note_models"),
      value: data.note_models,
      hidden: !data.note_models,
    },
    {
      alias: alias.NOTE_FOR_AGENCY,
      label:
        data?.type === EVENT_TYPE_TRAVEL
          ? i18n.t("notes_for_agency")
          : i18n.t("note_internal"),
      value: data.note_internal,
      hidden: !data.note_internal,
    },
  ]
}

export const getOtherEventsInfoData = data => {
  return getEventInfoData(data).filter(infoItem => {
    if (
      [
        alias.PERIOD,
        alias.SERVICE_NAME,
        alias.SERVICE_DETAILS,
        alias.DEPARTURE,
        alias.ARRIVAL,
        alias.NOTE_FOR_AGENCY,
        alias.NOTE_FOR_MODEL,
        alias.TRAVEL_BY,
      ].includes(infoItem.alias)
    ) {
      return infoItem
    }
  })
}
