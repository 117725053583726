import { NormalizeUtils } from "utils"
import { getFeatureNaturalnessOptions } from "./general"
import { MEASUREMENT_SYSTEM_METRIC } from "consts"

const featureNaturalnessOptions = getFeatureNaturalnessOptions().map(item => ({
  ...item,
  text: item.label,
}))

export const featuresFormInitialValues = {
  height: null,
  height_public: null,
  bust: null,
  bust_public: null,
  waist: null,
  waist_public: null,
  hips: null,
  hips_public: null,
  shoes: null,
  shoes_public: null,

  dress_size: null,
  shoulders: null,
  trousers: null,
  head: null,
  neck: null,
  cup: null,
  weight: "",

  hair_color: null,
  hair_length: null,
  eyes_color: null,
  breast: null,
  hands: false,
  feet: false,
  tatoos: false,
  ears_pierced: false,
  lips: null,
  piercing: "",
  scars: "",

  surfing: false,
  dancing: false,
  swimming: false,
  water_skiing: false,
  horse_riding: false,
  skating: false,
  tennis: false,
  skiing: false,
  driving: false,
  singing: false,
  acting: false,
  alcool: false,
  smoke: false,
  fur: false,
  lingerie: false,
  nude: false,
  topless: false,
  swimsuit: false,
  special_skills: "",
  sports: "",
  languages: "",

  vegetarian: false,
  vegan: false,
  allergies: "",
}

export const getFeaturesFormFieldsSection1 = fieldsOptions => [
  [
    {
      name: "height",
      label: "height",
      placeholder: "height",
      options: fieldsOptions.height,
      fieldType: "combobox",
    },
    {
      name: "height_public",
      label: "public-height",
      placeholder: "public-height",
      options: fieldsOptions.height,
      fieldType: "combobox",
    },
  ],
  [
    {
      name: "bust",
      label: "bust",
      placeholder: "bust",
      options: fieldsOptions.other_length,
      fieldType: "combobox",
    },
    {
      name: "bust_public",
      label: "public-bust",
      placeholder: "public-bust",
      options: fieldsOptions.other_length,
      fieldType: "combobox",
    },
  ],
  [
    {
      name: "waist",
      label: "waist",
      placeholder: "waist",
      options: fieldsOptions.other_length,
      fieldType: "combobox",
    },
    {
      name: "waist_public",
      label: "public-waist",
      placeholder: "public-waist",
      options: fieldsOptions.other_length,
      fieldType: "combobox",
    },
  ],
  [
    {
      name: "hips",
      label: "hips",
      placeholder: "hips",
      options: fieldsOptions.other_length,
      fieldType: "combobox",
    },
    {
      name: "hips_public",
      label: "public-hips",
      placeholder: "public-hips",
      options: fieldsOptions.other_length,
      fieldType: "combobox",
    },
  ],
  [
    {
      name: "shoes",
      label: "shoes",
      placeholder: "shoes",
      options: fieldsOptions.shoes,
      fieldType: "combobox",
    },
    {
      name: "shoes_public",
      label: "public-shoes",
      placeholder: "public-shoes",
      options: fieldsOptions.shoes,
      fieldType: "combobox",
    },
  ],
]

export const getFeaturesFormFieldsSection2 = (
  fieldsOptions,
  measurementSystem
) => [
  {
    name: "dress_size",
    label: "dress_size",
    placeholder: "dress_size",
    options: fieldsOptions.dress_size,
    fieldType: "combobox",
  },
  {
    name: "shoulders",
    label: "shoulders",
    placeholder: "shoulders",
    options: fieldsOptions.other_length,
    fieldType: "combobox",
  },
  {
    name: "trousers",
    label: "trousers",
    placeholder: "trousers",
    options: fieldsOptions.other_length,
    fieldType: "combobox",
  },
  {
    name: "head",
    label: "model.head",
    placeholder: "model.head",
    options: fieldsOptions.other_length,
    fieldType: "combobox",
  },
  {
    name: "neck",
    label: "model.neck",
    placeholder: "model.neck",
    options: fieldsOptions.other_length,
    fieldType: "combobox",
  },
  {
    name: "cup",
    label: "model.cup",
    placeholder: "model.cup",
    options: fieldsOptions.cup,
    fieldType: "combobox",
  },
  {
    name: "weight",
    label: "weight",
    placeholder: "weight",
    fieldType: "input",
    type: "text",
    normalize: value =>
      measurementSystem === MEASUREMENT_SYSTEM_METRIC
        ? NormalizeUtils.onlyDecimal(value, 3)
        : NormalizeUtils.onlyNumbers(value),
    translateParams: {
      min: weightValidValue(measurementSystem).min,
      max: weightValidValue(measurementSystem).max,
    },
  },
]

export const weightValidValue = measurementSystem => ({
  min: measurementSystem === MEASUREMENT_SYSTEM_METRIC ? 30 : 100,
  max: measurementSystem === MEASUREMENT_SYSTEM_METRIC ? 100 : 200,
})

export const getFeaturesFormFieldsSection3 = fieldsOptions => [
  {
    name: "hair_color",
    label: "hair-color",
    placeholder: "hair-color",
    options: fieldsOptions.hair_color,
    fieldType: "combobox",
  },
  {
    name: "hair_length",
    label: "hair-length",
    placeholder: "hair-length",
    options: fieldsOptions.hair_length,
    fieldType: "combobox",
  },
  {
    name: "eyes_color",
    label: "eyes-color",
    placeholder: "eyes-color",
    options: fieldsOptions.eyes_color,
    fieldType: "combobox",
  },
  {
    name: "breast",
    label: "breast",
    placeholder: "breast",
    options: featureNaturalnessOptions,
    fieldType: "combobox",
    normalize: option => (option ? option.value : null),
    isDetermineValue: true,
    isSearchable: false,
  },
  {
    name: "hands",
    label: "hand",
    fieldType: "checkbox",
    wrapClass: "mb-3 mt-3",
  },
  {
    name: "feet",
    label: "feet",
    fieldType: "checkbox",
    wrapClass: "mb-3 mt-3",
  },
  {
    name: "tatoos",
    label: "tatoos",
    fieldType: "checkbox",
    wrapClass: "mb-3 mt-3",
  },
  {
    name: "ears_pierced",
    label: "ears_pierced",
    fieldType: "checkbox",
    wrapClass: "mb-3 mt-3",
  },
  {
    name: "lips",
    label: "lips",
    placeholder: "lips",
    options: featureNaturalnessOptions,
    fieldType: "combobox",
    normalize: option => (option ? option.value : null),
    isDetermineValue: true,
    isSearchable: false,
  },
]

export const getFeaturesFormFieldsSection4 = () => [
  {
    name: "piercing",
    label: "piercing",
    placeholder: "piercing",
    fieldType: "input",
    type: "textarea",
  },
  {
    name: "scars",
    label: "scars",
    placeholder: "scars",
    fieldType: "input",
    type: "textarea",
  },
]

export const getFeaturesFormFieldsSection5 = () => [
  {
    name: "surfing",
    label: "surfing",
    fieldType: "checkbox",
  },
  {
    name: "dancing",
    label: "dancing",
    fieldType: "checkbox",
  },
  {
    name: "swimming",
    label: "swimming",
    fieldType: "checkbox",
  },
  {
    name: "water_skiing",
    label: "water-skiing",
    fieldType: "checkbox",
  },
  {
    name: "horse_riding",
    label: "horse-riding",
    fieldType: "checkbox",
  },
  {
    name: "skating",
    label: "skating",
    fieldType: "checkbox",
  },
  {
    name: "tennis",
    label: "tennis",
    fieldType: "checkbox",
  },
  {
    name: "skiing",
    label: "skiing",
    fieldType: "checkbox",
  },
  {
    name: "driving",
    label: "driving",
    fieldType: "checkbox",
  },
  {
    name: "singing",
    label: "singing",
    fieldType: "checkbox",
  },
  {
    name: "acting",
    label: "acting",
    fieldType: "checkbox",
  },
  {
    name: "alcool",
    label: "alcool",
    fieldType: "checkbox",
  },
  {
    name: "smoke",
    label: "smoke",
    fieldType: "checkbox",
  },
  {
    name: "fur",
    label: "fur",
    fieldType: "checkbox",
  },
  {
    name: "lingerie",
    label: "lingerie",
    fieldType: "checkbox",
  },
  {
    name: "nude",
    label: "nude",
    fieldType: "checkbox",
  },
  {
    name: "topless",
    label: "topless",
    fieldType: "checkbox",
  },
  {
    name: "swimsuit",
    label: "swimsuit",
    fieldType: "checkbox",
  },
]

export const getFeaturesFormFieldsSection6 = () => [
  {
    name: "special_skills",
    label: "special-skills",
    placeholder: "special-skills",
    fieldType: "input",
    type: "textarea",
  },
  {
    name: "sports",
    label: "sports",
    placeholder: "sports",
    fieldType: "input",
    type: "textarea",
  },
  {
    name: "languages",
    label: "languages",
    placeholder: "languages",
    fieldType: "input",
    type: "textarea",
  },
]

export const getFeaturesFormFieldsSection7 = () => [
  {
    name: "vegetarian",
    label: "vegetarian",
    fieldType: "checkbox",
  },
  {
    name: "vegan",
    label: "vegan",
    fieldType: "checkbox",
  },
]

export const getFeaturesFormFieldsSection8 = () => [
  {
    name: "allergies",
    label: "allergies",
    placeholder: "allergies",
    fieldType: "input",
    type: "textarea",
  },
]
