import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import _ from "lodash"

import i18n from "../../../../../i18n"

import { MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTOS } from "./actionTypes"
import {
  modelPanelPhotosListRemovePhotosSuccess,
  modelPanelPhotosListRemovePhotosError,
} from "./actions"
import { modelProfileUpdateData } from "../../../../model/profile/actions"

import { API } from "../../../../../api"

function* removePhotos({ payload: { photos, onSuccess, onError } }) {
  try {
    const ids = photos.map(i => i.id)

    const response = yield call(API.model.removeModelPhotos, ids)

    const { data } = response

    yield put(modelPanelPhotosListRemovePhotosSuccess())
    toastr.success(i18n.t("model-photos.remove.success"))

    if (onSuccess) {
      onSuccess()
    }

    const avatarPhoto = _.find(photos, { is_avatar: true })
    if (avatarPhoto) {
      yield put(modelProfileUpdateData({ avatar: null, crops: null }))
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      modelPanelPhotosListRemovePhotosError({
        errors,
        message,
      })
    )

    toastr.error(message)

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchRemovePhotos() {
  yield takeEvery(MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTOS, removePhotos)
}

function* removePhotosSaga() {
  yield all([fork(watchRemovePhotos)])
}

export default removePhotosSaga
