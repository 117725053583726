import { combineReducers } from "redux"

import add from "./add/slice"
import remove from "./remove/slice"

const profileReducer = combineReducers({
    add,
    remove,
})

export default profileReducer