import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { agencyContactUploadFile } from "./slice"
import i18n from "../../../../../i18n"
import { API } from "../../../../../api"

function* uploadFile({ payload: { params, onSuccess, onError } }) {
    try {
        const { id, file } = params
        const response = id
            ? yield call(API.agency.uploadFileForContact, id, { file })
            : yield call(API.agency.uploadFileForNewContact, { file })

        const { data } = response

        yield put(agencyContactUploadFile.uploadCompleted())

        if (onSuccess) {
            onSuccess(data?.data)
        }
    } catch (error) {
        const errors = error.response?.data?.errors || {}
        const message = error.response?.data?.message || i18n.t("error")

        yield put(agencyContactUploadFile.uploadRejected(message))

        if (onError) {
            onError(errors)
        }
    }
}

export function* watchUploadFile() {
    yield takeEvery(agencyContactUploadFile.upload, uploadFile)
}

function* uploadSaga() {
    yield all([fork(watchUploadFile)])
}

export default uploadSaga