import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { connect, useDispatch } from "react-redux"

import { EntryForm } from "./EntryForm"
import { chatConversationMessagesSend } from "store/actions"
import {
  selectConversationGroupChatId,
  selectConversationIsGroup,
  selectConversationParticipantId,
} from "store/selectors"

const EntryToolbarComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(chatConversationMessagesSend.cleanState())
    }
  }, [props.participantId, props.groupChatId])

  const onSubmitSuccess = onSubmitProps => () => {
    const { onSuccess } = onSubmitProps
    onSuccess()
  }

  const onSubmit = (params, onSubmitProps) => {
    dispatch(
      chatConversationMessagesSend.send({
        userId: props.participantId,
        chatId: props.groupChatId,
        isGroup: props.isGroup,
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
      })
    )
  }

  return (
    <div className={"chat-room__entry-toolbar"}>
      <EntryForm
        participantId={props.participantId}
        groupChatId={props.groupChatId}
        loading={props.loading}
        error={props.error}
        disabled={props.messagesLoading}
        onSubmit={onSubmit}
      />
    </div>
  )
}

EntryToolbarComponent.propTypes = {
  participantId: PropTypes.number,
  groupChatId: PropTypes.number,
  isGroup: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.any,
  messagesLoading: PropTypes.bool,
}

const mapStateToProps = state => {
  const { loading, error } = state.chat.conversation.messages.send
  const { listLoading: messagesLoading } = state.chat.conversation.messages.list

  return {
    participantId: selectConversationParticipantId(state),
    groupChatId: selectConversationGroupChatId(state),
    isGroup: selectConversationIsGroup(state),
    loading,
    error,
    messagesLoading,
  }
}

export const EntryToolbar = connect(mapStateToProps)(EntryToolbarComponent)
