import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import placeholder from "assets/images/dev/avatar-placeholder.png"

export const Members = ({ participants, admin }) => {
  const { t } = useTranslation()

  return (
    <div className={"chat-members-section"}>
      <h5 className={"mb-2"}>{t("members")}</h5>

      <div className={"chat-members__block"}>
        {participants.map(item => (
          <div key={item.id} className={"chat-members-item"}>
            <div className={"chat-members-item__avatar"}>
              <img
                src={item.avatar ? item.avatar : placeholder}
                className="rounded-circle avatar-xs img-cover"
                alt="avatar"
              />
            </div>
            <div className={"chat-members-item__content"}>
              <div className={"chat-members-item__info"}>{item.name}</div>

              {item?.id === admin?.id && (
                <div className={"chat-members-item__end-block"}>
                  <span className={"text-secondary"}>{t("admin")}</span>
                </div>
              )}
            </div>
          </div>
        ))}
        <div></div>
      </div>
    </div>
  )
}

Members.propTypes = {
  participants: PropTypes.array,
  admin: PropTypes.object,
}
