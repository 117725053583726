import React from 'react';
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap";
import { connect } from 'react-redux';

import { MegaTagTitle } from "../../components";
import { HistoryContainer } from './components/HistoryComponents';

const HistoryComponent = (props) => {
    return (
        <div className={'history-panel'}>
            <MegaTagTitle title={'title.model.history'} />

            <Row>
                <Col>
                    <HistoryContainer />
                </Col>
            </Row>

        </div>
    );
};

HistoryComponent.propTypes = {
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const History = connect(mapStateToProps, mapDispatchToProps)(HistoryComponent);