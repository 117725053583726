import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { connect, useDispatch } from "react-redux"
import moment from "moment"

import { MessagesList } from "./MessagesList"
import { MessageItem } from "./MessageItem"
import { useMessagesScroll } from "../../hooks"
import { chatConversationMessagesList } from "store/actions"
import {
  selectConversationParticipantId,
  selectConversationGroupChatId,
  selectConversationIsGroup,
} from "store/selectors"

const MessagesContainerComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const messagesRef = useRef([])
  const scrollRef = useRef(null)

  // messages scroll logic (scroll to unread message and etc.)
  useMessagesScroll({
    messages: props.list,
    messagesLoading: props.listLoading,
    messagesIsMoreLoading: props.isMoreLoading,
    isGroup: props.isGroup,
    scrollRef,
    messagesRef,
  })

  useEffect(() => {
    fetchData({})

    return () => {
      dispatch(chatConversationMessagesList.cleanState())
    }
  }, [props.participantId, props.groupChatId])

  useEffect(() => {
    // if app set in background
    if (!props.windowFocus) {
      // mark received messages seen
      dispatch(chatConversationMessagesList.markReceivedMessagesSeen())
    }
  }, [props.windowFocus])

  const fetchData = (params = {}, state = {}) => {
    const payload = {
      userId: props.participantId,
      chatId: props.groupChatId,
      isGroup: props.isGroup,
      params,
      state,
    }
    dispatch(chatConversationMessagesList.getList(payload))
  }

  const loadMore = () => {
    const { meta } = props

    if (!meta) {
      return
    }

    const { current_first_id, chat_first_id } = meta

    if (
      current_first_id &&
      chat_first_id &&
      current_first_id !== chat_first_id &&
      !props.listLoading &&
      !props.isMoreLoading
    ) {
      fetchData(
        { last_message_id: meta.current_first_id },
        { isMoreLoading: true }
      )
    }
  }

  const renderMessages = () => {
    if (messagesRef.current) {
      messagesRef.current = []
    }

    const list = [...props.list].reverse()

    let messages = []

    for (let i = 0; i < list.length; i++) {
      let message = list[i]

      let prevMessage = list[i - 1]

      let withDay = true
      if (prevMessage) {
        // check if prev message has day separator
        withDay = !moment(prevMessage.created_at).isSame(
          moment(message.created_at),
          "day"
        )
      }

      // if message not mine and user not seen the message
      let withUnread = !props.isGroup && !message.is_my && !message.seen
      if (prevMessage && withUnread) {
        // check if prev message has unread separator
        if (!prevMessage.is_my) {
          withUnread = prevMessage.seen
        }
      }

      messages.push(
        <MessageItem
          item={message}
          key={`message-${message.id}`}
          day={withDay}
          unread={withUnread}
          avatar={props.isGroup}
          forwardRef={el => (messagesRef.current[message.id] = el)}
        />
      )
    }

    return messages.map(item => item)
  }

  return (
    <div className="chat-conversation p-3">
      <MessagesList
        scrollRef={scrollRef}
        items={props.list}
        loading={props.listLoading}
        isMoreLoading={props.isMoreLoading}
        onLoadMore={loadMore}
        renderMessages={renderMessages}
      />
    </div>
  )
}

MessagesContainerComponent.propTypes = {
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  isMoreLoading: PropTypes.bool,
  meta: PropTypes.object,
  windowFocus: PropTypes.bool,
  participantId: PropTypes.any,
  groupChatId: PropTypes.any,
  isGroup: PropTypes.bool,
}

const mapStateToProps = state => {
  const { list, listLoading, isMoreLoading, meta } =
    state.chat.conversation.messages.list

  return {
    list,
    listLoading,
    isMoreLoading,
    meta,
    windowFocus: state.app.state.windowFocus,
    participantId: selectConversationParticipantId(state),
    groupChatId: selectConversationGroupChatId(state),
    isGroup: selectConversationIsGroup(state),
  }
}

export const MessagesContainer = connect(mapStateToProps)(
  MessagesContainerComponent
)
