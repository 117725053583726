import React from "react"
import PropTypes from "prop-types"
import { UncontrolledTooltip } from "reactstrap"

export const TooltipWrapper = ({ children, target, content, ...props }) => {
  return (
    <>
      {children}

      <UncontrolledTooltip target={target} {...props}>
        {content}
      </UncontrolledTooltip>
    </>
  )
}

TooltipWrapper.propTypes = {
  children: PropTypes.any,
  target: PropTypes.any,
  content: PropTypes.any,
}
