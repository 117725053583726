import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { websiteTemplatesList, websiteUpdateTemplate } from "store/actions"
import { GridDataLoader } from "components"
import { TemplateOverviewCard } from "../TemplateOverviewCard"

const TemplatesContainerComponent = props => {
  const dispatch = useDispatch()
  useEffect(() => {
    fetchList()
    return () => {
      dispatch(websiteTemplatesList.cleanState())
      dispatch(websiteUpdateTemplate.cleanState())
    }
  }, [])
  const fetchList = () => {
    dispatch(websiteTemplatesList.getList())
  }
  const handleUpdate = id => {
    let params = {
      website_theme_id: id,
    }
    dispatch(websiteUpdateTemplate.update({ params }))
  }

  return (
    <GridDataLoader
      className={"website-template-data-grid"}
      loading={props.listLoading}
      error={props.listError}
      items={props.list}
      empty={"website.templates.no-templates"}
      renderItem={item => (
        <TemplateOverviewCard
          item={item}
          currentTheme={props.websiteData?.theme}
          onUpdate={handleUpdate}
          updateLoading={props.updateLoading}
        />
      )}
    />
  )
}

TemplatesContainerComponent.propTypes = {
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  websiteData: PropTypes.object,
  updateLoading: PropTypes.bool,
}

const mapStateToProps = state => {
  const { list, listLoading, listError } = state.website.templates.list
  const { loading: updateLoading } = state.website.templates.update
  const { data: websiteData } = state.website.general.data

  return {
    list,
    listLoading,
    listError,
    websiteData,
    updateLoading,
  }
}

const mapDispatchToProps = {}

export const TemplatesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplatesContainerComponent)
