import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import i18n from '../../../i18n';
import { PROFILE_UPLOAD_AVATAR, PROFILE_REMOVE_AVATAR } from './actionTypes';
import { profileUpdateAvatarSuccess, profileUpdateAvatarError } from './actions';
import { userProfileUpdateData } from '../../user/profile/actions';

import { API } from '../../../api';

function* uploadAvatar({ payload: { params } }) {
    try {
        const response = yield call(API.profile.updateAvatar, params);

        const { data } = response;

        yield put(profileUpdateAvatarSuccess());

        yield put(userProfileUpdateData({ avatar: data.data.src }));
    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : i18n.t('error');

        yield put(profileUpdateAvatarError({
            errors,
            message
        }));
    }
}

function* removeAvatar() {
    try {
        const response = yield call(API.profile.removeAvatar);

        const { data } = response;

        yield put(profileUpdateAvatarSuccess());

        yield put(userProfileUpdateData({ avatar: null }));
    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : i18n.t('error');

        yield put(profileUpdateAvatarError({
            errors,
            message
        }));
    }
}

export function* watchUploadAvatar() {
    yield takeEvery(PROFILE_UPLOAD_AVATAR, uploadAvatar);
}

export function* watchRemoveAvatar() {
    yield takeEvery(PROFILE_REMOVE_AVATAR, removeAvatar);
}

function* avatarSaga() {
    yield all([
        fork(watchUploadAvatar),
        fork(watchRemoveAvatar),
    ]);
}

export default avatarSaga;