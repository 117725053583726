import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Row, Col } from "reactstrap"
import moment from "moment"

import { BillingUtils, FormatUtils } from "utils"

export const Info = ({ data }) => {
  const { t } = useTranslation()

  const renderBillingInfo = (value, label = null) => {
    if (value) {
      return (
        <>
          <span>
            {label ? `${label}: ` : ""}
            {value}
          </span>
          <br />
        </>
      )
    }
    return null
  }

  const renderPaymentMethod = () => {
    const { card_brand, card_last4, sepa_bank_code, sepa_country, sepa_last4 } =
      data

    return card_brand && card_last4 ? (
      <>
        {card_brand && (
          <span className={"text-capitalize"}>{card_brand}&nbsp;</span>
        )}
        {card_last4 ? BillingUtils.paymentCardHiddenNumber(card_last4) : "-"}
      </>
    ) : sepa_bank_code && sepa_country && sepa_last4 ? (
      <>
        {t("sepa")}{" "}
        {BillingUtils.paymentIbanHiddenNumber({
          country: sepa_country,
          bank_code: sepa_bank_code,
          last4: sepa_last4,
        })}
      </>
    ) : (
      "-"
    )
  }

  return (
    <>
      <Row>
        <Col xs="6">
          <address>
            <strong>{t("billed_to")}:</strong>
            <br />
            {renderBillingInfo(data.customer_name)}
            {renderBillingInfo(data.customer_company)}
            {renderBillingInfo(FormatUtils.formatPhone(data.customer_phone))}
            {renderBillingInfo(data.customer_address_city)}
            {renderBillingInfo(data.customer_address_line)}
            {renderBillingInfo(data.customer_address_zip)}
            {renderBillingInfo(data.customer_note)}
            {renderBillingInfo(data.customer_tax_code, t("vat_code"))}
            {renderBillingInfo(data.customer_tax_id, t("vat_id"))}
          </address>
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="mt-3">
          <address>
            <strong>{t("payment_method")}:</strong>
            <br />
            {renderPaymentMethod()}
            <br />
            {data.customer_email}
          </address>
        </Col>
        <Col sm="6" className="mt-3 text-end">
          <address>
            <strong>{t("date")}:</strong>
            <br />
            {moment(data.created_at).format("DD/MM/YYYY")}
            <br />
            <br />
          </address>
        </Col>
      </Row>
    </>
  )
}

Info.propTypes = {
  data: PropTypes.object,
}
