import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next';

import { Modal } from '../../../../../components'
import { PublishBook } from './PublishBook';

const PublishBookModalComponent = (props) => {
    const { t } = useTranslation();
    const modalRef = useRef(null);

    useImperativeHandle(props.parentRef, () => ({
            show() {
                showModal();
            },
            hide() {
                hideModal();
            }
        }),
    );

    const showModal = () => {
        if (modalRef.current) {
            modalRef.current.show();
        }
    }

    const hideModal = () => {
        if (modalRef.current) {
            modalRef.current.hide();
        }
    }

    const handleClose = () => {
        hideModal();
    }

    const onSuccess = () => {
        hideModal();
        props.onSuccess();
    }

    return (
        <Modal
            ref={modalRef}
            title={t('publish_book')}
            size={'sm'}
            strapModalProp={{
                centered: true,
                onClosed: props.onClosed,
            }}
        >
            <PublishBook
                book={props.book}
                onSuccess={onSuccess}
                onCancel={handleClose}
            />
        </Modal>
    );
};

PublishBookModalComponent.propTypes = {
    parentRef: PropTypes.any,
    book: PropTypes.object,
    onSuccess: PropTypes.func,
    onCancel: PropTypes.func,
    onClosed: PropTypes.func,
}

export const PublishBookModal = forwardRef((props, ref) => (
    <PublishBookModalComponent {...props} parentRef={ref} />
));