import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { SCREEN } from "./consts"
import { EventInvoiceCreate } from "../EventInvoiceCreate"
import { EventInvoiceEdit } from "../EventInvoiceEdit"
import { EventInvoiceView } from "../EventInvoiceView"
import { EventInvoiceDrawer } from "../EventInvoiceDrawer"

export const EventInvoiceManager = ({
  state: { screen, event, invoice },
  actions,
  navigation,
  ...props
}) => {
  const { t } = useTranslation()

  const onScreenClose = () => {
    navigation.onScreenClose()
  }

  const openView = () => {
    navigation.onScreenView()
  }

  const resetInvoice = () => {
    actions.resetInvoice()
  }

  return (
    <>
      <EventInvoiceDrawer
        title={t("create_invoice")}
        visible={screen === SCREEN.CREATE}
        onClose={onScreenClose}
      >
        <EventInvoiceCreate
          onSuccess={openView}
          onCancel={onScreenClose}
          eventId={event?.id}
          clientId={event?.client?.id}
        />
      </EventInvoiceDrawer>

      <EventInvoiceEdit
        visible={screen === SCREEN.EDIT}
        onSuccess={openView}
        onDestroy={null}
        onClose={onScreenClose}
        eventId={event?.id}
      />

      <EventInvoiceView
        visible={screen === SCREEN.VIEW}
        onDestroy={null}
        onClose={onScreenClose}
        eventId={event?.id}
      />
    </>
  )
}

EventInvoiceManager.propTypes = {
  state: PropTypes.object,
  actions: PropTypes.object,
  navigation: PropTypes.object,
}
