import React from "react"
import PropTypes from "prop-types"

export const FilterTitle = ({ title }) => {
  return <h6>{title}</h6>
}

FilterTitle.propTypes = {
  title: PropTypes.string,
}
