import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Filters } from "./Filters"
import { ActivitiesTable } from "./ActivitiesTable"

export const ActivitiesEntriesContainer = ({
  filters,
  modelsOptions,
  onFiltersChange,
  modelsFetching,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Filters
        filters={filters}
        modelsOptions={modelsOptions}
        onFiltersChange={onFiltersChange}
        modelsFetching={modelsFetching}
      />

      <ActivitiesTable {...props} />
    </>
  )
}

ActivitiesEntriesContainer.propTypes = {
  filters: PropTypes.object,
  modelsOptions: PropTypes.array,
  onFiltersChange: PropTypes.func,
  modelsFetching: PropTypes.bool,
}
