import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "i18n"
import { API } from "api"
import { removeModelFromGroup } from "./slice"
import { modelGroupsList, modelProfileRemoveGroup } from "store"

function* remove({ payload: { modelId, groupId } }) {
  try {
    const response = yield call(
      API.model.removeModelFromGroup,
      modelId,
      groupId
    )
    const { data } = response
    yield put(removeModelFromGroup.removeCompleted())
    yield put(modelGroupsList.removeItem({ id: groupId }))
    yield put(modelProfileRemoveGroup({ id: groupId }))
    toastr.success(i18n.t("remove-from-group.success"))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      removeModelFromGroup.removeRejected({
        errors,
        message,
      })
    )
    toastr.error(message)
  }
}

export function* watchRemove() {
  yield takeEvery(removeModelFromGroup.remove, remove)
}

function* removeSaga() {
  yield all([fork(watchRemove)])
}

export default removeSaga
