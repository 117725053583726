import React from "react"
import PropTypes from "prop-types"
import { Label } from "reactstrap"
import classNames from "classnames"
import { FieldError } from "components"

export const StripeField = ({
  id,
  label,
  options,
  onChange,
  className = "mb-3",
  state = {},
  component: Component,
  ...props
}) => {
  const { error } = state

  const showError = Boolean(error?.message)

  const renderLabel = label => {
    if (label) {
      if (typeof label === "string") {
        return label
      } else return label
    } else return <></>
  }

  return (
    <div
      className={classNames({
        [className]: className,
      })}
    >
      {label && <Label htmlFor={id}>{renderLabel(label)}</Label>}
      <div
        className={classNames({
          "is-invalid": showError,
        })}
      >
        <Component options={options} onChange={onChange} />
      </div>

      <FieldError error={error?.message} showError={showError} />
    </div>
  )
}

StripeField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.any,
  options: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
  state: PropTypes.object,
  component: PropTypes.any,
}
