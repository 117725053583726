import * as Yup from "yup"

export const validationSchema = Yup.object().shape({
  subject: Yup.string().required("field.error.required"),
  message: Yup.string(),
  emails: Yup.mixed().test(
    "recipient-required",
    "field.error.recipients.empty",
    value => value.length > 0
  ),
})
