import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    loading: false,
    error: null,
}

const agencyContactRemoveFileSlice = createSlice({
    name: "agencyContactRemoveFile",
    initialState,
    reducers: {
        remove(state) {
            state.loading = true
            state.error = null
        },
        removeCompleted(state) {
            state.loading = false
        },
        removeRejected(state, action) {
            state.loading = false
            state.error = action.payload
        },
        cleanState() {
            return initialState
        },
    },
})
/**
 * @namespace
 * @property {function} remove
 *  @property {function} removeCompleted
 *  @property {function} removeRejected
 *  @property {function} cleanState
 */
export const agencyContactRemoveFile = agencyContactRemoveFileSlice.actions

export default agencyContactRemoveFileSlice.reducer