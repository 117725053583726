import React from "react"
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  generatePath,
} from "react-router-dom"
import PropTypes from "prop-types"
import { IntroIndex } from "../Intro"
import { WebsitePanelIndex } from "../WebsitePanel"

export const WebsiteRoutes = props => {
  let { path } = useRouteMatch()
  let conditionPath = props.website?.created ? "panel" : "intro"

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Redirect to={generatePath(`${path}/${conditionPath}`)} />
      </Route>
      <Route path={`${path}/intro`}>
        <IntroIndex website={props.website} />
      </Route>
      <Route path={`${path}/panel`}>
        <WebsitePanelIndex website={props.website} />
      </Route>
      <Route>
        <Redirect to={generatePath(`${path}/${conditionPath}`)} />
      </Route>
    </Switch>
  )
}

WebsiteRoutes.propTypes = {
  website: PropTypes.any,
}
