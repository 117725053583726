import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "../../../../../i18n"

import { MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTO } from "./actionTypes"
import {
  modelPanelPhotosListRemovePhotoSuccess,
  modelPanelPhotosListRemovePhotoError,
} from "./actions"
import { modelProfileUpdateData } from "../../../../model/profile/actions"

import { API } from "../../../../../api"

function* removePhoto({ payload: { photo, onSuccess } }) {
  try {
    const response = yield call(API.model.removeModelPhoto, photo.id)

    const { data } = response

    yield put(modelPanelPhotosListRemovePhotoSuccess(photo))
    toastr.success(i18n.t("model-photo.remove.success"))

    if (onSuccess) {
      onSuccess()
    }

    if (photo.is_avatar) {
      yield put(modelProfileUpdateData({ avatar: null, crops: null }))
    }
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")

    yield put(modelPanelPhotosListRemovePhotoError(photo))

    toastr.error(message)
  }
}

export function* watchRemovePhoto() {
  yield takeEvery(MODEL_PANEL_PHOTOS_LIST_REMOVE_PHOTO, removePhoto)
}

function* removeItemSaga() {
  yield all([fork(watchRemovePhoto)])
}

export default removeItemSaga
