import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Container, Row } from "reactstrap"
import { Drawer } from "components"
import { WorkForm } from "../WorkForm"
import { ValidationsUtils } from "utils"
import { modelUpdateWork } from "store"

const EditWorkComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const handleCloseDrawer = () => {
    props.onClose()
  }

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    props.onClose()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      modelUpdateWork.update({
        id: props.data.id,
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  const onDestroy = () => {
    dispatch(modelUpdateWork.cleanState())
    props.onDestroy()
  }

  return (
    <React.Fragment>
      <Drawer
        title={t("edit-work")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <Container fluid>
          <Row>
            <WorkForm
              initialValues={{
                ...props.data,
              }}
              loading={props.loading}
              error={props.error}
              onSubmit={handleSubmit}
              onCancel={handleCloseDrawer}
            />
          </Row>
        </Container>
      </Drawer>
    </React.Fragment>
  )
}

EditWorkComponent.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
  modelId: PropTypes.number,
  onDestroy: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
}

const mapStateToProps = state => {
  const { loading, error } = state.modelPanel.general.work.update
  return {
    loading,
    error,
  }
}

export const EditWork = connect(mapStateToProps)(EditWorkComponent)
