import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { useDeepCompareEffect, useFirstMountState } from "react-use"

import { PageHeader, ImportEntityModalButtonControl } from "components"
import { AddAgency } from "./AddAgency"
import { AgenciesGrid } from "./AgenciesGrid"
import { agenciesList } from "store/actions"
import { selectAgenciesListSyncCount } from "store"
import { useDrawer } from "hooks"
import { IMPORT_ENTITY_TYPE } from "consts"

const AgenciesPanelContainerComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isFirstMount = useFirstMountState()
  const {
    visible: addDrawerVisible,
    hide: closeAddDrawer,
    show: showAddDrawer,
  } = useDrawer()

  useEffect(() => {
    return () => {
      dispatch(agenciesList.cleanState())
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  useDeepCompareEffect(() => {
    if (!isFirstMount) {
      fetchData()
    }
  }, [props.page, props.filters.values, props.syncCount])

  const fetchData = () => {
    let params = {
      page: props.page,
      per_page: 12,
    }
    const { keywords, ...restFilters } = props.filters.values
    params.filters = {
      ...restFilters,
      keywords: keywords !== "" ? keywords : null,
    }
    dispatch(agenciesList.getList({ params }))
  }

  const handleAddNew = () => {
    fetchData()
  }

  const handlePageChange = item => {
    dispatch(agenciesList.setPage(item.selected + 1))
  }

  return (
    <React.Fragment>
      <PageHeader
        title={t("title.agencies")}
        headerRight={
          <div className={"d-flex"}>
            <ImportEntityModalButtonControl
              type={IMPORT_ENTITY_TYPE.AGENCIES}
              btnClass={"me-2"}
            />

            <AddAgency
              onAddSuccess={handleAddNew}
              visible={addDrawerVisible}
              onClose={closeAddDrawer}
              onOpen={showAddDrawer}
            />
          </div>
        }
      />

      <AgenciesGrid
        items={props.list}
        loading={props.listLoading}
        error={props.listError}
        meta={props.meta}
        page={props.page}
        onPageChange={handlePageChange}
      />
    </React.Fragment>
  )
}

AgenciesPanelContainerComponent.propTypes = {
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
  filters: PropTypes.object,
  syncCount: PropTypes.number,
}

const mapStateToProps = state => {
  const { list, listLoading, listError, meta, page, filters } =
    state.agencies.panel.list

  const syncCount = selectAgenciesListSyncCount(state)
  return {
    list,
    listLoading,
    listError,
    meta,
    page,
    filters,
    syncCount,
  }
}

export const AgenciesPanelContainer = connect(mapStateToProps)(
  AgenciesPanelContainerComponent
)
