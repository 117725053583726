import React, { useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { PostsCategoryToolbar } from "./PostsCategoryToolbar"
import { Card, CardBody } from "reactstrap"
import { FullCalendarControl, OverlayBlockLoading } from "components"
import { PostContent } from "./PostContent"
import { PostCategory } from "./PostCategory"
import moment from "moment"
import { usePostsDrawerManager } from "hooks"
import { useSelector } from "react-redux"
import {
  selectFullSocialCalendarPosts,
  selectSocialCalendarPostLoading,
} from "store"
import { useFacebookAuth } from "packages/facebook"

export const PostsCalendar = ({
  wrapClass,
  withToolbar = true,
  onPostDidMount = null,
  eventOrder = "start, order",
  onDataSet,
}) => {
  const { account } = useFacebookAuth()

  const posts = useSelector(selectFullSocialCalendarPosts)
  const isLoading = useSelector(selectSocialCalendarPostLoading)

  const validRange = useMemo(() => {
    if (account?.expiry_date) {
      return { end: moment(account?.expiry_date).toDate() }
    } else {
      return undefined
    }
  }, [account])

  const {
    navigation: { showPostCreateDrawer, showPostViewDrawer },
  } = usePostsDrawerManager()

  const handleEventCategoryClick = item => {
    const eventData = { type: item.type }
    showPostCreateDrawer({ ...eventData })
  }

  const handlePostClick = info => {
    let { extendedProps } = info.event
    showPostViewDrawer(extendedProps)
  }

  const handlePostDrop = info => {
    const { date } = info
    const draggedEl = info.draggedEl
    const eventType = draggedEl?.dataset?.eventType
    const values = { type: eventType, date_start: date, date_end: date }
    showPostCreateDrawer(values)
  }

  const handleDatesSet = rangeInfo => {
    const { start, end } = rangeInfo
    const dates = {
      date_start: moment(start).format("YYYY-MM-DD"),
      date_end: moment(end).add(-1, "days").format("YYYY-MM-DD"),
    }
    onDataSet(dates)
  }

  return (
    <OverlayBlockLoading isLoading={isLoading}>
      <div
        className={classNames("calendar posts-calendar", {
          [wrapClass]: wrapClass,
        })}
      >
        {withToolbar && (
          <PostsCategoryToolbar
            renderPostCategory={(data, index, draggableSelector) => (
              <PostCategory
                key={`post-category-${index}`}
                data={data}
                onPostCategoryClick={handleEventCategoryClick}
                draggableSelector={draggableSelector}
              />
            )}
          />
        )}
        <Card>
          <CardBody className={"bg-light"}>
            <FullCalendarControl
              events={posts}
              datesSet={handleDatesSet}
              eventClick={handlePostClick}
              drop={handlePostDrop} // dropped eternal event (category) without event data
              eventContent={eventInfo => <PostContent postData={eventInfo} />}
              eventOrder={eventOrder}
              eventDidMount={onPostDidMount}
              views={{
                week: {
                  dayHeaderContent: info =>
                    moment(info.date).format("ddd DD/MM"),
                },
              }}
              validRange={validRange}
            />
          </CardBody>
        </Card>
      </div>
    </OverlayBlockLoading>
  )
}

PostsCalendar.propTypes = {
  wrapClass: PropTypes.string,
  withToolbar: PropTypes.bool,
  onPostDidMount: PropTypes.func,
  eventOrder: PropTypes.string,
  onDataSet: PropTypes.func,
}
