import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Card, Row, Col, FormFeedback } from "reactstrap"
import classNames from "classnames"

import { OverlayLoading } from "../OverlayLoading"
import { IconButton } from "../Buttons"
import { FileUtils } from "../../utils"
import iconPlaceholder from "../../assets/images/dev/file-paperclip-icon.svg"

export const AttachedFile = ({
  className,
  file,
  name,
  size,
  error,
  onRemove,
  loading,
  withDownload,
  withRemove = true,
  onDownload,
  fileNamePlaceholderText,
  color = "neutral",
  ...props
}) => {
  const showError = Boolean(error)

  const { t } = useTranslation()

  return (
    <Card
      className={classNames("mt-1 mb-0 shadow-none border attached-file-card", {
        [className]: className,
        [`bg-${color}`]: color,
      })}
    >
      <div className="p-2 overlay-block">
        <div
          className={classNames("attached-file-card__body ", {
            "is-invalid": showError,
          })}
        >
          <Col>
            <Row className={"d-flex align-items-center"}>
              <Col className="col-auto">
                <div className={"bg-white  attached-file-card__icon-block"}>
                  <img className="" alt={"icon"} src={iconPlaceholder} />
                </div>
              </Col>
              <Col>
                <span className="text-gray font-weight-bold">
                  {name ? name : fileNamePlaceholderText}
                </span>
                {size && (
                  <p className="mb-0">
                    <strong>{FileUtils.formatBytes(size)}</strong>
                  </p>
                )}
              </Col>
            </Row>
          </Col>
          <div className={"ms-2 d-flex flex-column"}>
            {withRemove && (
              <IconButton
                name={"remove"}
                onClick={onRemove}
                bg
                disabled={loading}
              />
            )}

            {withDownload && (
              <IconButton
                name={"download"}
                onClick={onDownload}
                bg
                disabled={loading}
                wrapClass={"mt-1"}
              />
            )}
          </div>
        </div>

        <FormFeedback invalid={showError ? error : null} className={"mt-2"}>
          {error}
        </FormFeedback>

        <OverlayLoading isLoading={loading} />
      </div>
    </Card>
  )
}

AttachedFile.propTypes = {
  className: PropTypes.string,
  file: PropTypes.any,
  name: PropTypes.string,
  error: PropTypes.string,
  size: PropTypes.any,
  onRemove: PropTypes.func,
  loading: PropTypes.bool,
  withDownload: PropTypes.bool,
  withRemove: PropTypes.bool,
  onDownload: PropTypes.func,
  fileNamePlaceholderText: PropTypes.string,
  color: PropTypes.string,
}
