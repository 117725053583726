import { useTranslation } from "react-i18next"
import { useFetchData } from "hooks"
import { API } from "api"
import toastr from "toastr"

export const useModelStatementPaid = () => {
  const { t } = useTranslation()

  const { fetchData, loading } = useFetchData()

  const onStatementsPaid = async ({ id, statementsIds }) => {
    const params = {
      statements_ids: statementsIds,
    }
    try {
      const request = API.model.paidModelStatements(id, params)

      const result = await fetchData({
        request,
      })

      toastr.success(t("statement.paid.success"))

      return result
    } catch (error) {
      toastr.error(error?.message || t("error"))

      throw error
    }
  }

  return {
    onStatementsPaid,
    loading,
  }
}
