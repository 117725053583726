import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"

import { HistoryTable } from "../../../../../components"
import { agencyHistoryData } from "../../../../../store/actions"

const HistoryContainerComponent = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(agencyHistoryData.cleanState())
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [props.page])

  const fetchData = () => {
    let params = {
      page: props.page,
    }
    dispatch(agencyHistoryData.getData({ id: props.agency.id, params }))
  }

  const handlePageChange = item => {
    dispatch(agencyHistoryData.setPage(item.selected + 1))
  }

  return (
    <>
      <HistoryTable
        list={props.list}
        loading={props.loading}
        error={props.error}
        meta={props.meta}
        page={props.page}
        onPageChange={handlePageChange}
      />
    </>
  )
}

HistoryContainerComponent.propTypes = {
  agency: PropTypes.object,
  list: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
}

const mapStateToProps = state => {
  const { data } = state.agency.profile.data
  const { list, loading, error, meta, page } = state.agency.history.data
  return {
    agency: data,
    list,
    loading,
    error,
    meta,
    page,
  }
}

export const HistoryContainer = connect(mapStateToProps)(
  HistoryContainerComponent
)
