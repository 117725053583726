import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"

import { Models } from "./Models"
import { ModelPanel } from "../ModelPanel"

export const ModelsIndex = () => {
  let { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <Models />
      </Route>
      <Route path={`${path}/:id`}>
        <ModelPanel />
      </Route>
      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  )
}
