import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    loading: false,
    error: null,
}

const agencyRemoveLogoSlice = createSlice({
    name: "agencyRemoveLogo",
    initialState,
    reducers: {
        remove(state) {
            state.loading = true
            state.error = null
        },
        removeCompleted(state) {
            state.loading = false
        },
        removeRejected(state, action) {
            state.loading = false
            state.error = action.payload
        },
        cleanState() {
            return initialState
        },
    },
})

export const agencyRemoveLogo = agencyRemoveLogoSlice.actions

export default agencyRemoveLogoSlice.reducer