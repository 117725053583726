import React from "react"
import { Col, Row, Table } from "reactstrap"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

import { BILLING_SUBSCRIPTION_TARIFF_PERIOD_ANNUALLY } from "consts"
import { InfoCard } from "components"
import { billingHelper } from "helpers"

const currencySymbol = billingHelper.currency.getCurrency().symbol

export const PaymentInfo = props => {
  const { t } = useTranslation()

  const { price, tariff } = props

  return (
    <Row>
      <Col>
        <InfoCard title={t("payment_information")}>
          <div className="table-responsive table-centered">
            <Table className="table mb-0 table-border-bottom">
              <tbody>
                {tariff && (
                  <>
                    <tr>
                      <th scope="row">{t("subscription_plan")}:</th>
                      <td>{tariff?.name}</td>
                    </tr>

                    <tr>
                      <th scope="row">{t("billed_period")}:</th>
                      <td>
                        {tariff?.period ===
                        BILLING_SUBSCRIPTION_TARIFF_PERIOD_ANNUALLY
                          ? t("billing_subscription.tariff-period.annually")
                          : t("billing_subscription.tariff-period.monthly")}
                      </td>
                    </tr>
                  </>
                )}

                {price && (
                  <>
                    <tr>
                      <th scope="row">{t("price")}:</th>
                      <td>
                        {price} {currencySymbol}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </InfoCard>
      </Col>
    </Row>
  )
}

PaymentInfo.propTypes = {
  price: PropTypes.any,
  tariff: PropTypes.object,
}
