import React from "react"
import PropTypes from "prop-types"

import { EventsEmailSendDrawer } from "./EventsEmailSendDrawer"
import { EventsEmailSendContainer } from "./EventsEmailSendContainer"

export const EventsEmailSend = props => {
  return (
    <EventsEmailSendDrawer
      visible={props.visible}
      onClose={props.onClose}
      onDestroy={props.onDestroy}
    >
      <EventsEmailSendContainer
        modelId={props.modelId}
        date={props.date}
        onSuccess={props.onSuccess}
        initialRecipients={props.initialRecipients}
      />
    </EventsEmailSendDrawer>
  )
}

EventsEmailSend.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  modelId: PropTypes.any,
  date: PropTypes.object,
  onSuccess: PropTypes.func,
  initialRecipients: PropTypes.array,
}
