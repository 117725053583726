import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Modal } from "components"
import { useTranslation } from "react-i18next"
import { modelsAddGroup } from "store"
import { useSubmitHandler } from "hooks"
import { CreateGroupForm } from "./CreateGroupForm"

const CreateGroupComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { handleSubmit } = useSubmitHandler(
    ({ onSuccess, onError, params }) => {
      dispatch(
        modelsAddGroup.add({
          params: {
            type: props.gender,
            name: params.group_name,
            model_ids: props.selectedModels,
          },
          onSuccess,
          onError,
        })
      )
    },
    () => props.onAddSuccess()
  )

  return (
    <Modal
      ref={props.modalRef}
      title={t("create-group")}
      strapModalProp={{
        centered: true,
        onClosed: props.onCancel,
      }}
    >
      <CreateGroupForm
        loading={props.loading}
        onCancel={props.onCancel}
        onSubmit={handleSubmit}
        error={props.error}
        initialValues={{}}
      />
    </Modal>
  )
}

CreateGroupComponent.propTypes = {
  modalRef: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.any,
  gender: PropTypes.string,
  onCancel: PropTypes.func,
  onAddSuccess: PropTypes.func,
  onModalClose: PropTypes.func,
  selectedModels: PropTypes.array,
}

const mapStateToProps = state => {
  const { gender } = state.models.panel.list.data.filters.values
  const { loading, error } = state.models.groups.add
  return {
    gender,
    loading,
    error,
  }
}

export const CreateGroup = connect(mapStateToProps)(CreateGroupComponent)
