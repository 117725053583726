import { combineReducers } from "@reduxjs/toolkit"

import data from "./data/slice"
import messages from "./messages/reducers"
import attachment from "./attachment/reducers"

const conversationReducer = combineReducers({
  data,
  messages,
  attachment,
})

export default conversationReducer
