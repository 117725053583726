import React from "react"
import PropTypes from "prop-types"
import { IconButton, TextButton } from "components/Buttons"
import classnames from "classnames"
import { useMedia } from "react-use"

export const ProductTourButton = ({
  onClick,
  wrapClass = "",
  label = "start_product_tour",
}) => {
  const isWide = useMedia("(min-width: 648px)")

  return isWide ? (
    <TextButton
      leftIcon={classnames("font-size-16 align-middle bx bx-play-circle")}
      title={label}
      color={"secondary"}
      btnClass={wrapClass}
      onClick={onClick}
    />
  ) : (
    <IconButton
      icon={`align-middle font-size-20 bx bx-play-circle`}
      color={"text-secondary"}
      onClick={onClick}
      wrapClass={wrapClass}
    />
  )
}

ProductTourButton.propTypes = {
  onClick: PropTypes.func,
  wrapClass: PropTypes.string,
  label: PropTypes.string,
}
