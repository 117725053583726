import React from "react"
import PropTypes from "prop-types"
import { SOCIAL_POST_TYPE_FACEBOOK, SOCIAL_POST_TYPE_INSTAGRAM } from "consts"
import { InstagramPostForm } from "./InstagramPostForm"
import { FBPagePostForm } from "./FBPagePostForm"

export const PostForm = props => {
  const type = props.postType

  switch (type) {
    case SOCIAL_POST_TYPE_INSTAGRAM: {
      return <InstagramPostForm {...props} />
    }
    case SOCIAL_POST_TYPE_FACEBOOK: {
      return <FBPagePostForm {...props} />
    }
    default: {
      return null
    }
  }
}

PostForm.propTypes = {
  postType: PropTypes.string,
}
