import React from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next"

import {BooksGrid} from "../BooksGrid"

const OtherBooksContainerComponent = (props) => {
    const { t } = useTranslation();

    const handlePageChange = () => {

    }

    const handleRemove = () => {

    }

    const handleEdit = () => {

    }

    const handlePublish = () => {

    }

    const handleCopy = () => {

    }

    return (
        <>
            <h4 className={'text-capitalize mb-3'}>{t('other_books')}</h4>

            <BooksGrid
                items={[{id: 1}, {id: 2}]}
                loading={false}
                error={null}
                meta={null}
                page={1}
                removingItems={[]}
                onPageChange={handlePageChange}
                onRemove={handleRemove}
                onEdit={handleEdit}
                onPublish={handlePublish}
                onCopy={handleCopy}
            />
        </>
    );
};

OtherBooksContainerComponent.propTypes = {
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const OtherBooksContainer = connect(mapStateToProps, mapDispatchToProps)(OtherBooksContainerComponent);