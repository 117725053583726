import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { MegaTagTitle } from "components"
import { BecomeModelContainer } from "./components"

export const BecomeModel = props => {
  return (
    <div className={"website-become-model"}>
      <MegaTagTitle title={"title.website.become-model"} />
      <Row>
        <Col>
          <BecomeModelContainer alias={props.alias} />
        </Col>
      </Row>
    </div>
  )
}

BecomeModel.propTypes = {
  alias: PropTypes.string,
}
