import {
    MODEL_VIDEOS_REMOVE_ITEM,
    MODEL_VIDEOS_REMOVE_ITEM_SUCCESS,
    MODEL_VIDEOS_REMOVE_ITEM_ERROR,
    MODEL_VIDEOS_REMOVE_ITEM_CLEAN_STATE,
} from './actionTypes';

export const modelVideosRemoveVideo = (id, onSuccess) => {
    return {
        type: MODEL_VIDEOS_REMOVE_ITEM,
        payload: { id, onSuccess },
    }
};

export const modelVideosRemoveVideoSuccess = (id) => {
    return {
        type: MODEL_VIDEOS_REMOVE_ITEM_SUCCESS,
        payload: { id },
    }
};

export const modelVideosRemoveVideoError = (id) => {
    return {
        type: MODEL_VIDEOS_REMOVE_ITEM_ERROR,
        payload: { id },
    }
};

export const modelVideosRemoveVideoCleanState = () => {
    return { type: MODEL_VIDEOS_REMOVE_ITEM_CLEAN_STATE };
};