import React, { useEffect } from 'react';
import PropTypes from "prop-types"
import { connect } from "react-redux";
import { Alert } from "reactstrap"
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { FormikTextInputFiled as TextInputFiled, Button } from '../../../../components';
import { userForgetPassword, userForgetPasswordCleanSate } from '../../../../store/actions';

const ForgetPasswordFormComponent = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            props.userForgetPasswordCleanSate();
        }
    }, []);

    const onSubmit = (values, onSubmitProps) => {
        props.userForgetPassword(values, onSubmitProps);
    };

    return (
        <Formik
            initialValues={{
                email: '',
            }}
            validationSchema={Yup.object({
                email: Yup.string().email('field.error.invalid').required('field.error.required'),
            })}
            onSubmit={onSubmit}
            validateOnChange={true}
            validateOnBlur={false}
        >
            {({
                  isSubmitting,
                  ...rest
              }) => {
                return (
                    <>
                        {props.forgetError && (
                            <Alert color="danger">
                                {props.forgetError.message}
                            </Alert>
                        )}

                        {props.forgetSuccessMsg && (
                            <Alert color="success">
                                {props.forgetSuccessMsg}
                            </Alert>
                        )}

                        <Form className="form-horizontal" noValidate={true}>
                            <TextInputFiled
                                name="email"
                                label={t('field.label.email')}
                                placeholder={t('field.placeholder.email')}
                                id={'email'}
                                type="email"
                            />

                            <div className="mt-3 d-grid">
                                <Button
                                    submit
                                    title={'reset'}
                                    disabled={isSubmitting || props.loading}
                                    loading={props.loading}
                                />
                            </div>
                        </Form>
                    </>
                )
            }}
        </Formik>
    );
};

ForgetPasswordFormComponent.propTypes = {
    forgetSuccessMsg: PropTypes.string,
    forgetError: PropTypes.object,
    loading: PropTypes.bool,

    userForgetPassword: PropTypes.func,
    userForgetPasswordCleanSate: PropTypes.func,
}

const mapStateToProps = state => {
    const {
        forgetSuccessMsg, forgetError, loading,
    } = state.auth.passwordForget;
    return {
        forgetSuccessMsg, forgetError, loading,
    };
};

const mapDispatchToProps = {
    userForgetPassword,
    userForgetPasswordCleanSate,
};

export const ForgetPasswordForm = connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordFormComponent);