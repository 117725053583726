import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"

export const FilesPicker = props => {
  const { t } = useTranslation()

  const inputRef = useRef()

  const handleFilesPicked = event => {
    props.onChange(event.target.files)

    if (inputRef.current) {
      inputRef.current.value = null
    }
  }

  const pickFiles = event => {
    event.preventDefault()

    if (props.disabled) {
      return
    }

    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <>
      <input
        type="file"
        className={"d-none"}
        onChange={handleFilesPicked}
        ref={inputRef}
        multiple
        accept="image/png, image/jpeg, text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf"
      />

      <Link to="#" onClick={pickFiles}>
        <i
          className="mdi mdi-file-document-outline text-secondary"
          id="file-tooltip"
        />
        <UncontrolledTooltip placement="top" target="file-tooltip">
          {t("add_files")}
        </UncontrolledTooltip>
      </Link>
    </>
  )
}

FilesPicker.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}
