import { combineReducers } from "redux";

import books from './books/reducer';
import videos from './videos/reducer';

const dataCollectionsReducer = combineReducers({
    books,
    videos,
});

export default dataCollectionsReducer;