export const MODEL_PANEL_GENERAL_FETCH_LOGISTICS_LIST =
  "MODEL_PANEL_GENERAL_FETCH_LOGISTICS_LIST"
export const MODEL_PANEL_GENERAL_FETCH_LOGISTICS_LIST_SUCCESS =
  "MODEL_PANEL_GENERAL_FETCH_LOGISTICS_LIST_SUCCESS"
export const MODEL_PANEL_GENERAL_FETCH_LOGISTICS_LIST_ERROR =
  "MODEL_PANEL_GENERAL_FETCH_LOGISTICS_LIST_ERROR"
export const MODEL_PANEL_GENERAL_FETCH_LOGISTICS_LIST_CLEAN_STATE =
  "MODEL_PANEL_GENERAL_FETCH_LOGISTICS_LIST_CLEAN_STATE"

export const MODEL_PANEL_GENERAL_LOGISTICS_LIST_SET_PAGE =
  "MODEL_PANEL_GENERAL_LOGISTICS_LIST_SET_PAGE"
