import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

import { modelHelper, commonHelper } from "helpers"
import { MEASUREMENT_SYSTEM_METRIC, KG, LB } from "consts"

const getBooleanOptionText = value => commonHelper.format.booleanToName(value)

export const FeaturesInfo = ({ data, measurementsSystem }) => {
  const { t } = useTranslation()

  data = data || {}

  const renderItem = item => (
    <div className="info-list__block">
      <div className="info-list__item">
        <div className={"info-list__label"}>{t(item.label)}</div>
        <div className={"info-list__info"}>{item.value || "-"}</div>
      </div>
    </div>
  )

  const section1 = useMemo(
    () => [
      [
        {
          label: "height",
          value: data.height?.text,
        },
        {
          label: "public-height",
          value: data.height_public?.text,
        },
      ],
      [
        {
          label: "bust",
          value: data.bust?.text,
        },
        {
          label: "public-bust",
          value: data.bust_public?.text,
        },
      ],
      [
        {
          label: "waist",
          value: data.waist?.text,
        },
        {
          label: "public-waist",
          value: data.waist_public?.text,
        },
      ],
      [
        {
          label: "hips",
          value: data.hips?.text,
        },
        {
          label: "public-hips",
          value: data.hips_public?.text,
        },
      ],
      [
        {
          label: "shoes",
          value: data.shoes?.text,
        },
        {
          label: "public-shoes",
          value: data.shoes_public?.text,
        },
      ],
    ],
    [data]
  )

  const section2 = useMemo(
    () => [
      {
        label: "dress_size",
        value: data.dress_size?.text,
      },
      {
        label: "shoulders",
        value: data.shoulders?.text,
      },
      {
        label: "trousers",
        value: data.trousers?.text,
      },
      {
        label: "model.head",
        value: data.head?.text,
      },
      {
        label: "model.neck",
        value: data.neck?.text,
      },
      {
        label: "model.cup",
        value: data.cup?.text,
      },
      {
        label: "weight",
        value: data.weight
          ? `${data.weight} ${
              measurementsSystem === MEASUREMENT_SYSTEM_METRIC ? t(KG) : t(LB)
            }`
          : null,
      },
    ],
    [data]
  )

  const section3 = useMemo(
    () => [
      {
        label: "hair-color",
        value: data.hair_color?.text,
      },
      {
        label: "hair-length",
        value: data.hair_length?.text,
      },
      {
        label: "eyes-color",
        value: data.eyes_color?.text,
      },
      {
        label: "breast",
        value: modelHelper.general.getFeatureNaturalness(data.breast)?.label,
      },
      {
        label: "hand",
        value: getBooleanOptionText(data.hands),
      },
      {
        label: "feet",
        value: getBooleanOptionText(data.feet),
      },
      {
        label: "tatoos",
        value: getBooleanOptionText(data.tatoos),
      },
      {
        label: "ears_pierced",
        value: getBooleanOptionText(data.ears_pierced),
      },
      {
        label: "lips",
        value: modelHelper.general.getFeatureNaturalness(data.lips)?.label,
      },
    ],
    [data]
  )

  const section4 = useMemo(
    () => [
      {
        label: "piercing",
        value: data.piercing,
      },
      {
        label: "scars",
        value: data.scars,
      },
    ],
    [data]
  )

  const section5 = useMemo(
    () => [
      {
        label: "surfing",
        value: getBooleanOptionText(data.surfing),
      },
      {
        label: "dancing",
        value: getBooleanOptionText(data.dancing),
      },
      {
        label: "swimming",
        value: getBooleanOptionText(data.swimming),
      },
      {
        label: "water-skiing",
        value: getBooleanOptionText(data.water_skiing),
      },
      {
        label: "horse-riding",
        value: getBooleanOptionText(data.horse_riding),
      },
      {
        label: "skating",
        value: getBooleanOptionText(data.skating),
      },
      {
        label: "tennis",
        value: getBooleanOptionText(data.tennis),
      },
      {
        label: "skiing",
        value: getBooleanOptionText(data.skiing),
      },
      {
        label: "driving",
        value: getBooleanOptionText(data.driving),
      },
      {
        label: "singing",
        value: getBooleanOptionText(data.singing),
      },
      {
        label: "acting",
        value: getBooleanOptionText(data.acting),
      },
      {
        label: "alcool",
        value: getBooleanOptionText(data.alcool),
      },
      {
        label: "smoke",
        value: getBooleanOptionText(data.smoke),
      },
      {
        label: "fur",
        value: getBooleanOptionText(data.fur),
      },
      {
        label: "lingerie",
        value: getBooleanOptionText(data.lingerie),
      },
      {
        label: "nude",
        value: getBooleanOptionText(data.nude),
      },
      {
        label: "topless",
        value: getBooleanOptionText(data.topless),
      },
      {
        label: "swimsuit",
        value: getBooleanOptionText(data.swimsuit),
      },
    ],
    [data]
  )

  const section6 = useMemo(
    () => [
      {
        label: "special-skills",
        value: data.special_skills,
      },
      {
        label: "sports",
        value: data.sports,
      },
      {
        label: "languages",
        value: data.languages,
      },
    ],
    [data]
  )

  const section7 = useMemo(
    () => [
      {
        label: "vegetarian",
        value: getBooleanOptionText(data.vegetarian),
      },
      {
        label: "vegan",
        value: getBooleanOptionText(data.vegan),
      },
    ],
    [data]
  )

  const section8 = useMemo(
    () => [
      {
        label: "allergies",
        value: data.allergies,
      },
    ],
    [data]
  )

  return (
    <Col className={"model-features-full-view"}>
      <>
        {section1.map((row, i) => (
          <Row key={`s1-${i}`}>
            {row.map((item, j) => (
              <Col key={`s1-${i}-${j}`} md={3}>
                {renderItem(item)}
              </Col>
            ))}
          </Row>
        ))}
      </>

      <hr className="drawer-separator drawer-separator_form" />

      <Row>
        {section2.map((item, i) => (
          <Col md={3} key={`s2-${i}`}>
            {renderItem(item)}
          </Col>
        ))}
      </Row>

      <hr className="drawer-separator drawer-separator_form" />

      <Row>
        {section3.map((item, i) => (
          <Col md={3} key={`s3-${i}`}>
            {renderItem(item)}
          </Col>
        ))}
      </Row>

      <Row>
        {section4.map((item, i) => (
          <Col md={6} key={`s4-${i}`}>
            {renderItem(item)}
          </Col>
        ))}
      </Row>

      <hr className="drawer-separator drawer-separator_form" />

      <h4 className={"mb-4"}>{t("model.features.title.skills-permissions")}</h4>

      <Row>
        {section5.map((item, i) => (
          <Col md={3} key={`s5-${i}`}>
            {renderItem(item)}
          </Col>
        ))}
      </Row>

      <hr className="drawer-separator drawer-separator_form" />

      <Row>
        {section6.map((item, i) => (
          <Col md={6} key={`s6-${i}`}>
            {renderItem(item)}
          </Col>
        ))}
      </Row>

      <hr className="drawer-separator drawer-separator_form" />

      <h4 className={"mb-4"}>{t("model.features.title.food-allergies")}</h4>

      <Row>
        {section7.map((item, i) => (
          <Col md={3} key={`s7-${i}`}>
            {renderItem(item)}
          </Col>
        ))}
      </Row>

      <Row>
        {section8.map((item, i) => (
          <Col md={6} key={`s8-${i}`}>
            {renderItem(item)}
          </Col>
        ))}
      </Row>
    </Col>
  )
}

FeaturesInfo.propTypes = {
  data: PropTypes.object,
  measurementsSystem: PropTypes.string,
}
