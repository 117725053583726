import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { useFormContext } from "react-hook-form"

import { ComboboxFormField, ControllerHF } from "components"
import { BillingUtils } from "utils"
import { BILLING_PAYMENT_METHOD_TYPE } from "consts"

const { CARD, SEPA_DEBIT } = BILLING_PAYMENT_METHOD_TYPE

export const PaymentMethodSelectSection = props => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext()

  const payment_method_type = watch("payment_method_type")

  const mapOptions = useCallback(
    type => props.options.filter(item => item.type === type),
    [props.options]
  )

  const formatCardOptionLabel = ({ last4, exp_month, exp_year, brand }) => (
    <div className={"payment-card-select-label"}>
      <div className={"payment-card-select-label__number"}>
        {t("card_number")}
        <span
          className={"font-weight-bold"}
        >{`: ${BillingUtils.paymentCardHiddenNumber(last4)}`}</span>
      </div>
      <div className={"payment-card-select-label__date"}>
        <span className={""}>
          {t("expiry_date")}:{" "}
          {BillingUtils.paymentCardFormatExpiryDate(exp_month, exp_year)}
        </span>
      </div>
      <div className={"payment-card-select-label__payment-system"}>
        {t("payment_system")}
        <span className={"text-capitalize"}>{`: ${brand}`}</span>
      </div>
    </div>
  )

  const formatIbanOptionLabel = ({ country, bank_code, last4 }) => (
    <>
      {BillingUtils.paymentIbanHiddenNumber({
        country,
        bank_code,
        last4,
      })}
    </>
  )

  return (
    <>
      {payment_method_type === CARD && (
        <ControllerHF
          name={"card"}
          control={control}
          component={ComboboxFormField}
          id={"card"}
          label={t("field.label.card")}
          placeholder={t("field.placeholder.select_card")}
          options={mapOptions(CARD)}
          getOptionValue={option => option.id}
          normalize={value => (value ? value.id : null)}
          isDetermineValue
          selectProps={{
            isClearable: true,
            isSearchable: false,
            formatOptionLabel: formatCardOptionLabel,
            menuPortalTarget: document.querySelector("body"),
          }}
          wrapClass={"payment-card-select"}
        />
      )}

      {payment_method_type === SEPA_DEBIT && (
        <ControllerHF
          name={"iban"}
          control={control}
          component={ComboboxFormField}
          id={"card"}
          label={t("iban")}
          placeholder={t("iban")}
          options={mapOptions(SEPA_DEBIT)}
          getOptionValue={option => option.id}
          normalize={value => (value ? value.id : null)}
          isDetermineValue
          selectProps={{
            isClearable: true,
            isSearchable: false,
            formatOptionLabel: formatIbanOptionLabel,
            menuPortalTarget: document.querySelector("body"),
          }}
        />
      )}
    </>
  )
}

PaymentMethodSelectSection.propTypes = {
  options: PropTypes.array,
}
