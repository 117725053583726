import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "i18n"
import { API } from "api"
import { modelsUpdateGroup } from "./slice"
import { modelsGroupsList } from "store"

function* updateItem({ payload: { id, params, onSuccess, onError } }) {
  try {
    const response = yield call(API.modelsGroups.updateGroup, id, params)

    const { data } = response

    if (onSuccess) {
      onSuccess()
    }
    toastr.success(i18n.t("edit.success"))

    yield put(modelsUpdateGroup.updateCompleted())
    yield put(modelsGroupsList.updateItem({ ...data.data }))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      modelsUpdateGroup.updateRejected({
        errors,
        message,
      })
    )
    if (onError) {
      onError(errors)
    }
  }
}

export function* watchUpdateItem() {
  yield takeEvery(modelsUpdateGroup.update, updateItem)
}

function* updateSaga() {
  yield all([fork(watchUpdateItem)])
}

export default updateSaga
