import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useRouteMatch, generatePath } from "react-router-dom"

import { PanelMenu } from "components"

export const Nav = () => {
  const { t } = useTranslation()

  let { path } = useRouteMatch()

  const getPath = route => generatePath(`${path}/${route}`)

  const menu = [
    {
      key: "general",
      route: getPath("general"),
      title: t("general"),
    },
    {
      key: "subscriptions",
      route: getPath("subscriptions"),
      title: t("subscription_plans"),
    },
    {
      key: "info",
      route: getPath("info"),
      title: t("billing_information"),
    },
    {
      key: "payment-methods",
      route: getPath("payment-methods"),
      title: t("payment_methods"),
    },
    {
      key: "invoices",
      route: getPath("invoices"),
      title: t("invoices"),
    },
  ]

  return <PanelMenu items={menu} />
}

Nav.propTypes = {}
