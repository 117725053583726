import React from "react"
import PropTypes from "prop-types"
import { Row } from "reactstrap"
import { useTranslation } from "react-i18next"

import {
  IconButton,
  Pagination,
  SelectionControl,
  ShareMedia,
  VideoOverviewCard,
} from "components"
import * as HOC from "HOC"
import { MEDIA_CONTENT_TYPE } from "consts"

const DataShower = HOC.withDataShowerOverlay()

export const VideosGrid = props => {
  const { t } = useTranslation()

  const renderActions = item => {
    const isSelected = props.selectedVideos.includes(item.id)
    const isRemoving = props.removingItems.includes(item.id)

    return (
      <>
        <SelectionControl
          id={`video-selection-control-${item.id}`}
          onChange={() => props.onSelectVideo(item)}
          selected={isSelected}
          disabled={isRemoving}
        />
        <IconButton
          name={"edit"}
          onClick={() => props.onVideoEdit(item)}
          bg
          disabled={isRemoving}
        />

        <ShareMedia
          type={MEDIA_CONTENT_TYPE.VIDEO}
          src={item.url}
          id={item.id}
        />

        <IconButton
          name={"remove"}
          onClick={() => props.onVideoRemove(item)}
          bg
          disabled={isRemoving}
        />
      </>
    )
  }

  return (
    <Row>
      <div className={"model-panel-videos-grid mb-4"}>
        <DataShower
          isLoading={props.loading}
          isFailed={props.error}
          error={props.error}
        >
          {!!props.items.length ? (
            <>
              <div className="grid">
                <div className="grid__row">
                  {props.items.map((item, i) => (
                    <div
                      className="grid__col"
                      key={`model-videos-grid-item-${item.id}`}
                    >
                      <div className="grid__item">
                        <VideoOverviewCard
                          iframeUrl={item.iframe_url}
                          playerRef={el =>
                            (props.playersRef.current[item.id] = el)
                          }
                          title={item.title}
                          description={item.description}
                          actions={renderActions(item)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <Pagination data={props.meta} onPageChange={props.onPageChange} />
            </>
          ) : !props.loading ? (
            t("empty")
          ) : null}
        </DataShower>
      </div>
    </Row>
  )
}

VideosGrid.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,
  removingItems: PropTypes.array,
  playersRef: PropTypes.any,
  selectedVideos: PropTypes.array,

  onPageChange: PropTypes.func,
  onVideoRemove: PropTypes.func,
  onVideoEdit: PropTypes.func,
  onSelectVideo: PropTypes.func,
}
