import _ from "lodash"

import i18n from "i18n"

export const getReminderConfigs = () => {
  return [
    {
      label: i18n.t("reminder.period-before", { period: i18n.t("day") }),
      value: "day",
    },
    {
      label: i18n.t("reminder.period-before", { period: i18n.t("week") }),
      value: "week",
    },
    {
      label: i18n.t("reminder.period-before", { period: i18n.t("month") }),
      value: "month",
    },
  ]
}

export const getReminderConfig = value => {
  const options = getReminderConfigs()
  return _.find(options, { value: value })
}
