import { all } from "redux-saga/effects"

import updateSaga from "./update/saga"
import photosSaga from "./photos/saga"
import setSaga from "./set/saga"
import uploadSaga from "./upload/saga"
import removeSaga from "./remove/saga"

function* avatarSaga() {
  yield all([updateSaga(), photosSaga(), setSaga(), uploadSaga(), removeSaga()])
}

export default avatarSaga
