import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import classNames from "classnames"

import { IconButton } from "components"

export const Item = props => {
  const onClick = e => {
    e.preventDefault()

    if (props.active) {
      return
    }

    props.onSelect(props.item)
  }

  const onRemove = e => {
    e.stopPropagation()
    e.preventDefault()

    props.onRemove(props.item)
  }

  return (
    <li
      className={classNames({
        active: props.active,
      })}
    >
      <Link to="#" onClick={onClick}>
        <div className={"d-flex align-items-center justify-content-between"}>
          <span className="fw-medium">{props.item?.name}</span>

          {props.item?.deletable && (
            <div className={"ms-3"}>
              <IconButton
                name={"remove"}
                onClick={onRemove}
                color={"text-danger"}
                wrapClass={"font-size-20"}
              />
            </div>
          )}
        </div>
      </Link>
    </li>
  )
}

Item.propTypes = {
  item: PropTypes.object,
  onSelect: PropTypes.func,
  active: PropTypes.bool,
  onRemove: PropTypes.func,
}
