import { NormalizeUtils } from "utils"

const PREVIEW_MODE = process.env.REACT_APP_PREVIEW_MODE

export const isAllow = (forceStatus = null) => {
  if (forceStatus !== null) {
    return forceStatus
  }

  const status = NormalizeUtils.stringBoolToBoolean(PREVIEW_MODE)

  return typeof status === "boolean" ? status : false
}

export default {
  isAllow,
}
