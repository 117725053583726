import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const calendarEventRemoveSlice = createSlice({
  name: "calendarEventRemove",
  initialState,
  reducers: {
    remove(state) {
      state.loading = true
      state.error = null
    },
    removeSuccess(state) {
      state.loading = false
    },
    removeError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} remove
 * @property {function} removeSuccess
 * @property {function} removeError
 * @property {function} cleanState
 */
export const calendarEventRemove = calendarEventRemoveSlice.actions

export default calendarEventRemoveSlice.reducer
