import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { Modal, Button } from "components"
import { IMPORT_ENTITY_TYPE } from "consts"
import { importHelper } from "helpers"
import { ImportEntityDataLoader } from "./ImportEntityDataLoader"
import { ImportEntityStartImport } from "./ImportEntityStartImport"
import { ImportEntityInfo } from "./ImportEntityInfo"

const { MODELS, AGENCIES, CLIENTS } = IMPORT_ENTITY_TYPE

export const ImportEntity = ({ type, modalRef, onCancel }) => {
  const { t } = useTranslation()

  const { data } = useSelector(state => state.import.data)

  const onSubmitSuccess = () => {}

  return (
    <Modal
      ref={modalRef}
      title={`${t("import")}: ${
        importHelper.entity.getEntityType(type)?.label
      }`}
      strapModalProp={{
        centered: false,
      }}
    >
      <ImportEntityDataLoader type={type}>
        <ImportEntityStartImport
          type={type}
          active={data?.active}
          onSubmitSuccess={onSubmitSuccess}
        />

        <hr className="drawer-separator drawer-separator_form" />

        <ImportEntityInfo data={data} type={type} />
      </ImportEntityDataLoader>

      <div className="mt-3">
        <div className="button-items">
          <Button title={"cancel"} color="light" outline onClick={onCancel} />
        </div>
      </div>
    </Modal>
  )
}

ImportEntity.propTypes = {
  type: PropTypes.oneOf([MODELS, AGENCIES, CLIENTS]),
  modalRef: PropTypes.any,
  onCancel: PropTypes.func,
}
