import React from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { FileUploadingCard, IconButton } from "components"
import { multipleUpload } from "store/multipleUpload/slice"

export const FilesUploadingDataGrid = ({ className }) => {
  const dispatch = useDispatch()
  const { filesProgressList } = useSelector(state => state.multipleUpload)

  const onRemove = id => {
    dispatch(multipleUpload.removeFile({ id }))
  }
  const renderActions = item => {
    return (
      <>
        {item.error && (
          <IconButton name={"remove"} bg onClick={() => onRemove(item.id)} />
        )}
      </>
    )
  }
  return (
    <div className={className}>
      <div className="grid">
        <div className="grid__row">
          {filesProgressList.map(item => (
            <div className="grid__col" key={`grid-item-${item.id}`}>
              <div className="grid__item">
                <FileUploadingCard
                  value={item.progress}
                  fileName={item.name}
                  error={item.error}
                  actions={renderActions(item)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

FilesUploadingDataGrid.propTypes = {
  className: PropTypes.string,
}
