import { all } from "redux-saga/effects"

import listSaga from "./list/saga"
import dataSaga from "./data/saga"

function* tariffSaga() {
  yield all([listSaga(), dataSaga()])
}

export default tariffSaga
