import { combineReducers } from "redux"

import create from "./create/slice"
import data from "./data/slice"
import remove from "./remove/slice"
import update from "./update/slice"
import pagesList from "./pagesList/slice"
import currentPage from "./currentPage/reducers"

const generalReducer = combineReducers({
  create,
  data,
  remove,
  update,
  pagesList,
  currentPage,
})

export default generalReducer
