import { all } from "redux-saga/effects"

import listSaga from "./list/saga"
import addSaga from "./add/saga"
import removeSaga from "./remove/saga"

function* contactsSaga() {
  yield all([listSaga(), addSaga(), removeSaga()])
}

export default contactsSaga
