import {
    MODEL_PANEL_GENERAL_FETCH_FEATURES,
    MODEL_PANEL_GENERAL_FETCH_FEATURES_SUCCESS,
    MODEL_PANEL_GENERAL_FETCH_FEATURES_ERROR,
    MODEL_PANEL_GENERAL_FETCH_FEATURES_CLEAN_STATE,
} from './actionTypes';

export const modelPanelGeneralFetchFeatures = (id) => {
    return {
        type: MODEL_PANEL_GENERAL_FETCH_FEATURES,
        payload: { id },
    }
};

export const modelPanelGeneralFetchFeaturesSuccess = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_FETCH_FEATURES_SUCCESS,
        payload: data,
    }
};

export const modelPanelGeneralFetchFeaturesError = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_FETCH_FEATURES_ERROR,
        payload: data,
    }
};

export const modelPanelGeneralFetchFeaturesCleanState = () => {
    return { type: MODEL_PANEL_GENERAL_FETCH_FEATURES_CLEAN_STATE };
};