import {
    MODEL_PANEL_SET_MODEL_AVATAR,
    MODEL_PANEL_SET_MODEL_AVATAR_SUCCESS,
    MODEL_PANEL_SET_MODEL_AVATAR_ERROR,
    MODEL_PANEL_SET_MODEL_AVATAR_CLEAN_STATE,
} from './actionTypes';

const initialState = {
    attachmentId: null,
    loading: false,
    error: null,
};

const set = (state = initialState, action) => {
    switch (action.type) {
        case MODEL_PANEL_SET_MODEL_AVATAR:
            state = {
                ...state,
                attachmentId: action.payload.attachmentId,
                loading: true,
                error: null,
            };
            break;

        case MODEL_PANEL_SET_MODEL_AVATAR_SUCCESS:
            state = {
                ...state,
                loading: false,
                attachmentId: null,
            };
            break;

        case MODEL_PANEL_SET_MODEL_AVATAR_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload,
                attachmentId: null,
            };
            break;

        case MODEL_PANEL_SET_MODEL_AVATAR_CLEAN_STATE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default set;