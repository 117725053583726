import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Alert } from "reactstrap"

import { APP_NAME } from "consts"

export const StripeSepaMandateInfo = props => {
  const { t } = useTranslation()

  return (
    <Alert className="stripe-sepa-mandate-info" color={"info"}>
      By providing your payment information and confirming this payment, you
      authorise (A) {APP_NAME} and Stripe, our payment service provider, to send
      instructions to your bank to debit your account and (B) your bank to debit
      your account in accordance with those instructions. As part of your
      rights, you are entitled to a refund from your bank under the terms and
      conditions of your agreement with your bank. A refund must be claimed
      within 8 weeks starting from the date on which your account was debited.
      Your rights are explained in a statement that you can obtain from your
      bank. You agree to receive notifications for future debits up to 2 days
      before they occur.
    </Alert>
  )
}

StripeSepaMandateInfo.propTypes = {}
