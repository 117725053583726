import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { profileRestore } from "./slice"
import { userProfileRestoreCompany } from "../../user/profile/actions"

function* submit() {
  try {
    const response = yield call(API.profile.restoreCompany)

    const { data } = response
    yield put(profileRestore.submitSuccess())

    yield put(userProfileRestoreCompany())

    toastr.success(i18n.t("profile.activate.success"))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      profileRestore.submitError({
        errors,
        message,
      })
    )
  }
}

export function* watchSubmit() {
  yield takeEvery(profileRestore.submit, submit)
}

function* restoreSaga() {
  yield all([fork(watchSubmit)])
}

export default restoreSaga
