import React from "react"
import PropTypes from "prop-types"
import { IconButton, Popover } from "components/index"
import { useFetchShareLink, usePopover } from "hooks"
import * as HOC from "HOC"
import { ShareBox } from "./ShareBox"
import { MEDIA_CONTENT_TYPE } from "consts"

const DataShower = HOC.withDataShower()

const { IMAGE, VIDEO } = MEDIA_CONTENT_TYPE

export const ShareMedia = ({ src, type, title, description, id }) => {
  const { popoverId, toggle, popoverOpen } = usePopover({
    id,
  })

  const { fetchLink, error, shareLink, loading, setShareLink } =
    useFetchShareLink()

  const onFetchShareLink = async () => {
    let params = {
      title: title || "title",
      description: description || "description",
      src,
      type,
    }
    await fetchLink(params)
  }

  const handleClick = () => {
    if (popoverOpen === false) {
      if (type === IMAGE) {
        onFetchShareLink()
      }

      if (type === VIDEO) {
        setShareLink(src)
      }
    }
  }

  return (
    <>
      <IconButton
        name={"share"}
        type={"button"}
        id={popoverId}
        onClick={handleClick}
        bgColor={"bg-dark"}
      />
      <Popover popoverId={popoverId} popoverOpen={popoverOpen} toggle={toggle}>
        <DataShower isLoading={loading} isFailed={error} error={error}>
          <ShareBox link={shareLink} />
        </DataShower>
      </Popover>
    </>
  )
}

ShareMedia.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  src: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.oneOf(["video", "image"]),
}
