import React from "react"
import { Container } from "reactstrap"
import { MegaTagTitle } from "components"
import { AgenciesPanelContainer } from "./components"
import { Sidebar } from "./components/Sidebar"

export const Agencies = () => {
  return (
    <React.Fragment>
      <MegaTagTitle title={"title.agencies"} />

      <div className="page-content page-content_sidebar">
        <div className={"page-content__container"}>
          <Container fluid>
            <AgenciesPanelContainer />
          </Container>
        </div>
        <div className={"page-content__sidebar"}>
          <Sidebar />
        </div>
      </div>
    </React.Fragment>
  )
}
