import {
    MODEL_ADD_VIDEO,
    MODEL_ADD_VIDEO_SUCCESS,
    MODEL_ADD_VIDEO_ERROR,
    MODEL_ADD_VIDEO_CLEAN_STATE,
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
};

const add = (state = initialState, action) => {
    switch (action.type) {
        case MODEL_ADD_VIDEO:
            state = {
                ...state,
                loading: true,
                error: null,
            };
            break;

        case MODEL_ADD_VIDEO_SUCCESS:
            state = {
                ...state,
                loading: false,
            };
            break;

        case MODEL_ADD_VIDEO_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        case MODEL_ADD_VIDEO_CLEAN_STATE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default add;