import {
    MODEL_PANEL_HISTORY_FETCH_LIST,
    MODEL_PANEL_HISTORY_FETCH_LIST_SUCCESS,
    MODEL_PANEL_HISTORY_FETCH_LIST_ERROR,
    MODEL_PANEL_HISTORY_LIST_CLEAN_STATE,

    MODEL_PANEL_HISTORY_LIST_SET_PAGE,
} from './actionTypes'

export const modelPanelHistoryFetchList = (id, params) => {
    return {
        type: MODEL_PANEL_HISTORY_FETCH_LIST,
        payload: { id, params },
    }
};

export const modelPanelHistoryFetchListSuccess = (data) => {
    return {
        type: MODEL_PANEL_HISTORY_FETCH_LIST_SUCCESS,
        payload: data,
    }
};

export const modelPanelHistoryFetchListError = (data) => {
    return {
        type: MODEL_PANEL_HISTORY_FETCH_LIST_ERROR,
        payload: data,
    }
};

export const modelPanelHistoryListCleanState = () => {
    return { type: MODEL_PANEL_HISTORY_LIST_CLEAN_STATE };
};

export const modelPanelHistoryListSetPage = (data) => {
    return {
        type: MODEL_PANEL_HISTORY_LIST_SET_PAGE,
        payload: data,
    }
};