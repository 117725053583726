import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Col, Row } from "reactstrap"
import * as Yup from "yup"
import {
  Button,
  ControllerHF,
  FilePickerField,
  InputField,
} from "../../../../../../components"
import { FileUtils, ValidationsUtils } from "../../../../../../utils"
import { FILE_MAX_SIZE } from "../../../../../../consts/common/maxFileSize"

export const DocumentsForm = props => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      ...props.initialValues,
      file: props.initialValues?.attachment
        ? { attachment: props.initialValues.attachment }
        : null,
    },
    resolver: yupResolver(
      Yup.object().shape({
        title: Yup.string().required("field.error.required"),

        file: Yup.mixed()
          .test("maxSize", "field.error.file-size.max", value =>
            ValidationsUtils.fileMaxSize(value, FILE_MAX_SIZE)
          )
          .required("field.error.required"),
      })
    ),
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      title: values.title,
      file: values.file?.file ? values.file?.file : "",
    }
    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <ControllerHF
              name={"title"}
              control={control}
              component={InputField}
              id={"title"}
              label={t("field.label.name")}
              placeholder={t("field.placeholder.name")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <ControllerHF
              name={"file"}
              control={control}
              component={FilePickerField}
              fileNamePlaceholderText={t(
                "file-picker.file-placeholder.document"
              )}
              id={"file"}
              pickerLabelText={t("file-picker.file-placeholder.document")}
              onDownload={FileUtils.downloadFileByLink}
              accept={
                "image/jpeg, image/png, image/gif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              }
              translateParams={{
                text: `${FileUtils.formatBytes(FILE_MAX_SIZE)}`,
              }}
              meta={{ error: errors.file }}
            />
          </Col>
        </Row>
        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button title={"cancel"} color="light" outline onClick={onCancel} />
          </div>
        </div>
      </form>
    </>
  )
}

DocumentsForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
