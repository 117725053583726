import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from "toastr";
import _ from "lodash";

import i18n from '../../../../i18n';
import { MODEL_PANEL_RATING_ADD_RATING } from './actionTypes';
import { modelPanelRatingAddRatingSuccess, modelPanelRatingAddRatingError } from './actions';
import { modelProfileUpdateData } from '../../../model/profile/actions';

import { API } from '../../../../api';

function* addRating({ payload: { id, params, onSuccess, onError } }) {
    try {
        const response = yield call(API.model.addModelRating, id, params);

        const { data } = response;

        yield put(modelPanelRatingAddRatingSuccess());

        if (_.has(data.data, 'avg_rating')) {
            yield put(modelProfileUpdateData({ avg_rating: data?.data?.avg_rating }));
        }

        toastr.success(i18n.t('rating.add.success'));

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        const errors = error.response?.data?.errors || {};
        const message = error.response?.data?.message || i18n.t('error');

        yield put(modelPanelRatingAddRatingError({
            errors,
            message
        }));

        if (onError) {
            onError(errors);
        }
    }
}

export function* watchAddRating() {
    yield takeEvery(MODEL_PANEL_RATING_ADD_RATING, addRating);
}

function* addSaga() {
    yield all([
        fork(watchAddRating),
    ]);
}

export default addSaga;