export const getStatusConfig = (status, t) => {
    let data = {
        label: '',
        color: '',
    };
    switch (status) {
        case '1':
            data = {
                label: t(''),
                value: '1',
                color: 'warning',
            };
            break;

        case 2:
            data = {
                label: t(''),
                value: '2',
                color: 'success',
            };
            break;

        default:
            data = { ...data };
            break;
    }
    return data;
};