import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Alert, Button as StrapButton } from "reactstrap"
import { useTranslation } from "react-i18next"
import { Formik, Form } from "formik"
import * as Yup from "yup"

import {
  FormikTextInputFiled as TextInputFiled,
  Button,
  FormikComboboxFiled,
} from "../../../../components"
import { employeeHelper } from "../../../../helpers"

const CreateEmployeeFormComponent = props => {
  const { t } = useTranslation()

  const onSubmit = (values, onSubmitProps) => {
    const params = {
      ...values,
      department: values.department.value,
    }

    props.onSubmit(params, onSubmitProps)
  }

  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("field.error.invalid")
          .required("field.error.required"),
        name: Yup.string().required("field.error.required"),
        department: Yup.object().nullable().required("field.error.required"),
      })}
      onSubmit={onSubmit}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {({ isSubmitting, ...rest }) => {
        return (
          <>
            {props.error && <Alert color="danger">{props.error.message}</Alert>}

            <Form className="form-horizontal" noValidate={true}>
              <TextInputFiled
                name="email"
                label={t("field.label.email")}
                placeholder={t("field.placeholder.email")}
                id={"email"}
                type="email"
              />

              <TextInputFiled
                name="name"
                label={t("field.label.fullname")}
                placeholder={t("field.placeholder.fullname")}
                id={"name"}
                type="text"
              />

              <FormikComboboxFiled
                name={"department"}
                id={"department"}
                label={t("department")}
                placeholder={t("department")}
                options={employeeHelper.general.getDepartmentOptions()}
                selectProps={{
                  isSearchable: false,
                }}
              />

              <div className="mt-3">
                <div className="button-items">
                  <Button
                    submit
                    title={"btn.create"}
                    disabled={isSubmitting || props.loading}
                    loading={props.loading}
                  />

                  <StrapButton
                    type="button"
                    color="light-gray"
                    outline
                    className="waves-effect"
                    onClick={props.onCancel}
                  >
                    {t("cancel")}
                  </StrapButton>
                </div>
              </div>
            </Form>
          </>
        )
      }}
    </Formik>
  )
}

CreateEmployeeFormComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const CreateEmployeeForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEmployeeFormComponent)
