import React from 'react';
import PropTypes from "prop-types"
import { Container } from "reactstrap";

import { Header } from './Topbar';
import { Sidebar } from './Sidebar';
import { PanelTopBarProvider } from '../../../../../contexts';

export const PanelLayout = (props) => {
    return (
        <PanelTopBarProvider>
            <React.Fragment>
                <div className="page-content page-content_sidebar">
                    <div className={'page-content__container'}>
                        <Container fluid>
                            <Header />

                            <div>
                                {props.children}
                            </div>
                        </Container>
                    </div>

                    <div className={'page-content__sidebar'}>
                        <Sidebar />
                    </div>
                </div>
            </React.Fragment>
        </PanelTopBarProvider>
    );
};

PanelLayout.propTypes = {
    children: PropTypes.any,
    topBarOptions: PropTypes.object,
}