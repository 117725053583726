import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { useTranslation } from "react-i18next"
import _ from "lodash"

import { clientHelper, eventsCalendarHelper } from "helpers"
import { FormatUtils } from "utils"
import {
  EVENT_TYPE_ACCOMMODATION,
  EVENT_TYPE_ARRIVAL,
  EVENT_TYPE_DEPARTURE,
  EVENT_TYPE_TRAVEL,
} from "consts"

const { isOtherCategoryEvent } = eventsCalendarHelper.eventConfig

export const EventInfo = ({ event }) => {
  const { t } = useTranslation()

  const renderArrival = () => {
    const { date_start, time_start, date_end, time_end, type, arrival_place } =
      event

    let date = type === EVENT_TYPE_ARRIVAL ? date_start : date_end
    let time = type === EVENT_TYPE_ARRIVAL ? time_start : time_end

    return (
      <div>
        <div>
          {moment(date).format("DD/MM/YYYY")}{" "}
          {time && moment(time, "HH:mm").format("HH:mm")}
        </div>
        {arrival_place && <div>{arrival_place}</div>}
      </div>
    )
  }

  const info = [
    {
      label: t("title"),
      value: eventsCalendarHelper.calendar.getCalendarEventTitle(event),
    },
    {
      label: t("period"),
      value: (
        <>
          {moment(event.date_start).format("DD/MM/YYYY")}{" "}
          {event.time_start &&
            moment(event.time_start, "HH:mm").format("HH:mm")}
          {" - "}
          {moment(event.date_end).format("DD/MM/YYYY")}{" "}
          {event.time_end && moment(event.time_end, "HH:mm").format("HH:mm")}
        </>
      ),
      hidden:
        !isOtherCategoryEvent(event.type) ||
        [EVENT_TYPE_DEPARTURE, EVENT_TYPE_ARRIVAL, EVENT_TYPE_TRAVEL].includes(
          event.type
        ),
    },
    {
      label: t("departure"),
      value: (
        <div>
          <div>
            {moment(event.date_start).format("DD/MM/YYYY")}{" "}
            {event.time_start &&
              moment(event.time_start, "HH:mm").format("HH:mm")}
          </div>
          {event.departure_place && <div>{event.departure_place}</div>}
        </div>
      ),
      hidden:
        !isOtherCategoryEvent(event.type) ||
        [EVENT_TYPE_ACCOMMODATION, EVENT_TYPE_ARRIVAL].includes(event.type),
    },
    {
      label: t("arrival"),
      value: renderArrival(),
      hidden:
        !isOtherCategoryEvent(event.type) ||
        [EVENT_TYPE_ACCOMMODATION, EVENT_TYPE_DEPARTURE].includes(event.type),
    },
    {
      label: t("time"),
      value:
        event.times &&
        event.times.map((item, index) => (
          <div key={index}>
            {item.start} {item.end ? `- ${item.end}` : null}
          </div>
        )),
      hidden: !event.times || event.times?.length === 0,
    },
    {
      label: t("client"),
      value: event.client?.name,
      hidden: !event.client,
    },
    {
      label: t("family"),
      value: clientHelper.family.getFamily(event.family)?.label,
      hidden: !event.family,
    },
    {
      label: t("product"),
      value: event.product,
      hidden: !event.product,
    },
    {
      label: t("agency"),
      value: event.agency?.name,
      hidden: !event.agency,
    },
    {
      label: t("note_models"),
      value: event.note_models,
      hidden: !event.note_models,
    },
  ]

  const renderRow = ({ hidden, label, value }) =>
    !hidden && (
      <div>
        {label && <span className={"fw-bold"}>{label}: </span>}
        {value}
      </div>
    )

  const renderLocation = () => {
    const location =
      _.find(event.locations, { is_primary: true }) || event.locations[0]

    const { street, town, state, zip, access, phone, country } = location
    const rows = [
      {
        value: country?.name,
        hidden: !country,
      },
      {
        value: state,
        hidden: !state,
      },
      {
        value: town,
        hidden: !town,
      },
      {
        value: street,
        hidden: !street,
      },
      {
        value: zip,
        hidden: !zip,
      },
      {
        value: access,
        hidden: !access,
      },
      {
        value: FormatUtils.formatPhone(phone),
        hidden: !phone,
      },
    ]

    return (
      <div>
        <span className={"fw-bold"}>{t("location")}: </span>

        {rows.map((item, i) => (
          <React.Fragment key={i}>{renderRow(item)}</React.Fragment>
        ))}
      </div>
    )
  }

  return (
    <div>
      {info.map((item, i) => (
        <React.Fragment key={i}>{renderRow(item)}</React.Fragment>
      ))}

      {event.locations && !!event.locations.length ? renderLocation() : null}
    </div>
  )
}

EventInfo.propTypes = {
  event: PropTypes.object,
}
