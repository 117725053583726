import * as postConfig from "./postConfig"
import * as calendarConfig from "./calendar"
import * as postInfo from "./postInfo"
import * as postForms from "./postForms"

export const socialPostsCalendarHelper = {
  postConfig,
  calendarConfig,
  postInfo,
  postForms,
}
