import {
  MODEL_PANEL_GENERAL_LOGISTICS_DETAILS_EDIT,
  MODEL_PANEL_GENERAL_LOGISTICS_DETAILS_EDIT_SUCCESS,
  MODEL_PANEL_GENERAL_LOGISTICS_DETAILS_EDIT_ERROR,
  MODEL_PANEL_GENERAL_LOGISTICS_DETAILS_EDIT_CLEAN_STATE,
} from "./actionTypes"

export const modelPanelGeneralLogisticsDetailsEdit = (
  id,
  params,
  onSuccess,
  onError
) => {
  return {
    type: MODEL_PANEL_GENERAL_LOGISTICS_DETAILS_EDIT,
    payload: { id, params, onSuccess, onError },
  }
}

export const modelPanelGeneralLogisticsDetailsEditSuccess = () => {
  return {
    type: MODEL_PANEL_GENERAL_LOGISTICS_DETAILS_EDIT_SUCCESS,
  }
}

export const modelPanelGeneralLogisticsDetailsEditError = data => {
  return {
    type: MODEL_PANEL_GENERAL_LOGISTICS_DETAILS_EDIT_ERROR,
    payload: data,
  }
}

export const modelPanelGeneralLogisticsDetailsEditCleanState = () => {
  return { type: MODEL_PANEL_GENERAL_LOGISTICS_DETAILS_EDIT_CLEAN_STATE }
}
