import React from "react"
import PropTypes from "prop-types"
import { Row } from "reactstrap"
import { useTranslation } from "react-i18next"

import { ClientsGridItem } from "./ClientsGridItem"
import { Pagination } from "components"

import * as HOC from "../../../../../HOC"

const DataShower = HOC.withDataShowerOverlay()

export const ClientsGrid = props => {
  const { t } = useTranslation()

  return (
    <Row>
      <div className={"clients-panel-grid mb-4"}>
        <DataShower
          isLoading={props.loading}
          isFailed={props.error}
          error={props.error}
        >
          {!!props.items.length ? (
            <>
              <div className="grid">
                <div className="grid__row">
                  {props.items.map((item, i) => (
                    <div
                      className="grid__col"
                      key={`client-grid-item-${item.id}`}
                    >
                      <div className="grid__item">
                        <ClientsGridItem item={item} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <Pagination data={props.meta} onPageChange={props.onPageChange} />
            </>
          ) : !props.loading ? (
            t("clients.filter.empty")
          ) : null}
        </DataShower>
      </div>
    </Row>
  )
}

ClientsGrid.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,

  onPageChange: PropTypes.func,
}
