import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Row } from "reactstrap";

import { Drawer } from '../../../../../../components';
import { EditPhotoForm } from './EditPhotoForm';
import {
    modelPanelPhotosPhotoDetailsEditPhotoUpdate,
    modelPanelPhotosPhotoDetailsEditPhotoUpdateCleanState,
} from '../../../../../../store/actions';
import { ValidationsUtils } from "../../../../../../utils"

const EditPhotoComponent = (props) => {
    const { t } = useTranslation();

    const handleCloseDrawer = () => {
        props.onClose();
    }

    const onSubmitSuccess = (onSubmitProps) => () => {
        const { reset } = onSubmitProps;
        reset();

        props.onEditSuccess();
        props.onClose();
    }

    const onSubmitError = (onSubmitProps) => (errors) => {
        const { setError } = onSubmitProps;
        ValidationsUtils.setServerSideErrors(errors, setError);
    }

    const handleSubmit = (params, onSubmitProps) => {
        props.modelPanelPhotosPhotoDetailsEditPhotoUpdate(
            props.photo.id,
            params,
            onSubmitSuccess(onSubmitProps),
            onSubmitError(onSubmitProps),
        );
    };

    const onDestroy = () => {
        props.modelPanelPhotosPhotoDetailsEditPhotoUpdateCleanState();
        props.onDestroy();
    }

    return (
        <React.Fragment>
            <Drawer
                title={t('model.edit_photo')}
                visible={props.visible}
                onClose={handleCloseDrawer}
                placement={'right'}
                className={'drawer-container model-edit-photo-drawer'}
                destroyOnClose
                onDestroy={onDestroy}
                drawerProps={{}}
            >
                <Container fluid>
                    <Row>
                        <EditPhotoForm
                            photo={props.photo}
                            loading={props.loading}
                            error={props.error}
                            onSubmit={handleSubmit}
                            onCancel={handleCloseDrawer}
                        />
                    </Row>
                </Container>
            </Drawer>
        </React.Fragment>
    );
};

EditPhotoComponent.propTypes = {
    visible: PropTypes.bool,
    photo: PropTypes.object,
    onEditSuccess: PropTypes.func,
    onClose: PropTypes.func,
    onDestroy: PropTypes.func,

    loading: PropTypes.bool,
    error: PropTypes.object,
    model: PropTypes.object,

    modelPanelPhotosPhotoDetailsEditPhotoUpdate: PropTypes.func,
    modelPanelPhotosPhotoDetailsEditPhotoUpdateCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { data } = state.model.profile;
    const { loading, error } = state.modelPanel.photos.details.editPhoto.edit;
    return {
        model: data,
        loading, error,
    };
};

const mapDispatchToProps = {
    modelPanelPhotosPhotoDetailsEditPhotoUpdate,
    modelPanelPhotosPhotoDetailsEditPhotoUpdateCleanState,
};

export const EditPhoto = connect(mapStateToProps, mapDispatchToProps)(EditPhotoComponent);