import * as Yup from "yup"

import { EditorUtils } from "../../../utils"

export const validationSchema = Yup.object().shape({
  subject: Yup.string().required("field.error.required"),
  // message: Yup.mixed().test(
  //   "draft-editor-required",
  //   "field.error.required",
  //   (value) => !EditorUtils.draftJs.isContentEmpty(value)
  // ),
  recipients: Yup.mixed().test(
    "recipient-required",
    "field.error.recipients.empty",
    value => value.length > 0
  ),
  books_notes: Yup.array().of(
    Yup.object().shape({
      id: Yup.mixed().nullable().required("field.error.required"),
      text: Yup.string().nullable().required("field.error.required"),
    })
  ),
})
