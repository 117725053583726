import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FacebookPermissionsButton, HelpPopover, PageHeader } from "components"
import { useFacebookAuth } from "packages/facebook"
import moment from "moment"

export const Header = props => {
  const { t } = useTranslation()
  const { isLoggedIn, account } = useFacebookAuth()

  const expiry_date = useMemo(() => {
    if (account?.expiry_date) {
      return moment(account?.expiry_date).format("YYYY-MM-DD")
    }
  }, [account])

  const renderTitle = () => {
    return (
      <div className={"d-flex align-items-center"}>
        <div className={"mr-2"}>{t("title.social-media-calendar")}</div>
        {isLoggedIn ? (
          <>
            <FacebookPermissionsButton size={"sm"} responsive />{" "}
            <div className={"ms-2 d-flex align-items-center"}>
              <HelpPopover
                content={t("social-calendar.permissions.recommendations", {
                  expiry_date: expiry_date,
                })}
              />
            </div>
          </>
        ) : null}
      </div>
    )
  }

  return <PageHeader title={renderTitle()} />
}

Header.propTypes = {}
