import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { modelHelper } from "helpers"

export const TravelRow = ({ item }) => {
  return (
    <>
      <td>
        <span>{item.departure_place}</span>

        <span>
          {" - "}
          {item.arrival_place}
        </span>
      </td>

      <td>
        <span>{moment(item.departure_date).format("DD/MM/YYYY")}</span>
        <span>
          {" - "}
          {moment(item.arrival_date).format("DD/MM/YYYY")}
        </span>
      </td>

      <td>
        <span>
          {
            modelHelper.logistics.getLogisticsTravelAsOption(item.travel_by)
              .label
          }
        </span>
      </td>
    </>
  )
}

TravelRow.propTypes = {
  item: PropTypes.object,
}
