import { all } from 'redux-saga/effects';

import editSaga from './edit/saga';

function* contactsSaga() {
    yield all([
        editSaga(),
    ])
}

export default contactsSaga;