import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { MegaTagTitle } from "components"
import { HomeContainer } from "./components"

export const Home = props => {
  return (
    <div className={"website-home-panel"}>
      <MegaTagTitle title={"title.website.home"} />
      <Row>
        <Col>
          <HomeContainer alias={props.alias} />
        </Col>
      </Row>
    </div>
  )
}

Home.propTypes = {
  alias: PropTypes.string,
}
