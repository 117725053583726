import React, { useMemo } from "react"
import PropTypes from "prop-types"

import { EventsEmailSend as EventsEmailSendComponent } from "components"

export const EventsEmailSend = ({
  data,
  visible,
  onClose,
  onDestroy,
  onSuccess,
}) => {
  const initialRecipients = useMemo(() => {
    let recipients = []
    if (data?.model) {
      const { id, name, emails } = data?.model
      recipients = [
        {
          id,
          name,
          emails: emails.map(item => item?.value),
        },
      ]
    }
    return recipients
  }, [data?.model])

  return (
    <EventsEmailSendComponent
      modelId={data?.model.id}
      date={data?.date}
      initialRecipients={initialRecipients}
      onSuccess={onSuccess}
      visible={visible}
      onClose={onClose}
      onDestroy={onDestroy}
    />
  )
}

EventsEmailSend.propTypes = {
  data: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  onSuccess: PropTypes.func,
}
