import React, { useEffect } from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Row } from "reactstrap";

import { Drawer } from '../../../../../../components';
import { DocumentForm } from '../DocumentForm';
import { modelPanelGeneralAddDocument, modelPanelGeneralAddDocumentCleanState } from '../../../../../../store/actions';
import { useCountryOptions } from '../../../../../../hooks';
import { ValidationsUtils } from "../../../../../../utils"

const AddDocumentComponent = (props) => {
    const { t } = useTranslation();

    const countryOptions = useCountryOptions({ visible: props.visible })

    useEffect(() => {
        return () => {
            props.modelPanelGeneralAddDocumentCleanState();
        }
    }, []);

    const handleCloseDrawer = () => {
        props.onClose();
    }

    const onSubmitSuccess = (onSubmitProps) => () => {
        const { onReset } = onSubmitProps;
        onReset();

        props.onAddSuccess();
        props.onClose();
    }

    const onSubmitError = (onSubmitProps) => (errors) => {
        const { setError } = onSubmitProps;
        ValidationsUtils.setServerSideErrors(errors, setError);
    }

    const handleSubmit = (params, onSubmitProps) => {
        props.modelPanelGeneralAddDocument(props.model.id, params, onSubmitSuccess(onSubmitProps), onSubmitError(onSubmitProps));
    };

    return (
        <React.Fragment>
            <Drawer
                title={t('document')}
                visible={props.visible}
                onClose={handleCloseDrawer}
                placement={'right'}
                className={'drawer-container'}
                drawerProps={{}}
            >
                <Container fluid>
                    <Row>
                        <DocumentForm
                            initialValues={{}}
                            loading={props.loading}
                            error={props.error}
                            onSubmit={handleSubmit}
                            onCancel={handleCloseDrawer}
                            countryOptions={countryOptions}
                        />
                    </Row>
                </Container>
            </Drawer>
        </React.Fragment>
    );
};

AddDocumentComponent.propTypes = {
    visible: PropTypes.bool,
    onAddSuccess: PropTypes.func,
    onClose: PropTypes.func,

    loading: PropTypes.bool,
    error: PropTypes.object,
    model: PropTypes.object,

    modelPanelGeneralAddDocument: PropTypes.func,
    modelPanelGeneralAddDocumentCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { data } = state.model.profile;
    const { loading, error } = state.modelPanel.general.documents.add;
    return {
        model: data,
        loading, error,
    };
};

const mapDispatchToProps = {
    modelPanelGeneralAddDocument,
    modelPanelGeneralAddDocumentCleanState,
};

export const AddDocument = connect(mapStateToProps, mapDispatchToProps)(AddDocumentComponent);