import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const clientsAddClientSlice = createSlice({
  name: "clientsAddClient",
  initialState,
  reducers: {
    add(state) {
      state.loading = true
      state.error = null
    },
    addCompleted(state) {
      state.loading = false
    },
    addRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} add
 * @property {function} addCompleted
 * @property {function} addRejected
 *  @property {function} cleanState
 */
export const clientsAddClient = clientsAddClientSlice.actions

export default clientsAddClientSlice.reducer
