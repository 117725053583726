import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Drawer } from "../../../../../../components"
import { Container, Row } from "reactstrap"
import { ValidationsUtils } from "../../../../../../utils"
import { AttachmentsForm } from "../AttachmentsForm"
import { useTranslation } from "react-i18next"

import {  agencyUpdateAttachment } from "../../../../../../store/actions"

const EditAttachmentsContainerComponent = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const handleCloseDrawer = () => {
        props.onClose()
    }
    const onSubmitSuccess = onSubmitProps => () => {
        const { reset } = onSubmitProps
        reset()
        props.onEditSuccess()
        handleCloseDrawer()
    }

    const onSubmitError = onSubmitProps => errors => {
        const { setError } = onSubmitProps
        ValidationsUtils.setServerSideErrors(errors, setError)
    }

    const handleSubmit = (params, onSubmitProps) => {
        dispatch(
          agencyUpdateAttachment.update({
              id: props.attachment.id,
              params,
              onSuccess: onSubmitSuccess(onSubmitProps),
              onError: onSubmitError(onSubmitProps),
          })
        )
    }

    const onDestroy = () => {
        dispatch(agencyUpdateAttachment.cleanState())
        props.onDestroy()
    }
    return (
        <>
            <Drawer
                title={t("attachments")}
                visible={props.visible}
                onClose={handleCloseDrawer}
                placement={"right"}
                className={"drawer-container"}
                destroyOnClose
                onDestroy={onDestroy}
            >

                    <Container fluid>
                        <Row>
                            <AttachmentsForm
                                onSubmit={handleSubmit}
                                onCancel={handleCloseDrawer}
                                initialValues={{ ...props.attachment }}
                                loading={props.loading}
                                error={props.error}
                            />
                        </Row>
                    </Container>

            </Drawer>
        </>
    )
}

EditAttachmentsContainerComponent.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    attachment: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.object,
    onDestroy: PropTypes.func,
    onEditSuccess: PropTypes.func,
    agency: PropTypes.object
}

const mapStateToProps = state => {
    const { data } = state.agency.profile.data
    const { loading, error } = state.agency.attachments.add

    return {
        agency: data,
        loading,
        error,
    }
}

export const EditAttachments = connect(mapStateToProps)(EditAttachmentsContainerComponent)
