import { all } from "redux-saga/effects"

import contactsSaga from "./contacts/sagas"
import chatsSaga from "./chats/sagas"
import conversationSaga from "./conversation/sagas"
import groupsSaga from "./groups/sagas"

function* chatSaga() {
  yield all([contactsSaga(), chatsSaga(), conversationSaga(), groupsSaga()])
}

export default chatSaga
