import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "../../../../i18n"
import { calendarEventUploadFile } from "./slice"
import { API } from "../../../../api"

function* uploadFile({ payload: { params, onSuccess, onError } }) {
  try {
    const { id, file } = params
    const response = id
      ? yield call(API.calendar.uploadEventFile, id, { file })
      : yield call(API.calendar.uploadFileForNewEvent, { file })

    const { data } = response

    yield put(calendarEventUploadFile.uploadSuccess())

    if (onSuccess) {
      onSuccess(data?.data)
    }

    if (id) {
      toastr.success(i18n.t("event.update.success"))
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(calendarEventUploadFile.uploadError(message))

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchUploadFile() {
  yield takeEvery(calendarEventUploadFile.upload, uploadFile)
}

function* uploadSaga() {
  yield all([fork(watchUploadFile)])
}

export default uploadSaga
