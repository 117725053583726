import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { Col, Row } from "reactstrap"
import { ControllerHF } from "../../../../ReactHookForm"
import { ComboboxFormField, DateInputField } from "../../../../FormElements"
import { modelHelper } from "../../../../../helpers"

export const DateSection = props => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext()

  const watchDate = watch("date")

  return (
    <>
      <Row>
        <Col md={5}>
          <ControllerHF
            name={"type"}
            control={control}
            component={ComboboxFormField}
            id={"type"}
            label={t("date")}
            placeholder={t("date")}
            options={modelHelper.rate.getRateTypes()}
            normalize={option => (option ? option.value : null)}
            isDetermineValue
            disabled={props.isEdit}
            selectProps={{
              isSearchable: false,
            }}
          />
        </Col>
      </Row>

      {props.isEdit && watchDate && (
        <Row>
          <Col md={5}>
            <ControllerHF
              name={"date"}
              control={control}
              component={DateInputField}
              id={"date"}
              label={t("event-date")}
              placeholder={"DD/MM/YYYY"}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
              disabled
            />
          </Col>
        </Row>
      )}
    </>
  )
}

DateSection.propTypes = {
  isEdit: PropTypes.bool,
}
