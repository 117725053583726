import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

export const RequestDetails = ({ data }) => {
  const { t } = useTranslation()

  data = data || {}

  const renderItem = item => (
    <div className="info-list__block">
      <div className="info-list__item">
        <div className={"info-list__label"}>{t(item.label)}</div>
        <div className={"info-list__space"} />
        <div className={"info-list__info"}>{item.value || "-"}</div>
      </div>
    </div>
  )

  const generalInfo = useMemo(
    () => [
      {
        label: "name",
        value: data.name,
      },
      {
        label: "email",
        value: data.email,
      },
      {
        label: "mobile_phone",
        value: data.mobile_phone,
      },
      {
        label: "residence_phone",
        value: data.residence_phone,
      },
    ],
    [data]
  )
  const addressInfo = useMemo(
    () => [
      {
        label: "country",
        value: data.country?.name,
      },
      {
        label: "city",
        value: data.city,
      },
      {
        label: "street",
        value: data.street,
      },
    ],
    [data]
  )
  const measuments = useMemo(
    () => [
      {
        label: "height",
        value: data.height,
      },
      {
        label: "size",
        value: data.size,
      },
      {
        label: "bust",
        value: data.bust,
      },
      {
        label: "waist",
        value: data.waist,
      },
      {
        label: "hips",
        value: data.hips,
      },
      {
        label: "shoes",
        value: data.shoes,
      },
      {
        label: "eyes",
        value: data.eyes,
      },
      {
        label: "hair",
        value: data.hair,
      },
    ],
    [data]
  )

  return (
    <Col className={"website-request-info"}>
      <Row>
        <Col md={6}>
          {generalInfo.map((item, i) => (
            <div key={`general-${i}`}>{renderItem(item)}</div>
          ))}
        </Col>
        <Col md={6}>
          {addressInfo.map((item, i) => (
            <div key={`address-${i}`}>{renderItem(item)}</div>
          ))}
        </Col>
      </Row>
      <hr className="drawer-separator drawer-separator_form" />

      <Row>
        {measuments.map((item, i) => (
          <Col md={3} key={`measuments-${i}`}>
            {renderItem(item)}
          </Col>
        ))}
      </Row>
      <hr className="drawer-separator drawer-separator_form" />
      <Row>
        {data.attachments?.map((item, i) => (
          <div key={`attachments-${i}`} className={"image-block"}>
            <img
              src={item.attachment?.src}
              alt={item.attachment?.name}
              className={"cover-content"}
            />
          </div>
        ))}
      </Row>
    </Col>
  )
}

RequestDetails.propTypes = {
  data: PropTypes.object,
}
