import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Button } from "../../../Buttons"
import { Modal } from "../../../Modal"
import { AttachmentListTable } from "../AttachmentListTable"
import { ValidationsUtils } from "../../../../utils"
import { AttachmentForm } from "../AttachmentForm"
import { OverlayBlockLoading } from "../../../OverlayLoading"

export const AttachmentListContainer = props => {
  const { t } = useTranslation()

  const modalRef = useRef(null)

  const [isEdit, setIsEdit] = useState(false)
  const [attachment, setAttachment] = useState(null)

  const onSubmitSuccess = onSubmitProps => () => {
    const { onReset } = onSubmitProps
    closeModal()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const onSubmit = (values, onSubmitProps) => {
    const payload = {
      onSuccess: onSubmitSuccess(onSubmitProps),
      onError: onSubmitError(onSubmitProps),
    }

    if (!isEdit) {
      props.onAdd(values, payload)
    } else {
      props.onEdit(values, payload)
    }
  }

  const handleAdd = e => {
    e.preventDefault()
    isEdit && setIsEdit(false)
    if (modalRef.current) {
      modalRef.current.show()
    }
  }

  const handleEdit = item => {
    setIsEdit(true)
    if (modalRef.current) {
      modalRef.current.show()
    }
    setAttachment({ ...item })
  }

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.hide()
    }
  }

  const onModalClosed = () => {
    setAttachment(null)
  }

  return (
    <div className="">
      <Button title={"btn.add-attachment"} onClick={handleAdd} />

      {props.value && props.value.length > 0 ? (
        <OverlayBlockLoading isLoading={props.generalLoading}>
          <AttachmentListTable
            data={props.value}
            onEdit={handleEdit}
            onRemove={props.onRemove}
          />
        </OverlayBlockLoading>
      ) : (
        <div className={"mt-3"}>{t("no_attachments")}</div>
      )}

      <Modal
        ref={modalRef}
        title={t("attachment")}
        strapModalProp={{
          centered: true,
          onClosed: onModalClosed,
        }}
      >
        <AttachmentForm
          loading={props.loading}
          error={props.error}
          onSubmit={onSubmit}
          onCancel={closeModal}
          initialValues={{ ...attachment }}
          onFormDestroy={props.onFormDestroy}
          attachmentId={props.attachmentId}
          isEdit={isEdit}
        />
      </Modal>
    </div>
  )
}

AttachmentListContainer.propTypes = {
  value: PropTypes.any,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.any,
  className: PropTypes.string,
  generalLoading: PropTypes.bool,
  onFormDestroy: PropTypes.func,
  attachmentId: PropTypes.number,
}
