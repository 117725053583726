import * as Yup from "yup"

export const validationSchema = Yup.object().shape({
  subject: Yup.string().required("field.error.required"),
  message: Yup.string(),
  recipients: Yup.array()
    .of(
      Yup.object().shape({
        emails: Yup.mixed().test(
          "email-required",
          "field.error.recipients.email-required",
          value => value.length > 0
        ),
      })
    )
    .test(
      "recipient-required",
      "field.error.recipients.empty.add",
      value => value.length > 0
    ),
})
