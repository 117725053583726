/* eslint react/prop-types: off */
import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { useUserAccess } from "hooks"
import { DEPARTMENT_ACCOUNTING } from "consts"
import {
  ReactTableView,
  StatementPaymentStatusBadge,
  StatementReminder,
} from "components"
import { Edit } from "./Edit"
import { DateUtils } from "utils"

import * as HOC from "HOC"

const TableDataLoading = HOC.withTableDataLoading()

export const StatementTable = props => {
  const { t } = useTranslation()

  const { isDirector, isDepartmentAllow } = useUserAccess()

  const data = useMemo(() => {
    return props.list
  }, [props.list])

  const columnsConfig = [
    {
      Header: t("date"),
      accessor: "date",
      id: "date",
      Cell: ({ value }) => {
        return value ? moment(value).format("DD/MM/YYYY") : "-"
      },
    },
    {
      Header: t("description"),
      accessor: "description",
      id: "description",
      Cell: ({ value }) => value,
    },
    {
      Header: t("invoice"),
      accessor: "invoice_number",
      id: "invoice_number",
      Cell: ({ value }) => {
        return value || null
      },
    },
    {
      Header: t("amount"),
      accessor: "amount",
      id: "amount",
      Cell: ({ row: { original } }) => original?.total,
    },
    {
      Header: t("payment_deadline"),
      accessor: "deadline",
      id: "deadline",
      Cell: ({ value }) => {
        return value ? DateUtils.utcToLocale(value, "DD/MM/YYYY HH:mm") : "-"
      },
    },
    {
      Header: () => <div className={"w-100 text-center"}>{t("status")}</div>,
      accessor: "status",
      id: "status",
      Cell: ({ value }) => (
        <div className="d-flex align-items-center justify-content-center">
          <StatementPaymentStatusBadge value={value} />
        </div>
      ),
    },
    {
      Header: () => <div className={"d-print-none"} />,
      id: "action",
      Cell: ({ row: { original } }) => (
        <div className="d-flex align-items-center justify-content-end d-print-none">
          {!!original?.alerts?.length && (
            <StatementReminder id={original?.id} alerts={original?.alerts} />
          )}
          <Edit
            disabled={props.disabled}
            onClick={() => props.onEdit(original)}
          />
        </div>
      ),
    },
  ]

  const columns = useMemo(() => {
    return columnsConfig
  }, [columnsConfig, props.disabled])

  return (
    <TableDataLoading
      isLoading={props.loading}
      isFailed={props.error}
      error={props.error}
    >
      <ReactTableView
        isLoading={props.loading}
        columns={columns}
        data={data}
        initialState={{
          hiddenColumns:
            isDirector() || isDepartmentAllow([DEPARTMENT_ACCOUNTING])
              ? []
              : ["action"],
        }}
      />
    </TableDataLoading>
  )
}

StatementTable.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  onEdit: PropTypes.func,
  disabled: PropTypes.bool,
}
