import {
    BOOK_COMMON_FETCH_BOOK,
    BOOK_COMMON_FETCH_BOOK_SUCCESS,
    BOOK_COMMON_FETCH_BOOK_ERROR,
    BOOK_COMMON_FETCH_BOOK_CLEAN_STATE,
} from './actionTypes';

export const bookCommonFetchBook = (id, onSuccess, onError) => {
    return {
        type: BOOK_COMMON_FETCH_BOOK,
        payload: { id, onSuccess, onError },
    }
};

export const bookCommonFetchBookSuccess = (data) => {
    return {
        type: BOOK_COMMON_FETCH_BOOK_SUCCESS,
        payload: data,
    }
};

export const bookCommonFetchBookError = (data) => {
    return {
        type: BOOK_COMMON_FETCH_BOOK_ERROR,
        payload: data,
    }
};

export const bookCommonFetchBookCleanState = () => {
    return { type: BOOK_COMMON_FETCH_BOOK_CLEAN_STATE };
};