import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { validationSchema } from "./validation"
import { Alert } from "reactstrap"
import {
  DateSection,
  AttachFilesSection,
  AlertSection,
  TimeSection,
  ClientAndLocationSection,
  NotesSection,
  ModelsSection,
} from "../EventFormSections"
import { Button } from "../../../Buttons"
import {
  getDatePeriodDefaultValues,
  getTimePeriodsDefaultValues,
  getAttachFilesDefaultValues,
  getModelsDefaultValues,
  mapDatePeriodSubmitValues,
  getMapLocationsDefaultValues,
  mapLocationsSubmitValues,
  getClientDefaultValues,
  getNotesDefaultValues,
  getAlertDefaultValues,
  getClientsInfoDefaultValues,
  mapClientSubmitValues,
  mapTimePeriodsSubmitValues,
  mapModelsSubmitValues,
  mapAlertsSubmitValues,
  mapAttachFilesSubmitValues,
} from "../helpers"

export const EventGoSeeForm = props => {
  const { t } = useTranslation()

  const [filesProcessing, setFilesProcessing] = useState([])

  const defaultValues = useMemo(() => {
    const {
      id,
      date_start,
      date_end,
      times,
      client,
      defaultLocations,
      locations,
      alerts,
      agency,
      ...restValues
    } = props.initialValues
    return {
      ...restValues,
      id: id,
      ...getDatePeriodDefaultValues(date_start, date_end),
      ...getTimePeriodsDefaultValues(times),
      ...getMapLocationsDefaultValues(locations, defaultLocations),
      ...getClientDefaultValues(client),
      ...getAlertDefaultValues(alerts),
    }
  }, [props.initialValues])

  const methods = useForm({
    defaultValues: {
      ...getModelsDefaultValues(),
      ...getNotesDefaultValues(),
      ...getClientsInfoDefaultValues(),
      ...getAttachFilesDefaultValues(),
      ...defaultValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const { control, handleSubmit, setError } = methods

  const onSubmit = values => {
    const { client, ...restValues } = values
    let params = {
      ...restValues,
      ...mapDatePeriodSubmitValues(values.date_start, values.date_end),
      ...mapTimePeriodsSubmitValues(values.times),
      ...mapModelsSubmitValues(values.fashion_models),
      ...mapClientSubmitValues(client, props.isEditMode),
      ...mapAlertsSubmitValues(values.alerts),
      ...mapAttachFilesSubmitValues(values.files),
    }

    params.locations = mapLocationsSubmitValues(values.locations)

    props.onSubmit(params, { setError })
  }

  const addFileToProcessing = fieldId => {
    setFilesProcessing(prevState => [...prevState, fieldId])
  }

  const removeFileFromProcessing = fieldId => {
    setFilesProcessing(prevState => prevState.filter(item => item !== fieldId))
  }

  const isDisabled = useMemo(() => {
    return props.loading
  }, [props.loading])

  return (
    <FormProvider {...methods}>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <DateSection />

        <TimeSection />

        <hr className="drawer-separator drawer-separator_form" />

        <ModelsSection
          isEdit={props.isEditMode}
          eventId={props.initialValues.id}
          eventType={props.eventType}
          modelActionBar={{
            cancel: props.isEditMode,
          }}
          onEventUpdate={props.onEventUpdate}
          entity={props.entity}
          entityId={props.entityId}
        />

        <hr className="drawer-separator drawer-separator_form" />

        <NotesSection />

        <hr className="drawer-separator drawer-separator_form" />

        <ClientAndLocationSection />

        <hr className="drawer-separator drawer-separator_form" />

        <AttachFilesSection
          processing={filesProcessing}
          addFileToProcessing={addFileToProcessing}
          removeFileFromProcessing={removeFileFromProcessing}
          accept={
            "image/jpeg, image/png, image/gif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          }
          eventId={props.initialValues.id}
        />

        <hr className="drawer-separator drawer-separator_form" />

        <AlertSection />

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={isDisabled}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

EventGoSeeForm.propTypes = {
  isEditMode: PropTypes.bool,
  eventType: PropTypes.string,
  initialValues: PropTypes.object,
  error: PropTypes.any,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onEventUpdate: PropTypes.func,
  entity: PropTypes.string,
  entityId: PropTypes.number,
}
