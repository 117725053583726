import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { ConfirmPopup, IconButton } from "components/index"
import { calendarEventModelContractRemove } from "store"
import { useConfirmModal } from "hooks"

export const RemoveControl = ({ eventId, data, onSuccess }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { contract, ...model } = data

  const { modalRef, showModal, closeModal, handleConfirm } = useConfirmModal(
    item => {
      dispatch(
        calendarEventModelContractRemove.remove({
          event: eventId,
          model: model?.id,
          onSuccess: () => onSuccess(model),
        })
      )
    },
    () => {}
  )

  return (
    <>
      <IconButton name={"remove"} onClick={showModal} bg wrapClass={"ms-2"} />

      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
      />
    </>
  )
}

RemoveControl.propTypes = {
  eventId: PropTypes.number,
  data: PropTypes.object,
  onSuccess: PropTypes.func,
}
