import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import { useFieldArray } from "react-hook-form"

import { SocialNetworkIcon } from "../../SocialNetwork"
import { InputField } from "../../FormElements"
import { ControllerHF } from "../ControllerHF"
import { commonHelper } from "../../../helpers"

export const SocialNetworkFieldArrayHF = props => {
  const { t } = useTranslation()

  const fieldName = props.name
  const inputName = props.inputName || "value"

  const { fields } = useFieldArray({
    control: props.control,
    name: fieldName,
    keyName: "fieldId",
  })

  const renderLabel = name => (
    <>
      <SocialNetworkIcon name={name} />

      <span className={"ms-2"}>
        {commonHelper.socialNetwork.getConfig(name).label}
      </span>
    </>
  )

  return (
    <Row>
      {fields.map((item, index) => (
        <Col md={3} key={item.fieldId}>
          <ControllerHF
            name={`${fieldName}.${index}.${inputName}`}
            control={props.control}
            component={InputField}
            id={`${fieldName}.${index}.${inputName}`}
            label={renderLabel(item.name)}
            placeholder={t(
              commonHelper.socialNetwork.getConfig(item.name).inputPlaceholder
            )}
            type="text"
            normalize={
              commonHelper.socialNetwork.getConfig(item.name).inputNormalize
            }
            hintText={props.config && props.config[item.name]?.hintText}
          />
        </Col>
      ))}
    </Row>
  )
}

SocialNetworkFieldArrayHF.propTypes = {
  name: PropTypes.string,
  inputName: PropTypes.string,
  control: PropTypes.object,
  config: PropTypes.object,
}
