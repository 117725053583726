import React from 'react';
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap";
import { connect } from 'react-redux';

import { MegaTagTitle } from "../../components";
import { PhotosContainer } from './components/PhotosComponents';

const PhotosComponent = (props) => {
    return (
        <div className={'model-panel-photos'}>
            <MegaTagTitle title={'title.model.photo'} />

            <Row>
                <Col>
                    <PhotosContainer />
                </Col>
            </Row>

        </div>
    );
};

PhotosComponent.propTypes = {
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const Photos = connect(mapStateToProps, mapDispatchToProps)(PhotosComponent);