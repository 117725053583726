import React from "react"
import PropTypes from "prop-types"
import { PhotoCard } from "./PhotoCard"
import { useSubmitHandler } from "hooks"
import { PublishPhotoForm } from "./PublishPhotoForm"
import { useDispatch, useSelector } from "react-redux"
import { selectSocialCalendarCreatePost, socialCalendarCreatePost } from "store"

export const PublishPhoto = ({
  onEditCrop,
  src,
  onClose,
  description,
  attachmentId,
}) => {
  const dispatch = useDispatch()

  const { loading, error } = useSelector(selectSocialCalendarCreatePost)

  const closeModal = () => {
    onClose()
    dispatch(socialCalendarCreatePost.cleanState())
  }

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        socialCalendarCreatePost.create({
          params,
          onSuccess,
          onError,
        })
      )
    },
    () => {
      closeModal()
    }
  )

  return (
    <>
      <PhotoCard editCrop={onEditCrop} src={src} />
      <PublishPhotoForm
        error={error}
        loading={loading}
        onCancel={closeModal}
        onSubmit={handleSubmit}
        initialValues={{
          description,
          fashion_model_photo_id: attachmentId,
        }}
        imageUrl={src}
      />
    </>
  )
}

PublishPhoto.propTypes = {
  onEditCrop: PropTypes.func,
  src: PropTypes.string,
  onClose: PropTypes.func,
  description: PropTypes.string,
  attachmentId: PropTypes.any,
}
