import React from 'react';
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AddPackageForm } from './AddPackageForm';
import { DataCollections } from './DataCollections';
import { PackageData } from './PackageData';

const PackageContainerComponent = (props) => {
    const { t } = useTranslation();

    return (
        <Row>
            <Col md={8}>
                <div className={'pe-md-3'}>
                    <div className={'mb-3'}>
                        <DataCollections initialTab={props.initialTab} />
                    </div>

                    <div className={'mb-3'}>
                        <h4 className={'mb-3'}>{t('added')}</h4>
                        <PackageData initialTab={props.initialTab} />
                    </div>
                </div>
            </Col>

            <Col md={4} className={'col-divider_start col-divider_md'}>
                <div className={'pt-3 ps-md-3'}>
                    <AddPackageForm onSaveSuccess={props.onSaveSuccess} onSend={props.onSend} />
                </div>
            </Col>
        </Row>
    );
};

PackageContainerComponent.propTypes = {
    initialTab: PropTypes.oneOf(['books', 'videos']),
    onSaveSuccess: PropTypes.func,
    onSend: PropTypes.func,
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const PackageContainer = connect(mapStateToProps, mapDispatchToProps)(PackageContainerComponent);