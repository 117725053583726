import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { ControllerHF, FilterTitle, RadioButtonGroup } from "components"
import { GENDER_FEMALE, GENDER_MALE } from "consts"

export const GenderSection = () => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext()
  const groupWatch = !!watch("group")

  return (
    <div className={"mb-3"}>
      <FilterTitle title={t("gender")} />

      <ControllerHF
        id={"gender"}
        name={"gender"}
        control={control}
        component={RadioButtonGroup}
        options={[
          {
            label: t("female"),
            value: GENDER_FEMALE,
            disabled: groupWatch,
          },
          {
            label: t("male"),
            value: GENDER_MALE,
            disabled: groupWatch,
          },
        ]}
        className={"d-flex"}
      />
    </div>
  )
}

GenderSection.propTypes = {}
