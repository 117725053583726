import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import { InvoiceDetailsCard } from "./InvoiceDetailsCard"
import { billingInvoiceData } from "store/actions"
import * as HOC from "HOC"

const DataShower = HOC.withDataShower()

const InvoiceDetailsContainerComponent = props => {
  const dispatch = useDispatch()
  let { id } = useParams()

  useEffect(() => {
    dispatch(billingInvoiceData.getData({ id }))

    return () => {
      dispatch(billingInvoiceData.cleanState())
    }
  }, [])

  return (
    <DataShower
      isLoading={props.loading}
      isFailed={props.error}
      error={props.error}
    >
      {props.data && <InvoiceDetailsCard data={props.data} />}
    </DataShower>
  )
}

InvoiceDetailsContainerComponent.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.any,
}

const mapStateToProps = state => {
  const { data, loading, error } = state.billing.invoice.data
  return {
    data,
    loading,
    error,
  }
}

export const InvoiceDetailsContainer = connect(mapStateToProps)(
  InvoiceDetailsContainerComponent
)
