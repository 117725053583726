import { all } from "redux-saga/effects"

import linkingSaga from "./linking/sagas"
import rateSaga from "./rate/sagas"
import expenseSaga from "./expense/sagas"
import attachmentSaga from "./attachment/sagas"
import dataSaga from "./data/sagas"
import contractSaga from "./contract/sagas"

function* modelSaga() {
  yield all([
    linkingSaga(),
    rateSaga(),
    expenseSaga(),
    attachmentSaga(),
    dataSaga(),
    contractSaga(),
  ])
}

export default modelSaga
