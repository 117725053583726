import React from "react"
import PropTypes from "prop-types"
import { DrawerContainer } from "components"
import { useTranslation } from "react-i18next"
import { PostPreviewContainer } from "./PostPreviewContainer"
import { usePostsDrawerManager } from "hooks"

export const ViewPost = () => {
  const { t } = useTranslation()

  const {
    state: { isViewPostDrawer, postData },
    closeDrawer,
    navigation: { showPostEditDrawer },
  } = usePostsDrawerManager()

  return (
    <DrawerContainer
      title={t("post-preview")}
      visible={isViewPostDrawer}
      onClose={closeDrawer}
    >
      <PostPreviewContainer postId={postData?.id} onEdit={showPostEditDrawer} />
    </DrawerContainer>
  )
}

ViewPost.propTypes = {
  visible: PropTypes.bool,
  postData: PropTypes.object,
  onClose: PropTypes.func,
  onPostEdit: PropTypes.func,
}
