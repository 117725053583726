import React from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { DrawerContainer } from "components"
import { EventContractSendEmailForm } from "./EventContractSendEmailForm"
import { useSubmitHandler } from "hooks"
import { calendarEventContractSendEmail } from "store/actions"

export const EventContractSendEmail = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { loading, error } = useSelector(
    state => state.calendarEvent.contract.sendEmail
  )

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        calendarEventContractSendEmail.send({
          id: props.eventId,
          params,
          onSuccess,
          onError,
        })
      )
    },
    () => {
      props.onSuccess()
    }
  )

  const onDestroy = () => {
    dispatch(calendarEventContractSendEmail.cleanState())
    props.onDestroy && props.onDestroy()
  }

  return (
    <DrawerContainer
      title={t("send_contract")}
      visible={props.visible}
      onClose={props.onClose}
      onDestroy={onDestroy}
      nested
    >
      <EventContractSendEmailForm
        clientId={props.clientId}
        loading={loading}
        error={error}
        onSubmit={handleSubmit}
        onCancel={props.onClose}
      />
    </DrawerContainer>
  )
}

EventContractSendEmail.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  onSuccess: PropTypes.func,
  eventId: PropTypes.number,
  clientId: PropTypes.number,
}
