import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { commonHelper } from "helpers"

const getCurrency = currency =>
  commonHelper.currency.getCurrency(currency)?.label?.toUpperCase()

export const Notes = props => {
  const { t } = useTranslation()

  const { expiration_date, currency, total_amount } = props.data

  return (
    <div className="invoice-card-notes card-block">
      <div className="invoice-card-notes__info">
        <p className={"mb-0 fw-medium"}>{t("date_expiration")}</p>
        <div>
          {expiration_date ? moment(expiration_date).format("DD/MM/YYYY") : "-"}
          <span className={"invoice-card-notes__total"}>
            {getCurrency(currency)} {total_amount}
          </span>
        </div>
      </div>
    </div>
  )
}

Notes.propTypes = {
  data: PropTypes.object,
}
