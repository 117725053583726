const transformErrors = errors => {
  let result = {}

  return null
}

const youtubeVideoRegex =
  /^(?:http(?:s)?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user|shorts)\/))([^\?&\"'>]+)/

export const isObjectHasValue = obj => {
  for (let k in obj) {
    let value = obj[k]

    if (typeof value === "string" || value instanceof String) {
      value = value.trim()
    }

    if (value !== null && value !== "" && value !== undefined) {
      return true
    }
  }

  return false
}

const generateGoogleMapLink = (...locationArgs) => {
  const googleLinkBase = "https://www.google.com/maps/place/"
  const formatedLocationArgs = locationArgs
    .filter(arg => !!arg)
    .map(arg => {
      return arg.split(" ").join("+")
    })
    .join("+")
  return googleLinkBase + formatedLocationArgs
}

const isEmpty = obj =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length

const getCurrentTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

const isNil = value => value === null || value === undefined

export {
  transformErrors,
  youtubeVideoRegex,
  generateGoogleMapLink,
  isEmpty,
  getCurrentTimezone,
  isNil,
}
