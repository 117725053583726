import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { VideosGrid } from './VideosGrid';
import {
    packageDraftDataCollectionsFetchVideos,
    packageDraftDataCollectionsVideosSetPage,
} from '../../../../../../../store/actions';

const VideosComponent = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchData();
    }, [props.page]);

    const fetchData = () => {
        let params = {
            page: props.page,
            per_page: 4,
        };

        props.packageDraftDataCollectionsFetchVideos(props.model.id, params);
    };

    const handlePageChange = (item) => {
        props.packageDraftDataCollectionsVideosSetPage(item.selected + 1);
    };

    return (
        <div className={'pe-md-3'}>
            <VideosGrid
                items={props.list}
                loading={props.listLoading}
                error={props.listError}
                meta={props.meta}
                page={props.page}
                onPageChange={handlePageChange}
                onAdd={props.onAdd}
                picked={props.picked}
                playersRef={props.playersRef}
            />
        </div>
    );
};

VideosComponent.propTypes = {
    picked: PropTypes.array,
    onAdd: PropTypes.func,
    playersRef: PropTypes.any,

    list: PropTypes.array,
    listLoading: PropTypes.bool,
    listError: PropTypes.any,
    meta: PropTypes.object,
    page: PropTypes.any,
    model: PropTypes.object,

    packageDraftDataCollectionsFetchVideos: PropTypes.func,
    packageDraftDataCollectionsVideosSetPage: PropTypes.func,
}

const mapStateToProps = state => {
    const { data } = state.model.profile;
    const { list, listLoading, listError, meta, page } = state.package.draft.dataCollections.videos;
    return {
        list, listLoading, listError, meta, page,
        model: data,
    };
};

const mapDispatchToProps = {
    packageDraftDataCollectionsFetchVideos,
    packageDraftDataCollectionsVideosSetPage,
};

export const Videos = connect(mapStateToProps, mapDispatchToProps)(VideosComponent);