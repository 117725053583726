import React from "react"
import PropTypes from "prop-types"

import { Sidebar } from "./Sidebar"
import { Room } from "./Room"
import { ChatSocketMiddleware } from "components"

export const Chat = props => {
  return (
    <ChatSocketMiddleware>
      <div className="d-lg-flex">
        <Sidebar />

        <Room />
      </div>
    </ChatSocketMiddleware>
  )
}

Chat.propTypes = {}
