import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

import {
  BILLING_SUBSCRIPTION_TARIFF_PERIOD_ANNUALLY,
  BILLING_SUBSCRIPTION_TARIFF_PERIOD_MONTHLY,
} from "consts"

export const TariffPeriodSwitch = ({ value, onChange }) => {
  const { t } = useTranslation()

  const isChecked = value === BILLING_SUBSCRIPTION_TARIFF_PERIOD_ANNUALLY

  const onInputChange = e => {
    const inputValue = e.target.checked
    if (inputValue) {
      onChange(BILLING_SUBSCRIPTION_TARIFF_PERIOD_ANNUALLY)
    } else {
      onChange(BILLING_SUBSCRIPTION_TARIFF_PERIOD_MONTHLY)
    }
  }

  return (
    <form id="pricing_form">
      <div className="pricing-block__control-block">
        <label
          htmlFor="plan_check"
          className={
            `pricing-block__label--monthly` + (!isChecked ? " active" : "")
          }
        >
          {t("billing_subscription.tariff-period.monthly")}
        </label>

        <div className="pricing-block__checkbox-block">
          <input
            className="pricing-block__checkbox"
            id="plan_check"
            name="plan_check"
            type="checkbox"
            checked={isChecked}
            onChange={onInputChange}
          />
          <label htmlFor="plan_check" />
        </div>

        <label
          htmlFor="plan_check"
          className={
            `pricing-block__label--annually` + (isChecked ? " active" : "")
          }
        >
          {t("billing_subscription.tariff-period.annually")}
        </label>
      </div>
    </form>
  )
}

TariffPeriodSwitch.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}
