import React from 'react';
import PropTypes from "prop-types"
import classNames from "classnames";

import { commonHelper } from '../../../helpers';

export const RatingBadge = ({ value, className, wrapClassName, size = 'md' }) => {
    return (
        <div className={classNames('', {
            [wrapClassName]: wrapClassName
        })}>
            <img src={commonHelper.rating.getConfig(value).icon} alt={'rating'}
                className={classNames('', {
                    [className]: className,
                    [`rating-badge_size_${size}`]: size,
                })}
            />
        </div>
    );
};

RatingBadge.propTypes = {
    value: PropTypes.number,
    className: PropTypes.string,
    wrapClassName: PropTypes.string,
    size: PropTypes.string,
}