import { createAction } from "@reduxjs/toolkit"

const SOCKET_ON_SUBSCRIPTION_UPDATE = "socket/onSubscriptionUpdate"

export const socketOnSubscriptionUpdate = createAction(
  SOCKET_ON_SUBSCRIPTION_UPDATE,
  function prepare(data) {
    return {
      payload: {
        data,
      },
    }
  }
)
