import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"

import { EventSendEmailForm } from "./EventSendEmailForm"
import { ValidationsUtils } from "utils"
import { eventsCalendarHelper } from "helpers"
import { calendarEventSendEmail } from "store/actions"

const EventSendEmailComponent = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(calendarEventSendEmail.cleanState())
    }
  }, [])

  const onSubmitSuccess = onSubmitProps => () => {
    const { onReset } = onSubmitProps
    onReset()

    props.onSuccess()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    params = {
      ...params,
      id: props.event?.id,
    }

    dispatch(
      calendarEventSendEmail.send({
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  const initialValues = useMemo(() => {
    let values = {}
    if (props.event) {
      values = {
        subject:
          eventsCalendarHelper.calendar.getCalendarEventTitle(props.event) ||
          "",
      }
    }
    return values
  }, [props.event])

  return (
    <>
      <EventSendEmailForm
        event={props.event}
        initialValues={initialValues}
        loading={props.loading}
        error={props.error}
        onSubmit={handleSubmit}
      />
    </>
  )
}

EventSendEmailComponent.propTypes = {
  event: PropTypes.object,
  onSuccess: PropTypes.func,

  loading: PropTypes.bool,
  error: PropTypes.object,
}

const mapStateToProps = state => {
  const { loading, error } = state.calendarEvent.sendEmail

  return {
    loading,
    error,
  }
}

const mapDispatchToProps = {}

export const EventSendEmail = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventSendEmailComponent)
