import { createSlice } from "@reduxjs/toolkit"
import { AGENCIES_PANEL_FILTERS_INITIAL_VALUES } from "consts"

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  page: 1,
  meta: null,
  filters: {
    values: AGENCIES_PANEL_FILTERS_INITIAL_VALUES,
  },
  syncCount: 0,
  isFetched: false,
}

const agenciesListSlice = createSlice({
  name: "agenciesList",
  initialState,
  reducers: {
    getList(state) {
      state.listLoading = true
      state.listError = null
    },
    getListCompleted(state, action) {
      state.listLoading = false
      state.list = action.payload.data
      state.meta = action.payload.meta
      state.isFetched = true
    },
    getListRejected(state, action) {
      state.listLoading = false
      state.listError = action.payload
      state.isFetched = true
    },
    setPage(state, action) {
      state.page = action.payload
    },
    changeFilter(state, action) {
      state.page = 1
      state.filters.values = { ...state.filters.values, ...action.payload }
    },
    resetFilter(state) {
      state.filters = initialState.filters
    },
    sync(state) {
      state.syncCount = state.syncCount + 1
    },
    cleanState() {
      return initialState
    },
  },
})

/**
 * @namespace
 * @property {function} getList
 * @property {function} getListCompleted
 * @property {function} getListRejected
 *  @property {function} setPage
 *  @property {function} changeFilter
 *  @property {function} resetFilter
 *  @property {function} sync
 *  @property {function} cleanState
 */

export const agenciesList = agenciesListSlice.actions

export default agenciesListSlice.reducer
