import {
    MODEL_PUBLISH_BOOK,
    MODEL_PUBLISH_BOOK_SUCCESS,
    MODEL_PUBLISH_BOOK_ERROR,
    MODEL_PUBLISH_BOOK_CLEAN_STATE,
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
};

const publish = (state = initialState, action) => {
    switch (action.type) {
        case MODEL_PUBLISH_BOOK:
            state = {
                ...state,
                loading: true,
                error: null,
            };
            break;

        case MODEL_PUBLISH_BOOK_SUCCESS:
            state = {
                ...state,
                loading: false,
            };
            break;

        case MODEL_PUBLISH_BOOK_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        case MODEL_PUBLISH_BOOK_CLEAN_STATE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default publish;