import {
    MODEL_PANEL_GENERAL_DOCUMENT_UPLOAD_ATTACHMENT,
    MODEL_PANEL_GENERAL_DOCUMENT_UPLOAD_ATTACHMENT_SUCCESS,
    MODEL_PANEL_GENERAL_DOCUMENT_UPLOAD_ATTACHMENT_ERROR,
    MODEL_PANEL_GENERAL_DOCUMENT_UPLOAD_ATTACHMENT_CLEAN_STATE,
} from './actionTypes';

export const modelPanelGeneralDocumentUploadAttachment = (params, onSuccess, onError) => {
    return {
        type: MODEL_PANEL_GENERAL_DOCUMENT_UPLOAD_ATTACHMENT,
        payload: { params, onSuccess, onError },
    }
};

export const modelPanelGeneralDocumentUploadAttachmentSuccess = () => {
    return {
        type: MODEL_PANEL_GENERAL_DOCUMENT_UPLOAD_ATTACHMENT_SUCCESS,
    }
};

export const modelPanelGeneralDocumentUploadAttachmentError = () => {
    return {
        type: MODEL_PANEL_GENERAL_DOCUMENT_UPLOAD_ATTACHMENT_ERROR,
    }
};

export const modelPanelGeneralDocumentUploadAttachmentCleanState = () => {
    return { type: MODEL_PANEL_GENERAL_DOCUMENT_UPLOAD_ATTACHMENT_CLEAN_STATE };
};