import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { useDeepCompareEffect } from "react-use"

import { GeneralEventsCalendar } from "components"
import { GENERAL_CALENDAR_ENTITY } from "consts"
import { EventsCalendarProvider } from "contexts"
import { useEventsCalendar } from "hooks"

const { MODEL: ENTITY_MODEL } = GENERAL_CALENDAR_ENTITY

export const CalendarContainer = props => {
  const { model, genders, group } = useSelector(state => ({
    model: state.model.profile.data,
    genders: state.modelPanel.panel.modelsSidebar.filters.values?.genders,
    group: state.modelPanel.panel.modelsSidebar.filters.values?.group,
  }))

  const calendar = useEventsCalendar({})

  useDeepCompareEffect(() => {
    calendar.updateConfig({
      gender: [...genders].length === 1 ? [...genders][0] : null,
    })
  }, [genders])

  return (
    <EventsCalendarProvider {...calendar}>
      <GeneralEventsCalendar
        entity={ENTITY_MODEL}
        entityData={model}
        eventsFetchParams={{
          gender: [...genders].length === 1 ? [...genders] : null,
          groups: group ? [group] : null,
        }}
      />
    </EventsCalendarProvider>
  )
}

CalendarContainer.propTypes = {}
