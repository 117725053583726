import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import _ from "lodash"

import { usePanelTopBarOptions, useSelection } from "hooks"
import { AddPhoto } from "./AddPhoto"
import { EditPhoto } from "./EditPhoto"
import { DeletePhotos } from "./DeletePhotos"
import { PhotosGrid } from "./PhotosGrid"
import {
  modelPanelPhotosFetchList,
  modelPanelPhotosListCleanState,
  modelPanelPhotosListRemovePhoto,
  modelPanelPhotosListRemovePhotoCleanState,
  modelPanelPhotosListSetPage,
} from "store/actions"
import { ConfirmPopup, FilesUploadingDataGrid, TextButton } from "components"
import { AddPhotos } from "./AddPhotos"
import { FileUtils } from "../../../../../utils"
import { CreateCompcard } from "./CreateCompcard"

const PhotosContainerComponent = props => {
  const { t } = useTranslation()

  const { selectionValue, selectionSetValue, selectionClear } = useSelection({})

  const handleAddSuccess = () => {
    fetchData()
  }

  const handleDeleteSuccess = () => {
    selectionClear()
    fetchData()
  }

  const handleSelectAll = e => {
    e.preventDefault()
    const concat = [
      ...selectionValue,
      ...props.list.map(i => ({ id: i.id, is_avatar: i.is_avatar })),
    ]
    const result = _.uniqBy(concat, "id")
    selectionSetValue(result)
  }

  const panelOptions = useMemo(
    () => ({
      actions: (
        <div className={"d-flex justify-content-between"}>
          <CreateCompcard selected={selectionValue} />

          <AddPhotos onAddSuccess={handleAddSuccess} />

          <AddPhoto onAddSuccess={handleAddSuccess} />

          {!!props.list.length && (
            <TextButton
              title={"btn.select-all"}
              onClick={handleSelectAll}
              color={"secondary"}
            />
          )}

          {!!selectionValue.length && (
            <DeletePhotos
              selected={selectionValue}
              onDeleteSuccess={handleDeleteSuccess}
            />
          )}
        </div>
      ),
    }),
    [props.page, props.list, selectionValue]
  )

  usePanelTopBarOptions(panelOptions)

  const removeModalRef = useRef(null)
  const [photoRemove, setPhotoRemove] = useState(null)
  const [photoEdit, setPhotoEdit] = useState(null)
  const [editDrawerVisible, setEditDrawerVisible] = useState(null)

  useEffect(() => {
    return () => {
      props.modelPanelPhotosListCleanState()
      props.modelPanelPhotosListRemovePhotoCleanState()
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [props.page])

  const fetchData = () => {
    let params = {
      page: props.page,
      per_page: 12,
    }

    props.modelPanelPhotosFetchList(props.model.id, params)
  }

  const handlePageChange = item => {
    props.modelPanelPhotosListSetPage(item.selected + 1)
  }

  const removePhoto = item => {
    setPhotoRemove(item)

    if (removeModalRef.current) {
      removeModalRef.current.show()
    }
  }

  const resetPhotoRemove = () => {
    setPhotoRemove(null)
  }

  const onRemoveSuccess = () => {
    fetchData()
  }

  const confirmRemove = () => {
    const { id } = photoRemove
    const selectedItem = _.find(selectionValue, { id: id })
    if (selectedItem) {
      const selected = selectionValue.filter(item => item.id !== id)
      selectionSetValue(selected)
    }

    props.modelPanelPhotosListRemovePhoto(photoRemove, onRemoveSuccess)
  }

  const editPhoto = item => {
    setPhotoEdit(item)
    editDrawerShow()
  }

  const handleEditSuccess = () => {
    fetchData()
  }

  const resetPhotoEdit = () => {
    setPhotoEdit(null)
  }

  const editDrawerShow = () => {
    setEditDrawerVisible(true)
  }

  const editDrawerClose = () => {
    setEditDrawerVisible(false)
  }

  const editDrawerDestroy = () => {
    resetPhotoEdit()
  }

  const onSelectPhoto = photo => {
    let selected = []
    const filtered = selectionValue.filter(item => item.id !== photo.id)
    if (selectionValue.length === filtered.length) {
      const data = {
        id: photo.id,
        is_avatar: photo.is_avatar,
      }
      selected = [...selectionValue, data]
    } else {
      selected = filtered
    }

    selectionSetValue(selected)
  }

  const onPhotoDownload = item => {
    FileUtils.downloadFileByLink(item?.pdf_url)
  }

  return (
    <>
      <FilesUploadingDataGrid className={"model-photos-progress-data-grid"} />
      <PhotosGrid
        items={props.list}
        loading={
          props.listLoading ||
          props.removePhotosLoading ||
          props.compcardCreateLoading
        }
        error={props.listError}
        meta={props.meta}
        page={props.page}
        removingItems={props.removingItems}
        onPageChange={handlePageChange}
        onPhotoRemove={removePhoto}
        onPhotoEdit={editPhoto}
        selectedPhotos={selectionValue}
        onSelectPhoto={onSelectPhoto}
        onPhotoDownload={onPhotoDownload}
      />

      <EditPhoto
        visible={editDrawerVisible}
        onEditSuccess={handleEditSuccess}
        photo={photoEdit}
        onClose={editDrawerClose}
        onDestroy={editDrawerDestroy}
      />

      <ConfirmPopup
        ref={removeModalRef}
        onConfirm={confirmRemove}
        onClosed={resetPhotoRemove}
      >
        <p className={"font-size-22"}>{t("sure_delete_photo")}?</p>
      </ConfirmPopup>
    </>
  )
}

PhotosContainerComponent.propTypes = {
  model: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
  removingItems: PropTypes.array,
  removePhotosLoading: PropTypes.bool,
  compcardCreateLoading: PropTypes.bool,

  modelPanelPhotosFetchList: PropTypes.func,
  modelPanelPhotosListCleanState: PropTypes.func,
  modelPanelPhotosListSetPage: PropTypes.func,
  modelPanelPhotosListRemovePhoto: PropTypes.func,
  modelPanelPhotosListRemovePhotoCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.model.profile
  const { list, listLoading, listError, meta, page } =
    state.modelPanel.photos.list.data
  const { pending } = state.modelPanel.photos.list.removeItem
  const removePhotosLoading = state.modelPanel.photos.list.removePhotos.loading
  const compcardCreateLoading = state.compcard.create.loading

  return {
    model: data,
    list,
    listLoading,
    listError,
    meta,
    page,
    removingItems: pending,
    removePhotosLoading,
    compcardCreateLoading,
  }
}

const mapDispatchToProps = {
  modelPanelPhotosFetchList,
  modelPanelPhotosListCleanState,
  modelPanelPhotosListSetPage,
  modelPanelPhotosListRemovePhoto,
  modelPanelPhotosListRemovePhotoCleanState,
}

export const PhotosContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhotosContainerComponent)
