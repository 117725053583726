import React, { useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { ICalUrlsDataLoader } from "../ICalUrlsDataLoader"
import { TabNavigator } from "components"
import { Content } from "./Content"
import { Notes } from "./Notes"

export const ICalUrlsContainer = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { data, loading, error } = useSelector(state => state.iCal.urls)

  const TABS_CONFIG = {
    GOOGLE: {
      label: "Google Calendar",
      value: "google",
    },
    FILE: {
      label: t("sync_to_calendar.tabs.tab_name.file"),
      value: "file",
    },
  }

  const [tab, setTab] = useState(TABS_CONFIG.GOOGLE.value)

  const tabsConfigValues = Object.values(TABS_CONFIG)

  return (
    <div>
      <ICalUrlsDataLoader>
        <TabNavigator
          activeTab={tab}
          onTabChange={tab => setTab(tab)}
          tabs={tabsConfigValues.map(({ label, value }) => ({
            label,
            value,
            render: () => <Content data={data} type={value} />,
          }))}
        />

        <hr className="drawer-separator drawer-separator_form bg-gray-light" />

        <Notes />
      </ICalUrlsDataLoader>
    </div>
  )
}

ICalUrlsContainer.propTypes = {}
