import {
    MODEL_PANEL_GENERAL_DOCUMENT_REMOVE_ATTACHMENT,
    MODEL_PANEL_GENERAL_DOCUMENT_REMOVE_ATTACHMENT_SUCCESS,
    MODEL_PANEL_GENERAL_DOCUMENT_REMOVE_ATTACHMENT_ERROR,
    MODEL_PANEL_GENERAL_DOCUMENT_REMOVE_ATTACHMENT_CLEAN_STATE,
} from './actionTypes';

export const modelPanelGeneralDocumentRemoveAttachment = (id, onSuccess, onError) => {
    return {
        type: MODEL_PANEL_GENERAL_DOCUMENT_REMOVE_ATTACHMENT,
        payload: { id, onSuccess, onError },
    }
};

export const modelPanelGeneralDocumentRemoveAttachmentSuccess = () => {
    return {
        type: MODEL_PANEL_GENERAL_DOCUMENT_REMOVE_ATTACHMENT_SUCCESS,
    }
};

export const modelPanelGeneralDocumentRemoveAttachmentError = () => {
    return {
        type: MODEL_PANEL_GENERAL_DOCUMENT_REMOVE_ATTACHMENT_ERROR,
    }
};

export const modelPanelGeneralDocumentRemoveAttachmentCleanState = () => {
    return { type: MODEL_PANEL_GENERAL_DOCUMENT_REMOVE_ATTACHMENT_CLEAN_STATE };
};