import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import _ from "lodash"
import i18n from "../../../../i18n"
import { API } from "../../../../api"
import { clientAddRating } from "./slice"
import { clientProfileData } from "../../profile/actions"

function* addRating({ payload: { id, params, onSuccess, onError } }) {
  try {
    const response = yield call(API.client.addClientRating, id, params)

    const { data } = response

    yield put(clientAddRating.addCompleted())

    if (_.has(data.data, "avg_rating")) {
      yield put(
        clientProfileData.updateData({
          ...data,
          avg_rating: data?.data?.avg_rating,
        })
      )
    }

    toastr.success(i18n.t("rating.add.success"))

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      clientAddRating.addRejected({
        errors,
        message,
      })
    )

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchAddRating() {
  yield takeEvery(clientAddRating.add, addRating)
}

function* addSaga() {
  yield all([fork(watchAddRating)])
}

export default addSaga
