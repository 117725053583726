import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import { agencyAddAttachment } from "./slice"
import { API } from "../../../../api"
import i18n from "../../../../i18n"

function* addItem({ payload: { id, params, onSuccess, onError } }) {
    try {

        const response = yield call(API.agency.addAgencyAttachment, id, params)

        const { data } = response

        toastr.success(i18n.t("agency.add-attachment.success"))

        if (onSuccess) {
            onSuccess()
        }
        yield put(agencyAddAttachment.addCompleted())
    } catch (error) {
        const errors = error.response?.data?.errors || {}
        const message = error.response?.data?.message || i18n.t("error")

        yield put(
            agencyAddAttachment.addRejected({
                errors,
                message,
            })
        )

        if (onError) {
            onError(errors)
        }
    }
}

export function* watchAddItem() {
    yield takeEvery(agencyAddAttachment.add, addItem)
}

function* addSaga() {
    yield all([fork(watchAddItem)])
}

export default addSaga