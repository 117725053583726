import { all } from 'redux-saga/effects';

import panelSaga from './panel/sagas';

function* agenciesSaga() {
    yield all([
        panelSaga(),
    ])
}

export default agenciesSaga;