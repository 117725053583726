import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import classNames from "classnames"

import { ModelSelect } from "../ModelSelect"
import { SelectedListControl } from "../../../Controls"
import { OverlayBlockLoading } from "../../../OverlayLoading"

export const ModelsSelectedList = ({
  disabled,
  value,
  className,
  ...props
}) => {
  return (
    <>
      <Row>
        <Col md={12}>
          <ModelSelect
            value={value}
            disabled={disabled || props.loading}
            onSelect={props.onSelect}
            gendersValue={props.gendersValue}
          />
        </Col>
      </Row>
      <Row className={""}>
        <Col md={12}>
          <OverlayBlockLoading isLoading={props.loading}>
            <SelectedListControl
              {...props.selectedListConfig}
              items={value}
              onRemove={props.onRemove}
              renderItemContent={props.renderItemContent}
            />
          </OverlayBlockLoading>
        </Col>
      </Row>
    </>
  )
}

ModelsSelectedList.propTypes = {
  value: PropTypes.any,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  renderItemContent: PropTypes.func,
  loading: PropTypes.bool,
  selectedListConfig: PropTypes.object,
  gendersValue: PropTypes.array,
}
