export const EVENT_TYPE_NOTE = "note"
export const EVENT_TYPE_OUT = "out"
export const EVENT_TYPE_SCOUTING = "scouting"
export const EVENT_TYPE_GO_SEE = "go_see"
export const EVENT_TYPE_CASTING = "casting"
export const EVENT_TYPE_FITTING = "fitting"
export const EVENT_TYPE_OPTION = "option"
export const EVENT_TYPE_JOB = "job"

// models other events
export const EVENT_TYPE_ACCOMMODATION = "accommodation"
export const EVENT_TYPE_ARRIVAL = "arrival"
export const EVENT_TYPE_TRAVEL = "travel"
export const EVENT_TYPE_DEPARTURE = "departure"

// holiday events
export const EVENT_TYPE_BIRTHDAY = "birthday"

// events category (group)
export const CALENDAR_EVENT_CATEGORY_GENERAL = "general"
export const CALENDAR_EVENT_CATEGORY_OTHER = "other"
export const CALENDAR_EVENT_CATEGORY_HOLIDAY = "holiday"
