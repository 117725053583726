import React, { useCallback } from "react"
import PropTypes from "prop-types"

//COMMON
import {
  FACEBOOK,
  INSTAGRAM,
  MEDIA_CONTENT_TYPE,
  SOCIAL_NETWORK_PUBLISH_LIST,
} from "consts"
import { useModal, usePopover } from "hooks"
import { FacebookLoginButton, IconButton } from "components/Buttons"

//LOCAL FILES
import { PublishPopover } from "./PublishPopover"
import { PublishModal } from "./PublishModal"
import { PublishToFBPage, PublishToInstagram } from "./PublishModalContent"
import { RequiredFacebookLogin } from "packages/facebook"

const { IMAGE, VIDEO } = MEDIA_CONTENT_TYPE

export const SocialPublish = ({
  attachmentId,
  description,
  src,
  crops,
  type,
}) => {
  const { popoverId, toggle, popoverOpen } = usePopover({
    id: attachmentId,
  })
  const {
    item: socialName,
    closeModal,
    modalRef,
    showModal,
    onModalClose,
  } = useModal()

  const handleSocialButtonClick = socialName => {
    return () => {
      toggle()
      showModal(socialName)
    }
  }

  const renderModalContent = useCallback(
    socialName => {
      switch (socialName) {
        case FACEBOOK:
          return (
            <RequiredFacebookLogin
              component={FacebookLoginButton}
              wrapClass={"d-flex justify-content-center"}
            >
              <PublishToFBPage
                attachmentId={attachmentId}
                type={type}
                src={src}
                description={description}
                onClose={closeModal}
              />
            </RequiredFacebookLogin>
          )
        case INSTAGRAM:
          return (
            <RequiredFacebookLogin
              component={FacebookLoginButton}
              wrapClass={"d-flex justify-content-center"}
            >
              <PublishToInstagram
                src={src}
                description={description}
                onClose={closeModal}
                instagramCrop={crops?.find(crop => crop.type === "instagram")}
                attachmentId={attachmentId}
              />
            </RequiredFacebookLogin>
          )
      }
    },
    [src, description, type, crops]
  )

  return (
    <>
      <IconButton
        name={"share"}
        type={"button"}
        id={popoverId}
        onClick={toggle}
        bgColor={"bg-dark"}
      />
      <PublishPopover
        popoverId={popoverId}
        popoverOpen={popoverOpen}
        socialNetworkList={SOCIAL_NETWORK_PUBLISH_LIST}
        toggle={toggle}
        onSocialButtonClick={handleSocialButtonClick}
      />
      <PublishModal modalRef={modalRef} onClose={onModalClose}>
        {renderModalContent(socialName)}
      </PublishModal>
    </>
  )
}

SocialPublish.propTypes = {
  attachmentId: PropTypes.any,
  description: PropTypes.string,
  src: PropTypes.string.isRequired,
  crops: PropTypes.array.isRequired,
  type: PropTypes.oneOf([IMAGE, VIDEO]).isRequired,
}
