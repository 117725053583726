import React from "react"
import PropTypes from "prop-types"

import s from "./RecipientOptionLabel.module.scss"

export const RecipientOptionLabel = ({ option }) => {
  const { email, title, subtitle } = option

  return (
    <div className={s.label}>
      <div className={s.label__email}>{email}</div>

      <div className={s.label__info}>
        <div className={s.label__title}>{title}</div>

        {subtitle && <div className={s.label__subtitle}>{subtitle}</div>}
      </div>
    </div>
  )
}

RecipientOptionLabel.propTypes = {
  option: PropTypes.any,
}
