import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Table } from "reactstrap"

import { billingHelper } from "helpers"

const currencySymbol = billingHelper.currency.getCurrency().symbol

export const Summary = ({ data }) => {
  const { t } = useTranslation()

  const { lines, tax, subtotal, total } = data

  const taxExist = tax !== null

  return (
    <>
      <div className="py-2 mt-3">
        <h5 className="fw-bold">{t("summary")}</h5>
      </div>
      <div className="table-responsive">
        <Table
          className={
            "invoice-details-card__summary-table table-nowrap table-border-bottom"
          }
        >
          <thead className={"table-head-bg"}>
            <tr>
              <th style={{ width: "70px" }}>{t("number.no_abbreviation")}</th>
              <th>{t("description")}</th>
              <th>{t("quantity")}</th>
              {taxExist && <th>{t("vat")}</th>}
              <th className="text-end">{t("amount")}</th>
            </tr>
          </thead>
          <tbody>
            {lines &&
              !!lines.length &&
              lines.map((item, index) => (
                <tr key={"line-" + index}>
                  <td>{index + 1}</td>
                  <td>{item.description}</td>
                  <td>{item.quantity}</td>
                  {taxExist && (
                    <td>
                      {item.tax_amount} {` ${currencySymbol} `}
                    </td>
                  )}
                  <td className="text-end">
                    {item.amount} {` ${currencySymbol} `}
                  </td>
                </tr>
              ))}

            {taxExist && (
              <tr>
                <td colSpan={taxExist ? "4" : "3"} className="text-end">
                  {t("vat")}
                </td>
                <td className="text-end">
                  {tax} {` ${currencySymbol} `}
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={taxExist ? "4" : "3"} className="text-end">
                {t("subtotal")}
              </td>
              <td className="text-end">
                {subtotal} {` ${currencySymbol} `}
              </td>
            </tr>
            <tr>
              <td colSpan={taxExist ? "4" : "3"} className="border-0 text-end">
                <strong>{t("total")}</strong>
              </td>
              <td className="border-0 text-end">
                <h4 className="m-0">
                  {total} {` ${currencySymbol} `}
                </h4>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  )
}

Summary.propTypes = {
  data: PropTypes.object,
}
