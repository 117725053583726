import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useSelector } from "react-redux"
import { selectEventColorByEventType } from "store"

export const CalendarEventCategory = ({ item, ...props }) => {
  const bgColor = useSelector(state =>
    selectEventColorByEventType(state, item.type)
  )

  return (
    <div className={"calendar-event-category-col"}>
      <div
        className={classNames("calendar-event-category external-event", {
          [`calendar-event_theme_${item.theme}`]: item.theme,
          [props.draggableSelector]: props.draggableSelector,
        })}
        style={{ backgroundColor: bgColor }}
        data-event-type={item.type}
        draggable
        onDrag={event => props.onDrag(event, item)}
        onClick={() => props.onClick(item)}
      >
        {item.title}
      </div>
    </div>
  )
}

CalendarEventCategory.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  onDrag: PropTypes.func,
  draggableSelector: PropTypes.string,
}
