/* eslint react/prop-types: off */
import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { Button, ReactTableView, StatementPaidStatusBadge } from "components"
import { STATEMENT_TYPE } from "consts"
import { PaymentSubmit } from "./PaymentSubmit"
import { PaymentDetails } from "./PaymentDetails"

import * as HOC from "HOC"

const TableDataLoading = HOC.withTableDataLoading()

export const StatementTable = props => {
  const { t } = useTranslation()

  const data = useMemo(() => {
    return props.list
  }, [props.list])

  const columns = [
    {
      Header: t("date"),
      accessor: "date",
      id: "date",
      Cell: ({ value }) => {
        return value ? moment(value).format("DD/MM/YYYY") : "-"
      },
    },
    {
      Header: t("description"),
      accessor: "description",
      id: "description",
      Cell: ({ value }) => value,
    },
    {
      Header: t("amount"),
      accessor: "amount",
      id: "amount",
      Cell: ({ row: { original } }) => original?.value,
    },
    {
      Header: () => <div className={"w-100 text-center"}>{t("status")}</div>,
      accessor: "paid",
      id: "paid",
      Cell: ({ row: { original } }) =>
        original.type === STATEMENT_TYPE.STATEMENT ? (
          <div className="d-flex align-items-center justify-content-center">
            <StatementPaidStatusBadge value={original.is_paid} />
          </div>
        ) : null,
    },
    {
      Header: () => <div className={"d-print-none"} />,
      id: "action",
      Cell: ({ row: { original } }) => (
        <div className="d-flex align-items-center justify-content-end d-print-none">
          {original.type === STATEMENT_TYPE.STATEMENT ? (
            !original.is_paid && (
              <PaymentSubmit onClick={() => props.onPayment(original)} />
            )
          ) : (
            <PaymentDetails onClick={() => props.onPaymentDetails(original)} />
          )}
        </div>
      ),
    },
  ]

  return (
    <TableDataLoading
      isLoading={props.loading}
      isFailed={props.error}
      error={props.error}
    >
      <ReactTableView isLoading={props.loading} columns={columns} data={data} />
    </TableDataLoading>
  )
}

StatementTable.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  onPayment: PropTypes.func,
  onPaymentDetails: PropTypes.func,
}
