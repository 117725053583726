import { combineReducers } from "redux"

import general from "./general/reducers"
import avatar from "./avatar/reducers"
import menuItems from "./menuItems/reducers"
import models from "./models/reducers"
import templates from "./templates/reducers"
import requests from "./requests/reducers"

const websiteReducer = combineReducers({
  general,
  avatar,
  menuItems,
  models,
  templates,
  requests,
})

export default websiteReducer
