import _ from "lodash"

import i18n from "i18n"
import {
  DEPARTMENT_BOOKING,
  DEPARTMENT_SCOUTING,
  DEPARTMENT_LOGISTICS,
  DEPARTMENT_ACCOUNTING,
  DEPARTMENTS_PERMISSIONS,
} from "../../consts"

export const getDepartmentOptions = () => {
  return [
    {
      label: i18n.t("department.booking"),
      value: DEPARTMENT_BOOKING,
    },
    {
      label: i18n.t("department.scouting"),
      value: DEPARTMENT_SCOUTING,
    },
    {
      label: i18n.t("department.logistics"),
      value: DEPARTMENT_LOGISTICS,
    },
    {
      label: i18n.t("department.accounting"),
      value: DEPARTMENT_ACCOUNTING,
    },
  ]
}

export const getDepartment = value => {
  const options = getDepartmentOptions()
  const item = _.find(options, { value: value })

  return (
    item || {
      label: "",
      value: "",
    }
  )
}

export const getDepartmentsPermissionsOptions = () => {
  return [
    {
      label: i18n.t("model's_statement"),
      value: DEPARTMENTS_PERMISSIONS.MODEL_STATEMENT_READ,
    },
    {
      label: i18n.t("client's_statement"),
      value: DEPARTMENTS_PERMISSIONS.CLIENT_STATEMENT_READ,
    },
    {
      label: i18n.t("agency's_statement"),
      value: DEPARTMENTS_PERMISSIONS.AGENCY_STATEMENT_READ,
    },
  ]
}

export const getDepartmentPermission = value => {
  const options = getDepartmentsPermissionsOptions()
  const item = _.find(options, { value: value })

  return (
    item || {
      label: "",
      value: "",
    }
  )
}
