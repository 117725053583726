export const getMeasurementFields = (fieldsOptions = {}) => [
  {
    name: "profile.height",
    label: "height",
    placeholder: "height",
    options: fieldsOptions.height,
  },
  {
    name: "profile.bust",
    label: "bust",
    placeholder: "bust",
    options: fieldsOptions.other_length,
  },
  {
    name: "profile.waist",
    label: "waist",
    placeholder: "waist",
    options: fieldsOptions.other_length,
  },
  {
    name: "profile.hips",
    label: "hips",
    placeholder: "hips",
    options: fieldsOptions.other_length,
  },
  {
    name: "profile.shoes",
    label: "shoes",
    placeholder: "shoes",
    options: fieldsOptions.shoes,
  },
  {
    name: "profile.hair_color",
    label: "hair-color",
    placeholder: "hair-color",
    options: fieldsOptions.hair_color,
  },
  {
    name: "profile.eyes_color",
    label: "eyes-color",
    placeholder: "eyes-color",
    options: fieldsOptions.eyes_color,
  },
]
