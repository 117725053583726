import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { ControllerHF } from "components"
import { useFetchData } from "hooks"
import { API } from "api"
import { ModelSelect } from "./ModelsSelect"
import { useFormContext } from "react-hook-form"

export const FashionModels = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  // Models
  const {
    fetchData,
    loading: modelsFetching,
    data: modelsData,
  } = useFetchData()

  const onModelsFetch = async () => {
    const params = {
      pagination: false,
    }
    const request = API.model.getModelList(params)
    await fetchData({
      request,
    })
  }
  useEffect(() => {
    onModelsFetch()
  }, [])
  // ==== //

  return (
    <ControllerHF
      name={"fashion_models"}
      control={control}
      component={ModelSelect}
      options={modelsData?.data}
      loading={modelsFetching}
    />
  )
}

FashionModels.propTypes = {}
