import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { commonHelper, modelHelper } from "helpers"

export const ExpenseInfoCell = ({ data }) => {
  const { t } = useTranslation()

  const { value, currency, type, description } = data

  const getCurrencyLabel = currency =>
    commonHelper.currency.getCurrency(currency)?.label?.toUpperCase()

  return (
    <>
      <div className={"text-nowrap"}>
        <span className={"fw-bold"}>{value} </span>

        <span className={"fw-bold"}>
          {getCurrencyLabel(currency)}{" "}
        </span>

        <span>
          {modelHelper.expenses.getExpenseTypeAsOption(type)?.abbreviation}
        </span>
      </div>
      <div>
        <span>{description}</span>
      </div>
    </>
  )
}

ExpenseInfoCell.propTypes = {
  data: PropTypes.object,
}
