import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import i18n from "i18n"
import { API } from "api"
import { chatConversationMessagesRead } from "./slice"
import { chatChatsList } from "../../../chats/list/slice"

export function* readChatSubmit({ payload: { userId, chatId, isGroup } }) {
  try {
    const response = isGroup
      ? yield call(API.chat.seenGroupMessages, chatId)
      : yield call(API.chat.seenUserMessages, userId)

    const { data } = response

    if (chatId) {
      yield put(chatChatsList.updateItem({ id: chatId, unread_count: 0 }))
    }

    return true
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")

    throw error
  }
}

export function* watchRead() {
  yield takeEvery(chatConversationMessagesRead.submit, readChatSubmit)
}

function* readSaga() {
  yield all([fork(watchRead)])
}

export default readSaga
