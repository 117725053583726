import { takeLatest, fork, put, all, call } from "redux-saga/effects"

import i18n from "i18n"
import { API } from "api"
import { calendarEventCompareList } from "./slice"

export function* getList({ payload: { params } }) {
  try {
    const response = yield call(API.calendar.getEvents, params)

    const { data } = response

    yield put(calendarEventCompareList.getListSuccess(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(calendarEventCompareList.getListError(message))
  }
}

export function* watchGetList() {
  yield takeLatest(calendarEventCompareList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
