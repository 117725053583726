import { all } from "redux-saga/effects"

import createSaga from "./create/saga"
import itemSaga from "./item/saga"
import listSaga from "./list/saga"
import removeSaga from "./remove/saga"
import updateSaga from "./update/saga"

function* postSaga() {
  yield all([createSaga(), itemSaga(), listSaga(), removeSaga(), updateSaga()])
}

export default postSaga
