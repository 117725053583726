import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { Col, Row } from "reactstrap"
import { ControllerHF } from "../../../../ReactHookForm"
import { ComboboxFormField, InputField } from "../../../../FormElements"
import { modelHelper } from "helpers"

// const serviceCategories = modelHelper.service.getServiceCategories()

export const ServiceSection = props => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <Row>
      {/*<Col md={6}>*/}
      {/*  <ControllerHF*/}
      {/*    name={"service_category"}*/}
      {/*    control={control}*/}
      {/*    component={ComboboxFormField}*/}
      {/*    id={"service_category"}*/}
      {/*    label={t("service_category")}*/}
      {/*    placeholder={t("service_category")}*/}
      {/*    options={serviceCategories}*/}
      {/*    selectProps={{*/}
      {/*      isClearable: true,*/}
      {/*    }}*/}
      {/*    normalize={option => (option ? option.value : null)}*/}
      {/*    isDetermineValue*/}
      {/*  />*/}
      {/*</Col>*/}

      <Col md={12}>
        <ControllerHF
          name={"service_name"}
          control={control}
          component={InputField}
          id={"service_name"}
          label={t("service_name")}
          placeholder={t("service_name")}
        />
      </Col>

      <Col md={12}>
        <ControllerHF
          name={"service_details"}
          control={control}
          component={InputField}
          id={"service_details"}
          label={t("service_details")}
          placeholder={t("service_details")}
          type={"textarea"}
        />
      </Col>
    </Row>
  )
}

ServiceSection.propTypes = {}
