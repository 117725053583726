import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Col, Container, Row } from "reactstrap"
import { useDeepCompareEffect, useFirstMountState } from "react-use"
import { BooksSlider, ConfirmPopup, Drawer, GridDataLoader } from "components"
import { ModelsFilters } from "../ModelsFilters"
import { websiteModelsDrawerList, websiteModelsUpdateBook } from "store"
import { useConfirmModal } from "hooks"
import { AddBookButton } from "./AddBookButton"

const AddBooksContainerComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isFirstMount = useFirstMountState()
  useEffect(() => {
    return () => {
      dispatch(websiteModelsDrawerList.cleanState())
    }
  }, [])

  useDeepCompareEffect(() => {
    if (props.visible && !isFirstMount) {
      fetchData()
    }
  }, [props.page, props.filters.values, props.visible])

  const fetchData = () => {
    let params = {
      page: props.page,
      per_page: 8,
    }

    const { keywords } = props.filters.values
    params.filters = {
      ...props.filters.values,
      search_fields: keywords ? ["fullname"] : null,
      with_publish_book: false,
      with_book: true,
      genders: [props.gender],
    }
    dispatch(websiteModelsDrawerList.getList({ params }))
  }

  const handlePageChange = item => {
    dispatch(websiteModelsDrawerList.setPage(item.selected + 1))
  }

  const onAddSuccess = () => {
    fetchData()
    props.onAddModelBook()
  }
  const { modalRef, handleConfirm, showModal, closeModal } = useConfirmModal(
    item => {
      dispatch(
        websiteModelsUpdateBook.update({
          id: item.id,
          params: {
            published: true,
          },
          onSuccess: onAddSuccess,
        })
      )
    },
    () => dispatch(websiteModelsUpdateBook.cleanState())
  )

  const handleSearchChange = value => {
    dispatch(websiteModelsDrawerList.changeFilter({ keywords: value }))
  }

  const handleInTownChange = value => {
    dispatch(websiteModelsDrawerList.changeFilter({ is_in_town: value }))
  }

  return (
    <>
      <Drawer
        title={t("website.add-book.title")}
        visible={props.visible}
        onClose={props.onClose}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
      >
        <Container fluid>
          <Row>
            <Col md={8}>
              <div className={"pe-md-3"}>
                <GridDataLoader
                  loading={props.listLoading}
                  error={props.listError}
                  meta={props.meta}
                  page={props.page}
                  onPageChange={handlePageChange}
                  className={"models-books-data-grid models-books-picker-grid"}
                  items={props.list}
                  renderItem={item => (
                    <BooksSlider
                      error={"error"}
                      data={item}
                      actions={book => (
                        <AddBookButton onClick={() => showModal(book)} />
                      )}
                    />
                  )}
                />
              </div>
            </Col>

            <Col md={4} className={"col-divider_start col-divider_md"}>
              <div className={"pt-3 ps-md-3"}>
                <ModelsFilters
                  alias={"books"}
                  filters={props.filters}
                  onSearch={handleSearchChange}
                  onInTownChange={handleInTownChange}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <ConfirmPopup
          ref={modalRef}
          onConfirm={handleConfirm}
          onClosed={closeModal}
        >
          <p className={"font-size-22"}>{t("sure-publish-model-book")}?</p>
        </ConfirmPopup>
      </Drawer>
    </>
  )
}

AddBooksContainerComponent.propTypes = {
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
  filters: PropTypes.object,
  visible: PropTypes.any,
  onClose: PropTypes.func,
  gender: PropTypes.string,
  onAddModelBook: PropTypes.func,
}

const mapStateToProps = state => {
  const { list, listLoading, listError, meta, page, filters } =
    state.website.models.drawerList
  return {
    list,
    listLoading,
    listError,
    meta,
    page,
    filters,
  }
}

export const AddBooksContainer = connect(mapStateToProps)(
  AddBooksContainerComponent
)
