import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  list: [],
}

const modalNotificationsListSlice = createSlice({
  name: "modalNotificationsList",
  initialState,
  reducers: {
    addNotification(state, action) {
      state.list.push({
        data: action.payload?.data,
        alias: action.payload?.alias || null,
      })
    },
    removeNotificationByIndex(state, action) {
      state.list = state.list.filter(
        (_, index) => index !== action.payload.index
      )
    },
  },
})
/**
 * @namespace
 * @property {function} addNotification
 * @property {function} removeNotificationByIndex
 */
export const modalNotificationsList = modalNotificationsListSlice.actions

export default modalNotificationsListSlice.reducer
