import React from 'react';
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { Col, Label, Input, FormFeedback } from "reactstrap"
import classNames from "classnames";

export const TextInput = ({
    id,
    label,
    placeholder,
    type,
    defaultValue,
    reference,
    children,
    prependSymbol,
    appendAccessory,
    normalize,
    rightAccessory,
    disabled,
    ...props
}) => {
    const { field, meta } = props;

    const { touched, error } = meta;

    const { t } = useTranslation();
    const showError = touched && Boolean(error);

    const onChange = (event) => {
        event.target.value = normalize ? normalize(event.target.value) : event.target.value;
        field.onChange(event);

        if (props.onChange) {
            props.onChange(event);
        }
    };

    const renderByAccessoryType = (accessory) => {
        if (accessory) {
            if (typeof accessory === 'string') {
                return <i className={accessory} />
            } else return accessory;
        } else return <></>;
    };

    const renderLabel = (label) => {
        if (label) {
            if (typeof label === 'string') {
                return label;
            } else return label;
        } else return <></>;
    };


    return (
        <div className="mb-3">
            {label && (
                <Label htmlFor={id}>
                    {renderLabel(label)}
                </Label>
            )}
            <div className={classNames('input-group', {
                'is-invalid': showError,
            })}>
                <Input
                    type={type}
                    className={classNames('form-control', {
                        'form-control-right-accessory': rightAccessory,
                        'form-control-border-right-round': rightAccessory && !appendAccessory,
                    })}
                    id={id}
                    placeholder={placeholder}
                    disabled={disabled}
                    invalid={showError}
                    {...field}
                    value={field.value || ''}
                    onChange={(event) => onChange(event)}
                />

                {rightAccessory && (
                    renderByAccessoryType(rightAccessory)
                )}

                {appendAccessory && (
                    appendAccessory.map((item, index) => (
                        item
                    ))
                )}
            </div>

            <FormFeedback invalid={showError ? error : null }>
                { t(error, props.translateParams ? { ...props.translateParams } : {}) }
            </FormFeedback>
        </div>
    );
};

TextInput.propTypes = {
    id: PropTypes.string,
    label: PropTypes.any,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    defaultValue: PropTypes.any,
    reference: PropTypes.any,
    children: PropTypes.any,
    prependSymbol: PropTypes.any,
    appendAccessory: PropTypes.array,
    normalize: PropTypes.func,
    rightAccessory: PropTypes.any,
    disabled: PropTypes.bool,
    field: PropTypes.object,
    meta: PropTypes.object,
    onChange: PropTypes.func,
    translateParams: PropTypes.object,
}

export const TextInputFiled = (props) => {
    const [field, meta, form] = useField(props);

    return (
        <TextInput field={field} meta={meta} {...props} />
    )
}