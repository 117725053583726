import {
    MODEL_PANEL_GENERAL_FETCH_UPLOADED_PHOTOS,
    MODEL_PANEL_GENERAL_FETCH_UPLOADED_PHOTOS_SUCCESS,
    MODEL_PANEL_GENERAL_FETCH_UPLOADED_PHOTOS_ERROR,
    MODEL_PANEL_GENERAL_UPLOADED_PHOTOS_CLEAN_STATE,

    MODEL_PANEL_GENERAL_UPLOADED_PHOTOS_SET_PAGE,

    MODEL_PANEL_GENERAL_SYNC_UPLOADED_PHOTOS,
} from './actionTypes'

export const modelPanelGeneralFetchUploadedPhotos = (id, params) => {
    return {
        type: MODEL_PANEL_GENERAL_FETCH_UPLOADED_PHOTOS,
        payload: { id, params },
    }
};

export const modelPanelGeneralFetchUploadedPhotosSuccess = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_FETCH_UPLOADED_PHOTOS_SUCCESS,
        payload: data,
    }
};

export const modelPanelGeneralFetchUploadedPhotosError = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_FETCH_UPLOADED_PHOTOS_ERROR,
        payload: data,
    }
};

export const modelPanelGeneralUploadedPhotosCleanState = () => {
    return { type: MODEL_PANEL_GENERAL_UPLOADED_PHOTOS_CLEAN_STATE };
};

export const modelPanelGeneralUploadedPhotosSetPage = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_UPLOADED_PHOTOS_SET_PAGE,
        payload: data,
    }
};

export const modelPanelGeneralSyncUploadedPhotos = () => {
    return { type: MODEL_PANEL_GENERAL_SYNC_UPLOADED_PHOTOS };
};