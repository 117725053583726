import React from 'react';
import PropTypes from "prop-types"
import { Container } from "reactstrap";

import { Drawer } from '../../../../../components'

export const DrawerAdd = (props) => {
    return (
        <React.Fragment>
            <Drawer
                title={props.title}
                visible={props.visible}
                onClose={props.onClose}
                placement={'right'}
                className={'drawer-container'}
                destroyOnClose
            >
                <Container fluid>
                    {props.children}
                </Container>
            </Drawer>
        </React.Fragment>
    );
};

DrawerAdd.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
    children: PropTypes.any,
}