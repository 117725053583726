import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Container } from "reactstrap"

import { Drawer, EventCalendarIcon } from "components"
import { EventDataLoader } from "../../EventDataLoader"
import { eventsCalendarHelper } from "helpers"

export const EventPreviewDrawer = props => {
  const { t } = useTranslation()

  const handleCloseDrawer = () => {
    props.onClose()
  }

  const onDestroy = () => {
    props.onDestroy()
  }

  const getTitle = () => {
    return eventsCalendarHelper.calendar.getGeneralEventTypeTitle(
      props.previewEventData,
      props.entityScreen,
      props.entityId
    )
  }

  const title = useMemo(() => {
    let result = t("event-preview")
    if (props.event?.type) {
      result = result + " " + getTitle()
    }

    if (props.previewEventData?.is_closed) {
      result = (
        <div className={"d-flex align-items-center"}>
          {result}
          <EventCalendarIcon name={"closed"} className={"ms-1"} />
        </div>
      )
    }

    return result
  }, [props.event, props.previewEventData])

  const subtitle = useMemo(() => {
    if (props.previewEventData?.user) {
      return `${t("event_created_by")} ` + props.previewEventData?.user?.name
    }
    return null
  }, [props.previewEventData])

  return (
    <React.Fragment>
      <Drawer
        title={title}
        subtitle={subtitle}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <Container fluid>
          <EventDataLoader id={props.event?.id}>
            {props.children}
          </EventDataLoader>
        </Container>
      </Drawer>
    </React.Fragment>
  )
}

EventPreviewDrawer.propTypes = {
  event: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  children: PropTypes.any,
  entityScreen: PropTypes.string,
  entityId: PropTypes.number,
  previewEventData: PropTypes.object,
}
