import { combineReducers } from "redux"

import paymentLink from "./paymentLink/slice"
import cancel from "./cancel/slice"
import resume from "./resume/slice"
import swap from "./swap/slice"

const subscriptionReducer = combineReducers({
  paymentLink,
  cancel,
  resume,
  swap,
})

export default subscriptionReducer
