import { employeeHelper } from "helpers"
import _ from "lodash"

const departments = employeeHelper.general.getDepartmentOptions()
const permissions = employeeHelper.general.getDepartmentsPermissionsOptions()

export const getInitialValues = data => {
  let result = {
    departments: [],
  }

  if (data) {
    result.departments = departments.map(({ value }) => {
      // find department value from server data
      const departmentValue = _.find(data, { alias: value })

      return {
        name: value,
        permissions: permissions.map(item => {
          // find permission in department by key
          const permissionValue = departmentValue.permissions[item.value]

          return {
            name: item.value,
            value: permissionValue || false,
          }
        }),
      }
    })
  }

  return result
}
