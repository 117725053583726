import React, { useLayoutEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import moment from "moment"

import { layoutChangeBodyClass } from "store/actions"

export const TrialPeriodAlert = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { data: user } = useSelector(state => state.user.profile)

  const toggleBodyClass = () => {
    dispatch(layoutChangeBodyClass("trial-alert_enabled"))
  }

  const trialDays = useMemo(() => {
    if (user?.subscription?.trial_ends_at) {
      let start = moment(new Date()).startOf("day")
      let end = moment(user.subscription.trial_ends_at)

      // Difference in number of days
      return moment.duration(end.diff(start)).asDays()
    }

    return -1
  }, [])

  useLayoutEffect(() => {
    toggleBodyClass()

    return () => {
      toggleBodyClass()
    }
  }, [])

  return (
    <div className={"trial-alert"}>
      <div className={"trial-alert__block"}>
        {trialDays > 0 ? (
          <span>
            <Trans i18nKey="trial_period.expired.days" count={trialDays} />
            {"."}
          </span>
        ) : (
          <span>
            {t("subscription.trial_ends_date")} {` : `}
            <span className={"font-weight-bold"}>
              {moment(user?.subscription?.trial_ends_at).format("DD/MM/YYYY")}
              {"."}
            </span>
          </span>
        )}
        &nbsp;
        <Link
          to={"/billing/subscriptions"}
          className={
            "text-decoration-underline font-weight-bold text-white"
          }
        >
          {t("upgrade")}
        </Link>
      </div>
    </div>
  )
}

TrialPeriodAlert.propTypes = {}
