import axios from "axios"

class SocialAccountAPI {
  //====================FACEBOOK===========================//
  getFacebookAccount = async () => {
    return axios.get("api/social-account/facebook")
  }

  addFacebookAccount = async ({ token }) => {
    return axios.post("api/social-account/facebook", { token })
  }

  removeFacebookAccount = async () => {
    return axios.delete(`api/social-account/facebook`)
  }
}

export const socialAccount = new SocialAccountAPI()
