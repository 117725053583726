import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { connect } from "react-redux"

import { CalendarLayout, CalendarContainer } from "./components"
import { MegaTagTitle } from "components"
import { useModal } from "hooks"
import { ICalUrlsManager } from "features/ical/components/ICalUrls/ICalUrlsManager"

const CalendarComponent = props => {
  const { modalRef, closeModal, showModal } = useModal()

  return (
    <>
      <CalendarLayout headerProps={{ onSyncToCalendar: showModal }}>
        <MegaTagTitle title={"title.calendar"} />

        <Row>
          <Col>
            <CalendarContainer />
          </Col>
        </Row>
      </CalendarLayout>

      <ICalUrlsManager modalRef={modalRef} onCancel={closeModal} />
    </>
  )
}

CalendarComponent.propTypes = {}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const Calendar = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarComponent)
