import React from "react"
import PropTypes from "prop-types"
import { useFacebookAuth } from "../../hooks"

export const RequiredFacebookLogin = ({
  children,
  component: Component,
  wrapClass = "",
}) => {
  const { isLoggedIn } = useFacebookAuth()

  if (!isLoggedIn) {
    return (
      <div className={wrapClass}>
        <Component />
      </div>
    )
  } else return children
}

RequiredFacebookLogin.propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.elementType.isRequired,
  wrapClass: PropTypes.string,
}
