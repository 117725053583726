import React, { useMemo } from "react"
import PropTypes from "prop-types"
import _ from "lodash"

import { CalendarGeneralEventContent } from "../CalendarGeneralEventContent"
import { EVENT_TYPE_CASTING, EVENT_TYPE_OPTION, EVENT_TYPE_JOB } from "consts"
import { eventsCalendarHelper } from "helpers"

export const CalendarGeneralModelEventContent = ({
  eventInfo,
  contentData,
  model,
}) => {
  const {
    event: { extendedProps, start },
  } = eventInfo

  const modelContentData = useMemo(() => {
    const { fashion_models, type, entityScreenConfig } = extendedProps

    let data = {}

    if (fashion_models && !!fashion_models.length) {
      const item = _.find(fashion_models, { id: model.id })
      if (item) {
        // get model active status
        data.active = item?.active

        // get model notification status
        data.notificationStatus = item?.notification_status

        let eventTypeView = type
        if (entityScreenConfig) {
          const { entityEventType } = entityScreenConfig
          eventTypeView = entityEventType
        }

        if ([EVENT_TYPE_CASTING, EVENT_TYPE_OPTION].includes(eventTypeView)) {
          data.priority = item?.priority
        }

        if ([EVENT_TYPE_OPTION, EVENT_TYPE_JOB].includes(eventTypeView)) {
          data.isExecutedDate =
            eventsCalendarHelper.event.isExcludedEventDateByRates(
              start,
              item.rates
            )
        }
      }
    }

    return data
  }, [extendedProps])

  return (
    <>
      <CalendarGeneralEventContent {...contentData} {...modelContentData} />
    </>
  )
}

CalendarGeneralModelEventContent.propTypes = {
  eventInfo: PropTypes.object,
  contentData: PropTypes.object,
  model: PropTypes.object,
}
