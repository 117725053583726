import React from "react"
import PropTypes from "prop-types"
import brand_logo from "assets/images/dev/brand-logo-with-title.svg"

export const AuthLogo = props => {
  return (
    <div className={"d-flex justify-content-center mb-4"}>
      <img src={brand_logo} alt={"logo"} />
    </div>
  )
}

AuthLogo.propTypes = {}
