import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

export const Day = ({ date }) => {
  const { t } = useTranslation()

  const renderDay = () => {
    return moment.utc(date).local().format("DD/MM/YYYY")
  }

  return (
    <div className="chat-day-title">
      <div className="title rounded-pill">{renderDay()}</div>
    </div>
  )
}

Day.propTypes = {
  date: PropTypes.any,
}
