import React from "react"
import PropTypes from "prop-types"
import {
  IconButton,
  InfoCardShower,
  SocialNetworkOverview,
} from "../../../../../../components"
import { Col, Container, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { formatPhone } from "../../../../../../utils/format"

export const ContactsInfoCard = props => {
  const { t } = useTranslation()

  return (
    <InfoCardShower
      title={t("contacts_&_social")}
      addMode={props.addMode}
      onAdd={props.onAdd}
      items={props.items}
      loading={props.loading}
      error={props.error}
      meta={props.meta}
      onPageChange={props.onPageChange}
    >
      <div className={"d-flex flex-wrap"}>
        <Container fluid>
          <Row>
            {props.items.map(item => {
              return (
                <Col
                  sm={6}
                  md={4}
                  lg={3}
                  key={item.id}
                  className={"d-flex-col mb-3"}
                >
                  <div className={"mb-2 d-flex align-items-center"}>
                    <div className={"mr-2"}>{item.name}</div>
                    <div className={"d-flex "}>
                      <IconButton
                        name={"edit"}
                        bg
                        onClick={() => props.onEdit(item)}
                        wrapClass={"mr-2"}
                      />
                      <IconButton
                        name={"remove"}
                        bg
                        onClick={() => props.onRemove(item)}
                      />
                    </div>
                  </div>
                  <div className={"mb-1"}>
                    <div className={"fw-bold"}>{t("phone-numbers")}:</div>
                    {item.phones.map(phone => {
                      return (
                        <div key={phone.id}>{formatPhone(phone.value)}</div>
                      )
                    })}
                  </div>
                  <div className={"mb-1"}>
                    <div className={"fw-bold"}>{t("emails")}:</div>
                    {item.emails.map(email => {
                      return (
                        <div key={email.id} className={"text-break"}>
                          {email.value}
                        </div>
                      )
                    })}
                  </div>
                  <div className={"mt-3"}>
                    <SocialNetworkOverview items={item.social_networks} />
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
    </InfoCardShower>
  )
}

ContactsInfoCard.propTypes = {
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  addMode: PropTypes.bool,
  onRemove: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
  meta: PropTypes.any,
  onPageChange: PropTypes.func,
  list: PropTypes.array,
  items: PropTypes.array,
}
