import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Col, Row } from "reactstrap"

import {
  Button,
  ControllerHF,
  FilePickerField,
  InputField,
} from "../../../index"
import { validationSchema } from "./validation"
import { FILE_MAX_SIZE } from "../../../../consts/common/maxFileSize"
import { FileUtils } from "../../../../utils"

export const AttachmentForm = props => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      props.onFormDestroy && props.onFormDestroy()
    }
  }, [])

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      ...props.initialValues,
      file: props.initialValues?.attachment
        ? { attachment: props.initialValues.attachment }
        : null,
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  })

  const onReset = () => {
    reset()
  }

  const onSubmit = values => {
    let params = {
      ...values,
      title: values.title,
      file: values.file?.file ? values.file?.file : "",
    }

    props.onSubmit(params, { onReset, setError })
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form
        onSubmit={e => {
          e.stopPropagation()
          handleSubmit(onSubmit)(e)
        }}
      >
        <Row>
          <Col md={12}>
            <ControllerHF
              name={"title"}
              control={control}
              component={InputField}
              id={"title"}
              label={t("field.label.name")}
              placeholder={t("field.placeholder.name")}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <ControllerHF
              name={"file"}
              control={control}
              component={FilePickerField}
              id={"file"}
              pickerLabelText={t("attach-file")}
              onDownload={FileUtils.downloadFileByLink}
              accept={
                "image/jpeg, image/png, image/gif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              }
              translateParams={{
                text: `${FileUtils.formatBytes(FILE_MAX_SIZE)}`,
              }}
              hidePicker={props.isEdit}
              withRemove={!props.isEdit}
            />
          </Col>
        </Row>

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </>
  )
}

AttachmentForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  attachmentId: PropTypes.number,
  isEdit: PropTypes.bool,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onFormDestroy: PropTypes.func,
}
