import { all } from "redux-saga/effects"

import createSaga from "./create/saga"
import updateSaga from "./update/saga"
import removeSaga from "./remove/saga"
import userSaga from "./user/sagas"
import participantSaga from "./participant/sagas"

function* groupSaga() {
  yield all([
    createSaga(),
    updateSaga(),
    removeSaga(),
    userSaga(),
    participantSaga(),
  ])
}

export default groupSaga
