import React, { useCallback, useState } from "react"

/**
 * Represents a usePopover.
 * @constructor
 * @return {{popoverOpen: boolean, popoverId: string, toggle: (function(): void)}}
 */

export const usePopover = ({ id }) => {
  let popoverId = "Popover-" + id
  const [popoverOpen, setPopoverOpen] = useState(false)
  const toggle = useCallback(() => setPopoverOpen(!popoverOpen), [popoverOpen])
  return { popoverId, toggle, popoverOpen }
}
