import i18n from "i18n"
import { SOCIAL_POST_TYPE_FACEBOOK, SOCIAL_POST_TYPE_INSTAGRAM } from "consts"
import socialPostsColors from "assets/scss/_social_posts_colors.module.scss"
import { find } from "lodash"

export const getPosts = () => {
  return [
    {
      title: i18n.t("post-type.instagram"),
      type: SOCIAL_POST_TYPE_INSTAGRAM,
      theme: "instagram",
      textColor: socialPostsColors.textColor,
      backgroundColor: socialPostsColors.instagramColor,
      borderColor: socialPostsColors.textColor,
    },
    {
      title: i18n.t("post-type.facebook"),
      type: SOCIAL_POST_TYPE_FACEBOOK,
      theme: "facebook",
      textColor: socialPostsColors.textColor,
      backgroundColor: socialPostsColors.facebookColor,
      borderColor: socialPostsColors.textColor,
    },
  ]
}

export const getPostConfig = type => {
  const posts = getPosts()
  return find(posts, { type: type }) || {}
}
