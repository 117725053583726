import React from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"

import { GeneralModelsSidebar } from "../../../../../components"

export const ModelsSidebar = props => {
  const history = useHistory()

  const handleSelectModel = item => {
    if (props.drawerVisible) {
      props.toggleDrawerVisible(false)
    }

    history.push(`/models/${item.id}/calendar`)
  }

  return <GeneralModelsSidebar onModelSelect={handleSelectModel} />
}

ModelsSidebar.propTypes = {
  drawerVisible: PropTypes.bool,
  toggleDrawerVisible: PropTypes.func,
}
