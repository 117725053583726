import { all } from "redux-saga/effects"

import updateSaga from "./update/saga"
import removeSaga from "./remove/saga"
import listSaga from "./list/saga"
import addSaga from "./add/saga"
import dataSaga from "./data/saga"
import filesSaga from "./files/sagas"

function* contactsSaga() {
  yield all([updateSaga(), removeSaga(), listSaga(), addSaga(), dataSaga(), filesSaga()])
}

export default contactsSaga