import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Col } from "reactstrap"
import * as Yup from "yup"
import {
  Button,
  ComboboxFormField,
  ControllerHF,
  InputField,
} from "../../../../../../components"
import { NormalizeUtils } from "../../../../../../utils"
import { commonHelper } from "../../../../../../helpers"

export const PriceForm = props => {
  const { t } = useTranslation()

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      label: "",
      currency: "",
      type_quantity: null,
      price: null,
      ...props.initialValues,
    },
    resolver: yupResolver(
      Yup.object().shape({
        label: Yup.string().required("field.error.required"),
        currency: Yup.object().nullable().required("field.error.required"),
        type_quantity: Yup.number()
          .typeError("field.error.number")
          .nullable(true)
          .transform((v, o) => (o === "" ? null : v))
          .required("field.error.required"),
        price: Yup.number()
          .typeError("field.error.number")
          .nullable(true)
          .transform((v, o) => (o === "" ? null : v))
          .required("field.error.required"),
      })
    ),
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
      currency: values.currency?.value,
    }

    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Col>
          <ControllerHF
            name={"label"}
            control={control}
            component={InputField}
            id={"label"}
            label={t("label")}
            placeholder={t("label")}
          />
          <ControllerHF
            name={"type_quantity"}
            control={control}
            component={InputField}
            id={"type_quantity"}
            label={t("type_quantity")}
            placeholder={t("type_quantity")}
            normalize={NormalizeUtils.onlyNumbers}
          />
          <ControllerHF
            name={"price"}
            control={control}
            component={InputField}
            id={"price"}
            label={t("price")}
            placeholder={t("price")}
            normalize={value => NormalizeUtils.onlyDecimal(value, 2)}
          />
          <ControllerHF
            name={"currency"}
            control={control}
            component={ComboboxFormField}
            id={"currency"}
            label={t("currency")}
            placeholder={t("currency")}
            options={commonHelper.currency.getCurrencies()}
            selectProps={{
              isSearchable: false,
              styles: {
                option: provided => ({
                  ...provided,
                  textTransform: "uppercase",
                }),
                singleValue: provided => ({
                  ...provided,
                  textTransform: "uppercase",
                }),
              },
            }}
            getOptionLabel={option => `${option.label}`}
            getOptionValue={option => option.value}
          />

          <div className="mt-3">
            <div className="button-items">
              <Button
                submit
                title={"btn.save"}
                disabled={props.loading}
                loading={props.loading}
              />

              <Button
                title={"cancel"}
                color="light"
                outline
                onClick={onCancel}
              />
            </div>
          </div>
        </Col>
      </form>
    </>
  )
}

PriceForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
