import React, { useMemo, useCallback } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { ModelActionBar } from "../ModelActionBar"
import { GENERAL_CALENDAR_ENTITY } from "consts"
import { eventsCalendarHelper } from "helpers"
import { useSelector } from "react-redux"
import { selectEventColorByEventType } from "store"

const { MODEL: ENTITY_MODEL } = GENERAL_CALENDAR_ENTITY

export const ModelItem = ({ modelActionBar, ...props }) => {
  const { t } = useTranslation()

  const isMarkModelType = useMemo(() => {
    return (
      props.eventIsEdit &&
      props.eventData &&
      eventsCalendarHelper.calendar.isOptionJobEventType(
        props.eventData?.all_types
      )
    )
  }, [props.eventIsEdit, props.eventData])

  const bgColor = useSelector(state =>
    isMarkModelType
      ? selectEventColorByEventType(state, props.model.type)
      : null
  )

  const highlightModel = useCallback(
    id => {
      if (props.entity === ENTITY_MODEL && props.entityId) {
        return id === props.entityId
      }
      return false
    },
    [props.entity, props.entityId]
  )

  const renderModelTypeMark = useCallback(
    model => {
      if (isMarkModelType) {
        return (
          <div
            className={"event-mark-type ms-1"}
            style={{
              backgroundColor: bgColor,
            }}
          />
        )
      }
      return null
    },
    [isMarkModelType, bgColor]
  )

  return (
    <div className={"d-flex w-100 calendar-event-selected-model"}>
      <div
        className={classNames("selected-list-control__item-text", {
          "fw-bold": highlightModel(props.model?.id),
        })}
      >
        <div className={"calendar-event-selected-model__name"}>
          {props.model.name} {props.model.surname}
        </div>
        {renderModelTypeMark(props.model)}
      </div>

      {modelActionBar && (
        <div className={"d-flex flex-fill justify-content-end ms-3 me-3"}>
          <ModelActionBar
            actionBar={modelActionBar}
            model={props.model}
            eventId={props.eventId}
            eventType={props.eventType}
            eventIsEdit={props.eventIsEdit}
            onModelUpdate={props.onModelUpdate}
            onEventUpdate={props.onEventUpdate}
            onModelEdit={props.onModelEdit}
          />
        </div>
      )}
    </div>
  )
}

ModelItem.propTypes = {
  model: PropTypes.object,
  onModelUpdate: PropTypes.func,
  onModelEdit: PropTypes.func,
  eventType: PropTypes.string,
  eventData: PropTypes.object,
  modelActionBar: PropTypes.object,
  eventId: PropTypes.number,
  eventIsEdit: PropTypes.bool,
  onEventUpdate: PropTypes.func,
  entity: PropTypes.string,
  entityId: PropTypes.number,
}
