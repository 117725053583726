import {
  MODELS_PANEL_FETCH_LIST,
  MODELS_PANEL_FETCH_LIST_ERROR,
  MODELS_PANEL_FETCH_LIST_SUCCESS,
  MODELS_PANEL_LIST_CALL_CHANGE_FILTER,
  MODELS_PANEL_LIST_CHANGE_FILTER,
  MODELS_PANEL_LIST_CLEAN_STATE,
  MODELS_PANEL_LIST_RESET_FETCHED_DATA,
  MODELS_PANEL_LIST_RESET_FILTER,
  MODELS_PANEL_LIST_SET_PAGE,
  MODELS_PANEL_LIST_SYNC,
} from "./actionTypes"

export const modelsPanelFetchList = params => {
  return {
    type: MODELS_PANEL_FETCH_LIST,
    payload: { params },
  }
}

export const modelsPanelFetchListSuccess = data => {
  return {
    type: MODELS_PANEL_FETCH_LIST_SUCCESS,
    payload: data,
  }
}

export const modelsPanelFetchListError = data => {
  return {
    type: MODELS_PANEL_FETCH_LIST_ERROR,
    payload: data,
  }
}

export const modelsPanelListCleanState = () => {
  return { type: MODELS_PANEL_LIST_CLEAN_STATE }
}

export const modelsPanelListSetPage = data => {
  return {
    type: MODELS_PANEL_LIST_SET_PAGE,
    payload: data,
  }
}

export const modelsPanelListChangeFilter = data => {
  return {
    type: MODELS_PANEL_LIST_CHANGE_FILTER,
    payload: data,
  }
}

export const modelsPanelListCallChangeFilter = data => {
  return {
    type: MODELS_PANEL_LIST_CALL_CHANGE_FILTER,
    payload: data,
  }
}

export const modelsPanelListResetFilter = () => {
  return {
    type: MODELS_PANEL_LIST_RESET_FILTER,
  }
}

export const modelsPanelListResetFetchedData = () => {
  return { type: MODELS_PANEL_LIST_RESET_FETCHED_DATA }
}

export const modelsPanelListSync = () => {
  return { type: MODELS_PANEL_LIST_SYNC }
}
