import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { useFormContext } from "react-hook-form"
import { ControllerHF, ModelPhotoCropField } from "components"
import { ModelPhotoCropFieldLabel } from "./ModelPhotoCropFieldLabel"
import { SOCIAL_POST_TYPE_FACEBOOK, SOCIAL_POST_TYPE_INSTAGRAM } from "consts"
import { ModelPhoto } from "./ModelPhoto"

export const SelectedPhotoSection = ({ postType }) => {
  const { setValue, control } = useFormContext()

  const changePhoto = () => {
    setValue("photo", undefined)
  }

  return (
    <Row>
      <Col>
        {postType === SOCIAL_POST_TYPE_FACEBOOK && (
          <ModelPhoto onEditPhoto={changePhoto} />
        )}
        {postType === SOCIAL_POST_TYPE_INSTAGRAM && (
          <ControllerHF
            name={"photo"}
            control={control}
            component={ModelPhotoCropField}
            id={"photo"}
            label={<ModelPhotoCropFieldLabel onEdit={changePhoto} />}
            cropType={"instagram"}
          />
        )}
      </Col>
    </Row>
  )
}

SelectedPhotoSection.propTypes = {
  postType: PropTypes.string,
}
