import React from "react"
import PropTypes from "prop-types"
import { Alert } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { Button } from "components"
import { PermissionsFormSection } from "./PermissionsFormSection"
import { validationSchema } from "./validation"

export const SettingsForm = props => {
  const { t } = useTranslation()

  const methods = useForm({
    defaultValues: {
      departments: [],
      ...props.initialValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const { handleSubmit, setError, reset } = methods

  const onSubmit = values => {
    let params = {
      ...values,
    }

    props.onSubmit(params, { reset, setError })
  }

  return (
    <FormProvider {...methods}>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <PermissionsFormSection />

        <div className="mt-3 pt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

SettingsForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
