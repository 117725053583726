import { CALENDAR_EVENT_CATEGORY_OTHER, EVENT_TYPE_BIRTHDAY } from "consts"
import { eventsCalendarHelper, commonHelper } from "helpers"

const { getColorFromEntity } = commonHelper.colors
const { getGeneralEvents } = eventsCalendarHelper.eventConfig

export const getEventColorsParams = (eventColors, formValues) => {
  let params = {}
  for (const [key, value] of Object.entries(eventColors)) {
    //Set param if only form value not equal to default and not equal to custom
    if (
      formValues[key] !== value.default_color &&
      formValues[key] !== value.custom_color
    ) {
      params = {
        ...params,
        [key]: {
          custom_color: formValues[key],
        },
      }
    }
  }

  return {
    event_colors: [{ ...params }],
  }
}

export const getDefaultColors = eventColors => {
  let values = {}

  getGeneralEvents().forEach(event => {
    let colorItem = eventColors[event.type]
    values[event.type] = colorItem.default_color
  })

  values[CALENDAR_EVENT_CATEGORY_OTHER] =
    eventColors[CALENDAR_EVENT_CATEGORY_OTHER].default_color

  values[EVENT_TYPE_BIRTHDAY] =
    eventColors[EVENT_TYPE_BIRTHDAY]?.default_color || ""

  return values
}

export const formColorsValues = eventColors => {
  let values = {}

  getGeneralEvents().forEach(event => {
    values[event.type] = getColorFromEntity(eventColors[event.type])
  })

  values[CALENDAR_EVENT_CATEGORY_OTHER] = getColorFromEntity(
    eventColors[CALENDAR_EVENT_CATEGORY_OTHER]
  )

  values[EVENT_TYPE_BIRTHDAY] =
    getColorFromEntity(eventColors[EVENT_TYPE_BIRTHDAY]) || ""

  return values
}

//For removing custom_colors on backend
export const getEmptyCustomParams = () => {
  let values = {}

  getGeneralEvents().forEach(event => {
    values[event.type] = null
  })

  values[CALENDAR_EVENT_CATEGORY_OTHER] = null

  values[EVENT_TYPE_BIRTHDAY] = null

  return values
}
