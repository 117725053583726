import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Card, CardBody, Col, Row } from "reactstrap"
import { useDeepCompareEffect } from "react-use"

import { EVENT_TYPE_JOB, EVENT_TYPE_CASTING } from "consts"
import { eventsCalendarHelper } from "helpers"
import { clientActivitiesList } from "store/actions"
import { ActivitiesEntriesContainer } from "./ActivitiesEntriesContainer"
import { TabNavigator } from "components"
import { useFetchData } from "hooks"
import { API } from "api"

const getTabLabel = type =>
  eventsCalendarHelper.eventConfig.getEventConfig(type).title

const ActivitiesContainerComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState(EVENT_TYPE_JOB)
  const [entriesFilters, setEntriesFilters] = useState({
    [EVENT_TYPE_JOB]: {
      fashion_models: [],
    },
    [EVENT_TYPE_CASTING]: {
      fashion_models: [],
    },
  })

  useEffect(() => {
    return () => {
      cleanState()
    }
  }, [])

  const cleanState = () => {
    dispatch(clientActivitiesList.cleanState())
  }

  useDeepCompareEffect(() => {
    fetchList()
  }, [props.page, activeTab, entriesFilters])

  const fetchList = () => {
    const fashion_models =
      entriesFilters[activeTab].fashion_models.map(item => item.id) || []
    let params = {
      page: props.page,
      type: activeTab,
      fashion_models,
    }

    dispatch(clientActivitiesList.getList({ id: props.client.id, params }))
  }

  const handlePageChange = item => {
    dispatch(clientActivitiesList.setPage(item.selected + 1))
  }

  // Models
  const {
    fetchData,
    loading: modelsFetching,
    data: modelsData,
  } = useFetchData()

  const onModelsFetch = async () => {
    const params = {
      pagination: false,
    }
    const request = API.model.getModelList(params)
    await fetchData({
      request,
    })
  }
  useEffect(() => {
    onModelsFetch()
  }, [])
  // ==== //

  const onTabChange = tab => {
    setActiveTab(tab)

    cleanState()
  }

  const onEntriesFiltersChange = (type, value) => {
    setEntriesFilters(prevState => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        ...value,
      },
    }))
  }

  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody className={"bg-neutral"}>
              <TabNavigator
                activeTab={activeTab}
                onTabChange={onTabChange}
                destroyOnClose={true}
                tabBarType={"nav"}
                tabs={[
                  {
                    label: getTabLabel(EVENT_TYPE_JOB),
                    value: EVENT_TYPE_JOB,
                    render: () => (
                      <ActivitiesEntriesContainer
                        type={EVENT_TYPE_JOB}
                        list={props.list}
                        loading={props.listLoading}
                        error={props.listError}
                        meta={props.meta}
                        page={props.page}
                        onPageChange={handlePageChange}
                        filters={entriesFilters[EVENT_TYPE_JOB]}
                        modelsOptions={modelsData?.data}
                        onFiltersChange={value =>
                          onEntriesFiltersChange(EVENT_TYPE_JOB, value)
                        }
                        modelsFetching={modelsFetching}
                      />
                    ),
                  },
                  {
                    label: getTabLabel(EVENT_TYPE_CASTING),
                    value: EVENT_TYPE_CASTING,
                    render: () => (
                      <ActivitiesEntriesContainer
                        type={EVENT_TYPE_CASTING}
                        list={props.list}
                        loading={props.listLoading}
                        error={props.listError}
                        meta={props.meta}
                        page={props.page}
                        onPageChange={handlePageChange}
                        filters={entriesFilters[EVENT_TYPE_CASTING]}
                        modelsOptions={modelsData?.data}
                        onFiltersChange={value =>
                          onEntriesFiltersChange(EVENT_TYPE_CASTING, value)
                        }
                        modelsFetching={modelsFetching}
                      />
                    ),
                  },
                ]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

ActivitiesContainerComponent.propTypes = {
  client: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
}

const mapStateToProps = state => {
  const { data: client } = state.client.profile.data
  const { list, listLoading, listError, meta, page } =
    state.client.activities.list
  return {
    client,
    list,
    listLoading,
    listError,
    meta,
    page,
  }
}

export const ActivitiesContainer = connect(mapStateToProps)(
  ActivitiesContainerComponent
)
