import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Col, Row } from "reactstrap"
import { ControllerHF } from "../../../../ReactHookForm"
import {
  CheckboxField,
  ComboboxFormField,
  InputField,
} from "../../../../FormElements"
import { NormalizeUtils } from "utils"

export const LocationFields = ({ name, control, watch, ...props }) => {
  const { t } = useTranslation()

  const locationIsPrimaryWatch = watch(`${name}.is_primary`)

  return (
    <Row>
      <Col md={12}>
        <Row>
          <Col md={12}>
            <ControllerHF
              control={control}
              component={CheckboxField}
              name={`${name}.is_primary`}
              id={`${name}.is_primary`}
              label={t("primary")}
              wrapClass={"mb-3 form-checkbox-control mt-0"}
              size={"lg"}
              disabled={locationIsPrimaryWatch}
              onChange={props.onPrimaryChange}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <ControllerHF
              name={`${name}.street`}
              control={control}
              component={InputField}
              id={"street"}
              label={t("street")}
              placeholder={t("street")}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <ControllerHF
              name={`${name}.town`}
              control={control}
              component={InputField}
              id={"town"}
              label={t("town")}
              placeholder={t("town")}
            />
          </Col>

          <Col md={6}>
            <ControllerHF
              name={`${name}.state`}
              control={control}
              component={InputField}
              id={"state"}
              label={t("state")}
              placeholder={t("state")}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <ControllerHF
              name={`${name}.zip`}
              control={control}
              component={InputField}
              id={"zip"}
              label={t("zip")}
              placeholder={t("zip")}
            />
          </Col>

          <Col md={6}>
            <ControllerHF
              name={`${name}.country_id`}
              control={control}
              component={ComboboxFormField}
              id={"country_id"}
              label={t("country")}
              placeholder={t("country")}
              options={props.countryOptions}
              getOptionLabel={option => `${option.name}`}
              getOptionValue={option => option.id}
              selectProps={{
                isClearable: true,
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <ControllerHF
              name={`${name}.access`}
              control={control}
              component={InputField}
              id={"access"}
              label={t("access")}
              placeholder={t("access")}
            />
          </Col>

          <Col md={6}>
            <ControllerHF
              name={`${name}.phone`}
              control={control}
              component={InputField}
              id={`phone`}
              label={t("field.label.phone")}
              placeholder={t("field.placeholder.phone")}
              normalize={NormalizeUtils.phone}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

LocationFields.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  countryOptions: PropTypes.array,
  watch: PropTypes.any,
  onPrimaryChange: PropTypes.func,
}
