class FacebookService {
  accessToken = undefined

  setAccessToken(accessToken) {
    this.accessToken = accessToken
  }

  getAccessToken() {
    return this.accessToken
  }

  removeAccessToken() {
    this.setAccessToken(undefined)
  }

  handleError = (response, reject) => {
    if (!response) {
      reject("Error")
    }
    if (response.error) {
      reject(response.error)
    }
  }

  getLoginStatus() {
    return new Promise(resolve => {
      window.FB.getLoginStatus(() => resolve())
    })
  }

  getLongAccessToken(code, machineId) {
    return new Promise((resolve, reject) => {
      window.FB.api(
        "oauth/access_token",
        {
          client_id: process.env.REACT_APP_FACEBOOK_APP_ID,
          redirect_uri: process.env.REACT_APP_FACEBOOK_REDIRECT_URL,
          code,
          machine_id: machineId ? machineId : undefined,
        },
        response => {
          this.handleError(response, reject)
          resolve(response)
        }
      )
    })
  }

  loginToFB(permissions) {
    return new Promise((resolve, reject) => {
      window.FB.login(
        response => {
          if (response.status === "connected") {
            resolve({
              accessToken: response.authResponse?.accessToken,
            })
          } else if (response.status === "not_authorized") {
            reject({ status: "not_authorized" })
          } else {
            reject({ status: "unknown" })
          }
        },
        {
          scope: permissions,
          return_scopes: true,
        }
      )
    })
  }

  getFBPages = async () => {
    return new Promise((resolve, reject) => {
      window.FB.api(
        "me/accounts",
        { access_token: this.accessToken },
        response => {
          this.handleError(response, reject)
          resolve(response.data)
        }
      )
    })
  }

  getInstagramAccountId = async facebookPageId => {
    return new Promise((resolve, reject) => {
      window.FB.api(
        facebookPageId,
        {
          access_token: this.accessToken,
          fields: "instagram_business_account",
        },
        response => {
          this.handleError(response, reject)
          if (response.instagram_business_account) {
            resolve(response.instagram_business_account?.id)
          } else {
            reject(
              "Cannot access connected to page instagram account. Try to change permissions or connect account in settings of the Facebook page"
            )
          }
        }
      )
    })
  }

  getInstagramProfileInfo = async instagramAccountId => {
    return new Promise((resolve, reject) => {
      window.FB.api(
        instagramAccountId,
        {
          access_token: this.accessToken,
          fields: "name, username, profile_picture_url",
        },
        response => {
          this.handleError(response, reject)
          resolve(response)
        }
      )
    })
  }

  createInstagramContainer = async (
    instagramAccountId,
    imageUrl,
    description
  ) => {
    return new Promise((resolve, reject) => {
      window.FB.api(
        `${instagramAccountId}/media`,
        "POST",
        {
          access_token: this.accessToken,
          image_url: imageUrl,
          caption: description,
        },
        response => {
          this.handleError(response, reject)
          resolve(response.id)
        }
      )
    })
  }

  publishInstagramContainer = (instagramAccountId, mediaObjectContainerId) => {
    return new Promise((resolve, reject) => {
      window.FB.api(
        `${instagramAccountId}/media_publish`,
        "POST",
        {
          access_token: this.accessToken,
          creation_id: mediaObjectContainerId,
        },
        response => {
          this.handleError(response, reject)
          resolve(response.id)
        }
      )
    })
  }

  publishPhotoToFBPage = (
    facebookPageId,
    imageUrl,
    pageAccessToken,
    description
  ) => {
    return new Promise((resolve, reject) => {
      window.FB.api(
        `/${facebookPageId}/photos`,
        "POST",
        {
          url: imageUrl,
          message: description,
          access_token: pageAccessToken,
        },
        response => {
          this.handleError(response, reject)
          resolve(response.id)
        }
      )
    })
  }

  getInstagramPostData = instagramPostId => {
    return new Promise((resolve, reject) => {
      window.FB.api(
        `${instagramPostId}`,
        {
          access_token: this.accessToken,
          fields: "comments_count, like_count, is_comment_enabled, permalink",
        },
        response => {
          this.handleError(response, reject)
          resolve(response)
        }
      )
    })
  }

  getInstagramPostLimit = instagramAccountId => {
    return new Promise((resolve, reject) => {
      window.FB.api(
        `${instagramAccountId}/content_publishing_limit`,
        {
          access_token: this.accessToken,
          fields: "config, quota_usage",
        },
        response => {
          this.handleError(response, reject)
          let data = response.data[0]
          resolve({ posts_limit: data?.config?.quota_total - data.quota_usage })
        }
      )
    })
  }
}

export const FacebookClient = new FacebookService()
