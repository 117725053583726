import { all } from "redux-saga/effects"

import loadSaga from "./load/saga"
import photosSaga from "./photos/sagas"
import groupsSaga from "./groups/sagas"

function* userSaga() {
  yield all([loadSaga(), photosSaga(), groupsSaga()])
}

export default userSaga
