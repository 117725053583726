import { combineReducers } from "redux"

import add from "./add/slice"
import list from "./list/slice"

const expenseReducer = combineReducers({
  add,
  list,
})

export default expenseReducer
