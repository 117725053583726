import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { commonHelper } from "helpers"
import { RateRow } from "./RateRow"

export const RatesDetails = props => {
  const { t } = useTranslation()

  const getCurrencyLabel = currency =>
    commonHelper.currency.getCurrency(currency)?.label?.toUpperCase()

  const renderAmounts = data => {
    const items = Object.entries(data)
    return items.map(([key, value], index) => {
      const next = items[index + 1]
      return (
        <span key={key} className={"fw-bold"}>
          {getCurrencyLabel(key)} {value}
          {next ? ", " : null}
        </span>
      )
    })
  }

  return (
    <div className="table-responsive mt-4">
      <table className="table table-cell_p-v_sm">
        <tbody>
          {props.data?.items.map((item, index) => (
            <RateRow
              key={index}
              item={item}
              index={index}
              currency={props.currency}
              onRemove={props.onRemove}
              onEdit={props.onEdit}
            />
          ))}

          <tr className="table-border-bottom">
            <td className="pt-4">
              <span className={"fw-bold text-uppercase"}>
                {t("rates-confirmed-only")}
              </span>
            </td>
            <td className="pt-4">{renderAmounts(props.data.totalFees)}</td>
          </tr>

          <tr className="table-border-bottom">
            <td className="pt-4">
              <span className={"fw-bold text-uppercase"}>
                {t("agency_fees")}
              </span>
            </td>
            <td className="pt-4">{renderAmounts(props.data.agencyFees)}</td>
          </tr>

          <tr className="table-border-bottom">
            <td className="pt-4">
              <span className={"fw-bold"}>{t("total").toUpperCase()}</span>
            </td>
            <td className="pt-4">{renderAmounts(props.data.total)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

RatesDetails.propTypes = {
  data: PropTypes.object,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  currency: PropTypes.string,
}
