import { combineReducers } from "redux";

import data from './data/reducer';
import remove from './remove/reducer';

const listReducer = combineReducers({
    data,
    remove,
});

export default listReducer;