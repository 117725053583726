import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import {
  packageFetchList,
  packageListSetPage,
  packageFetchListCleanState,
} from "store/actions"
import { PackagesList } from "./PackagesList"

const PackagesListContainerComponent = props => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      props.packageFetchListCleanState()
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [props.page, props.syncCount])

  const fetchData = () => {
    let params = {
      page: props.page,
    }

    props.packageFetchList(params)
  }

  const onPageChange = item => {
    props.packageListSetPage(item.selected + 1)
  }

  return (
    <React.Fragment>
      <PackagesList
        list={props.list}
        loading={props.listLoading}
        error={props.listError}
        meta={props.meta}
        page={props.page}
        onPageChange={onPageChange}
        onView={props.onPackageView}
        onRemove={props.onPackageRemove}
        onEdit={props.onPackageEdit}
      />
    </React.Fragment>
  )
}

PackagesListContainerComponent.propTypes = {
  syncCount: PropTypes.number,
  onPackageView: PropTypes.func,
  onPackageRemove: PropTypes.func,
  onPackageEdit: PropTypes.func,

  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,

  packageFetchList: PropTypes.func,
  packageListSetPage: PropTypes.func,
  packageFetchListCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { list, listLoading, listError, meta, page } = state.package.list.data
  return {
    list,
    listLoading,
    listError,
    meta,
    page,
  }
}

const mapDispatchToProps = {
  packageFetchList,
  packageListSetPage,
  packageFetchListCleanState,
}

export const PackagesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackagesListContainerComponent)
