import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { websiteGeneralData } from "../../store/actions"
import * as HOC from "../../HOC"
import { WebsiteRoutes } from "./WebsiteRoutes"

const DataShower = HOC.withDataShower()

export const WebsiteIndexComponent = props => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(websiteGeneralData.getData())
    return () => {
      dispatch(websiteGeneralData.cleanState())
    }
  }, [])
  return (
    <>
      <DataShower
        isLoading={props.loading}
        isFailed={props.error}
        error={props.error}
        customLoaderClass={"page-content"}
        errorContainerClass={"page-content"}
      >
        {props.isFetched && <WebsiteRoutes website={props.website} />}
      </DataShower>
    </>
  )
}

WebsiteIndexComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  website: PropTypes.object,
  isFetched: PropTypes.bool,
}

const mapStateToProps = state => {
  const { loading, error, data, isFetched } = state.website.general.data
  return {
    loading,
    error,
    isFetched,
    website: data,
  }
}

export const WebsiteIndex = connect(mapStateToProps)(WebsiteIndexComponent)
