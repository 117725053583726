import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { InfoCard, EmployeeDetails } from "../../../../components"
import { RatingContainer } from "./RatingContainer"

export const EmployeeDetailsOverview = props => {
  return (
    <Row>
      <Col xl="6" className={"mb-4"}>
        <InfoCard>
          <EmployeeDetails data={props.data} />
        </InfoCard>
      </Col>
      <Col xl={6} className={"mb-4"}>
        <RatingContainer />
      </Col>
    </Row>
  )
}

EmployeeDetailsOverview.propTypes = {
  data: PropTypes.object,
}
