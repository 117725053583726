import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../../i18n';
import { EMPLOYEE_DETAILS_EDIT_PROFILE } from './actionTypes';
import { employeeDetailsEditProfileSuccess, employeeDetailsEditProfileError } from './actions';
import { employeeDetailsUpdateData } from '../data/actions';

import { API } from '../../../../api';

function* editProfile({ payload: { id, params, onSuccess, onError } }) {
    try {
        const response = yield call(API.employee.editEmployee, id, params);

        const { data } = response;

        yield put(employeeDetailsEditProfileSuccess());

        yield put(employeeDetailsUpdateData({ ...data?.data }));

        toastr.success(i18n.t('edit.success'));

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        const errors = error.response?.data?.errors || {};
        const message = error.response?.data?.message || i18n.t('error');

        yield put(employeeDetailsEditProfileError({
            errors,
            message
        }));

        if (onError) {
            onError(errors);
        }
    }
}

export function* watchEditProfile() {
    yield takeEvery(EMPLOYEE_DETAILS_EDIT_PROFILE, editProfile);
}

function* editSaga() {
    yield all([
        fork(watchEditProfile),
    ]);
}

export default editSaga;