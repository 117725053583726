import {
    MODEL_PANEL_GENERAL_DOCUMENT_LIST_REMOVE_ITEM,
    MODEL_PANEL_GENERAL_DOCUMENT_LIST_REMOVE_ITEM_SUCCESS,
    MODEL_PANEL_GENERAL_DOCUMENT_LIST_REMOVE_ITEM_ERROR,
    MODEL_PANEL_GENERAL_DOCUMENT_LIST_REMOVE_ITEM_CLEAN_STATE,
} from './actionTypes';

export const modelPanelGeneralDocumentListRemoveItem = (id, onSuccess) => {
    return {
        type: MODEL_PANEL_GENERAL_DOCUMENT_LIST_REMOVE_ITEM,
        payload: { id, onSuccess },
    }
};

export const modelPanelGeneralDocumentListRemoveItemSuccess = (id) => {
    return {
        type: MODEL_PANEL_GENERAL_DOCUMENT_LIST_REMOVE_ITEM_SUCCESS,
        payload: { id },
    }
};

export const modelPanelGeneralDocumentListRemoveItemError = (id) => {
    return {
        type: MODEL_PANEL_GENERAL_DOCUMENT_LIST_REMOVE_ITEM_ERROR,
        payload: { id },
    }
};

export const modelPanelGeneralDocumentListRemoveItemCleanState = () => {
    return { type: MODEL_PANEL_GENERAL_DOCUMENT_LIST_REMOVE_ITEM_CLEAN_STATE };
};