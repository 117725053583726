import {
    MODEL_PANEL_PHOTOS_ADD_PHOTO,
    MODEL_PANEL_PHOTOS_ADD_PHOTO_SUCCESS,
    MODEL_PANEL_PHOTOS_ADD_PHOTO_ERROR,
    MODEL_PANEL_PHOTOS_ADD_PHOTO_CLEAN_STATE,
} from './actionTypes';

export const modelPanelPhotosAddPhoto = (id, params, onSuccess, onError) => {
    return {
        type: MODEL_PANEL_PHOTOS_ADD_PHOTO,
        payload: { id, params, onSuccess, onError },
    }
};

export const modelPanelPhotosAddPhotoSuccess = () => {
    return {
        type: MODEL_PANEL_PHOTOS_ADD_PHOTO_SUCCESS,
    }
};

export const modelPanelPhotosAddPhotoError = (data) => {
    return {
        type: MODEL_PANEL_PHOTOS_ADD_PHOTO_ERROR,
        payload: data,
    }
};

export const modelPanelPhotosAddPhotoCleanState = () => {
    return { type: MODEL_PANEL_PHOTOS_ADD_PHOTO_CLEAN_STATE };
};