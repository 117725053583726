import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDeepCompareEffect, useFirstMountState } from "react-use"
import { connect, useDispatch } from "react-redux"

import { ContentHeader } from "../ContentHeader"
import { ContactsList } from "./ContactsList"
import { ContactsSearch } from "./ContactsSearch"
import { AddContact } from "./AddContact"

import {
  chatContactsList,
  chatConversationData,
  chatContactsRemove,
} from "store/actions"
import { selectConversationParticipantId } from "store/selectors"
import { useConfirmModal } from "hooks"
import { ConfirmPopup } from "components"

const ContactsContainerComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isFirstMount = useFirstMountState()

  useEffect(() => {
    fetchData()

    return () => {
      dispatch(chatContactsList.cleanState())
    }
  }, [])

  useDeepCompareEffect(() => {
    if (!isFirstMount) {
      fetchData()
    }
  }, [props.filters.values])

  const fetchData = params => {
    params = {
      ...params,
    }

    const { keywords } = props.filters.values
    if (keywords !== "") {
      params.filters = {
        keywords,
      }
    }
    dispatch(chatContactsList.getList({ params }))
  }

  const onContactSelect = item => {
    dispatch(chatConversationData.initParticipant(item))
  }

  const isFilter = props.filters.values?.keywords

  // Remove contact
  const {
    showModal: showRemoveModal,
    closeModal: closeRemoveModal,
    handleConfirm: confirmRemove,
    modalRef: removeModalRef,
  } = useConfirmModal(
    item => {
      dispatch(
        chatContactsRemove.remove({
          id: item.id,
        })
      )
    },
    () => dispatch(chatContactsRemove.cleanState())
  )
  // ==== //

  return (
    <div className={"chat-contacts"}>
      <ContentHeader title={t("contacts")} headerEnd={<AddContact />} />

      <ContactsSearch />

      <ContactsList
        items={props.list}
        loading={props.listLoading}
        error={props.listError}
        onSelect={onContactSelect}
        participantId={props.participantId}
        empty={isFilter ? t("chat.contacts.filter.empty") : null}
        onRemove={showRemoveModal}
      />

      <ConfirmPopup
        ref={removeModalRef}
        onConfirm={confirmRemove}
        onClosed={closeRemoveModal}
        questionText={"confirm-sure"}
      />
    </div>
  )
}

ContactsContainerComponent.propTypes = {
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  filters: PropTypes.object,
  participantId: PropTypes.any,
}

const mapStateToProps = state => {
  const { list, listLoading, listError, filters } = state.chat.contacts.list

  return {
    list,
    listLoading,
    listError,
    filters,
    participantId: selectConversationParticipantId(state),
  }
}

export const ContactsContainer = connect(mapStateToProps)(
  ContactsContainerComponent
)
