import { takeLatest, fork, put, all, call } from "redux-saga/effects"
import i18n from "../../../../i18n"
import { API } from "../../../../api"
import { RequestUtils } from "../../../../utils"
import { employeeRatingsData } from "./slice"

export function* getData({ payload: { id, params } }) {
  try {
    let requestParams = {
      includes: []
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response = yield call(API.employee.getRatingsData, id, requestParams)

    const { data } = response

    const { meta: { current_page, last_page } } = data
    if (current_page && last_page && current_page > last_page) {
      yield put(employeeRatingsData.getData({
        id, params: {
          ...params,
          page: last_page
        }
      }))
    } else {
      yield put(employeeRatingsData.getDataCompleted(data))
    }

  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")

    yield put(employeeRatingsData.getDataRejected(message))
  }
}

export function* watchGetData() {
  yield takeLatest(employeeRatingsData.getData, getData)
}

function* dataSaga() {
  yield all([
    fork(watchGetData)
  ])
}

export default dataSaga