import React from "react"
import PropTypes from "prop-types"

import s from "./RecipientOptionLabel.module.scss"

export const RecipientOptionLabel = ({ option }) => {
  const { email } = option

  return (
    <div className={s.label}>
      <div className={s.label__email}>{email}</div>
    </div>
  )
}

RecipientOptionLabel.propTypes = {
  option: PropTypes.any,
}
