import React, { useEffect, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import {
  modelVideosRemoveVideos,
  modelVideosRemoveVideosCleanState,
} from "../../../../../../store/actions"
import { ConfirmPopup } from "../../../../../../components"
import { useDraftPackageDataPicker } from "../../../../../../hooks"

const DeleteVideosComponent = props => {
  const { t } = useTranslation()

  const { onVideoRemove, isVideoPicked } = useDraftPackageDataPicker()

  const removeModalRef = useRef(null)

  useEffect(() => {
    return () => {
      props.modelVideosRemoveVideosCleanState()
    }
  }, [])

  const removeVideosFormDraftPackage = removeIds => {
    for (let i = 0; i < removeIds.length; i++) {
      const id = removeIds[i]
      if (isVideoPicked(id)) {
        onVideoRemove({ id })
      }
    }
  }

  const confirmRemove = () => {
    removeVideosFormDraftPackage(props.selected)

    props.modelVideosRemoveVideos(props.selected, props.onDeleteSuccess)
  }

  const handleDelete = () => {
    if (removeModalRef.current) {
      removeModalRef.current.show()
    }
  }

  const count = useMemo(() => {
    return props.selected.length
  }, [props.selected])

  return (
    <React.Fragment>
      <Link to="#" className="text-danger" onClick={handleDelete}>
        {t("btn.delete")} {count ? `(${count})` : null}
      </Link>

      <ConfirmPopup ref={removeModalRef} onConfirm={confirmRemove}>
        <p className={"font-size-22"}>{t("sure_delete_videos")}?</p>
      </ConfirmPopup>
    </React.Fragment>
  )
}

DeleteVideosComponent.propTypes = {
  selected: PropTypes.array,
  onDeleteSuccess: PropTypes.func,

  loading: PropTypes.bool,
  error: PropTypes.object,

  modelVideosRemoveVideos: PropTypes.func,
  modelVideosRemoveVideosCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, error } = state.modelPanel.video.list.removeVideos
  return {
    loading,
    error,
  }
}

const mapDispatchToProps = {
  modelVideosRemoveVideos,
  modelVideosRemoveVideosCleanState,
}

export const DeleteVideos = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteVideosComponent)
