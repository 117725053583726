import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect, useDispatch } from "react-redux"
import { Col, Row, Alert } from "reactstrap"
import PropTypes from "prop-types"

import { Button, InfoCard } from "components"
import { billingSubscriptionPaymentLink } from "store/actions"

const BillingIncompletePaymentComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(billingSubscriptionPaymentLink.cleanState())
    }
  }, [])

  const onClick = () => {
    dispatch(billingSubscriptionPaymentLink.getData())
  }

  return (
    <Row className={"mt-4 mb-4"}>
      <Col xl={12}>
        <InfoCard title={t("billing.incomplete_payment.title")}>
          <Alert color="secondary">
            {t("billing.incomplete_payment.message")}
          </Alert>

          {props.error && <Alert color="danger">{props.error}</Alert>}

          <div>
            <Button
              type="button"
              loading={props.loading}
              disabled={props.loading}
              onClick={onClick}
              title={"button.complete_payment"}
            />
          </div>
        </InfoCard>
      </Col>
    </Row>
  )
}

BillingIncompletePaymentComponent.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
}

const mapStateToProps = state => {
  const { data, loading, error } = state.billing.subscription.paymentLink

  return {
    data,
    loading,
    error,
  }
}

export const BillingIncompletePayment = connect(mapStateToProps)(
  BillingIncompletePaymentComponent
)
