import {
    DRAFT_PACKAGE_FORM_SET_VALUES,
    DRAFT_PACKAGE_FORM_RESET_VALUES,
} from './actionTypes';

const initialState = {
    values: {
        description: '',
        title: '',
    },
};

const form = (state = initialState, action) => {
    switch (action.type) {
        case DRAFT_PACKAGE_FORM_SET_VALUES:
            state = {
                ...state,
                values: {
                    ...action.payload,
                },
            };
            break;

        case DRAFT_PACKAGE_FORM_RESET_VALUES:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default form;