import { combineReducers } from "redux"

import upload from "./upload/slice"
import list from "./list/slice"
import edit from "./edit/slice"
import remove from "./remove/slice"

const attachmentReducer = combineReducers({
  upload,
  list,
  edit,
  remove,
})

export default attachmentReducer
