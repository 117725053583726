import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

import { ROLE_DIRECTOR, ROLE_EMPLOYEE } from "consts"
import { middlewareHelper } from "helpers"

export const useUserAccess = props => {
  const { data: user } = useSelector(state => state.user.profile)

  const isDirector = () => {
    return user?.role === ROLE_DIRECTOR
  }

  const isEmployee = () => {
    return user?.role === ROLE_EMPLOYEE
  }

  const isRoleAllow = roles => {
    return middlewareHelper.roleMiddleware.isAllow(roles, user.role)
  }

  const isSettingsAllow = roles => {
    return middlewareHelper.userSettings.isAllow(user)
  }

  const subscriptionAccessAllowed = () => {
    return middlewareHelper.subscription.isAllow(user)
  }

  const subscriptionFullAccessAllowed = () => {
    return middlewareHelper.subscription.fullAccessAllowed(user)
  }

  const isCompanyDeactivated = () => {
    return middlewareHelper.company.isCompanyDeactivated(user)
  }

  const isDepartmentAllow = departments => {
    return middlewareHelper.user.isDepartmentAllow(departments, user.department)
  }

  return {
    isDirector,
    isEmployee,
    isRoleAllow,
    isSettingsAllow,
    subscriptionAccessAllowed,
    subscriptionFullAccessAllowed,
    isCompanyDeactivated,
    isDepartmentAllow,
  }
}

useUserAccess.propTypes = {}
