import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Table } from "reactstrap"

import { CheckboxField, ControllerHF } from "components"
import { employeeHelper } from "helpers"

const getDepartment = employeeHelper.general.getDepartment
const getPermission = employeeHelper.general.getDepartmentPermission

const departments = employeeHelper.general.getDepartmentOptions()
const permissions = employeeHelper.general.getDepartmentsPermissionsOptions()

export const DepartmentsFields = ({ control }) => {
  const { t } = useTranslation()

  const fieldName = "departments"

  return (
    <div className="table-responsive">
      <Table className={"table-border-bottom align-middle"}>
        <thead className={"table-head-bg "}>
          <tr>
            <th />
            {departments.map((item, index) => (
              <th key={`column-${index}`}>
                <div className={"d-flex"}>
                  <div
                    className={
                      "d-flex flex-fill text-nowrap justify-content-center"
                    }
                  >
                    {getDepartment(item.value).label}
                  </div>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {permissions.map((permission, ri) => (
            <tr key={`row-${ri}`}>
              <td>{getPermission(permission.value).label}</td>

              {departments.map((item, cj) => {
                return (
                  <td key={`cell-${cj}`}>
                    <div className={"d-flex justify-content-center"}>
                      <ControllerHF
                        control={control}
                        component={CheckboxField}
                        name={`${fieldName}.${cj}.permissions.${ri}.value`}
                        id={`${fieldName}.${cj}.permissions.${ri}.id`}
                        size={"lg"}
                        wrapClass={"mb-0"}
                        label={null}
                      />
                    </div>
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

DepartmentsFields.propTypes = {
  control: PropTypes.object,
}
