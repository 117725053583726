import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
}

const chatGroupsLeaveSlice = createSlice({
  name: "chatGroupsLeave",
  initialState,
  reducers: {
    leave(state) {
      state.loading = true
    },
    leaveSuccess(state) {
      state.loading = false
    },
    leaveError(state, action) {
      state.loading = false
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} leave
 * @property {function} leaveSuccess
 * @property {function} leaveError
 * @property {function} cleanState
 */
export const chatGroupsLeave = chatGroupsLeaveSlice.actions

export default chatGroupsLeaveSlice.reducer
