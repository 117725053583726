import { analyticsConfig } from "configs"
import { NormalizeUtils } from "utils"
import { gtmService } from "../gtm"

class AppAnalyticsService {
  enabled =
    NormalizeUtils.stringBoolToBoolean(
      process.env.REACT_APP_ANALYTICS_ENABLED
    ) || false

  isEnabled = () => {
    return this.enabled
  }

  init = () => {
    if (this.isEnabled()) {
      gtmService.init(analyticsConfig.gtm)
    }
  }

  dispatch = (data = {}) => {
    if (this.isEnabled()) {
      gtmService.dispatch(data)
    }
  }
}

export const appAnalyticsService = new AppAnalyticsService()
