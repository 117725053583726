import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import {
  IconButton,
  Image,
  ReadMoreExpand,
  SelectionControl,
  SocialPublish,
} from "components"
import { MEDIA_CONTENT_TYPE } from "consts"

export const PhotosGridItem = props => {
  const { t } = useTranslation()

  return (
    <div className="model-photo-card">
      <div className={"model-photo-card__photo-block"}>
        <Image
          src={props.item?.attachment?.src}
          alt={"model-photo"}
          crops={props.item?.crops}
        />

        {props.item.is_avatar && (
          <div className="model-photo-card__avatar-badge-block">
            <div className={"model-photo-card__avatar-badge"}>
              <i className="align-middle bx bx-user" />
            </div>
          </div>
        )}
        <div className={"model-photo-card__actions-block"}>
          <SelectionControl
            id={`photo-selection-control-${props.item.id}`}
            onChange={() => props.onSelectPhoto(props.item)}
            selected={props.selected}
            disabled={props.removing}
          />
          <IconButton
            name={"edit"}
            onClick={() => props.onEdit(props.item)}
            bg
            disabled={props.removing}
          />
          <IconButton
            name={"remove"}
            onClick={() => props.onRemove(props.item)}
            bg
            disabled={props.removing}
          />
          <SocialPublish
            attachmentId={props.item.id}
            src={props.item.attachment.src}
            crops={props.item.crops}
            description={props.item.description}
            type={MEDIA_CONTENT_TYPE.IMAGE}
          />

          <button
            className={"icon-btn bg-dark text-white"}
            type={"button"}
            onClick={() => props.onDownload(props.item)}
            disabled={props.removing}
          >
            <span className={"font-size-10 font-weight-semibold"}>
              {t("pdf")}
            </span>
          </button>
        </div>
      </div>

      {props.item.title && (
        <div className={"model-photo-card__title-block"}>
          <div className={"model-photo-card__title"}>{props.item.title}</div>
        </div>
      )}

      {props.item.description && (
        <div className={"model-photo-card__body"}>
          <ReadMoreExpand
            className={"model-photo-card__description"}
            maxHeight={64}
            lineHeight={16}
          >
            {props.item.description}
          </ReadMoreExpand>
        </div>
      )}
    </div>
  )
}

PhotosGridItem.propTypes = {
  item: PropTypes.object,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  removing: PropTypes.bool,
  selected: PropTypes.bool,

  onSelectPhoto: PropTypes.func,
  onDownload: PropTypes.func,
}
