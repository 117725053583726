import React from "react"
import { PhotosContainer } from "./PhotosContainer"
import { useFormContext } from "react-hook-form"
import { useConfirmModal } from "hooks"
import { ConfirmPopup } from "components"
import { Label } from "reactstrap"
import { useTranslation } from "react-i18next"

export const PhotosSection = () => {
  const { watch, setValue } = useFormContext()
  const { t } = useTranslation()
  const model = watch("model")

  const { handleConfirm, modalRef, showModal, closeModal } = useConfirmModal(
    item => {
      setValue("photo", item, { shouldValidate: true })
    }
  )

  if (!model) {
    return <Label>{t("no-model-selected")}</Label>
  }

  return (
    <>
      <Label>{t("choose-photo")}</Label>
      <PhotosContainer onSelect={showModal} model={model} />
      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
        questionText={"confirm-photo-for-publication"}
      />
    </>
  )
}

PhotosSection.propTypes = {}
