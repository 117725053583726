import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useConfirmModal } from "hooks"
import { ConfirmPopup } from "components"
import { websiteRemoveRequest, websiteRequestsList } from "store"
import { RequestDetailsDrawer } from "../RequestDetails"
import { RequestsTable } from "../RequestsTable"

const RequestsContainerComponent = props => {
  const dispatch = useDispatch()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [requestDetails, setRequestDetails] = useState(null)
  const handleRequestShowDetails = requestDetails => {
    setRequestDetails(requestDetails)
    setDrawerVisible(true)
  }

  const closeDrawer = () => {
    setDrawerVisible(false)
    setRequestDetails(null)
  }
  useEffect(() => {
    return () => {
      dispatch(websiteRequestsList.cleanState())
    }
  }, [])

  useEffect(() => {
    fetchList()
  }, [props.page])

  const fetchList = () => {
    let params = {
      page: props.page,
      per_page: 10,
      pagination: true,
    }
    dispatch(websiteRequestsList.getList({ params }))
  }

  const handlePageChange = item => {
    dispatch(websiteRequestsList.setPage(item.selected + 1))
  }

  const onRemoveSuccess = () => {
    fetchList()
  }

  const { showModal, handleConfirm, modalRef, closeModal } = useConfirmModal(
    item => {
      dispatch(
        websiteRemoveRequest.remove({
          requestId: item.id,
          onSuccess: onRemoveSuccess,
        })
      )
    },
    () => {
      dispatch(websiteRemoveRequest.cleanState())
    }
  )
  return (
    <>
      <RequestsTable
        list={props.list}
        loading={props.listLoading}
        error={props.listError}
        meta={props.meta}
        page={props.page}
        onRemove={showModal}
        removingLoading={props.removeLoading}
        onPageChange={handlePageChange}
        onShowDetails={handleRequestShowDetails}
      />

      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
      />
      <RequestDetailsDrawer
        visible={drawerVisible}
        onClose={closeDrawer}
        requestDetails={requestDetails}
      />
    </>
  )
}

RequestsContainerComponent.propTypes = {
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
  removeLoading: PropTypes.bool,
}

const mapStateToProps = state => {
  const { list, listLoading, listError, meta, page } =
    state.website.requests.list
  const { loading: removeLoading } = state.website.requests.remove
  return {
    list,
    listLoading,
    listError,
    meta,
    page,
    removeLoading,
  }
}

export const RequestsContainer = connect(mapStateToProps)(
  RequestsContainerComponent
)
