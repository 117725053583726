import React, { useMemo } from "react"
import PropTypes from "prop-types"

import {
  CalendarGeneralEventContent,
  CalendarGeneralModelEventContent,
} from "../../EventsCalendarEventContent"
import {
  EVENT_TYPE_JOB,
  EVENT_TYPE_OPTION,
  GENERAL_CALENDAR_ENTITY,
} from "consts"
import { eventsCalendarHelper } from "../../../../../helpers"
import { isExcludedEventDateByModels } from "../../../../../helpers/eventsCalendar/event"

const {
  MODEL: ENTITY_MODEL,
  AGENCY: ENTITY_AGENCY,
  CLIENT: ENTITY_CLIENT,
} = GENERAL_CALENDAR_ENTITY

export const GeneralProvider = ({
  eventInfo,
  eventContent,
  entity = null,
  entityData = null,
}) => {
  const {
    event: { title, start, extendedProps },
  } = eventInfo

  const contentData = useMemo(() => {
    const { type, fashion_models } = extendedProps

    let result = {
      title: title,
      subTitle: extendedProps.product,
      times: extendedProps.times,
      active: extendedProps.active,
      notificationStatus: extendedProps.notification_status,
      closed: extendedProps.is_closed,
      done: extendedProps.done,
      isExecutedDate: false,
    }

    // if not entity calendar && is job or option type => check excluded event date
    if (
      entity !== ENTITY_MODEL &&
      [EVENT_TYPE_OPTION, EVENT_TYPE_JOB].includes(type)
    ) {
      result.isExecutedDate =
        eventsCalendarHelper.event.isExcludedEventDateByModels(
          start,
          fashion_models
        )
    }

    return result
  }, [extendedProps, title, entity])

  switch (entity) {
    case ENTITY_MODEL:
      return (
        <CalendarGeneralModelEventContent
          eventInfo={eventInfo}
          contentData={contentData}
          model={entityData}
        />
      )

    default:
      return (
        <>
          {eventContent ? (
            eventContent(eventInfo, contentData)
          ) : (
            <CalendarGeneralEventContent {...contentData} />
          )}
        </>
      )
  }
}

GeneralProvider.propTypes = {
  eventInfo: PropTypes.object,
  eventContent: PropTypes.func,
  entity: PropTypes.oneOf([ENTITY_MODEL, ENTITY_AGENCY, ENTITY_CLIENT]),
  entityData: PropTypes.object,
}
