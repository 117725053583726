import { createAction } from "@reduxjs/toolkit"

const SOCKET_ON_CHAT_SEEN = "socket/onChatSeen"

export const socketOnChatSeen = createAction(
  SOCKET_ON_CHAT_SEEN,
  function prepare(data) {
    return {
      payload: {
        data,
      },
    }
  }
)
