import React from "react"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom"
import { LinkButton } from "components"
import { useTranslation } from "react-i18next"
import { WEBSITE_DOMAIN_PATH, HOME_PAGE } from "consts"

export const HeaderLink = ({ page, subdomain, linkType }) => {
  const { state: pageState } = useLocation()
  const { t } = useTranslation()

  const isWebsiteLink = (page, pageState) => {
    if (page?.alias === HOME_PAGE) return true
    if (!page && !pageState) return true
    return false
  }

  const renderLink = (page, subdomain, linkType) => {
    switch (linkType) {
      case "page": {
        const isPageLink = !!(page || pageState)
        return isPageLink ? (
          <LinkButton
            to={`https://${subdomain}${WEBSITE_DOMAIN_PATH}/${page?.alias}`}
            title={t("open-page-link")}
            btnClass={"mr-2"}
            target={"blank"}
            color={"secondary"}
          />
        ) : null
      }
      case "website": {
        return isWebsiteLink(page, pageState) ? (
          <LinkButton
            to={`https://${subdomain}${WEBSITE_DOMAIN_PATH}`}
            title={t("open-website")}
            btnClass={"mr-2"}
            target={"blank"}
            color={"secondary"}
          />
        ) : null
      }
      default: {
        return null
      }
    }
  }
  return renderLink(page, subdomain, linkType)
}

HeaderLink.propTypes = {
  page: PropTypes.any,
  subdomain: PropTypes.string,
  linkType: PropTypes.string,
}
