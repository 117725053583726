import React from "react"
import PropTypes from "prop-types"
import { FormProvider, useForm } from "react-hook-form"

import { useFormValuesChangedDebounce } from "hooks"
import s from "./EventsFilter.module.scss"
import { Period } from "./Period"
import { ClosedState } from "./ClosedState"
import { Client } from "./Client"
import { Booker } from "./Booker"
import { Product } from "./Product"
import { FashionModels } from "./FashionModels"
import { Agencies } from "./Agencies"
import { RatesSumMin } from "./RatesSumMin"
import { RatesSumMax } from "./RatesSumMax"
import { Export } from "./Export"
import { OutgoingPayments } from "./OutgoingPayments"
import { IncomingPayments } from "./IncomingPayments"

import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

export const EventsFilter = ({ filter, onFilterChange, onExport }) => {
  const methods = useForm({
    defaultValues: {
      ...filter,
    },
    resolver: yupResolver(
      Yup.object().shape({
        period: Yup.array().test(
          "period",
          "field.error.period.required",
          value => {
            const [date_start, date_end] = value
            return date_start && date_end
          }
        ),
        rates_sum_min: Yup.number()
          .nullable(true)
          .transform((v, o) => (o === "" ? null : v)),
        rates_sum_max: Yup.number()
          .nullable(true)
          .transform((v, o) => (o === "" ? null : v)),
      })
    ),
    mode: "onChange",
  })
  const { watch} = methods

  useFormValuesChangedDebounce(watch, onFilterChange)

  return (
    <FormProvider {...methods}>
      <div className={`${s.grid}`}>
        <div className={`${s.gridRow}`}>
          <GridCol>
            <Period />
          </GridCol>

          <GridCol>
            <ClosedState />
          </GridCol>

          <GridCol>
            <Client />
          </GridCol>

          <GridCol>
            <Product />
          </GridCol>

          <GridCol>
            <FashionModels />
          </GridCol>

          <GridCol>
            <Agencies />
          </GridCol>

          <GridCol>
            <RatesSumMin />
          </GridCol>

          <GridCol>
            <RatesSumMax />
          </GridCol>

          <GridCol>
            <Booker />
          </GridCol>

          <GridCol>
            <OutgoingPayments />
          </GridCol>

          <GridCol>
            <IncomingPayments />
          </GridCol>

          <GridCol>
            <Export onExport={onExport} />
          </GridCol>
        </div>
      </div>
    </FormProvider>
  )
}

EventsFilter.propTypes = {
  filter: PropTypes.object,
  onFilterChange: PropTypes.func,
  onExport: PropTypes.func,
}

const GridCol = ({ children }) => {
  return <div className={`${s.gridCol}`}>{children}</div>
}

GridCol.propTypes = {
  children: PropTypes.node,
}
