import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Row } from "reactstrap"
import _ from "lodash"

import { PhotosGridItem } from "./PhotosGridItem"
import { MasonryDataGrid } from "components"

export const PhotosGrid = props => {
  const masonryConfig = {
    options: {
      gutter: 8,
    },
    enableResizableChildren: true,
  }

  const masonryRef = useRef(null)

  return (
    <Row>
      <div className={"model-panel-photos-grid mb-4"}>
        <MasonryDataGrid
          masonryRef={masonryRef}
          masonryConfig={masonryConfig}
          loading={props.loading}
          error={props.error}
          meta={props.meta}
          onPageChange={props.onPageChange}
          items={props.items}
          renderItem={item => (
            <div className={"grid-item"}>
              <PhotosGridItem
                item={item}
                onRemove={props.onPhotoRemove}
                onEdit={props.onPhotoEdit}
                removing={props.removingItems.includes(item.id)}
                selected={!!_.find(props.selectedPhotos, { id: item.id })}
                onSelectPhoto={props.onSelectPhoto}
                onDownload={props.onPhotoDownload}
              />
            </div>
          )}
        />
      </div>
    </Row>
  )
}

PhotosGrid.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,
  removingItems: PropTypes.array,
  selectedPhotos: PropTypes.array,

  onPageChange: PropTypes.func,
  onPhotoRemove: PropTypes.func,
  onPhotoEdit: PropTypes.func,
  onSelectPhoto: PropTypes.func,
  onPhotoDownload: PropTypes.func,
}
