import React from "react"
import PropTypes from "prop-types"

import { Settings } from "../Settings"
import { useDrawer } from "hooks"
import { Button } from "components"

export const SettingsButton = props => {
  const {
    hide: hideDrawer,
    show: showDrawer,
    visible: drawerVisible,
  } = useDrawer()

  const onSuccess = () => {
    hideDrawer()
  }

  return (
    <>
      <Button
        leftIcon={"bx bx-cog"}
        title={"settings"}
        onClick={showDrawer}
        color={"secondary"}
        btnClass={"me-2"}
      />

      <Settings
        visible={drawerVisible}
        onClose={hideDrawer}
        onSuccess={onSuccess}
      />
    </>
  )
}

SettingsButton.propTypes = {}
