import { takeEvery, fork, put, all, select } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { socketOnBillingInfoVatIdStatusUpdate } from "./actions"
import { billingBillingInfoData } from "../../../billing/billingInfo/actions"
import { BILLING_TAX_ID_STATUS } from "consts"

const { UNVERIFIED, VERIFIED } = BILLING_TAX_ID_STATUS

export const billingInfoDataState = state => state.billing.billingInfo.data

function* onVatIdStatusUpdate({ payload: { data } }) {
  const { status } = data

  const billingInfo = yield select(billingInfoDataState)

  if (billingInfo) {
    yield put(billingBillingInfoData.updateData({ tax_id_status: status }))
  }

  if (billingInfo?.tax_id_status !== status) {
    if (status === UNVERIFIED) {
      toastr.error(i18n.t("vat_id_unverified"))
    } else if (status === VERIFIED) {
      toastr.success(i18n.t("vat_id_verified"))
    }
  }
}

export function* watchOnVatIdStatusUpdate() {
  yield takeEvery(socketOnBillingInfoVatIdStatusUpdate, onVatIdStatusUpdate)
}

function* infoSaga() {
  yield all([fork(watchOnVatIdStatusUpdate)])
}

export default infoSaga
