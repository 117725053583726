import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import {
  PackageDataGrid,
  BookOverviewCard,
  IconButton,
} from "../../../../../components"

export const BooksTab = props => {
  const { t } = useTranslation()

  return (
    <PackageDataGrid
      className={"package-data-picker-grid"}
      items={props.books}
      empty={t("no_books")}
      renderItem={item => (
        <BookOverviewCard
          title={item.title}
          description={item.description}
          cover={item?.cover?.photo?.attachment?.src}
          crops={item?.cover?.photo?.crops}
          size={"sm"}
          onCoverClick={() => props.onCoverClick(item)}
          actions={
            <IconButton
              name={"remove"}
              onClick={() => props.onRemove(item)}
              bg
              disabled={false}
            />
          }
        />
      )}
    />
  )
}

BooksTab.propTypes = {
  books: PropTypes.array,
  onRemove: PropTypes.func,
  onCoverClick: PropTypes.func,
}
