import React from "react"
import PropTypes from "prop-types"
import { Modal } from "components/Modal"
import { useTranslation } from "react-i18next"

export const PublishModal = ({ modalRef, onClose, children }) => {
  const { t } = useTranslation()

  return (
    <Modal
      ref={modalRef}
      title={t("publish-content")}
      strapModalProp={{
        centered: true,
        onClosed: onClose,
      }}
    >
      {children}
    </Modal>
  )
}

PublishModal.propTypes = {
  modalRef: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
}
