import {
    MODEL_PANEL_GENERAL_FETCH_DOCUMENT_LIST,
    MODEL_PANEL_GENERAL_FETCH_DOCUMENT_LIST_SUCCESS,
    MODEL_PANEL_GENERAL_FETCH_DOCUMENT_LIST_ERROR,
    MODEL_PANEL_GENERAL_FETCH_DOCUMENT_LIST_CLEAN_STATE,

    MODEL_PANEL_GENERAL_DOCUMENT_LIST_SET_PAGE,
} from './actionTypes'

export const modelPanelGeneralFetchDocumentList = (id, params) => {
    return {
        type: MODEL_PANEL_GENERAL_FETCH_DOCUMENT_LIST,
        payload: { id, params },
    }
};

export const modelPanelGeneralFetchDocumentListSuccess = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_FETCH_DOCUMENT_LIST_SUCCESS,
        payload: data,
    }
};

export const modelPanelGeneralFetchDocumentListError = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_FETCH_DOCUMENT_LIST_ERROR,
        payload: data,
    }
};

export const modelPanelGeneralFetchDocumentListCleanState = () => {
    return { type: MODEL_PANEL_GENERAL_FETCH_DOCUMENT_LIST_CLEAN_STATE };
};

export const modelPanelGeneralDocumentListSetPage = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_DOCUMENT_LIST_SET_PAGE,
        payload: data,
    }
};