import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import { MegaTagTitle } from "../../components"
import { ResetPasswordForm } from "./components"

const ResetPasswordComponent = props => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <MegaTagTitle title={"title.reset_password"} />

      <Card className="overflow-hidden">
        <div className="bg-primary">
          <Row>
            <Col>
              <div className=" p-4 text-center">
                <h5 className="text-white mb-0">{t("title.reset_password")}</h5>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody>
          <div className="p-2">
            <ResetPasswordForm />
          </div>
        </CardBody>
      </Card>

      <div className="mt-5 text-center">
        <p>
          {t("go.back.to")}{" "}
          <Link to="/login" className="fw-medium text-secondary">
            {" "}
            {t("btn.login")}{" "}
          </Link>{" "}
        </p>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const ResetPassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordComponent)
