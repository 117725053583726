import { all } from "redux-saga/effects"

import listSaga from "./list/saga"
import paymentSaga from "./payment/sagas"
import dataSaga from "./data/sagas"

function* statementSaga() {
  yield all([listSaga(), paymentSaga(), dataSaga()])
}

export default statementSaga
