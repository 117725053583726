import { combineReducers } from "redux"

import list from "./list/slice"

import remove from "./remove/slice"
import update from "./update/slice"
import add from "./add/slice"

const workReducer = combineReducers({
  list,
  remove,
  update,
  add,
})

export default workReducer
