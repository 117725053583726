import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { EditWork } from "./EditWork"
import { modelGeneralWorkList, modelRemoveWork } from "store/actions"
import { WorkTable } from "./WorkInfoCard/WorkTable"
import { ConfirmPopup, InfoCardShower } from "components"
import { useConfirmModal, useDrawer } from "hooks"
import { AddWork } from "./AddWork"

const WorkContainerComponent = props => {
  const dispatch = useDispatch()

  //=======================REMOVE LOGIC ==============================//
  const { modalRef, showModal, closeModal, handleConfirm } = useConfirmModal(
    item => {
      dispatch(
        modelRemoveWork.remove({ id: item.id, onSuccess: onRemoveSuccess })
      )
    },
    () => {
      dispatch(modelRemoveWork.cleanState())
    }
  )
  const onRemoveSuccess = () => {
    fetchList()
  }

  //=============================UPDATE LOGIC ==============================//
  const {
    hide: hideEditDrawer,
    show: showEditDrawer,
    visible: editDrawerVisible,
    data,
    resetData,
  } = useDrawer()

  //============================ADD LOGIC =================================//
  const {
    hide: hideAddDrawer,
    show: showAddDrawer,
    visible: addDrawerVisible,
  } = useDrawer()

  const onAddSuccess = () => {
    fetchList()
  }

  //============================== LIST DATA LOGIC ========================//
  useEffect(() => {
    fetchList()
    return () => {
      modelGeneralWorkList.cleanState()
    }
  }, [props.page])

  const fetchList = () => {
    let params = {
      page: props.page,
      per_page: 5,
    }
    dispatch(modelGeneralWorkList.getList({ id: props.model?.id, params }))
  }
  const handlePageChange = item => {
    dispatch(modelGeneralWorkList.setPage(item.selected + 1))
  }
  //========================================================================//
  return (
    <>
      <InfoCardShower
        title={"works"}
        className={"model-works-info"}
        addMode
        onAdd={showAddDrawer}
        items={props.list}
        loading={props.listLoading}
        error={props.listError}
        meta={props.meta}
        onPageChange={handlePageChange}
      >
        <WorkTable
          items={props.list}
          onRemove={showModal}
          onEdit={showEditDrawer}
          removeLoading={props.removeLoading}
        />
      </InfoCardShower>
      <EditWork
        visible={editDrawerVisible}
        data={data}
        modelId={props.model?.id}
        onClose={hideEditDrawer}
        onDestroy={resetData}
      />
      <AddWork
        visible={addDrawerVisible}
        modelId={props.model?.id}
        onClose={hideAddDrawer}
        onAddSuccess={onAddSuccess}
      />
      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
      />
    </>
  )
}

WorkContainerComponent.propTypes = {
  model: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  page: PropTypes.any,
  removeLoading: PropTypes.bool,
  error: PropTypes.object,
  meta: PropTypes.any,
}

const mapStateToProps = state => {
  const { list, listLoading, listError, meta, page } =
    state.modelPanel.general.work.list
  const { loading: removeLoading } = state.modelPanel.general.work.remove
  return {
    model: state.model.profile?.data,
    list,
    listLoading,
    listError,
    meta,
    page,
    removeLoading,
  }
}

export const WorkContainer = connect(mapStateToProps)(WorkContainerComponent)
