import {
  MODEL_PANEL_GENERAL_FETCH_LOGISTICS,
  MODEL_PANEL_GENERAL_FETCH_LOGISTICS_SUCCESS,
  MODEL_PANEL_GENERAL_FETCH_LOGISTICS_ERROR,
  MODEL_PANEL_GENERAL_FETCH_LOGISTICS_CLEAN_STATE,
} from "./actionTypes"

export const modelPanelGeneralFetchLogistics = id => {
  return {
    type: MODEL_PANEL_GENERAL_FETCH_LOGISTICS,
    payload: { id },
  }
}

export const modelPanelGeneralFetchLogisticsSuccess = data => {
  return {
    type: MODEL_PANEL_GENERAL_FETCH_LOGISTICS_SUCCESS,
    payload: data,
  }
}

export const modelPanelGeneralFetchLogisticsError = data => {
  return {
    type: MODEL_PANEL_GENERAL_FETCH_LOGISTICS_ERROR,
    payload: data,
  }
}

export const modelPanelGeneralFetchLogisticsCleanState = () => {
  return { type: MODEL_PANEL_GENERAL_FETCH_LOGISTICS_CLEAN_STATE }
}
