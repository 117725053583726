import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { FieldError } from "components/FormElements/FieldError"
import { ModelPhotoCropControl } from "components"
import { Label } from "reactstrap"

export const ModelPhotoCropField = ({
  id,
  label,
  value,
  onChange,
  wrapClass = "mb-3",
  meta,
  cropType,
}) => {
  const { error } = meta

  const { t } = useTranslation()

  const showError = Boolean(error)

  const renderLabel = label => {
    if (label) {
      if (typeof label === "string") {
        return label
      } else return label
    } else return <></>
  }

  const handleCropChange = data => {
    onChange(data)
  }

  return (
    <div
      className={classNames({
        [wrapClass]: wrapClass,
      })}
    >
      <div
        className={classNames("d-flex flex-column ", {
          "is-invalid": showError,
        })}
      >
        {label && (
          <Label className={"mb-2"} htmlFor={id}>
            {renderLabel(label)}
          </Label>
        )}
        {value ? (
          <ModelPhotoCropControl
            cropType={cropType}
            photo={value}
            onCropChange={handleCropChange}
          />
        ) : (
          <div>{t("empty")}</div>
        )}
      </div>
      <FieldError error={error} showError={showError} />
    </div>
  )
}

ModelPhotoCropField.propTypes = {
  id: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.object,
  onChange: PropTypes.func,
  wrapClass: PropTypes.string,
  meta: PropTypes.object,
  cropType: PropTypes.string,
}
