import { createSlice } from "@reduxjs/toolkit"

const initialState = {}

const chatConversationMessagesReadSlice = createSlice({
  name: "chatConversationMessagesRead",
  initialState,
  reducers: {
    submit() {
      return initialState
    },
  },
})

/**
 * @namespace
 * @property {function} submit
 */

export const chatConversationMessagesRead =
  chatConversationMessagesReadSlice.actions

export default chatConversationMessagesReadSlice.reducer
