import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../i18n';
import { PROFILE_CHANGE_PASSWORD } from './actionTypes';
import { profileChangePasswordSuccess, profileChangePasswordError } from './actions';

import { API } from '../../../api';

function* changePassword({ payload: { params, onSubmitProps } }) {
    const { setErrors, setSubmitting, resetForm } = onSubmitProps;

    try {
        const response = yield call(API.profile.changePassword, params);

        const { data } = response;

        yield put(profileChangePasswordSuccess());

        toastr.success(i18n.t('profile.password_change.success'));

        setSubmitting(false);
        resetForm();
    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : i18n.t('error');

        yield put(profileChangePasswordError({
            errors,
            message
        }));

        setSubmitting(false);
        setErrors(errors);
    }
}

export function* watchChangePassword() {
    yield takeEvery(PROFILE_CHANGE_PASSWORD, changePassword);
}

function* passwordSaga() {
    yield all([
        fork(watchChangePassword),
    ]);
}

export default passwordSaga;