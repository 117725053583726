import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"

import { MegaTagTitle, PageHeader, SubscriptionAccessViewer } from "components"
import { Chat as ChatComponent, PageIntro } from "./components"

export const Chat = props => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <PageIntro>
        <MegaTagTitle title={"title.chat"} />

        <div className="page-content">
          <Container fluid>
            <PageHeader title={t("title.chat")} />

            <SubscriptionAccessViewer>
              <Row>
                <Col>
                  <ChatComponent />
                </Col>
              </Row>
            </SubscriptionAccessViewer>
          </Container>
        </div>
      </PageIntro>
    </React.Fragment>
  )
}

Chat.propTypes = {}
