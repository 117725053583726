import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { FieldError } from "components/FormElements/FieldError"
import { ColorPicker } from "components"
import { Label } from "reactstrap"

export const ColorPickerField = ({
  id,
  label,
  value,
  onChange,
  wrapClass = "mb-3",
  meta,
}) => {
  const { error } = meta

  const { t } = useTranslation()

  const showError = Boolean(error)

  const renderLabel = label => {
    if (label) {
      if (typeof label === "string") {
        return label
      } else return label
    } else return <></>
  }

  return (
    <div
      className={classNames({
        [wrapClass]: wrapClass,
      })}
    >
      <div
        className={classNames(
          "d-flex align-items-center justify-content-between",
          {
            "is-invalid": showError,
          }
        )}
      >
        {label && (
          <Label className={"mb-0"} htmlFor={id}>
            {renderLabel(label)}
          </Label>
        )}
        <ColorPicker id={id} onChange={onChange} color={value} />
      </div>
      <FieldError error={error} showError={showError} />
    </div>
  )
}

ColorPickerField.propTypes = {
  id: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  wrapClass: PropTypes.string,
  translateParams: PropTypes.func,
  meta: PropTypes.object,
  defaultColor: PropTypes.string,
}
