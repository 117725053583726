import React from "react"
import PropTypes from "prop-types"
import Masonry from "react-masonry-component"
import classNames from "classnames"

export const MasonryComponent = ({
  className = "masonry-grid",
  options = {},
  masonryRef = null,
  ...props
}) => {
  const defaultOptions = {
    itemSelector: ".grid-item",
  }

  return (
    <Masonry
      ref={masonryRef}
      className={className}
      options={{ ...defaultOptions, ...options }}
      {...props}
    >
      <div className="grid-sizer" />
      <div className="gutter-sizer" />

      {props.children}
    </Masonry>
  )
}

MasonryComponent.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  options: PropTypes.object,
  masonryRef: PropTypes.any,
}
