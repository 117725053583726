import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { billingSubscriptionResume } from "./slice"

function* resume() {
  try {
    const response = yield call(API.billing.subscriptionResume)
    const { data } = response

    yield put(billingSubscriptionResume.resumeSuccess())
    toastr.success(i18n.t(`billing.subscription.resume.success`))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")

    yield put(billingSubscriptionResume.resumeError(message))

    toastr.error(message)
  }
}

export function* watchResume() {
  yield takeEvery(billingSubscriptionResume.resume, resume)
}

function* resumeSaga() {
  yield all([fork(watchResume)])
}

export default resumeSaga
