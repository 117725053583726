import React, { useState } from "react"
import { PanelSidebar } from "components"
import { AgenciesFilterContainer } from "./AgenciesFilterContainer"

export const Sidebar = () => {
  const [drawerVisible, setDrawerVisible] = useState(false)

  const toggleDrawerVisible = forceStatus => {
    if (forceStatus !== undefined) {
      setDrawerVisible(forceStatus)
    } else {
      setDrawerVisible(prevStatus => !prevStatus)
    }
  }

  return (
    <PanelSidebar
      className={"panel-filters-sidebar"}
      drawerClassName={"panel-filters-sidebar"}
      drawerVisible={drawerVisible}
      toggleDrawerVisible={toggleDrawerVisible}
    >
      <div className="panel-sidebar__sidebar-content panel-filters-content">
        <AgenciesFilterContainer />
      </div>
    </PanelSidebar>
  )
}

Sidebar.propTypes = {}
