import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { PhotosGridItem } from "./PhotosGridItem"
import { MasonryDataGrid } from "components"

export const PhotosGrid = props => {
  const { t } = useTranslation()

  const masonryConfig = {
    options: {
      gutter: 8,
    },
    enableResizableChildren: true,
  }

  return (
    <div className={"model-photos-selection-grid"}>
      <MasonryDataGrid
        masonryConfig={masonryConfig}
        loading={props.loading}
        error={props.error}
        meta={props.meta}
        onPageChange={props.onPageChange}
        items={props.items}
        renderItem={item => (
          <div className={"grid-item"}>
            <PhotosGridItem item={item} onSelect={props.onSelect} />
          </div>
        )}
        empty={t("no_photos")}
      />
    </div>
  )
}

PhotosGrid.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,

  onPageChange: PropTypes.func,
  onSelect: PropTypes.func,
}
