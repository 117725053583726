import React, { useEffect, useRef } from 'react'
import PropTypes from "prop-types";
import SimpleBar from "simplebar-react";

export const Sidebar = (props) => {
    const ref = useRef();

    useEffect(() => {
        ref.current.recalculate()
    })

    return (
        <div className="panel-sidebar__sidebar">
            <SimpleBar className="h-100" ref={ref}>
                {props.children}
            </SimpleBar>
        </div>
    );
};

Sidebar.propTypes = {
    children: PropTypes.any,
}