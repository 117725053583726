import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { Modal } from "components"
import { PaymentForm } from "./PaymentForm"
import { useFetchData } from "hooks"

export const StatementPaymentPeriod = props => {
  const { t } = useTranslation()

  const [modalVisible, setModalVisible] = useState(false)
  const [amount, setAmount] = useState(null)

  useEffect(() => {
    return () => {
      if (modalVisible) {
        setAmount(null)

        props.onClose()
      }
    }
  }, [modalVisible])

  // Fetch amount
  const fetch = useFetchData()

  const onAmountFetch = async period => {
    const [date_start, date_end] = period

    const params = {
      date_start: moment(date_start).format("YYYY-MM-DD"),
      date_end: moment(date_end).format("YYYY-MM-DD"),
    }

    const request = props.mapRequest(params)
    const data = await fetch.fetchData({
      request,
    })

    data?.meta?.total_current !== null && setAmount(data?.meta?.total_current)
  }

  const onPeriodChange = period => {
    setAmount(null)

    if (!!period.length) {
      onAmountFetch(period)
    }
  }
  // === //

  return (
    <Modal
      ref={props.modalRef}
      title={t("payment")}
      onVisibleChange={value => setModalVisible(value)}
      strapModalProp={{
        centered: true,
      }}
      size={null}
    >
      <PaymentForm
        loading={props.loading}
        error={props.error}
        initialValues={{
          period: props.initialPeriod,
        }}
        onSubmit={props.onSubmit}
        onCancel={props.onCancel}
        onPeriodChange={onPeriodChange}
        amount={amount}
        amountLoading={fetch.loading}
        amountError={fetch.error}
      />
    </Modal>
  )
}

StatementPaymentPeriod.propTypes = {
  modalRef: PropTypes.any,
  onCancel: PropTypes.func,
  initialPeriod: PropTypes.array,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.any,
  onClose: PropTypes.func,
  mapRequest: PropTypes.func,
}
