import {
    MODEL_FETCH_BOOK,
    MODEL_FETCH_BOOK_SUCCESS,
    MODEL_FETCH_BOOK_ERROR,
    MODEL_FETCH_BOOK_CLEAN_STATE,
} from './actionTypes';

export const modelFetchBook = (id) => {
    return {
        type: MODEL_FETCH_BOOK,
        payload: { id },
    }
};

export const modelFetchBookSuccess = (data) => {
    return {
        type: MODEL_FETCH_BOOK_SUCCESS,
        payload: data,
    }
};

export const modelFetchBookError = (data) => {
    return {
        type: MODEL_FETCH_BOOK_ERROR,
        payload: data,
    }
};

export const modelFetchBookCleanState = () => {
    return { type: MODEL_FETCH_BOOK_CLEAN_STATE };
};