import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "i18n"
import { modelUpdateWork } from "./slice"
import { API } from "api"
import { modelGeneralWorkList } from "../actions"

function* updateItem({ payload: { id, params, onSuccess, onError } }) {
  try {
    const response = yield call(API.model.updateModelWork, id, params)
    const { data } = response
    if (onSuccess) {
      onSuccess()
    }
    toastr.success(i18n.t("model.work-edit.success"))

    yield put(modelUpdateWork.updateCompleted())
    yield put(modelGeneralWorkList.updateListItem({ ...data.data }))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      modelUpdateWork.updateRejected({
        errors,
        message,
      })
    )
    if (onError) {
      onError(errors)
    }
  }
}

export function* watchUpdateItem() {
  yield takeEvery(modelUpdateWork.update, updateItem)
}

function* updateSaga() {
  yield all([fork(watchUpdateItem)])
}

export default updateSaga
