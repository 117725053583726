import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { ChartCard, LineChart } from "components"
import { analyticsHelper } from "helpers"
import { useDispatch } from "react-redux"
import { CLIENTS, GRAPH_PERIOD_TYPE } from "consts"
import { analyticsGraphsList } from "store"

const defaultPeriodType = GRAPH_PERIOD_TYPE.MONTH

const initialChartState = {
  baseChart: [],
  comparisonChart: [],
}
const { getComparisonPeriodData } = analyticsHelper.period
const { formatStatsData } = analyticsHelper.stats
const { generateCustomOptions } = analyticsHelper.options
const { generateEarningsComparisonPeriodNames } = analyticsHelper.names

export const EarningsComparisonContainer = ({ graphData }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [periodNames, setPeriodNames] = useState(() => {
    let { params } = getComparisonPeriodData(defaultPeriodType)
    return generateEarningsComparisonPeriodNames(params)
  })

  useEffect(() => {
    let { params } = getComparisonPeriodData(defaultPeriodType)
    fetchGraphData(params)
  }, [])

  const fetchGraphData = params => {
    dispatch(
      analyticsGraphsList.getGraphStatsData({
        alias: graphData.alias,
        tab: graphData.tab,
        params: {
          tab: graphData.tab,
          ...params,
        },
      })
    )
  }

  const [charData, setCharData] = useState(initialChartState)

  useEffect(() => {
    if (graphData.stats.data) {
      let formattedData = formatStatsData(graphData.alias, graphData.stats.data)

      setCharData({
        baseChart: formattedData.baseChart,
        comparisonChart: formattedData.comparisonChart,
      })
    } else {
      setCharData(initialChartState)
    }
  }, [graphData.stats.data])

  const handlePeriodChange = useCallback(
    params => {
      fetchGraphData(params)
      setPeriodNames(generateEarningsComparisonPeriodNames(params))
    },
    [fetchGraphData]
  )

  const customCharOptions = generateCustomOptions(graphData.alias)

  return (
    <ChartCard
      title={
        graphData.tab === CLIENTS
          ? t("comparison-chart-client")
          : t(graphData.alias)
      }
      graphData={graphData}
      onPeriodChange={handlePeriodChange}
      withComparisonPeriod
    >
      <LineChart
        tooltipLabel={`${t("period")} 1`}
        isData={!!charData.baseChart.length}
        seriesData={[{ name: periodNames.baseChart, data: charData.baseChart }]}
        optionsData={customCharOptions.baseChart}
        loading={graphData.stats.loading}
        error={graphData.stats.error}
      />
      <LineChart
        tooltipLabel={`${t("period")} 2`}
        isData={!!charData.comparisonChart.length}
        seriesData={[
          { name: periodNames.comparisonChart, data: charData.comparisonChart },
        ]}
        optionsData={customCharOptions.comparisonChart}
        loading={graphData.stats.loading}
        error={graphData.stats.error}
      />
    </ChartCard>
  )
}
EarningsComparisonContainer.propTypes = {
  graphData: PropTypes.object,
}
