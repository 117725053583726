import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "../../../i18n"

import { API } from "../../../api"
import { calendarEventRemove } from "./slice"

function* remove({ payload: { id, onSuccess } }) {
  try {
    const response = yield call(API.calendar.removeEvent, id)

    const { data } = response

    yield put(calendarEventRemove.removeSuccess())
    if (onSuccess) {
      onSuccess()
    }
    toastr.success(i18n.t("remove.success"))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      calendarEventRemove.removeError({
        errors,
        message,
      })
    )
    toastr.error(message)
  }
}

export function* watchRemove() {
  yield takeEvery(calendarEventRemove.remove, remove)
}

function* removeSaga() {
  yield all([fork(watchRemove)])
}

export default removeSaga
