import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Badge } from "reactstrap"
import classNames from "classnames"

import { billingHelper } from "helpers"

export const VatIdStatusBadge = ({ value }) => {
  const config = useMemo(() => {
    return billingHelper.info.getVatIdStatusConfig(value)
  }, [value])

  return (
    <Badge
      pill
      className={classNames("font-size-12", {
        [`badge-soft-${config?.theme}`]: config?.theme,
      })}
    >
      {config?.label}
    </Badge>
  )
}

VatIdStatusBadge.propTypes = {
  value: PropTypes.string,
}
