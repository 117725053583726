import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"

import { ContactSelect } from "../ContactSelect"
import { MembersList } from "../MembersList"

export const MembersContainer = props => {
  return (
    <div className="members-container">
      <Row>
        <Col md={12}>
          <ContactSelect
            value={props.value}
            disabled={props.disabled}
            onSelect={props.onSelect}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <MembersList
            selectedListConfig={props.selectedListConfig}
            value={props.value}
            onRemove={props.onRemove}
            renderItemContent={props.renderListItemContent}
          />
        </Col>
      </Row>
    </div>
  )
}

MembersContainer.propTypes = {
  value: PropTypes.any,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  renderListItemContent: PropTypes.func,
  selectedListConfig: PropTypes.object,
}
