import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js"

import { StripeField } from "components"

export const StripeCardGroupFields = props => {
  const { t } = useTranslation()

  const CARD_ELEMENT_OPTIONS = {
    style: {
      ...props.elementStyle,
    },
  }

  return (
    <>
      <StripeField
        label={t("card_number")}
        state={props.state?.["cardNumber"]}
        options={CARD_ELEMENT_OPTIONS}
        onChange={props.onChange}
        component={CardNumberElement}
      />

      <StripeField
        label={t("expiry_date")}
        state={props.state?.["cardExpiry"]}
        options={CARD_ELEMENT_OPTIONS}
        onChange={props.onChange}
        component={CardExpiryElement}
      />

      <StripeField
        label={t("cvc")}
        state={props.state?.["cardCvc"]}
        options={CARD_ELEMENT_OPTIONS}
        onChange={props.onChange}
        component={CardCvcElement}
      />
    </>
  )
}

StripeCardGroupFields.propTypes = {
  state: PropTypes.object,
  elementStyle: PropTypes.object,
  onChange: PropTypes.func,
}
