import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

import {
  DateRangePickerField,
  InfoCard,
  PrintButton,
  StatementPrintHeader,
  IconButton,
} from "components"
import { commonHelper } from "helpers"
import { StatementTable } from "../StatementTable"

const currencySymbol = commonHelper.currency.getGeneralCurrency()?.symbol

export const StatementCard = ({ period, onPeriodChange, ...props }) => {
  const { t } = useTranslation()

  const [date_start, date_end] = period

  const invalidPeriod = useMemo(() => {
    return !(date_start || date_end)
  }, [date_start, date_end])

  return (
    <>
      {/* Only for printing */}
      <StatementPrintHeader title={props.client?.name} period={period} />

      <InfoCard
        absolute
        editMode={props.settings}
        editBtn={
          <IconButton
            name={"settings"}
            onClick={props.onSettings}
            bg
            wrapClass={"d-print-none"}
          />
        }
      >
        <Row className={"mb-3 d-print-none"}>
          <Col md={4}>
            <DateRangePickerField
              id={"period"}
              label={t("period")}
              placeholder={t("select-period")}
              value={period}
              onChange={onPeriodChange}
              isClearable={false}
              meta={{
                error: invalidPeriod && "field.error.period.required",
              }}
            />
          </Col>
        </Row>

        <StatementTable
          list={props.list}
          loading={props.listLoading}
          error={props.listError}
          onEdit={props.onStatementEdit}
          disabled={!props.settingsData}
        />

        <Row>
          <Col>
            {props.meta && (
              <div
                className={
                  "d-flex justify-content-end align-items-end statement-table-total"
                }
              >
                <div className={"d-flex align-items-center flex-wrap text-end"}>
                  <div>
                    <span className={"fw-bold"}>
                      {t("total_selected_period_amount")}:{" "}
                    </span>
                    {props.meta?.total_current} {currencySymbol}
                    <br />
                    <span className={"fw-bold"}>{t("total_amount")}: </span>
                    {props.meta?.total} {currencySymbol}
                    <br />
                    <span className={"fw-bold"}>{t("paid_amount")}: </span>
                    {props.meta?.total_paid} {currencySymbol}
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            <PrintButton />
          </Col>
        </Row>
      </InfoCard>
    </>
  )
}

StatementCard.propTypes = {
  period: PropTypes.array,
  onPeriodChange: PropTypes.func,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  client: PropTypes.object,
  onStatementEdit: PropTypes.func,
  settings: PropTypes.bool,
  onSettings: PropTypes.func,
  settingsData: PropTypes.object,
}
