import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { FilesUploaderHF } from "components"
import { FileUtils } from "utils"
import {
  chatConversationAttachmentUpload,
  chatConversationAttachmentRemove,
} from "store/actions"

export const FilesUploader = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onUploadError = (field, fieldArrayProps) => errors => {
    props.removeFileFromProcessing(field?.fieldId)

    if (errors["file"]) {
      props.setError(fieldArrayProps.fieldName, {
        type: "server",
        message: errors["file"].join(" "),
      })
    }
  }

  const onUploadSuccess = (field, fieldArrayProps) => data => {
    props.removeFileFromProcessing(field?.fieldId)
    const { fieldIndex, update } = fieldArrayProps
    update(fieldIndex, {
      ...field,
      ...data,
    })
  }

  const handleUpload = (field, fieldArrayProps) => {
    props.addFileToProcessing(field?.fieldId)
    let params = {
      file: field?.file,
    }
    dispatch(
      chatConversationAttachmentUpload.upload({
        params,
        onSuccess: onUploadSuccess(field, fieldArrayProps),
        onError: onUploadError(field, fieldArrayProps),
      })
    )
  }

  const onRemoveError = (field, fieldArrayProps) => () => {
    props.removeFileFromProcessing(field?.fieldId)
  }

  const onRemoveSuccess = (field, fieldArrayProps) => data => {
    props.removeFileFromProcessing(field?.fieldId)
    const { fieldIndex } = fieldArrayProps
    fieldArrayProps.remove(fieldIndex)
  }

  const handleRemove = (field, fieldArrayProps) => {
    // props.addFileToProcessing(field?.fieldId)
    // dispatch(
    //   chatConversationAttachmentRemove.remove({
    //     id: field?.id,
    //     onSuccess: onRemoveSuccess(field, fieldArrayProps),
    //     onError: onRemoveError(field, fieldArrayProps),
    //   })
    // )

    // Remove file local, api hasn't method and will not
    const { fieldIndex } = fieldArrayProps
    fieldArrayProps.remove(fieldIndex)
  }

  const onValidate = async (name, onValid) => {
    const result = await props.trigger(name)
    if (result && onValid) {
      onValid()
    }
  }

  const onDownload = field => {
    FileUtils.downloadFileByLink(field?.attachment?.src)
  }

  return (
    <FilesUploaderHF
      id={"files"}
      name={"files"}
      control={props.control}
      onUpload={handleUpload}
      onRemove={handleRemove}
      onDownload={onDownload}
      processing={props.processing}
      onValidate={onValidate}
      translateParams={{ text: `${FileUtils.formatBytes(props.fileMaxSize)}` }}
      picker={false}
      ref={props.filesUploaderRef}
    />
  )
}

FilesUploader.propTypes = {
  control: PropTypes.object,
  processing: PropTypes.array,
  addFileToProcessing: PropTypes.func,
  removeFileFromProcessing: PropTypes.func,
  trigger: PropTypes.func,
  setError: PropTypes.func,
  fileMaxSize: PropTypes.number,
  filesUploaderRef: PropTypes.any,
}
