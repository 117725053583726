import React from "react"
import PropTypes from "prop-types"
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from "react-share"

import { FACEBOOK, TELEGRAM, TWITTER, VIBER, WHATSAPP } from "consts"
import { ShareIcon } from "./ShareIcon"

export const ShareButton = ({ url, name }) => {
  let icon = <ShareIcon name={name} />

  const renderSocialShareButton = name => {
    switch (name) {
      case TWITTER:
        return <TwitterShareButton url={url}>{icon}</TwitterShareButton>

      case FACEBOOK:
        return <FacebookShareButton url={url}>{icon}</FacebookShareButton>

      case TELEGRAM:
        return <TelegramShareButton url={url}>{icon}</TelegramShareButton>

      case WHATSAPP:
        return <WhatsappShareButton url={url}>{icon}</WhatsappShareButton>
      case VIBER:
        return <ViberShareButton url={url}>{icon}</ViberShareButton>
      default:
        return null
    }
  }
  return renderSocialShareButton(name)
}

ShareButton.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
}
