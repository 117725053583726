import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { SharedEntity } from "../../../../components"
import { AddPackage } from "../AddPackage"
import { EditPackage } from "../EditPackage"

const MODE_ADD = "add"
const MODE_EDIT = "edit"

export const PackageFormContainer = props => {
  const { t } = useTranslation()
  const history = useHistory()

  const [packageData, setPackageData] = useState(null)
  const [sendDrawerVisible, setSendDrawerVisible] = useState(false)

  const onSaveSuccess = () => {
    history.push(`/packages`)
  }

  const onSend = packageData => {
    setSendDrawerVisible(true)
    setPackageData(packageData)
  }

  const closeSendDrawer = () => {
    setSendDrawerVisible(false)
    setPackageData(null)
    history.push(`/packages`)
  }

  const onSendSuccess = () => {
    closeSendDrawer()
  }

  return (
    <>
      {props.mode === "add" ? (
        <AddPackage onSaveSuccess={onSaveSuccess} onSend={onSend} />
      ) : props.mode === "edit" ? (
        <EditPackage onSaveSuccess={onSaveSuccess} onSend={onSend} />
      ) : null}

      <SharedEntity
        entity={"package"}
        entityId={packageData?.id}
        visible={sendDrawerVisible}
        onCancel={closeSendDrawer}
        onSuccess={onSendSuccess}
        books={packageData?.books}
      />
    </>
  )
}

PackageFormContainer.propTypes = {
  mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
}
