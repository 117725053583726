import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

import { InfoCard, PrintButton, Button, StatementPrintHeader } from "components"
import { commonHelper } from "helpers"
import { StatementTable } from "../StatementTable"

const currencySymbol = commonHelper.currency.getGeneralCurrency()?.symbol

export const StatementCard = ({
  toolbar,
  period,
  statementsPayment,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <>
      {/* Only for printing */}
      <StatementPrintHeader
        title={`${props.model.name} ${props.model.surname || ""}`}
        period={period}
      />

      <InfoCard>
        <div className={"d-print-none"}>{toolbar}</div>

        <StatementTable
          list={props.list}
          loading={props.listLoading}
          error={props.listError}
          onPayment={props.onStatementPayment}
          onPaymentDetails={props.onPaymentDetails}
          statementsPayment={statementsPayment}
        />

        <Row>
          <Col>
            {props.meta && (
              <div
                className={
                  "d-flex justify-content-end align-items-end statement-table-total"
                }
              >
                <div className={"d-flex align-items-center flex-wrap"}>
                  <Button
                    title={t("select_period_to_pay")}
                    onClick={props.onPeriodPay}
                    btnClass={"me-4 d-print-none"}
                  />

                  <div>
                    <span className={"fw-bold"}>{t("total")}: </span>
                    <span>
                      {props.meta?.total} {currencySymbol}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            <PrintButton />
          </Col>
        </Row>
      </InfoCard>
    </>
  )
}

StatementCard.propTypes = {
  toolbar: PropTypes.element,
  period: PropTypes.array,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  model: PropTypes.object,
  onPeriodPay: PropTypes.func,
  onStatementPayment: PropTypes.func,
  onPaymentDetails: PropTypes.func,
  statementsPayment: PropTypes.shape({
    btnProps: PropTypes.shape({
      loading: PropTypes.bool,
      disabled: PropTypes.bool,
    }),
    onStatementsPayment: PropTypes.func,
  }),
}
