import { put, call, select } from "redux-saga/effects"

import { chatConversationData } from "./slice"
import { selectConversationGroupChatId } from "./selectors"

const groupChatIdState = state => selectConversationGroupChatId(state)

export function* onConversationUpdate({ payload: { chat } }) {
  const groupChatId = yield select(groupChatIdState)

  const isGroup = chat.is_group

  const isCurrentChat = isGroup && chat.id === groupChatId

  if (isCurrentChat) {
    yield put(chatConversationData.updateData(chat))
  }
}

export function* onConversationClose({ payload: { id } }) {
  const groupChatId = yield select(groupChatIdState)

  const isCurrentChat = id === groupChatId

  if (isCurrentChat) {
    yield put(chatConversationData.cleanState())
  }
}
