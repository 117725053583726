import {
    EMPLOYEE_DETAILS_REMOVE_USER,
    EMPLOYEE_DETAILS_REMOVE_USER_SUCCESS,
    EMPLOYEE_DETAILS_REMOVE_USER_ERROR,
    EMPLOYEE_DETAILS_REMOVE_USER_CLEAN_STATE,
} from './actionTypes';

export const employeeDetailsRemoveUser = (id, history) => {
    return {
        type: EMPLOYEE_DETAILS_REMOVE_USER,
        payload: { id, history },
    }
};

export const employeeDetailsRemoveUserSuccess = () => {
    return {
        type: EMPLOYEE_DETAILS_REMOVE_USER_SUCCESS,
    }
};

export const employeeDetailsRemoveUserError = () => {
    return {
        type: EMPLOYEE_DETAILS_REMOVE_USER_ERROR,
    }
};

export const employeeDetailsRemoveUserCleanState = () => {
    return { type: EMPLOYEE_DETAILS_REMOVE_USER_CLEAN_STATE };
};