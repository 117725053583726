import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import i18n from "i18n"
import { clientStatementSettingsData } from "./slice"
import { API } from "api"

export function* getData({ payload: { id } }) {
  try {
    const response = yield call(API.client.getClientAlertSettings, id)

    const { data } = response

    yield put(clientStatementSettingsData.getDataSuccess(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(clientStatementSettingsData.getDataError(message))
  }
}

export function* watchGetData() {
  yield takeEvery(clientStatementSettingsData.getData, getData)
}

function* dataSaga() {
  yield all([fork(watchGetData)])
}

export default dataSaga
