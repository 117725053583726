import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import { UploadedPhotos } from "../UploadedPhotos"
import { Drawer } from "components"
import { AvatarManager } from "./AvatartManager"

const EditAvatarComponent = props => {
  const { t } = useTranslation()
  const handleCloseDrawer = () => {
    props.onClose()
    removeNotCroppedImage()
  }
  const [notCroppedImage, setNotCroppedImage] = useState(null)

  const removeNotCroppedImage = useCallback(() => {
    setNotCroppedImage(null)
  }, [])

  return (
    <React.Fragment>
      <Drawer
        title={t("edit-model-avatar-title")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container model-edit-avatar-drawer"}
        destroyOnClose
      >
        <div className="mb-4">
          <AvatarManager
            notCroppedImage={notCroppedImage}
            removeNotCroppedImage={removeNotCroppedImage}
          />
        </div>
        <Row>
          <Col>
            <UploadedPhotos setNotCroppedImage={setNotCroppedImage} />
          </Col>
        </Row>
      </Drawer>
    </React.Fragment>
  )
}

EditAvatarComponent.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,

  model: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,

  modelPanelUploadModelAvatar: PropTypes.func,
  modelPanelRemoveModelAvatar: PropTypes.func,
  modelPanelUpdateModelAvatarCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.model.profile
  const { loading, error } = state.modelPanel.general.avatar.update
  return {
    model: data,
    loading,
    error,
  }
}

export const EditAvatar = connect(mapStateToProps)(EditAvatarComponent)
