import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import moment from "moment"

import {
  agencyStatementList,
  agencyStatementDataPaymentSubmit,
} from "store/actions"
import { StatementCard } from "./StatementCard"
import { PaymentPeriod } from "./PaymentPeriod"
import { useConfirmModal, useDrawer, useModal } from "hooks"
import { ConfirmPopup, StatementPaymentDetails } from "components"
import { APP_ENTITY } from "consts"

const defaultPeriod = [
  moment().startOf("month").toDate(),
  moment().endOf("month").toDate(),
]

const StatementContainerComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [period, setPeriod] = useState(defaultPeriod)
  const [date_start, date_end] = period

  useEffect(() => {
    return () => {
      dispatch(agencyStatementList.cleanState())
      dispatch(agencyStatementDataPaymentSubmit.cleanState())
    }
  }, [])

  // Data
  useEffect(() => {
    if (date_start && date_end) {
      fetchData()
    }
  }, [period])

  const fetchData = (params = {}) => {
    params = {
      ...params,
      date_start: moment(date_start).format("YYYY-MM-DD"),
      date_end: moment(date_end).format("YYYY-MM-DD"),
    }

    dispatch(agencyStatementList.getList({ id: props.agency.id, params }))
  }

  const onPeriodChange = value => {
    setPeriod(value)
  }
  // === //

  // Payment period
  const paymentPeriodModal = useModal()
  // === //

  //======================= Statement (id) payment submit  ==============================//
  const statementPaymentModal = useConfirmModal(
    ({ id }) => {
      dispatch(
        agencyStatementDataPaymentSubmit.submit({
          id: props.agency.id,
          statement: id,
          onSuccess: fetchData,
        })
      )
    },
    () => {}
  )
  // === //

  // Payment details
  const paymentDetailsDrawer = useDrawer()
  // === //

  const isLoading = useMemo(() => {
    return props.listLoading || props.loading
  }, [props.listLoading, props.loading])

  return (
    <>
      <StatementCard
        period={period}
        onPeriodChange={onPeriodChange}
        list={props.list}
        listLoading={isLoading}
        listError={props.listError}
        meta={props.meta}
        agency={props.agency}
        onPeriodPay={paymentPeriodModal.showModal}
        onStatementPayment={statementPaymentModal.showModal}
        onPaymentDetails={paymentDetailsDrawer.show}
      />

      <PaymentPeriod
        modalRef={paymentPeriodModal.modalRef}
        onCancel={paymentPeriodModal.closeModal}
        agencyId={props.agency.id}
        initialPeriod={period}
        onSuccess={fetchData}
      />

      <ConfirmPopup
        ref={statementPaymentModal.modalRef}
        onConfirm={statementPaymentModal.handleConfirm}
        onClosed={statementPaymentModal.closeModal}
        questionText={"sure_make_payment"}
      />

      <StatementPaymentDetails
        data={paymentDetailsDrawer.data}
        visible={paymentDetailsDrawer.visible}
        onClose={paymentDetailsDrawer.hide}
        onDestroy={paymentDetailsDrawer.resetData}
        entity={APP_ENTITY.AGENCY}
      />
    </>
  )
}

StatementContainerComponent.propTypes = {
  agency: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  loading: PropTypes.bool,
}

const mapStateToProps = state => {
  const { data: agency } = state.agency.profile.data
  const { list, listLoading, listError, meta } = state.agency.statement.list

  const { loading } = state.agency.statement.data.payment.submit
  return {
    agency,
    list,
    listLoading,
    listError,
    meta,
    loading,
  }
}

export const StatementContainer = connect(mapStateToProps)(
  StatementContainerComponent
)
