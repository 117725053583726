import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Nav } from "../Nav"
import { PageHeader, RatingBadge } from "../../../../../../../components"
import { usePanelTopBarContext } from "../../../../../../../contexts"

export const HeaderComponent = props => {
    const { t } = useTranslation()
    const { options } = usePanelTopBarContext()

    return (
        <div className={"panel-topbar"}>
            <PageHeader
                title={t("statistics")}
                headerLeft={
                    props?.employee?.avg_rating && (
                        <RatingBadge
                            value={props.employee.avg_rating}
                            size={"md"}
                            wrapClassName={"ms-3"}
                        />
                    )
                }
            />

            <div className={"panel-topbar__nav mb-4"}>
                <div className={"panel-topbar__menu"}>
                    <Nav />
                </div>

                {options.actions && (
                    <div className="panel-topbar__actions">
                        {options.actions}
                    </div>
                )}
            </div>
        </div>
    )
}

HeaderComponent.propTypes = {
    employee: PropTypes.object,
    topBarOptions: PropTypes.object,
}

const mapStateToProps = state => {
    const { data: employee } = state.employee.details.data
    return {
        employee,
    }
}

const mapDispatchToProps = {}

export const Header = connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderComponent)