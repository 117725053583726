import React from "react"
import PropTypes from "prop-types"

export const PrintPanelHeader = ({ children, header, title, subtitle }) => {
  return (
    <div className={"d-none d-print-block print-panel-header"}>
      {header ? (
        header
      ) : (
        <>
          {title && <h4>{title}</h4>}

          {subtitle && <h5>{subtitle}</h5>}
        </>
      )}

      {children}
    </div>
  )
}

PrintPanelHeader.propTypes = {
  children: PropTypes.any,
  header: PropTypes.any,
  title: PropTypes.any,
  subtitle: PropTypes.any,
}
