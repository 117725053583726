import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import { PackageViewDrawer, PackageContainer } from "./components"
import { packageDraftResetData } from "store/actions"
import { selectPackageDataCount } from "../../../store/package/draft/data/dataPicker/selectors"
import { SharedEntity } from "../../SharedEntity"

const DraftPackageContainerComponent = props => {
  const { t } = useTranslation()

  const [packageData, setPackageData] = useState(null)
  const [sendDrawerVisible, setSendDrawerVisible] = useState(false)

  const resetDraftData = () => {
    props.packageDraftResetData()
  }

  const onSaveSuccess = () => {
    props.draftPackageClose()

    resetDraftData()
  }

  const onSend = packageData => {
    setSendDrawerVisible(true)
    setPackageData(packageData)
  }

  const closeSendDrawer = () => {
    setSendDrawerVisible(false)
    setPackageData(null)
  }

  const onSendSuccess = () => {
    closeSendDrawer()
  }

  return (
    <div className={"draft-package"}>
      <PackageViewDrawer
        packageDataCount={props.packageDataCount}
        visible={props.draftPackageVisible}
        onClose={props.draftPackageClose}
        onDestroy={() => {}}
      >
        <PackageContainer
          initialTab={props.initialTab}
          onSaveSuccess={onSaveSuccess}
          onSend={onSend}
        />
      </PackageViewDrawer>

      <SharedEntity
        entity={"package"}
        entityId={packageData?.id}
        visible={sendDrawerVisible}
        onCancel={closeSendDrawer}
        onSuccess={onSendSuccess}
        books={packageData?.books}
      />
    </div>
  )
}

DraftPackageContainerComponent.propTypes = {
  draftPackageVisible: PropTypes.bool,
  draftPackageClose: PropTypes.func,
  initialTab: PropTypes.oneOf(["books", "videos"]),

  packageDataCount: PropTypes.number,

  packageDraftResetData: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    packageDataCount: selectPackageDataCount(state),
  }
}

const mapDispatchToProps = {
  packageDraftResetData,
}

export const DraftPackageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DraftPackageContainerComponent)
