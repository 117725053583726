import React from "react"
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  generatePath,
} from "react-router-dom"
import PropTypes from "prop-types"

import { IntroLayout } from "./components"
import { CreateInfoPage, CreateWebsitePage } from "./pages"

export const IntroIndex = props => {
  let { path } = useRouteMatch()
  if (props.website?.created) {
    return <Redirect to={"/website/panel"} />
  }

  return (
    <IntroLayout>
      <Switch>
        <Route exact path={`${path}`}>
          <Redirect to={generatePath(`${path}/create-info`)} />
        </Route>
        <Route path={`${path}/create-info`}>
          <CreateInfoPage />
        </Route>
        <Route path={`${path}/create-website`}>
          {props.website ? (
            <Redirect to={`${path}/create-info`} />
          ) : (
            <CreateWebsitePage />
          )}
        </Route>
        <Route>
          <Redirect to={generatePath(`${path}/create-info`)} />
        </Route>
      </Switch>
    </IntroLayout>
  )
}
IntroIndex.propTypes = {
  website: PropTypes.any,
}
