import { clientHelper } from "helpers"

export const mapClientData = client => {
  let result = {
    client_information: "",
    client_name: client?.name || "",
    client_address_line: "",
    client_address_city: "",
    client_address_zip: "",
    client_country_id: client?.country || null,
    client_vat: client?.vat || "",
    bank_name: client?.bank_name || "",
    iban: client?.bank_account_number || "",
    swift: client?.swift_code || "",
  }

  const billingAddress = clientHelper.common.getBillingAddress(client)

  if (billingAddress) {
    const { street, town, zip } = billingAddress

    result = {
      ...result,
      client_address_line: street || "",
      client_address_city: town || "",
      client_address_zip: zip || "",
    }
  }

  return result
}
