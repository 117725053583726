import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import i18n from '../../../i18n';

import { PASSWORD_RESET } from './actionTypes';
import {
    passwordResetSuccess,
    passwordResetError,
} from './actions';

import { API } from '../../../api';

function* passwordReset({ payload: { params, onSubmitProps } }) {
    const { setErrors, setSubmitting } = onSubmitProps;

    try {
        const response = yield call(API.auth.resetPassword, params);

        const { data } = response;
        yield put(passwordResetSuccess(
            data?.message ? data?.message : "Success!"
        ));

        setSubmitting(false);
    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message ? error.response.data.message : i18n.t('error');

        yield put(passwordResetError({
            errors,
            message,
        }));

        setSubmitting(false);
        setErrors(errors);
    }
}

export function* watchPasswordReset() {
    yield takeEvery(PASSWORD_RESET, passwordReset)
}

function* passwordResetSaga() {
    yield all([
        fork(watchPasswordReset),
    ]);
}

export default passwordResetSaga;