import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"

const CompareEventsCalendarContext = createContext(null)

const CompareEventsCalendarProvider = ({ children, ...props }) => {
  return (
    <CompareEventsCalendarContext.Provider value={{ ...props }}>
      {children}
    </CompareEventsCalendarContext.Provider>
  )
}

CompareEventsCalendarProvider.propTypes = {
  children: PropTypes.any,
}

const useCompareEventsCalendarContext = () =>
  useContext(CompareEventsCalendarContext)

export { CompareEventsCalendarProvider, useCompareEventsCalendarContext }
