import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"

import { Agencies } from "./Agencies"
import { AgencyPanel } from "../AgencyPanel"

export const AgenciesIndex = () => {
    let { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path}>
                <Agencies />
            </Route>
            <Route path={`${path}/:id`}>
                <AgencyPanel />
            </Route>
            <Route>
                <Redirect to={path} />
            </Route>
        </Switch>
    )
}