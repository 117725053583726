import React from "react"
import PropTypes from "prop-types"
import { IconButton } from "components/Buttons"
import { MediaOverviewCard } from "components/MediaOverviewCard"
import { Image } from "components"

export const PhotoCard = ({ editCrop, src }) => {
  const renderActions = () => {
    return (
      <>
        <IconButton name={"crop"} onClick={editCrop} bg />
      </>
    )
  }
  const renderContent = () => {
    return <Image src={src} />
  }
  return (
    <MediaOverviewCard
      actions={renderActions()}
      content={renderContent()}
      size={"sm"}
      className={"d-inline-flex justify-content-start"}
    />
  )
}

PhotoCard.propTypes = {
  src: PropTypes.string,
  editCrop: PropTypes.func,
}
