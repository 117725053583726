export const getModelAvatarRequestBody = async ({
  avatar_rectangle,
  avatar_square,
  photo,
  is_avatar,
}) => {
  const { scale: scaleRectangle } = avatar_rectangle
  const { scale: scaleSquare } = avatar_square

  //Aspect ratio 1 / 1.6
  const rectangleHeight = Math.round(
    avatar_rectangle.crop.height * scaleRectangle
  )
  const rectangleWidth = Math.round(rectangleHeight * 0.625)

  //Aspect ratio 1 / 1
  const squareHeight = Math.round(avatar_square.crop.height * scaleSquare)
  const squareWidth = squareHeight

  let crop_data = {
    is_avatar,
    "param_rectangle[x]": Math.round(avatar_rectangle.crop.x * scaleRectangle),
    "param_rectangle[y]": Math.round(avatar_rectangle.crop.y * scaleRectangle),
    "param_rectangle[width]": rectangleWidth,
    "param_rectangle[height]": rectangleHeight,
    "param_square[x]": Math.round(avatar_square.crop.x * scaleSquare),
    "param_square[y]": Math.round(avatar_square.crop.y * scaleSquare),
    "param_square[width]": squareWidth,
    "param_square[height]": squareHeight,
  }
  if (photo) {
    return {
      photo,
      ...crop_data,
    }
  } else {
    return {
      ...crop_data,
    }
  }
}

export const getInstagramPhotoRequestBody = async ({ instagram_photo }) => {
  const { scale } = instagram_photo

  let crop_data = {
    "param_instagram[x]": Math.round(instagram_photo.crop.x * scale),
    "param_instagram[y]": Math.round(instagram_photo.crop.y * scale),
    "param_instagram[width]": Math.round(instagram_photo.crop.height * scale),
    "param_instagram[height]": Math.round(instagram_photo.crop.height * scale),
  }
  return {
    ...crop_data,
  }
}

export const getCropFormInitialValues = crops => {
  const avatar_square = crops ? crops.find(crop => crop.type === "square") : {}
  const avatar_rectangle = crops
    ? crops.find(crop => crop.type === "rectangle")
    : {}

  return {
    avatar_square: {
      crop: {
        unit: "px",
        ...avatar_square?.crop_params,
      },
    },
    avatar_rectangle: {
      crop: {
        unit: "px",
        ...avatar_rectangle?.crop_params,
      },
    },
  }
}

export const getInstagramCropFormInitialValues = crop => {
  return {
    instagram_photo: {
      crop: {
        unit: "px",
        ...crop?.crop_params,
      },
    },
  }
}
