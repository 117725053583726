import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import { websiteGeneralCreate } from "./slice"
import i18n from "../../../../i18n"
import { API } from "../../../../api"
import { websiteGeneralData } from "../actions"

function* create({ payload: { params, onSuccess, onError } }) {
  try {
    const response = yield call(API.website.createWebsite, params)

    const { data } = response

    toastr.success(i18n.t("website.create.started"))
    yield put(websiteGeneralCreate.createCompleted())

    yield put(websiteGeneralData.updateData({ ...data.data }))
    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      websiteGeneralCreate.createRejected({
        errors,
        message,
      })
    )

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchCreate() {
  yield takeEvery(websiteGeneralCreate.create, create)
}

function* createSaga() {
  yield all([fork(watchCreate)])
}

export default createSaga
