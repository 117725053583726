import { takeEvery, fork, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { socketOnChatGroupUserAdded } from "./actions"
import { onChatAdd } from "../../../../../chat/chats/list/saga"

function* onAdd({ payload: { data, history } }) {
  const { chat } = data

  yield call(onChatAdd, {
    payload: {
      chat,
    },
  })

  toastr.success(i18n.t("chat.group.user_added"))
}

export function* watchOnAdd() {
  yield takeEvery(socketOnChatGroupUserAdded, onAdd)
}

function* addSaga() {
  yield all([fork(watchOnAdd)])
}

export default addSaga
