import React, { useState } from "react"

export const useStripeForm = (props = { initialState: {} }) => {
  const [state, setState] = useState(props.initialState)

  const isValid = Object.keys(state).every(key => state[key]?.complete === true)

  const onChange = data => {
    setState(prevState => ({
      ...prevState,
      [data.elementType]: data,
    }))
  }

  const onStateSet = state => {
    setState(state)
  }

  const reset = () => {
    setState(props.initialState)
  }

  return {
    state,
    isValid,
    onChange,
    onStateSet,
    reset,
  }
}
