import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap"
import { useTranslation } from "react-i18next"
import { FacebookLoginButton, FacebookPermissionsButton } from "components"

export const SocialAccounts = props => {
  const { t } = useTranslation()
  return (
    <Card className={"h-100"}>
      <CardHeader className={"bg-dark"}>
        <CardTitle className={"text-white mb-0"}>
          {t("social-accounts")}
        </CardTitle>
      </CardHeader>
      <CardBody
        className={"bg-neutral d-flex flex-wrap gap-2 align-items-center"}
      >
        <FacebookPermissionsButton size={"md"} />
        <FacebookLoginButton />
      </CardBody>
    </Card>
  )
}

SocialAccounts.propTypes = {}
