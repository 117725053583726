import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { calendarEventEditDone } from "./slice"

function* submit({ payload: { id, params, onSuccess, onError } }) {
  try {
    const response = yield call(API.calendar.updateEvent, id, params)

    const { data } = response

    yield put(calendarEventEditDone.submitSuccess())

    toastr.success(i18n.t("save.success"))

    if (onSuccess) {
      onSuccess(data?.data)
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      calendarEventEditDone.submitError({
        errors,
        message,
      })
    )

    toastr.error(message)

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchSubmit() {
  yield takeEvery(calendarEventEditDone.submit, submit)
}

function* doneSaga() {
  yield all([fork(watchSubmit)])
}

export default doneSaga
