import moment from "moment"
import PropTypes from "prop-types"
import React from "react"

export const DateBlock = ({ date_start, date_end, bgColor }) => {
  const renderDate = date => {
    return (
      <div className={"d-flex gap-1"}>
        <div className={"date-number"}>{moment(date).format("DD")}</div>
        <div className={"d-flex flex-column"}>
          <div className={"date-text"}>{moment(date).format("dddd")}</div>
          <div className={"date-text"}>
            {moment(date).format("MMMM").toUpperCase()}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div
      className={"calendar-event-info__date-block"}
      style={{ backgroundColor: bgColor }}
    >
      <div className={"d-flex gap-1 flex-wrap"}>
        {date_start === date_end ? (
          renderDate(date_start)
        ) : (
          <>
            {renderDate(date_start)}

            <div className={"separator"}>-</div>

            {renderDate(date_end)}
          </>
        )}
      </div>
    </div>
  )
}

DateBlock.propTypes = {
  date_start: PropTypes.string,
  date_end: PropTypes.string,
  bgColor: PropTypes.string,
}
