import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import { calendarEventModelContractEditData } from "store/actions"
import * as HOC from "HOC"

const DataShower = HOC.withDataShower()

export const EventModelContractDataLoader = props => {
  const dispatch = useDispatch()

  const { data, loading, error } = useSelector(
    state => state.calendarEvent.model.contract.edit.data
  )

  useEffect(() => {
    fetchData()

    return () => {
      dispatch(calendarEventModelContractEditData.cleanState())
    }
  }, [])

  const fetchData = () => {
    dispatch(
      calendarEventModelContractEditData.getData({
        event: props.event,
        model: props.model,
      })
    )
  }

  return (
    <DataShower isLoading={loading} isFailed={error} error={error}>
      {data && props.children}
    </DataShower>
  )
}

EventModelContractDataLoader.propTypes = {
  event: PropTypes.number,
  model: PropTypes.number,
  children: PropTypes.any,
}
