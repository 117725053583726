import {
    PROFILE_UPLOAD_AVATAR,
    PROFILE_UPDATE_AVATAR_SUCCESS,
    PROFILE_UPDATE_AVATAR_ERROR,
    PROFILE_REMOVE_AVATAR,
    PROFILE_AVATAR_CLEAN_STATE,
} from './actionTypes';

export const profileUploadAvatar = (params) => {
    return {
        type: PROFILE_UPLOAD_AVATAR,
        payload: { params },
    }
};

export const profileUpdateAvatarSuccess = () => {
    return {
        type: PROFILE_UPDATE_AVATAR_SUCCESS,
    }
};

export const profileUpdateAvatarError = (data) => {
    return {
        type: PROFILE_UPDATE_AVATAR_ERROR,
        payload: data,
    }
};

export const profileAvatarCleanState = () => {
    return { type: PROFILE_AVATAR_CLEAN_STATE };
};

export const profileRemoveAvatar = () => {
    return {
        type: PROFILE_REMOVE_AVATAR,
    }
};