import _ from "lodash"

import i18n from "../../i18n"
import {
  EXPENSE_PAID_AGENCY,
  EXPENSE_PAID_SCOUTER,
  MODEL_EXPENSE_AGENCY_CLIENT,
  MODEL_EXPENSE_AGENCY_MODEL,
  MODEL_EXPENSE_MODEL_CLIENT,
} from "../../consts"

export const getEventExpenseTypeOptions = () => {
  return [
    {
      label: i18n.t("expense.paid_agency"),
      value: EXPENSE_PAID_AGENCY,
      abbreviation: "[A]",
    },
    {
      label: i18n.t("expense.paid_scouter"),
      value: EXPENSE_PAID_SCOUTER,
      abbreviation: "[S]",
    },
  ]
}

export const getModelExpenseTypeOptions = () => {
  return [
    {
      label: i18n.t("paid_agency_client"),
      value: MODEL_EXPENSE_AGENCY_CLIENT,
      abbreviation: "[A > C]",
    },
    {
      label: i18n.t("paid_agency_model"),
      value: MODEL_EXPENSE_AGENCY_MODEL,
      abbreviation: "[A > M]",
    },
    {
      label: i18n.t("paid_model_client"),
      value: MODEL_EXPENSE_MODEL_CLIENT,
      abbreviation: "[M > C]",
    },
  ]
}

export const getExpenseTypeOptions = () => [
  ...getModelExpenseTypeOptions(),
  ...getEventExpenseTypeOptions(),
]

export const getModelExpenseTypeAsOption = value => {
  const options = getModelExpenseTypeOptions()
  return _.find(options, { value: value })
}

export const getExpenseTypeAsOption = value => {
  const options = getExpenseTypeOptions()
  return _.find(options, { value: value })
}

export const getExpensesTotalData = data => {
  let total = {}

  data.forEach(item => {
    total = {
      ...total,
      [item.currency]: (total[item.currency] || 0) + Number(item.value),
    }
  })

  return total
}
