import React from 'react';
import { Alert } from "reactstrap";
import PropTypes from "prop-types";
import classNames from "classnames";

export const ValidationErrors = ({ data, className }) => {
    const renderErrors = () => {
        if (data.errors) {
            let errors = Object.values(data.errors);
            errors = errors.flat();

            return errors.map((item, index) => (
                <div key={'validation-' + index}>
                    <span>{`· ${item}`}</span>
                </div>
            ));
        }

        return null;
    };

    return (
        <Alert color="danger" className={classNames('mt-2', {
            [className]: className
        })}>
            {data.message}

            {renderErrors()}
        </Alert>
    );
};

ValidationErrors.propTypes = {
    data: PropTypes.object,
    className: PropTypes.string,
}