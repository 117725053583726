import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

const defaultConfig = {
  doc: true,
  pdf: true,
}

export const ContractDownloadMenuControl = ({
  onDownload,
  children,
  config,
  menuProps,
  className,
}) => {
  const { t } = useTranslation()

  config = {
    ...defaultConfig,
    ...config,
  }

  const [open, setOpen] = useState(false)

  const toggleMenuOpen = () => {
    setOpen(prevState => !prevState)
  }

  const onSelect = value => {
    onDownload(value)

    toggleMenuOpen()
  }

  return (
    <Dropdown
      isOpen={open}
      toggle={toggleMenuOpen}
      className={classNames("d-inline-block", {
        [className]: className,
      })}
    >
      <DropdownToggle tag="div">{children}</DropdownToggle>

      <DropdownMenu {...menuProps}>
        {config.doc && (
          <DropdownItem onClick={() => onSelect("doc")}>
            {t("doc")}
          </DropdownItem>
        )}
        {config.pdf && (
          <DropdownItem onClick={() => onSelect("pdf")}>
            {t("pdf")}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}

ContractDownloadMenuControl.propTypes = {
  children: PropTypes.node,
  onDownload: PropTypes.func,
  config: PropTypes.object,
  menuProps: PropTypes.object,
  className: PropTypes.string,
}
