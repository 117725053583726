import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFirstMountState } from "react-use"
import toastr from "toastr"
import _ from "lodash"

import {
  VideoOverviewCard,
  PackageDataAddButton,
} from "../../../../../../components"
import { PackageDataSlider } from "../../PackageDataSlider"
import { useFetchData } from "../../../../../../hooks"
import { API } from "../../../../../../api"

import * as HOC from "../../../../../../HOC"

const DataShower = HOC.withDataShowerOverlay()

export const VideosSlider = props => {
  const { t } = useTranslation()

  const isFirstMount = useFirstMountState()
  const { loading, data, fetchData } = useFetchData()

  const [page, setPage] = useState(1)
  const [video, setVideo] = useState(props.data?.first_video || {})
  const playersRef = useRef([])

  const onPageChange = value => {
    setPage(value)
  }

  useEffect(() => {
    if (!isFirstMount) {
      onFetchData()
    }
  }, [page])

  const onFetchData = async () => {
    const params = {
      per_page: 1,
      page,
    }
    const request = API.model.getModelVideos(props.data.id, params)
    await fetchData({
      request,
      onError: onFetchError,
    })
  }

  const onFetchError = () => {
    toastr.error(t("error"))
  }

  useEffect(() => {
    if (!isFirstMount) {
      const video = data?.data[0]
      setVideo(video)
    }
  }, [data?.data])

  return (
    <div className={"w-100"}>
      <DataShower isLoading={loading} isFailed={null} error={null}>
        <PackageDataSlider
          title={props.data?.name}
          onPageChange={onPageChange}
          currentPage={page}
          count={props.data?.videos_count}
          countPrefix={t("video")}
          renderSlide={() => (
            <VideoOverviewCard
              iframeUrl={video.iframe_url}
              playerRef={el => (playersRef.current[video.id] = el)}
              actions={
                <PackageDataAddButton
                  status={!!_.find(props.pickedVideos, { id: video.id })}
                  onClick={() => props.onAddVideo(video)}
                  disabled={false}
                />
              }
            />
          )}
        />
      </DataShower>
    </div>
  )
}

VideosSlider.propTypes = {
  data: PropTypes.object,
  pickedVideos: PropTypes.array,
  onAddVideo: PropTypes.func,
}
