import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Container, Row } from "reactstrap"

import { Drawer } from "components"
import { LogisticsForm } from "../LogisticsForm"
import {
  modelPanelGeneralAddLogistics,
  modelPanelGeneralAddLogisticsCleanState,
} from "store/actions"
import { useCountryOptions } from "hooks"
import { ValidationsUtils } from "utils"

const AddLogisticsComponent = props => {
  const { t } = useTranslation()

  const countryOptions = useCountryOptions({ visible: props.visible })

  useEffect(() => {
    return () => {
      props.modelPanelGeneralAddLogisticsCleanState()
    }
  }, [])

  const handleCloseDrawer = () => {
    props.onClose()
  }

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()

    props.onAddSuccess()
    props.onClose()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    props.modelPanelGeneralAddLogistics(
      props.model.id,
      params,
      onSubmitSuccess(onSubmitProps),
      onSubmitError(onSubmitProps)
    )
  }
  const onDestroy = () => {
    props.modelPanelGeneralAddLogisticsCleanState()
  }
  return (
    <React.Fragment>
      <Drawer
        title={t("logistics_and_expenses")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        drawerProps={{}}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <Container fluid>
          <Row>
            <LogisticsForm
              initialValues={{}}
              loading={props.loading}
              error={props.error}
              onSubmit={handleSubmit}
              onCancel={handleCloseDrawer}
              countryOptions={countryOptions}
            />
          </Row>
        </Container>
      </Drawer>
    </React.Fragment>
  )
}

AddLogisticsComponent.propTypes = {
  visible: PropTypes.bool,
  onAddSuccess: PropTypes.func,
  onClose: PropTypes.func,

  loading: PropTypes.bool,
  error: PropTypes.object,
  model: PropTypes.object,

  modelPanelGeneralAddLogistics: PropTypes.func,
  modelPanelGeneralAddLogisticsCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.model.profile
  //must be chenged after updating backend
  const { loading, error } = state.modelPanel.general.logistics.add
  return {
    model: data,
    loading,
    error,
  }
}

const mapDispatchToProps = {
  modelPanelGeneralAddLogistics,
  modelPanelGeneralAddLogisticsCleanState,
}

export const AddLogistics = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddLogisticsComponent)
