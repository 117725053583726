import { all } from 'redux-saga/effects';

import listSaga from './list/sagas';

function* photosSaga() {
    yield all([
        listSaga(),
    ])
}

export default photosSaga;