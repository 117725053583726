import { all } from "redux-saga/effects"

import updateSaga from "./update/saga"
import removeSaga from "./remove/saga"
import addSaga from "./add/saga"
import listSaga from "./list/saga"
import reorderSaga from "./reorder/saga"

function* menuItemsSaga() {
  yield all([updateSaga(), removeSaga(), addSaga(), listSaga(), reorderSaga()])
}

export default menuItemsSaga
