import React, { useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Container, Row } from "reactstrap"
import { useHistory } from "react-router-dom"

import { Drawer, Button } from "components"
import { SwapConfirm } from "./SwapConfirm"
import { billingSubscriptionSwap } from "store/actions"

export const SubscriptionSwap = ({ tariff, ...props }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const { loading, error } = useSelector(
    state => state.billing.subscription.swap
  )

  const [drawerVisible, setDrawerVisible] = useState(false)

  const onSwap = () => {
    setDrawerVisible(true)
  }

  const closeDrawer = () => {
    setDrawerVisible(false)
  }

  const onSubmitSuccess = () => {
    closeDrawer()

    history.push(`/billing`)
  }

  const onSubmit = () => {
    const params = {
      id: tariff.id,
      onSuccess: onSubmitSuccess,
    }
    dispatch(billingSubscriptionSwap.swap(params))
  }

  const onDrawerDestroy = () => {
    dispatch(billingSubscriptionSwap.cleanState())
  }

  return (
    <React.Fragment>
      <Button
        title={"change_subscription_plan"}
        onClick={onSwap}
        loading={loading}
        disabled={!tariff || loading}
        type={"button"}
        size={"btn-lg"}
      />

      <Drawer
        title={t("change_subscription_plan")}
        visible={drawerVisible}
        onClose={closeDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDrawerDestroy}
      >
        <Container fluid>
          <Row>
            <SwapConfirm
              tariff={tariff}
              loading={loading}
              error={error}
              onConfirm={onSubmit}
              onCancel={closeDrawer}
            />
          </Row>
        </Container>
      </Drawer>
    </React.Fragment>
  )
}

SubscriptionSwap.propTypes = {
  tariff: PropTypes.object,
}
