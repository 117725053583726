import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"

import { Nav } from "../Nav"
import { usePanelTopBarContext } from "../../../../../../../contexts"
import { PageHeader } from "components"

export const HeaderComponent = props => {
  const { t } = useTranslation()

  const { options } = usePanelTopBarContext()

  return (
    <div className={"panel-topbar"}>
      <PageHeader title={t("billing_portal")} />

      <div className={"panel-topbar__nav mb-4"}>
        <div className={"panel-topbar__menu"}>
          <Nav />
        </div>

        {options.actions && (
          <div className="panel-topbar__actions">{options.actions}</div>
        )}
      </div>
    </div>
  )
}

HeaderComponent.propTypes = {}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent)
