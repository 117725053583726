import React, { useEffect, useMemo, useState } from "react"
import { Alert } from "reactstrap"
import { useTranslation } from "react-i18next"
import { connect, useDispatch } from "react-redux"
import PropTypes from "prop-types"

import { OverlayBlockLoading } from "components"
import { BillingInformationEditForm } from "../BillingInformationEditForm"
import { ValidationsUtils } from "utils"
import { billingBillingInfoData, billingBillingInfoEdit } from "store/actions"

const BillingInformationEditComponent = ({
  onSuccess = null,
  disabledMode = false,
  ...props
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isFormActive, setIsFormActive] = useState(!disabledMode)

  useEffect(() => {
    dispatch(billingBillingInfoData.getData())

    return () => {
      dispatch(billingBillingInfoData.cleanState())
      dispatch(billingBillingInfoEdit.cleanState())
    }
  }, [])

  const isEditable = useMemo(() => {
    return !props.subscription?.tariff?.id
  }, [props.subscription?.tariff?.id])

  const isDisabled = useMemo(() => {
    return !props.data || props.editLoading || !isFormActive
  }, [props.data, props.editLoading, isFormActive])

  const onSubmitSuccess = onSubmitProps => () => {
    if (disabledMode && isFormActive) {
      setIsFormActive(false)
    }

    if (onSuccess) {
      onSuccess()
    }
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const onSubmit = (params, onSubmitProps) => {
    if (isDisabled) {
      return
    }

    dispatch(
      billingBillingInfoEdit.edit({
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  const infoError = useMemo(() => {
    if (props.error || props.editError) {
      return props.error || props.editError?.message
    }
    return false
  }, [props.error, props.editError])

  const toggleActiveForm = () => {
    setIsFormActive(prevState => !prevState)
  }

  return (
    <>
      <Alert color="info">{t("billing_info.editable-alert")}</Alert>

      {infoError && <Alert color="danger">{infoError}</Alert>}

      <OverlayBlockLoading isLoading={props.loading}>
        <BillingInformationEditForm
          initialValues={{
            ...props.data,
          }}
          onSubmit={onSubmit}
          isDisabled={isDisabled}
          loading={props.editLoading}
          disabledMode={disabledMode}
          isFormActive={isFormActive}
          toggleActiveForm={toggleActiveForm}
          isEditable={isEditable}
        />
      </OverlayBlockLoading>
    </>
  )
}

BillingInformationEditComponent.propTypes = {
  onSuccess: PropTypes.func,
  disabledMode: PropTypes.bool,

  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.any,
  editLoading: PropTypes.bool,
  editError: PropTypes.any,

  subscription: PropTypes.object,
}

const mapStateToProps = state => {
  const { data, loading, error } = state.billing.billingInfo.data
  const { loading: editLoading, error: editError } =
    state.billing.billingInfo.edit

  return {
    data,
    loading,
    error,
    editLoading,
    editError,
    subscription: state.user.profile?.data?.subscription,
  }
}

export const BillingInformationEdit = connect(mapStateToProps)(
  BillingInformationEditComponent
)
