import React from "react"
import PropTypes from "prop-types"
import { Link, useRouteMatch } from "react-router-dom"
import { RatingBadge } from "../../../../../components"

export const AgenciesGridItem = props => {
    let { url } = useRouteMatch()

    return (
        <React.Fragment>
            <div className="agencies-panel-card-overview">
                <div className={"agencies-panel-card-overview__title-block"}>
                    <div className={"agencies-panel-card-overview__title-name"}>
                        <Link to={`${url}/${props.item.id}/general`}>{props.item.name}</Link>
                    </div>

                    {props.item?.avg_rating && (
                        <RatingBadge value={props.item.avg_rating} size={"sm"} wrapClassName={"ms-1"} />
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}

AgenciesGridItem.propTypes = {
    item: PropTypes.object,
}