import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"

import {
  ComboboxFormField,
  ControllerHF,
  InputField,
  DateInputField,
} from "components"
import { ExpensesSection } from "../FormsSections"

export const AccommodationForm = props => {
  const { t } = useTranslation()

  useEffect(() => {
    props.reset()
  }, [])

  const [watchDateIn, watchDateOut] = props.watch(["date_in", "date_out"])

  return (
    <>
      <Row>
        <Col md={4}>
          <ControllerHF
            name={"date_in"}
            control={props.control}
            component={DateInputField}
            id={"date_in"}
            label={t("date_in")}
            placeholder={"DD/MM/YYYY"}
            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            openToDate={(!watchDateIn && watchDateOut) || ""}
          />
        </Col>
        <Col md={4}>
          <ControllerHF
            name={"date_out"}
            control={props.control}
            component={DateInputField}
            id={"date_out"}
            label={t("date_out")}
            placeholder={"DD/MM/YYYY"}
            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            translateParams={{ date: t("date_in") }}
            openToDate={(!watchDateOut && watchDateIn) || ""}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <ControllerHF
            name={"room"}
            control={props.control}
            component={InputField}
            id={"room"}
            label={t("room")}
            placeholder={t("room")}
          />
        </Col>

        <Col md={4}>
          <ControllerHF
            name={"country_id"}
            control={props.control}
            component={ComboboxFormField}
            id={"country_id"}
            label={t("country")}
            placeholder={t("country")}
            options={props.countryOptions}
            getOptionLabel={option => `${option.name}`}
            getOptionValue={option => option.id}
            selectProps={{
              isClearable: true,
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <ControllerHF
            name={"state"}
            control={props.control}
            component={InputField}
            id={"state"}
            label={t("state")}
            placeholder={t("state")}
          />
        </Col>

        <Col md={4}>
          <ControllerHF
            name={"town"}
            control={props.control}
            component={InputField}
            id={"town"}
            label={t("town")}
            placeholder={t("town")}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <ControllerHF
            name={"street"}
            control={props.control}
            component={InputField}
            id={"street"}
            label={t("street")}
            placeholder={t("street")}
          />
        </Col>
      </Row>

      <Row>
        <Col md={8}>
          <ControllerHF
            name={"note"}
            control={props.control}
            component={InputField}
            id={"note"}
            label={t("note")}
            placeholder={t("note")}
            type={"textarea"}
          />
        </Col>
      </Row>

      <hr className="drawer-separator drawer-separator_form" />

      <ExpensesSection control={props.control} />
    </>
  )
}

AccommodationForm.propTypes = {
  control: PropTypes.object,
  countryOptions: PropTypes.array,
  reset: PropTypes.func,
  watch: PropTypes.any,
}
