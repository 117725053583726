import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "../../../../i18n"
import { API } from "../../../../api"
import { agencyRemoveAttachment } from "./slice"

function* remove({ payload: { id, onSuccess } }) {
    try {
        const response = yield call(API.agency.removeAgencyAttachment, id)

        const { data } = response

        yield put(agencyRemoveAttachment.removeCompleted())
        if(onSuccess) {
            onSuccess()
        }
        toastr.success(i18n.t("agency.remove-attachment.success"))

    } catch (error) {
        const errors = error.response?.data?.errors || {}
        const message = error.response?.data?.message || i18n.t("error")

        yield put(
            agencyRemoveAttachment.removeRejected({
                errors,
                message,
            })
        )
        toastr.error(message)
    }
}

export function* watchRemove() {
    yield takeEvery(agencyRemoveAttachment.remove, remove)
}

function* removeSaga() {
    yield all([fork(watchRemove)])
}

export default removeSaga