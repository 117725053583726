import React, { forwardRef, useImperativeHandle, useRef } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Button as StrapButton } from "reactstrap"
import { Modal } from "../Modal"
import { Button } from "../Buttons"

const ConfirmPopupComponent = props => {
  const { t } = useTranslation()
  const modalRef = useRef(null)

  useImperativeHandle(props.parentRef, () => ({
    show() {
      showModal()
    },
    hide() {
      hideModal()
    },
  }))

  const showModal = () => {
    if (modalRef.current) {
      modalRef.current.show()
    }
  }

  const hideModal = () => {
    if (modalRef.current) {
      modalRef.current.hide()
    }
  }

  const handleConfirm = () => {
    props.onConfirm()
    hideModal()
  }

  return (
    <Modal
      ref={modalRef}
      strapModalProp={{
        centered: true,
        onClosed: props.onClosed,
      }}
      className={"modal-block modal-confirm-popup"}
    >
      {props.children ? (
        props.children
      ) : (
        <p className={"font-size-22"}>
          {props.questionText
            ? t(props.questionText)
            : t("sure_permanently_delete")}
          ?
        </p>
      )}

      <div className="mt-4">
        <div className="button-items">
          <Button
            title={props.confirmText || "btn.confirm"}
            onClick={handleConfirm}
          />

          <StrapButton
            type="button"
            color="light-gray"
            outline
            className="waves-effect"
            onClick={hideModal}
          >
            {t("cancel")}
          </StrapButton>
        </div>
      </div>
    </Modal>
  )
}

ConfirmPopupComponent.propTypes = {
  children: PropTypes.any,
  parentRef: PropTypes.any,
  confirmText: PropTypes.string,
  questionText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onClosed: PropTypes.func,
}

export const ConfirmPopup = forwardRef((props, ref) => (
  <ConfirmPopupComponent {...props} parentRef={ref} />
))
