import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { PackageForm } from "../../../../components"
import { ValidationsUtils } from "../../../../utils"
import { PACKAGE_FORM_SUBMIT_TYPE_SEND } from "../../../../consts"
import { packageAdd, packageAddCleanState } from "../../../../store/actions"

const AddPackageComponent = props => {
  useEffect(() => {
    return () => {
      props.packageAddCleanState()
    }
  }, [])

  const onSubmitSuccess = onSubmitProps => data => {
    const { onReset, submitType } = onSubmitProps
    onReset()

    if (submitType && submitType === PACKAGE_FORM_SUBMIT_TYPE_SEND) {
      props.onSend(data)
    } else {
      props.onSaveSuccess()
    }
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    props.packageAdd(
      params,
      onSubmitSuccess(onSubmitProps),
      onSubmitError(onSubmitProps)
    )
  }

  return (
    <PackageForm
      initialValues={{}}
      loading={props.loading}
      error={props.error}
      onSubmit={handleSubmit}
      dataPicker={props.dataPicker}
    />
  )
}

AddPackageComponent.propTypes = {
  onSaveSuccess: PropTypes.func,
  onSend: PropTypes.func,

  loading: PropTypes.bool,
  error: PropTypes.object,
  dataPicker: PropTypes.object,

  packageAdd: PropTypes.func,
  packageAddCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, error } = state.package.add
  return {
    loading,
    error,
    dataPicker: state.package.dataPicker,
  }
}

const mapDispatchToProps = {
  packageAdd,
  packageAddCleanState,
}

export const AddPackage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPackageComponent)
