import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Badge } from "reactstrap"
import classNames from "classnames"

import { statementHelper } from "helpers"

export const StatementPaidStatusBadge = ({ value }) => {
  const config = useMemo(() => {
    return statementHelper.common.getPaidStatusConfig(value)
  }, [value])

  return (
    <Badge
      pill
      className={classNames("font-size-12", {
        [`badge-soft-${config?.theme}`]: config?.theme,
      })}
    >
      {config?.label}
    </Badge>
  )
}

StatementPaidStatusBadge.propTypes = {
  value: PropTypes.bool,
}
