import { all } from "redux-saga/effects"

import messageSaga from "./message/saga"
import seenSaga from "./seen/saga"
import typingSaga from "./typing/saga"
import groupSaga from "./group/sagas"

function* chatSaga() {
  yield all([messageSaga(), seenSaga(), typingSaga(), groupSaga()])
}

export default chatSaga
