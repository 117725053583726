import * as Yup from "yup"

export const validationSchema = Yup.object().shape({
  status: Yup.string().nullable().required("field.error.required"),
  alerts: Yup.array().of(
    Yup.object().shape({
      date_start: Yup.date().nullable().required("field.error.required"),
      description: Yup.string().nullable(),
    })
  ),
})
