import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { CheckboxMark } from "../../Marks"

export const SelectionControl = ({
  id,
  selected,
  onChange,
  className,
  disabled,
  size,
}) => {
  return (
    <label
      htmlFor={id}
      className={classNames("selection-control", {
        [className]: className,
      })}
    >
      <input
        className={classNames("d-none", {})}
        type="checkbox"
        id={id}
        checked={selected}
        onChange={event => onChange(event)}
        disabled={disabled}
      />

      <CheckboxMark status={selected} size={size} />
    </label>
  )
}

SelectionControl.propTypes = {
  id: PropTypes.string,
  selected: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}
