import { all } from 'redux-saga/effects';

import bookSaga from './book/saga';

function* commonSaga() {
    yield all([
        bookSaga(),
    ])
}

export default commonSaga;