import React from "react"
import PropTypes from "prop-types"

import { SelectionControl } from "components"

export const RecipientEmails = ({
  option,
  optionIndex,
  optionValues = [],
  onChange,
  ...props
}) => {
  const { id, emails, type } = option

  const onSelect = data => {
    let values = [...optionValues]
    const isSelected = values.includes(data)

    if (isSelected) {
      values = values.filter(item => item !== data)
    } else {
      values = [...values, data]
    }

    onChange([...values])
  }

  const renderEmail = email => (
    <label htmlFor={`${optionIndex}-${email}`}>
      <span>{email}</span>
    </label>
  )

  return (
    <React.Fragment>
      {emails.map((item, index) => (
        <div key={index}>
          {type === "custom" ? (
            renderEmail(item)
          ) : (
            <>
              <SelectionControl
                id={`${optionIndex}-${item}`}
                onChange={() => onSelect(item)}
                selected={optionValues.includes(item)}
                className={"me-2"}
                size={"sm"}
              />

              {renderEmail(item)}
            </>
          )}
        </div>
      ))}
    </React.Fragment>
  )
}

RecipientEmails.propTypes = {
  option: PropTypes.object,
  optionIndex: PropTypes.number,
  optionValues: PropTypes.array,
  onChange: PropTypes.func,
}
