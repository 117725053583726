import { all } from "redux-saga/effects"

import updateSaga from "./update/saga"
import removeSaga from "./remove/saga"
import dataSaga from "./data/saga"

function* profileSaga() {
    yield all([updateSaga(), removeSaga(), dataSaga()])
}

export default profileSaga