import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"

import { InvoiceCard } from "./InvoiceCard"
import { GridDataLoader } from "components"
import { billingInvoiceList } from "store/actions"

const InvoicesContainerComponent = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(billingInvoiceList.cleanState())
    }
  }, [])

  useEffect(() => {
    fetchList()
  }, [props.page])

  const fetchList = () => {
    let params = {
      page: props.page,
      per_page: 12,
    }

    dispatch(billingInvoiceList.getList({ params }))
  }

  const handlePageChange = item => {
    dispatch(billingInvoiceList.setPage(item.selected + 1))
  }

  return (
    <GridDataLoader
      loading={props.listLoading}
      error={props.listError}
      meta={props.meta}
      page={props.page}
      onPageChange={handlePageChange}
      className={"billing-invoices-grid"}
      items={props.list}
      renderItem={item => <InvoiceCard data={item} />}
    />
  )
}

InvoicesContainerComponent.propTypes = {
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,
}

const mapStateToProps = state => {
  const { list, listLoading, listError, meta, page } =
    state.billing.invoice.list

  return {
    list,
    listLoading,
    listError,
    page,
    meta,
  }
}

export const InvoicesContainer = connect(mapStateToProps)(
  InvoicesContainerComponent
)
