import {
  SET_STATE,
  RESET_STATE,
  CLEAN_STATE,
  SET_CONTRACT,
  RESET_CONTRACT,
} from "./actionTypes"

export const setState = data => {
  return {
    type: SET_STATE,
    payload: data,
  }
}

export const resetState = () => {
  return { type: RESET_STATE }
}

export const cleanState = () => {
  return { type: CLEAN_STATE }
}

export const setContract = data => {
  return {
    type: SET_CONTRACT,
    payload: data,
  }
}

export const resetContract = () => {
  return { type: RESET_CONTRACT }
}
