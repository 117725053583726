import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { SelectorCombobox } from "components"
import { useFetchData } from "hooks"
import { API } from "api"

export const ContactSelect = ({ value, disabled, ...props }) => {
  const { t } = useTranslation()

  const { fetchData, loading, data } = useFetchData()

  const onFetch = async () => {
    const request = API.users.getContacts()
    await fetchData({
      request,
    })
  }

  const onSelect = option => {
    props.onSelect(option)
  }

  return (
    <>
      <SelectorCombobox
        value={value}
        options={data?.data}
        onSelect={onSelect}
        onFetchOptions={onFetch}
        id={"chat-contact-select"}
        label={t("select_contact")}
        placeholder={t("select_contact")}
        loading={loading}
        getOptionLabel={option => option.name}
        getOptionValue={option => `${option.id}`}
        disabled={disabled}
      />
    </>
  )
}

ContactSelect.propTypes = {
  value: PropTypes.any,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
}
