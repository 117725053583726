import { takeEvery, fork, put, all, call, select } from "redux-saga/effects"

import i18n from "i18n"
import { API } from "api"
import { USER_SETTINGS_UPDATE_DATA } from "./actionTypes"
import {
  userSettingUpdateDataSuccess,
  userSettingUpdateDataError,
} from "./actions"
import { userProfileUpdateData } from "../../profile/actions"
import { staticMeasurementAll } from "../../../static/actions"

export const userState = state => state.user.profile
export const measurementState = state => state.static.measurement.all

function* updateSettings({ payload: { params, onSuccess, onError } }) {
  try {
    const user = yield select(userState)
    const currentMeasurementSystem = user?.data?.settings?.measurement_system

    const response = yield call(API.profile.updateSettings, params)

    const { data } = response

    yield put(userSettingUpdateDataSuccess())

    yield put(userProfileUpdateData({ settings: data.data }))

    // fetch measurement data if system changed
    const measurement = yield select(measurementState)
    if (
      currentMeasurementSystem !== data.data?.measurement_system &&
      measurement?.data
    ) {
      yield put(staticMeasurementAll.getData({}))
    }

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      userSettingUpdateDataError({
        errors,
        message,
      })
    )

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchUpdateSettings() {
  yield takeEvery(USER_SETTINGS_UPDATE_DATA, updateSettings)
}

function* updateSaga() {
  yield all([fork(watchUpdateSettings)])
}

export default updateSaga
