import React from "react"
import { useRouteMatch } from "react-router-dom"

import { Layout, PanelRouting } from "./components"

export const AdministrationIndex = () => {
  let { path } = useRouteMatch()

  return (
    <Layout>
      <PanelRouting />
    </Layout>
  )
}
