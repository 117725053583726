import React from "react"
import PropTypes from "prop-types"
import { IconButton, Image } from "components"
import { MediaOverviewCard } from "components"

export const HomePhotoOverviewCard = React.memo(({ item, onRemove }) => {
  const renderActions = item => {
    return (
      <>
        <IconButton name={"remove"} bg onClick={() => onRemove(item)} />
      </>
    )
  }
  const renderContent = item => {
    return <Image src={item.src} alt={item.name} />
  }
  return (
    <MediaOverviewCard
      actions={renderActions(item)}
      item={item}
      content={renderContent(item)}
      size={"sm"}
    />
  )
})

HomePhotoOverviewCard.propTypes = {
  item: PropTypes.object,
  onRemove: PropTypes.func,
}
