import { takeLatest, fork, put, all, call } from "redux-saga/effects"

import i18n from "../../../../../i18n"
import { API } from "../../../../../api"
import { calendarEventModelRateList } from "./slice"

export function* getList({ payload: { event, model } }) {
  try {
    const response = yield call(API.calendar.getModelRates, event, model)

    const { data } = response

    yield put(calendarEventModelRateList.getListSuccess(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(calendarEventModelRateList.getListError(message))
  }
}

export function* watchGetList() {
  yield takeLatest(calendarEventModelRateList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
