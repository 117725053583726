import { createSlice } from "@reduxjs/toolkit"

const initialState = {}

const panelGeneralSlice = createSlice({
  name: "panelGeneral",
  initialState,
  reducers: {
    resetState(state) {
      return state
    },
  },
})

/**
 * @namespace
 * @property {function} resetState
 */

export const panelGeneral = panelGeneralSlice.actions

export default panelGeneralSlice.reducer
