import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { connect } from "react-redux"

import { MegaTagTitle, SubscriptionAccessViewer } from "components"

import { RatingContainer } from "./components/RatingContainer"

const RatingComponent = () => {
  return (
    <>
      <MegaTagTitle title={"title.employee.rating"} />

      <SubscriptionAccessViewer>
        <div className={"rating-panel-employee"}>
          <Row>
            <Col>
              <RatingContainer />
            </Col>
          </Row>
        </div>
      </SubscriptionAccessViewer>
    </>
  )
}

RatingComponent.propTypes = {}

const mapStateToProps = state => {
  return {}
}

export const Rating = connect(mapStateToProps)(RatingComponent)
