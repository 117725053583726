import React from "react"
import PropTypes from "prop-types"
import { EntityWithPaymentStatus } from "../EntityWithPaymentStatus"
import { GENERAL_CALENDAR_ENTITY } from "consts"

const { MODEL, AGENCY, CLIENT } = GENERAL_CALENDAR_ENTITY

export const EntitiesWithPaymentStatus = ({ data = [], entity }) => {
  if (data.length > 0) {
    return (
      <div className={"d-flex gap-2"}>
        {data.map((item, index) => (
          <div className={"d-flex"} key={index}>
            <EntityWithPaymentStatus data={item} entity={entity} />

            {index !== data.length - 1 ? <div>{`,`}</div> : null}
          </div>
        ))}
      </div>
    )
  } else {
    return <>-</>
  }
}

EntitiesWithPaymentStatus.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      status: PropTypes.bool,
    })
  ),
  entity: PropTypes.oneOf([MODEL, AGENCY, CLIENT]),
}
