import { all } from "redux-saga/effects"

import infoSaga from "./info/saga"
import invoiceSaga from "./invoice/saga"

function* billingSaga() {
  yield all([infoSaga(), invoiceSaga()])
}

export default billingSaga
