import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"

import { InfoCard } from "components"
import { BillingInformationEdit } from "./BillingInformationEdit"

export const BillingInformation = props => {
  const { t } = useTranslation()

  return (
    <Row>
      <Col>
        <InfoCard title={t("billing_information")}>
          <BillingInformationEdit {...props} />
        </InfoCard>
      </Col>
    </Row>
  )
}

BillingInformation.propTypes = {}
