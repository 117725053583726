import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "../../../../i18n"
import { API } from "../../../../api"
import { agencyUpdateProfile } from "./slice"
import { agencyProfileData } from "../data/slice"

function* updateProfile({ payload: { id, params, onSuccess, onError } }) {
    try {
        const response = yield call(API.agency.updateAgency, id, params)

        const { data } = response

        if (onSuccess) {
            onSuccess()
        }
        toastr.success(i18n.t("edit.success"))

        yield put(agencyUpdateProfile.updateCompleted())
        yield put(agencyProfileData.updateData({ ...data.data }))
    } catch (error) {
        const errors = error.response?.data?.errors || {}
        const message = error.response?.data?.message || i18n.t("error")

        yield put(
            agencyUpdateProfile.updateRejected({
                errors,
                message,
            })
        )
        if (onError) {
            onError(errors)
        }
    }
}

export function* watchUpdateProfile() {
    yield takeEvery(agencyUpdateProfile.update, updateProfile)
}

function* updateSaga() {
    yield all([fork(watchUpdateProfile)])
}

export default updateSaga
