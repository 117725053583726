import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { Col, Row } from "reactstrap"
import { ControllerHF } from "../../../../ReactHookForm"
import { ComboboxFormField, InputField } from "../../../../FormElements"
import {
  MODEL_AGENCY_PROFIT_TYPE_GROSS,
  MODEL_AGENCY_AS_TYPE_MOTHER,
} from "../../../../../consts"
import { NormalizeUtils } from "../../../../../utils"
import { modelHelper } from "../../../../../helpers"
import { useFetchData } from "../../../../../hooks"
import { API } from "../../../../../api"

const PERCENT_VALUES = {
  min: 0,
  max: 100,
}

export const AgencySection = props => {
  const { t } = useTranslation()

  const { data, fetchData } = useFetchData()

  const onFetchData = async () => {
    const params = {
      pagination: false,
      filters: {
        as_types: [MODEL_AGENCY_AS_TYPE_MOTHER],
      },
    }
    const request = API.model.getModelAgencies(props.modelId, params)
    await fetchData({
      request,
    })
  }

  useEffect(() => {
    onFetchData()
  }, [])

  const { control, setValue, watch } = useFormContext()

  const watchAgency = watch("fashion_model_agency_id")

  const onAgencyChange = value => {
    setValue("fashion_model_agency_id", value)

    // set value for other fields when agency change
    if (value) {
      const { percentage_mother, percentage_type } = value

      setValue(
        "agency_percent",
        percentage_mother || percentage_mother === 0
          ? percentage_mother.toString()
          : "10"
      )
      setValue("agency_on", percentage_type)
    }
  }

  const getAgencyOptionLabel = option => {
    const { agency } = option
    let result = agency.name
    const country = agency?.country?.name
    if (country) {
      result = `${result} - ${country}`
    }

    return result
  }

  return (
    <Row>
      <Col md={5}>
        <ControllerHF
          name={"fashion_model_agency_id"}
          control={control}
          component={ComboboxFormField}
          id={"fashion_model_agency_id"}
          label={t("mother_agency")}
          placeholder={t("mother_agency")}
          options={data?.data || []}
          shouldUnregister
          defaultValue={null}
          selectProps={{
            isClearable: true,
          }}
          onChange={onAgencyChange}
          getOptionLabel={option => getAgencyOptionLabel(option)}
          getOptionValue={option => option.id}
        />
      </Col>

      {watchAgency && (
        <Col md={4}>
          <ControllerHF
            name={"agency_percent"}
            control={control}
            component={InputField}
            id={"agency_percent"}
            label={t("percent")}
            placeholder={t("percent")}
            normalize={NormalizeUtils.onlyNumbers}
            translateParams={{
              min: PERCENT_VALUES.min,
              max: PERCENT_VALUES.max,
            }}
            shouldUnregister
            defaultValue={"10"}
          />
        </Col>
      )}

      {watchAgency && (
        <Col md={3}>
          <ControllerHF
            name={"agency_on"}
            control={control}
            component={ComboboxFormField}
            id={"agency_on"}
            label={t("model-agency-on")}
            placeholder={t("model-agency-on")}
            options={modelHelper.agency.getAgencyProfitTypeOptions()}
            selectProps={{
              isSearchable: false,
            }}
            shouldUnregister
            defaultValue={MODEL_AGENCY_PROFIT_TYPE_GROSS}
            normalize={option => (option !== null ? option.value : null)}
            isDetermineValue
          />
        </Col>
      )}
    </Row>
  )
}

AgencySection.propTypes = {
  modelId: PropTypes.number,
}
