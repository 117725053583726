import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

import { SepaForm } from "./SepaForm"
import { billingHelper } from "helpers"
import * as HOC from "HOC"

const DataShower = HOC.withDataShower()

export const SepaContainer = props => {
  const { data, loading } = useSelector(state => state.billing.billingInfo.data)

  useEffect(() => {
    return () => {
      props.onDestroy()
    }
  }, [])

  return (
    <>
      <DataShower isLoading={loading}>
        {data && (
          <SepaForm
            defaultValues={{
              name: data?.first_name,
              email: data?.email,
              address: billingHelper.info.mapBillingAddress(data),
            }}
            {...props}
          />
        )}
      </DataShower>
    </>
  )
}

SepaContainer.propTypes = {
  onDestroy: PropTypes.func,
}
