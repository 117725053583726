/* eslint react/prop-types: off */
import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { ReactTableView } from "components"
import { Date } from "./Date"
import { EntitiesWithPaymentStatus } from "./EntitiesWithPaymentStatus"
import { EntityWithPaymentStatus } from "./EntityWithPaymentStatus"
import { Amount } from "./Amount"
import { Payment } from "./Payment"
import { GENERAL_CALENDAR_ENTITY } from "consts"

import * as HOC from "HOC"

const { MODEL, AGENCY, CLIENT } = GENERAL_CALENDAR_ENTITY

const TableDataLoading = HOC.withTableDataLoading()

export const EventsTable = props => {
  const { t } = useTranslation()

  const data = useMemo(() => {
    return props.list
  }, [props.list])

  const [columns] = useState([
    {
      Header: t("id"),
      accessor: "id",
      id: "id",
      Cell: ({ value }) => value,
    },
    {
      Header: t("date"),
      accessor: "date",
      id: "date",
      Cell: ({ row }) => {
        return <Date data={row.original} />
      },
    },
    // {
    //   Header: t("title"),
    //   accessor: "title",
    //   id: "title",
    //   Cell: ({ row }) => {
    //     return <Title data={row.original} />
    //   },
    // },
    {
      Header: t("client"),
      accessor: "client_with_payment_status",
      id: "client_with_payment_status",
      Cell: ({ value }) => (
        <EntityWithPaymentStatus data={value} entity={CLIENT} />
      ),
    },
    {
      Header: t("product"),
      accessor: "product",
      id: "product",
      Cell: ({ value }) => {
        return value || "-"
      },
    },
    {
      Header: t("models"),
      accessor: "models_with_payment_status",
      id: "models_with_payment_status",
      Cell: ({ value }) => (
        <EntitiesWithPaymentStatus data={value} entity={MODEL} />
      ),
    },
    {
      Header: t("agencies"),
      accessor: "agencies_with_payment_status",
      id: "agencies_with_payment_status",
      Cell: ({ value }) => (
        <EntitiesWithPaymentStatus data={value} entity={AGENCY} />
      ),
    },
    {
      Header: () => <div className={"w-100 text-end"}>{t("amount")}</div>,
      accessor: "amount",
      id: "amount",
      Cell: props => <Amount data={props.row.original} />,
    },
    {
      Header: () => null,
      accessor: "payment_status",
      id: "payment_status",
      Cell: props => (
        <Payment data={props.row.original} onPayment={props.meta.onPayment} />
      ),
    },
  ])

  return (
    <TableDataLoading
      isLoading={props.loading}
      isFailed={props.error}
      error={props.error}
    >
      <ReactTableView
        isLoading={props.loading}
        columns={columns}
        data={data}
        meta={{ onPayment: props.onPayment }}
      />
    </TableDataLoading>
  )
}

EventsTable.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  onPayment: PropTypes.func,
}
