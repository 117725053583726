import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"
import { ControllerHF, CheckboxGroup, FilterTitle } from "components"
import i18n from "i18n"
import { NormalizeUtils } from "utils"

const isInTownOptions = [
  {
    label: (
      <span className={"text-secondary"}>
        {i18n.t("in-town.status.in")}
      </span>
    ),
    value: true,
    color: "primary",
  },
  {
    label: (
      <span className={"text-danger"}>
        {i18n.t("in-town.status.out")}
      </span>
    ),
    value: false,
    color: "primary",
  },
]

export const InTownSection = props => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext()

  const watchStatus = watch("is_in_town")

  return (
    <div className={"mb-3"}>
      <FilterTitle title={t("status")} />

      <ControllerHF
        id={"status"}
        name={"is_in_town"}
        control={control}
        component={CheckboxGroup}
        options={isInTownOptions}
        normalize={NormalizeUtils.stringBoolToBoolean}
        className={"d-flex"}
        fieldValue={watchStatus || []}
      />
    </div>
  )
}

InTownSection.propTypes = {}
