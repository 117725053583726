import React, { useEffect, useRef, useState } from 'react'
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux"

import { PackageDataTabs } from "../../../../PackageDataTabs"
import { Books } from "./Books";
import { Videos } from "./Videos"
import {
    packageDraftDataCollectionsFetchBooksCleanState,
    packageDraftDataCollectionsFetchVideosCleanState,
} from '../../../../../../store/actions'
import { useDraftPackageDataPicker } from "../../../../../../hooks"

const BOOKS_TAB = 'books';
const VIDEOS_TAB = 'videos';

export const DataCollections = (props) => {
    const { t } = useTranslation();

    const dispatch = useDispatch()

    const [activeTab, setActiveTab] = useState(props.initialTab);
    const playersRef = useRef([]);

    const { pickedBooks, onBookToggle, pickedVideos, onVideoToggle } = useDraftPackageDataPicker();

    useEffect(() => {
        return () => {
            dispatch(packageDraftDataCollectionsFetchBooksCleanState());
            dispatch(packageDraftDataCollectionsFetchVideosCleanState());
        }
    }, []);

    useEffect(() => {
        return () => {
            if (activeTab === VIDEOS_TAB) {
                pausePlayers();
            }
        }
    }, [activeTab])

    const pausePlayers = () => {
        if (playersRef.current) {
            playersRef.current.forEach((player) => {
                if (player && player?.pause) {
                    player.pause();
                }
            })
        }
    }

    const onAddBook = (book) => {
        onBookToggle(book);
    }

    const onAddVideo = (video) => {
        onVideoToggle(video);
    }

    return (
        <div>
            <PackageDataTabs
                activeTab={activeTab}
                onTabChange={(tab) => setActiveTab(tab)}
                booksTab={
                    <Books picked={pickedBooks} onAdd={onAddBook} />
                }
                videosTab={
                    <Videos picked={pickedVideos} onAdd={onAddVideo} playersRef={playersRef} />
                }
                destroyOnClose
            />
        </div>
    );
};

DataCollections.propTypes = {
    initialTab: PropTypes.oneOf([BOOKS_TAB, VIDEOS_TAB]),
}