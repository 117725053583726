import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import { PanelLayout, PanelRouting } from "./components"

import * as HOC from "../../HOC"
import { clientProfileData } from "../../store/actions"

const DataShower = HOC.withDataShower()

const ClientPanelComponent = props => {
  let { id } = useParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(clientProfileData.getData({ id }))
    return () => {
      dispatch(clientProfileData.cleanState())
    }
  }, [id])

  return (
    <PanelLayout>
      <DataShower
        isLoading={props.loading}
        isFailed={props.error}
        error={props.error}
        customLoaderClass={"mt-5"}
      >
        {props.client && <PanelRouting />}
      </DataShower>
    </PanelLayout>
  )
}

ClientPanelComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  client: PropTypes.object,
}

const mapStateToProps = state => {
  const { loading, error, data } = state.client.profile.data
  return {
    loading,
    error,
    client: data,
  }
}

export const ClientPanel = connect(mapStateToProps)(ClientPanelComponent)
