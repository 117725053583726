import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

import { StripeSepaFormSection } from "components"

export const SepaForm = props => {
  const { t } = useTranslation()

  return (
    <StripeSepaFormSection
      state={props.stripeState}
      defaultValues={props.defaultValues}
      onStripeChange={props.onChange}
    />
  )
}

SepaForm.propTypes = {
  defaultValues: PropTypes.object,
  onChange: PropTypes.func,
  stripeState: PropTypes.object,
}
