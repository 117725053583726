import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { Nav } from "../Nav"
import { usePanelTopBarContext } from "contexts"

export const HeaderComponent = props => {
  const { options } = usePanelTopBarContext()

  return (
    <div className={"panel-topbar"}>
      <div className={"panel-topbar__nav mb-4"}>
        <div className={"panel-topbar__menu"}>
          <Nav />
        </div>

        {options.actions && (
          <div className="panel-topbar__actions">{options.actions}</div>
        )}
      </div>
    </div>
  )
}

HeaderComponent.propTypes = {
  topBarOptions: PropTypes.object,
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent)
