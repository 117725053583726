import React from "react"
import moment from "moment"

import { IconButton } from "components"
import { useCompareEventsCalendarContext } from "../../../contexts"
import { useTranslation } from "react-i18next"

export const Header = () => {
  const { t } = useTranslation()

  const compareEventsCalendarContext = useCompareEventsCalendarContext()

  const {
    state: { period },
    onClean,
  } = compareEventsCalendarContext

  const [start, end] = period

  const renderPeriod = () => {
    if (start && end) {
      const s = moment(start).format("DD/MM/YYYY")
      const e = moment(end).format("DD/MM/YYYY")
      return `${s} - ${e}`
    }

    return null
  }

  return (
    <>
      <div className={"d-flex gap-3 align-items-center mb-3"}>
        <h4 className={"mb-0"}>
          {t("compare_period")}: {renderPeriod()}
        </h4>

        <div>
          <IconButton
            name={"remove"}
            onClick={() => onClean()}
            bg
            bgColor={"bg-secondary"}
          />
        </div>
      </div>
    </>
  )
}

Header.propTypes = {}
