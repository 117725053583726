import React from "react"
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  generatePath,
} from "react-router-dom"

import { Calendar } from "../../Calendar"
import { Events } from "../../Events"

export const PanelRouting = props => {
  let { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={generatePath(`${path}/calendar`)} />
      </Route>
      <Route path={`${path}/calendar`}>
        <Calendar />
      </Route>
      <Route path={`${path}/events`}>
        <Events />
      </Route>
      <Route>
        <Redirect to={generatePath(`${path}/general`)} />
      </Route>
    </Switch>
  )
}
