import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { HistoryTable } from "../../../../../components"
import { clientHistoryData } from "../../../../../store/actions"

const HistoryContainerComponent = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(clientHistoryData.cleanState())
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [props.page])

  const fetchData = () => {
    let params = {
      page: props.page,
    }
    dispatch(clientHistoryData.getData({ id: props.client.id, params }))
  }

  const handlePageChange = item => {
    dispatch(clientHistoryData.setPage(item.selected + 1))
  }

  return (
    <>
      <HistoryTable
        list={props.list}
        loading={props.loading}
        error={props.error}
        meta={props.meta}
        page={props.page}
        onPageChange={handlePageChange}
      />
    </>
  )
}

HistoryContainerComponent.propTypes = {
  client: PropTypes.object,
  list: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
}

const mapStateToProps = state => {
  const { data } = state.client.profile.data
  const { list, loading, error, meta, page } = state.client.history.data
  return {
    client: data,
    list,
    loading,
    error,
    meta,
    page,
  }
}

export const HistoryContainer = connect(mapStateToProps)(
  HistoryContainerComponent
)
