import React from "react"
import PropTypes from "prop-types"

import { Button } from "components"

export const PaymentSubmit = ({ onClick, ...props }) => {
  return (
    <Button
      title={"pay"}
      onClick={onClick}
      type={"button"}
      size={"btn-sm"}
      btnClass={"statement-payment-action__btn"}
    />
  )
}

PaymentSubmit.propTypes = {
  onClick: PropTypes.func,
}
