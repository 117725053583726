import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

import { AddRecipient } from "../AddRecipient"
import { RecipientsTable } from "../RecipientsTable"

export const RecipientsContainer = ({ disabled, value, options, ...props }) => {
  const { t } = useTranslation()

  const isDisabled = useMemo(() => {
    if (disabled) return disabled

    if (props.limit) {
      return value.length >= props.limit
    }
    return false
  }, [value.length, props.limit, disabled])

  return (
    <>
      <Row>
        <Col md={12}>
          <div className={"d-flex justify-content-between flex-wrap"}>
            <h4>{t("event.send-email.recipient-title")}</h4>
            <div>
              <AddRecipient disabled={isDisabled} onAdd={props.onAdd} />
            </div>
          </div>
        </Col>
      </Row>
      <Row className={""}>
        <Col md={12}>
          {options && options.length > 0 ? (
            <RecipientsTable
              value={value}
              options={options}
              onRemove={props.onRemove}
              onUpdate={props.onUpdate}
              validationsErrors={props.validationsErrors}
            />
          ) : (
            <div className={""}>{t("no_recipients")}</div>
          )}
        </Col>
      </Row>
    </>
  )
}

RecipientsContainer.propTypes = {
  value: PropTypes.any,
  limit: PropTypes.number,
  onAdd: PropTypes.func,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.any,
  className: PropTypes.string,
  options: PropTypes.array,
  validationsErrors: PropTypes.array,
}
