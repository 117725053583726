import { takeLatest, fork, put, all, call } from "redux-saga/effects"
import i18n from "../../../../i18n"
import { API } from "../../../../api"
import { RequestUtils } from "../../../../utils"
import { clientHistoryData } from "./slice"

export function* getData({ payload: { id, params } }) {
  try {
    let requestParams = {
      includes: [],
    }
    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response = yield call(API.client.getClientHistory, id, requestParams)

    const { data } = response

    yield put(clientHistoryData.getDataCompleted(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(clientHistoryData.getDataRejected(message))
  }
}

export function* watchGetData() {
  yield takeLatest(clientHistoryData.getData, getData)
}

function* dataSaga() {
  yield all([fork(watchGetData)])
}

export default dataSaga
