import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { InfoCardShower } from "../InfoCardShower"
import { AddLogistics } from "./AddLogistics"
import { LogisticsTable } from "./LogisticsTable"
import { EditLogistics } from "./EditLogistics"
import {
  modelPanelGeneralFetchLogisticsList,
  modelPanelGeneralFetchLogisticsListCleanState,
  modelPanelGeneralLogisticsListSetPage,
  modelPanelGeneralLogisticsListRemoveItem,
  modelPanelGeneralLogisticsListRemoveItemCleanState,
} from "../../../../../store/actions"
import { ConfirmPopup } from "../../../../../components"

const LogisticsContainerComponent = props => {
  const removeModalRef = useRef(null)

  const [logisticsRemove, setLogisticsRemove] = useState(null)
  const [logisticsEdit, setLogisticsEdit] = useState(null)
  const [editDrawerVisible, setEditDrawerVisible] = useState(null)
  const [addDrawerVisible, setAddDrawerVisible] = useState(null)

  useEffect(() => {
    return () => {
      props.modelPanelGeneralFetchLogisticsListCleanState()
      props.modelPanelGeneralLogisticsListRemoveItemCleanState()
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [props.page])

  const fetchData = () => {
    let params = {
      page: props.page,
      per_page: 5,
    }

    props.modelPanelGeneralFetchLogisticsList(props.model.id, params)
  }

  const handlePageChange = item => {
    props.modelPanelGeneralLogisticsListSetPage(item.selected + 1)
  }

  const handleAddSuccess = () => {
    fetchData()
  }

  const removeLogistics = item => {
    setLogisticsRemove(item)

    if (removeModalRef.current) {
      removeModalRef.current.show()
    }
  }

  const resetLogisticsRemove = () => {
    setLogisticsRemove(null)
  }

  const onRemoveSuccess = () => {
    fetchData()
  }

  const confirmRemove = () => {
    props.modelPanelGeneralLogisticsListRemoveItem(
      logisticsRemove.id,
      onRemoveSuccess
    )
  }

  const editLogistics = item => {
    setLogisticsEdit(item)
    editDrawerShow()
  }

  const handleEditSuccess = () => {
    fetchData()
  }

  const resetLogisticsEdit = () => {
    setLogisticsEdit(null)
  }

  const editDrawerShow = () => {
    setEditDrawerVisible(true)
  }

  const editDrawerClose = () => {
    setEditDrawerVisible(false)
  }

  const editDrawerDestroy = () => {
    resetLogisticsEdit()
  }

  const addLogistics = () => {
    setAddDrawerVisible(true)
  }

  const addDrawerClose = () => {
    setAddDrawerVisible(false)
  }

  return (
    <>
      <InfoCardShower
        title={"logistics_and_expenses"}
        className={"model-logistics-info"}
        addMode
        onAdd={addLogistics}
        items={props.list}
        loading={props.listLoading}
        error={props.listError}
        meta={props.meta}
        onPageChange={handlePageChange}
      >
        <LogisticsTable
          items={props.list}
          removingItems={props.removingItems}
          onEdit={editLogistics}
          onRemove={removeLogistics}
        />
      </InfoCardShower>

      <AddLogistics
        visible={addDrawerVisible}
        onAddSuccess={handleAddSuccess}
        onClose={addDrawerClose}
      />

      <EditLogistics
        visible={editDrawerVisible}
        onEditSuccess={handleEditSuccess}
        id={logisticsEdit?.id}
        onClose={editDrawerClose}
        onDestroy={editDrawerDestroy}
      />

      <ConfirmPopup
        ref={removeModalRef}
        onConfirm={confirmRemove}
        onClosed={resetLogisticsRemove}
      />
    </>
  )
}

LogisticsContainerComponent.propTypes = {
  model: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
  removingItems: PropTypes.array,

  modelPanelGeneralFetchLogisticsList: PropTypes.func,
  modelPanelGeneralFetchLogisticsListCleanState: PropTypes.func,
  modelPanelGeneralLogisticsListSetPage: PropTypes.func,
  modelPanelGeneralLogisticsListRemoveItem: PropTypes.func,
  modelPanelGeneralLogisticsListRemoveItemCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.model.profile
  //must be changed after updating backend
  const { list, listLoading, listError, meta, page } =
    state.modelPanel.general.logistics.list.data
  const { pending } = state.modelPanel.general.logistics.list.removeItem
  return {
    model: data,
    list,
    listLoading,
    listError,
    meta,
    page,
    removingItems: pending,
  }
}

const mapDispatchToProps = {
  modelPanelGeneralFetchLogisticsList,
  modelPanelGeneralFetchLogisticsListCleanState,
  modelPanelGeneralLogisticsListSetPage,
  modelPanelGeneralLogisticsListRemoveItem,
  modelPanelGeneralLogisticsListRemoveItemCleanState,
}

export const LogisticsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LogisticsContainerComponent)
