import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from "toastr";

import i18n from '../../../../i18n';
import { PACKAGE_EDIT_DATA } from './actionTypes';
import {
    packageEditDataSuccess,
    packageEditDataError,
} from './actions';

import { API } from '../../../../api';

function* editData({ payload: { id, params, onSuccess, onError } }) {
    try {
        const response = yield call(API.packageApi.updatePackage, id, params);

        const { data } = response;

        yield put(packageEditDataSuccess());

        toastr.success(i18n.t('save.success'));

        if (onSuccess) {
            onSuccess(data?.data);
        }
    } catch (error) {
        const errors = error.response?.data?.errors || {};
        const message = error.response?.data?.message || i18n.t('error');

        yield put(packageEditDataError({
            errors,
            message
        }));

        if (onError) {
            onError(errors);
        }
    }
}

export function* watchEditData() {
    yield takeEvery(PACKAGE_EDIT_DATA, editData);
}

function* editSaga() {
    yield all([
        fork(watchEditData),
    ]);
}

export default editSaga;