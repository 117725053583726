import {
  MODEL_PANEL_GENERAL_ADD_LOGISTICS,
  MODEL_PANEL_GENERAL_ADD_LOGISTICS_SUCCESS,
  MODEL_PANEL_GENERAL_ADD_LOGISTICS_ERROR,
  MODEL_PANEL_GENERAL_ADD_LOGISTICS_CLEAN_STATE,
} from "./actionTypes"

export const modelPanelGeneralAddLogistics = (
  id,
  params,
  onSuccess,
  onError
) => {
  return {
    type: MODEL_PANEL_GENERAL_ADD_LOGISTICS,
    payload: { id, params, onSuccess, onError },
  }
}

export const modelPanelGeneralAddLogisticsSuccess = () => {
  return {
    type: MODEL_PANEL_GENERAL_ADD_LOGISTICS_SUCCESS,
  }
}

export const modelPanelGeneralAddLogisticsError = data => {
  return {
    type: MODEL_PANEL_GENERAL_ADD_LOGISTICS_ERROR,
    payload: data,
  }
}

export const modelPanelGeneralAddLogisticsCleanState = () => {
  return { type: MODEL_PANEL_GENERAL_ADD_LOGISTICS_CLEAN_STATE }
}
