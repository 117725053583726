import {
    PROFILE_UPLOAD_AVATAR,
    PROFILE_UPDATE_AVATAR_SUCCESS,
    PROFILE_UPDATE_AVATAR_ERROR,
    PROFILE_REMOVE_AVATAR,
    PROFILE_AVATAR_CLEAN_STATE,
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
};

const avatar = (state = initialState, action) => {
    switch (action.type) {
        case PROFILE_UPLOAD_AVATAR:
            state = {
                ...state,
                loading: true,
                error: null,
            };
            break;

        case PROFILE_UPDATE_AVATAR_SUCCESS:
            state = {
                ...state,
                loading: false,
            };
            break;

        case PROFILE_UPDATE_AVATAR_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        case PROFILE_AVATAR_CLEAN_STATE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        case PROFILE_REMOVE_AVATAR:
            state = {
                ...state,
                loading: true,
                error: null,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default avatar;