import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Nav, NavItem, NavLink } from "reactstrap"
import classNames from "classnames"
import _ from "lodash"

import { GENDER_FEMALE, GENDER_MALE } from "consts"

const defaultTabs = [
  {
    label: "female",
    value: [GENDER_FEMALE],
  },
  {
    label: "male",
    value: [GENDER_MALE],
  },
  {
    label: "all",
    value: [GENDER_FEMALE, GENDER_MALE],
  },
]

export const GenderTabsNav = ({
  tabs = defaultTabs,
  activeTab,
  onTabChange,
}) => {
  const { t } = useTranslation()

  const isActive = value => _.isEqual(value, activeTab)

  return (
    <Nav tabs className="gender-tabs-nav">
      {tabs.map(item => (
        <NavItem key={item.value}>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classNames({
              active: isActive(item.value),
            })}
            onClick={() => {
              if (!isActive(item.value)) {
                onTabChange(item.value)
              }
            }}
          >
            <span className="text-nowrap">{t(item.label)}</span>
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

GenderTabsNav.propTypes = {
  tabs: PropTypes.array,
  activeTab: PropTypes.array,
  onTabChange: PropTypes.func,
}
