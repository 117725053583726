import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { Modal, RatingForm } from "../../../../../../components"
import {
  modelPanelRatingAddRating,
  modelPanelRatingAddRatingCleanState,
} from "../../../../../../store/actions"

const AddRatingComponent = props => {
  const { t } = useTranslation()

  const modalRef = useRef(null)
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    return () => {
      if (modalVisible) {
        props.modelPanelRatingAddRatingCleanState()
      }
    }
  }, [modalVisible])

  const handleAdd = e => {
    e.preventDefault()
    if (modalRef.current) {
      modalRef.current.show()
    }
  }

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.hide()
    }
  }

  const onSubmitSuccess = onSubmitProps => () => {
    const { setSubmitting, resetForm } = onSubmitProps
    setSubmitting(false)
    resetForm()

    props.onAddSuccess()

    closeModal()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setErrors, setSubmitting } = onSubmitProps
    setSubmitting(false)
    setErrors(errors)
  }

  const handleSubmit = (params, onSubmitProps) => {
    props.modelPanelRatingAddRating(
      props.model.id,
      params,
      onSubmitSuccess(onSubmitProps),
      onSubmitError(onSubmitProps)
    )
  }

  return (
    <React.Fragment>
      <Link to="#" className="text-secondary" onClick={handleAdd}>
        {t("btn.new-rating")}
      </Link>

      <Modal
        ref={modalRef}
        title={t("model-rating.add-rating.title")}
        onVisibleChange={value => setModalVisible(value)}
        strapModalProp={{
          centered: true,
        }}
      >
        <RatingForm
          loading={props.loading}
          error={props.error}
          onSubmit={handleSubmit}
          onCancel={closeModal}
        />
      </Modal>
    </React.Fragment>
  )
}

AddRatingComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  onAddSuccess: PropTypes.func,

  model: PropTypes.object,

  modelPanelRatingAddRating: PropTypes.func,
  modelPanelRatingAddRatingCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.model.profile
  const { loading, error } = state.modelPanel.rating.add
  return {
    model: data,
    loading,
    error,
  }
}

const mapDispatchToProps = {
  modelPanelRatingAddRating,
  modelPanelRatingAddRatingCleanState,
}

export const AddRating = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddRatingComponent)
