import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import { calendarEventContractEditData } from "store/actions"
import * as HOC from "HOC"

const DataShower = HOC.withDataShower()

export const EventContractDataLoader = props => {
  const dispatch = useDispatch()

  const { data, loading, error } = useSelector(
    state => state.calendarEvent.contract.edit.data
  )

  useEffect(() => {
    fetchData()

    return () => {
      dispatch(calendarEventContractEditData.cleanState())
    }
  }, [])

  const fetchData = () => {
    dispatch(calendarEventContractEditData.getData({ id: props.id }))
  }

  return (
    <DataShower isLoading={loading} isFailed={error} error={error}>
      {data && props.children}
    </DataShower>
  )
}

EventContractDataLoader.propTypes = {
  id: PropTypes.number,
  children: PropTypes.any,
}
