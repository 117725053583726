import React from "react"
import { Spinner } from "reactstrap"
import PropTypes from "prop-types"
import { OverlayLoading } from "./OverlayLoading"

export const OverlayBlockLoading = ({ isLoading, children }) => {
  return (
    <div className={"overlay-block"}>
      {children}

      <OverlayLoading isLoading={isLoading} />
    </div>
  )
}

OverlayBlockLoading.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.any,
}
