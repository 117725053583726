import React from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"

import { Sidebar } from "./Sidebar"
import { Header } from "./Header"
import { PageIntro } from "../PageIntro"

export const CalendarLayout = ({ children, headerProps, ...props }) => {
  return (
    <PageIntro>
      <div className="page-content page-content_sidebar">
        <div className={"page-content__container"}>
          <Container fluid>
            <Header {...headerProps} />
            <div>{children}</div>
          </Container>
        </div>

        <div className={"page-content__sidebar"}>
          <Sidebar />
        </div>
      </div>
    </PageIntro>
  )
}

CalendarLayout.propTypes = {
  children: PropTypes.any,
  headerProps: PropTypes.any,
}
