import React from "react"
import PropTypes from "prop-types"

import { RecipientsContainer } from "./RecipientsContainer"

export const RecipientsContactsControl = ({
  disabled,
  value = [],
  className,
  options,
  ...props
}) => {
  const onAdd = data => {
    const recipient = {
      emails: [data.email],
      type: "custom", // type for custom recipient
    }

    props.onAdd(recipient)

    props.onChange([...value, { ...recipient }])
  }

  const onUpdate = (data, index) => {
    let items = value.map((item, i) => {
      if (i !== index) {
        return item
      }
      return {
        ...item,
        ...data,
      }
    })

    props.onChange([...items])
  }

  const onRemove = (data, index) => {
    props.onRemove(data, index)

    let items = value.filter((_, i) => i !== index)
    props.onChange([...items])
  }

  return (
    <RecipientsContainer
      value={value}
      limit={props.limit}
      onAdd={onAdd}
      onUpdate={onUpdate}
      onRemove={onRemove}
      disabled={disabled}
      className={className}
      options={options}
      validationsErrors={props.validationsErrors}
    />
  )
}

RecipientsContactsControl.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  limit: PropTypes.number,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.any,
  className: PropTypes.string,
  options: PropTypes.array,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  validationsErrors: PropTypes.array,
}
