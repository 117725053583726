import { takeLatest, fork, put, all, call, select } from "redux-saga/effects"
import _ from "lodash"

import { chatChatsList } from "./slice"
import i18n from "i18n"
import { API } from "api"
import { chatHelper } from "helpers"

export const chatsListState = state => state.chat.chats.list

export function* getList() {
  try {
    const response = yield call(API.chat.getChats)

    const { data } = response

    yield put(chatChatsList.getListSuccess(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(chatChatsList.getListError(message))
  }
}

// create/updated chat when message received or submit message
export function* chatUpdateOrCreate({
  payload: { message, chat, isCurrentChat },
}) {
  const listState = yield select(chatsListState)

  if (listState.isLoaded) {
    let chatItem = _.find(listState.list, { id: message?.chat_id })

    if (chatItem) {
      // if exist - update chat item
      let item = chatHelper.chats.updateChatItem(
        { ...chatItem },
        message,
        chat,
        isCurrentChat
      )
      yield put(
        chatChatsList.updateItem({
          id: chatItem.id,
          ...item,
        })
      )
    } else {
      // add new chat item
      let newChat = chatHelper.chats.createChatItemBasedOnMessage(
        message,
        chat,
        isCurrentChat
      )
      yield put(chatChatsList.addItem(newChat))
    }
  }
}

// add chat when received socket event
export function* onChatAdd({ payload: { chat } }) {
  const listState = yield select(chatsListState)

  if (listState.isLoaded) {
    yield put(chatChatsList.addItem(chat))
  }
}

// remove chat when received socket event
export function* onChatRemove({ payload: { id } }) {
  const listState = yield select(chatsListState)

  if (listState.isLoaded) {
    yield put(chatChatsList.removeItem({ id }))
  }
}

// updated chat when received socket event
export function* onChatUpdate({ payload: { chat } }) {
  const listState = yield select(chatsListState)

  if (listState.isLoaded) {
    yield put(chatChatsList.updateItem(chat))
  }
}

export function* watchGetList() {
  yield takeLatest(chatChatsList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
