import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { TextButton, HelpPopover } from "components"

export const UploadPhoto = props => {
  const { t } = useTranslation()

  const inputRef = useRef()

  const handleImageSelected = event => {
    let files = event.target.files
    let fileList = [...files]
    props.onUpload(fileList)
    if (inputRef.current) {
      inputRef.current.value = null
    }
  }

  const selectPhoto = event => {
    event.preventDefault()

    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <div className={"d-flex"}>
      <input
        type="file"
        id={"avatar"}
        className="d-none"
        onChange={handleImageSelected}
        ref={inputRef}
        accept="image/png, image/jpeg"
        multiple
      />
      <TextButton
        loading={props.loading}
        onClick={e => selectPhoto(e)}
        disabled={props.loading}
        title={"btn.upload-website-home-photo"}
        color={"secondary"}
      />

      <div className={"ms-2 d-flex align-items-center"}>
        <HelpPopover
          content={t("website.home-image-recommendations", {
            aspectRatio: "16:9",
            size: "1920 x 1080",
          })}
        />
      </div>
    </div>
  )
}

UploadPhoto.propTypes = {
  image: PropTypes.any,
  loading: PropTypes.bool,
  onUpload: PropTypes.func,
}
