import React, { useEffect, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { BookPickedPhoto, DndListControl } from "components"
import { useBookPhotosPicker } from "hooks"
import SimpleBar from "simplebar-react"

const BookPhotosPickerSidebarComponent = props => {
  const { t } = useTranslation()
  const ref = useRef()

  const { changeOrder, changeCover, removeItem } = useBookPhotosPicker()

  const isReady = useMemo(() => {
    if (props.mode === "edit") {
      return !!props.book
    }
    return true
  }, [props.mode, props.book])

  useEffect(() => {
    if (ref.current) {
      ref.current.recalculate()
    }
  })

  return (
    <div className="panel-sidebar__sidebar-content panel-common-sidebar-content book-picked-photos-sidebar-content">
      {isReady && (
        <>
          <div className={""}>
            <p className={"mb-0 font-size-16 font-weight-semibold"}>
              {t("your_choice")} - {props.picked.length}
            </p>
            <hr className={"divider"} />
          </div>
          <div className={"book-picked-photos"}>
            <SimpleBar className="h-100" ref={ref}>
              <DndListControl
                value={props.picked}
                onChange={changeOrder}
                wrapClass={"book-picked-photos__grid"}
                renderContainer={(provided, item, index) => (
                  <BookPickedPhoto
                    key={`book-picked-photos-${index}`}
                    provided={provided}
                    item={item}
                    index={index}
                    onCoverChange={changeCover}
                    onRemove={removeItem}
                  />
                )}
              />
            </SimpleBar>
          </div>
        </>
      )}
    </div>
  )
}

BookPhotosPickerSidebarComponent.propTypes = {
  drawerVisible: PropTypes.bool,
  toggleDrawerVisible: PropTypes.func,
  mode: PropTypes.oneOf(["add", "edit"]),

  book: PropTypes.object,
  picked: PropTypes.array,
}

const mapStateToProps = state => {
  const { data } = state.modelPanel.book.details.data
  const { picked } = state.modelPanel.book.photosPicker
  return {
    book: data,
    picked,
  }
}

export const BookPhotosPickerSidebar = connect(mapStateToProps)(
  BookPhotosPickerSidebarComponent
)
