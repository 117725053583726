import React from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"
import classNames from "classnames"

import { Drawer } from "components"

export const EventContractDrawer = props => {
  const handleCloseDrawer = () => {
    props.onClose()
  }

  const onDestroy = () => {
    props.onDestroy && props.onDestroy()
  }

  return (
    <Drawer
      title={props.title}
      visible={props.visible}
      onClose={handleCloseDrawer}
      placement={"right"}
      className={classNames("drawer-container drawer_nested", {
        [props.className]: props.className,
      })}
      destroyOnClose
      onDestroy={onDestroy}
    >
      <Container fluid>{props.children}</Container>
    </Drawer>
  )
}

EventContractDrawer.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.any,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
}
