import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { v4 as uuidv4 } from "uuid"

import { RatesContainer } from "./RatesContainer"

export const RatesControl = ({
  disabled,
  value,
  async = false,
  className,
  ...props
}) => {
  // Add
  const onAdd = (items, payload) => {
    const { onSuccess, onError } = payload

    if (async) {
      const params = {
        // callback: (data) => add(data),
        onSuccess: data => onAddSuccess(data, onSuccess),
        onError: errors => onError(errors),
      }
      props.onAdd && props.onAdd(items, params)
    } else {
      items = items.map(item => ({
        ...item,
        id: uuidv4()
      }))

      onAddSuccess(items, onSuccess)
    }
  }

  const onAddSuccess = (items, onSuccess) => {
    add(items)
    onSuccess()
  }

  const add = items => {
    props.onChange([...value, ...items])
  }
  // --- //

  // Edit
  const onEdit = (data, payload) => {
    const { onSuccess, onError } = payload

    if (async) {
      const params = {
        // callback: (data) => edit(data),
        onSuccess: data => onEditSuccess(data, onSuccess),
        onError: errors => onError(errors),
      }
      props.onEdit && props.onEdit(data, params)
    } else {
      onEditSuccess(data, onSuccess)
    }
  }

  const onEditSuccess = (data, onSuccess) => {
    edit(data)
    onSuccess()
  }

  const edit = data => {
    let items = value.map(item => {
      if (item.id !== data.id) {
        return item
      }
      return {
        ...item,
        ...data,
      }
    })

    props.onChange([...items])
  }
  // --- //

  const onRemove = data => {
    if (async) {
      const params = {
        // callback: (data) => remove(data),
        onSuccess: data => remove(data),
      }
      props.onRemove && props.onRemove(data, params)
    } else {
      remove(data)
    }
  }

  const remove = data => {
    let items = value.filter(item => item.id !== data.id)
    props.onChange([...items])
  }

  const isDisabled = useMemo(() => {
    return disabled
  }, [disabled])

  return (
    <>
      <RatesContainer
        value={value}
        onAdd={onAdd}
        onEdit={onEdit}
        onRemove={onRemove}
        loading={props.loading}
        error={props.error}
        disabled={isDisabled}
        currency={props.currency}
        generalLoading={props.generalLoading}
        onFormDestroy={props.onFormDestroy}
        datePeriod={props.datePeriod}
        modelId={props.modelId}
      />
    </>
  )
}

RatesControl.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  async: PropTypes.bool,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.any,
  className: PropTypes.string,
  currency: PropTypes.string,
  generalLoading: PropTypes.bool,
  onFormDestroy: PropTypes.func,
  datePeriod: PropTypes.object,
  modelId: PropTypes.number,
}
