import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import toastr from "toastr"

import { SettingsForm } from "./SettingsForm"
import { commonHelper } from "../../../../helpers"
import {
  userSettingUpdateData,
  userSettingUpdateDataCleanState,
} from "../../../../store/actions"
import i18n from "../../../../i18n"
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap"

const SettingsComponent = props => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      props.userSettingUpdateDataCleanState()
    }
  }, [])

  const onSubmitSuccess = onSubmitProps => () => {
    const { setSubmitting } = onSubmitProps
    setSubmitting(false)

    toastr.success(i18n.t("settings.saved.success"))
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setErrors, setSubmitting } = onSubmitProps
    setSubmitting(false)
    setErrors(errors)
  }

  const handleSubmit = (params, onSubmitProps) => {
    props.userSettingUpdateData(
      params,
      onSubmitSuccess(onSubmitProps),
      onSubmitError(onSubmitProps)
    )
  }

  return (
    <Card className={"h-100"}>
      <CardHeader className={"bg-dark"}>
        <CardTitle className={"text-white mb-0"}>{t("settings")}</CardTitle>
      </CardHeader>
      <CardBody className={"bg-neutral"}>
        <SettingsForm
          loading={props.loading}
          error={props.error}
          initialValues={{
            currency:
              commonHelper.currency.getCurrency(
                props.user.settings?.currency
              ) || null,
            measurement_system:
              commonHelper.measurements.getMeasurementSystem(
                props.user.settings?.measurement_system
              ) || null,
          }}
          onSubmit={handleSubmit}
        />
      </CardBody>
    </Card>
  )
}

SettingsComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  user: PropTypes.object,

  userSettingUpdateData: PropTypes.func,
  userSettingUpdateDataCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, error } = state.user.settings.update
  const { data } = state.user.profile
  return {
    loading,
    error,
    user: data,
  }
}

const mapDispatchToProps = {
  userSettingUpdateData,
  userSettingUpdateDataCleanState,
}

export const Settings = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsComponent)
