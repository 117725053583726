import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"
import { modelGeneralUploadAvatar } from "./slice"
import i18n from "i18n"
import { API } from "api"
import { modelHelper } from "helpers"
import {
  modelPanelGeneralSyncUploadedPhotos,
  modelProfileUpdateData,
} from "store"

function* uploadAvatar({ payload: { id, params, onSuccess, onError } }) {
  try {
    let requestBody = yield call(
      modelHelper.photos.getModelAvatarRequestBody,
      params
    )
    const response = yield call(API.model.uploadModelPhoto, id, requestBody)

    const { data } = response

    toastr.success(i18n.t("model.add-avatar.success"))

    yield put(modelGeneralUploadAvatar.uploadCompleted())
    yield put(
      modelProfileUpdateData({
        avatar: data.data?.attachment?.src,
        crops: data.data?.crops,
        avatar_photo_id: data.data?.id,
      })
    )
    yield put(modelPanelGeneralSyncUploadedPhotos())
    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      modelGeneralUploadAvatar.uploadRejected({
        errors,
        message,
      })
    )

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchAddItem() {
  yield takeEvery(modelGeneralUploadAvatar.upload, uploadAvatar)
}

function* addSaga() {
  yield all([fork(watchAddItem)])
}

export default addSaga
