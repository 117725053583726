import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { IconButton } from "components/index"
import moment from "moment"
import { RemoveControl } from "./RemoveControl"
import { ContractDownloadMenuControl } from "../../../../../../Shared"

export const ContractRow = ({ data, contractProps, ...props }) => {
  const { t } = useTranslation()

  const { contract, ...model } = data

  const { onEdit, onRemoveSuccess, send, onSend, onDownload } = contractProps

  return (
    <tr>
      <td>
        <div className={"text-nowrap align-middle ps-5"}>
          <span className={"fw-medium"}>
            {t("contract")}
            {contract?.contract_date
              ? ` - ${moment(contract?.contract_date).format("DD/MM/YYYY")}`
              : null}
          </span>
        </div>
      </td>
      <td
        className={"table-column-sticky_end bg-white text-nowrap text-end"}
        style={{ width: "76px" }}
      >
        {send && <IconButton name={"mail"} onClick={() => onSend(model)} bg />}
        <IconButton
          name={"edit"}
          onClick={() => onEdit(model)}
          bg
          wrapClass={"ms-2"}
        />

        <ContractDownloadMenuControl
          onDownload={value => {
            if (value === "doc") {
              onDownload(contract?.doc_url)
            } else if (value === "pdf") {
              onDownload(contract?.pdf_url)
            }
          }}
          className={"ms-2"}
          menuProps={{
            positionFixed: true,
            className: "dropdown-menu-fixed",
          }}
        >
          <IconButton name={"download"} bg />
        </ContractDownloadMenuControl>

        <RemoveControl
          eventId={props.event?.id}
          data={data}
          onSuccess={onRemoveSuccess}
        />
      </td>
    </tr>
  )
}

ContractRow.propTypes = {
  data: PropTypes.object,
  contractProps: PropTypes.object,
  event: PropTypes.object,
}
