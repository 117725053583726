import React from "react"
import PropTypes from "prop-types"

import { usePanelLayoutIntro } from "hooks"
import { ProductTourButton } from "./ProductTourButton"

export const ProductTour = props => {
  const { config } = usePanelLayoutIntro()

  const onStart = () => {
    config.onStart && config.onStart()
  }

  return config.visible ? (
    <ProductTourButton
      onClick={onStart}
      wrapClass={props.btnWrapClass}
      label={config.label}
    />
  ) : null
}

ProductTour.propTypes = {
  btnWrapClass: PropTypes.string,
}
