import {
  MODEL_PROFILE_SET_DATA,
  MODEL_PROFILE_CLEAN_STATE,
  MODEL_PROFILE_UPDATE_DATA,
  MODEL_PROFILE_UPDATE_FEATURES,
  MODEL_PROFILE_ADD_GROUP,
  MODEL_PROFILE_REMOVE_GROUP,
} from "./actionTypes"

export const modelProfileSetData = data => {
  return {
    type: MODEL_PROFILE_SET_DATA,
    payload: data,
  }
}

export const modelProfileCleanState = () => {
  return { type: MODEL_PROFILE_CLEAN_STATE }
}

export const modelProfileUpdateData = data => {
  return {
    type: MODEL_PROFILE_UPDATE_DATA,
    payload: data,
  }
}

export const modelProfileUpdateFeatures = data => {
  return {
    type: MODEL_PROFILE_UPDATE_FEATURES,
    payload: data,
  }
}

export const modelProfileAddGroup = data => {
  return {
    type: MODEL_PROFILE_ADD_GROUP,
    payload: data,
  }
}

export const modelProfileRemoveGroup = data => {
  return {
    type: MODEL_PROFILE_REMOVE_GROUP,
    payload: data,
  }
}
