import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { Modal } from "components"
import { useSubmitHandler } from "hooks"
import { AddContactForm } from "./AddContactForm"
import { chatContactsAdd } from "store/actions"

export const AddContact = ({ modalRef, onCancel, onSuccess }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { loading, error } = useSelector(state => state.chat.contacts.add)

  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    return () => {
      if (modalVisible) {
        dispatch(chatContactsAdd.cleanState())
      }
    }
  }, [modalVisible])

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        chatContactsAdd.add({
          params,
          onSuccess,
          onError,
        })
      )
    },
    () => {
      onSuccess()
    }
  )

  return (
    <Modal
      ref={modalRef}
      title={t("add-contact")}
      onVisibleChange={value => setModalVisible(value)}
      strapModalProp={{
        centered: true,
      }}
    >
      <AddContactForm
        loading={loading}
        error={error}
        onSubmit={handleSubmit}
        onCancel={onCancel}
      />
    </Modal>
  )
}

AddContact.propTypes = {
  modalRef: PropTypes.any,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
}
