import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Members } from "./Members"

export const ChatInfo = ({ data }) => {
  const { t } = useTranslation()

  const { name, participants, admin } = data

  return (
    <div className={"chat-info-view"}>
      <div className={"d-flex mb-4"}>
        <div className={"me-2"}>
          <h5 className={"mb-0"}>{`${t("name")}: `}</h5>
        </div>
        <div>
          <h5 className={"mb-0 fw-normal"}>{name}</h5>
        </div>
      </div>

      <Members participants={participants} admin={admin} />
    </div>
  )
}

ChatInfo.propTypes = {
  data: PropTypes.object,
}
