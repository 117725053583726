import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { ShareButton } from "./ShareButton"
import { SOCIAL_NETWORK_SHARE_LIST } from "consts"

export const ShareBox = ({ link }) => {
  const { t } = useTranslation()
  return (
    <div className={"share-box"}>
      {link && (
        <>
          <h6 className={"share-box__title"}>{t("share")}</h6>

          <div className={"share-box__icons-container "}>
            {SOCIAL_NETWORK_SHARE_LIST.map((socialName, index) => {
              return (
                <ShareButton
                  key={`share-button-${index}`}
                  url={link}
                  name={socialName}
                />
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

ShareBox.propTypes = {
  link: PropTypes.string,
}
