import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  filters: {
    values: {
      keywords: "",
    },
  },
}

const chatContactsListSlice = createSlice({
  name: "chatContactsList",
  initialState,
  reducers: {
    getList(state) {
      state.listLoading = true
      state.listError = null
      state.list = []
    },
    getListSuccess(state, action) {
      state.listLoading = false
      state.list = [...action.payload.data]
    },
    getListError(state, action) {
      state.listLoading = false
      state.listError = action.payload
    },
    changeFilter(state, action) {
      state.filters.values = { ...state.filters.values, ...action.payload }
    },
    addItem(state, action) {
      state.list.push(action.payload)
    },
    removeItem(state, action) {
      state.list = state.list.filter(item => item.id !== action.payload.id)
    },
    cleanState() {
      return initialState
    },
  },
})

/**
 * @namespace
 * @property {function} getList
 * @property {function} getListSuccess
 * @property {function} getListError
 * @property {function} changeFilter
 * @property {function} addItem
 * @property {function} removeItem
 * @property {function} cleanState
 */

export const chatContactsList = chatContactsListSlice.actions

export default chatContactsListSlice.reducer
