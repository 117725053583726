import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Container } from "reactstrap"

import { Drawer } from "../../Drawer"
import { BOOK, PACKAGE } from "../consts"

export const SharedEntityDrawer = props => {
  const { t } = useTranslation()

  const handleCloseDrawer = () => {
    props.onClose()
  }

  const onDestroy = () => {}

  const config = useMemo(
    () => ({
      title: props.entity === BOOK ? t("send-book") : t("send-package"),
    }),
    [props.entity]
  )

  return (
    <React.Fragment>
      <Drawer
        title={config.title}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <Container fluid>{props.children}</Container>
      </Drawer>
    </React.Fragment>
  )
}

SharedEntityDrawer.propTypes = {
  entity: PropTypes.oneOf([BOOK, PACKAGE]).isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  children: PropTypes.any,
}
