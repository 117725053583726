import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Container } from "reactstrap"

import { Drawer } from "../../Drawer"
import { EventDataLoader } from "../EventDataLoader"
import { eventsCalendarHelper } from "../../../helpers"

export const EventFormDrawer = props => {
  const { t } = useTranslation()

  const handleCloseDrawer = () => {
    props.onClose()
  }

  const onDestroy = () => {
    props.onDestroy()
  }

  const getTitle = () => {
    if (!props.isEditMode) {
      return eventsCalendarHelper.eventConfig.getEventConfig(props.eventType)
        ?.title
    } else {
      return eventsCalendarHelper.calendar.getGeneralEventTypeTitle(
        props.editEventData,
        props.entityScreen,
        props.entityId
      )
    }
  }

  const title = useMemo(() => {
    if (props.eventType) {
      let result = !props.isEditMode ? t("add") : t("edit")
      return result + " " + getTitle()
    }
    return ""
  }, [props.eventType, props.editEventData])

  const subtitle = useMemo(() => {
    if (props.editEventData?.user) {
      return `${t("event_created_by")} ` + props.editEventData?.user?.name
    }
    return null
  }, [props.editEventData])

  return (
    <React.Fragment>
      <Drawer
        title={title}
        subtitle={subtitle}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <Container fluid>
          {!props.isEditMode ? (
            props.children
          ) : (
            <EventDataLoader id={props.eventId} isEdit>
              {props.children}
            </EventDataLoader>
          )}
        </Container>
      </Drawer>
    </React.Fragment>
  )
}

EventFormDrawer.propTypes = {
  isEditMode: PropTypes.bool,
  eventType: PropTypes.string,
  eventId: PropTypes.number,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  children: PropTypes.any,
  entityScreen: PropTypes.string,
  entityId: PropTypes.number,
  editEventData: PropTypes.object,
}
