import React from 'react'
import PropTypes from "prop-types"
import { Alert } from "reactstrap"
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Button, FormikComboboxFiled } from '../../../../components';
import { commonHelper } from "../../../../helpers";

export const SettingsForm = (props) => {
    const { t } = useTranslation();

    const onSubmit = (values, onSubmitProps) => {
        const params = {
            ...values,
            currency: values.currency?.value,
            measurement_system: values.measurement_system?.value,
        };

        props.onSubmit(params, onSubmitProps);
    };

    return (
        <Formik
            initialValues={props.initialValues}
            validationSchema={Yup.object({
                currency: Yup.object().nullable().required('field.error.required'),
                measurement_system: Yup.object().nullable().required('field.error.required'),
            })}
            onSubmit={onSubmit}
            validateOnChange={true}
            validateOnBlur={false}
            enableReinitialize={true}
        >
            {({
                  isSubmitting,
                  ...rest
              }) => {
                return (
                    <>
                        {props.error && (
                            <Alert color="danger">
                                {props.error.message}
                            </Alert>
                        )}

                        <Form className="form-horizontal" noValidate={true}>
                            <FormikComboboxFiled
                                name={'currency'}
                                id={'currency'}
                                label={t('currency')}
                                placeholder={t('currency')}
                                options={commonHelper.currency.getCurrencies()}
                                // getOptionLabel={(option) => `${option.title}`}
                                selectProps={{
                                    isSearchable: false,
                                    styles: {
                                        option: (provided) => ({
                                            ...provided,
                                            textTransform: 'uppercase',
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            textTransform: 'uppercase',
                                        })
                                    }
                                }}
                            />

                            <FormikComboboxFiled
                                name={'measurement_system'}
                                id={'measurement_system'}
                                label={t('measurement_system')}
                                placeholder={t('measurement_system')}
                                options={commonHelper.measurements.getMeasurementSystems()}
                                selectProps={{
                                    isSearchable: false
                                }}
                            />

                            <div className="mt-3">
                                <div className="button-items">
                                    <Button
                                        submit
                                        title={'btn.save'}
                                        disabled={isSubmitting || props.loading}
                                        loading={props.loading}
                                    />
                                </div>
                            </div>
                        </Form>
                    </>
                )
            }}
        </Formik>
    );
};

SettingsForm.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.object,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
}