import { GENDER_FEMALE } from "../model"

export const MODELS_PANEL_FILTERS_INITIAL_VALUES = {
  keywords: "",
  residence: null,
  birthdayplace: "",
  gender: GENDER_FEMALE,
  is_in_town: [],
  age_from: "",
  age_to: "",
  height_from: null,
  height_to: null,
  bust_from: null,
  bust_to: null,
  waist_from: null,
  waist_to: null,
  hips_from: null,
  hips_to: null,
  eyes_colors: null,
  hair_colors: null,
  shoes_from: null,
  shoes_to: null,
  ethnics: null,
  group: null,
  not_in_group: null,
  influencer: false,
  plus_size: false,
}
