import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import {
  websiteCurrentPageData,
  websiteCurrentPageRemoveAttachment,
} from "store"
import {
  ConfirmPopup,
  FilesUploadingDataGrid,
  GridDataLoader,
} from "components"
import { useConfirmModal, usePanelTopBarOptions } from "hooks"
import { AddPhoto } from "../AddPhoto"
import { HomePhotoOverviewCard } from "../HomePhotoOverviewCard"

const HomeContainerComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    fetchData()
    return () => {
      dispatch(websiteCurrentPageData.cleanState())
    }
  }, [])

  const fetchData = () => {
    dispatch(websiteCurrentPageData.getData({ alias: props.alias }))
  }

  const { modalRef, closeModal, showModal, handleConfirm } = useConfirmModal(
    item => {
      dispatch(
        websiteCurrentPageRemoveAttachment.remove({
          id: item.id,
          successMessage: t("website.home.photo-removed-success"),
        })
      )
    },
    () => dispatch(websiteCurrentPageRemoveAttachment.cleanState())
  )
  const handleAddSuccess = () => {
    fetchData()
  }
  const panelOptions = useMemo(
    () => ({
      actions: (
        <>
          <AddPhoto alias={props.alias} onAddSuccess={handleAddSuccess} />
        </>
      ),
    }),
    []
  )

  usePanelTopBarOptions(panelOptions)

  return (
    <>
      <FilesUploadingDataGrid className={"website-home-progress-data-grid"} />
      <GridDataLoader
        className={"website-home-data-grid"}
        loading={props.loading}
        items={props.data?.template_values?.attachments}
        empty={"website.home.no-photo"}
        renderItem={item => (
          <HomePhotoOverviewCard item={item} onRemove={showModal} />
        )}
      />
      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
      />
    </>
  )
}

HomeContainerComponent.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.any,
  alias: PropTypes.string,
  removingItem: PropTypes.bool,
}

const mapStateToProps = state => {
  const { data, loading, error } = state.website.general.currentPage.data
  const { loading: removingItem } =
    state.website.general.currentPage.attachments.remove
  return {
    data,
    loading,
    error,
    removingItem,
  }
}

const mapDispatchToProps = {}

export const HomeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeContainerComponent)
