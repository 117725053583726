import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap"
import { useSubmitHandler } from "hooks"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { ColorSettingsForm } from "./ColorSettingsForm"
import { userOptionsUpdate } from "store"
import { values } from "lodash"
import {
  formColorsValues,
  getDefaultColors,
  getEventColorsParams,
} from "./formHelper"

export const ColorSettings = ({ options }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { loading, error } = useSelector(state => state.user.options.update)

  useEffect(() => {
    return () => {
      dispatch(userOptionsUpdate.cleanState())
    }
  }, [])

  const isExistCustomColors = useMemo(() => {
    return values(options?.event_colors).some(
      color => color.custom_color !== null
    )
  }, [options?.event_colors])

  const { handleSubmit } = useSubmitHandler(
    ({ params, reset }) => {
      dispatch(
        userOptionsUpdate.update({
          params: getEventColorsParams(options?.event_colors, params),
          onError: reset,
          onSuccess: () => {
            if (params.isDefaultValues) {
              reset(getDefaultColors(options?.event_colors))
            } else {
              reset(params)
            }
          },
        })
      )
    },
    () => {}
  )
  return (
    <Card className={"h-100"}>
      <CardHeader className={"bg-dark"}>
        <CardTitle className={"text-white mb-0"}>
          {t("color-settings")}
        </CardTitle>
      </CardHeader>
      <CardBody className={"bg-neutral"}>
        <ColorSettingsForm
          loading={loading}
          error={error}
          onSubmit={handleSubmit}
          initialValues={formColorsValues(options?.event_colors)}
          isExistCustomColors={isExistCustomColors}
        />
      </CardBody>
    </Card>
  )
}

ColorSettings.propTypes = {
  options: PropTypes.object,
}
