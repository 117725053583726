import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { useSubmitHandler } from "hooks"
import { calendarEventEditDone } from "store/actions"
import { DoneForm } from "./DoneForm"

export const EventDone = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { loading } = useSelector(state => state.calendarEvent.edit.done)

  useEffect(() => {
    return () => {
      dispatch(calendarEventEditDone.cleanState({}))
    }
  }, [])

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        calendarEventEditDone.submit({
          id: props.event.id,
          params,
          onSuccess,
          onError,
        })
      )
    },
    data => {
      const { id, done } = data
      props.onSuccess({
        id,
        done,
      })
    }
  )

  return (
    <DoneForm
      loading={loading}
      initialValues={{
        done: !!props.event?.done,
      }}
      onSubmit={handleSubmit}
    />
  )
}

EventDone.propTypes = {
  event: PropTypes.object,
  onSuccess: PropTypes.func,
}
