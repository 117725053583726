import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "../../../../../i18n"
import { API } from "../../../../../api"
import { calendarEventModelUpdate } from "./slice"

function* update({ payload: { event, model, params, onSuccess, onError } }) {
  try {
    const response = yield call(API.calendar.updateModel, event, model, params)

    const { data } = response

    yield put(calendarEventModelUpdate.updateSuccess())

    if (onSuccess) {
      onSuccess(data?.data)
    }

    toastr.success(i18n.t("update.success"))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      calendarEventModelUpdate.updateError({
        errors,
        message,
      })
    )
    if (onError) {
      onError(errors)
    }

    toastr.error(message)
  }
}

export function* watchUpdate() {
  yield takeEvery(calendarEventModelUpdate.update, update)
}

function* updateSaga() {
  yield all([fork(watchUpdate)])
}

export default updateSaga
