import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { ExpenseInfoCell } from "components"
import { commonHelper, modelHelper } from "helpers"

const getCurrencyLabel = currency =>
  commonHelper.currency.getCurrency(currency)?.label?.toUpperCase()

export const Expenses = ({ data }) => {
  const { t } = useTranslation()

  const expensesData = useMemo(() => {
    let total = {}

    // map expenses data
    let models = data
      .filter(model => !!model?.expenses?.length)
      .map(item => ({
        id: item.id,
        name: item.name,
        expense: {
          items: item.expenses,
          total: modelHelper.expenses.getExpensesTotalData(item.expenses),
        },
      }))

    // map expenses total
    if (!!models.length) {
      models.forEach(model => {
        const modelExpenseTotal = model.expense.total
        Object.entries(modelExpenseTotal).forEach(entry => {
          const [currency, amount] = entry

          total = {
            ...total,
            [currency]: (total[currency] || 0) + amount,
          }
        })
      })
    }

    return { models, total }
  }, [data])

  const renderAmounts = () => {
    const items = Object.entries(expensesData.total)
    return items.map(([key, value], index) => {
      const next = items[index + 1]
      return (
        <span key={key} className={"fw-bold"}>
          {getCurrencyLabel(key)} {value}
          {next ? ", " : null}
        </span>
      )
    })
  }

  return (
    <div className="event-preview-expenses">
      {!!expensesData.models.length ? (
        <>
          {expensesData.models.map(model => (
            <React.Fragment key={model.id}>
              <h5 className={"mb-0"}>{model.name}</h5>

              <div className="table-responsive">
                <table className="table table-nowrap table-border-bottom">
                  <tbody>
                    {model.expense?.items.map(expense => (
                      <tr key={expense.id}>
                        <td>
                          <ExpenseInfoCell data={expense} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          ))}

          <h5 className={"fw-bold"}>
            {t("total").toUpperCase()}
            <br />
            {renderAmounts()}
          </h5>
        </>
      ) : (
        t("no_expenses")
      )}
    </div>
  )
}

Expenses.propTypes = {
  data: PropTypes.array,
}
