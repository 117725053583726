import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import PropTypes from "prop-types"
import _ from "lodash"

import {
  BILLING_SUBSCRIPTION_TARIFF_ALIAS_BASIC,
  BILLING_SUBSCRIPTION_TARIFF_ALIAS_BUSINESS,
} from "consts"
import { billingHelper, commonHelper } from "helpers"

const billingCurrency = billingHelper.currency.getCurrency().value
const getCurrencySymbol = currency =>
  commonHelper.currency.getCurrency(currency)?.symbol

export const TariffCard = props => {
  const { t } = useTranslation()

  const price = useMemo(() => {
    let item = _.find(props.data?.prices_monthly, { currency: props.currency })

    if (!item) {
      item = {
        currency: billingCurrency,
        value: props.data?.price_monthly,
      }
    }

    return item
  }, [props.data, props.currency])

  return (
    <div
      className={classNames("pricing-block__card-block", {
        small: props.data?.alias === BILLING_SUBSCRIPTION_TARIFF_ALIAS_BASIC,
        large: props.data?.alias === BILLING_SUBSCRIPTION_TARIFF_ALIAS_BUSINESS,
        active: props.selected || props.current,
      })}
      onClick={() => props.onSelect(props.data)}
    >
      <div className="pricing-block__card-overhead">
        <span className="plans-name">{props.data?.name}</span>
        <svg
          width="18"
          height="9"
          className="pricing-block__card-overhead-icon"
        >
          <svg
            id="card_arrow"
            viewBox="0 0 18 9"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9.68674 8.3512C9.30132 8.71533 8.69868 8.71533 8.31326 8.3512L1.3016 1.7269C0.644001 1.10563 1.08368 0 1.98835 0L16.0117 0C16.9163 0 17.356 1.10563 16.6984 1.7269L9.68674 8.3512Z" />
          </svg>
        </svg>
      </div>
      <div className="pricing-block__card-wrapper">
        <div className="pricing-block__card-price">
          <span className="currency">{getCurrencySymbol(price.currency)}</span>
          <span className="price">{price.value}</span>
        </div>
        {props.data?.vat_text && (
          <div className="pricing-block__card-price-desc">
            {props.data?.vat_text}
          </div>
        )}
        <div className="pricing-block__card-billing">
          {props.isAnnual ? t("billed_annually") : t("billed_monthly")}
        </div>
        {/*<a href="#" className="pricing-block__card-free-block">Try now for FREE</a>*/}
        {props.data?.bonus_text && props.data?.bonus_text !== "" && (
          <div
            className="pricing-block__card-bonus"
            dangerouslySetInnerHTML={{ __html: props.data?.bonus_text }}
          />
        )}
      </div>
      {props.data?.benefits_text && props.data?.benefits_text !== "" && (
        <div className="pricing-block__card-wrapper">
          <div
            className="pricing-block__card-list"
            dangerouslySetInnerHTML={{ __html: props.data?.benefits_text }}
          />
        </div>
      )}
      <div className="pricing-block__card-bottom">
        <div className="pricing-block__card-inner-title">{t("modules")}</div>
        <div
          className="pricing-block__card-list"
          dangerouslySetInnerHTML={{ __html: props.data?.modules_text }}
        />
      </div>
      <a
        href="#"
        onClick={e => {
          e.preventDefault()
          props.onSelect(props.data)
        }}
        className={classNames("tariff-btn tariff-btn--select", {
          "tariff-btn--select_disabled": props.disabled,
        })}
      >
        <svg width="16" height="16" className="selected">
          <svg
            id="selected"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="16" height="16" rx="8" fill="#fff" />
            <path
              d="M4.8 8.693L7.115 10.4 11.2 4.8"
              strokeWidth="1.7"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </svg>
        {props.current ? (
          <span className="disabled">{t("subscribed")}</span>
        ) : (
          <>
            <span className="active">{t("select")}</span>
            <span className="disabled">{t("selected")}</span>
          </>
        )}
      </a>
    </div>
  )
}

TariffCard.propTypes = {
  data: PropTypes.object,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  current: PropTypes.bool,
  selected: PropTypes.bool,
  isAnnual: PropTypes.bool,
  currency: PropTypes.string,
}
