import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Drawer } from "components"
import { Container } from "reactstrap"
import { EditInvoiceDetailsForm } from "./EditInvoiceDetailsForm"
import { ValidationsUtils } from "utils"
import { useTranslation } from "react-i18next"
import { clientUpdateProfile } from "store/actions"

const EditInvoiceDetailsComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const handleCloseDrawer = () => {
    props.onClose()
  }
  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    props.onClose()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      clientUpdateProfile.update({
        id: props.client.id,
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  const onDestroy = () => {
    dispatch(clientUpdateProfile.cleanState())
  }
  return (
    <>
      <Drawer
        title={t("invoice-details")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <Container fluid>
          <EditInvoiceDetailsForm
            onSubmit={handleSubmit}
            onCancel={handleCloseDrawer}
            initialValues={{
              bank_name: props.client.bank_name,
              company_name: props.client.company_name,
              billing_address: props.client.billing_address,
              vat: props.client.vat,
              other_information: props.client.other_information,
            }}
            loading={props.loading}
            error={props.error}
          />
        </Container>
      </Drawer>
    </>
  )
}

EditInvoiceDetailsComponent.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,

  loading: PropTypes.bool,
  error: PropTypes.object,
  client: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.client.profile.data
  const { loading, error } = state.client.profile.update
  return {
    client: data,
    loading,
    error,
  }
}

export const EditInvoiceDetails = connect(mapStateToProps)(
  EditInvoiceDetailsComponent
)
