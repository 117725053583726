import { all } from "redux-saga/effects"

import addSaga from "./add/saga"
import removeSaga from "./remove/saga"

function* userSaga() {
  yield all([addSaga(), removeSaga()])
}

export default userSaga
