import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux';
import { PanelSidebar } from '../../../../../../components';
import { AgenciesSidebar } from '../../AgenciesSidebar';
import { agenciesSidebar } from "../../../../../../store/actions"

export const Sidebar = () => {
    const dispatch = useDispatch();

    const [drawerVisible, setDrawerVisible] = useState(false);

    useEffect(() => {
        return () => {
            dispatch(agenciesSidebar.cleanState());
        };
    }, [])

    const toggleDrawerVisible = (forceStatus) => {
        if (forceStatus !== undefined) {
            setDrawerVisible(forceStatus);
        } else {
            setDrawerVisible(prevStatus => !prevStatus);
        }
    };

    return (
        <PanelSidebar
            className={'agencies-panel-sidebar'}
            drawerClassName={'agencies-panel-sidebar'}
            drawerVisible={drawerVisible}
            toggleDrawerVisible={toggleDrawerVisible}
        >
            <AgenciesSidebar
                drawerVisible={drawerVisible}
                toggleDrawerVisible={toggleDrawerVisible}
            />
        </PanelSidebar>
    );
};

Sidebar.propTypes = {
}