import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import { FieldError, RecipientsContactsControl } from "components"

export const RecipientsContactsField = ({
  id,
  disabled,
  value = [],
  meta = {},
  translateParams,
  className,
  ...props
}) => {
  const { error } = meta

  const isCommonError = !Array.isArray(error)

  const { t } = useTranslation()
  const showError = Boolean(error)

  return (
    <div className={classNames("", { [className]: className })}>
      <div
        className={classNames("", {
          "is-invalid": showError,
        })}
      >
        <RecipientsContactsControl
          value={value}
          onChange={props.onChange}
          disabled={disabled}
          limit={props.limit}
          options={props.options}
          onAdd={props.onAdd}
          onRemove={props.onRemove}
          validationsErrors={isCommonError ? null : error}
        />
      </div>

      <FieldError
        error={error}
        translateParams={translateParams}
        showError={showError}
      />
    </div>
  )
}

RecipientsContactsField.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  translateParams: PropTypes.object,
  value: PropTypes.any,
  meta: PropTypes.object,
  className: PropTypes.string,
  limit: PropTypes.number,
  options: PropTypes.array,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
}
