import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import { PhotosGrid } from "./PhotosGrid"
import {
  modelPanelGeneralFetchUploadedPhotos,
  modelPanelGeneralUploadedPhotosCleanState,
  modelPanelGeneralUploadedPhotosSetPage,
  modelPanelSetModelAvatar,
  modelPanelSetModelAvatarCleanState,
} from "../../../../../../store/actions"
import { ConfirmPopup } from "../../../../../../components"
import { IMAGE_CROP_TYPE } from "consts"
import { ImageUtils } from "utils"

const { hasImageCropsByTypes } = ImageUtils
const { SQUARE, RECTANGLE } = IMAGE_CROP_TYPE

const UploadedPhotosComponent = props => {
  const { t } = useTranslation()

  const confirmModalRef = useRef(null)
  const [selectedPhoto, setSelectedPhoto] = useState(null)

  useEffect(() => {
    return () => {
      props.modelPanelGeneralUploadedPhotosCleanState()
      props.modelPanelSetModelAvatarCleanState()
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [props.page])

  const fetchData = () => {
    let params = {
      page: props.page,
      per_page: 16,
    }

    props.modelPanelGeneralFetchUploadedPhotos(props.model.id, params)
  }

  const handlePageChange = item => {
    props.modelPanelGeneralUploadedPhotosSetPage(item.selected + 1)
  }

  const selectPhoto = item => {
    if (props.setIsLoading) {
      return
    }

    setSelectedPhoto(item)

    if (confirmModalRef.current) {
      confirmModalRef.current.show()
    }
  }

  const resetSelectedPhoto = () => {
    setSelectedPhoto(null)
  }

  const confirmSelect = () => {
    if (!hasImageCropsByTypes([SQUARE, RECTANGLE], selectedPhoto)) {
      props.setNotCroppedImage(selectedPhoto)
    } else {
      props.setNotCroppedImage(null)
      props.modelPanelSetModelAvatar(selectedPhoto.id, { is_avatar: true })
    }
  }

  return (
    <>
      <h4 className={"mb-4"}>{t("select_from_uploaded_photos")}</h4>

      <PhotosGrid
        items={props.list}
        loading={props.listLoading}
        error={props.listError}
        meta={props.meta}
        page={props.page}
        onPageChange={handlePageChange}
        onPhotoSelect={selectPhoto}
        selectedPhoto={selectedPhoto}
        uploading={props.setIsLoading}
        attachmentId={props.attachmentId}
        profileAvatarSrc={props.model.avatar}
      />

      <ConfirmPopup
        ref={confirmModalRef}
        onConfirm={confirmSelect}
        onClosed={resetSelectedPhoto}
        confirmText={t("yes")}
      >
        <p className={"font-size-22"}>{t("set_as_avatar")}?</p>
      </ConfirmPopup>
    </>
  )
}

UploadedPhotosComponent.propTypes = {
  model: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,

  setIsLoading: PropTypes.bool,
  attachmentId: PropTypes.number,

  modelPanelGeneralFetchUploadedPhotos: PropTypes.func,
  modelPanelGeneralUploadedPhotosCleanState: PropTypes.func,
  modelPanelGeneralUploadedPhotosSetPage: PropTypes.func,

  modelPanelSetModelAvatar: PropTypes.func,
  modelPanelSetModelAvatarCleanState: PropTypes.func,

  setNotCroppedImage: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.model.profile
  const { list, listLoading, listError, meta, page } =
    state.modelPanel.general.avatar.photos
  const { loading, attachmentId } = state.modelPanel.general.avatar.set
  return {
    model: data,
    list,
    listLoading,
    listError,
    meta,
    page,
    setIsLoading: loading,
    attachmentId,
  }
}

const mapDispatchToProps = {
  modelPanelGeneralFetchUploadedPhotos,
  modelPanelGeneralUploadedPhotosCleanState,
  modelPanelGeneralUploadedPhotosSetPage,
  modelPanelSetModelAvatar,
  modelPanelSetModelAvatarCleanState,
}

export const UploadedPhotos = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadedPhotosComponent)
