import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  page: 1,
  meta: null,
}

const websiteMenuItemsListSlice = createSlice({
  name: "websiteMenuItemsList",
  initialState,
  reducers: {
    getList(state) {
      state.listLoading = true
      state.listError = null
    },
    getListCompleted(state, action) {
      state.listLoading = false
      state.list = action.payload.data
      state.meta = action.payload.meta
    },
    getListRejected(state, action) {
      state.listLoading = false
      state.listError = action.payload
    },
    setPage(state, action) {
      state.page = action.payload
    },
    updateListItem(state, action) {
      state.list = state.list.map(item => {
        if (item.id === action.payload.id) {
          return action.payload
        } else {
          return item
        }
      })
    },
    updateList(state, action) {
      state.list = action.payload
    },
    removeListItem(state, action) {
      state.list = state.list.filter(item => item.id !== action.payload.id)
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getList
 * @property {function} getListCompleted
 * @property {function} getListRejected
 *  @property {function} setPage
 *  @property {function} cleanState
 *  @property {function} updateListItem
 *  @property {function} updateList
 *  @property {function} removeListItem
 */
export const websiteMenuItemsList = websiteMenuItemsListSlice.actions

export default websiteMenuItemsListSlice.reducer
