export const DEPARTMENT_BOOKING = "booking"
export const DEPARTMENT_SCOUTING = "scouting"
export const DEPARTMENT_LOGISTICS = "logistics"
export const DEPARTMENT_ACCOUNTING = "accounting"

export const DEPARTMENTS_PERMISSIONS = {
  MODEL_STATEMENT_READ: "model_statement.read",
  AGENCY_STATEMENT_READ: "agency_statement.read",
  CLIENT_STATEMENT_READ: "client_statement.read",
}
