import { combineReducers } from "redux"

import list from "./list/slice"
import remove from "./remove/slice"

const requestsReducer = combineReducers({
  list,
  remove,
})

export default requestsReducer
