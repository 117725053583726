import React, { useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Draggable } from "@fullcalendar/interaction"

import { eventsCalendarHelper } from "../../../../helpers"
import { CalendarEventCategory } from "../CalendarEventCategory"
import { EventTypeFilter } from "../EventTypeFilter"

const categories = eventsCalendarHelper.eventConfig.getGeneralEvents()
const draggableSelector = "external-event"

export const EventsToolbar = props => {
  useEffect(() => {
    new Draggable(document.getElementById("external-events"), {
      itemSelector: `.${draggableSelector}`,
      // eventData: (eventEl) => (),
    })
  }, [])

  return (
    <div className={"events-calendar-toolbar"}>
      <div
        id={"external-events"}
        className={"events-calendar-toolbar__category-block"}
      >
        {categories.map((item, index) => (
          <CalendarEventCategory
            key={`event-category-${index}`}
            item={item}
            onClick={props.onEventCategoryClick}
            onDrag={props.onEventCategoryDrag}
            draggebleSelectror={draggableSelector}
          />
        ))}
      </div>
      <div className={"events-calendar-toolbar__subbar-block"}>
        <div className={"events-calendar-toolbar__filter-block"}>
          <EventTypeFilter {...props.eventTypeFilterProps} />
        </div>
        {props.subBarActionBar && (
          <div className={"events-calendar-toolbar__action-bar-block"}>
            {props.subBarActionBar}
          </div>
        )}
      </div>
    </div>
  )
}

EventsToolbar.propTypes = {
  onEventCategoryClick: PropTypes.func,
  onEventCategoryDrag: PropTypes.func,

  eventTypeFilterProps: PropTypes.object,
  subBarActionBar: PropTypes.any,
}
