import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Row } from "reactstrap";

import { Drawer } from '../../../../../../components';
import { DocumentDataLoader } from '../DocumentDataLoader';
import { DocumentForm } from '../DocumentForm';
import { modelPanelGeneralDocumentDetailsEdit, modelPanelGeneralDocumentDetailsEditCleanState } from '../../../../../../store/actions';
import { useCountryOptions } from '../../../../../../hooks';
import { ValidationsUtils } from "../../../../../../utils"

const EditDocumentComponent = (props) => {
    const { t } = useTranslation();

    const countryOptions = useCountryOptions({ visible: props.visible })

    const handleCloseDrawer = () => {
        props.onClose();
    }

    const onSubmitSuccess = (onSubmitProps) => () => {
        const { onReset } = onSubmitProps;
        onReset();

        props.onEditSuccess();
        props.onClose();
    }

    const onSubmitError = (onSubmitProps) => (errors) => {
        const { setError } = onSubmitProps;
        ValidationsUtils.setServerSideErrors(errors, setError);
    }

    const handleSubmit = (params, onSubmitProps) => {
        props.modelPanelGeneralDocumentDetailsEdit(props.id, params, onSubmitSuccess(onSubmitProps), onSubmitError(onSubmitProps));
    };

    const onDestroy = () => {
        props.modelPanelGeneralDocumentDetailsEditCleanState();
        props.onDestroy();
    }

    return (
        <React.Fragment>
            <Drawer
                title={t('document')}
                visible={props.visible}
                onClose={handleCloseDrawer}
                placement={'right'}
                className={'drawer-container'}
                destroyOnClose
                onDestroy={onDestroy}
            >
                <DocumentDataLoader id={props.id}>
                    <Container fluid>
                        <Row>
                            <DocumentForm
                                initialValues={{
                                    ...props.document,
                                    country_id: props.document?.country,
                                }}
                                loading={props.loading}
                                error={props.error}
                                onSubmit={handleSubmit}
                                onCancel={handleCloseDrawer}
                                countryOptions={countryOptions}
                                documentId={props.document?.id}
                            />
                        </Row>
                    </Container>
                </DocumentDataLoader>
            </Drawer>
        </React.Fragment>
    );
};

EditDocumentComponent.propTypes = {
    visible: PropTypes.bool,
    onEditSuccess: PropTypes.func,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onDestroy: PropTypes.func,

    document: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.object,

    modelPanelGeneralDocumentDetailsEdit: PropTypes.func,
    modelPanelGeneralDocumentDetailsEditCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { data } = state.modelPanel.general.documents.details.data;
    const { loading, error } = state.modelPanel.general.documents.details.edit;
    return {
        document: data,
        loading, error,
    };
};

const mapDispatchToProps = {
    modelPanelGeneralDocumentDetailsEdit,
    modelPanelGeneralDocumentDetailsEditCleanState,
};

export const EditDocument = connect(mapStateToProps, mapDispatchToProps)(EditDocumentComponent);