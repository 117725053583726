import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useForm, FormProvider } from "react-hook-form"
import PropTypes from "prop-types"
import { useStripe, useElements } from "@stripe/react-stripe-js"
import { Col, Row } from "reactstrap"
import { yupResolver } from "@hookform/resolvers/yup"

import { useStripeForm } from "hooks"
import {
  Button,
  CheckboxField,
  ControllerHF,
  ValidationErrors,
  StripeSepaFormSection,
  StripeSepaMandateInfo,
} from "components"
import { validationSchema } from "./validation"

export const SepaForm = props => {
  const { t } = useTranslation()

  const stripe = useStripe()
  const elements = useElements()

  const stripeInitialState = {
    iban: undefined,
  }

  const stripeForm = useStripeForm({
    initialState: stripeInitialState,
  })

  const methods = useForm({
    defaultValues: {
      ...props.initialValues,
      default: false,
    },
    resolver: yupResolver(validationSchema),
  })

  const { control, handleSubmit } = methods

  const isDisabled = useMemo(() => {
    if (!stripe || !elements) {
      return true
    }

    if (!stripeForm.isValid) {
      return true
    }

    return props.loading
  }, [stripe, elements, stripeForm.isValid, props.loading])

  const onSubmit = values => {
    if (isDisabled) {
      return
    }

    const params = {
      ...values,
      billing_details: {
        name: values.name,
        email: values.email,
        address: {
          line1: values.address,
          country: stripeForm.state?.["iban"]?.["country"],
        },
      },
      payment_method_type: props.paymentMethodType,
    }

    props.onSubmit(stripe, elements, params, {})
  }

  return (
    <FormProvider {...methods}>
      {props.error && <ValidationErrors data={props.error} />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <StripeSepaFormSection
          state={stripeForm.state}
          onStripeChange={stripeForm.onChange}
          mandate={false}
        />

        <Row>
          <Col>
            <ControllerHF
              name={"default"}
              control={control}
              component={CheckboxField}
              id={"default"}
              label={t("default")}
              wrapClass={"form-checkbox-control"}
              size={"lg"}
            />
          </Col>
        </Row>

        <div className="mt-3 mb-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={isDisabled}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>

        <div className="mt-3 mb-3">
          <StripeSepaMandateInfo />
        </div>
      </form>
    </FormProvider>
  )
}

SepaForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.any,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  paymentMethodType: PropTypes.string,
}
