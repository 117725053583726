import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Drawer } from "../../../../../../components"
import { Container, Row } from "reactstrap"
import { ValidationsUtils } from "../../../../../../utils"
import { AttachmentsForm } from "../AttachmentsForm"
import { useTranslation } from "react-i18next"
import { agencyAddAttachment } from "../../../../../../store/actions"

const AddAttachmentContainerComponent = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const handleCloseDrawer = () => {
        props.onClose()
    }
    const onSubmitSuccess = onSubmitProps => () => {
        const { reset } = onSubmitProps
        reset()
        props.onAddSuccess()
        handleCloseDrawer()
    }

    const onSubmitError = onSubmitProps => errors => {
        const { setError } = onSubmitProps
        ValidationsUtils.setServerSideErrors(errors, setError)
    }

    const handleSubmit = (params, onSubmitProps) => {
        dispatch(
          agencyAddAttachment.add({
              id: props.agency.id,
              params,
              onSuccess: onSubmitSuccess(onSubmitProps),
              onError: onSubmitError(onSubmitProps),
          })
        )
    }

    const onDestroy = () => {
        dispatch(agencyAddAttachment.cleanState())
    }
    return (
        <>
            <Drawer
                title={t("attachments")}
                visible={props.visible}
                onClose={handleCloseDrawer}
                placement={"right"}
                className={"drawer-container"}
                destroyOnClose
                onDestroy={onDestroy}
            >
                <Container fluid>
                    <Row>
                        <AttachmentsForm
                            onSubmit={handleSubmit}
                            onCancel={handleCloseDrawer}
                            initialValues={{}}
                            loading={props.loading}
                            error={props.error}
                        />
                    </Row>
                </Container>
            </Drawer>
        </>
    )
}

AddAttachmentContainerComponent.propTypes = {
    visible: PropTypes.bool,
    onAddSuccess: PropTypes.func,
    onClose: PropTypes.func,

    loading: PropTypes.bool,
    error: PropTypes.object,
    agency: PropTypes.object,
}

const mapStateToProps = state => {
    const { data } = state.agency.profile.data
    const { loading, error } = state.agency.attachments.add
    return {
        agency: data,
        loading,
        error,
    }
}

export const AddAttachment = connect(mapStateToProps)(AddAttachmentContainerComponent)
