import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"

import { Nav } from "../Nav"
import { PageHeader, RatingBadge } from "components"
import { ModelUtils } from "utils"
import { usePanelTopBarContext } from "contexts"

export const HeaderComponent = props => {
  const history = useHistory()

  const { options } = usePanelTopBarContext()

  const title = useMemo(() => {
    if (props.model && props.model.birthday) {
      return `${props.model?.name}, ${ModelUtils.getAge(props.model.birthday)}`
    }
    if (props.model) {
      return `${props.model?.name}`
    }
    return null
  }, [props.model])

  const onBack = () => {
    history.push("/models")
  }

  return (
    <div className={"panel-topbar"}>
      <PageHeader
        title={title}
        back
        onBack={onBack}
        headerLeft={
          props?.model?.avg_rating && (
            <RatingBadge
              value={props.model.avg_rating}
              size={"md"}
              wrapClassName={"ms-3"}
            />
          )
        }
      />

      <div className={"panel-topbar__nav mb-4"}>
        <div className={"panel-topbar__menu"}>
          <Nav />
        </div>

        {options.actions && (
          <div className="panel-topbar__actions">{options.actions}</div>
        )}
      </div>
    </div>
  )
}

HeaderComponent.propTypes = {
  model: PropTypes.object,
  topBarOptions: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.model.profile
  return {
    model: data,
  }
}

const mapDispatchToProps = {}

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent)
