import { all } from 'redux-saga/effects';

import addSaga from './add/saga';
import listSaga from './list/sagas';
import detailsSaga from './details/sagas';
import attachmentsSaga from './attachments/sagas';

function* documentsSaga() {
    yield all([
        addSaga(),
        listSaga(),
        detailsSaga(),
        attachmentsSaga(),
    ])
}

export default documentsSaga;