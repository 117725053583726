export const MODEL_SERVICE_CATEGORY_APARTMENT = "apartment"
export const MODEL_SERVICE_CATEGORY_GYM = "gym"
export const MODEL_SERVICE_CATEGORY_HAIRDRESSER = "hairdresser"
export const MODEL_SERVICE_CATEGORY_HOTEL = "hotel"
export const MODEL_SERVICE_CATEGORY_MAKEUP_BEAUTY = "makeup_beauty"
export const MODEL_SERVICE_CATEGORY_MULTIMEDIA = "multimedia"
export const MODEL_SERVICE_CATEGORY_PUB_BAR = "pub_bar"
export const MODEL_SERVICE_CATEGORY_RESIDENCE = "residence"
export const MODEL_SERVICE_CATEGORY_RESTAURANT = "restaurant"
export const MODEL_SERVICE_CATEGORY_TRAVEL_AGENCY = "travel_agency"
export const MODEL_SERVICE_CATEGORY_OTHER = "other"
