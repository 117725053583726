import React from "react"
import PropTypes from "prop-types"
import { IconButton } from "components"

export const AddBookButton = props => {
  return (
    <>
      <IconButton name={"add"} bg onClick={props.onClick} disabled={false} />
    </>
  )
}

AddBookButton.propTypes = {
  onClick: PropTypes.func,
}
