import React from "react"
import PropTypes from "prop-types"
import { InfoCard, InfoCardListItem } from "components"
import { useTranslation } from "react-i18next"

export const InvoiceDetailsInfoCard = props => {
  const { t } = useTranslation()
  return (
    <InfoCard
      onEdit={props.onEdit}
      editMode={props.editMode}
      title={t("invoice-details")}
      className={"h-100 agency-note-info"}
    >
      <div className={"info-list__block"}>
        <InfoCardListItem label={t("bank-name")} value={props.data.bank_name} />
        <InfoCardListItem
          label={t("bank-account-number")}
          value={props.data.bank_account_number}
        />
        <InfoCardListItem
          label={t("swift-code")}
          value={props.data.swift_code}
        />
        <InfoCardListItem label={t("vat")} value={props.data.vat} />
        <InfoCardListItem
          label={t("other-information")}
          value={props.data.other_information}
        />
      </div>
    </InfoCard>
  )
}

InvoiceDetailsInfoCard.propTypes = {
  onEdit: PropTypes.func,
  editMode: PropTypes.bool,
  data: PropTypes.object,
}
