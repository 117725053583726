import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import moment from "moment"

import {
  Button,
  ComboboxFormField,
  ControllerHF,
  InputField,
  ValidationErrors,
} from "../../index"
import { validationSchema } from "./validation"
import { RATE_TYPE_GENERAL, RATE_PERIOD_TYPE_DAY } from "../../../consts"
import { DateSection, RateSection, AgencySection } from "./FormSections"
import { commonHelper } from "../../../helpers"

export const RateForm = props => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      props.onFormDestroy && props.onFormDestroy()
    }
  }, [])

  const defaultValues = useMemo(() => {
    const {
      date,
      currency,
      value,
      total_fee,
      net_for_model,
      fashion_model_agency,
      agency_percent,
      agency_on,
      model_commission,
      ...restValues
    } = props.initialValues

    let values = {
      ...restValues,
      currency: currency || props.currency,
      value: value || value === 0 ? value.toString() : "0",
      total_fee: total_fee || total_fee === 0 ? total_fee.toString() : "0",
      model_commission:
        model_commission || model_commission === 0
          ? model_commission.toString()
          : "40",
      net_for_model:
        net_for_model || net_for_model === 0 ? net_for_model.toString() : "0",
    }

    if (date) {
      values.date = date
    }

    if (fashion_model_agency) {
      values.fashion_model_agency_id = fashion_model_agency
    }

    if (agency_percent || agency_percent === 0) {
      values.agency_percent = agency_percent.toString()
    }

    if (agency_on) {
      values.agency_on = agency_on
    }

    return values
  }, [props.initialValues])

  const methods = useForm({
    defaultValues: {
      type: RATE_TYPE_GENERAL,
      label: "",
      value: "0",
      count: 1,
      period: RATE_PERIOD_TYPE_DAY,
      total_fee: "0",
      agency_commission: 20,
      model_commission: 40,
      net_for_model: "0",
      description: "",
      ...defaultValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const { control, handleSubmit, setError, reset } = methods

  const onReset = () => {
    reset()
  }

  const onSubmit = values => {
    const { date, fashion_model_agency_id, ...restValues } = values

    let params = {
      ...restValues,
    }

    if (date) {
      params.date = moment(date).format("YYYY-MM-DD")
    }

    if (props.isEdit) {
      params.fashion_model_agency_id = fashion_model_agency_id
        ? fashion_model_agency_id?.id
        : null
      // for render in rate details table
      params.fashion_model_agency = fashion_model_agency_id
        ? fashion_model_agency_id
        : null
    } else if (!props.isEdit && fashion_model_agency_id) {
      params.fashion_model_agency_id = fashion_model_agency_id?.id
      // for render in rate details table
      params.fashion_model_agency = fashion_model_agency_id
    }

    props.onSubmit(params, { onReset, setError })
  }

  const onKeyDown = keyEvent => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  return (
    <FormProvider {...methods}>
      {props.error && (
        <ValidationErrors data={props.error} className={"mt-0"} />
      )}

      <form
        onSubmit={e => {
          e.stopPropagation()
          handleSubmit(onSubmit)(e)
        }}
        onKeyDown={e => onKeyDown(e)}
      >
        <DateSection isEdit={props.isEdit} />

        <Row>
          <Col md={12}>
            <ControllerHF
              name={"label"}
              control={control}
              component={InputField}
              id={"label"}
              label={t("label")}
              placeholder={t("label")}
            />
          </Col>

          <Col md={5}>
            <ControllerHF
              name={"currency"}
              control={control}
              component={ComboboxFormField}
              id={"currency"}
              label={t("currency")}
              placeholder={t("currency")}
              options={commonHelper.currency.getCurrencies()}
              getOptionLabel={option => option.label.toUpperCase()}
              normalize={option => (option ? option.value : null)}
              isDetermineValue
              selectProps={{
                isSearchable: false,
              }}
            />
          </Col>
        </Row>

        <RateSection isEdit={props.isEdit} />

        <Row>
          <Col md={12}>
            <ControllerHF
              name={"description"}
              control={control}
              component={InputField}
              id={"description"}
              label={t("description")}
              placeholder={t("description")}
              type={"textarea"}
            />
          </Col>
        </Row>

        {props.modelId && <AgencySection modelId={props.modelId} />}

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

RateForm.propTypes = {
  isEdit: PropTypes.bool,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  currency: PropTypes.string,
  modelId: PropTypes.number,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onFormDestroy: PropTypes.func,
}
