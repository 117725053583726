import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

export const Models = ({ data = [], loading }) => {
  const { t } = useTranslation()

  return !!data.length ? (
    <div className="table-responsive">
      <table className="table text-nowrap">
        <tbody>
          {data.map(item => (
            <tr key={item.id} className="table-border-bottom">
              <td>
                <div className={"text-nowrap"}>
                  {item.name} {item.surname}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : loading ? null : (
    t("empty")
  )
}

Models.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
}
