import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { CALENDAR_EVENT_NOTIFICATION_STATUS_EMAIL_READ as READ } from "consts"
import { EventCalendarIcon } from "../../EventCalendarIcon"

export const CalendarGeneralEventContent = ({
  title,
  subTitle,
  times,
  active,
  notificationStatus,
  priority,
  closed,
  done,
  isExecutedDate = false,
}) => {
  const renderTimes = () =>
    times.map((item, index) => (
      <div key={index}>
        {item.start} {item.end ? `- ${item.end}` : null}
      </div>
    ))

  const renderNotificationStatus = () => (
    <EventCalendarIcon
      name={"check"}
      className={"font-size-18"}
      double={notificationStatus === READ}
    />
  )

  return (
    <div
      className={classNames("calendar-event-content", {
        "text-decoration-line-through": active === false || isExecutedDate,
      })}
    >
      {(notificationStatus || done) && (
        <div className={"calendar-event-content__left-block"}>
          {notificationStatus && renderNotificationStatus()}

          {done && (
            <div className={"calendar-event-content__done-block"}>
              <EventCalendarIcon name={"done"} />
            </div>
          )}
        </div>
      )}
      <div className={"calendar-event-content__main"}>
        {title && <div>{title}</div>}
        {subTitle !== title ? <div>{subTitle}</div> : null}
        {times && !!times.length && (
          <div className={"time"}>{renderTimes()}</div>
        )}
      </div>
      {(priority || closed) && (
        <div className={"calendar-event-content__right-block"}>
          {priority && (
            <div className={"calendar-event-content__priority-block"}>
              {priority}
            </div>
          )}

          {closed && (
            <div className={"calendar-event-content__close-block"}>
              <EventCalendarIcon name={"closed"} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

CalendarGeneralEventContent.propTypes = {
  title: PropTypes.string,
  eventConfigTitle: PropTypes.string,
  subTitle: PropTypes.string,
  times: PropTypes.array,
  active: PropTypes.bool,
  notificationStatus: PropTypes.number,
  priority: PropTypes.number,
  closed: PropTypes.bool,
  done: PropTypes.bool,
  isExecutedDate: PropTypes.bool,
}
