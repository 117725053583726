import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { UncontrolledTooltip } from "reactstrap"
import { IconButton, Image } from "components"

export const BookPickedPhoto = props => {
  const { t } = useTranslation()

  return (
    <div
      className={"book-picked-photo"}
      {...props.provided?.draggableProps}
      {...props.provided?.dragHandleProps}
      ref={props.provided?.innerRef}
    >
      <div
        style={{
          backgroundImage: `url("${props.item?.photo?.attachment?.src}")`,
        }}
        className={"book-picked-photo__img"}
      />

      <div className={"book-picked-photo__actions"}>
        <IconButton
          name={"remove"}
          onClick={() => props.onRemove(props.item)}
          bg
        />
        <IconButton
          name={"cover"}
          onClick={() => props.onCoverChange(props.item)}
          bg
          wrapClass={classNames({
            "book-picked-photo__btn_is-cover": props.item.is_cover,
          })}
          id={"book-picked-photo__btn-cover"}
          disabled={props.item.is_cover}
        />
        <UncontrolledTooltip
          target={`book-picked-photo__btn-cover`}
          placement={"left"}
        >
          {t("make_cover")}
        </UncontrolledTooltip>
      </div>

      <div className={"book-picked-photo__badges-block"}>
        <div className={"book-picked-photo__order-badge"}>
          {props.index + 1}
        </div>
      </div>
    </div>
  )
}

BookPickedPhoto.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  provided: PropTypes.any,
  onRemove: PropTypes.func,
  onCoverChange: PropTypes.func,
}
