import React from 'react';
import PropTypes from "prop-types";
import { useField } from "formik"
import classNames from "classnames";
import { Input, Label } from "reactstrap";

import { commonHelper } from '../../../helpers';
import { RatingBadge } from '../../Badges';

export const RadioButton = ({
    id,
    value,
    field,
    onChange,
}) => {
    return (
        <div className={classNames('rating-radio', {})}>
            <label htmlFor={`${id}-${value}`} className={'rating-radio__icon-label'}>
                <RatingBadge value={value} />
            </label>

            <div className={'rating-radio__control'}>
                <Input
                    className={classNames('rating-radio__input', {})}
                    type="radio"
                    id={`${id}-${value}`}
                    {...field}
                    value={value}
                    checked={field.value === value}
                    onChange={(event) => onChange(event)}
                />
                <Label
                    className={classNames('rating-radio__label', {
                        'text-primary-green': field.value === value
                    })}
                    htmlFor={`${id}-${value}`}
                >
                    {commonHelper.rating.getConfig(value).label}
                </Label>
            </div>
        </div>
    );
};

export const RatingRadioGroup = ({
    id,
    label,
    normalize,
    options = [0, 1, 2, 3, 4, 5],
    onChange,
    className,
    ...props
}) => {
    const { field, form } = props;

    const { setValue } = form;

    const handleChange = (event) => {
        const value = normalize ? normalize(event.target.value) : event.target.value;

        setValue(value);

        if (onChange) {
            onChange(value);
        }
    };

    return (
        <div className={classNames('rating-radio-group', {
            [className]: className,
        })}>
            {options.map((value, index) => {
                return (
                    <RadioButton
                        key={`${id}-${index}`}
                        id={id}
                        field={field}
                        value={value}
                        index={index}
                        onChange={handleChange}
                    />
                );
            })}
        </div>
    );
};

RadioButton.propTypes = {
    id: PropTypes.any,
    item: PropTypes.any,
    field: PropTypes.object,
    value: PropTypes.any,
    onChange: PropTypes.func,
    index: PropTypes.number,
}

RatingRadioGroup.propTypes = {
    id: PropTypes.any,
    label: PropTypes.any,
    normalize: PropTypes.func,
    options: PropTypes.array,
    field: PropTypes.object,
    meta: PropTypes.object,
    form: PropTypes.object,
    onChange: PropTypes.func,
    className: PropTypes.string,
}

export const RatingRadioGroupFiled = (props) => {
    const [field, meta, form] = useField(props);

    return (
        <RatingRadioGroup field={field} meta={meta} form={form} {...props} />
    )
}