import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { Col, Alert, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import moment from "moment"

import {
  Button,
  ComboboxFormField,
  ControllerHF,
  InputField,
  DateInputField,
  CheckboxField,
} from "../../../../../../components"
import { NormalizeUtils } from "../../../../../../utils"
import { useFetchAgencies } from "../../../../../../hooks"
import { modelHelper } from "../../../../../../helpers"
import { MODEL_AGENCY_AS_TYPE_MOTHER } from "../../../../../../consts"
import { validationSchema } from "./validation"

const PERCENT_VALUES = {
  min: 0,
  max: 100,
}

export const AgencyForm = props => {
  const { t } = useTranslation()

  const { data, fetchData } = useFetchAgencies()

  const defaultValues = useMemo(() => {
    const { agency, percentage_mother, percentage_type, ...rest } =
      props.initialValues
    let result = {
      ...rest,
    }
    if (agency) {
      result.agency_id = agency
    }
    if (percentage_mother || percentage_mother === 0) {
      result.percentage_mother = percentage_mother.toString()
    }
    if (percentage_type) {
      result.percentage_type = percentage_type
    }
    return result
  }, [props.initialValues])

  const { control, handleSubmit, setError, watch, reset } = useForm({
    defaultValues: {
      agency_id: null,
      as_type: null,
      date_in: null,
      date_out: null,
      note: "",
      current_location: false,
      ...defaultValues,
    },
    resolver: yupResolver(validationSchema(PERCENT_VALUES)),
    mode: "onChange",
  })

  const watchAsType = watch("as_type")

  useEffect(() => {
    if (props.mode === "add") {
      fetchData()
    }
  }, [])

  const onSubmit = values => {
    const params = {
      ...values,
      agency_id: values.agency_id?.id,
      date_in: values.date_in
        ? moment(values.date_in).format("YYYY-MM-DD")
        : "",
      date_out: values.date_out
        ? moment(values.date_out).format("YYYY-MM-DD")
        : "",
    }

    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }

  const getAgencyOptionLabel = option => {
    let result = option.name
    const country = option?.country?.name
    if (country) {
      result = `${result} - ${country}`
    }

    return result
  }

  const [watchDateIn, watchDateOut] = watch(["date_in", "date_out"])

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={8}>
            <ControllerHF
              name={"agency_id"}
              control={control}
              component={ComboboxFormField}
              id={"agency_id"}
              label={t("agency")}
              placeholder={t("agency")}
              options={data}
              getOptionLabel={option => getAgencyOptionLabel(option)}
              getOptionValue={option => option.id}
              selectProps={{
                isClearable: true,
              }}
              disabled={props.mode === "edit"}
            />
          </Col>

          <Col md={4}>
            <ControllerHF
              name={"current_location"}
              control={control}
              component={CheckboxField}
              id={"current_location"}
              label={t("current_location")}
              wrapClass={"mb-3 form-checkbox-control"}
              size={"lg"}
            />
          </Col>
        </Row>

        <Row>
          <Col md={3}>
            <ControllerHF
              name={"as_type"}
              control={control}
              component={ComboboxFormField}
              id={"as_type"}
              label={t("model-agency-as")}
              placeholder={t("model-agency-as")}
              options={modelHelper.agency.getAgencyAsOptions()}
              selectProps={{
                isSearchable: false,
              }}
              normalize={option => (option ? option.value : null)}
              isDetermineValue
            />
          </Col>

          {watchAsType === MODEL_AGENCY_AS_TYPE_MOTHER && (
            <>
              <Col md={2}>
                <ControllerHF
                  name={"percentage_mother"}
                  control={control}
                  component={InputField}
                  id={"percentage_mother"}
                  label={t("percent")}
                  placeholder={t("percent")}
                  normalize={NormalizeUtils.onlyNumbers}
                  translateParams={{
                    min: PERCENT_VALUES.min,
                    max: PERCENT_VALUES.max,
                  }}
                  shouldUnregister
                  defaultValue={"10"}
                  // appendAccessory={[
                  //     <span key={'1'} className="input-group-text">%</span>
                  // ]}
                />
              </Col>
              <Col md={3}>
                <ControllerHF
                  name={"percentage_type"}
                  control={control}
                  component={ComboboxFormField}
                  id={"percentage_type"}
                  label={t("model-agency-on")}
                  placeholder={t("model-agency-on")}
                  options={modelHelper.agency.getAgencyProfitTypeOptions()}
                  selectProps={{
                    isSearchable: false,
                  }}
                  shouldUnregister
                  defaultValue={null}
                  normalize={option => (option !== null ? option.value : null)}
                  isDetermineValue
                />
              </Col>
            </>
          )}
        </Row>

        <Row>
          <Col md={4}>
            <ControllerHF
              name={"date_in"}
              control={control}
              component={DateInputField}
              id={"date_in"}
              label={t("date_in")}
              placeholder={"DD/MM/YYYY"}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
              openToDate={(!watchDateIn && watchDateOut) || ""}
            />
          </Col>
          <Col md={4}>
            <ControllerHF
              name={"date_out"}
              control={control}
              component={DateInputField}
              id={"date_out"}
              label={t("date_out")}
              placeholder={"DD/MM/YYYY"}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
              translateParams={{ date: t("date_in") }}
              openToDate={(!watchDateOut && watchDateIn) || ""}
            />
          </Col>
        </Row>

        <Row>
          <Col md={8}>
            <ControllerHF
              name={"note"}
              control={control}
              component={InputField}
              id={"note"}
              label={t("note")}
              placeholder={t("note")}
              type={"textarea"}
            />
          </Col>
        </Row>

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button title={"cancel"} color="light" outline onClick={onCancel} />
          </div>
        </div>
      </form>
    </>
  )
}

AgencyForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  mode: PropTypes.oneOf(["add", "edit"]),

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
