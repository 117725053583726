import {
    MODELS_PANEL_ADD_MODEL,
    MODELS_PANEL_ADD_MODEL_SUCCESS,
    MODELS_PANEL_ADD_MODEL_ERROR,
    MODELS_PANEL_ADD_MODEL_CLEAN_STATE
} from './actionTypes';

export const modelsPanelAddModel = (params, onSuccess, onError) => {
    return {
        type: MODELS_PANEL_ADD_MODEL,
        payload: { params, onSuccess, onError },
    }
};

export const modelsPanelAddModelSuccess = () => {
    return {
        type: MODELS_PANEL_ADD_MODEL_SUCCESS,
    }
};

export const modelsPanelAddModelError = (data) => {
    return {
        type: MODELS_PANEL_ADD_MODEL_ERROR,
        payload: data,
    }
};

export const modelsPanelAddModelCleanState = () => {
    return { type: MODELS_PANEL_ADD_MODEL_CLEAN_STATE };
};