import { takeLatest, fork, put, all, call } from "redux-saga/effects"

import i18n from "../../../../i18n"
import { API } from "../../../../api"
import { RequestUtils } from "../../../../utils"

import { clientsSidebar } from "./slice"

export function* fetchList({ payload: { params } }) {
  try {
    let requestParams = {
      includes: [],
    }
    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response = yield call(API.client.getClients, requestParams)

    const { data } = response

    yield put(clientsSidebar.getListCompleted(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(clientsSidebar.getListRejected(message))
  }
}

export function* watchFetchList() {
  yield takeLatest(clientsSidebar.getList, fetchList)
}

function* clientsSidebarSaga() {
  yield all([fork(watchFetchList)])
}

export default clientsSidebarSaga
