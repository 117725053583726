import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

export const PublishMediaBox = ({ children }) => {
  const { t } = useTranslation()
  return (
    <div className={"share-box"}>
      <h6 className={"share-box__title"}>{t("publish")}</h6>
      <div className={"share-box__icons-container "}>{children}</div>
    </div>
  )
}

PublishMediaBox.propTypes = {
  children: PropTypes.node,
}
