import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { calendarEventEventsEmailSend } from "./slice"

function* sendEmail({ payload: { params, onSuccess, onError } }) {
  try {
    const { id } = params

    const response = yield call(API.model.eventsEmailSend, id, params)

    const { data } = response

    yield put(calendarEventEventsEmailSend.sendSuccess())

    toastr.success(i18n.t("send.success"))

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      calendarEventEventsEmailSend.sendError({
        errors,
        message,
      })
    )

    toastr.error(message)

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchSendEmail() {
  yield takeEvery(calendarEventEventsEmailSend.send, sendEmail)
}

function* sendSaga() {
  yield all([fork(watchSendEmail)])
}

export default sendSaga
