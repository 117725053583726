import React from "react"
import PropTypes from "prop-types"

import { CalendarDayHeaderContent } from "../../../EventsCalendar"

export const ModelCalendarDayHeaderContent = ({
  info,
  contentData,
  onEmailSend,
  onPrint,
}) => {
  const onEmail = e => {
    e.preventDefault()

    onEmailSend && onEmailSend(info.date)
  }

  const handlePrint = e => {
    e.preventDefault()

    onPrint && onPrint(info.date)
  }

  const modelContentData = {
    rightSideBar: (
      <div className={"d-flex align-self-center"}>
        <div className={""} onClick={onEmail}>
          <i className={"bx bx-envelope text-secondary font-size-20"} />
        </div>

        <div className={"ms-2 d-none d-md-block"} onClick={handlePrint}>
          <i className={"bx bx-printer text-secondary font-size-20"} />
        </div>
      </div>
    ),
  }

  return (
    <>
      <CalendarDayHeaderContent {...contentData} {...modelContentData} />
    </>
  )
}

ModelCalendarDayHeaderContent.propTypes = {
  info: PropTypes.object,
  contentData: PropTypes.object,
  onEmailSend: PropTypes.func,
  onPrint: PropTypes.func,
}
