import { MODEL_LOGISTICS_TYPE } from "consts"

export const accommodationDefaultValues = {
  date_in: null,
  date_out: null,
  room: "",
  country_id: null,
  state: "",
  town: "",
  street: "",
  note: "",
  expenses: [],
}

export const travelDefaultValues = {
  service_details: "",
  travel_by: null,
  departure_place: "",
  departure_date: null,
  departure_hour: null,
  arrival_place: "",
  arrival_date: null,
  arrival_hour: null,
  model_notes: "",
  agency_notes: "",
  expenses: [],
}

export const driverDefaultValues = {
  service_details: "",
  model_notes: "",
  agency_notes: "",
  workdays_from: null,
  workdays_to: null,
  expenses: [],
}

export const basicDefaultValues = {
  service_details: "",
  workdays_from: null,
  workdays_to: null,
  expenses: [],
}

export const getLogisticsDefaultValues = logisticType => {
  switch (logisticType) {
    case MODEL_LOGISTICS_TYPE.ACCOMMODATION:
      return { ...accommodationDefaultValues }

    case MODEL_LOGISTICS_TYPE.TRAVEL:
      return { ...travelDefaultValues }

    case MODEL_LOGISTICS_TYPE.DRIVER:
      return { ...driverDefaultValues }

    default:
      return {
        ...basicDefaultValues,
      }
  }
}
