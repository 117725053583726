import {
    MODEL_PANEL_PHOTOS_PHOTO_DETAILS_EDIT_PHOTO_UPDATE,
    MODEL_PANEL_PHOTOS_PHOTO_DETAILS_EDIT_PHOTO_UPDATE_SUCCESS,
    MODEL_PANEL_PHOTOS_PHOTO_DETAILS_EDIT_PHOTO_UPDATE_ERROR,
    MODEL_PANEL_PHOTOS_PHOTO_DETAILS_EDIT_PHOTO_UPDATE_CLEAN_STATE,
} from './actionTypes';

export const modelPanelPhotosPhotoDetailsEditPhotoUpdate = (attachmentId, params, onSuccess, onError) => {
    return {
        type: MODEL_PANEL_PHOTOS_PHOTO_DETAILS_EDIT_PHOTO_UPDATE,
        payload: { attachmentId, params, onSuccess, onError },
    }
};

export const modelPanelPhotosPhotoDetailsEditPhotoUpdateSuccess = () => {
    return {
        type: MODEL_PANEL_PHOTOS_PHOTO_DETAILS_EDIT_PHOTO_UPDATE_SUCCESS,
    }
};

export const modelPanelPhotosPhotoDetailsEditPhotoUpdateError = (data) => {
    return {
        type: MODEL_PANEL_PHOTOS_PHOTO_DETAILS_EDIT_PHOTO_UPDATE_ERROR,
        payload: data,
    }
};

export const modelPanelPhotosPhotoDetailsEditPhotoUpdateCleanState = () => {
    return { type: MODEL_PANEL_PHOTOS_PHOTO_DETAILS_EDIT_PHOTO_UPDATE_CLEAN_STATE };
};