import React from "react"
import PropTypes from "prop-types"
import { Col, Alert, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { Button, ControllerHF, InputField } from "components"
import { validationSchema } from "./validation"
import { RecipientsField } from "./RecipientsField"

export const EventInvoiceSendEmailForm = props => {
  const { t } = useTranslation()

  const { control, handleSubmit, setError } = useForm({
    defaultValues: {
      subject: "",
      message: "",
      emails: [],
      ...props.initialValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = values => {
    let params = {
      ...values,
    }

    props.onSubmit(params, { setError })
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={12}>
            <ControllerHF
              name={"subject"}
              control={control}
              component={InputField}
              id={"subject"}
              label={t("subject")}
              placeholder={t("subject")}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <ControllerHF
              name={"message"}
              control={control}
              component={InputField}
              id={"message"}
              label={t("message")}
              placeholder={t("message")}
              type={"textarea"}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          <Col>
            <ControllerHF
              name={"emails"}
              control={control}
              component={RecipientsField}
              id={"emails"}
              placeholder={t("recipients")}
              limit={20}
              select={Boolean(props.clientId)}
              clientId={props.clientId}
            />
          </Col>
        </Row>

        <div className="mt-3 pt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.send"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </>
  )
}

EventInvoiceSendEmailForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  clientId: PropTypes.number,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
