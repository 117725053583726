import { takeEvery, fork, put, all, select } from "redux-saga/effects"

import { socketOnChatSeen } from "./actions"
import { chatConversationMessagesList } from "../../../chat/conversation/messages/list/slice"
import {
  selectConversationGroupChatId,
  selectConversationParticipantId,
  selectConversationIsGroup,
} from "../../../chat/conversation/data/selectors"

export const participantIdState = state =>
  selectConversationParticipantId(state)
export const groupChatIdState = state => selectConversationGroupChatId(state)
export const isGroupState = state => selectConversationIsGroup(state)

function* onChatSeen({ payload: { data } }) {
  const { chat_id, user } = data

  const participantId = yield select(participantIdState)
  const groupChatId = yield select(groupChatIdState)
  const isGroup = yield select(isGroupState)

  const isCurrentChat =
    (isGroup && chat_id === groupChatId) ||
    (!isGroup && user.id === participantId)

  if (isCurrentChat) {
    // mark messages as seen
    yield put(chatConversationMessagesList.markMessagesSeen())
  }
}

export function* watchOnChatSeen() {
  yield takeEvery(socketOnChatSeen, onChatSeen)
}

function* seenSaga() {
  yield all([fork(watchOnChatSeen)])
}

export default seenSaga
