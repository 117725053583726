import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { IMPORT_ENTITY_TYPE } from "consts"
import { Form } from "./Form"
import { importSubmit } from "store/actions"
import { useSubmitHandler } from "hooks"

const { MODELS, AGENCIES, CLIENTS } = IMPORT_ENTITY_TYPE

export const ImportEntityStartImport = ({ type, active, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { loading, error } = useSelector(state => state.import.submit)

  useEffect(() => {
    return () => {
      dispatch(importSubmit.cleanState())
    }
  }, [])

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        importSubmit.submit({
          params: {
            ...params,
            type,
          },
          onSuccess,
          onError,
        })
      )
    },
    () => {
      props.onSubmitSuccess()
    }
  )

  return (
    <>
      <Form
        type={type}
        loading={loading}
        onSubmit={handleSubmit}
        error={error}
        importActive={active}
      />
    </>
  )
}

ImportEntityStartImport.propTypes = {
  type: PropTypes.oneOf([MODELS, AGENCIES, CLIENTS]),
  active: PropTypes.bool,
  onSubmitSuccess: PropTypes.func,
}
