import * as Yup from "yup"

export const validationSchema = Yup.object().shape({
  type: Yup.mixed().required("field.error.required"),
  // description: Yup.string().nullable().required("field.error.required"),
  value: Yup.number()
    .nullable(true)
    .transform((v, o) => (o === "" ? null : v))
    .required("field.error.required"),
  currency: Yup.string().nullable().required("field.error.required"),
})
