import React from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"
import { useTranslation } from "react-i18next"

import { PanelTopBarProvider } from "contexts"
import { Header } from "./Header"
import { PageIntro } from "../PageIntro"

export const AnalyticsPanelLayout = props => {
  const { t } = useTranslation()

  return (
    <PageIntro>
      <PanelTopBarProvider>
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <Header />
              <div>{props.children}</div>
            </Container>
          </div>
        </React.Fragment>
      </PanelTopBarProvider>
    </PageIntro>
  )
}

AnalyticsPanelLayout.propTypes = {
  children: PropTypes.any,
  topBarOptions: PropTypes.object,
}
