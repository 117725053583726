export async function canvasPreview(image, canvas, crop) {
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  const ctx = canvas.getContext("2d")
  const pixelRatio = window.devicePixelRatio

  canvas.width = +(crop.width * pixelRatio * scaleX).toFixed(0)
  canvas.height = +(crop.height * pixelRatio * scaleY).toFixed(0)

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
  ctx.imageSmoothingQuality = "high"

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  )
}
