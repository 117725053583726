import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardBody,
  Alert,
  Col,
  Button as StrapButton,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { Link } from "react-router-dom"

import {
  FormikTextInputFiled as TextInputFiled,
  Button,
} from "../../../../components"
import { profileRemoveUser, profileRemoveUserCleanState } from "store/actions"

const RemoveProfileComponent = ({
  loading,
  error,
  profileRemoveUser,
  profileRemoveUserCleanState,
}) => {
  const { t } = useTranslation()

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    return () => {
      profileRemoveUserCleanState()
    }
  }, [])

  const onSubmit = (values, onSubmitProps) => {
    profileRemoveUser(onSubmitProps)
  }

  return (
    <Card>
      <CardHeader className={"bg-dark"}>
        <CardTitle className={"text-white mb-0"}>{t("deactivation")}</CardTitle>
      </CardHeader>
      <CardBody>
        {!visible ? (
          <div>
            <Link
              to="#"
              onClick={e => {
                e.preventDefault()
                setVisible(true)
              }}
              className="text-secondary"
            >
              {t("profile.remove")}?
            </Link>
          </div>
        ) : (
          <>
            <CardSubtitle className="mb-3">
              {t("profile.remove.type-delete")}
            </CardSubtitle>

            {error && <Alert color="danger">{error.message}</Alert>}

            <Formik
              initialValues={{
                confirmation: "",
              }}
              validationSchema={Yup.object({
                confirmation: Yup.string().test(
                  "confirmation",
                  "field.error.invalid",
                  value => {
                    return value && value === "DELETE"
                  }
                ),
              })}
              onSubmit={onSubmit}
              validateOnChange={false}
              validateOnBlur={true}
              validateOnMount={true}
              enableReinitialize={true}
            >
              {({ isSubmitting, ...rest }) => {
                return (
                  <Form className="form-horizontal" noValidate={true}>
                    <Col lg={6}>
                      <TextInputFiled
                        name="confirmation"
                        label={t("field.label.confirmation")}
                        placeholder={t("field.placeholder.confirmation")}
                        id={"confirmation"}
                        type="text"
                      />
                    </Col>

                    <div className="mt-3">
                      <div className="button-items">
                        <StrapButton
                          type="button"
                          color="light"
                          outline
                          className="waves-effect"
                          disabled={isSubmitting || loading}
                          onClick={() => setVisible(false)}
                        >
                          {t("cancel")}
                        </StrapButton>

                        <Button
                          submit
                          color="danger"
                          title={"profile.remove"}
                          disabled={isSubmitting || loading || !rest.isValid}
                          loading={loading}
                        />
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </>
        )}
      </CardBody>
    </Card>
  )
}

RemoveProfileComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,

  profileRemoveUser: PropTypes.func,
  profileRemoveUserCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, error } = state.profile.remove
  return {
    loading,
    error,
  }
}

const mapDispatchToProps = {
  profileRemoveUser,
  profileRemoveUserCleanState,
}

export const RemoveProfile = connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveProfileComponent)
