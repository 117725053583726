import React from 'react';
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { Label, Input, FormFeedback } from "reactstrap"
import classNames from "classnames";

export const Checkbox = ({
    id,
    label,
    reference,
    children,
    normalize,
    color = 'primary-green',
    wrapClass,
    size,
    ...props
}) => {
    const { field, meta, form } = props;

    const { touched, error } = meta;
    const { setValue } = form;

    const { t } = useTranslation();
    const showError = touched && Boolean(error);

    //eslint-disable-next-line
    const onChange = (event) => {
        const checked = normalize ? normalize(event.target.checked) : event.target.checked;

        setValue(checked);

        if (props.onChange) {
            props.onChange(checked);
        }
    };

    const renderLabel = (label) => {
        if (label) {
            if (typeof label === 'string') {
                return t(label)
            } else return label;
        } else return <></>;
    };

    return (
        <div className={classNames('mb-3', {
            [wrapClass]: wrapClass,
        })}>
            <div className={classNames('form-check', {
                'is-invalid': showError,
                [`form-check-${color}`]: color,
                [`form-check_size_${size}`]: size,
            })}>
               <div className={classNames('form-check-wrap', {'is-invalid': showError})}>
                   <Input
                       id={id}
                       {...field}
                       type="checkbox"
                       className={classNames('form-check-input', {
                           '': showError,
                       })} // 'is-invalid'
                       checked={field.value}
                       onChange={(e) => onChange(e)}
                   />
                   <Label
                       className="form-check-label"
                       htmlFor={id}
                   >
                       {renderLabel(label)}
                   </Label>
               </div>

                <FormFeedback invalid={showError ? error : null }>
                    { t(error, props.translateParams ? { ...props.translateParams } : {}) }
                </FormFeedback>
            </div>
        </div>
    );
};

Checkbox.propTypes = {
    id: PropTypes.string,
    label: PropTypes.any,
    reference: PropTypes.any,
    children: PropTypes.any,
    normalize: PropTypes.func,
    disabled: PropTypes.bool,
    field: PropTypes.object,
    meta: PropTypes.object,
    form: PropTypes.object,
    onChange: PropTypes.func,
    translateParams: PropTypes.object,
    color: PropTypes.string,
    wrapClass: PropTypes.string,
    size: PropTypes.string,
}

export const CheckboxFiled = (props) => {
    const [field, meta, form] = useField(props);

    return (
        <Checkbox field={field} meta={meta} form={form} {...props} />
    )
}
