import React from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"

import { Header } from "./Header"
import { PageIntro } from "../PageIntro"

export const Layout = props => {
  return (
    <React.Fragment>
      <PageIntro>
        <div className="page-content">
          <Container fluid>
            <Header />

            {props.children}
          </Container>
        </div>
      </PageIntro>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.any,
}
