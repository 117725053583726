import React from "react"
import { Row } from "reactstrap"
import { MegaTagTitle } from "components"
import { RequestsContainer } from "./components"

export const Requests = () => {
  return (
    <div className={"website-requests-panel"}>
      <MegaTagTitle title={"title.website.requests"} />
      <Row>
        <RequestsContainer />
      </Row>
    </div>
  )
}
