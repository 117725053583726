import {
    MODEL_BOOKS_REMOVE_ITEM,
    MODEL_BOOKS_REMOVE_ITEM_SUCCESS,
    MODEL_BOOKS_REMOVE_ITEM_ERROR,
    MODEL_BOOKS_REMOVE_ITEM_CLEAN_STATE,
} from './actionTypes';

export const modelBooksRemoveBook = (id, onSuccess) => {
    return {
        type: MODEL_BOOKS_REMOVE_ITEM,
        payload: { id, onSuccess },
    }
};

export const modelBooksRemoveBookSuccess = (id) => {
    return {
        type: MODEL_BOOKS_REMOVE_ITEM_SUCCESS,
        payload: { id },
    }
};

export const modelBooksRemoveBookError = (id) => {
    return {
        type: MODEL_BOOKS_REMOVE_ITEM_ERROR,
        payload: { id },
    }
};

export const modelBooksRemoveBookCleanState = () => {
    return { type: MODEL_BOOKS_REMOVE_ITEM_CLEAN_STATE };
};