import {
    MODEL_EDIT_VIDEO,
    MODEL_EDIT_VIDEO_SUCCESS,
    MODEL_EDIT_VIDEO_ERROR,
    MODEL_EDIT_VIDEO_CLEAN_STATE,
} from './actionTypes';

export const modelEditVideo = (id, params, onSuccess, onError) => {
    return {
        type: MODEL_EDIT_VIDEO,
        payload: { id, params, onSuccess, onError },
    }
};

export const modelEditVideoSuccess = () => {
    return {
        type: MODEL_EDIT_VIDEO_SUCCESS,
    }
};

export const modelEditVideoError = (data) => {
    return {
        type: MODEL_EDIT_VIDEO_ERROR,
        payload: data,
    }
};

export const modelEditVideoCleanState = () => {
    return { type: MODEL_EDIT_VIDEO_CLEAN_STATE };
};