import React from "react"
import { Row, Col } from "reactstrap"
import { MegaTagTitle } from "../../../../../components"
import { CreateWebsiteContainer } from "../../components"

export const CreateWebsitePage = () => {
  return (
    <div className={"create-website"}>
      <MegaTagTitle title={"title.website.create-website"} />
      <Row>
        <Col>
          <CreateWebsiteContainer />
        </Col>
      </Row>
    </div>
  )
}
