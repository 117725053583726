import { all } from 'redux-saga/effects';


import agenciesSidebarSaga from "./agenciesSidebar/saga"

function* panelSaga() {
  yield all([
    agenciesSidebarSaga(),
  ])
}

export default panelSaga;