import { combineReducers } from "redux"

import list from "./list/slice"
import data from "./data/slice"

const tariffReducer = combineReducers({
  list,
  data,
})

export default tariffReducer
