import React, { useEffect, useState } from 'react'

import { requestHelper } from "../../helpers"

export const useCountryOptions = ({ visible }) => {
    const [countryOptions, setCountryOptions] = useState([]);

    useEffect(() => {
        if (visible) {
            fetchCountries();
        }
        return () => {
            if (visible) {
                setCountryOptions([]);
            }
        }
    }, [visible]);

    const fetchCountries = async () => {
        try {
            const response = await requestHelper.staticData.getCountries({ pagination: false });
            const { data } = response;

            setCountryOptions(data.data)
        } catch (e) {
        }
    };

    return countryOptions;
};