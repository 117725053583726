import { combineReducers } from "redux"

import data from "./data/slice"
import update from "./update/slice"

const permissionsReducer = combineReducers({
  data,
  update,
})

export default permissionsReducer
