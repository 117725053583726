import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

import { Drawer as DrawerComponent } from '../Drawer';

export const Drawer = (props) => {
    const onHandleClick = (e) => {
        e.preventDefault();
        props.toggleVisible();
    }

    const handler = () => (
        <div className="drawer-handle">
            <i className={classNames('mdi font-size-18', {
                'mdi-magnify': !props.visible,
                'font-size-20 mdi-close': props.visible,
            })}/>
        </div>
    )

    return (
        <DrawerComponent
            visible={props.visible}
            onClose={() => props.toggleVisible(false)}
            onHandleClick={onHandleClick}
            placement={'right'}
            className={classNames('panel-sidebar-drawer', {
                [props.className]: props.className,
            })}
            handler={handler()}
            drawerProps={{
                // getContainer: props.container,
            }}
        >
            {props.children}
        </DrawerComponent>
    );
};

Drawer.propTypes = {
    children: PropTypes.any,
    visible: PropTypes.bool,
    toggleVisible: PropTypes.func,
    container: PropTypes.any,
    className: PropTypes.string,
}