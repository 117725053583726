import { useTranslation } from "react-i18next"
import { useFetchData } from "hooks"
import { API } from "api"
import toastr from "toastr"

export const useCalendarEventPayment = () => {
  const { t } = useTranslation()

  const { fetchData } = useFetchData()

  const onPayment = async ({ id }) => {
    try {
      const request = API.calendar.eventPayment({
        id,
      })

      const result = await fetchData({
        request,
      })

      toastr.success(t("paid.success"))

      return result
    } catch (error) {
      toastr.error(error?.message || t("error"))

      throw error
    }
  }

  return {
    onPayment,
  }
}
