import React from 'react';
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next';

import { PackageDataGrid } from '../../../../../PackageDataGrid';
import { VideoOverviewCard } from "../../../../../../Videos";
import { IconButton } from "../../../../../../Buttons";

export const VideosGrid = (props) => {
    const { t } = useTranslation();

    return (
        <PackageDataGrid
            className={'package-data-picker-grid_videos'}
            items={props.videos}
            empty={t('no_videos')}
            renderItem={(item) => (
                <VideoOverviewCard
                    iframeUrl={item.iframe_url}
                    playerRef={el => props.playersRef.current[item.id] = el}
                    title={item.title}
                    description={item.description}
                    actions={(
                        <IconButton name={'remove'} onClick={() => props.onRemove(item)} bg disabled={false} />
                    )}
                />
            )}
        />
    );
};

VideosGrid.propTypes = {
    videos: PropTypes.array,
    playersRef: PropTypes.any,
    onRemove: PropTypes.func,
}