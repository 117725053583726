import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Header } from "./Header"
import { Info } from "./Info"
import { Summary } from "./Summary"
import { Footer } from "./Footer"

export const InvoiceDetailsCard = props => {
  const { t } = useTranslation()

  return (
    <div className={"invoice-details-card"}>
      <Header data={props.data} />
      <hr />
      <Info data={props.data} />
      <Summary data={props.data} />
      <Footer />
    </div>
  )
}

InvoiceDetailsCard.propTypes = {
  data: PropTypes.object,
}
