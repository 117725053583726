import React, { createContext, useContext, useState } from "react"
import PropTypes from "prop-types"

const initialConfig = {
  visible: false,
  onStart: null,
}

const PanelLayoutIntroContext = createContext({
  config: {},
  setConfig: () => {},
  updateConfig: (data = {}) => {},
  resetConfig: () => null,
})

const PanelLayoutIntroProvider = ({ children }) => {
  const [config, setConfig] = useState(initialConfig)

  const updateConfig = (data = {}) => {
    setConfig(prevState => ({
      ...prevState,
      ...data,
    }))
  }

  const resetConfig = () => {
    setConfig(initialConfig)
  }

  return (
    <PanelLayoutIntroContext.Provider
      value={{ config, setConfig, updateConfig, resetConfig }}
    >
      {children}
    </PanelLayoutIntroContext.Provider>
  )
}

PanelLayoutIntroProvider.propTypes = {
  children: PropTypes.any,
}

const usePanelLayoutIntroContext = () => useContext(PanelLayoutIntroContext)

export { PanelLayoutIntroProvider, usePanelLayoutIntroContext }
