import React from 'react'

import { useSelection } from "./useSelection"

export const useSelectionRegular = ({
    initialValues = [],
    multi = true,
}) => {
    const selection = useSelection({ initialValues, multi });

    const { selectionValue, selectionSetValue } = selection;

    const addSelectedItems = (values) => {
        const result = [...selectionValue, ...values.filter((item) => selectionValue.indexOf(item) < 0)];
        selectionSetValue(result);
    }

    const unSelect = (value) => {
        const selected = selectionValue.filter(i => i !== value);
        selectionSetValue(selected);
    }

    const toggleSelect = (value) => {
        const selected = selectionValue.includes(value) ? selectionValue.filter(i => i !== value) : [...selectionValue, value];
        selectionSetValue(selected);
    };

    return {
        ...selection,
        selectionAddSelectedItems: addSelectedItems,
        selectionRemoveSelected: unSelect,
        selectionToggleSelect: toggleSelect,
    };
};