import React from 'react'
import PropTypes from "prop-types"
import { useSelector, useDispatch } from 'react-redux';
import _ from "lodash"

import {
    draftPackageDataPickerToggleBook,
    draftPackageDataPickerRemoveBook,
    draftPackageDataPickerUpdateBook,
    draftPackageDataPickerToggleVideo,
    draftPackageDataPickerRemoveVideo,
    draftPackageDataPickerUpdateVideo,
    draftPackageDataPickerCleanValue,
} from '../../../store/actions'

export const useDraftPackageDataPicker = (props) => {
    const dispatch = useDispatch()
    const { books, videos } = useSelector((state) => state.package.draft.data.dataPicker)

    const onBookToggle = (book) => {
        const data = {
            ...book,
        }

        dispatch(draftPackageDataPickerToggleBook(data))
    };

    const onBookRemove = (book) => {
        dispatch(draftPackageDataPickerRemoveBook(book.id))
    };

    const updateBook = (id, book) => {
        dispatch(draftPackageDataPickerUpdateBook(id, book));
    };

    const onVideoToggle = (video) => {
        const data = {
            ...video,
        }

        dispatch(draftPackageDataPickerToggleVideo(data))
    };

    const onVideoRemove = (video) => {
        dispatch(draftPackageDataPickerRemoveVideo(video.id))
    };

    const updateVideo = (id, video) => {
        dispatch(draftPackageDataPickerUpdateVideo(id, video));
    };

    const isBookPicked = (id) => {
        return !!_.find(books, { id: id });
    }

    const isVideoPicked = (id) => {
        return !!_.find(videos, { id: id });
    }

    const resetValue = () => {
        dispatch(draftPackageDataPickerCleanValue());
    }

    return {
        pickedBooks: books,
        pickedVideos: videos,
        onBookToggle,
        onBookRemove,
        updateBook,
        onVideoToggle,
        onVideoRemove,
        updateVideo,
        isBookPicked,
        isVideoPicked,
        pickerResetValue: resetValue,
    };
};

useDraftPackageDataPicker.propTypes = {
}