import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { connect } from "react-redux"

import { CalendarContainer } from "./components"
import { MegaTagTitle } from "../../components"

const CalendarComponent = props => {
  return (
    <>
      <MegaTagTitle title={"title.administration"} />

      <Row>
        <Col>
          <CalendarContainer />
        </Col>
      </Row>
    </>
  )
}

CalendarComponent.propTypes = {}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const Calendar = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarComponent)
