/* eslint react/prop-types: off */
import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { ReactTableView, Pagination, IconButton } from "components"
import { DateUtils } from "utils"
import { Recipients } from "./Recipients"

import * as HOC from "HOC"

const TableDataLoading = HOC.withTableDataLoading()

export const StatisticsList = props => {
  const { t } = useTranslation()

  const data = useMemo(() => {
    return props.list
  }, [props.list])

  const [columns, setColumns] = useState([
    {
      Header: t("send_date_and_time"),
      accessor: "created_at",
      id: "created_at",
      Cell: ({ value }) => {
        return value ? DateUtils.utcToLocale(value, "DD/MM/YYYY HH:mm") : "-"
      },
    },
    {
      Header: t("receivers"),
      accessor: "recipients",
      id: "recipients",
      Cell: ({ row: { original }, value }) => <Recipients data={value} />,
    },
    {
      Header: t("title"),
      accessor: "title",
      id: "title",
      Cell: ({ row: { original }, value }) => original?.sharable?.title || "-",
    },
    {
      Header: t("models"),
      accessor: "models",
      id: "models",
      Cell: ({ row: { original }, value }) => {
        return original?.sharable?.models || "-"
      },
    },
    {
      Header: t("opened"),
      accessor: "opened_at",
      id: "opened_at",
      Cell: ({ value }) => {
        return value ? DateUtils.utcToLocale(value, "DD/MM/YYYY HH:mm") : "-"
      },
    },
    {
      Header: t("clicks"),
      accessor: "pages",
      id: "pages",
      Cell: ({ row: { original }, value }) => (
        <a
          href={"#"}
          onClick={e => {
            e.preventDefault()
            props.onClicksDetails(original)
          }}
        >
          {t("see_details")}
        </a>
      ),
    },
    {
      Header: "",
      id: "actions",
      Cell: ({ row: { original } }) => (
        <div className={"text-end text-nowrap"}>
          <IconButton
            name={"view"}
            onClick={() => props.onView(original?.sharable)}
            bg
            disabled={false}
            color={"text-white"}
            bgColor={"bg-dark"}
          />
        </div>
      ),
    },
  ])

  return (
    <TableDataLoading
      isLoading={props.loading}
      isFailed={props.error}
      error={props.error}
    >
      <ReactTableView isLoading={props.loading} columns={columns} data={data} />

      <Pagination data={props.meta} onPageChange={props.onPageChange} />
    </TableDataLoading>
  )
}

StatisticsList.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,

  onPageChange: PropTypes.func,
  onView: PropTypes.func,
  onClicksDetails: PropTypes.func,
}
