import {
  MODEL_PANEL_PHOTOS_FETCH_LIST,
  MODEL_PANEL_PHOTOS_FETCH_LIST_SUCCESS,
  MODEL_PANEL_PHOTOS_FETCH_LIST_ERROR,
  MODEL_PANEL_PHOTOS_LIST_CLEAN_STATE,
  MODEL_PANEL_PHOTOS_LIST_SET_PAGE,
  MODEL_PANEL_PHOTOS_LIST_UPDATE_ITEM,
} from "./actionTypes"

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  page: 1,
  meta: null,
}

const data = (state = initialState, action) => {
  switch (action.type) {
    case MODEL_PANEL_PHOTOS_FETCH_LIST:
      state = {
        ...state,
        listLoading: true,
        listError: null,
      }
      break

    case MODEL_PANEL_PHOTOS_FETCH_LIST_SUCCESS:
      state = {
        ...state,
        listLoading: false,
        list: action.payload.data,
        meta: action.payload.meta,
      }
      break

    case MODEL_PANEL_PHOTOS_FETCH_LIST_ERROR:
      state = {
        ...state,
        listLoading: false,
        listError: action.payload,
      }
      break

    case MODEL_PANEL_PHOTOS_LIST_CLEAN_STATE:
      state = {
        ...state,
        ...initialState,
      }
      break

    case MODEL_PANEL_PHOTOS_LIST_SET_PAGE:
      state = {
        ...state,
        page: action.payload,
      }
      break
    case MODEL_PANEL_PHOTOS_LIST_UPDATE_ITEM:
      state = {
        ...state,
        list: state.list.map(photo => {
          if (photo.id === action.payload.id) {
            return action.payload
          } else {
            return photo
          }
        }),
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default data
