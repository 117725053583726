import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import i18n from '../../../../../../i18n';

import { MODEL_PANEL_GENERAL_DOCUMENT_UPLOAD_ATTACHMENT } from './actionTypes';
import {
    modelPanelGeneralDocumentUploadAttachmentSuccess,
    modelPanelGeneralDocumentUploadAttachmentError,
} from './actions';

import { API } from '../../../../../../api';

function* upload({ payload: { params, onSuccess, onError } }) {
    try {
        const { id, file } = params;
        const response = id
            ? yield call(API.model.uploadFileForDocument, id, { file })
            : yield call(API.model.uploadFileForNewDocument, { file });

        const { data } = response;

        yield put(modelPanelGeneralDocumentUploadAttachmentSuccess());

        if (onSuccess) {
            onSuccess(data?.data);
        }
    } catch (error) {
        const errors = error.response?.data?.errors || {};
        const message = error.response?.data?.message || i18n.t('error');

        yield put(modelPanelGeneralDocumentUploadAttachmentError());

        if (onError) {
            onError(errors);
        }
    }
}

export function* watchUpload() {
    yield takeEvery(MODEL_PANEL_GENERAL_DOCUMENT_UPLOAD_ATTACHMENT, upload)
}

function* uploadSaga() {
    yield all([
        fork(watchUpload),
    ]);
}

export default uploadSaga;