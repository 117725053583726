import { all } from 'redux-saga/effects';

import editSaga from './edit/saga';

function* profileSaga() {
    yield all([
        editSaga(),
    ])
}

export default profileSaga;