import React, { useEffect } from 'react';

import { usePanelTopBarContext } from "../../contexts"

export const usePanelTopBarOptions = (options = {}) => {
    const { setOptions } = usePanelTopBarContext();

    useEffect(() => {
        return () => {
            setOptions({});
        };
    }, []);

    useEffect(() => {
        setOptions(options);
    }, [options]);
};