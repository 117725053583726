import React from "react"
import PropTypes from "prop-types"
import Menu, { MenuItem } from "rc-menu"
import classNames from "classnames"

export const HorizontalMenu = props => {
  const renderMenuItem = () =>
    props.items.map(item =>
      !item.hidden ? (
        <MenuItem key={item.value}>
          <a href={"#"} onClick={e => e.preventDefault()}>
            {item.label}
          </a>
        </MenuItem>
      ) : null
    )

  const onSelect = item => {
    if (item.key !== props.active) {
      props.onChange(item.key, item)
    }
  }

  return (
    <>
      <Menu
        mode="horizontal"
        selectedKeys={[props.active]}
        className={classNames("panel-menu", {
          [props.className]: props.className,
        })}
        overflowedIndicatorPopupClassName="panel-menu__popup"
        triggerSubMenuAction={"click"}
        overflowedIndicator={<i className="bx bx-dots-vertical-rounded" />}
        onSelect={onSelect}
        // motion={{ motionName: `rc-menu-open-slide-up` }}
      >
        {renderMenuItem()}
      </Menu>
    </>
  )
}

HorizontalMenu.propTypes = {
  items: PropTypes.array,
  active: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
}
