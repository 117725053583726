import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"
import { Row, TabContent, TabPane } from "reactstrap"

import { TabsNav } from "../../../../Tabs"
import { LocationFields } from "./LocationFields"
import { useConfirmModal, useCountryOptions } from "hooks"
import { ConfirmPopup } from "../../../../ConfirmPopup"

const TABS = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
]

export const LocationSection = props => {
  const { t } = useTranslation()

  const [activeTab, setActiveTab] = useState("1")

  const { control, watch, setValue } = useFormContext()

  const countryOptions = useCountryOptions({ visible: true })

  // Confirm change primary location
  const { modalRef, showModal, closeModal, handleConfirm } = useConfirmModal(
    item => onPrimaryChangeConfirm(item - 1),
    () => {}
  )
  const onPrimaryChange = index => {
    showModal(index + 1)
  }

  const onPrimaryChangeConfirm = index => {
    let oppositeIndex

    if (index === 0) {
      oppositeIndex = 1
    } else if (index === 1) {
      oppositeIndex = 0
    }

    setValue(`locations.${index}.is_primary`, true)
    setValue(`locations.${oppositeIndex}.is_primary`, false)
  }
  // === //

  return (
    <Row>
      <div className={"d-flex"}>
        <h4 className={"mb-4"}>{t("location")}</h4>
        <div className={"ms-3"}>
          <TabsNav
            className={"location-form-tabs-nav"}
            options={TABS}
            active={activeTab}
            onChange={tab => setActiveTab(tab)}
          />
        </div>
      </div>

      <div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={"1"}>
            <LocationFields
              name={"locations.0"}
              control={control}
              countryOptions={countryOptions}
              watch={watch}
              onPrimaryChange={() => onPrimaryChange(0)}
            />
          </TabPane>
          <TabPane tabId={"2"}>
            <LocationFields
              name={"locations.1"}
              control={control}
              countryOptions={countryOptions}
              watch={watch}
              onPrimaryChange={() => onPrimaryChange(1)}
            />
          </TabPane>
        </TabContent>
      </div>

      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
        questionText={"sure_change_primary_location"}
      />
    </Row>
  )
}

LocationSection.propTypes = {}
