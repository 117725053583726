import * as Yup from "yup"

import { ValidationsUtils } from "../../../../utils"
import { FILE_MAX_SIZE } from "../../../../consts/common/maxFileSize"

export const validationSchema = Yup.object().shape({
  title: Yup.string().required("field.error.required"),
  file: Yup.mixed()
    .test("maxSize", "field.error.file-size.max", value =>
      ValidationsUtils.fileMaxSize(value?.file, FILE_MAX_SIZE)
    )
    .required("field.error.required"),
})
