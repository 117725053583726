import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import { EventModelContractForm } from "../EventModelContractForm"
import { useFetchData, useSubmitHandler } from "hooks"
import { calendarEventModelContractCreate } from "store/actions"
import { mapModelData, mapCompanyData } from "./helpers"
import { API } from "api"
import * as HOC from "HOC"

const DataShower = HOC.withDataShower()

export const EventModelContractCreate = props => {
  const dispatch = useDispatch()

  const companyLogo = useSelector(
    state => state.user.profile.data?.company?.avatar
  )
  const { loading, error } = useSelector(
    state => state.calendarEvent.model.contract.create
  )

  useEffect(() => {
    return () => {
      dispatch(calendarEventModelContractCreate.cleanState())
    }
  }, [])

  // Fetch company data for from initial value
  const companyFetch = useFetchData()
  const companyData = companyFetch?.data?.data || null

  const onCompanyFetch = async () => {
    const request = API.profile.getCompany()
    await companyFetch.fetchData({
      request,
    })
  }

  useEffect(() => {
    onCompanyFetch()
  }, [])
  // === //

  // Fetch model data for from initial value
  const modelFetch = useFetchData()
  const modelData = modelFetch?.data?.data || null

  const onModelFetch = async () => {
    const request = API.model.getModel(props.modelId)
    await modelFetch.fetchData({
      request,
    })
  }

  useEffect(() => {
    if (props.modelId) {
      onModelFetch()
    }
  }, [props.modelId])
  // === //

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        calendarEventModelContractCreate.create({
          event: props.eventId,
          model: props.modelId,
          params,
          onSuccess,
          onError,
        })
      )
    },
    data => {
      props.onSuccess(data)
    }
  )

  const isLoading = useMemo(() => {
    return companyFetch.loading || modelFetch.loading
  }, [companyFetch.loading, modelFetch.loading])

  const isFormReady = useMemo(() => {
    let ready = true

    if (props.modelId) {
      const { data, loading, error } = modelFetch
      ready = !loading && !!(data || error)
    }

    if (ready) {
      const { data, loading, error } = companyFetch
      ready = !loading && !!(data || error)
    }
    return ready
  }, [companyFetch, props.modelId, modelFetch])

  const initialValues = useMemo(() => {
    let values = {
      logo: companyLogo,
    }

    if (isFormReady) {
      if (companyData) {
        values = {
          ...values,
          ...mapCompanyData(companyData),
        }
      }

      if (modelData) {
        values = {
          ...values,
          ...mapModelData(modelData),
        }
      }
    }
    return values
  }, [companyLogo, isFormReady, companyData, modelData])

  return (
    <DataShower isLoading={isLoading} isFailed={null} error={null}>
      {isFormReady && (
        <EventModelContractForm
          initialValues={{
            ...initialValues,
          }}
          loading={loading}
          error={error}
          onSubmit={handleSubmit}
          onCancel={props.onCancel}
        />
      )}
    </DataShower>
  )
}

EventModelContractCreate.propTypes = {
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  eventId: PropTypes.number,
  modelId: PropTypes.number,
}
