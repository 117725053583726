import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { ControllerHF, FilterTitle, CheckboxField } from "components"

export const GeneralDetailsSection = props => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <div className={"mb-3"}>
      {/*<FilterTitle title={t("details")} />*/}

      <div className={"d-flex"}>
        <ControllerHF
          control={control}
          component={CheckboxField}
          name={"influencer"}
          id={"influencer"}
          label={t("influencer")}
          wrapClass={"mb-0 me-3"}
        />

        <ControllerHF
          control={control}
          component={CheckboxField}
          name={"plus_size"}
          id={"plus_size"}
          label={t("plus_size")}
          wrapClass={"mb-0"}
        />
      </div>
    </div>
  )
}

GeneralDetailsSection.propTypes = {}
