import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { ReactTableView } from "components"

export const Table = ({ data = { views: 0, pages: [] }, loading }) => {
  const { t } = useTranslation()

  const items = useMemo(() => {
    const generalItem = { name: t("general_package"), value: data?.views }

    let result = data.pages.map(item => ({
      name: item.title,
      value: item.count,
    }))

    result = [generalItem, ...result]

    return result
  }, [data])

  const [columns, setColumns] = useState([
    {
      Header: t("name"),
      accessor: "name",
      id: "name",
      Cell: ({ row: { original }, value }) => value,
    },
    {
      Header: t("clicks"),
      accessor: "value",
      id: "value",
      Cell: ({ row: { original }, value }) => {
        return value
      },
    },
  ])

  return <ReactTableView isLoading={loading} columns={columns} data={items} />
}

Table.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
}
