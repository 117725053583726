import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { PanelSidebar } from "../../../../../../components"
import { ClientsSidebar } from "../../ClientsSidebar"
import { clientsSidebar } from "../../../../../../store/actions"

export const Sidebar = () => {
  const dispatch = useDispatch()

  const [drawerVisible, setDrawerVisible] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(clientsSidebar.cleanState())
    }
  }, [])

  const toggleDrawerVisible = forceStatus => {
    if (forceStatus !== undefined) {
      setDrawerVisible(forceStatus)
    } else {
      setDrawerVisible(prevStatus => !prevStatus)
    }
  }

  return (
    <PanelSidebar
      className={"clients-panel-sidebar"}
      drawerClassName={"clients-panel-sidebar"}
      drawerVisible={drawerVisible}
      toggleDrawerVisible={toggleDrawerVisible}
    >
      <ClientsSidebar
        drawerVisible={drawerVisible}
        toggleDrawerVisible={toggleDrawerVisible}
      />
    </PanelSidebar>
  )
}

Sidebar.propTypes = {}
