import React from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import { StatementPaymentPeriod } from "components"
import { useSubmitHandler } from "hooks"
import { agencyStatementPaymentSubmit } from "store/actions"
import { API } from "api"

export const PaymentPeriod = props => {
  const dispatch = useDispatch()

  const { loading, error } = useSelector(
    state => state.agency.statement.payment.submit
  )

  const onClose = () => {
    dispatch(agencyStatementPaymentSubmit.cleanState())
  }

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        agencyStatementPaymentSubmit.submit({
          id: props.agencyId,
          params,
          onSuccess,
          onError,
        })
      )
    },
    () => {
      props.onSuccess()
      props.onCancel()
    }
  )

  const mapRequest = params => {
    return API.agency.getAgencyStatements(props.agencyId, params)
  }

  return (
    <StatementPaymentPeriod
      modalRef={props.modalRef}
      onCancel={props.onCancel}
      initialPeriod={props.initialPeriod}
      loading={loading}
      error={error}
      onSubmit={handleSubmit}
      onClose={onClose}
      mapRequest={mapRequest}
    />
  )
}

PaymentPeriod.propTypes = {
  modalRef: PropTypes.any,
  onCancel: PropTypes.func,
  initialPeriod: PropTypes.array,
  agencyId: PropTypes.number,
  onSuccess: PropTypes.func,
}
