import React from "react"
import PropTypes from "prop-types"

import { GeneralEventsCalendar } from "components"
import { EventsCalendarProvider } from "contexts"
import { useEventsCalendar } from "hooks"
import { EVENTS_CALENDAR_MODE, EVENT_TYPE_JOB } from "consts"

export const CalendarContainer = props => {
  const calendar = useEventsCalendar({
    config: {
      eventsToolbar: false,
      mode: EVENTS_CALENDAR_MODE.ADMINISTRATION,
    },
  })

  return (
    <EventsCalendarProvider {...calendar}>
      <GeneralEventsCalendar
        eventsFetchParams={{ type: EVENT_TYPE_JOB, is_closed: true }}
      />
    </EventsCalendarProvider>
  )
}

CalendarContainer.propTypes = {}
