import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { TabNavigator } from "../../../Tabs"
import { RatesContainer } from "./RatesContainer"
import { ExpensesContainer } from "./ExpensesContainer"
import { AttachmentsContainer } from "./AttachmentsContainer"
import { EVENT_TYPE_CASTING, EVENT_TYPE_FITTING } from "consts"

const RATES_TAB = "rates"
const EXPENSES_TAB = "expenses"
const ATTACHMENTS_TAB = "attachments"

export const ModelDetailsContainer = props => {
  const { t } = useTranslation()

  const ratesTabHidden = useMemo(() => {
    return [EVENT_TYPE_CASTING, EVENT_TYPE_FITTING].includes(props.eventType)
  }, [props.eventType])

  const [activeTab, setActiveTab] = useState(
    ratesTabHidden ? EXPENSES_TAB : RATES_TAB
  )

  return (
    <>
      <TabNavigator
        activeTab={activeTab}
        onTabChange={tab => setActiveTab(tab)}
        destroyOnClose={true}
        tabs={[
          {
            label: t("rates"),
            value: RATES_TAB,
            render: () => (
              <RatesContainer
                model={props.model}
                eventId={props.eventId}
                onModelUpdate={props.onModelUpdate}
                eventDate={props.eventDate}
              />
            ),
            hidden: ratesTabHidden,
          },
          {
            label: t("expenses"),
            value: EXPENSES_TAB,
            render: () => (
              <ExpensesContainer
                model={props.model}
                eventId={props.eventId}
                onModelUpdate={props.onModelUpdate}
              />
            ),
          },
          {
            label: t("attachments"),
            value: ATTACHMENTS_TAB,
            render: () => (
              <AttachmentsContainer
                model={props.model}
                eventId={props.eventId}
                onModelUpdate={props.onModelUpdate}
              />
            ),
          },
        ]}
      />
    </>
  )
}

ModelDetailsContainer.propTypes = {
  model: PropTypes.object,
  eventId: PropTypes.number,
  onModelUpdate: PropTypes.func,
  eventDate: PropTypes.object,
  eventType: PropTypes.string,
}
