import React from "react"
import { useTranslation } from "react-i18next"
import {  useRouteMatch, generatePath } from "react-router-dom"

import { PanelMenu } from "../../../../../../../components"

export const Nav = () => {
    const { t } = useTranslation()
    let { path } = useRouteMatch()
    const getPath = route => generatePath(`${path}/${route}`)

    const menu = [
        {
            key: "rating",
            route: getPath("rating"),
            title: t("rating"),
            badge: null,
        },
    ]

    return <PanelMenu items={menu} />
}

Nav.propTypes = {}