import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Alerts } from './Alerts';

export const AlertsContainer = props => {
  const { t } = useTranslation()

  return (
    <>
      <Alerts data={props.event?.alerts} />
    </>
  )
}

AlertsContainer.propTypes = {
  event: PropTypes.object,
}
