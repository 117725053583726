import { takeLatest, fork, put, all, call } from "redux-saga/effects"

import i18n from "../../../../i18n"

import { clientContactData } from "./slice"
import { API } from "../../../../api"

export function* getData({ payload: { id, params } }) {
  try {
    const response = yield call(API.client.getClientContact, id, params)

    const { data } = response

    yield put(clientContactData.getDataCompleted(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(clientContactData.getDataRejected(message))
  }
}

export function* watchGetData() {
  yield takeLatest(clientContactData.getData, getData)
}

function* dataSaga() {
  yield all([fork(watchGetData)])
}

export default dataSaga
