import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { commonHelper, modelHelper } from "helpers"
import {
  IconButton,
  ExpenseInfoCell,
  StatementPaidStatusBadge,
} from "components"

export const ExpensesTable = props => {
  const { t } = useTranslation()

  const getCurrencyLabel = currency =>
    commonHelper.currency.getCurrency(currency)?.label?.toUpperCase()

  const renderTotal = () => {
    const data = Object.entries(
      modelHelper.expenses.getExpensesTotalData(props.data)
    )
    return data.map(([key, value], index) => {
      const next = data[index + 1]
      return (
        <span key={key} className={"fw-bold"}>
          {getCurrencyLabel(key)} {value}
          {next ? ", " : null}
        </span>
      )
    })
  }

  return (
    <div className="table-responsive mt-4">
      <table className="table table-cell_p-v_sm">
        <tbody>
          {props.data.map(item => (
            <tr key={item.id} className="expense-border-bottom">
              <td>
                <span className={"fw-bold"}>{t("expense")}</span>
              </td>
              <td>
                <ExpenseInfoCell data={item} />
              </td>

              {item.has_paid_statement ? (
                <td className={"text-end text-nowrap"}>
                  <StatementPaidStatusBadge value={true} />
                </td>
              ) : (
                <td
                  className={"table-column-sticky_end bg-white text-nowrap"}
                  style={{ width: "76px" }}
                >
                  <IconButton
                    name={"edit"}
                    bg
                    onClick={() => props.onEdit(item)}
                  />

                  <IconButton
                    name={"remove"}
                    bg
                    onClick={() => props.onRemove(item)}
                    wrapClass={"ms-2"}
                  />
                </td>
              )}
            </tr>
          ))}
          <tr className="expense-border-bottom">
            <td className="pt-4">
              <span className={"fw-bold"}>{t("total").toUpperCase()}</span>
            </td>
            <td className="pt-4">{renderTotal()}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

ExpensesTable.propTypes = {
  data: PropTypes.array,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  currency: PropTypes.string,
}
