import { createSelector } from "@reduxjs/toolkit"

const getSyncCount = state => state.agency.panel.agenciesSidebar.syncCount
const getImportSyncCount = state => state.socket.import.data.agenciesSyncCount

export const selectAgenciesSidebarSyncCount = createSelector(
  [getSyncCount, getImportSyncCount],
  (syncCount, importSyncCount) => {
    return syncCount + importSyncCount
  }
)
