import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { billingSubscriptionCancel } from "./slice"

function* cancel() {
  try {
    const response = yield call(API.billing.subscriptionDelete)
    const { data } = response

    yield put(billingSubscriptionCancel.cancelSuccess())
    toastr.success(i18n.t(`billing.subscription.cancel.success`))

  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      billingSubscriptionCancel.cancelError(message)
    )

    toastr.error(message)
  }
}

export function* watchCancel() {
  yield takeEvery(billingSubscriptionCancel.cancel, cancel)
}

function* cancelSaga() {
  yield all([fork(watchCancel)])
}

export default cancelSaga
