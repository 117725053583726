import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import { MegaTagTitle } from "../../components"
import { RegisterForm } from "./components"

const RegisterComponent = props => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <MegaTagTitle title={"title.sign_up"} />

      <Card className="overflow-hidden">
        <div className="">
          <Row>
            <Col>
              <div className="text-white p-4 text-center">
                <h5 className="mb-0">{t("sign-up")}</h5>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody>
          <div className="p-2">
            <RegisterForm />
          </div>
        </CardBody>
      </Card>

      <div className="mt-5 text-center">
        <p>
          {t("already_signed_up")}
          {"? "}
          <Link to="/login" className="font-weight-medium text-secondary">
            {" "}
            {t("sign_go_to_login")}
          </Link>{" "}
        </p>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const Register = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterComponent)
