import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Draggable } from "@fullcalendar/interaction"
import { socialPostsCalendarHelper } from "helpers"

const draggableSelector = "external-post"
const categories = socialPostsCalendarHelper.postConfig.getPosts()

export const PostsCategoryToolbar = ({ renderPostCategory }) => {
  useEffect(() => {
    new Draggable(document.getElementById("external-posts"), {
      itemSelector: `.${draggableSelector}`,
    })
  }, [])

  return (
    <div className={"posts-calendar-toolbar"} id={"external-posts"}>
      {categories.map((data, index) =>
        renderPostCategory(data, index, draggableSelector)
      )}
    </div>
  )
}

PostsCategoryToolbar.propTypes = {
  renderPostCategory: PropTypes.func,
}
