import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { connect } from "react-redux"
import { Layout, CalendarContainer } from "./components"
import { MegaTagTitle } from "components"

const CalendarComponent = props => {
  return (
    <Layout>
      <MegaTagTitle title={"title.social-media-calendar"} />

      <Row>
        <Col>
          <CalendarContainer />
        </Col>
      </Row>
    </Layout>
  )
}

CalendarComponent.propTypes = {}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const Calendar = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarComponent)
