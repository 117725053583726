import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"

import { Chat } from "./Chat"

export const ChatIndex = () => {
  let { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <Chat />
      </Route>
      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  )
}
