import {
    MODEL_FETCH_VIDEOS,
    MODEL_FETCH_VIDEOS_SUCCESS,
    MODEL_FETCH_VIDEOS_ERROR,
    MODEL_FETCH_VIDEOS_CLEAN_STATE,

    MODEL_VIDEOS_SET_PAGE,
} from './actionTypes'

export const modelFetchVideos = (id, params) => {
    return {
        type: MODEL_FETCH_VIDEOS,
        payload: { id, params },
    }
};

export const modelFetchVideosSuccess = (data) => {
    return {
        type: MODEL_FETCH_VIDEOS_SUCCESS,
        payload: data,
    }
};

export const modelFetchVideosError = (data) => {
    return {
        type: MODEL_FETCH_VIDEOS_ERROR,
        payload: data,
    }
};

export const modelFetchVideosCleanState = () => {
    return { type: MODEL_FETCH_VIDEOS_CLEAN_STATE };
};

export const modelVideosSetPage = (data) => {
    return {
        type: MODEL_VIDEOS_SET_PAGE,
        payload: data,
    }
};