import React from "react"
import PropTypes from "prop-types"

import { ExpensesForm } from "./ExpensesForm"
import { AsyncExpenses } from "./AsyncExpenses"

export const ExpensesSection = props => {
  return (
    <>
      {!props.isEdit ? (
        <ExpensesForm />
      ) : (
        <AsyncExpenses eventId={props.eventId} />
      )}
    </>
  )
}

ExpensesSection.propTypes = {
  isEdit: PropTypes.bool,
  eventId: PropTypes.number,
}
