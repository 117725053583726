import React from "react"
import PropTypes from "prop-types"

import { Button } from "../Button"

export const PrintButton = ({ onPrint }) => {
  const handlePrint = () => {
    if (onPrint) {
      onPrint()
    } else {
      window.print()
    }
  }

  return (
    <Button
      icon={"fa fa-print"}
      btnClass={"d-print-none"}
      renderTitle={""}
      onClick={handlePrint}
    />
  )
}

PrintButton.propTypes = {
  onPrint: PropTypes.func,
  className: PropTypes.string,
}
