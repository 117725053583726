import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import register from "./register/reducer"
import loginReducer from "./login/reducer"
import dataReducer from "./data/reducer"
import passwordForgetReducer from "./passwordForget/reducer"
import passwordResetReducer from "./passwordReset/reducer"
import logoutReducer from "./logout/reducer"

const authDataPersistConfig = {
  key: "authData",
  storage,
}

const authReducer = combineReducers({
  register,
  login: loginReducer,
  data: persistReducer(authDataPersistConfig, dataReducer),
  passwordForget: passwordForgetReducer,
  passwordReset: passwordResetReducer,
  logout: logoutReducer,
})

export default authReducer
