import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { MegaTagTitle } from "components"
import { ModelsPageLoader } from "./components"
import { GENDER_FEMALE } from "consts"

export const Women = props => {
  return (
    <div className={"website-panel-models"}>
      <MegaTagTitle title={"title.website.women"} />
      <Row>
        <Col>
          <ModelsPageLoader alias={props.alias} gender={GENDER_FEMALE} />
        </Col>
      </Row>
    </div>
  )
}

Women.propTypes = {
  alias: PropTypes.string,
}
