import React, { useCallback, useEffect, useState } from "react"
import { ChartCard, RadialBar } from "components"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { analyticsGraphsList } from "store"
import { GRAPH_PERIOD_TYPE } from "consts"
import { analyticsHelper } from "helpers"

const initialChartState = {
  series: [],
  labels: [],
}
const defaultPeriodType = GRAPH_PERIOD_TYPE.MONTH

const { getPeriodData } = analyticsHelper.period
const { formatStatsData } = analyticsHelper.stats
const { generateCustomOptions } = analyticsHelper.options

export const TotalCountContainer = ({ graphData }) => {
  const dispatch = useDispatch()

  const customCharOptions = generateCustomOptions(graphData.alias)

  useEffect(() => {
    let { params } = getPeriodData(defaultPeriodType)
    fetchGraphData(params)
  }, [])

  const fetchGraphData = params => {
    dispatch(
      analyticsGraphsList.getGraphStatsData({
        alias: graphData.alias,
        tab: graphData.tab,
        params,
      })
    )
  }

  const [charData, setCharData] = useState(initialChartState)

  useEffect(() => {
    if (graphData.stats.data) {
      let formattedData = formatStatsData(graphData.alias, graphData.stats.data)
      setCharData({
        series: [formattedData],
        labels: [formattedData.toLocaleString()],
      })
    } else {
      setCharData(initialChartState)
    }
  }, [graphData.stats.data])

  const handlePeriodChange = useCallback(
    params => {
      fetchGraphData(params)
    },
    [fetchGraphData]
  )

  return (
    <ChartCard graphData={graphData} onPeriodChange={handlePeriodChange}>
      <RadialBar
        labels={charData.labels}
        optionsData={customCharOptions}
        loading={graphData.stats.loading}
        error={graphData.stats.error}
      />
    </ChartCard>
  )
}

TotalCountContainer.propTypes = {
  graphData: PropTypes.object,
}
