import React from "react"
import DatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { Popover } from "reactstrap"
import s from "./GoToDatePicker.module.scss"

export const GoToDatePicker = ({
  isOpen,
  onPopoverToggle,
  onChange,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Popover
      trigger={"legacy"}
      target={".fc-goTo-button"}
      isOpen={isOpen}
      toggle={onPopoverToggle}
      fade={false}
      className={s.root}
      placement={"bottom"}
    >
      <DatePicker
        onChange={event => onChange(event)}
        dateFormat={"dd/MM/yyyy"}
        calendarStartDay={1}
        inline
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
        {...props}
      />
    </Popover>
  )
}

GoToDatePicker.propTypes = {
  isOpen: PropTypes.bool,
  onChange: PropTypes.func,
  onPopoverToggle: PropTypes.func,
}
