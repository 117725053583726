import React from "react"
import PropTypes from "prop-types"

import { ExpensesControl } from "../../../../../Controls"

export const Expenses = ({ expenses = [], ...props }) => {
  return (
    <ExpensesControl
      value={expenses}
      onChange={value => props.onChange(value)}
      currency={props.currency}
      expenseTypeOptions={props.expenseTypeOptions}
    />
  )
}

Expenses.propTypes = {
  expenses: PropTypes.array,
  onChange: PropTypes.func,
  currency: PropTypes.string,
  expenseTypeOptions: PropTypes.array,
}
