export const selectFBPages = account => {
  if (account?.data?.accounts) {
    return account?.data?.accounts
  } else {
    return []
  }
}

export const selectInstagramAccounts = account => {
  let accounts = []

  if (account?.data?.accounts) {
    account?.data?.accounts.forEach(page => {
      if (page?.instagram?.id) {
        accounts.push(page?.instagram)
      }
    })
  } else {
    return []
  }

  return accounts
}
