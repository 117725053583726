import { createSelector } from "@reduxjs/toolkit"

export const selectUserDepartmentPermission = createSelector(
  [
    state => state.user.department.permissions.data.data,
    (_, permission) => permission,
  ],
  (permissions, permission) => {
    if (permissions) {
      if (permissions.hasOwnProperty(permission)) {
        return permissions[permission]
      }
    }
    return false
  }
)
