import {
    MODEL_ADD_VIDEO,
    MODEL_ADD_VIDEO_SUCCESS,
    MODEL_ADD_VIDEO_ERROR,
    MODEL_ADD_VIDEO_CLEAN_STATE,
} from './actionTypes';

export const modelAddVideo = (id, params, onSuccess, onError) => {
    return {
        type: MODEL_ADD_VIDEO,
        payload: { id, params, onSuccess, onError },
    }
};

export const modelAddVideoSuccess = () => {
    return {
        type: MODEL_ADD_VIDEO_SUCCESS,
    }
};

export const modelAddVideoError = (data) => {
    return {
        type: MODEL_ADD_VIDEO_ERROR,
        payload: data,
    }
};

export const modelAddVideoCleanState = () => {
    return { type: MODEL_ADD_VIDEO_CLEAN_STATE };
};