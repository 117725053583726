import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"

const EventsCalendarContext = createContext(null)

const EventsCalendarProvider = ({ children, ...props }) => {
  return (
    <EventsCalendarContext.Provider value={{ ...props }}>
      {children}
    </EventsCalendarContext.Provider>
  )
}

EventsCalendarProvider.propTypes = {
  children: PropTypes.any,
}

const useEventsCalendarContext = () => useContext(EventsCalendarContext)

export { EventsCalendarProvider, useEventsCalendarContext }
