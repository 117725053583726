import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { Select } from "../Select"
import { Label } from "reactstrap"

export const SelectorCombobox = ({
  value,
  options = null,
  onSelect,
  onFetchOptions,
  id,
  disabled,
  label,
  className,
  wrapClassName = "mb-3",
  loading,
  ...props
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  useEffect(() => {
    if (menuIsOpen && !loading && !options) {
      onFetchOptions && onFetchOptions()
    }
  }, [menuIsOpen, loading, !!options])

  const onChange = useCallback(
    (value, { action, option }) => {
      if (action === "select-option") {
        onSelect && onSelect(option)
      }

      props.onChange && props.onChange(value)
    },
    [onSelect, props.onChange]
  )

  const onInputChange = (inputValue, { action, prevInputValue }) => {
    switch (action) {
      case "input-change":
        return inputValue
      case "menu-close":
        setMenuIsOpen(false)
        return prevInputValue
      default:
        return prevInputValue
    }
  }

  const renderLabel = label => {
    if (label) {
      if (typeof label === "string") {
        return label
      } else return label
    } else return <></>
  }

  return (
    <div
      className={classNames("select2-container", {
        [wrapClassName]: wrapClassName,
      })}
    >
      {label && <Label htmlFor={id}>{renderLabel(label)}</Label>}

      <Select
        value={value}
        options={options || []}
        className={className}
        isDisabled={disabled}
        isMulti
        id={id}
        isLoading={loading}
        onChange={onChange}
        hideSelectedOptions
        controlShouldRenderValue={false}
        isClearable={false}
        closeMenuOnSelect={false}
        // blurInputOnSelect={false}
        onInputChange={onInputChange}
        menuIsOpen={menuIsOpen}
        onFocus={() => setMenuIsOpen(true)}
        {...props}
      />
    </div>
  )
}

SelectorCombobox.propTypes = {
  value: PropTypes.any,
  options: PropTypes.any,
  onSelect: PropTypes.func,
  onFetchOptions: PropTypes.func,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.any,
  className: PropTypes.string,
  wrapClassName: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
}
