import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "../../../../i18n"
import { API } from "../../../../api"
import { clientRemoveLogo } from "./slice"
import { clientProfileData } from "../../profile/data/slice"

function* remove({ payload: { id, onSuccess, onError } }) {
  try {
    const response = yield call(API.client.removeClientAvatar, id)

    const { data } = response
    if (onSuccess) {
      onSuccess()
    }
    yield put(clientRemoveLogo.removeCompleted())
    toastr.success(i18n.t("client-logo.remove.success"))
    yield put(clientProfileData.updateData({ avatar: null }))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")
    if (onError) {
      onError(errors)
    }
    yield put(
      clientRemoveLogo.removeRejected({
        errors,
        message,
      })
    )
  }
}

export function* watchRemove() {
  yield takeEvery(clientRemoveLogo.remove, remove)
}

function* removeSaga() {
  yield all([fork(watchRemove)])
}

export default removeSaga
