import {
    MODEL_PANEL_GENERAL_EDIT_FEATURES,
    MODEL_PANEL_GENERAL_EDIT_FEATURES_SUCCESS,
    MODEL_PANEL_GENERAL_EDIT_FEATURES_ERROR,
    MODEL_PANEL_GENERAL_EDIT_FEATURES_CLEAN_STATE,
} from './actionTypes';

export const modelPanelGeneralEditFeatures = (id, params, onSuccess, onError) => {
    return {
        type: MODEL_PANEL_GENERAL_EDIT_FEATURES,
        payload: { id, params, onSuccess, onError },
    }
};

export const modelPanelGeneralEditFeaturesSuccess = () => {
    return {
        type: MODEL_PANEL_GENERAL_EDIT_FEATURES_SUCCESS,
    }
};

export const modelPanelGeneralEditFeaturesError = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_EDIT_FEATURES_ERROR,
        payload: data,
    }
};

export const modelPanelGeneralEditFeaturesCleanState = () => {
    return { type: MODEL_PANEL_GENERAL_EDIT_FEATURES_CLEAN_STATE };
};