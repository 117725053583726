import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";

import { ModelPanelLayout, ModelPanelRouting } from './components';
import { modelFetchProfile, modelFetchProfileCleanState, modelProfileCleanState } from '../../store/actions';
import * as HOC from '../../HOC';

const DataShower = HOC.withDataShower();

const ModelPanelComponent = (props) => {
    let { id } = useParams();

    useEffect(() => {
        props.modelFetchProfile(id);

        return () => {
            props.modelFetchProfileCleanState();
            props.modelProfileCleanState();
        };
    }, [id]);

    return (
        <ModelPanelLayout>
            <DataShower
                isLoading={props.loading}
                isFailed={props.error}
                error={props.error}
                customLoaderClass={'mt-5'}
            >
                {props.model && (
                    <ModelPanelRouting />
                )}
            </DataShower>
        </ModelPanelLayout>
    );
};

ModelPanelComponent.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.any,
    model: PropTypes.object,

    modelFetchProfile: PropTypes.func,
    modelFetchProfileCleanState: PropTypes.func,
    modelProfileCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { loading, error } = state.model.load;
    const { data } = state.model.profile;
    return {
        loading, error,
        model: data,
    };
};

const mapDispatchToProps = {
    modelFetchProfile,
    modelFetchProfileCleanState,
    modelProfileCleanState,
};

export const ModelPanel = connect(mapStateToProps, mapDispatchToProps)(ModelPanelComponent);