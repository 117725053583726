export const MODELS_PANEL_FETCH_LIST = "MODELS_PANEL_FETCH_LIST"
export const MODELS_PANEL_FETCH_LIST_SUCCESS = "MODELS_PANEL_FETCH_LIST_SUCCESS"
export const MODELS_PANEL_FETCH_LIST_ERROR = "MODELS_PANEL_FETCH_LIST_ERROR"
export const MODELS_PANEL_LIST_CLEAN_STATE = "MODELS_PANEL_LIST_CLEAN_STATE"

export const MODELS_PANEL_LIST_SET_PAGE = "MODELS_PANEL_LIST_SET_PAGE"

export const MODELS_PANEL_LIST_CHANGE_FILTER = "MODELS_PANEL_LIST_CHANGE_FILTER"
export const MODELS_PANEL_LIST_CALL_CHANGE_FILTER =
  "MODELS_PANEL_LIST_CALL_CHANGE_FILTER"
export const MODELS_PANEL_LIST_RESET_FILTER = "MODELS_PANEL_LIST_RESET_FILTER"

export const MODELS_PANEL_LIST_RESET_FETCHED_DATA =
  "MODELS_PANEL_LIST_RESET_FETCHED_DATA"

export const MODELS_PANEL_LIST_SYNC = "MODELS_PANEL_LIST_SYNC"
