import { all } from "redux-saga/effects"

import createSaga from "./create/saga"
import editSaga from "./edit/saga"
import participantSaga from "./participant/sagas"
import leaveSaga from "./leave/saga"
import removeSaga from "./remove/saga"

function* chatsSaga() {
  yield all([
    createSaga(),
    editSaga(),
    participantSaga(),
    leaveSaga(),
    removeSaga(),
  ])
}

export default chatsSaga
