import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Container, Row } from "reactstrap"
import { Button, Drawer } from "components"
import { AddClientForm } from "./AddClientForm"
import { useCountryOptions } from "hooks"
import { ValidationsUtils } from "utils"
import { clientsAddClient } from "store/actions"

const AddClientComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const countryOptions = useCountryOptions({ visible: props.visible })

  const handleCloseDrawer = () => {
    props.onClose()
  }
  const handleOpenDrawer = () => {
    props.onOpen()
  }

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    handleCloseDrawer()
    props.onAddSuccess()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      clientsAddClient.add({
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }
  const onDestroy = () => {
    dispatch(clientsAddClient.cleanState())
  }

  return (
    <React.Fragment>
      <Button
        leftIcon={"bx bx-plus"}
        title={"btn.add-new"}
        onClick={handleOpenDrawer}
      />

      <Drawer
        title={t("add_new_client")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <Container fluid>
          <Row>
            <AddClientForm
              initialValues={{
                booker_id: props.user,
              }}
              loading={props.loading}
              error={props.error}
              onSubmit={handleSubmit}
              onCancel={handleCloseDrawer}
              countryOptions={countryOptions}
            />
          </Row>
        </Container>
      </Drawer>
    </React.Fragment>
  )
}

AddClientComponent.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onAddSuccess: PropTypes.func,

  loading: PropTypes.bool,
  error: PropTypes.object,

  user: PropTypes.object,
}

const mapStateToProps = state => {
  const { loading, error } = state.clients.panel.add
  return {
    loading,
    error,
    user: state.user.profile.data,
  }
}

export const AddClient = connect(mapStateToProps)(AddClientComponent)
