import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import classNames from "classnames"

import { CheckboxMark, Image } from "../../../../../../components"

export const PhotosGridItem = props => {
  const { t } = useTranslation()

  const onSelect = (e, item) => {
    e.preventDefault()
    props.onSelect(item)
  }

  return (
    <div
      className={classNames("model-photos-selection-photo-card", {
        ["model-photos-selection-photo-card_selected"]: props.selected,
      })}
    >
      <Link to={"#"} onClick={e => onSelect(e, props.item)}>
        <div className={"model-photos-selection-photo-card__photo-block"}>
          <Image
            src={props.item?.attachment?.src}
            alt={"model-photo"}
            crops={props.item?.crops}
          />

          <div className="model-photos-selection-photo-card__img-cover" />

          <div className={"model-photos-selection-photo-card__actions-block"}>
            <CheckboxMark status={props.selected} />
          </div>
        </div>
      </Link>
    </div>
  )
}

PhotosGridItem.propTypes = {
  item: PropTypes.object,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
}
