import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { ControllerHF } from "components"
import { useFetchData } from "hooks"
import { API } from "api"
import { AgenciesSelect } from "./AgenciesSelect"
import { useFormContext } from "react-hook-form"

export const Agencies = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const { fetchData, loading, data } = useFetchData()

  const onFetch = async () => {
    const params = {
      pagination: false,
    }
    const request = API.agency.getAgencies(params)
    await fetchData({
      request,
    })
  }
  useEffect(() => {
    onFetch()
  }, [])
  // ==== //

  return (
    <ControllerHF
      name={"agencies"}
      control={control}
      component={AgenciesSelect}
      options={data?.data}
      loading={loading}
    />
  )
}

Agencies.propTypes = {}
