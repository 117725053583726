import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';

import { usePanelTopBarOptions } from '../../../../../hooks';
import { AddRating } from './AddRating';
import { RatingGrid } from './RatingGrid';
import { ConfirmPopup } from "../../../../../components"
import {
    modelPanelRatingFetchList,
    modelPanelRatingListCleanState,
    modelPanelRatingListSetPage,
    modelPanelRatingRemoveRating,
    modelPanelRatingRemoveRatingCleanState,
} from '../../../../../store/actions';
import { ROLE_DIRECTOR } from '../../../../../consts';

const RatingContainerComponent = (props) => {
    const handleAddSuccess = () => {
        fetchData();
    };

    const panelOptions = useMemo(() => ({
        actions: (
            <>
                <AddRating onAddSuccess={handleAddSuccess} />
            </>
        ),
    }), [props.page]);

    usePanelTopBarOptions(panelOptions);

    const removeModalRef = useRef(null);
    const [ratingRemove, setRatingRemove] = useState(null);

    useEffect(() => {
        return () => {
            props.modelPanelRatingListCleanState();
            props.modelPanelRatingRemoveRatingCleanState();
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [props.page]);

    const fetchData = () => {
        let params = {
            page: props.page,
        };

        props.modelPanelRatingFetchList(props.model.id, params);
    };

    const handlePageChange = (item) => {
        props.modelPanelRatingListSetPage(item.selected + 1);
    };

    const removeRating = (item) => {
        setRatingRemove(item);

        if (removeModalRef.current) {
            removeModalRef.current.show();
        }
    };

    const resetRatingRemove = () => {
        setRatingRemove(null);
    };

    const onRemoveSuccess = () => {
        fetchData();
    }

    const confirmRemove = () => {
        props.modelPanelRatingRemoveRating(ratingRemove?.id, onRemoveSuccess);
    };

    return (
        <>
            <RatingGrid
                items={props.list}
                loading={props.listLoading}
                error={props.listError}
                meta={props.meta}
                page={props.page}
                onPageChange={handlePageChange}
                onRemove={removeRating}
                withRemove={props.user?.role === ROLE_DIRECTOR}
                removing={props.removing}
            />

            <ConfirmPopup ref={removeModalRef} onConfirm={confirmRemove} onClosed={resetRatingRemove} />
        </>
    );
};

RatingContainerComponent.propTypes = {
    model: PropTypes.object,
    list: PropTypes.array,
    listLoading: PropTypes.bool,
    listError: PropTypes.any,
    meta: PropTypes.object,
    page: PropTypes.any,
    removing: PropTypes.array,

    user: PropTypes.object,

    modelPanelRatingFetchList: PropTypes.func,
    modelPanelRatingListCleanState: PropTypes.func,
    modelPanelRatingListSetPage: PropTypes.func,
    modelPanelRatingRemoveRating: PropTypes.func,
    modelPanelRatingRemoveRatingCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { data } = state.model.profile;
    const { list, listLoading, listError, meta, page } = state.modelPanel.rating.list.data;
    const { pending } = state.modelPanel.rating.list.remove;
    return {
        model: data,
        list, listLoading, listError, meta, page,
        user: state.user.profile.data,
        removing: pending,
    };
};

const mapDispatchToProps = {
    modelPanelRatingFetchList,
    modelPanelRatingListCleanState,
    modelPanelRatingListSetPage,
    modelPanelRatingRemoveRating,
    modelPanelRatingRemoveRatingCleanState,
};

export const RatingContainer = connect(mapStateToProps, mapDispatchToProps)(RatingContainerComponent);