import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { chatContactsAdd } from "./slice"
import { chatConversationData } from "../../conversation/data/slice"
import { chatContactsList } from "../list/slice"

function* add({ payload: { params, onSuccess, onError } }) {
  try {
    const response = yield call(API.users.addContact, params)

    const { data } = response

    yield put(chatContactsAdd.addSuccess())
    yield put(chatContactsList.addItem(data.data))

    if (onSuccess) {
      onSuccess(data.data)
    }

    toastr.success(i18n.t("chat.contact.add.success"))

    yield put(chatConversationData.initParticipant(data.data))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    let message = error.response?.data?.message || i18n.t("error")

    if (error.response && error.response.status === 404) {
      message = i18n.t("user_not_found")
    }

    yield put(
      chatContactsAdd.addError({
        errors,
        message,
      })
    )

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchAdd() {
  yield takeEvery(chatContactsAdd.add, add)
}

function* addSaga() {
  yield all([fork(watchAdd)])
}

export default addSaga
