import { all } from 'redux-saga/effects';

import dataCollectionsSaga from './dataCollections/sagas';
import commonSaga from './common/saga';

function* draftSaga() {
    yield all([
        dataCollectionsSaga(),
        commonSaga(),
    ])
}

export default draftSaga;