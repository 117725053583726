import React from "react"
import PropTypes from "prop-types"
import { Alert, Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { Button, ControllerHF, ComboboxFormField } from "components"

export const GroupAddForm = props => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      group_id: null,
    },
    resolver: yupResolver(
      Yup.object().shape({
        group_id: Yup.mixed().nullable(true).required("field.error.required"),
      })
    ),
  })

  const onSubmit = async values => {
    const params = {
      ...values,
    }

    await props.onSubmit(params, { reset, setError })
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <ControllerHF
              name={"group_id"}
              control={control}
              component={ComboboxFormField}
              id={"group_id"}
              label={t("group")}
              placeholder={t("group")}
              options={props.groups}
              getOptionLabel={option => `${option.name} (${option.type})`}
              getOptionValue={option => option.id}
              normalize={option => (option ? option.id : null)}
              selectProps={{
                isClearable: true,
              }}
              isDetermineValue
            />
          </Col>
        </Row>

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.add"}
              disabled={isSubmitting}
              loading={isSubmitting}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </>
  )
}

GroupAddForm.propTypes = {
  groups: PropTypes.array,
  error: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
}
