import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "../../../../../i18n"

import { websiteUpdateCurrentPageData } from "./slice"
import { API } from "../../../../../api"
import { websiteCurrentPageData } from "../actions"

function* updateItem({
  payload: { alias, params, onSuccess, onError, successMessage },
}) {
  try {
    const response = yield call(
      API.website.updateCurrentPageData,
      alias,
      params
    )

    const { data } = response

    if (onSuccess) {
      onSuccess()
    }
    successMessage
      ? toastr.success(successMessage)
      : toastr.success(i18n.t("edit.success"))

    yield put(websiteUpdateCurrentPageData.updateCompleted())
    yield put(websiteCurrentPageData.updateData({ ...data.data }))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      websiteUpdateCurrentPageData.updateRejected({
        errors,
        message,
      })
    )
    if (onError) {
      onError(errors)
    }
  }
}

export function* watchUpdateItem() {
  yield takeEvery(websiteUpdateCurrentPageData.update, updateItem)
}

function* updateSaga() {
  yield all([fork(watchUpdateItem)])
}

export default updateSaga
