import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { commonHelper, modelHelper } from "helpers"
import { RATE_TYPE_GENERAL } from "consts"

export const RateInfoCell = ({ data }) => {
  const { t } = useTranslation()

  const {
    type,
    date,
    total_fee,
    currency,
    value,
    count,
    period,
    net_for_model,
    agency_commission,
    total,
    label,
    description,
    fashion_model_agency,
    agency_percent,
    agency_on,
  } = data

  const getCurrencyLabel = currency =>
    commonHelper.currency.getCurrency(currency)?.label?.toUpperCase()

  return (
    <div className={"text-nowrap"}>
      <div>
        {type === RATE_TYPE_GENERAL ? (
          <>{modelHelper.rate.getRateType(type)?.label} </>
        ) : null}
        {date ? <>{moment(date).format("DD/MM/YYYY")} </> : null}
        <span className={"fw-bold"}>
          {total_fee} {getCurrencyLabel(currency)}
        </span>{" "}
        [{value} x {count} {modelHelper.rate.getRatePeriodType(period)?.label}]
      </div>
      <div>
        [{t("net_for_model")}: {net_for_model} {getCurrencyLabel(currency)}]
      </div>
      <div>
        [{t("agency_commission")}: {agency_commission}% -{" "}
        {t("total_to_invoice")}:{" "}
        <span className={"fw-bold"}>
          {total} {getCurrencyLabel(currency)}
        </span>
        ]
      </div>
      {label && label !== "" && <div>{label}</div>}
      {description && description !== "" && <div>{description}</div>}
      {fashion_model_agency && (
        <div>
          [{t("mother_agency_commission")}: {agency_percent}% {t("of-the")}{" "}
          <span className={"text-lowercase"}>
            {modelHelper.agency.getAgencyProfitTypeOption(agency_on)?.label}
          </span>
          ]
        </div>
      )}
    </div>
  )
}

RateInfoCell.propTypes = {
  data: PropTypes.object,
}
