import _ from "lodash"

import i18n from "i18n"
import { IMPORT_ENTITY_TYPE } from "consts"

export const getEntityTypes = () => {
  return [
    {
      label: i18n.t("models"),
      value: IMPORT_ENTITY_TYPE.MODELS,
      example:
        "https://docs.google.com/spreadsheets/d/1zwVWTMiK-qrCgl9w0IMJd6K0JWet6nDSn8v_NgO-xL4/edit?usp=sharing",
    },
    {
      label: i18n.t("agencies"),
      value: IMPORT_ENTITY_TYPE.AGENCIES,
      example:
        "https://docs.google.com/spreadsheets/d/1-KwcW7tp6cPsZj-l6ZDHX7PwtIiV8519xEKElKBn3n4/edit?usp=sharing",
    },
    {
      label: i18n.t("clients"),
      value: IMPORT_ENTITY_TYPE.CLIENTS,
      example:
        "https://docs.google.com/spreadsheets/d/1c98h7y2glj1HR6k4qJQNQdW7tF7-lZsqp2xJ9bSe3WY/edit?usp=sharing",
    },
  ]
}

export const getEntityType = value => {
  const options = getEntityTypes()
  return _.find(options, { value: value })
}
