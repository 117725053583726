import React from "react"
import PropTypes from "prop-types"
import { Alert } from "reactstrap"
import classNames from "classnames"

import i18n from "i18n"
import { OverlayLoading } from "../components"

export default () => {
  const anon = ({
    isLoading,
    isFailed = false,
    error,
    children,
    spinnerSize = "lg",
    customLoaderClass = null,
    center = true,
    ...rest
  }) =>
    !isFailed ? (
      <div className={"overlay-block"}>
        {children}

        <OverlayLoading isLoading={isLoading} spinnerSize={spinnerSize} />
      </div>
    ) : (
      <Alert color={"danger"}>{error || i18n.t("error")}</Alert>
    )

  return anon
}
