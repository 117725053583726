import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const websiteReorderMenuItemsSlice = createSlice({
  name: "websiteReorderMenuItems",
  initialState,
  reducers: {
    reorder(state) {
      state.loading = true
      state.error = null
    },
    reorderCompleted(state) {
      state.loading = false
    },
    reorderRejected(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})

export const websiteReorderMenuItems = websiteReorderMenuItemsSlice.actions

export default websiteReorderMenuItemsSlice.reducer
