import { all } from "redux-saga/effects"

import uploadSaga from "./upload/saga"
import listSaga from "./list/saga"
import editSaga from "./edit/saga"
import removeSaga from "./remove/saga"

function* attachmentSaga() {
  yield all([uploadSaga(), listSaga(), editSaga(), removeSaga()])
}

export default attachmentSaga
