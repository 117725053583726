import {
  MODEL_PANEL_PHOTOS_FETCH_LIST,
  MODEL_PANEL_PHOTOS_FETCH_LIST_SUCCESS,
  MODEL_PANEL_PHOTOS_FETCH_LIST_ERROR,
  MODEL_PANEL_PHOTOS_LIST_CLEAN_STATE,
  MODEL_PANEL_PHOTOS_LIST_SET_PAGE,
  MODEL_PANEL_PHOTOS_LIST_UPDATE_ITEM,
} from "./actionTypes"

export const modelPanelPhotosFetchList = (id, params) => {
  return {
    type: MODEL_PANEL_PHOTOS_FETCH_LIST,
    payload: { id, params },
  }
}

export const modelPanelPhotosFetchListSuccess = data => {
  return {
    type: MODEL_PANEL_PHOTOS_FETCH_LIST_SUCCESS,
    payload: data,
  }
}

export const modelPanelPhotosFetchListError = data => {
  return {
    type: MODEL_PANEL_PHOTOS_FETCH_LIST_ERROR,
    payload: data,
  }
}

export const modelPanelPhotosListCleanState = () => {
  return { type: MODEL_PANEL_PHOTOS_LIST_CLEAN_STATE }
}

export const modelPanelPhotosListSetPage = data => {
  return {
    type: MODEL_PANEL_PHOTOS_LIST_SET_PAGE,
    payload: data,
  }
}

export const modelPanelPhotosListUpdateItem = data => {
  return {
    type: MODEL_PANEL_PHOTOS_LIST_UPDATE_ITEM,
    payload: data,
  }
}
