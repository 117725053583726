import { combineReducers } from "redux"

import upload from "./upload/slice"
import remove from "./remove/slice"


const filesReducer = combineReducers({
  upload,
  remove,
})

export default filesReducer