import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFirstMountState } from "react-use"
import toastr from "toastr"
import _ from "lodash"

import {
  BookOverviewCard,
  PackageDataAddButton,
} from "../../../../../../components"
import { PackageDataSlider } from "../../PackageDataSlider"
import { useFetchData } from "../../../../../../hooks"
import { API } from "../../../../../../api"

import * as HOC from "../../../../../../HOC"

const DataShower = HOC.withDataShowerOverlay()

export const BooksSlider = props => {
  const { t } = useTranslation()

  const isFirstMount = useFirstMountState()
  const { loading, data, fetchData } = useFetchData()

  const [page, setPage] = useState(1)
  const [book, setBook] = useState(props.data?.first_book || {})

  const onPageChange = value => {
    setPage(value)
  }

  useEffect(() => {
    if (!isFirstMount) {
      onFetchData()
    }
  }, [page])

  const onFetchData = async () => {
    const params = {
      per_page: 1,
      page,
    }
    const request = API.model.getModelBooks(props.data.id, params)
    await fetchData({
      request,
      onError: onFetchError,
    })
  }

  const onFetchError = () => {
    toastr.error(t("error"))
  }

  useEffect(() => {
    if (!isFirstMount) {
      const book = data?.data[0]
      setBook(book)
    }
  }, [data?.data])

  return (
    <div className={"w-100"}>
      <DataShower isLoading={loading} isFailed={null} error={null}>
        <PackageDataSlider
          title={props.data?.name}
          onPageChange={onPageChange}
          currentPage={page}
          count={props.data?.books_count}
          countPrefix={t("book")}
          renderSlide={() => (
            <BookOverviewCard
              key={`package-slide-${book?.id}`}
              cover={book?.cover?.photo?.attachment?.src}
              crops={book?.cover?.photo?.crops}
              size={"sm"}
              actions={
                <PackageDataAddButton
                  status={!!_.find(props.pickedBooks, { id: book.id })}
                  onClick={() => props.onAddBook(book)}
                  disabled={false}
                />
              }
            />
          )}
        />
      </DataShower>
    </div>
  )
}

BooksSlider.propTypes = {
  data: PropTypes.object,
  pickedBooks: PropTypes.array,
  onAddBook: PropTypes.func,
}
