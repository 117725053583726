import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"

import { MegaTagTitle } from "components"
import { InvoicesContainer } from "../../components/InvoiceComponents"

export const Invoices = props => {
  return (
    <div className={"billing-portal-invoices"}>
      <MegaTagTitle title={"title.billing.invoices"} />

      <Row>
        <Col>
          <InvoicesContainer />
        </Col>
      </Row>
    </div>
  )
}

Invoices.propTypes = {}
