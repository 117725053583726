import { centerCrop, makeAspectCrop } from "react-image-crop"

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
  let crop
  if (mediaWidth > mediaHeight) {
    crop = {
      unit: "%",
      height: 70,
    }
  } else {
    crop = {
      unit: "%",
      width: 70,
    }
  }
  return centerCrop(
    makeAspectCrop(crop, aspect, mediaWidth, mediaHeight),
    mediaWidth,
    mediaHeight
  )
}

export { centerAspectCrop }
