import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "i18n"
import { API } from "api"
import { addModelToGroup } from "./slice"
import { modelProfileAddGroup } from "store"

function* addItem({ payload: { id, params, onSuccess, onError } }) {
  try {
    const response = yield call(API.modelsGroups.updateGroup, id, params)

    const { data } = response

    if (onSuccess) {
      onSuccess()
    }
    toastr.success(i18n.t("add-model-to-group.success"))
    yield put(addModelToGroup.addCompleted())
    yield put(modelProfileAddGroup({ ...data.data }))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      addModelToGroup.addRejected({
        errors,
        message,
      })
    )
    if (onError) {
      onError(errors)
    }
  }
}

export function* watchAddItem() {
  yield takeEvery(addModelToGroup.add, addItem)
}

function* addSaga() {
  yield all([fork(watchAddItem)])
}

export default addSaga
