import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Badge } from "reactstrap"
import classNames from "classnames"
import { socialPostsCalendarHelper } from "helpers"

const { getPublicationStatusConfig } = socialPostsCalendarHelper.postInfo

export const PostPublicationStatusBadge = ({ publicationStatus }) => {
  const config = useMemo(() => {
    return getPublicationStatusConfig(publicationStatus)
  }, [publicationStatus])

  return (
    <Badge
      pill
      className={classNames("font-size-12", {
        [`badge-soft-${config?.theme}`]: config?.theme,
      })}
    >
      {config?.label}
    </Badge>
  )
}

PostPublicationStatusBadge.propTypes = {
  publicationStatus: PropTypes.string,
}
