import {
    MODEL_FETCH_PROFILE,
    MODEL_FETCH_PROFILE_SUCCESS,
    MODEL_FETCH_PROFILE_ERROR,
    MODEL_FETCH_PROFILE_CLEAN_STATE,
} from './actionTypes';

export const modelFetchProfile = (id) => {
    return {
        type: MODEL_FETCH_PROFILE,
        payload: { id },
    }
};

export const modelFetchProfileSuccess = () => {
    return {
        type: MODEL_FETCH_PROFILE_SUCCESS,
    }
};

export const modelFetchProfileError = (data) => {
    return {
        type: MODEL_FETCH_PROFILE_ERROR,
        payload: data,
    }
};

export const modelFetchProfileCleanState = () => {
    return { type: MODEL_FETCH_PROFILE_CLEAN_STATE };
};