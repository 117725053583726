import React, { useEffect } from 'react';
import PropTypes from "prop-types"
import { Col, Container, Row } from "reactstrap"
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom"

import { PageHeader, MegaTagTitle } from '../../components'
import { PackageDataPickerContainer, PackageDataLoader, PackageFormContainer } from './components'
import { packageDataPickerSetValue } from '../../store/actions';

const PackageEditComponent = (props) => {
    const { t } = useTranslation();
    let { id } = useParams();

    useEffect(() => {
        if (props.package) {
            const { books, videos } = props.package;
            props.packageDataPickerSetValue(books, videos);
        }
    }, [props.package]);

    return (
        <React.Fragment>
            <MegaTagTitle title={'title.package.edit'} />

            <div className="page-content">
                <Container fluid>
                    <PageHeader
                        back
                        title={t('title.package.edit')}
                    />

                    <PackageDataLoader id={id}>
                        {props.package && (
                            <Row>
                                <Col md={8}>
                                    <div className={'pe-md-3'}>
                                        <PackageDataPickerContainer />
                                    </div>
                                </Col>

                                <Col md={4} className={'col-divider_start col-divider_md'}>
                                    <div className={'pt-3 ps-md-3'}>
                                        <PackageFormContainer mode={'edit'} />
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </PackageDataLoader>

                </Container>
            </div>
        </React.Fragment>
    );
};

PackageEditComponent.propTypes = {
    package: PropTypes.object,

    packageDataPickerSetValue: PropTypes.func,
}

const mapStateToProps = state => {
    const { data } = state.package.details.data;
    return {
        package: data,
    };
};

const mapDispatchToProps = {
    packageDataPickerSetValue,
};

export const PackageEdit = connect(mapStateToProps, mapDispatchToProps)(PackageEditComponent);