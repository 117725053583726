import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Row } from "reactstrap";

import { Drawer } from '../../../../../../components';
import { VideoForm } from '../VideoForm';
import { VideoDataLoader } from '../VideoDataLoader';
import { modelEditVideo, modelEditVideoCleanState } from '../../../../../../store/actions';
import { ValidationsUtils } from "../../../../../../utils"
import { useDraftPackageDataPicker } from "../../../../../../hooks"

const EditVideoComponent = (props) => {
    const { t } = useTranslation();

    const { updateVideo, isVideoPicked } = useDraftPackageDataPicker();

    const handleCloseDrawer = () => {
        props.onClose();
    }

    const findAndUpdateDraftPackageVideo = (video) => {
        if (isVideoPicked(video?.id)) {
            updateVideo(video.id, video);
        }
    }

    const onSubmitSuccess = (onSubmitProps) => (data) => {
        const { reset } = onSubmitProps;
        reset();

        findAndUpdateDraftPackageVideo(data);

        props.onEditSuccess();
        props.onClose();
    }

    const onSubmitError = (onSubmitProps) => (errors) => {
        const { setError } = onSubmitProps;
        ValidationsUtils.setServerSideErrors(errors, setError);
    }

    const handleSubmit = (params, onSubmitProps) => {
        props.modelEditVideo(props.id, params, onSubmitSuccess(onSubmitProps), onSubmitError(onSubmitProps));
    };

    const onDestroy = () => {
        props.modelEditVideoCleanState();
        props.onDestroy();
    }

    return (
        <React.Fragment>
            <Drawer
                title={t('link_youtube_video')}
                visible={props.visible}
                onClose={handleCloseDrawer}
                placement={'right'}
                className={'drawer-container'}
                destroyOnClose
                onDestroy={onDestroy}
            >
                <VideoDataLoader id={props.id}>
                    <Container fluid>
                        <Row>
                            <VideoForm
                                initialValues={{
                                    ...props.video,
                                }}
                                loading={props.loading}
                                error={props.error}
                                onSubmit={handleSubmit}
                                onCancel={handleCloseDrawer}
                            />
                        </Row>
                    </Container>
                </VideoDataLoader>
            </Drawer>
        </React.Fragment>
    );
};

EditVideoComponent.propTypes = {
    visible: PropTypes.bool,
    onEditSuccess: PropTypes.func,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onDestroy: PropTypes.func,

    video: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.object,

    modelEditVideo: PropTypes.func,
    modelEditVideoCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { data } = state.modelPanel.video.details.data;
    const { loading, error } = state.modelPanel.video.details.edit;
    return {
        video: data,
        loading, error,
    };
};

const mapDispatchToProps = {
    modelEditVideo,
    modelEditVideoCleanState,
};

export const EditVideo = connect(mapStateToProps, mapDispatchToProps)(EditVideoComponent);