import { all } from "redux-saga/effects"

import paySaga from "./pay/saga"
import tariffSaga from "./tariff/sagas"
import billingInfoSaga from "./billingInfo/sagas"
import paymentMethodSaga from "./paymentMethod/sagas"
import invoiceSaga from "./invoice/sagas"
import subscriptionSaga from "./subscription/sagas"

function* billingSaga() {
  yield all([
    paySaga(),
    tariffSaga(),
    billingInfoSaga(),
    paymentMethodSaga(),
    invoiceSaga(),
    subscriptionSaga(),
  ])
}

export default billingSaga
