import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: null,
}

const profileCompanyEditSlice = createSlice({
  name: "profileCompanyEdit",
  initialState,
  reducers: {
    edit(state) {
      state.loading = true
      state.error = null
    },
    editSuccess(state) {
      state.loading = false
    },
    editError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})

/**
 * @namespace
 * @property {function} edit
 * @property {function} editSuccess
 * @property {function} editError
 * @property {function} cleanState
 */

export const profileCompanyEdit = profileCompanyEditSlice.actions

export default profileCompanyEditSlice.reducer
