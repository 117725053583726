import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { useModal } from "hooks"
import { ModelsViewModal } from "../../../ModelsViewModal"

export const ModelsView = ({ agencyId }) => {
  const { t } = useTranslation()

  const { modalRef, closeModal, showModal } = useModal()

  const show = e => {
    e.preventDefault()

    showModal()
  }

  return (
    <>
      <a href={"#"} className={"text-primary"} onClick={show}>
        {t("btn.show")}
      </a>

      <ModelsViewModal
        modalRef={modalRef}
        onCancel={closeModal}
        agencyId={agencyId}
      />
    </>
  )
}

ModelsView.propTypes = {
  agencyId: PropTypes.number,
}
