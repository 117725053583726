import { takeLatest, fork, put, all, call } from "redux-saga/effects"

import { clientStatementList } from "./slice"
import i18n from "i18n"
import { API } from "api"

export function* getList({ payload: { id, params } }) {
  try {
    const response = yield call(API.client.getClientStatements, id, params)

    const { data } = response
    yield put(clientStatementList.getListSuccess(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(clientStatementList.getListError(message))
  }
}

export function* watchGetList() {
  yield takeLatest(clientStatementList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
