import React from "react"
import PropTypes from "prop-types"
import { PopoverBody, UncontrolledPopover } from "reactstrap"

export const PriorityMenu = ({ placement = "right", id, children = null }) => {
  return (
    <UncontrolledPopover
      className={"calendar-event-model-action-priority-menu"}
      trigger="legacy"
      placement={placement}
      target={id}
    >
      <PopoverBody>{children}</PopoverBody>
    </UncontrolledPopover>
  )
}

PriorityMenu.propTypes = {
  id: PropTypes.string,
  placement: PropTypes.oneOf(["left", "right", "top", "bottom"]),
  children: PropTypes.node.isRequired,
}
