import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { Alert, Col } from "reactstrap"
import { Button, ControllerHF, InputField } from "../../../../../../components"

export const EditPaymentDetailsForm = props => {
  const { t } = useTranslation()

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      payment_details: "",
      ...props.initialValues,
    },
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
      edit_payment: 1,
    }

    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Col md={8}>
          <ControllerHF
            name={`payment_details`}
            control={control}
            component={InputField}
            id={`payment_details`}
            type="textarea"
            rows={5}
          />
        </Col>
        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button title={"cancel"} color="light" outline onClick={onCancel} />
          </div>
        </div>
      </form>
    </>
  )
}

EditPaymentDetailsForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
