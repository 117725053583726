import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import i18n from "../../../../i18n"
import { API } from "../../../../api"
import { clientAddLogo } from "./slice"
import { clientProfileData } from "../../profile/actions"

function* addLogo({ payload: { id, params, onSuccess, onError } }) {
  try {
    const response = yield call(API.client.addClientAvatar, id, params)

    const { data } = response
    if (onSuccess) {
      onSuccess()
    }
    yield put(clientAddLogo.addCompleted())

    yield put(clientProfileData.updateData({ avatar: { src: data.data?.src } }))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")
    if (onError) {
      onError(errors)
    }
    yield put(
      clientAddLogo.addRejected({
        errors,
        message,
      })
    )
  }
}

export function* watchAddLogo() {
  yield takeEvery(clientAddLogo.add, addLogo)
}

function* addSaga() {
  yield all([fork(watchAddLogo)])
}

export default addSaga
