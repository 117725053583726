import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

import { EDITOR_DRAFT_DOCX_TOOLBAR_OPTIONS } from "consts"
import {
  DocumentTextEntryControlPickerFormSection,
  TABS_KEY,
} from "../DocumentTextEntryControlPickerFormSection"

export { TABS_KEY }

export const ContractDocumentTextEntryControlPickerFormSection = ({
  editorProps,
  fileProps,
  ...props
}) => {
  const { t } = useTranslation()

  editorProps = {
    name: "contract_text",
    id: "contract_text",
    label: t("contract_text"),
    placeholder: t("contract_text"),
    toolbar: {
      options: EDITOR_DRAFT_DOCX_TOOLBAR_OPTIONS,
    },
    ...editorProps,
  }

  return (
    <DocumentTextEntryControlPickerFormSection
      {...props}
      editorProps={editorProps}
      fileProps={fileProps}
    />
  )
}

ContractDocumentTextEntryControlPickerFormSection.propTypes = {
  value: PropTypes.oneOf([TABS_KEY.TEXT, TABS_KEY.FILE]),
  onChange: PropTypes.func,
  editorProps: PropTypes.object,
  fileProps: PropTypes.object,
}
