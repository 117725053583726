import React from "react"
import PropTypes from "prop-types"
import { SocialNetworkIcon } from "./SocialNetworkIcon"
import { SOCIAL_NETWORK_LIST } from "consts"

export const SocialNetworkButton = ({ name, size, onClick, disabled }) => {
  const handleClick = e => {
    onClick(e)
  }
  return (
    <button
      type={"button"}
      id={`social-button-${name}`}
      onClick={handleClick}
      disabled={disabled}
      className={"icon-btn"}
    >
      <SocialNetworkIcon name={name} size={size} />
    </button>
  )
}

SocialNetworkButton.propTypes = {
  name: PropTypes.oneOf(SOCIAL_NETWORK_LIST).isRequired,
  size: PropTypes.string,
  value: PropTypes.string,
  config: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
