import _ from "lodash"

import i18n from '../../i18n';
import {
    MODEL_DOCUMENT_TYPE_DRIVER_LICENSE,
    MODEL_DOCUMENT_TYPE_PASSPORT,
    MODEL_DOCUMENT_TYPE_ID_CARD,
    MODEL_DOCUMENT_TYPE_SCHENGEN_VISA,
    MODEL_DOCUMENT_TYPE_VISA,
    MODEL_DOCUMENT_TYPE_OTHER,
} from '../../consts';

export const getDocumentsOptions = () => {
    return [{
        label: i18n.t('document-type.driver_license'),
        value: MODEL_DOCUMENT_TYPE_DRIVER_LICENSE,
    }, {
        label: i18n.t('document-type.passport'),
        value: MODEL_DOCUMENT_TYPE_PASSPORT,
    }, {
        label: i18n.t('document-type.id_card'),
        value: MODEL_DOCUMENT_TYPE_ID_CARD,
    }, {
        label: i18n.t('document-type.schengen_visa'),
        value: MODEL_DOCUMENT_TYPE_SCHENGEN_VISA,
    }, {
        label: i18n.t('document-type.visa'),
        value: MODEL_DOCUMENT_TYPE_VISA,
    }, {
        label: i18n.t('document-type.other'),
        value: MODEL_DOCUMENT_TYPE_OTHER,
    }];
};

export const getDocument = (value) => {
    const options = getDocumentsOptions();
    return _.find(options, { value: value });
};