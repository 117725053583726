import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"

import { CheckboxField, ControllerHF } from "components"
import { useFormValuesChangedDebounce } from "hooks"

export const DoneForm = props => {
  const { t } = useTranslation()

  const { control, handleSubmit, setError, watch } = useForm({
    defaultValues: {
      done: false,
      ...props.initialValues,
    },
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
    }

    props.onSubmit(params, { setError })
  }

  useFormValuesChangedDebounce(watch, handleSubmit(onSubmit), 0)

  return (
    <>
      <form>
        <ControllerHF
          name={"done"}
          control={control}
          component={CheckboxField}
          id={"done"}
          label={t("mark_as_done")}
          size={"lg"}
          disabled={props.loading}
        />
      </form>
    </>
  )
}

DoneForm.propTypes = {
  loading: PropTypes.bool,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
}
