import {
    PACKAGE_DRAFT_RESET_DATA
} from './actionTypes';

const initialState = {
};

const common = (state = initialState, action) => {
    switch (action.type) {
        case PACKAGE_DRAFT_RESET_DATA:
            state = {
                ...state,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default common;