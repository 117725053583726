import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import moment from "moment"
import { Alert } from "reactstrap"

import { validationSchema } from "./validation"
import {
  TitleInfoSection,
  ServiceSection,
  ArrivalSection,
  AlertSection,
  ModelsSection,
} from "../EventFormSections"
import { Button } from "../../../Buttons"
import {
  getModelsDefaultValues,
  getTitleInfoDefaultValues,
  getServiceDefaultValues,
  getArrivalDefaultValues,
  mapModelsSubmitValues,
  getAlertDefaultValues,
  mapAlertsSubmitValues,
} from "../helpers"

export const EventArrivalForm = props => {
  const { t } = useTranslation()

  const defaultValues = useMemo(() => {
    const {
      id,
      type,
      gender,
      fashion_models,
      // service_category,
      service_name,
      service_details,
      title,
      description,
      date_start,
      time_start,
      alerts,
    } = props.initialValues
    return {
      id,
      type,
      gender,
      // service_category,
      service_name,
      service_details,
      title,
      description,
      ...getArrivalDefaultValues(date_start, time_start),
      ...getModelsDefaultValues(fashion_models),
      ...getAlertDefaultValues(alerts),
    }
  }, [props.initialValues])

  const methods = useForm({
    defaultValues: {
      ...getServiceDefaultValues(),
      ...getTitleInfoDefaultValues(),
      ...defaultValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const { handleSubmit, setError } = methods

  const onSubmit = values => {
    const { arrival_date, arrival_time, ...restValues } = values
    const params = {
      ...restValues,
      date_start: moment(arrival_date).format("YYYY-MM-DD"),
      time_start: arrival_time ? moment(arrival_time).format("HH:mm") : null,
      date_end: moment(arrival_date).format("YYYY-MM-DD"),
      ...mapModelsSubmitValues(values.fashion_models),
      ...mapAlertsSubmitValues(values.alerts),
    }

    props.onSubmit(params, { setError })
  }

  const isDisabled = useMemo(() => {
    return props.loading
  }, [props.loading])

  return (
    <FormProvider {...methods}>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <ServiceSection />

        <hr className="drawer-separator drawer-separator_form" />

        <ArrivalSection place={false} />

        <hr className="drawer-separator drawer-separator_form" />

        <TitleInfoSection />

        <hr className="drawer-separator drawer-separator_form" />

        <ModelsSection
          isEdit={props.isEditMode}
          eventId={props.initialValues.id}
          eventType={props.eventType}
          modelActionBar={{
            cancel: props.isEditMode,
          }}
          onEventUpdate={props.onEventUpdate}
          entity={props.entity}
          entityId={props.entityId}
        />

        <hr className="drawer-separator drawer-separator_form" />

        <AlertSection />

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={isDisabled}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

EventArrivalForm.propTypes = {
  isEditMode: PropTypes.bool,
  eventType: PropTypes.string,
  initialValues: PropTypes.object,
  error: PropTypes.any,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onEventUpdate: PropTypes.func,
  entity: PropTypes.string,
  entityId: PropTypes.number,
}
