import React, { useEffect, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import SimpleBar from "simplebar-react"

import { SelectedItem } from "./SelectedItem"

export const SelectedListControl = ({
  title = null,
  subtitle = null,
  className,
  remove = true,
  ...props
}) => {
  const ref = useRef()
  const { t } = useTranslation()

  useEffect(() => {
    if (ref.current) {
      ref.current.recalculate()
    }
  })

  const onRemove = (item, index) => {
    props.onRemove(item, index)
  }

  const selectedCount = useMemo(() => {
    return props.items.length
  }, [props.items.length])

  return (
    <div
      className={classNames("selected-list-control", {
        [className]: className,
      })}
    >
      {(title || subtitle) && (
        <div className={"selected-list-control__header"}>
          {title && (
            <div className={"selected-list-control__title"}>
              {title} {selectedCount > 0 ? ` (${selectedCount})` : null}
            </div>
          )}
          {subtitle && subtitle}
        </div>
      )}
      <div className={"selected-list-control__body"}>
        {!!props.items.length ? (
          <div className={"selected-list-control__list-wrap"}>
            <SimpleBar className="h-100" ref={ref}>
              {props.items.map((item, index) => (
                <div
                  className={"selected-list-control__item-block"}
                  key={`selected-item-${index}`}
                >
                  {props.renderItem ? (
                    props.renderItem(item, index)
                  ) : (
                    <SelectedItem
                      item={item}
                      index={index}
                      onRemove={() => onRemove(item, index)}
                      renderItemContent={props.renderItemContent}
                      removeBtn={props.removeBtn}
                      remove={remove}
                    />
                  )}
                </div>
              ))}
            </SimpleBar>
          </div>
        ) : props.emptyMessage ? (
          props.emptyMessage
        ) : (
          t("empty")
        )}
      </div>
    </div>
  )
}

SelectedListControl.propTypes = {
  items: PropTypes.array,
  onRemove: PropTypes.func,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  className: PropTypes.string,
  renderItem: PropTypes.func,
  renderItemContent: PropTypes.func,
  emptyMessage: PropTypes.any,
  removeBtn: PropTypes.any,
  remove: PropTypes.bool,
}
