import { createAction } from "@reduxjs/toolkit"

const SOCKET_ON_CHAT_GROUP_UPDATED = "socket/onChatGroupUpdated"

export const socketOnChatGroupUpdated = createAction(
  SOCKET_ON_CHAT_GROUP_UPDATED,
  function prepare(data) {
    return {
      payload: {
        data,
      },
    }
  }
)
