/* eslint react/prop-types: off */
import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Card, CardBody, Col, Row } from "reactstrap"
import { Pagination, Image, ReactTableView } from "components"
import * as HOC from "HOC"
import placeholder from "assets/images/dev/avatar-placeholder-square.svg"
import { Link, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { ROLE_DIRECTOR } from "consts"

const TableDataLoading = HOC.withTableDataLoading()

export const EmployeesTable = props => {
  const { t } = useTranslation()

  const { data: user } = useSelector(state => state.user.profile)
  const history = useHistory()

  const data = useMemo(() => {
    return props.list
  }, [props.list])

  const handleClick = (e, isDirector, employeeId) => {
    e.preventDefault()
    if (!isDirector) {
      let path = user.role === ROLE_DIRECTOR ? "employees" : "team"
      history.push(`/${path}/${employeeId}`)
    }
  }

  const [columns, setColumns] = useState([
    {
      Header: t("employee"),
      accessor: "name",
      id: "name",
      Cell: ({ row: { original } }) => {
        return (
          <Link
            to={`#`}
            className={"d-flex link-secondary"}
            onClick={e => handleClick(e, !original?.department, original.id)}
          >
            <div className={"image-rounded mr-2"}>
              <Image
                key={original.id}
                src={original.avatar || placeholder}
                alt={original.name}
              />
            </div>
            <div className={"align-self-center"}>{original.name}</div>
          </Link>
        )
      },
    },
    {
      Header: t("department"),
      accessor: "department",
      id: "department",
      disableSortBy: true,
      Cell: ({ value }) => value?.name || "",
    },
    {
      Header: t("email"),
      accessor: "email",
      id: "email",
      disableSortBy: true,
      Cell: ({ value }) => value,
    },
    {
      Header: t("earnings"),
      accessor: "earning",
      id: "earning",
      Cell: ({ value }) => value,
    },
    {
      Header: t("castings"),
      accessor: "count_casting",
      id: "number_casting",
      Cell: ({ value }) => value,
    },
    {
      Header: t("jobs-capitalized"),
      accessor: "jobs",
      id: "job",
      Cell: ({ value }) => value,
    },
    {
      Header: t("efficiency-rate"),
      accessor: "castings_per_jobs",
      id: "castings_per_jobs",
      Cell: ({ value }) => value,
    },
    {
      Header: t("earnings-per-jobs"),
      accessor: "earnings_per_jobs",
      id: "earnings_per_jobs",
      Cell: ({ value }) => `${value} ${props.currency.label.toUpperCase()}`,
    },
  ])

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody className={"bg-neutral"}>
            <TableDataLoading
              isLoading={props.loading}
              isFailed={props.error}
              error={props.error}
            >
              <ReactTableView
                isLoading={props.loading}
                columns={columns}
                data={data}
                initialSort={props.sortValue}
                disableSortBy={false}
                onSort={props.onSortChange}
              />
              <Pagination
                data={props.meta}
                onPageChange={props.onPageChange}
                className={"mt-2"}
              />
            </TableDataLoading>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

EmployeesTable.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  sortValue: PropTypes.array,
  cleanState: PropTypes.func,
}
