/* eslint react/prop-types: off */
import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { ReactTableView } from "components"

export const ModelStatementsTable = props => {
  const { t } = useTranslation()

  const data = useMemo(() => {
    return props.list
  }, [props.list])

  const columns = [
    {
      Header: t("date"),
      accessor: "date",
      id: "date",
      Cell: ({ value }) => {
        return value ? moment(value).format("DD/MM/YYYY") : "-"
      },
    },
    {
      Header: t("client"),
      accessor: "client_name",
      id: "client_name",
      Cell: ({ value }) => {
        return value || null
      },
    },
    {
      Header: t("description"),
      accessor: "description",
      id: "description",
      Cell: ({ value }) => value,
    },
    {
      Header: t("gross_amount"),
      accessor: "gross_amount",
      id: "gross_amount",
      Cell: ({ row: { original } }) => {
        const { value_gross } = original
        return value_gross ? value_gross : null
      },
    },
    {
      Header: () => <span className={"text-uppercase"}>{`+ ${t("net")}`}</span>,
      accessor: "net_plus",
      id: "net_plus",
      Cell: ({ value }) => value,
    },
    {
      Header: () => <span className={"text-uppercase"}>{`- ${t("net")}`}</span>,
      accessor: "net_minus",
      id: "net_minus",
      Cell: ({ value }) => value,
    },
  ]

  return (
    <ReactTableView
      columns={columns}
      data={data}
      bodyClass={"bg-transparent"}
    />
  )
}

ModelStatementsTable.propTypes = {
  list: PropTypes.array,
}
