import { takeLatest, fork, put, all, call } from "redux-saga/effects"

import i18n from "i18n"
import { clientStatementEditData } from "./slice"
import { API } from "api"

export function* getData({ payload: { id, params } }) {
  try {
    const response = yield call(API.client.getClientStatement, id, params)

    const { data } = response

    yield put(clientStatementEditData.getDataSuccess(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(clientStatementEditData.getDataError(message))
  }
}

export function* watchGetData() {
  yield takeLatest(clientStatementEditData.getData, getData)
}

function* dataSaga() {
  yield all([fork(watchGetData)])
}

export default dataSaga
