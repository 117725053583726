import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Tooltip } from "reactstrap"

import { InputField, Button } from "components"
import { useCopyToClipboard } from "hooks"

export const Input = ({ value = "" }) => {
  const { t } = useTranslation()

  const { state: copyState, onCopy, onReset } = useCopyToClipboard({})

  const [tooltipOpen, setTooltipOpen] = useState(false)

  const inputRef = useRef(null)
  const domElementId = "package-link-btn"

  const toggleTooltip = () => {
    if (copyState.copied && !tooltipOpen) {
      onReset()
    }

    setTooltipOpen(prevState => !prevState)
  }

  const handleCopy = event => {
    onCopy(value)

    inputRef.current && inputRef.current?.select()

    toggleTooltip()
  }

  return (
    <InputField
      fieldRef={inputRef}
      id={"link"}
      name={"link"}
      label={t("link")}
      value={value}
      placeholder={t("link")}
      meta={{}}
      readOnly
      appendAccessory={[
        <React.Fragment key={"copy"}>
          <Button
            id={domElementId}
            renderTitle={null}
            icon={"fas fa-copy"}
            onClick={handleCopy}
          />
          <Tooltip
            // key={copyState?.copied}
            target={domElementId}
            isOpen={tooltipOpen}
            toggle={toggleTooltip}
          >
            {copyState?.copied ? t("copied_to_clipboard") : t("copy")}
          </Tooltip>
        </React.Fragment>,
      ]}
    />
  )
}

Input.propTypes = {
  value: PropTypes.string,
}
