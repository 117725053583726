import { combineReducers } from "redux"

import list from "./list/slice"
import drawerList from "./drawerList/slice"
import updateBook from "./updateBook/slice"

const modelsReducer = combineReducers({
  list,
  drawerList,
  updateBook,
})

export default modelsReducer
