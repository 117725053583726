import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import "./i18n"

import configureStore from "./store"
import { FacebookContextProvider } from "packages/facebook"
import { appAnalyticsService } from "services"
import { CookiesProvider } from "react-cookie"

const { store, persistor } = configureStore()

// Init app analytics (gtm and etc)
appAnalyticsService.init()

const app = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <FacebookContextProvider>
        <CookiesProvider>
          <BrowserRouter>
            <Suspense fallback="">
              <App />
            </Suspense>
          </BrowserRouter>
        </CookiesProvider>
      </FacebookContextProvider>
    </PersistGate>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
