import { all, call, fork, put, takeLatest } from "redux-saga/effects"
import i18n from "i18n"
import { API } from "api"
import { RequestUtils } from "utils"
import { analyticsTablesDataList } from "./slice"

export function* getList({ payload: { params, alias } }) {
  try {
    let requestParams = {
      includes: [],
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response = yield call(
      API.analytics.getGraphData,
      alias,
      requestParams
    )

    const { data } = response
    yield put(analyticsTablesDataList.getListCompleted(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(analyticsTablesDataList.getListRejected(message))
  }
}

export function* watchGetList() {
  yield takeLatest(analyticsTablesDataList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
