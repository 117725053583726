import {
    PROFILE_CHANGE_PASSWORD,
    PROFILE_CHANGE_PASSWORD_SUCCESS,
    PROFILE_CHANGE_PASSWORD_ERROR,
    PROFILE_CHANGE_PASSWORD_CLEAN_STATE,
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
};

const password = (state = initialState, action) => {
    switch (action.type) {
        case PROFILE_CHANGE_PASSWORD:
            state = {
                ...state,
                loading: true,
                error: null,
            };
            break;

        case PROFILE_CHANGE_PASSWORD_SUCCESS:
            state = {
                ...state,
                loading: false,
            };
            break;

        case PROFILE_CHANGE_PASSWORD_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        case PROFILE_CHANGE_PASSWORD_CLEAN_STATE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default password;