import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Col, Row } from "reactstrap"
import * as Yup from "yup"
import {
  Button,
  CheckboxField,
  ComboboxFormField,
  ControllerHF,
  DateInputField,
  InputField,
} from "components"
import moment from "moment"
import { modelHelper } from "helpers"
import { useFetchBookers } from "hooks"
import { GroupsSection } from "./GroupsSection"
import { NormalizeUtils } from "../../../../../../utils"
import { MODEL_AGENCY_AS_TYPE_MOTHER } from "../../../../../../consts"

const ethnicCategories = modelHelper.general.getEthnicCategories()
const taxResidenceOptions = modelHelper.general.getTaxResidenceBoolOptions()

const PERCENT_VALUES = {
  min: 0,
  max: 100,
}

export const EditProfileForm = props => {
  const { t } = useTranslation()
  const { data: bookers, fetchData: fetchBookers } = useFetchBookers()
  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      is_eu: false,
      is_in_town: false,
      ethnic: null,
      influencer: false,
      plus_size: false,
      percentage_mother: "",
      ...props.initialValues,
    },
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required("field.error.required"),
        gender: Yup.mixed().nullable().required("field.error.required"),
        percentage_mother: Yup.number()
          .min(PERCENT_VALUES.min, "field.error.percent.between")
          .max(PERCENT_VALUES.max, "field.error.percent.between")
          .nullable(true)
          .transform((v, o) => (o === "" ? null : v)),
      })
    ),
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
      gender: values.gender.value,
      birthday: values.birthday
        ? moment(values.birthday).format("YYYY-MM-DD")
        : null,
      nationality_id: values.nationality ? values.nationality.id : null,
      residence_id: values.residence ? values.residence.id : null,
      in_agency_since: values.in_agency_since
        ? moment(values.in_agency_since).format("YYYY-MM-DD")
        : null,
      booker_id: values.booker ? values.booker.id : null,
      tax_residence: values.tax_residence ? values.tax_residence.value : null,
    }

    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }

  useEffect(() => {
    fetchBookers()
  }, [])
  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={4}>
            <ControllerHF
              name="name"
              control={control}
              component={InputField}
              label={t("field.label.name")}
              placeholder={t("field.placeholder.name")}
              id={"name"}
            />
          </Col>
          <Col md={4}>
            <ControllerHF
              name="surname"
              control={control}
              component={InputField}
              label={t("field.label.surname")}
              placeholder={t("field.placeholder.surname")}
              id={"surname"}
            />
          </Col>
          <Col md={4}>
            <ControllerHF
              control={control}
              component={ComboboxFormField}
              name={"gender"}
              id={"gender"}
              label={t("gender")}
              placeholder={t("gender")}
              options={modelHelper.general.getGenderOptions()}
              selectProps={{
                isSearchable: false,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <ControllerHF
              control={control}
              component={DateInputField}
              name="birthday"
              label={t("birthday")}
              placeholder={"DD/MM/YYYY"}
              id={"birthday"}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            />
          </Col>
          <Col md={4}>
            <ControllerHF
              control={control}
              component={InputField}
              name="birthdayplace"
              label={t("birthplace")}
              placeholder={t("birthplace")}
              id={"birthdayplace"}
            />
          </Col>
          <Col md={4}>
            <ControllerHF
              name={"booker"}
              control={control}
              component={ComboboxFormField}
              id={"booker"}
              label={t("booker")}
              placeholder={t("booker")}
              options={bookers}
              getOptionLabel={option => `${option.name}`}
              getOptionValue={option => option.id}
            />
          </Col>
        </Row>

        <Row>
          <Col md={8} lg={3}>
            <ControllerHF
              control={control}
              component={ComboboxFormField}
              name={"nationality"}
              id={"nationality"}
              label={t("nationality")}
              placeholder={t("nationality")}
              options={props.countryOptions}
              getOptionLabel={option => `${option.name}`}
              getOptionValue={option => `${option.id}`}
              selectProps={{
                isClearable: true,
              }}
            />
          </Col>
          <Col md={4} lg={1}>
            <ControllerHF
              control={control}
              component={CheckboxField}
              name={"is_eu"}
              id={"is_eu"}
              label={t("eu")}
              wrapClass={"mb-3 form-checkbox-control"}
              size={"lg"}
            />
          </Col>
          <Col md={8} lg={4}>
            <ControllerHF
              control={control}
              component={ComboboxFormField}
              name={"residence"}
              id={"residence"}
              label={t("residence_country")}
              placeholder={t("residence_country")}
              options={props.countryOptions}
              getOptionLabel={option => `${option.name}`}
              getOptionValue={option => `${option.id}`}
              selectProps={{
                isClearable: true,
              }}
            />
          </Col>
          <Col md={8} lg={4}>
            <ControllerHF
              control={control}
              component={ComboboxFormField}
              name={"ethnic"}
              id={"ethnic"}
              label={t("ethnic_category")}
              placeholder={t("ethnic_category")}
              options={ethnicCategories}
              normalize={option => (option !== null ? option.value : null)}
              isDetermineValue
              selectProps={{
                isClearable: true,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4} lg={2}>
            <ControllerHF
              control={control}
              component={CheckboxField}
              name={"influencer"}
              id={"influencer"}
              label={t("influencer")}
              wrapClass={"mt-3 form-checkbox_highlight"}
              size={"lg"}
              color={"secondary"}
            />
          </Col>
          <Col sm={4} lg={2}>
            <ControllerHF
              control={control}
              component={CheckboxField}
              name={"plus_size"}
              id={"plus_size"}
              label={t("plus_size")}
              wrapClass={"mt-3"}
              size={"lg"}
            />
          </Col>
        </Row>
        <Row>
          <Col md={8} lg={4}>
            <ControllerHF
              name={"percentage_mother"}
              control={control}
              component={InputField}
              id={"percentage_mother"}
              label={t("job_percentage")}
              placeholder={t("job_percentage")}
              normalize={NormalizeUtils.onlyNumbers}
              translateParams={{
                min: PERCENT_VALUES.min,
                max: PERCENT_VALUES.max,
              }}
              className={"mb-3 mt-3"}
              // appendAccessory={[
              //     <span key={'1'} className="input-group-text">%</span>
              // ]}
            />
          </Col>
        </Row>
        <hr className="drawer-separator drawer-separator_form" />
        <Row>
          <Col md={8} lg={4}>
            <ControllerHF
              control={control}
              component={InputField}
              name="bank_name"
              label={t("bank-name")}
              placeholder={t("bank-name")}
              id={"bank_name"}
            />
          </Col>
          <Col md={8} lg={4}>
            <ControllerHF
              control={control}
              component={InputField}
              name="bank_account_number"
              label={t("bank-account-number")}
              placeholder={t("bank-account-number")}
              id={"bank_account_number"}
            />
          </Col>
          <Col md={8} lg={4}>
            <ControllerHF
              control={control}
              component={InputField}
              name="swift_code"
              label={t("swift-code")}
              placeholder={t("swift-code")}
              id={"swift_code"}
            />
          </Col>
          <Col md={8} lg={4}>
            <ControllerHF
              control={control}
              component={ComboboxFormField}
              name={"tax_residence"}
              id={"tax_residence"}
              label={t("tax-residence")}
              placeholder={t("tax-residence")}
              options={taxResidenceOptions}
              selectProps={{
                isClearable: true,
              }}
            />
          </Col>
          <Col md={8} lg={4}>
            <ControllerHF
              control={control}
              component={InputField}
              name="vat"
              label={t("vat")}
              placeholder={t("vat")}
              id={"vat"}
            />
          </Col>

          <Col md={8} lg={4}>
            <ControllerHF
              control={control}
              component={InputField}
              name="fiscal_code"
              label={t("fiscal_code")}
              placeholder={t("fiscal_code")}
              id={"fiscal_code"}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          <Col md={8} lg={4}>
            <ControllerHF
              control={control}
              component={InputField}
              name="website"
              label={t("website")}
              placeholder={t("field.placeholder.website")}
              id={"website"}
            />
          </Col>
        </Row>
        <Row>
          <Col md={8} lg={4}>
            <ControllerHF
              control={control}
              component={DateInputField}
              name="in_agency_since"
              label={t("in_agency_since")}
              placeholder={"DD/MM/YYYY"}
              id={"in_agency_since"}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            />
          </Col>

          <Col md={4} lg={4}>
            <ControllerHF
              control={control}
              component={CheckboxField}
              name={"is_in_town"}
              id={"is_in_town"}
              label={t("set_as_in_town")}
              wrapClass={"form-checkbox-control"}
              size={"lg"}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />
        <Row>
          <Col md={8} lg={4}>
            <ControllerHF
              name={"alert"}
              control={control}
              component={InputField}
              id={"alert"}
              label={t("notes")}
              placeholder={t("notes")}
              type={"textarea"}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />
        <GroupsSection />

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button title={"cancel"} color="light" outline onClick={onCancel} />
          </div>
        </div>
      </form>
    </>
  )
}

EditProfileForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,

  countryOptions: PropTypes.array,
  measurementsOptions: PropTypes.object,
  initialValues: PropTypes.object,
}
