import React, { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import { useEchoSubscriptionEffect } from "hooks"
import { socketOnBillingInvoiceUpdate } from "store/actions"
import { ECHO_EVENT } from "consts"

export const BillingPortalSocketMiddleware = props => {
  const dispatch = useDispatch()

  const { data: user } = useSelector(state => state.user.profile)

  const channel = `company.${user?.company?.id}`

  const events = useMemo(
    () => ({
      // on billing invoice receive
      [ECHO_EVENT.UPDATE_BILLING_INVOICE_EVENT]: data =>
        dispatch(socketOnBillingInvoiceUpdate(data)),
    }),
    [dispatch]
  )

  useEchoSubscriptionEffect({ channel, events })

  return props.children
}
