import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { FilterFormProvider } from "components"
import { clientsList, staticCountryAll } from "store"
import { CLIENTS_PANEL_FILTERS_INITIAL_VALUES } from "consts"
import { SearchSection } from "./FormSections"

const ClientsFilterContainerComponent = props => {
  const dispatch = useDispatch()

  const onFiltersChange = values => {
    const { keywords, city } = values
    values = {
      ...values,
      keywords: keywords.trim(),
      city: city.trim(),
    }
    dispatch(clientsList.changeFilter({ ...values }))
  }

  const defaultValues = useMemo(() => {
    const { keywords, ...restValues } = props.filters.values
    return {
      keywords,
      ...restValues,
    }
  }, [props.filters.values])

  useEffect(() => {
    if (!props.countries.length) {
      dispatch(staticCountryAll.getData({}))
    }
  }, [])

  return (
    <FilterFormProvider
      initialValues={CLIENTS_PANEL_FILTERS_INITIAL_VALUES}
      defaultValues={defaultValues}
      onFiltersChange={onFiltersChange}
    >
      <SearchSection countryOptions={props.countries} />
    </FilterFormProvider>
  )
}

ClientsFilterContainerComponent.propTypes = {
  countries: PropTypes.array,
  filters: PropTypes.object,
}

const mapStateToProps = state => {
  const { data: countries } = state.static.country.all
  const { filters } = state.clients.panel.list
  return {
    countries,
    filters,
  }
}

export const ClientsFilterContainer = connect(mapStateToProps)(
  ClientsFilterContainerComponent
)
