import { takeLatest, fork, put, all, call } from "redux-saga/effects"
import i18n from "../../../../i18n"
import { API } from "../../../../api"
import { RequestUtils } from "../../../../utils"
import { agencyHistoryData } from "./slice"

export function* getData({ payload: { id, params } }) {
  try {
    let requestParams = {
      includes: [],
    }
    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response = yield call(API.agency.getAgencyHistory, id, requestParams)

    const { data } = response

    yield put(agencyHistoryData.getDataCompleted(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(agencyHistoryData.getDataRejected(message))
  }
}

export function* watchGetData() {
  yield takeLatest(agencyHistoryData.getData, getData)
}

function* dataSaga() {
  yield all([fork(watchGetData)])
}

export default dataSaga
