import {
    MODEL_PANEL_GENERAL_FETCH_AGENCY_LIST,
    MODEL_PANEL_GENERAL_FETCH_AGENCY_LIST_SUCCESS,
    MODEL_PANEL_GENERAL_FETCH_AGENCY_LIST_ERROR,
    MODEL_PANEL_GENERAL_FETCH_AGENCY_LIST_CLEAN_STATE,

    MODEL_PANEL_GENERAL_AGENCY_LIST_SET_PAGE,
} from './actionTypes'

export const modelPanelGeneralFetchAgencyList = (id, params) => {
    return {
        type: MODEL_PANEL_GENERAL_FETCH_AGENCY_LIST,
        payload: { id, params },
    }
};

export const modelPanelGeneralFetchAgencyListSuccess = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_FETCH_AGENCY_LIST_SUCCESS,
        payload: data,
    }
};

export const modelPanelGeneralFetchAgencyListError = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_FETCH_AGENCY_LIST_ERROR,
        payload: data,
    }
};

export const modelPanelGeneralFetchAgencyListCleanState = () => {
    return { type: MODEL_PANEL_GENERAL_FETCH_AGENCY_LIST_CLEAN_STATE };
};

export const modelPanelGeneralAgencyListSetPage = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_AGENCY_LIST_SET_PAGE,
        payload: data,
    }
};