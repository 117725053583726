import {
    EMPLOYEE_DETAILS_EDIT_PROFILE,
    EMPLOYEE_DETAILS_EDIT_PROFILE_SUCCESS,
    EMPLOYEE_DETAILS_EDIT_PROFILE_ERROR,
    EMPLOYEE_DETAILS_EDIT_PROFILE_CLEAN_STATE,
} from './actionTypes';

export const employeeDetailsEditProfile = (id, params, onSuccess, onError) => {
    return {
        type: EMPLOYEE_DETAILS_EDIT_PROFILE,
        payload: { id, params, onSuccess, onError },
    }
};

export const employeeDetailsEditProfileSuccess = () => {
    return {
        type: EMPLOYEE_DETAILS_EDIT_PROFILE_SUCCESS,
    }
};

export const employeeDetailsEditProfileError = (data) => {
    return {
        type: EMPLOYEE_DETAILS_EDIT_PROFILE_ERROR,
        payload: data,
    }
};

export const employeeDetailsEditProfileCleanState = () => {
    return { type: EMPLOYEE_DETAILS_EDIT_PROFILE_CLEAN_STATE };
};