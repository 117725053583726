import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { PrintPanelHeader } from "components"

export const StatementPrintHeader = ({ title, period }) => {
  const { t } = useTranslation()

  const [date_start, date_end] = period

  const periodLabel = useMemo(() => {
    let start = date_start ? moment(date_start).format("DD/MM/YYYY") : ""
    let end = date_end ? moment(date_end).format("DD/MM/YYYY") : ""

    return `${start} - ${end}`
  }, [date_start, date_end])

  return (
    <PrintPanelHeader
      title={title}
      subtitle={t("statement")}
    >
      <>
        <h5>{t("period")}:</h5>
        <p>{periodLabel}</p>
      </>
    </PrintPanelHeader>
  )
}

StatementPrintHeader.propTypes = {
  title: PropTypes.string,
  period: PropTypes.array,
}
