import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { IconButton } from "../../../../../../components"
import { modelHelper } from "../../../../../../helpers"

export const DocumentsTable = props => {
  const { t } = useTranslation()

  const inActionPending = id => props.removingItems.includes(id)

  return (
    <div className="table-responsive">
      <table className="table table-nowrap align-middle table-cell_p-v_sm">
        <tbody>
          {props.items.map(item => (
            <tr key={item.id}>
              <td>
                <div className="d-flex">
                  <div className={"float-left align-items-center me-2"}>
                    <div className={"mark-dot-pointer pos-t_d_2"} />
                  </div>

                  <span className={"fw-medium"}>
                    {modelHelper.documents.getDocument(item.type)?.label}

                    {item.files_count && item.files_count !== 0 ? (
                      <>
                        , {item.files_count}{" "}
                        <span className={"text-lowercase"}>
                          {t("attachments")}
                        </span>
                      </>
                    ) : null}
                  </span>
                </div>
              </td>

              <td>
                {item.reference && (
                  <div className={"text-dark"}>{item.reference}</div>
                )}
                {item.date_end && (
                  <div className={"text-dark"}>
                    {`${t("exp")}. ${moment(item.date_end).format(
                      "DD/MM/YYYY"
                    )}`}
                  </div>
                )}
              </td>

              <td
                className={"table-column-sticky_end bg-neutral"}
                style={{ width: "76px" }}
              >
                <IconButton
                  name={"edit"}
                  bg
                  onClick={() => props.onEdit(item)}
                  disabled={inActionPending(item.id)}
                />

                <IconButton
                  name={"remove"}
                  bg
                  onClick={() => props.onRemove(item)}
                  disabled={inActionPending(item.id)}
                  wrapClass={"ms-2"}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

DocumentsTable.propTypes = {
  items: PropTypes.array,
  removingItems: PropTypes.array,

  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
}
