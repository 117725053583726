import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"
import { ComboboxFormField, ControllerHF, FilterTitle } from "components"

export const MeasurementSection = props => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const measurementsSectionsBuilder = [
    {
      title: t("height"),
      fields: ["height_from", "height_to"],
      options: props.measurementsOptions?.height,
    },
    {
      title: t("bust"),
      fields: ["bust_from", "bust_to"],
      options: props.measurementsOptions?.other_length,
    },
    {
      title: t("waist"),
      fields: ["waist_from", "waist_to"],
      options: props.measurementsOptions?.other_length,
    },
    {
      title: t("hips"),
      fields: ["hips_from", "hips_to"],
      options: props.measurementsOptions?.other_length,
    },
    {
      title: t("shoes"),
      fields: ["shoes_from", "shoes_to"],
      options: props.measurementsOptions?.shoes,
    },
  ]

  const renderField = ({ name, options, placeholder, isMulti }) => (
    <ControllerHF
      name={name}
      control={control}
      component={ComboboxFormField}
      id={name}
      label={null}
      placeholder={placeholder}
      options={options}
      getOptionLabel={option => `${option.text}`}
      getOptionValue={option => option.value}
      normalize={value =>
        isMulti ? value.map(item => item.value) : value ? value.value : null
      }
      isMulti={isMulti}
      isDetermineValue
      selectProps={{
        isClearable: true,
      }}
    />
  )

  const renderMeasurementSection = section => {
    const { title, fields, options } = section
    return (
      <>
        <FilterTitle title={title} />

        {fields.map((name, i) => {
          const data = {
            name,
            options,
            placeholder: i === 0 ? t("from") : t("to"),
          }
          return <React.Fragment key={i}>{renderField(data)}</React.Fragment>
        })}
      </>
    )
  }

  return (
    <>
      {measurementsSectionsBuilder.map((section, i) => (
        <React.Fragment key={i}>
          {renderMeasurementSection(section)}
        </React.Fragment>
      ))}

      <FilterTitle title={t("eyes-color")} />
      {renderField({
        name: "eyes_colors",
        placeholder: t("eyes-color"),
        options: props.measurementsOptions?.eyes_color,
        isMulti: true,
      })}

      <FilterTitle title={t("hair-color")} />
      {renderField({
        name: "hair_colors",
        placeholder: t("hair-color"),
        options: props.measurementsOptions?.hair_color,
        isMulti: true,
      })}
    </>
  )
}

MeasurementSection.propTypes = {
  measurementsOptions: PropTypes.object,
}
