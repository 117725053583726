import React, { useMemo } from "react"
import { DrawerContainer, FacebookLoginButton } from "components"
import { useTranslation } from "react-i18next"
import { socialPostsCalendarHelper } from "helpers"
import { RequiredFacebookLogin } from "packages/facebook"
import { usePostsDrawerManager, useSubmitHandler } from "hooks"
import { selectSocialCalendarCreatePost, socialCalendarCreatePost } from "store"
import { useDispatch, useSelector } from "react-redux"
import { PostForm } from "../PostForms"

const { getPostTypeTitle } = socialPostsCalendarHelper.calendarConfig
const { getCreatePostInitialValues } = socialPostsCalendarHelper.postForms

export const CreatePost = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    closeDrawer,
    state: { isCreatePostDrawer, postData },
    navigation: { showPostViewDrawer },
  } = usePostsDrawerManager()

  const { loading, error } = useSelector(selectSocialCalendarCreatePost)

  const title = useMemo(() => {
    if (postData) {
      return t("create") + " " + getPostTypeTitle(postData.type)
    } else {
      return t("create") + ""
    }
  }, [postData])

  const closeCreatePostDrawer = () => {
    closeDrawer()
    dispatch(socialCalendarCreatePost.cleanState())
  }

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        socialCalendarCreatePost.create({
          params,
          onSuccess,
          onError,
        })
      )
    },
    postData => {
      closeCreatePostDrawer()
      postData && showPostViewDrawer(postData)
    }
  )

  return (
    <DrawerContainer
      title={title}
      visible={isCreatePostDrawer}
      onClose={closeCreatePostDrawer}
    >
      <RequiredFacebookLogin component={FacebookLoginButton}>
        <PostForm
          postType={postData?.type}
          error={error}
          loading={loading}
          initialValues={getCreatePostInitialValues(postData?.type, postData)}
          onSubmit={handleSubmit}
          onCancel={closeDrawer}
        />
      </RequiredFacebookLogin>
    </DrawerContainer>
  )
}

CreatePost.propTypes = {}
