import React from "react"
import PropTypes from "prop-types"
import { Label } from "reactstrap"
import classNames from "classnames"

import { FieldError } from "../FieldError"
import { DateRangePicker } from "../../DatePickers"

export const DateRangePickerField = ({
  id,
  label,
  placeholder,
  reference,
  children,
  prependSymbol,
  appendAccessory,
  normalize,
  rightAccessory,
  disabled,
  value,
  meta = {},
  ...props
}) => {
  const { error } = meta

  const showError = Boolean(error)

  const onChange = date => {
    date = normalize ? normalize(date) : date

    if (props.onChange) {
      props.onChange(date)
    }
  }

  const renderByAccessoryType = accessory => {
    if (accessory) {
      if (typeof accessory === "string") {
        return <i className={accessory} />
      } else return accessory
    } else return <></>
  }

  return (
    <div>
      {label ? <Label htmlFor={id}>{label}</Label> : null}
      <div
        className={classNames("input-group", {
          "is-invalid": showError,
        })}
      >
        <DateRangePicker
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          id={id}
          {...props}
        />

        {rightAccessory && renderByAccessoryType(rightAccessory)}

        {appendAccessory && appendAccessory.map(item => item)}
      </div>

      <FieldError
        error={error}
        translateParams={props.translateParams}
        showError={showError}
      />
    </div>
  )
}

DateRangePickerField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  reference: PropTypes.any,
  children: PropTypes.any,
  prependSymbol: PropTypes.any,
  appendAccessory: PropTypes.array,
  normalize: PropTypes.func,
  rightAccessory: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  translateParams: PropTypes.object,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  meta: PropTypes.object,
}
