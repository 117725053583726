import {
    MODEL_VIDEOS_REMOVE_VIDEOS,
    MODEL_VIDEOS_REMOVE_VIDEOS_SUCCESS,
    MODEL_VIDEOS_REMOVE_VIDEOS_ERROR,
    MODEL_VIDEOS_REMOVE_VIDEOS_CLEAN_STATE,
} from './actionTypes';

export const modelVideosRemoveVideos = (videos, onSuccess, onError) => {
    return {
        type: MODEL_VIDEOS_REMOVE_VIDEOS,
        payload: { videos, onSuccess, onError },
    }
};

export const modelVideosRemoveVideosSuccess = () => {
    return {
        type: MODEL_VIDEOS_REMOVE_VIDEOS_SUCCESS,
    }
};

export const modelVideosRemoveVideosError = (data) => {
    return {
        type: MODEL_VIDEOS_REMOVE_VIDEOS_ERROR,
        payload: data,
    }
};

export const modelVideosRemoveVideosCleanState = () => {
    return { type: MODEL_VIDEOS_REMOVE_VIDEOS_CLEAN_STATE };
};