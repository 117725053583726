import { all } from "redux-saga/effects"

import listSaga from "./list/saga"
import editSaga from "./edit/sagas"
import settingsSaga from "./settings/sagas"

function* statementSaga() {
  yield all([listSaga(), editSaga(), settingsSaga()])
}

export default statementSaga
