import React from "react"
import PropTypes from "prop-types"
import { Col, Alert, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import moment from "moment"

import {
  Button,
  ComboboxFormField,
  ControllerHF,
  DateInputField,
  InputField,
  RecipientsContactsField,
} from "components"
import { validationSchema } from "./validation"
import { getEventTypeOptions } from "./helper"

const eventTypeOptions = getEventTypeOptions()

export const EventsEmailSendForm = props => {
  const { t } = useTranslation()

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      date: null,
      type: "all",
      subject: t("events_of_day"),
      message: "",
      recipients: props.recipientsOptions.map(item => ({
        ...item,
        // set first email selected
        emails: item.emails.filter((_, i) => i === 0),
      })),
      ...props.initialValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = values => {
    const { recipients, date, type, ...restValues } = values
    let params = {
      date: moment(date).format("YYYY-MM-DD"),
      type: type !== "all" ? type : null,
      ...restValues,
    }

    let recipientsEmails = []
    recipients.forEach(item => {
      const emails = item.emails.map(email => email)
      recipientsEmails = [...recipientsEmails, ...emails]
    })

    params.emails = recipientsEmails

    props.onSubmit(params, { onReset, setError })
  }

  const onReset = () => {
    reset()
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <ControllerHF
              name={"date"}
              control={control}
              component={DateInputField}
              id={"date"}
              label={t("event_date")}
              placeholder={"DD/MM/YYYY"}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <ControllerHF
              name={"type"}
              control={control}
              component={ComboboxFormField}
              id={"type"}
              label={t("event_type")}
              placeholder={t("event_type")}
              options={eventTypeOptions}
              normalize={option => (option ? option.value : null)}
              isDetermineValue
              selectProps={{
                isSearchable: false,
              }}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          <Col md={12}>
            <ControllerHF
              name={"subject"}
              control={control}
              component={InputField}
              id={"subject"}
              label={t("subject")}
              placeholder={t("subject")}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <ControllerHF
              name={"message"}
              control={control}
              component={InputField}
              id={"message"}
              label={t("message")}
              placeholder={t("message")}
              type={"textarea"}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          <Col>
            <ControllerHF
              name={"recipients"}
              control={control}
              component={RecipientsContactsField}
              id={"recipients"}
              placeholder={t("recipients")}
              limit={null}
              options={props.recipientsOptions}
              onAdd={props.onAddRecipient}
              onRemove={props.onRemoveRecipient}
              translateParams={{ emailsCount: 100 }}
            />
          </Col>
        </Row>

        <div className="mt-3 mb-3 pt-4">
          <div className="button-items">
            <Button
              submit
              title={"btn.send"}
              disabled={props.loading}
              loading={props.loading}
            />
          </div>
        </div>
      </form>
    </>
  )
}

EventsEmailSendForm.propTypes = {
  initialValues: PropTypes.object,
  recipientsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      name: PropTypes.string,
      emails: PropTypes.any,
    })
  ),
  onAddRecipient: PropTypes.func,
  onRemoveRecipient: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,

  onSubmit: PropTypes.func,
}
