import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import {
  IconButton,
  Pagination,
  BookOverviewCard,
  PackageDataAddButton,
} from "../../../../../components"

import * as HOC from "../../../../../HOC"
import _ from "lodash"

const DataShower = HOC.withDataShowerOverlay()

export const BooksGrid = props => {
  const { t } = useTranslation()

  const renderActions = item => {
    const isRemoving = props.removingItems.includes(item.id)
    return (
      <>
        <IconButton
          name={"edit"}
          onClick={() => props.onEdit(item)}
          bg
          disabled={isRemoving}
        />
        <PackageDataAddButton
          status={!!_.find(props.packagePickedBooks, { id: item.id })}
          onClick={() => props.onAddToPackage(item)}
          disabled={isRemoving}
        />
        <IconButton
          name={"view"}
          onClick={() => props.onPublish(item)}
          bg
          disabled={isRemoving}
        />
        <IconButton
          name={"copy"}
          onClick={() => props.onCopy(item)}
          bg
          disabled={isRemoving}
        />
        <IconButton
          name={"remove"}
          onClick={() => props.onRemove(item)}
          bg
          disabled={isRemoving}
        />

        <div className={"mt-4 book-overview-card__actions-block-section"}>
          <IconButton
            name={"mail"}
            onClick={() => props.onSend(item)}
            bg
            disabled={isRemoving}
          />
          <button
            className={"icon-btn bg-dark text-white "}
            type={"button"}
            onClick={() => props.onDownload(item)}
            disabled={isRemoving}
          >
            <span className={"font-size-10 font-weight-semibold"}>
              {t("pdf")}
            </span>
          </button>
        </div>
      </>
    )
  }

  return (
    <div className="model-panel-books-grid">
      <DataShower
        isLoading={props.loading}
        isFailed={props.error}
        error={props.error}
      >
        {!!props.items.length ? (
          <>
            <div className="grid">
              <div className="grid__row">
                {props.items.map((item, i) => (
                  <div
                    className="grid__col"
                    key={`model-book-grid-item-${item.id}`}
                  >
                    <div className="grid__item">
                      <BookOverviewCard
                        title={item.title}
                        description={item.description}
                        cover={item?.cover?.photo?.attachment?.src}
                        crops={item?.cover?.photo?.crops}
                        pagesCount={item.pages_count}
                        actions={renderActions(item)}
                        onCoverClick={
                          props.onCoverClick
                            ? () => props.onCoverClick(item)
                            : null
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <Pagination data={props.meta} onPageChange={props.onPageChange} />
          </>
        ) : !props.loading ? (
          props.empty ? (
            props.empty
          ) : (
            t("empty")
          )
        ) : null}
      </DataShower>
    </div>
  )
}

BooksGrid.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,
  removingItems: PropTypes.array,
  empty: PropTypes.any,

  onPageChange: PropTypes.func,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  onPublish: PropTypes.func,
  onCopy: PropTypes.func,
  onDownload: PropTypes.func,
  packagePickedBooks: PropTypes.array,
  onAddToPackage: PropTypes.func,
  onSend: PropTypes.func,
  onCoverClick: PropTypes.func,
}
