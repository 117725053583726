import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { ComboboxFormField, ControllerHF } from "components"
import { useFetchBookers } from "hooks"
import { useFormContext } from "react-hook-form"

export const Booker = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const { data: bookers, fetchData: fetchBookers, loading } = useFetchBookers()

  useEffect(() => {
    fetchBookers({ pagination: false })
  }, [])

  return (
    <ControllerHF
      name={"booker"}
      control={control}
      component={ComboboxFormField}
      id={"booker"}
      label={t("booker")}
      placeholder={t("booker")}
      options={bookers}
      normalize={option => (option !== null ? option.id : null)}
      getOptionLabel={option => `${option.name}`}
      getOptionValue={option => option.id}
      isDetermineValue
      selectProps={{
        isClearable: true,
        isLoading: loading,
      }}
      wrapClass={"mb-0"}
    />
  )
}

Booker.propTypes = {}
