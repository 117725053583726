import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { Col, Row } from "reactstrap"
import { FILE_MAX_SIZE } from "../../../../../consts/common/maxFileSize"
import { FilesUploader } from "./FilesUploader"

export const AttachFilesSection = props => {
  const { t } = useTranslation()

  const { control, trigger, setError } = useFormContext()

  return (
    <Row>
      <Col md={12}>
        <FilesUploader
          control={control}
          processing={props.processing}
          addFileToProcessing={props.addFileToProcessing}
          removeFileFromProcessing={props.removeFileFromProcessing}
          trigger={trigger}
          setError={setError}
          fileMaxSize={FILE_MAX_SIZE}
          accept={props.accept}
          eventId={props.eventId}
          pickerLabelText={props.filePickerLabelText}
        />
      </Col>
    </Row>
  )
}

AttachFilesSection.propTypes = {
  processing: PropTypes.array,
  addFileToProcessing: PropTypes.func,
  removeFileFromProcessing: PropTypes.func,
  accept: PropTypes.string,
  eventId: PropTypes.number,
  filePickerLabelText: PropTypes.string,
}
