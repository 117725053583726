import React from "react"
import PropTypes from "prop-types"

export const MemberItem = props => {
  return (
    <div
      className={"d-flex w-100 chat-group-manager-selected-list-member-item"}
    >
      <div className={"selected-list-control__item-text flex-fill"}>
        {props.item.name}
      </div>
    </div>
  )
}

MemberItem.propTypes = {
  item: PropTypes.object,
}
