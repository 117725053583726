import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "../../../../i18n"

import { API } from "../../../../api"
import { agencyRemoveProfile } from "./slice"

function* remove({ payload: { id, history } }) {
    try {
        const response = yield call(API.agency.removeAgency, id)

        const { data } = response

        yield put(agencyRemoveProfile.removeCompleted())

        toastr.success(i18n.t("agency.remove.success"))

        history.push("/agencies")

    } catch (error) {
        const errors = error.response?.data?.errors || {}
        const message = error.response?.data?.message || i18n.t("error")

        yield put(
            agencyRemoveProfile.removeRejected({
                errors,
                message,
            })
        )
        toastr.error(message)
    }
}

export function* watchRemove() {
    yield takeEvery(agencyRemoveProfile.remove, remove)
}

function* removeSaga() {
    yield all([fork(watchRemove)])
}

export default removeSaga