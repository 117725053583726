import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Alert, Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { Button, CheckboxField, ControllerHF } from "components"
import { AlertsFormSection } from "./AlertsFormSection"
import { validationSchema } from "./validation"

export const SettingsForm = props => {
  const { t } = useTranslation()

  const defaultValues = useMemo(() => {
    return {
      ...props.initialValues,
    }
  }, [props.initialValues])

  const methods = useForm({
    defaultValues: {
      alert_1_period: null,
      alert_2_period: null,
      apply_for_new: false,
      override_existing: false,
      ...defaultValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const { control, handleSubmit, setError, reset } = methods

  const onSubmit = values => {
    let params = {
      ...values,
    }

    props.onSubmit(params, { reset, setError })
  }

  return (
    <FormProvider {...methods}>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <AlertsFormSection />

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          <Col>
            <ControllerHF
              name={"apply_for_new"}
              control={control}
              component={CheckboxField}
              id={"apply_for_new"}
              label={t("statement.settings.apply_for_new")}
              wrapClass={"mb-3"}
              size={"lg"}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <ControllerHF
              name={"override_existing"}
              control={control}
              component={CheckboxField}
              id={"override_existing"}
              label={
                <span className={"text-primary"}>
                  {t("statement.settings.override_existing")}
                </span>
              }
              wrapClass={"mb-3"}
              size={"lg"}
            />
          </Col>
        </Row>

        <div className="mt-3 pt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

SettingsForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
