import React from "react"
import PropTypes from "prop-types"

export const Logo = ({logo}) => {
  return (
    logo ? (
      <div className="invoice-card-logo">
        <img src={logo} alt={"logo"}/>
      </div>
    ) : null
  )
}

Logo.propTypes = {
  logo: PropTypes.string,
}
