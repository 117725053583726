import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Container, Row } from "reactstrap"
import { useHistory, useRouteMatch } from "react-router-dom"

import { Button, Drawer } from "../../../../../components"
import { PackageDataLoader } from "../../PackageDataLoader"
import { PackageDetailsContainer } from "../../PackageDetailsContainer"

const PackageViewDrawerComponent = props => {
  const { t } = useTranslation()

  const handleCloseDrawer = () => {
    props.onClose()
  }

  let { path } = useRouteMatch()
  const history = useHistory()

  const onEdit = () => {
    history.push(`${path}/${props.id}/edit`)
  }

  const onDestroy = () => {
    props.onDestroy()
  }

  return (
    <React.Fragment>
      <Drawer
        title={props.package ? props.package?.title || t("package_preview") : ""}
        headerRightAccessory={() => (
          <div className={"ms-4"}>
            <Button title={"btn.edit-package"} onClick={onEdit} />
          </div>
        )}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <PackageDataLoader id={props.id}>
          <Container fluid>
            <Row>
              <PackageDetailsContainer />
            </Row>
          </Container>
        </PackageDataLoader>
      </Drawer>
    </React.Fragment>
  )
}

PackageViewDrawerComponent.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.number,
  onDestroy: PropTypes.func,

  package: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.package.details.data
  return {
    package: data,
  }
}

const mapDispatchToProps = {}

export const PackageViewDrawer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackageViewDrawerComponent)
