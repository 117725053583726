export const formInitialValue = cropData => {
  if (cropData) {
    return {
      crop: {
        unit: "px",
        ...cropData?.crop_params,
      },
    }
  } else {
    return {
      crop: {
        unit: "px",
      },
    }
  }
}

export const getModelPhotoRequestBody = (data, type) => {
  const { scale } = data

  let crop_data = {
    [`param_${type}[x]`]: Math.round(data.crop.x * scale),
    [`param_${type}[y]`]: Math.round(data.crop.y * scale),
    [`param_${type}[width]`]: Math.round(data.crop.height * scale),
    [`param_${type}[height]`]: Math.round(data.crop.height * scale),
  }
  return {
    ...crop_data,
  }
}
