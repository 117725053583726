import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { canvasPreview } from "./canvasPreview"

export const CroppedCanvasPreview = React.memo(
  ({ pixelCropCompleted, image, className, aspect, containerStyle }) => {
    const canvasRef = useRef(null)

    useEffect(() => {
      if (pixelCropCompleted && image && canvasRef.current) {
        canvasPreview(image, canvasRef.current, pixelCropCompleted)
      }
    }, [pixelCropCompleted, image])

    const canvasStyle = {
      maxHeight: image?.height,
    }

    return (
      <div
        className={classNames("", {
          [className]: className,
        })}
        style={containerStyle}
      >
        {pixelCropCompleted && (
          <canvas
            ref={canvasRef}
            className={classNames("cropped-canvas-preview", {
              "cropped-canvas-preview__square": aspect >= 1,
              "cropped-canvas-preview__rectangle": aspect < 1,
            })}
            style={canvasStyle}
          />
        )}
      </div>
    )
  }
)

CroppedCanvasPreview.propTypes = {
  pixelCropCompleted: PropTypes.any,
  image: PropTypes.any,
  className: PropTypes.string,
  aspect: PropTypes.number,
  containerStyle: PropTypes.object,
}
