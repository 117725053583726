import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

export const Closed = ({ className, ...props }) => {
  return <i className={classNames("bx bx-key", { [className]: className })} />
}

Closed.propTypes = {
  className: PropTypes.string,
}
