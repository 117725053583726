import React from "react"
import PropTypes from "prop-types"
import { commonHelper } from "helpers"

export const ShareIcon = ({ name }) => {
  const icon = commonHelper.socialNetwork.getConfig(name).icon

  return <img src={icon} alt={name} />
}

ShareIcon.propTypes = {
  name: PropTypes.string,
}
