import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Col, Alert, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { Button, ComboboxFormField, ControllerHF, InputField } from "components"
import { ModelsSection } from "./ModelsSection"
import { useFetchClients } from "hooks"
import { NormalizeUtils } from "utils"

const AGENCY_COMMISSION_VALUES = {
  min: 0,
  max: 100,
}

export const EditProfileForm = props => {
  const { t } = useTranslation()
  const { data, fetchData } = useFetchClients()
  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      name: "",
      website: "",
      note: "",
      client_id: null,
      agency_commission: 10,
      vat: "",
      country_id: null,
      ...props.initialValues,
    },
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required("field.error.required"),
        agency_commission: Yup.number()
          .typeError("field.error.number")
          .min(
            AGENCY_COMMISSION_VALUES.min,
            "field.error.agency_commission.between"
          )
          .max(
            AGENCY_COMMISSION_VALUES.max,
            "field.error.agency_commission.between"
          )
          .nullable(true)
          .transform((v, o) => (o === "" ? null : v)),
      })
    ),
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
      country_id: values.country_id ? values.country_id.id : null,
      client_id: values.client_id ? values.client_id.id : null,
    }
    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={10}>
            <Row>
              <Col lg={6}>
                <ControllerHF
                  name={"name"}
                  control={control}
                  component={InputField}
                  id={"name"}
                  label={t("name")}
                  placeholder={t("name")}
                />
              </Col>
              <Col lg={6}>
                <ControllerHF
                  name={"website"}
                  control={control}
                  component={InputField}
                  id={"website"}
                  label={t("website")}
                  placeholder={t("website")}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ControllerHF
                  name={"client_id"}
                  control={control}
                  component={ComboboxFormField}
                  id={"client_id"}
                  label={t("billing-client")}
                  placeholder={t("billing-client")}
                  options={data}
                  getOptionLabel={option => `${option.name}`}
                  getOptionValue={option => option.id}
                  selectProps={{
                    isClearable: true,
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ControllerHF
                  name={"country_id"}
                  control={control}
                  component={ComboboxFormField}
                  id={"country_id"}
                  label={t("country")}
                  placeholder={t("country")}
                  options={props.countryOptions}
                  getOptionLabel={option => `${option.name}`}
                  getOptionValue={option => option.id}
                />
              </Col>

              <Col md={5} xs={11}>
                <ControllerHF
                  name={"agency_commission"}
                  control={control}
                  component={InputField}
                  id={"agency_commission"}
                  label={t("agency-commission")}
                  placeholder={t("agency-commission")}
                  normalize={NormalizeUtils.onlyNumbers}
                  translateParams={{
                    min: AGENCY_COMMISSION_VALUES.min,
                    max: AGENCY_COMMISSION_VALUES.max,
                  }}
                />
              </Col>
              <Col md={1} xs={1} className={"d-flex align-items-end mb-4"}>
                %
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ControllerHF
                  name={"vat"}
                  control={control}
                  component={InputField}
                  id={"vat"}
                  label={t("VAT")}
                  placeholder={t("VAT")}
                  type={"textarea"}
                />
              </Col>
              <Col md={6}>
                <ControllerHF
                  name={"note"}
                  control={control}
                  component={InputField}
                  id={"note"}
                  label={t("note")}
                  placeholder={t("note")}
                  type={"textarea"}
                />
              </Col>
            </Row>

            <Row className={"mt-3"}>
              <Col md={6}>
                <ModelsSection agencyId={props.agencyId} />
              </Col>
            </Row>

            <div className="mt-3">
              <div className="button-items">
                <Button
                  submit
                  title={"btn.save"}
                  disabled={props.loading}
                  loading={props.loading}
                />

                <Button
                  title={"cancel"}
                  color="light"
                  outline
                  onClick={onCancel}
                />
              </div>
            </div>
          </Col>
          <Col />
        </Row>
      </form>
    </>
  )
}

EditProfileForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  countryOptions: PropTypes.any,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  agencyId: PropTypes.number,
}
