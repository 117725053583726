import {
    EMPLOYEE_DETAILS_FETCH_DATA,
    EMPLOYEE_DETAILS_FETCH_DATA_SUCCESS,
    EMPLOYEE_DETAILS_FETCH_DATA_ERROR,
    EMPLOYEE_DETAILS_DATA_CLEAN_STATE,

    EMPLOYEE_DETAILS_UPDATE_DATA,
} from './actionTypes';

export const employeeDetailsFetchData = (id) => {
    return {
        type: EMPLOYEE_DETAILS_FETCH_DATA,
        payload: { id },
    }
};

export const employeeDetailsFetchDataSuccess = (data) => {
    return {
        type: EMPLOYEE_DETAILS_FETCH_DATA_SUCCESS,
        payload: data,
    }
};

export const employeeDetailsFetchDataError = (data) => {
    return {
        type: EMPLOYEE_DETAILS_FETCH_DATA_ERROR,
        payload: data,
    }
};

export const employeeDetailsDataCleanState = () => {
    return { type: EMPLOYEE_DETAILS_DATA_CLEAN_STATE };
};


export const employeeDetailsUpdateData = (data) => {
    return {
        type: EMPLOYEE_DETAILS_UPDATE_DATA,
        payload: data,
    }
};