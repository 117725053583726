import { combineReducers } from "redux";

import add from './add/reducer';
import list from './list/reducers';
import details from './details/reducers';
import attachments from './attachments/reducers';

const documentsReducer = combineReducers({
    add,
    list,
    details,
    attachments,
});

export default documentsReducer;