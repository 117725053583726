import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { MegaTagTitle } from "components"
import { BillingInformation as BillingInformationCard } from "../components"

export const BillingInformation = props => {
  const { t } = useTranslation()

  return (
    <div className={"billing-portal-billing-information"}>
      <MegaTagTitle title={"billing_information"} />

      <BillingInformationCard />
    </div>
  )
}

BillingInformation.propTypes = {}
