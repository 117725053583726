import { createAction } from "@reduxjs/toolkit"

const SOCKET_ON_CHAT_GROUP_USER_REMOVED = "socket/onChatGroupUserRemoved"

export const socketOnChatGroupUserRemoved = createAction(
  SOCKET_ON_CHAT_GROUP_USER_REMOVED,
  function prepare(data) {
    return {
      payload: {
        data,
      },
    }
  }
)
