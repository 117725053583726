import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { chatGroupsParticipantAdd } from "./slice"

function* add({ payload: { chat, user, onSuccess } }) {
  try {
    const response = yield call(API.chat.addUserToGroup, chat, user)

    const { data } = response

    yield put(chatGroupsParticipantAdd.addSuccess())
    if (onSuccess) {
      onSuccess(data.data)
    }

    toastr.success(i18n.t("chat.group.participant.add.success"))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      chatGroupsParticipantAdd.addError({
        errors,
        message,
      })
    )
    toastr.error(message)
  }
}

export function* watchAdd() {
  yield takeEvery(chatGroupsParticipantAdd.add, add)
}

function* addSaga() {
  yield all([fork(watchAdd)])
}

export default addSaga
