import React from "react"
import { useSelector } from "react-redux"
import { Badge } from "reactstrap"

export const ChatUnreadBadge = () => {
  const { data: user } = useSelector(state => state.user.profile)

  const count = user?.unread_messages || null

  return (
    <Badge
      pill
      className={"font-size-12 badge-soft-secondary rounded-pill float-end"}
    >
      {count}
    </Badge>
  )
}
