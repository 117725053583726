import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { ControllerHF, InputField } from "components"
import { FilesPicker } from "./FilesPicker"

export const InputSection = props => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const onKeyPress = e => {
    const { key, value } = e
    if (key === "Enter") {
      props.onSubmit()
    }
  }

  const onAttach = files => {
    // make show modal with attachments, caption and send message
    props.onAttachFiles(files)
  }

  return (
    <div className="position-relative">
      <ControllerHF
        name={"body"}
        control={control}
        component={InputField}
        id={"body"}
        label={null}
        placeholder={`${t("chat.input.placeholder")}...`}
        disabled={props.disabled}
        rightAccessory={
          <div className={"form-right-accessory"}>
            <FilesPicker disabled={props.disabled} onChange={onAttach} />
          </div>
        }
        meta={{
          error: null,
        }}
        fieldRef={props.inputRef}
        maxLength="10000"
        onKeyPress={onKeyPress}
        autoComplete="off"
        className={"mb-0"}
      />
    </div>
  )
}

InputSection.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  inputRef: PropTypes.any,
  onAttachFiles: PropTypes.func,
}
