import React, { useRef } from 'react';
import PropTypes from "prop-types"
import classNames from "classnames";
import { useWindowSize } from 'react-use';

import { Sidebar } from './Sidebar';
import { Drawer } from './Drawer';

const windowWidth = 1200;

export const PanelSidebar = (props) => {
    const containerRef = useRef();

    const { width } = useWindowSize();

    return (
        <div className={classNames('panel-sidebar', {
            [props.className]: props.className,
        })}>
            {width >= windowWidth && (
                <Sidebar>
                    {props.children}
                </Sidebar>
            )}

            <div className="panel-sidebar__drawer" ref={containerRef}>
                {width < windowWidth && (
                    <Drawer
                        className={props.drawerClassName}
                        visible={props.drawerVisible}
                        toggleVisible={props.toggleDrawerVisible}
                        container={containerRef.current}
                    >
                        {props.children}
                    </Drawer>
                )}
            </div>
        </div>
    );
};

PanelSidebar.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    drawerClassName: PropTypes.string,
    drawerVisible: PropTypes.bool,
    toggleDrawerVisible: PropTypes.func,
}