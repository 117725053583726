import { combineReducers } from "redux"

import update from "./update/slice"
import upload from "./upload/slice"
import remove from "./remove/slice"
import photos from "./photos/reducer"
import set from "./set/reducer"

const avatarReducer = combineReducers({
  update,
  photos,
  set,
  upload,
  remove,
})

export default avatarReducer
