import moment from "moment"

export const generateAlerts = (date, alerts = [], reminders = []) => {
  let newAlerts = []

  for (let i = 0; i < reminders.length; i++) {
    let { sort, period } = reminders[i]

    const getMomentPeriod = period => {
      switch (period) {
        case "day":
          return "days"
        case "week":
          return "weeks"
        case "month":
          return "months"
        default:
          return null
      }
    }

    const momentPeriod = getMomentPeriod(period)

    let newDate = moment(date)

    newDate.set({
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: 0,
    })

    newDate = newDate.subtract(1, momentPeriod)

    let newAlert = {
      date_start: newDate.toDate(),
    }

    newAlerts.push(newAlert)
  }

  newAlerts.sort((a, b) => a.date_start.getTime() - b.date_start.getTime())

  let values = newAlerts.map((item, index) => ({
    ...alerts[index], // "id" if exist
    description: alerts[index]?.description || "", // stay map description for override form value
    ...item,
  }))

  return values
}
