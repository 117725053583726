import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { PageHeader } from "components"
import { Intro } from "./Intro"
import { WebsiteInfo } from "./WebsiteInfo"

export const CreateInfoContainer = () => {
  const { t } = useTranslation()

  const { data } = useSelector(state => state.website.general.data)

  return (
    <React.Fragment>
      <PageHeader title={t("website")} />

      {data ? <WebsiteInfo data={data} /> : <Intro />}
    </React.Fragment>
  )
}

CreateInfoContainer.propTypes = {}
