import {
  MODELS_PANEL_FETCH_LIST,
  MODELS_PANEL_FETCH_LIST_SUCCESS,
  MODELS_PANEL_FETCH_LIST_ERROR,
  MODELS_PANEL_LIST_CLEAN_STATE,
  MODELS_PANEL_LIST_SET_PAGE,
  MODELS_PANEL_LIST_CHANGE_FILTER,
  MODELS_PANEL_LIST_RESET_FILTER,
  MODELS_PANEL_LIST_RESET_FETCHED_DATA,
  MODELS_PANEL_LIST_SYNC,
} from "./actionTypes"
import { MODELS_PANEL_FILTERS_INITIAL_VALUES } from "consts"

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  page: 1,
  meta: null,

  filters: {
    values: MODELS_PANEL_FILTERS_INITIAL_VALUES,
  },

  syncCount: 0,
  isFetched: false,
}

const data = (state = initialState, action) => {
  switch (action.type) {
    case MODELS_PANEL_FETCH_LIST:
      state = {
        ...state,
        listLoading: true,
        listError: null,
      }
      break

    case MODELS_PANEL_FETCH_LIST_SUCCESS:
      state = {
        ...state,
        listLoading: false,
        list: action.payload.data,
        meta: action.payload.meta,
        isFetched: true,
      }
      break

    case MODELS_PANEL_FETCH_LIST_ERROR:
      state = {
        ...state,
        listLoading: false,
        listError: action.payload,
        isFetched: true,
      }
      break

    case MODELS_PANEL_LIST_CLEAN_STATE:
      state = {
        ...state,
        ...initialState,
      }
      break

    case MODELS_PANEL_LIST_SET_PAGE:
      state = {
        ...state,
        page: action.payload,
      }
      break

    case MODELS_PANEL_LIST_CHANGE_FILTER:
      state = {
        ...state,
        filters: {
          ...state.filters,
          values: {
            ...state.filters.values,
            ...action.payload,
          },
        },
        page: 1,
      }
      break

    case MODELS_PANEL_LIST_RESET_FILTER:
      state = {
        ...state,
        filters: initialState.filters,
      }
      break

    case MODELS_PANEL_LIST_RESET_FETCHED_DATA:
      state = {
        ...state,
        list: [],
        listLoading: false,
        listError: null,
        page: 1,
        meta: null,
        syncCount: 0,
        isFetched: false,
      }
      break

    case MODELS_PANEL_LIST_SYNC:
      state = {
        ...state,
        syncCount: state.syncCount + 1,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default data
