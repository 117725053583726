import { takeLatest, fork, put, all, call } from "redux-saga/effects"
import i18n from "../../../../../i18n"

import { websiteCurrentPageData } from "./slice"
import { API } from "../../../../../api"

export function* getData({ payload: { alias } }) {
  try {
    const response = yield call(API.website.getCurrentPageData, alias)

    const { data } = response

    yield put(websiteCurrentPageData.getDataCompleted(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(websiteCurrentPageData.getDataRejected(message))
  }
}

export function* watchGetData() {
  yield takeLatest(websiteCurrentPageData.getData, getData)
}

function* dataSaga() {
  yield all([fork(watchGetData)])
}

export default dataSaga
