import { combineReducers } from "@reduxjs/toolkit"

import create from "./create/slice"
import edit from "./edit/slice"
import participant from "./participant/reducers"
import leave from "./leave/slice"
import remove from "./remove/slice"

const groupsReducer = combineReducers({
  create,
  edit,
  participant,
  leave,
  remove,
})

export default groupsReducer
