import React from "react"
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  generatePath,
} from "react-router-dom"

import {
  General,
  SubscriptionPlans,
  BillingInformation,
  PaymentMethods,
  Invoices,
  InvoiceDetails,
} from "../../BillingPanel"

export const PanelRouting = props => {
  let { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={generatePath(`${path}/general`)} />
      </Route>
      <Route path={`${path}/general`}>
        <General />
      </Route>
      <Route path={`${path}/subscriptions`}>
        <SubscriptionPlans />
      </Route>
      <Route path={`${path}/info`}>
        <BillingInformation />
      </Route>
      <Route path={`${path}/payment-methods`}>
        <PaymentMethods />
      </Route>
      <Route path={`${path}/invoices/:id`}>
        <InvoiceDetails />
      </Route>
      <Route path={`${path}/invoices`}>
        <Invoices />
      </Route>
      <Route>
        <Redirect to={generatePath(`${path}/general`)} />
      </Route>
    </Switch>
  )
}
