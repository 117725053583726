import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import {
  websiteMenuItemsList,
  websiteRemoveMenuItem,
  websiteReorderMenuItems,
  websiteUpdateMenuItem,
} from "store"
import * as HOC from "HOC"
import { AddMenuItem } from "./AddMenuItem"
import { ConfirmPopup, DndListControl } from "components"
import { UpdateMenuItem } from "./UpdateMenuItem"
import { useTranslation } from "react-i18next"
import { Header } from "./Header"
import { WebsiteMenuItemsCard } from "./WebsiteMenuItemsCard"

const DataShower = HOC.withDataShower()

const MenuItemsContainerComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    fetchData()
    return () => {
      dispatch(websiteMenuItemsList.cleanState())
      dispatch(websiteRemoveMenuItem.cleanState())
      dispatch(websiteReorderMenuItems.cleanState())
    }
  }, [])

  const fetchData = () => {
    dispatch(websiteMenuItemsList.getList())
  }
  //=======================ORDER ========================//
  const handleChangeOrder = data => {
    let params = {
      data: data,
    }
    dispatch(
      websiteReorderMenuItems.reorder({
        params,
        previousOrder: props.menuItems,
      })
    )
  }
  //========================ADD LOGIC =======================================//
  const handleAddMenuItemSuccess = () => {
    fetchData()
  }

  //==========================REMOVE LOGIC =================================//
  const removeModalRef = useRef(null)
  const [removeMenuItem, setRemoveMenuItem] = useState(null)
  const handleRemove = item => {
    setRemoveMenuItem(item)
    if (removeModalRef.current) {
      removeModalRef.current.show()
    }
  }
  const resetRemove = () => {
    setRemoveMenuItem(null)
  }

  const confirmRemove = () => {
    dispatch(
      websiteRemoveMenuItem.remove({
        id: removeMenuItem.id,
      })
    )
  }
  //==========================UPDATE LOGIC =================================//
  const [updateData, setUpdateData] = useState(null)

  const handleUpdate = data => {
    setUpdateData(data)
  }
  const handleUpdateClose = () => {
    setUpdateData(null)
  }
  //==========================STATUS LOGIC =================================//

  const handleStatusChange = item => {
    dispatch(
      websiteUpdateMenuItem.update({
        id: item.id,
        params: {
          active: !item.active,
        },
      })
    )
  }

  return (
    <>
      <DataShower
        isLoading={props.listLoading}
        isFailed={props.listError}
        error={props.listError}
      >
        <Header
          title={t("menu-items")}
          headerRight={<AddMenuItem onAddSuccess={handleAddMenuItemSuccess} />}
        />
        <DndListControl
          value={props.menuItems}
          onChange={handleChangeOrder}
          renderContainer={(provided, item, index) => (
            <WebsiteMenuItemsCard
              index={index}
              item={item}
              provided={provided}
              withEdit
              onEdit={handleUpdate}
              withStatus
              onStatusChange={handleStatusChange}
              withRemove
              onRemove={handleRemove}
              removeLoading={props.removeLoading}
              updateLoading={props.updateLoading}
            />
          )}
        />
        <UpdateMenuItem menuItem={updateData} onClose={handleUpdateClose} />
        <ConfirmPopup
          ref={removeModalRef}
          onConfirm={confirmRemove}
          onClosed={resetRemove}
        />
      </DataShower>
    </>
  )
}

MenuItemsContainerComponent.propTypes = {
  menuItems: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,

  reorderLoading: PropTypes.bool,
  reorderError: PropTypes.any,

  removeLoading: PropTypes.bool,
  updateLoading: PropTypes.bool,
}

const mapStateToProps = state => {
  const { list, listLoading, listError } = state.website.menuItems.list
  const { loading: reorderLoading, error: reorderError } =
    state.website.menuItems.reorder
  const { loading: removeLoading } = state.website.menuItems.remove
  const { loading: updateLoading } = state.website.menuItems.update
  return {
    menuItems: list,
    listLoading,
    listError,
    reorderLoading,
    reorderError,
    removeLoading,
    updateLoading,
  }
}

export const MenuItemsContainer = connect(mapStateToProps)(
  MenuItemsContainerComponent
)
