import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { Row, Col } from "reactstrap"
import { AlertsFieldArrayHF } from "components"

export const StatementAlertsFormSection = props => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <Row>
      <h4 className={"mb-4"}>{t("alerts")}</h4>

      <Col md={12}>
        <AlertsFieldArrayHF
          name={"alerts"}
          control={control}
          limit={2}
          meta={{}}
        />
      </Col>
    </Row>
  )
}

StatementAlertsFormSection.propTypes = {}
