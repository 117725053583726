import React from "react"
import PropTypes from "prop-types"

import { Day } from "./Day"
import { Unread } from "./Unread"
import { Avatar } from "./Avatar"
import { Message } from "../Message"

export const MessageItem = ({ item, day, unread, avatar, forwardRef }) => {
  return (
    <li className={item.is_my ? "right" : ""} ref={forwardRef}>
      <div className={"w-100"}>
        {day && <Day date={item.created_at} />}

        {unread && <Unread />}

        <div className="conversation-list">
          {avatar && !item.is_my && <Avatar avatar={item?.user?.avatar} />}
          <Message item={item} />
        </div>
      </div>
    </li>
  )
}

MessageItem.propTypes = {
  item: PropTypes.object,
  day: PropTypes.bool,
  unread: PropTypes.bool,
  avatar: PropTypes.bool,
  forwardRef: PropTypes.any,
}
