import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from 'toastr';

import i18n from '../../../../i18n';
import { EMPLOYEE_DETAILS_REMOVE_USER } from './actionTypes';
import { employeeDetailsRemoveUserSuccess, employeeDetailsRemoveUserError } from './actions';

import { API } from '../../../../api';

function* removeUser({ payload: { id, history } }) {
    try {
        const response = yield call(API.employee.removeEmployee, id);

        const { data } = response;

        yield put(employeeDetailsRemoveUserSuccess());

        toastr.success(i18n.t('employee.remove.success'));

        history.push('/employees');
    } catch (error) {
        const errors = error.response?.data?.errors ? error.response.data.errors : {};
        const message = error.response?.data?.message || i18n.t('error');

        yield put(employeeDetailsRemoveUserError({
            errors,
            message
        }));

        toastr.error(message);
    }
}

export function* watchRemoveUser() {
    yield takeEvery(EMPLOYEE_DETAILS_REMOVE_USER, removeUser);
}

function* removeSaga() {
    yield all([
        fork(watchRemoveUser),
    ]);
}

export default removeSaga;