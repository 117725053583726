import React from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"

import { Header } from "./Topbar"
import { PanelTopBarProvider } from "../../../../../contexts"

export const Layout = props => {
  return (
    <PanelTopBarProvider>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Header />

            {props.children}
          </Container>
        </div>
      </React.Fragment>
    </PanelTopBarProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.any,
}
