import { all } from "redux-saga/effects"

import sendSaga from "./send/saga"
import dataSaga from "./data/saga"

function* sharedEntitySaga() {
  yield all([sendSaga(), dataSaga()])
}

export default sharedEntitySaga
