import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useStripe, useElements } from "@stripe/react-stripe-js"

import { billingHelper } from "helpers"
import {
  Button,
  TabNavigator,
  ValidationErrors,
  RadioButtonGroup,
  ControllerHF,
} from "components"
import { validationSchema } from "./validation"
import { PAYMENT_METHOD_VIEW } from "./consts"
import { PaymentMethodSelectSection } from "./PaymentMethodSelectSection"
import { StripeFormSection } from "./StripeFormSection"
import { billingPay } from "store/actions"
import { BILLING_PAYMENT_METHOD_TYPE } from "consts"
import { middlewareHelper } from "helpers"
import { useStripeForm } from "hooks"
import { usePaymentMethodInit } from "./hooks"
import * as HOC from "HOC"

const DataShower = HOC.withDataShower()
const { CARD, SEPA_DEBIT } = BILLING_PAYMENT_METHOD_TYPE
const currencySymbol = billingHelper.currency.getCurrency().symbol

const isPreviewMode = middlewareHelper.previewMode.isAllow()

export const PayForm = props => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const { loading, error } = useSelector(state => state.billing.pay)

  const stripe = useStripe()
  const elements = useElements()

  const [paymentType, setPaymentType] = useState(PAYMENT_METHOD_VIEW.SELECT)

  // Stripe form
  const stripeCardForm = useStripeForm({
    initialState: {
      cardNumber: undefined,
      cardExpiry: undefined,
      cardCvc: undefined,
    },
  })

  const stripeSepaForm = useStripeForm({
    initialState: {
      iban: undefined,
    },
  })

  const onStripeChange = (type, e) => {
    if (type === CARD) {
      stripeCardForm.onChange(e)
    } else if (type === SEPA_DEBIT) {
      stripeSepaForm.onChange(e)
    }
  }

  const onStripeDestroy = type => {
    if (type === CARD) {
      stripeCardForm.reset()
    } else if (type === SEPA_DEBIT) {
      stripeSepaForm.reset()
    }
  }
  // === //

  useEffect(() => {
    return () => {
      dispatch(billingPay.cleanState())
    }
  }, [])

  // Form
  const methods = useForm({
    defaultValues: {
      payment_method_type: CARD,
      card: null,
      iban: null,
    },
    resolver: yupResolver(validationSchema(paymentType)),
  })

  const { control, handleSubmit, setValue, watch } = methods

  const payment_method_type = watch("payment_method_type")
  // === //

  // Set default payment method
  const paymentMethodInit = usePaymentMethodInit()

  const initFromDefaultValues = data => {
    if (data && data?.type) {
      const { type, id } = data

      if (isPreviewMode) {
        setValue("payment_method_type", type)
        if (type === CARD) {
          setValue("card", id)
        } else if (type === SEPA_DEBIT) {
          setValue("iban", id)
        }
      } else {
        if (type === CARD) {
          setValue("card", id)
        }
      }
    }
  }

  useEffect(() => {
    paymentMethodInit.init({ callback: initFromDefaultValues })
  }, [])
  // === //

  const isDisabled = useMemo(() => {
    if (paymentType === PAYMENT_METHOD_VIEW.NEW) {
      if (!stripe || !elements) {
        return true
      }

      if (payment_method_type === CARD && !stripeCardForm.isValid) {
        return true
      }

      if (payment_method_type === SEPA_DEBIT && !stripeSepaForm.isValid) {
        return true
      }
    }

    return loading
  }, [
    paymentType,
    stripe,
    elements,
    stripeCardForm.isValid,
    stripeSepaForm.isValid,
    payment_method_type,
    loading,
  ])

  const onSuccess = () => {
    history.push(
      `/billing?success=true&message=${t("billing.pay.subscription.success")}`
    )
  }

  const onSubmit = values => {
    if (isDisabled) {
      return
    }

    const { tariff } = props.paymentInfo
    let payload = {
      tariffId: tariff?.id,
      payment_method_type: values.payment_method_type,
    }

    if (values.payment_method_type === SEPA_DEBIT) {
      payload = {
        ...payload,
        billing_details: {
          name: values.name,
          email: values.email,
          address: {
            line1: values.address,
            country: stripeSepaForm.state?.["iban"]?.["country"],
          },
        },
      }
    }

    if (paymentType === PAYMENT_METHOD_VIEW.NEW) {
      dispatch(
        billingPay.stripeSetupAndPay({
          stripe,
          elements,
          payload,
          onSuccess,
        })
      )
    } else {
      const paymentMethod =
        values.payment_method_type === CARD ? values?.card : values?.iban

      dispatch(
        billingPay.stripePay({
          paymentMethod,
          payload,
          onSuccess,
        })
      )
    }
  }

  return (
    <FormProvider {...methods}>
      {error && <ValidationErrors data={error} />}

      <DataShower isLoading={paymentMethodInit.loading}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isPreviewMode && (
            <ControllerHF
              id={"payment_method_type"}
              name={"payment_method_type"}
              label={t("payment_method")}
              control={control}
              component={RadioButtonGroup}
              options={[
                {
                  label: t("card"),
                  value: CARD,
                },
                {
                  label: t("sepa"),
                  value: SEPA_DEBIT,
                  className: "ms-4",
                },
              ]}
              className={`d-flex mb-3`}
            />
          )}

          <TabNavigator
            activeTab={paymentType}
            onTabChange={value => setPaymentType(value)}
            tabBarType={"nav"}
            tabs={[
              {
                label:
                  payment_method_type === CARD ? t("your_cards") : t("sepa"),
                value: PAYMENT_METHOD_VIEW.SELECT,
                render: () => (
                  <PaymentMethodSelectSection
                    options={paymentMethodInit.data}
                  />
                ),
              },
              {
                label:
                  payment_method_type === CARD
                    ? t("new_card")
                    : `${t("new")} ${t("sepa")}`,
                value: PAYMENT_METHOD_VIEW.NEW,
                render: () => (
                  <StripeFormSection
                    cardState={stripeCardForm.state}
                    sepaState={stripeSepaForm.state}
                    type={payment_method_type}
                    onStripeChange={onStripeChange}
                    onStripeDestroy={onStripeDestroy}
                  />
                ),
              },
            ]}
          />

          <div className="mt-3 mb-3">
            <div className="button-items">
              <Button
                submit
                renderTitle={`${t("pay")} ${
                  props.paymentInfo?.price || ""
                } ${currencySymbol}`}
                disabled={isDisabled}
                loading={loading}
              />
            </div>
          </div>
        </form>
      </DataShower>
    </FormProvider>
  )
}

PayForm.propTypes = {
  paymentInfo: PropTypes.object,
}
