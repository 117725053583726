import React from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"

export const IntroLayout = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>{props.children}</Container>
      </div>
    </React.Fragment>
  )
}

IntroLayout.propTypes = {
  children: PropTypes.any,
}
