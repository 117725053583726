import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import ReactCrop, { convertToPixelCrop } from "react-image-crop"
import { CroppedAvatarPreview } from "./CroppedAvatarPreview"
import { CroppedCanvasPreview } from "./CroppedCanvasPreview"
import { centerAspectCrop } from "./centerAspectCrop"

export const ImageCropControl = ({
  imageDataURL,
  value,
  onChange,
  previewConfig,
  withPreview,
  previewType = "canvas",
  hasCrop,
  aspect,
}) => {
  const [crop, setCrop] = useState(null)
  const imgRef = useRef(null)
  const scale = useRef(null)

  const handleCropChange = pixelCrop => {
    setCrop({ ...pixelCrop })
  }

  const handleCropComplete = pixelCrop => {
    onChange({
      crop: pixelCrop,
      scale: scale.current,
    })
  }

  const onImageLoad = e => {
    const { width, height, naturalHeight } = e.currentTarget
    scale.current = naturalHeight / height

    let crop

    if (value.scale) {
      handleCropComplete(value.crop)
      setCrop(value.crop)
      return
    }

    if (hasCrop) {
      //Resize px crop from backend to fit the size of image
      crop = {
        unit: "px",
        x: value.crop.x / scale.current,
        y: value.crop.y / scale.current,
        width: value.crop.width / scale.current,
        height: value.crop.height / scale.current,
      }
    } else {
      const percentCrop = centerAspectCrop(width, height, aspect)
      crop = convertToPixelCrop(percentCrop, width, height)
    }
    handleCropComplete(crop)
    setCrop(crop)
  }

  return (
    <div
      className={
        "d-flex flex-column flex-md-row align-items-center justify-content-center image-crop-control"
      }
    >
      <ReactCrop
        crop={crop}
        keepSelection
        ruleOfThirds
        className={"mr-2 mb-2"}
        onChange={handleCropChange}
        onComplete={handleCropComplete}
        aspect={aspect}
      >
        <img
          ref={imgRef}
          alt="Crop me"
          src={imageDataURL}
          style={{ maxHeight: `360px` }}
          onLoad={onImageLoad}
        />
      </ReactCrop>
      {withPreview && (
        <>
          {previewType === "avatar" && (
            <div>
              <CroppedAvatarPreview
                src={imageDataURL}
                crop={value.crop}
                height={previewConfig.height}
                width={previewConfig.width}
                className={"mb-2"}
              />
            </div>
          )}

          {previewType === "canvas" && (
            <CroppedCanvasPreview
              pixelCropCompleted={value.crop}
              image={imgRef?.current}
              className={"mb-2 d-flex justify-content-center"}
              containerStyle={{ minWidth: "300px" }}
              aspect={aspect}
            />
          )}
        </>
      )}
    </div>
  )
}

ImageCropControl.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  imageDataURL: PropTypes.string,
  previewConfig: PropTypes.object,
  withPreview: PropTypes.bool,
  previewType: PropTypes.string,
  hasCrop: PropTypes.bool,
  aspect: PropTypes.number,
}
