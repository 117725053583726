import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import { generatePath, Link } from "react-router-dom"

import { IconButton, InfoCard, Image } from "components"
import placeholderIcon from "assets/images/dev/icon-photo-placeholder.svg"
import { ModelsView } from "./ModelsVIew"

export const ProfileInfoCard = ({ data, onEdit, onAvatarEdit }) => {
  const { t } = useTranslation()

  const getPath = () => {
    return generatePath(`/clients/:id/general`, { id: data.billing_client?.id })
  }
  return (
    <InfoCard
      onEdit={onEdit}
      editMode
      title={t("general")}
      className={"agency-general-info"}
    >
      <div className={"mb-2 d-flex"}>
        <div className={"card-logo-photo"}>
          <div className="card-logo-photo__block">
            <Image src={data?.avatar?.src || placeholderIcon} alt="logo" />
            <div className={"card-logo-photo__edit-block"}>
              <IconButton name={"edit"} onClick={onAvatarEdit} bg />
            </div>
          </div>
        </div>
        <div className={"align-self-center"}>
          {data.website && data.website !== "" && (
            <div className="info-list__item d-flex flex-column">
              <div className={"info-list__label"}>{t("website")}:</div>
              <div className={"info-list__info "}>
                <a
                  href={data.website}
                  target={"_blank"}
                  rel="noreferrer"
                  className={"text-primary-green"}
                >
                  {t("open")}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      <Row>
        <Col>
          <div className={"info-item"}>
            <div className={"info-item__label"}>{t("agency-commission")}:</div>
            <div className={"info-item__info"}>
              {data.agency_commission ? data.agency_commission + " %" : "-"}
            </div>
          </div>
        </Col>
        <Col>
          <div className={"info-item"}>
            <div className={"info-item__label"}>{t("billing-client")}:</div>
            {data.billing_client ? (
              <Link to={getPath()} className={"text-primary-green"}>
                <div className={"info-item__info"}>
                  {data.billing_client.name}
                </div>
              </Link>
            ) : (
              "-"
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <div className={"info-item"}>
          <div className={"info-item__label"}>{t("note")}:</div>
          <div className={"info-item__info"}>{data.note || "-"}</div>
        </div>
        <div className={"info-item"}>
          <div className={"info-item__label"}>{t("VAT")}:</div>
          <div className={"info-item__info"}>{data.vat || "-"}</div>
        </div>
        <div className={"info-item"}>
          <div className={"info-item__label"}>{t("models")}:</div>
          <div className={"info-item__info"}>
            <ModelsView agencyId={data?.id} />
          </div>
        </div>
      </Row>
    </InfoCard>
  )
}

ProfileInfoCard.propTypes = {
  data: PropTypes.object,
  onEdit: PropTypes.func,
  onAvatarEdit: PropTypes.func,
}
