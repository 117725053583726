import { all } from 'redux-saga/effects';

import dataSaga from './data/saga';
import editSaga from './edit/saga';

function* detailsSaga() {
    yield all([
        dataSaga(),
        editSaga(),
    ])
}

export default detailsSaga;