import { all } from "redux-saga/effects"

import generalSaga from "./general/saga"
import subscriptionSaga from "./subscription/saga"
import notificationsSaga from "./notifications/saga"
import chatSaga from "./chat/sagas"
import userSaga from "./user/sagas"
import billingSaga from "./billing/sagas"
import clientSaga from "./client/sagas"
import socialPostSaga from "./socialPost/saga"
import websiteSaga from "./website/sagas"
import importSaga from "./import/sagas"

function* socketSaga() {
  yield all([
    generalSaga(),
    subscriptionSaga(),
    notificationsSaga(),
    chatSaga(),
    userSaga(),
    billingSaga(),
    clientSaga(),
    socialPostSaga(),
    websiteSaga(),
    importSaga(),
  ])
}

export default socketSaga
