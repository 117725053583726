import * as Yup from "yup"

import {
  getEventDateValidations,
  getEventTimeValidations,
  getEventAttachFilesValidations,
  getEventLocationValidations,
} from "../helpers"

export const validationSchema = Yup.object().shape({
  ...getEventDateValidations(),
  ...getEventTimeValidations(),
  ...getEventLocationValidations(),
  ...getEventAttachFilesValidations(),
})
