import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { SelectedListControl } from "components"

export const MembersList = props => {
  const { t } = useTranslation()

  return (
    <SelectedListControl
      title={t("members")}
      emptyMessage={t("no_members")}
      {...props.selectedListConfig}
      items={props.value}
      onRemove={props.onRemove}
      renderItemContent={props.renderItemContent}
    />
  )
}

MembersList.propTypes = {
  value: PropTypes.any,
  onRemove: PropTypes.func,
  renderItemContent: PropTypes.func,
  selectedListConfig: PropTypes.object,
}
