import {
    MODEL_PANEL_GENERAL_AGENCY_LIST_REMOVE_ITEM,
    MODEL_PANEL_GENERAL_AGENCY_LIST_REMOVE_ITEM_SUCCESS,
    MODEL_PANEL_GENERAL_AGENCY_LIST_REMOVE_ITEM_ERROR,
    MODEL_PANEL_GENERAL_AGENCY_LIST_REMOVE_ITEM_CLEAN_STATE,
} from './actionTypes';

const initialState = {
    pending: [],
};

const removeItem = (state = initialState, action) => {
    switch (action.type) {
        case MODEL_PANEL_GENERAL_AGENCY_LIST_REMOVE_ITEM:
            state = {
                ...state,
                pending: [...state.pending, action.payload.id],
            };
            break;

        case MODEL_PANEL_GENERAL_AGENCY_LIST_REMOVE_ITEM_SUCCESS:
            state = {
                ...state,
                pending: state.pending.filter((item) => item !== action.payload.id),
            };
            break;

        case MODEL_PANEL_GENERAL_AGENCY_LIST_REMOVE_ITEM_ERROR:
            state = {
                ...state,
                pending: state.pending.filter((item) => item !== action.payload.id),
            };
            break;

        case MODEL_PANEL_GENERAL_AGENCY_LIST_REMOVE_ITEM_CLEAN_STATE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default removeItem;