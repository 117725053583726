import { combineReducers } from "redux";

import data from './data/reducer';
import edit from './edit/reducer';
import url from './url/reducers';
import statistics from './statistics/reducers';

const detailsReducer = combineReducers({
    data,
    edit,
    url,
    statistics,
});

export default detailsReducer;