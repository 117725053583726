import { combineReducers } from "redux";

import data from './data/reducer';
import edit from './edit/reducer';

const featuresReducer = combineReducers({
    data,
    edit,
});

export default featuresReducer;