import * as Yup from "yup"

import { getEventTitleInfoValidations } from "../helpers"
import { ValidationsUtils } from "utils"

export const validationSchema = Yup.object().shape({
  departure_date: Yup.date().nullable().required("field.error.required"),
  arrival_date: Yup.date()
    .nullable()
    .required("field.error.required")
    .test({
      name: "dateEqualOrGrater",
      exclusive: false,
      params: {},
      message: "field.error.date.after-or-equal",
      test: function (value) {
        return ValidationsUtils.dateEqualOrAfter(
          value,
          this.parent.departure_date
        )
      },
    }),
  ...getEventTitleInfoValidations(),
})
