import {
    MODEL_PANEL_SET_MODEL_AVATAR,
    MODEL_PANEL_SET_MODEL_AVATAR_SUCCESS,
    MODEL_PANEL_SET_MODEL_AVATAR_ERROR,
    MODEL_PANEL_SET_MODEL_AVATAR_CLEAN_STATE,
} from './actionTypes';

export const modelPanelSetModelAvatar = (attachmentId, params) => {
    return {
        type: MODEL_PANEL_SET_MODEL_AVATAR,
        payload: { attachmentId, params },
    }
};

export const modelPanelSetModelAvatarSuccess = () => {
    return {
        type: MODEL_PANEL_SET_MODEL_AVATAR_SUCCESS,
    }
};

export const modelPanelSetModelAvatarError = (data) => {
    return {
        type: MODEL_PANEL_SET_MODEL_AVATAR_ERROR,
        payload: data,
    }
};

export const modelPanelSetModelAvatarCleanState = () => {
    return { type: MODEL_PANEL_SET_MODEL_AVATAR_CLEAN_STATE };
};