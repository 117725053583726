import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert } from "reactstrap"
import * as Yup from "yup"
import { Button, ControllerHF, InputField } from "components"

export const SendUserIdeaForm = props => {
  const { t } = useTranslation()

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      message: "",
      ...props.initialValues,
    },
    resolver: yupResolver(
      Yup.object().shape({
        message: Yup.string().required("field.error.required"),
      })
    ),
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
    }

    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <ControllerHF
          name={"message"}
          control={control}
          component={InputField}
          id={"message"}
          label={t("send-idea.label")}
          placeholder={t("send-idea.placeholder")}
          type={"textarea"}
        />
        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.send"}
              disabled={props.loading}
              loading={props.loading}
            />
            <Button title={"cancel"} color="light" outline onClick={onCancel} />
          </div>
        </div>
      </form>
    </>
  )
}

SendUserIdeaForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
