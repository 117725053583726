import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Alert, Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import {
  Button,
  ComboboxFormField,
  ControllerHF,
  DateInputField,
  InputField,
  StatementAlertsFormSection,
} from "components"
import { validationSchema } from "./validation"
import { statementHelper } from "helpers"
import { DateUtils } from "utils"
import { generateAlerts } from "./helpers"

/* prettier-ignore */
const deadlineMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/]

export const StatementForm = props => {
  const { t } = useTranslation()

  const defaultValues = useMemo(() => {
    const { deadline, alerts, ...restValues } = props.initialValues

    let alertsValues = []
    if (alerts && alerts.length) {
      alertsValues = alerts.map(item => ({
        ...item,
        date_start: DateUtils.utcToLocale(item.date_start),
      }))
    }

    return {
      ...restValues,
      deadline: deadline ? DateUtils.utcToLocale(deadline) : null,
      alerts: alertsValues,
    }
  }, [props.initialValues])

  const methods = useForm({
    defaultValues: {
      description: "",
      deadline: null,
      status: null,
      alerts: [],
      ...defaultValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const { control, handleSubmit, setError, reset, setValue, getValues } =
    methods

  const onSubmit = values => {
    const { deadline, alerts, ...restValues } = values

    let params = {
      deadline: deadline
        ? DateUtils.localeToUTC(deadline, "YYYY-MM-DD HH:mm")
        : null,
      alerts:
        alerts?.map(item => ({
          ...item,
          date_start: item.date_start
            ? DateUtils.localeToUTC(item.date_start, "YYYY-MM-DD HH:mm")
            : null,
          description: item.description || null,
        })) || [],
      ...restValues,
    }

    props.onSubmit(params, { setError })
  }

  const { alert_1_period, alert_2_period, apply_for_new, override_existing } =
    props.settings

  const defaultReminders = useMemo(() => {
    let reminders = []

    if (alert_1_period) {
      reminders.push({
        sort: 1,
        period: alert_1_period,
      })
    }

    if (alert_2_period) {
      reminders.push({
        sort: 2,
        period: alert_2_period,
      })
    }

    return reminders
  }, [alert_1_period, alert_2_period])

  const applySettings = date => {
    if (!!defaultReminders.length) {
      const { deadline, alerts: initialAlerts } = props.initialValues
      const { alerts: alertsValue } = getValues()
      let newAlerts = []

      if (
        (apply_for_new && deadline === null && initialAlerts.length === 0) ||
        (override_existing && deadline)
      ) {
        newAlerts = generateAlerts(date, alertsValue, defaultReminders)
        setValue("alerts", newAlerts)
      }
    }
  }

  const onDeadlineChange = value => {
    setValue("deadline", value)

    if (value) {
      applySettings(value)
    }
  }

  return (
    <FormProvider {...methods}>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <ControllerHF
              name={"description"}
              control={control}
              component={InputField}
              id={"description"}
              label={t("description")}
              placeholder={t("description")}
              type={"textarea"}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <ControllerHF
              name={"deadline"}
              control={control}
              component={DateInputField}
              id={"deadline"}
              label={t("payment_deadline")}
              placeholder={"DD/MM/YYYY hh:mm"}
              showTimeSelect
              dateFormat={"dd/MM/yyyy HH:mm"}
              mask={deadlineMask}
              onChange={onDeadlineChange}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <ControllerHF
              name={"status"}
              control={control}
              component={ComboboxFormField}
              id={"payment-status"} // not use id === status
              label={t("status")}
              placeholder={t("status")}
              options={statementHelper.common.getPaymentStatusConfigs()}
              selectProps={{
                isClearable: false,
                isSearchable: false,
              }}
              normalize={option => (option ? option.value : null)}
              isDetermineValue
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <StatementAlertsFormSection />

        <div className="mt-3 pt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

StatementForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  settings: PropTypes.object,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
