import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { useSelector } from "react-redux"

import { MegaTagTitle } from "components"
import {
  BillingIncompletePayment,
  BillingOverviewInfo,
  BillingSubscriptionRequiredAlert,
} from "../components"
import { LatestInvoicesContainer } from "../components/InvoiceComponents"
import { BILLING_SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT } from "consts"

export const General = props => {
  const { data: user } = useSelector(state => state.user.profile)

  return (
    <div className={"billing-portal-general"}>
      <MegaTagTitle title={"title.billing.general"} />

      {user?.subscription?.status ===
        BILLING_SUBSCRIPTION_STATUS_INCOMPLETE_PAYMENT && (
        <BillingIncompletePayment />
      )}

      <Row>
        <Col>
          {user?.subscription ? (
            <BillingOverviewInfo />
          ) : (
            <BillingSubscriptionRequiredAlert />
          )}
        </Col>
      </Row>

      <Row className={"mt-4"}>
        <Col>
          <LatestInvoicesContainer />
        </Col>
      </Row>
    </div>
  )
}

General.propTypes = {}
