import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useRouteMatch, generatePath } from "react-router-dom"
import { PanelMenu } from "components"
import { connect } from "react-redux"
import { CLIENTS, EMPLOYEES, MODELS, OVERALL } from "consts"
import { analyticsHelper } from "helpers"

export const NavComponent = props => {
  const { t } = useTranslation()

  let { path } = useRouteMatch()

  const getPath = route => generatePath(`${path}/${route}`)

  const menu = [
    {
      key: OVERALL,
      route: getPath("overall"),
      title: t("overall"),
    },
    {
      key: MODELS,
      route: getPath("models"),
      title: t("models"),
      badge: null,
    },
    {
      key: CLIENTS,
      route: getPath("clients"),
      title: t("clients"),
      badge: null,
    },
    {
      key: EMPLOYEES,
      route: getPath("employees"),
      title: t("employees"),
      badge: null,
    },
  ]

  const filteredMenuWithPermissions = menu.filter(menuItem =>
    analyticsHelper.permissions.hasTab(props.graphs, menuItem.key)
  )

  return <PanelMenu items={filteredMenuWithPermissions} />
}

NavComponent.propTypes = {
  graphs: PropTypes.array,
}

const mapStateToProps = state => {
  let { list: graphs } = state.analytics.graphs.list
  return {
    graphs,
  }
}

export const Nav = connect(mapStateToProps)(NavComponent)
