import * as general from "./general"
import * as statuses from "./statuses"
import * as features from "./features"
import * as documents from "./documents"
import * as agency from "./agency"
import * as logistics from "./logistics"
import * as expenses from "./expenses"
import * as rate from "./rate"
import * as service from "./service"
import * as travel from "./travel"
import * as photos from "./photos"

export const modelHelper = {
  statuses,
  general,
  features,
  documents,
  agency,
  logistics,
  expenses,
  rate,
  service,
  travel,
  photos,
}
