import React, { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import { useEchoSubscriptionEffect } from "hooks"
import {
  socketOnChatGroupUpdated,
  socketOnChatSeen,
  socketOnChatGroupRemoved,
  socketOnChatGroupUserRemoved,
  socketOnChatGroupParticipantAdded,
  socketOnChatGroupParticipantRemoved,
} from "store/actions"
import { ECHO_EVENT } from "consts"

export const ChatSocketMiddleware = props => {
  const dispatch = useDispatch()

  const { data: user } = useSelector(state => state.user.profile)

  const channel = `user.${user.id}`

  const events = useMemo(
    () => ({
      // on chat seen
      [ECHO_EVENT.CHAT_SEEN_EVENT]: data => dispatch(socketOnChatSeen(data)),

      // on group chat updated
      [ECHO_EVENT.CHAT_GROUP_UPDATED_EVENT]: data =>
        dispatch(socketOnChatGroupUpdated(data)),

      // on group chat removed
      [ECHO_EVENT.CHAT_GROUP_REMOVED_EVENT]: data =>
        dispatch(socketOnChatGroupRemoved(data)),

      // on group chat user removed or user leave chat
      [ECHO_EVENT.CHAT_GROUP_REMOVED_FOR_USER_EVENT]: data =>
        dispatch(socketOnChatGroupUserRemoved(data)),

      // on group chat participant added
      [ECHO_EVENT.CHAT_GROUP_USER_ADDED_EVENT]: data =>
        dispatch(socketOnChatGroupParticipantAdded(data)),

      // on group chat participant removed
      [ECHO_EVENT.CHAT_GROUP_USER_REMOVED_EVENT]: data =>
        dispatch(socketOnChatGroupParticipantRemoved(data)),
    }),
    [dispatch]
  )

  useEchoSubscriptionEffect({ channel, events })

  return props.children
}
