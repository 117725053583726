import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import _ from "lodash"

import { PhotosGridItem } from "./PhotosGridItem"
import { MasonryDataGrid } from "components"

export const PhotosGrid = props => {
  const { t } = useTranslation()

  const masonryConfig = {
    options: {
      gutter: 8,
    },
    enableResizableChildren: true,
  }

  return (
    <div className={"model-photos-selection-grid"}>
      <MasonryDataGrid
        masonryConfig={masonryConfig}
        loading={props.loading}
        error={props.error}
        meta={props.meta}
        onPageChange={props.onPageChange}
        items={props.items}
        renderItem={item => (
          <div className={"grid-item"}>
            <PhotosGridItem
              item={item}
              selected={!!_.find(props.selected, { photo: { id: item.id } })}
              onSelect={props.onSelect}
            />
          </div>
        )}
        empty={t("no_photos")}
      />
    </div>
  )

  // return (
  //   <div className={"model-photos-selection-grid"}>
  //     <DataShower
  //       isLoading={props.loading}
  //       isFailed={props.error}
  //       error={props.error}
  //     >
  //       {!!props.items.length ? (
  //         <>
  //           <div className="grid">
  //             <div className="grid__row">
  //               {props.items.map((item, i) => (
  //                 <div
  //                   className="grid__col"
  //                   key={`model-selection-photos-grid-item-${item.id}`}
  //                 >
  //                   <div className="grid__item">
  //                     <PhotosGridItem
  //                       item={item}
  //                       selected={
  //                         !!_.find(props.selected, { photo: { id: item.id } })
  //                       }
  //                       onSelect={props.onSelect}
  //                     />
  //                   </div>
  //                 </div>
  //               ))}
  //             </div>
  //           </div>
  //
  //           <Pagination data={props.meta} onPageChange={props.onPageChange} />
  //         </>
  //       ) : !props.loading ? (
  //         t("no_photos")
  //       ) : null}
  //     </DataShower>
  //   </div>
  // )
}

PhotosGrid.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,
  selected: PropTypes.array,

  onPageChange: PropTypes.func,
  onSelect: PropTypes.func,
}
