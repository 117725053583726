import _ from "lodash"

import i18n from "i18n"
import {
  GENDER_MALE,
  GENDER_FEMALE,
  ETHNIC_CATEGORY_ASIAN,
  ETHNIC_CATEGORY_BLACK,
  ETHNIC_CATEGORY_CAUCASIAN,
  ETHNIC_CATEGORY_HISPANIC,
  ETHNIC_CATEGORY_INDIAN,
  ETHNIC_CATEGORY_NATIVE_AMERICAN,
  MODEL_FEATURE_NATURALNESS,
} from "../../consts"

export const getGenderOptions = () => {
  return [
    {
      label: i18n.t("female"),
      value: GENDER_FEMALE,
    },
    {
      label: i18n.t("male"),
      value: GENDER_MALE,
    },
  ]
}

export const getGender = value => {
  let data = {
    label: "",
    value: "",
  }
  switch (value) {
    case GENDER_MALE:
      data = {
        label: i18n.t("male"),
        value: GENDER_MALE,
      }
      break

    case GENDER_FEMALE:
      data = {
        label: i18n.t("female"),
        value: GENDER_FEMALE,
      }
      break

    default:
      data = { ...data }
      break
  }
  return data
}

export const getEthnicCategories = () => {
  return [
    {
      label: i18n.t("ethnic-category.asian"),
      value: ETHNIC_CATEGORY_ASIAN,
    },
    {
      label: i18n.t("ethnic-category.black"),
      value: ETHNIC_CATEGORY_BLACK,
    },
    {
      label: i18n.t("ethnic-category.caucasian"),
      value: ETHNIC_CATEGORY_CAUCASIAN,
    },
    {
      label: i18n.t("ethnic-category.hispanic"),
      value: ETHNIC_CATEGORY_HISPANIC,
    },
    {
      label: i18n.t("ethnic-category.indian"),
      value: ETHNIC_CATEGORY_INDIAN,
    },
    {
      label: i18n.t("ethnic-category.native_american"),
      value: ETHNIC_CATEGORY_NATIVE_AMERICAN,
    },
  ]
}

export const getEthnicCategory = value => {
  const items = getEthnicCategories()
  return _.find(items, { value: value })
}

export const getTaxResidenceBoolOptions = () => {
  return [
    {
      label: i18n.t("yes"),
      value: "yes",
    },
    {
      label: i18n.t("no"),
      value: "no",
    },
  ]
}

export const getTaxResidenceBool = value => {
  let data
  switch (value) {
    case "yes":
      data = {
        label: i18n.t("yes"),
        value: "yes",
      }
      break

    case "no":
      data = {
        label: i18n.t("no"),
        value: "no",
      }
      break

    default:
      data = null
      break
  }
  return data
}


export const getFeatureNaturalnessOptions = () => {
  return [
    {
      label: i18n.t("natural"),
      value: MODEL_FEATURE_NATURALNESS.NATURAL,
    },
    {
      label: i18n.t("artificial"),
      value: MODEL_FEATURE_NATURALNESS.ARTIFICIAL,
    },
  ]
}

export const getFeatureNaturalness = value => {
  const items = getFeatureNaturalnessOptions()
  return _.find(items, { value: value })
}