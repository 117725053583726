import React from "react"
import { useTranslation } from "react-i18next"

import { ControllerHF, InputField } from "components"
import { useFormContext } from "react-hook-form"

export const Invoice = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <ControllerHF
      name={"invoice"}
      id={"invoice"}
      label={t("invoice")}
      placeholder={t("invoice")}
      control={control}
      component={InputField}
      className={"mb-0"}
    />
  )
}

Invoice.propTypes = {}
