import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { ComboboxFormField, ControllerHF } from "components"
import { useFetchClients } from "hooks"
import { useFormContext } from "react-hook-form"

export const Client = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const { data: clients, fetchData: fetchClients, loading } = useFetchClients()

  useEffect(() => {
    fetchClients({ pagination: false })
  }, [])

  return (
    <ControllerHF
      name={"client"}
      control={control}
      component={ComboboxFormField}
      id={"client"}
      label={t("client")}
      placeholder={t("client")}
      options={clients}
      normalize={option => (option !== null ? option.id : null)}
      getOptionLabel={option => `${option.name}`}
      getOptionValue={option => option.id}
      isDetermineValue
      selectProps={{
        isClearable: true,
        isLoading: loading,
      }}
      wrapClass={"mb-0"}
    />
  )
}

Client.propTypes = {}
