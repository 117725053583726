import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import {
  modelFetchPhotos,
  modelFetchPhotosCleanState,
  modelPhotosSetPage,
} from "../../../../../store/actions"
import { PhotosGrid } from "./PhotosGrid"
import { usePanelTopBarOptions } from "../../../../../hooks"

const PhotosContainerComponent = props => {
  const { t } = useTranslation()

  const handleSelectAll = e => {
    e.preventDefault()
    props.onAddItems(props.list)
  }

  const panelOptions = useMemo(
    () => ({
      actions: (
        <>
          {!!props.list.length && (
            <div className="ms-3 d-inline">
              <Link to="#" className="text-secondary" onClick={handleSelectAll}>
                {t("btn.select-all")}
              </Link>
            </div>
          )}
        </>
      ),
    }),
    [props.list]
  )

  usePanelTopBarOptions(panelOptions)

  useEffect(() => {
    return () => {
      props.modelFetchPhotosCleanState()
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [props.page])

  const fetchData = () => {
    let params = {
      page: props.page,
      per_page: 16,
    }

    props.modelFetchPhotos(props.model.id, params)
  }

  const handlePageChange = item => {
    props.modelPhotosSetPage(item.selected + 1)
  }

  return (
    <PhotosGrid
      items={props.list}
      loading={props.listLoading}
      error={props.listError}
      meta={props.meta}
      page={props.page}
      onPageChange={handlePageChange}
      selected={props.picked}
      onSelect={props.onToggleSelect}
    />
  )
}

PhotosContainerComponent.propTypes = {
  picked: PropTypes.array,
  onToggleSelect: PropTypes.func,
  onAddItems: PropTypes.func,

  model: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,

  modelFetchPhotos: PropTypes.func,
  modelFetchPhotosCleanState: PropTypes.func,
  modelPhotosSetPage: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.model.profile
  const { list, listLoading, listError, meta, page } =
    state.model.photos.list.data
  return {
    model: data,
    list,
    listLoading,
    listError,
    meta,
    page,
  }
}

const mapDispatchToProps = {
  modelFetchPhotos,
  modelFetchPhotosCleanState,
  modelPhotosSetPage,
}

export const PhotosContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhotosContainerComponent)
