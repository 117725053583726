import React from "react"
import { Route, Redirect, withRouter } from "react-router-dom"
import PropTypes from "prop-types"

const AllowedRouteComponent = ({ component: Component, path, ...rest }) => {
  return (
    <Route {...rest}>
      {!rest.allowed ? (
        <Redirect
          to={{ pathname: rest.match.path, state: { from: rest.location } }}
        />
      ) : (
        <Component {...rest} />
      )}
    </Route>
  )
}

AllowedRouteComponent.propTypes = {
  component: PropTypes.any,
  path: PropTypes.string,
  allowed: PropTypes.bool,
}

export const AllowedRoute = withRouter(AllowedRouteComponent)
