import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { API } from "api"
import i18n from "i18next"
import { analyticsGraphsList } from "store/analytics/graphs/actions"

export function* getGraphData({ payload: { alias, tab, params } }) {
  try {
    const response = yield call(API.analytics.getGraphData, alias, params)
    const { data } = response

    yield put(
      analyticsGraphsList.getGraphStatsDataCompleted({
        alias,
        tab,
        data: data?.data,
      })
    )
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(
      analyticsGraphsList.getGraphStatsDataRejected({
        alias,
        tab,
        error: message,
      })
    )
  }
}

export function* watchGetGraphData() {
  yield takeEvery(analyticsGraphsList.getGraphStatsData, getGraphData)
}

function* graphDataSaga() {
  yield all([fork(watchGetGraphData)])
}

export default graphDataSaga
