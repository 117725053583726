import React from "react"
import PropTypes from "prop-types"
import { Nav } from "./Nav"

export const DataSlider = ({
  renderSlide,
  title,
  navTitle,
  withCounterLabel,
  count,
  currentPage,
  onPageChange,
  className,
}) => {
  return (
    <div className={className}>
      <div className={"data-slider"}>
        <div className={"slider__slide"}>{renderSlide()}</div>

        <div className={"data-slider__title"}>{title}</div>
        <Nav
          title={navTitle}
          withCounterLabel={withCounterLabel}
          count={count}
          page={currentPage}
          onChange={onPageChange}
        />
      </div>
    </div>
  )
}

DataSlider.propTypes = {
  title: PropTypes.string,
  renderSlide: PropTypes.func,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  count: PropTypes.number,
  navTitle: PropTypes.string,
  withCounterLabel: PropTypes.bool,
  className: PropTypes.string,
}
