import React from 'react'
import PropTypes from "prop-types"
import { connect } from "react-redux";
import { Col, Alert, Row } from "reactstrap"
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { ControllerHF, InputField, Button, PhotoPickerField } from '../../../../../../components';
import { FileUtils, ValidationsUtils } from "../../../../../../utils"

const FILE_MAX_SIZE = 16777216; // 16 mb

const AddPhotoFormComponent = (props) => {
    const { t } = useTranslation();

    const { control, handleSubmit, setError, reset } = useForm({
        defaultValues: {
            photo: null,
            title: '',
            description: '',
        },
        resolver: yupResolver(Yup.object({
            photo: Yup.mixed().nullable().required('field.model-photo.required')
                .test('maxSize', 'field.error.file-size.max', (value) => ValidationsUtils.fileMaxSize(value, FILE_MAX_SIZE)),
        })),
    });

    const onSubmit = (values) => {
        props.onSubmit(values, { setError, reset });
    };

    const onCancel = () => {
        props.onCancel();
    }

    return (
        <>
            {props.error && (
                <Alert color="danger">
                    {props.error.message}
                </Alert>
            )}

            <form className="form-horizontal" noValidate={true} onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col>
                        <ControllerHF
                            name={'photo'}
                            control={control}
                            component={PhotoPickerField}
                            id={'photo'}
                            selectBtnText={t('btn.add-photo')}
                            className={'model-photo-picker mb-3'}
                            translateParams={{ text: `${FileUtils.formatBytes(FILE_MAX_SIZE)}` }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <ControllerHF
                            name="title"
                            control={control}
                            component={InputField}
                            label={t('model.photo.title.label')}
                            placeholder={t('model.photo.title.placeholder')}
                            id={'title'}
                            type="text"
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <ControllerHF
                            name="description"
                            control={control}
                            component={InputField}
                            label={t('model.photo.description.label')}
                            placeholder={t('model.photo.description.placeholder')}
                            id={'description'}
                            type="textarea"
                        />
                    </Col>
                </Row>

                <div className="mt-3">
                    <div className="button-items">
                        <Button
                            submit
                            title={'btn.save'}
                            disabled={props.loading}
                            loading={props.loading}
                        />

                        <Button
                            title={'cancel'}
                            color="light"
                            outline
                            onClick={() => onCancel()}
                        />
                    </div>
                </div>
            </form>
        </>
    );
};

AddPhotoFormComponent.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.object,

    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const AddPhotoForm = connect(mapStateToProps, mapDispatchToProps)(AddPhotoFormComponent);