import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { Image, ReadMoreExpand } from "components"

export const BookOverviewCard = ({ pagesCount = null, ...props }) => {
  const { t } = useTranslation()

  const renderAmount = amount => {
    if (amount < 100) {
      return amount
    } else {
      amount = amount.toString().slice(-2)
      return <span className={"font-size-10"}>{`...${amount}`}</span>
    }
  }

  return (
    <div
      className={classNames("book-overview-card", {
        [props.className]: props.className,
        [`book-overview-card_size_${props.size}`]: props.size,
      })}
    >
      <div className={"book-overview-card__cover-block"}>
        <div
          className={"book-overview-card__cover-wrapper"}
          style={{
            cursor: props.onCoverClick ? "pointer" : null,
          }}
          onClick={props.onCoverClick}
        >
          <Image
            crops={props.crops}
            src={props?.cover}
            alt={"book-cover"}
            objectPosition={"top"}
          />
        </div>

        {pagesCount !== null && (
          <div className="book-overview-card__photos-amount-badge-block">
            <div className={"book-overview-card__photos-amount-badge"}>
              {renderAmount(pagesCount)}
            </div>
          </div>
        )}

        {props.actions && (
          <div className={"book-overview-card__actions-block"}>
            {props.actions}
          </div>
        )}
      </div>

      {props.headerPanel && (
        <div className={"book-overview-card__header-panel"}>
          {props.headerPanel}
        </div>
      )}

      {props.title && (
        <div className={"book-overview-card__title-block"}>
          <div className={"book-overview-card__title"}>{props.title}</div>
        </div>
      )}

      {props.description && (
        <div className={"book-overview-card__body"}>
          <ReadMoreExpand
            className={"book-overview-card__description"}
            maxHeight={64}
            lineHeight={16}
          >
            {props.description}
          </ReadMoreExpand>
        </div>
      )}
    </div>
  )
}

BookOverviewCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  cover: PropTypes.string,
  pagesCount: PropTypes.number,
  className: PropTypes.string,
  actions: PropTypes.any,
  size: PropTypes.string,
  headerPanel: PropTypes.any,
  onCoverClick: PropTypes.func,
  crops: PropTypes.array,
}
