import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { DataShower } from "components"
import { iCalUrls } from "features/ical/store/actions"

export const ICalUrlsDataLoader = ({ children }) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { data, loading, error } = useSelector(state => state.iCal.urls)

  const fetchData = () => {
    dispatch(iCalUrls.getData({}))
  }

  useEffect(() => {
    fetchData()
    return () => {
      dispatch(iCalUrls.cleanState())
    }
  }, [])

  return (
    <DataShower
      isLoading={loading}
      isFailed={!!error}
      error={error}
      isFetched={!!data}
    >
      {children}
    </DataShower>
  )
}

ICalUrlsDataLoader.propTypes = {
  children: PropTypes.any,
}
