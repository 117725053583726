import {
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_ERROR,
    FORGET_PASSWORD_CLEAN_STATE,
} from "./actionTypes";

export const userForgetPassword = (params, onSubmitProps) => {
    return {
        type: FORGET_PASSWORD,
        payload: { params, onSubmitProps },
    };
};

export const userForgetPasswordSuccess = data => {
    return {
        type: FORGET_PASSWORD_SUCCESS,
        payload: data,
    };
};

export const userForgetPasswordError = data => {
    return {
        type: FORGET_PASSWORD_ERROR,
        payload: data,
    };
};

export const userForgetPasswordCleanSate = () => {
    return {
        type: FORGET_PASSWORD_CLEAN_STATE,
    };
};