import { takeLatest, fork, put, all, call } from "redux-saga/effects"

import i18n from "../../../../i18n"
import { agencyAddressData } from "./slice"
import { API } from "../../../../api"

export function* getData({ payload: { id, params } }) {
    try {
        const response = yield call(API.agency.getAgencyAddress, id, params)

        const { data } = response

        yield put(agencyAddressData.getDataCompleted(data))
    } catch (error) {
        const message = error.response?.data?.message || i18n.t("error")
        yield put(agencyAddressData.getDataRejected(message))
    }
}

export function* watchGetData() {
    yield takeLatest(agencyAddressData.getData, getData)
}

function* dataSaga() {
    yield all([fork(watchGetData)])
}

export default dataSaga