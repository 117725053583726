import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Container, Row } from "reactstrap"
import { Drawer } from "components"
import { useCountryOptions } from "hooks"
import { ValidationsUtils } from "utils"
import {
  modelPanelGeneralLogisticsDetailsEdit,
  modelPanelGeneralLogisticsDetailsEditCleanState,
} from "store/actions"

import { LogisticsForm } from "../LogisticsForm"
import { LogisticsDataLoader } from "../LogisticsDataLoader"
import moment from "moment"

const EditLogisticsComponent = props => {
  const { t } = useTranslation()

  const countryOptions = useCountryOptions({ visible: props.visible })

  const handleCloseDrawer = () => {
    props.onClose()
  }

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()

    props.onEditSuccess()
    props.onClose()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    props.modelPanelGeneralLogisticsDetailsEdit(
      props.id,
      params,
      onSubmitSuccess(onSubmitProps),
      onSubmitError(onSubmitProps)
    )
  }

  const onDestroy = () => {
    props.modelPanelGeneralLogisticsDetailsEditCleanState()
    props.onDestroy()
  }

  return (
    <React.Fragment>
      <Drawer
        title={t("logistics_and_expenses")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <LogisticsDataLoader id={props.id}>
          <Container fluid>
            <Row>
              <LogisticsForm
                initialValues={{
                  ...props.logistics,
                  country_id: props.logistics?.country,
                  departure_hour: props.logistics?.departure_hour
                    ? moment(props.logistics?.departure_hour, "HH:mm")
                    : null,
                  arrival_hour: props.logistics?.arrival_hour
                    ? moment(props.logistics?.arrival_hour, "HH:mm")
                    : null,
                }}
                loading={props.loading}
                error={props.error}
                onSubmit={handleSubmit}
                onCancel={handleCloseDrawer}
                countryOptions={countryOptions}
              />
            </Row>
          </Container>
        </LogisticsDataLoader>
      </Drawer>
    </React.Fragment>
  )
}

EditLogisticsComponent.propTypes = {
  visible: PropTypes.bool,
  onEditSuccess: PropTypes.func,
  onClose: PropTypes.func,
  id: PropTypes.number,
  onDestroy: PropTypes.func,

  logistics: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,

  modelPanelGeneralLogisticsDetailsEdit: PropTypes.func,
  modelPanelGeneralLogisticsDetailsEditCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.modelPanel.general.logistics.details.data
  const { loading, error } = state.modelPanel.general.logistics.details.edit
  return {
    logistics: data,
    loading,
    error,
  }
}

const mapDispatchToProps = {
  modelPanelGeneralLogisticsDetailsEdit,
  modelPanelGeneralLogisticsDetailsEditCleanState,
}

export const EditLogistics = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditLogisticsComponent)
