import React, { useMemo } from "react"
import PropTypes from "prop-types"

import { CalendarHolidayEventContent } from "../../EventsCalendarEventContent"
import { GENERAL_CALENDAR_ENTITY, EVENT_TYPE_BIRTHDAY } from "consts"

const {
  MODEL: ENTITY_MODEL,
  AGENCY: ENTITY_AGENCY,
  CLIENT: ENTITY_CLIENT,
} = GENERAL_CALENDAR_ENTITY

export const HolidayProvider = ({
  eventInfo,
  eventContent,
  entity = null,
  entityData = null,
}) => {
  const {
    event: { title, extendedProps },
  } = eventInfo

  const contentData = useMemo(() => {
    return {
      title: title,
      type: extendedProps.type,
    }
  }, [extendedProps, title])

  return (
    <>
      {eventContent ? (
        eventContent(eventInfo, contentData)
      ) : (
        <CalendarHolidayEventContent {...contentData} />
      )}
    </>
  )
}

HolidayProvider.propTypes = {
  eventInfo: PropTypes.object,
  eventContent: PropTypes.func,
  entity: PropTypes.oneOf([ENTITY_MODEL, ENTITY_AGENCY, ENTITY_CLIENT]),
  entityData: PropTypes.object,
}
