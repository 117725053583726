import React from 'react';
import PropTypes from "prop-types"
import { Col, Container, Row } from "reactstrap"
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PageHeader, MegaTagTitle } from '../../components'
import { PackageDataPickerContainer, PackageFormContainer } from './components';

const PackageAddComponent = (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <MegaTagTitle title={'title.package.create'} />

            <div className="page-content">
                <Container fluid>
                    <PageHeader
                        back
                        title={t('title.package.create')}
                    />

                    <Row>
                        <Col md={8}>
                            <div className={'pe-md-3'}>
                                <PackageDataPickerContainer />
                            </div>
                        </Col>

                        <Col md={4} className={'col-divider_start col-divider_md'}>
                            <div className={'pt-3 ps-md-3'}>
                                <PackageFormContainer mode={'add'} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

PackageAddComponent.propTypes = {
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export const PackageAdd = connect(mapStateToProps, mapDispatchToProps)(PackageAddComponent);