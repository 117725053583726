import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import toastr from "toastr";

import i18n from '../../../../../../i18n';
import { MODEL_PANEL_GENERAL_AGENCY_DETAILS_EDIT } from './actionTypes';
import {
    modelPanelGeneralAgencyDetailsEditSuccess,
    modelPanelGeneralAgencyDetailsEditError,
} from './actions';

import { API } from '../../../../../../api';

function* editAgency({ payload: { id, params, onSuccess, onError } }) {
    try {
        const response = yield call(API.model.updateModelAgency, id, params);

        const { data } = response;

        yield put(modelPanelGeneralAgencyDetailsEditSuccess());

        toastr.success(i18n.t('edit.success'));

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        const errors = error.response?.data?.errors || {};
        const message = error.response?.data?.message || i18n.t('error');

        yield put(modelPanelGeneralAgencyDetailsEditError({
            errors,
            message
        }));

        if (onError) {
            onError(errors);
        }
    }
}

export function* watchEditAgency() {
    yield takeEvery(MODEL_PANEL_GENERAL_AGENCY_DETAILS_EDIT, editAgency);
}

function* editSaga() {
    yield all([
        fork(watchEditAgency),
    ]);
}

export default editSaga;