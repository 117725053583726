import * as Yup from "yup"

export const validationSchema = isEdit =>
  Yup.object().shape({
    name: Yup.string().required("field.error.required"),
    users: isEdit
      ? Yup.array()
      : Yup.array().test(
          "users-required",
          "field.error.required",
          value => value.length > 0
        ),
  })
