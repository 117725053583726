import * as Yup from "yup"

import { ValidationsUtils } from "utils"

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("field.error.invalid")
    .nullable()
    .required("field.error.required"),
  first_name: Yup.string().nullable().required("field.error.required"),
  last_name: Yup.string().nullable().required("field.error.required"),
  company_name: Yup.string().nullable().required("field.error.required"),
  phone: Yup.string()
    .nullable()
    .required("field.error.required")
    .test("phone", "field.error.invalid", ValidationsUtils.phoneFormat),
  address: Yup.string().nullable().required("field.error.required"),
  city: Yup.string().nullable().required("field.error.required"),
  zip: Yup.number()
    .required("field.error.required")
    .typeError("field.error.number")
    .nullable(true)
    .transform((v, o) => (o === "" ? null : v)),
  note: Yup.string().nullable(),
  billing_address_country_id: Yup.mixed()
    .nullable(true)
    .when("tax_id", {
      is: tax_id => tax_id,
      then: Yup.mixed().nullable(true).required("field.error.required"),
    }),
  tax_id: Yup.string().nullable(),
})
