import { createSelector } from "@reduxjs/toolkit"
import { commonHelper } from "helpers"
export const selectEventColorByEventType = createSelector(
  [
    state => state.user.options.data.data.event_colors,
    (_, eventType) => eventType,
  ],
  (eventColors, eventType) =>
    commonHelper.colors.getColorFromEntity(eventColors[eventType])
)
