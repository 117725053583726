/* eslint react/prop-types: off */
import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { ReactTableView, Pagination } from "components"
import { clientHelper, commonHelper } from "helpers"
import { EVENT_TYPE_JOB, EVENT_TYPE_CASTING } from "consts"
import * as HOC from "HOC"

const currencySymbol = commonHelper.currency.getGeneralCurrency()?.symbol

const TableDataLoading = HOC.withTableDataLoading()

export const ActivitiesTable = props => {
  const { t } = useTranslation()

  const data = useMemo(() => {
    return props.list
  }, [props.list])

  const [columns, setColumns] = useState([
    {
      Header: t("date"),
      accessor: "date",
      id: "date",
      Cell: ({ row: { original } }) => {
        const { date_start, date_end } = original
        return `${moment(date_start).format("DD/MM/YYYY")} - ${moment(
          date_end
        ).format("DD/MM/YYYY")}`
      },
    },
    {
      Header: t("family"),
      accessor: "family",
      id: "family",
      Cell: ({ row: { original }, value }) => {
        return value ? clientHelper.family.getFamily(value)?.label : "-"
      },
    },
    {
      Header: t("client"),
      accessor: "client",
      id: "client",
      Cell: ({ row: { original } }) => {
        const { client } = original
        return client ? client?.name : "-"
      },
    },
    {
      Header: t("product"),
      accessor: "product",
      id: "product",
      Cell: ({ row: { original } }) => {
        const { product } = original
        return product || "-"
      },
    },
    {
      Header: t("budget"),
      accessor: "budget",
      id: "budget",
      Cell: ({ row: { original } }) => {
        const { budget } = original
        return `${budget} ${currencySymbol}`
      },
    },
  ])

  return (
    <TableDataLoading
      isLoading={props.loading}
      isFailed={props.error}
      error={props.error}
    >
      <ReactTableView
        isLoading={props.loading}
        columns={columns}
        data={data}
        initialState={{
          hiddenColumns: props.type === EVENT_TYPE_CASTING ? ["budget"] : [],
        }}
      />

      <Pagination
        data={props.meta}
        onPageChange={props.onPageChange}
        className={"mt-2"}
      />
    </TableDataLoading>
  )
}

ActivitiesTable.propTypes = {
  type: PropTypes.oneOf([EVENT_TYPE_JOB, EVENT_TYPE_CASTING]).isRequired,
  list: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  page: PropTypes.any,
  meta: PropTypes.object,
  onPageChange: PropTypes.func,
}
