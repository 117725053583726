import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { IconButton } from "components"
import { ContractDownloadMenuControl } from "components/Events/Shared"

export const Contracts = ({
  data,
  onEdit,
  onRemove,
  removing,
  onDownload,
  send,
  onSend,
}) => {
  const { t } = useTranslation()

  return (
    <div className="event-preview-invoices">
      <div className="table-responsive">
        <table className="table table-nowrap table-border-bottom">
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>
                  <div className={"text-nowrap"}>
                    <span className={"fw-medium"}>
                      {t("contract")}
                      {item?.contract_date
                        ? ` - ${moment(item?.contract_date).format(
                            "DD/MM/YYYY"
                          )}`
                        : null}
                    </span>
                  </div>
                </td>

                <td
                  className={
                    "table-column-sticky_end bg-white text-nowrap text-end"
                  }
                  style={{ width: "76px" }}
                >
                  {send && (
                    <IconButton
                      name={"mail"}
                      onClick={() => onSend()}
                      bg
                      disabled={removing}
                    />
                  )}
                  <IconButton
                    name={"edit"}
                    onClick={() => onEdit(item)}
                    bg
                    wrapClass={"ms-2"}
                    disabled={removing}
                  />

                  <ContractDownloadMenuControl
                    onDownload={value => {
                      if (value === "doc") {
                        onDownload(item?.doc_url)
                      } else if (value === "pdf") {
                        onDownload(item?.pdf_url)
                      }
                    }}
                    className={"ms-2"}
                    menuProps={{
                      positionFixed: true,
                      className: "dropdown-menu-fixed",
                    }}
                  >
                    <IconButton name={"download"} bg disabled={removing} />
                  </ContractDownloadMenuControl>

                  <IconButton
                    name={"remove"}
                    onClick={() => onRemove(item)}
                    bg
                    wrapClass={"ms-2"}
                    disabled={removing}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

Contracts.propTypes = {
  data: PropTypes.array,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  removing: PropTypes.bool,
  onDownload: PropTypes.func,
  send: PropTypes.bool,
  onSend: PropTypes.func,
}
