import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from "prop-types"

const PrivateRouteComponent = ({
    component: Component,
    path,
    ...rest
}) => {
    return (
        <Route {...rest}>
            {!rest.isLoggedIn ? (
                <Redirect to={{ pathname: '/login', state: { from: rest.location } }} />
            ) : (
                <Component {...rest} />
            )}
        </Route>
    );
};

PrivateRouteComponent.propTypes = {
    component: PropTypes.any,
    path: PropTypes.string,
    isLoggedIn: PropTypes.bool,
}

const mapStateToProps = state => {
    const { isLoggedIn } = state.auth.data;
    return { isLoggedIn };
};

export const PrivateRoute = withRouter(connect(mapStateToProps, {})(PrivateRouteComponent));