import { call } from "redux-saga/effects"
import { CardNumberElement, IbanElement } from "@stripe/react-stripe-js"

import { API } from "api"
import { BILLING_PAYMENT_METHOD_TYPE } from "consts"

const { CARD, SEPA_DEBIT } = BILLING_PAYMENT_METHOD_TYPE

export function* stripeSetup({ payload: { stripe, elements, params } }) {
  try {
    let result = {}

    const { payment_method_type, billing_details } = params

    const response = yield call(API.billing.setupIntent, {})
    const { data } = response

    const clientSecret = data.data.client_secret

    if (payment_method_type === CARD) {
      const cardNumberElement = elements.getElement(CardNumberElement)
      result = yield stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          // billing_details: { },
        },
      })
    } else if (payment_method_type === SEPA_DEBIT) {
      const iban = elements.getElement(IbanElement)
      result = yield stripe.confirmSepaDebitSetup(clientSecret, {
        payment_method: {
          sepa_debit: iban,
          billing_details: billing_details,
        },
      })
    }

    if (result.error) {
      result = { error: result.error }
    } else {
      // The card has been verified successfully...
      result = { setupIntent: result.setupIntent }
    }

    return result
  } catch (error) {
    throw error
  }
}
