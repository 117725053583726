import axios from "axios"

import { RequestUtils } from "utils"

class ImportAPI {
  getImport = async () => {
    return axios.get(`api/import`)
  }

  startImport = async params => {
    let formData = new FormData()

    Object.entries(params).forEach(entry => {
      const [key, value] = entry

      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          formData.append(`${key}[${i}]`, value[i])
        }
      } else {
        formData.append(key, value)
      }
    })

    return axios.post("api/import", formData)
  }
}

export const importEntity = new ImportAPI()
