import { takeEvery, fork, put, all, select } from "redux-saga/effects"

import { socketOnBillingInvoiceUpdate } from "./actions"
import { billingInvoiceList as billingInvoiceListAction } from "../../../billing/invoice/list/slice"

export const billingInvoiceListState = state => state.billing.invoice.list

function* onBillingInvoiceUpdate({ payload: { data } }) {
  const { invoice } = data

  const billingInvoiceList = yield select(billingInvoiceListState)

  // if is fetched
  if (billingInvoiceList.meta) {
    const {
      page,
      meta: { per_page },
    } = billingInvoiceList

    const params = {
      page,
      per_page,
    }

    // fetch invoices
    yield put(billingInvoiceListAction.getList({ params }))
  }
}

export function* watchOnBillingInvoiceUpdate() {
  yield takeEvery(socketOnBillingInvoiceUpdate, onBillingInvoiceUpdate)
}

function* invoiceSaga() {
  yield all([fork(watchOnBillingInvoiceUpdate)])
}

export default invoiceSaga
