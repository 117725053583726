import _ from "lodash"

export const createChatItemBasedOnMessage = (
  message,
  chat,
  isCurrentChat = false
) => {
  const { chat_id, ...messageData } = message

  const { is_group } = chat

  const isReceived = !messageData.is_my

  let result = {
    ...chat,
    id: chat_id,
    last_message: messageData,
    unread_count: isReceived && !isCurrentChat ? 1 : 0,
  }

  if (!is_group) {
    const { avatar, id, name } = chat.participant
    result.participant = {
      avatar,
      id,
      name,
    }
  }

  return result
}

export const updateChatItem = (
  item,
  message = {},
  chat,
  isCurrentChat = false
) => {
  const { chat_id, ...messageData } = message
  const isReceived = !messageData.is_my

  let result = {
    last_message: _.isEmpty(messageData) ? null : messageData,
  }

  if (isReceived) {
    result.participant = chat?.participant
    result.unread_count = isCurrentChat
      ? item.unread_count
      : item.unread_count + 1
  }

  return result
}
