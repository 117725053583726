import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { Button } from "components"
import { billingSubscriptionResume } from "store/actions"

export const SubscriptionResume = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { loading } = useSelector(state => state.billing.subscription.resume)

  useEffect(() => {
    return () => {
      dispatch(billingSubscriptionResume.cleanState())
    }
  }, [])

  const onResume = () => {
    dispatch(billingSubscriptionResume.resume())
  }

  return (
    <Button
      title={"resume"}
      onClick={onResume}
      outline
      loading={loading}
      disabled={loading}
      type={"button"}
      size={"btn-sm"}
    />
  )
}

SubscriptionResume.propTypes = {}
