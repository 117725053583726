import { all } from 'redux-saga/effects';

import booksSaga from './books/saga';
import videosSaga from './videos/saga';

function* dataCollectionsSaga() {
    yield all([
        booksSaga(),
        videosSaga(),
    ])
}

export default dataCollectionsSaga;