import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import i18n from "i18n"
import { API } from "api"
import { modelLogisticsExpenseAdd } from "./slice"

function* add({ payload: { id, params, onSuccess, onError } }) {
  try {
    const response = yield call(API.model.addLogisticExpenses, id, params)

    const { data } = response

    yield put(modelLogisticsExpenseAdd.addSuccess())

    if (onSuccess) {
      onSuccess(data?.data)
    }
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      modelLogisticsExpenseAdd.addError({
        errors,
        message,
      })
    )

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchAdd() {
  yield takeEvery(modelLogisticsExpenseAdd.add, add)
}

function* addSaga() {
  yield all([fork(watchAdd)])
}

export default addSaga
