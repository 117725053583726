import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import {
  calendarEventInvoiceEditData,
  calendarEventInvoiceViewData,
} from "store/actions"
import * as HOC from "HOC"

const DataShower = HOC.withDataShower()

export const EventInvoiceDataLoader = props => {
  const dispatch = useDispatch()

  const { data, loading, error } = useSelector(state =>
    props.isEdit
      ? state.calendarEvent.invoice.edit.data
      : state.calendarEvent.invoice.view.data
  )

  useEffect(() => {
    fetchData()

    return () => {
      if (props.isEdit) {
        dispatch(calendarEventInvoiceEditData.cleanState())
      } else {
        dispatch(calendarEventInvoiceViewData.cleanState())
      }
    }
  }, [])

  const fetchData = () => {
    if (props.isEdit) {
      dispatch(calendarEventInvoiceEditData.getData({ id: props.id }))
    } else {
      dispatch(calendarEventInvoiceViewData.getData({ id: props.id }))
    }
  }

  return (
    <DataShower isLoading={loading} isFailed={error} error={error}>
      {data && props.children}
    </DataShower>
  )
}

EventInvoiceDataLoader.propTypes = {
  id: PropTypes.number,
  isEdit: PropTypes.bool,
  children: PropTypes.any,
}
