import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { usePanelTopBarOptions } from "../../../../../hooks"
import { AddRating } from "./AddRating"
import { ConfirmPopup } from "../../../../../components"
import { ROLE_DIRECTOR } from "../../../../../consts"
import { RatingGrid } from "../../../../../components/RatingGrid"
import {
  clientRatingsData,
  clientRemoveRating,
} from "../../../../../store/actions"

const RatingContainerComponent = props => {
  const dispatch = useDispatch()
  //========================DATA PROVIDE LOGIC=======================//
  const fetchData = () => {
    let params = {
      page: props.page,
    }
    dispatch(clientRatingsData.getData({ id: props.client.data?.id, params }))
  }

  useEffect(() => {
    fetchData()
  }, [props.page])

  useEffect(() => {
    return () => {
      dispatch(clientRatingsData.cleanState())
      dispatch(clientRemoveRating.cleanState())
    }
  }, [])

  //========================ADD RATING LOGIC=======================//

  const handleAddSuccess = () => {
    fetchData()
  }

  const panelOptions = useMemo(
    () => ({
      actions: (
        <>
          <AddRating onAddSuccess={handleAddSuccess} />
        </>
      ),
    }),
    [props.page]
  )

  usePanelTopBarOptions(panelOptions)

  //========================PAGE CHANGE LOGIC=======================//

  const handlePageChange = item => {
    dispatch(clientRatingsData.setPage(item.selected + 1))
  }

  //========================REMOVE LOGIC=======================//

  const removeModalRef = useRef(null)
  const [ratingRemove, setRatingRemove] = useState(null)

  const removeRating = item => {
    setRatingRemove(item)

    if (removeModalRef.current) {
      removeModalRef.current.show()
    }
  }

  const resetRatingRemove = () => {
    setRatingRemove(null)
  }

  const onRemoveSuccess = () => {
    fetchData()
  }

  const confirmRemove = () => {
    dispatch(
      clientRemoveRating.remove({
        id: ratingRemove?.id,
        onSuccess: onRemoveSuccess,
      })
    )
  }

  return (
    <>
      <RatingGrid
        items={props.list}
        loading={props.listLoading}
        error={props.listError}
        meta={props.meta}
        page={props.page}
        onPageChange={handlePageChange}
        onRemove={removeRating}
        withRemove={props.user?.role === ROLE_DIRECTOR}
        removing={props.removing}
      />

      <ConfirmPopup
        ref={removeModalRef}
        onConfirm={confirmRemove}
        onClosed={resetRatingRemove}
      />
    </>
  )
}

RatingContainerComponent.propTypes = {
  client: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
  removing: PropTypes.bool,

  user: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.client.profile
  const { list, loading, error, meta, page } = state.client.rating.data
  return {
    client: data,
    listLoading: loading,
    listError: error,
    user: state.user.profile.data,
    removing: state.client.rating.remove.loading,
    list,
    meta,
    page,
  }
}

export const RatingContainer = connect(mapStateToProps)(
  RatingContainerComponent
)
