import React from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"

import { Drawer } from "components"

export const GroupFormDrawer = props => {
  const handleCloseDrawer = () => {
    props.onClose()
  }

  const onDestroy = () => {
    props.onDestroy && props.onDestroy()
  }

  return (
    <Drawer
      title={props.title}
      visible={props.visible}
      onClose={handleCloseDrawer}
      placement={"right"}
      className={"drawer-container"}
      destroyOnClose
      onDestroy={onDestroy}
    >
      <Container fluid>{props.children}</Container>
    </Drawer>
  )
}

GroupFormDrawer.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.any,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  children: PropTypes.any,
}
