import { combineReducers } from "redux"

import remove from "./remove/slice"
import update from "./update/slice"
import add from "./add/slice"
import reorder from "./reorder/slice"
import list from "./list/slice"

const menuItemsReducer = combineReducers({
  remove,
  update,
  add,
  reorder,
  list,
})

export default menuItemsReducer
