import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import * as HOC from "HOC"
import { websiteCurrentPageData } from "store"
import { ModelsContainer } from "../ModelsContainer"

const DataShower = HOC.withDataShower()

const ModelsPageLoaderComponent = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    fetchData()
    return () => {
      dispatch(websiteCurrentPageData.cleanState())
    }
  }, [])

  const fetchData = () => {
    dispatch(websiteCurrentPageData.getData({ alias: props.alias }))
  }

  return (
    <>
      <DataShower
        isLoading={props.loading}
        isFailed={props.error}
        error={props.error}
      >
        {props.data && <ModelsContainer gender={props.gender} />}
      </DataShower>
    </>
  )
}

ModelsPageLoaderComponent.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.any,

  alias: PropTypes.string,
  gender: PropTypes.string,
}

const mapStateToProps = state => {
  const { data, loading, error } = state.website.general.currentPage.data
  return {
    data,
    loading,
    error,
  }
}

export const ModelsPageLoader = connect(mapStateToProps)(
  ModelsPageLoaderComponent
)
