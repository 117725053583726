import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { commonHelper } from "helpers"

const getCurrency = currency =>
  commonHelper.currency.getCurrency(currency)?.label?.toUpperCase()

const Cell = ({ label, value }) => {
  return (
    <>
      <p className={"mb-0 fw-medium"}>{label}</p>
      {value}
    </>
  )
}

Cell.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
}

export const Summary = props => {
  const { t } = useTranslation()

  const {
    job_fee,
    expenses_sum,
    agencies_fee_sum,
    currency,
    vat_percent,
    taxable,
    tax,
    total_taxable,
    total_tax,
    total_amount,
  } = props.data

  return (
    <div className="invoice-card-summary">
      <div className={"table-responsive"}>
        <table className={"table table-bordered text-nowrap"}>
          <tbody>
            <tr>
              <td>
                <Cell label={t("job_fee")} value={job_fee} />
              </td>
              <td>
                <Cell label={t("expenses")} value={expenses_sum} />
              </td>
              <td>
                <Cell label={t("prod_fee")} value={agencies_fee_sum} />
              </td>
              <td>
                <Cell label={t("currency")} value={getCurrency(currency)} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={"table-responsive"}>
        <table className={"table table-bordered text-nowrap"}>
          <tbody>
          <tr>
            <td>
              <Cell label={t("vat")} value={`${vat_percent} %`} />
            </td>
            <td>
              <Cell label={t("taxable")} value={taxable} />
            </td>
            <td>
              <Cell label={t("tax")} value={tax} />
            </td>
            <td>
              <Cell label={t("total_taxable")} value={total_taxable} />
            </td>
            <td>
              <Cell label={t("total_tax")} value={total_tax} />
            </td>
            <td>
              <Cell
                label={t("total_amount_due")}
                value={
                  <h4>
                    {getCurrency(currency)} {total_amount}
                  </h4>
                }
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

Summary.propTypes = {
  data: PropTypes.object,
}
