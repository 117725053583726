import { LOGOUT_USER, LOGOUT_USER_SUCCESS } from "./actionTypes"

export const logoutUser = ({ onSuccess }) => {
  return {
    type: LOGOUT_USER,
    payload: { onSuccess },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}
