import axios from "axios"

class SharedEntitiesAPI {
  getSharedEntity = async id => {
    return axios.get(`api/shared-entities/${id}`)
  }

  createSharedEntities = async params => {
    return axios.post("api/shared-entities", params)
  }
}

export const sharedEntities = new SharedEntitiesAPI()
