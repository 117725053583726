import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
import classNames from "classnames"
import { useFieldArray } from "react-hook-form"

import {
  IconButton,
  DateInputField,
  ControllerHF,
  InputField,
} from "components"

/* prettier-ignore */
const mask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/]

export const AlertsFieldArrayHF = props => {
  const { t } = useTranslation()

  const error = props?.meta?.error
  const fieldName = props.name

  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: fieldName,
    keyName: "fieldId",
  })

  const addRow = e => {
    e.preventDefault()

    if (props.clearErrors) {
      props.clearErrors(props.name)
    }
    append({ date_start: null, description: "" })
  }

  const removeRow = index => {
    remove(index)
  }

  return (
    <Col>
      <Row>
        {fields.map((item, index) => (
          <Col key={item.fieldId} md={6}>
            <Row>
              <Col xs={10}>
                <Row>
                  <Col>
                    <ControllerHF
                      name={`${fieldName}.${index}.date_start`}
                      control={props.control}
                      component={DateInputField}
                      id={`${fieldName}.${index}.date_start`}
                      label={t("date")}
                      placeholder={"DD/MM/YYYY hh:mm"}
                      showTimeSelect
                      dateFormat={"dd/MM/yyyy HH:mm"}
                      mask={mask}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ControllerHF
                      name={`${fieldName}.${index}.description`}
                      control={props.control}
                      component={InputField}
                      id={`${fieldName}.${index}.description`}
                      label={t("description")}
                      placeholder={t("description")}
                      type={"textarea"}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xs={2}>
                <div className={"d-flex"}>
                  <div className={"mb-3 form-action-button-block"}>
                    <IconButton
                      name={"remove"}
                      bg
                      onClick={() => removeRow(index)}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>

      {fields.length < props.limit && (
        <Row>
          <Col>
            <Link
              to="#"
              className="text-secondary text-decoration-underline"
              onClick={addRow}
            >
              <i className={classNames("align-middle mr-2 bx bx-plus")} />

              {t("add_alert")}
            </Link>
            <div className={"field-error-message"}>
              {error && t(error?.message)}
            </div>
          </Col>
        </Row>
      )}
    </Col>
  )
}

AlertsFieldArrayHF.propTypes = {
  name: PropTypes.string,
  limit: PropTypes.number,
  control: PropTypes.object,
  clearErrors: PropTypes.any,
  meta: PropTypes.any,
}
