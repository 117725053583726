import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types"
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export const ImageLightbox = ({
    items,
    isOpen,
    initialIndex,
    onClose,
    enableZoom = false,
    onChange,
}) => {
    const [activeIndex, setActiveIndex] = useState(initialIndex);

    useEffect(() => {
        setActiveIndex(initialIndex)
        if (onChange) {
            onChange(initialIndex);
        }
    }, [initialIndex, isOpen]);

    return (
        <>
            {isOpen && (
                <Lightbox
                    mainSrc={items[activeIndex]}
                    nextSrc={items[(activeIndex + 1) % items.length]}
                    prevSrc={
                        items[(activeIndex + items.length - 1) % items.length]
                    }
                    enableZoom={enableZoom}
                    onCloseRequest={() => { onClose() }}
                    onMovePrevRequest={() => { setActiveIndex((activeIndex + items.length - 1) % items.length) } }
                    onMoveNextRequest={() => { setActiveIndex((activeIndex + 1) % items.length) } }
                />
            )}
        </>
    );
};

ImageLightbox.propTypes = {
    items: PropTypes.array,
    isOpen: PropTypes.bool,
    initialIndex: PropTypes.number,
    onClose: PropTypes.func,
    enableZoom: PropTypes.bool,
    onChange: PropTypes.func,
}