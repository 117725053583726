import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import i18n from '../../../../../i18n';
import { PACKAGE_DRAFT_DATA_COLLECTIONS_FETCH_VIDEOS } from './actionTypes';
import {
    packageDraftDataCollectionsFetchVideosSuccess,
    packageDraftDataCollectionsFetchVideosError,
} from './actions';

import { API } from '../../../../../api';
import { RequestUtils } from '../../../../../utils';

export function* fetchList({ payload: { id, params } }) {
    try {
        let requestParams = {
            includes: [],
        };

        requestParams = RequestUtils.getMapRequestParams(params, requestParams);

        const response = yield call(API.model.getModelVideos, id, requestParams);

        const { data } = response;

        yield put(packageDraftDataCollectionsFetchVideosSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message || i18n.t('error');
        yield put(packageDraftDataCollectionsFetchVideosError(message));
    }
}

export function* watchFetchList() {
    yield takeLatest(PACKAGE_DRAFT_DATA_COLLECTIONS_FETCH_VIDEOS, fetchList)
}

function* dataSaga() {
    yield all([
        fork(watchFetchList),
    ]);
}

export default dataSaga;