import React from "react"
import PropTypes from "prop-types"
import { InfoCard } from "../../../../../../components"
import { useTranslation } from "react-i18next"

export const PaymentDetailsInfoCard = props => {
  const { t } = useTranslation()
  return (
    <InfoCard
      onEdit={props.onEdit}
      editMode={props.editMode}
      title={t("payment-details")}
      className={"h-100 client-note-info"}
    >
      <div className={"d-flex flex-wrap client-note-info__wrap"}>
        {props.data.payment_details || t("empty")}
      </div>
    </InfoCard>
  )
}

PaymentDetailsInfoCard.propTypes = {
  onEdit: PropTypes.func,
  editMode: PropTypes.bool,
  data: PropTypes.object,
}
