import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { HeaderContent } from "./HeaderContent"
import { EditGroup, ChatInfoView } from "../../Common"
import { useConfirmModal, useDrawer, useModal } from "hooks"
import { chatGroupsRemove, chatGroupsLeave } from "store/actions"
import { ConfirmPopup } from "components"

export const Header = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { user, conversation, removing, leaving } = useSelector(state => ({
    user: state.user.profile.data,
    conversation: state.chat.conversation.data.data,
    removing: state.chat.groups.remove.loading,
    leaving: state.chat.groups.leave.loading,
  }))

  const { is_group, participant, name, participants, admin, id } = conversation
  const title = is_group ? name : participant?.name
  const isAdmin = user.id === admin?.id

  // Edit group
  const {
    hide: hideEditDrawer,
    show: showEditDrawer,
    visible: editDrawerVisible,
  } = useDrawer()

  const onEditSuccess = () => {
    hideEditDrawer()
  }
  // === //

  // Remove group
  const {
    showModal: showRemoveModal,
    closeModal: closeRemoveModal,
    handleConfirm: confirmRemove,
    modalRef: removeModalRef,
  } = useConfirmModal(
    () => {
      dispatch(
        chatGroupsRemove.remove({
          id,
        })
      )
    },
    () => {}
  )

  // reset removing state
  useEffect(() => {
    if (id && is_group && removing) {
      dispatch(chatGroupsRemove.cleanState())
    }
  }, [id, is_group, removing])
  // === //

  // Leave group
  const {
    showModal: showLeaveModal,
    closeModal: closeLeaveModal,
    handleConfirm: confirmLeave,
    modalRef: leaveModalRef,
  } = useConfirmModal(
    () => {
      dispatch(
        chatGroupsLeave.leave({
          id,
        })
      )
    },
    () => {}
  )

  // reset leaving state
  useEffect(() => {
    if (id && is_group && leaving) {
      dispatch(chatGroupsLeave.cleanState())
    }
  }, [id, is_group, leaving])
  // === //

  // View info
  const {
    modalRef: infoModalRef,
    closeModal: closeInfoModal,
    showModal: showInfoModal,
  } = useModal()
  // === //

  return (
    <>
      <HeaderContent
        title={title}
        isAdmin={isAdmin}
        isGroup={is_group}
        participants={participants}
        onEdit={showEditDrawer}
        onRemove={showRemoveModal}
        onLeave={showLeaveModal}
        onView={showInfoModal}
      />

      {is_group && (
        <>
          <EditGroup
            visible={editDrawerVisible}
            onClose={hideEditDrawer}
            onSuccess={onEditSuccess}
          />

          <ChatInfoView modalRef={infoModalRef} onCancel={closeInfoModal} />

          {/* refactoring: make single confirm popup on remove, leave */}
          <ConfirmPopup
            ref={removeModalRef}
            onConfirm={confirmRemove}
            onClosed={closeRemoveModal}
            questionText={"confirm-sure"}
          />

          <ConfirmPopup
            ref={leaveModalRef}
            onConfirm={confirmLeave}
            onClosed={closeLeaveModal}
            questionText={"confirm-sure"}
          />
        </>
      )}
    </>
  )
}

Header.propTypes = {}
