import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { Link } from "react-router-dom"

import placeholderIcon from "../../../assets/images/dev/icon-photo-placeholder.svg"
import { IconButton } from "../../Buttons"
import { FieldError } from "../FieldError"

export const PhotoPickerField = ({
  id,
  label,
  selectBtnText,
  selectBtnIcon = "bx bx-plus",
  className,
  value,
  meta = {},
  ...props
}) => {
  const { error } = meta

  const showError = Boolean(error)

  const { t } = useTranslation()

  const [selectedPhoto, setSelectedPhoto] = useState(null)
  const inputRef = useRef()

  const onChange = date => {
    if (props.onChange) {
      props.onChange(date)
    }
  }

  const handleImageSelected = event => {
    const file = event.target.files[0]

    onChange(file)

    if (inputRef.current) {
      inputRef.current.value = null
    }
  }

  const selectPhoto = event => {
    event.preventDefault()

    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const removePhoto = () => {
    onChange(null)
  }

  useEffect(() => {
    if (value) {
      const file = value

      const reader = new FileReader()
      reader.onload = () => {
        if (reader.readyState === 2) {
          setSelectedPhoto(reader.result)
        }
      }
      reader.readAsDataURL(file)
    } else {
      setSelectedPhoto(null)
    }
  }, [value])

  const renderPlaceholder = () => (
    <div className="photo-picker__placeholder-block">
      <img src={placeholderIcon} alt="icon" />
    </div>
  )

  return (
    <div
      className={classNames("photo-picker", {
        [className]: className,
        "photo-picker__selected": selectedPhoto,
      })}
    >
      <div
        className={classNames("d-flex", {
          "is-invalid": showError,
        })}
      >
        <div className={"photo-picker__block"}>
          <div className={"photo-picker__select-block"}>
            <div className="photo-picker__select-wrap">
              <label htmlFor={`single-photo-${id}`} className={"avatar-label"}>
                <div className="photo-picker__photo-block">
                  {selectedPhoto ? (
                    <img
                      src={selectedPhoto}
                      alt="photo"
                      className="img-cover"
                    />
                  ) : (
                    renderPlaceholder()
                  )}
                </div>
              </label>
              <input
                type="file"
                id={`single-photo-${id}`}
                className="d-none"
                onChange={handleImageSelected}
                ref={inputRef}
                accept="image/png, image/jpeg"
              />
            </div>
          </div>

          <Link
            to="#"
            className="text-secondary text-decoration-underline photo-picker__select-btn"
            onClick={selectPhoto}
          >
            <i
              className={classNames("align-middle mr-2", {
                [selectBtnIcon]: selectBtnIcon,
              })}
            />

            {selectBtnText}
          </Link>
        </div>

        {value && (
          <div>
            <IconButton name={"remove"} onClick={removePhoto} bg />
          </div>
        )}
      </div>

      <FieldError
        error={error}
        translateParams={props.translateParams}
        showError={showError}
        className={"mt-2"}
      />
    </div>
  )
}

PhotoPickerField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  selectBtnText: PropTypes.string,
  selectBtnIcon: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  translateParams: PropTypes.object,
  value: PropTypes.any,
  meta: PropTypes.object,
}
