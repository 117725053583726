import {
    MODEL_PANEL_DELETE_MODEL,
    MODEL_PANEL_DELETE_MODEL_SUCCESS,
    MODEL_PANEL_DELETE_MODEL_ERROR,
    MODEL_PANEL_DELETE_MODEL_CLEAN_STATE,
} from './actionTypes';

export const modelPanelDeleteModel = (id, history) => {
    return {
        type: MODEL_PANEL_DELETE_MODEL,
        payload: { id, history },
    }
};

export const modelPanelDeleteModelSuccess = () => {
    return {
        type: MODEL_PANEL_DELETE_MODEL_SUCCESS,
    }
};

export const modelPanelDeleteModelError = () => {
    return {
        type: MODEL_PANEL_DELETE_MODEL_ERROR,
    }
};

export const modelPanelDeleteModelCleanState = () => {
    return { type: MODEL_PANEL_DELETE_MODEL_CLEAN_STATE };
};