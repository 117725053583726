import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { IconButton, RateInfoCell } from "components"
import { FileUtils } from "utils"

export const Attachments = ({ data }) => {
  const { t } = useTranslation()

  const attachmentsData = useMemo(() => {
    // map attachments data
    let models = data
      .filter(model => !!model?.files?.length)
      .map(item => ({
        id: item.id,
        name: item.name,
        files: item.files,
      }))
    return { models }
  }, [data])

  const onDownload = item => {
    FileUtils.downloadFileByLink(item?.attachment?.src)
  }

  return (
    <div className="event-preview-attachments">
      {!!attachmentsData.models.length
        ? attachmentsData.models.map(model => (
            <React.Fragment key={model.id}>
              <h5 className={"mb-0"}>{model.name}</h5>

              <div className="table-responsive">
                <table className="table table-nowrap table-border-bottom">
                  <tbody>
                    {model.files.map(item => (
                      <tr key={item.id}>
                        <td>
                          <div className={"text-nowrap"}>
                            <span className={"fw-bold"}>
                              {item.title}
                            </span>
                          </div>
                        </td>

                        <td
                          className={
                            "table-column-sticky_end bg-white text-nowrap text-end"
                          }
                          style={{ width: "76px" }}
                        >
                          <IconButton
                            name={"download"}
                            onClick={() => onDownload(item)}
                            bg
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          ))
        : t("no_attachments")}
    </div>
  )
}

Attachments.propTypes = {
  data: PropTypes.array,
}
