import React from "react"
import PropTypes from "prop-types"
import { SOCIAL_POST_TYPE_FACEBOOK, SOCIAL_POST_TYPE_INSTAGRAM } from "consts"
import { socialPostsCalendarHelper } from "helpers"

const { getInstagramAccountLink, getFacebookPageLink } =
  socialPostsCalendarHelper.postInfo

export const AccountLink = ({ type, accountName, accountId }) => {
  switch (type) {
    case SOCIAL_POST_TYPE_INSTAGRAM:
      return (
        <a
          href={getInstagramAccountLink(accountName)}
          target={"_blank"}
          rel="noreferrer"
        >
          {"@" + accountName}
        </a>
      )
    case SOCIAL_POST_TYPE_FACEBOOK:
      return (
        <a
          href={getFacebookPageLink(accountId)}
          target={"_blank"}
          rel="noreferrer"
        >
          {accountName}
        </a>
      )
    default:
      return ""
  }
}

AccountLink.propTypes = {
  type: PropTypes.string,
  accountName: PropTypes.string,
  accountId: PropTypes.any,
}
