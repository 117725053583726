import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Media, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import { Image } from "components"

const UploadImageComponent = ({
  id = "img-upload",
  image,
  placeholder,
  labelUpload,
  labelUploadNew,
  labelRemove,
  loading,
  onUpdate,
  onRemove,
}) => {
  const { t } = useTranslation()

  const [selectedPhoto, setSelectedPhoto] = useState(null)
  const inputRef = useRef()

  const handleImageSelected = event => {
    const file = event.target.files[0]

    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setSelectedPhoto(reader.result)
      }
    }
    reader.readAsDataURL(file)

    onUpdate(file)

    if (inputRef.current) {
      inputRef.current.value = null
    }
  }

  const selectPhoto = event => {
    event.preventDefault()

    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const removePhoto = () => {
    onRemove()
  }

  useEffect(() => {
    if (image) {
      setSelectedPhoto(null)
    }
  }, [image])

  return (
    <Media>
      <div className="d-flex align-items-baseline me-3 avatar-select-wrap">
        <label htmlFor={id} className={"image-rounded photo-rounded__md mb-0"}>
          <Image
            src={selectedPhoto ? selectedPhoto : image ? image : placeholder}
          />
        </label>
        <input
          type="file"
          id={id}
          style={{ display: "none" }}
          onChange={handleImageSelected}
          ref={inputRef}
          accept="image/png, image/jpeg"
        />
        <div
          className={"avatar-loading"}
          style={{ display: loading ? "flex" : "none" }}
        >
          <Spinner size={"sm"} color="primary-green" />
        </div>
      </div>
      <Media body className="align-self-center">
        <div
          className={"flex-column"}
          style={{ display: !loading ? "flex" : "none" }}
        >
          <div>
            <Link to="#" className="text-secondary" onClick={selectPhoto}>
              {image ? t(labelUploadNew) : t(labelUpload)}
            </Link>
          </div>

          {image && (
            <div className={"mt-1"}>
              <Link to="#" className="text-danger" onClick={removePhoto}>
                {t(labelRemove)}
              </Link>
            </div>
          )}
        </div>
      </Media>
    </Media>
  )
}

UploadImageComponent.propTypes = {
  id: PropTypes.string,
  image: PropTypes.any,
  placeholder: PropTypes.any,
  labelUpload: PropTypes.string,
  labelUploadNew: PropTypes.string,
  labelRemove: PropTypes.string,
  loading: PropTypes.bool,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const UploadImage = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadImageComponent)
