import { all } from "redux-saga/effects"

import removeSaga from "./remove/saga"
import addSaga from "./add/saga"

function* logoSaga() {
  yield all([addSaga(), removeSaga()])
}

export default logoSaga
