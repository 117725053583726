import { combineReducers } from "redux"

import pay from "./pay/slice"
import tariff from "./tariff/reducers"
import billingInfo from "./billingInfo/reducers"
import paymentMethod from "./paymentMethod/reducers"
import invoice from "./invoice/reducers"
import subscription from "./subscription/reducers"

const billingReducer = combineReducers({
  pay,
  tariff,
  billingInfo,
  paymentMethod,
  invoice,
  subscription,
})

export default billingReducer
