import React, { useRef } from "react"
import { useDeepCompareEffect } from "react-use"
import { useSelector } from "react-redux"

import { GeneralEventsCalendar } from "components"
import { EventsCalendarProvider } from "contexts"
import { useEventsCalendar } from "hooks"
import { CompareEventsCalendar } from "features/calendar/CompareEventsCalendar/components"
import { useCompareEventsCalendar } from "features/calendar/CompareEventsCalendar/hooks"
import { CompareEventsCalendarProvider } from "features/calendar/CompareEventsCalendar/contexts"

export const CalendarContainer = () => {
  const compareCalendarBlock = useRef(null)

  const { genders, group } = useSelector(
    state => state.modelPanel.panel.modelsSidebar.filters.values
  )
  const { type } = useSelector(state => state.calendarEvent.list.filters.values)

  const compareEventsCalendar = useCompareEventsCalendar()

  const comparePeriod = compareEventsCalendar.state.period

  const comparePeriodValid =
    comparePeriod.filter(date => Boolean(date)).length === 2

  const onComparePeriodSetup = (period = []) => {
    compareEventsCalendar.onSetup({
      period,
      fetchParams: {
        gender: [...genders].length === 1 ? [...genders] : null,
        groups: group ? [group] : null,
        type: type || null,
      },
    })

    if (compareCalendarBlock.current) {
      compareCalendarBlock.current.scrollIntoView()
    }
  }

  const calendar = useEventsCalendar({
    config: { withComparePeriod: true },
    onComparePeriod: onComparePeriodSetup,
  })

  useDeepCompareEffect(() => {
    calendar.updateConfig({
      gender: [...genders].length === 1 ? [...genders][0] : null,
    })
  }, [genders])

  return (
    <>
      <div>
        <EventsCalendarProvider {...calendar}>
          <GeneralEventsCalendar
            eventsFetchParams={{
              gender: [...genders].length === 1 ? [...genders] : null,
              groups: group ? [group] : null,
            }}
          />
        </EventsCalendarProvider>
      </div>

      <div ref={compareCalendarBlock}>
        <CompareEventsCalendarProvider {...compareEventsCalendar}>
          {comparePeriodValid ? <CompareEventsCalendar /> : null}
        </CompareEventsCalendarProvider>
      </div>
    </>
  )
}

CalendarContainer.propTypes = {}
