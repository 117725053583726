import { combineReducers } from "redux"

import list from "./list/slice"
import update from "./update/slice"

const templatesReducer = combineReducers({
  list,
  update,
})

export default templatesReducer
