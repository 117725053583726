import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

export const Check = ({ className, double = false, ...props }) => {
  return (
    <i
      className={classNames("bx", {
        [className]: className,
        "bx-check": !double,
        "bx-check-double": double,
      })}
    />
  )
}

Check.propTypes = {
  className: PropTypes.string,
  double: PropTypes.bool,
}
