import React, { useRef, useEffect } from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

import { Button, ConfirmPopup } from '../../../../components';
import { employeeDetailsRemoveUser, employeeDetailsRemoveUserCleanState } from '../../../../store/actions';

const RemoveEmployeeComponent = (props) => {
    const { t } = useTranslation();
    const history = useHistory();

    const modalRef = useRef(null);

    useEffect(() => {
        return () => {
            props.employeeDetailsRemoveUserCleanState();
        }
    }, []);

    const handleRemove = () => {
        if (modalRef.current) {
            modalRef.current.show();
        }
    };

    const handleConfirm = () => {
        props.employeeDetailsRemoveUser(props.id, history);
    };

    return (
        <React.Fragment>
            <Button
                title={'btn.remove'}
                onClick={handleRemove}
                loading={props.loading}
                disabled={props.loading}
            />

            <ConfirmPopup ref={modalRef} onConfirm={handleConfirm} />
        </React.Fragment>
    );
};

RemoveEmployeeComponent.propTypes = {
    id: PropTypes.any,
    loading: PropTypes.bool,
    error: PropTypes.object,

    employeeDetailsRemoveUser: PropTypes.func,
    employeeDetailsRemoveUserCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { loading, error } = state.employee.details.remove;
    return {
        loading,
        error,
    };
};

const mapDispatchToProps = {
    employeeDetailsRemoveUser,
    employeeDetailsRemoveUserCleanState,
};

export const RemoveEmployee = connect(mapStateToProps, mapDispatchToProps)(RemoveEmployeeComponent);