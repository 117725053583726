import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import toastr from "toastr"
import { Redirect } from "react-router-dom"

import { PanelLayout, PanelRouting } from "../components"
import { BillingPortalSocketMiddleware } from "components"
import { useQuery } from "hooks"

const BillingPanelComponent = props => {
  let query = useQuery()
  const { t } = useTranslation()

  if (query.get("success")) {
    const status = query.get("success") === "true"

    if (status) {
      const message = query.get("message") || t("billing.pay.success")
      toastr.success(message)
    } else {
      const message = query.get("message") || t("billing.pay.error")
      toastr.error(message)
    }

    return <Redirect to={"/billing/general"} />
  }

  return (
    <BillingPortalSocketMiddleware>
      <PanelLayout>
        <PanelRouting />
      </PanelLayout>
    </BillingPortalSocketMiddleware>
  )
}

BillingPanelComponent.propTypes = {}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const BillingPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingPanelComponent)
