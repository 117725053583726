import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert } from "reactstrap"
import * as Yup from "yup"
import {
  Button,
  ComboboxFormField,
  ControllerHF,
  Image,
  InputField,
} from "components"
import { useFacebookAuth } from "packages/facebook"
import { SOCIAL_POST_METHOD_MANUAL, SOCIAL_POST_TYPE_FACEBOOK } from "consts"

export const PublishToFBPageForm = props => {
  const { t } = useTranslation()
  const { pages } = useFacebookAuth()
  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      page: null,
      description: "",
      ...props.initialValues,
    },
    resolver: yupResolver(
      Yup.object().shape({
        page: Yup.object()
          .nullable()
          .required("field.error.required-facebook-page"),
      })
    ),
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
      type: SOCIAL_POST_TYPE_FACEBOOK,
      method: SOCIAL_POST_METHOD_MANUAL,
      account_number: values.page.id,
      account_name: values.page.name,
    }
    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={
            "image-rect image-rect__w-25 d-flex justify-content-center"
          }
        >
          <Image src={props.imageUrl} alt="image" />
        </div>
        {props.error && <Alert color="danger">{props.error.message}</Alert>}
        <ControllerHF
          type={"textarea"}
          name={"description"}
          control={control}
          component={InputField}
          id={"description"}
          label={t("description")}
          placeholder={t("description")}
        />
        <ControllerHF
          name={"page"}
          control={control}
          component={ComboboxFormField}
          id={"page"}
          placeholder={t("fb-page")}
          label={t("fb-page")}
          options={pages}
          getOptionLabel={option => option.name}
          getOptionValue={option => option}
        />
        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              leftIcon={"bx bxl-facebook-circle"}
              color={"facebook"}
              title={"publish-to-page"}
              disabled={props.loading}
              loading={props.loading}
            />
            <Button title={"cancel"} color="light" outline onClick={onCancel} />
          </div>
        </div>
      </form>
    </>
  )
}

PublishToFBPageForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  imageUrl: PropTypes.string,
}
