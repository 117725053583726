import _ from "lodash"

import i18n from "../../i18n"
import {
  CLIENT_FAMILY_TYPE_ADVERTORIAL,
  CLIENT_FAMILY_TYPE_CALLBACK,
  CLIENT_FAMILY_TYPE_CAMPAIGN,
  CLIENT_FAMILY_TYPE_CATALOGUE,
  CLIENT_FAMILY_TYPE_COMMERCIAL,
  CLIENT_FAMILY_TYPE_DIGITAL_CAMPAIGN,
  CLIENT_FAMILY_TYPE_DIGITAL_SHOOTING,
  CLIENT_FAMILY_TYPE_EDITORIAL,
  CLIENT_FAMILY_TYPE_ECOMMERCE,
  CLIENT_FAMILY_TYPE_FITTING,
  CLIENT_FAMILY_TYPE_FREE_TEST,
  CLIENT_FAMILY_TYPE_HAIR_AND_MAKEUP,
  CLIENT_FAMILY_TYPE_INTERNAL_VIDEO,
  CLIENT_FAMILY_TYPE_INTERNET,
  CLIENT_FAMILY_TYPE_LOOKS,
  CLIENT_FAMILY_TYPE_LOOKBOOK,
  CLIENT_FAMILY_TYPE_ON_REQUEST,
  CLIENT_FAMILY_TYPE_OPEN,
  CLIENT_FAMILY_TYPE_POP,
  CLIENT_FAMILY_TYPE_POSTER,
  CLIENT_FAMILY_TYPE_PRESENTATION,
  CLIENT_FAMILY_TYPE_PRESS_RELEASE,
  CLIENT_FAMILY_TYPE_REHEARSAL,
  CLIENT_FAMILY_TYPE_SHOWROOM,
  CLIENT_FAMILY_TYPE_STANDING_SHOW,
  CLIENT_FAMILY_TYPE_SHOW,
  CLIENT_FAMILY_TYPE_TEST,
  CLIENT_FAMILY_TYPE_TV,
  CLIENT_FAMILY_TYPE_VIDEOCLIP,
} from "../../consts"

export const getFamilyOptions = () => {
  return [
    {
      label: i18n.t("client-family-type.advertorial"),
      value: CLIENT_FAMILY_TYPE_ADVERTORIAL,
    },
    {
      label: i18n.t("client-family-type.callback"),
      value: CLIENT_FAMILY_TYPE_CALLBACK,
    },
    {
      label: i18n.t("client-family-type.campaign"),
      value: CLIENT_FAMILY_TYPE_CAMPAIGN,
    },
    {
      label: i18n.t("client-family-type.catalogue"),
      value: CLIENT_FAMILY_TYPE_CATALOGUE,
    },
    {
      label: i18n.t("client-family-type.commercial"),
      value: CLIENT_FAMILY_TYPE_COMMERCIAL,
    },
    {
      label: i18n.t("client-family-type.digital_campaign"),
      value: CLIENT_FAMILY_TYPE_DIGITAL_CAMPAIGN,
    },
    {
      label: i18n.t("client-family-type.digital_shooting"),
      value: CLIENT_FAMILY_TYPE_DIGITAL_SHOOTING,
    },
    {
      label: i18n.t("client-family-type.editorial"),
      value: CLIENT_FAMILY_TYPE_EDITORIAL,
    },
    {
      label: i18n.t("client-family-type.ecommerce"),
      value: CLIENT_FAMILY_TYPE_ECOMMERCE,
    },
    {
      label: i18n.t("client-family-type.fitting"),
      value: CLIENT_FAMILY_TYPE_FITTING,
    },
    {
      label: i18n.t("client-family-type.free_test"),
      value: CLIENT_FAMILY_TYPE_FREE_TEST,
    },
    {
      label: i18n.t("client-family-type.hair_and_makeup"),
      value: CLIENT_FAMILY_TYPE_HAIR_AND_MAKEUP,
    },
    {
      label: i18n.t("client-family-type.internal_video"),
      value: CLIENT_FAMILY_TYPE_INTERNAL_VIDEO,
    },
    {
      label: i18n.t("client-family-type.internet"),
      value: CLIENT_FAMILY_TYPE_INTERNET,
    },
    {
      label: i18n.t("client-family-type.looks"),
      value: CLIENT_FAMILY_TYPE_LOOKS,
    },
    {
      label: i18n.t("client-family-type.lookbook"),
      value: CLIENT_FAMILY_TYPE_LOOKBOOK,
    },
    {
      label: i18n.t("client-family-type.on_request"),
      value: CLIENT_FAMILY_TYPE_ON_REQUEST,
    },
    {
      label: i18n.t("client-family-type.open"),
      value: CLIENT_FAMILY_TYPE_OPEN,
    },
    {
      label: i18n.t("client-family-type.pop"),
      value: CLIENT_FAMILY_TYPE_POP,
    },
    {
      label: i18n.t("client-family-type.poster"),
      value: CLIENT_FAMILY_TYPE_POSTER,
    },
    {
      label: i18n.t("client-family-type.presentation"),
      value: CLIENT_FAMILY_TYPE_PRESENTATION,
    },
    {
      label: i18n.t("client-family-type.press_release"),
      value: CLIENT_FAMILY_TYPE_PRESS_RELEASE,
    },
    {
      label: i18n.t("client-family-type.rehearsal"),
      value: CLIENT_FAMILY_TYPE_REHEARSAL,
    },
    {
      label: i18n.t("client-family-type.showroom"),
      value: CLIENT_FAMILY_TYPE_SHOWROOM,
    },
    {
      label: i18n.t("client-family-type.standing_show"),
      value: CLIENT_FAMILY_TYPE_STANDING_SHOW,
    },
    {
      label: i18n.t("client-family-type.show"),
      value: CLIENT_FAMILY_TYPE_SHOW,
    },
    {
      label: i18n.t("client-family-type.test"),
      value: CLIENT_FAMILY_TYPE_TEST,
    },
    {
      label: i18n.t("client-family-type.tv"),
      value: CLIENT_FAMILY_TYPE_TV,
    },
    {
      label: i18n.t("client-family-type.videoclip"),
      value: CLIENT_FAMILY_TYPE_VIDEOCLIP,
    },
  ]
}

export const getFamily = value => {
  const options = getFamilyOptions()
  return _.find(options, { value: value })
}
