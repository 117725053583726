import { all } from "redux-saga/effects"

import removeSaga from "./remove/saga"
import addSaga from "./add/saga"

function* avatarSaga() {
  yield all([removeSaga(), addSaga()])
}

export default avatarSaga
