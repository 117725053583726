import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { connect } from "react-redux"

import { MegaTagTitle, SubscriptionAccessViewer } from "components"
import { RatingContainer } from "./components/RatingComponents"

const RatingComponent = props => {
  return (
    <>
      <MegaTagTitle title={"title.model.rating"} />

      <SubscriptionAccessViewer>
        <div className={"rating-panel"}>
          <Row>
            <Col>
              <RatingContainer />
            </Col>
          </Row>
        </div>
      </SubscriptionAccessViewer>
    </>
  )
}

RatingComponent.propTypes = {}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const Rating = connect(
  mapStateToProps,
  mapDispatchToProps
)(RatingComponent)
