import { all } from "redux-saga/effects"

import dataSaga from "./data/saga"
import editSaga from "./edit/saga"
import removeSaga from "./remove/saga"

function* expenseSaga() {
  yield all([dataSaga(), editSaga(), removeSaga()])
}

export default expenseSaga
