import React, { useEffect } from "react"
import { PostsCalendar } from "./PostsCalendar"
import { ViewPost } from "./ViewPost"
import { CreatePost } from "./CreatePost"
import { EditPost } from "./EditPost"
import { socialCalendarPostList } from "store"
import { useDispatch } from "react-redux"
import { DrawerNavigatorProvider } from "contexts"

export const CalendarContainer = () => {
  const dispatch = useDispatch()

  const fetchPosts = (params = {}) => {
    dispatch(socialCalendarPostList.getList({ params }))
  }

  const handleDataSet = dates => {
    fetchPosts({ ...dates })
  }

  useEffect(() => {
    return () => {
      dispatch(socialCalendarPostList.cleanState())
    }
  }, [])

  return (
    <DrawerNavigatorProvider>
      <PostsCalendar onDataSet={handleDataSet} />
      <CreatePost />
      <EditPost />
      <ViewPost />
    </DrawerNavigatorProvider>
  )
}

CalendarContainer.propTypes = {}
