import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Container, Row } from "reactstrap"

import { Drawer, TextButton } from "components"
import { AddPhotoForm } from "./AddPhotoForm"
import {
  modelPanelPhotosAddPhoto,
  modelPanelPhotosAddPhotoCleanState,
} from "store/actions"
import { ValidationsUtils } from "utils"

const AddPhotoComponent = props => {
  const { t } = useTranslation()

  const [drawerVisible, setDrawerVisible] = useState(false)

  useEffect(() => {
    return () => {
      props.modelPanelPhotosAddPhotoCleanState()
    }
  }, [])

  const handleAdd = e => {
    e.preventDefault()
    setDrawerVisible(true)
  }

  const handleCloseDrawer = () => {
    setDrawerVisible(false)
  }

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()

    props.onAddSuccess()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    props.modelPanelPhotosAddPhoto(
      props.model.id,
      params,
      onSubmitSuccess(onSubmitProps),
      onSubmitError(onSubmitProps)
    )
  }

  return (
    <React.Fragment>
      <TextButton
        title={"btn.add-photo"}
        onClick={handleAdd}
        color={"secondary"}
      />

      <Drawer
        title={t("model.add_photo")}
        visible={drawerVisible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        drawerProps={{}}
      >
        <Container fluid>
          <Row>
            <AddPhotoForm
              loading={props.loading}
              error={props.error}
              onSubmit={handleSubmit}
              onCancel={handleCloseDrawer}
            />
          </Row>
        </Container>
      </Drawer>
    </React.Fragment>
  )
}

AddPhotoComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  onAddSuccess: PropTypes.func,

  model: PropTypes.object,

  modelPanelPhotosAddPhoto: PropTypes.func,
  modelPanelPhotosAddPhotoCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.model.profile
  const { loading, error } = state.modelPanel.photos.add
  return {
    model: data,
    loading,
    error,
  }
}

const mapDispatchToProps = {
  modelPanelPhotosAddPhoto,
  modelPanelPhotosAddPhotoCleanState,
}

export const AddPhoto = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPhotoComponent)
