import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Modal } from "../../../../../../components"
import { clientUpdatePrice } from "../../../../../../store/actions"
import { ValidationsUtils } from "../../../../../../utils"
import { Container, Row } from "reactstrap"
import { PriceForm } from "../PriceForm"
import { PriceDataLoader } from "../PriceDataLoader"
import { commonHelper } from "../../../../../../helpers"

const UpdatePriceComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const modalRef = useRef(null)
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    return () => {
      if (modalVisible) {
        dispatch(clientUpdatePrice.cleanState())
      }
    }
  }, [modalVisible])

  useEffect(() => {
    if (modalRef.current && props.id) {
      modalRef.current.show()
    }
  }, [props.id])

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.hide()
    }
    props.onClose()
  }

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    props.onUpdateSuccess()
    closeModal()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      clientUpdatePrice.update({
        id: props.price.id,
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  return (
    <React.Fragment>
      <Modal
        ref={modalRef}
        title={t("client.edit-price.title")}
        onVisibleChange={value => setModalVisible(value)}
        strapModalProp={{
          centered: true,
        }}
      >
        <PriceDataLoader id={props.id}>
          <Container fluid>
            <Row>
              <PriceForm
                onSubmit={handleSubmit}
                onCancel={closeModal}
                initialValues={{
                  ...props.price,
                  currency:
                    commonHelper.currency.getCurrency(props.price?.currency) ||
                    null,
                }}
                loading={props.loading}
                error={props.error}
              />
            </Row>
          </Container>
        </PriceDataLoader>
      </Modal>
    </React.Fragment>
  )
}

UpdatePriceComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  onUpdateSuccess: PropTypes.func,
  onClose: PropTypes.func,
  id: PropTypes.number,
  price: PropTypes.object,
}

const mapStateToProps = state => {
  const { data } = state.client.prices.data
  const { loading, error } = state.client.prices.update
  return {
    price: data,
    loading,
    error,
  }
}

export const UpdatePrice = connect(mapStateToProps)(UpdatePriceComponent)
