import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Spinner, Badge } from "reactstrap"

import { PerfectScrollbar } from "components"
import { ScrollbarUtils } from "utils"
import * as HOC from "HOC"

const DataShower = HOC.withDataShower()

export const MessagesList = props => {
  const { t } = useTranslation()

  const onScroll = e => {
    if (ScrollbarUtils.isCloseToTop(e, 300)) {
      props.onLoadMore()
    }
  }

  return (
    <PerfectScrollbar
      style={{ height: "470px" }}
      containerRef={ref => (props.scrollRef.current = ref)}
      onScrollY={onScroll}
    >
      <DataShower
        isLoading={props.loading}
        customLoaderClass={
          "d-flex align-items-center justify-content-center w-100 h-100"
        }
      >
        {props.isMoreLoading && (
          <div className={"text-center mt-2 mb-4"}>
            <Spinner size={"sm"} color="primary" />
          </div>
        )}

        {!!props.items.length ? (
          <ul className="list-unstyled">{props.renderMessages()}</ul>
        ) : !props.loading ? (
          <div
            className={"h-100 d-flex align-items-center justify-content-center"}
          >
            <Badge
              pill
              className={"font-size-12 badge-soft-secondary rounded-pill"}
            >
              {t("chat.no_messages")}
            </Badge>
          </div>
        ) : null}
      </DataShower>
    </PerfectScrollbar>
  )
}

MessagesList.propTypes = {
  scrollRef: PropTypes.any,
  items: PropTypes.array,
  loading: PropTypes.bool,
  isMoreLoading: PropTypes.bool,
  onLoadMore: PropTypes.func,
  renderMessages: PropTypes.func,
}
