import React from 'react';
import PropTypes from "prop-types"
import { Container } from "reactstrap";
import { Header } from './Topbar';
import { PanelTopBarProvider } from '../../../../../contexts';

export const PanelLayout = (props) => {
    return (
        <PanelTopBarProvider>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Header />
                        <div>
                            {props.children}
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        </PanelTopBarProvider>
    );
};

PanelLayout.propTypes = {
    children: PropTypes.any,
    topBarOptions: PropTypes.object,
}