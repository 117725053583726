import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Row, Col } from "reactstrap"

import { IconButton } from "components"
import { FileUtils } from "utils"
import iconPlaceholder from "assets/images/dev/file-paperclip-icon.svg"

export const Attachment = ({ item: { attachment } }) => {
  const { t } = useTranslation()

  const onDownload = () => {
    FileUtils.downloadFileByLink(attachment.src)
  }

  return (
    <div className={"d-flex pb-2 text-start"}>
      <Col>
        <Row className={"d-flex align-items-center"}>
          <Col className="col-auto">
            <div className={"bg-neutral attached-file-card__icon-block"}>
              <img className="" alt={"icon"} src={iconPlaceholder} />
            </div>
          </Col>
          <Col>
            <span className="text-gray">{attachment.name}</span>
            {attachment?.size && (
              <p className="mb-0">
                <strong>{FileUtils.formatBytes(attachment?.size)}</strong>
              </p>
            )}
          </Col>
        </Row>
      </Col>
      <div className={"ms-2 d-flex flex-column"}>
        <IconButton
          name={"download"}
          onClick={onDownload}
          bg
          wrapClass={"mt-1"}
        />
      </div>
    </div>
  )
}

Attachment.propTypes = {
  item: PropTypes.object,
}
