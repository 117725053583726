import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Col, Row } from "reactstrap"
import * as Yup from "yup"
import { Button, ComboboxFormField, ControllerHF, InputField } from "components"
import { useFacebookAuth } from "packages/facebook"
import { SOCIAL_POST_METHOD_MANUAL, SOCIAL_POST_TYPE_INSTAGRAM } from "consts"

export const PublishPhotoForm = props => {
  const { t } = useTranslation()
  const { instagramAccounts } = useFacebookAuth()
  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      description: "",
      instagram_profile: null,
      ...props.initialValues,
    },
    resolver: yupResolver(
      Yup.object().shape({
        instagram_profile: Yup.object()
          .nullable()
          .required("field.error.required-instagram-account"),
      })
    ),
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
      type: SOCIAL_POST_TYPE_INSTAGRAM,
      method: SOCIAL_POST_METHOD_MANUAL,
      account_number: values.instagram_profile.id,
      account_name: values.instagram_profile.username,
    }

    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <ControllerHF
          type={"textarea"}
          name={"description"}
          control={control}
          component={InputField}
          id={"description"}
          label={t("description")}
          placeholder={t("description")}
        />
        <Row>
          <Col>
            <ControllerHF
              name={"instagram_profile"}
              control={control}
              component={ComboboxFormField}
              id={"instagram_profile"}
              placeholder={t("connected_instagram_profile")}
              label={t("connected_instagram_profile")}
              options={instagramAccounts}
              getOptionLabel={option => option.username}
              getOptionValue={option => option}
            />
          </Col>
        </Row>
        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              leftIcon={"bx bxl-instagram font-size-16"}
              color={"instagram"}
              title={"publish-to-instagram"}
              disabled={props.loading}
              loading={props.loading}
            />
            <Button title={"cancel"} color="light" outline onClick={onCancel} />
          </div>
        </div>
      </form>
    </>
  )
}

PublishPhotoForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  imageUrl: PropTypes.string,
}
