import React, { useMemo, useState, useRef } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { validationSchema } from "./validation"
import { Alert, Col, Row } from "reactstrap"
import {
  DateSection,
  AttachFilesSection,
  AlertSection,
  TimeSection,
  ClientAndLocationSection,
  NotesSection,
  ModelsSection,
} from "../EventFormSections"
import { Button } from "../../../Buttons"
import {
  getDatePeriodDefaultValues,
  getTimePeriodsDefaultValues,
  getAttachFilesDefaultValues,
  getModelsDefaultValues,
  mapDatePeriodSubmitValues,
  getMapLocationsDefaultValues,
  mapLocationsSubmitValues,
  getClientDefaultValues,
  getNotesDefaultValues,
  getAlertDefaultValues,
  getClientsInfoDefaultValues,
  mapClientSubmitValues,
  mapTimePeriodsSubmitValues,
  mapModelsSubmitValues,
  mapAlertsSubmitValues,
  checkModelsRatesDate,
  mapAttachFilesSubmitValues,
} from "../helpers"
import { ControllerHF } from "../../../ReactHookForm"
import { TabsControlField } from "../../../FormElements"
import i18n from "../../../../i18n"
import { AlertPopup } from "../../../Popups"
import { eventsCalendarHelper } from "../../../../helpers"
import { EVENT_TYPE_JOB } from "../../../../consts"

const getEntityEventType = eventsCalendarHelper.calendar.getEntityEventType

export const EventOptionForm = props => {
  const { t } = useTranslation()

  const [filesProcessing, setFilesProcessing] = useState([])

  const alertModalRef = useRef(null)

  const defaultValues = useMemo(() => {
    const {
      id,
      date_start,
      date_end,
      times,
      client,
      defaultLocations,
      locations,
      alerts,
      agency,
      ...restValues
    } = props.initialValues
    return {
      ...restValues,
      id: id,
      ...getDatePeriodDefaultValues(date_start, date_end),
      ...getTimePeriodsDefaultValues(times),
      ...getMapLocationsDefaultValues(locations, defaultLocations),
      ...getClientDefaultValues(client),
      ...getAlertDefaultValues(alerts),
    }
  }, [props.initialValues])

  const methods = useForm({
    defaultValues: {
      is_direct: false,
      ...getModelsDefaultValues(),
      ...getNotesDefaultValues(),
      ...getClientsInfoDefaultValues(),
      ...getAttachFilesDefaultValues(),
      ...defaultValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const { control, handleSubmit, setError } = methods

  const onSubmit = values => {
    const { fashion_models, client, ...resetValues } = values
    let params = {
      ...resetValues,
      ...mapDatePeriodSubmitValues(values.date_start, values.date_end),
      ...mapTimePeriodsSubmitValues(values.times),
      ...mapClientSubmitValues(client, props.isEditMode),
      ...mapAlertsSubmitValues(values.alerts),
      ...mapAttachFilesSubmitValues(values.files),
    }

    if (!props.isEditMode) {
      params = {
        ...params,
        ...mapModelsSubmitValues(fashion_models),
      }
    }

    params.locations = mapLocationsSubmitValues(values.locations)

    props.onSubmit(params, { setError })
  }

  const showInvalidModelsAlert = models => {
    if (alertModalRef.current) {
      alertModalRef.current.alert(
        <>
          <p className={"font-size-22"}>
            {t("event.alert.invalid.models-rates-date")}
          </p>
          <ul>
            {models.map((model, i) => (
              <li key={i}>
                {model?.name} {model?.surname}
              </li>
            ))}
          </ul>
        </>
      )
    }
  }

  const onFormSubmit = values => {
    if (props.isEditMode) {
      onSubmit(values)
    } else {
      const { fashion_models, date_start, date_end } = values
      const { isValid, invalidModels } = checkModelsRatesDate(
        fashion_models,
        date_start,
        date_end
      )

      if (isValid) {
        onSubmit(values)
      } else {
        showInvalidModelsAlert(invalidModels)
      }
    }
  }

  const addFileToProcessing = fieldId => {
    setFilesProcessing(prevState => [...prevState, fieldId])
  }

  const removeFileFromProcessing = fieldId => {
    setFilesProcessing(prevState => prevState.filter(item => item !== fieldId))
  }

  const isDisabled = useMemo(() => {
    return props.loading
  }, [props.loading])

  const actionTypeOptions = useMemo(() => {
    let type
    if (props.entity) {
      type = getEntityEventType(
        props.initialValues,
        props.entity,
        props.entityId
      )
    }

    return [
      {
        label: i18n.t(
          type === EVENT_TYPE_JOB ? "event-type.job" : "event-type.option"
        ),
        value: false,
      },
      { label: i18n.t("direct_booking"), value: true },
    ]
  }, [props.initialValues, props.entity, props.entityId])

  return (
    <FormProvider {...methods}>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Row className={"mb-3"}>
          <Col md={12}>
            <ControllerHF
              name={"is_direct"}
              control={control}
              component={TabsControlField}
              id={"is_direct"}
              options={actionTypeOptions}
            />
          </Col>
        </Row>

        <DateSection />

        <TimeSection />

        <hr className="drawer-separator drawer-separator_form" />

        <ModelsSection
          isEdit={props.isEditMode}
          eventId={props.initialValues.id}
          eventType={props.eventType}
          eventData={props.initialValues}
          modelActionBar={{
            edit: true,
            cancel: props.isEditMode,
            priority: true,
            job: props.isEditMode,
          }}
          onEventUpdate={props.onEventUpdate}
          entity={props.entity}
          entityId={props.entityId}
        />

        <hr className="drawer-separator drawer-separator_form" />

        <NotesSection />

        <hr className="drawer-separator drawer-separator_form" />

        <ClientAndLocationSection />

        <hr className="drawer-separator drawer-separator_form" />

        <AttachFilesSection
          processing={filesProcessing}
          addFileToProcessing={addFileToProcessing}
          removeFileFromProcessing={removeFileFromProcessing}
          accept={
            "image/jpeg, image/png, image/gif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          }
          eventId={props.initialValues.id}
        />

        <hr className="drawer-separator drawer-separator_form" />

        <AlertSection />

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={isDisabled}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>

      <AlertPopup ref={alertModalRef} />
    </FormProvider>
  )
}

EventOptionForm.propTypes = {
  isEditMode: PropTypes.bool,
  eventType: PropTypes.string,
  initialValues: PropTypes.object,
  error: PropTypes.any,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onEventUpdate: PropTypes.func,
  entity: PropTypes.string,
  entityId: PropTypes.number,
}
