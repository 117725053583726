import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "../../../../i18n"

import { API } from "../../../../api"
import { clientRemovePrice } from "./slice"

function* remove({ payload: { id, onSuccess } }) {
  try {
    const response = yield call(API.client.removeClientPrice, id)

    const { data } = response

    yield put(clientRemovePrice.removeCompleted())
    if (onSuccess) {
      onSuccess()
    }
    toastr.success(i18n.t("client.remove-price.success"))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      clientRemovePrice.removeRejected({
        errors,
        message,
      })
    )
    toastr.error(message)
  }
}

export function* watchRemove() {
  yield takeEvery(clientRemovePrice.remove, remove)
}

function* removeSaga() {
  yield all([fork(watchRemove)])
}

export default removeSaga
