import React from 'react';
import PropTypes from "prop-types"
import { ReactTitle } from 'react-meta-tags';
import { useTranslation } from 'react-i18next';

import { APP_NAME } from '../../consts';

export const MegaTagTitle = ({ title }) => {
    const { t } = useTranslation();

    return (
        <ReactTitle title={`${t(title)} | ${APP_NAME}`} />
    );
};

MegaTagTitle.propTypes = {
    title: PropTypes.string
}