export const GENDER_MALE = "male"
export const GENDER_FEMALE = "female"

export const ETHNIC_CATEGORY_ASIAN = "asian"
export const ETHNIC_CATEGORY_BLACK = "black"
export const ETHNIC_CATEGORY_CAUCASIAN = "caucasian"
export const ETHNIC_CATEGORY_HISPANIC = "hispanic"
export const ETHNIC_CATEGORY_INDIAN = "indian"
export const ETHNIC_CATEGORY_NATIVE_AMERICAN = "native_american"

export const MODEL_FEATURE_NATURALNESS = {
  NATURAL: "natural",
  ARTIFICIAL: "artificial",
}
