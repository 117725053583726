import React from "react"
import PropTypes from "prop-types"
import { Editor } from "react-draft-wysiwyg"
import classNames from "classnames"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

export const WYSIWYGEditor = props => {
  return (
    <Editor
      defaultEditorState={props.defaultEditorState}
      editorState={props.editorState}
      onEditorStateChange={props.onEditorStateChange}
      wrapperClassName="wysiwyg-editor-wrapper"
      toolbarClassName="wysiwyg-editor-toolbar"
      editorClassName={classNames("wysiwyg-editor-editor", {
        [props.editorClassName]: props.editorClassName,
      })}
      onBlur={props.onBlur}
      toolbar={props.toolbar}
      readOnly={props.readOnly}
    />
  )
}

WYSIWYGEditor.propTypes = {
  defaultEditorState: PropTypes.any,
  editorState: PropTypes.any,
  onEditorStateChange: PropTypes.func,
  onBlur: PropTypes.func,
  toolbar: PropTypes.object,
  readOnly: PropTypes.bool,
  editorClassName: PropTypes.string,
}
