import {
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
} from './actionTypes';

const initialState = {
};

const logout = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT_USER:
            state = { ...state };
            break;

        case LOGOUT_USER_SUCCESS:
            state = { ...state };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default logout;