import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import i18n from "i18n"
import { API } from "api"
import { billingSubscriptionPaymentLink } from "./slice"

export function* getData() {
  try {
    const response = yield call(API.billing.getSubscriptionPaymentLink)

    const { data } = response

    yield put(billingSubscriptionPaymentLink.getDataSuccess(data?.data))

    window.location.href = data?.data?.url
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(billingSubscriptionPaymentLink.getDataError(message))
  }
}

export function* watchGetData() {
  yield takeEvery(billingSubscriptionPaymentLink.getData, getData)
}

function* dataSaga() {
  yield all([fork(watchGetData)])
}

export default dataSaga
