import * as roleMiddleware from "./role"
import * as userSettings from "./userSettings"
import * as subscription from "./subscription"
import * as company from "./company"
import * as user from "./user"
import * as previewMode from "./previewMode"
import * as departmentPermissions from "./departmentPermissions"

export const middlewareHelper = {
  roleMiddleware,
  userSettings,
  subscription,
  company,
  user,
  previewMode,
  departmentPermissions,
}
