import React from "react"
import PropTypes from "prop-types"
import moment from "moment"

export const AccommodationRow = ({ item }) => {
  return (
    <>
      <td>
        <span>{item.room}</span>
      </td>
      <td>
        {" "}
        <span>{moment(item.date_in).format("DD/MM/YYYY")}</span>
        {item.date_out && (
          <span>{` - ${moment(item.date_out).format("DD/MM/YYYY")}`}</span>
        )}
      </td>
      <td>
        <span>{item.country?.name}</span>
      </td>
    </>
  )
}

AccommodationRow.propTypes = {
  item: PropTypes.object,
}
