import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import i18n from '../../../../i18n';
import { EMPLOYEE_FETCH_LIST } from './actionTypes';
import { employeeFetchListSuccess, employeeFetchListError } from './actions';

import { API } from '../../../../api';
import { RequestUtils } from '../../../../utils';

export function* fetchList({ payload: { params } }) {
    try {
        let requestParams = {
            includes: [],
        };

        requestParams = RequestUtils.getMapRequestParams(params, requestParams);

        const response = yield call(API.employee.getEmployeeList, requestParams);

        const { data } = response;

        yield put(employeeFetchListSuccess(data));

    } catch (error) {
        const message = error.response?.data?.message || i18n.t('error');
        yield put(employeeFetchListError(message));
    }
}

export function* watchFetchList() {
    yield takeLatest(EMPLOYEE_FETCH_LIST, fetchList)
}

function* dataSaga() {
    yield all([
        fork(watchFetchList),
    ]);
}

export default dataSaga;