import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { generatePath, useRouteMatch } from "react-router-dom"

import { PanelMenu } from "components"
import {
  BECOME_A_MODEL_PAGE,
  CONTACTS_PAGE,
  HOME_PAGE,
  MEN_PAGE,
  WOMEN_PAGE,
} from "consts"

export const Nav = props => {
  const { t } = useTranslation()

  let { path } = useRouteMatch()

  const getPath = route => generatePath(`${path}/${route}`)

  const menu = [
    {
      key: "templates",
      route: getPath("templates"),
      title: t("templates"),
    },
    {
      key: "settings",
      route: getPath("settings"),
      title: t("settings"),
    },
    {
      key: "home-page",
      route: getPath(HOME_PAGE),
      title: t("home-page"),
      stateParams: props.stateParams,
    },
    {
      key: "women",
      route: getPath(WOMEN_PAGE),
      title: t("women"),
      stateParams: props.stateParams,
    },
    {
      key: "men",
      route: getPath(MEN_PAGE),
      title: t("men"),
      stateParams: props.stateParams,
    },
    {
      key: "contacts",
      route: getPath(CONTACTS_PAGE),
      title: t("contacts"),
      stateParams: props.stateParams,
    },
    {
      key: "become-a-model",
      route: getPath(BECOME_A_MODEL_PAGE),
      title: t("become-a-model"),
      stateParams: props.stateParams,
    },
    {
      key: "requests",
      route: getPath("requests"),
      title: t("requests"),
    },
  ]

  return <PanelMenu items={menu} />
}

Nav.propTypes = {
  stateParams: PropTypes.object,
}
