import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  LOAD_USER_CLEAN_STATE,
} from "./actionTypes"

export const fetchUser = () => {
  return {
    type: FETCH_USER,
  }
}

export const fetchUserSuccess = () => {
  return {
    type: FETCH_USER_SUCCESS,
  }
}

export const fetchUserError = data => {
  return {
    type: FETCH_USER_ERROR,
    payload: data,
  }
}

export const loadUserCleanState = () => {
  return { type: LOAD_USER_CLEAN_STATE }
}
