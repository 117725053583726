import {
    MODEL_PANEL_GENERAL_ADD_AGENCY,
    MODEL_PANEL_GENERAL_ADD_AGENCY_SUCCESS,
    MODEL_PANEL_GENERAL_ADD_AGENCY_ERROR,
    MODEL_PANEL_GENERAL_ADD_AGENCY_CLEAN_STATE,
} from './actionTypes';

export const modelPanelGeneralAddAgency = (id, params, onSuccess, onError) => {
    return {
        type: MODEL_PANEL_GENERAL_ADD_AGENCY,
        payload: { id, params, onSuccess, onError },
    }
};

export const modelPanelGeneralAddAgencySuccess = () => {
    return {
        type: MODEL_PANEL_GENERAL_ADD_AGENCY_SUCCESS,
    }
};

export const modelPanelGeneralAddAgencyError = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_ADD_AGENCY_ERROR,
        payload: data,
    }
};

export const modelPanelGeneralAddAgencyCleanState = () => {
    return { type: MODEL_PANEL_GENERAL_ADD_AGENCY_CLEAN_STATE };
};