import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';

import { modelPanelGeneralFetchAgency, modelPanelGeneralFetchAgencyCleanState } from '../../../../../../store/actions';

import * as HOC from '../../../../../../HOC';
const DataShower = HOC.withDataShower();

const AgencyDataLoaderComponent = (props) => {

    useEffect(() => {
        fetchData();

        return () => {
            props.modelPanelGeneralFetchAgencyCleanState();
        };
    }, []);

    const fetchData = () => {
        props.modelPanelGeneralFetchAgency(props.id);
    };

    return (
        <DataShower isLoading={props.loading} isFailed={props.error} error={props.error}>
            {props.data && (
                props.children
            )}
        </DataShower>
    );
};

AgencyDataLoaderComponent.propTypes = {
    id: PropTypes.number,
    children: PropTypes.any,

    data: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.any,

    modelPanelGeneralFetchAgency: PropTypes.func,
    modelPanelGeneralFetchAgencyCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { data, loading, error } = state.modelPanel.general.agency.details.data;

    return {
        data,
        loading,
        error,
    };
};

const mapDispatchToProps = {
    modelPanelGeneralFetchAgency,
    modelPanelGeneralFetchAgencyCleanState,
};

export const AgencyDataLoader = connect(mapStateToProps, mapDispatchToProps)(AgencyDataLoaderComponent);