import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"
import i18n from "../../../../i18n"
import { websiteUpdateMenuItem } from "./slice"
import { API } from "../../../../api"
import { websiteMenuItemsList } from "../actions"

function* updateItem({ payload: { id, params, onSuccess, onError } }) {
  try {
    const response = yield call(API.website.updateWebsiteMenuItem, id, params)

    const { data } = response

    if (onSuccess) {
      onSuccess()
    }
    toastr.success(i18n.t("edit.success"))
    yield put(websiteMenuItemsList.updateListItem(data.data))
    yield put(websiteUpdateMenuItem.updateCompleted())
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      websiteUpdateMenuItem.updateRejected({
        errors,
        message,
      })
    )
    if (onError) {
      onError(errors)
    }
    toastr.error(message)
  }
}

export function* watchUpdateItem() {
  yield takeEvery(websiteUpdateMenuItem.update, updateItem)
}

function* updateSaga() {
  yield all([fork(watchUpdateItem)])
}

export default updateSaga
