import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Col, Row } from "reactstrap"
import { ControllerHF } from "../../../../../ReactHookForm"
import { ModelsSelectedListField } from "../../../../../FormElements"
import { EVENT_TYPE_OPTION, EVENT_TYPE_JOB } from "consts"
import { ModelItem } from "./ModelItem"

export const ModelsFormField = props => {
  const { t } = useTranslation()

  const renderItemContent = item => {
    return <ModelItem {...props} model={item} />
  }

  const selectedListConfig = useMemo(
    () => ({
      subtitle: [EVENT_TYPE_OPTION, EVENT_TYPE_JOB].includes(
        props.eventType
      ) ? (
        <span className={"text-gray"}>
          {t("event.selected-models.subtitle")}
        </span>
      ) : null,
    }),
    []
  )

  return (
    <Row>
      <Col xl={6}>
        <ControllerHF
          name={"fashion_models"}
          control={props.control}
          component={ModelsSelectedListField}
          async={props.async}
          onRemove={props.onRemove}
          onSelect={props.onSelect}
          renderItemContent={renderItemContent}
          loading={props.loading}
          selectedListConfig={selectedListConfig}
          normalize={option =>
            option
              ? {
                  id: option.id,
                  name: option.name,
                  surname: option.surname,
                  active: true,
                  priority: null,
                  type: props.eventType,
                }
              : null
          }
          className={"calendar-event-select-models-control"}
          gendersValue={[props.gender]}
        />
      </Col>
    </Row>
  )
}

ModelsFormField.propTypes = {
  control: PropTypes.object,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
  loading: PropTypes.bool,
  eventType: PropTypes.string,
  async: PropTypes.bool,
  gender: PropTypes.string,
}
