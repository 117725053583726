import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Container, Row } from "reactstrap"
import { Link } from "react-router-dom"

import { Drawer } from "../../../../../../components"
import { VideoForm } from "../VideoForm"
import {
  modelAddVideo,
  modelAddVideoCleanState,
} from "../../../../../../store/actions"
import { ValidationsUtils } from "../../../../../../utils"

const AddVideoComponent = props => {
  const { t } = useTranslation()

  const [drawerVisible, setDrawerVisible] = useState(false)

  useEffect(() => {
    return () => {
      props.modelAddVideoCleanState()
    }
  }, [])

  const handleAdd = e => {
    e.preventDefault()
    setDrawerVisible(true)
  }

  const handleCloseDrawer = () => {
    setDrawerVisible(false)
  }

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()

    props.onAddSuccess()
    handleCloseDrawer()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    props.modelAddVideo(
      props.model.id,
      params,
      onSubmitSuccess(onSubmitProps),
      onSubmitError(onSubmitProps)
    )
  }

  return (
    <React.Fragment>
      <Link to="#" className="text-secondary" onClick={handleAdd}>
        {t("btn.add-video")}
      </Link>

      <Drawer
        title={t("link_youtube_video")}
        visible={drawerVisible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        drawerProps={{}}
      >
        <Container fluid>
          <Row>
            <VideoForm
              initialValues={{}}
              loading={props.loading}
              error={props.error}
              onSubmit={handleSubmit}
              onCancel={handleCloseDrawer}
            />
          </Row>
        </Container>
      </Drawer>
    </React.Fragment>
  )
}

AddVideoComponent.propTypes = {
  onAddSuccess: PropTypes.func,

  loading: PropTypes.bool,
  error: PropTypes.object,
  model: PropTypes.object,

  modelAddVideo: PropTypes.func,
  modelAddVideoCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.model.profile
  const { loading, error } = state.modelPanel.video.add
  return {
    model: data,
    loading,
    error,
  }
}

const mapDispatchToProps = {
  modelAddVideo,
  modelAddVideoCleanState,
}

export const AddVideo = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddVideoComponent)
