import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import moment from "moment"
import toastr from "toastr"

import {
  modelStatementDataPaymentSubmit,
  modelStatementList,
} from "store/actions"
import { useConfirmModal, useDrawer, useModal, useFetchData } from "hooks"
import { StatementCard } from "./StatementCard"
import { ConfirmPopup, StatementPaymentDetails } from "components"
import { APP_ENTITY } from "consts"
import { useDeepCompareEffect } from "react-use"
import { StatementFilter } from "./StatementFilter"
import { PaymentPeriod } from "./PaymentPeriod"
import { API } from "api"
import { useModelStatementPaid } from "features/fashion-model/Statement/hooks"

const defaultPeriod = [
  moment().startOf("month").toDate(),
  moment().endOf("month").toDate(),
]

const StatementContainerComponent = props => {
  const dispatch = useDispatch()

  const [filter, setFilter] = useState({
    period: defaultPeriod,
    client: null,
    client_payment_status: null,
    description: "",
    invoice: "",
    gross_amount_min: "",
    gross_amount_max: "",
    is_paid: null,
  })

  const period = filter.period

  const [date_start, date_end] = period

  const onFilterChange = value => {
    setFilter(prevState => ({
      ...prevState,
      ...value,
    }))
  }

  useEffect(() => {
    return () => {
      dispatch(modelStatementList.cleanState())
      dispatch(modelStatementDataPaymentSubmit.cleanState())
    }
  }, [])

  // Fetch data
  useDeepCompareEffect(() => {
    if (date_start && date_end) {
      fetchStatements()
    }
  }, [filter])

  const getMappedParams = () => {
    let params = {
      date_start: moment(date_start).format("YYYY-MM-DD"),
      date_end: moment(date_end).format("YYYY-MM-DD"),
    }

    const {
      client,
      client_payment_status,
      description,
      invoice,
      gross_amount_min,
      gross_amount_max,
      is_paid,
    } = filter

    const clientName = client ? client.name : null

    params = {
      ...params,
      client: clientName,
      client_payment_status,
      description: description.trim() ? description.trim() : null,
      invoice: invoice.trim() ? invoice.trim() : null,
      gross_amount_min: gross_amount_min !== "" ? gross_amount_min : null,
      gross_amount_max: gross_amount_max !== "" ? gross_amount_max : null,
      is_paid,
    }

    return params
  }

  const fetchStatements = () => {
    const params = getMappedParams()

    dispatch(modelStatementList.getList({ id: props.model.id, params }))
  }
  // === //

  // Pay
  const paymentModal = useModal()
  // === //

  //======================= Statement (id) payment submit  ==============================//
  const statementPaymentModal = useConfirmModal(
    ({ id, is_paid }) => {
      dispatch(
        modelStatementDataPaymentSubmit.submit({
          id: props.model.id,
          statement: id,
          params: { is_paid: !is_paid },
          onSuccess: fetchStatements,
        })
      )
    },
    () => {}
  )
  // === //

  // Payment details
  const paymentDetailsDrawer = useDrawer()
  // === //

  const isLoading = useMemo(() => {
    return props.listLoading || props.loading
  }, [props.listLoading, props.loading])

  // === Export === //
  const { fetchData } = useFetchData()

  const onExport = async () => {
    try {
      const params = getMappedParams()

      const request = API.model.getModelStatements(
        props.model.id,
        {
          ...params,
          export: true,
        },
        {
          responseType: "blob",
        }
      )

      const result = await fetchData({
        request,
      })

      if (result) {
        const href = URL.createObjectURL(result)

        const link = document.createElement("a")
        link.href = href
        link.setAttribute("download", `exported-model-statements.xlsx`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        URL.revokeObjectURL(href)
      }
    } catch (error) {
      toastr.error(error?.message || "")
    }
  }
  // === //

  // === Statements Payments === //
  const { onStatementsPaid, loading: statementsPaidLoading } =
    useModelStatementPaid()

  const onStatementsPayment = async (ids = []) => {
    if (ids.length > 0) {
      try {
        await onStatementsPaid({ id: props.model.id, statementsIds: ids })

        fetchStatements()
      } catch (error) {}
    }
  }

  const statementsPaymentBtnProps = useMemo(() => {
    return {
      loading: statementsPaidLoading,
      disabled: false,
    }
  }, [statementsPaidLoading])
  // === //

  return (
    <>
      <StatementCard
        period={period}
        list={props.list}
        listLoading={isLoading}
        listError={props.listError}
        meta={props.meta}
        model={props.model}
        onPeriodPay={paymentModal.showModal}
        onStatementPayment={statementPaymentModal.showModal}
        onPaymentDetails={paymentDetailsDrawer.show}
        toolbar={
          <StatementFilter
            filter={filter}
            onFilterChange={onFilterChange}
            onExport={onExport}
          />
        }
        statementsPayment={{
          btnProps: statementsPaymentBtnProps,
          onStatementsPayment,
        }}
      />

      <PaymentPeriod
        modalRef={paymentModal.modalRef}
        onCancel={paymentModal.closeModal}
        modelId={props.model.id}
        initialPeriod={period}
        onSuccess={fetchStatements}
      />

      <ConfirmPopup
        ref={statementPaymentModal.modalRef}
        onConfirm={statementPaymentModal.handleConfirm}
        onClosed={statementPaymentModal.closeModal}
        questionText={
          statementPaymentModal.item?.is_paid
            ? "sure_make_refund"
            : "sure_make_payment"
        }
      />

      <StatementPaymentDetails
        data={paymentDetailsDrawer.data}
        visible={paymentDetailsDrawer.visible}
        onClose={paymentDetailsDrawer.hide}
        onDestroy={paymentDetailsDrawer.resetData}
        entity={APP_ENTITY.MODEL}
      />
    </>
  )
}

StatementContainerComponent.propTypes = {
  model: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  loading: PropTypes.bool,
}

const mapStateToProps = state => {
  const { data: model } = state.model.profile
  const { list, listLoading, listError, meta } = state.modelPanel.statement.list

  const { loading } = state.modelPanel.statement.data.payment.submit
  return {
    model,
    list,
    listLoading,
    listError,
    meta,
    loading,
  }
}

export const StatementContainer = connect(mapStateToProps)(
  StatementContainerComponent
)
