import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
}

const chatGroupsRemoveSlice = createSlice({
  name: "chatGroupsRemove",
  initialState,
  reducers: {
    remove(state) {
      state.loading = true
    },
    removeSuccess(state) {
      state.loading = false
    },
    removeError(state, action) {
      state.loading = false
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} remove
 * @property {function} removeSuccess
 * @property {function} removeError
 * @property {function} cleanState
 */
export const chatGroupsRemove = chatGroupsRemoveSlice.actions

export default chatGroupsRemoveSlice.reducer
