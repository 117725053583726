import React, { useEffect } from "react"
import { AnalyticsPanelRouting } from "./components/AnalyticsPanelRouting"
import { connect, useDispatch } from "react-redux"
import { analyticsGraphsList } from "store"
import PropTypes from "prop-types"
import * as HOC from "../../HOC"
import { Alert } from "reactstrap"
import { useTranslation } from "react-i18next"
import { SubscriptionAccessAlert } from "components"
import { useUserAccess } from "hooks"

const DataShower = HOC.withDataShower()

export const AnalyticsIndexComponent = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { subscriptionFullAccessAllowed } = useUserAccess()

  const subscriptionAllowed = subscriptionFullAccessAllowed()

  useEffect(() => {
    if (subscriptionAllowed) {
      dispatch(analyticsGraphsList.getList())
    }
    return () => {
      dispatch(analyticsGraphsList.cleanState())
    }
  }, [])

  return subscriptionAllowed ? (
    <DataShower
      isLoading={props.loading}
      isFailed={props.error}
      error={props.error}
      customLoaderClass={"page-content"}
      errorContainerClass={"page-content"}
    >
      {props.isFetched && !!props.graphs.length ? (
        <AnalyticsPanelRouting graphs={props.graphs} />
      ) : (
        <div className={"page-content"}>
          <Alert color={"danger"}>{t("no-permissions")}</Alert>
        </div>
      )}
    </DataShower>
  ) : (
    <SubscriptionAccessAlert wrapClass={"page-content"} />
  )
}

AnalyticsIndexComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  isFetched: PropTypes.bool,
  graphs: PropTypes.array,
}

const mapStateToProps = state => {
  let {
    listLoading,
    listError,
    isFetched,
    list: graphs,
  } = state.analytics.graphs.list
  return {
    loading: listLoading,
    error: listError,
    isFetched,
    graphs,
  }
}

export const AnalyticsIndex = connect(mapStateToProps)(AnalyticsIndexComponent)
