import React from "react"
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom"
import { PanelLayout } from "./components"
import PropTypes from "prop-types"
import { Settings } from "./Settings"
import { Contacts } from "./Contacts"
import {
  BECOME_A_MODEL_PAGE,
  CONTACTS_PAGE,
  HOME_PAGE,
  MEN_PAGE,
  WOMEN_PAGE,
} from "consts"
import { BecomeModel } from "./BecomeModel"
import { Home } from "./Home"
import { Women } from "./Women"
import { Men } from "./Men"
import { Templates } from "./Templates"
import { Requests } from "./Requests"

export const WebsitePanelIndex = props => {
  let { path } = useRouteMatch()
  if (!props.website?.created) {
    return <Redirect to={"/website/intro"} />
  }

  return (
    <PanelLayout>
      <Switch>
        <Route exact path={`${path}`}>
          <Redirect to={generatePath(`${path}/settings`)} />
        </Route>
        <Route path={`${path}/${HOME_PAGE}`}>
          <Home alias={HOME_PAGE} />
        </Route>
        <Route path={`${path}/templates`}>
          <Templates />
        </Route>
        <Route path={`${path}/settings`}>
          <Settings />
        </Route>
        <Route path={`${path}/${WOMEN_PAGE}`}>
          <Women alias={WOMEN_PAGE} />
        </Route>
        <Route path={`${path}/${MEN_PAGE}`}>
          <Men alias={MEN_PAGE} />
        </Route>
        <Route path={`${path}/${CONTACTS_PAGE}`}>
          <Contacts alias={CONTACTS_PAGE} />
        </Route>
        <Route path={`${path}/${BECOME_A_MODEL_PAGE}`}>
          <BecomeModel alias={BECOME_A_MODEL_PAGE} />
        </Route>
        <Route path={`${path}/requests`}>
          <Requests />
        </Route>
        <Route>
          <Redirect to={generatePath(`${path}/settings`)} />
        </Route>
      </Switch>
    </PanelLayout>
  )
}

WebsitePanelIndex.propTypes = {
  website: PropTypes.any,
}
