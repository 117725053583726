import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useBookPhotosPicker } from "hooks"

const BookPhotosPickerComponent = props => {
  const { component: Component, picked } = props

  const { toggleSelect, addItems, changeCover, removeItem } =
    useBookPhotosPicker()

  useEffect(() => {
    return () => {}
  }, [])

  return (
    <Component
      onToggleSelect={toggleSelect}
      onRemove={removeItem}
      onCoverChange={changeCover}
      onAddItems={addItems}
      picked={picked}
      {...props}
    />
  )
}

BookPhotosPickerComponent.propTypes = {
  component: PropTypes.any,

  picked: PropTypes.array,
}

const mapStateToProps = state => {
  const { picked } = state.modelPanel.book.photosPicker
  return {
    picked,
  }
}

export const BookPhotosPicker = connect(mapStateToProps)(
  BookPhotosPickerComponent
)
