import {
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    REGISTER_USER_CLEAN_STATE,
} from './actionTypes';

export const registerUser = (params, onSubmitProps) => {
    return {
        type: REGISTER_USER,
        payload: { params, onSubmitProps },
    }
};

export const registerUserSuccess = (data) => {
    return {
        type: REGISTER_USER_SUCCESS,
        payload: data,
    }
};

export const registerUserError = (error) => {
    return {
        type: REGISTER_USER_ERROR,
        payload: error,
    }
};

export const registerUserCleanState = () => {
    return {
        type: REGISTER_USER_CLEAN_STATE,
    };
};