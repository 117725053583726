import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"
import { ControllerHF, InputField } from "components"
import { NormalizeUtils } from "utils"

export const RatesSumMax = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <ControllerHF
      name={"rates_sum_max"}
      control={control}
      component={InputField}
      id={"rates_sum_max"}
      label={t("amount_max")}
      placeholder={t("amount_max")}
      normalize={NormalizeUtils.onlyNumbers}
      className={"mb-0"}
    />
  )
}

RatesSumMax.propTypes = {}
