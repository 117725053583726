import { takeEvery, fork, put, all } from 'redux-saga/effects';

import { PACKAGE_DRAFT_RESET_DATA } from './actionTypes';
import { draftPackageDataPickerCleanValue } from '../data/dataPicker/actions';
import { draftPackageFormResetValues } from '../data/form/actions';

function* packageDraftResetData() {
    yield put(draftPackageDataPickerCleanValue());
    yield put(draftPackageFormResetValues());
}

export function* watchPackageDraftResetData() {
    yield takeEvery(PACKAGE_DRAFT_RESET_DATA, packageDraftResetData);
}

function* commonSaga() {
    yield all([
        fork(watchPackageDraftResetData),
    ]);
}

export default commonSaga;