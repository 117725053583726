import React, { useState } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { ProfileInfoCard } from './ProfileInfoCard';
import { EditProfile } from './EditProfile';
import { EditLogo } from "./EditLogo"


const ProfileContainerComponent = (props) => {

    const [editProfileDrawerVisible, setEditProfileDrawerVisible] = useState(false);
    const [editAvatarDrawerVisible, setEditAvatarDrawerVisible] = useState(false);

    const handleEditProfile = () => {
        setEditProfileDrawerVisible(true);
    };

    const closeEditProfileDrawer = () => {
        setEditProfileDrawerVisible(false);
    };

    const handleEditAvatar = () => {
        setEditAvatarDrawerVisible(true);
    };

    const closeEditAvatarDrawer = () => {
        setEditAvatarDrawerVisible(false);
    };

    return (
        <>
            <ProfileInfoCard
                onEdit={handleEditProfile}
                data={props.agency}
                onAvatarEdit={handleEditAvatar}
            />

            <EditProfile visible={editProfileDrawerVisible} onClose={closeEditProfileDrawer} />

            <EditLogo visible={editAvatarDrawerVisible} onClose={closeEditAvatarDrawer} />
        </>
    );
};

ProfileContainerComponent.propTypes = {
    agency: PropTypes.object,
}

const mapStateToProps = state => {
    const { data } = state.agency.profile.data;
    return {
        agency: data
    };
};

const mapDispatchToProps = {
};

export const ProfileContainer = connect(mapStateToProps, mapDispatchToProps)(ProfileContainerComponent);