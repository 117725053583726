import React from "react"
import PropTypes from "prop-types"
import {
  useRouteMatch,
  useParams,
  useLocation,
  generatePath,
  useHistory,
} from "react-router-dom"

import { GeneralModelsSidebar } from "../../../../../components"

export const ModelsSidebar = props => {
  let { url } = useRouteMatch()
  let { path } = useRouteMatch()
  const location = useLocation()
  const history = useHistory()

  const { id } = useParams()

  const getPath = selectedId => {
    const route = location.pathname.split(url)[1]
    return generatePath(`${path}${route}`, { id: selectedId })
  }

  const handleSelectModel = item => {
    if (props.drawerVisible) {
      props.toggleDrawerVisible(false)
    }

    if (item.id.toString() !== id) {
      history.push(getPath(item.id))
    }
  }

  return (
    <GeneralModelsSidebar onModelSelect={handleSelectModel} selectedId={id} />
  )
}

ModelsSidebar.propTypes = {
  drawerVisible: PropTypes.bool,
  toggleDrawerVisible: PropTypes.func,
}
