import axios from "axios"

import { RequestUtils } from "../../utils"

class SocialCalendarAPI {
  getPosts = async params => {
    return axios.get("api/social-calendar/posts", {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  getPost = async id => {
    return axios.get(`api/social-calendar/posts/${id}`)
  }

  addPost = async params => {
    return axios.post("api/social-calendar/posts", params)
  }

  updatePost = async (id, params) => {
    return axios.put(`api/social-calendar/posts/${id}`, params)
  }

  removePost = async id => {
    return axios.delete(`api/social-calendar/posts/${id}`)
  }
}

export const socialCalendar = new SocialCalendarAPI()
