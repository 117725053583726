import React from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"
import { analyticsHelper } from "helpers"

export const RadialBar = ({
  optionsData,
  height = "280",
  loading,
  error,
  labels,
}) => {
  const defaultOptions = {
    labels: labels,
    plotOptions: {
      radialBar: {
        offsetX: 0,
        offsetY: 60,
        hollow: {
          size: "37%",
        },
        dataLabels: {
          name: {
            offsetY: 5,
            fontWeight: 900,
            fontSize: "18px",
          },
          value: {
            show: false,
          },
          total: {
            show: false,
          },
        },
      },
    },
    noData: analyticsHelper.options.noDataOptions(loading, error),
  }

  return (
    <ReactApexChart
      options={{ ...defaultOptions, ...optionsData }}
      series={[100]}
      type={"radialBar"}
      height={height}
    />
  )
}

RadialBar.propTypes = {
  optionsData: PropTypes.object,
  height: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  labels: PropTypes.array,
}
