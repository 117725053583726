import React from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import { Button } from "components"
import { clientStatementSettingsData } from "store/actions"
import * as HOC from "HOC"

const DataShower = HOC.withDataShower()

export const StatementSettingsDataLoader = props => {
  const dispatch = useDispatch()

  const { data, loading, error } = useSelector(
    state => state.client.statement.settings.data
  )

  const fetchData = () => {
    dispatch(
      clientStatementSettingsData.getData({
        id: props.id,
      })
    )
  }

  return (
    <>
      <DataShower isLoading={loading} isFailed={error} error={error}>
        {data && props.children}
      </DataShower>

      {error && (
        <Button renderTitle={""} onClick={fetchData} icon={"mdi mdi-refresh"} />
      )}
    </>
  )
}

StatementSettingsDataLoader.propTypes = {
  id: PropTypes.number,
  children: PropTypes.any,
}
