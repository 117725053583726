import { combineReducers } from "redux"

import measurement from "./measurement/reducers"
import country from "./country/reducers"
import staticPage from "./staticPage/slice"

const staticReducer = combineReducers({
  measurement,
  country,
  staticPage,
})

export default staticReducer
