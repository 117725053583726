export const mapClientInfo = invoice => {
  const {
    client_information,
    client_name,
    client_address_line,
    client_address_city,
    client_address_zip,
    client_country,
  } = invoice

  const newLine = `\n`

  let value = client_information ? `${client_information}${newLine}` : ""

  value += `${client_name}`
  const country = client_country?.name

  value += `${newLine}${client_address_line} ${client_address_city}`

  if (client_address_zip) {
    value += ` ${client_address_zip}`
  }

  value += `${newLine}${country}`

  return value
}
