import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { ControllerHF, InputField } from "components"
import { useFormContext } from "react-hook-form"

export const Product = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <ControllerHF
      name={"product"}
      id={"product"}
      label={t("product")}
      placeholder={t("product")}
      control={control}
      component={InputField}
      className={"mb-0"}
    />
  )
}

Product.propTypes = {}
