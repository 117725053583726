import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import { AttachedFile } from "../../AttachedFile"

export const FilesUploaderFileField = ({
  id,
  label,
  className,
  value,
  meta = {},
  onRemove,
  onUpload,
  withDownload,
  withRemove = true,
  onDownload,
  loading,
  ...props
}) => {
  const { error } = meta

  const showError = Boolean(error)

  const { t } = useTranslation()

  useEffect(() => {
    if (props.onValidate && value) {
      props.onValidate(props.name, onValid)
    }
  }, [])

  const onValid = () => {
    onUpload()
  }

  const removeFile = () => {
    onRemove()
  }

  const getErrorMessage = () => {
    if (!error) {
      return null
    }

    let message = null
    if (typeof error === "string") {
      message = error
    }
    if (error?.message) {
      message = error.message
    }
    if (message) {
      message = t(
        message,
        props.translateParams ? { ...props.translateParams } : {}
      )
    }
    return message
  }

  const file = useMemo(() => {
    if (value) {
      return value
    }

    return props?.item?.attachment
  }, [value, props.item])

  return (
    <div
      className={classNames("files-uploader-file", {
        [className]: className,
      })}
    >
      <div
        className={classNames("d-flex w-100 h-100", {
          "is-invalid": showError,
        })}
      >
        <div className={"files-uploader-file__block"}>
          <AttachedFile
            name={file?.name}
            onRemove={() => removeFile(value)}
            size={file?.size}
            withDownload={withDownload}
            withRemove={withRemove}
            onDownload={onDownload}
            loading={loading}
            error={getErrorMessage()}
            fileNamePlaceholderText={t("attached-file")}
          />
        </div>
      </div>
    </div>
  )
}

FilesUploaderFileField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  translateParams: PropTypes.object,
  value: PropTypes.any,
  meta: PropTypes.object,
  onRemove: PropTypes.func,
  onUpload: PropTypes.func,
  withDownload: PropTypes.bool,
  withRemove: PropTypes.bool,
  onDownload: PropTypes.func,
  loading: PropTypes.bool,
  onValidate: PropTypes.func,
  onChange: PropTypes.func,
  item: PropTypes.object,
}
