import { combineReducers } from "redux"

import send from "./send/slice"
import data from "./data/slice"

const sharedEntityReducer = combineReducers({
  send,
  data,
})

export default sharedEntityReducer
