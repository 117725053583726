import { createSelector } from "@reduxjs/toolkit"
import moment from "moment"

const getChatsList = state => state.chat.chats.list.list

const sort = chats => {
  chats.sort((a, b) => {
    if (b.last_message === null && a.last_message) {
      return -1
    }

    if (a.last_message === null && b.last_message) {
      return 1
    }

    if (b.last_message === null && a.last_message === null) {
      return 0
    }

    return (
      moment(b.last_message.created_at).valueOf() -
      moment(a.last_message.created_at).valueOf()
    )
  })

  return chats
}

export const selectChatsSortList = createSelector([getChatsList], list =>
  sort([...list])
)
