import React, { useState } from "react"
import PropTypes from "prop-types"

import { Button } from "components"

export const Export = ({ onExport }) => {
  const [loading, setLoading] = useState(false)

  const handleExport = async () => {
    setLoading(true)

    try {
      await onExport()
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={"d-flex align-items-end h-100"}>
      <Button
        title={"export"}
        onClick={handleExport}
        disabled={loading}
        loading={loading}
        color={"secondary"}
      />
    </div>
  )
}

Export.propTypes = {
  onExport: PropTypes.func,
}
