import React, { useReducer } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"

import {
  EditControl,
  CancelControl,
  PriorityControl,
  JobControl,
} from "./Controls"
import { calendarEventModelUpdate } from "store"
import { EVENT_TYPE_JOB, EVENT_TYPE_OPTION } from "consts"

const initialState = {
  loading: {},
}

function reducer(state, action) {
  switch (action.type) {
    case "submit":
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload]: true,
        },
      }
    case "submitCompleted":
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload]: false,
        },
      }
    case "cleanState":
      return initialState
    default:
      return state
  }
}

export const ModelActionBar = ({ actionBar, model, eventType, ...props }) => {
  const [{ loading }, d] = useReducer(reducer, initialState)

  const dispatch = useDispatch()

  const onChange = (payload = {}) => {
    props.onModelUpdate(model, payload)
  }

  const onSubmit = (name, params = {}) => {
    if (loading[name]) {
      return
    }

    d({ type: "submit", payload: name })

    dispatch(
      calendarEventModelUpdate.update({
        event: props.eventId,
        model: model?.id,
        params: params,
        onSuccess: onSubmitSuccess(name, params),
        onError: onSubmitError(name),
      })
    )
  }

  const onSubmitSuccess = (name, params) => eventData => {
    d({ type: "submitCompleted", payload: name })

    onChange(params)

    props.onEventUpdate(eventData)
  }

  const onSubmitError = name => () => {
    d({ type: "submitCompleted", payload: name })
  }

  return (
    <div className={"model-action-bar"}>
      {actionBar["edit"] && (
        <div className={"model-action-bar__item"}>
          <EditControl
            onClick={() => props.onModelEdit(model)}
            modelId={model?.id}
          />
        </div>
      )}

      {actionBar["cancel"] && (
        <div className={"model-action-bar__item"}>
          <CancelControl
            eventId={props.eventId}
            modelId={model?.id}
            status={model?.active}
            onChange={onChange}
            onSubmit={onSubmit}
            loading={loading["active"]}
          />
        </div>
      )}

      {actionBar["priority"] && model?.type !== EVENT_TYPE_JOB && (
        <div className={"model-action-bar__item"}>
          <PriorityControl
            eventType={eventType}
            eventId={props.eventId}
            modelId={model?.id}
            priority={model?.priority}
            onChange={onChange}
            onSubmit={onSubmit}
            loading={loading["priority"]}
          />
        </div>
      )}

      {actionBar["job"] && model?.type === EVENT_TYPE_OPTION && (
        <div className={"model-action-bar__item"}>
          <JobControl
            eventId={props.eventId}
            modelId={model?.id}
            type={model?.type}
            onChange={onChange}
            onSubmit={onSubmit}
            loading={loading["type"]}
          />
        </div>
      )}
    </div>
  )
}

ModelActionBar.propTypes = {
  actionBar: PropTypes.object,
  model: PropTypes.object,
  eventType: PropTypes.string,
  eventId: PropTypes.number,
  eventIsEdit: PropTypes.bool,
  onModelUpdate: PropTypes.func,
  onEventUpdate: PropTypes.func,
  onModelEdit: PropTypes.func,
}
