import { takeLatest, fork, put, all, call } from "redux-saga/effects"
import { clientPricesList } from "./slice"
import { RequestUtils } from "../../../../utils"
import i18n from "../../../../i18n"
import { API } from "../../../../api"

export function* getList({ payload: { id, params } }) {
  try {
    let requestParams = {
      includes: [],
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response = yield call(API.client.getClientPrices, id, requestParams)

    const { data } = response
    const {
      meta: { current_page, last_page },
    } = data
    if (current_page && last_page && current_page > last_page) {
      yield put(
        clientPricesList.getList({
          id,
          params: {
            ...params,
            page: last_page,
          },
        })
      )
    } else {
      yield put(clientPricesList.getListCompleted(data))
    }
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(clientPricesList.getListRejected(message))
  }
}

export function* watchGetList() {
  yield takeLatest(clientPricesList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
