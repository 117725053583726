import React from "react"
import PropTypes from "prop-types"
import { Label } from "reactstrap"

export const Header = ({ title, headerRight, headerLeft }) => {
  return (
    <div className={"d-flex justify-content-between align-items-center mb-2"}>
      <div className={"d-flex gap-2 align-items-center"}>
        <Label className={"mb-0"}>{title}</Label>
        {headerLeft}
      </div>
      <div>{headerRight}</div>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  headerRight: PropTypes.any,
  headerLeft: PropTypes.any,
}
