import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { API } from "api"
import { addUserIdea } from "./slice"
import i18n from "../../../i18n"

function* addItem({ payload: { params, onSuccess, onError } }) {
  try {
    const response = yield call(API.userIdeas.addUserIdea, params)

    const { data } = response

    if (onSuccess) {
      onSuccess()
    }
    yield put(addUserIdea.addCompleted())
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      addUserIdea.addRejected({
        errors,
        message,
      })
    )

    if (onError) {
      onError(errors)
    }
  }
}

export function* watchAddItem() {
  yield takeEvery(addUserIdea.add, addItem)
}

function* addSaga() {
  yield all([fork(watchAddItem)])
}

export default addSaga
