import * as Yup from "yup"

import {
  getEventTitleInfoValidations,
  getEventPeriodValidations,
} from "../helpers"

export const validationSchema = Yup.object().shape({
  ...getEventPeriodValidations(),
  ...getEventTitleInfoValidations(),
})
