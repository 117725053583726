import React from "react"
import PropTypes from "prop-types"
import { GenderSection } from "./GenderSection"
import { ModelsSection } from "./ModelsSection"
import { PhotosSection } from "./PhotosSection"
import { Col, Row } from "reactstrap"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

export const PhotoSelectSection = () => {
  const {
    formState: { errors },
  } = useFormContext()

  const { t } = useTranslation()

  return (
    <Row>
      <Col md={4}>
        <GenderSection />
        <ModelsSection />
      </Col>
      <Col md={8}>
        <PhotosSection />
      </Col>
      <div className={"field-error-message"}>
        {errors?.photo && t(errors?.photo.message)}
      </div>
    </Row>
  )
}

PhotoSelectSection.propTypes = {}
