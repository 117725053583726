import {
    MODEL_PANEL_GENERAL_EDIT_CONTACTS,
    MODEL_PANEL_GENERAL_EDIT_CONTACTS_SUCCESS,
    MODEL_PANEL_GENERAL_EDIT_CONTACTS_ERROR,
    MODEL_PANEL_GENERAL_EDIT_CONTACTS_CLEAN_STATE,
} from './actionTypes';

export const modelPanelGeneralEditContacts = (id, params, onSuccess, onError) => {
    return {
        type: MODEL_PANEL_GENERAL_EDIT_CONTACTS,
        payload: { id, params, onSuccess, onError },
    }
};

export const modelPanelGeneralEditContactsSuccess = () => {
    return {
        type: MODEL_PANEL_GENERAL_EDIT_CONTACTS_SUCCESS,
    }
};

export const modelPanelGeneralEditContactsError = (data) => {
    return {
        type: MODEL_PANEL_GENERAL_EDIT_CONTACTS_ERROR,
        payload: data,
    }
};

export const modelPanelGeneralEditContactsCleanState = () => {
    return { type: MODEL_PANEL_GENERAL_EDIT_CONTACTS_CLEAN_STATE };
};