import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

export const Cake = ({ className, ...props }) => {
  return (
    <i
      className={classNames("fas fa-birthday-cake", { [className]: className })}
    />
  )
}

Cake.propTypes = {
  className: PropTypes.string,
}
