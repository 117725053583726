import _ from "lodash"
import calendarsColors from "assets/scss/dev/blocks/events-calendar/_calendar-event-colors.module.scss"
import i18n from "../../i18n"
import {
  EVENT_TYPE_NOTE,
  EVENT_TYPE_OUT,
  EVENT_TYPE_SCOUTING,
  EVENT_TYPE_GO_SEE,
  EVENT_TYPE_CASTING,
  EVENT_TYPE_FITTING,
  EVENT_TYPE_OPTION,
  EVENT_TYPE_JOB,
  EVENT_TYPE_ACCOMMODATION,
  EVENT_TYPE_ARRIVAL,
  EVENT_TYPE_TRAVEL,
  EVENT_TYPE_DEPARTURE,
  EVENT_TYPE_BIRTHDAY,
  CALENDAR_EVENT_CATEGORY_GENERAL,
  CALENDAR_EVENT_CATEGORY_OTHER,
  CALENDAR_EVENT_CATEGORY_HOLIDAY,
} from "../../consts"
import { commonHelper } from "helpers"

export const getEvents = () => {
  return [
    {
      title: i18n.t("event-type.note"),
      type: EVENT_TYPE_NOTE,
      theme: "note",
      textColor: calendarsColors.textColor,
      backgroundColor: calendarsColors.noteBg,
      borderColor: calendarsColors.noteBg,
      order: 12,
      category: CALENDAR_EVENT_CATEGORY_GENERAL,
    },
    {
      title: i18n.t("event-type.out"),
      type: EVENT_TYPE_OUT,
      theme: "out",
      textColor: calendarsColors.textColor,
      backgroundColor: calendarsColors.outBg,
      borderColor: calendarsColors.outBg,
      order: 11,
      category: CALENDAR_EVENT_CATEGORY_GENERAL,
    },
    {
      title: i18n.t("event-type.scouting"),
      type: EVENT_TYPE_SCOUTING,
      theme: "scouting",
      textColor: calendarsColors.textColor,
      backgroundColor: calendarsColors.scoutingBg,
      borderColor: calendarsColors.scoutingBg,
      order: 10,
      category: CALENDAR_EVENT_CATEGORY_GENERAL,
    },
    {
      title: i18n.t("event-type.go_see"),
      type: EVENT_TYPE_GO_SEE,
      theme: "go_see",
      textColor: calendarsColors.textColor,
      backgroundColor: calendarsColors.go_seeBg,
      borderColor: calendarsColors.go_seeBg,
      order: 9,
      category: CALENDAR_EVENT_CATEGORY_GENERAL,
    },
    {
      title: i18n.t("event-type.casting"),
      type: EVENT_TYPE_CASTING,
      theme: "casting",
      textColor: calendarsColors.textColor,
      backgroundColor: calendarsColors.castingBg,
      borderColor: calendarsColors.castingBg,
      order: 8,
      category: CALENDAR_EVENT_CATEGORY_GENERAL,
    },
    {
      title: i18n.t("event-type.fitting"),
      type: EVENT_TYPE_FITTING,
      theme: "fitting",
      textColor: calendarsColors.textColor,
      backgroundColor: calendarsColors.fittingBg,
      borderColor: calendarsColors.fittingBg,
      order: 7,
      category: CALENDAR_EVENT_CATEGORY_GENERAL,
    },
    {
      title: i18n.t("event-type.option"),
      type: EVENT_TYPE_OPTION,
      theme: "option",
      textColor: calendarsColors.textColor,
      backgroundColor: calendarsColors.optionBg,
      borderColor: calendarsColors.optionBg,
      order: 6,
      category: CALENDAR_EVENT_CATEGORY_GENERAL,
    },
    {
      title: i18n.t("event-type.job"),
      type: EVENT_TYPE_JOB,
      theme: "job",
      textColor: calendarsColors.textColor,
      backgroundColor: calendarsColors.jobBg,
      borderColor: calendarsColors.jobBg,
      order: 5,
      category: CALENDAR_EVENT_CATEGORY_GENERAL,
    },
    {
      title: i18n.t("event-type.accommodation"),
      type: EVENT_TYPE_ACCOMMODATION,
      textColor: calendarsColors.textColor,
      backgroundColor: calendarsColors.otherCategoryEventBgColor,
      borderColor: calendarsColors.otherCategoryEventBgColor,
      order: 1,
      category: CALENDAR_EVENT_CATEGORY_OTHER,
      hidden: true, // WARNING: temporarily removed for creation event, but left in case there were already created event of this type
    },
    {
      title: i18n.t("event-type.arrival"),
      type: EVENT_TYPE_ARRIVAL,
      textColor: calendarsColors.textColor,
      backgroundColor: calendarsColors.otherCategoryEventBgColor,
      borderColor: calendarsColors.otherCategoryEventBgColor,
      order: 2,
      category: CALENDAR_EVENT_CATEGORY_OTHER,
    },
    {
      title: i18n.t("event-type.travel"),
      type: EVENT_TYPE_TRAVEL,
      textColor: calendarsColors.textColor,
      backgroundColor: calendarsColors.otherCategoryEventBgColor,
      borderColor: calendarsColors.otherCategoryEventBgColor,
      order: 3,
      category: CALENDAR_EVENT_CATEGORY_OTHER,
    },
    {
      title: i18n.t("event-type.departure"),
      type: EVENT_TYPE_DEPARTURE,
      textColor: calendarsColors.textColor,
      backgroundColor: calendarsColors.otherCategoryEventBgColor,
      borderColor: calendarsColors.otherCategoryEventBgColor,
      order: 4,
      category: CALENDAR_EVENT_CATEGORY_OTHER,
    },
    {
      title: i18n.t("event-type.birthday"),
      type: EVENT_TYPE_BIRTHDAY,
      textColor: calendarsColors.textColor,
      backgroundColor: calendarsColors.holidayCategoryEventBgColor,
      borderColor: calendarsColors.holidayCategoryEventBgColor,
      order: 0,
      category: CALENDAR_EVENT_CATEGORY_HOLIDAY,
    },
  ]
}

export const getEventConfig = type => {
  const events = getEvents()
  return _.find(events, { type: type }) || {}
}

export const getEventsByCategory = category => {
  return getEvents().filter(item => item.category === category && !item.hidden)
}

export const getGeneralEvents = () => {
  return getEventsByCategory(CALENDAR_EVENT_CATEGORY_GENERAL)
}

export const getOtherCategoryEvents = () => {
  return getEventsByCategory(CALENDAR_EVENT_CATEGORY_OTHER)
}

export const getHolidayCategoryEvents = () => {
  return getEventsByCategory(CALENDAR_EVENT_CATEGORY_HOLIDAY)
}

export const isOtherCategoryEvent = eventType => {
  return getEventConfig(eventType)?.category === CALENDAR_EVENT_CATEGORY_OTHER
}

export const isHolidayCategoryEvent = eventType => {
  return getEventConfig(eventType)?.category === CALENDAR_EVENT_CATEGORY_HOLIDAY
}

export const getEventsByTypes = types => {
  return getEvents().filter(event => types.includes(event.type))
}

export const getEventColor = (eventColors, type) => {
  let entity = eventColors[type]

  if (isOtherCategoryEvent(type)) {
    entity = eventColors[CALENDAR_EVENT_CATEGORY_OTHER]
  }

  if (isHolidayCategoryEvent(type)) {
    entity = eventColors[EVENT_TYPE_BIRTHDAY] // CALENDAR_EVENT_CATEGORY_HOLIDAY
  }

  let color = commonHelper.colors.getColorFromEntity(entity)

  if (!color) {
    color = getEventConfig(type)?.backgroundColor
  }

  return color
}
