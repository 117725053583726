import React from "react"
import { Row, Col } from "reactstrap"

import { MegaTagTitle } from "components"
import { StatementContainer } from "./components/StatementComponents"

export const Statement = () => {
  return (
    <div className={"client-statement"}>
      <MegaTagTitle title={"title.client.statement"} />
      <Row>
        <Col>
          <StatementContainer />
        </Col>
      </Row>
    </div>
  )
}
