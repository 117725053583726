import { EVENT_TYPE_SCOUTING, GENERAL_CALENDAR_ENTITY } from "consts"
import { FormatUtils } from "utils"

const { MODEL, AGENCY, CLIENT } = GENERAL_CALENDAR_ENTITY

export const getEventLocationsFromEntity = data => {
  let locations = []

  if (data && data?.addresses && data?.addresses?.length) {
    const { country } = data
    let addresses = [...data.addresses]

    // sort by main address
    addresses.sort((a, b) =>
      a.main_type === b.main_type ? 0 : a.main_type ? -1 : 1
    )

    locations = addresses.slice(0, 2).map((item, i) => ({
      country_id: country || null,
      street: item.street,
      town: item.town,
      zip: item.zip,
      phone: FormatUtils.formatPhone(item.phone),
      is_primary: i === 0,
    }))
  }

  return locations
}

export const getEntityCreateEventInitialValues = (
  entity,
  entityData,
  eventType
) => {
  let values = null

  const { id, name } = entityData

  if (entity === MODEL) {
    values = {
      fashion_models: [
        {
          id,
          name,
          surname: entityData.surname,
        },
      ],
    }
  } else if (entity === AGENCY) {
    values = {
      agency: {
        id,
        name,
      },
      defaultLocations:
        eventType === EVENT_TYPE_SCOUTING
          ? getEventLocationsFromEntity(entityData)
          : null,
    }
  } else if (entity === CLIENT) {
    values = {
      client: {
        id,
        name,
      },
      defaultLocations:
        eventType !== EVENT_TYPE_SCOUTING
          ? getEventLocationsFromEntity(entityData)
          : null,
    }
  }

  return values
}
