import { combineReducers } from "redux"

import create from "./create/slice"
import item from "./item/slice"
import list from "./list/slice"
import remove from "./remove/slice"
import update from "./update/slice"

const postReducer = combineReducers({
  create,
  item,
  list,
  remove,
  update,
})

export default postReducer
