import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { EventContractDrawer } from "../EventContractDrawer"
import { EventContractDataLoader } from "../EventContractDataLoader"
import { EventContractForm } from "../EventContractForm"
import { useSubmitHandler } from "hooks"
import { calendarEventContractEditSubmit } from "store/actions"
import { EditorUtils } from "utils"

export const EventContractEdit = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const {
    contract,
    submit: { loading, error },
  } = useSelector(state => ({
    contract: state.calendarEvent.contract.edit.data.data,
    submit: state.calendarEvent.contract.edit.submit,
  }))

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        calendarEventContractEditSubmit.submit({
          id: props.eventId,
          params,
          onSuccess,
          onError,
        })
      )
    },
    () => {
      props.onSuccess()
    }
  )

  const onDestroy = () => {
    dispatch(calendarEventContractEditSubmit.cleanState())
    props.onDestroy && props.onDestroy()
  }

  const initialValues = useMemo(() => {
    let result = {}
    if (contract) {
      const { contract_text, ...rest } = contract

      result = {
        ...rest,
        contract_text: EditorUtils.draftJs.getEditorState(
          contract_text ? contract_text : ""
        ),
      }
    }

    return result
  }, [contract])

  return (
    <EventContractDrawer
      title={t("edit_contract")}
      visible={props.visible}
      onClose={props.onClose}
      onDestroy={onDestroy}
    >
      <EventContractDataLoader id={props.eventId} isEdit>
        <EventContractForm
          loading={loading}
          error={error}
          onSubmit={handleSubmit}
          onCancel={props.onClose}
          initialValues={{
            ...initialValues,
          }}
          isEdit
        />
      </EventContractDataLoader>
    </EventContractDrawer>
  )
}

EventContractEdit.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  onSuccess: PropTypes.func,
  eventId: PropTypes.number,
}
