import React, { useEffect } from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Row } from "reactstrap";

import { Drawer } from '../../../../../../components';
import { AgencyForm } from '../AgencyForm';
import { modelPanelGeneralAddAgency, modelPanelGeneralAddAgencyCleanState } from '../../../../../../store/actions';
import { ValidationsUtils } from "../../../../../../utils"

const AddAgencyComponent = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            props.modelPanelGeneralAddAgencyCleanState();
        }
    }, []);

    const handleCloseDrawer = () => {
        props.onClose();
    }

    const onSubmitSuccess = (onSubmitProps) => () => {
        const { reset } = onSubmitProps;
        reset();

        props.onAddSuccess();
        props.onClose();
    }

    const onSubmitError = (onSubmitProps) => (errors) => {
        const { setError } = onSubmitProps;
        ValidationsUtils.setServerSideErrors(errors, setError);
    }

    const handleSubmit = (params, onSubmitProps) => {
        props.modelPanelGeneralAddAgency(props.model.id, params, onSubmitSuccess(onSubmitProps), onSubmitError(onSubmitProps));
    };

    return (
        <React.Fragment>
            <Drawer
                title={t('agency')}
                visible={props.visible}
                onClose={handleCloseDrawer}
                placement={'right'}
                className={'drawer-container'}
                drawerProps={{}}
            >
                <Container fluid>
                    <Row>
                        <AgencyForm
                            initialValues={{}}
                            loading={props.loading}
                            error={props.error}
                            onSubmit={handleSubmit}
                            onCancel={handleCloseDrawer}
                            mode={'add'}
                        />
                    </Row>
                </Container>
            </Drawer>
        </React.Fragment>
    );
};

AddAgencyComponent.propTypes = {
    visible: PropTypes.bool,
    onAddSuccess: PropTypes.func,
    onClose: PropTypes.func,

    loading: PropTypes.bool,
    error: PropTypes.object,
    model: PropTypes.object,

    modelPanelGeneralAddAgency: PropTypes.func,
    modelPanelGeneralAddAgencyCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { data } = state.model.profile;
    const { loading, error } = state.modelPanel.general.agency.add;
    return {
        model: data,
        loading, error,
    };
};

const mapDispatchToProps = {
    modelPanelGeneralAddAgency,
    modelPanelGeneralAddAgencyCleanState,
};

export const AddAgency = connect(mapStateToProps, mapDispatchToProps)(AddAgencyComponent);