import { all } from 'redux-saga/effects';

import addSaga from './add/saga';
import dataSaga from './data/saga';
import removeSaga from './remove/saga';


function* ratingSaga() {
  yield all([
    addSaga(),
    dataSaga(),
    removeSaga(),
  ])
}

export default ratingSaga;