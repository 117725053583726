import React, { useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch, useSelector } from "react-redux"
import { analyticsTablesDataList } from "store"
import { TableFilter } from "./TableFilter"
import { useDeepCompareEffect, useFirstMountState } from "react-use"
import { CLIENTS_TABLE, EMPLOYEES_TABLE, MODELS_TABLE } from "consts"
import { ClientsTable, EmployeesTable, ModelsTable } from "./Tables"
import { commonHelper } from "helpers"

export const TableContainerComponent = props => {
  const dispatch = useDispatch()
  const isFirstMount = useFirstMountState()
  const { data: profile } = useSelector(state => state.user.profile)

  useEffect(() => {
    fetchGraphData()
    return () => dispatch(analyticsTablesDataList.cleanState())
  }, [])

  useDeepCompareEffect(() => {
    if (!isFirstMount) {
      fetchGraphData()
    }
  }, [props.filters.values, props.sort, props.page])

  const fetchGraphData = () => {
    let params = {
      page: props.page,
      per_page: 10,
      sort: props.sort.length ? props.sort : null,
    }

    const { name } = props.filters.values

    params.filters = {
      name: name !== "" ? name : null,
    }

    dispatch(
      analyticsTablesDataList.getList({
        alias: props.graphData.alias,
        params,
      })
    )
  }

  const handlePageChange = item => {
    dispatch(analyticsTablesDataList.setPage(item.selected + 1))
  }

  const handleSearch = value => {
    dispatch(analyticsTablesDataList.changeFilter({ name: value }))
  }
  const handleSort = useCallback(value => {
    dispatch(analyticsTablesDataList.changeSort({ sort: value }))
  }, [])

  const renderTable = alias => {
    let tableProps = {
      list: props.list,
      page: props.page,
      loading: props.listLoading,
      error: props.listError,
      meta: props.meta,
      onPageChange: handlePageChange,
      onSortChange: handleSort,
      sortValue: props.sort,
      currency: commonHelper.currency.getCurrency(profile.settings?.currency),
    }
    switch (alias) {
      case MODELS_TABLE: {
        return <ModelsTable {...tableProps} />
      }
      case CLIENTS_TABLE: {
        return <ClientsTable {...tableProps} />
      }
      case EMPLOYEES_TABLE: {
        return <EmployeesTable {...tableProps} />
      }
    }
  }
  return (
    <>
      <TableFilter onSearch={handleSearch} graphData={props.graphData} />
      {props.tableAlias === props.graphData.alias
        ? renderTable(props.graphData.alias)
        : null}
    </>
  )
}

TableContainerComponent.propTypes = {
  graphData: PropTypes.object,

  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
  filters: PropTypes.object,
  sort: PropTypes.array,
  tableAlias: PropTypes.string,
}

const mapStateToProps = state => {
  const {
    list,
    listLoading,
    listError,
    meta,
    page,
    filters,
    sort,
    tableAlias,
  } = state.analytics.graphs.tablesDataList
  return {
    list,
    listLoading,
    listError,
    meta,
    page,
    filters,
    sort,
    tableAlias,
  }
}

export const AnalyticsTableContainer = connect(mapStateToProps)(
  TableContainerComponent
)
