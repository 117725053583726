import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { MegaTagTitle } from "../../components"

export const RegisterConfirmed = props => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <MegaTagTitle title={"title.sign_up.confirmed"} />

      <Card className="overflow-hidden">
        <div className="bg-primary">
          <Row>
            <Col>
              <div className="text-white p-4 text-center">
                <h5 className="text-white mb-0">
                  {t("title.sign_up.confirmed")}
                </h5>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody>
          <div className="p-2">
            <div className={"text-center"}>
              <h4>{t("register_confirmed.message")}</h4>

              <div className={"mt-3"}>
                <Link to="/login" className="btn btn-primary" replace>
                  {t("btn.login")}
                </Link>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      <div className="mt-5 text-center">
        <p>
          <Link
            to="/login"
            className="font-weight-medium text-secondary"
            replace
          >
            {t("btn.login")}
          </Link>
        </p>
      </div>
    </React.Fragment>
  )
}
