import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { RatingGrid } from "../../../../components/RatingGrid"
import { employeeRatingsData } from "../../../../store/actions"

const RatingContainerComponent = props => {
  const dispatch = useDispatch()

  const fetchData = () => {
    let params = {
      page: props.page,
    }
    dispatch(employeeRatingsData.getData({ id: props.employee.id, params }))
  }

  useEffect(() => {
    fetchData()
  }, [props.page])

  useEffect(() => {
    return () => {
      dispatch(employeeRatingsData.cleanState())
    }
  }, [])

  const handlePageChange = item => {
    dispatch(employeeRatingsData.setPage(item.selected + 1))
  }

  return (
    <>
      <RatingGrid
        items={props.list}
        loading={props.listLoading}
        error={props.listError}
        meta={props.meta}
        page={props.page}
        onPageChange={handlePageChange}
        withoutAuthor
      />
    </>
  )
}

RatingContainerComponent.propTypes = {
  employee: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
}

const mapStateToProps = state => {
  const { data } = state.employee.details.data
  const { list, loading, error, meta, page } = state.employee.rating.data
  return {
    employee: data,
    listLoading: loading,
    listError: error,
    list,
    meta,
    page,
  }
}

export const RatingContainer = connect(mapStateToProps)(
  RatingContainerComponent
)
