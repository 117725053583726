import { all } from "redux-saga/effects"

import measurementSaga from "./measurement/sagas"
import countrySaga from "./country/sagas"
import staticPageSaga from "./staticPage/saga"

function* staticSaga() {
  yield all([measurementSaga(), countrySaga(), staticPageSaga()])
}

export default staticSaga
