import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { PackageForm } from "../../../../components"
import { ValidationsUtils } from "../../../../utils"
import { PACKAGE_FORM_SUBMIT_TYPE_SEND } from "../../../../consts"
import {
  packageEditData,
  packageEditDataCleanState,
} from "../../../../store/actions"

const EditPackageComponent = props => {
  useEffect(() => {
    return () => {
      props.packageEditDataCleanState()
    }
  }, [])

  const onSubmitSuccess = onSubmitProps => data => {
    const { submitType } = onSubmitProps

    if (submitType && submitType === PACKAGE_FORM_SUBMIT_TYPE_SEND) {
      props.onSend(data)
    } else {
      props.onSaveSuccess()
    }
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    props.packageEditData(
      props.package?.id,
      params,
      onSubmitSuccess(onSubmitProps),
      onSubmitError(onSubmitProps)
    )
  }

  return (
    <PackageForm
      initialValues={{
        title: props.package?.title || "",
        description: props.package?.description || "",
      }}
      loading={props.loading}
      error={props.error}
      onSubmit={handleSubmit}
      dataPicker={props.dataPicker}
      isEdit
      id={props.package?.id}
    />
  )
}

EditPackageComponent.propTypes = {
  onSaveSuccess: PropTypes.func,
  onSend: PropTypes.func,

  loading: PropTypes.bool,
  error: PropTypes.object,
  dataPicker: PropTypes.object,
  package: PropTypes.object,

  packageEditData: PropTypes.func,
  packageEditDataCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, error } = state.package.details.edit
  const { data } = state.package.details.data
  return {
    loading,
    error,
    dataPicker: state.package.dataPicker,
    package: data,
  }
}

const mapDispatchToProps = {
  packageEditData,
  packageEditDataCleanState,
}

export const EditPackage = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPackageComponent)
