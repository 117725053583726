import React from "react"
import PropTypes from "prop-types"
import { Row, Col, Container } from "reactstrap"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { MegaTagTitle, PageHeader } from "components"
import { PaymentInfo, PaySection } from "../components/PayComponents"
import { BillingInformation } from "../components"

export const BillingPay = props => {
  const location = useLocation()
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <MegaTagTitle title={"payment"} />

      <div className="page-content">
        <Container fluid>
          <PageHeader back backTitle={t("back")} />

          <PaymentInfo
            tariff={location.state?.paymentInfo?.tariff}
            price={location.state?.paymentInfo?.price}
          />

          <div className={"mt-4"}>
            <BillingInformation disabledMode />
          </div>

          <div className={"mt-4"}>
            <PaySection paymentInfo={location.state?.paymentInfo} />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

BillingPay.propTypes = {}
