import { combineReducers } from "redux";

import addReducer from './add/slice'
import removeReducer from './remove/slice'
import dataReducer from './data/slice'

const ratingReducer = combineReducers({
    data: dataReducer,
    add: addReducer,
    remove: removeReducer
});

export default ratingReducer;