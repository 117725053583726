import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { UploadPhoto } from "./UploadPhoto"
import { useTranslation } from "react-i18next"
import { nanoid } from "@reduxjs/toolkit"
import { MAX_FILE_COUNT, UPLOAD_FILE_TYPE } from "consts"
import toastr from "toastr"
import { multipleUpload } from "store"

const AddPhotoComponent = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  useEffect(() => {
    return () => {
      dispatch(multipleUpload.cleanState())
    }
  }, [])

  const handleUpload = fileList => {
    //Handle max file count error
    if (fileList.length > MAX_FILE_COUNT) {
      toastr.error(t(`files-count.error`, { max_count: MAX_FILE_COUNT }))
      return
    }
    //Prepare payload object for each file id, params for request, fileConfig {id, name, size}
    let fileListWithPayload = fileList.map(file => {
      let customId = nanoid(3)
      return {
        id: customId,
        params: {
          attachment: file,
          alias: props.alias,
        },
        fileConfig: {
          name: file.name,
          size: file.size,
          id: customId,
        },
      }
    })
    dispatch(
      multipleUpload.addFiles({
        filesType: UPLOAD_FILE_TYPE.WEBSITE_ATTACHMENT,
        filesList: fileListWithPayload,
        onSuccess: handleSuccess,
      })
    )
  }

  const handleSuccess = () => {
    props.onAddSuccess()
  }

  return <UploadPhoto loading={props.filesUploading} onUpload={handleUpload} />
}

AddPhotoComponent.propTypes = {
  filesUploading: PropTypes.bool,
  alias: PropTypes.string,

  onAddSuccess: PropTypes.func,
}

const mapStateToProps = state => {
  const { filesUploading } = state.multipleUpload
  return {
    filesUploading,
  }
}

export const AddPhoto = connect(mapStateToProps)(AddPhotoComponent)
