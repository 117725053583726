import { combineReducers } from "redux"

import data from "./data/slice"
import edit from "./edit/slice"
import remove from "./remove/slice"

const expenseReducer = combineReducers({
  data,
  edit,
  remove,
})

export default expenseReducer
