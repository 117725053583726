import { all } from "redux-saga/effects"

import profileSaga from "./profile/sagas"
import avatarSaga from "./avatar/sagas"
import contactsSaga from "./contacts/sagas"
import featuresSaga from "./features/sagas"
import logisticsSaga from "./logistics/sagas"
import documentsSaga from "./documents/sagas"
import workSaga from "./work/sagas"
import agencySaga from "./agency/sagas"

function* generalSaga() {
  yield all([
    profileSaga(),
    avatarSaga(),
    contactsSaga(),
    featuresSaga(),
    logisticsSaga(),
    documentsSaga(),
    workSaga(),
    agencySaga(),
  ])
}

export default generalSaga
