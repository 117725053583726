import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { Modal } from "components"
import { useFetchData } from "hooks"
import { GroupAddForm } from "./GroupAddForm"
import { modelsGroupsList } from "store/actions"
import { API } from "api"
import { tempData } from "./temp"

export const GroupAdd = ({ modalRef, onCancel, onSuccess }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { list: groups, isFetched } = useSelector(
    state => state.models.groups.list
  )

  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    if (modalVisible && !isFetched) {
      dispatch(
        modelsGroupsList.getList({
          params: {
            pagination: false,
            type: null,
          },
        })
      )
    }
  }, [modalVisible, isFetched])

  useEffect(() => {
    return () => {
      dispatch(modelsGroupsList.cleanState())
    }
  }, [])

  const { fetchData, error, clean } = useFetchData()

  const onSubmit = async values => {
    clean()

    try {
      const id = values.group_id

      const request = API.modelsGroups.getGroupPublishedBooks(id)
      const result = await fetchData({
        request,
      })

      onSuccess(result?.data || [])
    } catch (e) {}
  }

  return (
    <Modal
      ref={modalRef}
      title={t("add_group")}
      onVisibleChange={value => setModalVisible(value)}
      size={"md"}
      strapModalProp={{
        centered: true,
      }}
    >
      <GroupAddForm
        groups={groups}
        error={error}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </Modal>
  )
}

GroupAdd.propTypes = {
  modalRef: PropTypes.any,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
}
