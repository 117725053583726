import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { SCREEN } from "./consts"
import { EventContractCreate } from "../EventContractCreate"
import { EventContractEdit } from "../EventContractEdit"
import { EventContractDrawer } from "../EventContractDrawer"

export const EventContractManager = ({
  state: { screen, event, contract },
  actions,
  navigation,
  ...props
}) => {
  const { t } = useTranslation()

  const onScreenClose = () => {
    navigation.onScreenClose()
  }

  const resetContract = () => {
    actions.resetContract()
  }

  return (
    <>
      <EventContractDrawer
        title={t("create_contract")}
        visible={screen === SCREEN.CREATE}
        onClose={onScreenClose}
      >
        <EventContractCreate
          onSuccess={onScreenClose}
          onCancel={onScreenClose}
          eventId={event?.id}
          clientId={event?.client?.id}
        />
      </EventContractDrawer>

      <EventContractEdit
        visible={screen === SCREEN.EDIT}
        onSuccess={onScreenClose}
        onDestroy={null}
        onClose={onScreenClose}
        eventId={event?.id}
      />
    </>
  )
}

EventContractManager.propTypes = {
  state: PropTypes.object,
  actions: PropTypes.object,
  navigation: PropTypes.object,
}
