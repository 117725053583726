import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"

import { MegaTagTitle } from "components"
import { PaymentMethodsContainer } from "../components"

export const PaymentMethods = props => {
  return (
    <div className={"billing-portal-payment-methods"}>
      <MegaTagTitle title={"payment_methods"} />

      <Row>
        <Col>
          <PaymentMethodsContainer />
        </Col>
      </Row>
    </div>
  )
}

PaymentMethods.propTypes = {}
