import { combineReducers } from "redux";

import data from './data/reducer';
import remove from './remove/reducer';
import edit from './edit/reducer';

const detailsReducer = combineReducers({
    data,
    remove,
    edit,
});

export default detailsReducer;