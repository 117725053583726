import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"

import { ConfirmPopup } from "../../../../../components"
import { clientRemoveProfile } from "../../../../../store/actions"

const DeleteClientComponent = props => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const modalRef = useRef(null)

  useEffect(() => {
    return () => {
      dispatch(clientRemoveProfile.cleanState())
    }
  }, [])

  const handleDelete = e => {
    e.preventDefault()

    if (props.loading) {
      return
    }

    if (modalRef.current) {
      modalRef.current.show()
    }
  }

  const handleConfirm = () => {
    dispatch(clientRemoveProfile.remove({ id: props.client.id, history }))
  }

  return (
    <React.Fragment>
      <Link to={"#"} onClick={handleDelete} className={"text-danger"}>
        {props.loading && (
          <>
            {" "}
            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2" />
          </>
        )}
        {t("btn.delete")}
      </Link>

      <ConfirmPopup ref={modalRef} onConfirm={handleConfirm} />
    </React.Fragment>
  )
}

DeleteClientComponent.propTypes = {
  client: PropTypes.object,
  loading: PropTypes.bool,
}

const mapStateToProps = state => {
  const { loading } = state.client.profile.remove
  const { data } = state.client.profile.data
  return {
    loading,
    client: data,
  }
}

export const DeleteClient = connect(mapStateToProps)(DeleteClientComponent)
