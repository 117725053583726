import { combineReducers } from "redux"

import list from "./list/slice"
import add from "./add/slice"
import update from "./update/slice"
import remove from "./remove/slice"
import groupDetails from "./groupDetails/slice"
import members from "./members/reducers"

const groupsReducer = combineReducers({
  list,
  add,
  update,
  remove,
  groupDetails,
  members,
})

export default groupsReducer
