import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { withRouter, useHistory, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"

import { API } from "../../api"
import { APP_NAME } from "../../consts"
import { MegaTagTitle } from "../../components"

import * as HOC from "../../HOC"
const DataShower = HOC.withDataShower()

const PrivacyComponent = props => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()

  const from = location.state?.from?.pathname
    ? location.state?.from?.pathname
    : "/"

  const [dataLoading, setDataLoading] = useState(false)
  const [dataContent, setDataContent] = useState(null)
  const [dataError, setDataError] = useState(null)

  const fetchData = async () => {
    setDataLoading(true)
    setDataError(null)

    try {
      const response = await API.common.getStatic("privacy")
      const { data } = response

      setDataContent(data.data.content[0])

      setDataLoading(false)
    } catch (e) {
      setDataLoading(false)
      setDataError("error")
    }
  }

  useEffect(() => {
    // fetchData();
  }, [])

  const navigate = e => {
    e.preventDefault()
    history.push(from)
  }

  return (
    <React.Fragment>
      <MegaTagTitle title={"title.privacy_policy"} />

      <div className="account-pages my-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={12} lg={12} xl={12}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="p-4">
                        <h5 className={"text-white"}>
                          {t("title.privacy_policy")}
                        </h5>
                        <Link
                          className="font-weight-medium text-white"
                          to="#"
                          onClick={navigate}
                        >
                          {from === "/"
                            ? `${t("go_to")} ${APP_NAME}`
                            : t("go_back")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody>
                  <DataShower
                    isLoading={dataLoading}
                    isFailed={dataError}
                    error={t(dataError)}
                  >
                    <iframe
                      width="100%"
                      height={"750px"}
                      src={`https://www.iubenda.com/privacy-policy/${process.env.REACT_APP_IUB_POLICY_ID}`}
                      frameBorder="0"
                    />
                  </DataShower>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                <Link className="font-weight-medium text-primary" to="/">
                  {t("go_to")} {APP_NAME}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const Privacy = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PrivacyComponent)
)
