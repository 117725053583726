import React, { useEffect } from "react"
import { ComboboxFormField, ControllerHF } from "components"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { useFetchData } from "hooks"
import { API } from "api"
import { useFormContext } from "react-hook-form"

export const ModelsSelect = ({ gender }) => {
  const { t } = useTranslation()
  const { control } = useFormContext()
  const { fetchData, loading, data, clean } = useFetchData()

  const onFetch = async () => {
    const params = {
      pagination: false,
      filters: {
        genders: gender ? [gender] : [],
      },
    }
    const request = API.model.getModelList(params)
    await fetchData({
      request,
    })
  }
  useEffect(() => {
    onFetch()
    return () => clean()
  }, [gender])

  return (
    <>
      <ControllerHF
        name={"model"}
        control={control}
        component={ComboboxFormField}
        id={"model"}
        placeholder={t("model")}
        options={data?.data}
        getOptionLabel={option => `${option.name}`}
        getOptionValue={option => option.id}
        normalize={option => (option ? option : undefined)}
        selectProps={{
          isClearable: true,
          isLoading: loading,
        }}
        disabled={loading}
      />
    </>
  )
}

ModelsSelect.propTypes = {
  gender: PropTypes.string,
  gendersValue: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object,
}
