import React from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"
import { MegaTagTitle } from "components"
import { ClientsPanelContainer } from "./components"
import { Sidebar } from "./components/Sidebar"

export const Clients = props => {
  return (
    <React.Fragment>
      <MegaTagTitle title={"title.clients"} />

      <div className="page-content page-content_sidebar">
        <div className={"page-content__container"}>
          <Container fluid>
            <ClientsPanelContainer />
          </Container>
        </div>

        <div className={"page-content__sidebar"}>
          <Sidebar />
        </div>
      </div>
    </React.Fragment>
  )
}

Clients.propTypes = {}
