import { useEffect } from "react"
import { FacebookClient } from "packages/facebook/services"

export const useFacebookInit = ({ appId, cookie, xfbml, version, src }) => {
  useEffect(() => {
    //1. Entry point
    setupFacebook()
  }, [])

  const checkInitialLoginStatus = async () => {
    //Get login status for SDK
    await FacebookClient.getLoginStatus()
  }

  const setupFacebook = async () => {
    //2. If SDK downloaded - check auth status
    if (document.getElementById("facebook-jssdk")) {
      await checkInitialLoginStatus()
    }
    //3. If SDK not downloaded
    setFbAsyncInit()
    loadSdkAsynchronously()
  }

  const setFbAsyncInit = () => {
    window.fbAsyncInit = async () => {
      window.FB.init({
        appId,
        cookie,
        xfbml,
        version,
      })
      await checkInitialLoginStatus()
    }
  }

  const loadSdkAsynchronously = () => {
    ;((d, s, id) => {
      const element = d.getElementsByTagName(s)[0]
      const fjs = element
      let js = element
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = src
      fjs.parentNode.insertBefore(js, fjs)
    })(document, "script", "facebook-jssdk")
  }
}
