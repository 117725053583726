import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Alert, Spinner } from "reactstrap"
import i18n from "i18n"

export const DataShower = ({
  isLoading,
  isFailed = false,
  error,
  children,
  spinnerSize = "lg",
  customLoaderClass = null,
  errorContainerClass = null,
  isFetched = false,
  center = true,
  ...rest
}) => {
  if (isLoading)
    return (
      <div
        className={classNames({
          "data-loader": true,
          "text-center": center,
          [customLoaderClass]: customLoaderClass,
        })}
      >
        <Spinner size={spinnerSize} color="primary" />
      </div>
    )

  if (isFailed) {
    return (
      <div className={errorContainerClass}>
        <Alert color={"danger"}>{error || i18n.t("error")}</Alert>
      </div>
    )
  }

  if (isFetched) {
    return children
  } else {
    return null
  }
}

DataShower.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool.isRequired,
  isFetched: PropTypes.bool.isRequired,
  children: PropTypes.node,
  spinnerSize: PropTypes.string,
  customLoaderClass: PropTypes.string,
  errorContainerClass: PropTypes.string,
  center: PropTypes.bool,
  error: PropTypes.string,
}
