import React from "react"
import PropTypes from "prop-types"
import placeholder from "assets/images/dev/avatar-placeholder.png"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { InfoCardListItem } from "components/InfoCard"
import { FormatUtils } from "utils"

export const EmployeeDetails = props => {
  const { t } = useTranslation()

  return (
    <div className={"d-flex flex-wrap"}>
      <div className="d-flex card-user-photo">
        <div className="user-photo-avatar__block">
          <img
            src={props.data?.avatar ? props.data.avatar : placeholder}
            alt="photo"
            className="img-cover"
          />
        </div>
      </div>
      <div className="info-list__block">
        <InfoCardListItem label={t("name")} value={props.data.name} />
        <InfoCardListItem label={t("email")} value={props.data.email} />
        <InfoCardListItem
          label={t("department")}
          value={props.data.department?.name}
        />
        <InfoCardListItem
          label={t("phone")}
          value={props.data.phone}
          normalize={value => FormatUtils.formatPhone(value)}
        />
        <InfoCardListItem
          label={t("date_added")}
          value={props.data.created_at}
          normalize={value => moment(value).format("DD/MM/YYYY")}
        />
        <InfoCardListItem
          label={t("date-of-birth")}
          value={props.data.date_of_birth}
          normalize={value => moment(value).format("DD/MM/YYYY")}
        />
        <InfoCardListItem label={t("address")} value={props.data.address} />
        <InfoCardListItem label={t("bank-name")} value={props.data.bank_name} />
        <InfoCardListItem
          label={t("bank-account-number")}
          value={props.data.bank_account_number}
        />
        <InfoCardListItem label={t("vat")} value={props.data.vat} />
        <InfoCardListItem
          label={t("other-information")}
          value={props.data.other_information}
        />
      </div>
    </div>
  )
}

EmployeeDetails.propTypes = {
  data: PropTypes.object,
}
