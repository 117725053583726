import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';

import { packageFetchData, packageFetchDataCleanState } from '../../../../store/actions';

import * as HOC from '../../../../HOC';
const DataShower = HOC.withDataShower();

const PackageDataLoaderComponent = (props) => {

    useEffect(() => {
        fetchData();

        return () => {
            props.packageFetchDataCleanState();
        };
    }, []);

    const fetchData = () => {
        props.packageFetchData(props.id);
    };

    return (
        <DataShower isLoading={props.loading} isFailed={props.error} error={props.error}>
            {props.data && (
                props.children
            )}
        </DataShower>
    );
};

PackageDataLoaderComponent.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    children: PropTypes.any,

    data: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.any,

    packageFetchData: PropTypes.func,
    packageFetchDataCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { data, loading, error } = state.package.details.data;

    return {
        data,
        loading,
        error,
    };
};

const mapDispatchToProps = {
    packageFetchData,
    packageFetchDataCleanState,
};

export const PackageDataLoader = connect(mapStateToProps, mapDispatchToProps)(PackageDataLoaderComponent);