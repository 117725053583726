import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import { ExpensesControl } from "../../Controls"
import { FieldError } from "../FieldError"

export const ExpensesField = ({
  id,
  disabled,
  value = [],
  meta = {},
  translateParams,
  className,
  ...props
}) => {
  const { error } = meta
  const showError = Boolean(error)
  const { t } = useTranslation()

  return (
    <div className={classNames("", { [className]: className })}>
      <div
        className={classNames("", {
          "is-invalid": showError,
        })}
      >
        <ExpensesControl
          {...props}
          value={value}
          onChange={props.onChange}
          currency={props.currency}
        />
      </div>

      <FieldError
        error={error}
        translateParams={translateParams}
        showError={showError}
      />
    </div>
  )
}

ExpensesField.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  translateParams: PropTypes.object,
  value: PropTypes.any,
  meta: PropTypes.object,
  className: PropTypes.string,
  currency: PropTypes.string,
}
