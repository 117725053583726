import {
    MODEL_FETCH_VIDEO,
    MODEL_FETCH_VIDEO_SUCCESS,
    MODEL_FETCH_VIDEO_ERROR,
    MODEL_FETCH_VIDEO_CLEAN_STATE,
} from './actionTypes';

export const modelFetchVideo = (id) => {
    return {
        type: MODEL_FETCH_VIDEO,
        payload: { id },
    }
};

export const modelFetchVideoSuccess = (data) => {
    return {
        type: MODEL_FETCH_VIDEO_SUCCESS,
        payload: data,
    }
};

export const modelFetchVideoError = (data) => {
    return {
        type: MODEL_FETCH_VIDEO_ERROR,
        payload: data,
    }
};

export const modelFetchVideoCleanState = () => {
    return { type: MODEL_FETCH_VIDEO_CLEAN_STATE };
};