import _ from "lodash"

export const getBillingAddress = data => {
  let address = null

  if (data && data?.addresses && data?.addresses?.length) {
    let addresses = [...data.addresses]

    // sort by main address
    addresses.sort((a, b) =>
      a.main_type === b.main_type ? 0 : a.main_type ? -1 : 1
    )

    address = _.find(addresses, item => item.billing_type)
  }

  return address
}
