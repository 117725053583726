import { createAction } from "@reduxjs/toolkit"

const SOCKET_ON_CHAT_GROUP_REMOVED = "socket/onChatGroupRemoved"

export const socketOnChatGroupRemoved = createAction(
  SOCKET_ON_CHAT_GROUP_REMOVED,
  function prepare(data) {
    return {
      payload: {
        data,
      },
    }
  }
)
