import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useWindowSize } from "react-use"

import { withRouter } from "react-router-dom"
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  layoutChangeBodyClass,
} from "store/actions"

// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"
import Rightbar from "../CommonForBoth/RightSidebar"
import Footer from "./Footer"
import { SidebarDrawer } from "./SidebarDrawer"

//redux
import { useDispatch, useSelector } from "react-redux"

import { BILLING_SUBSCRIPTION_STATUS_TRIALING, ROLE_DIRECTOR } from "consts"
import { TrialPeriodAlert } from "components"

const windowWidth = 992

const Layout = props => {
  const dispatch = useDispatch()

  const { width } = useWindowSize()

  const {
    isPreloader,
    leftSideBarThemeImage,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    showRightSidebar,
    leftSideBarTheme,
    user,
  } = useSelector(state => ({
    isPreloader: state.Layout.isPreloader,
    leftSideBarThemeImage: state.Layout.leftSideBarThemeImage,
    leftSideBarType: state.Layout.leftSideBarType,
    layoutWidth: state.Layout.layoutWidth,
    topbarTheme: state.Layout.topbarTheme,
    showRightSidebar: state.Layout.showRightSidebar,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
    user: state.user.profile?.data,
  }))

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile))
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile))
    }
  }

  /*
  layout  settings
  */
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    dispatch(changeLayout("vertical"))
  }, [dispatch])

  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme))
    }
  }, [leftSideBarTheme, dispatch])

  useEffect(() => {
    if (leftSideBarThemeImage) {
      dispatch(changeSidebarThemeImage(leftSideBarThemeImage))
    }
  }, [leftSideBarThemeImage, dispatch])

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth))
    }
  }, [layoutWidth, dispatch])

  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType))
    }
  }, [leftSideBarType, dispatch])

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme))
    }
  }, [topbarTheme, dispatch])

  // === Sidebar drawer === //
  const [sidebarDrawerVisible, setSidebarDrawerVisible] = useState(false)

  const toggleSidebarDrawerVisible = forceStatus => {
    if (forceStatus !== undefined) {
      setSidebarDrawerVisible(forceStatus)
    } else {
      setSidebarDrawerVisible(prevStatus => !prevStatus)
    }
  }
  // === //

  // === Sidebar enable === //
  const onSidebarToggle = () => {
    if (window.innerWidth < 992) {
      toggleSidebarDrawerVisible(true)
    } else {
      dispatch(layoutChangeBodyClass("vertical-collpsed"))
    }
  }
  // === //

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header
          toggleMenuCallback={toggleMenuCallback}
          onSidebarToggle={onSidebarToggle}
        />

        {width >= windowWidth ? (
          <Sidebar
            theme={leftSideBarTheme}
            type={leftSideBarType}
            isMobile={isMobile}
          />
        ) : (
          <SidebarDrawer
            visible={sidebarDrawerVisible}
            toggleVisible={toggleSidebarDrawerVisible}
          />
        )}
        <div className="main-content">
          {user?.subscription?.status ===
            BILLING_SUBSCRIPTION_STATUS_TRIALING &&
            user?.role === ROLE_DIRECTOR && <TrialPeriodAlert />}

          {props.children}
        </div>
        {/*<Footer />*/}
      </div>
      {showRightSidebar ? <Rightbar /> : null}
    </React.Fragment>
  )
}

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.node,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
}

export default withRouter(Layout)
