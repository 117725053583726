import { useState } from "react"
import { useFacebookContext } from "./useFacebookContext"
import { FacebookClient } from "../index"
import { API } from "api"
import { useCookies } from "react-cookie"
import moment from "moment"
import {
  selectFBPages,
  selectInstagramAccounts,
} from "packages/facebook/helpers"

export const useFacebookAuth = () => {
  const {
    removeAccessToken,
    updateAccessToken,
    isAccessToken,
    account,
    updateAccount,
    removeAccount,
  } = useFacebookContext()

  const [loading, setLoading] = useState(false)

  const [cookies, setCookie, removeCookie] = useCookies([
    "fb_long_token",
    "fb_machine_id",
  ])

  const setAuthFBCookie = (token, machineId, expire) => {
    let expiry_date = expire ? moment(expire).toDate() : undefined

    setCookie("fb_long_token", token, {
      secure: true,
      maxAge: 2592000,
      expires: expiry_date, // 30 days max exist
    })

    setCookie("fb_machine_id", machineId, {
      secure: true,
      maxAge: 2678400, // 31 days
      expires: expiry_date,
    })
  }

  const checkFacebookLoginStatus = async () => {
    try {
      setLoading(true)

      //Get account from server
      const response = await API.socialAccount.getFacebookAccount()
      const { data } = response

      //If data empty array  -> remove token data local
      if (Array.isArray(data) && data?.length === 0) {
        removeAccessToken()
        return
      }

      //Generate client long token using client_code from server
      if (data && data?.client_code) {
        updateAccount(data)
        //Take access token from cookie if exists (30 days)
        if (cookies.fb_long_token) {
          updateAccessToken(cookies.fb_long_token)
          return
        }

        const tokenData = await FacebookClient.getLongAccessToken(
          data.client_code,
          cookies.fb_machine_id
        )

        setAuthFBCookie(
          tokenData?.access_token,
          tokenData?.machine_id,
          data?.expiry_date
        )
        updateAccessToken(tokenData?.access_token)
      }
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  const loginToFB = async permissions => {
    try {
      setLoading(true)

      const facebook_data = await FacebookClient.loginToFB(permissions)

      const response = await API.socialAccount.addFacebookAccount({
        token: facebook_data.accessToken,
      })

      const { data } = response

      updateAccount(data)

      const tokenData = await FacebookClient.getLongAccessToken(
        data.client_code
      )

      setAuthFBCookie(
        tokenData?.access_token,
        tokenData?.machine_id,
        data?.expiry_date
      )

      updateAccessToken(tokenData?.access_token)
    } catch (error) {
      if (error?.status === "not_authorized") {
        removeAccessToken()
        removeAccount()
      }
    } finally {
      setLoading(false)
    }
  }

  const disconnectFB = async () => {
    setLoading(true)
    try {
      await API.socialAccount.removeFacebookAccount()
      removeAccessToken()
      removeCookie("fb_long_token")
      removeCookie("fb_machine_id")
      removeAccount()
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  return {
    loading: loading,
    isLoggedIn: isAccessToken,
    account,
    loginToFB,
    disconnectFB,
    checkFacebookLoginStatus,
    pages: selectFBPages(account),
    instagramAccounts: selectInstagramAccounts(account),
  }
}
