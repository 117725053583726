import { combineReducers } from "redux";

import remove from './delete/reducer';
import modelsSidebar from './modelsSidebar/reducer';

const panelReducer = combineReducers({
    remove,
    modelsSidebar,
});

export default panelReducer;