import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { DrawerContainer } from "components"
import { StatementDataLoader } from "../StatementDataLoader"
import { StatementForm } from "../StatementForm"
import { useSubmitHandler } from "hooks"
import { clientStatementEditSubmit } from "store/actions"
import { STATEMENT_PAYMENT_STATUS } from "consts"

export const EditStatement = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const {
    statement,
    submit: { loading, error },
  } = useSelector(state => ({
    statement: state.client.statement.edit.data.data,
    submit: state.client.statement.edit.submit,
  }))

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      dispatch(
        clientStatementEditSubmit.submit({
          id: props.id,
          params,
          onSuccess,
          onError,
        })
      )
    },
    data => {
      // if status changed and need update total paid amount
      if (
        data?.status !== statement?.status &&
        [data?.status, statement?.status].includes(
          STATEMENT_PAYMENT_STATUS.PAID
        )
      ) {
        props.onStatementsUpdate()
      }

      props.onSuccess()
    }
  )

  const onDestroy = () => {
    dispatch(clientStatementEditSubmit.cleanState())
    props.onDestroy && props.onDestroy()
  }

  return (
    <DrawerContainer
      title={t("statement")}
      visible={props.visible}
      onClose={props.onClose}
      onDestroy={onDestroy}
    >
      <StatementDataLoader id={props.id}>
        <StatementForm
          loading={loading}
          error={error}
          onSubmit={handleSubmit}
          onCancel={props.onClose}
          initialValues={{
            ...statement,
          }}
          settings={props.settings}
        />
      </StatementDataLoader>
    </DrawerContainer>
  )
}

EditStatement.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDestroy: PropTypes.func,
  onSuccess: PropTypes.func,
  id: PropTypes.number,
  settings: PropTypes.object,
  onStatementsUpdate: PropTypes.func,
}
