import React, { useState } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';

import { ContactsInfoCard } from './ContactsInfoCard';
import { EditContacts } from './EditContacts';

const ContactsContainerComponent = (props) => {
    const [editDrawerVisible, setEditDrawerVisible] = useState(false);

    const handleEdit = () => {
        setEditDrawerVisible(true)
    };

    const closeEditDrawer = () => {
        setEditDrawerVisible(false);
    };

    return (
        <>
            <ContactsInfoCard
                onEdit={handleEdit}
                data={props.model}
            />

            <EditContacts visible={editDrawerVisible} onClose={closeEditDrawer} />
        </>
    );
};

ContactsContainerComponent.propTypes = {
    model: PropTypes.object,
}

const mapStateToProps = state => {
    const { data } = state.model.profile;
    return {
        model: data,
    };
};

const mapDispatchToProps = {
};

export const ContactsContainer = connect(mapStateToProps, mapDispatchToProps)(ContactsContainerComponent);