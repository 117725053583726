import React, { useMemo } from 'react'
import PropTypes from "prop-types"
import { Col, Alert, Row } from "reactstrap"
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

import {
    Button,
    ControllerHF,
    InputField,
} from '../../../../../../components'
import { validationSchema } from './validation';

export const VideoForm = (props) => {
    const { t } = useTranslation();

    const { control, handleSubmit, setError, reset } = useForm({
        defaultValues: {
            url: '',
            title: '',
            description: '',
            ...props.initialValues,
        },
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = (values) => {
        const params = {
            ...values,
        }

        props.onSubmit(params, { reset, setError });
    };

    const onCancel = () => {
        props.onCancel();
    }

    return (
        <>
            {props.error && (
                <Alert color="danger">
                    {props.error.message}
                </Alert>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={8}>
                        <ControllerHF
                            name={'url'}
                            control={control}
                            component={InputField}
                            id={'url'}
                            label={t('link')}
                            placeholder={t('link')}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={8}>
                        <ControllerHF
                            name={'title'}
                            control={control}
                            component={InputField}
                            id={'title'}
                            label={t('title')}
                            placeholder={t('title')}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={8}>
                        <ControllerHF
                            name={'description'}
                            control={control}
                            component={InputField}
                            id={'description'}
                            label={t('description')}
                            placeholder={t('description')}
                            type={'textarea'}
                        />
                    </Col>
                </Row>

                <div className="mt-3">
                    <div className="button-items">
                        <Button
                            submit
                            title={'btn.save'}
                            disabled={props.loading}
                            loading={props.loading}
                        />

                        <Button
                            title={'cancel'}
                            color="light"
                            outline
                            onClick={onCancel}
                        />
                    </div>
                </div>
            </form>

        </>
    );
};

VideoForm.propTypes = {
    initialValues: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.object,

    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
}