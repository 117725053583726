import React from "react"
import PropTypes from "prop-types"
import { IconButton, MediaOverviewCard, Image } from "components"
import { ModelUtils } from "utils"

export const ModelBookOverviewCard = ({ item, onRemove }) => {
  const renderActions = item => {
    return (
      <>
        <IconButton
          name={"remove"}
          bg
          onClick={() => onRemove(item?.published_book)}
        />
      </>
    )
  }
  const renderContent = item => {
    const path = item?.published_book?.cover?.photo?.attachment?.src
    const crops = item?.published_book?.cover?.photo?.crops

    return (
      <Image src={path} objectPosition={"top"} alt={item.name} crops={crops} />
    )
  }
  return (
    <MediaOverviewCard
      actions={renderActions(item)}
      title={`${item.name}, ${ModelUtils.getAge(item.birthday)}`}
      content={renderContent(item)}
    />
  )
}

ModelBookOverviewCard.propTypes = {
  item: PropTypes.object,
  onRemove: PropTypes.func,
}
