import _ from "lodash"

import i18n from '../../i18n';
import {
    MODEL_AGENCY_AS_TYPE_MOTHER,
    MODEL_AGENCY_AS_TYPE_OTHER,
    MODEL_AGENCY_AS_TYPE_REFUSED,
    MODEL_AGENCY_AS_TYPE_PROPOSAL,
    MODEL_AGENCY_AS_TYPE_DROPPED_MOTHER,
    MODEL_AGENCY_AS_TYPE_DROPPED,
    MODEL_AGENCY_AS_TYPE_LEFT,

    MODEL_AGENCY_PROFIT_TYPE_GROSS,
    MODEL_AGENCY_PROFIT_TYPE_NET_FOR_MODEL,
} from '../../consts';

export const getAgencyAsOptions = () => {
    return [{
        label: i18n.t('model-agency-as-type.mother'),
        value: MODEL_AGENCY_AS_TYPE_MOTHER,
    }, {
        label: i18n.t('model-agency-as-type.other'),
        value: MODEL_AGENCY_AS_TYPE_OTHER,
    }, {
        label: i18n.t('model-agency-as-type.refused'),
        value: MODEL_AGENCY_AS_TYPE_REFUSED,
    }, {
        label: i18n.t('model-agency-as-type.proposal'),
        value: MODEL_AGENCY_AS_TYPE_PROPOSAL,
    }, {
        label: i18n.t('model-agency-as-type.dropped_mother'),
        value: MODEL_AGENCY_AS_TYPE_DROPPED_MOTHER,
    }, {
        label: i18n.t('model-agency-as-type.dropped'),
        value: MODEL_AGENCY_AS_TYPE_DROPPED,
    }, {
        label: i18n.t('model-agency-as-type.left'),
        value: MODEL_AGENCY_AS_TYPE_LEFT,
    }];
};

export const getAgencyAsOption = (value) => {
    const options = getAgencyAsOptions();
    return _.find(options, { value: value });
};

export const getAgencyProfitTypeOptions = () => {
    return [{
        label: i18n.t('model-agency-profit-type.gross'),
        value: MODEL_AGENCY_PROFIT_TYPE_GROSS,
    }, {
        label: i18n.t('model-agency-profit-type.net_for_model'),
        value: MODEL_AGENCY_PROFIT_TYPE_NET_FOR_MODEL,
    }];
};

export const getAgencyProfitTypeOption = (value) => {
    const options = getAgencyProfitTypeOptions();
    return _.find(options, { value: value });
};