import { eventsCalendarHelper } from "helpers"

export const mapCompanyData = companyData => {
  return eventsCalendarHelper.contract.mapCompanyData(companyData, {
    isModel: true,
  })
}

export const mapModelData = model => {
  let result = {
    client_information: "",
    city: "",
  }

  const mapInfo = () => {
    const newLine = `\n`

    const { name, surname, other_information, vat, fiscal_code } = model

    let value = name
    if (surname) {
      value += ` ${surname}`
    }

    if (vat) {
      value += `${newLine}P.IVA / VAT .: ${vat}`
    }

    if (fiscal_code) {
      value += `${newLine}Fiscal code: ${fiscal_code}`
    }

    if (other_information) {
      value += `${newLine}${other_information}`
    }

    return value
  }

  result.client_information = mapInfo()

  return result
}
