import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

import { IconButton, InfoCard, InfoCardListItem, Image } from "components"
import placeholderIcon from "assets/images/dev/icon-photo-placeholder.svg"

export const ProfileInfoCard = ({ data, onEdit, onAvatarEdit }) => {
  const { t } = useTranslation()

  const getWebsiteValue = value => {
    return (
      <a
        href={value}
        target={"_blank"}
        rel="noreferrer"
        className={"text-secondary"}
      >
        {t("open")}
      </a>
    )
  }

  return (
    <InfoCard
      onEdit={onEdit}
      editMode
      title={t("general")}
      className={"client-general-info"}
    >
      <div className={"mb-2 d-flex flex-wrap"}>
        <div className={"card-logo-photo"}>
          <div className="card-logo-photo__block">
            <Image src={data?.avatar?.src || placeholderIcon} alt="logo" />
            <div className={"card-logo-photo__edit-block"}>
              <IconButton name={"edit"} onClick={onAvatarEdit} bg />
            </div>
          </div>
        </div>
        <div className={"align-self-center"}>
          <InfoCardListItem
            label={t("category")}
            value={data.category?.value}
          />

          {data.website && data.website !== "" && (
            <InfoCardListItem
              value={data.website}
              label={t("website")}
              normalize={getWebsiteValue}
            />
          )}
        </div>
      </div>
      <Row>
        <Col>
          <div className={"info-list__block"}>
            <InfoCardListItem label={t("booker")} value={data.booker?.name} />
          </div>
        </Col>

        <Row>
          <Col>
            <div className={"info-list__block"}>
              <InfoCardListItem label={t("note")} value={data.note} />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={"info-list__block"}>
              <InfoCardListItem label={t("vat")} value={data.vat} />
              <InfoCardListItem label={t("bank-name")} value={data.bank_name} />
              <InfoCardListItem
                label={t("bank-account-number")}
                value={data.bank_account_number}
              />
              <InfoCardListItem label={t("swift")} value={data.swift_code} />
              <InfoCardListItem
                label={t("other-information")}
                value={data.other_information}
              />
            </div>
          </Col>
        </Row>
      </Row>
    </InfoCard>
  )
}

ProfileInfoCard.propTypes = {
  data: PropTypes.object,
  onEdit: PropTypes.func,
  onAvatarEdit: PropTypes.func,
}
