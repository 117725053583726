import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useParams, useRouteMatch, generatePath } from "react-router-dom"

import { PanelMenu } from "components"
import { DEPARTMENTS_PERMISSIONS } from "consts"
import { useDepartmentPermissionGuard } from "hooks"

export const Nav = () => {
  const { t } = useTranslation()

  let { path } = useRouteMatch()
  const { id } = useParams()

  const { status: allowed } = useDepartmentPermissionGuard({
    permission: DEPARTMENTS_PERMISSIONS.CLIENT_STATEMENT_READ,
  })

  const getPath = route => generatePath(`${path}/${route}`, { id: id })

  const menu = useMemo(() => {
    const items = [
      {
        key: "general",
        route: getPath("general"),
        title: t("general"),
      },
      {
        key: "calendar",
        route: getPath("calendar"),
        title: t("calendar"),
      },
      {
        key: "statement",
        route: getPath("statement"),
        title: t("statement"),
        hidden: !allowed,
      },
      {
        key: "activities",
        route: getPath("activities"),
        title: t("activities"),
      },
      // {
      //   key: "prices",
      //   route: getPath("prices"),
      //   title: t("prices"),
      // },
      {
        key: "rating",
        route: getPath("rating"),
        title: t("rating"),
        badge: null,
      },
      {
        key: "history",
        route: getPath("history"),
        title: t("history"),
      },
    ]

    return items.filter(item => !item.hidden)
  }, [getPath, allowed])

  return <PanelMenu items={menu} />
}

Nav.propTypes = {}
