import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Drawer } from "../../../../../../components"
import { Container, Row } from "reactstrap"
import { ValidationsUtils } from "../../../../../../utils"
import { AddressForm } from "../AddressForm"
import { useTranslation } from "react-i18next"
import { AddressDataLoader } from "../AddressDataLoader"
import { agencyUpdateAddress } from "../../../../../../store/actions"

const EditAddressContainerComponent = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const handleCloseDrawer = () => {
        props.onClose()
    }
    const onSubmitSuccess = onSubmitProps => () => {
        const { reset } = onSubmitProps
        reset()
        props.onEditSuccess()
        handleCloseDrawer()
    }

    const onSubmitError = onSubmitProps => errors => {
        const { setError } = onSubmitProps
        ValidationsUtils.setServerSideErrors(errors, setError)
    }

    const handleSubmit = (params, onSubmitProps) => {
        dispatch(
            agencyUpdateAddress.update({
                id: props.id,
                params,
                onSuccess: onSubmitSuccess(onSubmitProps),
                onError: onSubmitError(onSubmitProps),
            })
        )
    }

    const onDestroy = () => {
        dispatch(agencyUpdateAddress.cleanState())
        props.onDestroy()
    }

    return (
        <>
            <Drawer
                title={t("address")}
                visible={props.visible}
                onClose={handleCloseDrawer}
                placement={"right"}
                className={"drawer-container"}
                destroyOnClose
                onDestroy={onDestroy}
            >
                <AddressDataLoader id={props.id}>
                    <Container fluid>
                        <Row>
                            <AddressForm
                                onSubmit={handleSubmit}
                                onCancel={handleCloseDrawer}
                                initialValues={{
                                    ...props.address,
                                }}
                                loading={props.loading}
                                error={props.error}
                            />
                        </Row>
                    </Container>
                </AddressDataLoader>
            </Drawer>
        </>
    )
}

EditAddressContainerComponent.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    loading: PropTypes.bool,
    error: PropTypes.object,
    address: PropTypes.object,
    onDestroy: PropTypes.func,
    onEditSuccess: PropTypes.func,
}

const mapStateToProps = state => {
    const { data } = state.agency.addresses.data
    const { loading, error } = state.agency.addresses.update

    return {
        address: data,
        loading,
        error,
    }
}

export const EditAddress = connect(mapStateToProps)(
    EditAddressContainerComponent
)
