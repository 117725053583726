import { all } from "redux-saga/effects"

import dataSaga from "./data/saga"
import editSaga from "./edit/saga"
import urlSaga from "./url/sagas"
import statisticsSaga from "./statistics/sagas"

function* detailsSaga() {
  yield all([dataSaga(), editSaga(), urlSaga(), statisticsSaga()])
}

export default detailsSaga
