import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Expenses } from "./Expenses"

export const ExpensesContainer = props => {
  const { t } = useTranslation()

  return (
    <>
      <Expenses data={props.event?.fashion_models} />
    </>
  )
}

ExpensesContainer.propTypes = {
  event: PropTypes.object,
}
