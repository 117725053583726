import {
    PACKAGE_DRAFT_DATA_COLLECTIONS_FETCH_VIDEOS,
    PACKAGE_DRAFT_DATA_COLLECTIONS_FETCH_VIDEOS_SUCCESS,
    PACKAGE_DRAFT_DATA_COLLECTIONS_FETCH_VIDEOS_ERROR,
    PACKAGE_DRAFT_DATA_COLLECTIONS_FETCH_VIDEOS_CLEAN_STATE,

    PACKAGE_DRAFT_DATA_COLLECTIONS_VIDEOS_SET_PAGE,
} from './actionTypes'

export const packageDraftDataCollectionsFetchVideos = (id, params) => {
    return {
        type: PACKAGE_DRAFT_DATA_COLLECTIONS_FETCH_VIDEOS,
        payload: { id, params },
    }
};

export const packageDraftDataCollectionsFetchVideosSuccess = (data) => {
    return {
        type: PACKAGE_DRAFT_DATA_COLLECTIONS_FETCH_VIDEOS_SUCCESS,
        payload: data,
    }
};

export const packageDraftDataCollectionsFetchVideosError = (data) => {
    return {
        type: PACKAGE_DRAFT_DATA_COLLECTIONS_FETCH_VIDEOS_ERROR,
        payload: data,
    }
};

export const packageDraftDataCollectionsFetchVideosCleanState = () => {
    return { type: PACKAGE_DRAFT_DATA_COLLECTIONS_FETCH_VIDEOS_CLEAN_STATE };
};

export const packageDraftDataCollectionsVideosSetPage = (data) => {
    return {
        type: PACKAGE_DRAFT_DATA_COLLECTIONS_VIDEOS_SET_PAGE,
        payload: data,
    }
};