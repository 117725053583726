import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { Link } from "react-router-dom"

import paperclipIcon from "../../../assets/images/dev/file-paperclip-icon.svg"
import { FieldError } from "../FieldError"
import { FilesUploaderFileField } from "./FilesUploaderFileField"

export const FilesUploaderField = ({
  id,
  label,
  pickerLabelText = "attach-file",
  className,
  fields,
  meta = {},
  accept,
  onRemove,
  onAdd,
  onUpload,
  onDownload,
  name = "files",
  fileFieldName = "file",
  limit,
  disabled,
  processing,
  picker = true,
  ...props
}) => {
  const { error } = meta

  const showError = Boolean(error)

  const { t } = useTranslation()

  const inputRef = useRef()

  const handleFilesPicked = event => {
    const files = event.target.files

    onAdd(files)

    if (inputRef.current) {
      inputRef.current.value = null
    }
  }

  const pickFiles = event => {
    event.preventDefault()

    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const renderPicker = () => (
    <div className={"files-uploader-field__picker"}>
      <input
        type="file"
        id={id}
        className="d-none"
        onChange={handleFilesPicked}
        ref={inputRef}
        accept={accept}
        multiple
      />

      <Link
        to="#"
        className="text-dark text-decoration-underline files-uploader-field__picker-btn"
        onClick={pickFiles}
      >
        <img
          src={paperclipIcon}
          alt={"pick"}
          className={"files-uploader-field__picker-btn-icon"}
        />

        {pickerLabelText}
      </Link>
    </div>
  )

  const removeFile = (field, fieldIndex) => {
    const fieldName = getFileFieldName(field, fieldIndex)
    onRemove(field, fieldIndex, fieldName)
  }

  const uploadFile = (field, fieldIndex) => {
    const fieldName = getFileFieldName(field, fieldIndex)
    onUpload(field, fieldIndex, fieldName)
  }

  const getFileFieldName = (item, index) => `${name}.${index}.${fileFieldName}`

  return (
    <div
      className={classNames("files-uploader-field", {
        [className]: className,
      })}
    >
      <div
        className={classNames("d-flex w-100", {
          "is-invalid": showError,
        })}
      >
        <div className={"files-uploader-field__block"}>
          <div className={"files-uploader-field__files-block"}>
            {!!fields.length &&
              fields.map((item, index) => (
                <div
                  className={"files-uploader-field__file-col"}
                  key={item?.fieldId}
                >
                  <div className={"files-uploader-field__file-item"}>
                    {/* make custom field */}
                    {props.renderFileField ? (
                      props.renderFileField({
                        id: getFileFieldName(item, index),
                        name: getFileFieldName(item, index),
                        onRemove: () => removeFile(item, index),
                        onUpload: () => uploadFile(item, index),
                        withDownload: Boolean(item?.id),
                        onDownload: () => onDownload(item),
                        loading: processing.includes(item?.fieldId),
                        item: item,
                      })
                    ) : (
                      <FilesUploaderFileField
                        value={item.file}
                        id={getFileFieldName(item, index)}
                        name={getFileFieldName(item, index)}
                        onRemove={() => removeFile(item, index)}
                        onUpload={() => uploadFile(item, index)}
                        withDownload={Boolean(item?.id)}
                        onDownload={() => onDownload(item)}
                        loading={processing.includes(item?.fieldId)}
                        onValidate={props.onValidate}
                        item={item}
                        meta={{ error }}
                      />
                    )}
                  </div>
                </div>
              ))}
          </div>

          {picker
            ? limit
              ? fields.length < limit && renderPicker()
              : renderPicker()
            : null}
        </div>
      </div>

      {/*<FieldError error={error} translateParams={props.translateParams} showError={showError} className={'mt-2'} />*/}
    </div>
  )
}

FilesUploaderField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  pickerLabelText: PropTypes.string,
  className: PropTypes.string,
  translateParams: PropTypes.object,
  fields: PropTypes.any,
  meta: PropTypes.object,
  accept: PropTypes.string,
  onRemove: PropTypes.func,
  onAdd: PropTypes.func,
  onUpload: PropTypes.func,
  onDownload: PropTypes.func,
  name: PropTypes.string,
  fileFieldName: PropTypes.string,
  limit: PropTypes.number,
  disabled: PropTypes.bool,
  processing: PropTypes.array,
  customFileField: PropTypes.any,
  renderFileField: PropTypes.func,
  onValidate: PropTypes.func,
  picker: PropTypes.bool,
}
