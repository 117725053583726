import React from 'react'
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames";

export const PackageDataGrid = (props) => {
    const { t } = useTranslation();

    return (
        <div className={classNames('package-data-grid', {
            [props.className]: props.className,
        })}>
            {props.items && !!props.items.length ? (
                <div className="grid">
                    <div className="grid__row">
                        {props.items.map((item, i) => (
                            <div className="grid__col" key={`package-data-grid-${item.id}`}>
                                <div className="grid__item">
                                    {props.renderItem(item)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                props.empty ? props.empty : t('empty')
            )}
        </div>
    );
};

PackageDataGrid.propTypes = {
    items: PropTypes.array,
    empty: PropTypes.any,
    className: PropTypes.string,
    renderItem: PropTypes.func.isRequired,
}