import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Alert } from "reactstrap"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { OverlayBlockLoading } from "components/OverlayLoading"
import { EventsCalendar } from "components/Calendars/EventsCalendar"
import { eventsCalendarHelper } from "helpers"
import { useCompareEventsCalendarContext } from "../../../contexts"

export const CompareEventsCalendarControl = props => {
  const { t } = useTranslation()

  const compareEventsCalendarContext = useCompareEventsCalendarContext()

  const {
    state: { period },
  } = compareEventsCalendarContext

  const [startDate, endDate] = period

  const validRange = useMemo(() => {
    return {
      start: moment(startDate).startOf("day").toDate(),
      end: moment(endDate).endOf("day").toDate(),
    }
  }, [startDate, endDate])

  return (
    <>
      {props.calendarError && (
        <Alert color={"danger"}>{props.calendarError || t("error")}</Alert>
      )}

      {/* Calendar */}
      <OverlayBlockLoading isLoading={props.calendarLoading}>
        <EventsCalendar
          eventsToolbar={false}
          events={props.calendarEvents}
          onDatesSet={props.onCalendarDatesSet}
          onEventDidMount={info =>
            eventsCalendarHelper.calendar.onCalendarEventUpdateElement(
              info,
              props.eventColors
            )
          }
          renderEventContent={props.renderCalendarEventContent}
          eventOrder={props.calendarEventOrder}
          fullcalendarProps={{
            headerToolbar: {
              left: "prev,next",
              center: "title",
              right: "dayGridMonth,dayGridWeek,dayGridDay",
            },
            validRange,
          }}
        />
      </OverlayBlockLoading>
    </>
  )
}

CompareEventsCalendarControl.propTypes = {
  calendarLoading: PropTypes.bool,
  calendarError: PropTypes.any,
  calendarEvents: PropTypes.array,
  onCalendarDatesSet: PropTypes.func,
  entity: PropTypes.string,
  entityData: PropTypes.object,
  renderCalendarEventContent: PropTypes.func,
  calendarEventOrder: PropTypes.any,
  eventColors: PropTypes.object,
}
