import { takeLatest, fork, put, all, call } from "redux-saga/effects"
import { websiteModelsList } from "./slice"
import { RequestUtils } from "utils"
import { API } from "api"
import i18n from "i18n"

export function* getList({ payload: { params } }) {
  try {
    let requestParams = {
      includes: [],
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response = yield call(API.model.getModelList, requestParams)

    const { data } = response
    const {
      meta: { current_page, last_page },
    } = data
    if (current_page && last_page && current_page > last_page) {
      yield put(
        websiteModelsList.getList({
          params: {
            ...params,
            page: last_page,
          },
        })
      )
    } else {
      yield put(websiteModelsList.getListCompleted(data))
    }
  } catch (error) {
    const message = error.response?.data?.message || i18n.t("error")
    yield put(websiteModelsList.getListRejected(message))
  }
}

export function* watchGetList() {
  yield takeLatest(websiteModelsList.getList, getList)
}

function* listSaga() {
  yield all([fork(watchGetList)])
}

export default listSaga
