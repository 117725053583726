import React from 'react';
import PropTypes from 'prop-types'
import { Switch, BrowserRouter as Router, Route, Redirect, useLocation } from "react-router-dom"
import { connect } from "react-redux"

import { publicRoutes, utilityRoutes } from "../routes/allRoutes";
import { PrivateRoute, CommonRoute } from "../routes/middleware";
import { AuthIndex, Panel } from '../pages';
import { BlankLayout } from '../components';

export const RouteSwitch = (props) => {
    let location = useLocation();

    let path = '';

    if (props.isLoggedIn) {
        path = '/calendar';
    } else {
        path = '/login';
    }

    return (
        <Redirect to={{ ...location, pathname: path}} />
    )
};

RouteSwitch.propTypes = {
    isLoggedIn: PropTypes.bool,
}

const RootNavigatorComponent = (props) => {
    return (
        <Router>
            <Switch>
                <Route exact path={'/'}>
                    <RouteSwitch isLoggedIn={props.isLoggedIn} />
                </Route>

                <Route {...props}
                       path={[
                           ...publicRoutes.map(item => item.path),
                       ]}
                >
                    <AuthIndex {...props} />
                </Route>

                {utilityRoutes.map((route, idx) => (
                    <CommonRoute path={route.path} layout={BlankLayout} component={route.component} key={idx}/>
                ))}

                <PrivateRoute
                    path={'/'}
                    component={Panel}
                />
            </Switch>
        </Router>
    );
};

RootNavigatorComponent.propTypes = {
    isLoggedIn: PropTypes.bool,
}

const mapStateToProps = state => {
    const { isLoggedIn } = state.auth.data;
    return { isLoggedIn };
};

const mapDispatchToProps = {
};

export const RootNavigator = connect(mapStateToProps, mapDispatchToProps)(RootNavigatorComponent);