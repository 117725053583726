import React from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"
import { analyticsHelper } from "helpers"

export const LineChart = ({
  seriesData,
  optionsData,
  height = "200",
  loading,
  error,
  isData,
  tooltipLabel,
}) => {
  const defaultOptions = {
    chart: {
      toolbar: {
        tools: {
          download: false,
          selection: false,
          pan: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
        },
      },
    },
    dataLabels: { enabled: false },
    stroke: { width: [3, 3] },
    markers: { style: "inverted", size: 6 },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeFormatter: {
          month: "MMM 'yyyy",
          day: "dd MMM",
          hour: "HH:mm",
        },
      },
    },
    tooltip: {
      x: {
        format: "dd MMM yy",
      },
      y: {
        title: {
          formatter: () => {
            return tooltipLabel
          },
        },
      },
    },
    yaxis: {
      labels: {
        show: isData,
        minWidth: 40,
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: "top",
      horizontalAlign: "left",
      markers: {
        radius: 0,
        offsetX: "-7px",
        offsetY: "1px",
      },
      itemMargin: {
        horizontal: 21,
        vertical: 0,
      },
    },
    noData: analyticsHelper.options.noDataOptions(loading, error),
  }

  return (
    <ReactApexChart
      options={{ ...defaultOptions, ...optionsData }}
      series={seriesData}
      type={"line"}
      height={height}
    />
  )
}

LineChart.propTypes = {
  optionsData: PropTypes.object,
  seriesData: PropTypes.array,
  type: PropTypes.string,
  height: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  isData: PropTypes.bool,
  tooltipLabel: PropTypes.string,
}
