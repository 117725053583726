import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Button, Modal, AddRecipientForm } from "components"
import { useModal } from "hooks"

export const AddRecipient = ({ disabled, className, ...props }) => {
  const { t } = useTranslation()

  const { modalRef, closeModal, showModal } = useModal()

  const handleSubmit = values => {
    props.onAdd(values.email)

    closeModal()
  }

  return (
    <React.Fragment>
      <Button disabled={disabled} title={"add-contact"} onClick={showModal} />

      <Modal
        ref={modalRef}
        title={t("add-contact")}
        strapModalProp={{
          centered: true,
        }}
      >
        <AddRecipientForm onSubmit={handleSubmit} onCancel={closeModal} />
      </Modal>
    </React.Fragment>
  )
}

AddRecipient.propTypes = {
  onAdd: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}
