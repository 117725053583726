import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"

import { clientAddressData } from "../../../../../../store/actions"

import * as HOC from "../../../../../../HOC"

const DataShower = HOC.withDataShower()

const AddressDataLoaderComponent = props => {
  const dispatch = useDispatch()
  useEffect(() => {
    fetchData()
    return () => {
      dispatch(clientAddressData.cleanState())
    }
  }, [])

  const fetchData = () => {
    dispatch(clientAddressData.getData({ id: props.id }))
  }

  return (
    <DataShower
      isLoading={props.loading}
      isFailed={props.error}
      error={props.error}
    >
      {props.data && props.children}
    </DataShower>
  )
}

AddressDataLoaderComponent.propTypes = {
  id: PropTypes.number,
  children: PropTypes.any,

  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.any,
}

const mapStateToProps = state => {
  const { data, loading, error } = state.client.addresses.data

  return {
    data,
    loading,
    error,
  }
}

export const AddressDataLoader = connect(mapStateToProps)(
  AddressDataLoaderComponent
)
