import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import drag_icon from "../../../assets/images/dev/icons/drag-arrow.svg"

export const DnDSectionContainer = ({
  title,
  children,
  wrapClass,
  provided,
  actions,
}) => {
  return (
    <div
      className={classNames("mb-3", {
        [wrapClass]: wrapClass,
      })}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
    >
      <div className={classNames("dnd-card", {})}>
        <div
          className={classNames("dnd-card__draggable-btn", {
            "mb-3": children,
          })}
          {...provided?.dragHandleProps}
        >
          <div className={"dnd-card__draggable-btn_icon-title"}>
            {provided && (
              <img
                alt={"drag_icon"}
                src={drag_icon}
                className={"dnd-card__draggable-btn_icon"}
              />
            )}
            <div className={"dnd-card__draggable-btn_title"}>{title}</div>
          </div>
          <div className={"dnd-card__draggable-btn_controllers"}>{actions}</div>
        </div>
        <div className={"dnd-card__body"}>
          <div>{children}</div>
        </div>
      </div>
    </div>
  )
}

DnDSectionContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  wrapClass: PropTypes.string,
  provided: PropTypes.any,
  actions: PropTypes.any,
}
