import moment from "moment"

import { MODEL_LOGISTICS_TYPE } from "consts"

export const getLogisticsParams = values => {
  switch (values.type) {
    case MODEL_LOGISTICS_TYPE.ACCOMMODATION:
      return {
        type: values.type,
        date_in: values.date_in
          ? moment(values.date_in).format("YYYY-MM-DD")
          : null,
        date_out: values.date_out
          ? moment(values.date_out).format("YYYY-MM-DD")
          : null,
        country_id: values.country_id ? values.country_id.id : null,
        room: values.room,
        state: values.state,
        town: values.town,
        street: values.street,
        note: values.note,
        expenses: values.expenses,
      }

    case MODEL_LOGISTICS_TYPE.TRAVEL:
      return {
        type: values.type,
        travel_by: values.travel_by || null,
        departure_date: values.departure_date
          ? moment(values.departure_date).format("YYYY-MM-DD")
          : null,
        departure_hour: values.departure_hour
          ? moment(values.departure_hour).format("HH:mm")
          : null,
        arrival_date: values.arrival_date
          ? moment(values.arrival_date).format("YYYY-MM-DD")
          : null,
        arrival_hour: values.arrival_hour
          ? moment(values.arrival_hour).format("HH:mm")
          : null,
        service_details: values.service_details,
        departure_place: values.departure_place,
        arrival_place: values.arrival_place,
        model_notes: values.model_notes,
        agency_notes: values.agency_notes,
        expenses: values.expenses,
      }

    case MODEL_LOGISTICS_TYPE.DRIVER:
      return {
        type: values.type,
        workdays_from: values.workdays_from
          ? moment(values.workdays_from).format("YYYY-MM-DD")
          : null,
        workdays_to: values.workdays_to
          ? moment(values.workdays_to).format("YYYY-MM-DD")
          : null,
        service_details: values.service_details,
        model_notes: values.model_notes,
        agency_notes: values.agency_notes,
        expenses: values.expenses,
      }

    default:
      return {
        type: values.type,
        workdays_from: values.workdays_from
          ? moment(values.workdays_from).format("YYYY-MM-DD")
          : null,
        workdays_to: values.workdays_to
          ? moment(values.workdays_to).format("YYYY-MM-DD")
          : null,
        service_details: values.service_details,
        expenses: values.expenses,
      }
  }
}
