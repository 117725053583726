import { combineReducers } from "redux"

import update from "./update/slice"
import data from "./data/slice"

const dataReducer = combineReducers({
  update,
  data,
})

export default dataReducer
