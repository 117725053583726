import React from "react"
import PropTypes from "prop-types"

import { AddContact as AddContactComponent } from "../../../Common"
import { useModal } from "hooks"
import { TextButton } from "components"

export const AddContact = props => {
  const { modalRef, closeModal, showModal } = useModal()

  return (
    <>
      <TextButton
        title={"add-contact"}
        onClick={showModal}
        color={"secondary"}
      />

      <AddContactComponent
        modalRef={modalRef}
        onSuccess={closeModal}
        onCancel={closeModal}
      />
    </>
  )
}

AddContact.propTypes = {}
